import React, { useState, useEffect, useRef } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import axiosClient from '../../../config/AxiosClient';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton/IconButton';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import { GridOverlay, DataGrid } from '@material-ui/data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import { accessStyles } from '../../../styles/style';
import moment from 'moment-timezone';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import RefreshIcon from '@material-ui/icons/Refresh';
import Select from '@material-ui/core/Select';
import FilterListIcon from '@material-ui/icons/FilterList';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import vi from 'date-fns/locale/vi';
import QRCode from 'qrcode.react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import ResidenceInfo from '../../residences/ResidenceInfo';
import SuccessAlert from '../../alert/SuccessAlert';
import ErrorAlert from '../../alert/ErrorAlert';
import ExportData from './ExportData';
import { apiHistoriesDelete, apiHistoryGetAll } from '../../../api/histories';
import { CustomLoading, EmptyRowsView } from '../../grid';
import { Confirm } from '../../dialog';
import { apiUserinfo } from '../../../api/userinfo';
import LargeTooltip from '../../largeTooltip';

const CloseButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);
const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);
const DeleteButton = withStyles(theme => ({ root: theme.delete_button }))(Button);
const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);

const AccessTable = (props) => {
  const { role, searchString, changeLoadingStatus } = props;
  const classes = accessStyles();
  const [isFilter, setIsFilter] = useState(false);
  const [loading, setLoading] = useState({ page: false, table: false });
  const [filteredData, setFilteredData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [zoomImage, setZoomImage] = useState(false);
  const [src, setSrc] = useState('');
  const [alert, setAlert] = useState({ success: '', error: '', request: '', caller: '', callee: '', receptionist: '' })
  const [callIds, setCallIds] = useState([]);
  const [qrIds, setQrIds] = useState([]);
  const [faceIds, setFaceIds] = useState([]);
  const [faceidFiles, setFaceidFiles] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [QRCodeZoom, setQRCodeZoom] = useState(false);
  const [QRCodeString, setQRCodeString] = useState('');
  const [rowsTotal, setRowsTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageTotal, setPageTotal] = useState(0);
  const [historyType, setHistoryType] = useState('');
  const [historyTime, setHistoryTime] = useState(null);
  const [isExpiredSession, setIsExpiredSession] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const alertRef = useRef();
  const [fullName, setFullName] = useState('');
  const [doorStatus, setDoorStatus] = useState('');
  const [isReceptionist, setIsReceptionist] = useState(false);
  const [receptionist, setReceptionist] = useState({});
  const [receptionistLoading, setReceptionistLoading] = useState(false);
  const columns = [
    {
      headerName: t("Họ và tên"),
      field: 'Requiremer',
      flex: 1,
      cellClassName: 'super-app-theme--cell',
      renderCell: params => showFullName(params.row)
    },
    {
      headerName: t("Chấp nhận bởi"),
      field: 'ApprovedBy',
      flex: 1,
      cellClassName: 'super-app-theme--cell',
      renderCell: params =>
        <LargeTooltip title={showTextApproveBy(params.row)} placement="bottom-start" >
          <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} > {showApproveBy(params.row)}</div>
        </LargeTooltip>
    },
    {
      headerName: t("Thiết bị"),
      field: 'DeviceName',
      flex: 1,
      cellClassName: 'super-app-theme--cell',
      renderCell: params =>
        <LargeTooltip title={params.row.DeviceName} placement="bottom-start" >
          <p style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{params.row.DeviceName}</p>
        </LargeTooltip>
    },
    {
      headerName: t("Thời gian xác thực"),
      field: 'Time',
      width: 190,
      cellClassName: 'super-app-theme--cell',
      renderCell: params => params.row.Time !== '' && <p>{moment(params.row.Time).format('DD-MM-YYYY HH:mm:ss')}</p>
    },
    {
      headerName: t("Phương thức"),
      field: 'Type',
      width: 150,
      cellClassName: 'super-app-theme--cell',
      renderCell: params => showType(params.row)
    },
    {
      headerName: t("Nhiệt độ"),
      field: 'Temperature',
      width: 150,
      cellClassName: 'super-app-theme--cell',
      renderCell: params => showTemperature(params.row)
    },
    {
      headerName: t("Trạng thái cửa"),
      field: 'IsOpenDoor',
      width: 160,
      cellClassName: 'super-app-theme--cell',
      renderCell: params => params.row.IsOpenDoor ? <p style={{ color: '#2AB930' }}>{t('Đã mở cửa')}</p> : <p style={{ color: '#E01010' }}>{t('Đã từ chối')}</p>
    },
    {
      headerName: t("Hình ảnh"),
      field: 'image',
      width: 120,
      sortable: false,
      cellClassName: 'super-app-theme--cell',
      renderCell: params => showMethod(params.row)
    },
  ];

  useEffect(() => {
    if (isExpiredSession) {
      history.push('/login');
      dispatch({ type: 'IS_LOGIN_AGAIN_TRUE' });
    }
  }, [isExpiredSession]);

  const showTemperature = (data) => {
    switch (data.Type) {
      case 'faceid':
        if (parseInt(data.Temperature) === -1) return '';
        if (parseFloat(data.Temperature) < 37)
          return <p className={classes.degree37} >{data.Temperature}</p>
        if (parseFloat(data.Temperature) < 38)
          return <p className={classes.degree38} >{data.Temperature}</p>
        if (parseFloat(data.Temperature) >= 38)
          return <p className={classes.degree39} >{data.Temperature}</p>
        break;
      default: return '';
    }
  }

  const showFullName = (data) => {
    if (data.Type === 'faceid')
      if (data.ApartmentOwner)
        return (
          <LargeTooltip title={data.ApartmentOwner + ' - ' + data.FullName} placement="bottom-start" >
            <div style={{ cursor: 'pointer', textOverflow: 'ellipsis', overflow: 'hidden', textDecoration: 'underline', color: '#0b59c8' }} onClick={() => onEditResidentClick(data.Username)}><b>{data.ApartmentOwner}</b> - {data.FullName}</div>
          </LargeTooltip>)
      else
        return (
          <LargeTooltip title={data.FullName} placement="bottom-start" >
            <div style={{ cursor: 'pointer', textOverflow: 'ellipsis', overflow: 'hidden', textDecoration: 'underline', color: '#0b59c8' }} onClick={() => onEditResidentClick(data.Username)}>{data.ApartmentOwner ? `${data.ApartmentOwner} - ${data.FullName}` : data.FullName}</div>
          </LargeTooltip>)
    else if (data.Type === 'qr' && data.QRType === 'totp')
      if (data.ApartmentOwner)
        return (
          <LargeTooltip title={data.ApartmentOwner + ' - ' + data.FullName} placement="bottom-start" >
            <div style={{ cursor: 'pointer', textOverflow: 'ellipsis', overflow: 'hidden', textDecoration: 'underline', color: '#0b59c8' }} onClick={() => onEditResidentClick(data.Creator)}><b>{data.ApartmentOwner}</b> - {data.FullName}</div>
          </LargeTooltip>)
      else
        return (
          <LargeTooltip title={data.FullName} placement="bottom-start" >
            <div style={{ cursor: 'pointer', textOverflow: 'ellipsis', overflow: 'hidden', textDecoration: 'underline', color: '#0b59c8' }} onClick={() => onEditResidentClick(data.Creator)}>{data.FullName}</div>
          </LargeTooltip>)
    else
      return <div>{t("Khách")}</div>
  }

  const showApproveBy = (data) => {
    if (data.Type === 'call') {
      if (data.CalleeFullName !== '') {
        if (data.CalleeType === 'Residence')
          return (
            <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', textDecoration: 'underline', color: '#0b59c8', cursor: 'pointer' }} onClick={() => onEditResidentClick(data.CalleeUsername)} ><b>{data.Callee}</b> - {data.CalleeFullName}</div>
          )
        else
          return (
            <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', textDecoration: 'underline', color: '#0b59c8', cursor: 'pointer' }} onClick={() => onViewStaffClick(data.CalleeUsername)} >{data.CalleeFullName}</div>
          )
      } else {
        // return(<b>{data.Callee}</b>)
        return '';
      }
    } else if (data.Type === 'qr' && data.QRType !== 'totp') {
      if (data.IsOpenDoor) {
        return <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', textDecoration: 'underline', color: '#0b59c8', cursor: 'pointer' }} onClick={() => onEditResidentClick(data.Creator)}><b>{data.ApartmentOwner}</b> - {data.FullName}</div>;
      } else {
        return '';
      }
    } else return '';
  }


  const showTextApproveBy = (data) => {
    if (data.Type === 'call') {
      if (data.CalleeFullName !== '') {
        if (data.CalleeType === "Receptionist")
          return data.CalleeFullName
        else {
          return (<div><b>{data.Callee}</b> - {data.CalleeFullName}</div>)
        }
      } else {
        return '';
      }
    } else if (data.Type === 'qr' && data.QRType !== 'totp') return data.FullName;
    else return '';
  }

  const showType = (data) => {
    if (data.Type === 'call') return t("Gọi Video");
    return data.Type === 'qr' ? t('Mã QR') : 'Face ID';
  }

  const showMethod = (data) => {
    if (data.Type === 'qr' && data.QRType !== 'totp')
      return <LargeTooltip title={t("Xem mã QR")}>
        <img onClick={() => { onQrCodeZoom(data.QRID + ' ' + data.QRCode) }} style={{ height: '40px', width: '40px', cursor: 'pointer' }} src={window.location.origin + '/images/qrcode.png'} alt="" />
      </LargeTooltip>
    else if (data.Type === 'qr' && data.QRType === 'totp')
      return <LargeTooltip title={t("Xem mã QR")}>
        <img onClick={() => { onQrCodeZoom(data.Creator + ' ' + data.QRCode + ' TOTP') }} style={{ height: '40px', width: '40px', cursor: 'pointer' }} src={window.location.origin + '/images/qrcode.png'} alt="" />
      </LargeTooltip>
    else if (data.Type === 'faceid')
      return <LargeTooltip title={t("Xem ảnh")} >
        <img onClick={() => imageClick(data.Image)} style={{ height: '40px', width: '40px', cursor: 'pointer' }} src={data.Thumbnail} alt="" />
      </LargeTooltip>
    else
      return ''
  }

  const handleHistories = (histories) => {
    var data = [];
    histories.forEach(item => {
      var deviceName = '';
      var approvedBy = '';
      var id = item.ID + '-' + item.Type;
      var requiremer = '';
      var time = '';
      var IsOpenDoor = false;
      if (item.Type === 'call') {
        IsOpenDoor = item.IsOpenDoor;
        approvedBy = item.CalleeFullName;
        deviceName = item.CallerFullName;
        if (item.VerificationTime && item.VerificationTime !== null) time = item.VerificationTime;
      } else if (item.Type === 'faceid') {
        IsOpenDoor = true;
        time = parseInt(item.VerificationTime);
        deviceName = item.DeviceName;
        if (item.ApartmentOwner) requiremer = `${item.ApartmentOwner} - ${item.FullName}`;
        else requiremer = item.FullName
      } else {
        IsOpenDoor = item.VerificationStatus === 'Passed' ? true : false;
        time = item.VerifyTime;
        deviceName = item.DeviceName;
        if (item.QRType !== 'totp') approvedBy = item.FullName;
        else if (item.QRType === 'totp') {
          if (item.ApartmentOwner) requiremer = `${item.ApartmentOwner} - ${item.FullName}`;
          else requiremer = item.FullName;
        }
      }
      data.push({ ...item, id: id, ApprovedBy: approvedBy, Requiremer: requiremer, Time: time, IsOpenDoor: IsOpenDoor, DeviceName: deviceName });
    })
    return data;
  }

  const getQueryInfo = () => {
    var filter = {
      full_name: fullName,
      time: historyTime,
      type: historyType,
      door_status: doorStatus
    };
    if (fullName === '') delete filter.full_name;
    if (historyType.trim() === '') delete filter.type;
    if (historyTime === null) delete filter.time;
    if (doorStatus === '') delete filter.door_status;
    const end = currentPage * rowsPerPage;
    const start = end - rowsPerPage;
    const query = {
      start_index: start,
      end_index: end,
      filter: filter
    };
    return query;
  }

  const getDefaultValues = async () => {
    try {
      var query = getQueryInfo();
      var res = await apiHistoryGetAll(query);
      setRowsTotal(res.data.Count);
      if (res.data.Count > rowsPerPage && res.data.Count % rowsPerPage === 0) setPageTotal(res.data.Count / rowsPerPage);
      else if (res.data.Count > rowsPerPage && res.data.Count % rowsPerPage !== 0) setPageTotal(parseInt(res.data.Count / rowsPerPage) + 1);
      else setPageTotal(1);
      var data = handleHistories(res.data.Items);
      setFilteredData(data);
      setLoading({ page: true, table: true });
      setCallIds([]);
      setQrIds([]);
      setFaceidFiles([]);
      setIsDelete(false);
      setAlert({ ...alert, request: '' });
      changeLoadingStatus();
    } catch (error) {
      if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
      setAlert({ ...alert, request: t("Không lấy được dữ liệu") });
    }
  }

  useEffect(() => {
    if (!loading.page || !loading.table) getDefaultValues();
  }, [currentPage, loading.page, loading.table])

  useEffect(() => {
    setCurrentPage(1);
    setLoading({ ...loading, table: false });
  }, [rowsPerPage])

  useEffect(() => {
    setCurrentPage(1);
    setLoading({ ...loading, table: false });
    setFullName(searchString);
  }, [searchString]);

  const onEditResidentClick = (username) => {
    history.replace(`/admin/manage-access/resident?username=${username}`);
  }

  const onViewStaffClick = async (username) => {
    setIsReceptionist(true);
    setReceptionistLoading(true);
    try {
      const res = await apiUserinfo({ 'username': username, 'type': 'Receptionist' });
      setReceptionist(res.data);
      setAlert({ ...alert, receptionist: '' });
    } catch (error) {
      setAlert({ ...alert, receptionist: t('Không lấy được dữ liệu') });
    }
    setReceptionistLoading(false);
  }

  const onSelectedChange = (e) => {
    if (e.length > 0) {
      setIsDelete(true);
    } else {
      setIsDelete(false);
    }
    const callItems = [];
    const qrItems = [];
    const faceItems = [];
    const files = [];
    const values = e;
    values.forEach(item => {
      if (item.indexOf('qr') !== -1) {
        qrItems.push(item.slice(0, item.indexOf('-')));
      } else if (item.indexOf('call') !== -1) {
        callItems.push(item.slice(0, item.indexOf('-')));
      } else {
        faceItems.push(item.slice(0, item.indexOf('-')));
        const res = filteredData.filter(value => item.indexOf(value.ID) !== -1);
        files.push(res[0].Image, res[0].Thumbnail);
      }
    })
    setCallIds(callItems);
    setQrIds(qrItems);
    setFaceIds(faceItems);
    setFaceidFiles(files);
  }

  const onDeleteClick = () => {
    setConfirmDialog(!confirmDialog);
  }

  const onConfirmDeleteClick = async () => {
    try {
      setIsDeleting(true);
      var data = [];
      if (callIds.length > 0) {
        data.push({
          ids: callIds,
          type: 'call'
        })
      }
      if (faceIds.length > 0) {
        data.push({
          ids: faceIds,
          files: faceidFiles,
          type: 'faceid'
        })
      }
      if (qrIds.length > 0) {
        data.push({
          ids: qrIds,
          type: 'qr'
        })
      }
      var query = getQueryInfo();
      await apiHistoriesDelete({
        ...query,
        histories: data
      });
      setAlert({ ...alert, success: t("Xóa thành công!"), error: '' });
      setLoading({ ...loading, table: false });
      setIsDeleting(false);
      setConfirmDialog(false);
    } catch (error) {
      if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
      setAlert({ ...alert, error: t("Xóa không thành công, vui lòng thử lại!"), success: '' });
      setIsDeleting(false);
      setConfirmDialog(false);
    }
  }

  const onAlertClose = () => {
    setAlert({ ...alert, success: '', error: '' });
  }

  useEffect(() => {
    if (alert.success !== '' || alert.error !== '') {
      if (alertRef.current) clearTimeout(alertRef.current);
      alertRef.current = setTimeout(() => {
        onAlertClose();
      }, 5000);
    }
  }, [alert])

  const onQrCodeZoom = (qr) => {
    setQRCodeZoom(true);
    setQRCodeString(qr);
  }

  const onQRCodeClose = () => {
    setQRCodeZoom(false);
  }

  const imageClick = (image) => {
    setSrc(image);
    setZoomImage(true);
  }

  const onCloseImageClick = () => {
    setZoomImage(false);
  }

  const onDateFilterChange = (e) => {
    if (e === null) setHistoryTime(null);
    else {
      const date = new Date(e).getTime();
      setHistoryTime(date);
    }
  }

  const onFullNameChange = (e) => {
    setFullName(e.target.value);
  }

  const QRCodeZoomDialog = () => {
    return (
      <Dialog open={QRCodeZoom} maxWidth="lg" >
        <DialogContent style={{ textAlign: 'right', width: '500px', height: '450px' }}>
          <IconButton style={{ position: 'absolute', top: '0', right: '0', borderRadius: '50%', padding: '2px', background: 'gray' }} onClick={onQRCodeClose} >
            <CloseIcon style={{ color: 'white' }} />
          </IconButton>
          <div style={{ width: '100%', height: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <QRCode value={QRCodeString} renderAs="svg" style={{ width: '300px', height: '300px' }} /> <br /><br />
          </div>
          <p style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'bold' }}>{t('QR Code')}: {QRCodeString}</p>
        </DialogContent>
      </Dialog>
    )
  }

  const dialogDelete = () => {
    return (
      <Dialog
        open={confirmDialog}
        maxWidth="xl"
      >
        <DialogTitle className={classes.dialogTitleBackground}>
          <p className={classes.dialogTitle}>{t("Thông báo")}</p>
        </DialogTitle>
        <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center', position: 'relative' }}>
          <p className={classes.contentDialog}> {t("Bạn có chắc chắn muốn xóa lịch sử ra vào?")} </p>
          <CloseButton
            style={{ margin: '10px 0' }}
            onClick={() => { setConfirmDialog(!confirmDialog) }}
            disabled={isDeleting}
          >
            {t("Trở về")}
          </CloseButton>
          <CreateButton
            style={{ margin: '10px 0 10px 24px' }}
            onClick={onConfirmDeleteClick}
            disabled={isDeleting}
          >
            {t("Đồng ý")}
          </CreateButton>
          {isDeleting && <CircularProgress style={{ position: 'absolute', top: '10%', left: '45%', color: '#0b59c8' }} />}
        </DialogContent>
      </Dialog>
    )
  }

  const zoomImageDialog = () => {
    return (
      <Dialog open={zoomImage} maxWidth="lg" >
        <DialogContent style={{ textAlign: 'right', width: '480px', height: '480px' }}>
          <IconButton style={{ position: 'absolute', top: '0', right: '0', zIndex: '2', borderRadius: '50%', padding: '2px', background: 'gray' }} onClick={onCloseImageClick} className={classes.closeButton}>
            <CloseIcon style={{ color: 'white' }} />
          </IconButton>
          <img src={src} alt="" width='100%' height='100%' style={{ position: 'absolute', top: '0', left: '0', zIndex: '1', objectFit: 'cover' }} />
        </DialogContent>
      </Dialog>
    )
  }

  const showReceptionist = () => {
    return (
      <Dialog
        open={isReceptionist}
        maxWidth="xl"
      >
        <DialogContent style={{ width: '448px', padding: '0' }}>
          {receptionistLoading &&
            <div style={{ height: '455px', width: '448px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '20px', color: '#6D6D6D' }}>
              <CircularProgress style={{ color: '#0b59c8' }} />
            </div>}
          {!receptionistLoading && alert.receptionist === '' && <div>
            <p className={classes.secondaryTitle} style={{ textAlign: 'center', paddingTop: '10px' }}>{t('Thông tin người chấp nhận')}</p>
            <div style={{ width: '90%', margin: 'auto', height: '80px' }}>
              <label className={classes.label}>{t('Tên lễ tân')} </label><br />
              <TextField
                placeholder={t('Tên lễ tân')}
                variant="outlined"
                style={{ width: '100%' }}
                size="small"
                value={receptionist.DisplayName}
                disabled
              />
            </div>
            <div style={{ width: '90%', margin: 'auto', height: '80px' }}>
              <label className={classes.label}>{t('Tên đăng nhập')} </label><br />
              <TextField
                placeholder={t('Tên đăng nhập')}
                variant="outlined"
                style={{ width: '100%' }}
                size="small"
                value={receptionist.UserName}
                disabled
              />
            </div>
            <div style={{ width: '90%', margin: 'auto', height: '80px' }}>
              <label className={classes.label}>{t('Số điện thoại')}</label><br />
              <TextField
                placeholder={t('Số điện thoại')}
                variant="outlined"
                style={{ width: '100%' }}
                size="small"
                value={receptionist.Phone}
                disabled
              />
            </div>
            <div style={{ width: '90%', margin: 'auto', height: '80px' }}>
              <label className={classes.label}>Block </label><br />
              <TextField
                placeholder='Block'
                variant="outlined"
                style={{ width: '100%' }}
                size="small"
                value={receptionist.BlockNumber}
                disabled
              />
            </div>
          </div>}
          {!receptionistLoading && alert.receptionist !== '' && <div style={{ height: '455px', width: '448px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '20px', color: '#6D6D6D' }}>
            {alert.receptionist}
          </div>}
          {!receptionistLoading && <div style={{ width: '80%', margin: 'auto', textAlign: 'right', marginTop: '22px', paddingBottom: '22px' }}>
            <CancelButton
              variant="outlined"
              onClick={() => {
                setIsReceptionist(false);
                setReceptionist({});
              }}
            >
              {t("Đóng")}
            </CancelButton>
          </div>}
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <div style={{ paddingBottom: '1%' }}>
      {QRCodeZoomDialog()}
      {zoomImageDialog()}
      {showReceptionist()}
      <Confirm
        isOpen={confirmDialog}
        handleClose={() => setConfirmDialog(!confirmDialog)}
        isLoading={isDeleting}
        mess={t("Bạn có chắc chắn muốn xóa lịch sử ra vào?")}
        onConfirm={onConfirmDeleteClick}
      />
      <SuccessAlert
        message={alert.success}
        onCloseDialog={onAlertClose}
      />
      <ErrorAlert
        message={alert.error}
        onCloseDialog={onAlertClose}
      />
      {alert.request !== '' && <div className={classes.requestError}>{alert.request}</div>}
      {!loading.page && alert.request === '' && <CircularProgress color="primary" style={{ marginLeft: '50%', color: '#0b59c8' }} />}
      {loading.page && alert.request === '' &&
        <div style={{ textAlign: 'right' }}>
          {isFilter &&
            <Paper className={clsx(classes.inputHeight, classes.focus)}>
              <div style={{ width: '100%' }}>
                <div className={classes.inputRightBlock}>
                  <label className={classes.label} style={{ marginBottom: '0' }}>{t("Họ và tên")}</label><br />
                  <TextField
                    classes={{
                      root: classes.input
                    }}

                    size="small"
                    style={{ background: '#FFFFFF', width: '100%' }}
                    placeholder={t("Họ và tên")}
                    variant="outlined"
                    value={fullName}
                    onChange={onFullNameChange}
                  />
                </div>
                <div className={classes.inputRightBlock}>
                  <label className={classes.label} style={{ marginBottom: '0' }}>{t("Ngày xác thực")}</label><br />
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === 'vi' ? vi : ''} >
                    <KeyboardDatePicker
                      inputVariant="outlined"
                      style={{ position: 'relative', top: '-16px', width: '100%' }}
                      size="small"
                      format="dd/MM/yyyy"
                      margin="normal"
                      placeholder={t("Ngày xác thực")}
                      InputProps={{ readOnly: true }}
                      value={historyTime}
                      onChange={onDateFilterChange}
                      helperText={isNaN(historyTime) ? t("Thông tin chưa hợp lệ") : ''}
                      cancelLabel={t("Hủy")}
                      okLabel={t("Đồng ý")}
                      views={["date", "month", "year"]}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div className={clsx(classes.select, classes.inputRightBlock)}>
                  <label className={classes.label} style={{ marginBottom: '0' }}>{t("Phương thức")}</label><br />
                  <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                    <Select
                      value={historyType}
                      displayEmpty
                      onChange={(e) => setHistoryType(e.target.value)}
                    >
                      <MenuItem value='' disabled ><p className={classes.placeholder}> {t("Phương thức")} </p> </MenuItem>
                      <MenuItem value='call'>{t("Gọi Video")}</MenuItem>
                      <MenuItem value='faceid'>FaceID</MenuItem>
                      <MenuItem value='qr'>QR Code</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className={clsx(classes.select, classes.inputRightBlock)}>
                  <label className={classes.label} style={{ marginBottom: '0' }}>{t("Trạng thái cửa")}</label><br />
                  <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                    <Select
                      value={doorStatus}
                      displayEmpty
                      onChange={(e) => setDoorStatus(e.target.value)}
                    >
                      <MenuItem value='' disabled ><p className={classes.placeholder}> {t("Trạng thái cửa")} </p> </MenuItem>
                      <MenuItem value={true}>{t("Đã mở cửa")}</MenuItem>
                      <MenuItem value={false}>{t("Đã từ chối")}</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div className={classes.inputRightBlock} style={{ minWidth: '210px' }}>
                  <br />
                  <CreateButton
                    variant="contained"
                    style={{ marginTop: '8px', height: '32px' }}
                    onClick={() => {
                      setLoading({ ...loading, table: false });
                      setCurrentPage(1);
                    }}

                  >
                    {t("Áp dụng")}
                  </CreateButton>
                  <CloseButton
                    variant="outlined"
                    style={{ marginLeft: '15px', marginTop: '8px', height: '32px' }}
                    onClick={() => {
                      setHistoryType('');
                      setHistoryTime(null);
                      setFullName('');
                      setDoorStatus('');
                    }}
                  >
                    {t("filter.Xóa")}
                  </CloseButton>
                </div>
              </div>
            </Paper>}
        </div>}
      {loading.page && alert.request === '' &&
        <div className={clsx(classes.tableContent, classes.root)}>
          <div className={classes.tableTitle}>
            {(role === 'admin' || role === 'root') &&
              <DeleteButton
                variant="outlined"
                style={{ marginTop: '7px', marginLeft: '8px', float: 'left' }}
                startIcon={<DeleteOutlineOutlinedIcon />}
                onClick={onDeleteClick}
                disabled={!isDelete}
              >
                {t("Xóa")}
              </DeleteButton>
            }
            <ExportData historyPerPage={filteredData} />
            <CreateButton
              variant="contained"
              style={{ marginTop: '7px', marginLeft: '8px' }}
              startIcon={<RefreshIcon />}
              onClick={() => { setLoading({ ...loading, table: false }) }}
            >
              {t("Làm mới")}
            </CreateButton>
            <CancelButton
              style={{ float: 'right', position: 'relative', margin: '7px 8px 0 0' }}
              variant="outlined"
              startIcon={<FilterListIcon />}
              onClick={() => setIsFilter(!isFilter)}
            >
              {t("Bộ lọc")}
            </CancelButton>
          </div>
          {(role === 'admin' || role === 'root') &&
            <DataGrid
              columns={columns}
              rows={filteredData}
              pageSize={rowsPerPage}
              rowsPerPageOptions={[10, 50, 100]}
              onPageSizeChange={(pageSize) => setRowsPerPage(pageSize)}
              checkboxSelection
              onSelectionModelChange={onSelectedChange}
              hideFooterRowCount
              disableSelectionOnClick
              disableColumnMenu
              hideFooterPagination
              loading={!loading.table}
              components={{
                NoRowsOverlay: () => EmptyRowsView(t('Không tìm thấy lịch sử ra vào')),
                LoadingOverlay: CustomLoading,
              }}
              localeText={{
                footerRowSelected: (count) => count + t(" hàng trong bảng đã được chọn"),
                columnHeaderSortIconLabel: t('Sắp xếp')
              }}
            />}
          {role === 'moderator' &&
            <DataGrid
              columns={columns}
              rows={filteredData}
              pageSize={rowsPerPage}
              rowsPerPageOptions={[10, 50, 100]}
              onPageSizeChange={(pageSize) => setRowsPerPage(pageSize)}
              hideFooterRowCount
              disableSelectionOnClick
              disableColumnMenu
              hideFooterPagination
              loading={!loading.table}
              components={{
                NoRowsOverlay: () => EmptyRowsView(t('Không tìm thấy lịch sử ra vào')),
                LoadingOverlay: CustomLoading,
              }}
              localeText={{
                footerRowSelected: (count) => count + t(" hàng trong bảng đã được chọn"),
                columnHeaderSortIconLabel: t('Sắp xếp')
              }}
            />
          }
          <div style={{ position: 'absolute', bottom: '2px', right: '5px', display: 'flex' }} className={classes.selectInput}>
            <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0 20px' }} >{t("Số dòng mỗi trang")}</p>
            <Select
              value={rowsPerPage}
              onChange={(e) => { setRowsPerPage(e.target.value) }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
            <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0 20px' }} >
              {rowsTotal === 0 ? 0 : (currentPage * rowsPerPage) - rowsPerPage + 1}-{currentPage * rowsPerPage > rowsTotal ? rowsTotal : currentPage * rowsPerPage} {t("trong số")} {rowsTotal}
            </p>
            <IconButton
              disabled={currentPage === 1}
              onClick={() => {
                setCurrentPage(currentPage - 1);
                setLoading({ ...loading, table: false });
              }}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
            <IconButton
              disabled={currentPage === pageTotal}
              onClick={() => {
                setCurrentPage(currentPage + 1);
                setLoading({ ...loading, table: false });
              }}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          </div>
        </div>
      }
    </div>
  )
}

export default AccessTable;
