export const PaymentConfiguration = {
    HIDE: -1,
    NOT_REQURIRE: 0,
    REQUIRE: 1,
    SHOW: 2,
    UNPAID: "unpaid",
    PAID: "paid",
    EXPIRED: "expired",
    ALLOW: 3,
    NEED_APPROVAL: 4,
    DENIED: 5
}

export const NotificationConfiguration = {
    ON: 1,
    OFF: -1,
    ASK: 0
}

export const FaceError = {
    IMAGE_IS_NONE: 477,
    INVALID_IMAGE: 480,
    CANNOT_DETECT_FACE: 481,
    BLURRY_IMAGE: 482,
    DUPLICATED: 483,
    MORE_THAN_ONE_FACE_IN_FRAME: 484,
    NO_FACE_LARGE_ENOUGH: 486,
    INVALID_FACE: 487,
    HOST_EXISTS: 'host exists',
    CAN_NOT_FIND_OUT_MAIN_RESIDENT: 'Can not find out Main Residence',
    EMAIL_EXISTS: 'Email exists'
}

