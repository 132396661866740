import React,{useState,useEffect} from 'react';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import Button from '@material-ui/core/Button/Button';
import { GridOverlay, DataGrid } from '@material-ui/data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import axiosClient from '../../config/AxiosClient';
import Cookies from 'js-cookie';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import LargeTooltip from '../largeTooltip/index';

const useStyles = makeStyles((theme) => ({
    primaryTitle:theme.primary_title,
    table:{width:'100%',height:'500px',background:'#FFFFFF',paddingBottom:'55px',marginBottom:'22px',borderRadius:'6px'},
    root:theme.dataGridHeaderStyle,
    icon:{
        '& svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall':{
            color:'#2DCE8', fontWeight:'bold'
        }
    },
    tableTitle:{position:'relative',height:'48px',border:'1px solid rgba(224, 224, 224, 1)',borderBottom:'none',borderTopLeftRadius:'6px',borderTopRightRadius:'6px'},
}))

const CreateButton = withStyles(theme =>({root:theme.create_button}))(Button);

const WarningTable = (props) =>{
    const {t} = useTranslation();
    const classes = useStyles();
    const [loading,setLoading] = useState(false);
    const [warnings,setWarnings] = useState([]);
    const columns = [
        {
            headerName: t("Cảnh báo/Lỗi"),
            field: 'Description',
            flex:1,
            cellClassName:'super-app-theme--cell-dashboard',
            renderCell:params=>
            <LargeTooltip title={params.row.Description} placement="bottom-start">
                <p style={{textOverflow: 'ellipsis',overflow:'hidden'}}>{params.row.Description}</p>
            </LargeTooltip>
        },
        {
            headerName: t("Độ ưu tiên"),
            field: 'Priority',
            flex:0.6,
            renderCell:params=>showPriority(params.row.Priority)
        },  
        {
            headerName: t("Giải pháp"),
            field: 'Resolution',
            flex:0.6,
            cellClassName:'super-app-theme--cell-dashboard',
            renderCell:params=>
            <LargeTooltip title={showResolution(params.row.Resolution)} placement="bottom-start">
                <p style={{textOverflow: 'ellipsis',overflow:'hidden'}}>{showResolution(params.row.Resolution)}</p>
            </LargeTooltip>
        },  
        
    ]

    const showPriority = (priority)=>{
        switch (priority) {
            case 'normal': return t('Bình thường');     
            default: return '';  
        }
    }

    const showResolution = (resolution) =>{
        switch (resolution) {
            case '':
                return <i>{t('Chưa xử lý')}</i>
            case undefined:
                return <i>{t('Chưa xử lý')}</i>
            default:
                return resolution;
        }
    }   

    const getDefaultValues = async() =>{
        try {
            if(process.env.REACT_APP_IS_WARNING==='hide') return setLoading(true);
            var res;
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                res = await axiosClient.get(`/people/counting/warnings?offset=0&&limit=5`,{headers:{'Authorization': `Bearer ${Cookies.get('token')}`}}); 
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                res = await axiosClient.get(`/warning?offset=0&&limit=5`,{headers:{'Authorization': `Bearer ${Cookies.get('token')}`}});
            }
            const data =[];
            res.data.Items.forEach(item=>{
                data.push({...item,id:item.ID});
            })
            setWarnings(data);
        } catch (error) {
            
        }
        setLoading(true);
    }

    useEffect(()=>{
        if(!loading) getDefaultValues();
    },[loading])

    const EmptyRowsView = () => {
        return (
          <div
            style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}
          >
            <h3 style={{fontSize: '16px'}}>
                {t('Không tìm thấy cảnh báo/lỗi')}
            </h3>
          </div>
        );
    };

    const customLoading = () =>{
        return(
            <GridOverlay>
                <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                    <LinearProgress />
                </div>
            </GridOverlay>
        )
    }

    return(
        <div>
            <div className={classes.tableTitle}>
                <p style={{fontSize:'18px',float:'left',margin:'10px',fontWeight:'bold',color:'#333333',opacity:'0.6'}}>{t('Cảnh báo/Lỗi')}</p>
                <CreateButton 
                    variant="contained" 
                    style={{margin:'7px',float:'right',height:'32px'}}
                    onClick={()=>setLoading(false)}
                >
                    {t('Làm mới')}
                </CreateButton>
            </div>
            <div style={{height:'283px'}} className={clsx(classes.root)}>
                <DataGrid 
                    componentsProps={{
                        pagination: {
                            labelRowsPerPage: t("Số dòng mỗi trang"),
                            labelDisplayedRows:({from,to,count}) => {return `${from}-${to} ${t('trong số')} ${count}`}
                        }
                    }}
                    components={{
                        NoRowsOverlay:EmptyRowsView,
                        LoadingOverlay: customLoading,
                    }}  
                    rows={warnings} 
                    columns={columns} 
                    pageSize={5}
                    rowHeight={45}
                    hideFooterRowCount
                    hideFooterSelectedRowCount
                    disableSelectionOnClick
                    disableColumnMenu={true}
                    hideFooter
                    localeText={{columnHeaderSortIconLabel: t('Sắp xếp')}}
                    loading={!loading}
                />
            </div>
        </div>
    )
}

export default WarningTable;