import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { getAdmin } from '../api/adminuser';
import AccessComon from '../components/access/common/CommonTable';
import AdminDataTable from '../components/admin/AdminDataTable';
import DeviceMenu from '../components/device_management/DeviceMenu';
import Menu from '../components/menu/Menu';
import MyAccount from '../components/myAccount/MyAccount';
import AddNotificationForm from '../components/notifications/AddNotificationForm';
import NotificationsTable from '../components/notifications/NotificationsTable';
import AddResidenceFile from '../components/residences/AddResidenceFile';
import AddResidenceForm from '../components/residences/AddResidenceForm';
import ApprovalHistories from '../components/residences/approval_history/ApprovalHistories';
import PaymentMenu from '../components/paymentInfo/PaymentMenu';
import ResidentDetailInfo from '../components/residences/approval_history/ResidentDetailInfo';
import SmartCamera from '../components/smart_camera/live_camera/SmartCamera';
import CameraTable from '../components/smart_camera/settings/CameraTable';
import SmartCameraMenu from '../components/smart_camera/SmartCameraMenu';
import makeStyles from '@material-ui/core/styles/makeStyles';
import RegisterOverview from '../components/residences/RegisterOverview';
import ResidencesTable from '../components/residences/ResidencesTable';
import Setting from '../components/setting/Setting';
import PaymentTable from '../components/paymentInfo/payments/PaymentTable';
import AddPaymentInfo from '../components/paymentInfo/payments/AddPaymentInfo';
import Staff from '../components/staff_management/staff/Staff';
import Dashboard from '../components/statistic/Dashboard';
import Warnings from '../components/warning/Warnings';
import manageBookingMenu from '../pages/manageBooking';
import SecurityCamera from '../pages/securityCamera';
import ManageCamera from '../pages/securityCamera/manageCamera';
import SingleCamera from '../pages/securityCamera/singleCamera';
import PendingApprovalList from '../components/residences/PendingApprovalList';
import RegisterDetail from '../components/residences/RegisterDetail';
import ChangedFaceDetail from '../components/residences/ChangedFaceDetail';
import Cabinet from '../pages/cabinet';
import CanbinetDetails from '../pages/cabinet/management/cabinetDetails';

const useStyles = makeStyles(theme=>({
    pageStyle:{
        width: '100%', 
        height: '99vh', 
        display: 'flex'
    },
    totalPage: {
        width: '100%',
        maxWidth: 'calc(100% - 70px)',
        height: '100%',
        minHeight: '640px'
    },
    pageItem: {
        height: '100%',
        paddingTop: '80px'
    }
}));

const DirectURL = () => {
    const classes = useStyles();
    const [permission, setPermission] = useState(false);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const loadingConfig = useSelector(state => state.configState);
    const adminUserState = useSelector(state => state.adminUserState);
    const dispatch = useDispatch();
    const history = useHistory();

    const getAdminRole = async (username) => {
        try {
            const res = await getAdmin({ username: username });
            dispatch({
                type: 'ADD_ADMIN_USER_INFO',
                username: username,
                role: res.data.Items[0].AdminRole
            })
            setPermission(true);
            setLoading(true);
        } catch (error) {
            if (error.response && error.response.data === "Invalid LoginToken"){
                history.push('/login');    
            } 
        }
    }

    useEffect(() => {
        if (localStorage.getItem('username') === '' || localStorage.getItem('username') === null) {
            setPermission(false);
            history.push('/login');
        } else {
            if (adminUserState.role === '') {
                getAdminRole(localStorage.getItem('username'));
            } else {
                setPermission(true);
            }
        }
    }, [location, localStorage.getItem('username')])

    useEffect(() => {
        if (loadingConfig.loading) {
            setLoading(true);
        }
    }, [loadingConfig.loading])

    return (
        <div>
            {loading && permission &&
            <div className={classes.pageStyle}>
                <Route path="/admin" component={Menu}/>
                <div className={classes.totalPage}>
                    <div className={classes.pageItem}>
                        <Route exact path="/admin/manage-residences" component={ResidencesTable}/>
                        {/* <Route exact path="/admin/register-overview" component={RegisterOverview}/> */}
                        <Route exact path="/admin/register-overview" component={PendingApprovalList}/>
                        <Route exact path="/admin/register-overview/detail/:residentID" component={RegisterDetail}/>
                        <Route exact path="/admin/register-overview/faceid/detail/:residentID" component={ChangedFaceDetail}/>
                        <Route exact path="/admin/approval-history" component={ApprovalHistories}/>
                        <Route path="/admin/approval-history/resident" component={ResidentDetailInfo}/>
                        <Route exact path="/admin/add-new-residence" component={AddResidenceForm}/>
                        <Route exact path="/admin/add-new-residences" component={AddResidenceFile}/>             
                        {process.env.REACT_APP_IS_STAFF==='show' && <Route path="/admin/staff-management" component={Staff}/>}
                        <Route exact path="/admin/manage-access" component={AccessComon}/>
                        <Route path="/admin/manage-access/resident" component={ResidentDetailInfo}/>
                        {process.env.REACT_APP_IS_WARNING==='show' && <Route exact path="/admin/warnings" component={Warnings}/>}
                        <Route exact path="/admin/statistic-page" component={Dashboard}/>
                        <Route exact path="/admin/manage-notifications" component={NotificationsTable}/>
                        <Route exact path="/admin/add-notification" component={AddNotificationForm}/>
                        {/* <Route path="/admin/payments" component={PaymentMenu}/> */}
                        {process.env.REACT_APP_PAYMENT==='show' &&<Route exact path="/admin/payments/info" component={PaymentTable}/>}
                        {process.env.REACT_APP_PAYMENT==='show' &&<Route exact path="/admin/payments/add-info" component={AddPaymentInfo}/>}
                        <Route exact path="/admin/my-account" component={MyAccount}/>
                        {process.env.REACT_APP_IS_SMART_CAMERA==='show' && <Route path="/admin/smart-camera" component={SmartCameraMenu}/>}
                        <Route path="/admin/device-management" component={DeviceMenu}/>
                        {process.env.REACT_APP_IS_SECURITY_CAMERA==='show' && <Route exact path="/admin/security-camera" component={SecurityCamera} />}
                        {process.env.REACT_APP_IS_SECURITY_CAMERA==='show' && <Route exact path={"/admin/security-camera/manage"} component={ManageCamera} />}
                        {process.env.REACT_APP_IS_SECURITY_CAMERA==='show' && <Route exact path={"/admin/security-camera/live/:cameraID"} component={SingleCamera} />}
                        <Route path="/admin/cabinet" component={Cabinet} />
                        {process.env.REACT_APP_IS_BOOKING !== "hide" && <Route path="/admin/service-booking" component={manageBookingMenu} />}
                        {(adminUserState.role === 'admin' || adminUserState.role === 'root') && <Route path="/admin/setting" component={Setting}/>}
                        {(adminUserState.role === 'admin' || adminUserState.role === 'root') && <Route exact path="/admin/admin-account" component={AdminDataTable}/>} 
                    </div>
                </div>           
            </div>}
        </div>
    )

}

export default DirectURL;