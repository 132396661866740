import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    time: {
        position: 'relative',
        minWidth: '70px',
        width: '70px',
        height: "fit-content"
    },
    title: {
        position: '-webkit-sticky',
        position: 'sticky',
        top: '0',
        zIndex: "11",

        display: 'flex',
        justifyContent: "center",
        alignItems: 'center',

        marginBottom: '1rem',
        border: '1px solid rgba(0, 0, 0, 0.3)',
        borderBottom: '4px solid black',
        height: '70px',
        background: 'white',
        'box-shadow': '0px 10px 8px 0 rgba(0, 0, 0, 0.3)'
    },
    item: {
        display: 'flex',
        justifyContent: "center",
        alignItems: "flex-end",
        height: '40px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        "&:nth-child(3)": {
            borderTop: '1px solid rgba(0, 0, 0, 0.1)',
        }
    },
    childItem: {
        marginBottom: "-9.5px",
        height: 'fit-content',
        background: "white"
    }
}));

export default useStyles;