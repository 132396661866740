import AxiosClient from "../config/AxiosClient";

export const apiInfo = async (bodyRequest) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/info';
    } else {
        url = '/residences/get';
    }
    return await AxiosClient.post(url, bodyRequest);
}