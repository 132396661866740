import {combineReducers } from 'redux';
import ConfigReducer from './ConfigReducer';
import NotificationReducer from './NotificationReducer';
import LoginSessionReducer from './SessionReducer';
import SyncAllReducer from './SyncAllReducer';
import AdminUserReducer from './AdminUserReducer';
import serviceBooking from './serviceBooking';
import cabinetReducer from './cabinetReducer'

const rootReducer = combineReducers({
    configState: ConfigReducer,
    notiState:NotificationReducer,
    sessionState:LoginSessionReducer,
    syncAllState:SyncAllReducer,
    adminUserState:AdminUserReducer,
    serviceBooking,
    cabinetReducer
})

export default rootReducer;