import React,{useState,useEffect,useRef} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button/Button';
import {isEmpty} from '../../../validation/CheckForm';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import {useTranslation} from 'react-i18next';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import IconButton from '@material-ui/core/IconButton';
import {useHistory} from 'react-router-dom';
import axiosClient from '../../../config/AxiosClient';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import md5 from 'md5';
import CircularProgress from '@material-ui/core/CircularProgress';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import SuccessAlert from '../../alert/SuccessAlert';

const useStyles = makeStyles((theme)=>({
    inputBlock:{height:'80px',width:'100%',position:'relative'},
    label:{margin:'0',fontSize: '14px',lineHeight: '140%',color: '#000000',display: 'inline-block'},
    input:{width: '100%'},
    placeholder:theme.place_holder,
    error:{color:'red',position:'absolute',bottom:'0',left:'20px'},
    alert:{position:'absolute', left:'3px', top:'3px',width:'99%'},
    requestError:{width:'100%',height:'65vh',display:'flex',justifyContent:'center',alignItems:'center',fontSize:'20px',color:'#6D6D6D'},
    secondaryTitle:theme.secondary_title,
    dialogTitle:{textAlign:'center',fontSize:'20px',fontWeight:'600',lineHeight:'25px',color:'#FFFFFF'},
    contentDialog:{fontSize:'16px',height:'68px',borderBottom:'1px solid #EEEDF2',display:'flex',alignItems:'center',justifyContent:'center'},
    dialogTitleBackground:theme.primaryColor,
    errorColor:theme.errorColor,
    focus:{
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': { 
                borderColor: theme.themeColor,
            },
        },
    },
    select:{
        '& .MuiOutlinedInput-input':{padding:'10px 12px'}
    },
    primaryColor:theme.primaryTextColor
}))

const CreateButton = withStyles(theme =>({root:theme.create_button}))(Button);
const CancelButton = withStyles(theme =>({root:theme.cancel_button}))(Button);
const ErrorButton = withStyles(theme =>({root:theme.error_button}))(Button);
const EditConfig = props =>{
    const classes = useStyles();
    const {onEditStatusChange,profileName,configs} = props;
    const [profile,setProfile] = useState({
        profile_name:'',
        profile_description:'',
        useInternalRelay:'',
        deviceBlock:'',
        fdThreshold:'',
        // fdCamera:'',
        // fdRotateImageAngle:'',
        // fdResolution:'',
        receptionistNumber:'',
        useSpeaker:'',
        callCameraResolution:'',
        callCamera:'',
        ledLightMode:''
    })
    const [alert,setAlert] = useState({
        profile_name:'',
        profile_description:'',
        useInternalRelay:'',
        deviceBlock:'',
        fdThreshold:'',
        receptionistNumber:'',
        useSpeaker:'',
        callCameraResolution:'',
        callCamera:'',
        success:'',
        ledLightMode:''
    });
    const [blocks,setBlocks] = useState([]);
    const [receptionists,setReceptionists] = useState([]);
    const [isFailed,setIsFailed] = useState(false);
    const [isUpdating,setIsUpdating] = useState(false);
    const [isUpdate,setIsUpdate] = useState(false);
    const [isEdit,setIsEdit] = useState(false);
    const [loading,setLoading] = useState(false);
    const [faceidOptional,setFaceidOptional] = useState(false);
    const [callingOptional,setCallingOptional] = useState(false);
    const [newPassword,setNewPassword] = useState('');
    const history = useHistory();
    const {t} = useTranslation();
    const profileRef = useRef();
    const [isExpiredSession,setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();

    useEffect(()=>{
        if(isExpiredSession){
          history.push('/login');
          dispatch({type:'IS_LOGIN_AGAIN_TRUE'});
        } 
    },[isExpiredSession]);

    const getBlocks = async()=>{
        try {
            var res;
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                res = await axiosClient.get('/getapartmentdata',{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
                });
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                res = await axiosClient.get('/apartmentrooms/get',{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`,'Content-Type': 'application/json'}
                });
            }
            var blockArr = [];
            res.data.Items.forEach(item=>{
                blockArr.push(item.BlockID);
            })
            blockArr.sort();
            blockArr = [...new Set(blockArr)];
            setBlocks(blockArr);   
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
        }   
    }

    const getReceptionists = async()=>{
        try {
            var res;
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                res = await axiosClient.get('/receptionist/getall',{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
                });
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                res = await axiosClient.get('/receptionists/get',{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`,'Content-Type':'application/json'}
                });
            }
            setReceptionists(res.data.Items);
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
        }
        
    }

    const getDefaultValues = async() =>{
        try {
            const res = await axiosClient.post('/profile/get',{'profile_name':profileName},{
                headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
            });
            const data = res.data.Items[0];
            setProfile({...profile,
                profile_name:data.ProfileName,
                profile_description:data.Description,
                deviceBlock:data.Payload.deviceBlock,
                // fdResolution:data.Payload.fdResolution,
                // fdRotateImageAngle:data.Payload.fdRotateImageAngle,
                fdThreshold:data.Payload.fdThreshold,
                // fdCamera:data.Payload.fdCamera,
                receptionistNumber:data.Payload.receptionistNumber,
                useInternalRelay:data.Payload.useInternalRelay,
                useSpeaker:data.Payload.useSpeaker,
                ledLightMode:data.Payload.ledLightMode,
                callCameraResolution:data.Payload.callCameraResolution,
                callCamera:data.Payload.callCamera
            })
            setAlert({...alert,
                profile_name:'',
                profile_description:'',
                useInternalRelay:'',
                deviceBlock:'',
                fdThreshold:'',
                // fdCamera:'',
                // fdRotateImageAngle:'',
                // fdResolution:'',
                receptionistNumber:'',
                useSpeaker:'',
                callCameraResolution:'',
                callCamera:''
            })
            setNewPassword('');
            setIsEdit(false);
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
        }
        setLoading(true);
    }
    useEffect(()=>{  
        if(!loading) getDefaultValues();
    },[loading])
    
    useEffect(()=>{
        getBlocks();
        getReceptionists();
    },[])

    const getReceptionistPhoneOptions = () =>{
        return receptionists.map((item,key)=>{
            return(
                <MenuItem key={key} value={item.Phone}>{item.Phone} - {item.DisplayName}</MenuItem>
            )
        })
    }
    const onBlockChange = (e, newValue) =>{
        if(newValue === null){
            setAlert({...alert,deviceBlock:t('Không được để trống')});
            setProfile({...profile,deviceBlock:''});
            return;
        }
        setAlert({...alert,deviceBlock:""});
        setProfile({...profile,deviceBlock:newValue});
    }

    const onProfileNameChange = (e) =>{
        const value = e.target.value;
        setProfile({...profile,profile_name:value});
        if(isEmpty(value)) setAlert({...alert,profile_name:t('Không được để trống')});
        else{
            setAlert({...alert,profile_name:''});
            if(profileRef.current) clearTimeout(profileRef.current);
            profileRef.current = setTimeout(() => {
                const res = configs.filter(item => item.ProfileName === value);
                if(res.length > 0) setAlert({...alert,profile_name:t('Đã bị trùng')});
            }, 500);
        } 
    }
    const onChange = (e) =>{
        const name = e.target.name;
        const value = e.target.value;
        setProfile({...profile,[name]:value});
    }
    const onRequireChange = (e) =>{
        const name = e.target.name;
        const value = e.target.value;
        setProfile({...profile,[name]:value});
        if(isEmpty(value)) setAlert({...alert,[name]:t('Không được để trống')});
        else setAlert({...alert,[name]:''});
    }

    const onBooleanChange = (e)=>{
        const name = e.target.name;
        const value = e.target.value;
        setProfile({...profile,[name]:value});
        setAlert({...alert,[name]:''});
    }

    const onEditClick = () =>{
        setIsEdit(!isEdit);
        if(isEdit) setLoading(false);
    }
    
    const onUpdateClick = async()=>{
        if(isEmpty(profile.profile_name)) return setAlert({...alert,profile_name:t('Không được để trống')});
        if(alert.profile_name!=='') return;
        if(profile.useInternalRelay==='') return setAlert({...alert,useInternalRelay:t('Không được để trống')});
        if(isEmpty(profile.deviceBlock)) return setAlert({...alert,deviceBlock:t('Không được để trống')});
        if(alert.deviceBlock!=='') return;
        if(isEmpty(profile.receptionistNumber)) return setAlert({...alert,receptionistNumber:t('Không được để trống')});
        setIsUpdate(true);    
    }

    const onConfirmUpdateClick = async() =>{
        try {
            setIsUpdating(true);
            var receptionist = receptionists.filter(item => item.Phone === profile.receptionistNumber);
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                await axiosClient.post('/profile/update',JSON.stringify({...profile,mqttPassword:newPassword===''?profile.mqttPassword:md5(profile.mqttPassword), receptionistName: receptionist[0].DisplayName}),{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
                })
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                await axiosClient.post('/profile/update',{...profile,mqttPassword:newPassword===''?profile.mqttPassword:md5(profile.mqttPassword), receptionistName: receptionist[0].DisplayName},{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
                })
            }
            setAlert({...alert,success:t('Đã cập nhật cấu hình thành công!')});
            setLoading(false);
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setIsFailed(true);
        }
        setIsUpdate(false);
        setIsUpdating(false);
    }

    const onCloseDialog = ()=>{
        setAlert({...alert,success:''});
    }

    useEffect(()=>{
        if(alert.success !== ''){
            setTimeout(() => {
                onCloseDialog();
            }, 5000);
        }
    },[alert.success])

    const ConfirmUpdateDialog = () =>{
        return(
          <Dialog 
            open={isUpdate}
            maxWidth="xl"
          >
            <DialogTitle className={classes.dialogTitleBackground}>
                <p className={classes.dialogTitle}>{t('Thông báo')}</p>
            </DialogTitle>
            <DialogContent style={{width:'448px',padding:'0',textAlign:'center',position:'relative'}}>
                <p className={classes.contentDialog}>{t('Bạn có chắc chắn muốn lưu thay đổi?')}</p>
                <CancelButton 
                  style={{marginLeft:'30px'}}
                  onClick={()=>setIsUpdate(false)}
                  disabled={isUpdating} 
                >
                  {t('Trở về')}
                </CancelButton>
                <CreateButton 
                  style={{margin:'10px 20px'}}
                  onClick={onConfirmUpdateClick} 
                  disabled={isUpdating}
                >
                  {t('Đồng ý')}
                </CreateButton>
                {isUpdating && <CircularProgress style={{position:'absolute',top:'10%',left:'45%',color:'#0b59c8'}}/>}
            </DialogContent>
          </Dialog>
        )
    }

    const FailedDialog = () =>{
        return(
          <Dialog 
            open={isFailed}
            maxWidth="xl"
          >
            <DialogTitle className={classes.errorColor}>
                <p className={classes.dialogTitle}>{t('Thông báo')}</p>
            </DialogTitle>
            <DialogContent style={{width:'448px',padding:'0',textAlign:'center'}}>
                <p className={classes.contentDialog}>{t('Cập nhật cấu hình không thành công, vui lòng thử lại!')}</p>
                <ErrorButton 
                  style={{margin:'10px 0'}}
                  onClick={()=>setIsFailed(false)} 
                >
                  {t('Đồng ý')}
                </ErrorButton>
            </DialogContent>
          </Dialog>
        )
    }

    return (
        <div>
            {FailedDialog()}
            {ConfirmUpdateDialog()}
            <div style={{paddingBottom:'8px',marginTop:'23px',position:'relative'}}>
                <IconButton style={{float:'left',marginLeft:'23px',padding:'2px',marginRight:'5px',zIndex:'100'}} onClick={onEditStatusChange}>
                    <ArrowBackIosOutlinedIcon className={classes.primaryColor} style={{width:'28px',height:'28px'}} />
                </IconButton>
                <p className={classes.secondaryTitle} style={{position:'relative'}}>
                    {t("Chỉnh sửa cấu hình thiết bị")}
                </p>
                <SuccessAlert message={alert.success} onCloseDialog={onCloseDialog} />
                {/* {alert.success !== '' && <Alert onClose={onCloseDialog}style={{position:'absolute',right:'3px',top:'-10px'}}>{alert.success}</Alert>} */}
            </div>     
            {!loading && <div style={{width:'100%',height:'250px',display:'flex',justifyContent:'center',alignItems:'center'}}><CircularProgress style={{color:'#0b59c8'}}/></div>}        
            {loading && 
            <div style={{padding:'0 16px'}} className={clsx(classes.focus)}>
                <Divider style={{clear:'both'}}/>
                <p style={{fontWeight:'bold',margin:'5px 2% 5px 16px'}}>{t('Cài đặt chung')}</p>
                <div style={{padding:'0 16px',paddingBottom:'0',width:'33%', float:'left'}}>
                    <div className={classes.inputBlock} style={{marginRight:'2%'}}>
                        <label className={classes.label}>{t('Tên cấu hình')} <span style={{color:'red'}}>(*)</span></label><br />
                        <TextField 
                            classes={{
                                root:classes.input
                            }}
                            name="profile_name"
                            size="small"
                            placeholder={t("Tên cấu hình")}
                            variant="outlined" 
                            onChange={onProfileNameChange}
                            value={profile.profile_name}
                            disabled={true}
                        />
                        <p style={{color:'red',fontSize:'13px',paddingTop:'4px'}}>{alert.profile_name}</p>
                    </div>
                </div>
                <div style={{padding:'0 16px',paddingBottom:'0',width:'33%', float:'left'}}>
                    <div className={classes.inputBlock} style={{marginRight:'2%'}}>
                        <label className={classes.label} >{t('Mô tả')} </label><br />
                        <TextField 
                            classes={{
                                root:classes.input
                            }}
                            name="profile_description"
                            size="small"
                            placeholder={t("Mô tả")}
                            variant="outlined" 
                            onChange={onChange}
                            value={profile.profile_description}
                            disabled={!isEdit}
                        />
                        <p style={{color:'red',fontSize:'13px',paddingTop:'4px'}}>{alert.profile_description}</p>
                    </div>
                </div>   
                <div style={{padding:'0 16px',paddingBottom:'0',width:'33%',float:'left'}}>
                    <div className={classes.inputBlock} style={{marginRight:'2%'}}>
                        <label className={classes.label} >Block <span style={{color:'red'}}>(*)</span></label>
                        <Autocomplete
                            style={{position:'relative',top:'-16px'}}
                            size="small" 
                            clearText={t("Xóa")}
                            noOptionsText={t("Không có dữ liệu")}
                            openText={t("Mở")}
                            options={blocks}
                            value={profile.deviceBlock}
                            disabled={!isEdit}
                            renderInput={(params) => (
                                <TextField {...params} style={{background:'#FFFFFF'}} placeholder="block" margin="normal" variant="outlined"/>
                            )}
                            onChange={(e,newValue)=>onBlockChange(e,newValue)}
                            
                        />
                        <p style={{color:'red',fontSize:'13px',position:'absolute',bottom:'0'}}>{alert.deviceBlock}</p>
                    </div>
                </div>
                
                
                <Divider style={{clear:'both'}}/>
                <div style={{display:'flex',margin:'5px 2% 5px 16px'}}>
                    <p style={{flex:1,fontWeight:'bold'}}>{t('Nhận diện khuôn mặt')}</p>
                    {!faceidOptional && <p style={{cursor:'pointer',color:'#009ADA'}} onClick={()=>setFaceidOptional(!faceidOptional)} >{t('Tùy chọn')}</p>}
                    {faceidOptional && <p style={{cursor:'pointer',color:'#009ADA'}} onClick={()=>setFaceidOptional(!faceidOptional)} >{t('Ẩn')}</p>}
                </div>
                {faceidOptional &&
                <div>
                    <div style={{padding:'0 16px',paddingBottom:'0',width:'33%', float:'left'}}>
                        <div className={classes.inputBlock} style={{marginRight:'2%'}}>
                            <label className={classes.label}>{t('Ngưỡng nhận diện khuôn mặt')} </label><br />
                            <TextField 
                                classes={{
                                    root:classes.input
                                }}
                                name="fdThreshold"
                                size="small"
                                type="number"
                                placeholder={t("Ngưỡng nhận diện khuôn mặt")+' (0~1)'}
                                variant="outlined" 
                                onChange={onChange}
                                value={profile.fdThreshold}
                                disabled={!isEdit}
                            />
                            <p style={{color:'red',fontSize:'13px',paddingTop:'4px'}}>{alert.fdThreshold}</p>
                        </div>
                    </div>
                    {/* <div style={{padding:'0 16px',paddingBottom:'0',width:'33%', float:'left'}}>
                        <div className={clsx(classes.inputBlock,classes.select)} style={{marginRight:'2%'}}>
                            <FormControl required variant="outlined" classes={{root:classes.input}}>
                                <label className={classes.label}>{t('Máy ảnh')} </label>
                                <Select
                                    value={profile.fdCamera}
                                    style={{height:'40px',background:'#FFFFFF'}}
                                    name="fdCamera"
                                    displayEmpty
                                    onChange={onChange}
                                    disabled={!isEdit}
                                >
                                    <MenuItem value='' disabled ><p className={classes.placeholder}> {`< ${t('Chọn')} >`} </p> </MenuItem>
                                    <MenuItem value='front' >{t('trước')}</MenuItem>
                                    <MenuItem value='rear' >{t('sau')}</MenuItem>
                                    
                                </Select>
                            </FormControl><br />
                        </div> 
                    </div> */}
                    <div style={{padding:'0 16px',paddingBottom:'0',width:'33%', float:'left'}}>
                        <div className={clsx(classes.inputBlock,classes.select)} style={{marginRight:'2%'}}>
                            <FormControl required variant="outlined" classes={{root:classes.input}}>
                                <label className={classes.label}>{t('Chế độ LED')} </label>
                                <Select
                                    value={profile.ledLightMode}
                                    style={{height:'40px',background:'#FFFFFF'}}
                                    name="ledLightMode"
                                    displayEmpty
                                    onChange={onChange}
                                    disabled={!isEdit}
                                >
                                    <MenuItem value={0} >{t('Tự động')}</MenuItem>
                                    <MenuItem value={1} >{t('Luôn luôn bật')}</MenuItem>
                                    <MenuItem value={-1} >{t('Luôn luôn tắt')}</MenuItem>
                                    
                                </Select>
                            </FormControl><br />
                        </div> 
                    </div>
                    <div style={{clear:'both'}}></div>
                    {/* <div style={{padding:'0 16px',paddingBottom:'0',width:'33%', float:'left'}}>
                        <div className={clsx(classes.inputBlock,classes.select)} style={{marginRight:'2%'}}>
                            <FormControl required variant="outlined" classes={{root:classes.input}}>
                                <label className={classes.label}>{t('Xoay hình ảnh')} </label>
                                <Select
                                    value={profile.fdRotateImageAngle}
                                    style={{height:'40px',background:'#FFFFFF'}}
                                    name="fdRotateImageAngle"
                                    displayEmpty
                                    onChange={onChange}
                                    disabled={!isEdit}
                                >
                                    <MenuItem value='' disabled ><p className={classes.placeholder}> {`< ${t('Chọn')} >`} </p> </MenuItem>
                                    <MenuItem value={0} >0</MenuItem>
                                    <MenuItem value={90} >90</MenuItem>
                                    <MenuItem value={180} >180</MenuItem>
                                    <MenuItem value={270} >270</MenuItem>                      
                                </Select>
                            </FormControl><br />
                        </div>
                    </div> */}
                    {/* <div style={{padding:'0 16px',paddingBottom:'0',width:'33%', float:'left'}}>
                        <div className={clsx(classes.inputBlock,classes.select)} style={{marginRight:'2%'}}>
                            <FormControl required variant="outlined" classes={{root:classes.input}}>
                                <label className={classes.label}>{t('Độ phân giải')} </label>
                                <Select
                                    value={profile.fdResolution}
                                    style={{height:'40px',background:'#FFFFFF'}}
                                    name="fdResolution"
                                    displayEmpty
                                    onChange={onChange}
                                    disabled={!isEdit}
                                >
                                    <MenuItem value='' disabled ><p className={classes.placeholder}> {`< ${t('Chọn')} >`} </p> </MenuItem>
                                    <MenuItem value='480x640' >480x640</MenuItem>
                                    <MenuItem value='720x1280' >720x1280</MenuItem>
                                    <MenuItem value='1024x1280' >1024x1280</MenuItem>
                                    <MenuItem value='1080x1920' >1080x1920</MenuItem>                      
                                </Select>
                            </FormControl><br />
                        </div>
                    </div> */}
                </div>}
                <Divider style={{clear:'both'}}/>
                <div style={{display:'flex',margin:'5px 2% 5px 16px'}}>
                    <p style={{flex:1,fontWeight:'bold'}}>{t('Cuộc gọi')}</p>
                    {!callingOptional && <p style={{cursor:'pointer',color:'#009ADA'}} onClick={()=>setCallingOptional(!callingOptional)} >{t('Tùy chọn')}</p>}
                    {callingOptional && <p style={{cursor:'pointer',color:'#009ADA'}} onClick={()=>setCallingOptional(!callingOptional)} >{t('Ẩn')}</p>}
                </div>
                <div style={{padding:'0 16px',paddingBottom:'0',width:'33%', float:'left'}}>
                    <div className={clsx(classes.inputBlock,classes.select)} style={{marginRight:'2%'}}>
                        <FormControl required variant="outlined" classes={{root:classes.input}}>
                            <label className={classes.label}>{t('Số điện thoại lễ tân')} <span style={{color:'red'}}>(*)</span></label>
                            <Select
                                value={profile.receptionistNumber}
                                style={{height:'40px',background:'#FFFFFF'}}
                                name="receptionistNumber"
                                displayEmpty
                                onChange={onRequireChange}
                                disabled={!isEdit}
                            >
                                <MenuItem value='' disabled ><p className={classes.placeholder}> {`< ${t('Chọn')} >`} </p> </MenuItem>
                                {getReceptionistPhoneOptions()}
                            </Select>
                        </FormControl><br />
                        <p style={{color:'red',fontSize:'13px',paddingTop:'4px'}}>{alert.receptionistNumber}</p>
                    </div> 
                </div>
                
                {callingOptional &&
                <div style={{clear:'both'}}>
                    <div style={{padding:'0 16px',paddingBottom:'0',width:'33%', float:'left'}}>
                        <div className={clsx(classes.inputBlock,classes.select)} style={{marginRight:'2%'}}>
                            <FormControl required variant="outlined" classes={{root:classes.input}}>
                                <label className={classes.label}>{t('Máy ảnh')} </label>
                                <Select
                                    value={profile.callCamera}
                                    style={{height:'40px',background:'#FFFFFF'}}
                                    name="callCamera"
                                    displayEmpty
                                    onChange={onChange}
                                    disabled={!isEdit}
                                >
                                    <MenuItem value='' disabled ><p className={classes.placeholder}> {`< ${t('Chọn')} >`} </p> </MenuItem>
                                    <MenuItem value='front' >{t('trước')}</MenuItem>
                                    <MenuItem value='rear' >{t('sau')}</MenuItem>           
                                </Select>
                            </FormControl><br />
                        </div> 
                    </div>
                    <div style={{padding:'0 16px',paddingBottom:'0',width:'33%', float:'left'}}>
                        <div className={clsx(classes.inputBlock,classes.select)} style={{marginRight:'2%'}}>
                            <FormControl required variant="outlined" classes={{root:classes.input}}>
                                <label className={classes.label}>{t('Độ phân giải')} </label>
                                <Select
                                    value={profile.callCameraResolution}
                                    style={{height:'40px',background:'#FFFFFF'}}
                                    name="callCameraResolution"
                                    displayEmpty
                                    onChange={onChange}
                                    disabled={!isEdit}
                                >
                                    <MenuItem value='' disabled ><p className={classes.placeholder}> {`< ${t('Chọn')} >`} </p> </MenuItem>
                                    <MenuItem value='480x640' >480x640</MenuItem>
                                    <MenuItem value='720x1280' >720x1280</MenuItem>
                                    <MenuItem value='1024x1280' >1024x1280</MenuItem>
                                    <MenuItem value='1080x1920' >1080x1920</MenuItem>                      
                                </Select>
                            </FormControl><br />
                        </div>
                    </div>
                    <div style={{padding:'0 16px',paddingBottom:'0',width:'33%', float:'left'}}>
                        <div className={clsx(classes.inputBlock,classes.select)} style={{marginRight:'2%'}}>
                            <FormControl required variant="outlined" classes={{root:classes.input}}>
                                <label className={classes.label}>{t('Loa')}</label>
                                <Select
                                    value={profile.useSpeaker}
                                    style={{height:'40px',background:'#FFFFFF',marginTop:'1px'}}
                                    name="useSpeaker"
                                    displayEmpty
                                    onChange={onBooleanChange}
                                    disabled={!isEdit}
                                >
                                    <MenuItem value='' disabled ><p className={classes.placeholder}> {`< ${t('Chọn')} >`} </p> </MenuItem>
                                    <MenuItem value={true} >{t('Có')}</MenuItem>
                                    <MenuItem value={false} >{t('Không')}</MenuItem>
                                </Select>
                            </FormControl><br />
                            <p style={{color:'red',fontSize:'13px',paddingTop:'4px'}}>{alert.useSpeaker}</p>
                        </div>
                    </div>
                </div>}               
                <Divider style={{clear:'both',marginBottom:'10px'}}/>
                {isEdit && <div className={classes.inputBlock} style={{width:'100%',height:'50px',textAlign:'right',clear:'both'}}>
                    <CancelButton 
                        variant="outlined" 
                        onClick={()=>{
                            setIsEdit(false);
                            setLoading(false);
                        }} 
                        style={{marginRight:'16px'}}
                    >
                        {t('Hủy')}
                    </CancelButton>
                    <CreateButton 
                        variant="contained" 
                        onClick={onUpdateClick} 
                        style={{marginRight:'16px'}}
                    >
                        {t('Lưu')}
                    </CreateButton>
                </div>}
                {!isEdit && <div className={classes.inputBlock} style={{width:'100%',height:'50px',textAlign:'right',clear:'both'}}>                   
                    <CreateButton 
                        variant="contained" 
                        onClick={onEditClick} 
                        style={{marginRight:'16px'}}
                    >
                        {t('Chỉnh sửa')}
                    </CreateButton>
                </div>}
            </div>}
        </div>
    )
}

export default EditConfig;