import React, { useState, useEffect, useRef } from 'react';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation, getDefaults } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider/Divider';
import Autocomplete from '@material-ui/lab/Autocomplete';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import vi from 'date-fns/locale/vi';
import { compare } from 'natural-orderby';
import moment from 'moment-timezone';
import { isEmpty } from '../../../validation/CheckForm';
import SuccessAlert from '../../alert/SuccessAlert';
import ErrorAlert from '../../alert/ErrorAlert';
import { apiPayment, apiPaymentUpdate } from '../../../api/payments';
import { apiGetapartmentdata } from '../../../api/apartment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import clsx from 'clsx';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { PaymentConfiguration } from '../../../config/enum';
import AddIcon from '@mui/icons-material/Add';
import { Confirm } from '../../dialog';
import InputAdornment from '@material-ui/core/InputAdornment';
const provinceData = require('../../../data/data.json');

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '20px',
        fontWeight: '700',
        lineHeight: '27px',
        color: '#000000',
        fontStyle: 'normal',
        float: 'left',
        marginRight: '24px',
        paddingTop: '5px'
    },
    alert: {
        position: 'absolute',
        left: '3px',
        top: '3px',
        width: '99%'
    },
    dialogTitleBackground: theme.primaryColor,
    dialogFailedTitleBackground: theme.errorColor,
    dialogTitle: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '25px',
        color: '#FFFFFF',
    },
    contentDialog: {
        fontSize: '16px',
        height: '68px',
        fontWeight: '600',
        borderBottom: '1px solid #EEEDF2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    contentBottomForm: {
        width: '98%',
        margin: 'auto',
        height: '100%'
    },
    root: theme.dataGridStyle,
    secondaryTitle: theme.secondary_title,
    primaryColor: theme.primaryTextColor,
    header: {
        width: '100%',
        height: '56px',
        background: '#FFFFFF',
        border: '1px solid #E0E0E0',
        position: 'relative',
        borderRadius: '6px 6px 0 0'
    },
    inputBlock: {
        height: '80px',
        width: '100%',
        position: 'relative'
    },
    inputBillBlock: {
        minHeight: '80px',
        width: '100%',
        position: 'relative'
    },
    inputDetailBlock: {
        height: '64px',
        width: '100%',
        position: 'relative',
        display: 'flex',
    },
    label: {
        margin: '0',
        fontSize: '14px',
        lineHeight: '140%',
        color: '#000000',
        display: 'inline-block',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '22px',
        color: '#616161',
        paddingBottom: '2px'
    },
    detailLabel: {
        margin: '0',
        fontSize: '14px',
        lineHeight: '140%',
        color: '#000000',
        display: 'inline-block',
        width: '50%',
        marginTop: '10px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '22px',
        color: '#616161',
        paddingBottom: '2px'
    },
    longTextStyle: {
        whiteSpace: 'nowrap',
        width: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    requestError: {
        width: '100%',
        height: '65vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20px',
        color: '#6D6D6D'
    },
    select: {
        '& .MuiOutlinedInput-input': {
            padding: '8px 12px'
        }
    },
    radio: {
        color: 'rgba(0, 0, 0, 0.23)!important',
        '&$checked': {
            color: theme.menuBackground + '!important'
        }
    },
    checked: {},
    radioGroupStyle: {
        marginLeft: '16px',
        paddingTop: '5px',
        position: 'relative',
        left: '-17px',
    },
    blockStyle: {
        background: '#FFFFFF',
        width: '32%',
        height: 'auto',
        padding: '24px',
        minHeight: '500px'
    },
    blockDetailStyle: {
        background: '#FFFFFF',
        width: '32%',
        height: 'auto',
        padding: '20px 15px',
        minHeight: '500px'
    },
    marginLeft: {
        marginLeft: '1%'
    },
    blockTitleStyle: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '20px',
        lineHeight: '28px',
        color: '#000000',
        paddingBottom: '24px',
    },
    blockDetailTitleStyle: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '20px',
        lineHeight: '28px',
        color: '#000000',
        paddingBottom: '24px',
        textAlign: 'center',
        borderBottom: '1px dashed #BEBEBE',
        position: 'relative'
    },
    borderTop: {
        borderTop: '1px solid #E0E0E0',
        paddingTop: '24px'
    },
    valueStyle: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '22px',
        color: '#000000',
        width: '100%'
    },
    valueDetailStyle: {
        width: '50%',
        textAlign: 'right',
        marginTop: '10px',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '18px',
        lineHeight: '25px',
        textAlign: 'right',
        color: '#000000'
    },
    status1: {
        color: '#2AB930',
        paddingBottom: '0px'
    },
    status2: {
        color: '#6D6D6D',
        paddingBottom: '0px'
    },
    status3: {
        color: 'rgb(228, 2, 29)',
        paddingBottom: '0px'
    },
    font: {
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '18px',
        lineHeight: '25px',
    },
    headerBlockStyle: {
        paddingBottom: '20px',
        paddingTop: '15px',
        width: '100%',
        margin: 'auto',
        display: 'flex'
    },
    backIconButtonStyle: {
        width: '24px',
        height: '24px',
        marginTop: '10px',
        marginRight: '10px'
    },
    headerTitle: {
        marginLeft: '0',
        marginTop: '7px',
        width: '50%'
    },
    circleLeft: {
        width: '28.77px',
        height: '36.8px',
        background: '#fafafa',
        borderRadius: '50%',
        position: 'absolute',
        bottom: '-19px',
        left: '-30px'
    },
    circleRight: {
        width: '28.77px',
        height: '36.8px',
        background: '#fafafa',
        borderRadius: '50%',
        position: 'absolute',
        bottom: '-19px',
        right: '-30px'
    },
    moreDetailLabel: {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '16px',
        textAlign: 'center',
        color: '#4F4F4F',
        position: 'relative',
        top: '2px'
    },
    moreDetailBlock: {
        cursor: 'pointer',
        marginBottom: '20px',
        width: '180px'
    },
    inputAmountStyle: {
        marginLeft: '5%',
        width: '45%',
        background: '#FFFFFF'
    }
}))

const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);
const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);

const PaymentDetail = (props) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { onBackClick, id } = props;
    const [alert, setAlert] = useState({
        expiredDate: '',
        block: '',
        floor: '',
        room: '',
        requestInfo: '',
        amount: '',
        success: '',
        error: '',
        request: ''
    });
    const [blocks, setBlocks] = useState([]);
    const [floors, setFloors] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [payment, setPayment] = useState({
        block: '',
        floor: '',
        room: ''
    });
    const [moreDetail, setMoreDetail] = useState([]);
    const [moreDetailLabel, setMoreDetailLabel] = useState({});
    const [moreDetailLabels, setMoreDetailLabels] = useState([]);
    const [oldStatus, setOldStatus] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [displayExpiredDate, setDisplayExpiredDate] = useState(null);
    const [detailInfo, setDetailInfo] = useState([]);
    const [apartmentData, setApartmentData] = useState([]);
    const alertRef = useRef();
    const inputRef = useRef();

    const formatter = Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND'
    })

    const handled_key = (str) => {
        if (str === null || str === undefined) return str;
        str = str.toLowerCase();
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/ /g, "_");
        return str;
    }

    const getDefaultValues = async () => {
        try {
            const res = await apiPayment({ id: id });
            const data = res.data.Items[0];
            var detailObject = {};
            var detail = [];
            if (data.Detail && data.Detail.length > 0) {
                data.Detail.forEach(item => {

                    const key = handled_key(item.name);
                    detail.push({ key: key, label: item.name });
                    detailObject[key] = item.amount;
                })
            }
            setDetailInfo(detail);
            var value = {
                ...detailObject,
                amount: data.Amount,
                block: data.BlockNumber,
                floor: data.Floor,
                room: data.Room,
                expiredDate: data.ExpiredDate,
                requestInfo: data.RequestInfo,
                requestDate: data.RequestDate,
                detail: data.Detail,
                id: data.ID,
                status: data.PaymentStatus,
                host: data.FullName,
                phone: data.Phone
            }
            if (moment().format('DD-MM-YYYY') > moment(value.expiredDate).format('DD-MM-YYYY') && value.status !== PaymentConfiguration.PAID) {
                setPayment({
                    ...value,
                    status: PaymentConfiguration.EXPIRED
                });
                setOldStatus(PaymentConfiguration.EXPIRED);
            }
            setPayment(value);
            setOldStatus(data.PaymentStatus);
            setMoreDetail([]);
            setMoreDetailLabels([]);
        } catch (error) {
            setAlert({ ...alert, request: t('Không lấy được dữ liệu') });
        }
        setLoading(true);
    }

    const getBlocks = async () => {
        try {
            const res = await apiGetapartmentdata();
            setApartmentData(res.data.Items);
            if (res.data.Items.length === 0) return setBlocks([]);
            var blockNames = [];
            res.data.Items.forEach(item => {
                blockNames.push(item.BlockID);
            });
            blockNames.sort();
            setBlocks([...new Set(blockNames)]);
        } catch (error) {
            // if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
        }
    }

    const getFloors = async (blockID) => {
        const data = apartmentData.filter(item => item.BlockID === blockID);
        if (data.length === 0) return setFloors([]);
        var floorsName = [];
        data.forEach(item => {
            floorsName.push(item.FloorID + '');
        });
        floorsName.sort((a, b) => compare({ order: 'asc' })(a, b));
        setFloors([...new Set(floorsName)]);
    }

    const getRooms = async (blockID, floorID) => {
        var data = apartmentData.filter(item => item.BlockID === blockID && item.FloorID === floorID);
        if (data.length === 0) return setRooms([]);
        var roomNames = [];
        data.forEach(item => {
            roomNames.push(item.RoomID + '');
        });
        roomNames.sort();
        setRooms([...new Set(roomNames)]);
    }

    useEffect(() => {
        if (!loading) {
            getDefaultValues();
            getBlocks();
        }
    }, [loading])

    useEffect(() => {
        if (payment.block !== '') getFloors(payment.block);
    }, [payment.block])

    useEffect(() => {
        if (payment.floor === '') return;
        getRooms(payment.block, payment.floor);
    }, [payment.floor])

    const onBlockChange = (e, newValue) => {
        setFloors([]);
        setRooms([]);
        if (newValue === null) {
            setPayment({ ...payment, block: '', floor: '', room: '' });
            setAlert({ ...alert, block: t('Không được để trống') });
            return;
        }
        setAlert({ ...alert, block: "" });
        setPayment({ ...payment, block: newValue, floor: '', room: '' });
    }

    const onFloorChange = (e, newValue) => {
        setRooms([]);
        if (newValue === null) {
            setPayment({ ...payment, floor: '', room: '' });
            setAlert({ ...alert, floor: t('Không được để trống') });
            return;
        }
        setAlert({ ...alert, floor: "" });
        setPayment({ ...payment, floor: newValue, room: '' });
    }

    const onRoomChange = (e, newValue) => {
        if (newValue === null) {
            setPayment({ ...payment, room: '' });
            setAlert({ ...alert, room: t('Không được để trống') });
            return;
        }
        setAlert({ ...alert, room: "" });
        setPayment({ ...payment, room: newValue });
    }

    const onStatusChange = (e) => {
        setPayment({ ...payment, status: e.target.value });
    }

    const onRequestInfoChange = (e) => {
        setPayment({ ...payment, requestInfo: e.target.value });
        if (e.target.value.trim() === '') setAlert({ ...alert, requestInfo: t('Không được để trống') });
        else setAlert({ ...alert, requestInfo: '' });
    }

    const onAmountChange = (e) => {
        var amount = parseInt(e.target.value);
        setPayment({ ...payment, amount: amount });
        if (amount < 0 || isNaN(amount)) setAlert({ ...alert, amount: t('Số tiền không hợp lệ') });
        else setAlert({ ...alert, amount: '' });
    }

    const onDetailInfoChange = (e) => {
        var name = e.target.name;
        var value = e.target.value;
        setPayment({ ...payment, [name]: value });
        if (parseInt(value) < 0 || isNaN(parseInt(value))) {
            setAlert({ ...alert, [name]: t('Số tiền không hợp lệ') });
        } else {
            setAlert({ ...alert, [name]: '' });
        }
    }

    const onMoreDetailChange = (e) => {
        var name = e.target.name;
        var value = e.target.value;
        var key = handled_key(value.trim());
        setMoreDetailLabel({ ...moreDetailLabel, [name]: key });
        if (isEmpty(value)) {
            setAlert({ ...alert, [name]: t('Không được để trống') });
        } else {
            setAlert({ ...alert, [name]: '' });
        }
        if (inputRef.current) clearTimeout(inputRef.current);
        inputRef.current = setTimeout(() => {
            var arr = [...moreDetailLabels];
            arr.push({
                key: key,
                label: value
            })
            setMoreDetailLabels(arr);
        }, 1000);
    }

    const moreDetailValueChange = (e) => {
        var name = e.target.name;
        var value = e.target.value;
        setPayment({ ...payment, [name]: value });
        if (parseInt(value) < 0 || isNaN(parseInt(value))) {
            setAlert({ ...alert, [name]: t('Số tiền không hợp lệ') });
        } else {
            setAlert({ ...alert, [name]: '' });
        }
    }

    const onDateChange = (e) => {
        const today = new Date();
        const date = new Date(e);
        setDisplayExpiredDate(e);
        setPayment({ ...payment, expiredDate: date.getTime() });
        if (isNaN(date.getTime()) || e === null) {
            setAlert({ ...alert, expiredDate: t("Thông tin chưa hợp lệ") });
        } else if (moment(today.getTime()).format('YYYY-MM-DD') > moment(date.getTime()).format('YYYY-MM-DD')) {
            setAlert({ ...alert, expiredDate: t("Phải sau ngày hiện tại") });
        } else {
            setAlert({ ...alert, expiredDate: '' });
        }
    }

    const onSaveClick = () => {
        if (isEmpty(payment.block)) return setAlert({ ...alert, block: t('Không được để trống') });
        if (alert.block !== '') return;
        if (isEmpty(payment.floor)) return setAlert({ ...alert, floor: t('Không được để trống') });
        if (alert.floor !== '') return;
        if (isEmpty(payment.room)) return setAlert({ ...alert, room: t('Không được để trống') });
        if (alert.room !== '') return;
        if (isEmpty(payment.requestInfo)) return setAlert({ ...alert, requestInfo: t('Không được để trống') });
        if (alert.requestInfo !== '' || alert.expiredDate !== '') return;
        if (payment.amount === '') return setAlert({ ...alert, amount: t('Không được để trống') });
        if (alert.amount !== '') return;
        var errors = Object.values(alert);
        var res = errors.filter(item => !isEmpty(item));
        if (res.length > 0) return;
        setIsSave(true);
    }

    const handledData = (data) => {
        var detail = [];
        var keys = Object.keys(data);
        var amount = 0;
        if (detailInfo.length > 0) {
            keys.forEach(key => {
                const res = detailInfo.filter(info => info.key === key);
                if (res.length > 0 && data[key] > 0) {
                    detail.push({ name: res[0].label, amount: parseInt(data[key]) });
                    amount += parseInt(data[key]);
                    delete data[key];
                }
            })
        }
        if (moreDetailLabels.length > 0) {
            keys.forEach(key => {
                const res = moreDetailLabels.filter(info => info.key === key);
                if (res.length > 0 && data[key] > 0) {
                    detail.push({ name: res[0].label, amount: parseInt(data[key]) });
                    amount += parseInt(data[key]);
                    delete data[key];
                }
            })
        }
        if (detailInfo.length === 0 || moreDetailLabels.length === 0) {
            amount = payment.amount;
        }
        return { ...data, detail: detail, amount: amount }
    }

    const onConfirmSaveClick = async () => {
        try {
            setIsSaving(true);
            const data = handledData(payment);
            if (data.status === oldStatus) {
                delete data.status;
            }
            await apiPaymentUpdate(data);
            setLoading(false);
            setAlert({ ...alert, success: t('Lưu thành công!'), error: '' });
            setIsEdit(false);
        } catch (error) {
            setAlert({ ...alert, error: t('Lưu không thành công, vui lòng thử lại sau!'), success: '' });
        }
        setIsSave(false);
        setIsSaving(false);
    }

    const onCancelClick = () => {
        setIsEdit(false);
        setLoading(false);
        setAlert({
            expiredDate: '',
            block: '',
            floor: '',
            room: '',
            requestInfo: '',
            amount: '',
            success: '',
            error: '',
            request: ''
        })
    }

    const onCloseDialog = () => {
        setAlert({ ...alert, success: '', error: '' });
    }

    useEffect(() => {
        if (alert.success !== '' || alert.error !== '') {
            if (alertRef.current) clearTimeout(alertRef.current);
            alertRef.current = setTimeout(() => {
                onCloseDialog();
            }, 5000);
        }
    }, [alert])

    const addMoreDetailClick = () => {
        if (!isEdit) return;
        var arr = []
        for (var i = 0; i <= moreDetail.length; i++) {
            arr.push(i);
        }
        setMoreDetail(arr);
    }

    const showPaymentStatus = () => {
        switch (payment.status) {
            case PaymentConfiguration.PAID:
                return <div className={clsx(classes.status1, classes.font)}><FiberManualRecordIcon style={{ position: 'relative', top: '0px' }} />{t('Đã thanh toán')}</div>
            case PaymentConfiguration.UNPAID:
                return <div className={clsx(classes.status2, classes.font)}><FiberManualRecordIcon style={{ position: 'relative', top: '0px' }} />{t('Chưa thanh toán')}</div>
            case PaymentConfiguration.EXPIRED:
                return <div className={clsx(classes.status3, classes.font)}><FiberManualRecordIcon style={{ position: 'relative', top: '0px' }} />{t('payment.Đã quá hạn')}</div>
            default: return '';
        }
    }

    const showDetailInput = () => {
        return detailInfo.map((item, key) => {
            return (
                <div key={key} className={classes.inputDetailBlock}>
                    <label className={classes.detailLabel}>
                        <p className={classes.longTextStyle}>{t(item.label)}</p>
                    </label>
                    {!isEdit && <p className={classes.valueDetailStyle}>{formatter.format(payment[item.key])}</p>}
                    {isEdit && <TextField
                        className={classes.inputAmountStyle}
                        size="small"
                        type="number"
                        name={item.key}
                        placeholder={t(item.label)}
                        variant="outlined"
                        value={payment[item.key]}
                        onChange={onDetailInfoChange}
                        helperText={alert[item.key]}
                        error={!isEmpty(alert[item.key])}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    đ
                                </InputAdornment>
                            ),
                        }}
                    />}
                </div>
            )
        })
    }

    const showMoreDetail = () => {
        return moreDetail.map((item, key) => {
            return (
                <div key={key} className={classes.inputDetailBlock}>
                    <TextField
                        style={{ width: '50%', background: '#FFFFFF' }}
                        size="small"
                        name={'detail' + key}
                        placeholder={t('Tên dịch vụ')}
                        variant="outlined"
                        onChange={onMoreDetailChange}
                        helperText={alert['detail' + key]}
                        error={!isEmpty(alert['detail' + key])}
                    />
                    <TextField
                        className={classes.inputAmountStyle}
                        size="small"
                        type="number"
                        name={moreDetailLabel['detail' + key]}
                        placeholder={t('Số tiền')}
                        variant="outlined"
                        disabled={!moreDetailLabel['detail' + key]}
                        onChange={moreDetailValueChange}
                        helperText={alert[moreDetailLabel['detail' + key]]}
                        error={!isEmpty(alert[moreDetailLabel['detail' + key]])}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    đ
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
            )
        })
    }

    return (
        <div style={{ width: '96%', margin: 'auto', height: '100%', minWidth: '1280px' }}>
            <Confirm
                isOpen={isSave}
                handleClose={() => setIsSave(false)}
                isLoading={isSaving}
                mess={t('Bạn có chắc chắn muốn lưu thay đổi?')}
                onConfirm={onConfirmSaveClick}
            />
            <SuccessAlert
                message={alert.success}
                onCloseDialog={onCloseDialog}
            />
            <ErrorAlert
                message={alert.error}
                onCloseDialog={onCloseDialog}
            />
            <div className={classes.headerBlockStyle}>
                <IconButton
                    size="small"
                    onClick={onBackClick}
                    className={classes.backIconButtonStyle}
                >
                    <ArrowBackIosOutlinedIcon className={classes.primaryColor} style={{ width: '24px', height: '24px' }} />
                </IconButton>
                {!isEdit && <p className={clsx(classes.secondaryTitle, classes.headerTitle)}>{t("Chi tiết thông tin thanh toán")}</p>}
                {isEdit && <p className={clsx(classes.secondaryTitle, classes.headerTitle)}>{t("Chỉnh sửa thông tin thanh toán")}</p>}
                <div style={{ flex: 1 }}></div>
                <div style={{ marginRight: '2%' }}>
                    {!isEdit && payment.status !== PaymentConfiguration.PAID &&
                        <CreateButton
                            variant="contained"
                            onClick={() => setIsEdit(true)}
                        >
                            {t('Chỉnh sửa')}
                        </CreateButton>
                    }
                    {isEdit && <CancelButton
                        variant="outlined"
                        onClick={onCancelClick}
                    >
                        {t('Hủy')}
                    </CancelButton>}
                    {isEdit && <CreateButton
                        variant="contained"
                        onClick={onSaveClick}
                    >
                        {t('Lưu')}
                    </CreateButton>}

                </div>
            </div>
            {!loading && <div style={{ width: '100%', height: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress style={{ color: '#0b59c8' }} /></div>}
            {loading && alert.request !== '' && <div className={classes.requestError}>{alert.request}</div>}
            {loading && alert.request === '' &&
                <div style={{ height: 'auto', display: 'flex' }}>
                    <div className={classes.blockStyle}>
                        <p className={classes.blockTitleStyle}>{t('Thông tin căn hộ')}</p>
                        <div className={classes.inputBlock}>
                            <label className={classes.label}>
                                {t('Block')}
                                {isEdit && <span style={{ color: 'red' }}> (*)</span>}
                            </label>
                            {!isEdit && <p className={classes.valueStyle}>{payment.block}</p>}
                            {isEdit && <Autocomplete
                                value={payment.block}
                                style={{ height: '40px', width: '100%', background: '#FFFFFF' }}
                                size="small"
                                clearText={t("Xóa")}
                                noOptionsText={t("Không có dữ liệu")}
                                openText={t("Mở")}
                                getOptionSelected={(option, value) => {
                                    return true;
                                }}
                                options={blocks}
                                disabled={!isEdit}
                                renderInput={
                                    (params) => <TextField {...params} placeholder={t("Block")} variant="outlined" helperText={alert.block} error={alert.block !== ''} />
                                }
                                onChange={(e, newValue) => onBlockChange(e, newValue)}
                            />}
                        </div>
                        <div className={classes.inputBlock}>
                            <label className={classes.label}>
                                {t('Tầng')}
                                {isEdit && <span style={{ color: 'red' }}> (*)</span>}
                            </label>
                            {!isEdit && <p className={classes.valueStyle}>{payment.floor}</p>}
                            {isEdit && <Autocomplete
                                value={payment.floor}
                                classes={{
                                    root: classes.input
                                }}
                                style={{ height: '40px', width: '100%', background: '#FFFFFF' }}
                                size="small"
                                clearText={t("Xóa")}
                                noOptionsText={t("Không có dữ liệu")}
                                openText={t("Mở")}
                                getOptionSelected={(option, value) => {
                                    return true;
                                }}
                                options={floors}
                                disabled={!isEdit}
                                renderInput={(params) => <TextField {...params} placeholder={t("Tầng")} variant="outlined" helperText={alert.floor} error={alert.floor !== ''} />}
                                onChange={(e, newValue) => onFloorChange(e, newValue)}
                            />}
                        </div>
                        <div className={classes.inputBlock}>
                            <label className={classes.label}>
                                {t('Phòng')}
                                {isEdit && <span style={{ color: 'red' }}> (*)</span>}
                            </label>
                            {!isEdit && <p className={classes.valueStyle}>{payment.room}</p>}
                            {isEdit && <Autocomplete
                                value={payment.room}
                                classes={{
                                    root: classes.input
                                }}
                                style={{ height: '40px', width: '100%', background: '#FFFFFF' }}
                                size="small"
                                clearText={t("Xóa")}
                                noOptionsText={t("Không có dữ liệu")}
                                openText={t("Mở")}
                                getOptionSelected={(option, value) => {
                                    return true;
                                }}
                                options={rooms}
                                disabled={!isEdit}
                                renderInput={(params) => <TextField {...params} placeholder={t("Phòng")} variant="outlined" helperText={alert.room} error={alert.room !== ''} />}
                                onChange={(e, newValue) => onRoomChange(e, newValue)}
                            />}
                        </div>
                        <div className={classes.inputBlock}>
                            <label className={classes.label}>{t('Chủ hộ')} </label>
                            <p className={classes.valueStyle}>{payment.host ? payment.host : t('Không có thông tin')}</p>
                        </div>
                        <div className={classes.inputBlock}>
                            <label className={classes.label}>{t('Số điện thoại')} </label>
                            <p className={classes.valueStyle}>{payment.phone ? payment.phone : t('Không có thông tin')}</p>
                        </div>
                    </div>
                    <div className={clsx(classes.blockStyle, classes.marginLeft)}>
                        <p className={classes.blockTitleStyle}>{t('Thông tin thanh toán')}</p>
                        <div className={classes.inputBillBlock}>
                            <label className={classes.label}>
                                {t('Tên hóa đơn')}
                                {isEdit && <span style={{ color: 'red' }}> (*)</span>}
                            </label>
                            {!isEdit && <p className={classes.valueStyle}>{payment.requestInfo}</p>}
                            {isEdit && <TextField
                                style={{ width: '100%', background: '#FFFFFF' }}
                                name="RequestInfo"
                                size="small"
                                placeholder={t("Tên hóa đơn")}
                                variant="outlined"
                                value={payment.requestInfo}
                                disabled={!isEdit}
                                onChange={onRequestInfoChange}
                                helperText={alert.requestInfo}
                                error={alert.requestInfo !== ''}
                            />}
                        </div>
                        <div className={classes.inputBlock}>
                            <label className={classes.label}>
                                {t('Ngày đến hạn')}
                                {isEdit && <span style={{ color: 'red' }}> (*)</span>}
                            </label>
                            {!isEdit && <p className={classes.valueStyle}>{payment.expiredDate ? moment(payment.expiredDate).format('DD-MM-YYYY') : '-'}</p>}
                            {isEdit && <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === 'vi' ? vi : ''}>
                                <KeyboardDatePicker
                                    inputVariant="outlined"
                                    style={{ position: 'relative', top: '-16px', width: '100%', background: '#FFFFFF' }}
                                    size="small"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    placeholder={t("Ngày đến hạn")}
                                    value={payment.expiredDate}
                                    onChange={onDateChange}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date"
                                    }}
                                    error={alert.expiredDate !== ''}
                                    helperText={alert.expiredDate}
                                    cancelLabel={t("Đóng")}
                                    okLabel={t("Đồng ý")}
                                    views={["date", "month", "year"]}
                                    disabled={!isEdit}
                                />
                            </MuiPickersUtilsProvider>}
                        </div>
                        <div className={classes.inputBlock}>
                            <label className={classes.label} >
                                {t("Trạng thái")}
                                {isEdit && <span style={{ color: 'red' }}> (*)</span>}
                            </label>
                            {!isEdit && showPaymentStatus()}
                            {isEdit && <RadioGroup
                                value={payment.status}
                                onChange={onStatusChange}
                                className={classes.radioGroupStyle}
                            >
                                <FormControlLabel
                                    value='unpaid'
                                    control={
                                        <Radio
                                            classes={{ root: classes.radio, checked: classes.checked }}
                                            size="small"
                                        />}
                                    label={t('Chưa thanh toán')}
                                />
                                <FormControlLabel
                                    value='paid'
                                    control={
                                        <Radio
                                            classes={{ root: classes.radio, checked: classes.checked }}
                                            size="small"
                                        />}
                                    label={t('Đã thanh toán')}
                                />
                                <FormControlLabel
                                    value='expired'
                                    control={
                                        <Radio
                                            classes={{ root: classes.radio, checked: classes.checked }}
                                            size="small"
                                        />}
                                    label={t('payment.Đã quá hạn')}
                                />
                            </RadioGroup>}
                        </div>
                    </div>
                    <div className={clsx(classes.blockDetailStyle, classes.marginLeft)}>
                        <div className={classes.blockDetailTitleStyle}>
                            {t('Chi tiết hóa đơn')}
                            <p className={classes.circleLeft}></p>
                            <p className={classes.circleRight}></p>
                        </div>
                        {payment.detail.length > 0 &&
                            <div style={{ marginTop: '24px' }}>
                                {showDetailInput()}
                                {showMoreDetail()}
                                <div className={classes.moreDetailBlock} onClick={addMoreDetailClick}>
                                    <AddIcon fontSize="small" />
                                    <span className={classes.moreDetailLabel}>{t('Thêm chi tiết hóa đơn')}</span>
                                </div>
                            </div>
                        }
                        {payment.detail.length === 0 &&
                            <div style={{ marginTop: '24px' }}>
                                {showMoreDetail()}
                                <div className={classes.moreDetailBlock} onClick={addMoreDetailClick}>
                                    <AddIcon fontSize="small" />
                                    <span className={classes.moreDetailLabel}>{t('Thêm chi tiết hóa đơn')}</span>
                                </div>
                            </div>
                        }
                        <div className={clsx(classes.inputDetailBlock, classes.borderTop)}>
                            <label className={classes.detailLabel}>{t('Tổng tiền')} </label>
                            {(!isEdit || payment.detail.length > 0) && <p className={classes.valueDetailStyle}>{formatter.format(payment.amount)}</p>}
                            {isEdit && payment.detail.length === 0 && <TextField
                                style={{ width: '50%', background: '#FFFFFF' }}
                                size="small"
                                type="number"
                                name="amount"
                                placeholder={t('Tổng tiền')}
                                variant="outlined"
                                value={payment.amount}
                                onChange={onAmountChange}
                                helperText={alert.amount}
                                error={alert.amount !== ''}
                            />}
                        </div>
                    </div>
                </div>}
            <div style={{ height: '30px' }}></div>
        </div>
    )
}

export default PaymentDetail;