import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
    },
    booking: {
        padding: "1rem",
        paddingBottom: "unset",
        width: "100%",
        height: "calc(100vh - 82px)",
    },
    searchIcon: {
        cursor: "pointer"
    },
    title: {
        display: "flex"
    },
    helperText: {
        color: "black",
        fontSize: "14px"
    },
    handle: {
        display: "flex",
        alignItems: "flex-start",
        marginTop: "16px"
    },
    getRoomLoading: {
        marginTop: '1rem',
    },
    getRoomNodata: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100% - 165px)",
        fontSize: "1.5rem"
    },
    table: {
        position: "relative",
        display: 'flex',
        paddingBottom: "1rem",
        width: 'auto',
        height: "calc(100% - 162px)",
        overflow: "auto"
    },
}));

export default useStyles;