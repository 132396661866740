import React, { useEffect, useState, useRef } from 'react';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import clsx from 'clsx';
import UiSetting from './UiSetting';
import { NavLink, Link, Route, useLocation, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import InfoIcon from '@mui/icons-material/Info';
import CircularProgress from '@material-ui/core/CircularProgress';
import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined';
import FitnessCenterOutlinedIcon from '@mui/icons-material/FitnessCenterOutlined';
import LocalBarOutlinedIcon from '@mui/icons-material/LocalBarOutlined';
import OutdoorGrillOutlinedIcon from '@mui/icons-material/OutdoorGrillOutlined';

import LargeTooltip from '../largeTooltip';

import RoomsTable from './room/RoomsTable';
import ReceptionistSetting from './receptionist/ReceptionistSetting';
import ApiKey from './ApiKey/ApiKey';
import BackupTable from './backup_restore/BackupTable';
import AddAd from './tbuilding/AddAd';
import Advertisements from './tbuilding/Advertisements';
import Configuration from './registration/Configuration';
import CallConfiguration from './call_configuration/CallConfiguration';
import MomoConfig from './paymentConfig/MomoConfig';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import VNPayConfig from './paymentConfig/VNPayConfig';
import ZaloPayConfig from './paymentConfig/ZaloPayConfig';
import PaymentConfig from './paymentConfig/PaymentConfig';
import ServiceBooking from './serviceBooking/index';
import AddEditService from './serviceBooking/addEditService'

import SuccessAlert from '../alert/SuccessAlert';
import { getBookingService, deleteBookingService } from '../../api/booking';
import { ConfirmWarning } from '../dialog/index';
import ErrorAlert from '../alert/ErrorAlert';
import { IS_CHANGED_SERVICES } from '../../redux/reducers/serviceBooking';

const useStyles = makeStyles((theme) => ({
    topBlock: {
        marginTop: '22px',
        marginBottom: '20px',
        height: '40px'
    },
    buildingSetting: {
        borderRadius: '0 6px 6px 0',
        background: '#FFFFFF',
        position: 'relative',
        width: '100%'
    },
    primaryTitle: theme.primary_title,
    secondaryTitle: theme.secondary_title,
    menu: {
        width: '18%',
        height: '100%',
        marginRight: '1%',
        background: '#FFFFFF',
        borderRadius: '6px',
        paddingBottom: '2rem',
        overflowY: "auto",
        overflowX: "hidden",
        "&::-webkit-scrollbar": {
            width: "4px",
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#EBEBEB",
            borderRadius: "8px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: "#b3000"
        },
        scrollbarWidth: "thin"
    },
    content: {
        width: '81%',
        borderRadius: '6px',
        background: '#FFFFFF',
        height: '100%'
    },
    link: {
        display: 'block',
        height: '40px',
        fontSize: '14px',
        padding: '10px',
        color: '#000000',
        position: 'relative'
    },
    active: {
        background: '#50c9ff4a'
    },
    activeBackground: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        background: '#50c9ff4a'
    },
    triangle: {
        height: '30px',
        width: '30px',
        position: 'absolute',
        top: '5px',
        right: '-5px',
        background: '#FFFFFF',
        transform: 'rotate(45deg)',
        zIndex: '0'
    },
    paymentLogoStyle: {
        position: 'relative',
        top: '-2px',
        left: '-5px',
        width: "20px",
        height: "20px"
    },
    extendLink: {
        cursor: "pointer"
    },
    wrapLink: {
        position: "relative"
    },
    subLink: {
        paddingLeft: "1.5rem"
    },
    isActive: {
        height: '40px', width: '100%', background: '#50c9ff4a', fontWeight: 'unset !important'
    },
    wrapSubLink: {
        width: "78%",
        height: "20.78px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
    infoIcon: {
        marginLeft: '3px'
    },
    wrapIconAction: {
        position: "absolute",
        top: "9px",
        right: "9px",
        zIndex: "2",
        cursor: "pointer"
    },
    wrapCircularProgress: {
        display: "flex",
        justifyContent: "center"
    }
}))

const Setting = props => {
    const [state, setState] = useState({
        listService: [],
        infoService: {},
        serviceIdWillDelete: "",
        responseStatus: {
            succ: "",
            err: ""
        },
        isAddEdit: false,
        isConfirm: false,
        isServiceLoading: false,
        isConfirmLoading: false,
    })
    const [active, setActive] = useState(0);
    const [isService, setIsService] = useState(false);

    const dispatch = useDispatch();
    const classes = useStyles();
    const location = useLocation();
    const [paymentActive, setPaymentActive] = useState(false);
    const [isShowSettings, setIsShowSettings] = useState(false);
    const { url } = useRouteMatch();
    const { t } = useTranslation();
    const heightRef = useRef();

    const getBookingServiceApi = async () => {
        setState(prev => ({
            ...prev,
            isServiceLoading: true
        }))

        try {
            const { data } = await getBookingService();
            const { Items: listService } = data;

            setState(prev => ({
                ...prev,
                isServiceLoading: false,
                listService
            }))
        } catch (error) {
            console.log(error);
            setState(prev => ({
                ...prev,
                isServiceLoading: false,
                responseStatus: {
                    err: t("Tải danh sách dịch vụ thất bại!"),
                    succ: ""
                }
            }))
        }
    }

    const handleChangeIsAddEdit = (responseStatus) => {
        setState(prev => ({
            ...prev,
            infoService: {},
            isAddEdit: !prev.isAddEdit,
            responseStatus: responseStatus ? responseStatus : {
                succ: "",
                err: ""
            }
        }));

        if (responseStatus) {
            getBookingServiceApi();
        }
    }
    const onCloseDialog = () => {
        setState(prev => ({
            ...prev,
            responseStatus: {
                succ: "",
                err: ""
            }
        }))
    }

    const deleteBookingServiceApi = async (id) => {
        setState(prev => ({
            ...prev,
            isConfirmLoading: true
        }));

        try {
            await deleteBookingService({ id });
            setState(prev => ({
                ...prev,
                responseStatus: {
                    succ: t("Xóa thành công"),
                    err: ''
                }
            }));
        } catch (error) {
            console.log(error);
            setState(prev => ({
                ...prev,
                responseStatus: {
                    succ: "",
                    err: t("Xóa thất bại, vui lòng thử lại!")
                }
            }));
        }

        dispatch({ type: IS_CHANGED_SERVICES });
        setState(prev => ({
            ...prev,
            serviceIdWillDelete: '',
            isConfirmLoading: false,
            isConfirm: false
        }));

        getBookingServiceApi();
    }

    const iconOptionLabel = {
        RestaurantOutlinedIcon: <RestaurantOutlinedIcon
            style={{
                color: "#04068c",
                fontSize: "14px",
                marginRight: "4px"
            }}
        />
        ,
        FitnessCenterOutlinedIcon: <FitnessCenterOutlinedIcon
            style={{
                color: "#04068c",
                fontSize: "14px",
                marginRight: "4px"
            }}
        />
        ,
        LocalBarOutlinedIcon: <LocalBarOutlinedIcon
            style={{
                color: "#04068c",
                fontSize: "14px",
                marginRight: "4px"
            }}
        />
        ,
        OutdoorGrillOutlinedIcon: <OutdoorGrillOutlinedIcon
            style={{
                color: "#04068c",
                fontSize: "14px",
                marginRight: "4px"
            }}
        />
    };

    useEffect(() => {
        if(process.env.REACT_APP_IS_BOOKING === "hide") return;
        
        getBookingServiceApi();
    }, [])

    useEffect(() => {
        if (location.pathname === '/admin/setting/theme') {
            heightRef.current.style['height'] = '100%';
            setActive(1);
        }
        if (location.pathname === '/admin/setting/rooms') {
            heightRef.current.style['height'] = '100%';
            setActive(2);
        }
        if (location.pathname === '/admin/setting/call-configuration') {
            heightRef.current.style['height'] = '100%';
            setActive(3);
        }
        if (location.pathname === '/admin/setting/receptionist') {
            heightRef.current.style['height'] = '120%';
            setActive(4);
        }
        if (location.pathname === '/admin/setting/device') {
            heightRef.current.style['height'] = '100%';
            setActive(5);
        }
        if (location.pathname === '/admin/setting/apikey') {
            heightRef.current.style['height'] = '100%';
            setActive(6);
        }
        if (location.pathname === '/admin/setting/backup-restore') {
            heightRef.current.style['height'] = '120%';
            setActive(7);
        }
        if (location.pathname === '/admin/setting/advertisement' || location.pathname === '/admin/setting/add-advertisement') {
            heightRef.current.style['height'] = '100%';
            setActive(8);
        }
        if (location.pathname === '/admin/setting/registration-configuration') {
            heightRef.current.style['height'] = '100%';
            setActive(9);
        }
        if (location.pathname.match('/admin/setting/payment/*')) {
            heightRef.current.style['height'] = '100%';
            setPaymentActive(true);
            setActive(-1);
        } else {
            setPaymentActive(false);
            setIsShowSettings(false);
        }
        if (location.pathname === '/admin/setting/payment/MOMO') {
            heightRef.current.style['height'] = '100%';
            setActive(10);
        }
        if (location.pathname === '/admin/setting/payment/VNPAY') {
            heightRef.current.style['height'] = '100%';
            setActive(11);
        }
        if (location.pathname === '/admin/setting/payment/ZaloPay') {
            heightRef.current.style['height'] = '100%';
            setActive(12);
        }
    }, [location.pathname])

    const onSettingClick = () => {
        setIsShowSettings(true);
    }

    return (
        <>
            <div style={{ width: '98%', minWidth: '1250px', margin: 'auto', height: '100%' }} >
                <div ref={heightRef} style={{ width: '100%', height: '100%', display: 'flex' }} >
                    <div className={classes.menu}>
                        <div className={classes.topBlock} style={{ marginBottom: '0' }}>
                            <p className={classes.secondaryTitle} style={{ marginLeft: '10px' }}>{t('Cài đặt')}</p>
                        </div>
                        <Link to='/admin/setting/theme' className={clsx(classes.link)}>
                            {t('Giao diện')}
                            {active === 1 && <div className={classes.triangle}></div>}
                            {active === 1 && <div className={classes.activeBackground}></div>}
                        </Link>
                        <Link to='/admin/setting/advertisement' className={clsx(classes.link)}>
                            {t('Danh sách quảng cáo')}
                            {active === 8 && <div className={classes.triangle}></div>}
                            {active === 8 && <div className={classes.activeBackground}></div>}
                        </Link>
                        <Link to='/admin/setting/rooms' className={clsx(classes.link)}>
                            {t('Danh sách phòng')}
                            {active === 2 && <div className={classes.triangle}></div>}
                            {active === 2 && <div className={classes.activeBackground}></div>}
                        </Link>
                        <Link to='/admin/setting/registration-configuration' className={clsx(classes.link)}>
                            {t('Cấu hình cư dân')}
                            {active === 9 && <div className={classes.triangle}></div>}
                            {active === 9 && <div className={classes.activeBackground}></div>}
                        </Link>
                        <Link to='/admin/setting/receptionist' className={clsx(classes.link)}>
                            {t('Lễ tân')}
                            {active === 4 && <div className={classes.triangle}></div>}
                            {active === 4 && <div className={classes.activeBackground}></div>}
                        </Link>
                        <Link to='/admin/setting/call-configuration' className={clsx(classes.link)}>
                            {t('Cấu hình cuộc gọi')}
                            {active === 3 && <div className={classes.triangle}></div>}
                            {active === 3 && <div className={classes.activeBackground}></div>}
                        </Link>
                        {process.env.REACT_APP_PAYMENT === 'show' && <Link to='/admin/setting/payment' className={clsx(classes.link)} onClick={onSettingClick}>
                            {t('Cấu hình thanh toán')}
                            {paymentActive && <div className={classes.triangle}></div>}
                            {paymentActive && <div className={classes.activeBackground}></div>}
                        </Link>}
                        {isShowSettings && <Link to='/admin/setting/payment/MOMO' className={clsx(classes.link)} style={{ paddingLeft: '25px' }}>
                            <img src={window.location.origin + '/images/MoMo_Logo.svg'} className={classes.paymentLogoStyle} alt="" />
                            {t('Thanh toán MOMO')}
                            {active === 10 && <div className={classes.triangle}></div>}
                            {active === 10 && <div className={classes.activeBackground}></div>}
                        </Link>}
                        {isShowSettings && <Link to='/admin/setting/payment/VNPAY' className={clsx(classes.link)} style={{ paddingLeft: '25px' }}>
                            <img src={window.location.origin + '/images/Vnpay_Logo.svg'} style={{ position: 'relative', top: '0px', left: '-5px' }} width="20px" height="20px" alt="" />
                            {t('Thanh toán VNPAY')}
                            {active === 11 && <div className={classes.triangle}></div>}
                            {active === 11 && <div className={classes.activeBackground}></div>}
                        </Link>}
                        {isShowSettings && <Link to='/admin/setting/payment/ZaloPay' className={clsx(classes.link)} style={{ paddingLeft: '25px' }}>
                            <img src={window.location.origin + '/images/ZaloPay_Logo.svg'} style={{ position: 'relative', top: '3px', left: '-5px' }} width="20px" height="20px" alt="" />
                            {t('Thanh toán Zalo Pay')}
                            {active === 12 && <div className={classes.triangle}></div>}
                            {active === 12 && <div className={classes.activeBackground}></div>}
                        </Link>}
                        <Link to='/admin/setting/apikey' className={clsx(classes.link)}>
                            {t('Danh sách Key')}
                            {active === 6 && <div className={classes.triangle}></div>}
                            {active === 6 && <div className={classes.activeBackground}></div>}
                        </Link>
                        <Link to='/admin/setting/backup-restore' className={clsx(classes.link)}>
                            {t('Sao lưu và khôi phục')}
                            {active === 7 && <div className={classes.triangle}></div>}
                            {active === 7 && <div className={classes.activeBackground}></div>}
                        </Link>
                        {
                            process.env.REACT_APP_IS_BOOKING !== "hide" && (
                                <>
                                    <div className={clsx(classes.link)} >
                                        <span
                                            onClick={() => setIsService(!isService)}
                                            className={classes.extendLink}
                                        >
                                            {t('Cấu hình đặt dịch vụ')}
                                            <ArrowDropDownIcon
                                                style={{
                                                    transform: isService && "rotate(180deg)"
                                                }}
                                            />
                                        </span>
                                        <span
                                            className={classes.extendLink}
                                            onClick={() => handleChangeIsAddEdit()}
                                        >
                                            <LargeTooltip title={t("Thêm dịch vụ")} >
                                                <AddIcon fontSize="small" />
                                            </LargeTooltip>
                                        </span>
                                    </div>
                                    <div style={{
                                        height: (isService && !state.isServiceLoading) ? 40 * state.listService.length : 0,
                                        overflow: 'hidden',
                                        transition: '150ms'
                                    }} >
                                        {
                                            !state.isServiceLoading && state.listService.map(service => (
                                                <div
                                                    key={service.ID}
                                                    className={classes.wrapLink}
                                                >
                                                    <NavLink
                                                        to={{
                                                            pathname: `${url}/service-booking/${service.ID}`
                                                        }}
                                                        className={isActive => {
                                                            if (isActive) {
                                                                setActive(0);
                                                            }
                                                            return clsx(
                                                                classes.link, classes.subLink,
                                                                isActive && classes.isActive
                                                            )
                                                        }}
                                                    >
                                                        <div className={classes.wrapSubLink} >
                                                            {iconOptionLabel[service.ServiceIcon]}
                                                            <span style={{
                                                                marginLeft: !service?.ServiceIcon && "20px"
                                                            }}>
                                                                {`"${service.ServiceName}"`}
                                                            </span>
                                                        </div>
                                                    </NavLink>

                                                    <div className={classes.wrapIconAction} >
                                                        {
                                                            service.ServiceStatus === "deactivated" && (
                                                                <LargeTooltip title={t("Xóa")} >
                                                                    <RemoveCircleOutlineIcon
                                                                        fontSize="small"
                                                                        style={{
                                                                            color: "red"
                                                                        }}
                                                                        onClick={() => setState(prev => ({
                                                                            ...prev,
                                                                            serviceIdWillDelete: service.ID,
                                                                            isConfirm: true
                                                                        }))}
                                                                    />
                                                                </LargeTooltip>
                                                            )
                                                        }
                                                        <span
                                                            className={classes.infoIcon}
                                                            onClick={() => setState(prev => ({
                                                                ...prev,
                                                                infoService: service,
                                                                isAddEdit: true
                                                            }))}
                                                        >
                                                            <LargeTooltip title={t("Chi tiết")} >
                                                                <InfoIcon
                                                                    style={{
                                                                        color: "rgb(0, 154, 218)",
                                                                        fontSize: 14
                                                                    }}
                                                                />
                                                            </LargeTooltip>
                                                        </span>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>

                                    {
                                        state.isServiceLoading && (
                                            <div className={classes.wrapCircularProgress} >
                                                <CircularProgress
                                                    size="20px"
                                                    style={{
                                                        color: "#0b59c8",
                                                        margin: "auto"
                                                    }}
                                                />
                                            </div>
                                        )
                                    }
                                </>
                            )
                        }
                    </div>
                    <div className={classes.content} >
                        <Route exact path='/admin/setting/theme' component={UiSetting} />
                        <Route exact path='/admin/setting/rooms' component={RoomsTable} />
                        <Route exact path='/admin/setting/receptionist' component={ReceptionistSetting} />
                        <Route exact path='/admin/setting/apikey' component={ApiKey} />
                        <Route exact path='/admin/setting/backup-restore' component={BackupTable} />
                        <Route exact path='/admin/setting/advertisement' component={Advertisements} />
                        <Route exact path='/admin/setting/add-advertisement' component={AddAd} />
                        <Route exact path='/admin/setting/registration-configuration' component={Configuration} />
                        {process.env.REACT_APP_IS_BOOKING !== "hide" && <Route exact path='/admin/setting/service-booking/:serviceId' component={ServiceBooking} />}
                        <Route exact path='/admin/setting/call-configuration' component={CallConfiguration} />
                        {process.env.REACT_APP_PAYMENT === 'show' &&
                            <div>
                                <Route exact path='/admin/setting/payment' component={PaymentConfig} />
                                <Route exact path='/admin/setting/payment/MOMO' component={MomoConfig} />
                                <Route exact path='/admin/setting/payment/VNPAY' component={VNPayConfig} />
                                <Route exact path='/admin/setting/payment/ZaloPay' component={ZaloPayConfig} />
                            </div>}
                    </div>
                </div>
                {
                    <ConfirmWarning
                        isOpen={state.isConfirm}
                        handleClose={() => setState(prev => ({
                            ...prev,
                            isConfirm: false
                        }))}
                        isLoading={state.isConfirmLoading}
                        mess={t("Xóa dịch vụ sẽ xóa tất cả phòng, bạn có chắc muốn xóa?")}
                        onConfirm={() => deleteBookingServiceApi(state.serviceIdWillDelete)}
                    />
                }

                {
                    state.isAddEdit && (
                        <AddEditService
                            handleChangeIsAddEdit={handleChangeIsAddEdit}
                            infoService={state.infoService}
                        />
                    )
                }
            </div>

            <SuccessAlert
                message={state.responseStatus.succ}
                onCloseDialog={onCloseDialog}
            />

            <ErrorAlert
                message={state.responseStatus.err}
                onCloseDialog={onCloseDialog}
            />
        </>
    )
}

export default Setting;
