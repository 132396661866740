import { LinearProgress, MenuItem } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getApartmentList } from '../../../../api/apartments';
import ErrorAlert from '../../../../components/alert/ErrorAlert';
import CancelButton from '../../../../components/button/cancelButton';
import { CreateButton } from '../../../../components/button/createButton';
import { PrimaryTitle } from '../../../../components/title';
import { useStyles } from './style';

export default function Permission({ handleChangeIsPermission, bodyRequestRef }) {
    const [state, setState] = useState({
        listBlock: [],
        listFloor: [],
        listAccessPermission: [],
        blockSelected: "",
        floorSelected: "",
        responseStatus: {
            succ: "",
            err: ""
        },
        isLoading: false
    })

    const classes = useStyles();
    const { t } = useTranslation();

    const fetchGetApartmentList = async () => {
        try {
            setState(prev => ({
                ...prev,
                isLoading: true
            }));

            const { Items } = await (await getApartmentList()).data;
            const block = [];
            const floor = [];

            Items.forEach(item => {
                block.push(item.BlockID);
                floor.push(item.FloorID);
            })

            setState(prev => ({
                ...prev,
                listBlock: [...new Set(block)],
                listFloor: [...new Set(floor)]
            }));
        } catch (error) {
            setState(prev => ({
                ...prev,
                responseStatus: {
                    succ: "",
                    err: t("Tải danh sách tòa và tầng thất bại!")
                }
            }))
        }

        setState(prev => ({
            ...prev,
            isLoading: false
        }));
    }

    const onSelectChange = (key, value) => {
        setState(prev => ({
            ...prev,
            [key]: value
        }))
    }

    const onAddClick = () => {
        const listAccessPermission = [...state.listAccessPermission];
        const checked = listAccessPermission.findIndex(item => {
            return item.block === state.blockSelected && item.floor === state.floorSelected;
        });

        if (checked < 0) {
            listAccessPermission.push({
                block: state.blockSelected,
                floor: state.floorSelected
            })
        }

        setState(prev => ({
            ...prev,
            listAccessPermission
        }))
    }
    const onClickDelete = (block, floor) => {
        const listAccessPermission = state.listAccessPermission.filter(item => {
            return item.block !== block || item.floor !== floor;
        });

        setState(prev => ({
            ...prev,
            listAccessPermission
        }))
    }
    const onSaveClick = () => {
        bodyRequestRef.current = {
            ...bodyRequestRef.current,
            access_permission: [...state.listAccessPermission]
        }
        handleChangeIsPermission();
    }
    const onDefaultClick = () => {
        bodyRequestRef.current = {
            ...bodyRequestRef.current,
            access_permission: []
        }
        handleChangeIsPermission();
    }

    useEffect(() => {
        fetchGetApartmentList();
    }, []);

    useEffect(() => {
        setState(prev => ({
            ...prev,
            listAccessPermission: [...bodyRequestRef.current.access_permission]
        }))
    }, [bodyRequestRef]);

    return (
        <>
            <div className={classes.permisCamera}>
                <div className={classes.contain}>
                    <div className={classes.containTitle}>
                        <PrimaryTitle>{t("Phân quyền truy cập camera")}</PrimaryTitle>
                    </div>

                    {
                        state.isLoading && (
                            <LinearProgress
                                style={{
                                    color: "#0b59c8"
                                }}
                            />
                        )
                    }

                    <div className={classes.containField}>
                        <div className={classes.containTextField}>
                            <label htmlFor="block">
                                {t("Tòa")}
                                <span className={classes.required}> (*)</span>
                            </label>
                            <br />
                            <Select
                                displayEmpty
                                name='block'
                                variant="outlined"
                                size='small'
                                placeholder={t("Tòa")}
                                defaultValue=""
                                disabled={state.isLoading}
                                value={state.blockSelected}
                                className={classes.textField}
                                onChange={(e) => onSelectChange("blockSelected", e.target.value)}
                            >
                                <MenuItem value="" disabled>{t("<Chọn>")}</MenuItem>
                                {
                                    state.listBlock?.map(item => (
                                        <MenuItem key={item} value={item} >{item}</MenuItem>
                                    ))
                                }
                            </Select>
                        </div>

                        <div className={classes.containTextField}>
                            <label htmlFor="floor">
                                {t("Tầng")}
                                <span className={classes.required}> (*)</span>
                            </label>
                            <br />
                            <Select
                                displayEmpty
                                name='floor'
                                variant="outlined"
                                size='small'
                                placeholder={t("Tầng")}
                                defaultValue=""
                                disabled={state.isLoading}
                                value={state.floorSelected}
                                className={classes.textField}
                                onChange={(e) => onSelectChange("floorSelected", e.target.value)}
                            >
                                <MenuItem disabled value="">{t("<Chọn>")}</MenuItem>
                                {
                                    state.listFloor?.map(item => (
                                        <MenuItem key={item} value={item} >{item}</MenuItem>
                                    ))
                                }
                            </Select>
                        </div>

                        <CreateButton
                            style={{
                                margin: "27px 0 0 0"
                            }}
                            disabled={!state.blockSelected || !state.floorSelected}
                            onClick={onAddClick}
                        >
                            {t("Thêm")}
                        </CreateButton>
                    </div>

                    <div className={classes.permisShow}>
                        {
                            state.listAccessPermission?.map(item => (
                                <div key={item.block + item.floor} className={classes.permisShowItem}>
                                    <span>
                                        {`${t("Tòa")} ${item.block} - ${t("Tầng")} ${item.floor}`}
                                    </span>
                                    <div
                                        className={classes.permisShowDelete}
                                        onClick={() => onClickDelete(item.block, item.floor)}
                                    >
                                        <ClearOutlinedIcon fontSize='small' />
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                    <hr />

                    <div className={classes.wrapBtn}>
                        <CancelButton
                            onClick={handleChangeIsPermission}
                        >
                            {t("Hủy")}
                        </CancelButton>
                        <CreateButton onClick={onDefaultClick}>
                            {t("Mặc định")}
                        </CreateButton>
                        <CreateButton onClick={onSaveClick}>
                            {t("Lưu")}
                        </CreateButton>
                    </div>
                </div>
            </div>

            <ErrorAlert
                message={state.responseStatus.err}
                onCloseDialog={() => setState(prev => ({
                    ...prev,
                    responseStatus: {
                        err: "",
                        succ: ""
                    }
                }))}
            />
        </>
    )
}
