import React, { useState, useEffect, useRef } from 'react';
import SubDetailTitle from '../../title/subDetailTitle';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { isEmpty, checkDisplayName, checkIPv4 } from '../../../validation/CheckForm';
import Divider from '@material-ui/core/Divider/Divider';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import MultipleAutocomplete from '@mui/material/Autocomplete';
import { apiGetapartmentdata } from '../../../api/apartment';
import { compare } from 'natural-orderby';
import { getBookingService } from '../../../api/booking';
import { apiTommiAdd, apiTommiTest, apiTommiExist } from '../../../api/tommi';
import { SuccessDialog, FailedDialog } from '../../dialog/index';
import CircularProgress from '@material-ui/core/CircularProgress';
import { authenticator } from 'otplib';
import clsx from 'clsx';
import { BlockFloorRoom } from '../../policies/blockFloorRoom/BlockFloorRoom';
import { useDispatch } from 'react-redux';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';
import SuccessAlert from '../../alert/SuccessAlert';
import ErrorAlert from '../../alert/ErrorAlert';
import LargeTooltip from '../../largeTooltip';

const useStyles = makeStyles((theme) => ({
    page: {
        width: '96%',
        margin: 'auto'
    },
    formBlock: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    inputBlock: {
        width: '33%',
        height: '80px'
    },
    autoInputBlock: {
        width: '95%',
        height: 'auto',
        padding: '24px 5% 4px 5%',
        background: '#F0F0F0',
        borderRadius: '6px',
        '& .css-1auycx3-MuiAutocomplete-root .MuiOutlinedInput-root': {
            padding: '3px',
        }
    },
    inputStyle: {
        width: '90%'
    },
    label: {
        margin: '0',
        fontSize: '14px',
        lineHeight: '140%',
        color: '#000000',
        display: 'inline-block'
    },
    title: {
        width: '100%',
        marginBottom: '5px',
        fontSize: '18px'
    },
    dividerStyle: {
        width: '100%',
        marginBottom: '20px!important',
        marginTop: '10px!important'
    },
    actionBlock: {
        marginRight: '4%',
        marginTop: '15px'
    },
    radio: {
        '&$checked': {
            color: theme.menuBackground
        }
    },
    checked: {},
    autocompleteStyle: {
        '& input#tags-outlined': {
            minWidth: '0!important'
        },
        width: '100%',
        background: '#FFFFFF',
    },
    errorTextStyle: {
        color: 'red',
        fontSize: '12px',
        height: '20px',
        paddingTop: '3px'
    },
    openDoorButton: {
        marginTop: '15px'
    },
    flex1: {
        flex: 1
    },
    displayFlex: {
        display: 'flex'
    },
    select: {
        '& .MuiOutlinedInput-input': {
            padding: '10px 12px'
        }
    },
    input: {
        width: '90%'
    },
    overlay: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        background: 'rgba(0,0,0,0.1)',
        zIndex: '2300'
    },
}));

const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);
const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);

const AddTommiDevice = () => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const [device, setDevice] = useState({
        deviceName: '',
        deviceIp: '',
        deviceType: 'Tommi',
        deviceSn: '',
        clientId: '',
        workingMode: 'online',
        policies: {},
        enableSameFace: true
    });
    const [alert, setAlert] = useState({
        deviceName: '',
        deviceIp: '',
        deviceType: '',
        deviceSn: '',
        blockPolicy: '',
        roomPolicy: '',
        servicePolicy: '',
        clientId: '',
        success: '',
        error: ''
    });
    const [type, setType] = useState('block');
    const [services, setServices] = useState([]);
    const [selectedService, setSelectedService] = useState([]);
    const [roomPolicyData, setRoomPolicyData] = useState([]);
    const [blockPolicyData, setBlockPolicyData] = useState([]);
    const [isChoosePolicy, setIsChoosePolicy] = useState(false);
    const [apartmentData, setApartmentData] = useState([]);
    const [isAdding, setIsAdding] = useState(false);
    const [isSucceed, setIsSucceed] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [isTommiTest, setIsTommiTest] = useState(true);
    const [isConnecting, setIsConnecting] = useState(false);
    const [isExpiredSession, setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const alertRef = useRef();
    const deviceSnRef = useRef();

    useEffect(() => {
        if (isExpiredSession) {
            history.push('/login');
            dispatch({ type: 'IS_LOGIN_AGAIN_TRUE' });
        }
    }, [isExpiredSession]);

    const onBackClick = () => {
        history.replace('/admin/device-management/devices');
    }

    const onChoosePolicyClick = () => {
        setIsChoosePolicy(true);
    }

    const onCancelBlockPolicyClick = () => {
        setDevice({ ...device, policies: {} });
    }

    const getServiceList = async () => {
        try {
            var res = await getBookingService();
            var data = [];
            res.data.Items.forEach(item => {
                data.push({
                    serviceID: item.ID,
                    serviceName: item.ServiceName
                })
            })
            setServices(data);
        } catch (error) {
            if (error.response ?.status === 401) {
                return setIsExpiredSession(true);
            }
        }
    }

    const getBlocks = async () => {
        try {
            var res = await apiGetapartmentdata();
            setApartmentData(res.data.Items);
        } catch (error) {
            if (error.response ?.status === 401) {
                return setIsExpiredSession(true);
            }
        }
    }

    useEffect(() => {
        getBlocks();
        getServiceList();
    }, [])

    const onNameChange = (e) => {
        const name = e.target.value;
        setDevice({
            ...device,
            deviceName: name
        });
        if (isEmpty(name)) {
            setAlert({
                ...alert,
                deviceName: t('Không được để trống')
            });
        } else if (!checkDisplayName(name)) {
            setAlert({
                ...alert,
                deviceName: t('Không được có ký tự đặc biệt')
            });
        } else if (name.length < 6 || name.length > 30) {
            setAlert({
                ...alert,
                deviceName: t('Độ dài ký tự trong khoảng từ 6 đến 30')
            });
        } else {
            setAlert({
                ...alert,
                deviceName: ''
            });
        }
    }

    const onDeviceTypeChange = (e) => {
        const value = e.target.value;
        setDevice({
            ...device,
            deviceType: value
        });
        if (isEmpty(value)) {
            setAlert({
                ...alert,
                deviceType: t('Không được để trống')
            });
        } else {
            setAlert({
                ...alert,
                deviceType: ''
            });
        }
    }

    const onDeviceIpChange = (e) => {
        const value = e.target.value;
        setDevice({ ...device, deviceIp: value });
        if (isEmpty(value)) {
            setAlert({
                ...alert,
                deviceIp: t('Không được để trống')
            });
        } else {
            if (!checkIPv4(value)) {
                setAlert({
                    ...alert,
                    deviceIp: t('IP không hợp lệ')
                });
            } else {
                setAlert({
                    ...alert,
                    deviceIp: ''
                });
            }
        }
    }

    const onSnChange = (e) => {
        const value = e.target.value;
        setDevice({
            ...device,
            deviceSn: value
        });
        if (deviceSnRef.current) clearTimeout(deviceSnRef.current);
        deviceSnRef.current = setTimeout(async () => {
            if (isEmpty(value)) {
                setAlert({
                    ...alert,
                    deviceSn: t('Không được để trống')
                });
            } else {
                try {
                    const res = await apiTommiExist({ deviceSn: value });
                    if (res.data === 'Found') {
                        setAlert({
                            ...alert,
                            deviceSn: t('Số seri của thiết bị đã tồn tại')
                        });
                    } else {
                        setAlert({
                            ...alert,
                            deviceSn: ''
                        });
                    }
                } catch (error) {

                }
            }
        }, 500);
    }

    const onGenerateClientIdClick = () => {
        setDevice({
            ...device,
            clientId: authenticator.generateSecret()
        })
        setAlert({
            ...alert,
            clientId: ''
        });
    }

    const onTypeChange = (e) => {
        setType(e.target.value);
        setBlockPolicyData([]);
        setRoomPolicyData([]);
        setDevice({
            ...device,
            policies: {}
        })
    }

    const onWorkingModeChange = (e) => {
        setDevice({
            ...device,
            workingMode: e.target.value
        })
    }

    const onEnableSameFaceChange = (e) => {
        if (e.target.value === 'false') {
            setDevice({
                ...device,
                enableSameFace: false
            })
        } else {
            setDevice({
                ...device,
                enableSameFace: true
            })
        }
    }

    const onServiceChange = (e, newValue) => {
        setSelectedService(newValue);
        setDevice({
            ...device,
            workingMode: 'online',
            policies: {
                servicePolicy: newValue
            }
        });
        if (newValue.length === 0) {
            setAlert({
                ...alert, servicePolicy: t('Không được để trống')
            });
        } else {
            setAlert({
                ...alert, servicePolicy: ''
            });
        }
    }

    const onBlockPolicyChange = (e, newValue) => {
        var data = {
            blockPolicy: newValue
        }
        setBlockPolicyData(data.blockPolicy);
        setDevice({
            ...device,
            policies: data
        });
    }

    const onRoomPolicyChange = (e, newValue) => {
        var data = {
            roomPolicy: newValue
        }
        setRoomPolicyData(data.roomPolicy);
        setDevice({
            ...device,
            policies: data
        });
    }

    const onSaveBlockPoliciesClick = (blockPData, roomData) => {
        var data = {};
        if (type === 'block') {
            data = {
                blockPolicy: blockPData
            }
            setBlockPolicyData(blockPData);
        } else if (type === 'room') {
            data = {
                roomPolicy: roomData
            }
            setRoomPolicyData(roomData);
        }
        setAlert({
            ...alert,
            blockPolicy: '',
            roomPolicy: '',
            servicePolicy: ''
        })
        setDevice({ ...device, policies: data });
        setIsChoosePolicy(false);
    }

    const onCreateDeviceClick = async () => {
        if (isEmpty(device.deviceName)) {
            return setAlert({ ...alert, deviceName: t('Không được để trống') });
        }
        if (isEmpty(device.deviceSn)) {
            return setAlert({ ...alert, deviceSn: t('Không được để trống') });
        }
        if (isEmpty(device.deviceIp)) {
            return setAlert({ ...alert, deviceIp: t('Không được để trống') });
        }
        if (isEmpty(device.clientId)) {
            return setAlert({ ...alert, clientId: t('Không được để trống') });
        }
        if (type === 'block') {
            if (device.policies.blockPolicy) {
                if (device.policies.blockPolicy.lenth === 0) {
                    return setAlert({ ...alert, blockPolicy: t('Không được để trống') });
                }
            } else {
                return setAlert({ ...alert, blockPolicy: t('Không được để trống') });
            }
        } else if (type === 'room') {
            if (device.policies.roomPolicy) {
                if (device.policies.roomPolicy.lenth === 0) {
                    return setAlert({ ...alert, roomPolicy: t('Không được để trống') });
                }
            } else {
                return setAlert({ ...alert, roomPolicy: t('Không được để trống') });
            }
        } else {
            if (device.policies.servicePolicy) {
                if (device.policies.servicePolicy.lenth === 0) {
                    return setAlert({ ...alert, servicePolicy: t('Không được để trống') });
                }
            } else {
                return setAlert({ ...alert, servicePolicy: t('Không được để trống') });
            }
        }
        if (alert.deviceName !== '' || alert.deviceSn !== '' || alert.deviceIp !== '' || alert.clientId !== '') return;
        try {
            setIsAdding(true);
            await apiTommiAdd(device);
            setIsSucceed(true);
        } catch (error) {
            if (error.response ?.status === 401) {
                return setIsExpiredSession(true);
            }
            setIsFailed(true);
        }
        setIsAdding(false);
    }

    const onSucceedConfirm = () => {
        history.replace('/admin/device-management/devices');
    }

    const onFailedConfirm = () => {
        setIsFailed(false);
    }

    const getTilte = () => {
        if (type === 'block') {
            return t('Phân quyền mở cửa tòa/ tầng');
        } else if (type === 'room') {
            return t('Phân quyền mở cửa phòng');
        }
    }

    const onTestConectionClick = async () => {
        setIsTommiTest(false);
        setIsConnecting(true);
        try {
            await apiTommiTest({ clientId: device.clientId });
            setAlert({
                ...alert,
                success: t('Kết nối thành công!'),
                error: ''
            })
        } catch (error) {
            setAlert({
                ...alert,
                error: t('Thử không thành công, vui lòng thử lại sau!'),
                success: ''
            })
        }
        setIsTommiTest(true);
        setIsConnecting(false);
    }

    const disabledConnection = () => {
        var flag = true;
        if (device.clientId !== '' && isTommiTest) {
            flag = false;
        }
        return flag;
    }

    const onCloseDialog = () => {
        if (alert.success !== '' || alert.error !== '') {
            setAlert({
                ...alert,
                success: '',
                error: ''
            })
        }
    }

    useEffect(() => {
        if (alertRef.current) clearTimeout(alertRef.current);
        alertRef.current = setTimeout(() => {
            onCloseDialog();
        }, 5000);
    }, [alert.success, alert.error])

    const onCloseDialogClick = () => {
        setIsChoosePolicy(false);
    }

    return (
        <div className={classes.page}>
            {isAdding && <div className={classes.overlay}></div>}
            <BlockFloorRoom
                title={getTilte()}
                isChoosePolicy={isChoosePolicy}
                closeDialog={onCloseDialogClick}
                blockData={blockPolicyData}
                roomData={roomPolicyData}
                type={type}
                onCancelClick={onCancelBlockPolicyClick}
                onSaveClick={(blockData, roomData) => onSaveBlockPoliciesClick(blockData, roomData)}
                apartmentData={apartmentData}
                CreateButton={CreateButton}
                CancelButton={CancelButton}
            />
            <SuccessAlert
                message={alert.success}
                onCloseDialog={onCloseDialog}
            />
            <ErrorAlert
                message={alert.error}
                onCloseDialog={onCloseDialog}
            />
            <SuccessDialog
                isOpen={isSucceed}
                mess={t('Thêm mới thiết bị thành công!')}
                onConfirm={onSucceedConfirm}
            />
            <FailedDialog
                isOpen={isFailed}
                mess={t('Thêm mới thiết bị không thành công, vui lòng thử lại sau!')}
                onConfirm={onFailedConfirm}
            />
            <div className={classes.displayFlex}>
                <SubDetailTitle
                    onClick={onBackClick}
                    text={t('Thêm thiết bị mới')}
                />
                <div className={classes.flex1}></div>
                <CreateButton
                    variant="contained"
                    onClick={onTestConectionClick}
                    className={classes.openDoorButton}
                    disabled={disabledConnection()}
                >
                    {t('Thử kết nối')}
                    {isConnecting && <CircularProgress size={25} style={{ position: 'absolute', color: '#0b59c8' }} />}
                </CreateButton>
                <div className={classes.actionBlock}>
                    <CreateButton
                        variant="contained"
                        onClick={onCreateDeviceClick}
                        disabled={isAdding}
                    >
                        {t('Thêm')}
                        {isAdding && <CircularProgress size={25} style={{ position: 'absolute', color: '#0b59c8' }} />}
                    </CreateButton>
                </div>
            </div>
            <div className={classes.formBlock}>
                <div className={classes.title}>{t('Thông tin thiết bị')}</div>
                <div className={classes.inputBlock}>
                    <label className={classes.label}>{t('Tên thiết bị')} <span style={{ color: 'red' }}>(*)</span></label><br />
                    <TextField
                        classes={{
                            root: classes.inputStyle
                        }}
                        value={device.deviceName}
                        size="small"
                        placeholder={t("Tên thiết bị")}
                        variant="outlined"
                        onChange={onNameChange}
                        helperText={alert.deviceName}
                        error={alert.deviceName !== ''}
                    />
                </div>
                <div className={clsx(classes.inputBlock, classes.select)}>
                    <FormControl required variant="outlined" classes={{ root: classes.input }}>
                        <label className={classes.label}>{t('Loại thiết bị')} </label>
                        <Select
                            value={device.deviceType}
                            style={{ height: '40px', background: '#FFFFFF'}}
                            displayEmpty
                        >
                            <MenuItem value={device.deviceType} >{device.deviceType}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className={classes.inputBlock}>
                    <label className={classes.label}>{t('Số seri của thiết bị')} <span style={{ color: 'red' }}>(*)</span></label><br />
                    <TextField
                        classes={{
                            root: classes.inputStyle
                        }}
                        value={device.deviceSn}
                        size="small"
                        placeholder={t("Số seri của thiết bị")}
                        variant="outlined"
                        onChange={onSnChange}
                        helperText={alert.deviceSn}
                        error={alert.deviceSn !== ''}
                    />
                </div>
                <div className={classes.inputBlock}>
                    <label className={classes.label}>{t('IP của thiết bị')} <span style={{ color: 'red' }}>(*)</span></label><br />
                    <TextField
                        classes={{
                            root: classes.inputStyle
                        }}
                        value={device.deviceIp}
                        size="small"
                        placeholder={t("IP của thiết bị")}
                        variant="outlined"
                        onChange={onDeviceIpChange}
                        helperText={alert.deviceIp}
                        error={alert.deviceIp !== ''}
                    />
                </div>
                <div className={classes.inputBlock}>
                    <label className={classes.label}>{t('ClientID')} <span style={{ color: 'red' }}>(*)</span></label><br />
                    <TextField
                        classes={{
                            root: classes.inputStyle
                        }}
                        size="small"
                        placeholder={t("ClientID")}
                        variant="outlined"
                        value={device.clientId}
                        inputProps={{
                            readOnly: true
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={onGenerateClientIdClick}
                                    >
                                        <LargeTooltip title={t('Tạo mới')} >
                                            <CreateIcon fontSize="small" />
                                        </LargeTooltip>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        helperText={alert.clientId}
                        error={alert.clientId !== ''}
                    />
                </div>
                <Divider className={classes.dividerStyle} />
                <div className={classes.title}>
                    {t('Chế độ quét khuôn mặt')}
                    <LargeTooltip
                        title={
                            <div>
                                {t('Trực tuyến')}: {t('Quét khuôn mặt, phía backend sẽ xử lý khuôn mặt, hợp lệ mới cho phép thiết bị TOMMI mở cửa')} <br />
                                {t('Ngoại tuyến')}: {t('Quét khuôn mặt và mở cửa trên thiết bị TOMMI')}
                            </div>
                        }
                    >
                        <HelpOutlineIcon style={{ fontSize: '16px', marginLeft: '5px' }} />
                    </LargeTooltip>
                </div>
                <div>
                    <RadioGroup
                        row
                        onChange={onWorkingModeChange}
                        value={device.workingMode}
                    >
                        <FormControlLabel
                            value="online"
                            control={
                                <Radio
                                    color="primary"
                                    classes={{ root: classes.radio, checked: classes.checked }}
                                    size="small"
                                />}
                            label={t('Trực tuyến')}
                        />
                        <FormControlLabel
                            value="offline"
                            control={
                                <Radio
                                    color="primary"
                                    classes={{ root: classes.radio, checked: classes.checked }}
                                    size="small"
                                    disabled={device.policies.servicePolicy}
                                />}
                            label={t('Ngoại tuyến')}
                        />
                    </RadioGroup>
                </div>
                <Divider className={classes.dividerStyle} />
                {/* <div className={classes.title}>
                    {t('Cho phép trùng khuôn mặt trên thiết bị')}
                </div>
                <div>
                    <RadioGroup
                        row
                        onChange={onEnableSameFaceChange}
                        value={device.enableSameFace}
                    >
                        <FormControlLabel
                            value={true}
                            control={
                                <Radio
                                    color="primary"
                                    classes={{ root: classes.radio, checked: classes.checked }}
                                    size="small"
                                />}
                            label={t('Cho phép')}
                        />
                        <FormControlLabel
                            value={false}
                            control={
                                <Radio color="primary"
                                    classes={{ root: classes.radio, checked: classes.checked }}
                                    size="small"
                                />}
                            label={t('Không cho phép')}
                        />
                    </RadioGroup>
                </div>
                <Divider className={classes.dividerStyle} /> */}
                <div className={classes.title}>{t('Phân quyền mở cửa')}</div>
                <div>
                    <RadioGroup
                        row
                        onChange={onTypeChange}
                        value={type}
                    >
                        <FormControlLabel
                            value="block"
                            control={
                                <Radio
                                    color="primary"
                                    classes={{ root: classes.radio, checked: classes.checked }}
                                    size="small"
                                />}
                            label={t('Tòa/ Tầng')}
                        />
                        <FormControlLabel
                            value="room"
                            control={
                                <Radio
                                    color="primary"
                                    classes={{ root: classes.radio, checked: classes.checked }}
                                    size="small"
                                />}
                            label={t('Phòng')}
                        />
                        <FormControlLabel
                            value="service"
                            control={
                                <Radio color="primary"
                                    classes={{ root: classes.radio, checked: classes.checked }}
                                    size="small"
                                />}
                            label={t('Dịch vụ')}
                        />
                    </RadioGroup>
                </div>
                {
                    type === 'block' &&
                    <div className={classes.autoInputBlock}>
                        <MultipleAutocomplete
                            multiple
                            classes={{ inputRoot: classes.autocompleteStyle }}
                            id="tags-outlined"
                            open={false}
                            options={blockPolicyData}
                            value={blockPolicyData}
                            size="small"
                            getOptionLabel={(option) => {
                                let mess = "";
                                if (option.block === "all") {
                                    mess = `${t("Tất cả tòa")} - `
                                } else {
                                    mess = `${t("Tòa")} ${option.block} - `
                                }
                                if (option.floor === "all") {
                                    mess += `${t("Tất cả tầng")} - `
                                } else {
                                    mess += `${t("Tầng")} ${option.floor} `
                                }
                                return mess;
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder={blockPolicyData.length === 0 ? t('Tòa/ Tầng') : t('')}
                                    disabled
                                    onClick={onChoosePolicyClick}
                                />
                            )}
                            onChange={(e, newValue) => onBlockPolicyChange(e, newValue)}
                        />
                        <p className={classes.errorTextStyle}>{alert.blockPolicy}</p>
                    </div>
                }
                {
                    type === 'room' &&
                    <div className={classes.autoInputBlock}>
                        <MultipleAutocomplete
                            multiple
                            classes={{ inputRoot: classes.autocompleteStyle }}
                            id="tags-outlined"
                            open={false}
                            options={roomPolicyData}
                            value={roomPolicyData}
                            size="small"
                            getOptionLabel={(option) => {
                                let mess = "";
                                if (option.block === "all") {
                                    mess = `${t("Tất cả tòa")} - `
                                } else {
                                    mess = `${t("Tòa")} ${option.block} - `
                                }
                                if (option.floor === "all") {
                                    mess += `${t("Tất cả tầng")} - `
                                } else {
                                    mess += `${t("Tầng")} ${option.floor} - `
                                }
                                if (option.room === "all") {
                                    mess += `${t("Tất cả phòng")} - `
                                } else {
                                    mess += `${t("Phòng")} ${option.room} `
                                }
                                return mess;
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder={roomPolicyData.length === 0 ? t('Phòng') : t('')}
                                    disabled
                                    onClick={onChoosePolicyClick}
                                />
                            )}
                            onChange={(e, newValue) => onRoomPolicyChange(e, newValue)}
                        />
                        <p className={classes.errorTextStyle}>{alert.roomPolicy}</p>
                    </div>
                }
                {
                    type === 'service' &&
                    <div className={classes.autoInputBlock}>
                        <MultipleAutocomplete
                            multiple
                            classes={{ inputRoot: classes.autocompleteStyle }}
                            id="tags-outlined"
                            options={services}
                            size="small"
                            value={selectedService}
                            getOptionLabel={(option) => option.serviceName}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder={selectedService.length === 0 ? t('Dịch vụ') : t('')}
                                    disabled
                                />
                            )}
                            onChange={(e, newValue) => onServiceChange(e, newValue)}
                        />
                        <p className={classes.errorTextStyle}>{alert.servicePolicy}</p>
                    </div>
                }
                <Divider className={classes.dividerStyle} />
            </div>
        </div>
    )
}

export default AddTommiDevice;