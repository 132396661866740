import React, {useEffect,useState, useRef} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import axiosClient from '../../config/AxiosClient';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField/TextField';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import Button from '@material-ui/core/Button/Button';
import { checkPassword, isEmpty, checkDisplayName } from '../../validation/CheckForm';
import {useHistory} from 'react-router-dom';
import md5 from 'md5';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PasswordStrengthBar from 'react-password-strength-bar';
import Cookies from 'js-cookie';
import clsx from 'clsx';
import zxcvbn from 'zxcvbn';
import SuccessAlert from '../alert/SuccessAlert';
import ErrorAlert from '../alert/ErrorAlert';

const useStyles = makeStyles((theme)=>({
    font:{fontSize:'20px',background:'#FFFFFF',borderRadius:'6px',boxShadow:'5px 5px #f1f1f1'},
    content:{height:'92%',minWidth:'980px',display:'flex',justifyContent:'center',position:'relative',marginTop:'20px'},
    title:{fontWeight:'bolder',paddingLeft:'40%',paddingBottom:'22px'},
    label:{paddingBottom:'10px',fontSize: '14px',lineHeight: '140%',color: '#7A7171',display: 'inline-block',paddingTop:'10px',width:'40%',textAlign:'right',paddingRight:'5%'},
    dialogTitle:{textAlign:'center',fontSize:'20px',fontWeight:'600',lineHeight:'25px',color:'#FFFFFF'},
    contentDialog:{fontSize:'16px',height:'68px',borderBottom:'1px solid #EEEDF2',display:'flex',alignItems:'center',justifyContent:'center'},
    dialogTitleBackground:theme.primaryColor,
    errorDialogTitleBackground:theme.errorColor,
    overlay:{position:'fixed',width:'100%',height:'100%',top:'0',left:'0',background:'rgba(0,0,0,0.1)',zIndex:'1300'},
    focus:{
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': { 
                borderColor: theme.themeColor,
            },
        },
    },
    primaryColor:theme.primaryTextColor
}))
const CreateButton = withStyles(theme =>({
    root:theme.create_button
}))(Button);
const CancelButton = withStyles(theme =>({
    root:theme.cancel_button
}))(Button);
const ErrorButton = withStyles(theme=>({
    root:theme.error_button
}))(Button);

const MyAccount = props =>{
    const classes = useStyles();
    const [account,setAccount] = useState({});
    const [loading,setLoading] = useState(false);
    const [showEnterPassword,setShowEnterPassword] = useState(false);
    const [showReEnterPassword,setShowReEnterPassword] = useState(false);
    const [isEdit,setIsEdit] = useState(false);
    const [isEditName,setIsEditName] = useState(false);
    const [alert,setAlert] = useState({
        password:'',
        rePassword:'',
        display_name:'',
        success:'',
        err:''
    })
    const [password,setPassword] = useState('');
    const [rePassword,setRePassword] = useState('');
    const [isDialog,setIsDialog] = useState(false);
    const [isChanging,setIsChanging] = useState(false);
    const [isSuccessDialog,setIsSuccessDialog] = useState(false);
    const [isFailedDialog,setIsFailedDialog] = useState(false);
    const [isExpiredSession,setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const {t} = useTranslation();
    const [error,setError] = useState('');
    const passwordRef = useRef();
    const rePasswordRef = useRef();

    useEffect(()=>{
        if(isExpiredSession){
          history.push('/login');
          dispatch({type:'IS_LOGIN_AGAIN_TRUE'});
        } 
    },[isExpiredSession]);

    const getDefaultValues = async()=>{
        try {
            var res;
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                res = await axiosClient.post('/adminuser',{'username':localStorage.getItem('username')},{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
                });
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                res = await axiosClient.post('/adminusers/get',{'username':localStorage.getItem('username')},{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`,'Content-Type': 'application/json'}
                });
            }
            const data = res.data.Items[0];
            setAccount({
                Username:data.Username,
                AdminRole:data.AdminRole,
                Email:data.Email,
                Password:data.Password,
                LoginToken:data.LoginToken,
                DisplayName:typeof(data.DisplayName)!=='undefined'?data.DisplayName:''
            });
            setIsDialog(false);
            setShowEnterPassword(false);
            setShowReEnterPassword(false);
            setAlert({...alert,password:'',rePassword:'',display_name:''});
            setPassword('');
            setRePassword('');
            setIsEditName(false);
        } catch (err) {
            if (typeof(err.response)!=='undefined' && err.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
        }
        setLoading(true);
    }
    
    useEffect(()=>{
        getDefaultValues();
    },[loading])


    const onEditClick = () =>{
        setIsEdit(true);
    }
    const showEnterPasswordClick = () =>{
        setShowEnterPassword(!showEnterPassword);
    }
    const showReEnterPasswordClick = () =>{
        setShowReEnterPassword(!showReEnterPassword);
    }
    const onCancelClick = () =>{
        setIsEdit(false);
        setLoading(false);
    }

    const onNameChange = (e)=>{
        const name = e.target.value;
        setAccount({...account,DisplayName:name});
        if(isEmpty(name)){
            setAlert({...alert,display_name:t('Không được để trống')});
        }else if(!checkDisplayName(name)){
            setAlert({...alert,display_name:t('Không được có ký tự đặc biệt')});
        }else if (name.trim().length < 6 || name.trim().length > 30){
            setAlert({...alert,display_name:t('Độ dài ký tự trong khoảng từ 6 đến 30')});
        }else{
            setAlert({...alert,display_name:''});
        }
    }

    const onSaveNameClick = async() =>{
        setIsChanging(true);
        try {
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                await axiosClient.post('/updateadminuser',{'username':account.Username,'display_name':account.DisplayName,'token':account.LoginToken},{headers:{'Authorization': `Bearer ${Cookies.get('token')}`,'Content-Type': 'application/json'}});
            } else {
                await axiosClient.post('/adminusers/update', {username: account.Username, display_name: account.DisplayName },{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`,'Content-Type': 'application/json'}
                });
            }
            localStorage.setItem('fullname',account.DisplayName);
            setAlert({...alert,success:t('Lưu thành công!')});
            setIsEditName(false);
            setLoading(false);
        } catch (err) {
            if (typeof(err.response)!=='undefined'){
                if(err.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            }
            else setAlert({...alert,err:t('Lưu không thành công, vui lòng thử lại!')});  
        }
        setIsChanging(false);
    }
    const onPasswordChange = (e) =>{
        const value = e.target.value;
        setPassword(value);
        if(isEmpty(value)){
            setAlert({...alert,password:t("Không được để trống")});
        }else if(!checkPassword(value)){
            setAlert({...alert,password:t("Tối thiểu 8 ký tự bao gồm chữ HOA, chữ thường, số và ký tự đặc biệt")});
        }else{
            setAlert({...alert,password:''});
        }
        if(passwordRef.current) clearTimeout(passwordRef.current);
        passwordRef.current = setTimeout(() => {
            const result = zxcvbn(password);
            if(result.feedback.warning !== '') return setAlert({...alert,password:t('Đã trùng mật khẩu phổ biến')});
            if(rePassword === '') return;
            if(value === rePassword) setAlert({...alert,rePassword:''});
            else setAlert({...alert,rePassword:t('Mật khẩu không giống nhau')});
        }, 500);
    }

    const onRePasswordChange = (e) =>{
        const value = e.target.value;
        setRePassword(value);
        if(isEmpty(value)){
            setAlert({...alert,rePassword:t("Không được để trống")});
        }else{
            setAlert({...alert,rePassword:''});
            if(rePasswordRef.current) clearTimeout(rePasswordRef.current);
            rePasswordRef.current = setTimeout(() => {
                if(value !== password) setAlert({...alert,rePassword:t('Mật khẩu không giống nhau')});
            }, 500);
        }   
    }

    const onSaveClick = async() =>{
        if(isEmpty(password)) return setAlert({...alert,password:t("Không được để trống")});
        if(alert.password !== '') return;
        if(isEmpty(rePassword)) return setAlert({...alert,rePassword:t("Không được để trống")});
        if(password !== rePassword) return setAlert({...alert,rePassword:t("Mật khẩu không giống nhau")});
        if(alert.rePassword !== '') return;
        setIsDialog(true);
    }

    const onConfirmClick = async() =>{
        setIsChanging(true);
        try {
            const data = {
                username:account.Username,
                password:account.Password,
                new_password:md5(password)
            }
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                await axiosClient.post('/cognito/password/change',JSON.stringify(data));
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                await axiosClient.post('/auth/changepassword',JSON.stringify({username:account.Username, oldpassword:account.Password, newpassword:md5(password)}),{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`,'Content-Type': 'application/json'}
                });           
            }
            setIsSuccessDialog(true);
        } catch (err) {
            if (typeof(err.response)!=='undefined'){
                if(err.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
                else if(err.response.data === "New password can not be same with old password") setError(t('Mật khẩu mới không được giống mật khẩu cũ!'))
            }
            else setError(t("Thay đổi mật khẩu không thành công!")) 
            setIsFailedDialog(true);
        }
        setIsDialog(false);
        setIsChanging(false);
    }

    const onBackDialog = () =>{
        setIsDialog(false);
    }
    const onSuccessClick = () =>{
        history.push('/login');
        setIsSuccessDialog(false);
    }
    const onFailedClick = () =>{
        setIsFailedDialog(false);
    }

    const onAlertClose = () =>{
        setAlert({...alert,success:'',err:''});
    }

    useEffect(()=>{
        if(alert.success !=='' || alert.err !== ''){
            setTimeout(() => {
                onAlertClose();
            }, 5000);
        }
    },[alert])
    const getConfirmDialog = () =>{
        return(
            <Dialog 
                open={isDialog}
                maxWidth="xl"
            >
                <DialogTitle className={classes.dialogTitleBackground}>
                    <p className={classes.dialogTitle}>{t("Thông báo")}</p>
                </DialogTitle>
                <DialogContent style={{width:'448px',padding:'0',position:'relative',textAlign:'center'}}>
                    <div className={classes.contentDialog}>
                        <p >{t("Bạn có chắc chắn muốn thay đổi mật khẩu?")}</p>
                    </div>
                    <CancelButton variant="outlined" disabled={isChanging} style={{margin:'10px 0'}} onClick={onBackDialog} >{t("Hủy")}</CancelButton>
                    <CreateButton variant="contained" disabled={isChanging} style={{margin:'10px 16px'}} onClick={onConfirmClick} >{t("Đồng ý")}</CreateButton>

                    {isChanging && <CircularProgress style={{position:'absolute',left:'43%',top:'10%',color:'#0b59c8'}}/>}
                </DialogContent>
                
            </Dialog>
        )
    }

    const getSuccessDialog = ()=>{
        return(
            <Dialog 
                open={isSuccessDialog}
                maxWidth="xl"
            >
                <DialogTitle className={classes.dialogTitleBackground}>
                    <p className={classes.dialogTitle}>{t("Thông báo")}</p>
                </DialogTitle>
                <DialogContent style={{width:'448px',padding:'0',textAlign:'center'}}>
                    <p className={classes.contentDialog}>{t("Thay đổi mật khẩu thành công, vui lòng đăng nhập lại!")}</p>
                    <CancelButton variant="outlined" style={{margin:'10px 0'}} onClick={onSuccessClick}>{t("Đồng ý")}</CancelButton>
                </DialogContent>
            </Dialog>
        )
    }
    const getFailedDialog = ()=>{
        return(
            <Dialog 
                open={isFailedDialog}
                maxWidth="xl"
            >
                <DialogTitle className={classes.errorDialogTitleBackground}>
                    <p className={classes.dialogTitle}>{t("Thông báo")}</p>
                </DialogTitle>
                <DialogContent style={{width:'448px',padding:'0',textAlign:'center'}}>
                    <p className={classes.contentDialog}>{error}</p>
                    <ErrorButton variant="outlined" style={{margin:'10px 0'}} onClick={onFailedClick}>{t("Đồng ý")}</ErrorButton>
                </DialogContent>

            </Dialog>
        )
    }

    return(
        <div className={clsx(classes.focus,classes.content)} style={{position:'relative'}}>       
            {getConfirmDialog()}
            {getSuccessDialog()}
            {getFailedDialog()}
            {isChanging && <div className={classes.overlay}></div>}
            {!loading && <CircularProgress style={{left:'50%',top:'10%',color:'#0b59c8',position:'absolute'}}/>}
            {loading && 
            <div className={classes.font} style={{height:'100%',width:'90%',position:'relative'}}> 
                <SuccessAlert message={alert.success} onCloseDialog={onAlertClose} />
                <ErrorAlert message={alert.err} onCloseDialog={onAlertClose} />
                <h2 className={classes.title}>{t('Tài khoản')}</h2>
                <div style={{display:'flex',height:'50px'}}>
                    <p style={{width:'40%',textAlign:'right',paddingRight:'5%',fontWeight:'bold'}}>{t('Tên người quản trị')}</p>
                    {!isEditName &&<p style={{width:'30%'}}>{account.DisplayName}</p>}
                    {isEditName && <TextField
                        placeholder={t('Tên người quản trị')}
                        variant="outlined"
                        style={{width:'30%',position:'relative',top:'-10px'}}
                        size="small"
                        value={account.DisplayName}
                        onChange={onNameChange}
                        error = {alert.display_name !== ''}
                        helperText={alert.display_name}
                    />}
                    <div style={{display:'flex',width:'25%',marginLeft:'5%'}}>
                        {!isEditName && <p className={classes.primaryColor} style={{width:'100%',cursor:'pointer',textDecoration:'underline'}} onClick={()=>setIsEditName(!isEditName)}>{t('Thay đổi')}</p>}
                        {isEditName && <p className={classes.primaryColor} style={{width:'25%',cursor:'pointer',textDecoration:'underline'}} onClick={onSaveNameClick}>{t('Lưu')}</p>}
                        {isEditName && <p className={classes.primaryColor} style={{width:'25%',cursor:'pointer',textDecoration:'underline'}} onClick={()=>setLoading(false)}>{t('Hủy')}</p>}
                    </div>
                </div>
                <div style={{display:'flex',height:'50px'}}>
                    <p style={{width:'40%',textAlign:'right',paddingRight:'5%',fontWeight:'bold'}}>Email</p>
                    <p style={{width:'60%'}}>{account.Email}</p>
                </div>
                <div style={{display:'flex',height:'50px'}}>
                    <p style={{width:'40%',textAlign:'right',paddingRight:'5%',fontWeight:'bold'}}>{t('Tên đăng nhập')}</p>
                    <p style={{width:'60%'}}>{account.Username}</p>
                </div>
                <div style={{display:'flex',height:'50px'}}>
                    <p style={{width:'40%',textAlign:'right',paddingRight:'5%',fontWeight:'bold'}}>{t('Vai trò')}</p>
                    <p style={{width:'60%'}}>{account.AdminRole}</p>
                </div>
                {!isEdit && <div style={{display:'flex',height:'50px'}}>
                    <p style={{width:'40%',textAlign:'right',paddingRight:'5%',fontWeight:'bold'}}>{t('Mật khẩu')}</p>
                    <p style={{width:'35%'}}>**************************</p>
                    <p className={classes.primaryColor} style={{width:'25%',textDecoration:'underline',cursor:'pointer'}} onClick={onEditClick}>{t('Thay đổi')}</p>
                </div>}
                {isEdit &&
                    <div>
                        <div style={{width:'100%',margin:'auto',height:'90px',display:'flex'}}>
                            <p style={{width:'40%',textAlign:'right',paddingRight:'5%',fontWeight:'bold'}}>{t('Mật khẩu mới')}</p>
                            <div style={{width:'30%'}}>
                                <TextField
                                    InputProps={{
                                        endAdornment: (    
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={showEnterPasswordClick}
                                                >
                                                    {showEnterPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    placeholder={t('Mật khẩu mới')}
                                    variant="outlined"
                                    style={{width:'100%'}}
                                    size="small"
                                    type={showEnterPassword?"text":"password"}
                                    onChange={onPasswordChange}
                                    error = {alert.password !== ''}
                                />
                                {password!=='' && 
                                <PasswordStrengthBar 
                                    scoreWords={['','','','']} 
                                    shortScoreWord='' 
                                    password={password} 
                                />}
                                <p style={{color:'#f44336',paddingTop:'3px',fontSize:'0.75rem',width:'200%'}}>{alert.password}</p>
                            </div>
                        </div>
                        <div style={{width:'100%',margin:'auto',height:'70px',display:'flex'}}>
                            <p style={{width:'40%',textAlign:'right',paddingRight:'5%',fontWeight:'bold'}}>{t('Nhập lại mật khẩu')}</p>
                            <div style={{width:'30%'}}>
                                <TextField
                                    InputProps={{
                                        endAdornment: (    
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={showReEnterPasswordClick}
                                                >
                                                    {showReEnterPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    placeholder={t('Nhập lại mật khẩu')}
                                    variant="outlined"
                                    style={{width:'100%'}}
                                    size="small"
                                    type={showReEnterPassword?"text":"password"}
                                    onChange={onRePasswordChange}
                                    error = {alert.rePassword !== ''}
                                />
                                <p style={{color:'#f44336',paddingTop:'3px',fontSize:'0.75rem'}}>{alert.rePassword}</p>
                            </div>
                        </div>
                        <div style={{display:'flex'}}>
                            <p style={{width:'40%',paddingRight:'5%'}}></p>
                            <CancelButton 
                                style={{margin:'10px 0',width:'80px'}}
                                onClick={onCancelClick} 
                            >
                                {t('Hủy')}
                            </CancelButton>
                            <CreateButton 
                                style={{margin:'10px 0 10px 24px',width:'50px'}}
                                onClick={onSaveClick} 
                            >
                                {t('Lưu')}
                            </CreateButton>
                        </div>
                    </div>
                }
            </div>
            }
        </div>
    )
}

export default MyAccount;