import Button from '@material-ui/core/Button';
import FilterListIcon from '@material-ui/icons/FilterList';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import React from 'react';

export default function FilterButton({ children, ...Props }) {
    const MakeButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);
    return (
        <MakeButton
            {...Props}
            variant="outlined"
            startIcon={<FilterListIcon />}
        >
            {children}
        </MakeButton>
    )
}