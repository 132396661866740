import React, { useEffect, useState, useRef } from 'react';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import { Link, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { getSyncFaceHistories } from '../../../api/device_management/syncFace';
import moment from 'moment-timezone';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Divider from '@mui/material/Divider/Divider';
import clsx from 'clsx';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import withStyles from '@material-ui/core/styles/withStyles';
import LargeTooltip from '../../largeTooltip';

const useStyles = makeStyles((theme) => ({
    inputBlock: {
        height: '60px',
        width: '45%',
        position: 'relative',
        marginRight: '5%'
    },
    label: {
        margin: '0',
        fontSize: '14px',
        lineHeight: '140%',
        color: '#000000',
        display: 'inline-block',
        fontWeight: 'bold'
    },
    input: {
        width: '100%'
    },
    placeholder: theme.place_holder,
    error: {
        color: 'red',
        position: 'absolute',
        bottom: '0',
        left: '20px'
    },
    alert: {
        position: 'absolute',
        left: '3px',
        top: '3px',
        width: '99%'
    },
    requestError: {
        width: '100%',
        height: '65vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20px',
        color: '#6D6D6D'
    },
    secondaryTitle: theme.secondary_title,
    dialogTitle: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '25px',
        color: '#FFFFFF',
    },
    contentDialog: {
        fontSize: '16px',
        height: '68px',
        borderBottom: '1px solid #EEEDF2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    dialogTitleBackground: theme.primaryColor,
    errorColor: theme.errorColor,
    overlay: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        background: 'rgba(0,0,0,0.1)',
        zIndex: '1300'
    },
    select: {
        '& .MuiOutlinedInput-input': {
            padding: '10px 12px'
        }
    },
    focus: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: theme.themeColor,
            },
        },
    },
    primaryColor: theme.primaryTextColor,
    fontSize18: {
        fontSize: '18px'
    },
    reasonStyle: {
        cursor: 'pointer',
        fontStyle: 'italic',
        color: '#0b59c8'
    },
    dividerStyle: {
        width: '100%',
        marginTop: '10px!important',
        marginBottom: '20px!important'
    },
    width100: {
        width: '100%'
    },
    errorText: {
        fontSize: '18px',
        color: '#e01010',
        width: 'auto'
    },
    loadingBlock: {
        width: '100%',
        height: '250px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    circularColor: {
        color: '#0b59c8!important'
    },
    resultText: {
        fontSize: '18px',
        marginBottom: '10px',
        lineHeight: '140%',
        color: '#000000',
        display: 'inline-block',
        fontWeight: 'bold'
    },
    contentBlock: {
        width: '94%',
        margin: 'auto',
        padding: '10px',
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: '30px'
    },
    resultBlock: {
        width: '100%',
        marginLeft: '20px'
    },
    ArrowDropIcon: {
        fontSize: '30px!important',
        position: 'relative',
        top: '-2px',
        cursor: 'pointer'
    },
    iconButtonStyle: {
        float: 'left',
        marginLeft: '30px',
        padding: '2px!important',
        marginRight: '5px'
    },
    arrowBackIcon: {
        width: '28px',
        height: '28px'
    },
    titleBlock: {
        paddingBottom: '8px',
        marginTop: '23px'
    }
}))

const DetailInfo = () => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [syncDetail, setSyncDetail] = useState({ Reasons: [] });
    const [error, setError] = useState();
    const [isExpiredSession, setIsExpiredSession] = useState(false);
    const [idInfo, setIdInfo] = useState({});
    const [details, setDetails] = useState([]);
    const [expands, setExpands] = useState({});
    const dispatch = useDispatch();
    const history = useHistory();
    const expandRef = useRef();

    useEffect(() => {
        if (isExpiredSession) {
            history.push('/login');
            dispatch({ type: 'IS_LOGIN_AGAIN_TRUE' });
        }
    }, [isExpiredSession]);

    const showTriggerBy = (data) => {
        var triggerBy = '';
        switch (data) {
            case undefined: triggerBy = 'Tự động';
                break;
            case 'undefined (undefined)': triggerBy = 'Tự động';
                break;
            default: triggerBy = data;
                break;
        }
        return triggerBy;
    }

    const handleSyncDetail = (result, total) => {
        var data = [];
        const values = Object.values(result);
        const keys = Object.keys(result);
        values.forEach((item, key) => {
            var deviceName;
            var res = [];
            if (item.length === 0) {
                deviceName = keys[key].slice(keys[key].indexOf('(') + 1, keys[key].indexOf(')'));
            } else {
                deviceName = item[0].DeviceName;
                item.forEach(value => {
                    res.push({
                        username: value.Username,
                        reason: value.Reason
                    })
                })
            }
            data.push({
                deviceName: deviceName,
                res: res,
                total: total
            })
        })
        setDetails(data);
    }

    const getDefaultValues = async () => {
        try {
            const data = queryString.parse(history.location.search);
            if (data.id) {
                setIdInfo({ id: data.id, DeviceId: data.DeviceId });
                const res = await getSyncFaceHistories({ requestId: data.id });
                const action = res.data.Items[0].Action === 'ADD' ? 'Thêm khuôn mặt' : res.data.Items[0].Action === 'DELETE' ? 'Xóa khuôn mặt' : 'Cập nhật khuôn mặt';
                var deviceName = '';
                const values = Object.values(res.data.Items[0].Result);
                values[0].forEach(item => {
                    deviceName = item.DeviceName;
                })
                setSyncDetail({
                    ...res.data.Items[0],
                    deviceName: deviceName,
                    SyncResult: `${values[0].length} ${t('khuôn mặt bị lỗi')}`,
                    Reasons: values[0],
                    Action: action,
                    TriggerBy: showTriggerBy(res.data.Items[0].TriggerBy)
                });
                handleSyncDetail(res.data.Items[0].Result, res.data.Items[0].Total)
                setError('');
            } else {
                setError(t('Không lấy được dữ liệu'));
            }
        } catch (err) {
            if (error.response && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setError(t('Không lấy được dữ liệu'));
        }
        setLoading(true);
    }

    useEffect(() => {
        if (!loading) getDefaultValues();
    }, [loading])

    useEffect(() => {
        setLoading(false);
    }, [i18n.language])

    const onDetailClick = (username) => {
        history.replace(`/admin/device-management/sync-face/resident/detail?id=${idInfo.id}&&username=${username}`)
    }

    const onExpandClick = (e) => {
        setExpands({
            ...expands,
            [expandRef.current.id]: true
        })
    }

    const onCloseClick = (e) => {
        setExpands({
            ...expands,
            [expandRef.current.id]: false
        })
    }
    
    const getReason = (reason) => {
        var result = '';
        switch (reason) {
            case 'cannot_download_image': result = t('Không thể tải ảnh đăng ký Face ID');
                break;
            case 'more_than_one_face': result = t('Có hơn 1 khuôn mặt trong khung hình');
                break;
            case 'no_face_recognized': result = t('Không nhận diện được khuôn mặt');
                break;
            case 'face_mask_not_allowed': result = t('Phát hiện đeo khẩu trang');
                break;
            default: result = t('Chưa rõ nguyên nhân');
                break;
        }
        return result;
    }

    const showReasons = (result) => {
        return result.map((value, key) => {
            return (
                <p key={key} className={classes.fontSize18}>
                    <b className={classes.reasonStyle} onClick={() => onDetailClick(value.username)}>{value.username}</b> - {getReason(value.reason)}
                </p>
            )
        })
    }

    const showDeviceResult = () => {
        return details.map((item, key) => {
            if (item.res.length === 0) {
                return (
                    <div key={key} className={classes.width100}>
                        <Divider className={classes.dividerStyle} />
                        <div className={classes.resultBlock}>
                            <div className={classes.inputBlock} style={{ width: '100%' }}>
                                <label className={classes.label}>{t('Thiết bị')}</label><br />
                                <p className={classes.fontSize18}>{item.deviceName}</p>
                            </div>
                            <div className={classes.width100}>
                                <label className={classes.label}>{t('Kết quả đồng bộ')}</label><br />
                                <div className={classes.fontSize18} >{item.total} {t('khuôn mặt đã được đồng bộ thành công')}</div>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div key={key} className={classes.width100}>
                        <Divider className={classes.dividerStyle} />
                        <div className={classes.resultBlock}>
                            <div className={clsx(classes.inputBlock, classes.width100)}>
                                <label className={classes.label}>{t('Thiết bị')}</label><br />
                                <p className={classes.fontSize18}>{item.deviceName}</p>
                            </div>
                            <div ref={expandRef} className={classes.width100} id={'record' + key}>
                                <label className={classes.label}>{t('Kết quả đồng bộ')}</label><br />
                                <div className={classes.errorText} >
                                    {item.res.length} {t('khuôn mặt bị lỗi')}
                                    {
                                        !expands['record' + key] &&
                                        <LargeTooltip title={t('Mở rộng')} >
                                            <ArrowDropDownIcon className={classes.ArrowDropIcon} onClick={onExpandClick} />
                                        </LargeTooltip>
                                    }
                                    {
                                        expands['record' + key] &&
                                        <LargeTooltip title={t('Rút gọn')} >
                                            <ArrowDropUpIcon className={classes.ArrowDropIcon} onClick={onCloseClick} />
                                        </LargeTooltip>
                                    }
                                </div>
                                {
                                    expands['record' + key] &&
                                    <div>{showReasons(item.res)}</div>
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        })
    }

    return (
        <div>
            <div className={classes.titleBlock}>
                <IconButton
                    className={classes.iconButtonStyle}
                    component={Link}
                    to="/admin/device-management/sync-face"
                >
                    <ArrowBackIosOutlinedIcon className={clsx(classes.primaryColor, classes.arrowBackIcon)} />
                </IconButton>
                <p className={classes.secondaryTitle}>{t("Thông tin chi tiết đồng bộ Face ID")}</p>
            </div>
            {
                loading && error !== '' &&
                <div className={classes.requestError}>{error}</div>
            }
            {
                !loading &&
                <div className={classes.loadingBlock}>
                    <CircularProgress className={classes.circularColor} />
                </div>
            }
            {
                loading && error === '' &&
                <div className={classes.contentBlock}>
                    <Divider className={classes.dividerStyle} />
                    <div className={classes.width100}>
                        <label className={classes.resultText} >{t('Thông tin')}</label><br />
                    </div>
                    <div className={classes.inputBlock} style={{ height: '70px' }}>
                        <label className={classes.label}>{t('Thực hiện bởi')}</label><br />
                        <p className={classes.fontSize18}>{t(syncDetail.TriggerBy)}</p>
                    </div>
                    <div className={classes.inputBlock}>
                        <label className={classes.label}>{t('Thời gian')}</label><br />
                        <p className={classes.fontSize18}>{moment(syncDetail.Time).format('DD-MM-YYYY HH:mm:ss')}</p>
                    </div>
                    <Divider className={classes.dividerStyle} />
                    <div >
                        <label className={classes.resultText} >{t('Kết quả')}</label><br />
                    </div>
                    {showDeviceResult()}
                </div>
            }
        </div>
    )
}

export default DetailInfo;
