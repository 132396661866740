import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button/Button';
import TextField from '@material-ui/core/TextField';
import { isEmpty } from '../../../validation/CheckForm';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import SuccessAlert from '../../alert/SuccessAlert';
import ErrorAlert from '../../alert/ErrorAlert';
import Cookies from 'js-cookie';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import { apiGetconfig } from '../../../api/getconfig';
import { apiUpdateconfig } from '../../../api/updateconfig';

const useStyles = makeStyles((theme) => ({
    inputBlock: {
        height: '80px',
        width: '90%',
        position: 'relative',
        margin: 'auto'
    },
    label: {
        margin: '0',
        fontSize: '14px',
        lineHeight: '140%',
        color: '#000000',
        display: 'inline-block'
    },
    input: {
        width: '100%'
    },
    placeholder: theme.place_holder,
    error: {
        color: 'red',
        position: 'absolute',
        bottom: '0',
        left: '20px'
    },
    alert: {
        position: 'absolute',
        left: '3px',
        top: '3px',
        width: '99%'
    },
    requestError: {
        width: '100%',
        height: '442px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20px',
        color: '#6D6D6D'
    },
    secondaryTitle: theme.secondary_title,
    dialogTitle: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '25px',
        color: '#FFFFFF',
    },
    contentDialog: {
        fontSize: '16px',
        height: '68px',
        borderBottom: '1px solid #EEEDF2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    dialogTitleBackground: theme.primaryColor,
    errorColor: theme.errorColor,
    overlay: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        background: 'rgba(0,0,0,0.1)',
        zIndex: '1300'
    },
    select: {
        '& .MuiOutlinedInput-input': {
            padding: '10px 12px'
        }
    },
    focus: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: theme.themeColor,
            },
        },
    },
    primaryColor: theme.primaryTextColor,
    checkbox: {
        '&$checked': {
            color: theme.themeColor,
        }
    },
    checked: {},
    titleStyle: {
        fontSize: '22px',
        fontWeight: 'bold',
        padding: '23px 0 15px 0'
    }
}))

const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);
const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);
const ErrorButton = withStyles(theme => ({ root: theme.error_button }))(Button);

const MomoConfig = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [momo, setMomo] = useState({ test: true, access_code: '', method_name: 'momo', label: '', partner_code: '', secret_key: '' });
    const [alert, setAlert] = useState({ label: '', partner_code: '', access_code: '', secret_key: '', success: '', error: '' });
    const [saveConfirmation, setSaveConfirmation] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const alertRef = useRef();
    const username = 'tsmart-building';

    const getDefaultValues = async () => {
        try {
            let res = await apiGetconfig();
            if (res.data.Items[0].Payment_Configuration) {
                res.data.Items[0].Payment_Configuration.forEach(item => {
                    if (item.access_code && item.label && item.partner_code && item.secret_key && item.test)
                        setMomo({
                            access_code: item.access_code,
                            label: item.label,
                            partner_code: item.partner_code,
                            secret_key: item.secret_key,
                            test: item.test,
                            method_name: 'momo'
                        })
                })
            }
            setAlert({ ...alert, label: '', partner_code: '', access_code: '', secret_key: '' })
        } catch (error) {
            // if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            // setConfigs(configurations);
        }
        setLoading(true);
    }

    useEffect(() => {
        if (!loading) getDefaultValues();
    }, [loading])

    const onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setMomo({ ...momo, [name]: value });
        if (isEmpty(value)) setAlert({ ...alert, [name]: t('Không được để trống') });
        else setAlert({ ...alert, [name]: '' });
    }

    const onTestModeChange = (e) => {
        setMomo({ ...momo, test: e.target.checked });
    }

    const onSaveClick = () => {
        if (isEmpty(momo.label)) return setAlert({ ...alert, label: t('Không được để trống') });
        if (alert.label !== '') return;
        if (isEmpty(momo.partner_code)) return setAlert({ ...alert, partner_code: t('Không được để trống') });
        if (alert.partner_code !== '') return;
        // if (isEmpty(momo.access_code)) return setAlert({ ...alert, access_code: t('Không được để trống') });
        // if (alert.access_code !== '') return;
        // if (isEmpty(momo.secret_key)) return setAlert({ ...alert, secret_key: t('Không được để trống') });
        // if (alert.secret_key !== '') return;
        setSaveConfirmation(true);
    }

    const onSaveConfirmationClick = async () => {
        try {
            setIsUpdating(true);
            const data = {
                username: username,
                payment_configuration: [momo]
            }
            await apiUpdateconfig(data);
            setAlert({ ...alert, success: t('Cấu hình thanh toán đã được lưu!'), error: '' });
            setLoading(false);
            setIsEdit(false);
        } catch (error) {
            setAlert({ ...alert, error: t('Lưu cấu hình thanh toán không thành công, vui lòng thử lại sau!'), success: '' });
        }
        setSaveConfirmation(false);
        setIsUpdating(false);
    }

    const onCloseMessage = () => {
        setAlert({ ...alert, success: '', error: '' });
    }

    useEffect(() => {
        if (alert.success !== '' || alert.error !== '') {
            if (alertRef.current) clearTimeout(alertRef.current);
            alertRef.current = setTimeout(() => {
                onCloseMessage()
            }, 5000);
        }
    }, [alert])

    const saveConfirmationDialog = () => {
        return (
            <Dialog
                open={saveConfirmation}
                maxWidth="xl"
            >
                <DialogTitle className={classes.dialogTitleBackground}>
                    <p className={classes.dialogTitle}>{t("Thông báo")}</p>
                </DialogTitle>
                <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center', position: 'relative' }}>
                    <p className={classes.contentDialog}>{t("Bạn có chắc muốn lưu cấu hình thanh toán?")}</p>
                    <CancelButton
                        style={{ marginLeft: '20px' }}
                        variant="outlined"
                        onClick={() => { setSaveConfirmation(!saveConfirmation) }}
                        disabled={isUpdating}
                    >
                        {t('Trở về')}
                    </CancelButton>
                    <CreateButton
                        variant="contained"
                        style={{ margin: '10px 16px' }}
                        onClick={onSaveConfirmationClick}
                        disabled={isUpdating}
                    >
                        {t('Đồng ý')}
                    </CreateButton>
                    {isUpdating && <CircularProgress style={{ position: 'absolute', left: '45%', top: '10%', color: '#0b59c8' }} />}
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <div style={{ width: '98%', margin: 'auto' }}>
            {saveConfirmationDialog()}
            <SuccessAlert message={alert.success} onCloseDialog={onCloseMessage} />
            <ErrorAlert message={alert.error} onCloseDialog={onCloseMessage} />
            <p className={classes.titleStyle}>{t('Cấu hình thanh toán')}</p>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div style={{ width: '50%' }}>
                    <div style={{ width: '96%', margin: 'auto', background: '#fafafa', borderRadius: '6px' }}>
                        {!loading && <div className={classes.requestError}><CircularProgress style={{ color: '#0b59c8' }} /> </div>}
                        {loading && <div>
                            <p style={{ fontSize: '16px', fontWeight: 'bold', padding: '20px 0', width: '90%', margin: 'auto' }}>{t('Thanh toán trực tuyến qua ví MOMO')}</p>
                            <div className={classes.inputBlock}>
                                <label className={classes.label}>{t('Tên phương thức thanh toán')} <span style={{ color: 'red' }}>(*)</span></label><br />
                                <TextField
                                    classes={{
                                        root: classes.input
                                    }}
                                    inputProps={{
                                        autoComplete: 'off'
                                    }}
                                    style={{ background: '#FFFFFF' }}
                                    name="label"
                                    size="small"
                                    placeholder={t("Tên phương thức thanh toán")}
                                    variant="outlined"
                                    disabled={!isEdit}
                                    value={momo.label}
                                    onChange={onChange}
                                    error={alert.label !== ''}
                                />
                                <p style={{ color: 'red', fontSize: '13px', paddingTop: '4px' }}>{alert.label}</p>
                            </div>
                            <div className={classes.inputBlock}>
                                <label className={classes.label}>{t('Partner Code')} <span style={{ color: 'red' }}>(*)</span></label><br />
                                <TextField
                                    classes={{
                                        root: classes.input
                                    }}
                                    inputProps={{
                                        autoComplete: 'off'
                                    }}
                                    style={{ background: '#FFFFFF' }}
                                    name="partner_code"
                                    size="small"
                                    placeholder={t("Partner Code")}
                                    variant="outlined"
                                    disabled={!isEdit}
                                    value={momo.partner_code}
                                    onChange={onChange}
                                    error={alert.partner_code !== ''}
                                />
                                <p style={{ color: 'red', fontSize: '13px', paddingTop: '4px' }}>{alert.partner_code}</p>
                            </div>
                            {/* <div className={classes.inputBlock}>
                                <label className={classes.label}>{t('Access Code / Password')} <span style={{ color: 'red' }}>(*)</span></label><br />
                                <TextField
                                    classes={{
                                        root: classes.input
                                    }}
                                    inputProps={{
                                        autoComplete: 'off'
                                    }}
                                    style={{ background: '#FFFFFF' }}
                                    name="access_code"
                                    size="small"
                                    placeholder={t("Access Code/ Password")}
                                    variant="outlined"
                                    disabled={!isEdit}
                                    value={momo.access_code}
                                    onChange={onChange}
                                    error={alert.access_code !== ''}
                                />
                                <p style={{ color: 'red', fontSize: '13px', paddingTop: '4px' }}>{alert.access_code}</p>
                            </div>
                            <div className={classes.inputBlock} style={{ height: '70px' }}>
                                <label className={classes.label}>{t('Secret Key')} <span style={{ color: 'red' }}>(*)</span></label><br />
                                <TextField
                                    classes={{
                                        root: classes.input
                                    }}
                                    inputProps={{
                                        autoComplete: 'off'
                                    }}
                                    style={{ background: '#FFFFFF' }}
                                    name="secret_key"
                                    size="small"
                                    type="password"
                                    placeholder={t("Secret Key")}
                                    variant="outlined"
                                    disabled={!isEdit}
                                    value={momo.secret_key}
                                    onChange={onChange}
                                    error={alert.secret_key !== ''}
                                />
                                <p style={{ color: 'red', fontSize: '13px', paddingTop: '4px' }}>{alert.secret_key}</p>
                            </div> */}
                            <div className={classes.inputBlock} style={{ height: '30px' }}>
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        classes={{ label: classes.controlLabel }}
                                        control={
                                            <Checkbox color="primary"
                                                onChange={onTestModeChange}
                                                checked={momo.test}
                                                classes={{ root: classes.checkbox, checked: classes.checked }}
                                                disabled={!isEdit}
                                            />}
                                        label={t('Môi trường test')}
                                        labelPlacement="end"
                                    />
                                </FormGroup>
                            </div>
                            {isEdit && <div className={classes.inputBlock} style={{ height: '60px', paddingTop: '10px' }}>
                                <CreateButton
                                    variant="contained"
                                    style={{ margin: '0' }}
                                    onClick={onSaveClick}
                                >
                                    {t('Lưu')}
                                </CreateButton>
                                <CancelButton
                                    variant="outlined"
                                    style={{ margin: '0 16px' }}
                                    onClick={() => {
                                        setIsEdit(false);
                                        setLoading(false);
                                    }}
                                >
                                    {t('Hủy')}
                                </CancelButton>
                            </div>}
                            {!isEdit && <div className={classes.inputBlock} style={{ height: '60px', paddingTop: '10px' }}>
                                <CreateButton
                                    variant="contained"
                                    style={{ margin: '0' }}
                                    onClick={() => setIsEdit(true)}
                                >
                                    {t('Thêm/Cập nhật')}
                                </CreateButton>
                            </div>}
                        </div>}
                    </div>
                </div>
                <div style={{ width: '50%' }}>
                    <div style={{ width: '95%', margin: 'auto' }}>
                        <p style={{ fontWeight: 'bold', fontSize: '16px', padding: '10px 0' }}>{t('Hướng dẫn cấu hình MOMO')}</p>
                        <ul style={{ fontSize: '14px', padding: '0' }}>{t('Để sử dụng MOMO bạn cần')}
                            <ol>
                                <li style={{ paddingTop: '5px' }}>{t('Đăng ký dịch vụ với MOMO')}</li>
                                <li style={{ paddingTop: '5px' }}>{t('Sau khi hoàn tất các bước đăng ký tại MOMO, bạn sẽ có các thông số Partner Code, Access Key, Secret Key')}</li>
                                <li style={{ paddingTop: '5px' }}>{t('Nhập các thông số Partner Code, Access Key, Secret Key vào ô bên phải')}</li>
                            </ol>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MomoConfig;