import AxiosClient from "../config/AxiosClient";

export const apiTommiAdd = async (requestBody) => {
    var url = '/tommi/add';
    return await AxiosClient.post(url, requestBody);
}

export const apiTommiUpdate = async (requestBody) => {
    var url = '/tommi/update';
    return await AxiosClient.post(url, requestBody);
}

export const apiTommiSync = async (requestBody) => {
    var url = '/tommi/sync';
    return await AxiosClient.post(url, requestBody);
}

export const apiTommiTest = async (requestBody) => {
    var url = '/tommi/test';
    return await AxiosClient.post(url, requestBody);
}

export const apiTommiOpen = async (requestBody) => {
    var url = '/tommi/open';
    return await AxiosClient.post(url, requestBody);
}

export const apiTommiExist = async (requestBody) => {
    var url = '/tommi/exist';
    return await AxiosClient.post(url, requestBody);
}

export const apiTommiPing = async (requestBody) => {
    var url = '/tommi/ping';
    return await AxiosClient.post(url, requestBody);
}