import React, { useState, useRef, useEffect } from 'react';
import { isEmpty, checkUsername, checkDisplayName, checkBuilding, checkPassword } from '../../../validation/CheckForm';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import PasswordStrengthBar from 'react-password-strength-bar';
import Cookies from 'js-cookie';
import zxcvbn from 'zxcvbn';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiReceptionistExist } from '../../../api/receptionist';

const useStyles = makeStyles((theme) => ({
    inputBlock: {
        height: '110px',
        width: '19%',
        margin: 'auto',
        position: 'relative',
        float: 'left'
    },
    label: {
        margin: '0',
        fontSize: '14px',
        lineHeight: '140%',
        color: '#000000',
        display: 'inline-block'
    },
    input: {
        width: '100%'
    },
    placeholder: {
        color: '#B2B2B2',
        fontSize: '12px'
    },
    error: {
        color: 'red',
        position: 'absolute',
        bottom: '0',
        left: '20px'
    },
    alert: {
        position: 'absolute',
        left: '3px',
        top: '3px',
        width: '99%'
    },
    focus: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: theme.themeColor,
            },
        },
    },
}))

const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);

const AddReceptionist = (props) => {
    const { blocks, addDataToDB, isAdding } = props;
    const classes = useStyles();
    const [receptionist, setReceptionist] = useState({ name: '', username: '', phone: '', password: '', block: '' });
    const [alert, setAlert] = useState({ name: '', username: '', password: '', phone: '', block: '' });
    const [isExpiredSession, setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const usernameRef = useRef();
    const phoneRef = useRef();
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);
    const passwordRef = useRef();

    useEffect(() => {
        if (isExpiredSession) {
            history.push('/login');
            dispatch({ type: 'IS_LOGIN_AGAIN_TRUE' });
        }
    }, [isExpiredSession]);

    const onNameChange = (e) => {
        const name = e.target.value;
        setReceptionist({ ...receptionist, name: name });
        if (isEmpty(name)) {
            setAlert({ ...alert, name: t('Không được để trống') });
        } else if (!checkDisplayName(name)) {
            setAlert({ ...alert, name: t('Không được có ký tự đặc biệt') });
        } else if (name.trim().length < 6 || name.trim().length > 30) {
            setAlert({ ...alert, name: t('Độ dài ký tự trong khoảng từ 6 đến 30') });
        } else {
            setAlert({ ...alert, name: '' });
        }
    }

    const onUsernameChange = (e) => {
        const username = e.target.value.trim();
        setReceptionist({ ...receptionist, username: username });
        if (isEmpty(username)) {
            setAlert({ ...alert, username: t('Không được để trống') });
        } else if (!checkUsername(username)) {
            setAlert({ ...alert, username: t('Không được có ký tự đặc biệt') });
        } else if (username.trim().length < 6 || username.trim().length > 24) {
            setAlert({ ...alert, username: t('Độ dài ký tự trong khoảng từ 6 đến 24') });
        } else {
            setAlert({ ...alert, username: '' });
            if (usernameRef.current) clearTimeout(usernameRef.current);
            usernameRef.current = setTimeout(() => {
                checkExistedValues('username', username);
            }, 300);
        }

    }

    const onPasswordChange = (e) => {
        const password = e.target.value.trim();
        setReceptionist({ ...receptionist, password: password });
        if (isEmpty(password)) {
            setAlert({ ...alert, password: t('Không được để trống') });
        } else if (!checkPassword(password)) {
            setAlert({ ...alert, password: t('Tối thiểu 8 ký tự bao gồm chữ HOA, chữ thường, số và ký tự đặc biệt') });
        } else {
            setAlert({ ...alert, password: '' });
            if (passwordRef.current) clearTimeout(passwordRef.current);
            passwordRef.current = setTimeout(() => {
                const result = zxcvbn(password);
                if (result.feedback.warning !== '') setAlert({ ...alert, password: t('Đã trùng mật khẩu phổ biến') });
            }, 300);
        }
    }

    const checkExistedValues = async (name, value) => {
        try {
            const data = { [name]: value };
            var res = await apiReceptionistExist(data);
            if (res.data === 'Found') {
                if (name === 'phone') {
                    setAlert({
                        ...alert,
                        phone: t('Số điện thoại đã tồn tại')
                    });
                } else {
                    setAlert({
                        ...alert,
                        username: t('Tên đăng nhập đã tồn tại')
                    });
                }

            }
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            if (name === 'phone') {
                setAlert({
                    ...alert,
                    phone: t('Số điện thoại đã tồn tại')
                });
            } else {
                setAlert({
                    ...alert,
                    username: t('Tên đăng nhập đã tồn tại')
                });
            }
        }
    }

    const onPhoneChange = (e) => {
        const phone = e.target.value;
        setReceptionist({ ...receptionist, phone: phone });
        if (isEmpty(phone)) {
            setAlert({ ...alert, phone: t('Không được để trống') });
        } else {
            setAlert({ ...alert, phone: '' });
            if (phoneRef.current) clearTimeout(phoneRef.current);
            phoneRef.current = setTimeout(() => {
                checkExistedValues('phone', phone);
            }, 300);
        }
    }

    const onBlockChange = (e, newValue) => {
        if (newValue === null) {
            setAlert({ ...alert, block: t('Không được để trống') });
            setReceptionist({ ...receptionist, block: '' });
            return;
        }
        setAlert({ ...alert, block: "" });
        setReceptionist({ ...receptionist, block: newValue });
    }

    const onAddClick = () => {
        if (isEmpty(receptionist.name)) return setAlert({ ...alert, name: t('Không được để trống') });
        if (alert.name !== '') return;
        if (isEmpty(receptionist.username)) return setAlert({ ...alert, username: t('Không được để trống') });
        if (!checkBuilding(receptionist.username)) return setAlert({ ...alert, username: t('Không được có ký tự đặc biệt') });
        if (alert.username !== '') return;
        if (isEmpty(receptionist.password)) return setAlert({ ...alert, password: t('Không được để trống') });
        if (alert.password !== '') return;
        if (isEmpty(receptionist.phone)) return setAlert({ ...alert, phone: t('Không được để trống') });
        if (alert.phone !== '') return;
        if (isEmpty(receptionist.block)) return setAlert({ ...alert, block: t('Không được để trống') });
        addDataToDB(receptionist);
    }

    return (
        <Paper className={clsx(classes.focus)} style={{ height: '190px', marginTop: '10px', background: '#FAFAFA' }}>
            <div style={{ fontSize: '24px', padding: '16px' }}>
                <div className={classes.inputBlock} style={{ marginRight: '1%' }}>
                    <label className={classes.label} style={{ paddingBottom: '16px' }}>{t('Tên lễ tân')} <span style={{ color: 'red' }}>(*)</span></label><br />
                    <TextField
                        classes={{
                            root: classes.input
                        }}
                        inputProps={{
                            autoComplete: 'off'
                        }}
                        style={{ background: '#FFFFFF' }}
                        size="small"
                        placeholder={t("Tên lễ tân")}
                        variant="outlined"
                        onChange={onNameChange}
                        error={alert.name !== ''}
                    />
                    <p style={{ color: '#f44336', fontSize: '0.75rem', paddingTop: '5px' }}>{alert.name}</p>
                </div>
                <div className={classes.inputBlock} style={{ marginRight: '1%' }}>
                    <label className={classes.label} style={{ paddingBottom: '16px' }}>{t('Tên đăng nhập')} <span style={{ color: 'red' }}>(*)</span></label><br />
                    <TextField
                        classes={{
                            root: classes.input
                        }}
                        inputProps={{
                            autoComplete: 'new-username'
                        }}
                        style={{ background: '#FFFFFF' }}
                        size="small"
                        placeholder={t("Tên đăng nhập")}
                        variant="outlined"
                        onChange={onUsernameChange}
                        error={alert.username !== ''}
                    />
                    <p style={{ color: '#f44336', fontSize: '0.75rem', paddingTop: '5px' }}>{alert.username}</p>
                </div>
                <div className={classes.inputBlock} style={{ marginRight: '1%' }}>
                    <label className={classes.label} style={{ paddingBottom: '16px' }}>{t('Mật khẩu')} <span style={{ color: 'red' }}>(*)</span></label><br />
                    <TextField
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        inputProps={{
                            autoComplete: 'new-password'
                        }}
                        size="small"
                        style={{ background: '#FFFFFF' }}
                        type={showPassword ? "text" : "password"}
                        placeholder={t("Mật khẩu")}
                        variant="outlined"
                        onChange={onPasswordChange}
                        error={alert.password !== ''}
                    />
                    {receptionist.password !== '' &&
                        <PasswordStrengthBar
                            scoreWords={['', '', '', '']}
                            shortScoreWord=''
                            password={receptionist.password}
                        />}
                    <p style={{ color: '#f44336', fontSize: '0.75rem', paddingTop: '5px' }}>{alert.password}</p>
                </div>
                <div className={classes.inputBlock} style={{ marginRight: '1%' }}>
                    <label className={classes.label} style={{ paddingBottom: '16px' }}>{t('Số điện thoại')} <span style={{ color: 'red' }}>(*)</span></label><br />
                    <TextField
                        classes={{
                            root: classes.input
                        }}
                        inputProps={{
                            autoComplete: 'off'
                        }}
                        size="small"
                        style={{ background: '#FFFFFF' }}
                        placeholder={t("Số điện thoại")}
                        variant="outlined"
                        onChange={onPhoneChange}
                        error={alert.phone !== ''}
                    />
                    <p style={{ color: '#f44336', fontSize: '0.75rem', paddingTop: '5px' }}>{alert.phone}</p>
                </div>
                <div className={classes.inputBlock} style={{ marginRight: '1%' }}>
                    <label className={classes.label} >Block <span style={{ color: 'red' }}>(*)</span></label>
                    <Autocomplete
                        style={{ position: 'relative', top: '-3px' }}
                        size="small"
                        clearText={t("Xóa")}
                        noOptionsText={t("Không có dữ liệu")}
                        openText={t("Mở")}
                        options={blocks}
                        value={receptionist.block}
                        getOptionSelected={(option, value) => {
                            return true;
                        }}
                        renderInput={(params) => (
                            <TextField {...params} style={{ background: '#FFFFFF' }} placeholder="Block" margin="normal" variant="outlined" error={alert.block !== ''} />
                        )}
                        onChange={(e, newValue) => onBlockChange(e, newValue)}
                    />
                    <p style={{ color: '#f44336', fontSize: '0.75rem', position: 'absolute', bottom: '2px' }}>{alert.block}</p>
                </div>
                <div style={{ width: '100%', textAlign: 'right', paddingRight: '1%' }}>
                    <CreateButton
                        variant="contained"
                        onClick={onAddClick}
                        disabled={isAdding}
                    >
                        {t("Thêm")}
                        {isAdding && <CircularProgress size={25} style={{ color: '#0b59c8', position: 'absolute' }} />}
                    </CreateButton>
                </div>
            </div>
        </Paper>
    )
}

export default AddReceptionist;
