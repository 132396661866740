import AxiosClient from "../config/AxiosClient";

export const securitycameraDeviceCreate = (body) => {
    /*
        body = {
            camera_name: "",
            rtsp_link: "",
            camera_note: "",
            block: "",
            location: "",
            thumbnail: "",
            access_permission: [{
                block: "",
                floor: ""
            }]
        }
    */
    const url = "/securitycamera/device/create";
    return AxiosClient.post(url, body);
}

export const securitycameraDeviceGet = (body) => {
    /**
        Items: [{
            AccessPermission,
            BlockNumber,
            CameraNote,
            CameraFunction,
            CameraLocation,
            CameraName,
            ID,
            RtspLink,
            Thumbnail
        }]
     */

    /*
    const body = {
        id,
        offset,
        limit,
        location, 
        camera_name, 
        block
    }
    */
    const url = "/securitycamera/device";
    return AxiosClient.get(url, { params: body });
}

export const securitycameraDeviceDelete = (body) => {
    /*
        body = {
            ids: [],
            thumbnails: []
        }
    */
    const url = "/securitycamera/device/delete";
    return AxiosClient.delete(url, { data: body });
}

export const securitycameraDeviceUpdate = (body) => {
    /*
        body = {
            id: "",
            field need to update
        }
    */
    const url = "/securitycamera/device/update";
    return AxiosClient.put(url, body);
}