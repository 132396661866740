import makeStyles from "@material-ui/core/styles/makeStyles";

export const PrimaryTitle = ({ children, ...Props }) => {
    const useStyles = makeStyles(them => ({
        primaryTitle: them.primary_title
    }))
    const classes = useStyles();

    return (
        <p
            className={classes.primaryTitle}
            style={{
                float: "unset"
            }}
            {...Props}
        >
            {children}
        </p>
    )
}