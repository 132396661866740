import { CreateButton } from "../index";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export const AddButton = ({ children, ...Props }) => {
    return (
        <CreateButton
            {...Props}
        >
            <div style={{
                display: "flex",
                alignItems: "center"
            }}>
                <AddCircleOutlineIcon
                    fontSize="small"
                    style={{
                        marginRight: "8px"
                    }}
                />
                <span>{children}</span>
            </div>
        </CreateButton>
    )
}