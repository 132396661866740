import React,{useState,useEffect} from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Button from '@material-ui/core/Button/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/styles/withStyles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import LargeTooltip from '../../largeTooltip';

const useStyles = makeStyles((theme)=>({
    inputBlock:{height:'75px',width:'25%',margin:'auto',position:'relative',float:'left'},
    label:{margin:'0',fontSize: '14px',lineHeight: '140%',color: '#000000',display: 'inline-block'},
    input:{width: '100%'},
    placeholder:{color:'#B2B2B2',fontSize:'12px'},
    focus:{
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': { 
                borderColor: theme.themeColor,
            },
        },
    },
}))

const CreateButton = withStyles(theme =>({root:theme.create_button}))(Button);
const CancelButton = withStyles(theme =>({root:theme.cancel_button}))(Button);

const QuicklyAddRooms = props => {
    const classes = useStyles();
    const {changeStatusAddClick,apartmentData,addRoomsToDB} = props;
    const [roomStr, setRoomStr] = useState('');
    const [block,setBlock] = useState('');
    const [floorStr,setFloorStr] = useState('');
    const [blocks,setBlocks] = useState([]);
    const [floors,setFloors] = useState([]);
    const [message,setMessage] = useState({block:'',floor:'',room:'',})
    const {t} = useTranslation();

    const getDefaultValues = async()=>{
        var blockArr = [];
        apartmentData.forEach(item=>{
            blockArr.push(item.BlockID);
        })
        blockArr = [...new Set(blockArr)];
        setBlocks(blockArr);
        setBlock('');
        setFloorStr('');
        setRoomStr('');
    }
    useEffect(() => {
        getDefaultValues();
    }, [])

    useEffect(() => {
        var data = apartmentData.filter(item=>item.BlockID === block);
        var floorArr = [];
        data.forEach(item=>{
            floorArr.push(item.FloorID+'');
        })
        floorArr = [...new Set(floorArr)];
        setFloors(floorArr);
    }, [block])

    const onListRoomChange = (e) =>{
        const value = e.target.value;
        setRoomStr(value);
        if(value === ''){
            setMessage({...message,room:t('Không được để trống')});
        }else{
            setMessage({...message,room:''});
        }
    }

    const handleRoomInput = (str) =>{
        const arr = str.split(',');
        var list = [];
        arr.forEach(item=>{
            if(item.trim().indexOf('-')!== -1){
                const arr1 = item.split('-');
                for(var i = parseInt(arr1[0]); i<= parseInt(arr1[1]);i++){
                    if(i < 10) list.push('0'+i);
                    else list.push(i+'');
                }
            }else{
                if(!isNaN(Number(item))) list.push('0'+parseInt(item));
                else list.push(item);
            }
        })
        return list;
    }
    const handleFloorInput = (str) =>{
        const arr = str.split(',');
        var list = [];
        arr.forEach(item=>{
            if(item.trim().indexOf('-')!== -1){
                const arr1 = item.split('-');
                if(parseInt(arr1[0])+''===arr1[0]){
                    for(var i = parseInt(arr1[0]); i<= parseInt(arr1[1]);i++){
                        list.push(i+'');    
                    }
                }else{
                    for(var i = parseInt(arr1[0]); i<= parseInt(arr1[1]);i++){
                        if(i < 10) list.push('0'+i);
                        else list.push(i+'');    
                    }
                } 
            }else{
                const re = /^\d+[a-zA-Z]{1}$/;
                if(re.test(item)) list.push(item);
                else if(!isNaN(Number(item))) list.push(item);
            }
        })
        return list;
    }

    const onBlockChange = (e, newValue) =>{
        if(newValue === null){
            setMessage({...message,block:t("Không được để trống")});
            setBlock('');
            return;
        }
        setMessage({...message,block:""});
        setBlock(newValue);
    }
    const onBlockInput = (e,newValue)=>{
        var value = e.target.value;
        if(value === 0) return;
        setBlock(value);
        if(typeof(value) === 'undefined' || value === ''){
            setMessage({...message,block:t("Không được để trống")});
        }else{
            setMessage({...message,block:""});
        }   
    }

    const onFloorChange = (e,newValue)=>{
        if(newValue === null){
            setMessage({...message,floor:t("Không được để trống")});
            setFloorStr('');
            return;
        }
        setMessage({...message,floor:""});
        setFloorStr(newValue);
    }
    const onFloorInput = (e,newValue)=>{
        var value = e.target.value;
        if(value === 0) return;
        setFloorStr(value);
        if(typeof(value) === 'undefined' || value === ''){
            setMessage({...message,floor:t("Không được để trống")});
        }else{
            setMessage({...message,floor:""});
        }   
        
    }

    const onAddClick = () =>{
        if(block === '') return setMessage({...message,block:t('Không được để trống')});
        if(floorStr === '') return setMessage({...message,floor:t('Không được để trống')});
        if(roomStr === '') return setMessage({...message,room:t('Không được để trống')});
        const roomList = handleRoomInput(roomStr);
        const floorList = handleFloorInput(floorStr);
        const data = hanldeDataToJsonFormat(roomList,floorList);
        addRoomsToDB(data);
    }
    const hanldeDataToJsonFormat = (rooms,floorList) =>{
        const handleRooms = [];
        const handleFloors = [] 
        rooms.forEach(item=>{
            handleRooms.push({room_name:item});
        })       
        floorList.forEach(item=>{
            handleFloors.push({floor_name:item,rooms:handleRooms});
        })
        return {
            blocks: [
                {
                    block_name:block,
                    floors:handleFloors
                }
            ]
        }
    }

    return (            
            <Paper className={clsx(classes.focus)} style={{background:'#FAFAFA',width:'100%',height:'90px'}}>
                <div className={classes.inputBlock} style={{marginRight:'1%',marginLeft:'1%'}}>
                    <label className={classes.label}>Block <span style={{color:'red'}}>(*)</span></label>          
                    <Autocomplete
                        classes={{
                            root:classes.input
                        }}
                        style={{position:'relative',top:'-16px'}}
                        size="small" 
                        clearText={t("Xóa")}
                        noOptionsText={t("Không có dữ liệu")}
                        openText={t("Mở")}
                        options={blocks}
                        inputValue ={block}
                        renderInput={(params) => (
                            <TextField {...params} style={{background:'#FFFFFF'}} placeholder="Block" margin="normal" variant="outlined" />
                        )}
                        onChange={(e,newValue)=>onBlockChange(e,newValue)}
                        onInputChange={(e,newValue)=>onBlockInput(e,newValue)}
                    />
                    <p style={{color:'red',position:'absolute',bottom:'-10px',left:'0'}}>{message.block}</p>
                </div>
                <div className={classes.inputBlock} style={{marginRight:'1%'}}>
                    <label className={classes.label}>{t('Tầng')} <span style={{color:'red'}}>(*)</span></label><br />
                    <LargeTooltip title={t("Có thể thêm nhiều tầng theo mẫu sau 1,3-10,12A")} placement="top">
                        <Autocomplete
                            classes={{
                                root:classes.input
                            }}
                            style={{position:'relative',top:'-16px'}}
                            size="small" 
                            clearText={t("Xóa")}
                            noOptionsText={t("Không có dữ liệu")}
                            openText={t("Mở")}
                            options={floors}
                            inputValue ={floorStr}
                            renderInput={(params) => (
                                <TextField {...params} style={{background:'#FFFFFF'}} placeholder={t("Chọn/Nhập số tầng" )}margin="normal" variant="outlined" />
                            )}
                            onChange={(e,newValue)=>onFloorChange(e,newValue)}
                            onInputChange={(e,newValue)=>onFloorInput(e,newValue)}
                        />
                    </LargeTooltip>
                    <p style={{color:'red',position:'absolute',bottom:'-10px',left:'0'}}>{message.floor}</p>
                </div>
                <div className={classes.inputBlock} style={{marginRight:'1%'}}>
                    <label className={classes.label}>{t('Phòng')} <span style={{color:'red'}}>(*)</span></label><br />
                    <LargeTooltip title={t('"Có thể thêm nhiều phòng theo mẫu sau 1,3-10,12"')} placement="top">
                        <TextField 
                            classes={{
                                root:classes.input
                            }}
                            value={roomStr}
                            size="small"
                            style={{background:'#FFFFFF'}}
                            placeholder={t("Nhập số phòng")} 
                            variant="outlined" 
                            onChange={onListRoomChange}
                        />
                    </LargeTooltip>
                    <p style={{color:'red',position:'absolute',bottom:'-10px',left:'0'}}>{message.room}</p>
                </div>
                <div className={classes.inputBlock} style={{minWidth:'200px',width:'20%'}}>
                    <CreateButton variant="contained" onClick={onAddClick} style={{}}>{t('Thêm')} </CreateButton>
                    <CancelButton variant="outlined" style={{margin:'19px 10px'}} onClick={changeStatusAddClick}>{t('Hủy')}</CancelButton>
                </div>
            </Paper>
    )
}


export default QuicklyAddRooms;
