import React,{useState,useEffect,useRef} from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography/Typography';
import TextField from '@material-ui/core/TextField/TextField';
import Button from '@material-ui/core/Button/Button';
import { useHistory, Link} from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import { Auth } from 'aws-amplify';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import {isEmpty, checkPassword} from '../../validation/CheckForm';
import axiosClient from '../../config/AxiosClient';
import md5 from 'md5';
import { loginStyles } from '../../styles/style';
import Checkbox from '@material-ui/core/Checkbox';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useMediaQuery } from 'react-responsive'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AWS from 'aws-sdk';
import {useTranslation} from 'react-i18next';
import PasswordStrengthBar from 'react-password-strength-bar';
import Cookies from 'js-cookie';
import zxcvbn from 'zxcvbn';
import clsx from 'clsx';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import {getToken,messageListioner} from '../../config/FirebaseInit';
import { sendNotificationToken } from '../../api/device_management/syncFace';
import CryptoJS from 'crypto-js';

AWS.config.update({
    region:'ap-southeast-1',
    accessKeyId:process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey:process.env.REACT_APP_SECRET_ACCESS_KEY
});

const CreateButton = withStyles(theme =>({root:theme.create_button}))(Button);

const Login = props => {
    const classes = loginStyles();    
    const [account,setAccount] = useState({username:'',password:'',code:''});
    const [message,setMessage] = useState('');
    const [email,setEmail] = useState('');
    const [err,setErr] = useState({
        username:'',
        password:'',
        enterPassword:'',
        reEnterPassword:''
    })
    const [alert,setAlert] = useState({code:''})
    const [checking,setChecking] = useState(false);
    const [isLogin,setIsLogin] = useState(true);
    const [isConfirmForm,setIsConfirmForm] = useState(false);
    const [isChangePassword,setIsChangePassword] = useState(false);
    const [isRemeber,setIsRemember] = useState(false);
    const [showPassword,setShowPassword] = useState(false);
    const [showEnterPassword,setShowEnterPassword] = useState(false);
    const [showReEnterPassword,setShowReEnterPassword] = useState(false);
    const [isMessage,setIsMessage] = useState(false);
    const [isLoginAgain,setIsLoginAgain] = useState(false);
    const [firstLogin,setFirstLogin] = useState(false);
    const [isActive,setIsActive] = useState(false);
    const [enterPassword,setEnterPassword] = useState('');
    const [reEnterPassword,setReEnterPassword] = useState('');
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const isDesktopOrLaptop  = useMediaQuery({ query: '(min-width: 1224px)' });
    const loginState = useSelector(state => state.sessionState);
    const dispatch = useDispatch();
    const history = useHistory();
    const {t} = useTranslation();
    const passwordRef = useRef();
    const rePasswordRef = useRef();
    const [isDialog,setIsDialog] = useState(false);
    const [firebaseToken,setFirebaseToken] = useState('');
    
    const getFirebaseToken = async() =>{
        try {
            const res = await getToken();
            setFirebaseToken(res);
        } catch (error) {
            console.log('Have an error when getting message token: ',error);
        }
    }
    
    useEffect(() => {
        getFirebaseToken();
        localStorage.removeItem('role');
        localStorage.removeItem('fullname');
        
        Cookies.remove('token',{path:''});
        localStorage.removeItem('username');
        if(localStorage.getItem('remember')==='true'){
            setIsRemember(true);
            var bytes = CryptoJS.AES.decrypt(localStorage.getItem('login_password'),process.env.REACT_APP_PASSWORD_SECRET_KEY);
            setAccount({...account,username:localStorage.getItem('login_username'),password:bytes.toString(CryptoJS.enc.Utf8)});
        }else{
            setIsRemember(false);
            setAccount({...account,username:'',password:''});
        }     
    }, []);

    useEffect(()=>{
        if(account.username !== ''){
            var email = account.username;
            let hide = email.split("@")[0].length - 2;
            var r = new RegExp(".{"+hide+"}@", "g");
            setEmail(email.replace(r, "*****@" ));
        }
    },[account.username])

    useEffect(()=>{
        setMessage('');
        setShowPassword(false);
    },[isLogin])

    const rememberPassword = (username,password) =>{
        if(isRemeber){
            localStorage.setItem('remember',true);
            localStorage.setItem('login_username',username);
            localStorage.setItem('login_password',CryptoJS.AES.encrypt(password,process.env.REACT_APP_PASSWORD_SECRET_KEY).toString());
        }else{
            localStorage.setItem('remember',false);
            localStorage.setItem('login_username','');
            localStorage.setItem('login_password','');
        }
    }

    const signIn = async(username,password) =>{
        var res;
        
        if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
            try {
                setChecking(true);
                res = await axiosClient.post('/cognito/login',{'username':username,'password':md5(password)});
                if(typeof(res.data.code)!=='undefined'){
                    if(res.data.code==='UserNotConfirmedException'){
                        setIsConfirmForm(true);
                        setIsLogin(false);
                        setIsChangePassword(false);
                        setIsActive(false);
                    }else if(res.data.code==='PasswordResetRequiredException'){
                        setIsConfirmForm(true);
                        setIsLogin(false);
                        setIsChangePassword(false);
                        setIsActive(true);
                    }else{
                        if(res.data.message==='Incorrect username or password.'){
                            setMessage(t('Mật khẩu không chính xác'));
                        }else if(res.data.message==='New password is required'){
                            setIsChangePassword(true);
                            setIsConfirmForm(false);
                            setIsLogin(false);
                            setFirstLogin(true);
                        }else{
                            setMessage(t('Tài khoản đã bị khóa')); 
                            setIsMessage(true); 
                        }              
                    }   
                    setChecking(false);        
                }else{
                    if(typeof(res.data.role)==='undefined'){
                        setIsChangePassword(true);
                        setIsConfirmForm(false);
                        setIsLogin(false);
                        setChecking(false);
                    }else{
                        localStorage.setItem('fullname',res.data.displayName);
                        localStorage.setItem('rowsPerPage',10);
                        Cookies.set('token',res.data.data.idToken.jwtToken,{secure:true,sameSite:'strict'});
                        localStorage.setItem('username',username);
                        const status = await axiosClient.get('/table/import/status',{
                            headers:{'Authorization': `Bearer ${res.data.data.idToken.jwtToken}`}
                        });
                        dispatch({
                            type:'ADD_ADMIN_USER_INFO',
                            username:username,
                            role:res.data.role
                        })
                        rememberPassword(username,password);
                        if(typeof(status.data.Items[0].Action)==='undefined' || typeof(status.data.Items[0].ActionStatus)==='undefined'){
                            history.replace('/admin/statistic-page');
                            sendNotificationToken({token:firebaseToken}); 
                        }else if(status.data.Items[0].Action==='Restore' && status.data.Items[0].ActionStatus === 'In-progress'){
                            history.replace('/maintainance');
                        }else{
                            history.replace('/admin/statistic-page'); 
                            sendNotificationToken({token:firebaseToken}); 
                        }   
                        setChecking(false);  
                        localStorage.setItem('restore',status.data.Status);   
                    }
                }               
            } catch (error) {
                console.log(error)
                if(typeof(error.response)!=='undefined'){
                    if(typeof(error.response.data.LoginFailureNumber)!=='undefined'){
                        if(error.response.data.AdminStatus === 'deactivated'){
                            setMessage(t('Tài khoản đã bị khóa')); 
                            setIsMessage(true);
                            setChecking(false);
                        }else
                            setMessage(`${t('Bạn đã đăng nhập không thành công')} ${error.response.data.LoginFailureNumber} ${t('lần')}`);    
                    }else if(error.response.data.code==='ValidationException'){
                        setMessage(t('Mật khẩu không chính xác'));
                    }else if(error.response.data.indexOf('Can not find out Admin')!==-1){
                        setMessage(t('Tài khoản chưa được đăng ký'));
                    }
                }else{
                    setMessage(t('Đã xảy ra lỗi, vui lòng thử lại sau'));
                }
                setChecking(false);
            }
        } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
            try {
                setChecking(true);
                res = await axiosClient.post('/auth/signin',{ 'username': username, 'password': md5(password)},{
                    headers:{
                        'Content-Type': 'application/json'
                    }
                });
                Cookies.set('token',res.data.token,{sameSite:'strict',path:'/'});
                localStorage.setItem('username',username);
                const status = await axiosClient.get('/table/import/status',{
                    headers:{'Authorization': `Bearer ${res.data.token}`}
                })
                dispatch({
                    type:'ADD_ADMIN_USER_INFO',
                    payload:username,
                    role:res.data.role
                })
                localStorage.setItem('fullname',res.data.displayName);
                rememberPassword(username,password);
                setChecking(false);
                if(status.data.Items[0]?.ActionStatus==='Done'){
                    history.replace('/admin/statistic-page');
                }else if(status.data.Items[0]?.ActionStatus === 'In-progress'){
                    history.replace('/maintainance');
                }else{
                    history.replace('/admin/statistic-page');
                }
                setChecking(false);
                localStorage.setItem('restore',status.data.Items[0]?.ActionStatus);
            } catch (error) {
                
                if(error.response.data.error === 'UserNotConfirmedException'){
                    setIsConfirmForm(true);
                    setIsLogin(false);
                    setIsChangePassword(false);
                } else if((error.response.data.error === 'TooManyAttemptsError') || (error.response.data.error === 'UserIsLockedException')){  
                    setMessage(t('Tài khoản đã bị khóa'));
                    setIsMessage(true);
                } else if (error.response.data.error === 'IncorrectUsernameError'){
                    setMessage(t('Tài khoản chưa được đăng ký'));
                } else if (error.response.data.error === 'IncorrectPasswordError'){
                    if(error.response.data.attempt < 5) 
                        setMessage(`${t('Bạn đã đăng nhập không thành công')} ${error.response.data.attempt} ${t('lần')}`);
                    else if (error.response.data.attempt === 5) {
                        setMessage(t('Tài khoản của bạn đã bị khóa do đăng nhập thất bại 5 lần'));
                    }
                } 
                setChecking(false);       
            } 
        }
    }

    const onUsernameChange = (e)=>{
        var value = e.target.value;
        setAccount({...account,username:value});
        if(value.trim()===''){
            setErr({...err,username:t('Vui lòng nhập tên đăng nhập')});
        }else{
            setErr({...err,username:''});
        }
    }
    const onPasswordChange = (e) =>{
        var value = e.target.value;
        setAccount({...account,password:value});
        if(value.trim()===''){
            setErr({...err,password:t('Vui lòng nhập mật khẩu')});
        }else{
            setErr({...err,password:''});
        }
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
      
    const onRememberPasswordChange = (e) =>{
        setIsRemember(e.target.checked);
    }
    const loginSubmit = (e) =>{
        e.preventDefault();
        if(isEmpty(account.username) && isEmpty(account.password)) return setMessage(t('Vui lòng nhập tên đăng nhập và mật khẩu'));
        if(isEmpty(account.username)) return setErr({...err,username:t('Vui lòng nhập tên đăng nhập')});
        if(isEmpty(account.password)) return setErr({...err,password:t('Vui lòng nhập mật khẩu')});
        signIn(account.username,account.password);   
    }

    const failedLoginDialog = () =>{
        return(
            <Dialog 
                open={isMessage}
                maxWidth="xl"
            >
                <DialogTitle className={classes.dialogFailedTitleBackground}>
                    <p className={classes.dialogTitle}>{t('Thông báo')}</p>
                </DialogTitle>
                <DialogContent style={{width:'448px',padding:'0',textAlign:'center'}}>
                    <p className={classes.contentDialog}>{t('Tài khoản đã bị khóa.')}<br/> {t('Vui lòng liên hệ người quản trị để mở khóa!')}</p>
                    <Button variant="outlined" color="primary" className={classes.errorBtn} style={{margin:'10px 0'}} onClick={()=>setIsMessage(false)}>{t('Đóng')}</Button>
                </DialogContent>
            </Dialog>
        )
    }

    const loginForm = () =>{
        if(isLogin){
            return(
                <Paper className={clsx(classes.paper)} >
                    
                    {isTabletOrMobile && <Typography variant="h4"  className={classes.title}>{t('Đăng nhập')}</Typography>}
                    {isDesktopOrLaptop && <Typography variant="h3"  className={classes.title}>{t('Đăng nhập')}</Typography>}
                    <Divider style={{width:'80px',textAlign:'center',height:'5px',background:'#072A3B',margin:'auto',marginBottom:'10px'}} />
                    <div className={classes.createNew}>
                        {message !=='' && <p style={{color:'red'}}>{message}</p>      }    
                    </div>    
                    <form>
                        <div className={classes.inputBlock}>
                            <label className={classes.label}>{t('Tên đăng nhập')} *</label>
                            <TextField 
                                classes={{
                                    root:classes.input
                                }}
                                value={account.username}
                                name='username' 
                                variant="outlined" 
                                placeholder={t("Nhập tên đăng nhập của bạn")}
                                onChange={onUsernameChange}
                                error={err.username!==''}
                                helperText={err.username}
                            />
                        </div>
                        <div className={classes.inputBlock}>
                            <label className={classes.label}>{t('Mật khẩu')} *</label>
                            <TextField 
                                classes={{
                                    root:classes.input
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                value={account.password}
                                name='password' 
                                variant="outlined" 
                                placeholder={t("Nhập mật khẩu của bạn")}
                                type={!showPassword?'password':'text'}
                                onChange={onPasswordChange}
                                error={err.password!==''}
                                helperText={err.password}
                            />
                        </div>
                        <div style={{width:'80%',margin:'auto'}}>
                            <Checkbox
                                checked={isRemeber}
                                onChange={onRememberPasswordChange}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                color="primary"
                            /> 
                            <label className={classes.label} style={{display:'inline',position:'relative',top:'5px'}}>{t('Nhớ mật khẩu')}</label>
                        </div>
                        <div className={classes.action} style={{paddingTop:'0'}}>
                            <Button 
                                variant="contained" 
                                disableRipple 
                                type='submit' 
                                autoFocus 
                                disabled={checking} 
                                color="primary" 
                                className={classes.signinAction} 
                                onClick={loginSubmit} 
                            >
                                {t('Đăng nhập')}
                                {checking && <CircularProgress style={{position:'absolute',left:'0',right:'0',marginLeft:'auto',marginRight:'auto',color:'#0b59c8'}} />}
                            </Button>
                            <Link to="/forget-password" className={classes.forgetPassword}>{t('Quên mật khẩu?')}</Link>
                        </div>
                    </form>
                </Paper>
            )
        }
    }

    const onCodeChange = (e) =>{
        var code = e.target.value;
        if(isEmpty(code)){
            setAlert({...alert,code:t('Không được để trống')});
        }else{
            setAlert({...alert,code:''});
            setAccount({...account,code:code});
        }
    }
    
    const confirmEmailClick = async()=>{
        try {
            if( isEmpty(account.code)){
                setAlert({...alert,code:t('Không được để trống')});
                return;
            }
            setChecking(true);
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                if(!isActive){
                    const data = {
                        username:account.username,
                        password:md5(account.password),
                        code:account.code
                    }
                    await axiosClient.post('/cognito/login/confirm',JSON.stringify(data));               
                }else{
                    const data = {
                        username:account.username,
                        new_password:md5(account.password),
                        code:account.code
                    }
                    await axiosClient.post('/cognito/password/confirm',JSON.stringify(data));                   
                }
                setFirstLogin(true);    
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                await axiosClient.post('/auth/verify',{'username':account.username,'otp': account.code},{
                    headers:{
                        'Content-Type': 'application/json'
                    }
                });
            }
            setIsChangePassword(true);
            setIsConfirmForm(false);
            setIsLogin(false);
            setMessage('');
        } catch (error) {
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                if(error.response.data.code==='ExpiredCodeException'){
                    setMessage(t('Mã đã hết hạn'));
                }else if(error.response.data.code === 'NotAuthorizedException'){
                    setMessage(t('Tài khoản không được ủy quyền'));
                }else if(error.response.data.code === 'CodeMismatchException'){
                    setMessage(t('Mã không chính xác'));
                }else{
                    setMessage(t('Bạn đã nhập sai quá số lần quy định, vui lòng thử lại sau 15 phút'));
                }
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                error?.response?.data?.message === 'CodeMismatchException' ?
                    setMessage(t('Mã không chính xác')) :
                    setMessage(t('Mã đã hết hạn'));
            }
        }
        setChecking(false);   
    }

    const sendCodeClick = async(e) =>{
        e.preventDefault();
        try {
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                if(!isActive)
                    await Auth.resendSignUp(account.username);
                else
                    await Auth.forgotPassword(account.username);
                setMessage(t('Code đã được gửi đến email của bạn'));
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                await axiosClient.post('/auth/otp/request',{username: account.username},{
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setMessage(t('Code đã được gửi đến email của bạn'));
            }
        } catch (error) {
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                if(error.code === 'LimitExceededException')
                    return setMessage(t('Đã quá số lận nhận mã, vui lòng thử lại sau 15 phút'));
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                error?.response?.data?.error &&
                setMessage(t('Đã quá số lận nhận mã, vui lòng thử lại sau 15 phút'));
            }
        }   
    }

    const onBackClick = () =>{
        setIsConfirmForm(false);
        setIsLogin(true);
        setIsChangePassword(false);
    }

    const confirmForm = () =>{
        if(isConfirmForm){
            return(
                <Paper className={clsx(classes.paper)} >
                    <Typography variant="h3"  className={classes.title}>{t('Xác thực tài khoản')}</Typography>
                    <Divider style={{width:'80px',textAlign:'center',height:'5px',background:'#072A3B',margin:'auto',marginBottom:'10px'}} />
                    <div className={classes.createNew}>
                        {message !=='' && <p style={{color:'red'}}>{message}</p>}    
                    </div>
                    <div className={classes.inputBlock} style={{height:'150px'}}>
                        <label className={classes.label}>
                            <p style={{textAlign:'center',paddingBottom:'10px'}} >{t('Vui lòng nhập mã xác thực đã được gửi đến ') + email}</p>
                            {t('Mã xác thực')} <span style={{color:'red'}}>(*)</span>
                        </label>
                        <TextField 
                            classes={{
                                root:classes.input
                            }}
                            name='code' 
                            variant="outlined" 
                            placeholder={t("Nhập mã số của bạn")}
                            onChange={onCodeChange}
                            error={alert.code.length > 0}
                            helperText={alert.code}
                        />
                    </div>
                    <div className={classes.action}>
                        <Button variant="contained" disabled={checking} color="primary" style={{background:'#0b59c8'}} className={classes.signinAction} onClick={confirmEmailClick} >
                            {t('Xác nhận')}
                        {checking && <CircularProgress style={{position:'absolute',textAlign:'center',color:'#0b59c8'}} />}
                        </Button>
                        <p className={classes.forgetPassword} onClick={onBackClick} style={{float:'left',cursor:'pointer'}}>{t('Quay lại')}</p>
                        <p onClick={sendCodeClick} className={classes.forgetPassword} style={{float:'right',paddingBottom:'20px',cursor:'pointer'}}>{t('Gửi lại mã')}</p>
                    </div>
                </Paper>
            )
        }
    }
    const showEnterPasswordClick = () =>{
        setShowEnterPassword(!showEnterPassword);
    }
    const showReEnterPasswordClick = ()=>{
        setShowReEnterPassword(!showReEnterPassword);
    }
    const handleChangePassword = async () =>{      
        if(isEmpty(enterPassword)) return setErr({...err,enterPassword:t('Không được để trống')});
        if(err.enterPassword !== '') return;
        if(isEmpty(reEnterPassword)) return setErr({...err,reEnterPassword:t('Không được để trống')});
        if(enterPassword !== reEnterPassword) return setErr({...err,reEnterPassword:t('Mật khẩu không giống nhau')});
        if(err.reEnterPassword !== '') return;
        setChecking(true);
        const data = {
            username:account.username,
            password:md5(account.password),
            new_password:md5(enterPassword)
        }
        try {
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                if(!firstLogin){
                    const user = await Auth.signIn(account.username, md5(account.password));
                    await Auth.completeNewPassword(
                        user,
                        md5(account.password),
                        {
                            email: account.username,
                        }
                    )
                    await axiosClient.post('/cognito/password/change',JSON.stringify(data));
                    setAccount({...account,username:account.username,password:enterPassword});
                }else{
                    await axiosClient.post('/cognito/password/change',JSON.stringify(data));
                    setAccount({...account,password:enterPassword});
                    setFirstLogin(false);
                }
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                await axiosClient.post('/auth/activateuser',{...data, code: account.code},{
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
            }
            setIsDialog(true);
        } catch (error) {
            if (typeof(error.response)!=='undefined'){
                if(error.response.data === "New password can not be same with old password") setMessage(t('Mật khẩu mới không được giống mật khẩu cũ!'))
            }else{
                setMessage(t('Đã xảy ra lỗi, vui lòng thử lại!'));  
            }
                 
        }
        setChecking(false);
    }

    const onEnterPasswordChange = (e)=>{
        const value = e.target.value;
        setEnterPassword(value);
        if(isEmpty(value)){
            setErr({...err,enterPassword:t('Không được để trống')});
        }else if(!checkPassword(value)){
            setErr({...err,enterPassword:t('Tối thiểu 8 ký tự bao gồm chữ HOA, chữ thường, số và ký tự đặc biệt')});
        }else{
            setErr({...err,enterPassword:''});
        }
        if(passwordRef.current) clearTimeout(passwordRef.current);
        passwordRef.current = setTimeout(() => {
            const result = zxcvbn(value);
            if(result.feedback.warning !== '') return setErr({...err,enterPassword:t('Đã trùng mật khẩu phổ biến')});
            if(reEnterPassword === '') return;
            if(value === reEnterPassword) setErr({...err,reEnterPassword:''});
            else setErr({...err,reEnterPassword:t('Mật khẩu không giống nhau')});
        }, 500);
    }
    const onRePasswordChange = (e) =>{
        const value = e.target.value;
        setReEnterPassword(value);
        if(isEmpty(value)){
            setErr({...err,reEnterPassword:t('Không được để trống')});
        }else{
            setErr({...err,reEnterPassword:''});
            if(rePasswordRef.current) clearTimeout(rePasswordRef.current);
            rePasswordRef.current = setTimeout(() => {
                if(value !== enterPassword) setErr({...err,reEnterPassword:t('Mật khẩu không giống nhau')});
            }, 500);
        }
    }
    const changePassword = () =>{
        if(isChangePassword){
            return(
                <Paper className={clsx(classes.paper)} >
                    <div style={{height:'180px',textAlign:'center'}}>
                        <Typography variant="h2"  className={classes.title} style={{fontSize:'35px'}}>{t('Đổi mật khẩu lần đầu')}</Typography>
                        <Divider style={{width:'80px',height:'5px',background:'#072A3B',margin:'auto',marginBottom:'20px'}} />
                        <p style={{color:'red'}}>{message}</p>
                    </div>
                    <div className={classes.inputBlock} style={{height:'135px'}}>
                        <label className={classes.label}>{t('Nhập mật khẩu mới')} <span style={{color:'red'}}>(*)</span></label>
                        <TextField
                            InputProps={{
                                endAdornment: (    
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={showEnterPasswordClick}
                                        >
                                            {showEnterPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            placeholder={t("Mật khẩu")}
                            variant="outlined"
                            style={{width:'100%'}}
                            type={showEnterPassword?"text":"password"}
                            onChange={onEnterPasswordChange}
                            error = {err.enterPassword !== ''}
                        />
                        {enterPassword!=='' && 
                        <PasswordStrengthBar 
                            scoreWords={['','','','']} 
                            shortScoreWord='' 
                            password={enterPassword}
                        />}
                        <p style={{color:'#f44336',paddingTop:'3px',fontSize:'0.75rem'}}>{err.enterPassword}</p>
                    </div>
                    <div className={classes.inputBlock}>
                        <label className={classes.label}>{t('Nhập lại mật khẩu mới')} <span style={{color:'red'}}>(*)</span></label>
                        <TextField
                            InputProps={{
                                endAdornment: (    
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={showReEnterPasswordClick}
                                        >
                                            {showReEnterPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            placeholder={t("Mật khẩu")}
                            variant="outlined"
                            style={{width:'100%'}}
                            type={showReEnterPassword?"text":"password"}
                            onChange={onRePasswordChange}
                            error = {err.reEnterPassword !== ''}
                        />  
                        <p style={{color:'#f44336',paddingTop:'3px',fontSize:'0.75rem',width:'150%'}}>{err.reEnterPassword}</p>
                    </div>
                    <div className={classes.action}>
                        <Button variant="contained" disabled={checking} color="primary" style={{background:'#0b59c8'}} className={classes.signinAction} onClick={handleChangePassword} >
                            {t('Xác nhận')}
                        {checking && <CircularProgress style={{position:'absolute',textAlign:'center',color:'#0b59c8'}} />}
                        </Button>
                    </div>
                </Paper>
            )
        }
    }

    useEffect(()=>{
        if(loginState.loginSession) setIsLoginAgain(true);
        else setIsLoginAgain(false);
    },[loginState])

    const onAgreeLoginClick = () =>{
        dispatch({type:'IS_LOGIN_AGAIN_FALSE'});
    }

    const loginAgainMessage = () =>{
        return(
            <Dialog 
                open={isLoginAgain}
                maxWidth="xl"
            >
                <DialogTitle className={classes.dialogFailedTitleBackground}>
                    <p className={classes.dialogTitle}>{t('Thông báo')}</p>
                </DialogTitle>
                <DialogContent style={{width:'448px',padding:'0',textAlign:'center'}}>
                    <p className={classes.contentDialog}>{t('Tài khoản đã đăng nhập nơi khác hoặc hệ thống đang bảo trì')}. {t('Vui lòng đăng nhập lại!')}</p>
                    <Button variant="outlined" color="primary" className={classes.errorBtn} style={{margin:'10px 0'}} onClick={onAgreeLoginClick}>{t('Đóng')}</Button>
                </DialogContent>
            </Dialog>
        )
    }

    const successDialog = () =>{
        return(
            <Dialog 
                open={isDialog}
                maxWidth="xl"
            >
                <DialogTitle className={classes.dialogTitleBackground}>
                    <p className={classes.dialogTitle}>{t("Thông báo")}</p>
                </DialogTitle>
                <DialogContent style={{width:'448px',padding:'0',position:'relative',textAlign:'center'}}>
                    <div className={classes.contentDialog}>
                        <p >{t("Đã thay đổi mật khẩu thành công!")}</p>
                    </div>
                    <CreateButton 
                        variant="contained" 
                        style={{margin:'10px 0'}}
                        onClick={()=>{
                            setIsChangePassword(false);
                            setIsLogin(true);
                            setIsConfirmForm(false);
                            setIsDialog(false);
                        }} 
                    >
                        {t("Đồng ý")}
                    </CreateButton>

                </DialogContent>
                
            </Dialog>
        )
    }

    return (
        <div style={{minWidth:'500px',height:'100vh',width:'100%',overflow:'hidden',display:'flex',justifyContent:'center',alignItems:'center'}}>
            {checking && <div className={classes.overlay}></div>}
            {successDialog()}
            {failedLoginDialog()}
            {loginForm()}
            {confirmForm()}
            {changePassword()}
            {loginAgainMessage()}
        </div>
    )
}

export default Login;
