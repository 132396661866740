import AxiosClient from "../config/AxiosClient";

export const apiRegisterresidences = async (bodyRequest) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/registerresidences';
    } else {
        url = '/residences/create';
    }
    return await AxiosClient.post(url, bodyRequest);
}