import axiosClient from "../config/AxiosClient";
import { ServiceApiConstants } from "../services/serviceApi";

/**cabinet */
export const apiCabinetsPost = (body) => {
    /*
        body = {
            cabinetName: "",
            location: "",
            typeCabinet: "",
            overDueDate: "",
            cells: json
        }
    */
    const url = ServiceApiConstants.CABINETS;
    return axiosClient.post(url, body);
}

export const apiCabinetsGet = (body) => {
    /*
        getList: noBody, getDetails: body
        body = {
            id: ""
        }
        return {
            CabinetName: "",
            Cells: [{
                cabinetCellName: "1"
                cellType: "1650510517885"
                deliveredTime: 456789456 (thời gian giao hàng) (có start && k có này => chờ gửi hàng; có start && có này => chờ lấy hàng)
                expiredTime: undefined || 456789456 (thời gian cứ dân lấy hàng (trả tủ), có này =>  đã lấy hàng)
                id: 0
                isExpired: 456789456 (thời gian hết hạn) (này < currentTime => quá hạn)
                isLocked: true (tủ đã đóng)
                notes: "Ghi chú"
                startTime: 456789456 (chưa có start -> trống)
                unlockCode: "1234"
                userName: "0352560649",
                fullName: "abcdef"
            }],
            Location: "",
            OverdueDate: "",
            TypeCabinet: ""
        }
    */
    const url = ServiceApiConstants.CABINETS;
    return axiosClient.get(url, { params: body });
}

export const apiCabinetListGet = () => {
    const url = ServiceApiConstants.CABINETS_LIST;
    return axiosClient.get(url);
}

export const apiCabinetsDelete = (body) => {
    /*
        body = {
            id: ""
        }
    */
    const url = ServiceApiConstants.CABINETS;
    return axiosClient.delete(url, { data: body });
}

export const apiCabinetsPut = (body) => {
    /*
        body = {
            id: ""
        }
    */
    const url = ServiceApiConstants.CABINETS;
    return axiosClient.put(url, body);
}

/*********** CELL TYPE ********* */

export const apiCabinetCellTypesPost = (body) => {
    /*
        body =  {
            "cellType": [{
                "language": "vi",
                "name": "Lớn"
                },{
                "language": "en",
                "name": "Large"
                }],
            "specifications" :
                "length": "45",
                "width": "35",
                "height": "56"
            },
            "priceType": "free" || "scale" || "hourly" || "turn",
            "priceValue": 0 || {
                3: 10000,
                8: 30000
            } || 10000 || 10000
        }
    */

    const url = ServiceApiConstants.CABINETS_CELLS_TYPES;
    return axiosClient.post(url, body);
}

export const apiCabinetCellTypesGet = (body) => {
    /*
        body = {
            "language": "vi" | "en" (i18)
        }
        return = {

        }
    */
    const url = ServiceApiConstants.CABINETS_CELLS_TYPES;
    return axiosClient.get(url, { params: body });
}

export const apiCabinetCellTypesDelete = (body) => {
    /*
        body = {
            ids: []
        }
    */
    const url = ServiceApiConstants.CABINETS_CELLS_TYPES;
    return axiosClient.delete(url, { data: body });
}

export const apiCabinetCellTypesUpdate = (body) => {
    const url = ServiceApiConstants.CABINETS_CELLS_TYPES;
    return axiosClient.put(url, body);
};

/************** CABINET CELLS ************* */

export const apiCabinetCellsGet = (body) => {
    /*
        body = {
            cabinetId: "",
            cellId: "",
            language: ""
        }
    */

    const url = ServiceApiConstants.CABINETS_CELLS;
    return axiosClient.get(url, { params: body });
}

/********************************** */

export const apiCabinetTemplatesPost = (body) => {
    /*
        body: {
            "cabinetTemplateName": "2Q",
            "cells": JSON
        }
    */

    const url = ServiceApiConstants.CABINETS_TEMPLATES;
    return axiosClient.post(url, body);
}

export const apiCabinetTemplatesGet = (body) => {
    const url = ServiceApiConstants.CABINETS_TEMPLATES;
    return axiosClient.get(url, { params: body });
}

/********* CELL HISTORY ********* */

export const apiCellsHistoryGet = (body) => {
    /**
        body = {
            language: "",
            cabinetId: "",
            start_index: 0,
            end_index: 0,
            searchKeyWord: "" (option),
            paymentMethod,(option),
            paymentStatus,(option),
            startTimeBooking,(option),
            endTimeBooking,(option),
            startTimeReturn,(option),
            endTimeReturn,(option),
        }
     */

    const url = ServiceApiConstants.CABINETS_CELLS_HISTORY;
    return axiosClient.get(url, { params: body });
}

/************* CABINET CHART ************* */

export const apiCabinetsChartGet = (body) => {
    /*
        body = {
            cabinetId: "" (noneCabinetId => get totals)
        }
    */
    const url = ServiceApiConstants.CABINETS_CHARTS;
    return axiosClient.get(url, { params: body });
}

//get bar booked chart
export const apiCabinetsDateChartsGet = (body) => {
    /*
        body = {
            cabinetId: ""
            date: 5-20-2022 (dd/mm/yy => get by day) || 5-2022 (mm/yy => get by month)
        }
    */
    const url = ServiceApiConstants.CABINETS_DATE_CHARTS;
    return axiosClient.get(url, { params: body });
}

//get bar cell chart
export const apiCabinetsTypesDateChartsGet = (body) => {
    /*
        body = {
            cabinetId: ""
            date: 5-20-2022 (dd/mm/yy => get by day) || 5-2022 (mm/yy => get by month)
        }
    */
    const url = ServiceApiConstants.CABINETS_TYPES_DATE_CHARTS;
    return axiosClient.get(url, { params: body });
}

export const apiCabinetsRevenueDateChartsGet = (body) => {
    /*
        body = {
            cabinetId: ""
            date: 5-20-2022 (dd/mm/yy => get by day) || 5-2022 (mm/yy => get by month),
            typeDate: "week" (only this key => get totalRevenue)
        }
    */
    const url = ServiceApiConstants.CABINETS_REVENUE_DATE_CHARTS;
    return axiosClient.get(url, { params: body });
}

export const apiCabinetsRevenuesDateChartsGet = (body) => {
    /*
        body = {
            typeDate: "week"
        }
    */
    const url = ServiceApiConstants.CABINETS_REVENUES_DATE_CHARTS;
    return axiosClient.get(url, { params: body });
}

/********** cabinet cell open ********/

export const apiCabinetsCellsOpen = (body) => {
    /*
        body = {
            cabinetId: "",
            cellId: "",
            reason
        }
    */
    const url = ServiceApiConstants.CABINETS_CELLS_OPEN;
    return axiosClient.post(url, body);
}

/*************** config profile *************** */

export const apiTlockerProfilePost = (body) => {
    /**
      body = {
        profileName: "", (required)
        profileDescription: "",
        overideSystemLogo: "" (boolean),
        logo: "" (!overideSystemLogo => required)
      }
     */
    const url = ServiceApiConstants.TLOCKER_PROFILES;
    return axiosClient.post(url, body)
}

export const apiTlockerProfileGet = (body) => {
    /**
      body = {
        profileName: "", (optional => get a element)
      }
     */
    const url = ServiceApiConstants.TLOCKER_PROFILES;
    return axiosClient.get(url, { params: body })
}

export const apiTlockerProfilePut = (body) => {
    /**
      body = {
        profileName: "",
      }
     */
    const url = ServiceApiConstants.TLOCKER_PROFILES;
    return axiosClient.put(url, body);
}

export const apiTlockerProfileDelete = (body) => {
    /**
      body = {
        profiles: [profileName],
        logos: [logo]
      }
     */
    const url = ServiceApiConstants.TLOCKER_PROFILES;
    return axiosClient.delete(url, { data: body });
}