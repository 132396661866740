
import React, { useState, useEffect } from 'react';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';
import Divider from '@material-ui/core/Divider/Divider';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import Button from '@mui/material/Button/Button';
import { compare } from 'natural-orderby';
import { handleResponseError } from '../../../helper/handleResponseError';

const useStyles = makeStyles((theme) => ({
    inputBlockPolicyBlock: {
        height: '80px',
        width: '40%',
        float: 'left'
    },

    inputStyle: {
        width: '90%'
    },
    autoInputStyle: {
        width: '100%'
    },
    label: {
        margin: '0',
        fontSize: '14px',
        lineHeight: '140%',
        color: '#000000',
        display: 'inline-block'
    },
    title: {
        width: '100%',
        marginBottom: '5px',
        fontSize: '18px'
    },
    dividerStyle: {
        width: '100%',
        marginBottom: '20px!important',
        marginTop: '10px!important'
    },
    actionBlock: {
        width: '95%',
        textAlign: 'right'
    },
    base_info_title: {
        margin: '24px'
    },
    inputPolicyPart: {
        display: 'flex',
        padding: '0 24px'
    },
    addPolicyStyle: {
        float: 'right',
        height: '80px',
        paddingTop: '20px'
    },
    showBlockPolicyStyle: {
        margin: '5px 24px',
        fontSize: '16px',
        display: 'flex',
        border: '1px solid rgb(0, 0, 0, 0.3)',
        padding: '5px 12px',
        cursor: 'pointer'
    },
    textBlockFloorRoomStyle: {
        width: '10%',
        textAlign: 'right',
        textDecoration: 'underline',
        color: 'rgba(0, 0, 0, 0.12)',
        fontSize: '16px',
    },
    homeIconStyle: {
        fontSize: '30px',
        position: 'relative',
        top: '-2px'
    },
    select: {
        '& .MuiOutlinedInput-input': {
            padding: '10px 12px'
        }
    },
    bottomDividerStyle: {
        clear: 'both',
        margin: '0 4% 10px 4%',
        padding: '1px'
    },
    actionBlock: {
        margin: '0 4%',
        textAlign: 'right'
    },
    dialogContentBlock: {
        width: '700px',
        padding: '0!important'
    },
    selectStyle: {
        height: '40px',
        background: '#FFFFFF',
        width: '100%'
    },
    width90: {
        width: '90%'
    },
    closeDialogStyle: {
        float: 'right',
        position: 'relative',
        top: '-15px',
        right: '-15px',
        color: '#666666',
        cursor: 'pointer'
    },
    rowSelecting: {
        border: '1px solid red',
    }
}));

export const BlockFloorRoom = ({
    title,
    isChoosePolicy,
    closeDialog,
    blockData,
    roomData,
    type,
    onCancelClick,
    onSaveClick,
    apartmentData,
    CreateButton,
    CancelButton
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [selectedBlockPolicies, setSelectedBlockPolicies] = useState({
        block: '',
        floor: '',
        room: ''
    });
    const [blockPoliciesData, setBlockPoliciesData] = useState([]);
    const [roomPoliciesData, setRoomPoliciesData] = useState([]);
    const [blocks, setBlocks] = useState([]);
    const [floors, setFloors] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [allFloors, setAllFloors] = useState([]);
    const [allRooms, setAllRooms] = useState([]);
    const [rowSelecting, setRowSelecting] = useState(-1);
    const [oldRowSelected, setOldRowSelected] = useState({
        block: '',
        floor: '',
        room: ''
    })
    const [isEditRow, setIsEditRow] = useState(false);

    const getBlocks = async () => {
        var names = [];
        var allB = [];
        var allF = [];
        apartmentData.forEach(item => {
            names.push(item.BlockID);
            allB.push(item.FloorID);
            allF.push(item.RoomID);
        });
        names.sort((a, b) =>
            a.localeCompare(b, undefined, {
                numeric: true,
                sensitivity: 'base'
            })
        );
        allB.sort((a, b) =>
            a.localeCompare(b, undefined, {
                numeric: true,
                sensitivity: 'base'
            })
        );
        allF.sort((a, b) =>
            a.localeCompare(b, undefined, {
                numeric: true,
                sensitivity: 'base'
            })
        );
        setBlocks([...new Set(names)]);
        setAllFloors([...new Set(allB)]);
        setAllRooms([...new Set(allF)]);
    }

    const getFloors = async (blockID) => {
        if (blockID === 'all') {
            return setFloors(allFloors);
        }
        const data = apartmentData.filter(item => item.BlockID === blockID);
        if (data.length === 0) return setFloors([]);
        var names = [];
        data.forEach(item => {
            names.push(item.FloorID + '');
        });
        names.sort((a, b) =>
            a.localeCompare(b, undefined, {
                numeric: true,
                sensitivity: 'base'
            })
        );
        setFloors([...new Set(names)]);
    }

    const getRooms = async (blockID, floorID) => {
        if (floorID === 'all' || blockID === 'all') {
            return setRooms(allRooms);
        }
        var data = apartmentData.filter(item => item.BlockID === blockID && item.FloorID === floorID);
        if (data.length === 0) return setRooms([]);
        var names = [];
        data.forEach(item => {
            names.push(item.RoomID + '');
        });
        names.sort((a, b) =>
            a.localeCompare(b, undefined, {
                numeric: true,
                sensitivity: 'base'
            })
        );
        setRooms([...new Set(names)]);
    }

    useEffect(() => {
        getBlocks();
    }, [apartmentData])

    useEffect(() => {
        if (selectedBlockPolicies.block !== '') getFloors(selectedBlockPolicies.block);
    }, [selectedBlockPolicies.block])

    useEffect(() => {
        if (selectedBlockPolicies.floor === '') return;
        getRooms(selectedBlockPolicies.block, selectedBlockPolicies.floor);
    }, [selectedBlockPolicies.floor])

    useEffect(() => {
        setBlockPoliciesData([]);
        setRoomPoliciesData([]);
    }, [type])

    useEffect(() => {
        setBlockPoliciesData(blockData);
    }, [blockData])

    useEffect(() => {
        setRoomPoliciesData(roomData);
    }, [roomData])

    const deleteBlockPoliciesClick = (apart) => {
        const data = blockPoliciesData.filter(item => item.block !== apart.block || item.floor !== apart.floor);
        setBlockPoliciesData(data);
    }

    const deleteRoomPoliciesClick = (apart) => {
        const data = roomPoliciesData.filter(item => item.block !== apart.block || item.floor !== apart.floor || item.room !== apart.room);
        setRoomPoliciesData(data);
    }

    const onBlockChange = (e) => {
        setFloors([]);
        setRooms([]);
        setSelectedBlockPolicies({
            ...selectedBlockPolicies,
            block: e.target.value,
            floor: '',
            room: ''
        });
    }

    const onFloorChange = (e) => {
        setRooms([]);
        setSelectedBlockPolicies({
            ...selectedBlockPolicies,
            floor: e.target.value,
            room: ''
        });
    }

    const onRooomChange = (e) => {
        setSelectedBlockPolicies({
            ...selectedBlockPolicies,
            room: e.target.value
        });
    }

    const grayOutAddButton = () => {
        var flag = false;
        if (type === 'block') {
            if (selectedBlockPolicies.block === '' || selectedBlockPolicies.floor === '') {
                flag = true;
            }
        } if (type === 'room') {
            if (selectedBlockPolicies.block === '' || selectedBlockPolicies.floor === '' || selectedBlockPolicies.room === '') {
                flag = true;
            }
        }
        return flag;
    }

    const onEditRowClick = () => {
        if (type === 'block') {
            blockPoliciesData.forEach(item => {
                if (item.block === oldRowSelected.block && item.floor === oldRowSelected.floor) {
                    item.block = selectedBlockPolicies.block;
                    item.floor = selectedBlockPolicies.floor;
                }
            })
        }
        if (type === 'room') {
            roomPoliciesData.forEach(item => {
                if (item.block === oldRowSelected.block && item.floor === oldRowSelected.floor && item.room === oldRowSelected.room) {
                    item.block = selectedBlockPolicies.block;
                    item.floor = selectedBlockPolicies.floor;
                    item.room = selectedBlockPolicies.room;
                }
            })
        }
        setSelectedBlockPolicies({
            block: '',
            floor: '',
            room: ''
        })
        setIsEditRow(false);
        setRowSelecting(-1);
    }

    const onAddBlockPolicyClick = () => {
        if (type === 'block') {
            if (selectedBlockPolicies.block === '' || selectedBlockPolicies.floor === '') {
                return;
            }
            var res = blockPoliciesData.filter(item => item.block === selectedBlockPolicies.block && item.floor === selectedBlockPolicies.floor);
            if (res.length > 0) {
                return setSelectedBlockPolicies({ block: '', floor: '', room: '' });
            }
            var data = [...blockPoliciesData];
            data.push({
                block: selectedBlockPolicies.block,
                floor: selectedBlockPolicies.floor
            });
            setBlockPoliciesData(data);
            setSelectedBlockPolicies({ block: '', floor: '', room: '' });
        }
        if (type === 'room') {
            if (selectedBlockPolicies.block === '' || selectedBlockPolicies.floor === '' || selectedBlockPolicies.room === '') {
                return;
            }
            var res = roomPoliciesData.filter(item => item.block === selectedBlockPolicies.block && item.floor === selectedBlockPolicies.floor && item.room === selectedBlockPolicies.room);
            if (res.length > 0) {
                return setSelectedBlockPolicies({ block: '', floor: '', room: '' });
            }
            var data = [...roomPoliciesData];
            data.push(selectedBlockPolicies);
            setRoomPoliciesData(data);
            setSelectedBlockPolicies({ block: '', floor: '', room: '' });
        }
    }

    const onSaveSelectedClick = (blockPoliciesData, roomPoliciesData) => {
        setIsEditRow(false);
        setRowSelecting(-1);
        setSelectedBlockPolicies({ block: '', floor: '', room: '' });
        onSaveClick(blockPoliciesData, roomPoliciesData);
    }

    const onCloseDialogClick = () => {
        setIsEditRow(false);
        setRowSelecting(-1);
        setSelectedBlockPolicies({ block: '', floor: '', room: '' });
        closeDialog();
    }

    const onRoomClick = (value, key) => {
        setRowSelecting(key);
        setIsEditRow(true);
        if (value.room) {
            setSelectedBlockPolicies({
                block: value.block,
                floor: value.floor,
                room: value.room
            });
            setOldRowSelected({
                block: value.block,
                floor: value.floor,
                room: value.room
            });
        } else {
            setSelectedBlockPolicies({
                block: value.block,
                floor: value.floor,
                room: ''
            });
            setOldRowSelected({
                block: value.block,
                floor: value.floor,
                room: ''
            });
        }
    }

    const showBlockPolicyList = () => {
        if (type === 'block') {
            return blockPoliciesData.map((value, key) => {
                return (
                    <div key={key} className={clsx(classes.showBlockPolicyStyle, { [classes.rowSelecting]: rowSelecting === key })}>
                        <p
                            className={classes.width90}
                            onClick={() => onRoomClick(value, key)}
                        >
                            <HomeOutlinedIcon className={classes.homeIconStyle} /> {t('Tòa')} {value.block === 'all' ? t('Tất cả') : value.block} - {t('Tầng')} {value.floor === 'all' ? t('Tất cả') : value.floor}
                        </p>
                        <p className={classes.textBlockFloorRoomStyle} >
                            <CloseIcon onClick={() => deleteBlockPoliciesClick(value)} style={{ cursor: 'pointer' }} />
                        </p>
                    </div>
                )
            })
        }
        if (type === 'room') {
            return roomPoliciesData.map((value, key) => {
                return (
                    <div key={key} className={clsx(classes.showBlockPolicyStyle, { [classes.rowSelecting]: rowSelecting === key })}>
                        <p
                            className={classes.width90}
                            onClick={() => onRoomClick(value, key)}
                        >
                            <HomeOutlinedIcon className={classes.homeIconStyle} /> {t('Tòa')} {value.block === 'all' ? t('Tất cả') : value.block} - {t('Tầng')} {value.floor === 'all' ? t('Tất cả') : value.floor} - {t('Phòng')} {value.room === 'all' ? t('Tất cả') : value.room}
                        </p>
                        <p className={classes.textBlockFloorRoomStyle} >
                            <CloseIcon onClick={() => deleteRoomPoliciesClick(value)} style={{ cursor: 'pointer' }} />
                        </p>
                    </div>
                )
            })
        }
    }

    const onCancelPolicyClick = () => {
        setBlockPoliciesData([]);
        setRoomPoliciesData([]);
        setRowSelecting(-1);
        setIsEditRow(false);
        onCancelClick();
    }

    return (
        <Dialog
            open={isChoosePolicy}
            maxWidth="xl"
        >
            <DialogContent className={classes.dialogContentBlock}>
                <div>
                    <h4 className={classes.base_info_title}>
                        {title}
                        <CloseIcon
                            className={classes.closeDialogStyle}
                            onClick={onCloseDialogClick}
                        />
                    </h4>
                    <div className={classes.inputPolicyPart}>
                        <div className={clsx(classes.inputBlockPolicyBlock, classes.select)}>
                            <FormControl required variant="outlined" classes={{ root: classes.inputStyle }}>
                                <label className={classes.label}>{t("Tòa")}</label>
                                <Select
                                    value={selectedBlockPolicies.block}
                                    className={classes.selectStyle}
                                    displayEmpty
                                    onChange={onBlockChange}
                                >
                                    <MenuItem value='' disabled >
                                        <p className={classes.placeholder}> {`< ${t('Chọn')} >`} </p>
                                    </MenuItem>
                                    <MenuItem value={"all"} >{t("Tất cả")}</MenuItem>
                                    {
                                        blocks.map(item => (
                                            <MenuItem key={item} value={item} >{item}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div className={clsx(classes.inputBlockPolicyBlock, classes.select)}>
                            <FormControl required variant="outlined" classes={{ root: classes.inputStyle }}>
                                <label className={classes.label}>{t("Tầng")}</label>
                                <Select
                                    value={selectedBlockPolicies.floor}
                                    className={classes.selectStyle}
                                    displayEmpty
                                    onChange={onFloorChange}
                                >
                                    <MenuItem value='' disabled >
                                        <p className={classes.placeholder}> {`< ${t('Chọn')} >`} </p>
                                    </MenuItem>
                                    <MenuItem value={"all"} >{t("Tất cả")}</MenuItem>
                                    {
                                        floors.map(item => (
                                            <MenuItem key={item} value={item} >{item}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        {
                            type === 'room' &&
                            <div className={clsx(classes.inputBlockPolicyBlock, classes.select)}>
                                <FormControl required variant="outlined" classes={{ root: classes.inputStyle }}>
                                    <label className={classes.label}>{t("Phòng")}</label>
                                    <Select
                                        value={selectedBlockPolicies.room}
                                        className={classes.selectStyle}
                                        displayEmpty
                                        onChange={onRooomChange}
                                    >
                                        <MenuItem value='' disabled >
                                            <p className={classes.placeholder}> {`< ${t('Chọn')} >`} </p>
                                        </MenuItem>
                                        {
                                            rooms.map(item => (
                                                <MenuItem key={item} value={item} >{item}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        }
                        <div style={{ flex: '1' }}></div>
                        <div className={classes.addPolicyStyle}>
                            {
                                !isEditRow &&
                                <CreateButton
                                    variant="contained"
                                    onClick={onAddBlockPolicyClick}
                                    disabled={grayOutAddButton()}
                                >
                                    {t('Thêm')}
                                </CreateButton>
                            }
                            {
                                isEditRow &&
                                <CreateButton
                                    variant="contained"
                                    onClick={onEditRowClick}
                                    disabled={grayOutAddButton()}
                                >
                                    {t('Sửa')}
                                </CreateButton>
                            }
                        </div>
                    </div>
                    <Divider className={classes.bottomDividerStyle} />
                    <div >
                        {showBlockPolicyList()}
                    </div>
                    <div className={classes.actionBlock}>
                        <CancelButton
                            variant="outlined"
                            style={{ margin: '10px 0' }}
                            onClick={onCancelPolicyClick}
                        >
                            {t("filter.Xóa")}
                        </CancelButton>
                        <CreateButton
                            variant="contained"
                            onClick={() => onSaveSelectedClick(blockPoliciesData, roomPoliciesData)}
                        >
                            {t("Lưu")}
                        </CreateButton>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}