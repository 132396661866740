import React,{useState,useEffect} from 'react';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useTranslation} from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button/Button';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import DescriptionIcon from '@material-ui/icons/Description';
import axiosClient from '../../../config/AxiosClient';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useHistory} from 'react-router-dom';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InputAdornment from '@material-ui/core/InputAdornment';
import Minizip from 'minizip-asm.js';

const useStyles = makeStyles((theme)=>({
    inputBlock:{height:'50px',width:'100%',position:'relative',paddingTop:'10px'},
    label:{margin:'0',fontSize: '14px',lineHeight: '140%',color: '#000000',marginRight:'5px'},
    placeholder:{color:'#B2B2B2',fontSize:'12px'},
    height:{display:'flex',
        '& .MuiInputBase-input': {
            height: '10px'
        }
    },
    root: {
        display: 'flex',
        alignItems: 'center',
        height:'32px',
        width:'320px',
        float:'left',
        position:'relative',
        top:'-3px'
    },
    dialogTitle:{textAlign:'center',fontSize:'20px',fontWeight:'600',lineHeight:'25px',color:'#FFFFFF'},
    contentDialog:{fontSize:'16px',height:'68px',borderBottom:'1px solid #EEEDF2',display:'flex',alignItems:'center',justifyContent:'center',fontWeight:'bold'},
    dialogTitleBackground:theme.primaryColor,
    errorColor:theme.errorColor,
    focus:{
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': { 
                borderColor: theme.themeColor,
            },
        },
    },
    radio: {
        '&$checked': {
          color: theme.menuBackground
        }
      },
    checked: {}
}))

const CreateButton = withStyles(theme =>({root:theme.create_button}))(Button);
const CancelButton = withStyles(theme =>({root:theme.cancel_button}))(Button);
const ErrorButton = withStyles(theme =>({root:theme.error_button}))(Button);

const Restore = (props) =>{
    const {backupNames,closeBackupOrRestore} = props;
    const classes = useStyles();
    const {t} = useTranslation();
    const [type,setType] = useState('local');
    const [source,setSource] = useState('');
    const [localFileName,setLocalFileName] = useState('');
    const [cloudFileName,setCloudFileName] = useState('');
    const [isRestore,setIsRestore] = useState(false);
    const [isFailed,setIsFailed] = useState(false);
    const [isSuccess,setIsSuccess] = useState(false);
    const [isRestoring,setIsRestoring] = useState(false);
    const [alert,setAlert] = useState({source:'',file_name:'',password:''});
    const history = useHistory();
    const [isExpiredSession,setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const [password,setPassword] = useState('');
    const [showPassword,setShowPassword] = useState(false);

    useEffect(()=>{
        if(isExpiredSession){
          history.push('/login');
          dispatch({type:'IS_LOGIN_AGAIN_TRUE'});
        } 
    },[isExpiredSession]);

    const onTypeChange = (e) =>{
        setType(e.target.value);
        setAlert({...alert,password:''});
    }

    const onBackupFileChange = (e) =>{
        const file = e.target.files[0];
        if(typeof(file)==='undefined') return;
        if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
            if(e.target.files[0].type!=='application/x-zip-compressed'){
                setAlert({...alert,source:t('Vui lòng upload file dữ liệu')});
                return;
            }
        } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
            if(e.target.files[0].name.indexOf('.gzip') === -1){
                setAlert({...alert,source:t('Vui lòng upload file dữ liệu')});
                return;
            }
        }
        setLocalFileName(file.name);
        setAlert({...alert,source:''});
        const reader = new FileReader();
        reader.onload = () =>{
            if(reader.readyState === 2){
                const file_source = reader.result.slice(reader.result.indexOf('base64,')+7);
                setSource(file_source);
            }
        }
        reader.readAsDataURL(e.target.files[0]);
    }

    const onFileNameChange = (e,newValue) =>{
        if(newValue === null){
            setCloudFileName('');
            setAlert({...alert,file_name:t('Vui lòng chọn file')});
            return;
        }
        setCloudFileName(newValue);
        setAlert({...alert,file_name:''});
    }
    const onPasswordChange = (e) =>{
        const value = e.target.value;
        setPassword(value);
        setAlert({...alert,password:''});
    }
    const restoreFile = async(file_source,pw)=>{
        var data = {};
        if(pw==='') data = {source:file_source};
        else data = {source:file_source,password:pw};
        if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
            await axiosClient.post('/table/import/file',JSON.stringify(data),{
                headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
            })
        } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
            await axiosClient.post('/table/restore',{source:file_source, type: 'local'},{
                headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
            })
        }
    }
    const restoreOnCloud = async(file_name,pw) =>{ 
        var data = {};
        if(pw==='') data = {file_name:file_name};
        else data = {file_name:file_name,password:pw};
        if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
            await axiosClient.post('/table/import/s3',JSON.stringify(data),{
                headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
            })
        } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
            await axiosClient.post('/table/restore',{file_name:file_name, type: 'server'},{
                headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
            })
        }      
    }
    const onRestoreClick = () =>{
        if(type==='local'){
            if(source==='') return setAlert({...alert,source:t('Vui lòng nhập file'),file_name:''});
            try {
                var mz = new Minizip(Buffer.from(source, 'base64'));
                mz.extract('ExportedData.json', { password: password});
            } catch (error) {
                return setAlert({...alert,password:t('Mật khẩu không chính xác'),file_name:''});
            }
        }
        if(type==='cloud'){
            if(cloudFileName==='') return setAlert({...alert,file_name:t('Vui lòng nhập file'),source:''});
            if(cloudFileName.indexOf('PwdRequired')!== -1 && password === '') return setAlert({...alert,password:t('File yêu cầu mật khẩu'),source:''});
        }
        setIsRestore(true);
    }
    const onConfirmRestoreClick = async() =>{ 
        try {
            setIsRestoring(true);
            if(type==='local')
                await restoreFile(source,password);
            else{
                await restoreOnCloud(cloudFileName,password);
            }
            setTimeout(() => {
                setIsRestoring(false);
                setIsRestore(false);
                history.push('/maintainance');
            }, 1000);
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setIsFailed(true);
            setIsRestoring(false);
            setIsRestore(false);
        }   
    }
    const onAgreeClick = () =>{
        setIsSuccess(false);
        setIsFailed(false);
        closeBackupOrRestore();
    }

    const ConfirmRestoreDialog = () =>{
        return(
          <Dialog 
            open={isRestore}
            maxWidth="xl"
          >
            <DialogTitle className={classes.dialogTitleBackground}>
                <p className={classes.dialogTitle}>{t('Thông báo')}</p>
            </DialogTitle>
            <DialogContent style={{width:'448px',padding:'0',textAlign:'center',position:'relative'}}>
                <p className={classes.contentDialog}>{t('Hệ thống sẽ tạm ngưng trong quá trình khôi phục.')}<br />{t('Bạn có chắc chắn muốn khôi phục dữ liệu?')}</p>
                <CancelButton 
                  style={{marginLeft:'30px'}}
                  onClick={()=>setIsRestore(false)}
                  disabled={isRestoring} 
                >
                  {t('Trở về')}
                </CancelButton>
                <CreateButton 
                  style={{margin:'10px 20px'}}
                  onClick={onConfirmRestoreClick} 
                  disabled={isRestoring}
                >
                  {t('Đồng ý')}
                </CreateButton>
                {isRestoring && <CircularProgress style={{position:'absolute',top:'10%',left:'45%',color:'#0b59c8'}}/>}
            </DialogContent>
          </Dialog>
        )
    }

    const SuccessfulDialog = () =>{
        return(
          <Dialog 
            open={isSuccess}
            maxWidth="xl"
          >
            <DialogTitle className={classes.dialogTitleBackground}>
                <p className={classes.dialogTitle}>{t('Thông báo')}</p>
            </DialogTitle>
            <DialogContent style={{width:'448px',padding:'0',textAlign:'center'}}>
                <p className={classes.contentDialog}>{t('Đã khôi phục dữ liệu thành công!')}</p>
                <CancelButton 
                  style={{margin:'10px 0'}}
                  onClick={onAgreeClick} 
                >
                  {t('Đồng ý')}
                </CancelButton>
            </DialogContent>
          </Dialog>
        )
    }

    const FailedDialog = () =>{
        return(
          <Dialog 
            open={isFailed}
            maxWidth="xl"
          >
            <DialogTitle className={classes.errorColor}>
                <p className={classes.dialogTitle}>{t('Thông báo')}</p>
            </DialogTitle>
            <DialogContent style={{width:'448px',padding:'0',textAlign:'center'}}>
                <p className={classes.contentDialog}>{t('Khôi phục dữ liệu không thành công, vui lòng thử lại!')}</p>
                <ErrorButton 
                  style={{margin:'10px 0'}}
                  onClick={onAgreeClick} 
                >
                  {t('Đồng ý')}
                </ErrorButton>
            </DialogContent>
          </Dialog>
        )
    }

    return(
        <div style={{height:'80%',width:'100%',marginLeft:'16px',position:'relative'}}>
            {SuccessfulDialog()}
            {FailedDialog()}
            {ConfirmRestoreDialog()}
            <div style={{marginLeft:'16px'}}>
                
                {type==='cloud' &&
                <div className={clsx(classes.height,classes.inputBlock,classes.focus)} style={{height:'60px'}}>
                    <label className={classes.label}  style={{marginRight:'0px',fontSize:'16px',float:'left',position:'relative',top:'13px',left:'-20px'}} >{t('Chọn file')}:</label>
                    <Autocomplete
                        style={{position:'relative',top:'-15px',width:'320px',float:'left'}}
                        size="small" 
                        clearText={t("Xóa")}
                        noOptionsText={t("Không có dữ liệu")}
                        openText={t("Mở")}
                        options={backupNames}
                        renderInput={(params) => (
                            <TextField {...params} placeholder={t('Chọn file')}margin="normal" variant="outlined" style={{background:'#FFFFFF'}} error={alert.file_name!==''}/>
                        )}
                        onChange={(e,newValue)=>onFileNameChange(e,newValue)}
                    />
                    <p style={{marginLeft:'80px',color:'#f44336',fontSize:'0.75rem',position:'absolute',bottom:'0'}}>{alert.file_name}</p>
                </div>}
                {type==='local' && 
                <div className={classes.inputBlock} style={{clear:'both',height:'60px'}}>
                   <div style={{height:'50px'}}>
                        <label className={classes.label} style={{marginRight:'0px',float:'left',fontSize:'16px',position:'relative',top:'12px',left:'-20px'}} >{t('Chọn file')}:</label>
                        {process.env.REACT_APP_DEPLOY_ENV === 'aws' &&
                                <input accept=".zip" hidden id="icon-button-file" type="file" onChange={onBackupFileChange} onClick={(e)=>e.target.value=''}/>
                        }
                        {process.env.REACT_APP_DEPLOY_ENV === 'on-premise' &&
                                <input accept=".gzip" hidden id="icon-button-file" type="file" onChange={onBackupFileChange} onClick={(e)=>e.target.value=''}/>
                        }
                    
                        <Paper component="form" className={classes.root}>
                            <IconButton className={classes.iconButton} disabled>      
                                <DescriptionIcon fontSize="small"/>
                            </IconButton>
                            <InputBase
                                style={{width:'50%',flex:'1'}}
                                placeholder="Import file"
                                disabled={true}
                                value={localFileName}
                            />
                        </Paper>
                        <CancelButton 
                                variant="outlined" 
                                style={{height:'32px',float:'left',marginLeft:'10px',marginTop:'-2px'}}
                            >
                            <label htmlFor="icon-button-file" style={{margin:'10px 0'}}>
                                {t('Import')}
                            </label>
                        </CancelButton>
                        <p style={{marginLeft:'80px',color:'#f44336',fontSize:'0.75rem',clear:'both'}}>{alert.source}</p>
                    </div>
                </div>}
                {process.env.REACT_APP_DEPLOY_ENV !== 'on-premise' && <div className={classes.inputBlock} style={{clear:'both',display:'flex'}}>
                    <label style={{fontSize:'16px',paddingRight:'10px',paddingTop:'14px',position:'relative',left:'-10px'}} >{t('Mật khẩu')}:</label>
                    <div>
                        <TextField 
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={()=>setShowPassword(!showPassword)}
                                        >
                                        {showPassword ? <Visibility fontSize="small"/> : <VisibilityOff fontSize="small"/>}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            inputProps={{
                                style: {height:'11px'},
                            }}
                            size="small"
                            style={{background:'#FFFFFF',width:'320px'}}
                            type={showPassword?"text":"password"}
                            placeholder={t("Mật khẩu file sao lưu")} 
                            variant="outlined"
                            onChange={onPasswordChange}
                        />
                        <p style={{color:'#f44336',fontSize:'0.75rem',paddingTop:'5px'}}>{alert.password}</p>
                    </div>
                </div>}
                <div style={{clear:'both',paddingTop:'10px'}}>
                    <RadioGroup row onChange={onTypeChange} value={type}>
                        <span className={classes.label}  style={{position:'relative',top:'8px',marginLeft:'27px',marginRight:'20px',fontSize:'16px'}}>Type:</span>
                        <FormControlLabel value="local" control={<Radio color="primary" classes={{root: classes.radio, checked: classes.checked}}  size="small" />} label="Local" />
                        <FormControlLabel value="cloud" control={<Radio color="primary" classes={{root: classes.radio, checked: classes.checked}} size="small" />} label="Cloud" />
                    </RadioGroup>
                </div>
            </div>
            <CreateButton variant="contained" onClick={onRestoreClick} style={{height:'32px',margin:'0'}}>{t('Khôi phục')}</CreateButton>
        </div>
    )
}

export default Restore;