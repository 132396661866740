import React, {useEffect,useState, useRef} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField/TextField';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import Button from '@material-ui/core/Button/Button';
import { checkPassword, isEmpty } from '../../validation/CheckForm';
import {useHistory} from 'react-router-dom';
import md5 from 'md5';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PasswordStrengthBar from 'react-password-strength-bar';
import Cookies from 'js-cookie';
import Alert from '@material-ui/lab/Alert/Alert';
import clsx from 'clsx';
import zxcvbn from 'zxcvbn';
import TopBar from './TopBar';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import { updateReceptionist, getReceptionist } from '../../api/residentRegistrations';

const useStyles = makeStyles((theme)=>({
    font:{fontSize:'20px',background:'#FFFFFF',borderRadius:'6px',boxShadow:'5px 5px #f1f1f1'},
    content:{height:'92%',minWidth:'980px',display:'flex',justifyContent:'center',position:'relative',marginTop:'20px'},
    title:{fontWeight:'bolder',paddingLeft:'40%',paddingBottom:'22px'},
    label:{paddingBottom:'10px',fontSize: '14px',lineHeight: '140%',color: '#7A7171',display: 'inline-block',paddingTop:'10px',width:'40%',textAlign:'right',paddingRight:'5%'},
    dialogTitle:{textAlign:'center',fontSize:'20px',fontWeight:'600',lineHeight:'25px',color:'#FFFFFF'},
    contentDialog:{fontSize:'16px',height:'68px',borderBottom:'1px solid #EEEDF2',display:'flex',alignItems:'center',justifyContent:'center'},
    dialogTitleBackground:theme.primaryColor,
    errorDialogTitleBackground:theme.errorColor,
    alertStyle:{position:'absolute',right:'8px',top:'8px',padding:'0 10px',zIndex:'10'},
    overlay:{position:'fixed',width:'100%',height:'100%',top:'0',left:'0',background:'rgba(0,0,0,0.1)',zIndex:'1300'},
    focus:{
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': { 
                borderColor: theme.themeColor,
            },
        },
    },
    primaryColor:theme.primaryTextColor,
    secondaryTitle:theme.secondary_title,
}))
const CreateButton = withStyles(theme =>({
    root:theme.create_button
}))(Button);
const CancelButton = withStyles(theme =>({
    root:theme.cancel_button
}))(Button);
const ErrorButton = withStyles(theme=>({
    root:theme.error_button
}))(Button);

const MyReceptionist = props =>{
    const classes = useStyles();
    const [account,setAccount] = useState({});
    const [loading,setLoading] = useState(false);
    const [showEnterPassword,setShowEnterPassword] = useState(false);
    const [showReEnterPassword,setShowReEnterPassword] = useState(false);
    const [isEdit,setIsEdit] = useState(false);
    const [alert,setAlert] = useState({
        password:'',
        rePassword:'',
        display_name:'',
        success:'',
        error:'',
        currentPassword:''
    })
    const [currentPassword,setCurrentPassword] = useState('');
    const [showCurrentPassword,setShowCurrentPassword] = useState(false);
    const [password,setPassword] = useState('');
    const [rePassword,setRePassword] = useState('');
    const [isDialog,setIsDialog] = useState(false);
    const [isChanging,setIsChanging] = useState(false);
    const [isSuccessDialog,setIsSuccessDialog] = useState(false);
    const [isFailedDialog,setIsFailedDialog] = useState(false);
    const [isExpiredSession,setIsExpiredSession] = useState(false);
    const [permission,setPermission] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const {t} = useTranslation();
    const [error,setError] = useState('');
    const passwordRef = useRef();
    const rePasswordRef = useRef();

    useEffect(()=>{
        if(isExpiredSession){
          history.push('/receptionist-login');
          dispatch({type:'IS_LOGIN_AGAIN_TRUE'});
        } 
    },[isExpiredSession]);

    useEffect(()=>{
        if(Cookies.get('receptionistToken')!=='' && Cookies.get('receptionistToken')!==null && typeof(Cookies.get('receptionistToken'))!=='undefined') setPermission(true);
        else{
            setPermission(false);
            history.push('/receptionist-login'); 
        }
    },[localStorage.getItem('receptionistName')])

    const getDefaultValues = async()=>{
        try {
            var res
            if(process.env.REACT_APP_DEPLOY_ENV === 'aws')
                res = await getReceptionist({id:localStorage.getItem('receptionistID'),isReceptionist:true});
            else if(process.env.REACT_APP_DEPLOY_ENV === 'on-premise')
                res = await getReceptionist({id:localStorage.getItem('receptionistID')});
            const data = res.data.Items[0];
            setAccount({
                id:data.ID,
                username:data.UserName,
                password:data.Password,
                name:data.DisplayName,
                phone:data.Phone,
                block:data.BlockNumber
            });
            setIsDialog(false);
            setShowEnterPassword(false);
            setShowReEnterPassword(false);
            setShowCurrentPassword(false);
            setAlert({...alert,password:'',rePassword:'',display_name:''});
            setPassword('');
            setRePassword('');
        } catch (err) {
            if (typeof(err.response)!=='undefined' && err.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
        }
        setLoading(true);
    }
    
    useEffect(()=>{
        getDefaultValues();
    },[loading])


    const onEditClick = () =>{
        setIsEdit(true);
    }
    const showEnterPasswordClick = () =>{
        setShowEnterPassword(!showEnterPassword);
    }
    const showReEnterPasswordClick = () =>{
        setShowReEnterPassword(!showReEnterPassword);
    }
    const showCurrentPasswordClick = () =>{
        setShowCurrentPassword(!showCurrentPassword);
    }
    const onCancelClick = () =>{
        setIsEdit(false);
        setLoading(false);
    }

    const onCurrentPasswordChange = (e) =>{
        const value = e.target.value;
        setCurrentPassword(value);
        if(isEmpty(value)) setAlert({...alert,currentPassword:t("Không được để trống")});
        else setAlert({...alert,currentPassword:''});
    }

    const onPasswordChange = (e) =>{
        const value = e.target.value;
        setPassword(value);
        if(isEmpty(value)){
            setAlert({...alert,password:t("Không được để trống")});
        }else if(!checkPassword(value)){
            setAlert({...alert,password:t("Tối thiểu 8 ký tự bao gồm chữ HOA, chữ thường, số và ký tự đặc biệt")});
        }else{
            setAlert({...alert,password:''});
        }
        if(passwordRef.current) clearTimeout(passwordRef.current);
        passwordRef.current = setTimeout(() => {
            const result = zxcvbn(password);
            if(result.feedback.warning !== '') return setAlert({...alert,password:t('Đã trùng mật khẩu phổ biến')});
            if(rePassword === '') return;
            if(value === rePassword) setAlert({...alert,rePassword:''});
            else setAlert({...alert,rePassword:t('Mật khẩu không giống nhau')});
        }, 500);
    }

    const onRePasswordChange = (e) =>{
        const value = e.target.value;
        setRePassword(value);
        if(isEmpty(value)){
            setAlert({...alert,rePassword:t("Không được để trống")});
        }else{
            setAlert({...alert,rePassword:''});
            if(rePasswordRef.current) clearTimeout(rePasswordRef.current);
            rePasswordRef.current = setTimeout(() => {
                if(value !== password) setAlert({...alert,rePassword:t('Mật khẩu không giống nhau')});
            }, 500);
        }   
    }

    const onSaveClick = async() =>{
        if(isEmpty(currentPassword)) return setAlert({...alert,currentPassword:t("Không được để trống")});
        if(alert.currentPassword !== '') return;
        if(isEmpty(password)) return setAlert({...alert,password:t("Không được để trống")});
        if(alert.password !== '') return;
        if(isEmpty(rePassword)) return setAlert({...alert,rePassword:t("Không được để trống")});
        if(password !== rePassword) return setAlert({...alert,rePassword:t("Mật khẩu không giống nhau")});
        if(alert.rePassword !== '') return;
        if(md5(currentPassword) === account.password){
            if(currentPassword===password){
                setError(t("Mật khẩu mới không được giống mật khẩu cũ!"));
                setIsFailedDialog(true);
            }else setIsDialog(true);
        } 
        else{
            setError(t("Mật khẩu hiện tại của bạn không chính xác!"));
            setIsFailedDialog(true);
        } 
        
    }

    const onConfirmClick = async() =>{
        setIsChanging(true);
        try {
            await updateReceptionist({...account,password:md5(password),isReceptionist:true});
            setIsSuccessDialog(true);
        } catch (err) {
            if (typeof(err.response)!=='undefined'){
                if(err.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            }
            else setError(t("Thay đổi mật khẩu không thành công!")) 
            setIsFailedDialog(true);
        }
        setIsDialog(false);
        setIsChanging(false);
    }

    const onBackDialog = () =>{
        setIsDialog(false);
    }
    const onSuccessClick = () =>{
        history.push('receptionist-login');
        localStorage.setItem('receptionist_password','');
        setIsSuccessDialog(false);
    }
    const onFailedClick = () =>{
        setIsFailedDialog(false);
    }

    const onAlertClose = () =>{
        setAlert({...alert,success:'',error:''});
    }

    useEffect(()=>{
        if(alert.success !=='' || alert.error !== ''){
            setTimeout(() => {
                onAlertClose();
            }, 5000);
        }
    },[alert])
    const getConfirmDialog = () =>{
        return(
            <Dialog 
                open={isDialog}
                maxWidth="xl"
            >
                <DialogTitle className={classes.dialogTitleBackground}>
                    <p className={classes.dialogTitle}>{t("Thông báo")}</p>
                </DialogTitle>
                <DialogContent style={{width:'448px',padding:'0',position:'relative',textAlign:'center'}}>
                    <div className={classes.contentDialog}>
                        <p >{t("Bạn có chắc chắn muốn thay đổi mật khẩu?")}</p>
                    </div>
                    <CancelButton variant="outlined" disabled={isChanging} style={{margin:'10px 0'}} onClick={onBackDialog} >{t("Hủy")}</CancelButton>
                    <CreateButton variant="contained" disabled={isChanging} style={{margin:'10px 16px'}} onClick={onConfirmClick} >{t("Đồng ý")}</CreateButton>

                    {isChanging && <CircularProgress style={{position:'absolute',left:'43%',top:'10%',color:'#0b59c8'}}/>}
                </DialogContent>
                
            </Dialog>
        )
    }

    const getSuccessDialog = ()=>{
        return(
            <Dialog 
                open={isSuccessDialog}
                maxWidth="xl"
            >
                <DialogTitle className={classes.dialogTitleBackground}>
                    <p className={classes.dialogTitle}>{t("Thông báo")}</p>
                </DialogTitle>
                <DialogContent style={{width:'448px',padding:'0',textAlign:'center'}}>
                    <p className={classes.contentDialog}>{t("Thay đổi mật khẩu thành công, vui lòng đăng nhập lại!")}</p>
                    <CancelButton variant="outlined" style={{margin:'10px 0'}} onClick={onSuccessClick}>{t("Đồng ý")}</CancelButton>
                </DialogContent>
            </Dialog>
        )
    }
    const getFailedDialog = ()=>{
        return(
            <Dialog 
                open={isFailedDialog}
                maxWidth="xl"
            >
                <DialogTitle className={classes.errorDialogTitleBackground}>
                    <p className={classes.dialogTitle}>{t("Thông báo")}</p>
                </DialogTitle>
                <DialogContent style={{width:'448px',padding:'0',textAlign:'center'}}>
                    <p className={classes.contentDialog}>{error}</p>
                    <ErrorButton variant="outlined" style={{margin:'10px 0'}} onClick={onFailedClick}>{t("Đồng ý")}</ErrorButton>
                </DialogContent>

            </Dialog>
        )
    }

    return(
        <div className={clsx(classes.focus,classes.content)} style={{position:'relative'}}>       
            {permission && <TopBar />}
            {getConfirmDialog()}
            {getSuccessDialog()}
            {getFailedDialog()}
            
            {isChanging && <div className={classes.overlay}></div>}
            {!loading && <CircularProgress style={{left:'50%',top:'10%',color:'#0b59c8',position:'absolute'}}/>}
            {loading && permission && 
            <div  style={{height:'100%',width:'96%',marginTop:'50px'}}> 
                <div style={{paddingBottom:'8px',marginTop:'15px',position:'relative',left:'-9px'}}>
                    <IconButton style={{float:'left',padding:'2px',marginRight:'5px'}}  onClick={()=>history.push('register')} >
                        <ArrowBackIosOutlinedIcon className={classes.primaryColor} style={{width:'28px',height:'28px'}} />
                    </IconButton>
                    <p className={classes.secondaryTitle}>{t("CHI TIẾT TÀI KHOẢN")}</p>
                </div>
                <div className={classes.font} style={{height:'100%',width:'100%',padding:'20px 0',position:'relative'}}>
                    {alert.success !== '' &&<Alert className={classes.alertStyle} icon={false} onClose={onAlertClose}>{alert.success}</Alert>}
                    { alert.error !=='' && <Alert className={classes.alertStyle} severity="error" onClose={onAlertClose}>{alert.error}</Alert>}      
                    {/* <h2 className={classes.title}>{t('Tài khoản')}</h2> */}
                    <div style={{display:'flex',height:'50px'}}>
                        <p style={{width:'40%',textAlign:'right',paddingRight:'5%',fontWeight:'bold'}}>{t('Tên lễ tân')}</p>
                        <p style={{width:'30%'}}>{account.name}</p>      
                    </div>
                    <div style={{display:'flex',height:'50px'}}>
                        <p style={{width:'40%',textAlign:'right',paddingRight:'5%',fontWeight:'bold'}}>{t('Số điện thoại')}</p>
                        <p style={{width:'60%'}}>{account.phone}</p>
                    </div>
                    <div style={{display:'flex',height:'50px'}}>
                        <p style={{width:'40%',textAlign:'right',paddingRight:'5%',fontWeight:'bold'}}>{t('Block')}</p>
                        <p style={{width:'60%'}}>{account.block}</p>
                    </div>
                    {!isEdit && <div style={{display:'flex',height:'50px'}}>
                        <p style={{width:'40%',textAlign:'right',paddingRight:'5%',fontWeight:'bold'}}>{t('Mật khẩu')}</p>
                        <p style={{width:'35%'}}>**************************</p>
                        <p className={classes.primaryColor} style={{width:'25%',textDecoration:'underline',cursor:'pointer'}} onClick={onEditClick}>{t('Thay đổi')}</p>
                    </div>}
                    {isEdit &&
                        <div>
                            <div style={{width:'100%',margin:'auto',height:'90px',display:'flex'}}>
                                <p style={{width:'40%',textAlign:'right',paddingRight:'5%',fontWeight:'bold'}}>{t('Mật khẩu hiện tại')}</p>
                                <div style={{width:'30%'}}>
                                    <TextField
                                        InputProps={{
                                            endAdornment: (    
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={showCurrentPasswordClick}
                                                    >
                                                        {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        placeholder={t('Nhập lại mật khẩu')}
                                        variant="outlined"
                                        style={{width:'100%'}}
                                        size="small"
                                        type={showCurrentPassword?"text":"password"}
                                        onChange={onCurrentPasswordChange}
                                        error = {alert.currentPassword !== ''}
                                    />
                                    <p style={{color:'#f44336',paddingTop:'3px',fontSize:'0.75rem'}}>{alert.currentPassword}</p>
                                </div>
                            </div>
                            <div style={{width:'100%',margin:'auto',height:'90px',display:'flex'}}>
                                <p style={{width:'40%',textAlign:'right',paddingRight:'5%',fontWeight:'bold'}}>{t('Mật khẩu mới')}</p>
                                <div style={{width:'30%'}}>
                                    <TextField
                                        InputProps={{
                                            endAdornment: (    
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={showEnterPasswordClick}
                                                    >
                                                        {showEnterPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        placeholder={t('Mật khẩu mới')}
                                        variant="outlined"
                                        style={{width:'100%'}}
                                        size="small"
                                        type={showEnterPassword?"text":"password"}
                                        onChange={onPasswordChange}
                                        error = {alert.password !== ''}
                                    />
                                    {password!=='' && 
                                    <PasswordStrengthBar 
                                        scoreWords={['','','','']} 
                                        shortScoreWord='' 
                                        password={password} 
                                    />}
                                    <p style={{color:'#f44336',paddingTop:'3px',fontSize:'0.75rem',width:'200%'}}>{alert.password}</p>
                                </div>
                            </div>
                            <div style={{width:'100%',margin:'auto',height:'70px',display:'flex'}}>
                                <p style={{width:'40%',textAlign:'right',paddingRight:'5%',fontWeight:'bold'}}>{t('Nhập lại mật khẩu')}</p>
                                <div style={{width:'30%'}}>
                                    <TextField
                                        InputProps={{
                                            endAdornment: (    
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={showReEnterPasswordClick}
                                                    >
                                                        {showReEnterPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        placeholder={t('Nhập lại mật khẩu')}
                                        variant="outlined"
                                        style={{width:'100%'}}
                                        size="small"
                                        type={showReEnterPassword?"text":"password"}
                                        onChange={onRePasswordChange}
                                        error = {alert.rePassword !== ''}
                                    />
                                    <p style={{color:'#f44336',paddingTop:'3px',fontSize:'0.75rem'}}>{alert.rePassword}</p>
                                </div>
                            </div>
                            <div style={{display:'flex'}}>
                                <p style={{width:'40%',paddingRight:'5%'}}></p>
                                <CancelButton 
                                    style={{margin:'10px 0',width:'80px'}}
                                    onClick={onCancelClick} 
                                >
                                    {t('Hủy')}
                                </CancelButton>
                                <CreateButton 
                                    style={{margin:'10px 0 10px 24px',width:'50px'}}
                                    onClick={onSaveClick} 
                                >
                                    {t('Lưu')}
                                </CreateButton>
                            </div>
                        </div>
                    }
                </div>
            </div>
            }
        </div>
    )
}

export default MyReceptionist;