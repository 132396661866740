import { FormHelperText, Select } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useStyles } from './styles';

export default function SelectCpn({ className, helperText, isRounded, children, ...otherProps }) {
    const classes = useStyles();

    return (
        <>
            <Select
                displayEmpty
                variant='outlined'
                className={clsx(
                    className,
                    classes.cpnSelect,
                    isRounded && classes.rounded
                )}
                {...otherProps}
            >
                {children}
            </Select>
            <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>
        </>
    )
}
