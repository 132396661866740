import { TextField } from '@material-ui/core';
import { PhotoCamera } from '@mui/icons-material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { Checkbox, FormControlLabel } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { apiTlockerProfileGet, apiTlockerProfilePost, apiTlockerProfilePut } from '../../../../api/cabinets';
import ErrorAlert from '../../../../components/alert/ErrorAlert';
import { CreateButton } from '../../../../components/button/createButton';
import { Confirm } from '../../../../components/dialog';
import { PageLoading } from '../../../../components/loading';
import { PrimaryTitle } from '../../../../components/title';
import { checkImage } from '../../../../validation/CheckForm';
import { useStyles } from './styles';

export default function AddEditDevice({ changeResponseStatus }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const { ProfileName } = useParams();

    const [state, setState] = useState({
        isCheckedSystemImg: true,
        logoBase64: '',
        oldProfileName: ProfileName,
        logo: '',
        responseStatus: {
            succ: '',
            err: ''
        },
        confirmMess: t("Bạn có muốn thêm cấu hình thiết bị"),
        isLoading: false,
        isConfirm: false,
        isConfirmLoading: false,
    });
    const [validate, setValidate] = useState({
        profileName: '',
        logo: ''
    })

    const bodyRequestRef = useRef({
        profileName: '',
        profileDescription: '',
        overideSystemLogo: true,
    })
    const profileNameRef = useRef();
    const profileDescriptionRef = useRef();

    const fetchtApiTlockerProfilePost = async (body) => {
        setState(prev => ({
            ...prev,
            isConfirmLoading: true
        }));

        try {
            await apiTlockerProfilePost(body);
            changeResponseStatus(t("Thêm cấu hình thiết bị thành công"));

            handleBack();
        } catch (error) {
            handleChangeResponseStatus("", t("Thêm không thành công"))
        }

        handleCloseConfirm();
    }

    const fetchApiTlockerProfileGet = async (body) => {
        setState(prev => ({
            ...prev,
            isLoading: true
        }))

        try {
            const { data } = await apiTlockerProfileGet(body);

            if (data?.Items.length > 0) {
                profileNameRef.current.value = data?.Items[0].ProfileName;
                profileDescriptionRef.current.value = data?.Items[0].Description;

                setState(prev => ({
                    ...prev,
                    isCheckedSystemImg: !data?.Items[0].Payload?.overideSystemLogo,
                    logo: data?.Items[0].Payload?.profileLogo
                }))
            }
        } catch (error) {
            handleChangeResponseStatus("", t("Tải thông tin cấu hình thất bại!"))
        }

        setState(prev => ({
            ...prev,
            isLoading: false
        }))
    }

    const fetchApiTlockerProfilePut = async (body) => {
        setState(prev => ({
            ...prev,
            isConfirmLoading: true
        }));

        try {
            await apiTlockerProfilePut(body);

            changeResponseStatus(t("Cập nhật cấu hình thiết bị thành công"));
            fetchApiTlockerProfileGet({ profileName: ProfileName });
        } catch (error) {
            handleChangeResponseStatus("", t("Cập nhật không thành công"))
        }

        handleCloseConfirm();
    }

    const handleChangeResponseStatus = (succ = '', err = '') => {
        setState(prev => ({
            ...prev,
            responseStatus: {
                succ,
                err
            }
        }))
    }

    const handleBack = () => {
        history.push('/admin/cabinet/device-configuration')
    }

    const handleOnChangeCheckBox = (e) => {
        setState(prev => ({
            ...prev,
            isCheckedSystemImg: e.target.checked
        }))
    }

    const handleOnLogoChange = (e) => {
        if (typeof (e.target.files[0]) === 'undefined') return;
        if (!checkImage(e.target.files[0].type)) {
            handleChangeResponseStatus("", t("Chọn tệp thất bại. Vui lòng chọn tệp tin hình ảnh!"));
            return;
        }
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                const source = reader.result.slice(reader.result.indexOf('base64,') + 7);
                const logoBase64 = source;

                setState(prev => ({
                    ...prev,
                    logoBase64
                }));
            }
        }
        reader.readAsDataURL(e.target.files[0]);
    }

    const handleOnSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);

        bodyRequestRef.current.profileName = formData.get('profileName') || profileNameRef.current.value;
        bodyRequestRef.current.profileDescription = formData.get('profileDescription');
        bodyRequestRef.current.overideSystemLogo = !state.isCheckedSystemImg;

        if (!state.isCheckedSystemImg) {
            bodyRequestRef.current.logo = state.logoBase64;

            if (!bodyRequestRef.current.logo) {
                delete bodyRequestRef.current.logo;
            }
        }

        //validate
        if (!bodyRequestRef.current.profileName) {
            setValidate(prev => ({
                ...prev,
                profileName: t("Không được để trống")
            }))
            return;
        } else {
            setValidate(prev => ({
                ...prev,
                profileName: ""
            }))
        }

        if ((!ProfileName || !state.logo) && !state.isCheckedSystemImg && !bodyRequestRef.current.logo) {
            handleChangeResponseStatus("", t("Vui lòng chọn hình ảnh"));
            return;
        }

        //pass validate
        setState(prev => ({
            ...prev,
            isConfirm: true,
            confirmMess: ProfileName ? t("Bạn có chắc muốn cập nhật hồ sơ?") : t("Bạn có muốn thêm cấu hình thiết bị?")
        }))
    }

    const handleCloseConfirm = () => {
        setState(prev => ({
            ...prev,
            isConfirm: false,
            isConfirmLoading: false
        }))
    }

    const handleOnConfirm = () => {
        if (ProfileName) {
            fetchApiTlockerProfilePut(bodyRequestRef.current);
            return
        }

        fetchtApiTlockerProfilePost(bodyRequestRef.current);
    }

    useEffect(() => {
        if (!ProfileName) return;

        fetchApiTlockerProfileGet({ profileName: ProfileName })
    }, [ProfileName])

    return (
        <div>
        <form id="form-id" onSubmit={handleOnSubmit}>
                <div className={classes.wrapHead}>
                    <div className={classes.wrapTitle}>
                        <div
                            className={classes.wrapIconback}
                            onClick={handleBack}
                        >
                            <ArrowBackIosNewOutlinedIcon />
                        </div>
                        <PrimaryTitle>
                            {ProfileName ? t("Chỉnh sửa cấu hình thiết bị") : t("Thêm cấu hình thiết bị")}
                        </PrimaryTitle>
                    </div>
                    <div>
                        <CreateButton
                            type="submit"
                        >
                            {ProfileName ? t("Cập nhật") : t("Thêm")}
                        </CreateButton>
                    </div>
                </div>

                {state.isLoading && (
                    <PageLoading />
                )}

                <div
                    className={classes.contain}
                    style={{
                        height: state.isLoading && 0,
                        overflow: 'hidden'
                    }}
                >
                    <div className={classes.wrapLeftForm}>
                        <div>
                            <label htmlFor="profileName">
                                {t("Tên cấu hình")}
                                <span className={classes.labelForce}>(*)</span>
                            </label>
                            <br />
                            <TextField
                                inputRef={profileNameRef}
                                name="profileName"
                                size="small"
                                variant='outlined'
                                disabled={!!ProfileName}
                                helperText={validate.profileName}
                                error={!!validate.profileName}
                                className={classes.textField}
                            />
                        </div>

                        <div>
                            <label htmlFor="profileDescription">{t("Mô tả")}</label>
                            <br />
                            <TextField
                                inputRef={profileDescriptionRef}
                                name="profileDescription"
                                size="small"
                                variant='outlined'
                                className={classes.textField}
                            />
                        </div>
                    </div>

                    <div className={classes.wrapRightForm}>
                        <label htmlFor="logo">{t("Logo cho thiết bị")}</label>
                        <br />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.isCheckedSystemImg}
                                    onChange={handleOnChangeCheckBox}
                                />
                            }
                            label={t("Sử dụng ảnh của hệ thống")}
                            className={classes.checkbox}
                        />

                        {!state.isCheckedSystemImg && (
                            <div className={classes.wrapImg}>
                                {state.logoBase64 && (
                                    <img
                                        src={`data:image/png;base64, ${state.logoBase64}`}
                                        alt="device-img"
                                        width="100%"
                                        height="100%"
                                    />
                                )}

                                {state.logo && (
                                    <img
                                        src={state.logo}
                                        alt="device-img"
                                        width="100%"
                                        height="100%"
                                    />
                                )}

                                <div className={classes.wrapInputFile}>
                                    <label
                                        htmlFor="icon-button-file"
                                        className={classes.btnPhotoLabel}
                                    >
                                        <div className={classes.btnPhoto}>
                                            <PhotoCamera
                                                style={{
                                                    width: '30px',
                                                    height: '30px',
                                                    color: 'grey'
                                                }}
                                            />
                                        </div>
                                    </label>
                                    <input
                                        id="icon-button-file"
                                        accept="image/*"
                                        hidden
                                        name="logo"
                                        type="file"
                                        onChange={handleOnLogoChange}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </form>

            <ErrorAlert
                message={state.responseStatus.err}
                onCloseDialog={() => handleChangeResponseStatus()}
            />

            <Confirm
                isOpen={state.isConfirm}
                handleClose={handleCloseConfirm}
                isLoading={state.isConfirmLoading}
                mess={state.confirmMess}
                onConfirm={handleOnConfirm}
            />
        </div>
    )
}
