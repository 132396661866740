import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import { useTranslation } from 'react-i18next';

const ErrorBtn = withStyles(theme => ({ root: theme.error_button }))(Button);

const useStyles = makeStyles(theme => ({
    dialogFailedTitleBackground: theme.errorColor,
    dialogTitle: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '25px',
        color: '#FFFFFF',
    },
    contentDialog: {
        fontSize: '16px!important',
        height: '68px',
        borderBottom: '1px solid #EEEDF2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}))

export const FailedDialog = ({
    isOpen, mess, onConfirm
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Dialog
            open={isOpen}
            maxWidth="xl"
        >
            <DialogTitle className={classes.dialogFailedTitleBackground}>
                <p className={classes.dialogTitle}>{t('Thông báo')}</p>
            </DialogTitle>
            <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center' }}>
                <p className={classes.contentDialog}>{mess}</p>
                <ErrorBtn style={{ margin: '10px 0' }} onClick={onConfirm}>{t('Đồng ý')}</ErrorBtn>
            </DialogContent>
        </Dialog>
    )
}