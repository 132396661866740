import AxiosClient from "../config/AxiosClient";

export const apiUpdateconfig = async (requestBody) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/updateconfig';
    } else {
        url = '/configs/update';
    }
    return await AxiosClient.post(url, requestBody);
}