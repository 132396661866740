import makeStyles from '@material-ui/styles/makeStyles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    root: theme.dataGridStyle,
    cabinetDetails: {
        minHeight: "100%"
    },
    head: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: "22px"
    },
    wrapTitle: {
        display: "flex",
        alignItems: "center",

        "& svg": {
            color: "#0b59c8",
            cursor: "pointer"
        },

        "& p": {
            marginLeft: "1rem"
        }
    },
    wrapLoading: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 210px)"
    },
    contain: {
        display: "flex",
        justifyContent: "space-between",
        minHeight: "calc(100vh - 208px)",
    },
    containColumn: {
        borderRadius: "8px",
        padding: "1rem 1rem 0",
        width: "32%",
        background: "rgb(0, 0, 0, 0.03)"
    },
    infoTitle: {
        paddingBottom: "1rem",
        fontSize: "20px",
        fontWeight: "bold"
    },
    label: {
        color: "#616161",
        marginBottom: "0.25rem"
    },
    info: {
        fontWeight: "400",
        fontSize: '16px'
    },
    spaceWrap: {
        marginBottom: "1rem"
    }
}));