import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { CircularProgress, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { apiCabinetCellTypesGet, apiCabinetCellTypesUpdate } from '../../../../../api/cabinets';
import ErrorAlert from '../../../../../components/alert/ErrorAlert';
import { CreateButton } from '../../../../../components/button';
import CancelButton from '../../../../../components/button/cancelButton';
import { Confirm } from '../../../../../components/dialog';
import SelectCpn from '../../../../../components/input/select/select';
import { PrimaryTitle } from '../../../../../components/title';
import { CABINET_ROUTE_PATHS } from '../../../../../constants/cabinet';
import { deepCloneArr } from '../../../../../helper/deepClone';
import { useStyles } from './styles';

const CanbinSection = ({
    cabinType,
    cabinTimeType,
    getPriceValueFromCabinSection,
    getPriceFromCabinSection,
    priceValue,
    reRender
}) => {
    const getPriceValueFromCabinFeeChagne = (priceValueToSet) => {
        getPriceValueFromCabinSection(priceValueToSet);
    };
    const getPriceFromCabinWithoutChange = (event) => {
        getPriceFromCabinSection(event);
    };

    return (
        <div>
            {cabinType === "scale" ? (
                <CabinFeeChange
                    setPriceValue={getPriceValueFromCabinFeeChagne}
                    priceValue={priceValue}
                    reRender={reRender}
                />
            ) : (
                <></>
            )}
            {cabinType === "turn" || cabinType === "hourly" ? (
                <CabinFeeWhithoutChange
                    time={cabinTimeType}
                    setPrice={getPriceFromCabinWithoutChange}
                    priceValue={priceValue}
                />
            ) : (
                <></>
            )}
        </div>
    );
};

const CustomInput = (props) => {
    return (
        <TextField
            {...props}
            variant='outlined'
            autoComplete='off'
            InputProps={{
                endAdornment: (
                    <InputAdornment position='end'>
                        {props?.affix}
                    </InputAdornment>
                ),
                style: {
                    background: '#FFFFFF',
                    height: '40px',
                },
            }}
        />
    );
};

const CabinFeeChange = ({ setPriceValue, priceValue, reRender }) => {
    const classes = useStyles();
    const [feeChange, setFeeChange] = useState([]);
    const [addtimeline, setAddtimeline] = useState([]);
    const [validate, setValidate] = useState([]);

    const { t, i18n } = useTranslation();

    const onClickAddTimeLine = () => {
        setValidate(prev => [...prev, {
            hour: "",
            fee: ""
        }]);
        setAddtimeline((addtimeline) => [...addtimeline, { "": "" }]);
        setFeeChange(prev => [
            ...prev,
            { "": "" }
        ])
    }

    const onkeyChange = (event) => {
        const { name: index, value } = event.target;
        const temp = [...feeChange];
        let result = {};

        temp[index] = { [value]: Object.values(temp[index])[0] };
        setFeeChange(temp);
        setAddtimeline(temp);

        //validate
        if (!value || isNaN(value) || Number(value) < 1 || !Number.isInteger(Number(value))) {
            const validateTemp = [...validate];
            validateTemp[index].hour = t("Phải là số nguyên và lớn hơn 0");
            setValidate(prev => [
                ...prev,
                validateTemp
            ]);

            result = null;
        } else {
            const validateTemp = [...validate];
            validateTemp[index].hour = "";
            setValidate(prev => [
                ...prev,
                validateTemp
            ]);

            //get data to set for addSize
            temp.forEach((item, index) => {
                for (let key in item) {
                    result[key] = item[key];
                }
            });
        }


        setPriceValue(result);
    };

    const onValueChange = (event) => {
        const { name: index, value } = event.target;
        const temp = [...feeChange];
        let result = {};

        Object.keys(temp[index]).forEach((key) => {
            temp[index][key] = value;
        });
        setFeeChange(temp);
        setAddtimeline(temp);

        //validate
        if (!value || isNaN(value) || Number(value) < 1000) {
            const validateTemp = [...validate];
            validateTemp[index].fee = t("Phải là số và lớn hơn 1000");
            setValidate(prev => [
                ...prev,
                validateTemp
            ])

            result = null;
        } else {
            const validateTemp = [...validate];
            validateTemp[index].fee = "";
            setValidate(prev => [
                ...prev,
                validateTemp
            ]);

            //get result to addSize
            temp.forEach((item, index) => {
                for (let key in item) {
                    if (Number(key) && Number(item[key])) {
                        result[key] = item[key];
                    }
                }
            });
        }


        setPriceValue(result);
    };

    const onClickDeleteTimeLine = (index) => {
        if (index >= 0) {
            const temp = [...feeChange];
            temp.splice(index, 1);

            setFeeChange(temp);
            setAddtimeline(temp);
        }
    }

    useEffect(() => {
        if (priceValue) {
            const addtimeline = [];
            const validate = [];
            const feeChange = [];

            for (let key in priceValue) {
                addtimeline.push({
                    [key]: priceValue[key]
                });

                validate.push({
                    hour: "",
                    fee: ""
                });

                feeChange.push({
                    [key]: priceValue[key],
                })
            }

            setAddtimeline(addtimeline);
            setValidate(validate);
            setFeeChange(feeChange);
        }
    }, [reRender])

    return (
        <div className={clsx(classes.cabin)}>
            <Grid container spacing={2}>
                <Grid item xs={5}>
                    <Typography sx={{ fontWeight: 600 }}>
                        {t('Thời gian')}
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography sx={{ fontWeight: 600 }}>
                        {t('Phí thuê')}
                    </Typography>
                </Grid>
                {addtimeline.map((e, i) => {
                    return (
                        <React.Fragment key={`${i}`}>
                            <Grid item xs={4}>
                                <CustomInput
                                    type="number"
                                    error={!!validate[i].hour}
                                    helperText={validate[i].hour}
                                    affix={i18n.language === "vi" ? t("Giờ") : `${t("Giờ")}(s)`}
                                    name={`${i}`}
                                    value={Object.keys(e)[0]}
                                    onChange={(event) => onkeyChange(event)}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Box style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    paddingTop: "0.5rem"
                                }}>-</Box>
                            </Grid>
                            <Grid item xs={7}>
                                <CustomInput
                                    type="number"
                                    // disabled={!!!Object.keys(feeChange[i])[0]}
                                    error={!!validate[i].fee}
                                    helperText={validate[i].fee}
                                    affix='VNĐ'
                                    name={`${i}`}
                                    value={e[Object.keys(e)[0]]}
                                    onChange={(event) => onValueChange(event)}
                                />

                                <RemoveCircleOutlineOutlinedIcon
                                    style={{
                                        margin: "0.5rem 0 0 1.5rem",
                                        color: "#DB1A1A",
                                        cursor: "pointer"
                                    }}
                                    onClick={() => onClickDeleteTimeLine(i)}
                                />
                            </Grid>
                        </React.Fragment>
                    );
                })}
            </Grid>
            <Box
                sx={{
                    display: 'flex',
                    alignItem: 'center',
                    marginTop: '1.5rem',
                    cursor: 'pointer',
                }}
                onClick={onClickAddTimeLine}
            >
                <AddIcon
                    sx={{
                        position: 'relative',
                        marginTop: '1px',
                        fontSize: 'medium',
                    }}
                />
                <Box
                    sx={{
                        display: 'flex',
                    }}
                >
                    {t('Thêm mốc thời gian')}
                </Box>
            </Box>
            <Box>
                <Typography
                    sx={{
                        fontWeight: '600',
                        fontStyle: 'italic',
                        fontSize: '14px',
                    }}
                >
                    {t("Lưu ý")}: {t("Hệ thống sẽ ngưng tính phí khi hàng ở trong tủ quá mốc thời gian lớn nhất được cài đặt.")}
                </Typography>
            </Box>
        </div>
    );
};
const CabinFeeWhithoutChange = ({ time, setPrice, priceValue }) => {
    const [validateAmount, setValidateAmount] = useState("");

    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const priceChange = (event) => {
        const value = event.target.value;

        if (!value || isNaN(value) || Number(value) < 1000) {
            setValidateAmount(t("Phải là số và lớn hơn 1000"));
        } else {
            setPrice(event.target.value);
            setValidateAmount("");
        }
    };

    return (
        <div className={clsx(classes.cabin)}>
            <Grid container spacing={2} sx={{ padding: '1rem 0 1rem 0' }}>
                <Grid item xs={7}>
                    <CustomInput
                        type="number"
                        error={!!validateAmount}
                        helperText={validateAmount}
                        placeholder={t('Nhập số tiền')}
                        affix='VNĐ'
                        defaultValue={priceValue || ""}
                        onChange={(event) => priceChange(event)}
                    />
                </Grid>
                <Grid
                    item
                    xs={5}
                    sx={{ display: 'flex', alignItems: 'center' }}
                >
                    /1 {t(time)}
                </Grid>
            </Grid>
        </div>
    );
};
const EditSize = ({ changeResponseStatus }) => {
    const [cabinType, setCabinType] = useState('');
    const [cabinTimeType, setCabinTimeType] = useState('Lượt thuê');
    const [openConfirmDialog, setConfirmDialog] = useState(false);
    const [rent, setRent] = useState(true);
    const [message, setMessage] = useState('');
    const [addSize, setAddSize] = useState({
        cellType: [
            {
                language: 'vi',
                name: '',
            },
            {
                language: 'en',
                name: '',
            },
        ],
        specifications: {
            length: '',
            width: '',
            height: '',
        },
        priceType: 'free',
        priceValue: 0,
    });
    const [validate, setValidate] = useState({
        name: "",
        width: "",
        height: "",
        length: ""
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isConfirmLoading, setIsConfirmLoading] = useState(false);
    const [reRender, setReRender] = useState(false);

    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const { sizeID } = useParams();

    const oldSizeRef = useRef();

    const fetchApiCabinetCellTypesGet = async (body) => {
        setIsLoading(true);
        try {
            const body = {
                cellTypeID: sizeID
            }

            const { data } = await apiCabinetCellTypesGet(body);
            const { Items } = data;

            const size = {
                id: Items[0].ID,
                cellType: [
                    { language: 'vi', name: Items[0].CellType[0].name },
                    { language: 'en', name: Items[0].CellType[0].name }
                ],
                specifications: Items[0].Specifications,
                priceType: Items[0].PriceType,
                priceValue: Items[0].PriceValue
            }
            let cabinTimeType = 'Lượt thuê', cabinetType = Items[0].PriceType;

            if (Items[0].PriceType === "turn") {
                cabinTimeType = "Lượt thuê"
            }
            if (Items[0].PriceType === "hourly") {
                cabinTimeType = "Giờ";
                cabinetType = 'turn'
            }

            oldSizeRef.current = deepCloneArr(size);

            setAddSize(size);
            setCabinType(cabinetType);
            setCabinTimeType(cabinTimeType);
        } catch (error) {
            setMessage(t("Tải thông tin kích thước tủ lỗi. Vui lòng thử lại!"));
        }
        setIsLoading(false);
    }

    const selectChange = (event) => {
        setCabinTimeType(event.target.value);
        switch (event.target.value) {
            case 'Lượt thuê':
                setAddSize({
                    ...addSize,
                    priceType: 'turn',
                });

                break;
            case 'Giờ':
                setAddSize({
                    ...addSize,
                    priceType: 'hourly',
                });
                break;
            default:
        }
    };

    const radioChange = (event) => {
        setCabinType(event.target.value);
        switch (event.target.value) {
            case 'free':
                setAddSize({
                    ...addSize,
                    priceType: 'free',
                    priceValue: 0,
                });
                break;
            case 'scale':
                setAddSize({
                    ...addSize,
                    priceType: 'scale',
                    priceValue: null,
                });
                break;
            case 'turn':
                setAddSize({
                    ...addSize,
                    priceType: 'turn',
                    priceValue: 0
                });
                break;
            default:
        }
    };

    const onCancelClick = () => {
        // if (!addSize.id) {
        //     goBack();
        // } else {
        //     let cabinTimeType = "", cabinetType = oldSizeRef.current.priceType;

        //     if (oldSizeRef.current.priceType === "turn") {
        //         cabinTimeType = "Lượt thuê"
        //     }
        //     if (oldSizeRef.current.priceType === "hourly") {
        //         cabinTimeType = "Giờ";
        //         cabinetType = 'turn'
        //     }

        //     setAddSize({ ...deepCloneArr(oldSizeRef.current) });
        //     setCabinType(cabinetType);
        //     setCabinTimeType(cabinTimeType);
        //     setReRender(!reRender);
        // }
        goBack();
    };

    const goBack = () => {
        history.push(CABINET_ROUTE_PATHS.CABINET_SYSTEM_SIZE);
    };

    const onBackClick = () => {
        setConfirmDialog(false);
    };

    const sizename = (event) => {
        setAddSize({
            ...addSize,
            cellType: [
                {
                    language: 'vi',
                    name: event.target.value,
                },
                {
                    language: 'en',
                    name: event.target.value,
                },
            ],
        });
        setValidate(prev => ({
            ...prev,
            name: ""
        }))
    };

    const spec = (event) => {
        const getspec = addSize;
        getspec.specifications[event.target.name] = event.target.value;

        setAddSize(getspec);
        setValidate(prev => ({
            ...prev,
            [event.target.name]: ""
        }))
    };

    const onPriceValueChange = (priceValue) => {
        setAddSize({
            ...addSize,
            priceValue
        });
        setValidate(prev => ({
            ...prev,
            priceValue: ""
        }))
    };

    const onClose = () => {
        setMessage('');
    };

    const onSaveClick = () => {
        //validate
        const validate = {
            name: "", width: "", length: "", height: ""
        };
        if (!addSize.cellType[0].name) {
            validate.name = t("Không được để trống")
        }
        if (!addSize.specifications.length) {
            validate.length = t("Không được để trống")
        } else if (Number(addSize.specifications.length) < 10) {
            validate.length = t("Phải là số lớn hơn 9")
        }
        if (!addSize.specifications.width) {
            validate.width = t("Không được để trống")
        } else if (Number(addSize.specifications.width) < 10) {
            validate.width = t("Phải là số lớn hơn 9")
        }
        if (!addSize.specifications.height) {
            validate.height = t("Không được để trống")
        } else if (Number(addSize.specifications.height) < 10) {
            validate.height = t("Phải là số lớn hơn 9")
        }

        setValidate(validate);

        for (let key in validate) {
            if (validate[key]) {
                return;
            }
        }
        //pass a half validate
        if (addSize.priceType !== "free" && !addSize.priceValue) {
            setMessage(t("Vui lòng nhập đầy đủ và chính xác phí thuê tủ"));
            return;
        }
        //pass validate
        setConfirmDialog(true);
    }

    const onConfirmSave = async () => {
        setIsConfirmLoading(true);
        try {
            await apiCabinetCellTypesUpdate(addSize);

            changeResponseStatus(t("Lưu kích thước tủ thành công"));
            fetchApiCabinetCellTypesGet();
        } catch (error) {
            setMessage(t("Lưu kích thước tủ lỗi. Vui lòng thử lại!"));
        }
        setIsConfirmLoading(false);
        setConfirmDialog(false);
    };

    const getPrice = (value) => {
        switch (addSize.priceType) {
            case 'turn':
                setAddSize({
                    ...addSize,
                    priceValue: value,
                });
                break;
            case 'hourly':
                setAddSize({
                    ...addSize,
                    priceValue: value,
                });
            default:
        }
        setValidate(prev => ({
            ...prev,
            priceValue: ""
        }))
    };

    useEffect(() => {
        fetchApiCabinetCellTypesGet();
    }, [sizeID])

    return (
        <div className={clsx(classes.container)}>
            <Confirm
                isOpen={openConfirmDialog}
                mess={t('Bạn chắc chắn muốn lưu kích thước?')}
                handleClose={onBackClick}
                onConfirm={onConfirmSave}
                isLoading={isConfirmLoading}
            />
            <ErrorAlert message={message} onCloseDialog={onClose} />

            <Box display={'flex'} justifyContent='space-between'>
                <Box display={'flex'} alignItems='center'>
                    <IconButton
                        style={{
                            float: 'left',
                            padding: '2px',
                            marginRight: "0.5rem"
                        }}
                        onClick={goBack}
                    >
                        <ArrowBackIosOutlinedIcon
                            className={classes.primaryColor}
                        />
                    </IconButton>

                    <PrimaryTitle>
                        {t('Chỉnh sửa kích thước')}
                    </PrimaryTitle>
                </Box>
                <Box
                    display={'flex'}
                    alignItems='center'
                    justifyContent='center'
                    style={{ float: 'right' }}
                >
                    <CancelButton
                        variant='outlined'
                        style={{ marginRight: '0.2rem' }}
                        onClick={onCancelClick}
                    >
                        {t('Hủy')}
                    </CancelButton>
                    <CreateButton
                        variant='contained'
                        style={{ marginRight: '1rem' }}
                        onClick={onSaveClick}
                    >
                        {t('Lưu')}
                    </CreateButton>
                </Box>
            </Box>

            {isLoading && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="calc(100vh - 188px)"
                >
                    <CircularProgress />
                </Box>
            )}

            {!isLoading && addSize.id && (
                <div className={classes.form}>
                    <div>
                        <Box padding={'16px'}>
                            <Box padding={'8px'}>
                                <Typography sx={{ fontWeight: 600 }}>
                                    {t('Tên kích thước')}
                                </Typography>
                            </Box>
                            <Box display={'flex'}>
                                <Box
                                    style={{
                                        padding: '2px',
                                    }}
                                >
                                    <TextField
                                        variant='outlined'
                                        size='small'
                                        placeholder={t('Nhập tên kích thước')}
                                        error={!!validate.name}
                                        helperText={validate.name}
                                        value={addSize.cellType[0].name}
                                        onChange={(event) => sizename(event)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    {'  '}
                                                </InputAdornment>
                                            ),
                                            style: {
                                                background: '#FFFFFF',
                                                width: '250px',
                                            },
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </div>
                    <div>
                        <Box padding={'16px'}>
                            <Box padding={'8px'}>
                                <Typography sx={{ fontWeight: 600 }}>
                                    {t('Thông số')}
                                </Typography>
                            </Box>
                            <Box display={'flex'}>
                                <Box>
                                    <TextField
                                        type='number'
                                        placeholder={t('Nhập chiều dài')}
                                        variant='outlined'
                                        size='small'
                                        name='length'
                                        error={!!validate.length}
                                        helperText={validate.length}
                                        value={addSize.specifications.length}
                                        onChange={(event) => spec(event)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    cm
                                                </InputAdornment>
                                            ),
                                            style: {
                                                background: '#FFFFFF',
                                                width: '250px',
                                            },
                                        }}
                                    />
                                </Box>
                                <Box
                                    padding={'8px'}
                                >
                                    x
                                </Box>
                                <Box>
                                    <TextField
                                        type='number'
                                        placeholder={t('Nhập chiều rộng')}
                                        variant='outlined'
                                        size='small'
                                        name='width'
                                        error={!!validate.width}
                                        helperText={validate.width}
                                        value={addSize.specifications.width}
                                        onChange={(event) => spec(event)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    cm
                                                </InputAdornment>
                                            ),
                                            style: {
                                                background: '#FFFFFF',
                                                width: '250px',
                                            },
                                        }}
                                    />
                                </Box>
                                <Box
                                    padding={'8px'}
                                >
                                    x
                                </Box>
                                <Box>
                                    <TextField
                                        type='number'
                                        placeholder={t('Nhập chiều cao')}
                                        variant='outlined'
                                        size='small'
                                        name='height'
                                        error={!!validate.height}
                                        helperText={validate.height}
                                        value={addSize.specifications.height}
                                        onChange={(event) => spec(event)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    cm
                                                </InputAdornment>
                                            ),
                                            style: {
                                                background: '#FFFFFF',
                                                width: '250px',
                                            },
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </div>
                    <div>
                        <Box
                            style={{
                                padding: '16px',
                                width: '830px',
                            }}
                        >
                            <Box padding={'8px'}>
                                <Typography sx={{ fontWeight: 600 }}>
                                    {t('Phí thuê tủ')}
                                </Typography>
                            </Box>
                            <div>
                                <RadioGroup
                                    row
                                    aria-labelledby='demo-row-radio-buttons-group-label'
                                    name='row-radio-buttons-group'
                                    style={{
                                        width: '100%',
                                        justifyContent: "space-between"
                                    }}
                                    value={cabinType}
                                    onChange={radioChange}
                                >
                                    <FormControlLabel
                                        value='free'
                                        control={<Radio />}
                                        label={t('Không tính phí')}
                                    />
                                    <FormControlLabel
                                        value='scale'
                                        control={<Radio />}
                                        label={t('Phí thay đổi theo giờ')}
                                    />
                                    <div>
                                        <FormControlLabel
                                            value='turn'
                                            control={<Radio />}
                                            label={t('Phí cố định theo')}
                                        />
                                        <FormControl
                                            disabled={rent}
                                            required
                                            variant='outlined'
                                        >
                                            <SelectCpn
                                                variant='outlined'
                                                style={{ width: "150px", height: '40px' }}
                                                className={classes.customSelect}
                                                disabled={cabinType === "free" || cabinType === "scale"}
                                                value={cabinTimeType}
                                                onChange={selectChange}
                                            >
                                                <MenuItem value='Lượt thuê'>
                                                    {t('Lượt thuê')}
                                                </MenuItem>
                                                <MenuItem value='Giờ'>
                                                    {t('Giờ')}
                                                </MenuItem>
                                            </SelectCpn>
                                        </FormControl>
                                    </div>
                                </RadioGroup>
                            </div>
                            <CanbinSection
                                cabinType={cabinType}
                                cabinTimeType={cabinTimeType}
                                getPriceValueFromCabinSection={onPriceValueChange}
                                getPriceFromCabinSection={getPrice}
                                priceValue={addSize.priceValue}
                                reRender={reRender}
                            />
                        </Box>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EditSize;
