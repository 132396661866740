export const CHANGE_IS_MENU = "CHANGE_IS_MENU";

const initState = {
    isMenu: true
}
const cabinetReducer = (state = initState, action) => {
    switch (action.type) {
        case CHANGE_IS_MENU:
            return { ...state, isMenu: action.payload }
        default:
            return state;
    }
}

export default cabinetReducer;