import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useStyles } from './styles';

const DoughnutCommon = React.memo(({
    data,
    centerTitle,
    centerValue,
    isHiddenLabel = false,
    cutout = 52
}) => {
    const classes = useStyles();

    return (
        <div className={classes.containDoughnut}>
            <div
                className={classes.wrapDoughnut}
                style={{
                    width: isHiddenLabel && '100%'
                }}
            >
                <Doughnut
                    className={classes.doughnut}
                    width={143}
                    data={data}
                    options={{
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: false,
                                position: 'bottom',
                                labels: {
                                    boxWidth: 15,
                                    usePointStyle: true,
                                    color: '#000000',
                                    font: {
                                        family: 'Nunito',
                                        size: '14px',
                                    }
                                }
                            }
                        },
                        cutout: cutout
                    }}
                />

                <div className={classes.totalCells}>
                    <p>{centerTitle}</p>
                    <p>{centerValue}</p>
                </div>

            </div>
            {!isHiddenLabel && (<div className={classes.doughnutLabel}>
                {data?.labelCustoms?.map((item, index) => (
                    <div key={item} className={classes.wrapLabel}>
                        <p className={classes.label}>
                            <div style={{
                                display: 'inline-block',
                                width: '12px',
                                height: '12px',
                                marginRight: '8px',
                                borderRadius: '8px',
                                background: data?.datasets[0]?.backgroundColor[index]
                            }} />
                            <span className={classes.labelText}>{item}</span>
                        </p>
                        <p className={classes.value}>{data?.datasets[0]?.data[index]}</p>
                    </div>
                ))}
            </div>)}
        </div>
    )
});

export default DoughnutCommon;