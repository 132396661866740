
import makeStyles from "@material-ui/styles/makeStyles/makeStyles";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    block: {
        width: '100%',
        height: '500px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden'
    },
    iconStyle: {
        color: theme.primaryColor.background
    }
}))

export const PageLoading = () => {
    const classes = useStyles();
    return (
        <div className={classes.block}>
            <CircularProgress className={classes.iconStyle} />
        </div>
    )
}