import React, { useState, useEffect, useRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SnackbarContent from '@mui/material/SnackbarContent';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    messageStyle: {
        fontWeight: 'bold',
        fontSize: '16px',
        paddingBottom: '5px'
    },
    snackbarContentStyle: {
        background: 'rgb(237,  247,  237)', color: 'rgb(42,  185,  48)'
    },
    colorRed: {
        color: '#e01010'
    }
}));

const SyncFaceAlert = React.memo((props) => {
    const classes = useStyles();
    const { alertData, onCloseDialog, isOpen, ms } = props;
    const [notification, setNotification] = useState({});
    const { t } = useTranslation();
    const history = useHistory();
    const alertRef = useRef();

    const onNotificationClick = () => {
        if (!alertData.operator) {
            onCloseDialog();
            if (alertData.id && alertData.deviceId)
                history.replace(`/admin/device-management/sync-face/detail?id=${alertData.id}&&DeviceId=${alertData.deviceId}`);
            else
                history.replace(`/admin/device-management/sync-face`);
        }
    }

    useEffect(() => {
        if (isOpen) {
            if (alertRef.current) clearTimeout(alertRef.current);
            alertRef.current = setTimeout(() => {
                onCloseDialog();
            }, ms);
        }
    }, [isOpen])

    const getFailMessage = () => {
        if (alertData.result) {
            if (alertData.result.length !== 0) {
                return <p className={classes.colorRed}>{alertData.result.length} khuôn mặt đồng bộ không thành công trên thiết bị {alertData.deviceName}</p>
            }
        }
    }

    return (
        <Snackbar
            open={isOpen}
            TransitionComponent={Slide}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
        >
            <SnackbarContent
                style={{ background: 'rgb(237,  247,  237)', color: 'rgb(42,  185,  48)' }}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={onCloseDialog}
                    >
                        <CloseIcon />
                    </IconButton>
                }
                message={
                    <div onClick={onNotificationClick}>
                        <p className={classes.messageStyle} >{alertData.title}</p>
                        <p>{alertData.body}</p>
                        {getFailMessage()}
                    </div>
                }
            />
        </Snackbar>
    )
})

export default SyncFaceAlert;