import React, { useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { apiCabinetsDateChartsGet } from '../../../../api/cabinets';
import { useStyles } from './styles';

function CabinetBar({ date, cabinetId, handleToggleCabinetBarLoading }) {
    const [state, setState] = useState({
        dataBar: {
            labels: [],
            datasets: [
                {
                    data: [],
                    backgroundColor: '#0FA7E7',
                }
            ],
        }
    });

    const classes = useStyles();
    const moment = require('moment-timezone');

    const fetchApiCabinetsDateChartsGet = async (body) => {
        handleToggleCabinetBarLoading();

        let dataBar = {};
        try {
            const { data } = await apiCabinetsDateChartsGet(body);
            let labels = data?.labels || [];

            if (labels?.length > 25) {
                labels.forEach((item, index) => {
                    labels[index] = moment.tz(item, "Asia/Ho_Chi_Minh").format("DD/MM");
                })
            }

            dataBar = {
                labels: labels,
                datasets: [{
                    data: data?.values || [],
                    backgroundColor: '#0FA7E7'
                }]
            }
        } catch (error) {

        }

        setState(prev => ({
            ...prev,
            dataBar
        }));
        handleToggleCabinetBarLoading();
    }

    useEffect(() => {
        if (!date || !cabinetId) return;

        fetchApiCabinetsDateChartsGet({ date, cabinetId });
    }, [date, cabinetId])

    const BarMemo = useMemo(() => (
        <Bar
            data={state.dataBar}
            height={70}
            options={{
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                        position: 'bottom'
                    }
                },
                scales: {
                    y: {
                        beginAtZero: true,
                        suggestedMin: 0,
                        suggestedMax: 10,
                        stacked: true,
                        ticks: {
                            display: true,
                            callback: function (value) {
                                return value < 1000 ? value : value / 1000 + 'k';
                            }
                        },
                        grid: {
                            display: true
                        }

                    },
                    x: {
                        stacked: true,
                        grid: {
                            display: false
                        }
                    }
                },

            }}
        />
    ), [state.dataBar])

    return (
        <div className={classes.bar}>
            {BarMemo}
        </div>
    )
}

export default React.memo(CabinetBar);