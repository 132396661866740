import AxiosClient from "../config/AxiosClient";

export const apiHistoriesDelete = async (bodyRequest) => {
    var url = '/histories/delete';
    return await AxiosClient.post(url, bodyRequest);
}

export const apiHistoriesDetails = async () => {
    var url = '/history/details';
    return await AxiosClient.get(url);
}

export const apiHistoryGetAll = async (bodyRequest) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/history/getall';
    } else {
        url = '/histories/get';
    }
    return await AxiosClient.post(url, bodyRequest);
}