import makeStyles from "@material-ui/styles/makeStyles/makeStyles";

export const useStyles = makeStyles(theme => ({
    root: theme.dataGridStyle,
    permisCamera: {
        position: "fixed",
        zIndex: "1201",
        top: "0",
        left: "0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        minWidth: "1280px",
        height: "100%",
        background: "rgb(0, 0, 0, 0.6)"
    },
    contain: {
        borderRadius: "0.5rem",
        padding: "1.5rem",
        width: "650px",
        background: "white"
    },
    containTitle: {
        borderBottom: "1px solid rgb(0, 0, 0, 0.1)",
        paddingBottom: "1rem"
    },
    required: {
        color: "red"
    },
    containField: {
        display: "flex",
        alignItems: "center",
        marginTop: "1rem",
        width: "100%"
    },
    containTextField: {
        width: "50%",
        marginRight: "1rem"
    },
    textField: {
        width: "100% !important",
        height: "40px"
    },
    permisShow: {
        borderRadius: "0.5rem",
        marginTop: "1rem",
        padding: "1rem",
        width: "100%",
        minHeight: "100px",
        background: "#F3F3F3"
    },
    permisShowItem: {
        display: "flex",
        justifyContent: "space-between",
        border: "1px solid",
        borderRadius: "4px",
        marginBottom: "1rem",
        padding: "5px 10px",
        background: "white",
        "&:last-child": {
            marginBottom: "unset"
        }
    },
    wrapBtn: {
        display: "flex",
        justifyContent: "flex-end"
    },
    permisShowDelete: {
        cursor: "pointer"
    }
}));