import makeStyles from '@material-ui/styles/makeStyles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    root: theme.dataGridStyle,
    manage: {
        // padding: "22px 1rem 2rem 1rem",
        minHeight: "100%"
    },
    manageHeader: {
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: "1.5rem",
        alignItems: "center",
    },
    wrapTitle: {
        display: "flex",
    },
    select: {
        width: "250px",
        height: "40px",
        '& div': {
            background: "unset !important"
        }
    },
    selectNoneBorder: {
        width: "auto",
        maxWidth: '250px',
        overflow: 'hidden',
        '&::before': {
            border: 'unset !important'
        },
        '&::after': {
            border: 'unset !important'
        },
    },
    menuItem: {
        maxWidth: "250px",
        "& p": {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden"
        }
    },
    manageAction: {
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: "1.5rem"
    },
    wrapFilter: {
        display: "flex",
        alignItems: "center",

        "& label": {
            margin: "0 1rem 0 0",
            width: "fit-content",
            fontSize: "16px",
            fontWeight: "600"
        },
    },
    selectStatus: {
        width: "300px"
    },
    tab: {
        display: "flex",
        borderBottom: "1px solid #C6C6C6",
        marginBottom: "1.5rem",

        "& div": {
            position: "relative",
            padding: "0 1.5rem 1rem",
            fontSize: "16px",
            cursor: "pointer",
        }
    },
    active: {
        fontWeight: "600",
        '&::before': {
            content: "''",
            position: "absolute",
            bottom: "0",
            left: "0",

            width: "100%",
            height: "3px",
            background: "#333333",
            borderRadius: "10px 10px 0px 0px",
        }
    },
    cellStatus: {
        position: "relative",
        paddingLeft: "20px",
        fontWeight: 700,

        "&::before": {
            content: "''",
            position: "absolute",
            left: "0",
            top: "calc(50% - 4px)",

            borderRadius: "8px",
            width: "8px",
            height: "8px",
        }
    },
    emptyStatus: {
        color: "black",

        "&::before": {
            background: "black"
        }
    },
    waitDeliveryStatus: {
        color: "#EE8306",

        "&::before": {
            background: "#EE8306"
        }
    },
    waitGetItemStatus: {
        color: "#2A6FD1",

        "&::before": {
            background: "#2A6FD1"
        }
    },
    overDueStatus: {
        color: "#ED1626",

        "&::before": {
            background: "#ED1626"
        }
    },
    table: {
        position: 'relative',
        borderRadius: '6px',
        paddingBottom: '16px',
        width: '100%',
        height: 'calc(100vh - 330px)',
        minHeight: "500px",
        background: '#FFFFFF',

        "& .Mui-even.MuiDataGrid-row, .Mui-odd.MuiDataGrid-row": {
            cursor: "pointer"
        }
    },
}));