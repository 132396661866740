import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import SearchIcon from '@material-ui/icons/Search';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton/IconButton';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import { DataGrid } from '@material-ui/data-grid';
import moment from 'moment-timezone';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import SuccessAlert from '../../alert/SuccessAlert';
import ErrorAlert from '../../alert/ErrorAlert';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import PaymentDetail from './PaymentDetail';
import ExportPaymentFile from './ExportPaymentFile';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import vi from 'date-fns/locale/vi';
import Select from '@material-ui/core/Select';
import Divider from '@mui/material/Divider/Divider';
import { apiGetmultiplepayments, apiDeletepayments } from '../../../api/payments';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { PaymentConfiguration } from '../../../config/enum';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Confirm } from '../../dialog';
import { CustomLoading, EmptyRowsView } from '../../grid';
import { ShowFilterValues } from '../../filter';
import { FilterIcon, RemoveIcon } from '../../icons';

const useStyles = makeStyles(theme => ({
    fontWeight400: {
        fontWeight: '400'
    },
    fontWeight600: {
        fontWeight: '600'
    },
    font: {
        fontSize: '16px',
        lineHeight: '22.4px',
        color: '#6D6D6D'
    },
    status1: {
        color: '#2AB930',
        paddingBottom: '0px'
    },
    status2: {
        color: '#6D6D6D',
        paddingBottom: '0px'
    },
    status3: {
        color: 'rgb(228, 2, 29)',
        paddingBottom: '0px'
    },
    primaryTitle: theme.primary_title,
    createBtn: theme.create_button,
    dialogTitleBackground: theme.primaryColor,
    cancelBtn: theme.cancel_button,
    topBlock: {
        marginTop: '22px',
        marginBottom: '20px',
        height: '40px',
        width: '100%'
    },
    selectTitle: {
        fontSize: '16px',
        lineHeight: '22.4px',
        float: 'left',
        margin: '16px 0',
        padding: '0 16px',
        borderRight: '2px solid #E0E0E0'
    },
    dialogTitle: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '25px',
        color: '#FFFFFF',
    },
    contentDialog: {
        fontSize: '16px',
        height: '68px',
        borderBottom: '1px solid #EEEDF2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    header: {
        width: '100%',
        height: '56px',
        background: '#FFFFFF',
        border: '1px solid #E0E0E0',
        position: 'relative',
        borderRadius: '6px 6px 0 0',
        display: 'flex'
    },
    table: {
        width: '100%',
        maxHeight: '700px',
        height: '90%',
        background: '#FFFFFF',
        marginBottom: '22px',
        borderRadius: '6px',
        position: 'relative'
    },
    root: theme.dataGridStyle,
    requestError: {
        width: '100%',
        height: '65vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20px',
        color: '#6D6D6D'
    },
    inputHeight: {
        textAlign: 'left',
        position: 'relative',
        width: '578px',
        // '& .MuiOutlinedInput-root': {
        //     height: '32px'
        // }
    },
    focus: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: theme.themeColor,
            },
        },
    },
    inputRightBlock: {
        width: '50%',
        position: 'relative',
        height: '80px'
    },
    inputFullWidthBlock: {
        width: '100%',
        height: '80px',
        position: 'relative'
    },
    label: {
        paddingBottom: '0px',
        paddingTop: '8px',
        fontSize: '16px',
        lineHeight: '22px',
        color: '#333333',
        display: 'inline-block',
        margin: '0'
    },
    placeholder: {
        color: '#B2B2B2',
        fontSize: '16px'
    },
    selectInput: {
        '& .MuiInput-underline:before': {
            display: 'none'
        },
        '& .MuiInput-underline:after': {
            display: 'none'
        }
    },
    textFieldStyle: {
        background: '#FFFFFF',
        width: '95%'
    },
    datePickerStyle: {
        position: 'relative',
        top: '-15px',
        width: '95%'
    },
    floatLeft: {
        float: 'left'
    },
    radio: {
        color: 'rgba(0, 0, 0, 0.23)!important',
        '&$checked': {
            color: theme.menuBackground + '!important'
        }
    },
    checked: {},
    radioGroupStyle: {
        marginLeft: '16px',
        height: '40px',
        paddingTop: '5px',
        position: 'relative',
        left: '-17px'
    },
    dialogTitleStyle: {
        padding: '0 24px',
    },
    pTitleStyle: {
        borderBottom: '1px solid #E0E0E0',
        height: '66px',
        display: 'flex',
        alignItems: 'center',
        fontWeight: '600',
        fontSize: '20px',
        lineHeight: '28px',
        color: '#333333'
    },
    buttonGroupStyle: {
        width: '98%',
        textAlign: 'right',
        paddingBottom: '10px',
        marginTop: '20px'
    },
    inputStyle: {
        background: '#FFFFFF',
        width: '95%'
    },
    inputFullWidthStyle: {
        background: '#FFFFFF',
        width: '98%'
    },
    datePickerStyle: {
        position: 'relative',
        top: '-15px',
        width: '95%'
    },
    headerTitleStyle: {
        fontSize: '22px',
        fontWeight: 'bold',
        padding: '23px 0 15px 0'
    },
    fiberManualIcon: {
        position: 'relative',
        top: '-2px'
    }
}))

const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);
const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);
const DeleteButton = withStyles(theme => ({ root: theme.delete_button }))(Button);

const PaymentTable = props => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [paymentsData, setPaymentsData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageTotal, setPageTotal] = useState(0);
    const [rowsTotal, setRowsTotal] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchString, setSearchString] = useState('');
    const [ids, setIds] = useState([]);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isDetail, setIsDetail] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    const [alert, setAlert] = useState({
        success: '',
        error: '',
        request: ''
    })
    const [filter, setFilter] = useState({
        apartment: '',
        expiredDate: null,
        requestDate: null,
        paymentDate: null,
        status: '',
        requestInfo: ''
    });
    const [filterValues, setFilterValues] = useState({});
    const [isFilter, setIsFilter] = useState(false);
    const [isFiltering, setIsFiltering] = useState(false);
    const [disabledLoading, setDisabledLoading] = useState(false);
    const alertRef = useRef();
    const filterRef = useRef();
    const searchRef = useRef();
    const formatter = Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND'
    })

    const columns = [
        {
            headerName: t('title.apartment'),
            field: 'Apartment',
            cellClassName: 'super-app-theme--cell-payment',
            width: 120
        },
        {
            headerName: t('Tên hóa đơn'),
            field: 'RequestInfo',
            cellClassName: 'super-app-theme--cell-payment',
            flex: 1
        },
        {
            headerName: t('Tổng tiền'),
            field: 'Amount',
            cellClassName: 'super-app-theme--cell-payment',
            width: 150,
            renderCell: params => formatter.format(params.row.Amount)
        },
        {
            headerName: t('Trạng thái'),
            field: 'PaymentStatus',
            width: 200,
            cellClassName: 'super-app-theme--cell-payment',
            renderCell: params => showPaymentStatus(params.row.PaymentStatus, params.row.ExpiredDate)
        },
        {
            headerName: t('Ngày tạo'),
            field: 'RequestDate',
            cellClassName: 'super-app-theme--cell-payment',
            width: 130,
            renderCell: params => moment(params.row.RequestDate).format('DD-MM-YYYY')
        },
        {
            headerName: t('Ngày đến hạn'),
            field: 'ExpiredDate',
            cellClassName: 'super-app-theme--cell-payment',
            width: 180,
            renderCell: params => params.row.ExpiredDate ? moment(params.row.ExpiredDate).format('DD-MM-YYYY') : '-'
        },
        {
            headerName: t('Ngày thanh toán'),
            field: 'PaymentDate',
            cellClassName: 'super-app-theme--cell-payment',
            width: 180,
            renderCell: params => params.row.PaymentDate ? moment(params.row.PaymentDate).format('DD-MM-YYYY') : '-'
        },
    ]

    const showPaymentStatus = (status, time) => {
        var date = new Date();
        date.setHours(24, 0, 0, 0);
        if (!time) {
            return (
                <div className={clsx(classes.status2, classes.font)}>
                    <FiberManualRecordIcon className={classes.fiberManualIcon} />
                    <b>{t('Chưa thanh toán')}</b>
                </div>
            )
        }
        if (date.getTime() > time && status !== PaymentConfiguration.PAID) {
            return (
                <div className={clsx(classes.status3, classes.font)}>
                    <FiberManualRecordIcon className={classes.fiberManualIcon} />
                    <b>{t('payment.Đã quá hạn')}</b>
                </div>
            )
        }
        switch (status) {
            case PaymentConfiguration.UNPAID:
                return (
                    <div className={clsx(classes.status2, classes.font)}>
                        <FiberManualRecordIcon className={classes.fiberManualIcon} />
                        <b>{t('Chưa thanh toán')}</b>
                    </div>
                )
            case PaymentConfiguration.PAID:
                return (
                    <div className={clsx(classes.status1, classes.font)}>
                        <FiberManualRecordIcon className={classes.fiberManualIcon} />
                        <b>{t('Đã thanh toán')}</b>
                    </div>
                )
            case PaymentConfiguration.EXPIRED:
                return (
                    <div className={clsx(classes.status3, classes.font)}>
                        <FiberManualRecordIcon className={classes.fiberManualIcon} />
                        <b>{t('payment.Đã quá hạn')}</b>
                    </div>
                )
            default: return '';
        }
    }

    const getDefaultValues = async () => {
        try {
            const offset = currentPage * rowsPerPage;
            const limit = rowsPerPage;
            var filterData = { ...filter, search: searchString };
            if (filterData.apartment === '') {
                delete filterData.apartment;
            }
            if (filterData.expiredDate === null) {
                delete filterData.expiredDate;
            }
            if (filterData.requestDate === null) {
                delete filterData.requestDate;
            }
            if (filterData.paymentDate === null) {
                delete filterData.paymentDate;
            }
            if (filterData.status === '') {
                delete filterData.status;
            }
            if (filterData.search === '') {
                delete filterData.search;
            }
            if (filterData.requestInfo === '') {
                delete filterData.requestInfo;
            }
            const query = {
                offset: offset,
                limit: limit,
                filter: filterData
            };
            var res = await apiGetmultiplepayments(query);
            setRowsTotal(res.data.Count);
            const sortedData = res.data.Items.sort((a, b) => sortData(a, b));
            const data = [];
            sortedData.forEach(item => {
                data.push({ ...item, id: item.ID });
            })
            setPaymentsData(data);
            setIds([]);
            setLoading(true);
            setAlert({
                ...alert,
                request: ''
            });
            setDisabledLoading(false);
        } catch (error) {
            setAlert({
                ...alert,
                request: 'Không lấy được dữ liệu'
            });
        }
    }

    const sortData = (a, b) => {
        if (parseInt(a.RequestDate) - parseInt(b.RequestDate) < 0) return 1;
        return parseInt(a.RequestDate) - parseInt(b.RequestDate) > 0 ? -1 : 0;
    }

    useEffect(() => {
        if (!loading) {
            getDefaultValues();
        }
    }, [loading, currentPage])

    useEffect(() => {
        setCurrentPage(0);
        setLoading(false);
    }, [rowsPerPage, searchString])

    useEffect(() => {
        if (filter.status !== '' || filter.apartment !== '' || filter.expiredDate !== null || filter.requestDate !== null || filter.requestInfo !== '' || filter.paymentDate !== null) {
            setIsFiltering(true);
        } else {
            setIsFiltering(false);
        }
    }, [filterValues])

    const onRowClick = (e) => {
        setSelectedId(e.id);
        setIsDetail(true);
    }

    const onRefeshClick = () => {
        setLoading(false);
        setDisabledLoading(true);
    }

    const onDetailClick = (id) => {
        setSelectedId(id);
        setIsDetail(true);
    }

    const onBackClick = () => {
        setIsDetail(false);
        setLoading(false);
    }

    const onSelectedChange = (e) => {
        setIds(e);
    }

    const onCheckedChange = (e, id) => {
        var results = [...ids];
        if (e.target.checked) {
            results.push(id);
            setIds(results);
        } else {
            results = results.filter(item => item !== id);
            setIds(results);
        }
    }

    const onDeleteClick = () => {
        setDeleteDialog(true);
    }

    const onConfirmDeleteClick = async () => {
        try {
            setIsDeleting(true);
            await apiDeletepayments({ ids: ids });
            setAlert({
                ...alert,
                success: `Xóa thành công ${ids.length} thông tin thanh toán`,
                error: ''
            });
            setLoading(false);
        } catch (error) {
            setAlert({
                ...alert,
                error: 'Xóa không thành công, vui lòng thử lại sau',
                success: ''
            });
        }
        setDeleteDialog(false);
        setIsDeleting(false);
    }

    const onRequestDateChange = (e) => {
        var date = new Date(e);
        setFilter({
            ...filter,
            requestDate: date.setHours(7, 0, 0)
        });
    }

    const onExpiredDateChange = (e) => {
        var date = new Date(e);
        setFilter({
            ...filter,
            expiredDate: date.setHours(7, 0, 0)
        });
    }

    const onPaymentDateChange = (e) => {
        var date = new Date(e);
        setFilter({
            ...filter,
            paymentDate: date.setHours(7, 0, 0)
        });
    }

    const onApartmentChange = (e) => {
        setFilter({
            ...filter,
            apartment: e.target.value
        });
    }

    const onStatusChange = (e) => {
        setFilter({
            ...filter,
            status: e.target.value
        });
    }

    const onRequestInfoChange = (e) => {
        setFilter({
            ...filter,
            requestInfo: e.target.value
        });
    }

    const onClearFilter = () => {
        setFilter({
            status: '',
            expiredDate: null,
            requestDate: null,
            paymentDate: null,
            apartment: '',
            requestInfo: ''
        });
    }

    const onApplyFilter = () => {
        setLoading(false);
        setFilterValues({ ...filter });
        setIsFilter(false);
        setCurrentPage(0);
    }

    const onCloseFilterClick = () => {
        setIsFilter(false);
    }

    const onSearchChange = (e) => {
        if (searchRef.current) clearTimeout(searchRef.current);
        searchRef.current = setTimeout(() => {
            setSearchString(e.target.value);
        }, 500);
    }

    const onPageChange = (newPage) => {
        setCurrentPage(newPage);
        setLoading(false);
    }

    const onCloseDialog = () => {
        setAlert({
            ...alert,
            success: '',
            error: ''
        });
    }

    useEffect(() => {
        if (alert.success !== '' || alert.error !== '') {
            if (alertRef.current) clearTimeout(alertRef.current);
            alert.current = setTimeout(() => {
                onCloseDialog();
            }, 5000);
        }
    }, [alert])

    const onRemoveItemFilterClick = (key) => {
        var ft = {};
        if (key === 'expiredDate' || key === 'requestDate' || key === 'paymentDate') {
            ft = {
                ...filter,
                [key]: null
            }
        } else {
            ft = {
                ...filter,
                [key]: ''
            }
        }
        setFilter(ft);
        setFilterValues(ft);
        setLoading(false);
    }

    const getFilter = () => {
        return (
            <Dialog
                disableScrollLock
                open={isFilter}
                maxWidth="xl"
                aria-labelledby="simple-dialog-title"
            >
                <DialogTitle aria-labelledby="simple-dialog-title" className={classes.dialogTitleStyle} >
                    <div className={classes.pTitleStyle}>
                        {t('Bộ lọc')}
                        <p style={{ flex: 1 }}></p>
                        <IconButton
                            onClick={onCloseFilterClick}
                            size="small"
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>

                </DialogTitle>
                <DialogContent className={clsx(classes.inputHeight, classes.picker)}>
                    <div>
                        <div className={clsx(classes.select, classes.inputRightBlock)}>
                            <label className={classes.label} >{t("Căn hộ")}</label><br />
                            <TextField
                                size="small"
                                className={classes.inputStyle}
                                placeholder={t("Căn hộ")}
                                variant="outlined"
                                value={filter.apartment}
                                onChange={onApartmentChange}
                            />
                        </div>
                        <div className={clsx(classes.select, classes.inputFullWidthBlock)}>
                            <label className={classes.label} >{t("Tên hóa đơn")}</label><br />
                            <TextField
                                size="small"
                                className={classes.inputFullWidthStyle}
                                placeholder={t("Tên hóa đơn")}
                                variant="outlined"
                                value={filter.requestInfo}
                                onChange={onRequestInfoChange}
                            />
                        </div>
                        <div className={clsx(classes.inputFullWidthBlock)}>
                            <label className={classes.label} >{t("Trạng thái")}</label><br />
                            <RadioGroup
                                value={filter.status}
                                onChange={onStatusChange}
                                row
                                className={classes.radioGroupStyle}
                            >
                                <FormControlLabel
                                    value='unpaid'
                                    control={
                                        <Radio
                                            classes={{ root: classes.radio, checked: classes.checked }}
                                            size="small"
                                        />}
                                    label={t('Chưa thanh toán')}
                                />
                                <FormControlLabel
                                    value='paid'
                                    control={
                                        <Radio
                                            classes={{ root: classes.radio, checked: classes.checked }}
                                            size="small"
                                        />}
                                    label={t('Đã thanh toán')}
                                />
                                <FormControlLabel
                                    value='expired'
                                    control={
                                        <Radio
                                            classes={{ root: classes.radio, checked: classes.checked }}
                                            size="small"
                                        />}
                                    label={t('payment.Đã quá hạn')}
                                />
                            </RadioGroup>
                        </div>
                        <div className={clsx(classes.inputRightBlock, classes.floatLeft)}>
                            <label className={classes.label}>{t('Ngày tạo')}</label><br />
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={i18n.language === 'vi' ? vi : ''}
                            >
                                <KeyboardDatePicker
                                    inputVariant="outlined"
                                    className={classes.datePickerStyle}
                                    size="small"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    InputProps={{ readOnly: true }}
                                    placeholder={t("Ngày tạo")}
                                    value={filter.requestDate}
                                    onChange={onRequestDateChange}
                                    cancelLabel={t("Đóng")}
                                    okLabel={t("Đồng ý")}
                                    views={["date", "month", "year"]}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className={clsx(classes.inputRightBlock, classes.floatLeft)}>
                            <label className={classes.label}>{t('Ngày đến hạn')}</label><br />
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={i18n.language === 'vi' ? vi : ''}
                            >
                                <KeyboardDatePicker
                                    inputVariant="outlined"
                                    className={classes.datePickerStyle}
                                    size="small"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    InputProps={{ readOnly: true }}
                                    placeholder={t("Ngày đến hạn")}
                                    value={filter.expiredDate}
                                    onChange={onExpiredDateChange}
                                    cancelLabel={t("Đóng")}
                                    okLabel={t("Đồng ý")}
                                    views={["date", "month", "year"]}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className={clsx(classes.inputRightBlock, classes.floatLeft)}>
                            <label className={classes.label}>{t('Ngày thanh toán')}</label><br />
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={i18n.language === 'vi' ? vi : ''}
                            >
                                <KeyboardDatePicker
                                    inputVariant="outlined"
                                    className={classes.datePickerStyle}
                                    size="small"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    InputProps={{ readOnly: true }}
                                    placeholder={t("Ngày thanh toán")}
                                    value={filter.paymentDate}
                                    onChange={onPaymentDateChange}
                                    cancelLabel={t("Đóng")}
                                    okLabel={t("Đồng ý")}
                                    views={["date", "month", "year"]}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div style={{ clear: 'both' }} ></div>
                        <div className={classes.buttonGroupStyle}>
                            <CancelButton
                                variant="outlined"
                                style={{ marginLeft: '15px' }}
                                onClick={onClearFilter}
                            >
                                {t('Xóa bộ lọc')}
                            </CancelButton>
                            <CreateButton
                                variant="contained"
                                onClick={onApplyFilter}
                            >
                                {t('Áp dụng')}
                            </CreateButton>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <div style={{ height: '100%', minWidth: '1280px' }}>
            {getFilter()}
            <Confirm
                isOpen={deleteDialog}
                handleClose={() => { setDeleteDialog(!deleteDialog) }}
                isLoading={isDeleting}
                mess={t('Bạn có chắc chắn muốn xóa thông tin thanh toán?')}
                onConfirm={onConfirmDeleteClick}
            />
            {isDetail &&
                <PaymentDetail
                    id={selectedId}
                    onBackClick={onBackClick}
                />
            }
            <SuccessAlert
                message={alert.success}
                onCloseDialog={onCloseDialog}
            />
            <ErrorAlert
                message={alert.error}
                onCloseDialog={onCloseDialog}
            />
            {!isDetail &&
                <div style={{ width: '98%', height: '80%', margin: 'auto', position: 'relative' }}>
                    <div style={{ display: 'flex' }}>
                        <p className={classes.headerTitleStyle}>{t('Thông tin thanh toán')}</p>
                        <div style={{ flex: '1' }}></div>
                        <ExportPaymentFile />
                        <CreateButton
                            variant="contained"
                            style={{ margin: '8px 0 8px 8px' }}
                            component={Link}
                            to='/admin/payments/add-info'
                        >
                            {t("Thêm dữ liệu")}
                        </CreateButton>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <TextField
                            placeholder={t("Tìm kiếm căn hộ, tên hóa đơn")}
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                style: {
                                    background: '#FFFFFF',
                                    height: '40px',
                                    width: '320px',
                                    marginTop: '8px'
                                }
                            }}
                            onChange={onSearchChange}
                        />
                        <CancelButton
                            startIcon={<FilterIcon hexColor="#0b59c8" />}
                            variant="outlined"
                            style={{ margin: '8px 0 8px 8px' }}
                            onClick={() => setIsFilter(!isFilter)}
                        >

                            {t("Bộ lọc")}
                        </CancelButton>
                        <div style={{ flex: 1 }}></div>
                        <CreateButton
                            variant="contained"
                            startIcon={<RefreshIcon />}
                            style={{ margin: '8px 0 0 8px' }}
                            onClick={onRefeshClick}
                            disabled={disabledLoading}
                        >
                            {t("Làm mới")}
                        </CreateButton>
                        <DeleteButton
                            variant="outlined"
                            onClick={onDeleteClick}
                            startIcon={ids.length !== 0 ? <RemoveIcon color="#e01010" /> : <RemoveIcon color="#C2C2C2" />}
                            style={{ margin: '8px 0 8px 8px' }}
                            disabled={ids.length === 0}
                        >
                            {t('Xóa')}
                        </DeleteButton>
                    </div>
                    {
                        isFiltering &&
                        <ShowFilterValues
                            filterValues={filterValues}
                            onRemoveItemFilterClick={onRemoveItemFilterClick}
                        />
                    }
                    {alert.request !== '' && <div className={classes.requestError}>{alert.request}</div>}
                    {alert.request === '' &&
                        <div className={clsx(classes.table, classes.root)}>
                            <DataGrid
                                localeText={{
                                    footerRowSelected: (count) => count + t(" hàng trong bảng đã được chọn"),
                                    columnHeaderSortIconLabel: t('Sắp xếp')
                                }}
                                componentsProps={{
                                    pagination: {
                                        labelRowsPerPage: t("Số dòng mỗi trang"),
                                        labelDisplayedRows: ({ from, to, count }) => { return `${from}-${to} ${t('trong số')} ${count}` }
                                    }
                                }}
                                columns={columns}
                                rows={paymentsData}
                                pageSize={rowsPerPage}
                                rowsPerPageOptions={[10, 50, 100]}
                                onPageSizeChange={(pageSize) => setRowsPerPage(pageSize)}
                                checkboxSelection
                                onSelectionModelChange={onSelectedChange}
                                onRowClick={onRowClick}
                                disableColumnMenu
                                loading={!loading}
                                components={{
                                    NoRowsOverlay: () => EmptyRowsView(t('Không tìm thấy thông tin thanh toán')),
                                    LoadingOverlay: CustomLoading,
                                }}
                                rowCount={rowsTotal}
                                paginationMode="server"
                                onPageChange={(newPage) => onPageChange(newPage)}
                            />
                        </div>
                    }
                </div>}
            {isFilter && <div style={{ height: '200px' }}></div>}
        </div>
    )
}

export default PaymentTable;

