import React from "react";
import { useStyles } from "./styles";

export default function DefaultLayout({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.layoutPage}>
      <div className={classes.containLayout}>{children}</div>
    </div>
  );
}

export {default as LayoutSideBar} from "./layoutSidebar/index"
export {default as LayoutContent} from "./layoutContent/index"
