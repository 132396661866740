import makeStyles from "@material-ui/styles/makeStyles/makeStyles";
import clsx from 'clsx';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
    primaryColor: theme.primaryTextColor,
    titleBlock: {
        padding: '0',
        height: '50px',
        marginTop: '16px'
    },
    buttonStyle: {
        float: 'left',
        padding: '4px!important',
        marginTop: '4px!important'
    },
    iconStyle: {
        width: '22px!important',
        height: '22px!important'
    },
    secondaryTitle: theme.secondary_title,
    textStyle: {
        float: 'left',
        margin: '0',
        paddingTop: '5px'
    }
}))

const SubDetailTitle = (props) => {
    const { onClick, text } = props;
    const classes = useStyles();

    return (
        <div className={classes.titleBlock}>
            <IconButton
                onClick={onClick}
                className={classes.buttonStyle}
            >
                <ArrowBackIosOutlinedIcon className={clsx(classes.primaryColor, classes.iconStyle)} />
            </IconButton>
            <p className={clsx(classes.secondaryTitle, classes.textStyle)} >
                {text}
            </p>
        </div>
    )
}

export default SubDetailTitle;