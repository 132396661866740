import React, { useState, useEffect, useRef } from 'react';
import { isEmpty, checkUsername, checkDisplayName, checkBuilding, checkPassword } from '../../../validation/CheckForm';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import md5 from 'md5';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PasswordStrengthBar from 'react-password-strength-bar';
import Cookies from 'js-cookie';
import zxcvbn from 'zxcvbn';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import clsx from 'clsx';
import { apiReceptionistGet, apiReceptionistExist } from '../../../api/receptionist';

const useStyles = makeStyles((theme) => ({
    inputBlock: {
        height: '110px',
        width: '19%',
        margin: 'auto',
        position: 'relative',
        float: 'left'
    },
    label: {
        margin: '0',
        fontSize: '14px',
        lineHeight: '140%',
        color: '#000000',
        display: 'inline-block'
    },
    input: {
        width: '100%'
    },
    placeholder: {
        color: '#B2B2B2',
        fontSize: '12px'
    },
    error: {
        color: 'red',
        position: 'absolute',
        bottom: '0',
        left: '20px'
    },
    alert: {
        position: 'absolute',
        left: '3px',
        top: '3px',
        width: '99%'
    },
    dialogTitle: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '25px',
        color: '#FFFFFF',
    },
    contentDialog: {
        fontSize: '16px',
        height: '68px',
        borderBottom: '1px solid #EEEDF2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    dialogTitleBackground: theme.primaryColor,
    requestError: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20px',
        color: '#6D6D6D'
    },
    focus: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: theme.themeColor,
            },
        },
    },
}))

const CreateButton = withStyles(theme => ({
    root: theme.create_button
}))(Button);
const CancelButton = withStyles(theme => ({
    root: theme.cancel_button
}))(Button);

const EditReceptionist = (props) => {
    const { blocks, id, onCloseEditClick, updateDataToDB, isAdding } = props;
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [receptionist, setReceptionist] = useState({
        id: '',
        name: '',
        username: '',
        phone: '',
        password: '',
        block: ''
    });
    const [alert, setAlert] = useState({
        name: '',
        username: '',
        password: '',
        phone: '',
        block: '',
        request: ''
    });
    const [isExpiredSession, setIsExpiredSession] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const usernameRef = useRef();
    const phoneRef = useRef();
    const { t } = useTranslation();
    const passwordRef = useRef();

    useEffect(() => {
        if (isExpiredSession) {
            history.push('/login');
            dispatch({ type: 'IS_LOGIN_AGAIN_TRUE' });
        }
    }, [isExpiredSession]);

    const getDefaultValues = async () => {
        try {
            var res = await apiReceptionistGet({ id: id });
            const data = res.data.Items[0];
            setReceptionist({
                id: data.ID,
                name: data.DisplayName,
                username: data.UserName,
                phone: data.Phone,
                password: data.Password,
                block: data.BlockNumber
            })
            setNewPassword('');
            setAlert({ 
                ...alert, 
                request: t('') 
            });
            setLoading(true);
            setShowPassword(false);
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({ 
                ...alert, 
                request: t('Không lấy được dữ liệu') 
            });
        }
    }

    useEffect(() => {
        if (!loading) getDefaultValues();
    }, [loading])

    useEffect(() => {
        setLoading(false);
    }, [id])

    const onNameChange = (e) => {
        const name = e.target.value;
        setReceptionist({ 
            ...receptionist, 
            name: name 
        });
        if (isEmpty(name)) {
            setAlert({ 
                ...alert, 
                name: t('Không được để trống') 
            });
        } else if (!checkDisplayName(name)) {
            setAlert({ 
                ...alert, 
                name: t('Không được có ký tự đặc biệt') 
            });
        } else if (name.trim().length < 6 || name.trim().length > 30) {
            setAlert({ 
                ...alert, 
                name: t('Độ dài ký tự trong khoảng từ 6 đến 30') 
            });
        } else {
            setAlert({ 
                ...alert, 
                name: '' 
            });
        }
    }

    const checkExistedValues = async (name, value) => {
        try {
            const data = { [name]: value };
            var res = await apiReceptionistExist(data);
            if (res.data === 'Found'){
                if (name === 'phone'){
                    setAlert({ 
                        ...alert, 
                        phone: t('Số điện thoại đã tồn tại') 
                    });
                }else{
                    setAlert({ 
                        ...alert, 
                        username: t('Tên đăng nhập đã tồn tại') 
                    });
                }
            }     
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            if (name === 'phone')
                setAlert({ 
                    ...alert, 
                    phone: t('Số điện thoại đã tồn tại') 
                });
            else
                setAlert({ 
                    ...alert, 
                    username: t('Tên đăng nhập đã tồn tại') 
                });
        }
    }

    const onUsernameChange = (e) => {
        const username = e.target.value;
        setReceptionist({ 
            ...receptionist, 
            username: username 
        });
        if (isEmpty(username)) {
            setAlert({ 
                ...alert, 
                username: t('Không được để trống') 
            });
        } else if (!checkUsername(username)) {
            setAlert({ 
                ...alert, 
                username: t('Không được có ký tự đặc biệt') 
            });
        } else if (username.trim().length < 6 || username.trim().length > 24) {
            setAlert({ 
                ...alert, 
                username: t('Độ dài ký tự trong khoảng từ 6 đến 24') 
            });
        } else {
            setAlert({ 
                ...alert, 
                username: '' 
            });
            if (usernameRef.current) clearTimeout(usernameRef.current);
            usernameRef.current = setTimeout(() => {
                checkExistedValues('username', username)
            }, 500);
        }
    }

    const onPasswordChange = (e) => {
        const password = e.target.value;
        setNewPassword(password);
        setReceptionist({ 
            ...receptionist, 
            password: password 
        });
        if (isEmpty(password)) {
            setAlert({ 
                ...alert, 
                password: t('Không được để trống') 
            });
        } else if (!checkPassword(password)) {
            setAlert({ 
                ...alert, 
                password: t('Tối thiểu 8 ký tự bao gồm chữ HOA, chữ thường, số và ký tự đặc biệt') 
            });
        } else {
            setAlert({ 
                ...alert, 
                password: '' 
            });
            if (passwordRef.current) clearTimeout(passwordRef.current);
            passwordRef.current = setTimeout(() => {
                const result = zxcvbn(password);
                if (result.feedback.warning !== ''){
                    setAlert({ 
                        ...alert, 
                        password: t('Đã trùng mật khẩu phổ biến') 
                    });
                } 
            }, 300);
        }
    }

    const onPhoneChange = (e) => {
        const phone = e.target.value;
        setReceptionist({ 
            ...receptionist, 
            phone: phone 
        });
        if (isEmpty(phone)) {
            setAlert({ 
                ...alert, 
                phone: t('Không được để trống') 
            });
        } else {
            setAlert({ 
                ...alert, 
                phone: '' 
            });
            if (phoneRef.current) clearTimeout(phoneRef.current);
            phoneRef.current = setTimeout(() => {
                checkExistedValues('phone', phone);
            }, 300);
        }
    }

    const onBlockChange = (e, newValue) => {
        if (newValue === null) {
            setAlert({ 
                ...alert, 
                block: t("Không được để trống") 
            });
            setReceptionist({ 
                ...receptionist, 
                block: '' 
            });
            return;
        }
        setAlert({ 
            ...alert, 
            block: "" 
        });
        setReceptionist({ 
            ...receptionist, 
            block: newValue 
        });
    }

    const onUpdateClick = () => {
        if (isEmpty(receptionist.name)) return setAlert({ ...alert, name: t('Không được để trống') });
        if (alert.name !== '') return;
        if (isEmpty(receptionist.username)) return setAlert({ ...alert, username: t('Không được để trống') });
        if (!checkBuilding(receptionist.username)) return setAlert({ ...alert, username: t('Không được có ký tự đặc biệt') });
        if (alert.username !== '') return;
        if (isEmpty(receptionist.password)) return setAlert({ ...alert, password: t('Không được để trống') });
        if (alert.password !== '') return;
        if (isEmpty(receptionist.phone)) return setAlert({ ...alert, phone: t('Không được để trống') });
        if (alert.phone !== '') return;
        if (isEmpty(receptionist.block)) return setAlert({ ...alert, block: t('Không được để trống') });
        setConfirmDialog(true);

    }
    
    const onConfirmEditClick = () => {
        if (newPassword === '') {
            updateDataToDB(receptionist);
        } else {
            updateDataToDB({ 
                ...receptionist, 
                password: md5(receptionist.password) 
            });
        }
    }

    const confirmAddDialog = () => {
        return (
            <Dialog
                open={confirmDialog}
                maxWidth="xl"
            >
                <DialogTitle className={classes.dialogTitleBackground}>
                    <p className={classes.dialogTitle}>{t('Thông báo')}</p>
                </DialogTitle>
                <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center', position: 'relative' }}>
                    <p className={classes.contentDialog}>{t('Bạn có chắc chắn muốn lưu chỉnh sửa?')}</p>
                    <CancelButton
                        style={{ margin: '10px 0' }}
                        onClick={() => { setConfirmDialog(!confirmDialog) }}
                        disabled={isAdding}
                    >
                        {t('Trở về')}
                    </CancelButton>
                    <CreateButton
                        style={{ margin: '10px 0 10px 24px' }}
                        onClick={onConfirmEditClick}
                        disabled={isAdding}
                    >
                        {t('Đồng ý')}
                    </CreateButton>
                    {isAdding && <CircularProgress color="primary" style={{ position: 'absolute', left: '45%', top: '10%', color: '#0b59c8' }} />}
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <Paper style={{ height: '190px', marginTop: '10px', background: '#FAFAFA' }}>
            {confirmAddDialog()}
            {alert.request !== '' && <div className={classes.requestError}>{t('Không lấy được dữ liệu')}</div>}
            {!loading && alert.request === '' &&
                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress style={{ color: '#0b59c8' }} />
                </div>}
            {loading && alert.request === '' &&
                <div className={clsx(classes.focus)} style={{ fontSize: '24px', padding: '16px' }}>
                    <div className={classes.inputBlock} style={{ marginRight: '1%' }}>
                        <label className={classes.label} style={{ paddingBottom: '16px' }}>{t('Tên lễ tân')} <span style={{ color: 'red' }}>(*)</span></label><br />
                        <TextField
                            classes={{
                                root: classes.input
                            }}
                            inputProps={{
                                autoComplete: 'off'
                            }}
                            style={{ background: '#FFFFFF' }}
                            value={receptionist.name}
                            name="name"
                            size="small"
                            placeholder={t("Tên lễ tân")}
                            variant="outlined"
                            onChange={onNameChange}
                            error={alert.name !== ''}
                        />
                        <p style={{ color: '#f44336', fontSize: '0.75rem', paddingTop: '5px' }}>{alert.name}</p>
                    </div>
                    <div className={classes.inputBlock} style={{ marginRight: '1%' }}>
                        <label className={classes.label} style={{ paddingBottom: '16px' }}>{t('Tên đăng nhập')} <span style={{ color: 'red' }}>(*)</span></label><br />
                        <TextField
                            classes={{
                                root: classes.input
                            }}
                            inputProps={{
                                autoComplete: 'off'
                            }}
                            style={{ background: '#FFFFFF' }}
                            value={receptionist.username}
                            disabled={true}
                            name="username"
                            size="small"
                            placeholder={t("Tên đăng nhập")}
                            variant="outlined"
                            onChange={onUsernameChange}
                            error={alert.username !== ''}
                        />
                        <p style={{ color: '#f44336', fontSize: '0.75rem', paddingTop: '5px' }}>{alert.username}</p>
                    </div>
                    <div className={classes.inputBlock} style={{ marginRight: '1%' }}>
                        <label className={classes.label} style={{ paddingBottom: '16px' }}>{t('Mật khẩu')} <span style={{ color: 'red' }}>(*)</span></label><br />
                        <TextField
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {newPassword !== '' && <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                                        </IconButton>}
                                    </InputAdornment>
                                ),
                            }}
                            classes={{
                                root: classes.input
                            }}
                            inputProps={{
                                autoComplete: 'off'
                            }}
                            style={{ background: '#FFFFFF' }}
                            value={receptionist.password}
                            name="password"
                            size="small"
                            type={showPassword ? "text" : "password"}
                            placeholder={t("Mật khẩu")}
                            variant="outlined"
                            onChange={onPasswordChange}
                            error={alert.password !== ''}
                        />
                        {receptionist.password !== '' &&
                            <PasswordStrengthBar
                                scoreWords={['', '', '', '']}
                                shortScoreWord=''
                                password={receptionist.password}
                            />}
                        <p style={{ color: '#f44336', fontSize: '0.75rem', paddingTop: '5px' }}>{alert.password}</p>
                    </div>
                    <div className={classes.inputBlock} style={{ marginRight: '1%' }}>
                        <label className={classes.label} style={{ paddingBottom: '16px' }}>{t('Số điện thoại')} <span style={{ color: 'red' }}>(*)</span></label><br />
                        <TextField
                            classes={{
                                root: classes.input
                            }}
                            inputProps={{
                                autoComplete: 'off'
                            }}
                            style={{ background: '#FFFFFF' }}
                            value={receptionist.phone}
                            name="phone"
                            size="small"
                            placeholder={t("Số điện thoại")}
                            variant="outlined"
                            onChange={onPhoneChange}
                            error={alert.phone !== ''}
                        />
                        <p style={{ color: '#f44336', fontSize: '0.75rem', paddingTop: '5px' }}>{alert.phone}</p>
                    </div>
                    <div className={classes.inputBlock} style={{ marginRight: '1%' }}>
                        <label className={classes.label} >Block <span style={{ color: 'red' }}>(*)</span></label>
                        <Autocomplete
                            style={{ position: 'relative', top: '-3px' }}
                            value={receptionist.block}
                            size="small"
                            clearText={t("Xóa")}
                            noOptionsText={t("Không có dữ liệu")}
                            openText={t("Mở")}
                            options={blocks}
                            renderInput={(params) => (
                                <TextField {...params} style={{ background: '#FFFFFF' }} placeholder="block" margin="normal" variant="outlined" error={alert.block !== ''} />
                            )}
                            onChange={(e, newValue) => onBlockChange(e, newValue)}
                        />
                        <p style={{ color: '#f44336', fontSize: '0.75rem', position: 'absolute', bottom: '3px' }}>{alert.block}</p>
                    </div>
                    <div style={{ width: '100%', textAlign: 'right', paddingRight: '1%' }}>
                        {loading && <CancelButton variant="outlined" onClick={onCloseEditClick} style={{ marginLeft: '16px' }}>{t('Hủy')} </CancelButton>}
                        <CreateButton
                            variant="contained"
                            onClick={onUpdateClick}
                        >
                            {t('Lưu')}
                        </CreateButton>
                    </div>
                </div>}
        </Paper>
    )
}

export default EditReceptionist;
