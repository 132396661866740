import makeStyles from "@material-ui/styles/makeStyles/makeStyles";

export const styles = makeStyles(theme => ({
    root: theme.dataGridStyle,
    container: {
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        // height: "calc(100% - 12px)",
        background: "white"
    },
    working: {
        width: "100%",
        // height: "100%",
    },
    title: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "40px",
        margin: "22px 0",
    },
    titleArrow: {
        display: "flex",
        alignItems: "center",
        "& svg": {
            color: theme.themeColor,
            cursor: "pointer",
            marginRight: "1rem"
        },
        "& p": {
            paddingTop: "0"
        }
    },
    restore: {
        marginBottom: "1rem"
    },
    wrapper: {
        height: "calc(100% - 152px)",
        padding: '1rem 0 2rem 0'
    },
    tableTitle: {
        display: "flex",
    },
    colunmTitle: {
        position: '-webkit-sticky',
        position: 'sticky',
        top: '0',
        zIndex: "11",

        display: 'flex',
        justifyContent: "center",
        alignItems: 'center',

        borderLeft: "unset",
        height: '40px',
        fontWeight: "700",
        background: "white",

        '&::before': {
            position: "absolute",
            left: "0",
            bottom: "0",
            content: '""',
            display: "block",
            width: "1px",
            height: "20px",
            background: "rgb(0, 0, 0, 0.1)"
        },

        '&::after': {
            position: "absolute",
            left: "0",
            bottom: "0",
            content: '""',
            display: "block",
            width: "100%",
            height: "2px",
            background: "rgb(0, 0, 0, 0.1)"
        }
    },
    table: {
        position: "relative",
        display: 'flex',
        width: '100%',
        height: "100%",
        // overflowY: "auto"
    },
    column: {
        position: 'relative',
        width: 'calc((100% -70px)/7)',
        height: "fit-content",
        "&:last-child": {
            borderRight: "1px solid rgb(0, 0, 0, 0.1)",
        }
    },
    timeItem: {
        height: '10px',
        borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
        cursor: 'pointer',
    },
    workingContain: {
        position: 'absolute',
        left: '0',
        width: '100%',
    },
    workingInfo: {
        border: '1px solid  rgba(0, 0, 0, 0.3)',
        borderRadius: "5px",
        padding: "0.5rem",
        width: "90%",
        overflow: "hidden",

        background: "#6F9AD8",
        color: "white",
        overflowY: "hidden",
        textOverflow: "ellipsis",
        cursor: "pointer",
    },
    workingInfoHover: {
        "&:hover": {
            background: "#7ba7e8",
            'box-shadow': '2px 2px 10px 8px rgba(0, 0, 0, 0.3)'
        }
    },
    workingInfoItem: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        lineHeight: "1.2rem"
    },
    dialogPopUp: {
        position: "fixed",
        top: "0",
        left: "0",
        zIndex: "1202",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        width: "100%",
        height: "100%",
    },
    popUp: {
        padding: "1rem"
    },
    popUpHead: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "1rem",
        paddingBottom: "1rem",
        borderBottom: "1px solid #E0E0E0",

        "& p": {
            fontSize: "20px",
            fontWeight: "600"
        },

        "& > div > span": {
            marginLeft: "1rem",
            cursor: "pointer"
        }
    },
    popUpDay: {
        marginBottom: "1rem",
        "& > p": {
            fontWeight: "700"
        },
        "& > p > span": {
            marginRight: "1rem"
        },
    },
    wrapper_textFied: {
        display: 'flex',
        alignItems: "center",
        width: '380px',
        background: "white"
    },
    textFied: {
        width: '100%',
        marginLeft: '1rem !important'
    }
}))