import Button from '@material-ui/core/Button/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import MultipleAutocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Webcam from "react-webcam";
import { getResidents } from '../../../api/residents';
import { getStaffById, staffsExist, updateStaff } from '../../../api/staffs';
import axiosClient from '../../../config/AxiosClient';
import { checkStaffFieldEdited } from '../../../helper/staff';
import { checkDisplayName, checkFullName, checkNumberPhone, checkUsername, isEmpty } from '../../../validation/CheckForm';
import SuccessAlert from '../../alert/SuccessAlert';

const useStyles = makeStyles((theme) => ({
    inputBlock: { minHeight: '90px', width: '33%', position: 'relative' },
    inputDevice: { height: '90px', width: '74.2%', position: 'relative' },
    label: { margin: '0', fontSize: '14px', lineHeight: '140%', color: '#000000', display: 'inline-block' },
    input: { width: '100%' },
    placeholder: { color: '#B2B2B2', fontSize: '16px' },
    secondaryTitle: theme.secondary_title,
    primaryColor: theme.primaryTextColor,
    avatar_control: { marginTop: '8px', marginBottom: '15px', position: 'relative', height: '127px', width: '25%', textAlign: 'center' },
    avatar_logo: { width: '116px', height: '116px', border: '1px solid #c8c8c8', margin: 'auto', borderRadius: '50%' },
    fileBtn: { position: 'absolute', bottom: '-25px', right: '-12px' },
    select: {
        '& .MuiOutlinedInput-input': { padding: '10px 12px' }
    },
    imageDialog: { position: 'fixed', width: '100%', height: '100%', top: '0', left: '0', background: 'rgba(0,0,0,0.1)', zIndex: '1333', display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: '550px' },
    dialogTitle: { textAlign: 'center', fontSize: '20px', fontWeight: '600', lineHeight: '25px', color: '#FFFFFF'},
    dialogTitleBackground: theme.primaryColor,
    errorColor: theme.errorColor,
    contentDialog: { fontSize: '16px', height: '68px', borderBottom: '1px solid #EEEDF2', display: 'flex', alignItems: 'center', justifyContent: 'center' },
    permiss: {
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: "1202",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        background: "rgb(0, 0, 0, 0.6)"
    },
    permissWrap: {
        borderRadius: "8px",
        padding: "1.5rem",
        width: "700px",
        background: "white"
    },
    containPermiss: {
        display: "flex",
        alignItems: "flex-end",
        marginBottom: "0.5rem",
        width: "100%"
    },
    permissInputBlock: {
        position: "relative",
        width: "50%"
    },
    searchResi: {
        position: "relative",
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "20px",
        width: "92.5%"
    },
    searchResiIcon: {
        position: "absolute",
        top: "8px",
        right: "8px",
        background: "white"
    },
    wrapBtn: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "1rem"
    },
    permissAdded: {
        display: "flex",
        justifyContent: "space-between",
        border: '1px solid rgb(0, 0, 0, 0.3)',
        borderRadius: "3px",
        margin: "0.5rem 0",
        padding: "5px 12px",
        fontSize: "1rem"
    },
    permisBtnDelete: {
        cursor: "pointer"
    },
    wrapShow: {
        margin: "1.5rem 0",
        width: "92.5%"
    },
    wrapAvatar: {
        borderRadius: "50%",
        width: "100%",
        height: "100%",
        overflow: "hidden"
    },
    onOpenMenu: {
        paddingRight: "0 !important",
        overflow: "auto !important"
    }
}))
const ErrorButton = withStyles(theme => ({ root: theme.error_button }))(Button);
const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);
const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);

const EditStaff = (props) => {
    const { id, onCloseEditClick } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    const [state, setState] = useState({
        listResident: [],
        blockSelected: "",
        listPermissDoorAdded: [],
        listPermissDoorTemp: [],
        listPermissRoomAdded: [],
        listPermissRoomTemp: [],
        residentChose: {},
        isSearching: false,
        isSearchLoading: false,
        isPermissDoor: false,
        isPermissRoom: false,
        isPermissDoorEdited: false,
        isPermissRoomEdited: false
    })
    const [staff, setStaff] = useState({
        full_name: '',
        username: '',
        role: '',
        phone: '',
        residence_id: "",
        block: "",
        face_id: '',
        policies: {
            block_policy: [],
            device_policy: [],
        }
    });
    const [oldStaff, setOldStaff] = useState({});
    const [blocks, setBlocks] = useState([]);
    const [floorState, setFloorState] = useState({
        listAllFloor: [],
        listDummyAllFloor: [],
        floorSelected: "",
    });
    const [roomState, setRoomState] = useState({
        listAllRoom: [],
        listDummyAllRoom: [],
        roomSelected: "",
    })

    const [alert, setAlert] = useState({
        full_name: '',
        username: '',
        role: '',
        phone: '',
        block: '',
        faceid: '',
        success: '',
        request: "",
        permissBlock: "",
        permissFloor: "",
        permissRoom: ""
    });
    const [image, setImage] = useState('');
    const [oldFaceID, setOldFaceID] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [isZoomAvatar, setIsZoomAvatar] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [previewFaceID, setPreviewFaceID] = useState(null);
    const [isPreview, setIsPreview] = useState(false);
    const [isCapture, setIsCapture] = useState(false);
    const [devices, setDevices] = React.useState([]);
    const [videoConstraints, setVideoContraints] = useState({ deviceId: '', width: 640, height: 480 })
    const [selectedCam, setSelectedCam] = useState(false);
    const [isImageAdding, setIsImageAdding] = useState(false);
    const [base64data, setBase64data] = useState('');
    const [isFailed, setIsFailed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [isExpiredSession, setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const webRef = useRef();
    const usernameRef = useRef();
    const phoneRef = useRef();
    const alerRef = useRef();
    const searchDebounceRef = useRef();

    const getBlocks = async () => {
        try {
            var res;
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                res = await axiosClient.get('/getapartmentdata', {
                    headers: { 'Authorization': `Bearer ${Cookies.get('token')}` }
                });
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                res = await axiosClient.get('/apartmentrooms/get', {
                    headers: { 'Authorization': `Bearer ${Cookies.get('token')}`, 'Content-Type': 'application/json' }
                });
            }
            var blockArr = [];
            const floorArr = [];
            const roomArr = [];

            res.data.Items.forEach(item => {
                //push all block, floor, room
                blockArr.push(item.BlockID);
                floorArr.push({
                    block: item.BlockID,
                    floor: item.FloorID
                })
                roomArr.push({
                    block: item.BlockID,
                    floor: item.FloorID,
                    room: item.RoomID
                })
            });

            blockArr.sort();
            blockArr = [...new Set(blockArr)];
            setBlocks(blockArr);
            setFloorState(prev => ({
                ...prev,
                listDummyAllFloor: floorArr
            }));
            setRoomState(prev => ({
                ...prev,
                listDummyAllRoom: roomArr
            }));
        } catch (error) {
            setState(prev => ({
                ...prev,
                responseStatus: {
                    succ: "",
                    err: t("Tải danh sách tòa/tầng/phòng thất bại!")
                }
            }))
        }
    }

    useEffect(() => {
        getBlocks();
    }, [])

    const getDefaultValues = async () => {
        try {
            setLoading(true);

            let res, resResident;
            res = await getStaffById({ username: id });

            if (res?.data?.Items[0]?.ResidentID) {
                const body = {
                    start_index: 0,
                    end_index: 10,
                    filter: { username: res.data.Items[0].ResidentID }
                };
                resResident = await getResidents(body);
            }
            // }
            const data = res?.data?.Items[0];
            const residentChose = resResident?.data?.Items[0];

            const listPermissDoorAdded = [];
            const listPermissRoomAdded = [];

            data?.Policies?.block_policy?.forEach(blockPoliItem => {
                blockPoliItem.floor.forEach(item => {
                    listPermissDoorAdded.push({
                        block: blockPoliItem.block,
                        floor: item
                    })
                })
            });
            data.Policies?.device_policy?.forEach(devicePoliItem => {
                devicePoliItem.room.forEach(item => {
                    listPermissRoomAdded.push({
                        block: devicePoliItem.block,
                        floor: devicePoliItem.floor,
                        room: item
                    })
                })
            });
            if (residentChose?.Username) {
                setState(prev => ({
                    ...prev,
                    residentChose
                }))
            }
            setState(prev => ({
                ...prev,
                listPermissDoorAdded,
                listPermissDoorTemp: [...listPermissDoorAdded],
                listPermissRoomAdded,
                listPermissRoomTemp: [...listPermissRoomAdded]
            }))
            setStaff({
                ...data,
                id: data.Username,
                residence_id: data.ResidentID || "",
                full_name: data.FullName,
                username: data.Username,
                role: data.StaffRole,
                phone: data.Phone,
                block: data.Block,
                face_id: data.FaceId,
                policies: data.Policies
            })
            setOldStaff({
                ...data,
                id: data.Username,
                residence_id: data.ResidentID || "",
                full_name: data.FullName,
                username: data.Username,
                role: data.StaffRole,
                phone: data.Phone,
                block: data.Block,
                face_id: data.FaceId,
                policies: data.Policies
            })

            setImage(data.FaceId ? data.FaceId : '');
            setOldFaceID(data.FaceId ? data.FaceId : '');
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({ ...alert, request: t('Không lấy được dữ liệu') });
        }
        setLoading(false);
    }

    useEffect(() => {
        getDefaultValues();
    }, [])

    const optionsAvatarClose = () => {
        setAnchorEl(null);
    }
    const onAvatarOptionsClick = (e) => {
        setIsZoomAvatar(true);
        setAnchorEl(null);
    }

    const onSearchChange = (value) => {
        if (searchDebounceRef.current) {
            clearTimeout(searchDebounceRef.current);
        }
        if (value) {
            setState(prev => ({
                ...prev,
                isSearching: true
            }))
        } else {
            setState(prev => ({
                ...prev,
                isSearchLoading: false,
                isSearching: false,
                listResident: [],
                residentChose: {}
            }))
            return;
        }

        const body = {
            start_index: 0,
            end_index: 10,
            filter: { username: value }
        };

        setState(prev => ({
            ...prev,
            isSearchLoading: true
        }))

        searchDebounceRef.current = setTimeout(async () => {
            try {
                const { data } = await getResidents(body);
                setState(prev => ({
                    ...prev,
                    listResident: data.Items
                }));
            } catch (error) {
            }
            setState(prev => ({
                ...prev,
                isSearchLoading: false
            }));
        }, 700)
    }

    const onNameChange = (e) => {
        const name = e.target.value;
        setStaff({ ...staff, full_name: name });
        if (isEmpty(name)) {
            setAlert({ ...alert, full_name: t('Không được để trống') });
        } else if (!checkDisplayName(name)) {
            setAlert({ ...alert, full_name: t('Không được có ký tự đặc biệt') });
        } else if (name.trim().length > 24) {
            setAlert({ ...alert, full_name: t('Độ dài ký tự không quá 24') });
        } else if (name.trim().length < 6) {
            setAlert({ ...alert, full_name: t('Độ dài ký tự không ít hơn 6') });
        } else if (!checkFullName(name)) {
            setAlert({ ...alert, full_name: t('Không hợp lệ') });
        } else {
            setAlert({ ...alert, full_name: '' });
        }
    }
    const onUsernameChange = (e) => {
        const username = e.target.value.trim();
        setStaff({ ...staff, username: username });
        if (isEmpty(username)) {
            setAlert({ ...alert, username: t('Không được để trống') });
        } else if (!checkUsername(username)) {
            setAlert({ ...alert, username: t('Không được có ký tự đặc biệt') });
        } else if (username.trim().length < 6 || username.trim().length > 24) {
            setAlert({ ...alert, username: t('Độ dài ký tự trong khoảng từ 6 đến 24') });
        } else {
            setAlert({ ...alert, username: '' });
            if (usernameRef.current) clearTimeout(usernameRef.current);
            usernameRef.current = setTimeout(() => {
                if (username === staff.Username) return;
                checkExistedValues('username', username);
            }, 600);
        }
    }

    const checkExistedValues = async (name, value) => {
        try {
            var res;
            const data = {
                [name]: value
            };
            res = await staffsExist(data)
            if (res.data === 'Found')
                if (name === 'phone')
                    setAlert({ ...alert, phone: t('Số điện thoại đã tồn tại') });
                else
                    setAlert({ ...alert, username: t('Tên đăng nhập đã tồn tại') });
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            if (name === 'phone')
                setAlert({ ...alert, phone: t('Số điện thoại đã tồn tại') });
            else
                setAlert({ ...alert, username: t('Tên đăng nhập đã tồn tại') });
        }
    }

    const onPhoneChange = (e) => {
        const phone = e.target.value;
        setStaff({ ...staff, phone: phone });
        if (phone && !checkNumberPhone(phone)) {
            return setAlert({ ...alert, phone: t("Không hợp lệ") });
        }
        setAlert({ ...alert, phone: '' });
        if (!phone) {
            return;
        }
        if (phoneRef.current) clearTimeout(phoneRef.current);
        phoneRef.current = setTimeout(() => {
            if (phone === oldStaff.phone) return;
            checkExistedValues('phone', phone);
        }, 600);
    }
    const onRoleChange = (e) => {
        const role = e.target.value;
        setStaff({ ...staff, role: role });
        if (role !== 'receptionist') setAlert({ ...alert, phone: '', block: '', role: '' });
        else setAlert({ ...alert, role: '' });
    }

    const onBlockChange = (e, newValue) => {
        if (newValue === null) {
            staff.role === 'receptionist' ? setAlert({ ...alert, block: t('Không được để trống') }) : setAlert({ ...alert, block: '' });
            setStaff({ ...staff, block: '' });
            return;
        }
        setAlert({ ...alert, block: "" });
        setStaff({ ...staff, block: newValue });
    }

    const checkFaceID = async (base64) => {
        setIsImageAdding(true);
        try {
            const source = base64.slice(base64.indexOf('base64,') + 7);
            const res = await axios.post(process.env.REACT_APP_QUALITY_ASSESSMENT, { 'user_faces': [source] });
            setPreviewFaceID('valid');
        } catch (error) {
            switch (error.response?.status) {
                case 477:
                    setPreviewFaceID(t('Hình ảnh không có'));
                    break;
                case 480:
                    setPreviewFaceID(t('Hình ảnh không hợp lệ'));
                    break;
                case 481:
                    setPreviewFaceID(t('Không thể phát hiện khuôn mặt'));
                    break;
                case 482:
                    setPreviewFaceID(t('Hình ảnh mờ'));
                    break;
                case 483:
                    setPreviewFaceID(t('Khuôn mặt đã bị trùng'));
                    break;
                case 484:
                    setPreviewFaceID(t('Nhiều khuôn mặt trong một hình'));
                    break;
                case 486:
                    setPreviewFaceID(t('Khuôn mặt không đủ lớn'));
                    break;
                case 487:
                    setPreviewFaceID(t('Khuôn mặt không hợp lệ'));
                    break;
                default:
                    setPreviewFaceID(t('Đã xảy ra lỗi, vui lòng thử lại!'));
                    break;
            }
        }
        setIsImageAdding(false);
    }

    const onOpenSelect = () => {
        const body = document.getElementsByTagName("BODY")[0];
        body.classList.add(classes.onOpenMenu);
    }
    const onCloseSelect = () => {
        const body = document.getElementsByTagName("BODY")[0];
        body.classList.remove(classes.onOpenMenu);
    }

    const onPermissBlockChange = (e) => {
        setState(prev => ({
            ...prev,
            blockSelected: e.target.value
        }))
        if (!e.target.value) {
            setAlert({
                ...alert,
                permissBlock: t("Không được để trống")
            })
            return;
        }
        setAlert({
            ...alert,
            permissBlock: ""
        })
    }
    const onPermissFloorChange = (e) => {
        setFloorState(prev => ({
            ...prev,
            floorSelected: e.target.value
        }))
        if (!e.target.value) {
            setAlert({
                ...alert,
                permissFloor: t("Không được để trống")
            })
            return;
        }
        setAlert({
            ...alert,
            permissFloor: ""
        })
    }
    const onPermissRoomChange = (e) => {
        setRoomState(prev => ({
            ...prev,
            roomSelected: e.target.value
        }))
        if (!e.target.value) {
            setAlert({
                ...alert,
                permissRoom: t("Không được để trống")
            })
            return;
        }
        setAlert({
            ...alert,
            permissRoom: ""
        })
    }

    const onClickAddPermissDoor = () => {
        if (!state.blockSelected || !floorState.floorSelected) return;

        const listPermissDoorTemp = [];
        const objToCheckKeyValue = {};
        let listAddedNewValue = [...state.listPermissDoorTemp, {
            block: state.blockSelected,
            floor: floorState.floorSelected
        }];

        if (state.blockSelected === "all" && floorState.floorSelected === "all") {
            setState(prev => ({
                ...prev,
                listPermissDoorTemp: [{
                    block: "all",
                    floor: "all"
                }]
            }));
            return;
        }
        if (state.blockSelected === "all") {
            listAddedNewValue.forEach(item => {
                item.block = "all"
            })
        }
        if (floorState.floorSelected === "all") {
            listAddedNewValue.forEach(item => {
                item.floor = "all"
            })
        }
        if (state.blockSelected !== "all" && floorState.floorSelected !== "all") {
            listAddedNewValue = listAddedNewValue.filter(item => {
                return item.block !== "all" && item.floor !== "all"
            })
        }

        listAddedNewValue.forEach(item => {
            if (item.block !== "all" || item.floor !== "all") {
                objToCheckKeyValue[`${item.block}${item.floor}`] = {
                    block: item.block,
                    floor: item.floor
                }
            }
        });

        for (let key in objToCheckKeyValue) {
            listPermissDoorTemp.push(objToCheckKeyValue[key]);
        }

        setState(prev => ({
            ...prev,
            listPermissDoorTemp,
            isPermissDoorEdited: true
        }))
    }
    const onClickDeletePermissDoor = (blockId, floorId) => {
        const listPermissDoorTemp = state.listPermissDoorTemp.filter(item => {
            return item.block !== blockId || item.floor !== floorId;
        });

        setState(prev => ({
            ...prev,
            listPermissDoorTemp
        }))
    }

    const onClickAddPermissRoom = () => {
        if (!state.blockSelected || !floorState.floorSelected || !roomState.roomSelected) return;

        const listPermissRoomTemp = [];
        const objToCheckKeyValue = {};
        let listAddedNewValue = [...state.listPermissRoomTemp, {
            block: state.blockSelected,
            floor: floorState.floorSelected,
            room: roomState.roomSelected
        }];

        if (state.blockSelected === "all" && floorState.floorSelected === "all" && roomState.roomSelected === "all") {
            setState(prev => ({
                ...prev,
                listPermissRoomTemp: [{
                    block: state.blockSelected,
                    floor: floorState.floorSelected,
                    room: roomState.roomSelected
                }]
            }));
            return
        }
        if (roomState.roomSelected === "all") {
            listAddedNewValue.forEach(item => {
                item.room = "all"
            });
        }
        if (floorState.floorSelected === "all") {
            listAddedNewValue.forEach(item => {
                item.floor = "all"
            });
        }
        if (state.blockSelected === "all") {
            listAddedNewValue.forEach(item => {
                item.block = "all"
            });
        }
        if (state.blockSelected !== "all" && floorState.floorSelected === "all") {
            listAddedNewValue = listAddedNewValue.filter(item => {
                return item.block !== "all" && item.floor === "all";
            })
        }
        if (state.blockSelected === "all" && floorState.floorSelected !== "all") {
            listAddedNewValue = listAddedNewValue.filter(item => {
                return item.block === "all" && item.floor !== "all";
            })
        }
        if (state.blockSelected !== "all" && floorState.floorSelected !== "all" && roomState.roomSelected !== "all") {
            listAddedNewValue = listAddedNewValue.filter(item => {
                return item.block !== "all" && item.floor !== "all" && item.room !== "all";
            })
        }

        listAddedNewValue.forEach(item => {
            if (item.block !== "all" || item.floor !== "all" || item.room !== "all") {
                objToCheckKeyValue[`${item.block}${item.floor}${item.room}`] = {
                    block: item.block,
                    floor: item.floor,
                    room: item.room
                }
            }
        });

        for (let key in objToCheckKeyValue) {
            listPermissRoomTemp.push(objToCheckKeyValue[key]);
        }

        setState(prev => ({
            ...prev,
            listPermissRoomTemp,
            isPermissRoomEdited: true
        }))
    }
    const onClickDeletePermissRoom = (blockId, floorId, roomId) => {
        const listPermissRoomTemp = state.listPermissRoomTemp.filter(item => {
            return item.block !== blockId || item.floor !== floorId || item.room !== roomId;
        })

        setState(prev => ({
            ...prev,
            listPermissRoomTemp
        }))
    }

    const onClosePermiss = () => {
        setState(prev => ({
            ...prev,
            isPermissDoor: false,
            isPermissRoom: false,
            blockSelected: ""
        }));
        setFloorState(prev => ({
            ...prev,
            floorSelected: ''
        }));
        setRoomState(prev => ({
            ...prev,
            roomSelected: ""
        }));
    }
    const onCancelPermiss = () => {
        setState(prev => ({
            ...prev,
            listPermissDoorTemp: [...prev.listPermissDoorAdded],
            listPermissRoomTemp: [...prev.listPermissRoomAdded],
        }))
        onClosePermiss();
    }
    const onSavePermiss = () => {
        setState(prev => ({
            ...prev,
            listPermissDoorAdded: [...prev.listPermissDoorTemp],
            listPermissRoomAdded: [...prev.listPermissRoomTemp],
        }))

        onClosePermiss();
    }

    const onClearPermissDoor = () => {
        setState(prev => ({
            ...prev,
            listPermissDoorTemp: [],
            blockSelected: "all"
        }));
        setFloorState(prev => ({
            ...prev,
            floorSelected: "all"
        }))
    }
    const onClearPermissRoom = () => {
        setState(prev => ({
            ...prev,
            listPermissRoomTemp: [],
            blockSelected: "all"
        }));
        setFloorState(prev => ({
            ...prev,
            floorSelected: "all"
        }));
    }

    useEffect(() => {
        const listAllFloor = [];

        floorState.listDummyAllFloor.forEach(item => {
            if (item.block === state.blockSelected) {
                listAllFloor.push(item.floor);
            }
            if (state.blockSelected === "all") {
                listAllFloor.push(item.floor);
            }
        });
        listAllFloor.sort();

        setFloorState(prev => ({
            ...prev,
            listAllFloor: [...new Set(listAllFloor)],
            floorSelected: ""
        }))
    }, [state.blockSelected]);

    useEffect(() => {
        const listAllRoom = [];
        roomState.listDummyAllRoom.forEach(item => {
            if (state.blockSelected === "all" && floorState.floorSelected === "all") {
                listAllRoom.push(item.room);
            } else if (state.blockSelected === "all" && floorState.floorSelected !== "all") {
                if (item.floor === floorState.floorSelected) {
                    listAllRoom.push(item.room);
                }
            } else if (state.blockSelected !== "all" && floorState.floorSelected === "all") {
                if (item.block === state.blockSelected) {
                    listAllRoom.push(item.room);
                }
            } else if (item.block === state.blockSelected && item.floor === floorState.floorSelected) {
                listAllRoom.push(item.room);
            }
        });
        listAllRoom.sort();

        setRoomState(prev => ({
            ...prev,
            listAllRoom: [...new Set(listAllRoom)],
            roomSelected: ""
        }))
    }, [floorState.floorSelected]);

    const handleDevices = React.useCallback(mediaDevices => {
        if (selectedCam) return;
        const results = mediaDevices.filter(({ kind }) => kind === "videoinput");
        setVideoContraints({ ...videoConstraints, deviceId: results[0]?.deviceId });
        setDevices(results);
    }, [devices]);

    React.useEffect(() => {
        navigator.mediaDevices.enumerateDevices().then(handleDevices);
    }, [handleDevices]);

    const getDevice = () => {
        return devices.map((value, key) => {
            return <option key={key} value={value.deviceId}>{value.label}</option>
        })
    }

    const onDeviceChange = (e) => {
        setSelectedCam(true);
        setTimeout(() => {
            setVideoContraints({ ...videoConstraints, deviceId: e.target.value });
        }, 300);
    }
    const onCaptureImage = () => {
        const base64 = webRef.current.getScreenshot();
        if (typeof (base64) === 'undefined' || base64 === null) {
            setIsCapture(false);
            setImage('');
            setBase64data('');
            setAlert({ ...alert, faceid: t('Hình ảnh không có') });
            return;
        }
        checkFaceID(base64);
        setBase64data(base64);
        setIsPreview(true);
    }

    const onApplyCaptureImage = async () => {
        const today = new Date();
        const source = base64data.slice(base64data.indexOf('base64,') + 7);
        setImage(base64data);
        setStaff({ ...staff, face_id: source });
        setAlert({ ...alert, faceid: t('') });
        setPreviewFaceID(null);
        setIsPreview(false);
        setIsCapture(false);
    }
    const captureImage = () => {
        if (isCapture) {
            return (
                <div className={classes.imageDialog}>
                    <Paper style={{ width: '640px', height: '560px', borderRadius: '6px', position: 'relative' }}>
                        <div style={{ height: '480', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 0 }}>
                            <Webcam
                                audio={false}
                                ref={webRef}
                                height={480}
                                width={640}
                                screenshotFormat="image/png"
                                screenshotQuality={1}
                                videoConstraints={videoConstraints}
                            />
                            <div style={{ height: '50px', width: '100%', textAlign: 'center', marginBottom: '10px', position: 'absolute', bottom: '0', left: '0' }}>
                                <CancelButton
                                    variant="outlined"
                                    onClick={() => setIsCapture(false)}
                                    style={{ background: '#FFFFFF', margin: '10px 5px' }}
                                >
                                    {t("Hủy")}
                                </CancelButton>
                                <CreateButton
                                    variant="contained"
                                    style={{ margin: '10px 5px' }}
                                    onClick={onCaptureImage}
                                >
                                    {t("Chụp")}
                                </CreateButton>
                            </div>
                            <div style={{ position: 'absolute', top: '5px', left: '5px' }}>
                                <select
                                    style={{ height: '30px', fontSize: '16px' }}
                                    onChange={onDeviceChange}
                                >
                                    {getDevice()}
                                </select>
                            </div>
                        </div>
                    </Paper>
                </div>
            )
        }
    }

    const previewCaptureImage = () => {
        if (isPreview) {
            return (
                <div className={classes.imageDialog}>
                    <Paper style={{ width: '640px', height: '560px', borderRadius: '6px', position: 'relative' }}>
                        <div style={{ height: '100%', width: '100%' }}>
                            <div style={{ height: '480px', width: '100%' }}>
                                <img src={base64data} alt="preview" width="100%" height="100%" />
                            </div>
                            {previewFaceID !== null && previewFaceID !== 'valid' && <p style={{ color: 'red', fontSize: '16px', height: '20px' }}>{previewFaceID}</p>}
                            {previewFaceID === 'valid' && <p style={{ color: 'rgb(42, 185, 48)', fontSize: '16px', height: '20px' }}>{t('Hình ảnh hợp lệ')}</p>}
                            {previewFaceID === 'valid' && <div style={{ height: '50px', width: '100%', textAlign: 'center', marginBottom: '10px' }}>
                                <CancelButton
                                    variant="outlined"
                                    style={{ margin: '10px 5px', background: '#FFFFFF' }}
                                    disabled={isImageAdding}
                                    onClick={() => {
                                        setIsCapture(true);
                                        setIsPreview(false);
                                        setPreviewFaceID(null)
                                    }}
                                >
                                    {t("Chụp lại")}
                                </CancelButton>
                                <CreateButton
                                    variant="contained"
                                    style={{ margin: '10px 5px' }}
                                    onClick={onApplyCaptureImage}
                                    disabled={isImageAdding}
                                >
                                    {t("Áp dụng")}
                                    {isImageAdding && <CircularProgress size={25} style={{ color: '#0b59c8', position: 'absolute' }} />}
                                </CreateButton>
                            </div>}
                            {previewFaceID !== null && previewFaceID !== 'valid' && <div style={{ height: '50px', width: '100%', textAlign: 'center', marginBottom: '10px' }}>
                                <CancelButton
                                    variant="outlined"
                                    style={{ margin: '10px 5px', background: '#FFFFFF' }}
                                    disabled={isImageAdding}
                                    onClick={() => {
                                        setIsCapture(false);
                                        setIsPreview(false);
                                        setPreviewFaceID(null)
                                    }}
                                >
                                    {t("Hủy")}
                                </CancelButton>
                                <CreateButton
                                    variant="contained"
                                    style={{ margin: '10px 5px' }}
                                    onClick={() => {
                                        setIsCapture(true);
                                        setIsPreview(false);
                                        setPreviewFaceID(null)
                                    }}
                                    disabled={isImageAdding}
                                >
                                    {t("Chụp lại")}
                                    {isImageAdding && <CircularProgress size={25} style={{ color: '#0b59c8', position: 'absolute' }} />}
                                </CreateButton>
                            </div>}
                        </div>
                    </Paper>

                </div>
            )
        }
    }

    const onSaveClick = async () => {
        if (isEmpty(staff.full_name)) return setAlert({ ...alert, full_name: t('Không được để trống') });
        if (alert.full_name !== '') return;
        if (isEmpty(staff.username)) return setAlert({ ...alert, username: t('Không được để trống') });
        if (alert.username !== '') return;
        if (isEmpty(staff.role)) return setAlert({ ...alert, role: t('Không được để trống') });
        if (alert.phone !== "") return;
        if (isEmpty(staff.block) && staff.role === 'Receptionist') return setAlert({ ...alert, block: t('Không được để trống') });
        if (alert.block !== '') return;
        setConfirmDialog(true);
    }

    const onSaveConfirm = async () => {
        try {
            let data = {};
            let staffRequest = checkStaffFieldEdited(oldStaff, staff);

            const block_policy = [];
            state.listPermissDoorAdded.forEach((item, i) => {
                if (i === 0) {
                    block_policy.push({
                        block: item.block,
                        floor: [item.floor]
                    });
                } else {
                    const hadPush = block_policy.findIndex(blockPoliItem => {
                        if (blockPoliItem.block === item.block) {
                            blockPoliItem.floor.push(item.floor);
                            return true;
                        }
                    });
                    if (hadPush < 0) {
                        block_policy.push({
                            block: item.block,
                            floor: [item.floor]
                        });
                    }
                }
            });

            staffRequest = {
                ...staffRequest,
                policies: {
                    ...staffRequest.policies,
                    block_policy
                }
            }

            const device_policy = [];
            state.listPermissRoomAdded.forEach((item, i) => {
                if (i === 0) {
                    device_policy.push({
                        block: item.block,
                        floor: item.floor,
                        room: [item.room]
                    });
                } else {
                    const hadPush = device_policy.findIndex(devicePoliItem => {
                        if (devicePoliItem.block === item.block && devicePoliItem.floor === item.floor) {
                            devicePoliItem.room.push(item.room);
                            return true;
                        }
                    });
                    if (hadPush < 0) {
                        device_policy.push({
                            block: item.block,
                            floor: item.floor,
                            room: [item.room]
                        });
                    }
                }
            });

            staffRequest = {
                ...staffRequest,
                policies: {
                    ...staffRequest.policies,
                    device_policy
                }
            }

            if (!staffRequest.residence_id) {
                delete staffRequest['residence_id'];
            }

            data = { ...staffRequest, username: oldStaff.username };
            setIsUpdating(true);
            await updateStaff(data);
            setAlert({ ...alert, success: t('Lưu thành công!') });
            setLoading(false);
            setIsEdit(false);
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setIsFailed(true);
            console.log(error);
        }
        setConfirmDialog(false);
        setIsUpdating(false);
        getDefaultValues();
    }
    const onCloseDialog = () => {
        setAlert({ ...alert, success: '', error: '' });
    }

    const saveConfirmDialog = () => {
        return (
            <Dialog
                open={confirmDialog}
                maxWidth="xl"
            >
                <DialogTitle className={classes.dialogTitleBackground}>
                    <p className={classes.dialogTitle}>{t('Thông báo')}</p>
                </DialogTitle>
                <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center', position: 'relative' }}>
                    <p className={classes.contentDialog}>{t('Bạn có chắc muốn lưu?')}</p>
                    <CancelButton
                        style={{ margin: '10px 0' }}
                        onClick={() => { setConfirmDialog(!confirmDialog) }}
                        disabled={isUpdating}
                    >
                        {t('Trở về')}
                    </CancelButton>
                    <CreateButton
                        style={{ margin: '10px 0 10px 24px' }}
                        onClick={onSaveConfirm}
                        disabled={isUpdating}
                    >
                        {t('Đồng ý')}
                    </CreateButton>
                    {isUpdating && <CircularProgress style={{ position: 'absolute', top: '10%', left: '45%', color: '#0b59c8' }} />}
                </DialogContent>
            </Dialog>
        )
    }

    const FailedDialog = () => {
        return (
            <Dialog
                open={isFailed}
                maxWidth="xl"
            >
                <DialogTitle className={classes.errorColor}>
                    <p className={classes.dialogTitle}>{t('Thông báo')}</p>
                </DialogTitle>
                <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center' }}>
                    <p className={classes.contentDialog}>{t('Lưu không thành công, vui lòng thử lại sau!')}</p>
                    <ErrorButton
                        style={{ margin: '10px 0' }}
                        onClick={() => setIsFailed(false)}
                    >
                        {t('Đồng ý')}
                    </ErrorButton>
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <div style={{ position: 'relative', width: '97%', margin: 'auto', paddingBottom: "1rem" }}>
            {captureImage()}
            {previewCaptureImage()}
            {FailedDialog()}
            {saveConfirmDialog()}
            <SuccessAlert message={alert.success} onCloseDialog={onCloseDialog} />
            <div style={{ marginTop: '23px', position: 'relative', left: '-9px' }}>
                <IconButton style={{ float: 'left', padding: '2px', marginRight: '5px' }} onClick={onCloseEditClick} >
                    <ArrowBackIosOutlinedIcon className={classes.primaryColor} style={{ width: '28px', height: '28px' }} />
                </IconButton>
                <p className={classes.secondaryTitle}>{t("Chi tiết nhân viên")}</p>
            </div>
            {loading && <div style={{ width: '100%', height: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress style={{ color: '#0b59c8' }} /></div>}
            {!loading && <div>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '33%' }}></div>
                    <div className={classes.avatar_control}>
                        <div className={classes.avatar_logo} style={{ position: 'relative' }} >
                            <div className={classes.wrapAvatar}>
                                {image === '' && <img src={window.location.origin + '/images/User.svg'} width="100%" height="85%" alt="Face ID" onClick={(e) => setAnchorEl(e.target)} />}
                                {image !== '' && <img src={image} onClick={(e) => setAnchorEl(e.target)} style={{ objectFit: 'cover' }} width="100%" height="100%" alt="Face ID" />}
                            </div>
                            <label className={classes.fileBtn} >
                                {isEdit && <IconButton aria-label="upload picture" style={{ position: 'relative', left: '10px' }} component="span" onClick={(e) => setIsCapture(true)}>
                                    <PhotoCamera style={{ color: '#565e67', fontSize: '40px', }} />
                                </IconButton>}
                                {/* <Menu
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={optionsAvatarClose}
                                >
                                    {image !== '' && <MenuItem onClick={onAvatarOptionsClick}>{t('Xem ảnh')}</MenuItem>}
                                    {isEdit && <MenuItem
                                        onClick={() => {
                                            setIsCapture(true);
                                            setAnchorEl(null);
                                        }}
                                    >
                                        <label htmlFor="icon-button-file" style={{ position: 'relative', top: '5px' }}>{t('Chụp hình')}</label>
                                    </MenuItem>}
                                </Menu> */}
                            </label>
                        </div>
                        <p style={{ color: 'red', width: '100%', textAlign: 'center' }}>{alert.faceid}</p>
                    </div>
                </div>
                <Divider />
                <div className={classes.searchResi}>
                    <Autocomplete
                        style={{ position: 'relative', top: '-16px', width: '40%' }}
                        size="small"
                        clearText={t("Xóa")}
                        noOptionsText={state.isSearching ? t("Không tìm thấy cư dân") : t("Vui lòng nhập số điện thoại")}
                        openText={t("Mở")}
                        disableClearable={!staff.residence_id}
                        disabled={!isEdit}
                        loading={state.isSearchLoading}
                        options={state.listResident}
                        value={state.residentChose}
                        getOptionLabel={option => option.Username ? `${option.Username} - ${option.FullName}` : ""}
                        getOptionSelected={() => true}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                style={{ background: '#FFFFFF' }}
                                placeholder={t("Tìm cư dân theo số điện thoại")}
                                margin="normal"
                                variant="outlined"
                            />
                        )}
                        onChange={(e, newValue) => {
                            if (newValue?.FaceID) {
                                setImage(newValue?.FaceID);
                            } else {
                                setImage("")
                            }
                            setStaff(prev => ({
                                ...prev,
                                residence_id: newValue ? newValue.Username : "",
                                full_name: newValue ? newValue.FullName : ""
                            }));
                            setState(prev => ({
                                ...prev,
                                residentChose: newValue
                            }))
                        }}
                        onInputChange={(e, value) => onSearchChange(value)}
                    />
                    <SearchOutlinedIcon
                        className={classes.searchResiIcon}
                        style={{
                            color: !isEdit && 'rgb(0, 0, 0, 0.3)'
                        }}
                    />
                </div>

                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <div className={classes.inputBlock}>
                        <label className={classes.label}>{t('Họ và tên')} <span style={{ color: 'red' }}>(*)</span></label><br />
                        <TextField
                            inputProps={{
                                autoComplete: 'off'
                            }}
                            style={{ background: '#FFFFFF', width: '80%' }}
                            size="small"
                            placeholder={t("Họ và tên")}
                            variant="outlined"
                            disabled={!isEdit || !!staff.residence_id}
                            value={staff.full_name}
                            onChange={onNameChange}
                        />
                        <p style={{ color: '#f44336', fontSize: '0.75rem', paddingTop: '5px' }}>{alert.full_name}</p>
                    </div>
                    <div className={classes.inputBlock}>
                        <label className={classes.label}>{t('Tên đăng nhập')} <span style={{ color: 'red' }}>(*)</span></label><br />
                        <TextField
                            inputProps={{
                                autoComplete: 'off'
                            }}
                            style={{ background: '#FFFFFF', width: '80%' }}
                            size="small"
                            placeholder={t("Tên đăng nhập")}
                            variant="outlined"
                            disabled
                            value={staff.username}
                            onChange={onUsernameChange}
                        />
                        <p style={{ color: '#f44336', fontSize: '0.75rem', paddingTop: '5px' }}>{alert.username}</p>
                    </div>
                    <div className={clsx(classes.inputBlock, classes.select)}>
                        <FormControl required variant="outlined" classes={{ root: classes.input }}>
                            <label className={classes.label}>{t('Vai trò')} <span style={{ color: 'red' }}>(*)</span> </label>
                            <Select
                                value={staff.role}
                                style={{ height: '40px', background: '#FFFFFF', width: '80%' }}
                                name="role"
                                displayEmpty
                                disabled={!isEdit}
                                onChange={onRoleChange}
                            >
                                <MenuItem value='' disabled ><p className={classes.placeholder}> {`< ${t('Chọn')} >`} </p> </MenuItem>
                                <MenuItem value={'Receptionist'} >{t('Lễ tân')}</MenuItem>
                                <MenuItem value={'Guard'} >{t('Bảo vệ')}</MenuItem>
                                <MenuItem value={'Management Staff'} >{t('Nhân viên quản lý')}</MenuItem>

                            </Select>
                        </FormControl>
                        <p style={{ color: '#f44336', fontSize: '0.75rem', paddingTop: '5px' }}>{alert.role}</p>
                    </div>
                    <div className={classes.inputBlock}>
                        <label className={classes.label}>{t('Số điện thoại cá nhân')}</label><br />
                        <TextField
                            inputProps={{
                                autoComplete: 'off'
                            }}
                            style={{ background: '#FFFFFF', width: '80%' }}
                            size="small"
                            placeholder={t("Số điện thoại cá nhân")}
                            variant="outlined"
                            disabled={!isEdit}
                            value={staff.phone}
                            onChange={onPhoneChange}
                        />
                        <p style={{ color: '#f44336', fontSize: '0.75rem', paddingTop: '5px' }}>{alert.phone}</p>
                    </div>
                    <div className={classes.inputBlock}>
                        <label className={classes.label} >{t("Block")} {staff.role === 'Receptionist' && <span style={{ color: 'red' }}>(*)</span>}</label>
                        <Autocomplete
                            style={{ position: 'relative', top: '-16px', width: '80%' }}
                            size="small"
                            clearText={t("Xóa")}
                            noOptionsText={t("Không có dữ liệu")}
                            openText={t("Mở")}
                            options={blocks}
                            disabled={!isEdit}
                            value={staff.block}
                            getOptionSelected={() => true}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    style={{ background: '#FFFFFF' }}
                                    placeholder={t("Block")}
                                    margin="normal"
                                    variant="outlined"
                                    error={alert.block !== ''}
                                />
                            )}
                            onChange={(e, newValue) => onBlockChange(e, newValue)}
                        />
                        <p style={{ color: '#f44336', fontSize: '0.75rem', position: 'absolute', bottom: '2px' }}>{alert.block}</p>
                    </div>
                </div>

                <Divider />

                <div className={classes.wrapShow} >
                    <label className={classes.label}>{t('Phân quyền mở cửa tòa/tầng')}</label><br />
                    <MultipleAutocomplete
                        multiple
                        open={false}
                        disabled={!isEdit}
                        size="small"
                        clearText={t("Xóa")}
                        openText={t("Mở")}
                        loadingText={t("Đang tải...")}
                        options={state.listPermissDoorAdded}
                        value={state.listPermissDoorAdded}
                        getOptionLabel={(option) => {
                            let mess = "";
                            if (option.block === "all") {
                                mess = `${t("Tất cả tòa")} - `
                            } else {
                                mess = `${t("Tòa")} ${option.block} - `
                            }
                            if (option.floor === "all") {
                                mess += `${t("Tất cả tầng")}`
                            } else {
                                mess += `${t("Tầng")} ${option.floor}`
                            }
                            return mess;
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                disabled
                                placeholder={state.listPermissDoorAdded.length ? "" : t('Phân quyền mở cửa tòa/tầng')}
                                onClick={() => isEdit && setState(prev => ({
                                    ...prev,
                                    isPermissDoor: true
                                }))}
                            />
                        )}
                        onChange={(e, newValue) => setState(prev => ({
                            ...prev,
                            listPermissDoorAdded: newValue,
                            listPermissDoorTemp: newValue,
                            isPermissDoorEdited: true
                        }))}
                    />
                </div>
                <div className={classes.wrapShow} >
                    <label className={classes.label}>{t('Phân quyền mở cửa phòng')}</label><br />
                    <MultipleAutocomplete
                        multiple
                        open={false}
                        disabled={!isEdit}
                        size="small"
                        clearText={t("Xóa")}
                        openText={t("Mở")}
                        loadingText={t("Đang tải...")}
                        options={state.listPermissRoomAdded}
                        value={state.listPermissRoomAdded}
                        getOptionLabel={(option) => {
                            let mess = "";
                            if (option.block === "all") {
                                mess = `${t("Tất cả tòa")} - `
                            } else {
                                mess = `${t("Tòa")} ${option.block} - `
                            }
                            if (option.floor === "all") {
                                mess += `${t("Tất cả tầng")} - `
                            } else {
                                mess += `${t("Tầng")} ${option.floor} - `
                            }
                            if (option.room === "all") {
                                mess += `${t("Tất cả phòng")}`
                            } else {
                                mess += `${t("Phòng")} ${option.room}`
                            }
                            return mess;
                        }} renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                disabled
                                placeholder={state.listPermissRoomAdded.length ? "" : t('Phân quyền mở cửa phòng')}
                                onClick={() => isEdit && setState(prev => ({
                                    ...prev,
                                    isPermissRoom: true,
                                }))}
                            />
                        )}
                        onChange={(e, newValue) => setState(prev => ({
                            ...prev,
                            listPermissRoomAdded: newValue,
                            listPermissRoomTemp: newValue,
                            isPermissRoomEdited: true
                        }))}
                    />
                </div>

                <Divider />

                {
                    (state.isPermissDoor || state.isPermissRoom) && (
                        <div className={classes.permiss} >
                            <div className={classes.permissWrap} >
                                <h4 style={{
                                    margin: "0 0 1.5rem 0"
                                }} >
                                    {
                                        state.isPermissRoom ? (
                                            t("Phân quyền mở cửa phòng")
                                        ) : (
                                            t("Phân quyền mở cửa tòa/tầng")
                                        )
                                    }
                                </h4>

                                <div className={classes.containPermiss} >
                                    <div className={clsx(classes.permissInputBlock, classes.select)}>
                                        <FormControl required variant="outlined" classes={{ root: classes.input }}>
                                            <label className={classes.label}>{t("Block")} <span style={{ color: 'red' }}>(*)</span> </label>
                                            <Select
                                                value={state.blockSelected}
                                                style={{ height: '40px', background: '#FFFFFF', width: '80%' }}
                                                name="permissBlock"
                                                displayEmpty
                                                onChange={onPermissBlockChange}
                                                onOpen={onOpenSelect}
                                                onClose={onCloseSelect}
                                            >
                                                <MenuItem value='' disabled ><p className={classes.placeholder}> {`< ${t('Chọn')} >`} </p> </MenuItem>
                                                <MenuItem value="all" >{t("All")}</MenuItem>
                                                {
                                                    blocks.map(item => (
                                                        <MenuItem key={item} value={item} >{item}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                        <p style={{ color: '#f44336', fontSize: '0.75rem', paddingTop: '5px' }}>{alert.permissBlock}</p>
                                    </div>

                                    <div className={clsx(classes.permissInputBlock, classes.select)}>
                                        <FormControl required variant="outlined" classes={{ root: classes.input }}>
                                            <label className={classes.label}>{t("Tầng")} <span style={{ color: 'red' }}>(*)</span> </label>
                                            <Select
                                                value={floorState.floorSelected}
                                                style={{ height: '40px', background: '#FFFFFF', width: '80%' }}
                                                name="permissFloor"
                                                displayEmpty
                                                onChange={onPermissFloorChange}
                                                onOpen={onOpenSelect}
                                                onClose={onCloseSelect}
                                            >
                                                <MenuItem value='' disabled ><p className={classes.placeholder}> {`< ${t('Chọn')} >`} </p> </MenuItem>
                                                <MenuItem value="all" >{t("All")}</MenuItem>
                                                {
                                                    floorState.listAllFloor.map(item => (
                                                        <MenuItem key={item} value={item} >{item}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                        <p style={{ color: '#f44336', fontSize: '0.75rem', paddingTop: '5px' }}>{alert.permissFloor}</p>
                                    </div>

                                    {
                                        state.isPermissRoom && (
                                            <div className={clsx(classes.permissInputBlock, classes.select)}>
                                                <FormControl required variant="outlined" classes={{ root: classes.input }}>
                                                    <label className={classes.label}>{t("Phòng")} <span style={{ color: 'red' }}>(*)</span> </label>
                                                    <Select
                                                        value={roomState.roomSelected}
                                                        style={{ height: '40px', background: '#FFFFFF', width: '80%' }}
                                                        name="permissFloor"
                                                        displayEmpty
                                                        onChange={onPermissRoomChange}
                                                        onOpen={onOpenSelect}
                                                        onClose={onCloseSelect}
                                                    >
                                                        <MenuItem value='' disabled ><p className={classes.placeholder}> {`< ${t('Chọn')} >`} </p> </MenuItem>
                                                        {
                                                            roomState.listAllRoom.map(item => (
                                                                <MenuItem key={item} value={item} >{item}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                                <p style={{ color: '#f44336', fontSize: '0.75rem', paddingTop: '5px' }}>{alert.permissFloor}</p>
                                            </div>
                                        )
                                    }
                                    <CreateButton
                                        disabled={state.isPermissDoor ? (
                                            !state.blockSelected || !floorState.floorSelected
                                        ) : (
                                            !state.blockSelected || !floorState.floorSelected || !roomState.roomSelected
                                        )
                                        }
                                        onClick={() => {
                                            if (state.isPermissDoor) {
                                                onClickAddPermissDoor();
                                            } else if (state.isPermissRoom) {
                                                onClickAddPermissRoom();
                                            }
                                        }}
                                        style={{
                                            margin: "0",
                                            marginBottom: "3px",
                                            background: state.isPermissDoor ? (
                                                (!state.blockSelected || !floorState.floorSelected) && "#9a9a9a"
                                            ) : (
                                                (!state.blockSelected || !floorState.floorSelected || !roomState.roomSelected) && "#9a9a9a"
                                            )
                                        }}
                                    >
                                        {t("Thêm")}
                                    </CreateButton>
                                </div>
                                {
                                    state.isPermissDoor && (
                                        <>
                                            <Divider />
                                            {
                                                state.listPermissDoorTemp.map(item => (
                                                    <div key={item.block + item.floor} className={classes.permissAdded} >
                                                        <p>
                                                            <span>{item.block === "all" ? t("Tất cả tòa") : `${t("Tòa")} ${item.block}`} - </span>
                                                            <span>{item.floor === "all" ? t("Tất cả tầng") : `${t("Tầng")} ${item.floor}`}</span>
                                                        </p>
                                                        <div
                                                            className={classes.permisBtnDelete}
                                                            onClick={() => onClickDeletePermissDoor(item.block, item.floor)}
                                                        >
                                                            <ClearOutlinedIcon fontSize="small" />
                                                        </div>
                                                    </div>
                                                ))

                                            }
                                        </>
                                    )
                                }

                                {
                                    state.isPermissRoom && (
                                        <>
                                            <Divider />
                                            {
                                                state.listPermissRoomTemp.map(item => (
                                                    <div key={item.block + item.floor + item.room} className={classes.permissAdded} >
                                                        <p>
                                                            <span>{item.block === "all" ? t("Tất cả tòa") : `${t("Tòa")} ${item.block}`} - </span>
                                                            <span>{item.floor === "all" ? t("Tất cả tầng") : `${t("Tầng")} ${item.floor}`} - </span>
                                                            <span>{item.room === "all" ? t("Tất cả phòng") : `${t("Phòng")} ${item.room}`}</span>
                                                        </p>
                                                        <div
                                                            className={classes.permisBtnDelete}
                                                            onClick={() => onClickDeletePermissRoom(item.block, item.floor, item.room)}
                                                        >
                                                            <ClearOutlinedIcon fontSize="small" />
                                                        </div>
                                                    </div>
                                                ))

                                            }
                                        </>
                                    )
                                }

                                <Divider />
                                <div className={classes.wrapBtn} >
                                    <CancelButton
                                        onClick={onCancelPermiss}
                                    >
                                        {t("Hủy")}
                                    </CancelButton>
                                    {
                                        state.isPermissDoor && (
                                            <CreateButton
                                                variant="contained"
                                                disabled={!state.listPermissDoorTemp.length}
                                                onClick={onClearPermissDoor}
                                            >
                                                {t("Xóa tất cả")}
                                            </CreateButton>
                                        )
                                    }
                                    {
                                        state.isPermissRoom && (
                                            <CreateButton
                                                variant="contained"
                                                disabled={!state.listPermissRoomTemp.length}
                                                onClick={onClearPermissRoom}
                                            >
                                                {t("Xóa tất cả")}
                                            </CreateButton>
                                        )
                                    }
                                    <CreateButton
                                        onClick={onSavePermiss}
                                    >
                                        {t("Lưu")}
                                    </CreateButton>
                                </div>
                            </div>
                        </div>
                    )
                }

                <Divider />

                <div style={{ display: 'flex', marginTop: '20px', paddingBottom: "20px" }}>
                    <div style={{ width: '66%' }}></div>
                    <div style={{ width: '33%' }}>
                        {!isEdit && <div style={{ width: '80%', textAlign: 'right' }}>
                            <CreateButton
                                variant="contained"
                                onClick={() => setIsEdit(true)}
                            >
                                {t("Chỉnh sửa")}
                            </CreateButton>
                        </div>}
                        {isEdit && <div style={{ width: '80%', textAlign: 'right' }}>
                            <CancelButton
                                variant="outlined"
                                onClick={() => {
                                    setIsEdit(false);
                                    setStaff({ ...oldStaff })
                                }}
                                disabled={isUpdating}
                            >
                                {t("Hủy")}
                            </CancelButton>
                            <CreateButton
                                variant="contained"
                                onClick={onSaveClick}
                                disabled={isUpdating}
                            >
                                {t("Lưu")}
                                {isUpdating && <CircularProgress size={25} style={{ color: '#0b59c8', position: 'absolute' }} />}
                            </CreateButton>
                        </div>}
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default EditStaff;
