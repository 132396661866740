import makeStyles from "@material-ui/styles/makeStyles/makeStyles";

export const  useStyles = makeStyles(theme => ({
    root: theme.dataGridStyle,
    table: {
        position: 'relative',
        borderRadius: '6px',
        paddingBottom: '16px',
        width: '100%',
        height: '526px',
        background: '#FFFFFF'
    },
    manageCamera: {
        position: "relative",
        padding: "0 1rem",
        minWidth: "1280px"
    },
    wrapTitle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "1rem 0"
    },
    title: {
        display: "flex",
        alignItems: "center"
    },
    titleIcon: {
        paddingTop: "5px",
        color: "#0b59c8",
        cursor: "pointer"
    },
    wrapSearch: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "1rem"
    },
    filter: {
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: "1210",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        background: "rgb(0, 0, 0, 0.6)",
    },
    filterContain: {
        position: "relative",
        borderRadius: "0.5rem",
        padding: "1.5rem",
        width: "500px",
        background: "white"
    },
    filterTitle: {
        borderBottom: "1px solid #C4C4C4",
        paddingBottom: "1rem",
        marginBottom: "1rem"
    },
    filterClose: {
        position:"absolute",
        top: "1.5rem",
        right: "1.5rem",
        cursor: "pointer"
    },
    filterTextField: {
        width: "100%",
        height: "40px"
    },
    filterWrapBtn: {
        marginTop: "1rem",
        display: "flex",
        justifyContent: "flex-end"
    },
    wrapShowFilter: {
        borderTop: "1px solid rgb(0, 0, 0, 0.2)",
        padding: "1rem 0"
    },
    showFilter: {
        display: "inline-flex",
        flexDirection: "row",
        alignItems: "center",
        borderRadius: "16px",
        padding: "4px 8px",
        marginLeft: "1rem",
        minWidth: "20px",
        width: "fit-Content",
        background: "#EBEBEB"
    },
    showFilterTitle: {
        fontSize: "1rem",
        fontWeight: "800"
    },
    showFilterInput: {
        marginLeft: "4px",
        fontWeight: "700",
    },
    showFilterClear: {
        cursor: "pointer",
        marginTop: "-2px",
        marginLeft: "1rem"
    }
}));