export const checkStaffFieldEdited = (oldStaff, newStaff) => {
    const staffReturn = {};
    for (let key in oldStaff) {
        if (key !== "policies") {
            oldStaff[key] !== newStaff[key] && (
                staffReturn[key] = newStaff[key]
            )
        }
    }
    staffReturn['id'] = newStaff.id;
    
    return staffReturn;
}