import AxiosClient from "../config/AxiosClient";
import AxiosRegistration from '../config/AxiosRegistration';
import AxiosNoAuthen from '../config/AxiosNoAuthen';

export const getResidentRegistrations = async (bodyRequest) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/temp/info';
    } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
        url = '/residencetemp/list';
    }
    return await AxiosClient.post(url, bodyRequest);
}

export const createRegistration = async (bodyRequest) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/resident/temp/request';
    } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
        url = 'residencetemp/create';
    }
    return await AxiosRegistration.post(url, bodyRequest);
}

export const getConfigurations = async (bodyRequest) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/getconfig';
    } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
        url = '/configs/get';
    }
    return await AxiosRegistration.post(url, bodyRequest);
}

export const getApartmentList = async (bodyRequest) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/getapartmentdata';
    } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
        url = '/apartmentrooms/get';
    }
    return await AxiosRegistration.post(url, bodyRequest);
}

export const checkExist = async (bodyRequest) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/exists';
    } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
        url = '/residences/exist';
    }
    return await AxiosRegistration.post(url, bodyRequest);
}

export const getReceptionist = async (bodyRequest) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/receptionist/get';
    }
    return await AxiosRegistration.post(url, bodyRequest);
}

export const updateReceptionist = async (bodyRequest) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/receptionist/update';
    }
    return await AxiosRegistration.post(url, bodyRequest);
}

export const loginReceptionist = async (bodyRequest) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/receptionist/login';
    } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
        url = '/receptionists/login';
    }
    return await AxiosNoAuthen.post(url, bodyRequest);
}

export const changePasswordReceptionist = async (authen, bodyRequest) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/receptionist/update';
    } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
        url = '/receptionists/activate';
    }
    return await AxiosNoAuthen.post(url, bodyRequest, {
        headers: {
            'Authorization': `Bearer ${authen}`,
            'Content-Type': 'application/json'
        }
    });
}


