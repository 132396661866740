import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    column: {
        position: 'relative',
        minWidth: '180px',
        minWidth: '180px',
        height: "fit-content"
    },
    title: {
        position: '-webkit-sticky',
        position: 'sticky',
        top: '0',
        zIndex: "11",

        display: 'flex',
        justifyContent: "center",
        alignItems: 'center',

        marginBottom: '1rem',
        border: '1px solid rgba(0, 0, 0, 0.3)',
        borderBottom: '4px solid blue',
        height: '70px',
        background: 'white',
        'box-shadow': '0px 10px 8px 0 rgba(0, 0, 0, 0.3)'
    },
    timeItem: {
        height: '40px',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        borderTop: "unset",
        background: 'rgba(0, 0, 0, 0.02)',
        '&:hover': {
            cursor: 'pointer',
            'box-shadow': '0 4px 5px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
        },
        "&:nth-child(2)": {
            borderTop: '1px solid rgba(0, 0, 0, 0.1)'
        }
    },
    appointContain: {
        position: 'absolute',
        left: '0',
        width: '100%'
    },
    appoint: {
        border: '1px solid  rgba(0, 0, 0, 0.3)',
        borderLeft: '4px solid #0b59c8',
        borderTopRightRadius: "5px",
        borderBottomRightRadius: "5px",

        padding: "0 5px",
        width: "98%",
        cursor: "pointer",
        'box-shadow': '0px 10px 8px 0 rgba(0, 0, 0, 0.3)',
        overflowY: "hidden",
        textOverflow: "ellipsis",
    },
    appointInfo: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        lineHeight: "1.2rem"
    }
}));

export default useStyles;