import makeStyles from '@material-ui/styles/makeStyles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    root: theme.dataGridStyle,
    addCabin: {
        minHeight: "98%"
    },
    head: {
        display: "flex",
        justifyContent: "space-between",
        padding: "0px 0px 22px 0px"
    },
    headTitle: {
        display: "flex",
        alignItems: "center",

        "& svg": {
            color: "#0b59c8",
            marginRight: "1rem",
            cursor: "pointer"
        }
    },
    headBtn: {
        display: "flex"
    },
    containInput: {
        display: "flex",
        margin: "1rem 0 1.5rem"
    },
    wrapInput: {
        width: "20%",
        marginRight: "1rem",
        "&:last-child": {
            marginRight: 0
        }
    },
    label: {
        fontWeight: "600"
    },
    input: {
        width: "100%"
    },
    placeholder: {
        color: "rgb(0, 0, 0, 0.4)"
    },
    table: {
        position: 'relative',
        borderRadius: '6px',
        paddingBottom: '16px',
        width: '100%',
        height: 'calc(100vh - 310px)',
        minHeight: "500px",
        background: '#FFFFFF',

        '& .MuiDataGrid-columnsContainer': {
            justifyContent: 'center'
        }
    },
}));