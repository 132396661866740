import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { CircularProgress } from '@mui/material';
import clsx from 'clsx';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { apiCabinetCellTypesGet, apiCabinetsGet, apiCabinetsDelete } from '../../../../api/cabinets';
import ErrorAlert from '../../../../components/alert/ErrorAlert';
import { Confirm } from '../../../../components/dialog';
import { CreateButton, DeleteButton } from '../../../../components/button';
import { PrimaryTitle } from '../../../../components/title';
import { formatToVND } from '../../../../helper/currency';
import { useStyles } from './styles';

export default function CabinetDetails({ changeResponseStatus }) {
    const [state, setState] = useState({
        cabinet: {},
        listCellType: [],
        responseStatus: {
            succ: "",
            err: ""
        },
        isLoading: true,
        isCabinetLoading: false,
        isConfirm: false,
        isConfirmLoading: false
    });

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const { cabinetID } = useParams();

    const cabinet = location?.state?.cabinet;

    const handleChangeResponseStatus = (succ = "", err = "") => {
        setState(prev => ({
            ...prev,
            responseStatus: {
                succ,
                err
            }
        }));
    }

    const onClickBackToCabinetSystem = () => {
        history.push('/admin/cabinet/system');
    }

    const fetchApiCabinetCellTypesGet = async () => {
        try {
            setState(prev => ({
                ...prev,
                isLoading: true
            }));

            const body = {
                language: i18n.language
            }

            const { data } = await apiCabinetCellTypesGet(body);
            const { Items: listCellType } = data;

            setState(prev => ({
                ...prev,
                listCellType
            }));
        } catch (error) {
            handleChangeResponseStatus("", t("Tải dữ liệu thất bại, vui lòng thử lại!"));
        }

        setState(prev => ({
            ...prev,
            isLoading: false
        }))
    }

    const fetchApiCabinetsGet = async () => {
        try {
            setState(prev => ({
                ...prev,
                isCabinetLoading: true
            }));

            const body = {
                id: cabinetID
            }

            const { data } = await apiCabinetsGet(body);
            const { Items: cabinetData } = data;

            setState(prev => ({
                ...prev,
                cabinet: cabinetData ? cabinetData[0] : []
            }));
        } catch (error) {
            if (error?.response?.data === "Cabinet not found") {
                // handle show page not found
            } else {
                handleChangeResponseStatus("", t("Tải thông tin tủ bị lỗi"))
            }
        }

        setState(prev => ({
            ...prev,
            isCabinetLoading: false
        }));
    }

    const fetchApiCabinetsDelete = async (body) => {
        try {
            setState(prev => ({
                ...prev,
                isConfirmLoading: true
            }));

            await apiCabinetsDelete(body);

            changeResponseStatus(t("Xóa thành công"));
            onClickBackToCabinetSystem();
        } catch (error) {
            if(error?.response?.data === "This CabinetId can not delete cause username already existed") {
                handleChangeResponseStatus("", t("Tủ đang được sử dụng, không thể xóa!"))
            } else {
                handleChangeResponseStatus("", t("Xóa tủ thất bại, vui lòng thử lại!"))
            }
        }

        setState(prev => ({
            ...prev,
            isConfirm: false,
            isConfirmLoading: false
        }));
    }

    const countCellsDetails = (cellsArr) => {
        if (!cellsArr || !Array.isArray(cellsArr)) return;

        const result = [];
        const count = [];

        cellsArr.forEach((item, index) => {
            if (index === 0) {
                count.push({
                    id: item.cellTypeID,
                    count: 1
                })
            } else {
                const checkHadCount = count.findIndex(countItem => {
                    if (countItem.id === item.cellTypeID) {
                        countItem.count += 1;
                        return true;
                    }
                });

                //had not count + 1 => push new
                if (checkHadCount < 0) {
                    count.push({
                        id: item.cellTypeID,
                        count: 1
                    })
                }
            }
        })

        state.listCellType.forEach(cellTypeItem => {
            count.forEach(countItem => {
                if (cellTypeItem.ID === countItem.id) {
                    result.push(
                        <React.Fragment key={countItem.id}>
                            <label className={classes.label}>{cellTypeItem.CellType[0].name}</label>
                            <br />
                            <p className={clsx(classes.info, classes.spaceWrap)}>
                                {countItem.count}
                            </p>
                        </React.Fragment>
                    )
                }
            })
        })

        return result;
    }

    const filterCellTypeHandler = (PriceType, PriceValue) => {
        let result = [];
        switch (PriceType) {
            case "free":
                result.push(
                    <p key={PriceType} className={clsx(classes.info, classes.spaceWrap)}>
                        {t("Không tính phí")}
                    </p>
                )
                break;
            case "scale":
                result.push(
                    <p key={PriceType} className={clsx(classes.info)}>
                        {t("Phí thay đổi theo giờ")}
                    </p>
                )

                for (let key in PriceValue) {
                    result.push(
                        <p key={key} className={clsx(classes.info)}>
                            {`${key} ${t("giờ")} - ${formatToVND(PriceValue[key])}`}
                        </p>
                    )
                }

                result.push(
                    <p key={"space"} className={clsx(classes.info, classes.spaceWrap)} />
                )
                break;
            case "turn":
                result.push(
                    <p key={PriceType} className={clsx(classes.info)}>{t("Phí cố định theo lượt")}</p>
                )
                result.push(
                    <p key={PriceValue} className={clsx(classes.info, classes.spaceWrap)}>
                        {`${formatToVND(PriceValue)}/ 1 ${t("lượt thuê")}`}
                    </p>
                )
                break;
            case "hourly":
                result.push(
                    <p key={PriceType} className={clsx(classes.info)}>{t("Phí cố định theo giờ")}</p>
                )
                result.push(
                    <p key={PriceValue} className={clsx(classes.info, classes.spaceWrap)}>
                        {`${formatToVND(PriceValue)}/ 1 ${t("giờ")}`}
                    </p>
                )
                break;
            default:
                result = [];
        }

        return result;
    }

    const onClickButtonDelete = () => {

        setState(prev => ({
            ...prev,
            isConfirm: true
        }));
    }

    const onClickButtonEdit = () => {
        history.push(`/admin/cabinet/system/edit-cabinet/${state.cabinet?.ID}`)
    }

    const onConfirm = () => {
        const body = {
            id: state?.cabinet?.ID
        }

        fetchApiCabinetsDelete(body);
    }

    useEffect(() => {
        if (!cabinet) {
            fetchApiCabinetsGet();
        } else {
            setState(prev => ({
                ...prev,
                cabinet: cabinet
            }));
        }

        fetchApiCabinetCellTypesGet();

    }, [cabinet, i18n.language]);

    return (
        <div className={classes.cabinetDetails}>
            <div className={classes.head}>
                <div className={classes.wrapTitle}>
                    <ArrowBackIosNewOutlinedIcon onClick={onClickBackToCabinetSystem} />
                    <PrimaryTitle>{t("Thông tin tủ")}</PrimaryTitle>
                </div>

                <div className={classes.wrapBtn}>
                    <DeleteButton
                        disabled={(state.isLoading || state.isCabinetLoading)}
                        onClick={onClickButtonDelete}
                    >
                        {t("Xóa")}
                    </DeleteButton>
                    <CreateButton
                        disabled={(state.isLoading || state.isCabinetLoading)}
                        onClick={onClickButtonEdit}
                    >
                        {t("Chỉnh sửa")}
                    </CreateButton>
                </div>
            </div>

            {(state.isLoading || state.isCabinetLoading) && (
                <div className={classes.wrapLoading}>
                    <CircularProgress />
                </div>
            )}

            {!(state.isLoading || state.isCabinetLoading) && (
                <div className={classes.contain}>
                    <div className={classes.containColumn}>
                        <p className={classes.infoTitle}>{t("Thông tin chung")}</p>

                        <label className={classes.label}>{t("Tên tủ")}</label>
                        <br />
                        <p className={clsx(classes.info, classes.spaceWrap)}>
                            {state.cabinet?.CabinetName}
                        </p>

                        <label className={classes.label}>{t("Tòa")}</label>
                        <br />
                        <p className={clsx(classes.info, classes.spaceWrap)}>
                            {state.cabinet?.Location}
                        </p>

                        <label className={classes.label}>{t("Loại tủ")}</label>
                        <br />
                        <p className={clsx(classes.info, classes.spaceWrap)}>
                            {state.cabinet?.TypeCabinet}
                        </p>

                        <label className={classes.label}>{t("Tổng số ô")}</label>
                        <br />
                        <p className={clsx(classes.info, classes.spaceWrap)}>
                            {state.cabinet?.Cells?.length}
                        </p>

                        {countCellsDetails(state.cabinet?.Cells)}
                    </div>

                    <div className={classes.containColumn}>
                        <p className={classes.infoTitle}>{t("Phí thuê tủ")}</p>

                        {state.listCellType.map(item => {
                            const checkMatchCellType = state.cabinet?.Cells?.findIndex(cellsItem => cellsItem.cellTypeID === item.ID)
                            
                            if (checkMatchCellType >= 0) {
                                return (
                                    <Fragment key={item.ID}>
                                        <label className={classes.label}>{item.CellType[0].name}</label>
                                        <br />
                                        {/* <p className={clsx(classes.info, classes.spaceWrap)}>A</p>
                                <p className={clsx(classes.info, classes.spaceWrap)}>A</p>
                                <p className={clsx(classes.info, classes.spaceWrap)}>A</p> */}
                                        {filterCellTypeHandler(item.PriceType, item.PriceValue)}
                                    </Fragment>
                                )
                            }
                        })}
                    </div>

                    <div className={classes.containColumn}>
                        <p className={classes.infoTitle}>{t("Thời gian quá hạn")}</p>

                        <label className={classes.label}>{t("Thời gian quá hạn")}</label>
                        <br />
                        <p className={clsx(classes.info, classes.spaceWrap)}>
                            {`${state.cabinet?.OverdueDate} ${t("ngày")}${i18n.language === "en" ? "(s)" : ""}`}
                        </p>
                    </div>
                </div>
            )}

            <Confirm
                isOpen={state.isConfirm}
                handleClose={() => setState(prev => ({
                    ...prev,
                    isConfirm: false
                }))}
                isLoading={state.isConfirmLoading}
                mess={t("Bạn có chắc muốn xóa?")}
                onConfirm={onConfirm}
            />

            <ErrorAlert
                message={state.responseStatus.err}
                onCloseDialog={() => handleChangeResponseStatus()}
            />
        </div>
    )
}
