import React, { useState, useEffect, useRef } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Button from '@material-ui/core/Button/Button';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { DataGrid } from '@material-ui/data-grid';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Cookies from 'js-cookie';
import SuccessAlert from '../../alert/SuccessAlert';
import ErrorAlert from '../../alert/ErrorAlert';
import Edit from './Edit';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import moment from 'moment-timezone';
import { apiGetapartmentdata } from '../../../api/apartment';
import { EmptyRowsView, CustomLoading } from '../../grid';
import { Confirm, FailedDialog } from '../../dialog';
import { apiDeviceGetAll, apiDeviceDelete } from '../../../api/device';
import SubTitle from '../../title/subTitle/index';
import { PageLoading } from '../../loading/index';
import { apiTommiAdd, apiTommiSync } from '../../../api/tommi';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import EditTommi from './EditTommi';
import CircularProgress from '@material-ui/core/CircularProgress';
import LargeTooltip from '../../largeTooltip';

const useStyles = makeStyles((theme) => ({
    root: theme.dataGridStyle,
    overlay: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        background: 'rgba(0,0,0,0.1)',
        zIndex: '2300'
    },
    requestError: {
        width: '100%',
        height: '65vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20px',
        color: '#6D6D6D'
    },
    status: {
        color: '#6D6D6D',
        paddingBottom: '0px'
    },
    statusActive: {
        color: '#2AB930',
        paddingBottom: '0px'
    },
    font: {
        fontSize: '16px',
        lineHeight: '22.4px',
        color: '#6D6D6D',
    },
    tableTitle: {
        position: 'relative',
        height: '56px',
        border: '1px solid rgba(224, 224, 224, 1)',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px'
    },
    alertStyle: {
        position: 'absolute',
        right: '9px',
        bottom: '8px',
        padding: '0 10px',
        zIndex: '10'
    },
    selectTitle: {
        fontSize: '16px',
        lineHeight: '22.4px',
        position: 'absolute',
        bottom: '8px',
        right: '8px'
    },
    tableStyle: {
        height: '100%',
        width: '100%',
        paddingBottom: '10px',
        maxHeight: '660px'
    },
    WarningIconStyle: {
        marginLeft: '10px',
        position: 'relative',
        bottom: '2px',
        color: 'rgb(255, 152, 0)!important'
    }
}))

const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);
const DeleteButton = withStyles(theme => ({ root: theme.delete_button }))(Button);

const Device = props => {
    const classes = useStyles();
    const [devices, setDevices] = useState([]);
    const [alert, setAlert] = useState({
        name: '',
        device_id: '',
        success: '',
        error: '',
        request: '',
        dialogMessage: ''
    });
    const [ids, setIds] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(parseInt(localStorage.getItem('rowsPerPage')));
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [id, setId] = useState('');
    const [isAdd, setIsAdd] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [blocks, setBlocks] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRow, setSelectedRow] = useState('');
    const [isSyncFace, setIsSyncFace] = useState(false);
    const [isExpiredSession, setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const alertRef = useRef();
    const { t } = useTranslation();

    const columns = [
        {
            field: 'DeviceName',
            headerName: t('Tên thiết bị'),
            cellClassName: 'super-app-theme--cell',
            flex: 1
        },
        {
            field: 'DeviceType',
            headerName: t('Loại thiết bị'),
            cellClassName: 'super-app-theme--cell',
            flex: 1,
            renderCell: params => params.row.DeviceType ? params.row.DeviceType : 'F10'
        },
        {
            field: 'DeviceStatus',
            headerName: t('Trạng thái'),
            cellClassName: 'super-app-theme--cell',
            flex: 1,
            renderCell: params => showDeviceStatus(params.row.DeviceStatus, params.row.LastUpdatedTime)
        },
        {
            field: 'Method',
            headerName: t('Thao tác'),
            cellClassName: 'super-app-theme--cell',
            width: 150,
            sortable: false,
            // renderCell: params => <p style={{ color: '#0b59c8', cursor: 'pointer' }} >{t("Đồng bộ")}</p>
            renderCell: params =>
                <div>
                    <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(e) => onActionClick(e, params.row.id)}
                        style={{ fontSize: '24px', fontWeight: '900', color: '#0b59c8' }}
                    >
                        ...
                </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={params.row.id === selectedRow && Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem
                            style={{ color: '#0b59c8' }}
                            onClick={() => onEditClick(params.row.id, params.row.DeviceType)}
                        >
                            {t("Xem chi tiết")}
                        </MenuItem>
                        {
                            params.row.DeviceType &&
                            <MenuItem
                                style={{ color: '#0b59c8' }}
                                onClick={() => onSyncDeviceClick(params.row.DeviceSN)}
                                disabled={isProcessing}
                            >
                                {t("Đồng bộ")}
                                {isProcessing && <CircularProgress size={24} style={{ marginLeft: '5px' }} />}
                            </MenuItem>
                        }
                    </Menu>
                </div>
        },

    ]

    useEffect(() => {
        if (isExpiredSession) {
            history.push('/login');
            dispatch({ type: 'IS_LOGIN_AGAIN_TRUE' });
        }
    }, [isExpiredSession]);

    const showOfflineWarning = (time) => {
        var date = new Date();
        date.setHours(date.getHours() - 1);
        if (date.getTime() > time) {
            return true;
        } else {
            return false;
        }
    }

    const showDeviceStatus = (status, time) => {
        switch (status) {
            case 'offline':
                return (
                    <div className={clsx(classes.status, classes.font)}>
                        <FiberManualRecordIcon style={{ position: 'relative', top: '0px' }} />
                        {t('Ngoại tuyến')}
                        {showOfflineWarning(time) && <LargeTooltip title={t("Thiết bị trực tuyến lần cuối vào") + ': ' + moment(time).format('DD-MM-YYYY HH:mm:ss')} >
                            <WarningAmberRoundedIcon className={classes.WarningIconStyle} />
                        </LargeTooltip>}
                    </div>)
            case 'online':
                return (
                    <div className={clsx(classes.statusActive, classes.font)}>
                        <FiberManualRecordIcon style={{ position: 'relative', top: '0px', color: '#2AB930' }} />
                        {t('Trực tuyến')}
                    </div>)
            default:
                return '';
        }
    }

    const sortDevices = (d1, d2) => {
        if (d1.DeviceStatus > d2.DeviceStatus) {
            return -1;
        }
        if (d1.DeviceStatus < d2.DeviceStatus) {
            return 1;
        }
        if (d1.ID > d2.ID) {
            return -1;
        }
        if (d1.ID < d2.ID) {
            return 1;
        }
        return 0;
    }

    const getDefaultValues = async () => {
        try {
            var res = await apiDeviceGetAll();
            const data = [];
            res.data.Items.forEach(item => {
                data.push({ ...item, id: item.ID });
            });
            data.sort((a, b) => sortDevices(a, b));
            setDevices(data);
            setAlert({ ...alert, name: '', device_id: '', block: '', request: '', receptionist: '', dialogMessage: '' });
            setIds([]);
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({ ...alert, request: t('Không lấy được dữ liệu') });
        }
        setLoading(true);
    }

    const getBlocks = async () => {
        try {
            const res = await apiGetapartmentdata();
            if (res.data.Items.length === 0) return setBlocks([]);
            var blockNames = [];
            res.data.Items.forEach(item => {
                blockNames.push(item.BlockID);
            });
            blockNames.sort();
            setBlocks([...new Set(blockNames)]);
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
        }
    }

    useEffect(() => {
        if (!loading) {
            getDefaultValues();
            getBlocks();
        }
    }, [loading])

    const onActionClick = (e, id) => {
        setAnchorEl(e.target);
        setSelectedRow(id);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const onRowClick = (e) => {
        setId(e.id);
        setAnchorEl(null);
        if (e.row.DeviceType) {
            history.replace(`/admin/device-management/device/${e.id}`);
        } else {
            setIsUpdate(true);
        }
    }

    const onEditClick = (deviceID, deviceType) => {
        setId(deviceID);
        setAnchorEl(null);
        if (deviceType) {
            history.replace(`/admin/device-management/device/${deviceID}`);
        } else {
            setIsUpdate(true);
        }
    }

    const onCloseEditClick = () => {
        setIsUpdate(false);
    }

    const onEditStatusChange = () => {
        setIsUpdate(false);
        if (isUpdate) setLoading(false);
    }

    const onSyncFaceClick = () => {
        setIsSyncFace(true);
        setAnchorEl(null);
    }

    const onAddClick = () => {
        setIsAdd(!isAdd);
    }

    const addDataToDB = async (device) => {
        try {
            setIsAdding(true);
            await apiTommiAdd(device);
            setAlert({ ...alert, success: t('Thêm thành công!') });
            setIsAdd(false);
            setLoading(false);
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setIsFailed(true);
            setAlert({ ...alert, dialogMessage: t('Thêm không thành công, vui lòng thử lại!') });
        }
        setIsAdding(false);
    }

    const onSelectedChange = (e) => {
        setIds(e);
    }

    const onDeleteClick = () => {
        setConfirmDialog(true);
    }

    const onConfirmDeleteClick = async () => {
        try {
            setIsDeleting(true);
            await apiDeviceDelete({ ids: ids });
            setAlert({ ...alert, error: '', success: `${t('Xóa thành công')} ${ids.length} ${ids.length > 1 ? t('nhiều thiết bị') : t('thiết bị')}` });
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({ ...alert, error: t('Xoá không thành công, vui lòng thử lại!'), success: '' });
        }
        setConfirmDialog(false);
        setIsDeleting(false);
        setLoading(false);
    }

    const onCloseDialog = () => {
        setAlert({ ...alert, success: '', error: '' });
    }

    useEffect(() => {
        if (alert.success !== '' || alert.error !== '') {
            if (alertRef.current) clearTimeout(alertRef.current);
            alertRef.current = setTimeout(() => {
                onCloseDialog();
            }, 5000);
        }
    }, [alert])

    const onAgreeClick = () => {
        setIsFailed(false);
    }

    const onConfirmSyncFaceClick = async () => {
        setIsProcessing(true);
        try {
            await apiTommiSync({});
            setAlert({ ...alert, error: '', success: t('Đồng bộ đang được tiến hành') });
        } catch (error) {
            if (error.response && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({ ...alert, error: t('Đồng bộ khuôn mặt không thành công, vui lòng thử lại sau!'), success: '' });
        }
        setIsProcessing(false);
        setIsSyncFace(false);
        setLoading(false);
    }

    const onSyncDeviceClick = async (deviceSn) => {
        setIsProcessing(true);
        try {
            await apiTommiSync({ deviceSn: deviceSn });
            setAlert({ ...alert, error: '', success: t('Đồng bộ đang được tiến hành') });
        } catch (error) {
            if (error.response && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({ ...alert, error: t('Đồng bộ khuôn mặt không thành công, vui lòng thử lại sau!'), success: '' });
        }
        setIsProcessing(false);
        setLoading(false);
        setAnchorEl(null);
    }

    return (
        <div style={{ position: 'relative', width: '97%', margin: 'auto', height: '100%' }} >
            {isProcessing && <div className={classes.overlay}></div>}
            <Confirm
                isOpen={confirmDialog}
                handleClose={() => setConfirmDialog(!confirmDialog)}
                isLoading={isDeleting}
                mess={t('Bạn có chắc muốn xóa thiết bị?')}
                onConfirm={onConfirmDeleteClick}
            />
            <Confirm
                isOpen={isSyncFace}
                handleClose={() => setIsSyncFace(!isSyncFace)}
                isLoading={isProcessing}
                mess={t('Bạn có muôn đồng bộ dữ liệu khuôn mặt lên thiết bị Tommi?')}
                onConfirm={onConfirmSyncFaceClick}
            />
            <FailedDialog
                isOpen={isFailed}
                mess={alert.dialogMessage}
                onConfirm={onAgreeClick}
            />
            {isUpdate &&
                <Edit
                    onEditStatusChange={onEditStatusChange}
                    id={id}
                />}
            {!isUpdate && <SubTitle text={t('Danh sách thiết bị')} />}
            {alert.request !== '' && <div className={classes.requestError}>{alert.request}</div>}
            {alert.request === '' && !isUpdate &&
                <div style={{ marginTop: '10px', width: '100%', clear: 'both', position: 'relative', height: '82%' }}>
                    <div className={classes.tableTitle}>
                        <SuccessAlert
                            message={alert.success}
                            onCloseDialog={onCloseDialog}
                        />
                        <ErrorAlert
                            message={alert.error}
                            onCloseDialog={onCloseDialog}
                        />
                        <CreateButton
                            variant="contained"
                            style={{ marginLeft: '8px' }}
                            component={Link}
                            to='/admin/device-management/add-device'
                        >
                            {t('Thêm')}
                        </CreateButton>
                        <DeleteButton
                            variant="outlined"
                            style={{ margin: '8px' }}
                            startIcon={<DeleteOutlineOutlinedIcon />}
                            disabled={ids.length === 0}
                            onClick={onDeleteClick}
                        >
                            {t('Xóa')}
                        </DeleteButton>
                        <CreateButton
                            variant="contained"
                            style={{ margin: '0' }}
                            onClick={() => setLoading(false)}
                        >
                            {t('Làm mới')}
                        </CreateButton>
                        <CreateButton
                            variant="contained"
                            style={{ margin: '7px', float: 'right' }}
                            onClick={() => setIsSyncFace(true)}
                        >
                            {t('Đồng bộ')}
                        </CreateButton>
                    </div>
                    <div className={clsx(classes.root, classes.tableStyle)}>
                        <DataGrid
                            componentsProps={{
                                pagination: {
                                    labelRowsPerPage: t("Số dòng mỗi trang"),
                                    labelDisplayedRows: ({ from, to, count }) => { return `${from}-${to} ${t('trong số')} ${count}` }
                                }
                            }}
                            components={{
                                NoRowsOverlay: () => EmptyRowsView(t('Không tìm thấy thiết bị')),
                                LoadingOverlay: CustomLoading,
                            }}
                            localeText={{
                                footerRowSelected: (count) => count + t(" hàng trong bảng đã được chọn"),
                                columnHeaderSortIconLabel: t('Sắp xếp')
                            }}
                            rows={devices}
                            columns={columns}
                            pageSize={rowsPerPage}
                            checkboxSelection
                            rowsPerPageOptions={[10, 50, 100]}
                            onPageSizeChange={pageSize => {
                                setRowsPerPage(pageSize);
                                localStorage.setItem('rowsPerPage', pageSize);
                            }}
                            // onRowClick={onRowClick}
                            onSelectionModelChange={onSelectedChange}
                            hideFooterRowCount
                            disableSelectionOnClick
                            disableColumnMenu={true}
                            loading={!loading}
                        />
                    </div>
                </div>}
        </div>
    )
}

export default Device;