import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, useRouteMatch } from "react-router-dom";
import ErrorAlert from "../../components/alert/ErrorAlert";
import SuccessAlert from "../../components/alert/SuccessAlert";
import {
  LayoutContent,
  LayoutSideBar
} from "../../components/layouts/defaultLayout";
import LayoutSideBarItem from "../../components/layouts/defaultLayout/layoutSidebar/layoutSidebarItems";
import { DefaultLayout } from "../../components/layouts/index";
import CabinetSystem from "./cabinetSystem";
import AddEditCabinet from "./cabinetSystem/addEditCanbinet";
import CabinetDetails from "./cabinetSystem/cabinetDetails";
import Size from "./cabinetSystem/size";
import AddSize from "./cabinetSystem/size/addSize";
import EditSize from "./cabinetSystem/size/editSize";
import DeviceConfig from "./deviceConfig";
import AddEditDevice from "./deviceConfig/addEditDevice";
import Management from "./management";
import CanbinetDetails from "./management/cabinetDetails";
import CabinetHistories from "./management/histories";
import HistoriesDetails from "./management/histories/details";
import StatisCabinet from "./statisticalAnalysis";
import { useStyles } from "./styles";

const Cabinet = () => {
  const [state, setState] = useState({
    responseStatus: {
      succ: "",
      err: "",
    },
    isCloseMenu: false,
  });
  const classes = useStyles();
  const { t } = useTranslation();
  const { url } = useRouteMatch();

  const handleChangeResponseStatus = (succ = "", err = "") => {
    setState((prev) => ({
      ...prev,
      responseStatus: {
        succ,
        err,
      },
    }));
  };
  const menuItemArray = [
    {
      to: `${url}/statistic`,
      title: t("Báo cáo thống kê"),
    },
    {
      to: `${url}/managenent`,
      title: t("Quản lý tủ"),
    },
    {
      to: `${url}/system`,
      title: t("Hệ thống tủ"),
    },
    {
      to: `${url}/device-configuration`,
      title: t('Danh sách cấu hình thiết bị')
    }
  ];

  return (
    <DefaultLayout>
      <LayoutSideBar isBtnHidden={false}>
        <p className={classes.secondaryTitle}>{t("Tủ hàng")}</p>
        {menuItemArray.map((item, index) => (
          <LayoutSideBarItem key={index} urls={item.to} titles={item.title} />
        ))}
      </LayoutSideBar>

      <LayoutContent>
        <Route exact path={`${url}/statistic`} component={StatisCabinet} />
        <Route exact path={`${url}/managenent`} component={Management} />
        <Route
          exact
          path={`${url}/managenent/details`}
          component={CanbinetDetails}
        />
        <Route
          exact
          path={`${url}/managenent/histories`}
          component={CabinetHistories}
        />
        <Route
          exact
          path={`${url}/managenent/histories/details`}
          component={HistoriesDetails}
        />
        <Route exact path={`${url}/system`} component={CabinetSystem} />
        <Route
          exact
          path={`${url}/system/add-cabinet`}
          render={(props) => (
            <AddEditCabinet
              {...props}
              changeResponseStatus={handleChangeResponseStatus}
            />
          )}
        />
        <Route
          exact
          path={`${url}/system/edit-cabinet/:cabinetID`}
          render={(props) => (
            <AddEditCabinet
              {...props}
              changeResponseStatus={handleChangeResponseStatus}
            />
          )}
        />
        <Route
          exact
          path={`${url}/system/cabinet-details/:cabinetID`}
          render={(props) => (
            <CabinetDetails
              {...props}
              changeResponseStatus={handleChangeResponseStatus}
            />
          )}
        />
        <Route exact path={`${url}/system/size`} component={Size} />
        <Route
          exact
          path={`${url}/system/size/add-size`}
          render={(props) => (
            <AddSize
              {...props}
              changeResponseStatus={handleChangeResponseStatus}
            />
          )}
        />
        <Route
          exact
          path={`${url}/system/size/edit-size/:sizeID`}
          render={(props) => (
            <EditSize
              {...props}
              changeResponseStatus={handleChangeResponseStatus}
            />
          )}
        />
        <Route
          exact
          path={`${url}/device-configuration`}
          render={(props) => (
            <DeviceConfig {...props} />
          )}
        />
        <Route
          exact
          path={`${url}/device-configuration/add`}
          render={(props) => (
            <AddEditDevice
              {...props}
              changeResponseStatus={handleChangeResponseStatus}
            />
          )}
        />
        <Route
          exact
          path={`${url}/device-configuration/edit/:ProfileName`}
          render={(props) => (
            <AddEditDevice
              {...props}
              changeResponseStatus={handleChangeResponseStatus}
            />
          )}
        />
      </LayoutContent>

      <SuccessAlert
        message={state.responseStatus.succ}
        onCloseDialog={() => handleChangeResponseStatus()}
      />

      <ErrorAlert
        message={state.responseStatus.err}
        onCloseDialog={() => handleChangeResponseStatus()}
      />
    </DefaultLayout>
  );
};

export default Cabinet;
