import React, { useEffect, useState, Suspense } from 'react';
import DirectURL from '../DirectURL/DirectURL';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Amplify from "aws-amplify";
import ForgetPasswordPage from './login/ForgetPasswordPage';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import awsConfig from '../aws-exports';
import axiosClient from '../config/AxiosClient';
import './i18n';
import LandingPage from './landing/LandingPage';
import Policy from './landing/Policy';
import Login from './login/Login';
import MaintainancePage from './maintainance/MaintainancePage';
import PinCodePage from './pin_info/PinCodePage';
import MyReceptionist from './register/MyReceptionist';
import ReceptionistLogin from './register/ReceptionistLogin';
import ResidentRegisterForm from './register/ResidentRegisterForm';

Amplify.configure(awsConfig);

const App = () => {
  const [configState, setConfigState] = useState({ logo: '', menu_background: '', theme_color: '', font_family: '' });
  const dispatch = useDispatch();
  const loadingConfig = useSelector(state => state.configState);
  const adminUserState = useSelector(state => state.adminUserState);

  useEffect(() => {
    setConfigState(loadingConfig);
    var style = document.createElement("style");
    style.innerHTML = `
      * {
        font-family: ${loadingConfig.font_family}!important;
      }
    `;
    document.head.appendChild(style);
  }, [loadingConfig.loading])

  const getConfig = async (username) => {
    try {
      var res;
      if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        res = await axiosClient.post('/getconfig', { 'username': username }, {
          headers: { 'Authorization': `Bearer ${Cookies.get('token')}` }
        });
      } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
        res = await axiosClient.post('/configs/get', { 'username': username }, {
          headers: {
            'Authorization': `Bearer ${Cookies.get('token')}`,
            'Content-Type': 'application/json'
          }
        });
      }
      var data = res.data.Items[0];
      var logo = data.Logo ? data.Logo : data.Logo.S;
      var menu_background = data.MenuBackground ? data.MenuBackground : data.MenuBackground.S;
      var theme_color = data.ThemeColor ? data.ThemeColor : data.ThemeColor.S;
      dispatch({
        type: "GET_CONFIG",
        logo: logo,
        menu_background: menu_background,
        theme_color: theme_color
      })
    } catch (error) {
      dispatch({
        type: "GET_CONFIG",
        logo: window.location.origin + '/images/TMAlogo.png',
        menu_background: '#072A3B',
        theme_color: '#0b59c8'
      })
    }
    dispatch({ type: "CHANGE_STATUS_LOADING" });
  }

  useEffect(() => {
    if (localStorage.getItem('username') !== null) getConfig('tsmart-building');
  }, [adminUserState.username, localStorage.getItem('username'), loadingConfig.isUpdatedTheme])

  useEffect(() => {
    Cookies.remove('token', { path: '/admin/manage-residences' });
  }, [])

  const theme = createTheme({
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: '#0b59c8',
        },
      },
      MuiPickersDay: {
        daySelected: {
          backgroundColor: '#0b59c8',
          '&:hover': {
            backgroundColor: '#0b59c8'
          }
        },
        current: {
          color: '#0b59c8',
        },
      },
      MuiPickersMonth: {
        root: {
          '&:focus': {
            color: 'none'
          }
        },
        focus: {
          color: '#0b59c8',
        },
        monthSelected: {
          color: '#0b59c8',
        },
        current: {
          color: '#0b59c8',
        },
      },
      MuiPickersYear: {
        root: {
          '&:focus': {
            color: 'none'
          }
        },
        yearSelected: {
          color: '#0b59c8',
        },
        current: {
          color: '#0b59c8',
        },
      },
      MuiRadio: {
        colorPrimary: {
          color: configState.backgroundColor,
          '&$checked': {
            color: configState.backgroundColor,
          }
        },
      }
    },
    create_button: {
      height: '40px',
      width: 'auto',
      textTransform: 'none',
      color: '#FFFFFF',
      fontSize: '18px',
      background: '#0b59c8',
      marginLeft: '16px',
      padding: '8px 16px',
      '&:hover': {
        background: '#0b59c8'
      }
    },
    cancel_button: {
      height: '40px',
      textTransform: 'none',
      color: '#0b59c8',
      fontSize: '18px',
      padding: '8px 16px',
      border: '1px solid #0b59c8',
      '&:hover': { borderColor: '#0b59c8' }
    },
    error_button: {
      height: '40px',
      textTransform: 'none',
      color: '#e01010',
      fontSize: '18px',
      padding: '8px 16px',
      border: '1px solid #e01010',
      '&:hover': {
        border: '1px solid #e01010'
      }
    },
    delete_button: {
      height: '40px',
      textTransform: 'none',
      color: '#e01010',
      fontSize: '18px',
      padding: '8px 16px',
      border: '1px solid #e01010',
      '&:hover': {
        border: '1px solid #e01010'
      }
    },
    primary_title: {
      fontSize: '22px',
      fontWeight: '700',
      lineHeight: '27px',
      color: '#000000',
      fontStyle: 'normal',
      float: 'left',
      marginRight: '24px',
      paddingTop: '5px'
    },
    delete_button: {
      height: '40px',
      textTransform: 'none',
      color: '#e01010',
      fontSize: '18px',
      padding: '8px 16px',
      border: '1px solid #e01010',
      '&:hover': { border: '1px solid #e01010' }
    },
    moreDetail: {
      color: '#0b59c8',
      textTransform: 'none',
      position: 'relative',
      left: '-5px',
      fontSize: '16px',
      lineHeight: '22.4px'
    },
    themeColor: '#0b59c8!important',
    place_holder: {
      color: '#B2B2B2',
      fontSize: '14px'
    },
    primaryColor: {
      background: '#0b59c8'
    },
    primaryTextColor: {
      color: '#0b59c8'
    },
    errorColor: {
      background: '#fb3729'
    },
    active: {
      background: 'rgba(40, 156, 216, 0.4)',
      borderLeft: '6px solid #289CD8',
      '&:hover': {
        background: 'rgba(40, 156, 216, 0.4)'
      }
    },
    secondary_title: {
      fontSize: '22px',
      lineHeight: '32px',
      fontWeight: '600',
      marginLeft: '20px',
      marginTop: '7px',
      marBottom: '14px',
    },
    moreDetail: { color: '#0b59c8', textTransform: 'none', position: 'relative', left: '-5px', fontSize: '16px', lineHeight: '22.4px' },
    themeColor: '#0b59c8!important',
    place_holder: { color: '#B2B2B2', fontSize: '14px', },
    primaryColor: { background: '#0b59c8' },
    primaryTextColor: { color: '#0b59c8' },
    errorColor: { background: '#fb3729' },
    active: {
      background: 'rgba(40, 156, 216, 0.4)',
      borderLeft: '6px solid #289CD8',
      '&:hover': { background: 'rgba(40, 156, 216, 0.4)' }
    },
    textMenu: {
      fontSize: '18px',
      lineHeight: '25.2px',
      fontWeight: '600',
      color: '#FFFFFF',
    },
    menuBackground: configState.menu_background,
    dataGridStyle: {
      '& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
        color: '#6D6D6D',
        fontSize: '16px',
        lineHeight: '22.4px',
        fontStyle: 'normal',
        fontWeight: '600',
      },
      '& .super-app-theme--cell': {
        color: '#000000',
        fontSize: '16px',
        lineHeight: '22px',
        fontStyle: 'normal',
        fontWeight: '400',
        textAlign: 'left',
        minWidth: '150px',
      },
      '& .super-app-theme--cell-payment': {
        color: '#000000',
        fontSize: '16px',
        lineHeight: '22px',
        fontStyle: 'normal',
        fontWeight: '400',
        textAlign: 'left',
        minWidth: '150px',
        cursor: 'pointer!important'
      },
      '& .super-app-theme--cell-dashboard': {
        color: '#000000',
        fontSize: '14px',
        lineHeight: '22px',
        fontStyle: 'normal',
        fontWeight: '400',
        textAlign: 'left',
      },
      '& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus': {
        outline: 'none',
        backgroundColor: 'none'
      },
      '& .MuiDataGrid-row:nth-child(even)': {
        backgroundColor: '#f9f9f9'
      },
      '& .MuiDataGrid-root .MuiDataGrid-row.Mui-selected': {
        backgroundColor: '#f9f9f9'
      },
      '& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
        outline: 'none'
      },
      '& .MuiCheckbox-colorPrimary.Mui-checked': {
        color: '#072A3B'
      },
      '& .MuiCheckbox-colorPrimary': {
        color: '#072A3B'
      },
      '& .MuiButton-colorPrimary': {
        color: '#072A3B'
      },
    },
    dataGridHeaderStyle: {
      '& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
        color: '#000000',
        fontSize: '16px',
        lineHeight: '22.4px',
        fontStyle: 'normal',
      },
      '& .super-app-theme--cell': {
        color: '#000000',
        fontSize: '16px',
        lineHeight: '22px',
        fontStyle: 'normal',
        fontWeight: '400',
        textAlign: 'left'
      },
      '& .super-app-theme--cell-dashboard': {
        color: '#000000',
        fontSize: '14px',
        lineHeight: '22px',
        fontStyle: 'normal',
        fontWeight: '400',
        textAlign: 'left',
      },
      '& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus': {
        outline: 'none',
        backgroundColor: 'none'
      },
      '& .MuiDataGrid-row:nth-child(even)': {
        backgroundColor: '#f9f9f9'
      },
      '& .MuiDataGrid-root .MuiDataGrid-row.Mui-selected': {
        backgroundColor: '#f9f9f9'
      },
      '& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
        outline: 'none'
      },
      '& .MuiCheckbox-colorPrimary.Mui-checked': {
        color: '#072A3B'
      },
      '& .MuiCheckbox-colorPrimary': {
        color: '#072A3B'
      },
      '& .MuiButton-colorPrimary': {
        color: '#072A3B'
      },
    }
  })

  return (
    <Suspense fallback="">
      <Router>
        <ThemeProvider theme={theme}>
          <Switch>
            <Route exact path='/my-receptionist' component={MyReceptionist} />
            <Route exact path='/register' component={ResidentRegisterForm} />
            <Route exact path='/receptionist-login' component={ReceptionistLogin} />
            <Route path="/code" component={PinCodePage} />
            <Route exact path='/privacy-policy.html' component={Policy} />
            <Route exact path='/' component={LandingPage} />
            <Route exact path='/login' component={Login} />
            <Route exact path='/forget-password' component={ForgetPasswordPage} />
            <Route exact path='/maintainance' component={MaintainancePage} />
            <Route>
              <DirectURL />
            </Route>
          </Switch>
        </ThemeProvider>
      </Router>
    </Suspense >
  );
}

export default App;
