import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty, checkEmail, checkNumberPhone, checkFullName, checkImage } from '../../validation/CheckForm';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { configurations } from '../../config/Registration';
import Button from '@material-ui/core/Button/Button';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MultipleAutocomplete from '@mui/material/Autocomplete';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import md5 from 'md5';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import vi from 'date-fns/locale/vi';
import "react-image-crop/dist/ReactCrop.css";
import Paper from '@material-ui/core/Paper';
import { v5 } from 'uuid';
import withStyles from '@material-ui/core/styles/withStyles';
import { compare } from 'natural-orderby';
import { useDispatch } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import nationalities from '../../data/nationalities.json';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { authenticator } from 'otplib';
import Webcam from "react-webcam";
import TopBar from './TopBar';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { getApartmentList, checkExist, createRegistration, getConfigurations } from '../../api/residentRegistrations';
import { checkQuality } from '../../api/faceID';
import { deepCloneArr } from '../../helper/deepClone';
import { BlockFloorRoomRole } from '../policies/index';
import { SuccessDialog, FailedDialog, Confirm } from '../dialog/index';
import { checkFaceImage } from '../actionImage/index';
import LargeTooltip from '../largeTooltip';

var province_data = require('../../data/data.json');
const useStyles = makeStyles(theme => ({
    part_form: {
        display: 'flex',
        width: '100%',
        minWidth: '1150px',
        background: '#FFFFFF',
        flexWrap: 'wrap',
        borderRadius: '6px'
    },
    contentBottomForm: {
        width: '98%',
        margin: 'auto',
        paddingBottom: '10px',
        paddingTop: '72px'
    },
    base_info_title: {
        fontSize: '20px',
        lineHeight: '28px',
        color: '#333333',
        fontWeight: '600',
        paddingLeft: '17px',
        paddingTop: '10px'
    },
    avatar_control: {
        width: '100%',
        marginLeft: '4%',
        marginTop: '8px',
        marginBottom: '12px',
        position: 'relative',
        height: '124px'
    },
    avatar_logo: {
        width: '116px',
        height: '116px',
        border: '1px solid #c8c8c8',
    },
    fileBtn: {
        position: 'absolute',
        bottom: '-25px',
        right: '-12px'
    },
    inputBlock: {
        height: '79px',
        width: '96%',
        paddingLeft: '4%',
        position: 'relative'
    },
    inputRightBlock: {
        height: '80px',
        width: '32%',
        paddingLeft: '2%',
        position: 'relative',
        float: 'left'
    },
    label: {
        paddingBottom: '0px',
        fontSize: '16px',
        lineHeight: '140%',
        color: '#000000',
        display: 'inline-block',
        margin: '0'
    },
    input: {
        width: '100%',
        height: '40px',
    },
    height50: {
        height: '44px'
    },
    error: {
        fontSize: '12px',
        color: 'red',
        position: 'absolute',
        bottom: '0'
    },

    secondaryTitle: theme.secondary_title,
    dialogTitleBackground: theme.primaryColor,
    dialogFailedTitleBackground: theme.errorColor,
    errorBtn: theme.error_button,
    dialogTitle: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '25px',
        color: '#FFFFFF'
    },
    contentDialog: {
        fontSize: '16px',
        height: '68px',
        borderBottom: '1px solid #EEEDF2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    placeholder: theme.place_holder,
    overlay: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        background: 'rgba(0,0,0,0.1)',
        zIndex: '1300'
    },
    imageAdding: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0'
    },
    date: {
        '& .MuiInput-underline:before': {
            border: '1px solid rgba(0, 0, 0, 0.23)',
            height: '40px',
            borderRadius: '4px',
        },
        '& .MuiInput-underline:after': {
            display: 'none'
        },
        '& input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd': {
            paddingLeft: '10px',
        }
    },
    imageDialog: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        background: 'rgba(0,0,0,0.1)',
        zIndex: '1333',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '550px'
    },
    focus: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: theme.themeColor,
            },
        },
    },
    select: {
        '& .MuiOutlinedInput-input': { padding: '10px 12px' }
    },
    checkbox: {
        '&$checked': {
            color: theme.menuBackground,
        },
    },
    checked: {},
    radio: {
        '&$checked': {
            color: theme.menuBackground
        }
    },
    formControlLabel: {
        position: 'relative', left: '-16px',
        '& .MuiFormControlLabel-label': {
            fontSize: '14px', lineHeight: '140%', color: '#000000'
        }
    },
    autocompleteStyle: {
        '& input#tags-outlined': {
            minWidth: '0'
        }
    },
    hidden: {
        display: 'none'
    },
    uploadStyle: {
        display: 'block',
        width: '100%',
        margin: '0',
        cursor: 'pointer'
    }
}))

const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);
const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);
const ErrorBtn = withStyles(theme => ({ root: theme.error_button }))(Button);

const ResidentRegisterForm = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const [wardNames, setWardNames] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [districtNames, setDistrictNames] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [provinceNames, setProvinceNames] = useState([]);
    const [apartmentData, setApartmentData] = useState([]);
    const [user, setUser] = useState({
        avatar: '',
        birthday: '',
        province: '',
        district: '',
        ward: '',
        sub_account: false,
        username: '',
        sex: 'other',
        phone: '',
        nationality: '',
        CMND: '',
        faceid: '',
        issued_place: '',
        apartments: []
    });
    const [message, setMessage] = useState({
        avatar: '',
        role: '',
        birthday: '',
        ward: '',
        district: '',
        province: '',
        full_name: '',
        username: '',
        password: '',
        phone: '',
        email: '',
        address: '',
        nationality: '',
        issued_place: '',
        CMND: '',
        career: '',
        note: '',
        sub_account: '',
        error: '',
        apartments: '',
        addApartment: ''
    });
    const [isSuccessDialog, setIsSuccessDialog] = useState(false);
    const [isFailedDialog, setIsFailedDialog] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [isImageAdding, setIsImageAdding] = useState(false);
    const [image, setImage] = useState('');
    const [displayBirthday, setDisplayBirthday] = useState(null);
    const defaultAvatar = window.location.origin + '/images/User.svg';
    const CMNDRef = useRef();
    const phoneRef = useRef();
    const [isExpiredSession, setIsExpiredSession] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isZoomAvatar, setIsZoomAvatar] = useState(false);
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const zoomRef = useRef();
    const webRef = useRef(null);
    const [isCapture, setIsCapture] = useState(false);
    const [permission, setPermission] = useState(false);
    const [devices, setDevices] = React.useState([]);
    const [videoConstraints, setVideoContraints] = useState({ deviceId: '', width: 640, height: 480 })
    const [isPreview, setIsPreview] = useState(false);
    const [base64data, setBase64data] = useState('');
    const [previewFaceID, setPreviewFaceID] = useState(null);
    const [selectedCam, setSelectedCam] = useState(false);
    const [isDialog, setIsDialog] = useState(false);
    const [age, setAge] = useState(100);
    const [configs, setConfigs] = useState({});
    const [loading, setLoading] = useState(false);
    const [logo, setLogo] = useState('');
    const [apartments, setApartments] = useState([]);
    const [isAddRoom, setIsAddRoom] = useState(false);

    useEffect(() => {
        if (isExpiredSession) {
            history.push('/receptionist-login');
            dispatch({ type: 'IS_LOGIN_AGAIN_TRUE' });
        }
    }, [isExpiredSession]);

    useEffect(() => {
        if (Cookies.get('receptionistToken') !== '' && Cookies.get('receptionistToken') !== null && typeof (Cookies.get('receptionistToken')) !== 'undefined') setPermission(true);
        else {
            setPermission(false);
            history.push('/receptionist-login');
        }
    }, [localStorage.getItem('receptionistName')])

    const getDefaultValues = async () => {
        try {
            let res;
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                res = await getConfigurations({ isReceptionist: true, username: 'tsmart-building' });
            } else {
                res = await getConfigurations({ username: 'tsmart-building' });
            }
            setConfigs(res.data.Items[0].Configuration ? res.data.Items[0].Configuration : configurations);
            setLogo(res.data.Items[0].Logo ? res.data.Items[0].Logo : `${window.origin}/images/TMAlogo.png`);
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setConfigs(configurations);
            setLogo(`${window.origin}/images/TMAlogo.png`);
        }
        setLoading(true);
    }

    useEffect(() => {
        if (!loading) getDefaultValues();
    }, [loading])

    const getBlocks = async () => {
        try {
            const res = await getApartmentList({ isReceptionist: true });
            setApartmentData(res.data.Items);
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
        }
    }

    const getProvinceNames = () => {
        const data = province_data.sort((a, b) => compare({ order: 'asc' })(a.Name, b.Name));
        var names = [];
        data.forEach(item => {
            names.push(item.Name);
        })
        setProvinces(data);
        setProvinceNames(names);
    }

    const getDistrictNames = (province) => {
        const data = provinces.filter(item => item.Name === province);
        if (data.length === 0) return setDistrictNames([]);
        var names = [];
        data[0].Districts.forEach(item => {
            names.push(item.Name);
        });
        names.sort((a, b) => compare({ order: 'asc' })(a, b))
        setDistrictNames(names);
        setDistricts(data[0].Districts);
    }

    const getWardNames = (district) => {
        const data = districts.filter(item => item.Name === district);
        if (data.length === 0) return setWardNames([]);
        var names = [];
        if (user.province === 'Thành phố Đà Nẵng' && data[0].Name === 'Huyện Hoàng Sa') names = [];
        else if (user.province === 'Thành phố Hải Phòng' && data[0].Name === 'Huyện Bạch Long Vĩ') names = [];
        else {
            data[0].Wards.forEach(item => {
                names.push(item.Name);
            });
        }
        names.sort((a, b) => compare({ order: 'asc' })(a, b))
        setWardNames(names);
    }

    useEffect(() => {
        getProvinceNames();
        getBlocks();
    }, [])

    useEffect(() => {
        if (user.province === '') return;
        getDistrictNames(user.province);
    }, [user.province])

    useEffect(() => {
        if (user.district === '') return;
        getWardNames(user.district);
    }, [user.district])

    const checkFaceID = async (base64) => {
        setIsImageAdding(true);
        try {
            const source = base64.slice(base64.indexOf('base64,') + 7);
            await checkQuality({ 'user_faces': [source] });
            setPreviewFaceID('valid');
        } catch (error) {
            var text = checkFaceImage(error.response ?.status);
            setPreviewFaceID(t(text));
        }
    }

    const checkFaceIDForUpload = async (base64) => {
        setIsImageAdding(true);
        try {
            const source = base64.slice(base64.indexOf('base64,') + 7);
            await checkQuality({ 'user_faces': [source] });
            setMessage({ ...message, avatar: '' });
        } catch (error) {
            var text = checkFaceImage(error.response ?.status);
            setMessage({ ...message, avatar: t(text) });
        }
        setIsImageAdding(false);
    }

    const onFaceChange = (e) => {
        const value = e.target.files[0];
        if (!checkImage(value.type)) {
            return setMessage({ ...message, avatar: t('Vui lòng tải lên hình ảnh') });
        }
        if (value.size > 1024000) {
            return setMessage({ ...message, avatar: t('Kích thước ảnh phải nhỏ hơn 1MB') });
        }
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                const source = reader.result.slice(reader.result.indexOf('base64,') + 7);
                setUser({ ...user, faceid: source });
                setImage(reader.result);
                checkFaceIDForUpload(reader.result);
            }
        }
        reader.readAsDataURL(value);
    }

    const handleDevices = React.useCallback(mediaDevices => {
        if (selectedCam) return;
        const results = mediaDevices.filter(({ kind }) => kind === "videoinput");
        setVideoContraints({ ...videoConstraints, deviceId: results[0] ?.deviceId});
        setDevices(results);
    }, [devices]);

    React.useEffect(() => {
        navigator.mediaDevices.enumerateDevices().then(handleDevices);
    }, [handleDevices]);

    const getDevice = () => {
        return devices.map((value, key) => {
            return <option key={key} value={value.deviceId}>{value.label}</option>
        })
    }

    const onDeviceChange = (e) => {
        setSelectedCam(true);
        setTimeout(() => {
            setVideoContraints({ ...videoConstraints, deviceId: e.target.value });
        }, 300);
    }

    const onCaptureImage = () => {
        const base64 = webRef.current.getScreenshot();
        if (typeof (base64) === 'undefined' || base64 === null) {
            setIsCapture(false);
            setImage('');
            setBase64data('');
            setMessage({ ...message, avatar: t('Hình ảnh không có') });
            return;
        }
        checkFaceID(base64);
        setBase64data(base64);
        setIsPreview(true);
    }

    const onApplyCaptureImage = async () => {
        const today = new Date();
        const source = base64data.slice(base64data.indexOf('base64,') + 7);
        setImage(base64data);
        setUser({ ...user, faceid: source });
        setMessage({ ...message, avatar: t('') });
        setPreviewFaceID(null);
        setIsPreview(false);
        setIsCapture(false);
    }

    const captureImage = () => {
        if (isCapture) {
            return (
                <div className={classes.imageDialog}>
                    <Paper style={{ width: '640px', height: '560px', borderRadius: '6px', position: 'relative' }}>
                        <div style={{ height: '480', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 0 }}>
                            <Webcam
                                audio={false}
                                ref={webRef}
                                height={480}
                                width={640}
                                screenshotFormat="image/png"
                                screenshotQuality={1}
                                videoConstraints={videoConstraints}
                            />
                            <div style={{ height: '50px', width: '100%', textAlign: 'center', marginBottom: '10px', position: 'absolute', bottom: '0', left: '0' }}>
                                <CancelButton
                                    variant="outlined"
                                    onClick={() => setIsCapture(false)}
                                    style={{ background: '#FFFFFF', margin: '10px 5px' }}
                                >
                                    {t("Hủy")}
                                </CancelButton>
                                <CreateButton
                                    variant="contained"
                                    style={{ margin: '10px 5px' }}
                                    onClick={onCaptureImage}
                                >
                                    {t("Chụp")}
                                </CreateButton>
                            </div>
                            <div style={{ position: 'absolute', top: '5px', left: '5px' }}>
                                <select
                                    style={{ height: '30px', fontSize: '16px'}}
                                    onChange={onDeviceChange}
                                >
                                    {getDevice()}
                                </select>
                            </div>
                        </div>
                    </Paper>
                </div>
            )
        }
    }

    const previewCaptureImage = () => {
        if (isPreview) {
            return (
                <div className={classes.imageDialog}>
                    <Paper style={{ width: '640px', height: '560px', borderRadius: '6px', position: 'relative' }}>
                        <div style={{ height: '100%', width: '100%' }}>
                            <div style={{ height: '480px', width: '100%' }}>
                                <img src={base64data} alt="preview" width="100%" height="100%" />
                            </div>
                            {previewFaceID !== null && previewFaceID !== 'valid' && <p style={{ color: 'red', fontSize: '16px', height: '20px' }}>{previewFaceID}</p>}
                            {previewFaceID === 'valid' && <p style={{ color: 'rgb(42, 185, 48)', fontSize: '16px', height: '20px' }}>{t('Hình ảnh hợp lệ')}</p>}
                            {previewFaceID === 'valid' && <div style={{ height: '50px', width: '100%', textAlign: 'center', marginBottom: '10px' }}>
                                <CancelButton
                                    variant="outlined"
                                    style={{ margin: '10px 5px', background: '#FFFFFF' }}
                                    disabled={isImageAdding}
                                    onClick={() => {
                                        setIsCapture(true);
                                        setIsPreview(false);
                                        setPreviewFaceID(null)
                                    }}
                                >
                                    {t("Chụp lại")}
                                </CancelButton>
                                <CreateButton
                                    variant="contained"
                                    style={{ margin: '10px 5px' }}
                                    onClick={onApplyCaptureImage}
                                    disabled={isImageAdding}
                                >
                                    {t("Áp dụng")}
                                    {isImageAdding && <CircularProgress size={25} style={{ color: '#0b59c8', position: 'absolute' }} />}
                                </CreateButton>
                            </div>}
                            {previewFaceID !== null && previewFaceID !== 'valid' && <div style={{ height: '50px', width: '100%', textAlign: 'center', marginBottom: '10px' }}>
                                <CancelButton
                                    variant="outlined"
                                    style={{ margin: '10px 5px', background: '#FFFFFF' }}
                                    disabled={isImageAdding}
                                    onClick={() => {
                                        setIsCapture(false);
                                        setIsPreview(false);
                                        setPreviewFaceID(null)
                                    }}
                                >
                                    {t("Hủy")}
                                </CancelButton>
                                <CreateButton
                                    variant="contained"
                                    style={{ margin: '10px 5px' }}
                                    onClick={() => {
                                        setIsCapture(true);
                                        setIsPreview(false);
                                        setPreviewFaceID(null)
                                    }}
                                    disabled={isImageAdding}
                                >
                                    {t("Chụp lại")}
                                    {isImageAdding && <CircularProgress size={25} style={{ color: '#0b59c8', position: 'absolute' }} />}
                                </CreateButton>
                            </div>}
                        </div>
                    </Paper>

                </div>
            )
        }
    }

    const onChange = (e) => {
        var name = e.target.name;
        var value = e.target.value.trim();
        if (isEmpty(value)) {
            setMessage({ ...message, [name]: t("Không được để trống") });
        } else {
            setMessage({ ...message, [name]: "" });
        }
        setUser({
            ...user,
            [name]: value
        })
    }

    const onMainAccountChange = (e) => {
        var sub_account = e.target.value;
        if (sub_account === 'false') {
            setUser({ ...user, sub_account: false });
        } else {
            var aprts = [];
            apartments.forEach(item => {
                if (item.role === 'host') aprts.push({ ...item, role: 'member' });
                else aprts.push(item);
            })
            setUser({ ...user, sub_account: true, phone: '', apartments: aprts });
            setMessage({ ...message, phone: '' });
            setApartments(aprts);
        }
    }

    const onFullNameChange = (e) => {
        const full_name = e.target.value.trim();
        setUser({ ...user, full_name: full_name });
        if (isEmpty(full_name)) {
            setMessage({ ...message, full_name: t("Không được để trống") });
        } else if (!checkFullName(full_name)) {
            setMessage({ ...message, full_name: t("Vui lòng nhập đúng họ tên") });
        } else if (full_name.length < 5 || full_name.length > 26) {
            setMessage({ ...message, full_name: t("Độ dài ký tự khoảng 5 đến 26") });
        } else {
            setMessage({ ...message, full_name: '' });
        }
    }

    const onNationalityChange = (e, newValue) => {
        if (newValue === null) {
            if (age < configs.CMND_threshold) setMessage({ ...message, nationality: '' })
            else setMessage({ ...message, nationality: t("Không được để trống") });
            setUser({ ...user, nationality: '' });
            return;
        }
        setUser({ ...user, nationality: newValue });
        const re = /^(\d{9}|\d{12})$/;
        const pp = /^[A-Z]{1}\d{7}$/;
        if (user.CMND.trim() === '') return setMessage({ ...message, nationality: '' });
        if (newValue === 'Việt Nam') {
            if (!pp.test(user.CMND) && !re.test(user.CMND)) {
                setMessage({ ...message, CMND: t("CMND/CCCD/Passport không hợp lệ"), nationality: '' });
            } else {
                setMessage({ ...message, CMND: '', nationality: '' });
                if (CMNDRef.current) clearTimeout(CMNDRef.current);
                CMNDRef.current = setTimeout(() => {
                    checkCMND(user.CMND, true);
                }, 300);
            }
        } else {
            if (user.CMND.trim().length < 8) {
                setMessage({ ...message, CMND: t("CMND/CCCD/Passport phải từ 8 ký tự"), nationality: '' });
            } else {
                setMessage({ ...message, CMND: '', nationality: '' });
                if (CMNDRef.current) clearTimeout(CMNDRef.current);
                CMNDRef.current = setTimeout(() => {
                    checkCMND(user.CMND, true);
                }, 300);
            }
        }

    }

    const onCMNDChange = (e) => {
        const CMND = e.target.value.trim();
        const re = /^(\d{9}|\d{12})$/;
        const pp = /^[A-Z]{1}\d{7}$/;
        setUser({ ...user, CMND: CMND });
        if (CMNDRef.current) clearTimeout(CMNDRef.current);
        CMNDRef.current = setTimeout(() => {
            if (CMND === '') {
                setUser({ ...user, issued_place: '', CMND: CMND });
                if (age < configs.CMND_threshold || configs.CMND !== 1) setMessage({ ...message, CMND: '' });
                else setMessage({ ...message, CMND: t('Không được để trống') });
                return;
            }
            if (user.nationality === 'Việt Nam' || user.nationality === '') {
                if (!pp.test(CMND) && !re.test(CMND)) {
                    setMessage({ ...message, CMND: t("CMND/CCCD/Passport không hợp lệ") });
                } else {
                    setMessage({ ...message, CMND: '' });
                    if (CMNDRef.current) clearTimeout(CMNDRef.current);
                    CMNDRef.current = setTimeout(() => {
                        checkCMND(CMND, false);
                    }, 300);
                }
            } else {
                if (CMND.trim().length < 8) {
                    setMessage({ ...message, CMND: t("CMND/CCCD/Passport phải từ 8 ký tự") });
                } else {
                    setMessage({ ...message, CMND: '' });
                    checkCMND(CMND, false);
                }
            }
        }, 300);
    }

    const checkCMND = async (CMND, nationality) => {
        try {
            var res;
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                res = await checkExist({ CMND: CMND, isReceptionist: true });
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                res = await checkExist({ CMND: CMND });
            }
            if (res.data === 'Found')
                nationality ? setMessage({ ...message, CMND: t("CMND/CCCD/Passport đã tồn tại"), nationality: '' }) : setMessage({ ...message, CMND: t("CMND/CCCD/Passport đã tồn tại") })
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setMessage({ ...message, CMND: t("Đã xảy ra lỗi trong quá trình kiểm tra CMND/CCCD/Passport") });
        }
    }

    const onDateChange = (e) => {
        const today = new Date();
        setDisplayBirthday(e);
        const date = new Date(e);
        setUser({ ...user, birthday: moment(date.getTime()).format('YYYY-MM-DD') });
        if (isNaN(date.getTime()) || e === null) {
            setMessage({ ...message, birthday: t("Thông tin chưa hợp lệ") });
        } else if (moment(today.getTime()).format('YYYY-MM-DD') < moment(date.getTime()).format('YYYY-MM-DD')) {
            setMessage({ ...message, birthday: t("Phải trước ngày hiện tại") });
        } else {
            const countAge = today.getFullYear() - date.getFullYear();
            setAge(countAge);
            if (user.CMND === '' && countAge < configs.CMND_threshold) setMessage({ ...message, birthday: '', CMND: '' });
            else setMessage({ ...message, birthday: '' });
        }
    }

    const onIssuedPlaceChange = (e) => {
        const value = e.target.value;
        setUser({ ...user, issued_place: value });
        // if(value.trim()==='' && configs.issued_place) setMessage({...message,issued_place:t('Không được để trống')})
        // else setMessage({...message,issued_place:''});
    }

    const onCareerChange = (e) => {
        const career = e.target.value.trim();
        setUser({ ...user, career: career });
        if (career.length > 30) {
            setMessage({ ...message, career: t('Độ dài ký tự không quá 30') });
        } else if (!checkFullName(career)) {
            setMessage({ ...message, career: t('Vui lòng nhập đúng nghề nghiệp') });
        } else {
            setMessage({ ...message, career: '' });
        }
    }

    const onNoteChange = (e) => {
        const note = e.target.value.trim();
        setUser({ ...user, note: note });
        if (note.length > 256) {
            setMessage({ ...message, note: t('Độ dài không quá ký tự không quá 256') });
        } else {
            setMessage({ ...message, note: '' });
        }
    }

    const onEmailChange = (e) => {
        var email = e.target.value.trim();
        setUser({ ...user, email: email });
        if (email === '') {
            setMessage({ ...message, email: "" });
        } else if (!checkEmail(email)) {
            setMessage({ ...message, email: t("Email không hợp lệ") });
        } else if (email.length < 6 || email.length > 40) {
            setMessage({ ...message, email: t("Độ dài ký tự khoảng từ 6 đến 40") });
        } else {
            setMessage({ ...message, email: "" });
        }
    }

    const onPhoneChange = (e) => {
        const phone = e.target.value.trim();
        setUser({ ...user, phone: phone });
        if (phoneRef.current) clearTimeout(phoneRef.current);
        phoneRef.current = setTimeout(() => {
            if (isEmpty(phone)) {
                setMessage({ ...message, phone: t("Không được để trống") });
            } else if (!checkNumberPhone(phone)) {
                setMessage({ ...message, phone: t("Phải là số điện thoại gồm 10 số") });
            } else {
                setMessage({ ...message, phone: "" });
                if (phone !== '') checkPhone(phone);
            }
        }, 500);
    }

    const checkPhone = async (phone) => {
        try {
            const handledPhone = phone.indexOf('0') === 0 ? phone.replace('0', '+84') : phone;
            var res;
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                res = await checkExist({ phone: handledPhone, isReceptionist: true });
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                res = await checkExist({ phone: handledPhone });
            }
            if (res.data === 'Found') setMessage({ ...message, phone: t("Số điện thoại đã tồn tại") });
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setMessage({ ...message, CMND: t("Đã xảy ra lỗi trong quá trình kiểm tra số điện thoại") });
        }
    }

    const onProvinceChange = (e, newValue) => {
        setDistrictNames([]);
        setWardNames([]);
        if (newValue === null) {
            setUser({ ...user, province: '', ward: '', district: '' });
            return;
        }
        setMessage({ ...message, province: "" });
        setUser({ ...user, province: newValue, ward: '', district: '' });
    }

    const onDistrictChange = (e, newValue) => {
        setWardNames([]);
        if (newValue === null) {
            setUser({ ...user, district: '', ward: '' });
            return;
        }
        setMessage({ ...message, district: "" });
        setUser({ ...user, district: newValue, ward: '' });
    }

    const onWardChange = (e, newValue) => {
        if (newValue === null) {
            setUser({ ...user, ward: '' });
            return;
        }
        setMessage({ ...message, ward: '' });
        setUser({ ...user, ward: newValue });
    }

    const onAddressChange = (e) => {
        const address = e.target.value;
        setUser({ ...user, address: address });
        if (address.length > 50) {
            setMessage({ ...message, address: t("Độ dài ký tự không quá 50") });
        } else {
            setMessage({ ...message, address: '' });
        }
    }

    const onApartmentChange = (e, newValue) => {
        setApartments(newValue);
        setUser({ ...user, apartments: newValue });
    }

    const showApartmentDialog = () => {
        setIsAddRoom(true);
    }

    const onCloseAddApartmentClick = () => {
        setIsAddRoom(false);
    }

    const onSaveRoomClick = (aparts) => {
        setUser({ ...user, apartments: [...aparts] });
        setIsAddRoom(false);
        setApartments(deepCloneArr(aparts));
        setMessage({ ...message, apartments: '' });
    }

    const onCancelSaveRoomClick = () => {
        setIsAddRoom(false);
        setApartments(deepCloneArr(user.apartments));
    }

    const onSubmit = async () => {
        if (isEmpty(user.phone) && !user.sub_account) return setMessage({ ...message, phone: t("Không được để trống") });
        if (message.phone !== '') return;
        if (user.apartments.length === 0) return setMessage({ ...message, apartments: t("Vui lòng nhập đầy đủ thông tin căn hộ") });
        if (isEmpty(user.full_name)) return setMessage({ ...message, full_name: t("Không được để trống") });
        if (message.full_name !== '') return;
        if (message.birthday !== '') return;
        if (message.career !== '') return;
        if (isEmpty(user.nationality)) return setMessage({ ...message, nationality: t("Không được để trống") });
        if (isEmpty(user.CMND) && age > 14 && configs.CMND === 1) return setMessage({ ...message, CMND: t("Không được để trống") });
        if (message.CMND !== '') return;
        if (message.address !== '') return;
        if (message.career !== '') return;
        if (message.email !== '') return;
        if (message.note !== '') return;
        if (isEmpty(user.faceid) && configs.faceid !== -1) setIsDialog(true);
        else onConfirmClick();
    }

    const onConfirmClick = async () => {
        try {
            setIsAdding(true);
            var userInfo;
            var apartmentTemp = [];
            user.apartments.forEach(item => {
                apartmentTemp.push({ block: item.block, floor: item.floor, room: item.room, role: item.role });
            })
            if (user.sub_account) {
                userInfo = { ...user, time: new Date().getTime(), status: 'inactive', password: md5('123456789x@X'), signing_key: v5(user.username, v5.URL), faceid_count: 0, private_key: authenticator.generateSecret() + authenticator.generateSecret(), isReceptionist: true, apartments: apartmentTemp };
            } else {
                userInfo = { ...user, time: new Date().getTime(), status: 'inactive', password: md5('123456789x@X'), username: user.phone, signing_key: v5(user.phone, v5.URL), phone: user.phone.indexOf('0') === 0 ? user.phone.replace('0', '+84') : user.phone, faceid_count: 0, private_key: authenticator.generateSecret() + authenticator.generateSecret(), isReceptionist: true, apartments: apartmentTemp };
            }
            await createRegistration(userInfo);
            localStorage.setItem('apartmentBlock', userInfo.apartments[0].block);
            localStorage.setItem('apartmentFloor', userInfo.apartments[0].floor);
            localStorage.setItem('apartmentRoom', userInfo.apartments[0].room);
            setIsSuccessDialog(true);
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setIsFailedDialog(true);
            setMessage({ ...message, error: t('Đã xảy ra lỗi, vui lòng thử lại!') });
        }
        setIsDialog(false);
        setIsAdding(false);
    }

    const onRefreshClick = () => {
        window.location.reload();
    }

    const onFailedAgreeClick = () => {
        setIsFailedDialog(false);
        setIsAdding(false);
    }

    const optionsAvatarClose = () => {
        setAnchorEl(null);
    }

    const onAvatarOptionsClick = (e) => {
        setIsZoomAvatar(true);
        setAnchorEl(null);
    }

    useEffect(() => {
        const handleClickOutside = (e => {
            if (zoomRef.current && !zoomRef.current.contains(e.target))
                setIsZoomAvatar(false);
        })
        document.addEventListener('mousedown', handleClickOutside);
    }, [zoomRef])

    const zoomAvatar = () => {
        return (
            <Dialog
                open={isZoomAvatar}
                maxWidth="xl"
            >
                <DialogContent ref={zoomRef} >
                    <img src={image} alt="" style={{ maxHeight: '500px' }} />
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <div>
            <TopBar logo={logo} />
            {!loading &&
                <div style={{ width: '100%', height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress style={{ color: '#0b59c8' }} />
                </div>}
            {loading && <div className={clsx(classes.contentBottomForm, classes.focus)}>
                {isAdding && <div className={classes.overlay}></div>}
                {zoomAvatar()}
                {captureImage()}
                {previewCaptureImage()}
                <Confirm
                    isOpen={isDialog}
                    handleClose={() => setIsDialog(false)}
                    isLoading={isAdding}
                    mess={t("Chưa có Face ID, bạn có muốn tiếp tục đăng ký?")}
                    onConfirm={onConfirmClick}
                />
                <SuccessDialog
                    isOpen={isSuccessDialog}
                    mess={t("Thông tin cư dân sẽ được duyệt bởi ban quản lý")}
                    onConfirm={onRefreshClick}
                />
                <FailedDialog
                    isOpen={isFailedDialog}
                    mess={message.error}
                    onConfirm={onFailedAgreeClick}
                />
                <BlockFloorRoomRole
                    title={t('Thông tin căn hộ')}
                    isChooseApartment={isAddRoom}
                    closeDialog={onCloseAddApartmentClick}
                    roomData={apartments}
                    onCancelClick={onCancelSaveRoomClick}
                    onSaveClick={(aparts) => onSaveRoomClick(aparts)}
                    apartmentData={apartmentData}
                    subAccount={user.sub_account}
                    CreateButton={CreateButton}
                    CancelButton={CancelButton}
                />
                {permission && <div style={{ paddingBottom: '8px', marginTop: '15px', position: 'relative', left: '-8px' }}>
                    <p className={classes.secondaryTitle} >{t("ĐĂNG KÝ CƯ DÂN MỚI")}</p>
                </div>}
                {permission && <div className={classes.part_form}>
                    <div style={{ width: '30%' }}>
                        <p className={classes.base_info_title} style={{ paddingLeft: '4%' }}>{t("Thông tin tài khoản")}</p>
                        {configs.faceid !== -1 && <div className={classes.avatar_control} >
                            <label className={classes.label}>{t("Face ID")} {configs.faceid === 1 && <span style={{ color: 'red' }}>(*)</span>} </label><br />
                            <div className={classes.avatar_logo} style={{ position: 'relative' }} >
                                {image === '' && <img src={defaultAvatar} onClick={(e) => setAnchorEl(e.target)} width="100%" height="85%" alt="Face ID" />}
                                {image !== '' && <img src={image} style={{ objectFit: 'cover' }} onClick={(e) => setAnchorEl(e.target)} width="100%" height="100%" alt="Face ID" />}
                                <label className={classes.fileBtn} >
                                    <IconButton
                                        component="span"
                                        onClick={(e) => {
                                            setIsCapture(true);
                                        }}
                                    >
                                        <PhotoCamera style={{ color: '#565e67', fontSize: '40px' }} />
                                    </IconButton>
                                    <Menu
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={optionsAvatarClose}
                                    >
                                        {image !== '' && <MenuItem onClick={onAvatarOptionsClick}>{t('Xem ảnh')}</MenuItem>}
                                        <MenuItem onClick={() => {
                                            setAnchorEl(null);
                                            setIsCapture(true);
                                        }}>
                                            {t('Chụp hình')}
                                        </MenuItem>
                                        <MenuItem onClick={optionsAvatarClose}>
                                            <label htmlFor="contained-button-file" className={classes.uploadStyle}>
                                                <input accept="image/*" id="contained-button-file" multiple type="file" className={classes.hidden} onChange={onFaceChange} />
                                                {t('Tải hình lên')}
                                            </label>
                                        </MenuItem>
                                    </Menu>
                                </label>
                                {image === '' && <p style={{ width: '80px', textAlign: 'center', position: 'absolute', bottom: '0', left: '0', color: '#000000' }}>{t('Chụp hình')}</p>}
                            </div>
                            <p style={{ color: 'red' }}>{message.avatar}</p>
                        </div>}
                        <div className={classes.inputBlock} style={{ height: '40px', minWidth: '380px', paddingTop: '9px', marginBottom: '5px' }}>
                            <RadioGroup className={classes.formControlLabel} value={user.sub_account} onChange={onMainAccountChange} row style={{ marginLeft: '16px', height: '40px', paddingTop: '5px' }} >
                                <FormControlLabel value={false} control={<Radio color="primary" classes={{ root: classes.radio, checked: classes.checked }} size="small" />} label={t('Tài khoản chính')} />
                                <FormControlLabel
                                    value={true}
                                    control={<Radio color="primary"
                                        classes={{ root: classes.radio, checked: classes.checked }}
                                        size="small" />}
                                    label={
                                        <LargeTooltip
                                            title={<div>
                                                {t('Tài khoản phụ KHÔNG thể')}: <br />
                                                - {t('Đăng nhập')} <br />
                                                - {t('Sử dụng cuộc gọi video')} <br />
                                                - {t('Sử dụng mã QR')} <br />
                                            </div>}
                                        >
                                            <span>
                                                {t('Tài khoản phụ')}
                                                <HelpOutlineIcon style={{ fontSize: '16px', marginLeft: '5px' }} />
                                            </span>
                                        </LargeTooltip>
                                    }
                                />

                            </RadioGroup>
                        </div>
                        <div className={classes.inputBlock}>
                            <label className={classes.label}>{t("Số điện thoại")}<span style={{ color: 'red' }}>(*)</span></label><br />
                            <TextField
                                classes={{
                                    root: classes.input
                                }}
                                autoFocus
                                value={user.phone}
                                size="small"
                                placeholder={t("Số điện thoại")}
                                variant="outlined"
                                disabled={user.sub_account}
                                onChange={onPhoneChange}
                                error={message.phone.length > 0}
                                helperText={message.phone}
                            />
                        </div>
                        <p className={classes.base_info_title} style={{ paddingTop: '0', paddingLeft: '4%' }}>{t("Thông tin tòa nhà")}</p>
                        <div className={classes.inputRightBlock} style={{ width: '96%', float: 'left', paddingLeft: '4%', height: 'auto' }}>
                            <label className={classes.label} style={{ marginBottom: '0' }}>{t('Căn hộ')}</label>
                            <MultipleAutocomplete
                                multiple
                                classes={{ inputRoot: classes.autocompleteStyle }}
                                id="tags-outlined"
                                open={false}
                                options={user.apartments}
                                size="small"
                                getOptionLabel={(option) => {
                                    let mess = option.block + option.floor + option.room + ' - ' + `${option.role === 'member' ? t('Người trong gia đình') : t('Chủ hộ')}`;
                                    return mess;
                                }}
                                value={user.apartments}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        disabled
                                        onClick={showApartmentDialog}
                                        helperText={message.apartments}
                                        error={message.apartments !== ''}
                                    />
                                )}
                                onChange={(e, newValue) => onApartmentChange(e, newValue)}
                            />
                        </div>
                    </div>
                    <div style={{ width: '70%' }}>
                        <p className={clsx(classes.base_info_title, classes.height50)} style={{ paddingLeft: '2%' }}>{t("Thông tin cá nhân")}</p>
                        <div className={classes.inputRightBlock}>
                            <label className={classes.label}>{t("Họ và tên")} <span style={{ color: 'red' }}>(*)</span></label><br />
                            <TextField
                                classes={{
                                    root: classes.input
                                }}
                                variant="outlined"
                                size="small"
                                placeholder={t("Họ và tên")}
                                onChange={onFullNameChange}
                                error={message.full_name.length > 0}
                                helperText={message.full_name}
                            />
                        </div>
                        <div className={clsx(classes.inputRightBlock, classes.date)}>
                            <label className={classes.label} style={{ marginBottom: '0' }}>{t("Ngày tháng năm sinh")} <span style={{ color: 'red' }}>(*)</span></label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === 'vi' ? vi : ''}>
                                <KeyboardDatePicker
                                    inputVariant="outlined"
                                    style={{ position: 'relative', top: '-16px', width: '100%' }}
                                    size="small"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    placeholder={t("Ngày/Tháng/Năm")}
                                    value={displayBirthday}
                                    onChange={onDateChange}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date"
                                    }}
                                    error={message.birthday !== ''}
                                    helperText={message.birthday}
                                    cancelLabel={t("Đóng")}
                                    okLabel={t("Đồng ý")}
                                    views={["date", "month", "year"]}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className={clsx(classes.select, classes.inputRightBlock)}>
                            <FormControl required variant="outlined" classes={{ root: classes.input }}>
                                <label className={classes.label}>{t("Giới tính")} <span style={{ color: 'red' }}>(*)</span></label>
                                <Select
                                    value={user.sex}
                                    style={{ height: '40px' }}
                                    name="sex"
                                    displayEmpty
                                    onChange={onChange}
                                >
                                    <MenuItem value='male'>{t('Nam')}</MenuItem>
                                    <MenuItem value='female'>{t('Nữ')}</MenuItem>
                                    <MenuItem value='other'>{t('Khác')}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className={classes.inputRightBlock}>
                            <label className={classes.label} style={{ marginBottom: '0' }}>{t("Quốc tịch")} <span style={{ color: 'red' }}>(*)</span></label>
                            <Autocomplete
                                value={user.nationality}

                                style={{ height: '40px' }}
                                size="small"
                                clearText={t("Xóa")}
                                noOptionsText={t("Không có dữ liệu")}
                                openText={t("Mở")}
                                getOptionSelected={(option, value) => {
                                    return true;
                                }}
                                options={nationalities}
                                renderInput={(params) => <TextField {...params} placeholder={t("Quốc tịch")} variant="outlined" error={message.nationality !== ''} helperText={message.nationality} />}
                                onChange={(e, newValue) => onNationalityChange(e, newValue)}
                            />
                        </div>
                        {configs.CMND !== -1 && <div className={classes.inputRightBlock}>
                            <label className={classes.label}>{t("CMND/CCCD/Passport")} {age >= configs.CMND_threshold && configs.CMND === 1 && <span style={{ color: 'red' }}>(*)</span>} </label><br />
                            <TextField
                                classes={{
                                    root: classes.input
                                }}
                                size="small"
                                placeholder={t("CMND/CCCD/Passport")}
                                variant="outlined"
                                onChange={onCMNDChange}
                                error={message.CMND !== ''}
                                helperText={message.CMND}
                            />
                        </div>}
                        <div className={classes.inputRightBlock} >
                            <label className={classes.label}>{t("Nơi cấp")}</label>
                            <TextField
                                classes={{
                                    root: classes.input
                                }}
                                value={user.issued_place}
                                disabled={user.CMND === ''}
                                size="small"
                                placeholder={t("Nơi cấp")}
                                variant="outlined"
                                onChange={onIssuedPlaceChange}
                                error={message.issued_place !== ''}
                                helperText={message.issued_place}
                            />
                        </div>
                        <div className={classes.label} style={{ width: '95%', marginLeft: '2%', paddingBottom: '1px' }}>{t("Địa chỉ thường trú")}</div>
                        <div className={classes.inputRightBlock} style={{ height: '78px' }} >
                            <label className={classes.label}>{t("Tỉnh/ Thành phố")}</label>
                            <Autocomplete
                                value={user.province}
                                style={{ height: '40px' }}
                                size="small"
                                clearText={t("Xóa")}
                                noOptionsText={t("Không có dữ liệu")}
                                openText={t("Mở")}
                                getOptionSelected={(option, value) => {
                                    return true;
                                }}
                                options={provinceNames}
                                renderInput={(params) => <TextField {...params} placeholder={`< ${t('Chọn')} >`} variant="outlined" error={message.province !== ''} helperText={message.province} />}
                                onChange={(e, newValue) => onProvinceChange(e, newValue)}
                            />
                        </div>
                        <div className={classes.inputRightBlock} style={{ height: '78px' }}>
                            <label className={classes.label}>{t("Huyện/ Quận")}</label>
                            <Autocomplete
                                value={user.district}
                                classes={{
                                    root: classes.input
                                }}
                                style={{ height: '40px' }}
                                size="small"
                                clearText={t("Xóa")}
                                noOptionsText={t("Không có dữ liệu")}
                                openText={t("Mở")}
                                getOptionSelected={(option, value) => {
                                    return true;
                                }}
                                options={districtNames}
                                renderInput={(params) => <TextField {...params} placeholder={`< ${t('Chọn')} >`} variant="outlined" error={message.district !== ''} helperText={message.district} />}
                                onChange={(e, newValue) => onDistrictChange(e, newValue)}
                            />
                        </div>
                        <div className={classes.inputRightBlock} style={{ height: '78px' }}>
                            <label className={classes.label} >{t("Xã/ Phường")}</label>
                            <Autocomplete
                                value={user.ward}
                                classes={{
                                    root: classes.input
                                }}
                                style={{ height: '40px' }}
                                size="small"
                                clearText={t("Xóa")}
                                noOptionsText={t("Không có dữ liệu")}
                                openText={t("Mở")}
                                getOptionSelected={(option, value) => {
                                    return true;
                                }}
                                options={wardNames}
                                renderInput={(params) => <TextField {...params} placeholder={`< ${t('Chọn')} >`} variant="outlined" error={message.ward !== ''} helperText={message.ward} />}
                                onChange={(e, newValue) => onWardChange(e, newValue)}
                            />
                        </div>
                        <div style={{ height: '28px', clear: 'both' }}></div>
                        <div className={classes.inputRightBlock} style={{ width: '96%' }}>
                            <label className={classes.label}>{t("Địa chỉ")}</label><br />
                            <TextField
                                classes={{
                                    root: classes.input
                                }}
                                inputProps={{
                                    autoComplete: 'off'
                                }}
                                style={{ height: '40px' }}
                                name="address"
                                placeholder={t("Địa chỉ nơi ở")}
                                variant="outlined"
                                size="small"
                                onChange={onAddressChange}
                                error={message.address.length > 0}
                                helperText={message.address}
                            />
                        </div>
                        {/* <div style={{width:'100%',height:'27px',clear:'both'}}></div> */}
                        <div className={classes.inputRightBlock}>
                            <label className={classes.label}>{t("Nghề nghiệp")} </label><br />
                            <TextField
                                classes={{
                                    root: classes.input
                                }}
                                variant="outlined"
                                size="small"
                                placeholder={t("Nghề nghiệp")}
                                onChange={onCareerChange}
                                error={message.career !== ''}
                                helperText={message.career}
                            />
                        </div>
                        <div className={classes.inputRightBlock}>
                            <label className={classes.label}>Email </label><br />
                            <TextField
                                classes={{
                                    root: classes.input
                                }}
                                size="small"
                                placeholder="Email"
                                variant="outlined"
                                onChange={onEmailChange}
                                error={message.email.length > 0}
                                helperText={message.email}
                            />
                        </div>
                        <div className={classes.inputRightBlock} >
                            <label className={classes.label}>{t("Ghi chú")}</label><br />
                            <TextField
                                classes={{
                                    root: classes.input
                                }}
                                size="small"
                                placeholder={t("Ghi chú")}
                                variant="outlined"
                                onChange={onNoteChange}
                                error={message.note !== ''}
                                helperText={message.note}
                            />
                        </div>
                        <div className={classes.inputRightBlock} style={{ width: '96%', textAlign: 'right', height: '50px' }}>
                            <CancelButton
                                variant="outlined"
                                disabled={isAdding}
                                onClick={onRefreshClick}
                            >
                                {t("Hủy")}
                            </CancelButton>
                            <CreateButton
                                variant="contained"
                                onClick={onSubmit}
                                disabled={isAdding}
                            >
                                {t("Đăng ký")}
                                {isAdding && !isDialog && <CircularProgress size={25} style={{ position: 'absolute', color: '#0b59c8' }} />}
                            </CreateButton>
                        </div>
                    </div>
                </div>}
            </div>}
        </div>
    );

}

export default ResidentRegisterForm;
