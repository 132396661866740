import { LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiCabinetsChartGet } from '../../../../api/cabinets';
import { formatToVND } from '../../../../helper/currency';
import DoughnutCommon from '../doughnutCommon';
import { useStyles } from './styles';

const CabinetStatisDoughnut = React.memo(({ cabinetID, isCabinetLoading }) => {
    const [state, setState] = useState({
        dataDoughnut: [
            // {
            //     id: '0',
            //     title: 'tủ nhỏ',
            //     data: {
            //         labelCustoms: [t('Tủ hoạt động'), t('Tủ trống')],
            //         datasets: [{
            //             label: 'Acess',
            //             data: [45, 15],
            //             backgroundColor: ['#FEA54C', '#F9E8C9'],
            //             borderWidth: 0
            //         }]
            //     }
            // }
        ],
        totalRevenue: 0,
        isLoading: false
    });

    const { t } = useTranslation();
    const classes = useStyles();

    const fetchApiCabinetsChartGet = async (body) => {
        setState(prev => ({
            ...prev,
            isLoading: true
        }))

        const dataDoughnut = [];
        let totalRevenue = 0;

        try {
            const { data } = await apiCabinetsChartGet(body);

            data?.arrayCellType?.forEach(item => {
                dataDoughnut.push({
                    id: item.cellTypeId,
                    title: item?.cellType?.name,
                    totalCell: item?.totalCell,
                    data: {
                        labels: [t('Tủ hoạt động'), t('Tủ trống')],
                        labelCustoms: [t('Tủ hoạt động'), t('Tủ trống')],
                        datasets: [{
                            label: 'Cabinet',
                            data: [item?.totalActiveCell, item?.totalEmptyCell],
                            backgroundColor: ['#FEA54C', '#F9E8C9'],
                            borderWidth: 0
                        }]
                    }
                })
            });
            totalRevenue = data?.totalRevenue || 0;
        } catch (error) {

        }

        setState(prev => ({
            ...prev,
            isLoading: false,
            dataDoughnut,
            totalRevenue
        }))
    }

    useEffect(() => {
        if (!cabinetID) return;

        fetchApiCabinetsChartGet({ cabinetId: cabinetID });
    }, [cabinetID])

    return (

        <div className={classes.containLine}>
            {(state.isLoading || isCabinetLoading) && (
                <div className={classes.wrapLoading}>
                    <LinearProgress />
                </div>
            )}

            <div className={classes.wrapTitleLine}>
                <p>
                    <span className={classes.lineTitle}>{t("Thống kê hiện tại")}</span>
                    <span className={classes.doughnutDate}>(21/03/2022)</span>
                </p>
                <div className={classes.wrapLineAction}>
                    <div className={classes.doughnutLabel}>
                        <div
                            className={classes.labelIcon}
                            style={{
                                background: '#FEA54C',
                            }}
                        />
                        <p className={classes.labelText}>{t("Tủ hoạt động")}</p>
                    </div>
                    <div className={classes.doughnutLabel}>
                        <div
                            className={classes.labelIcon}
                            style={{
                                background: '#F9E8C9',
                            }}
                        />
                        <p className={classes.labelText}>{t("Tủ trống")}</p>
                    </div>
                </div>
            </div>

            <p className={classes.lineTotal}>{t("Tổng")}: {formatToVND(state.totalRevenue)}</p>

            <div className={classes.wrapContainDouhnut}>
                <div
                    className={classes.containDoughnut}
                    style={{
                        height: state?.dataDoughnut?.length === 0 && '204px',
                        alignItems: state?.dataDoughnut?.length === 0 && 'center'
                    }}
                >
                    {!(state.isLoading || isCabinetLoading) && state?.dataDoughnut?.length === 0 && (
                        <p>{t("Chưa có dữ liệu")}</p>
                    )}
                    {state?.dataDoughnut?.map(item => (
                        <div key={item.id} className={classes.doughnutItem}>
                            <DoughnutCommon
                                key={item.id}
                                isHiddenLabel
                                data={item.data}
                                centerTitle={item.title}
                                centerValue={`${item?.data?.datasets[0].data[0]} / ${item?.totalCell}`}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
})

export default CabinetStatisDoughnut;