import AxiosClient from "../config/AxiosClient";

export const apiSmartcameraRecognize = async (requestBody) => {
    var url = `/smartcamera/recognize?offset=${requestBody.offset}&&limit=${requestBody.limit}`;
    return await AxiosClient.get(url);
}

export const apiSmartcameraHistoryDelete = async (requestBody) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/smartcamera/history/delete';
    } else {
        url = '/smartcamera/recognize/delete';
    }
    return await AxiosClient.post(url, requestBody);
}

export const apiSmartcameraSyncface = async () => {
    var url = `/smartcamera/syncface`;
    return await AxiosClient.post(url, {});
}

export const apiSmartcameraDevice = async () => {
    var url = '/smartcamera/device';
    return await AxiosClient.get(url);
}

export const apiSmartcameraDeviceDelete = async (requestBody) => {
    var url = '/smartcamera/device/delete';
    return await AxiosClient.post(url, requestBody);
}

export const apiSmartcameraDeviceCreate = async (requestBody) => {
    var url = '/smartcamera/device/create';
    return await AxiosClient.post(url, requestBody);
}