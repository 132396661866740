import AxiosClient from "../config/AxiosClient";

export const apiTempInfo = async (bodyRequest) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/temp/info';
    } else {
        url = '/residencetemp/list';
    }
    return await AxiosClient.post(url, bodyRequest);
}

export const apiTempDelete = async (bodyRequest) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/temp/delete';
    } else {
        url = '/residencetemp/delete';
    }
    return await AxiosClient.post(url, bodyRequest);
}

export const apiTempReject = async (bodyRequest) => {
    var url = '/temp/reject';
    return await AxiosClient.post(url, bodyRequest);
}