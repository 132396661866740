import { Dialog } from '@material-ui/core';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { CircularProgress, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { apiCabinetCellsGet, apiCabinetsCellsOpen } from '../../../../api/cabinets';
import ErrorAlert from '../../../../components/alert/ErrorAlert';
import InfoAlert from '../../../../components/alert/InfoAlert';
import { CreateButton } from '../../../../components/button';
import CancelButton from '../../../../components/button/cancelButton';
import { PrimaryTitle } from '../../../../components/title';
import CommonDetail from '../commonDetails';
import { useStyles } from './styles';
import { formatToVND } from '../../../../helper/currency';
import SuccessAlert from '../../../../components/alert/SuccessAlert';

export default function CanbinetDetails() {
    const [state, setState] = useState({
        cell: {},
        responseStatus: {
            succ: "",
            err: "",
            infor: ""
        },
        reasonOpenCell: 'canNotPay',
        isOpenCell: false,
        isLoading: false,
        isConfirmLoading: false
    });

    const classes = useStyles();
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const moment = require("moment-timezone");

    const cabinetID = location?.state?.cabinetID;
    const cellID = location?.state?.cellID;
    const oldFilterCell = location?.state?.oldFilterCell;
    const oldCabinet = location?.state?.oldCabinet;

    const handleChangeResponseStatus = (succ = "", err = "", infor = "") => {
        setState(prev => ({
            ...prev,
            responseStatus: {
                succ,
                err,
                infor
            }
        }));
    }

    const onClickBackToManagement = () => {
        history.push({
            pathname: '/admin/cabinet/managenent',
            state: {
                oldFilterCell: oldFilterCell,
                oldCabinet: oldCabinet
            }
        });
    }

    const fetchApiCabinetCellsGet = async (body) => {
        setState(prev => ({
            ...prev,
            isLoading: true
        }));
        try {
            const { data } = await apiCabinetCellsGet(body);
            const cell = data[0];

            setState(prev => ({
                ...prev,
                cell
            }))
        } catch (error) {
            handleChangeResponseStatus("", t("Tải thông tin thất bại, vui lòng thử lại!"))
        }

        setState(prev => ({
            ...prev,
            isLoading: false
        }))
    }

    const getTotalHourColunm = (cell) => {
        let result = "";

        const mess = cell.expiredTime ? moment
            .duration(cell.expiredTime - cell.startTime, 'milliseconds')
            .humanize({ h: Infinity }) : "";

        if (mess && isNaN(mess.split(' ')[0])) {
            result = 1;
        }
        if (mess && !isNaN(mess.split(' ')[0])) {
            if (mess.split(' ')[1] === "hours") {
                result = mess.split(' ')[0];
            } else {
                result = 1;
            }
        }

        return result;
    }

    const onClickOpenCell = () => {
        setState(prev => ({
            ...prev,
            isOpenCell: true
        }))
    }

    const onCloseDialogOpenCell = () => {
        setState(prev => ({
            ...prev,
            isOpenCell: false
        }))
    }

    const handleOnRadioChange = (e) => {
        setState(prev => ({
            ...prev,
            reasonOpenCell: e.target.value
        }))
    }

    const handleOnClickConfirmOpenCell = async () => {
        setState(prev => ({
            ...prev,
            isConfirmLoading: true
        }));
        try {
            const body = {
                cabinetId: cabinetID,
                cellId: cellID,
                reason: state?.reasonOpenCell
            }
            await apiCabinetsCellsOpen(body);

            handleChangeResponseStatus(t("Mở tủ thành công"));
        } catch (error) {
            handleChangeResponseStatus("", t("Mở tủ không thành công"));
        }

        fetchApiCabinetCellsGet({
            cabinetId: cabinetID,
            cellId: cellID,
        });

        setState(prev => ({
            ...prev,
            isConfirmLoading: false,
            isOpenCell: false
        }));
    }

    useEffect(() => {
        if (!cabinetID || !cellID) return;

        const body = {
            cabinetId: cabinetID,
            cellId: cellID,
        }

        fetchApiCabinetCellsGet(body);
    }, [])

    return (
        <div className={classes.details} >
            <div className={classes.detailsHeader}>
                <div className={classes.wrapTitle}>
                    <div
                        className={classes.wrapTitleIcon}
                        onClick={onClickBackToManagement}
                    >
                        <ArrowBackIosNewOutlinedIcon />
                    </div>
                    <PrimaryTitle>{t("Thông tin tủ")} { }</PrimaryTitle>
                </div>

                <CreateButton
                    disabled={state.isLoading || !state.cell?.ID || !state?.cell.startTime}
                    onClick={onClickOpenCell}
                >
                    {t("Mở tủ")}
                </CreateButton>
            </div>

            {state.isLoading && (
                <div className={classes.wrapLoading}>
                    <CircularProgress />
                </div>
            )}

            {!state.isLoading && (
                <div>
                    <CommonDetail cell={state.cell} />
                </div>
            )}

            <Dialog
                open={state.isOpenCell}
                onClose={onCloseDialogOpenCell}
            >
                <div className={classes.openCell}>
                    <h4 className={classes.openCellTitle}>{t("Xác nhận mở tủ")}</h4>

                    <p className={classes.openCellHeading}>
                        {t("Thông tin thanh toán")}
                    </p>
                    <div className={classes.openCellRow}>
                        <div className={classes.openCellColumn}>
                            <p className={classes.openCellLabel}>{t("Thời gian đặt tủ")}</p>
                            <p className={classes.openCellValue}>
                                {moment.tz((state?.cell?.startTime || state?.cell?.StartTime), "Asia/Ho_Chi_Minh").format("HH:mm DD/MM/YYYY ")}
                            </p>
                        </div>
                        <div className={classes.openCellColumn}>
                            <p className={classes.openCellLabel}>{t("Tổng giờ")}</p>
                            <p className={classes.openCellValue}>
                                {getTotalHourColunm(state?.cell)}
                            </p>
                        </div>
                    </div>

                    <div className={classes.openCellRow}>
                        <div className={classes.openCellColumn}>
                            {state?.cell?.paymentStatus !== "pending" && state?.cell?.expiredTime && (
                                <>
                                    <p className={classes.openCellLabel}>{t("Thời gian trả tủ")}</p>
                                    <p className={classes.openCellValue}>
                                        {moment.tz(state?.cell?.expiredTime, "Asia/Ho_Chi_Minh").format("HH:mm DD/MM/YYYY ")}
                                    </p>
                                </>
                            )}

                            {state?.cell?.expiredTime && (
                                <>
                                    <p className={classes.openCellLabel}>{t("Thời gian quá hạn")}</p>
                                    <p className={classes.openCellValue}>
                                        {moment.tz(state?.cell?.expiredTime, "Asia/Ho_Chi_Minh").format("HH:mm DD/MM/YYYY ")}
                                    </p>
                                </>
                            )}
                        </div>
                        <div className={classes.openCellColumn}>
                            <p className={classes.openCellLabel}>{t("Tổng thanh toán")}</p>
                            <p className={classes.openCellValue}>
                                {formatToVND(state?.cell?.totalPrice)}
                            </p>
                        </div>
                    </div>

                    <p className={classes.openCellHeading}>
                        {t("Lý do yêu cầu mở tủ")}
                    </p>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            value={state.reasonOpenCell}
                            onChange={handleOnRadioChange}
                        >
                            <FormControlLabel
                                className={classes.openCellRadio}
                                value="canNotPay"
                                control={<Radio />}
                                label={t("Khách hàng không thể thanh toán online")}
                            />
                            <FormControlLabel
                                className={classes.openCellRadio}
                                value="expired"
                                control={<Radio />}
                                label={t("Tủ sử dụng quá hạn")}
                            />
                        </RadioGroup>
                    </FormControl>

                    <div className={classes.openCellAction}>
                        <CancelButton
                            onClick={onCloseDialogOpenCell}
                            disabled={state.isConfirmLoading}
                        >
                            {t("Hủy")}
                        </CancelButton>
                        <CreateButton
                            onClick={handleOnClickConfirmOpenCell}
                            disabled={state.isConfirmLoading}
                        >
                            {state.isConfirmLoading && (
                                <span className={classes.wrapConfirmLoading}>
                                    <CircularProgress size={20} />
                                </span>
                            )}
                            {t("Xác nhận")}
                        </CreateButton>
                    </div>
                </div>
            </Dialog>

            <SuccessAlert
                message={state.responseStatus.succ}
                onCloseDialog={() => handleChangeResponseStatus()}
            />

            <ErrorAlert
                message={state.responseStatus.err}
                onCloseDialog={() => handleChangeResponseStatus()}
            />

            <InfoAlert
                message={state.responseStatus.infor}
                onCloseDialog={() => handleChangeResponseStatus()}
            />
        </div>
    )
}
