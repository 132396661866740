import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { LinearProgress, MenuItem } from '@mui/material';
import vi from 'date-fns/locale/vi';
import React, { cloneElement, useState } from 'react';
import { isElement } from 'react-dom/test-utils';
import { useTranslation } from 'react-i18next';
import SelectCpn from '../../../../components/input/select/select';
import { formatToVND } from '../../../../helper/currency';
import { useStyles } from './styles';

const CommonCabinetChart = React.memo(({
    title,
    ChartNode,
    isHiddenTotal,
    isUnsetPaddingBottom,
    isLoading,
    isChartLine
}) => {
    const [state, setState] = useState({
        viewMode: "day",
        dateValue: new Date(),
        dateWillPassProp: `${new Date().getMonth() + 1}-${new Date().getDate()}-${new Date().getFullYear()}`,
        totalRevenue: 0
    })

    const { t, i18n } = useTranslation();
    const classes = useStyles();

    const handleOnViewModeChange = (e) => {
        const value = e.target.value;
        let dateWillPassProp = `${state.dateValue.getMonth() + 1}-${state.dateValue.getDate()}-${state.dateValue.getFullYear()}`;

        if (value === 'month') {
            dateWillPassProp = `${state.dateValue.getMonth() + 1}-${state.dateValue.getFullYear()}`;
        }
        setState(prev => ({
            ...prev,
            viewMode: value,
            dateWillPassProp
        }));
    }

    const handleOnDateChange = (e) => {
        let dateWillPassProp = `${e.getMonth() + 1}-${e.getDate()}-${e.getFullYear()}`;

        if (state.viewMode === 'month') {
            dateWillPassProp = `${e.getMonth() + 1}-${e.getFullYear()}`;
        }
        setState(prev => ({
            ...prev,
            dateValue: e,
            dateWillPassProp
        }))
    }

    const handleChangeTotalRevenue = (totalRevenue = 0) => {
        setState(prev => ({
            ...prev,
            totalRevenue
        }))
    }

    return (

        <div
            className={classes.containLine}
            style={{
                paddingBottom: isUnsetPaddingBottom && 'unset'
            }}
        >
            {isLoading && (
                <div className={classes.wrapLoading}>
                    <LinearProgress />
                </div>
            )}

            <div className={classes.wrapTitleLine}>
                <p className={classes.lineTitle}>
                    {title + ' '}
                    {isChartLine && state.viewMode === 'day' && t("trong ngày")}
                    {isChartLine && state.viewMode === 'month' && t("trong tháng")}
                </p>
                <div className={classes.wrapLineAction}>
                    <p className={classes.viewMode}>{t("Chế độ xem")}</p>
                    <SelectCpn
                        isRounded
                        className={classes.lineSelect}
                        value={state.viewMode}
                        onChange={handleOnViewModeChange}
                    >
                        <MenuItem value='day'>{t('Theo ngày')}</MenuItem>
                        <MenuItem value='month'>{t('Theo tháng')}</MenuItem>
                    </SelectCpn>
                    <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={i18n.language === 'vi' ? vi : ''}
                    >
                        <KeyboardDatePicker
                            style={{
                                width: "255px"
                            }}
                            autoOk
                            disableFuture
                            size="small"
                            openTo="date"
                            variant="inline"
                            inputVariant="outlined"
                            InputProps={{ readOnly: true }}
                            InputAdornmentProps={{ position: "end" }}
                            format={state.viewMode === 'day' ? "dd/MM/yyyy" : "MM/yyyy"}
                            views={state.viewMode === 'day' ? ["date"] : ["month"]}
                            className={classes.inputDate}
                            value={state.dateValue}
                            onChange={handleOnDateChange}
                        />
                    </MuiPickersUtilsProvider>
                </div>
            </div>

            <p className={classes.lineTotal}>
                {!isHiddenTotal && `${t("Tổng")}: ${formatToVND(state?.totalRevenue)}`}
            </p>

            {isElement(ChartNode) && (
                cloneElement(ChartNode, {
                    date: state.dateWillPassProp,
                    handleChangeTotalRevenue: handleChangeTotalRevenue
                })
            )}
        </div>
    )
})

export default CommonCabinetChart;