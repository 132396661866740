import makeStyles from "@material-ui/styles/makeStyles/makeStyles";
export const useStyles = makeStyles((theme) => ({
  primaryTitle: theme.primary_title,
  layoutPage: {
    fontFamily: "Nunito !important",
    width: "100%",
    minWidth: "1250px",
    minHeight: "100%",
    height: "100%",
  },
  manage: {
    minHeight: "100%",
  },
  containLayout: {
    width: "100%",
    minHeight: "100%",
    display: "flex",
  },
  contentSideBar: {
    width: "100%"
  },
  rotateIcon: {
    transform: "rotate(180deg)"
  },
  layoutSideBar: {
    borderRadius: "6px",
    width: "20%",
    minWidth: '20%',
    minHeight: "100%",
    background: "#FFFFFF",
    display: "flex",
    justifyContent: "space-between",
    padding: "24px 24px 32px 24px",
    marginRight: "1rem",
    transition: "500ms",
  },
  hide: {
    width: "0",
    minWidth: '0',
    overflowX: "hidden",
    paddingLeft: "0",
    backgroundColor: "#fafafa"
  },
  closeMenu: {
    marginRight: "unset",
    width: "0",
    overflow: "hidden",
  },
  topBlock: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "22px",
    height: "40px",
  },
  secondaryTitle: {
    ...theme.secondary_title,
    marginLeft: "10px !important",
    marginBottom: "7px !important",
  },
  btnExtendMenu: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    width: "30px",
    height: "40px",
    cursor: "pointer",
    color: "#0b59c8",
    position: "absolute",
    transition: "300ms",
  },
  btnShowHidden: {
    height: "100%",
  },
  content: {
    flex: 1,
    width: 'calc(80% - 16px)',
    borderRadius: "6px",
    background: "#FFFFFF",
    minHeight: "100%",
    padding: "24px 24px 32px 24px",
  },
  contentfullWidth: {
    width: "100%",
    marginRight: "1rem",
  },
  link: {
    display: "block",
    height: "40px",
    fontSize: "14px",
    padding: "10px",
    color: "#000000",
    position: "relative",
  },
  active: {
    height: "40px",
    width: "100%",
    background: "#50c9ff4a",
    fontWeight: "unset !important",
  },
  activeGreyOut: {
    background: "rgba(0, 0, 0, 0.12)",
  },
}));
