import React,{useState,useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import queryString from 'query-string';
import {useLocation} from 'react-router-dom';

const PinCodePage = () =>{
    const {t} = useTranslation();
    const location = useLocation();
    const [code,setCode] = useState('');
    useEffect(()=>{
        const parseUrl = queryString.parse(location.search);
        setCode(parseUrl.code);
    },[]) 
    return(
        <div style={{width:'100%',height:'100%',position:'absolute',top:'0',display:'flex',justifyContent:'center',alignItems:'center',background:'rgb(245, 245, 245)'}}>
            <div style={{minWidth:'300px',width:'50%',background:'#FFFFFF',boxShadow:'0px 3px 1px -2px rgb(0 0 0 / 20%)',padding:'20px',borderRadius:'6px'}}>
                <h1 style={{color:'#0b59c8',fontWeight:'bold',textAlign:'center'}}>Smart Building Access</h1>
                <h4>{t('Không bao giờ chia sẻ mã xác thực với người khác để tránh mất thông tin. Mã xác thực của bạn có hiệu lực trong 1 giờ')}</h4>
                <h1 style={{background:'#e9ecef',border:'1px solid gray',width:'200px',margin:'20px auto',boxShadow:'1px -1px #bfbdbd',textAlign:'center'}}>{code}</h1>
            </div>
        </div>
    )
}

export default PinCodePage;