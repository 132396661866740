import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import FitnessCenterOutlinedIcon from '@mui/icons-material/FitnessCenterOutlined';
import LocalBarOutlinedIcon from '@mui/icons-material/LocalBarOutlined';
import OutdoorGrillOutlinedIcon from '@mui/icons-material/OutdoorGrillOutlined';
import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { createBookingService, updateBookingService } from '../../../../api/booking';
import { LISTMINUTESTEP } from '../../../../constants/serviceBooking';
import { IS_CHANGED_SERVICES } from '../../../../redux/reducers/serviceBooking';
import { isEmpty } from '../../../../validation/CheckForm';
import ErrorAlert from '../../../alert/ErrorAlert';
import { CreateButton } from '../../../button/index';
import { Confirm } from '../../../dialog/index';
import LargeTooltip from '../../../largeTooltip';
import { PrimaryTitle } from '../../../title/index';
import useStyles from './styles';

export const iconOption = [
    "RestaurantOutlinedIcon",
    "FitnessCenterOutlinedIcon",
    "LocalBarOutlinedIcon",
    "OutdoorGrillOutlinedIcon"
];

export default function AddEditService({
    handleChangeIsAddEdit,
    infoService
}) {
    const [state, setState] = useState({
        iconChose: infoService.ServiceIcon || "",
        listScheduledType: ["phòng", "bàn"],
        responseStatus: {
            succ: "",
            err: ""
        },
        isEdit: false,
        isScheduledTypeAdd: false,
        isLoading: false,
        isConfirm: false
    })
    const [validateState, setValidateState] = useState({
        serviceName: "",
        scheduledType: "",
        serviceStep: ""
    })

    const bodyRequestRef = useRef();

    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();

    const createBookingServiceApi = async (bodyRequest) => {
        setState(prev => ({
            ...prev,
            isLoading: true
        }));
        try {
            await createBookingService(bodyRequest);

            const responseStatus = {
                succ: t("Tạo dịch vụ thành công"),
                err: ""
            };
            handleChangeIsAddEdit(responseStatus);
        } catch (error) {
            console.log(error);
            setState(prev => ({
                ...prev,
                responseStatus: {
                    succ: "",
                    err: t("Thêm dịch vụ thất bại, vui lòng thử lại!")
                }
            }))
        }

        dispatch({ type: IS_CHANGED_SERVICES }); dispatch({ type: IS_CHANGED_SERVICES });
        setState(prev => ({
            ...prev,
            isLoading: false
        }));
    }
    const updateBookingServiceApi = async (bodyRequest) => {
        setState(prev => ({
            ...prev,
            isLoading: true
        }));
        try {
            await updateBookingService(bodyRequest);

            const responseStatus = {
                succ: t("Cập nhật dịch vụ thành công"),
                err: ""
            };
            handleChangeIsAddEdit(responseStatus);
        } catch (error) {
            setState(prev => ({
                ...prev,
                responseStatus: {
                    succ: "",
                    err: t("Cập nhật dịch vụ thất bại, vui lòng thử lại!")
                }
            }))
        }

        dispatch({ type: IS_CHANGED_SERVICES });
        setState(prev => ({
            ...prev,
            isLoading: false,
            isConfirm: false
        }))
    }

    const onClickIcon = (value) => {
        if (infoService.ID && !state.isEdit) return;

        setState(prev => ({
            ...prev,
            iconChose: prev.iconChose === value ? "" : value
        }))
    }

    const onSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);
        const bodyRequest = {
            service_name: formData.get("serviceName"),
            service_type: formData.get(state.isScheduledTypeAdd ? "scheduledTypeAdd" : "scheduledType"),
            description: formData.get("description"),
            service_icon: state.iconChose,
            service_step: formData.get("serviceStep"),
        }
        //validate
        if (isEmpty(bodyRequest.service_name)) {
            setValidateState(prev => ({
                ...prev,
                serviceName: t("Không được để trống")
            }))
            return;
        }

        if (isEmpty(bodyRequest.service_type)) {
            setValidateState(prev => ({
                ...prev,
                scheduledType: t("Không được để trống"),
                serviceName: ""
            }))
            return;
        }

        if (isEmpty(bodyRequest.service_step)) {
            setValidateState(prev => ({
                ...prev,
                serviceStep: t("Không được để trống"),
            }))
            return;
        }
        //pass validate
        setValidateState({
            scheduledType: "",
            serviceName: "",
            serviceStep: ""
        });

        if (state.isEdit) {
            bodyRequestRef.current = bodyRequest;
            bodyRequestRef.current["id"] = infoService.ID;
            bodyRequestRef.current.status = formData.get("status");

            setState(prev => ({
                ...prev,
                isConfirm: true
            }))
        } else {
            createBookingServiceApi(bodyRequest);
        }
    }
    const onConfirm = () => {
        updateBookingServiceApi(bodyRequestRef.current);
    }

    useEffect(() => {
        const body = document.body;
        body.classList.add(classes.hiddenScroll);

        return () => {
            body.classList.remove(classes.hiddenScroll);
        }
    }, []);

    useEffect(() => {
        if(!infoService) return;

        const checkHadType = state.listScheduledType.indexOf(infoService?.ServiceType);

        if(checkHadType < 0) {
            setState(prev => ({
                ...prev,
                listScheduledType: [...prev.listScheduledType, infoService?.ServiceType]
            }))
        }
    }, [state.listScheduledType, infoService])

    const iconOptionLabel = {
        RestaurantOutlinedIcon: (value) => (
            <RestaurantOutlinedIcon
                className={clsx(classes.icon, state.iconChose === "RestaurantOutlinedIcon" && classes.isIconActive)}
                onClick={() => onClickIcon(value)}
                style={{
                    cursor: infoService.ID && !state.isEdit && 'unset'
                }}
            />),
        FitnessCenterOutlinedIcon: (value) => (
            <FitnessCenterOutlinedIcon
                className={clsx(classes.icon, state.iconChose === "FitnessCenterOutlinedIcon" && classes.isIconActive)}
                onClick={() => onClickIcon(value)}
                style={{
                    cursor: infoService.ID && !state.isEdit && 'unset'
                }}
            />),
        LocalBarOutlinedIcon: (value) => (
            <LocalBarOutlinedIcon
                className={clsx(classes.icon, state.iconChose === "LocalBarOutlinedIcon" && classes.isIconActive)}
                onClick={() => onClickIcon(value)}
                style={{
                    cursor: infoService.ID && !state.isEdit && 'unset'
                }}
            />),
        OutdoorGrillOutlinedIcon: (value) => (
            <OutdoorGrillOutlinedIcon
                className={clsx(classes.icon, state.iconChose === "OutdoorGrillOutlinedIcon" && classes.isIconActive)}
                onClick={() => onClickIcon(value)}
                style={{
                    cursor: infoService.ID && !state.isEdit && 'unset'
                }}
            />)
    };

    return (
        <>
            <div className={classes.service} >
                <form
                    className={classes.form}
                    onSubmit={onSubmit}
                >
                    <div className={classes.title} >
                        <PrimaryTitle>{infoService?.ID ? t("Cập nhật dịch vụ") : t("Thêm dịch vụ")}</PrimaryTitle>
                    </div>

                    <TextField
                        variant="outlined"
                        size="small"
                        name="serviceName"
                        error={validateState.serviceName !== ""}
                        helperText={validateState.serviceName}
                        label={
                            <p>
                                {t("Tên dịch vụ")}
                                <span className={classes.required}> (*)</span>
                            </p>
                        }
                        defaultValue={infoService && infoService.ServiceName}
                        disabled={infoService.ID && !state.isEdit}
                        className={classes.textField}
                    />

                    <div className={classes.wrapInput} >
                        {
                            !state.isScheduledTypeAdd && (
                                <TextField
                                    select
                                    variant="outlined"
                                    size="small"
                                    name="scheduledType"
                                    error={validateState.scheduledType !== ""}
                                    helperText={validateState.scheduledType}
                                    label={
                                        <p>
                                            {t("Loại được đặt")}
                                            <span className={classes.required}> (*)</span>
                                        </p>
                                    }
                                    defaultValue={infoService.ID ? infoService?.ServiceType : state.listScheduledType[0]}
                                    disabled={infoService.ID && !state.isEdit}
                                    className={classes.textField}
                                >
                                    {
                                        state.listScheduledType.map(value => (
                                            <MenuItem key={value} value={value}>{t(value)}</MenuItem>
                                        ))
                                    }
                                </TextField>
                            )
                        }
                        {
                            state.isScheduledTypeAdd && (
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    name="scheduledTypeAdd"
                                    error={validateState.scheduledType !== ""}
                                    helperText={validateState.scheduledType}
                                    label={
                                        <p>
                                            {t("Thêm loại được đặt")}
                                            <span className={classes.required}> (*)</span>
                                        </p>
                                    }
                                    defaultValue={infoService.ID ? infoService?.ServiceType : ""}
                                    disabled={infoService.ID && !state.isEdit}
                                    className={classes.textField}
                                />
                            )
                        }
                        {
                            !(infoService.ID && !state.isEdit) && !state.isScheduledTypeAdd && (
                                <div
                                    className={classes.scheTypeAdd}
                                    onClick={() => setState(prev => ({
                                        ...prev,
                                        isScheduledTypeAdd: true
                                    }))}
                                >
                                    <LargeTooltip title={t("Thêm loại được đặt")}>
                                        <AddIcon />
                                    </LargeTooltip>
                                </div>
                            )
                        }
                    </div>

                    <TextField
                        select
                        variant="outlined"
                        size="small"
                        name="serviceStep"
                        error={validateState.serviceStep !== ""}
                        helperText={validateState.serviceStep}
                        label={
                            <p>
                                {t("Thời gian đặt nhỏ nhất")}
                                <span className={classes.required}> (*)</span>
                            </p>
                        }
                        defaultValue={infoService.ID ? infoService?.ServiceStep : LISTMINUTESTEP[0]}
                        disabled={infoService.ID && !state.isEdit}
                        className={classes.textField}
                    >
                        {
                            LISTMINUTESTEP.map(item => (
                                <MenuItem key={item} value={item}>{item} {t("Phút")}</MenuItem>
                            ))
                        }
                    </TextField>

                    <TextField
                        variant="outlined"
                        size="small"
                        name="description"
                        label={t("Miêu tả")}
                        defaultValue={infoService && infoService?.ServiceDescription}
                        disabled={infoService.ID && !state.isEdit}
                        className={classes.textField}
                    />

                    {
                        infoService?.ID && (
                            <TextField
                                select
                                variant="outlined"
                                size="small"
                                name="status"
                                label={t("Trạng thái")}
                                defaultValue={infoService && infoService?.ServiceStatus}
                                disabled={infoService.ID && !state.isEdit}
                                className={classes.textField}
                            >
                                <MenuItem value="activated">{t("Hoạt động")}</MenuItem>
                                <MenuItem value="deactivated">{t("Tạm ngừng")}</MenuItem>
                            </TextField>
                        )
                    }

                    <div className={classes.wrapIcon} >
                        <span>Icon:</span>
                        {
                            iconOption.map(item => (
                                <React.Fragment key={item} >
                                    {iconOptionLabel[item](item)}
                                </React.Fragment>
                            ))
                        }
                    </div>

                    <div className={classes.wrapBtn} >
                        <CreateButton
                            onClick={() => handleChangeIsAddEdit()}
                            disabled={state.isLoading}
                        >
                            {t("Hủy")}
                        </CreateButton>
                        {
                            !infoService.ID && (
                                <CreateButton
                                    type="submit"
                                    disabled={state.isLoading}
                                >
                                    {t("Thêm")}
                                    {
                                        state.isLoading && (
                                            <CircularProgress
                                                size={20}
                                                style={{
                                                    color: '#0b59c8',
                                                    marginLeft: "12px"
                                                }}
                                            />
                                        )
                                    }
                                </CreateButton>
                            )
                        }
                        {
                            infoService.ID && !state.isEdit && (
                                <CreateButton
                                    disabled={state.isLoading}
                                    onClick={() => setState(prev => ({
                                        ...prev,
                                        isEdit: true
                                    }))}
                                >
                                    {t("Sửa")}
                                </CreateButton>
                            )
                        }
                        {
                            infoService.ID && state.isEdit && (
                                <CreateButton type="submit" >
                                    {t("Lưu")}
                                </CreateButton>
                            )
                        }
                    </div>
                </form>
            </div>

            <Confirm
                isOpen={state.isConfirm}
                handleClose={() => setState(prev => ({
                    ...prev,
                    isConfirm: false
                }))}
                isLoading={state.isLoading}
                mess={t("Bạn có muốn lưu?")}
                onConfirm={onConfirm}
            />

            <ErrorAlert
                message={state.responseStatus.err}
                onCloseDialog={() => setState(prev => ({
                    ...prev,
                    responseStatus: {
                        succ: "",
                        err: ""
                    }
                }))}
            />
        </>
    )
}
