import React,{useState,useRef,useEffect} from 'react';
import AccessTable from '.././residences/AccessTable';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab/Tab';
import SearchIcon from '@material-ui/icons/Search';
import Tabs from '@material-ui/core/Tabs/Tabs';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField/TextField';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import { useMediaQuery } from 'react-responsive'
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    style1: {
      width: '179px',
      height: '26px',
      background: '#E1E1E1',
      borderBottomLeftRadius:'0',
      borderBottomRightRadius:'0',
      marginBottom: '0px',
      margin:'0',
      padding:'0',
      textTransform:'none'
      
    },
    btnActive:{
        background:'white'
    },
    
    createBtn:{
        background:'#289CD8',
        width:'95px',
        height: '24.71px',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '22px',
        textTransform:'none'
    },
    
    title:{fontSize:'20px',fontWeight:'700',lineHeight:'27px',color:'#000000',fontStyle:'normal',float:'left',marginRight:'24px',paddingTop:'5px',marginLeft:'16px'},
    topBlock:{marginTop:'22px',minWidth:'1080px',marginBottom:'20px',height:'40px', width:'98%',margin:'auto'},
    table:{width:'98%',margin:'auto',minWidth:'1080px'},
    indicator:{background:theme.primaryColor.background,height:'4px',borderRadius:'6px 6px 0 0'},
    fontTab:{color:'#000000',fontWeight:'400',fontSize:'16px',lineHeight:'22.4px',textTransform:'none'},
    fontTabActive:{color:theme.primaryColor.background,fontWeight:'600',fontSize:'16px',lineHeight:'22.4px',textTransform:'none'},
    container:{width: '100vw',height: '100vh',background:'#F5F5F5', display: 'flex'},
    content:{width: '100%',height: '100%', background: '#F5F5F5',overflowY: 'scroll'},
    primaryTitle:theme.primary_title,
    focus:{
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': { 
                borderColor: theme.themeColor,
            },
        },
    },
}));

const CommonTable = () =>{
    const classes = useStyles();
    const [tabValue, setTabValue] = useState(0);
    const [filter, setFilter] = useState('');
    const [role,setRole] = useState('');
    const [loading,setLoading] = useState(false);
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const filterRef = useRef();
    const {t} = useTranslation();
    const adminUserState = useSelector((state) =>state.adminUserState);

    useEffect(() => {
        setFilter('');
        setRole(adminUserState.role);
    }, [tabValue])

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const changeLoadingStatus = ()=>{
        setLoading(true);
    }

    const onFilterChange = (e)=>{
        if(filterRef.current) clearTimeout(filterRef.current);
        filterRef.current = setTimeout(() => {
            setFilter(e.target.value);
        }, 1000);
    }
    return(
        <div style={{paddingBottom:'20px'}}>                            
            <div className={clsx(classes.topBlock,classes.focus)}>
                <p className={classes.primaryTitle}>{t("Quản lý ra vào")}</p>
                {loading &&<TextField
                    placeholder={t("Tìm kiếm theo họ và tên")}
                    variant="outlined"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        style:isMobile?{ background:'#FFFFFF',height:'40px',width:'250px'}:{ background:'#FFFFFF',height:'40px',width:'320px'}
                    }}
                    onChange={onFilterChange}
                />}      
            </div>
            <div style={{width:'98%', margin:'auto',marginBottom:'16px',minWidth:'1080px'}}>
                <Tabs
                    classes={{indicator:classes.indicator}}
                    value={tabValue}
                    onChange={handleChange}                
                >
                    <Tab 
                        classes={tabValue===0?{root:classes.fontTabActive}:{root:classes.fontTab}} 
                        label={t("Lịch sử ra vào")}
                    />
                </Tabs>
                <Divider />
            </div>
            <div className={classes.table} >
                {tabValue===0 && <AccessTable searchString={filter} role={role} changeLoadingStatus={changeLoadingStatus}/>}
            </div>                          
        </div>
    )
}

export default CommonTable;