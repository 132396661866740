import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SnackbarContent from '@mui/material/SnackbarContent';
import { useTranslation } from 'react-i18next';

const InfoAlert = React.memo((props) =>{
    const { message, onCloseDialog } = props;
    const { t } = useTranslation();
    
    const showMessage = () =>{
        var result = '';
        switch (message) {
            case 'syncing': result = t('Đang đồng bộ dữ liệu cư dân')
                break;
            case 'publishing': result = t('Đang phát thông báo');
                break;
            case 'sync_face': result = t('Đang đồng bộ dữ liệu khuôn mặt');
                break;
            default: result = message;
                break;
        }
        return result;
    }

    return(
        <Snackbar
            open={message!==''}
            TransitionComponent={Slide}
        >
            <SnackbarContent
                style={{background:'rgb(229,  246,  253)', color:'rgb(1,  67,  97)'}}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={onCloseDialog}
                    >
                        {(message!=='syncing' && message!=='publishing') && <CloseIcon />}
                    </IconButton>
                    
                }
                message={
                    <div>
                        <p style={{fontWeight:'bold', fontSize:'16px', paddingBottom:'5px'}}>{t('Thông tin')}</p>
                        <p>{showMessage()}</p>
                    </div>
                }
            />   
        </Snackbar>
    )
})

export default InfoAlert;