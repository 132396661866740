import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import { useTranslation } from 'react-i18next';

const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);
const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);

const useStyles = makeStyles(theme => ({
    dialogTitleBackground: theme.errorColor,
    dialogTitle: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '25px',
        color: '#FFFFFF',
    },
    contentDialog: {
        fontSize: '16px!important',
        height: '68px',
        borderBottom: '1px solid #EEEDF2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}))

export const ConfirmWarning = ({
    isOpen, handleClose, isLoading, mess, onConfirm
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Dialog
            open={isOpen}
            maxWidth="xl"
        >
            <DialogTitle className={classes.dialogTitleBackground}>
                <p className={classes.dialogTitle}>{t("Cảnh báo")}</p>
            </DialogTitle>
            <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center', position: 'relative' }}>
                <div className={classes.contentDialog}>
                    <p >{mess}</p>
                </div>
                <CancelButton
                    style={{
                        margin: '10px 0',
                        border: '1px solid #fb3729',
                        color: "#fb3729"
                    }}
                    onClick={handleClose}
                    disabled={isLoading}
                >
                    {t("Trở về")}
                </CancelButton>
                <CreateButton
                    style={{
                        margin: '10px 0 10px 24px',
                        background: "#fb3729"
                    }}
                    onClick={onConfirm}
                    disabled={isLoading}
                >
                    {t("Đồng ý")}
                </CreateButton>
                {isLoading && <CircularProgress color="primary" style={{ position: 'absolute', left: '45%', top: '10%', color: '#0b59c8' }} />}
            </DialogContent>
        </Dialog>
    )
}