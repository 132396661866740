import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { PrimaryTitle } from '../../../../../components/title';
import CommonDetail from '../../commonDetails';
import { useStyles } from './styles';

export default function HistoriesDetails() {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const cell = location?.state?.cell;
    const cabinetID = location?.state?.cabinetID;

    const onClickBackToManagement = () => {
        history.push({
            pathname: '/admin/cabinet/managenent/histories',
            state: {
                cabinetID
            }
        });
    }

    return (
        <div className={classes.details} >
            <div className={classes.detailsHeader}>
                <div className={classes.wrapTitle}>
                    <div
                        className={classes.wrapTitleIcon}
                        onClick={onClickBackToManagement}
                    >
                        <ArrowBackIosNewOutlinedIcon />
                    </div>
                    <PrimaryTitle>{t("Chi tiết lịch sử đặt tủ")}</PrimaryTitle>
                </div>
            </div>

            <div className={classes.detailsContain}>
                <CommonDetail cell={cell} />
            </div>
        </div>
    )
}
