import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { apiCabinetsGet } from "../../../api/cabinets";
import ErrorAlert from "../../../components/alert/ErrorAlert";
import SuccessAlert from "../../../components/alert/SuccessAlert";
import { CreateButton } from "../../../components/button";
import CancelButton from "../../../components/button/cancelButton";
import { EditIcon, EmptyIcon } from "../../../components/icons";
import LargeTooltip from "../../../components/largeTooltip";
import { PrimaryTitle } from "../../../components/title";
import { useStyles } from "./styles";

export default function CabinetSystem() {
  const [state, setState] = useState({
    listCabinets: [],
    responseStatus: {
      succ: "",
      err: "",
    },
    isLoading: false,
  });

  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { url } = useRouteMatch();

  const handleChangeResponseStatus = (succ = "", err = "") => {
    setState((prev) => ({
      ...prev,
      responseStatus: {
        succ,
        err,
      },
    }));
  };

  const fetchApiCabinetsGet = async () => {
    try {
      setState((prev) => ({
        ...prev,
        isLoading: true,
      }));

      const { data } = await apiCabinetsGet();

      const { Items: listCabinets } = data;

      setState((prev) => ({
        ...prev,
        listCabinets,
      }));
    } catch (error) {
      handleChangeResponseStatus("", t("Tải thông tin tủ bị lỗi"));
    }

    setState((prev) => ({
      ...prev,
      isLoading: false,
    }));
  };

  const onClickButtonAdd = () => {
    history.push(`${url}/add-cabinet`);
  };

  const onClickSizeManagement = () => {
    history.push(`${url}/size`);
  };

  const onClickIconDetails = (cabinet) => {
    history.push({
      pathname: `${url}/cabinet-details/${cabinet.ID}`,
      state: {
        cabinet,
      },
    });
  };

  useEffect(() => {
    fetchApiCabinetsGet();
  }, []);

  useEffect(() => {
    const body = document.body;
    if (state.isLoading) {
      body.style.overflowY = 'hidden';
    } else {
      body.style.overflowY = 'auto';
    }
  }, [state.isLoading]);

  return (
    <div className={classes.cabinSystem}>
      <div className={classes.head}>
        <PrimaryTitle>{t("Hệ thống tủ")}</PrimaryTitle>

        <div className={classes.headBtn}>
          <CancelButton onClick={onClickSizeManagement}>
            {t("Quản lý kích thước")}
          </CancelButton>
          <CreateButton onClick={onClickButtonAdd}>{t("Thêm tủ")}</CreateButton>
        </div>
      </div>

      {state.isLoading && (
        <div className={classes.wrapLoading}>
          <CircularProgress />
        </div>
      )}

      {
        !state.isLoading && !state.listCabinets && (
          <div className={classes.wrapEmpty}>
            <EmptyIcon />
            <p className={classes.titleEmpty}>
              {t("Chưa có tủ được tạo")}
            </p>
          </div>
        )}

      {!state.isLoading && (
        <div className={classes.contain}>
          {state.listCabinets?.map((item) => (
            <div key={item.ID} className={classes.cabinItem}>
              <div className={classes.cabinItemHead}>
                <LargeTooltip title={`${t("Tủ")} ${item?.CabinetName}`}>
                  <p className={classes.cabinItemHeadTitle}>
                    {`${t("Tủ")} ${item?.CabinetName}`}
                  </p>
                </LargeTooltip>
                <span
                  className={classes.cabinItemHeadIcon}
                  onClick={() => onClickIconDetails(item)}
                >
                  <EditIcon />
                </span>
              </div>

              {!state.isLoading && state.listCabinets?.length === 0 && (
                <div className={classes.wrapLoading}>
                  <p>{t("Danh sách tủ rỗng")}</p>
                </div>
              )}

              <p className={classes.infoLabel}>{t("Loại tủ")}</p>
              <p className={classes.infoData}>{item?.TypeCabinet}</p>

              <p className={classes.infoLabel}>{t("Tổng số ô")}</p>
              <p className={classes.infoData}>{item?.Cells?.length}</p>
            </div>
            //   </div>
            // </div>
          ))
          }
        </div >
      )}

      <SuccessAlert
        message={state.responseStatus.succ}
        onCloseDialog={() => handleChangeResponseStatus()}
      />

      <ErrorAlert
        message={state.responseStatus.err}
        onCloseDialog={() => handleChangeResponseStatus()}
      />
    </div >
  );
}
