export const sortNumber = (v1, v2) => {
    return v1.localeCompare(v2, undefined, {
        numeric: true,
        sensitivity: 'base'
    });
}

export const sortText = (v1, v2) =>  {
    if (v1 > v2) {
        return -1;
    }
    if (v2 > v1) {
        return 1;
    }
    return 0;
}