import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';

const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);
const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);

const useStyles = makeStyles(theme => ({
    dialogTitleBackground: theme.primaryColor,
    dialogTitle: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '25px',
        color: '#FFFFFF',
    },
    dialogContentBlock: {
        width: 'auto',
        padding: '0',
        textAlign: 'center',
        position: 'relative',
        padding: '10px 20px',
        minWidth: '480px'
    },
    contentBlock: {
        width: '100%',
        marginTop: '10px',
        fontSize: '18px'
    },
    bold: {
        fontWeight: 'bold'
    },
    checkboxBlock: {
        height: '30px',
        paddingTop: '20px',
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    cancelButtonStyle: {
        margin: '10px 0'
    },
    createButtonStyle: {
        margin: '10px 0 10px 24px'
    },
    circularStyle: {
        position: 'absolute',
        left: '45%',
        top: '10%',
        color: '#0b59c8'
    }
}))

export const CheckboxConfirm = ({
    isOpen, 
    handleClose, 
    isLoading, 
    mess, 
    apartmentList,
    onConfirm, 
    user, 
    onNotificationChange, 
    isNotification, 
    notification
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Dialog
            open={isOpen}
            maxWidth="xl"
        >
            <DialogTitle className={classes.dialogTitleBackground}>
                <p className={classes.dialogTitle}>{t("Thông báo")}</p>
            </DialogTitle>
            <DialogContent className={classes.dialogContentBlock}>
                <div>
                    <div className={classes.contentBlock}>
                        <span>{mess} {user.full_name}</span>
                        {
                            user.apartments.length > 1 &&
                            <span className={classes.bold}>  <br />{apartmentList}</span>
                        }
                        <span>?</span>
                        <div className={classes.checkboxBlock}>
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    classes={{ label: classes.controlLabel }}
                                    control={
                                        <Checkbox
                                            color="primary"
                                            onChange={onNotificationChange}
                                            checked={isNotification}
                                            classes={{ root: notification === 0 ? classes.checkbox : classes.disabledCheckbox, checked: classes.checked }}
                                            disabled={notification !== 0}
                                        />}
                                    label={t('Gửi thông báo đến cư dân')}
                                    labelPlacement="end"
                                />
                            </FormGroup>
                        </div>
                    </div>
                </div>
                <CancelButton
                    variant="outlined"
                    className={classes.cancelButtonStyle}
                    onClick={handleClose}
                    disabled={isLoading}
                >
                    {t("Trở về")}
                </CancelButton>
                <CreateButton
                    variant="contained"
                    className={classes.createButtonStyle}
                    onClick={onConfirm}
                    disabled={isLoading}
                >
                    {t("Đồng ý")}
                </CreateButton>
                {isLoading && <CircularProgress color="primary" className={classes.circularStyle} />}
            </DialogContent>
        </Dialog>
    )
}