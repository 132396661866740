import DateFnsUtils from '@date-io/date-fns';
import { Dialog } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { DataGrid } from '@material-ui/data-grid';
import SearchIcon from '@material-ui/icons/Search';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { FormControlLabel, InputAdornment, MenuItem, Radio, RadioGroup } from '@mui/material';
import clsx from 'clsx';
import vi from 'date-fns/locale/vi';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { apiCabinetsGet, apiCellsHistoryGet } from '../../../../api/cabinets';
import ErrorAlert from '../../../../components/alert/ErrorAlert';
import InfoAlert from '../../../../components/alert/InfoAlert';
import CancelButton from '../../../../components/button/cancelButton';
import { CreateButton } from '../../../../components/button/createButton';
import FilterButton from '../../../../components/button/filterButton';
import { CustomLoading } from '../../../../components/grid/customLoading';
import { EmptyRowsView } from '../../../../components/grid/emtyRowsView';
import LargeTooltip from '../../../../components/largeTooltip';
import { PrimaryTitle } from '../../../../components/title';
import { formatToVND } from '../../../../helper/currency';
import { sortText } from '../../../../helper/sort';
import { capitalizeFirstLetter } from '../../../../helper/strings';
import { useStyles } from './styles';

export default function CabinetHistories() {
    const [state, setState] = useState({
        listCabinet: [],
        listHistory: [],
        cabinetID: "",
        responseStatus: {
            succ: "",
            err: "",
            infor: ""
        },
        isLoading: false,
        isLoadingTable: false
    })
    const [pagination, setPagination] = useState({
        page: 0,
        pageSize: 10,
        rowCount: 0,
    });
    const [filter, setFilter] = useState({
        paymentStatus: "",
        startTimeBooking: null,
        endTimeBooking: null,
        startTimeReturn: null,
        endTimeReturn: null,
        isFilter: false,
        isClear: false
    });

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const { url } = useRouteMatch();
    const moment = require('moment-timezone');
    const location = useLocation();

    const cabinetID = location?.state?.cabinetID;

    const currentPageRef = useRef(0);
    const filterRequestRef = useRef({
        searchKeyWord: "",
        paymentStatus: "",
        startTimeBooking: null,
        endTimeBooking: null,
        startTimeReturn: null,
        endTimeReturn: null,
    });
    const debounceRef = useRef();
    const searchInputRef = useRef();
    

    const fetchApiCabinetsGet = async (body) => {
        setState(prev => ({
            ...prev,
            isLoadingTable: true
        }));
        let cabinetIDTemp = cabinetID || '';

        try {
            const { data } = await apiCabinetsGet(body);
            const listCabinet = data?.Items || [];

            if(!cabinetIDTemp) {
                cabinetIDTemp = listCabinet.length > 0 ? listCabinet[0]?.ID : "";
            }

            setState(prev => ({
                ...prev,
                listCabinet,
                cabinetID: cabinetIDTemp
            }));
        } catch (error) {
            handleChangeResponseStatus("", t("Tải thông tin thất bại, vui lòng thử lại!"));
        }
        
        setState(prev => ({
            ...prev,
            isLoadingTable: false
        }));

        return cabinetID;
    }

    const fetchApiCellsHistoryGet = async (body) => {
        setState(prev => ({
            ...prev,
            isLoading: true
        }));
        try {
            const { data } = await apiCellsHistoryGet(body);
            const { Items: listHistory, ScannedCount } = data;

            listHistory.forEach(item => item.id = item.ID);

            setState(prev => ({
                ...prev,
                listHistory
            }));
            setPagination(prev => ({
                ...prev,
                rowCount: ScannedCount
            }))
        } catch (error) {
            handleChangeResponseStatus("", t("Tải thông tin thất bại, vui lòng thử lại!"));
        }
        setState(prev => ({
            ...prev,
            isLoading: false
        }));
    }

    const onClickBackToManagement = () => {
        history.push('/admin/cabinet/managenent');
    }

    const handleChangeResponseStatus = (succ = "", err = "", infor = "") => {
        setState(prev => ({
            ...prev,
            responseStatus: {
                succ,
                err,
                infor
            }
        }));
    }

    const onPageSizeChange = (pageSize) => {
        currentPageRef.current = 0;

        setPagination(prev => ({
            ...prev,
            pageSize,
            page: 0
        }));
    }
    const onPageChange = (newPage) => {
        if (currentPageRef.current !== pagination.page) return;

        currentPageRef.current = newPage;
        setPagination(prev => ({
            ...prev,
            page: newPage
        }))
    }

    const onCabinetChange = (e) => {
        filterRequestRef.current = {
            searchKeyWord: "",
            paymentStatus: "",
            startTimeBooking: null,
            endTimeBooking: null,
            startTimeReturn: null,
            endTimeReturn: null,
        };

        searchInputRef.current.value = "";

        setState(prev => ({
            ...prev,
            cabinetID: e.target.value
        }));
        setPagination(prev => ({
            ...prev,
            page: 0,
        }));
    }

    const getTotalHourColunm = (cell) => {
        let result = "";

        const mess = (cell.EndTime || cell.endTime) ? moment
            .duration((cell.EndTime || cell.endTime) - cell.StartTime, 'milliseconds')
            .humanize({ h: Infinity }) : "";

        if (mess && isNaN(mess.split(' ')[0])) {
            result = 1;
        }
        if (mess && !isNaN(mess.split(' ')[0])) {
            if (mess.split(' ')[1] === "hours") {
                result = mess.split(' ')[0];
            } else {
                result = 1;
            }
        }

        return result;
    }

    const onSearchChange = (e) => {
        filterRequestRef.current = {
            ...filterRequestRef.current,
            searchKeyWord: e.target.value
        }

        if (debounceRef.current) {
            clearTimeout(debounceRef.current);
        }

        debounceRef.current = setTimeout(() => {
            setPagination(prev => ({
                ...prev,
                page: 0
            }))
        }, 400);
    }

    const handleChangeFilter = () => {
        setFilter(prev => ({
            ...prev,
            isFilter: !prev.isFilter
        }))
    }

    const onClickButtonFilter = () => {
        handleChangeResponseStatus("", "", t("Tính năng chưa sẵn sàng"));
        return;

        setFilter(prev => ({
            ...prev,
            ...filterRequestRef.current,
            isFilter: !prev.isFilter
        }));
    }

    const onClickCloseFilter = () => {
        if (filter.isClear) {
            setFilter(prev => ({
                ...prev,
                isClear: false
            }));
        }
        handleChangeFilter();
    }

    const onFilterChange = (key, e) => {
        if (key !== "paymentStatus") {
            setFilter(prev => ({
                ...prev,
                [key]: e
            }));
            return;
        }

        const value = e?.target.value;

        setFilter(prev => ({
            ...prev,
            [key]: value
        }));
    }

    const onClickApplyFilter = () => {
        if (filter.isClear) {
            setFilter(prev => ({
                ...prev,
                isFilter: false,
                isClear: false
            }));
            return
        }

        filterRequestRef.current = {
            ...filterRequestRef.current,
            ...filter,
            isFilter: false
        }

        for (let key in filterRequestRef.current) {
            if (key.includes("Time") && filterRequestRef.current[key]) {
                filterRequestRef.current[key] = moment(filterRequestRef.current[key]).valueOf();
            }
        }

        setFilter(prev => ({
            ...prev,
            isFilter: false
        }));
    }

    const onClickClearFilter = () => {
        filterRequestRef.current = {};
        setFilter(prev => ({
            ...prev,
            paymentStatus: "",
            startTimeBooking: null,
            endTimeBooking: null,
            startTimeReturn: null,
            endTimeReturn: null,
            isClear: true
        }))
    }

    const onClickDeleteFilterItem = (key) => {
        setFilter(prev => ({
            ...prev,
            [key]: ""
        }));
        delete filterRequestRef.current[key];
    }

    const onRowClick = (e) => {
        history.push({
            pathname: `${url}/details`,
            state: {
                cell: e?.row,
                cabinetID: state.cabinetID
            }
        })
    }

    const sortNumber = (v1, v2) => {
        return v1.localeCompare(v2, undefined, {
            numeric: true,
            sensitivity: 'base'
        });
    }

    const handleSortTotalHour = (id1, id2) => {
        let element1, element2;

        state.listHistory.forEach(item => {
            if(item.id === id1) {
                element1 = item;
            }
            if(item.id === id2) {
                element2 = item;
            }
        })

        if(!element1 || !element2) return 0;

        return sortNumber(`${getTotalHourColunm(element1)}`, `${getTotalHourColunm(element2)}`);
    }

    useEffect(() => {
        fetchApiCabinetsGet({ language: i18n.language });
    }, []);

    useEffect(() => {
        if (!state.cabinetID) return;

        const body = {
            language: i18n.language,
            cabinetID: state.cabinetID,
            startIndex: pagination.page * pagination.pageSize,
            endIndex: pagination.page * pagination.pageSize + pagination.pageSize
        }

        for (let key in filterRequestRef.current) {
            if (filterRequestRef.current[key]) {
                body[key] = filterRequestRef.current[key];
            } else {
                delete body[key];
            }
        }

        fetchApiCellsHistoryGet(body);
    }, [state.cabinetID, i18n.language, pagination.page, pagination.pageSize, filterRequestRef.current])

    const columns = [
        {
            headerName: t("Số ô"),
            field: 'CabinetCellName',
            cellClassName: 'super-app-theme--cell',
            sortComparator: (v1, v2) => sortNumber(v1, v2),
            renderCell: params => (
                <LargeTooltip
                    title={params?.row?.CabinetCellName}
                >
                    <p style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }}>
                        {params?.row?.CabinetCellName}
                    </p>
                </LargeTooltip>
            ),
            flex: 1,
        },
        {
            headerName: t("Kích thước"),
            field: 'CellType',
            cellClassName: 'super-app-theme--cell',
            sortComparator: (v1, v2, param1, param2) => sortNumber(v1[0]?.name, v2[0]?.name),
            renderCell: params => (
                <LargeTooltip
                    title={Array.isArray(params?.row?.CellType)
                        && params?.row?.CellType.length > 0
                        && params?.row?.CellType[0].name
                    }
                >
                    <p style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }}>
                        {Array.isArray(params?.row?.CellType)
                            && params?.row?.CellType.length > 0
                            && params?.row?.CellType[0].name
                        }
                    </p>
                </LargeTooltip>
            ),
            flex: 1,
        },
        {
            headerName: t("Người đặt"),
            field: 'FullName',
            flex: 1,
            cellClassName: 'super-app-theme--cell',
            renderCell: params => (
                <LargeTooltip
                    title={`${params?.row?.FullName}`}
                >
                    <p style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }}>
                        {`${params?.row?.FullName}`}
                    </p>
                </LargeTooltip>
            ),
        },
        {
            headerName: t("Trạng thái"),
            field: 'PaymentStatus',
            cellClassName: 'super-app-theme--cell',
            sortComparator: (v1, v2) => {
                return sortText(t(capitalizeFirstLetter(v1)), t(capitalizeFirstLetter(v2)));
            },
            renderCell: params => (
                <LargeTooltip
                    title={params?.row?.PaymentStatus
                        ? t(capitalizeFirstLetter(params?.row?.PaymentStatus))
                        : ""}
                >
                    <p
                        style={{
                            position: "relative",
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            paddingLeft: "1rem",
                            color: params?.row?.PaymentStatus === "pending"
                                ? "#EE8306"
                                : params?.row?.PaymentStatus === "paid"
                                    ? "#29A62B"
                                    : "#ED1626"
                        }}
                        className={clsx(
                            params?.row?.PaymentStatus && classes[`statusColumn${params?.row?.PaymentStatus}`]
                        )}
                    >
                        {params?.row?.PaymentStatus
                            ? t(capitalizeFirstLetter(params?.row?.PaymentStatus))
                            : ""}
                    </p>
                </LargeTooltip>
            ),
            flex: 1,
        },
        {
            headerName: t("Thời gian đặt"),
            field: 'StartTime',
            cellClassName: 'super-app-theme--cell',
            renderCell: params => (
                <LargeTooltip
                    title={params?.row?.StartTime
                        ? moment.tz(params?.row?.StartTime, "Asia/Ho_Chi_Minh").format("HH:mm DD/MM/YYYY ")
                        : ""
                    }
                >
                    <p style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }}>
                        {params?.row?.StartTime
                            ? moment.tz(params?.row?.StartTime, "Asia/Ho_Chi_Minh").format("HH:mm DD/MM/YYYY ")
                            : ""
                        }
                    </p>
                </LargeTooltip>
            ),
            flex: 1,
        },
        {
            headerName: t("Thời gian trả tủ"),
            field: 'endTime',
            cellClassName: 'super-app-theme--cell',
            renderCell: params => (
                params.row?.PaymentStatus !== "pending" && <LargeTooltip
                    title={params?.row?.endTime
                        ? moment.tz(params?.row?.endTime, "Asia/Ho_Chi_Minh").format("HH:mm DD/MM/YYYY ")
                        : ""
                    }
                >
                    <p style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }}>
                        {params?.row?.endTime
                            ? moment.tz(params?.row?.endTime, "Asia/Ho_Chi_Minh").format("HH:mm DD/MM/YYYY ")
                            : ""
                        }
                    </p>
                </LargeTooltip>
            ),
            flex: 1,
        },
        {
            headerName: t("Tổng giờ"),
            field: 'TotalHours',
            cellClassName: 'super-app-theme--cell',
            sortComparator: (v1, v2, param1, param2) => handleSortTotalHour(param1.id, param2.id),
            renderCell: params => (
                params.row?.PaymentStatus !== "pending" && <LargeTooltip
                    title={getTotalHourColunm(params?.row)}
                >
                    <p style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }}>
                        {getTotalHourColunm(params?.row)}
                    </p>
                </LargeTooltip>
            ),
            flex: 1,
        },
        {
            headerName: t("Tổng thanh toán"),
            field: 'totalPrice',
            cellClassName: 'super-app-theme--cell',
            renderCell: params => (
                <LargeTooltip
                    title={formatToVND(params?.row?.totalPrice)}
                >
                    <p style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }}>
                        {formatToVND(params?.row?.totalPrice)}
                    </p>
                </LargeTooltip>
            ),
            flex: 1,
        },
    ];

    return (
        <div className={classes.manage}>
            <div className={classes.manageHeader}>
                <div className={classes.wrapTitle}>
                    <div
                        className={classes.wrapTitleIcon}
                        onClick={onClickBackToManagement}
                    >
                        <ArrowBackIosNewOutlinedIcon />
                    </div>
                    <PrimaryTitle style={{paddingTop: "unset"}}>{t("Lịch sử hoạt động")}</PrimaryTitle>
                    <Select
                        id='block'
                        size='small'
                        className={clsx(classes.select, classes.selectNoneBorder)}
                        disabled={state.isLoading}
                        value={state.cabinetID}
                        onChange={onCabinetChange}
                    >
                        {state.listCabinet?.map(item => (
                            <MenuItem
                                className={classes.menuItem}
                                key={item.ID}
                                value={item.ID}
                            >
                                <LargeTooltip
                                    style={{
                                        textOverflow: "ellipsis",
                                        overflow: "hidden"
                                    }}
                                    title={`${t("Tòa")} ${item.Location} - ${t("Tủ")} ${item.CabinetName}`}
                                >
                                    <p>
                                        {t("Tòa")} {item.Location} - {t("Tủ")} {item.CabinetName}
                                    </p>
                                </LargeTooltip>
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </div>

            <div className={classes.manageAction}>
                <TextField
                    inputRef={searchInputRef}
                    placeholder={t("Tìm kiếm số ô, người đặt tủ")}
                    variant="outlined"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        style: { background: '#FFFFFF', height: '40px', width: '320px' }
                    }}
                    onChange={onSearchChange}
                />

                <div className={classes.ActionBtn}>
                    <FilterButton
                        className={classes.filterBtn}
                        onClick={onClickButtonFilter}
                    >
                        {t("Bộ lọc")}
                    </FilterButton>
                </div>
            </div>

            <div className={classes.filterShow}>
                {(filterRequestRef.current.paymentStatus
                    || filterRequestRef.current.startTimeBooking
                    || filterRequestRef.current.endTimeBooking
                    || filterRequestRef.current.startTimeReturn
                    || filterRequestRef.current.endTimeReturn) && (
                        <div className={classes.showTitle}>{t("Lọc bởi")}</div>
                    )}
                <div className={classes.showDetails}>
                    {Object.keys(filterRequestRef.current).map((key) => (
                        <Fragment key={key}>
                            {(key !== "isFilter" && key !== "isClear" && key !== "searchKeyWord")
                                && filterRequestRef.current[key] && (
                                    <div className={classes.showItem}>
                                        <span className={classes.showItemKey}>{t(key)}: </span>
                                        <span className={classes.showItemValue}>
                                            {key.includes("Time")
                                                ? moment.tz(filterRequestRef.current[key], "Asia/Ho_Chi_Minh").format("DD/MM/YYYY ")
                                                : t(filterRequestRef.current[key])}
                                        </span>
                                        <ClearOutlinedIcon
                                            className={classes.showItemDelete}
                                            onClick={() => onClickDeleteFilterItem(key)}
                                        />
                                    </div>
                                )}
                        </Fragment>
                    ))}
                </div>
            </div>

            <div className={clsx(classes.table, classes.root)}>
                <DataGrid
                    disableSelectionOnClick
                    disableColumnMenu
                    componentsProps={{
                        pagination: {
                            labelRowsPerPage: t("Số dòng mỗi trang"),
                            labelDisplayedRows: ({ from, to, count }) => { return `${from}-${to} ${t("trong số")} ${count}` }
                        }
                    }}
                    components={{
                        NoRowsOverlay: () => EmptyRowsView(t("không tìm thấy dữ liệu")),
                        LoadingOverlay: CustomLoading
                    }}
                    localeText={{
                        footerRowSelected: (count) => count + t(" hàng trong bảng đã được chọn"),
                        columnHeaderSortIconLabel: t('Sắp xếp')
                    }}
                    loading={state.isLoading || state?.isLoadingTable}
                    columns={columns}
                    rows={state.listHistory}
                    page={pagination.page}
                    pageSize={pagination.pageSize}
                    rowCount={pagination.rowCount}
                    rowHeight={45}
                    rowsPerPageOptions={[10, 50, 100]}
                    paginationMode="server"
                    onPageSizeChange={(pageSize) => onPageSizeChange(pageSize)}
                    onPageChange={(newPage) => onPageChange(newPage)}
                    onRowClick={(e) => onRowClick(e)}
                />
            </div>

            {/* <div className={classes.total}>
                {t("Tổng tiền")}: 1.200.000 đ
            </div> */}

            <Dialog
                open={filter.isFilter}
                onClose={handleChangeFilter}
            >
                <div className={classes.filter}>
                    <p className={classes.filterTitle}>{t("Bộ lọc")}</p>
                    <div
                        className={classes.filterClose}
                        onClick={onClickCloseFilter}
                    >
                        <ClearOutlinedIcon />
                    </div>

                    <form>
                        <label className={classes.filterLabel}>{t("Trạng thái")}</label>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            className={classes.filterRadioGroup}
                            value={filter.paymentStatus}
                            onChange={(e) => onFilterChange("paymentStatus", e)}
                        >
                            <FormControlLabel value="pending" control={<Radio />} label={t("Chờ thanh toán")} />
                            <FormControlLabel value="paid" control={<Radio />} label={t("Đã thanh toán")} />
                            <FormControlLabel value="cancelled" control={<Radio />} label={t("Đã hủy")} />
                        </RadioGroup>

                        <label className={classes.filterLabel}>{t("Thời gian đặt tủ")}</label>
                        <div className={classes.filterWrapDate}>
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={i18n.language === 'vi' ? vi : ''}
                            >
                                <KeyboardDatePicker
                                    autoOk
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    inputVariant="outlined"
                                    size="small"
                                    openTo="date"
                                    placeholder={t("Thời gian bắt đầu")}
                                    InputAdornmentProps={{ position: "end" }}
                                    className={classes.filterDate}
                                    value={filter.startTimeBooking || null}
                                    onChange={(e) => onFilterChange("startTimeBooking", e)}
                                />
                            </MuiPickersUtilsProvider>

                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={i18n.language === 'vi' ? vi : ''}
                            >
                                <KeyboardDatePicker
                                    autoOk
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    inputVariant="outlined"
                                    size="small"
                                    openTo="date"
                                    placeholder={t("Thời gian kết thúc")}
                                    InputAdornmentProps={{ position: "end" }}
                                    className={classes.filterDate}
                                    value={filter.endTimeBooking || null}
                                    onChange={(e) => onFilterChange("endTimeBooking", e)}
                                />
                            </MuiPickersUtilsProvider>
                        </div>

                        <label className={classes.filterLabel}>{t("Thời gian trả tủ")}</label>
                        <div className={classes.filterWrapDate}>
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={i18n.language === 'vi' ? vi : ''}
                            >
                                <KeyboardDatePicker
                                    autoOk
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    inputVariant="outlined"
                                    size="small"
                                    openTo="date"
                                    placeholder={t("Thời gian bắt đầu")}
                                    InputAdornmentProps={{ position: "end" }}
                                    className={classes.filterDate}
                                    value={filter.startTimeReturn || null}
                                    onChange={(e) => onFilterChange("startTimeReturn", e)}
                                />
                            </MuiPickersUtilsProvider>

                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={i18n.language === 'vi' ? vi : ''}
                            >
                                <KeyboardDatePicker
                                    autoOk
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    inputVariant="outlined"
                                    size="small"
                                    openTo="date"
                                    placeholder={t("Thời gian kết thúc")}
                                    InputProps={{ readOnly: true }}
                                    InputAdornmentProps={{ position: "end" }}
                                    className={classes.filterDate}
                                    value={filter.endTimeReturn || null}
                                    onChange={(e) => onFilterChange("endTimeReturn", e)}
                                />
                            </MuiPickersUtilsProvider>
                        </div>

                        {/* <label className={classes.filterLabel}>{t("Người thanh toán")}</label>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            className={classes.filterRadioGroup}
                        >
                            <FormControlLabel value="female" control={<Radio />} label={t("Người đặt")} />
                            <FormControlLabel value="male" control={<Radio />} label={t("Ban quản lý")} />
                            <FormControlLabel value="other" control={<Radio />} label={t("Lễ tân")} />
                        </RadioGroup> */}
                    </form>

                    <div className={classes.filterAct}>
                        <CancelButton onClick={onClickClearFilter}>
                            {t("Xóa bộ lọc")}
                        </CancelButton>
                        <CreateButton onClick={onClickApplyFilter}>
                            {t("Áp dụng")}
                        </CreateButton>
                    </div>
                </div>
            </Dialog>

            <ErrorAlert
                message={state.responseStatus.err}
                onCloseDialog={() => handleChangeResponseStatus()}
            />

            <InfoAlert
                message={state.responseStatus.infor}
                onCloseDialog={() => handleChangeResponseStatus()}
            />
        </div>
    )
}
