import React,{useEffect} from 'react';
import { Link } from 'react-scroll';
import {useTranslation} from 'react-i18next';
import Cookies from 'js-cookie';

const buildingInfo = [
    {
        category:'CATEGORY',
        name:'Building A',
        image:'assets/img/portfolio/thumbnails/b1.jpg'
    },
    {
        category:'CATEGORY',
        name:'Building C',
        image:'assets/img/portfolio/thumbnails/b11.jpg'
    },
    {
        category:'CATEGORY',
        name:'Building H',
        image:'assets/img/portfolio/thumbnails/b8.jpg'
    },
    {
        category:'CATEGORY',
        name:'Building I',
        image:'assets/img/portfolio/thumbnails/b9.jpg'
    },
    {
        category:'CATEGORY',
        name:'Building K',
        image:'assets/img/portfolio/thumbnails/b10.jpg'
    },
    {
        category:'CATEGORY',
        name:'Building L',
        image:'assets/img/portfolio/thumbnails/b3.jpg'
    },
]

const LandingPage = props => {
    const {t,i18n} = useTranslation();

    useEffect(()=>{
        Cookies.remove('token',{path:''});
        localStorage.removeItem('username');
        Cookies.remove('receptionistToken',{path:''});
        localStorage.removeItem('apartmentBlock');
        localStorage.removeItem('apartmentFloor');
        localStorage.removeItem('apartmentRoom');
    },[])
    
    const onLanguageChange = (e) =>{
        i18n.changeLanguage(e.target.value);
    }

    return (
        <div id="page-top">
            
            {/* <!-- Navigation--> */}
                <nav className="navbar navbar-expand-lg navbar-light fixed-top py-3" id="mainNav">
                    <div className="container">
                        <span className="navbar-brand js-scroll-trigger">
                            <img src="Favicon-1.svg" alt="" height="59px" />
                            <img src="images/TMAlogo.png" alt="" />
                        </span>
                        <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                        <div className="collapse navbar-collapse" id="navbarResponsive">
                            <ul className="navbar-nav ml-auto my-2 my-lg-0">                                
                                <li className="nav-item"><Link to='services' spy={true} smooth={true} className="nav-link js-scroll-trigger" href="">{t('Giải pháp')}</Link></li>
                                <li className="nav-item"><Link to='contact' spy={true} smooth={true} className="nav-link js-scroll-trigger" href="">{t('Liên hệ')}</Link></li>
                                <li className="nav-item"><a className="nav-link js-scroll-trigger" href="login">{t('Đăng nhập')}</a></li>
                                <li className="nav-item"><a className="nav-link js-scroll-trigger" href="receptionist-login">{t('Đăng ký')}</a></li>
                                <li className="nav-item" style={{marginTop:'3px'}}>  
                                    {i18n.language==='vi' && <img src={window.location.origin +'/images/vi.png'} alt="vi" style={{width:'15px',height:'15px',borderRadius:'100%'}}/>}
                                    {i18n.language==='en' && <img src={window.location.origin +'/images/en.png'} alt="en" style={{width:'15px',height:'15px',borderRadius:'100%'}}/>}
                                    <select value={i18n.language} onChange={onLanguageChange} style={{background:'inherit',border:'none',color:'#615656',fontWeight:'bold',outline:'0px',position:'relative',top:'2px'}}>
                                        <option style={{color:'#615656',fontWeight:'bold',outline:'0px'}} value={'vi'}>VI</option>
                                        <option style={{color:'#615656',fontWeight:'bold',outline:'0px'}} value={'en'}>EN</option>
                                    </select>
                                    
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                {/* <!-- Masthead--> */}
                <header className="masthead">
                    <div className="container h-100">
                        <div className="row h-100 align-items-center justify-content-center text-center">
                            <div className="col-lg-10 align-self-end">
                                <div>
                                    <h1 className="text-uppercase text-white font-weight-bold" style={{maxWidth:'800px',margin:'auto'}} >
                                            {t('CHO CUỘC SỐNG HIỆN ĐẠI TIỆN NGHI VÀ AN TOÀN VỚI CÔNG NGHỆ 4.0')}
                                    </h1>
                                </div>
                                <hr className="divider my-4" />
                            </div>
                            <div className="col-lg-8 align-self-baseline">
                                <p className="text-white-75 font-weight-light mb-5">
                                    {t('Bạn có đang phải mang thẻ ra vào khu cư dân mọi lúc mọi nơi?')}<br/>
                                    {t('Bạn chạy xuống dưới sảnh để đón khách?')} <br/>
                                    {t('Bạn không biết khách của bạn là ai khi họ tới thăm?')}<br/>
                                </p>        
                                <Link to='services' spy={true} smooth={true} className="btn btn-xl js-scroll-trigger text-white">{t('Tìm hiểu thêm')}</Link>
                            </div>
                        </div>
                    </div>
                </header>
                {/* <!-- About--> */}
                <section className="page-section" id="services">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8 text-center">
                                <h2 className=" mt-0">{t('GIẢI PHÁP KIỂM SOÁT RA VÀO THÔNG MINH')}</h2>
                                <hr className="divider light my-4" />
                                <div className="row" style={{paddingBottom:'20px'}}>
                                    <div className="col-lg-3">
                                        <img src="images/icon1.png" alt="" width="84px" height="84px" />
                                        <p className="service-title">{t('Chung cư')}</p>
                                    </div>
                                    <div className="col-lg-3">
                                        <img src="images/icon2.png" alt="" width="84px" height="84px" />
                                        <p className="service-title">{t('Tòa nhà văn phòng')}</p>
                                    </div>
                                    <div className="col-lg-3">
                                        <img src="images/icon3.png" alt="" width="84px" height="84px" />
                                        <p className="service-title">{t('Khu phức hợp')}</p>
                                    </div>
                                    <div className="col-lg-3">
                                        <img src="images/icon4.png" alt="" width="84px" height="84px" />
                                        <p className="service-title">{t('Khu đô thị')}</p>
                                    </div>
                                </div>
                                <Link to='page-top' className="btn btn-xl js-scroll-trigger text-white" >{t('Bắt đầu!')}</Link>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Services--> */}
                <section className="page-section text-white bg-primary" id="">
                    <div className="container">
                        <h2 className="text-center  mt-0">{t('GIẢI PHÁP CỦA CHÚNG TÔI')}</h2>
                        <hr className="divider my-4" />
                        <div className="row">
                            <div className="col-lg-3 col-md-6 text-center">
                                <div className="mt-5">
                                    <i className="fas fa-4x fa-globe text-white mb-4"></i>      
                                    {/* <img src="images/faceid.svg" alt="" width="100px" height="100px" style={{}}/> */}
                                    <h3 className="h4 mb-2 solution-title">{t('Nhận diện khuôn mặt')}</h3>
                                    <p className="text-light mb-0 solution-content" style={{maxWidth:'220px'}}>
                                        {t('Mở cổng tự động khi nhận diện cư dân của tòa nhà.')}
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 text-center">
                                <div className="mt-5">
                                    <i className="fa fa-mobile text-white mb-4 fa-4x"></i>      
                                    {/* <PhoneAndroidIcon style={{fontSize:'66px',marginBottom:'24px'}}/> */}
                                    <h3 className="h4 mb-2 solution-title">{t('Gọi video')}</h3>
                                    <p className="text-light mb-0 solution-content" style={{maxWidth:'200px'}}>
                                        {t('Bạn sẽ thấy video của người tới thăm bạn, và cho phép mở cửa từ xa.')}
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 text-center">
                                <div className="mt-5">
                                    <i className="fa fa-qrcode text-white mb-4 fa-4x"></i>
                                    <h3 className="h4 mb-2 solution-title">{t('Mã QR')}</h3>
                                    <p className="text-light mb-0 solution-content" style={{maxWidth:'210px'}}>
                                        {t('Bạn không phải sợ quên thẻ. Gửi mã mời khách chỉ với 1 thao tác.')}
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 text-center">
                                <div className="mt-5">
                                    <i className="fa fa-braille text-white mb-4 fa-4x"></i>    
                                    <h3 className="h4 mb-2 solution-title">{t('Tiện ích')}</h3>
                                    <p className="text-light mb-0 solution-content">{t('Nhận thông báo từ Ban Quản Lý,')}<br/> {t('thanh toán hóa đơn,')}<br/> {t('đặt dịch vụ vệ sinh,')} ...</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Portfolio--> */}
                <div id="portfolio">
                    <div className="container-fluid p-0">
                        <div className="row no-gutters">
                            {
                                buildingInfo.map((values,key)=>{
                                    return(
                                        <div key={key} className="col-lg-4 col-sm-6">
                                            <div className="portfolio-box" >
                                                <img className="img-fluid" src={values.image} alt="..." width="100%" />
                                                <div className="portfolio-box-caption">
                                                    <div className="project-category text-white-50">{values.category}</div>
                                                    <div className="project-name">{values.name}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    
                                })
                            }
                        </div>
                    </div>
                </div>
                {/* <!-- Contact--> */}     
                <section className="page-section" id="contact">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8 text-center">
                                <h2 className="mt-0">{t('LIÊN LẠC VỚI CHÚNG TÔI')}</h2>
                                <hr className="divider light my-4" />
                                <p className="text-muted mb-5">{t('Bạn đã sẵn sàng cho những trải nghiệm mới, tiện nghi, hiện đại và an toàn.')}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 ml-auto text-center mb-5 mb-lg-0">
                                <i className="fas fa-phone fa-3x mb-3 text-muted"></i>
                                <div>+84-8 3997 8000 </div>
                            </div>
                            <div className="col-lg-4 mr-auto text-center">
                                <i className="fas fa-envelope fa-3x mb-3 text-muted"></i>
                                {/* <!-- Make sure to change the email address in BOTH the anchor text and the link target below!--> */}
                                <a className="d-block text-blue"  href="mailto:contact@tmainnovation.vn">contact@tmainnovation.vn</a>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Footer */}
                <footer className="bg-light py-5">
                    <div className="container">
                        <div className="small text-center text-muted">
                            Copyright &copy;
                            {/* <!-- This script automatically adds the current year to your website footer-->
                            <!-- (credit: https://updateyourfooter.com/)--> */}
                            {/* <script>
                                document.write(new Date().getFullYear());
                            </script> */}
                            - TMA Innovation
                        </div>
                        <div className="small text-center text-muted" style={{marginTop:'5px'}}>
                            {/* <FormControl required variant="outlined" style={{width:'172px'}}>
                                <Select
                                    value={i18n.language}
                                    className={classes.root}
                
                                    displayEmpty
                                    onChange={onLanguageChange}
                                >
                                    <MenuItem value='vi'>Tiếng Việt</MenuItem>
                                    <MenuItem value='en'>English</MenuItem>
                                </Select>
                            </FormControl> */}
                            {/* <select
                                value={i18n.language}
                                style={{height:'20px',width:'172px',paddingLeft: '50px',color:'#6c757d',border:'1px solid #6c757d'}}
                                displayEmpty
                                onChange={onLanguageChange}
                            >
                                <option value='vi'>Tiếng Việt</option>
                                <option value='en'>English</option>
                            </select> */}
                        </div>
                    </div>
                </footer>
        </div>
    )
}


export default LandingPage;
