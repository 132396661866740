import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    error: { color: 'red', position: 'absolute', bottom: '0', left: '20px' },
    alert: { position: 'absolute', left: '3px', top: '3px', width: '99%' },
    dialogTitle: { textAlign: 'center', fontSize: '20px', fontWeight: '600', lineHeight: '25px', color: '#FFFFFF' },
    contentDialog: { fontSize: '16px', height: '68px', borderBottom: '1px solid #EEEDF2', display: 'flex', alignItems: 'center', justifyContent: 'center' },
    dialogTitleBackground: theme.primaryColor,
    errorColor: theme.errorColor,
    root: theme.dataGridStyle,
    overlay: { position: 'fixed', width: '100%', height: '100%', top: '0', left: '0', background: 'rgba(0,0,0,0.1)', zIndex: '1300' },
    requestError: { width: '100%', height: '65vh', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '20px', color: '#6D6D6D' },
    tableTitle: { position: 'relative', height: '56px', border: '1px solid rgba(224, 224, 224, 1)', borderTopLeftRadius: '6px', borderTopRightRadius: '6px' },
    alertStyle: { position: 'absolute', right: '9px', bottom: '8px', padding: '0 10px', zIndex: '10' },
    selectTitle: { fontSize: '16px', lineHeight: '22.4px', position: 'absolute', bottom: '8px', right: '8px' },
    inputBlock: { height: '60px', width: '90%', margin: 'auto', position: 'relative' },
    label: { margin: '0', fontSize: '14px', lineHeight: '140%', color: '#000000', display: 'inline-block', fontWeight: 'bold' },
    input: { width: '100%' },
}));

export default useStyles;