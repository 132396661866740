import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    curTime: {
        position: "absolute",
        zIndex: "10",
        top: "86px",
        left: "0",
        width: "100%",
        height: "1px",
        background: "red"
    }
}));

export default useStyles;