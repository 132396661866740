import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import { Provider } from 'react-redux';
import store from './redux/store/Store';
import { SnackbarProvider } from "notistack";

Amplify.configure(awsExports);
ReactDOM.render(
    <Provider store={store}>
        <SnackbarProvider maxSnack={3}>
            <App />
        </SnackbarProvider>
    </Provider>
    ,document.getElementById('root')
);
