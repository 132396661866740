import makeStyles from '@material-ui/styles/makeStyles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    statistic: {
    },
    head: {
        display: 'flex',
        alignItems: "center",
        marginBottom: '24px'
    },
    title: {
        fontWeight: '800',
        fontSize: '28px',
        margin: '0 16px 0 0',
        color: '#333333'
    },
    select: {
        paddingTop: '8px',
        width: "250px",
        height: "40px",
        '& div': {
            background: "unset !important"
        }
    },
    selectNoneBorder: {
        width: "auto",
        maxWidth: '250px',
        overflow: 'hidden',
        '&::before': {
            border: 'unset !important'
        },
        '&::after': {
            border: 'unset !important'
        },
    },
    menuItem: {
        maxWidth: "250px",
        "& p": {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden"
        }
    },
}));