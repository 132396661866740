import AxiosClient from "../config/AxiosClient";

export const apiPeopleCoutingWarningId = async (id) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = `/people/counting/warnings?id=${id}`;
    } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
        url = `/warning/${id}`;
    }
    return await AxiosClient.get(url);
}

export const apiPeopleCoutingWarningOffsetLimit = async (offset, limit) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = `/people/counting/warnings?offset=${offset}&&limit=${limit}`;
    } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
        url = `/warning/offset=${offset}&&limit=${limit}`;
    }
    return await AxiosClient.get(url);
}

export const apiPeopleWarningDelete = async (requestBody) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = `/people/warning/delete`;
    } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
        url = `/warning/delete`;
    }
    return await AxiosClient.post(url, requestBody);
}

export const apiPeopleWarningUpdate = async (requestBody) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = `/people/warning/update`;
    } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
        url = `/warning/update`;
    }
    return await AxiosClient.post(url, requestBody);
}