import AxiosClient from '../config/AxiosClient';

export const staffCreate = (body) => {
    /*
        body = {
            username: string,
            role: 'Receptionist' | 'Guard' | 'Management Staff',
            full_name: string,
            block: blockID,
            residence_id: "",
            face_id: string [option],
            phone: "",
            policies: {
                block_policy : [
                    {
                        block : 'all',
                        floor : ['all'],
                    }
                ],
                device_policy : [ 
                    {
                        block : 'all',
                        floor : 'all',
                        room : ['all']
                    }
                ],
            }
        }
    */
    const url = "/staffs/create";
    return AxiosClient.post(url, body);
}

export const updateStaff = (body) => {
    /*
        body = {
            username: "" required,
            fields's updated same create
        }
    */
    const url = '/staffs/update';
    return AxiosClient.post(url, body);
}

export const getListStaff = (body) => {
    /*
    const body = {
        offset,
        limit,
        block,
        status,
        role,
        username
    }
    */
    const url = "/staffs/get";
    return AxiosClient.get(url, { params: body });
}

export const getStaffById = (body) => {
    /*
        body = {
            username: ""
        }
    */
    const url = "/staffs/get";
    return AxiosClient.get(url, { params: body });
}

export const deleteStaffByListId = (body) => {
    /*
        body = {
            usernames: [
                {username: "", faceid: ""}, (neu như không phải cư dân)
                {username: ""} (nếu như là cư dân)
            ]
        }
    */
    const url = "/staffs/delete";
    return AxiosClient.post(url, body);
}

export const staffsExist = (body) => {
    /*
        body = {
            username: "",   ||
            phone: ""
        }
    */
    const url = "staffs/exists";
    return AxiosClient.post(url, body);
}