import React, { useEffect, useState, useRef } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import ResidenceInfo from './ResidenceInfo';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton/IconButton';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import { GridOverlay, DataGrid } from '@material-ui/data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import SuccessAlert from '../alert/SuccessAlert';
import ErrorAlert from '../alert/ErrorAlert';
import FormControl from '@material-ui/core/FormControl';
import { apiTempInfo } from '../../api/temp';
import { apiInfo } from '../../api/info';
import { apiDeleteusers } from '../../api/deleteuser';
import { apiUnifiedSync } from '../../api/unified';
import { apiUserupdate } from '../../api/userupdate';
import LargeTooltip from '../largeTooltip';

const useStyles = makeStyles(theme => ({
  fontWeight400: {
    fontWeight: '400'
  },
  fontWeight600: {
    fontWeight: '600'
  },
  font: {
    fontSize: '16px',
    lineHeight: '22.4px',
    color: '#6D6D6D'
  },
  status: {
    color: '#6D6D6D',
    paddingBottom: '0px'
  },
  statusActive: {
    color: '#2AB930',
    paddingBottom: '0px'
  },
  statusDeActived: {
    color: '#e01010',
    paddingBottom: '0px'
  },
  primaryTitle: theme.primary_title,
  createBtn: theme.create_button,
  dialogTitleBackground: theme.primaryColor,
  cancelBtn: theme.cancel_button,
  topBlock: {
    marginTop: '22px',
    marginBottom: '20px',
    height: '40px',
    width: '100%'
  },
  mobileTopBlock: {
    marginTop: '22px',
    marginBottom: '20px',
    width: '100%',
    height: '100px'
  },
  selectTitle: {
    fontSize: '18px',
    lineHeight: '22.4px',
    float: 'left',
    marginTop: '29px',
    padding: '0 16px'
  },
  dialogTitle: {
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '25px',
    color: '#FFFFFF',
  },
  contentDialog: {
    fontSize: '16px!important',
    height: '68px',
    borderBottom: '1px solid #EEEDF2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  header: {
    width: '100%',
    height: '56px',
    background: '#FFFFFF',
    borderBottom: '1px solid #E0E0E0',
    position: 'relative',
    borderRadius: '6px 6px 0 0'
  },
  table: {
    width: '100%',
    height: '580px',
    background: '#FFFFFF',
    paddingBottom: '55px',
    borderRadius: '6px',
    position: 'relative'
  },
  root: theme.dataGridStyle,
  requestError: {
    width: '100%',
    height: '65vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
    color: '#6D6D6D'
  },
  selectInput: {
    '& .MuiInput-underline:before': {
      display: 'none'
    },
    '& .MuiInput-underline:after': {
      display: 'none'
    }
  },
  action: {
    '& .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded': {
      boxShadow: '0px 5px 5px -3px '
    }
  },
  inputRightBlock: {
    height: '80px',
    width: '16%',
    position: 'relative',
    float: 'left'
  },
  label: {
    paddingBottom: '0px',
    paddingLeft: '5%',
    paddingTop: '12px',
    fontSize: '14px',
    lineHeight: '140%',
    color: '#000000',
    display: 'inline-block',
    margin: '0'
  },
  inputHeight: {
    textAlign: 'left',
    height: '80px',
    margin: '10px 0',
    position: 'relative',
    '& .MuiOutlinedInput-root': {
      height: '32px'
    }
  },
  focus: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.themeColor,
      },
    },
  },
  primaryTextColor: theme.primaryTextColor,
  placeholder: {
    color: '#B2B2B2',
    fontSize: '16px'
  },
  select: {
    '& .MuiOutlinedInput-input': {
      padding: '8px 12px'
    }
  },
}))

const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);
const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);
const DeleteButton = withStyles(theme => ({ root: theme.delete_button }))(Button);

const ResidencesTable = () => {
  const classes = useStyles();
  const [residences, setResidences] = useState({});
  const [loadPage, setLoadPage] = useState({
    table: false,
    page: false
  });
  const [detail, setDetail] = useState(false);
  const [username, setUsername] = useState('');
  const [usernameArr, setUsernameArr] = useState([]);
  const [avatars, setAvatars] = useState([]);
  const [message, setMessage] = useState({
    success: '',
    error: '',
    request: '',
    deactivate: ''
  });
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowsTotal, setRowsTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageTotal, setPageTotal] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [filter, setFilter] = useState({
    username: '',
    block: '',
    floor: '',
    room: '',
    status: ''
  });
  const [isExpiredSession, setIsExpiredSession] = useState(false);
  const [isSyncAllUsers, setIsSyncAllUsers] = useState(false);
  const [isDeActivated, setIsDeActivated] = useState(false);
  const [deActivedUsername, setDeActivatedUsername] = useState('');
  const [isStatusChanging, setIsStatusChanging] = useState(false);
  const [activatedUser, setActivatedUser] = useState(false);
  const [isActivated, setIsActivated] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const syncAllState = useSelector(state => state.syncAllState);
  const dispatch = useDispatch();
  const history = useHistory();
  const searchRef = useRef();
  const actionRef = useRef();
  const alertRef = useRef();
  const [isAction, setIsAction] = useState('');
  const [isSingleDelete, setIsSingleDelete] = useState(false);
  const [selectedUsername, setSelectedUsername] = useState('');
  const [selectedFile, setSelectedFile] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [searchString, setSearchString] = useState('');
  const { t } = useTranslation();
  const [approvalCount, setApprovalCount] = useState(0);

  const columns = [
    {
      headerName: t("Họ và tên"),
      field: 'FullName',
      flex: 1,
      cellClassName: 'super-app-theme--cell',
      renderCell: params => <LargeTooltip title={params.row.FullName} placement="bottom-start">
        <p style={{ cursor: 'pointer', textOverflow: 'ellipsis', overflow: 'hidden', textDecoration: 'underline', color: '#0b59c8' }} onClick={() => onDetailClick(params.row.Username)}>{params.row.FullName}</p>
      </LargeTooltip>
    },
    {
      headerName: t("Tên đăng nhập"),
      field: 'Username',
      flex: 1,
      renderCell: params => <LargeTooltip
        title={params.row.Username} placement="bottom-start"><p style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{params.row.Username.indexOf('-') !== -1 ? params.row.Username.slice(0, params.row.Username.indexOf('-')) : params.row.Username}</p>
      </LargeTooltip>,
      cellClassName: 'super-app-theme--cell',
      sortComparator: (v1, v2, param1, param2) => sortNumber(v1, v2)
    },
    {
      headerName: t("Số điện thoại"),
      field: 'Phone',
      width: 170,
      cellClassName: 'super-app-theme--cell',
      renderCell: params => (typeof (params.row.Phone) === 'undefined' || params.row.Phone === '') ? '' : `(${params.row.Phone.slice(0, 3)}) ${params.row.Phone.slice(3, 6)} ${params.row.Phone.slice(6)}`
    },
    {
      headerName: t("Phòng"),
      field: 'ResidentRole',
      flex: 1,
      cellClassName: 'super-app-theme--cell',
      renderCell: params =>
        <LargeTooltip title={<div>{showResidentRole(params.row, true)}</div>}>{showResidentRole(params.row, false)}</LargeTooltip>
    },
    {
      headerName: 'Email',
      field: 'Email',
      flex: 1,
      cellClassName: 'super-app-theme--cell',
      renderCell: params => <LargeTooltip title={typeof (params.row.Email) === 'undefined' ? '' : params.row.Email} placement="bottom-start"><p style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{params.row.Email}</p></LargeTooltip>
    },
    {
      headerName: t("Trạng thái"),
      field: 'ResidenceStatus',
      width: 185,
      sortComparator: (v1, v2, params1, params2) => sortStatus(v1, v2),
      renderCell: params => showResidentStatus(params.row.ResidenceStatus),
    },
    {
      headerName: t("Đồng bộ dữ liệu"),
      field: 'SyncStatus',
      width: 175,
      renderCell: params => showSyncStatus(params.row.SyncStatus)
    },
    {
      headerName: t("Thao tác"),
      field: 'Action',
      width: 100,
      sortable: false,
      renderCell: params =>
        <div ref={actionRef} style={{ position: 'relative' }}>
          <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => {
            setAnchorEl(e.target);
            setIsAction(params.row.Username);
          }}
            style={{ fontSize: '24px', fontWeight: '900', color: '#0b59c8' }} >
            ...
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={params.row.Username === isAction && Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem style={{ color: '#0b59c8' }} onClick={() => onDetailClick(params.row.Username)}>{t("Xem chi tiết")}</MenuItem>
            {(params.row.ResidenceStatus === 'inactive' || params.row.ResidenceStatus === 'active') && <MenuItem style={{ color: '#ED9526' }} onClick={() => onDeActivateClick(params.row.Username)}>{t("Vô hiệu hóa")}</MenuItem>}
            {params.row.ResidenceStatus === 'deactivated' && <MenuItem style={{ color: '#2AB930' }} onClick={() => onActivateClick(params.row.Username)}>{t("Kích hoạt")}</MenuItem>}
            {params.row.ResidenceStatus === 'deactivated' &&
              <MenuItem
                style={{ color: '#e01010' }}
                onClick={() => {
                  setSelectedFile(typeof (params.row.Avatar) === 'undefined' ? '' : params.row.Avatar);
                  setSelectedUsername(params.row.Username);
                  setConfirmDialog(true);
                  setIsSingleDelete(true);
                  setAnchorEl(null);
                }}
              >
                {t("Xóa")}
              </MenuItem>}
          </Menu>
        </div>
    }
  ];

  const handleClose = () => {
    setAnchorEl(null);
  }

  useEffect(() => {
    if (isExpiredSession) {
      history.push('/login');
      dispatch({ type: 'IS_LOGIN_AGAIN_TRUE' });
    }
  }, [isExpiredSession]);

  const sortNumber = (v1, v2) => {
    return v1.localeCompare(v2, undefined, {
      numeric: true,
      sensitivity: 'base'
    });
  }

  const sortStatus = (v1, v2) => {
    if (v1 === 'active' && v2 === 'inactive') return 1;
    if (v1 === 'inactive' && v2 === 'active') return -1;
    if (v1 === 'active' && v2 === 'deactivated') return -1;
    if (v1 === 'deactivated' && v2 === 'active') return 1;
    if (v1 === 'inactive' && v2 === 'deactivated') return -1;
    if (v1 === 'deactivated' && v2 === 'inactive') return 1;
    return 0;
  }

  const showResidentStatus = (status) => {
    switch (status) {
      case 'inactive':
        return (<div className={clsx(classes.status, classes.font)}>
          <FiberManualRecordIcon style={{ position: 'relative', top: '-2px' }} />
          {t("Chưa đổi mật khẩu")}
        </div>)
      case 'active':
        return (<div className={clsx(classes.statusActive, classes.font)}>
          <FiberManualRecordIcon style={{ position: 'relative', top: '-3px', color: '#2AB930' }} />
          {t("Đã đổi mật khẩu")}
        </div>)
      case 'deactivated':
        return (<div className={clsx(classes.statusDeActived, classes.font)}>
          <FiberManualRecordIcon style={{ position: 'relative', top: '-3px', color: '#e01010' }} />
          {t("Đã vô hiệu")}
        </div>)
      default: return '';
    }
  }

  const showResidentRole = (data, showAll) => {
    var residentRole = '';
    if (data.Apartments)
      if (showAll)
        return data.Apartments.map((value, key) => {
          return <p key={key}>{value.block + value.floor + value.room} - {value.role === 'member' ? t('Người trong gia đình') : t('Chủ hộ')}</p>
        })
      else {
        if (data.Apartments.length > 1)
          return <p style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}><b>{data.Apartments[0].block + data.Apartments[0].floor + data.Apartments[0].room}</b> - {data.Apartments[0].role === 'member' ? t('Người trong gia đình') : t('Chủ hộ')}...</p>
        else
          return <p style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}><b>{data.Apartments[0].block + data.Apartments[0].floor + data.Apartments[0].room}</b> - {data.Apartments[0].role === 'member' ? t('Người trong gia đình') : t('Chủ hộ')}</p>
      }
    else
      return <p style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}><b>{data.BlockNumber + data.Floor + data.Room}</b> - {data.ResidentRole === 'member' ? t('Người trong gia đình') : t('Chủ hộ')}</p>
  }

  const showSyncStatus = (status) => {
    switch (status) {
      case false:
        return (<div className={clsx(classes.status, classes.font)}>
          <FiberManualRecordIcon style={{ position: 'relative', top: '-2px' }} />
          {t("Chưa đồng bộ")}
        </div>)
      case true:
        return (<div className={clsx(classes.statusActive, classes.font)}>
          <FiberManualRecordIcon style={{ position: 'relative', top: '-2px', color: '#2AB930' }} />
          {t("Đã đồng bộ")}
        </div>)
      default: return '';
    }
  }

  const showApartment = (data) => {
    var apartments = '';
    if (data.Apartments) {
      data.Apartments.forEach(value => {
        apartments += value.block + value.floor + value.room + ' ';
      })
    } else {
      apartments = data.RoomInfo
    }
    return apartments;
  }

  useEffect(() => {
    const getRegistrations = async () => {
      try {
        const res = await apiTempInfo({});
        setApprovalCount(res.data.Count);
      } catch (error) {
        if (error.response && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
      }
    }
    getRegistrations();
  }, [])

  const getDefaultValues = async () => {
    try {
      const end = currentPage * rowsPerPage;
      const start = end - rowsPerPage;
      const filterData = { ...filter };
      if (filterData.username === '') delete filterData.username;
      if (filterData.block === '') delete filterData.block;
      if (filterData.floor === '') delete filterData.floor;
      if (filterData.room === '') delete filterData.room;
      if (filterData.search === '') delete filterData.search;
      if (filterData.status === '') delete filterData.status;
      const query = {
        start_index: start,
        end_index: end,
        filter: filterData
      };
      var res = await apiInfo(query);
      setRowsTotal(res.data.Count);
      if (res.data.Count > rowsPerPage && res.data.Count % rowsPerPage === 0) setPageTotal(res.data.Count / rowsPerPage);
      else if (res.data.Count > rowsPerPage && res.data.Count % rowsPerPage !== 0) setPageTotal(parseInt(res.data.Count / rowsPerPage) + 1);
      else setPageTotal(1);
      var data = [];
      res.data.Items.forEach(item => {
        data.push({ ...item, id: item.Username });
      })
      setResidences(data);
      setLoadPage({ page: true, table: true });
      setIsSingleDelete(false);
      setDeActivatedUsername('');
      setUsernameArr([]);
      setAvatars([]);
      setMessage({ ...message, request: '' });
    } catch (error) {
      if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
      setMessage({ ...message, request: t("Không lấy được dữ liệu") });
    }
  }

  useEffect(() => {
    if (!loadPage.page || !loadPage.table) getDefaultValues();
  }, [currentPage, loadPage.page, loadPage.table])

  useEffect(() => {
    setCurrentPage(1);
    setLoadPage({ ...loadPage, table: false });
  }, [rowsPerPage, filter.search, filter.phone])

  const onSelectedChange = (e) => {
    const values = e;
    var usernames = [];
    const files = [];
    values.forEach(item => {
      usernames.push(item);
      const res = residences.filter(value => value.Username === item);
      if (res[0].Avatar !== '' && typeof (res[0].Avatar) !== 'undefined') files.push(res[0].Avatar);
      if (res[0].FaceID !== '' && typeof (res[0].FaceID) !== 'undefined') files.push(res[0].FaceID);
    })
    setUsernameArr(usernames);
    setAvatars(files);
  }

  const onDetailClick = (uname) => {
    setAnchorEl(null);
    setUsername(uname);
    setDetail(true);
  }

  const onDeleteClick = () => {
    setConfirmDialog(!confirmDialog);
  }

  const onConfirmDeleteClick = async () => {
    try {
      setIsDeleting(true);
      var data;
      if (!isSingleDelete) {
        data = { usernames: usernameArr, files: avatars };
      } else {
        const usernames = [];
        const files = [];
        usernames.push(selectedUsername);
        files.push(selectedFile);
        data = { usernames: usernames, files: files }
      }
      await apiDeleteusers(data);
      if (!isSingleDelete) setMessage({ ...message, success: `${t("Xóa thành công")} ${usernameArr.length} ${usernameArr.length > 1 ? t("nhiều cư dân") : t("cư dân")}!`, error: '' });
      else setMessage({ ...message, success: t("Xóa thành công 1 cư dân!"), error: '' });
      setUsernameArr([]);
      setLoadPage({ ...loadPage, page: false });
    } catch (error) {
      if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
      setMessage({ ...message, success: '', error: t("Xóa không thành công, vui lòng thử lại!") });
    }
    setIsDeleting(false);
    setConfirmDialog(!confirmDialog);
  }

  const onBackClick = () => {
    setDetail(!detail);
    setLoadPage({ ...loadPage, table: false });
  }

  useEffect(() => {
    if (message.success !== '' || message.error !== '') {
      if (alertRef.current) clearTimeout(alertRef.current);
      alertRef.current = setTimeout(() => {
        onCloseDialog()
      }, 5000);

    }
  }, [message])

  const onCloseDialog = () => {
    setMessage({ ...message, success: '', error: '' });
  }

  const onSearchChange = (e) => {
    var value = e.target.value;
    setSearchString(value);
    if (searchRef.current) clearTimeout(searchRef.current);
    searchRef.current = setTimeout(() => {
      setFilter({ ...filter, search: value });
    }, 500)
  }

  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFilter({ ...filter, [name]: value });
  }

  const onSyncAllUsersClick = () => {
    setIsSyncAllUsers(true);
  }

  const onConfirmSyncAllUsersClick = async () => {
    try {
      dispatch({ type: 'GET_STATUS_SYNC_ALL' });
      setIsSyncAllUsers(false);
      await apiUnifiedSync({});
      dispatch({ type: 'UPDATE_SUCCESS_MESSAGE', payload: t("Đồng bộ dữ liệu cư dân thành công!") });
    } catch (error) {
      if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
      dispatch({ type: 'UPDATE_FAILED_MESSAGE', payload: t("Đồng bộ dữ liệu cư dân không thành công!") });
    }
    dispatch({ type: 'GET_STATUS_SYNC_ALL' });
  }

  useEffect(() => {
    if (syncAllState.failedMessage !== '' || syncAllState.successMessage !== '') {
      setLoadPage({ ...loadPage, table: false });
    }
  }, [syncAllState])

  const onActivateClick = (uname) => {
    setAnchorEl(null);
    setActivatedUser(uname);
    setIsActivated(true);
  }

  const onConfirmActivateClick = async () => {
    setIsStatusChanging(true);
    try {
      const data = {
        username: activatedUser,
        status: 'inactive'
      }
      await apiUserupdate(data);
      setMessage({ ...message, success: `${t("Kích hoạt thành công tài khoản")} ${activatedUser}!`, error: `` })
    } catch (error) {
      switch (error.response ?.status) {
        case 477: setMessage({ ...message, success: '', error: `${t("Hình ảnh không có")}!` });
          break;
        case 480: setMessage({ ...message, success: '', error: `${t("Hình ảnh không hợp lệ")}!` });
          break;
        case 481: setMessage({ ...message, success: '', error: `${t("Không thể phát hiện khuôn mặt")}!` });
          break;
        case 482: setMessage({ ...message, success: '', error: `${t("Hình ảnh mờ")}!` });
          break;
        case 483: setMessage({ ...message, success: '', error: `${t("Khuôn mặt đã bị trùng")}!` });
          break;
        case 484: setMessage({ ...message, success: '', error: `${t("Nhiều khuôn mặt trong một hình")}!` });
          break;
        case 486: setMessage({ ...message, success: '', error: `${t("Khuôn mặt không đủ lớn")}!` });
          break;
        case 487: setMessage({ ...message, success: '', error: `${t("Khuôn mặt không hợp lệ")}!` });
          break;
        default: setMessage({ ...message, success: '', error: `${t("Kích hoạt không thành công tài khoản")} ${activatedUser}!` });
          break;
      }
      if (error.response ?.data === "Invalid LoginToken") return setIsExpiredSession(true);
    }
    setIsActivated(false);
    setIsStatusChanging(false);
    setLoadPage({ ...loadPage, table: false });
  }

  const onDeActivateClick = (uname) => {
    setAnchorEl(null);
    setDeActivatedUsername(uname);
    setMessage({ ...message, deactivate: 'confirm' });
    setIsDeActivated(true);
  }

  const onConfirmDeActivateClick = async () => {
    setIsStatusChanging(true);
    try {
      var data = {};
      if (message.deactivate === 'force')
        data = { username: deActivedUsername, status: 'deactivated', confirm: true };
      else
        data = { username: deActivedUsername, status: 'deactivated' };
      await apiUserupdate(data);
      setMessage({ ...message, success: `${t("Vô hiệu hóa thành công tài khoản")} ${deActivedUsername}!`, error: `` });
      setIsDeActivated(false);
      setLoadPage({ ...loadPage, table: false });
    } catch (error) {
      if (error.response ?.data === "Invalid LoginToken") return setIsExpiredSession(true);
      if (error.response ?.status === 465) {
        setMessage({ ...message, deactivate: 'force' });
        setIsStatusChanging(false);
        return;
      }
      setMessage({ ...message, success: '', error: `${t("Vô hiệu hóa không thành công tài khoản")} ${deActivedUsername}!` })
      setIsDeActivated(false);
    }
    setIsStatusChanging(false);
  }

  const deActivateDialog = () => {
    return (
      <Dialog
        open={isDeActivated}
        maxWidth="xl"
      >
        <DialogTitle className={classes.dialogTitleBackground}>
          <p className={classes.dialogTitle}>{t("Thông báo")}</p>
        </DialogTitle>
        <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center', position: 'relative' }}>
          <div className={classes.contentDialog}>
            {message.deactivate === 'confirm' && <p >{t("Bạn có chắc chắn muốn vô hiệu hóa tài khoản")} <br /> <b> {deActivedUsername} ? </b></p>}
            {message.deactivate === 'force' && <p >{t("Không tìm thấy Face ID trong cơ sở dữ liêu. Bạn có muốn tiếp tục vô hiệu tài khoản này?")}</p>}
          </div>
          <CancelButton
            style={{ margin: '10px 0' }}
            onClick={() => { setIsDeActivated(!isDeActivated) }}
            disabled={isStatusChanging}
          >
            {t("Trở về")}
          </CancelButton>
          <CreateButton
            style={{ margin: '10px 0 10px 24px' }}
            onClick={onConfirmDeActivateClick}
            disabled={isStatusChanging}
          >
            {t("Đồng ý")}
          </CreateButton>
          {isStatusChanging && <CircularProgress color="primary" style={{ position: 'absolute', left: '45%', top: '10%', color: '#0b59c8' }} />}
        </DialogContent>
      </Dialog>
    )
  }

  const activateDialog = () => {
    return (
      <Dialog
        open={isActivated}
        maxWidth="xl"
      >
        <DialogTitle className={classes.dialogTitleBackground}>
          <p className={classes.dialogTitle}>{t("Thông báo")}</p>
        </DialogTitle>
        <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center', position: 'relative' }}>
          <div className={classes.contentDialog}>
            <p>{t("Bạn có chắc chắn muốn kích hoạt lại tài khoản")}<br /> <b> {activatedUser} ? </b></p>
          </div>
          <CancelButton
            style={{ margin: '10px 0' }}
            onClick={() => { setIsActivated(!isActivated) }}
            disabled={isStatusChanging}
          >
            {t("Trở về")}
          </CancelButton>
          <CreateButton
            style={{ margin: '10px 0 10px 24px' }}
            onClick={onConfirmActivateClick}
            disabled={isStatusChanging}
          >
            {t("Đồng ý")}
          </CreateButton>
          {isStatusChanging && <CircularProgress color="primary" style={{ position: 'absolute', left: '45%', top: '10%', color: '#0b59c8' }} />}
        </DialogContent>
      </Dialog>
    )
  }

  const syncAllUsersDialog = () => {
    return (
      <Dialog
        open={isSyncAllUsers}
        maxWidth="xl"
      >
        <DialogTitle className={classes.dialogTitleBackground}>
          <p className={classes.dialogTitle}>{t("Thông báo")}</p>
        </DialogTitle>
        <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center', position: 'relative' }}>
          <p className={classes.contentDialog}>{t("Bạn có chắc chắn muốn đồng bộ dữ liệu?")}</p>
          <CancelButton
            style={{ margin: '10px 0' }}
            onClick={() => { setIsSyncAllUsers(!isSyncAllUsers) }}
          >
            {t("Trở về")}
          </CancelButton>
          <CreateButton
            style={{ margin: '10px 0 10px 24px' }}
            onClick={onConfirmSyncAllUsersClick}
          >
            {t("Đồng ý")}
          </CreateButton>
        </DialogContent>
      </Dialog>
    )
  }

  const dialogDelete = () => {
    return (
      <Dialog
        open={confirmDialog}
        maxWidth="xl"
      >
        <DialogTitle className={classes.dialogTitleBackground}>
          <p className={classes.dialogTitle}>{t("Thông báo")}</p>
        </DialogTitle>
        <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center', position: 'relative' }}>
          <div className={classes.contentDialog}>
            <p >{t("Bạn có chắc chắn muốn xóa cư dân?")}</p>
          </div>
          <CancelButton
            style={{ margin: '10px 0' }}
            onClick={() => {
              setConfirmDialog(!confirmDialog);
              setTimeout(() => {
                setIsSingleDelete(false);
              }, 500);
            }}
            disabled={isDeleting}
          >
            {t("Trở về")}
          </CancelButton>
          <CreateButton
            style={{ margin: '10px 0 10px 24px' }}
            onClick={onConfirmDeleteClick}
            disabled={isDeleting}
          >
            {t("Đồng ý")}
          </CreateButton>
          {isDeleting && <CircularProgress color="primary" style={{ position: 'absolute', left: '45%', top: '10%', color: '#0b59c8' }} />}
        </DialogContent>
      </Dialog>
    )
  }

  const EmptyRowsView = () => {
    return (
      <div
        style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <h3 style={{ color: '#6D6D6D', fontSize: '16px' }}>
          {t("Không tìm thấy cư dân")}
        </h3>
      </div>
    );
  };

  const customLoading = () => {
    return (
      <GridOverlay>
        <div style={{ position: 'absolute', top: 0, width: '100%' }}>
          <LinearProgress />
        </div>
      </GridOverlay>
    )
  }

  return (
    <div style={{ width: '100%', minWidth: '1080px', paddingBottom: '1%' }} className={classes.focus}>
      {dialogDelete()}
      {syncAllUsersDialog()}
      {deActivateDialog()}
      {activateDialog()}
      <div style={{ width: '98%', height: '80%', margin: 'auto', position: 'relative' }}>
        {!detail && <div className={classes.topBlock}>
          <p className={classes.primaryTitle}>{t("Quản trị cư dân")}</p>
          {loadPage.page && message.request === '' &&
            <div>
              <TextField
                placeholder={t("Tìm kiếm")}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  style: { background: '#FFFFFF', height: '40px', width: '320px' }
                }}
                value={searchString}
                onChange={onSearchChange}
              />

              <CreateButton variant='contained' component={Link} to="/admin/add-new-residences" style={{ float: 'right' }}>{t("Nhập từ file")}</CreateButton>
              <CreateButton variant='contained' onClick={onSyncAllUsersClick} style={{ float: 'right' }}>{t("Đồng bộ")}</CreateButton>
              <CreateButton
                variant='contained'
                component={Link}
                to="/admin/register-overview"
                style={{ float: 'right' }}
              >
                {t("Chờ duyệt")}
                {approvalCount !== 0 && <p style={{ position: 'absolute', top: '-20px', right: '-10px', color: '#FFFFFF', fontSize: '12px', background: '#e01010', borderRadius: '50%', width: '34px', height: '34px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >{approvalCount}</p>}
              </CreateButton>
            </div>}
        </div>}
        {!loadPage.page && message.request === '' && <CircularProgress color="primary" style={{ marginLeft: '50%', color: '#0b59c8' }} />}
        {!detail && message.request !== '' && <div className={classes.requestError}>{message.request}</div>}
        {!detail && loadPage.page && message.request === '' &&
          <div style={{ textAlign: 'right', marginBottom: '10px' }}>
            {isFilter &&
              <Paper className={clsx(classes.inputHeight)}>
                <div className={classes.inputRightBlock}>
                  <label className={classes.label} style={{ marginBottom: '0' }}>{t("Tên đăng nhập")}</label><br />
                  <TextField
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    style={{ background: '#FFFFFF', width: '90%', marginLeft: '5%' }}
                    variant="outlined"
                    placeholder={t("Tên đăng nhập")}
                    size="small"
                    name="username"
                    value={filter.username ? filter.username : ''}
                    onChange={onChange}
                  />
                </div>
                <div className={classes.inputRightBlock}>
                  <label className={classes.label} style={{ marginBottom: '0' }}>Block</label><br />
                  <TextField
                    style={{ background: '#FFFFFF', width: '90%', marginLeft: '5%' }}
                    variant="outlined"
                    placeholder="Block"
                    size="small"
                    name="block"
                    value={filter.block ? filter.block : ''}
                    onChange={onChange}
                  />
                </div>
                <div className={classes.inputRightBlock}>
                  <label className={classes.label} style={{ marginBottom: '0' }}>{t("Tầng")}</label> <br />
                  <TextField
                    style={{ background: '#FFFFFF', width: '90%', marginLeft: '5%' }}
                    variant="outlined"
                    placeholder={t("Tầng")}
                    size="small"
                    name="floor"
                    value={filter.floor ? filter.floor : ''}
                    onChange={onChange}
                  />
                </div>
                <div className={classes.inputRightBlock}>
                  <label className={classes.label} style={{ marginBottom: '0' }}>{t("Phòng")}</label><br />
                  <TextField
                    style={{ background: '#FFFFFF', width: '90%', marginLeft: '5%' }}
                    variant="outlined"
                    placeholder={t("Phòng")}
                    size="small"
                    name="room"
                    value={filter.room ? filter.room : ''}
                    onChange={onChange}
                  />
                </div>
                <div className={clsx(classes.select, classes.inputRightBlock)}>
                  <label className={classes.label} style={{ marginBottom: '0' }}>{t("Trạng thái")}</label><br />
                  <FormControl variant="outlined" size="small" style={{ background: '#FFFFFF', width: '90%', marginLeft: '5%' }}>
                    <Select
                      value={filter.status ? filter.status : ''}
                      displayEmpty
                      name="status"
                      onChange={onChange}
                    >
                      <MenuItem value='' disabled ><p className={classes.placeholder}> {t("Trạng thái")} </p> </MenuItem>
                      <MenuItem value='inactive'>{t("Chưa đổi mật khẩu")}</MenuItem>
                      <MenuItem value='active'>{t("Đã đổi mật khẩu")}</MenuItem>
                      <MenuItem value='deactivated'>{t("Đã vô hiệu")}</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className={classes.inputRightBlock} style={{ width: '20%', marginTop: '12px' }}>
                  <br />
                  <CreateButton
                    variant="contained"
                    style={{ height: '32px' }}
                    onClick={() => {
                      setLoadPage({ ...loadPage, table: false });
                      setCurrentPage(1);
                    }}

                  >
                    {t("Áp dụng")}
                  </CreateButton>
                  <CancelButton
                    variant="outlined"
                    style={{ height: '32px', marginLeft: '15px' }}
                    onClick={() => {
                      setFilter({ ...filter, block: '', floor: '', room: '', username: '', status: '' });
                    }}
                  >
                    {t("filter.Xóa")}
                  </CancelButton>
                </div>
              </Paper>}
          </div>}
        {!detail && loadPage.page && message.request === '' &&
          <div className={clsx(classes.table, classes.root)}>
            <div className={classes.header} >
              <LargeTooltip title={t("Thêm mới cư dân")} style={{ float: 'left' }}  >
                <CreateButton
                  component={Link} to="/admin/add-new-residence"
                  variant="contained"
                  style={{ float: 'left', margin: '7px 10px 0 10px' }}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  {t("Thêm")}
                </CreateButton>
              </LargeTooltip>
              {loadPage.page && !detail &&
                <LargeTooltip title={t("Xóa cư dân")} style={{ float: 'left' }} >
                  <span>
                    <DeleteButton
                      variant="outlined"
                      style={{ marginTop: '7px' }}
                      startIcon={<DeleteOutlineOutlinedIcon />}
                      onClick={onDeleteClick}
                      disabled={usernameArr.length === 0}
                    >
                      {t("Xóa")}
                    </DeleteButton>
                  </span>
                </LargeTooltip>
              }
              <CancelButton
                style={{ float: 'right', margin: '7px' }}
                variant="outlined"
                startIcon={<FilterListIcon />}
                onClick={() => setIsFilter(!isFilter)}
              >
                {t("Bộ lọc")}
              </CancelButton>
              <SuccessAlert message={message.success} onCloseDialog={onCloseDialog} />
              <ErrorAlert message={message.error} onCloseDialog={onCloseDialog} />
            </div>
            <DataGrid
              localeText={{
                footerRowSelected: (count) => count + t(" hàng trong bảng đã được chọn"),
                columnHeaderSortIconLabel: t('Sắp xếp')
              }}
              columns={columns}
              rows={residences}
              pageSize={rowsPerPage}
              rowsPerPageOptions={[10, 50, 100]}
              onPageSizeChange={(pageSize) => setRowsPerPage(pageSize)}
              checkboxSelection
              isRowSelectable={(params) => params.row.ResidenceStatus === 'deactivated'}
              onSelectionModelChange={onSelectedChange}
              hideFooterRowCount
              rowHeight={41}
              components={{
                NoRowsOverlay: EmptyRowsView,
                LoadingOverlay: customLoading,
              }}
              disableSelectionOnClick
              disableColumnMenu={true}
              hideFooterPagination
              loading={!loadPage.table}
              disableColumnReorder={true}
            />
            <div style={{ position: 'absolute', bottom: '3px', right: '5px', display: 'flex' }} className={classes.selectInput}>
              <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0 20px' }} >{t("Số dòng mỗi trang")}</p>
              <Select
                value={rowsPerPage}
                onChange={(e) => { setRowsPerPage(e.target.value) }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
              <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0 20px' }} >
                {rowsTotal === 0 ? 0 : (currentPage * rowsPerPage) - rowsPerPage + 1}-{currentPage * rowsPerPage > rowsTotal ? rowsTotal : currentPage * rowsPerPage} {t("trong số")} {rowsTotal}
              </p>
              <IconButton
                disabled={currentPage === 1}
                onClick={() => {
                  setCurrentPage(currentPage - 1);
                  setLoadPage({ ...loadPage, table: false });
                }}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              <IconButton
                disabled={currentPage === pageTotal}
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                  setLoadPage({ ...loadPage, table: false });
                }}
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            </div>
          </div>
        }
        {
          detail &&
          <ResidenceInfo username={username} onBackClick={() => onBackClick()} title={true} />
        }
      </div>
      <div style={{ height: '15px' }}></div>
    </div>
  )
};

export default ResidencesTable;