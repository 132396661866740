import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import clsx from "clsx";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import LargeTooltip from "../../../../components/largeTooltip";
import { useStyles } from "../styles";

export default function LayoutSideBar({ children, isBtnHidden }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [show, setShow] = useState(true);

  const onClickCloseMenu = () => {
    setShow(!show);
  };
  return (
    <>
      <div className={clsx(classes.layoutSideBar, !show && classes.hide)}>
        <div className={classes.contentSideBar}>{show && children}</div>
        <div className={classes.btnShowHidden}>
          {!isBtnHidden && (
            <div className={classes.btnExtendMenu} onClick={onClickCloseMenu}>
              <LargeTooltip
                title={!show ? t("Hiện danh mục") : t("Ẩn danh mục")}
              >
                <ArrowBackIosNewOutlinedIcon
                  className={!show && classes.rotateIcon}
                />
              </LargeTooltip>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
