const GET_STATUS_PUBLISHING = 'GET_STATUS_PUBLISHING'
const GET_FAILED_MESSAGE = 'GET_FAILED_MESSAGE'
const GET_SUCCESS_MESSAGE = 'GET_SUCCESS_MESSAGE'

const init = {
    isPublishing:false,
    failedMessage:'',
    successMessage:'',
}

const NotificationReducer = (state = init, action)=>{
    switch (action.type) {
        case GET_STATUS_PUBLISHING:
            return {...state,isPublishing:!state.isPublishing};
        case GET_FAILED_MESSAGE:
            return {...state,failedMessage:action.payload};
        case GET_SUCCESS_MESSAGE:
            return {...state,successMessage:action.payload};
        default:
            return state;
    }
}

export default NotificationReducer;