import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme)=>({
    pTag:{paddingBottom:'10px',fontSize:'18px',lineHeight:'1.7',textAlign: 'justify'},
    h2Header:{fontWeight:'700',margin:'20px 0 10px 0'},
    h1Header:{fontWeight:'700',margin:'25px 0 15px 0'},
}))

const Policy = props => {
    const classes = useStyles();
    return (
        <div style={{width:'60%',margin:'auto',}}>
            <h1 className={classes.h1Header} >Privacy Policy </h1>
            <p className={classes.pTag}>Last updated: May 20, 2021</p>
            <p className={classes.pTag}>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p> 
            <p className={classes.pTag}>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. </p>
            <h1 className={classes.h1Header}>Interpretation and Definitions</h1>
            <h2 className={classes.h2Header}>Interpretation</h2>
            <p className={classes.pTag}>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
            <h2 className={classes.h2Header}>Definitions</h2>
            <p className={classes.pTag}>For the purposes of this Privacy Policy: </p>
            <ul style={{paddingLeft:'5%'}}>
                <li className={classes.pTag}><b>Account</b> means a unique account created for You to access our Service or parts of our Service.</li>
                <li className={classes.pTag}><b>Application</b> means the software program provided by the Company downloaded by You on any electronic device, named T-Building</li>
                <li className={classes.pTag}><b>Company</b> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to TMA Innovation. </li>
                <li className={classes.pTag}><b>Country</b> refers to Vietnam </li>
                <li className={classes.pTag}><b>Device</b> means any device that can access the Service such as a smartphone, tablet.</li>
                <li className={classes.pTag}><b>Personal Data </b>is any information that relates to an identified or identifiable individual.</li>
                <li className={classes.pTag}><b>Service</b> refers to the Application</li>
                <li className={classes.pTag}><b>Server</b> refers to the backend infrastructure to serve the Service </li>
                <li className={classes.pTag}><b>Service Provider</b> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used. </li>
                <li className={classes.pTag}><b>Usage Data</b> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
                <li className={classes.pTag}><b>You</b> , refer to <b>User</b> means the individual accessing or using the Service, or the Company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>                
            </ul>
            <h1 className={classes.h1Header}>Collecting and Using Your Personal Data</h1>
            <h2 className={classes.h2Header}>Types of Data Collected</h2>
            <h4 className={classes.h2Header}>Personal Data</h4>
            <p className={classes.pTag}>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally, identifiable information may include, but is not limited to: </p>
            <ul style={{paddingLeft:'5%'}}>
                <li className={classes.pTag}>Email address</li>
                <li className={classes.pTag} style={{padding:'0'}}>First name and last name</li>
            </ul>
            <p className={classes.pTag}>Your contact information will be collected while your Account is created by the administrator of your building, apartment, or yourself through web console at access.smart-building.vn</p>
            <h4 className={classes.h2Header}>Photo/Video Upload and Face Data Policy</h4>
            
            <h5 className={classes.h2Header}>What face data is your app collecting?</h5>
            <p className={classes.pTag} style={{padding:'0'}}>We enable T-Building users to send one or several selfie pictures to the T-Building Service.</p>
            <h5 className={classes.h2Header}>For what purposes are you collecting this information? Please provide a complete and clear explanation of all planned uses of this data.</h5>
            <p className={classes.pTag}>Using face recognization in our smart building access solution is one option to allow users access to their living blocks and detect strangers. T-Building users can, but not required to, provide this information by uploading selfie images.</p>
            <p className={classes.pTag}>The complete process is:</p>
            <ol style={{paddingLeft:'5%'}}>
                <li className={classes.pTag}>User takes a selfie image via T-Building Application and uploads it to the server. </li>
                <li className={classes.pTag}>The server analyzes the image and creates the user's face recognition model. </li>
                <li className={classes.pTag}>The server sends the model user's T-Access devices. </li>
                <li className={classes.pTag} style={{padding:'0'}}>T-Access devices use the model to recognize the user, along with other factors, to grant some authorities in the user living area base on user’s configuration.</li>
            </ol>

            <p className={classes.pTag} style={{padding:'0'}}>T-Building users can update profile photos to build up a more personalized experience. </p>
            <h5 className={classes.h2Header}>Will the data be shared with any third parties? Where will this information be stored?</h5>
            <p className={classes.pTag}>The data will not be shared with any third parties. The selfie information is stored in private cloud servers with encryption enabled. Profile photos is stored in Amazon S3.</p>
            <h4 className={classes.h2Header}>Usage Data </h4>
            <p className={classes.pTag}>We don’t collect Usage Data. </p>
            <h2 className={classes.h2Header}>Use of Your Personal Data</h2>
            <p className={classes.pTag}>The Company may use Personal Data for the following purposes: </p>
            <ul style={{paddingLeft:'5%'}}>
                <li className={classes.pTag}><b>To provide and maintain our Service</b>, including to monitor the usage of our Service. </li>
                <li className={classes.pTag}><b>To manage Your Account: </b> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user. </li>
                <li className={classes.pTag}><b>To contact You: </b> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation. </li>
                <li className={classes.pTag} style={{padding:'0'}}><b>To manage Your requests:</b> To attend and manage Your requests to Us.</li>
            </ul>
            <p className={classes.pTag}>We may share Your personal information in the following situations:</p>
            <ul style={{paddingLeft:'5%'}}>
                <li className={classes.pTag}><b>For business transfers:</b> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</li>
                <li className={classes.pTag}><b>With Your consent:</b> We may disclose Your personal information for any other purpose with Your consent. </li>
            </ul>
            <h2 className={classes.h2Header}>Retention of Your Personal Data</h2>
            <p className={classes.pTag}>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies. </p>
            <h2 className={classes.h2Header}>Transfer of Your Personal Data</h2>
            <p className={classes.pTag}>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
            <p className={classes.pTag}>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
            <p className={classes.pTag}>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
            <h2 className={classes.h2Header}>Disclosure of Your Personal Data</h2>
            <h4 className={classes.h2Header}>Business Transactions</h4>
            <p className={classes.pTag}>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy. </p>
            <h4 className={classes.h2Header}>Law enforcement</h4>
            <p className={classes.pTag}>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency). </p>
            <h4 className={classes.h2Header}>Other legal requirements</h4>
            <p className={classes.pTag}>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to: </p>
            <ul style={{paddingLeft:'5%'}}>
                <li className={classes.pTag}>Comply with a legal obligation</li>
                <li className={classes.pTag}>Protect and defend the rights or property of the Company</li>
                <li className={classes.pTag}>Prevent or investigate possible wrongdoing in connection with the Service</li>
                <li className={classes.pTag}>Protect the personal safety of Users of the Service or the public</li>
                <li className={classes.pTag}>Protect against legal liability</li>
            </ul>
            <h2 className={classes.h2Header}>Security of Your Personal Data</h2>
            <p className={classes.pTag}>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security. </p>
            <h1 className={classes.h1Header}>Changes to this Privacy Policy</h1>
            <p className={classes.pTag}> We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
            <p className={classes.pTag}>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy. </p>
            <p className={classes.pTag}>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page. </p>
            <h1 className={classes.h1Header}>Contact Us</h1>
            <p className={classes.pTag}>If you have any questions about this Privacy Policy, You can contact us: </p>
            <ul style={{paddingLeft:'5%'}}>
                <li className={classes.pTag}>By email: contact@tmainnovation.vn</li>
                <li className={classes.pTag}>By visiting this page on our website: access.smart-building.vn </li>
            </ul>
        </div>
    )
}


export default Policy;
