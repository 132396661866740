import AxiosClient from "../config/AxiosClient";

export const apiUploadfile = async (bodyRequest) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/uploadfile';
    } else {
        url = '/fileUpload/file';
    }
    return await AxiosClient.post(url, bodyRequest);
}