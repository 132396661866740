import makeStyles from '@material-ui/styles/makeStyles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    commonDetail: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        height: "100%",
        minHeight: "calc(100vh - 208px)",
    },
    detailBooker: {
        borderRadius: "1rem",
        marginRight: "1rem",
        padding: "0 20px 20px 20px",
        width: "40%",
        background: "rgb(0, 0, 0, 0.03)",
    },
    noneBooker: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "4rem 0",

        "& p": {
            marginTop: "1rem"
        }
    },
    detailCabin: {
        borderRadius: "1rem",
        padding: "0 20px 20px 20px",
        width: "60%",
        background: "rgb(0, 0, 0, 0.03)",
        minHeight: "calc(100vh - 208px)",
    },
    detailTitle: {
        fontSize: "20px",
        fontWeight: "700",
        marginTop: "20px"
    },
    detailLabel: {
        color: "#616161",
        marginTop: "24px",
        lineHeight: "2rem"
    },
    detailInfo: {
        fontWeight: "bold"
    },
    wrapCabinInfo: {
        display: "flex"
    },
    left: {
        width: "50%"
    },
    right: {
        width: "50%"
    },

    cellStatus: {
        position: "relative",
        paddingLeft: "20px",
        fontWeight: 700,

        "&::before": {
            content: "''",
            position: "absolute",
            left: "0",
            top: "calc(50% - 4px)",

            borderRadius: "8px",
            width: "8px",
            height: "8px",
        }
    },
    emptyStatus: {
        color: "black",

        "&::before": {
            background: "black"
        }
    },
    waitDeliveryStatus: {
        color: "#EE8306",

        "&::before": {
            background: "#EE8306"
        }
    },
    waitGetItemStatus: {
        color: "#2A6FD1",

        "&::before": {
            background: "#2A6FD1"
        }
    },
    overDueStatus: {
        color: "#ED1626",

        "&::before": {
            background: "#ED1626"
        }
    },

    statusColumnpending: {
        "&::before": {
            content: "''",
            position: "absolute",
            top: "calc(50% - 4px)",
            left: "0",
            width: "8px",
            height: "8px",
            borderRadius: "8px",
            background: "#EE8306"
        }
    },
    statusColumnpaid: {
        "&::before": {
            content: "''",
            position: "absolute",
            top: "calc(50% - 4px)",
            left: "0",
            width: "8px",
            height: "8px",
            borderRadius: "8px",
            background: "#29A62B"
        }
    },
    statusColumncancelled: {
        "&::before": {
            content: "''",
            position: "absolute",
            top: "calc(50% - 4px)",
            left: "0",
            width: "8px",
            height: "8px",
            borderRadius: "8px",
            background: "#ED1626"
        }
    },
}));