import AxiosClient from "../config/AxiosClient";

export const apiReceptionistUpdate = async (requestBody) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = `/receptionist/update`;
    } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
        url = `/receptionists/update`;
    }
    return await AxiosClient.post(url, requestBody);
}

export const apiReceptionistAdd = async (requestBody) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = `/receptionist/add`;
    } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
        url = `/receptionists/create`;
    }
    return await AxiosClient.post(url, requestBody);
}

export const apiReceptionistDelete = async (requestBody) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = `/receptionist/delete`;
    } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
        url = `/receptionists/delete`;
    }
    return await AxiosClient.post(url, requestBody);
}

export const apiReceptionistGetall = async () => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = `/receptionist/getall`;
    } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
        url = `/receptionists/get`;
    }
    return await AxiosClient.get(url);
}

export const apiReceptionistGet = async (requestBody) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = `/receptionist/get`;
    } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
        url = `/receptionists/get`;
    }
    return await AxiosClient.post(url, requestBody);
}

export const apiReceptionistExist = async (requestBody) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = `/receptionist/exist`;
    } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
        url = `/receptionists/exist`;
    }
    return await AxiosClient.post(url, requestBody);
}
