import React, { useState, useEffect, useRef } from 'react';
import Tabs from '@material-ui/core/Tabs/Tabs';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'react-i18next';
import Tab from '@material-ui/core/Tab/Tab';
import makeStyles from '@material-ui/core/styles/makeStyles';
import axios from 'axios';
import IconButton from '@material-ui/core/IconButton/IconButton';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import { Link, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import RegisterOverview from './RegisterOverview';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button/Button';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ReplayIcon from '@material-ui/icons/Replay';
import { apiResidentsApprove } from '../../api/resident';
import SuccessAlert from '../alert/SuccessAlert';
import ErrorAlert from '../alert/ErrorAlert';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiGetconfig } from '../../api/getconfig';
import { NotificationConfiguration } from '../../config/enum';
import { EmptyRowsView, CustomLoading } from '../grid/index';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import moment from 'moment-timezone';
import { DataGrid } from '@material-ui/data-grid';
import { apiTempInfo, apiTempReject, apiTempDelete } from '../../api/temp';
import { checkFaceImage } from '../actionImage/index';
import { FilterIcon } from '../icons/index';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import vi from 'date-fns/locale/vi';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import CloseIcon from '@mui/icons-material/Close';
import { ShowFilterValues } from '../filter/index';
import { Confirm } from '../dialog/index';

const useStyles = makeStyles(theme => ({
    arrowBackIconStyle: {
        width: '24px',
        height: '24px',
        position: 'relative',
        top: '3px'
    },
    iconButtonStyle: {
        float: 'left',
        padding: '2px'
    },
    primaryTextColor: theme.primaryTextColor,
    primaryTitle: theme.primary_title,
    titleBlock: {
        margin: '22px 0 20px',
        height: '32px'
    },
    page: {
        minWidth: '980px',
        width: '98%',
        margin: 'auto',
        boxSizing: 'unset!important'
    },
    indicator: {
        // background: theme.primaryColor.background,
        background: '#333333',
        height: '4px',
        borderRadius: '6px 6px 0 0'
    },
    fontTab: {
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '22.4px',
        textTransform: 'none',
        color: '#8E8E8E!important'
    },
    fontTabActive: {
        // color: theme.primaryColor.background,
        color: '#333333!important',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '22.4px',
        textTransform: 'none'
    },
    actionBlock: {
        display: 'flex',
        marginBottom: '8px'
    },
    flex1: {
        flex: 1
    },
    fontWeight400: {
        fontWeight: '400'
    },
    fontWeight600: {
        fontWeight: '600'
    },
    font: {
        fontSize: '16px',
        lineHeight: '22.4px',
        color: '#6D6D6D'
    },
    status: {
        color: '#6D6D6D',
        paddingBottom: '0px'
    },
    statusActive: {
        color: '#2AB930',
        paddingBottom: '0px'
    },
    statusDeActived: {
        color: '#e01010',
        paddingBottom: '0px'
    },
    primaryTitle: theme.primary_title,
    createBtn: theme.create_button,
    dialogTitleBackground: theme.primaryColor,
    cancelBtn: theme.cancel_button,
    topBlock: {
        marginTop: '22px',
        marginBottom: '20px',
        height: '40px',
        width: '100%'
    },
    mobileTopBlock: {
        marginTop: '22px',
        marginBottom: '20px',
        width: '100%',
        height: '100px'
    },
    selectTitle: {
        fontSize: '18px',
        lineHeight: '22.4px',
        float: 'left',
        marginTop: '29px',
        padding: '0 16px'
    },
    dialogTitle: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '25px',
        color: '#FFFFFF',
        fontFamily: 'Nunito, sans-serif'
    },
    contentDialog: {
        fontSize: '16px!important',
        marginTop: '20px',
        width: '80%',
        margin: 'auto'
    },
    content: {
        fontSize: '16px',
        borderBottom: '1px solid #EEEDF2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        paddingBottom: '10px'
    },
    header: {
        width: '100%',
        height: '56px',
        background: '#FFFFFF',
        borderBottom: '1px solid #E0E0E0',
        position: 'relative',
        borderRadius: '6px 6px 0 0'
    },
    table: {
        width: '100%',
        height: '540px',
        background: '#FFFFFF',
        borderRadius: '6px',
        position: 'relative'
    },
    root: theme.dataGridStyle,
    requestError: {
        width: '100%',
        height: '65vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20px',
        color: '#6D6D6D'
    },
    selectInput: {
        '& .MuiInput-underline:before': { display: 'none' },
        '& .MuiInput-underline:after': { display: 'none' }
    },
    action: {
        '& .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded': {
            // boxShadow:'0px 5px 5px -3px rgb(0 0 0 / 20%)'
            boxShadow: '0px 5px 5px -3px '
        }
    },
    inputRightBlock: {
        height: '80px',
        width: '15%',
        position: 'relative',
        float: 'left'
    },
    label: {
        paddingBottom: '0px',
        paddingLeft: '5%',
        paddingTop: '12px',
        fontSize: '14px',
        lineHeight: '140%',
        color: '#000000',
        display: 'inline-block',
        margin: '0'
    },
    inputHeight: {
        textAlign: 'left',
        height: '80px',
        margin: '10px 0',
        position: 'relative',
        '& .MuiOutlinedInput-root': {
            height: '32px'
        }
    },
    itemFont: {
        '& .MuiMenuItem-root': {
            fontFamily: 'Nunito, sans-serif'
        }
    },
    fontNunito: {
        '& .MuiInputBase-root': theme.fontNunito
    },
    alertStyle: {
        position: 'absolute',
        right: '6px',
        bottom: '6px',
        zIndex: '10'
    },
    focus: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: theme.themeColor,
            },
        },
    },
    primaryTextColor: theme.primaryTextColor,
    checkbox: {
        '&$checked': {
            color: theme.themeColor,
        }
    },
    disabledCheckbox: {
        '&$checked': {
            color: 'rgba(0, 0, 0, 0.26)!important',
            backgroundColor: 'transparent'
        }
    },
    checked: {},
    controlLabel: {
        fontFamily: 'Nunito, sans-serif',
        color: '#000000!important'
    },
    textRowStyle: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    greenColor: {
        color: '#29CB97'
    },
    blueColor: {
        color: '#3870FF'
    },
    orangeColor: {
        color: '#FF9838'
    },
    cancelButtonStyle: {
        margin: '10px 0'
    },
    createButtonStyle: {
        margin: '10px 0 10px 24px'
    },
    approveButtonStyle: {
        float: 'left',
        margin: '0 10px 0 10px!important'
    },
    removeButtonStyle: {
        marginTop: '0!important',
        float: 'left'
    },
    refreshButtonStyle: {
        margin: '0 0px 0px 8px!important',
        float: 'left'
    },
    select: {
        '& .MuiOutlinedInput-input': {
            padding: '8px 12px'
        }
    },
    placeholder: {
        color: '#B2B2B2',
        fontSize: '16px'
    },
    rejectButtonStyle: {
        margin: '0 8px 0 0',
        float: 'left'
    },
    reasonTextStyle: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        color: '#e01010'
    },
    dialogTitleStyle: {
        padding: '0 24px',
    },
    pTitleStyle: {
        borderBottom: '1px solid #E0E0E0',
        height: '66px',
        display: 'flex',
        alignItems: 'center',
        fontWeight: '600',
        fontSize: '20px',
        lineHeight: '28px',
        color: '#333333'
    },
    buttonGroupStyle: {
        width: '98%',
        textAlign: 'right',
        paddingBottom: '10px',
        marginTop: '20px'
    },
    inputStyle: {
        background: '#FFFFFF',
        width: '95%'
    },
    inputFullWidthStyle: {
        background: '#FFFFFF',
        width: '98%'
    },
    datePickerStyle: {
        position: 'relative',
        top: '-15px',
        width: '95%'
    },
    headerTitleStyle: {
        fontSize: '22px',
        fontWeight: 'bold',
        padding: '23px 0 15px 0'
    },
    fiberManualIcon: {
        position: 'relative',
        top: '-2px'
    },
    dialogContentStyle: {
        textAlign: 'left',
        position: 'relative',
        width: '578px'
    },
    inputFullWidthBlock: {
        width: '100%',
        height: '80px',
        position: 'relative'
    },
    filterInput: {
        width: '100%',
        height: '80px'
    },
    filterSmallInput: {
        width: '33%',
        height: '80px'
    },
    displayFlex: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    filterLabel: {
        paddingBottom: '0px',
        paddingTop: '12px',
        fontSize: '14px',
        lineHeight: '140%',
        color: '#000000',
        display: 'inline-block',
        margin: '0'
    },
    radioGroupStyle: {
        marginLeft: '16px',
        height: '40px',
        paddingTop: '5px',
        position: 'relative',
        left: '-17px'
    },
    radio: {
        color: 'rgba(0, 0, 0, 0.23)!important',
        '&$checked': {
            color: theme.menuBackground + '!important'
        }
    },
    checked: {},
}))

const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);
const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);
const DeleteButton = withStyles(theme => ({ root: theme.delete_button }))(Button);
const LargeTooltip = withStyles(() => ({
    tooltip: { fontSize: '16px!important', fontFamily: 'Nunito, sans-serif' }
}))(Tooltip);

const PendingApprovalList = () => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const [tabValue, setTabValue] = useState(0);
    const [searchString, setSearchString] = useState('');
    const [loading, setLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [alert, setAlert] = useState({
        success: '',
        error: '',
        info: '',
        reason: ''
    })
    const [notification, setNotification] = useState();
    const [isNotification, setIsNotification] = useState(true);
    const [residents, setResidents] = useState([]);
    const [isApprove, setIsApprove] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rowsTotal, setRowsTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageTotal, setPageTotal] = useState(0);
    const [filter, setFilter] = useState({
        block: '',
        floor: '',
        room: '',
        register_time: null,
        registration_type: ''
    });
    const [userIDs, setUserIDs] = useState([]);
    const [avatars, setAvatars] = useState([]);
    const [residentID, setResidentID] = useState();
    const [registerTime, setRegisterTime] = useState(null);
    const [isDetail, setIsDetail] = useState(false);
    const [actionType, setActionType] = useState(false);
    const [isReject, setIsReject] = useState(false);
    const [reason, setReason] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [isFilter, setIsFilter] = useState(false);
    const [filterValues, setFilterValues] = useState({});
    const [isFiltering, setIsFiltering] = useState(false);
    const [isExpired, setIsExpiredSession] = useState(false);
    const alertRef = useRef();
    const history = useHistory();
    const searchRef = useRef();

    const columns = [
        {
            headerName: t('Loại yêu cầu'),
            field: 'RequestType',
            width: 180,
            cellClassName: 'super-app-theme--cell-payment',
            renderCell: params => showTypeOfRequest(params.row.RequestType)
        },
        {
            headerName: t("Họ và tên"),
            field: 'FullName',
            flex: 1,
            cellClassName: 'super-app-theme--cell-payment',
            renderCell: params =>
                <LargeTooltip
                    title={params.row.FullName}
                    placement="bottom-start"
                >
                    <p className={classes.textRowStyle}>{params.row.FullName}</p>
                </LargeTooltip>
        },
        {
            headerName: t("Số điện thoại"),
            field: 'Phone',
            width: 180,
            cellClassName: 'super-app-theme--cell-payment',
            renderCell: params =>
                (typeof (params.row.Phone) === 'undefined' || params.row.Phone === '') ?
                    '' : `(${params.row.Phone.slice(0, 3)}) ${params.row.Phone.slice(3, 6)} ${params.row.Phone.slice(6)}`
        },
        {
            headerName: t("Phòng"),
            field: 'ResidenceRole',
            flex: 1,
            cellClassName: 'super-app-theme--cell-payment',
            renderCell: params =>
                <LargeTooltip
                    title={
                        <div>{showResidentRole(params.row, true)}</div>
                    }
                >
                    {showResidentRole(params.row, false)}
                </LargeTooltip>
        },
        {
            headerName: 'Email',
            field: 'Email',
            flex: 1,
            cellClassName: 'super-app-theme--cell-payment',
            renderCell: params =>
                <LargeTooltip
                    title={typeof (params.row.Email) === 'undefined' ? '' : params.row.Email}
                    placement="bottom-start"
                >
                    <p style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{params.row.Email}</p>
                </LargeTooltip>
        },
        {
            headerName: t('Thời gian đăng ký'),
            field: 'CreatedTime',
            width: 200,
            cellClassName: 'super-app-theme--cell-payment',
            renderCell: params =>
                params.row.CreatedTime &&
                moment(parseInt(params.row.CreatedTime)).format('DD-MM-YYYY HH:mm:ss')
        },
        {
            field: 'ApproveStatus',
            headerName: tabValue === 0 ? t('Lỗi phê duyệt') : t('Lý do từ chối'),
            flex: 1,
            sortable: false,
            cellClassName: 'super-app-theme--cell-payment',
            renderCell: params =>
                <LargeTooltip
                    title={tabValue === 0 ? showNote(params.row.ApproveStatus) : t(params.row.Reason)} placement="bottom-start"
                >
                    <p className={classes.reasonTextStyle}>{tabValue === 0 ? showNote(params.row.ApproveStatus) : t(params.row.Reason)}</p>
                </LargeTooltip>
        },
    ];

    const showTypeOfRequest = (requestType) => {
        switch (requestType) {
            case 'Cập nhật Face ID':
                return <p className={classes.blueColor}>{t('Cập nhật Face ID')}</p>
            case 'Cập nhật thông tin':
                return <p className={classes.orangeColor}>{t('Cập nhật thông tin')}</p>
            case 'Đăng ký mới':
                return <p className={classes.greenColor}>{t('Đăng ký mới')}</p>
            default: return '';
        }
    }

    const showApartment = (data) => {
        var apartments = '';
        if (data.Apartments) {
            data.Apartments.forEach(value => {
                apartments += value.block + value.floor + value.room + ' ';
            })
        } else {
            apartments = data.RoomInfo
        }
        return apartments;
    }

    const showNote = (note) => {
        var text = '';
        if (note) {
            text = checkFaceImage(note);
        }
        return text;
    }

    const showResidentRole = (data, showAll) => {
        var residentRole = '';
        if (data.Apartments) {
            if (showAll) {
                return data.Apartments.map((value, key) => {
                    return <p key={key}>{value.block + value.floor + value.room} - {value.role === 'member' ? t('Người trong gia đình') : t('Chủ hộ')}</p>
                })
            } else {
                if (data.Apartments.length > 1) {
                    return <p className={classes.textRowStyle}><b>{data.Apartments[0].block + data.Apartments[0].floor + data.Apartments[0].room}</b> - {data.Apartments[0].role === 'member' ? t('Người trong gia đình') : t('Chủ hộ')}...</p>
                } else {
                    return <p className={classes.textRowStyle}><b>{data.Apartments[0].block + data.Apartments[0].floor + data.Apartments[0].room}</b> - {data.Apartments[0].role === 'member' ? t('Người trong gia đình') : t('Chủ hộ')}</p>
                }
            }
        } else {
            return <p className={classes.textRowStyle}><b>{data.BlockNumber + data.Floor + data.Room}</b> - {data.ResidentRole === 'member' ? t('Người trong gia đình') : t('Chủ hộ')}</p>
        }
    }

    const getNotificationConfig = async () => {
        try {
            let res = await apiGetconfig();
            const notificationConf = res.data.Items[0].Configuration ? res.data.Items[0].Configuration.notification : NotificationConfiguration.ASK;
            if (notificationConf === NotificationConfiguration.OFF) {
                setIsNotification(false);
            }
            setNotification(notificationConf);
        } catch (error) {
            if (error.response && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setNotification(NotificationConfiguration.ASK);
        }
    }

    const getDefaultValues = async () => {
        try {
            const end = (currentPage + 1) * rowsPerPage;
            const start = end - rowsPerPage;
            var query = {};
            if (registerTime === null) {
                query = {
                    start_index: start,
                    end_index: end,
                    filter: { ...filter }
                };
            } else {
                query = {
                    start_index: start,
                    end_index: end,
                    filter: {
                        ...filter,
                        register_time: registerTime
                    }
                };
            }
            if (tabValue === 0) {
                query = {
                    ...query,
                    isReject: false
                };
            } else {
                query = {
                    ...query,
                    isReject: true
                };
            }
            if (filter.registration_type === '') {
                delete query.filter.registration_type
            }
            if (filter.block === '') {
                delete query.filter.block
            }
            if (filter.floor === '') {
                delete query.filter.floor
            }
            if (filter.room === '') {
                delete query.filter.room
            }
            if (filter.search === '') {
                delete query.filter.search
            }
            if (filter.register_time === null) {
                delete query.filter.register_time
            }
            var res = await apiTempInfo(query);
            setRowsTotal(res.data.Count);
            var data = [];
            res.data.Items.forEach(item => {
                if (item.IsResident) {
                    if (item.RegistrationType && item.RegistrationType === 'faceid') {
                        data.push({
                            ...item,
                            id: item.ID,
                            RequestType: 'Cập nhật Face ID'
                        });
                    } else {
                        data.push({
                            ...item,
                            id: item.ID,
                            RequestType: 'Cập nhật thông tin'
                        });
                    }
                } else {
                    data.push({
                        ...item,
                        id: item.ID,
                        RequestType: 'Đăng ký mới'
                    });
                }
            })
            setResidents(data);
            setLoading(true);
            setUserIDs([]);
            setAvatars([]);
            setAlert({
                ...alert,
                request: ''
            });
        } catch (error) {
            if (error.response && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({
                ...alert,
                request: t("Không lấy được dữ liệu")
            });
        }
    }

    useEffect(() => {
        if (!loading) {
            getDefaultValues();
            getNotificationConfig();
        }
    }, [loading, currentPage])

    useEffect(() => {
        setCurrentPage(0);
        setLoading(false);
    }, [rowsPerPage])

    const onRefreshClick = () => {
        setLoading(false);
    }

    const onPageChange = (newPage) => {
        setCurrentPage(newPage);
        setLoading(false);
    }

    const onNotificationChange = (e) => {
        if (notification === NotificationConfiguration.ON) return;
        setIsNotification(e.target.checked);
    }

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        setLoading(false);
        setUserIDs([]);
    }

    const onSelectedChange = (e) => {
        const values = e;
        var usernames = [];
        const files = [];
        values.forEach(item => {
            usernames.push(item);
            const res = residents.filter(value => value.ID === item);
            if (res[0].FaceID !== '' && typeof (res[0].FaceID) !== 'undefined') files.push(res[0].FaceID);
        })
        setUserIDs(usernames);
        setAvatars(files);
    }

    const onRowClick = (e) => {
        if (e.row.RegistrationType !== 'faceid') {
            history.replace('/admin/register-overview/detail/' + e.id);
        } else {
            history.replace('/admin/register-overview/faceid/detail/' + e.id);
        }
        setResidentID(e.id);
        setActionType(e.row.RegistrationType);
        setIsDetail(true);
    }

    const onRejectClick = () => {
        setIsReject(true);
    }

    const onReasonChange = (e) => {
        const value = e.target.value;
        setReason(value);
        if (value.trim().length > 50) setAlert({ ...alert, reason: t('Độ dài ký tự không quá 50') });
        else setAlert({ ...alert, reason: '' });
    }

    const onRejectCancelClick = () => {
        setIsReject(false);
    }

    const onConfirmRejectClick = async () => {
        try {
            setIsProcessing(true);
            var data = { ids: userIDs, reason: reason };
            if (notification === NotificationConfiguration.ON) {
                data = {
                    ...data,
                    notification: true
                };
            } else if (notification === NotificationConfiguration.OFF) {
                data = {
                    ...data,
                    notification: false
                };
            } else {
                data = {
                    ...data,
                    notification: isNotification
                };
            }
            await apiTempReject(data);
            setAlert({ ...alert, success: `${t("Từ chối thành công")} ${userIDs.length} ${userIDs.length > 1 ? t("nhiều cư dân") : t("cư dân")}!`, error: '' });
            setLoading(false);
        } catch (error) {
            if (error.response ?.status === 401) {
                return setIsExpiredSession(true);
            }
            setAlert({ ...alert, success: '', error: t("Từ chối không thành công, vui lòng thử lại sau!") });
        }
        setIsProcessing(false);
        setIsReject(false);
    }

    const onApproveClick = () => {
        setIsApprove(true);
    }

    const onConfirmApproveClick = async () => {
        try {
            setIsProcessing(true);
            var ids = userIDs;
            var data = {};
            if (notification === NotificationConfiguration.ON) {
                data = {
                    notification: true,
                    ids: ids
                };
            } else if (notification === NotificationConfiguration.OFF) {
                data = {
                    notification: false,
                    ids: ids
                };
            } else {
                data = {
                    notification: isNotification,
                    ids: ids
                };
            }
            let res = await apiResidentsApprove(data);
            if (res.data.failedIDs.length > 0) {
                var temp = '';
                res.data.failedIDs.forEach((value) => {
                    if (value.Username && temp.indexOf(value.Username) === -1)
                        temp += `${value.Username ? value.Username : ''} `;
                })
                setAlert({
                    ...alert,
                    info: t("Duyệt thất bại") + ' ' + temp,
                    error: '',
                    success: ''
                });
            } else {
                setAlert({
                    ...alert,
                    success: t("Cư dân đã được duyệt thành công!"),
                    error: ''
                });
            }
            setLoading(false);
        } catch (error) {
            if (error.response ?.status === 401) {
                return setIsExpiredSession(true);
            }
            setAlert({
                ...alert,
                success: '',
                error: t("Duyệt không thành công, vui lòng thử lại!")
            });
        }
        setIsApprove(false);
        setIsProcessing(false);
    }

    const onDeleteClick = () => {
        setIsDelete(true);
    }

    const onConfirmDeleteClick = async () => {
        try {
            setIsProcessing(true);
            await apiTempDelete({ ids: userIDs });
            setAlert({ ...alert, success: `${t("Xóa thành công")} ${userIDs.length} ${userIDs.length > 1 ? t("nhiều cư dân") : t("cư dân")}!`, error: '' });
            setLoading(false);
        } catch (error) {
            if (error.response ?.status === 401) {
                return setIsExpiredSession(true);
            }
            setAlert({ ...alert, success: '', error: t("Xóa không thành công, vui lòng thử lại sau!") });
        }
        setIsDelete(false);
        setIsProcessing(false);
    }

    const onCancelDeleteClick = () => {
        setIsDelete(false);
    }

    useEffect(() => {
        if (alert.success !== '' || alert.error !== '') {
            if (alertRef.current) clearTimeout(alertRef.current);
            alertRef.current = setTimeout(() => {
                onCloseDialog()
            }, 5000);
        }
    }, [alert])

    const onCloseDialog = () => {
        setAlert({
            ...alert,
            success: '',
            error: '',
            info: ''
        });
    }

    const handleNotification = (ids) => {
        var names = [];
        ids.forEach(item => {
            const exists = residents.filter(value => value.ID === item);
            if (exists[0])
                if (exists[0].Apartments && exists[0].Apartments.length > 1)
                    names.push(exists[0].FullName);
        })
        return names;
    }

    const getNotificationConfirm = () => {
        var names = handleNotification(userIDs);
        if (names.length < 1) {
            return <p>{t("Bạn có chắc chắn muốn duyệt?")}</p>
        } else if (names.length === 1) {
            return (
                <div>
                    <p><b>{names.length}</b> {t('1 cư dân thuộc nhiều căn hộ')}: <b>{names[0]}</b></p>
                    <p>{t('Bạn có chắc chắn muốn duyệt?')}</p>
                </div>
            )
        } else if (names.length > 5) {
            var num = 0;
            return (
                <div>
                    <p><b>{names.length}</b> {t('cư dân thuộc nhiều căn hộ')}:</p>
                    {names.map((value, key) => {
                        if (num > 4) return;
                        num++;
                        return <p style={{ textAlign: 'left', paddingLeft: '25%', color: '#000000DE' }} key={key}>- <b>{value}</b></p>
                    })}
                    <p style={{ textAlign: 'left', paddingLeft: '25%' }}>...</p>
                    <p>{t('Bạn có chắc chắn muốn duyệt?')}</p>
                </div>
            )
        } else {
            return (
                <div >
                    <p><b>{names.length}</b> {t('cư dân thuộc nhiều căn hộ')}:</p>
                    {names.map((value, key) => <p style={{ textAlign: 'left', paddingLeft: '25%' }} key={key}> - <b>{value}</b></p>)}
                    <p>{t('Bạn có chắc chắn muốn duyệt?')}</p>
                </div>
            )
        }
    }

    const onSearchChange = (e) => {
        var value = e.target.value;
        setSearchString(value);
        if (searchRef.current) clearTimeout(searchRef.current);
        searchRef.current = setTimeout(() => {
            setFilter({
                ...filter,
                search: value
            });
            setLoading(false);
        }, 500)
    }

    const onFilterClick = () => {
        setIsFilter(true);
    }

    const onCloseFilterClick = () => {
        setIsFilter(false);
    }

    const onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFilter({ ...filter, [name]: value });
    }

    const onRegisterTimeChange = (e) => {
        if (e === null) {
            setFilter({
                ...filter,
                register_time: null
            })
        } else {
            const date = new Date(e).getTime();
            setFilter({
                ...filter,
                register_time: date
            })
        }
    }

    const onClearFilter = () => {
        setFilter({
            block: '',
            floor: '',
            room: '',
            register_time: null,
            registration_type: ''
        });
    }

    const onApplyFilter = () => {
        setLoading(false);
        setIsFilter(false);
        setFilterValues({ ...filter });
        setCurrentPage(0);
    }

    const onRemoveItemFilterClick = (key) => {
        var ft = {};
        if (key === 'register_time') {
            ft = {
                ...filter,
                [key]: null
            }
        } else {
            ft = {
                ...filter,
                [key]: ''
            }
        }
        setFilter(ft);
        setFilterValues(ft);
        setLoading(false);
    }

    useEffect(() => {
        if (filter.block !== '' || filter.floor !== '' || filter.room !== '' || filter.register_time !== null || filter.registration_type !== '') {
            setIsFiltering(true);
        } else {
            setIsFiltering(false);
        }
    }, [filterValues])

    const getFilter = () => {
        return (
            <Dialog
                disableScrollLock
                open={isFilter}
                maxWidth="xl"
                aria-labelledby="simple-dialog-title"
            >
                <DialogTitle aria-labelledby="simple-dialog-title" className={classes.dialogTitleStyle} >
                    <div className={classes.pTitleStyle}>
                        {t('Bộ lọc')}
                        <p style={{ flex: 1 }}></p>
                        <IconButton
                            onClick={onCloseFilterClick}
                            size="small"
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className={clsx(classes.fontNunito, classes.dialogContentStyle)}>
                    <div className={classes.displayFlex}>
                        <div className={classes.filterSmallInput}>
                            <label className={classes.filterLabel} >{t("Block")}</label><br />
                            <TextField
                                size="small"
                                name="block"
                                className={classes.inputStyle}
                                placeholder={t("Block")}
                                variant="outlined"
                                value={filter.block}
                                onChange={onChange}
                            />
                        </div>
                        <div className={classes.filterSmallInput}>
                            <label className={classes.filterLabel} >{t("Tầng")}</label><br />
                            <TextField
                                size="small"
                                name="floor"
                                className={classes.inputStyle}
                                placeholder={t("Tầng")}
                                variant="outlined"
                                value={filter.floor}
                                onChange={onChange}
                            />
                        </div>
                        <div className={classes.filterSmallInput}>
                            <label className={classes.filterLabel} >{t("Phòng")}</label><br />
                            <TextField
                                size="small"
                                name="room"
                                className={classes.inputStyle}
                                placeholder={t("Phòng")}
                                variant="outlined"
                                value={filter.room}
                                onChange={onChange}
                            />
                        </div>
                        <div className={clsx(classes.inputFullWidthBlock)}>
                            <label className={classes.filterLabel} >{t("Loại yêu cầu")}</label><br />
                            <RadioGroup
                                value={filter.registration_type}
                                onChange={onChange}
                                name="registration_type"
                                row
                                className={classes.radioGroupStyle}
                            >
                                <FormControlLabel
                                    value='new'
                                    control={
                                        <Radio
                                            classes={{ root: classes.radio, checked: classes.checked }}
                                            size="small"
                                        />}
                                    label={t('Đăng ký mới')}
                                />
                                <FormControlLabel
                                    value='faceid'
                                    control={
                                        <Radio
                                            classes={{ root: classes.radio, checked: classes.checked }}
                                            size="small"
                                        />}
                                    label={t('Cập nhật Face ID')}
                                />
                                <FormControlLabel
                                    value='update'
                                    control={
                                        <Radio
                                            classes={{ root: classes.radio, checked: classes.checked }}
                                            size="small"
                                        />}
                                    label={t('Cập nhật thông tin')}
                                />
                            </RadioGroup>
                        </div>
                        <div className={classes.filterInput}>
                            <label className={classes.filterLabel}>{t('Thời gian đăng ký')}</label><br />
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={i18n.language === 'vi' ? vi : ''}
                            >
                                <KeyboardDatePicker
                                    inputVariant="outlined"
                                    className={classes.datePickerStyle}
                                    size="small"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    InputProps={{ readOnly: true }}
                                    placeholder={t("Ngày tạo")}
                                    value={filter.register_time}
                                    onChange={onRegisterTimeChange}
                                    cancelLabel={t("Đóng")}
                                    okLabel={t("Đồng ý")}
                                    views={["date", "month", "year"]}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div style={{ clear: 'both' }} ></div>
                        <div className={classes.buttonGroupStyle}>
                            <CancelButton
                                variant="outlined"
                                style={{ marginLeft: '15px' }}
                                onClick={onClearFilter}
                            >
                                {t('Xóa bộ lọc')}
                            </CancelButton>
                            <CreateButton
                                variant="contained"
                                onClick={onApplyFilter}
                            >
                                {t('Áp dụng')}
                            </CreateButton>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        )
    }

    const confirmApprove = () => {
        return (
            <Dialog
                open={isApprove}
                maxWidth="xl"
            >
                <DialogTitle className={classes.dialogTitleBackground}>
                    <p className={classes.dialogTitle}>{t("Thông báo")}</p>
                </DialogTitle>
                <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center', position: 'relative' }}>
                    <div className={classes.content}>
                        <div style={{ width: '100%', marginTop: '10px', fontSize: '18px' }}>
                            {getNotificationConfirm()}
                            <div style={{ height: '30px', paddingTop: '20px', paddingLeft: '20%', display: 'flex', alignItems: 'center' }}>
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        classes={{ label: classes.controlLabel }}
                                        control={
                                            <Checkbox
                                                color="primary"
                                                onChange={onNotificationChange}
                                                checked={isNotification}
                                                classes={{ root: notification === NotificationConfiguration.ASK ? classes.checkbox : classes.disabledCheckbox, checked: classes.checked }}
                                                disabled={notification !== NotificationConfiguration.ASK}
                                            />}
                                        label={t('Gửi thông báo đến cư dân')}
                                        labelPlacement="end"
                                    />
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                    <div>
                        <CancelButton
                            style={{ margin: '10px 0' }}
                            onClick={() => {
                                setIsApprove(!isApprove);
                            }}
                            disabled={isProcessing}
                        >
                            {t("Trở về")}
                        </CancelButton>
                        <CreateButton
                            style={{ margin: '10px 0 10px 24px' }}
                            onClick={onConfirmApproveClick}
                            disabled={isProcessing}
                        >
                            {t("Đồng ý")}
                        </CreateButton>
                        {isProcessing && <CircularProgress color="primary" style={{ position: 'absolute', left: '45%', top: '10%', color: '#0b59c8' }} />}
                    </div>
                </DialogContent>
            </Dialog>
        )
    }

    const rejectDialog = () => {
        return (
            <Dialog
                open={isReject}
                maxWidth="xl"
            >
                <DialogTitle className={classes.dialogTitleBackground}>
                    <p className={classes.dialogTitle}>{t("Thông báo")}</p>
                </DialogTitle>
                <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center', position: 'relative' }}>
                    <div className={classes.contentDialog}>
                        <p style={{ fontSize: '18px' }} >{t("Vui lòng nhập lý do từ chối")}:</p>
                        <div style={{ height: '70px' }}>
                            <TextField
                                style={{ background: '#FFFFFF', width: '100%', marginTop: '5px' }}
                                variant="outlined"
                                placeholder={t("Không có Face ID, Số điện thoại đã bị trùng,...")}
                                size="small"
                                name="reason"
                                onChange={onReasonChange}
                                helperText={alert.reason}
                                error={alert.reason !== ''}
                            />
                        </div>
                        <div style={{ height: '30px', width: '100%', position: 'relative', top: '-15px', display: 'flex', justifyContent: 'center' }}>
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    classes={{ label: classes.controlLabel }}
                                    control={
                                        <Checkbox color="primary"
                                            onChange={onNotificationChange}
                                            checked={isNotification}
                                            classes={{ root: notification === NotificationConfiguration.ASK ? classes.checkbox : classes.disabledCheckbox, checked: classes.checked }}
                                            disabled={notification !== NotificationConfiguration.ASK}
                                        />}
                                    label={t('Gửi thông báo đến cư dân')}
                                    labelPlacement="end"
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div style={{ borderBottom: '1px solid #EEEDF2' }} ></div>
                    <CancelButton
                        variant="outlined"
                        className={classes.cancelButtonStyle}
                        onClick={onRejectCancelClick}
                        disabled={isProcessing}
                    >
                        {t("Trở về")}
                    </CancelButton>
                    <CreateButton
                        className={classes.createButtonStyle}
                        onClick={onConfirmRejectClick}
                        disabled={isProcessing || reason === ''}
                        variant="contained"
                    >
                        {t("Đồng ý")}
                    </CreateButton>
                    {isProcessing && <CircularProgress color="primary" style={{ position: 'absolute', left: '45%', top: '25%', color: '#0b59c8' }} />}
                </DialogContent>
            </Dialog>
        )
    }

    const deleteDialog = () => {
        return (
            <Dialog
                open={isDelete}
                maxWidth="xl"
            >
                <DialogTitle className={classes.dialogTitleBackground}>
                    <p className={classes.dialogTitle}>{t("Thông báo")}</p>
                </DialogTitle>
                <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center', position: 'relative' }}>
                    <div className={classes.contentDialog}>
                        <p style={{ fontSize: '18px' }} >{t("Vui lòng nhập lý do xóa")}:</p>
                        <div style={{ height: '70px' }}>
                            <TextField
                                style={{ background: '#FFFFFF', width: '100%', marginTop: '5px' }}
                                variant="outlined"
                                placeholder={t("Không có Face ID, Số điện thoại đã bị trùng,...")}
                                size="small"
                                name="reason"
                                onChange={onReasonChange}
                                helperText={alert.reason}
                                error={alert.reason !== ''}
                            />
                        </div>
                        <div style={{ height: '30px', width: '100%', position: 'relative', top: '-15px', display: 'flex', justifyContent: 'center' }}>
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    classes={{ label: classes.controlLabel }}
                                    control={
                                        <Checkbox color="primary"
                                            onChange={onNotificationChange}
                                            checked={isNotification}
                                            classes={{ root: notification === NotificationConfiguration.ASK ? classes.checkbox : classes.disabledCheckbox, checked: classes.checked }}
                                            disabled={notification !== NotificationConfiguration.ASK}
                                        />}
                                    label={t('Gửi thông báo đến cư dân')}
                                    labelPlacement="end"
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div style={{ borderBottom: '1px solid #EEEDF2' }} ></div>
                    <CancelButton
                        variant="outlined"
                        className={classes.cancelButtonStyle}
                        onClick={onCancelDeleteClick}
                        disabled={isProcessing}
                    >
                        {t("Trở về")}
                    </CancelButton>
                    <CreateButton
                        className={classes.createButtonStyle}
                        onClick={onConfirmDeleteClick}
                        disabled={isProcessing || reason === ''}
                        variant="contained"
                    >
                        {t("Đồng ý")}
                    </CreateButton>
                    {isProcessing && <CircularProgress color="primary" style={{ position: 'absolute', left: '45%', top: '25%', color: '#0b59c8' }} />}
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <div className={classes.page}>
            {confirmApprove()}
            {rejectDialog()}
            {/* {deleteDialog()} */}
            {getFilter()}
            <Confirm
                isOpen={isDelete}
                handleClose={onCancelDeleteClick}
                isLoading={isProcessing}
                mess={t('Bạn có chắc chắc muốn xóa?')}
                onConfirm={onConfirmDeleteClick}
            />
            <SuccessAlert
                message={alert.success}
                onCloseDialog={onCloseDialog}
            />
            <ErrorAlert
                message={alert.error}
                onCloseDialog={onCloseDialog}
            />
            <ErrorAlert
                message={alert.info}
                onCloseDialog={onCloseDialog}
            />
            <div className={classes.titleBlock}>
                <IconButton
                    className={classes.iconButtonStyle}
                    component={Link}
                    to="/admin/manage-residences"
                >
                    <ArrowBackIosOutlinedIcon className={clsx(classes.primaryTextColor, classes.arrowBackIconStyle)} />
                </IconButton>
                <p className={classes.primaryTitle}>{t("Danh sách chờ duyệt")}</p>
                <CreateButton
                    variant="contained"
                    style={{ float: 'right' }}
                    component={Link}
                    to='/admin/approval-history'
                >
                    {t('Lịch sử duyệt')}
                </CreateButton>
            </div>
            <div className={classes.actionBlock}>
                <div>
                    <TextField
                        placeholder={t("Tìm kiếm")}
                        variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            style: { background: '#FFFFFF', height: '40px', width: '320px' }
                        }}
                        value={searchString}
                        onChange={onSearchChange}
                    />
                </div>
                <CancelButton
                    variant="outlined"
                    className={classes.approveButtonStyle}
                    startIcon={<FilterIcon hexColor="#0b59c8" />}
                    onClick={onFilterClick}
                >
                    {t("Bộ lọc")}
                </CancelButton>
                <div className={classes.flex1}></div>
                <CreateButton
                    variant="contained"
                    className={classes.approveButtonStyle}
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={onApproveClick}
                    disabled={userIDs.length === 0}
                >
                    {t("Duyệt")}
                </CreateButton>
                {
                    tabValue === 0 &&
                    <DeleteButton
                        variant="outlined"
                        className={classes.rejectButtonStyle}
                        startIcon={<DoDisturbAltIcon />}
                        onClick={onRejectClick}
                        disabled={userIDs.length === 0}
                    >
                        {t("Từ chối")}
                    </DeleteButton>
                }
                {
                    tabValue === 1 &&
                    <DeleteButton
                        variant="outlined"
                        className={classes.removeButtonStyle}
                        startIcon={<DeleteOutlineOutlinedIcon />}
                        onClick={onDeleteClick}
                        disabled={userIDs.length === 0}
                    >
                        {t("Xóa")}
                    </DeleteButton>
                }
                <CreateButton
                    variant="contained"
                    className={classes.refreshButtonStyle}
                    startIcon={<ReplayIcon />}
                    onClick={onRefreshClick}
                    disabled={!loading}
                >
                    {t('Làm mới')}
                </CreateButton>
            </div>
            {
                isFiltering &&
                <ShowFilterValues
                    filterValues={filterValues}
                    onRemoveItemFilterClick={onRemoveItemFilterClick}
                />
            }
            <div style={{ width: '100%', margin: 'auto', marginBottom: '16px', minWidth: '1080px' }}>
                <Tabs
                    classes={{ indicator: classes.indicator }}
                    value={tabValue}
                    onChange={handleChange}
                >
                    <Tab
                        classes={tabValue === 0 ? { root: classes.fontTabActive } : { root: classes.fontTab }}
                        label={t("Danh sách chờ duyệt")}
                    />
                    <Tab
                        classes={tabValue === 1 ? { root: classes.fontTabActive } : { root: classes.fontTab }}
                        label={t("Danh sách bị từ chối")}
                    />
                </Tabs>
                <Divider />
            </div>
            <div className={classes.table} >
                {
                    tabValue === 0 &&
                    <div className={clsx(classes.table, classes.root)}>
                        <DataGrid
                            localeText={{
                                footerRowSelected: (count) => count + t(" hàng trong bảng đã được chọn"),
                                columnHeaderSortIconLabel: t('Sắp xếp')
                            }}
                            columns={columns}
                            rows={residents}
                            pageSize={rowsPerPage}
                            rowsPerPageOptions={[10, 50, 100]}
                            onPageSizeChange={(pageSize) => setRowsPerPage(pageSize)}
                            checkboxSelection
                            onSelectionModelChange={onSelectedChange}
                            hideFooterRowCount
                            rowHeight={42}
                            components={{
                                NoRowsOverlay: () => EmptyRowsView(t('Không tìm thấy cư dân')),
                                LoadingOverlay: CustomLoading,
                            }}
                            disableSelectionOnClick
                            disableColumnMenu
                            loading={!loading}
                            disableColumnReorder
                            onRowClick={onRowClick}
                            page={currentPage}
                            rowCount={rowsTotal}
                            paginationMode="server"
                            onPageChange={(newPage) => onPageChange(newPage)}
                        />
                    </div>
                }
                {
                    tabValue === 1 &&
                    <div className={clsx(classes.table, classes.root)}>
                        <DataGrid
                            localeText={{
                                footerRowSelected: (count) => count + t(" hàng trong bảng đã được chọn"),
                                columnHeaderSortIconLabel: t('Sắp xếp')
                            }}
                            localeText={{
                                footerRowSelected: (count) => count + t(" hàng trong bảng đã được chọn"),
                                columnHeaderSortIconLabel: t('Sắp xếp')
                            }}
                            columns={columns}
                            rows={residents}
                            rowHeight={42}
                            pageSize={rowsPerPage}
                            rowsPerPageOptions={[10, 50, 100]}
                            onPageSizeChange={(pageSize) => setRowsPerPage(pageSize)}
                            components={{
                                NoRowsOverlay: () => EmptyRowsView(t('Không tìm thấy danh sách từ chối')),
                                LoadingOverlay: CustomLoading,
                            }}
                            checkboxSelection
                            onSelectionModelChange={onSelectedChange}
                            disableSelectionOnClick
                            disableColumnMenu
                            loading={!loading}
                            onRowClick={onRowClick}
                            disableColumnReorder
                            page={currentPage}
                            rowCount={rowsTotal}
                            paginationMode="server"
                            onPageChange={(newPage) => onPageChange(newPage)}
                        />
                    </div>
                }
            </div>
            <div style={{ height: '30px' }}></div>
        </div>
    )
}

export default PendingApprovalList;