export const ServiceApiConstants = Object.freeze({
    CABINETS: 'cabinets',
    CABINETS_LIST: 'cabinets/list',
    CABINETS_CELLS_TYPES: 'cabinets/cells/types',
    CABINETS_CELLS: 'cabinets/cells',
    CABINETS_TEMPLATES: 'cabinets/templates',
    CABINETS_CELLS_HISTORY: 'cells/histories',
    CABINETS_CHARTS: 'cabinets/charts',
    CABINETS_CELLS_OPEN: 'cabinets/cells/open',
    CABINETS_DATE_CHARTS: 'cabinets/date/charts',
    CABINETS_TYPES_DATE_CHARTS: 'cabinets/types/date/charts',
    CABINETS_REVENUE_DATE_CHARTS: 'cabinets/revenue/date/charts',
    CABINETS_REVENUES_DATE_CHARTS: 'cabinets/revenues/date/charts',
    TLOCKER_PROFILES: 'tlocker/profiles'
})