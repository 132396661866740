import React,{useState,useEffect} from 'react';
import {Pie} from 'react-chartjs-2';
import {useTranslation} from 'react-i18next';

const AccessTypeChart = React.memo((props) =>{
    const {t} = useTranslation();
    const {circleData} = props;
    const [accessByMonth,setAccessByMonth] = useState([0,0,0]);

    useEffect(()=>{
        const data = [circleData.callHistories,circleData.qrHistories,circleData.faceidHistories];
        setAccessByMonth(data);
    },[circleData])

    const data = {
        labels:[t('Cuộc gọi'),t('Mã QR'),t('Nhận diện khuôn mặt')],
        datasets: [{
          label:'Acess',
          data:accessByMonth,
          backgroundColor:['#29CB97','#FF9838','#3870FF'],
          borderWidth:0
        }]
    }

    return(
        <div>
            <div style={{padding:'10px 20px'}}>
                <p style={{fontSize:'20px',fontWeight:'600',color:'#333333',opacity:'0.6'}}>{t('Phương thức ra vào')}</p>
                <p style={{fontSize:'14px',color:'#333333',opacity:'0.6'}}>{t('30 ngày trước')}</p>
            </div>
            <div style={{width:'95%',margin:'auto'}}>   
                <Pie
                    data={data}
                    options={{
                        plugins: {
                            legend: {
                                display:true,
                                position:'bottom',
                                labels:{
                                    boxWidth:15,            
                                    usePointStyle:true,
                                    color:'#000000',
                                    font:{
                                        family:'Nunito',
                                        size:'14px',
                                    }                             
                                }
                            }
                        },
                    }}
                />
            </div>
        </div>
    )
})

export default AccessTypeChart;