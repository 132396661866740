import React, { useState, useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button/Button';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import TextField from '@material-ui/core/TextField/TextField';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import IconButton from '@material-ui/core/IconButton/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { Link, useHistory } from 'react-router-dom';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { GridOverlay, DataGrid } from '@material-ui/data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import SuccessAlert from '../../alert/SuccessAlert';
import ErrorAlert from '../../alert/ErrorAlert';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { apiSmartcameraRecognize, apiSmartcameraHistoryDelete } from '../../../api/smartcamera';
import LargeTooltip from '../../largeTooltip';

const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: '14px',
        lineHeight: '140%',
        color: '#000000',
        display: 'inline-block',
        paddingTop: '10px'
    },
    content: {
        width: '98%',
        margin: 'auto',
        borderRadius: '6px',
        display: 'flex',
        flexWarp: 'wrap'
    },
    primaryColor: theme.primaryTextColor,
    dialogTitleBackground: theme.primaryColor,
    dialogTitle: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '25px',
        color: '#FFFFFF',
    },
    contentDialog: {
        fontSize: '16px',
        height: '68px',
        borderBottom: '1px solid #EEEDF2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    dialogFailedTitleBackground: theme.errorColor,
    overlay: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        background: 'rgba(0,0,0,0.1)',
        zIndex: '1300'
    },
    secondaryTitle: theme.secondary_title,
    focus: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: theme.themeColor,
            },
        },
    },
    textFeild: {
        fontWeight: 'bold',
        fontSize: '30px'
    },
    primaryTitle: theme.primary_title,
    topBlock: {
        marginTop: '22px',
        marginBottom: '20px',
        height: '40px',
        width: '98%',
        marginLeft: '16px',
        display: 'flex'
    },
    root: theme.dataGridStyle,
    tableHeader: {
        width: '100%',
        height: '56px',
        background: '#FFFFFF',
        border: '1px solid #E0E0E0',
        borderRadius: '6px 6px 0 0',
        position: 'relative'
    },
    selectInput: {
        '& .MuiInput-underline:before': { display: 'none' },
        '& .MuiInput-underline:after': { display: 'none' }
    },
    paginationStyle: {
        position: 'absolute',
        bottom: '-37px',
        right: '5px',
        display: 'flex'
    },
    paginationItemStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 20px'
    },
    title: {
        fontSize: '22px',
        fontWeight: 'bold',
        padding: '23px 0 15px 0',
        width: '98%',
        margin: 'auto'
    },
    deleteButtonStyle: {
        margin: '8px'
    },
    historyPageStyle: {
        position: 'relative',
        paddingBottom: '12px',
        height: '100%'
    },
    tableSection: {
        height: '80%',
        width: '98%',
        paddingBottom: '15px'
        , margin: 'auto',
        position: 'relative'
    },
    tableStyle: {
        height: '98%',
        background: '#FFFFFF'
    }
}))

const CreateButton = withStyles(theme => ({ root: theme.create_button, }))(Button);
const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);
const ErrorButton = withStyles(theme => ({ root: theme.error_button }))(Button);
const DeleteButton = withStyles(theme => ({ root: theme.delete_button }))(Button);
const TextButton = withStyles(theme => ({ root: theme.moreDetail }))(Button);

const HistoryTable = props => {
    const classes = useStyles();
    const [histories, setHistories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsTotal, setRowsTotal] = useState(0);
    const [pageTotal, setPageTotal] = useState(0);
    const [selectedIds, setSelectedIds] = useState([]);
    const [isZoomImage, setIsZoomImage] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [files, setFiles] = useState([]);
    const [isZoom, setIsZoom] = useState(false);
    const [fileStr, setFileStr] = useState('');
    const [alert, setAlert] = useState({ success: '', error: '' });
    const [isExpiredSession, setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const zoomRef = useRef();
    const alertRef = useRef();

    const columns = [
        {
            field: 'Image',
            headerName: t('Khuôn mặt'),
            cellClassName: 'super-app-theme--cell',
            flex: 1,
            renderCell: params => showImage(params.row.Image)
        },
        {
            field: 'FullName',
            headerName: t('Họ và tên'),
            cellClassName: 'super-app-theme--cell',
            flex: 1
        },
        {
            field: 'Time',
            headerName: t('Thời gian'),
            cellClassName: 'super-app-theme--cell',
            flex: 1,
        },
    ]

    const showImage = (image) => {
        if (image)
            return (
                <LargeTooltip title={t('Xem')} >
                    <img
                        src={image}
                        style={{ objectFit: 'cover', cursor: 'pointer', borderRadius: '4px' }}
                        alt="" width='40px' height='40px'
                        onClick={() => {
                            setIsZoomImage(true);
                            setSelectedImage(image);
                        }}
                    />
                </LargeTooltip>
            )
    }

    useEffect(() => {
        if (isExpiredSession) {
            history.push('/login');
            dispatch({ type: 'IS_LOGIN_AGAIN_TRUE' });
        }
    }, [isExpiredSession]);

    const getDefaultValues = async () => {
        try {
            const offset = (currentPage - 1) * rowsPerPage;
            const res = await apiSmartcameraRecognize({ offset: offset, limit: rowsPerPage });
            setRowsTotal(res.data.Count);
            if (res.data.Count > rowsPerPage && res.data.Count % rowsPerPage === 0) setPageTotal(res.data.Count / rowsPerPage);
            else if (res.data.Count > rowsPerPage && res.data.Count % rowsPerPage !== 0) setPageTotal(parseInt(res.data.Count / rowsPerPage) + 1);
            else setPageTotal(1);
            const data = [];
            res.data.Items.forEach(item => {
                data.push({ ...item, id: item.ID, FullName: item.Resident.FullName });
            })
            setHistories(data);
        } catch (error) {
            if (error.response ?.data === "Invalid LoginToken") return setIsExpiredSession(true);
        }
        setLoading(true);
    }

    useEffect(() => {
        if (!loading) getDefaultValues();
    }, [loading])

    const onSelectedChange = (e) => {
        setSelectedIds(e);
        var images = [];
        e.forEach(value => {
            const res = histories.filter(item => item.id === value);
            if (res[0]) images.push(res[0].Image);
        })
        setFiles(images);
    }

    const onDeleteConfirmation = async () => {
        try {
            setIsDeleting(true);
            await apiSmartcameraHistoryDelete({ files: files, ids: selectedIds });
            setAlert({ ...alert, success: t('Xóa thành công!'), error: '' });
            setLoading(false);
        } catch (error) {
            if (error.response ?.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({ ...alert, error: t('Xóa không thành công, vui lòng thử lại sau!') });
        }
        setIsDeleting(false);
        setDeleteConfirmation(false);
    }

    const onAlertClose = () => {
        setAlert({ ...alert, success: '', error: '' });
    }

    useEffect(() => {
        if (alert.success !== '' || alert.error !== '') {
            if (alertRef.current) clearTimeout(alertRef.current);
            alertRef.current = setTimeout(() => {
                onAlertClose();
            }, 5000);
        }
    }, [alert])

    useEffect(() => {
        const handleClickOutside = (e => {
            if (zoomRef.current && !zoomRef.current.contains(e.target))
                setIsZoomImage(false);
        })
        document.addEventListener('mousedown', handleClickOutside);
    }, [zoomRef])

    const zoomImage = () => {
        return (
            <Dialog
                open={isZoomImage}
                maxWidth="xl"
            >
                <DialogContent ref={zoomRef} style={{ padding: '0' }}>
                    <img src={selectedImage} alt="" style={{ height: '500px' }} />
                </DialogContent>
            </Dialog>
        )
    }

    const deleteConfirmationDialog = () => {
        return (
            <Dialog
                open={deleteConfirmation}
                maxWidth="xl"
            >
                <DialogTitle className={classes.dialogTitleBackground}>
                    <p className={classes.dialogTitle}>{t("Thông báo")}</p>
                </DialogTitle>
                <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center', position: 'relative' }}>
                    <div className={classes.contentDialog}>
                        <p >{t("Bạn có chắc chắn muốn xóa lịch sử?")}</p>
                    </div>
                    <CancelButton
                        style={{ margin: '10px 0' }}
                        onClick={() => {
                            setDeleteConfirmation(!deleteConfirmation);
                        }}
                        disabled={isDeleting}
                    >
                        {t("Trở về")}
                    </CancelButton>
                    <CreateButton
                        style={{ margin: '10px 0 10px 24px' }}
                        onClick={onDeleteConfirmation}
                        disabled={isDeleting}
                    >
                        {t("Đồng ý")}
                    </CreateButton>
                    {isDeleting && <CircularProgress color="primary" style={{ position: 'absolute', left: '45%', top: '10%', color: '#0b59c8' }} />}
                </DialogContent>
            </Dialog>
        )
    }

    const EmptyRowsView = () => {
        return (
            <div
                style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <h3 style={{ color: '#6D6D6D', fontSize: '16px' }}>
                    {t('Không tìm thấy lịch sử')}
                </h3>
            </div>
        );
    };

    const customLoading = () => {
        return (
            <GridOverlay>
                <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                    <LinearProgress />
                </div>
            </GridOverlay>
        )
    }

    return (
        <div className={clsx(classes.focus,classes.historyPageStyle)}>
            {deleteConfirmationDialog()}
            {zoomImage()}
            <SuccessAlert
                message={alert.success}
                onCloseDialog={onAlertClose}
            />
            <ErrorAlert
                message={alert.error}
                onCloseDialog={onAlertClose}
            />
            <p className={classes.title}>
                {t('Lịch sử phát hiện khuôn mặt')}
            </p>
            <div className={clsx(classes.root, classes.tableSection)}>
                <div className={classes.tableHeader}>
                    <DeleteButton
                        variant='outlined'
                        startIcon={<DeleteOutlineOutlinedIcon />}
                        disabled={selectedIds.length === 0}
                        className={classes.deleteButtonStyle}
                        onClick={() => setDeleteConfirmation(true)}
                    >
                        {t('Xóa')}
                    </DeleteButton>
                </div>
                <div className={classes.tableStyle}>
                    <DataGrid
                        componentsProps={{
                            pagination: {
                                labelRowsPerPage: t("Số dòng mỗi trang"),
                                labelDisplayedRows: ({ from, to, count }) => { return `${from}-${to} ${t('trong số')} ${count}` }
                            }
                        }}
                        components={{
                            NoRowsOverlay: EmptyRowsView,
                            LoadingOverlay: customLoading,
                        }}
                        localeText={{
                            footerRowSelected: (count) => count + t(" hàng trong bảng đã được chọn"),
                            columnHeaderSortIconLabel: t('Sắp xếp')
                        }}
                        rows={histories}
                        columns={columns}
                        pageSize={rowsPerPage}
                        rowHeight={45}
                        checkboxSelection
                        rowsPerPageOptions={[10, 50, 100]}
                        onPageSizeChange={pageSize => {
                            setRowsPerPage(pageSize);
                            localStorage.setItem('rowsPerPage', pageSize);
                        }}
                        onSelectionModelChange={onSelectedChange}
                        hideFooterRowCount
                        hideFooterPagination
                        disableSelectionOnClick
                        disableColumnMenu={true}
                        loading={!loading}
                    />
                    <div className={clsx(classes.selectInput, classes.paginationStyle)}>
                        <p className={classes.paginationItemStyle} >
                            {t("Số dòng mỗi trang")}
                        </p>
                        <Select
                            value={rowsPerPage}
                            onChange={(e) => { setRowsPerPage(e.target.value) }}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>
                        <p className={classes.paginationItemStyle} >
                            {rowsTotal === 0 ? 0 : (currentPage * rowsPerPage) - rowsPerPage + 1}-{currentPage * rowsPerPage > rowsTotal ? rowsTotal : currentPage * rowsPerPage} {t("trong số")} {rowsTotal}
                        </p>
                        <IconButton
                            disabled={currentPage === 1}
                            onClick={() => {
                                setCurrentPage(currentPage - 1);
                                setLoading({ ...loading, table: false });
                            }}
                        >
                            <KeyboardArrowLeftIcon />
                        </IconButton>
                        <IconButton
                            disabled={currentPage === pageTotal}
                            onClick={() => {
                                setCurrentPage(currentPage + 1);
                                setLoading({ ...loading, table: false });
                            }}
                        >
                            <KeyboardArrowRightIcon />
                        </IconButton>
                    </div>
                </div>
            </div>
            <div style={{ height: '25px' }}></div>
        </div>
    )
}


export default HistoryTable;


