import clsx from "clsx";
import React from "react";
import { NavLink } from "react-router-dom";
import LargeTooltip from "../../../../largeTooltip";
import { useStyles } from "./styles";

export default function LayoutSideBarItem({ urls, titles }) {
  const classes = useStyles();
  return (
    <NavLink
      to={urls}
      className={(isActive) => clsx(classes.link, isActive && classes.active)}
    >
      <LargeTooltip title={titles}>
        <p className={classes.title}>{titles}</p>
      </LargeTooltip>
    </NavLink>
  );
}
