import React,{useState,useEffect} from 'react';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import Button from '@material-ui/core/Button/Button';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import moment from 'moment';
import axiosClient from '../../config/AxiosClient';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import {useHistory } from 'react-router-dom';
import { GridOverlay, DataGrid } from '@material-ui/data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import LargeTooltip from '../largeTooltip';

const useStyles = makeStyles((theme) => ({
    table:{width:'100%',height:'500px',background:'#FFFFFF',paddingBottom:'55px',marginBottom:'22px',borderRadius:'6px'},
    root:theme.dataGridHeaderStyle,
    icon:{
        '& svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall':{
            color:'#2DCE8', fontWeight:'bold'
        }
    },
    tableTitle:{position:'relative',height:'48px',border:'1px solid rgba(224, 224, 224, 1)',borderBottom:'none',borderTopLeftRadius:'6px',borderTopRightRadius:'6px'},
    callHistory:{border:'1px solid #29CB97',display:'flex',justifyContent:'center',alignItems:'center',color:'#29CB97',height:'30px',padding:'0 10px',borderRadius:'6px'},
    qrHistory:{border:'1px solid #FF9838',display:'flex',justifyContent:'center',alignItems:'center',color:'#FF9838',height:'30px',padding:'0 10px',borderRadius:'6px'},
    faceidHistory:{border:'1px solid #3870FF',display:'flex',justifyContent:'center',alignItems:'center',color:'#3870FF',height:'30px',padding:'0 10px',borderRadius:'6px'},
    degree37:{display:'flex',justifyContent:'center',alignItems:'center',color:'#FFFFFF',height:'30px',padding:'2px 10px 0px 10px',borderRadius:'15px',background:'rgb(42, 185, 48)'},
    degree38:{display:'flex',justifyContent:'center',alignItems:'center',color:'#FFFFFF',height:'30px',padding:'2px 10px 0px 10px',borderRadius:'15px',background:'rgb(255, 152, 56)'},
    degree39:{display:'flex',justifyContent:'center',alignItems:'center',color:'#FFFFFF',height:'30px',padding:'2px 10px 0px 10px',borderRadius:'15px',background:'#e01010'},
}))

const CreateButton = withStyles(theme =>({root:theme.create_button}))(Button);

const AccessTable = React.memo((props) =>{
    const {t} = useTranslation();
    const classes = useStyles();
    const [accessHistories,setAccessHistories] = useState([]);
    const [tableLoading,setTableLoading] = useState(false);
    const [isExpiredSession,setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(()=>{
        if(isExpiredSession){
          history.push('/login');
          dispatch({type:'IS_LOGIN_AGAIN_TRUE'});
        } 
    },[isExpiredSession]);

    const columns = [
        {
            headerName: t("Họ và tên"),
            field: 'FullName',
            flex:1,
            cellClassName:'super-app-theme--cell-dashboard',
            renderCell: params => showFullName(params.row)
        },
        {
            headerName: t("Chấp nhận bởi"),
            field: 'ApprovedBy',
            flex:1,
            cellClassName:'super-app-theme--cell-dashboard',
            renderCell: params => 
            <LargeTooltip title={showApproveBy(params.row)} placement="bottom-start" >
                <div style={{textOverflow: 'ellipsis',overflow:'hidden'}}>{showApproveBy(params.row)}</div>
            </LargeTooltip>
        },  
        {
            headerName: t("Thiết bị"),
            field: 'DeviceName',
            flex:1,
            cellClassName:'super-app-theme--cell-dashboard',
            renderCell: params => 
            <LargeTooltip title={params.row.Type==='call'?params.row.CallerFullName:params.row.DeviceName} placement="bottom-start" >
                <p style={{textOverflow: 'ellipsis',overflow:'hidden'}} >{params.row.Type==='call'?params.row.CallerFullName:params.row.DeviceName}</p>
            </LargeTooltip>
        },  
        {
            headerName: t("Phương thức"),
            field: 'Type',
            width:150,
            cellClassName:'super-app-theme--cell-dashboard',
            renderCell: params => showType(params.row.Type)
        },  
        {
            headerName: t("Nhiệt độ"),
            field: 'Temperature',
            width:150,
            cellClassName:'super-app-theme--cell-dashboard',
            renderCell: params => showTemperature(params.row)
        },  
        {
            headerName: t("Thời gian xác thực"),
            field: 'Time',
            flex:1,
            cellClassName:'super-app-theme--cell-dashboard',
            renderCell: params =>params.row.Time!=='' && 
            <LargeTooltip title={showTime(params.row.Time)} placement="bottom-start" >
                <p style={{textOverflow: 'ellipsis',overflow:'hidden'}} >{showTime(params.row.Time)}</p>
            </LargeTooltip>
        },
    ]

    const showTime = (time) =>{
        if(moment(time).format('DD-MM-YYYY')===moment(new Date()).format('DD-MM-YYYY'))
            return moment(time).format('HH:mm:ss');
        return moment(time).format('DD-MM-YYYY HH:mm:ss');
    }

    const showTemperature = (data) =>{
      switch (data.Type) {
        case 'faceid':
            if(parseInt(data.Temperature) === -1) return '';
            if(parseFloat(data.Temperature) < 37)
                return <p className={classes.degree37} >{data.Temperature}</p>
            if(parseFloat(data.Temperature) < 38)
                return <p className={classes.degree38} >{data.Temperature}</p>
            if(parseFloat(data.Temperature) >= 38)
                return <p className={classes.degree39} >{data.Temperature}</p>
            break;
        default: return '';
      }
    }

    const showApproveBy = (data)=>{
        if(data.Type==='call'){
          if(data.CalleeFullName!==''){
            if(data.CalleeType==="Receptionist")
              return data.CalleeFullName
            else{
              return(<div><b>{data.Callee}</b> - {data.CalleeFullName}</div>)
            }
          }else{
            return '';
          }
        }else if (data.Type==='qr' && data.QRType !=='totp') return data.FullName;
        else return '';
    }

    const showFullName = (data)=>{
        if(data.Type==='faceid')
          if(data.ApartmentOwner)
            return (
            <LargeTooltip title={data.ApartmentOwner +' - '+data.FullName} placement="bottom-start" >
              <div ><b>{data.ApartmentOwner}</b> - {data.FullName}</div>
            </LargeTooltip>)
          else 
            return (
              <LargeTooltip title={data.FullName} placement="bottom-start" >
                <div>{data.FullName}</div>
              </LargeTooltip>)
        else if(data.Type==='qr' && data.QRType==='totp')
          if(data.ApartmentOwner)
            return (
            <LargeTooltip title={data.ApartmentOwner +' - '+ data.FullName} placement="bottom-start" >
              <div><b>{data.ApartmentOwner}</b> - {data.FullName}</div>
            </LargeTooltip>)
          else
            return (
              <LargeTooltip title={data.FullName} placement="bottom-start" >
                <div>{data.FullName}</div>
              </LargeTooltip>)
        else 
          return <div>{t("Khách")}</div>
    }
    const showType = (type) =>{
        if(type==='call') return <p className={classes.callHistory}>{t('Gọi Video')}</p>;
        return type==='qr'? <p className={classes.qrHistory}>QR Code</p>: <p className={classes.faceidHistory}>Face ID</p>;
    }

    const handleHistories = (histories)=>{
        var data=[];
        histories.forEach(item=>{
          var deviceName ='';
          var approvedBy ='';
          var id=item.ID+'-'+item.Type;
          var requiremer='';
          var time='';
          var IsOpenDoor=false;
          if(item.Type==='call'){
            IsOpenDoor=item.IsOpenDoor;
            approvedBy = item.CalleeFullName;
            deviceName = item.CallerFullName;
            if(item.VerificationTime && item.VerificationTime!==null)  time=item.VerificationTime;
          }else if(item.Type==='faceid'){
            IsOpenDoor=true;
            time=parseInt(item.VerificationTime);
            deviceName=item.DeviceName;
            if(item.ApartmentOwner) requiremer=`${item.ApartmentOwner} - ${item.FullName}`;
            else requiremer=item.FullName
          }else{
            IsOpenDoor=item.VerificationStatus==='Passed'?true:false;
            time =item.VerifyTime;
            deviceName = item.DeviceName;
            if (item.QRType !=='totp') approvedBy = item.FullName;
            else if(item.QRType ==='totp'){
              if(item.ApartmentOwner) requiremer = `${item.ApartmentOwner} - ${item.FullName}`;
              else requiremer = item.FullName;
            }
          }
          data.push({...item,id:id,ApprovedBy:approvedBy,Requiremer:requiremer,Time:time,IsOpenDoor:IsOpenDoor,DeviceName:deviceName});
        })
        return data;
    }

    const getDefaultValues = async() =>{
        try {
            const query = {
                start_index:0,
                end_index:5
            }
            let res=[];
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                res = await axiosClient.post('/history/getall',JSON.stringify(query),{headers:{'Authorization': `Bearer ${Cookies.get('token')}`}});
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                res = await axiosClient.post('/histories/get',query,{headers:{'Authorization': `Bearer ${Cookies.get('token')}`}});
            }
            var data = handleHistories(res.data.Items);   
            setAccessHistories(data);
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAccessHistories([]);     
        }
        setTableLoading(true);
    }
    useEffect(()=>{
        if(!tableLoading) getDefaultValues();   
    },[tableLoading])

    const EmptyRowsView = () => {
        return (
          <div
            style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}
          >
            <h3 style={{fontSize: '16px'}}>
              {t('Không tìm thấy sự ra vào')}
            </h3>
          </div>
        );
    };

    const customLoading = () =>{
        return(
            <GridOverlay>
                <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                    <LinearProgress />
                </div>
            </GridOverlay>
        )
    }

    return(
        <div>
            <div className={classes.tableTitle}>
                <p style={{fontSize:'18px',float:'left',margin:'10px',fontWeight:'bold',color:'#333333',opacity:'0.6'}}>{t('Ra vào lần cuối')}</p>
                <CreateButton 
                    variant="contained" 
                    style={{margin:'7px',float:'right',height:'32px'}}
                    onClick={()=>setTableLoading(false)}
                >
                    {t('Làm mới')}
                </CreateButton>
            </div>
            <div style={{height:'284px'}} className={clsx(classes.root)}>
                <DataGrid 
                    componentsProps={{
                        pagination: {
                            labelRowsPerPage: t("Số dòng mỗi trang"),
                            labelDisplayedRows:({from,to,count}) => {return `${from}-${to} ${t('trong số')} ${count}`}
                        }
                    }}
                    components={{
                        NoRowsOverlay:EmptyRowsView,
                        LoadingOverlay: customLoading,
                    }}  
                    rows={accessHistories} 
                    columns={columns} 
                    pageSize={5}
                    rowHeight={45}
                    hideFooterRowCount
                    hideFooterSelectedRowCount
                    disableSelectionOnClick
                    disableColumnMenu={true}
                    loading={!tableLoading}
                    hideFooter
                    localeText={{columnHeaderSortIconLabel: t('Sắp xếp')}}
                    scrollbarSize={25}
                />
            </div>
        </div>
    )
})

export default AccessTable;