import React, { useState, useEffect, useRef } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { GridOverlay, DataGrid } from '@material-ui/data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import vi from 'date-fns/locale/vi';
import Select from '@material-ui/core/Select';
import Divider from '@mui/material/Divider/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button/Button';
import { apiApaymentGetmomo } from '../../../api/payments';

const currency = (amount) => {
    return (amount).toLocaleString('vi', { style: 'currency', currency: 'VND', })
}

const useStyles = makeStyles(theme => ({
    selectTitle: {
        fontSize: '16px',
        lineHeight: '22.4px',
        float: 'left',
        margin: '16px 0',
        padding: '0 16px',
        fontWeight: 'bold'
    },
    dialogTitle: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '25px',
        color: '#FFFFFF',
    },
    tableContent: {
        paddingBottom: '55px',
        width: '100%',
        height: '80%',
        background: '#FFFFFF',
        borderRadius: '6px',
        marginBottom: '22px'
    },
    root: theme.dataGridStyle,
    requestError: {
        width: '100%',
        height: '65vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20px',
        color: '#6D6D6D'
    },
    inputRightBlock: {
        width: '25%',
        position: 'relative'
    },
    label: {
        paddingBottom: '0px',
        paddingTop: '8px',
        fontSize: '14px',
        lineHeight: '140%',
        fontWeight: 'bold',
        color: '#000000',
        display: 'inline-block',
        margin: '0'
    },
    placeholder: {
        color: '#B2B2B2',
        fontSize: '16px'
    },
}));

const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);
const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);

const MomoHistory = props => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [momoHistories, setMomoHistories] = useState([]);
    const [momoHistoriesFilter, setMomoHistoriesFilter] = useState([]);
    const [total, setTotal] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [requestError, setRequestError] = useState('');
    const [searchString, setSearchString] = useState('');
    const [isFilter, setIsFilter] = useState('');
    const [filter, setFilter] = useState({ paymentDate: null, apartment: '', method: '', status: '', requestInfo: '' });
    const searchRef = useRef();
    const columns = [
        {
            headerName: t('Căn hộ'),
            field: 'OrderID',
            cellClassName: 'super-app-theme--cell',
            flex: 1
        },
        {
            headerName: t('Số tiền'),
            field: 'Amount',
            flex: 1,
            cellClassName: 'super-app-theme--cell',
            renderCell: params => <p>{currency(parseInt(params.row.Amount))}</p>
        },
        {
            headerName: t('Loại hóa đơn'),
            field: 'OrderInfo',
            cellClassName: 'super-app-theme--cell',
            flex: 1
        },
        {
            headerName: t('Phương thức'),
            field: 'Method',
            cellClassName: 'super-app-theme--cell',
            flex: 1
        },
        {
            headerName: t('Trạng thái'),
            field: 'LocalMessage',
            cellClassName: 'super-app-theme--cell',
            flex: 1,
            renderCell: params => params.row.Message === 'Success' ? <p style={{ color: 'green' }}>{params.row.LocalMessage}</p> : <p style={{ color: 'red' }}>{params.row.LocalMessage}</p>
        },
        {
            headerName: t('Ngày thanh toán'),
            field: 'ResponseTime',
            cellClassName: 'super-app-theme--cell',
            flex: 1
        },

    ];

    const getDefaultValues = async () => {
        try {
            var res = await apiApaymentGetmomo();
            const data = [];
            res.data.Items.forEach(item => {
                data.push({ ...item, id: item.TransID });
            })
            setMomoHistories(data);
            setMomoHistoriesFilter(data);
            var totalAmount = 0;
            res.data.Items.forEach(item => {
                if (item.Message === 'Success')
                    totalAmount += parseInt(item.Amount);
            })
            setTotal(totalAmount);
            setRequestError('');
        } catch (error) {
            setRequestError('Không lấy được dữ liệu');
        }
        setLoading(true);
    }

    const onSearchChange = (e) => {
        if (searchRef.current) clearTimeout(searchRef.current);
        searchRef.current = setTimeout(() => {
            setSearchString(e.target.value);
        }, 500);
    }

    useEffect(() => {

    }, [searchString])

    useEffect(() => {
        if (!loading) getDefaultValues();
    }, [loading])

    const onStatusChange = (e) => {
        setFilter({ ...filter, status: e.target.value });
    }

    const onRequestInfoChange = (e) => {
        setFilter({ ...filter, requestInfo: e.target.value });
    }

    const onPaymentDate = (e) => {
        setFilter({ ...filter, paymentDate: new Date(e).getTime() });
    }

    const onMethodChange = (e) => {
        setFilter({ ...filter, method: e.target.value });
    }

    const onApartmentChange = (e) => {
        setFilter({ ...filter, apartment: e.target.value });
    }

    useEffect(() => {
        if (momoHistories.length > 0)
            setMomoHistoriesFilter(momoHistories.filter(item =>
                item.TransID.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                item.OrderID.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                item.Amount.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                item.PayType.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                item.OrderInfo.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                item.LocalMessage.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                item.ResponseTime.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                item.ExtraData.toLowerCase().indexOf(filter.toLowerCase()) !== -1
            ))
    }, [filter])

    const EmptyRowsView = () => {
        return (
            <div
                style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <h3 style={{ color: '#6D6D6D', fontSize: '16px' }}>
                    {t('Không tìm thấy lịch sử thanh toán')}
                </h3>
            </div>
        );
    };

    const customLoading = () => {
        return (
            <GridOverlay>
                <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                    <LinearProgress />
                </div>
            </GridOverlay>
        )
    }

    const getFilter = () => {
        return (
            <Dialog
                open={isFilter}
                maxWidth="xl"
            >
                <DialogTitle >
                    <p className={classes.dialogTitle}>Bộ lọc</p>
                </DialogTitle>
                <Divider />
                <DialogContent className={clsx(classes.inputHeight, classes.picker)}>
                    <div style={{ width: '800px', margin: 'auto', display: 'flex', flexWrap: 'wrap' }}>
                        <div className={clsx(classes.select, classes.inputRightBlock)}>
                            <label className={classes.label} >{t("Căn hộ")}</label><br />
                            <TextField
                                size="small"
                                style={{ background: '#FFFFFF', width: '95%' }}
                                placeholder={t("Căn hộ")}
                                variant="outlined"
                                value={filter.apartment}
                                onChange={onApartmentChange}
                            />
                        </div>
                        <div className={clsx(classes.select, classes.inputRightBlock)}>
                            <label className={classes.label} >{t("Loại hóa đơn")}</label><br />
                            <TextField
                                size="small"
                                style={{ background: '#FFFFFF', width: '95%' }}
                                placeholder={t("Loại hóa đơn")}
                                variant="outlined"
                                value={filter.requestInfo}
                                onChange={onRequestInfoChange}
                            />
                        </div>
                        <div className={classes.inputRightBlock}>
                            <label className={classes.label}>{t('Ngày thanh toán')}</label><br />
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === 'vi' ? vi : ''}>
                                <KeyboardDatePicker
                                    inputVariant="outlined"
                                    style={{ position: 'relative', top: '-15px', width: '95%' }}
                                    size="small"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    InputProps={{ readOnly: true }}
                                    placeholder={t("Ngày thanh toán")}
                                    value={filter.paymentDate}
                                    onChange={onPaymentDate}
                                    helperText={isNaN(filter.paymentDate) ? t('Thông tin chưa hợp lệ') : ''}
                                    cancelLabel={t("Đóng")}
                                    okLabel={t("Đồng ý")}
                                    views={["date", "month", "year"]}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className={clsx(classes.select, classes.inputRightBlock)}>
                            <label className={classes.label}>{t("Phương thức")}</label><br />
                            <FormControl variant="outlined" size="small" style={{ width: '95%' }}>
                                <Select
                                    value={filter.method}
                                    displayEmpty
                                    onClick={onMethodChange}
                                >
                                    <MenuItem value='' disabled ><p className={classes.placeholder}> {t("Phương thức")} </p> </MenuItem>
                                    <MenuItem value='momo'>{t("MOMO")}</MenuItem>
                                    <MenuItem value='vnpay'>{t('VNPAY')}</MenuItem>
                                    <MenuItem value='zalo'>{t('ZALO PAY')}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className={clsx(classes.select, classes.inputRightBlock)}>
                            <label className={classes.label}>{t("Trạng thái")}</label><br />
                            <FormControl variant="outlined" size="small" style={{ width: '95%' }}>
                                <Select
                                    value={filter.status}
                                    displayEmpty
                                    onClick={onStatusChange}
                                >
                                    <MenuItem value='' disabled ><p className={classes.placeholder}> {t("Trạng thái")} </p> </MenuItem>
                                    <MenuItem value='success'>{t("Thành công")}</MenuItem>
                                    <MenuItem value='error'>{t('Thất bại')}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ width: '99%', textAlign: 'right', paddingBottom: '10px' }}>
                            <CancelButton
                                variant="outlined"
                                style={{ marginLeft: '15px', height: '32px' }}
                                onClick={() => {
                                    setFilter({ status: '', paymentDate: null, method: '', apartment: '' })
                                }}
                            >
                                {t('filter.Xóa')}
                            </CancelButton>
                            <CreateButton
                                variant="contained"
                                style={{ height: '32px' }}
                                onClick={() => {
                                    setLoading(false);
                                    setIsFilter(false);
                                    // setCurrentPage(1);
                                }}

                            >
                                {t('Áp dụng')}
                            </CreateButton>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        )
    }
    
    return (
        <div style={{ position: 'relative', width: '98%', margin: 'auto', height: '100%' }}>
            {getFilter()}
            <div style={{ fontSize: '22px', fontWeight: 'bold', padding: '23px 0 15px 0' }}>
                {t('Lịch sử thanh toán')}
                <TextField
                    placeholder="Tìm kiếm căn hộ, loại hóa đơn"
                    variant="outlined"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        style: { background: '#FFFFFF', height: '40px', width: '320px', marginLeft: '10px' }
                    }}
                    onChange={onSearchChange}
                />
            </div>
            {!loading && requestError === '' && <div className={classes.requestError}> <CircularProgress style={{ color: '#0b59c8' }} /> </div>}
            {requestError !== '' && <div className={classes.requestError}>{requestError}</div>}
            {loading && requestError === '' &&
                <div className={clsx(classes.tableContent, classes.root)}>
                    <div style={{ width: '100%', height: '56px', background: '#FFFFFF', border: '1px solid #E0E0E0', position: 'relative', borderRadius: '6px' }}>
                        {/* <p className={classes.selectTitle} >Tổng số giao dịch: {momoHistories.length}</p>
                <p className={classes.selectTitle} style={{float:'right'}}>Tổng số tiền giao dịch thành công: {currency(parseInt(total))}</p> */}
                        <CreateButton
                            variant="contained"
                            style={{ margin: '7px', float: 'right' }}
                            onClick={() => setIsFilter(true)}
                        >
                            {t('Bộ lọc')}
                        </CreateButton>
                    </div>
                    <DataGrid
                        componentsProps={{
                            pagination: {
                                labelRowsPerPage: "Số dòng mỗi trang",
                                labelDisplayedRows: ({ from, to, count }) => { return `${from}-${to} trong số ${count}` }
                            }
                        }}
                        components={{
                            NoRowsOverlay: EmptyRowsView,
                            LoadingOverlay: customLoading,
                        }}
                        columns={columns}
                        rows={momoHistoriesFilter}
                        pageSize={rowsPerPage}
                        rowsPerPageOptions={[10, 50, 100]}
                        onPageSizeChange={(e) => setRowsPerPage(e.pageSize)}
                        hideFooterRowCount
                        hideFooterSelectedRowCount
                        disableSelectionOnClick
                        disableColumnMenu={true}
                    />
                </div>
            }

        </div>
    )
}

export default MomoHistory;
