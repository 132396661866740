import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import React from 'react';

const CancelBtn = withStyles(theme => ({ root: theme.cancel_button }))(Button);

export default function CancelButton({ children, ...Props }) {
    return (
        <CancelBtn {...Props}>
            {children}
        </CancelBtn>
    )
}