import React, { useState, useEffect, useRef } from 'react';
import SubDetailTitle from '../../title/subDetailTitle';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import { useHistory, useParams } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { isEmpty, checkDisplayName, checkIPv4 } from '../../../validation/CheckForm';
import Divider from '@material-ui/core/Divider/Divider';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import MultipleAutocomplete from '@mui/material/Autocomplete';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { apiGetapartmentdata } from '../../../api/apartment';
import { compare } from 'natural-orderby';
import { getBookingService } from '../../../api/booking';
import { apiTommiAdd, apiTommiUpdate, apiTommiOpen, apiTommiExist, apiTommiPing } from '../../../api/tommi';
import { Confirm } from '../../dialog/index';
import CircularProgress from '@material-ui/core/CircularProgress';
import { authenticator } from 'otplib';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';
import { apiDeviceGet } from '../../../api/device';
import SuccessAlert from '../../alert/SuccessAlert';
import ErrorAlert from '../../alert/ErrorAlert';
import { PageLoading } from '../../loading';
import { BlockFloorRoom } from '../../policies/blockFloorRoom/BlockFloorRoom';
import { useDispatch } from 'react-redux';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import LargeTooltip from '../../largeTooltip';

const useStyles = makeStyles((theme) => ({
    page: {
        width: '96%',
        margin: 'auto'
    },
    formBlock: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    inputBlock: {
        width: '33%',
        height: '80px'
    },
    autoInputBlock: {
        width: '95%',
        height: 'auto',
        padding: '24px 5% 4px 5%',
        background: '#F0F0F0',
        borderRadius: '6px',
        '& .css-1auycx3-MuiAutocomplete-root .MuiOutlinedInput-root': {
            padding: '3px',
        }
    },
    inputStyle: {
        width: '90%'
    },
    label: {
        margin: '0',
        fontSize: '14px',
        lineHeight: '140%',
        color: '#000000',
        display: 'inline-block'
    },
    title: {
        width: '100%',
        marginBottom: '5px',
        fontSize: '18px'
    },
    dividerStyle: {
        width: '100%',
        marginBottom: '20px!important',
        marginTop: '10px!important'
    },
    actionBlock: {
        marginRight: '4%',
        marginTop: '15px'
    },
    radio: {
        '&$checked': {
            color: theme.menuBackground
        }
    },
    checked: {},
    autocompleteStyle: {
        '& input#tags-outlined': {
            minWidth: '0!important'
        },
        width: '100%',
        background: '#FFFFFF',
    },
    errorTextStyle: {
        color: 'red',
        fontSize: '12px',
        height: '20px',
        paddingTop: '3px'
    },
    requestError: {
        width: '100%',
        height: '65vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20px',
        color: '#6D6D6D'
    },
    noteStyle: {
        fontStyle: 'italic',
        fontWeight: '700',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#707070'
    },
    displayFlex: {
        display: 'flex'
    },
    openDoorButton: {
        marginTop: '15px'
    },
    select: {
        '& .MuiOutlinedInput-input': {
            padding: '10px 12px'
        }
    },
    input: {
        width: '90%'
    },
}));

const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);
const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);

const EditTommi = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const [device, setDevice] = useState({
        deviceName: '',
        deviceIp: '',
        deviceType: 'Tommi',
        deviceSn: '',
        workingMode: 'online',
        policies: {}
    });
    const [alert, setAlert] = useState({
        deviceName: '',
        deviceIp: '',
        deviceType: '',
        deviceSn: '',
        blockPolicy: '',
        roomPolicy: '',
        servicePolicy: '',
        success: '',
        error: ''
    });
    const [type, setType] = useState('block');
    const [services, setServices] = useState([]);
    const [selectedService, setSelectedService] = useState([]);
    const [selectedServiceIndex, setSelectedServiceIndex] = useState([]);
    const [request, setRequest] = useState('');
    const [blockPoliciesData, setBlockPoliciesData] = useState([]);
    const [roomPoliciesData, setRoomPoliciesData] = useState([]);
    const [isChoosePolicy, setIsChoosePolicy] = useState(false);
    const [apartmentData, setApartmentData] = useState([]);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const [isOpenDoor, setIsOpenDoor] = useState(false);
    const [isPinging, setIsPinging] = useState(false);
    const [isExpiredSession, setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const { deviceID } = useParams();
    const deviceSnRef = useRef();

    useEffect(() => {
        if (isExpiredSession) {
            history.push('/login');
            dispatch({ type: 'IS_LOGIN_AGAIN_TRUE' });
        }
    }, [isExpiredSession]);

    const onBackClick = () => {
        history.replace('/admin/device-management/devices');
    }

    const onChoosePolicyClick = () => {
        if (isEdit) {
            setIsChoosePolicy(true);
        }
    }

    const onEditClick = () => {
        setIsEdit(true);
    }

    const onCancelEditClick = () => {
        setIsEdit(false);
        setLoading(false);
        setAlert({
            deviceName: '',
            deviceIp: '',
            deviceType: '',
            deviceSn: '',
            blockPolicy: '',
            roomPolicy: '',
            servicePolicy: '',
            success: '',
            error: ''
        })
    }

    const onCancelBlockPolicyClick = () => {
        setBlockPoliciesData([]);
        setRoomPoliciesData([]);
        setDevice({ ...device, policies: {} });
    }

    const getDefaultValues = async (serviceList) => {

        try {
            let res = await apiDeviceGet({ id: deviceID });
            const data = res.data.Items[0];
            setDevice({
                id: data.ID,
                clientId: data.ClientId,
                deviceIp: data.DeviceIp,
                deviceName: data.DeviceName,
                deviceType: data.DeviceType,
                deviceSn: data.DeviceSN,
                policies: data.Policies,
                workingMode: data.WorkingMode,
                enableSameFace: data.EnableSameFace
            })
            if (data.Policies.blockPolicy) {
                setBlockPoliciesData(data.Policies.blockPolicy);
                setType('block');
            } else if (data.Policies.roomPolicy) {
                setRoomPoliciesData(data.Policies.roomPolicy);
                setType('room');
            } else {
                var index = [];
                serviceList.forEach((item, key) => {
                    var arr = data.Policies.servicePolicy.filter(value => value.serviceID === item.ID);
                    if (arr.length === 1) {
                        index.push(key);
                    }
                })
                setSelectedServiceIndex(index);
                setSelectedService(data.Policies.servicePolicy);
                setType('service');
            }
        } catch (error) {
            if (error.response ?.status === 401) {
                return setIsExpiredSession(true);
            }
            setRequest(t('Không lấy được dữ liệu'));
        }
        setLoading(true);
    }

    const getServiceList = async () => {
        try {
            var res = await getBookingService();
            var data = [];
            res.data.Items.forEach(item => {
                data.push({
                    serviceID: item.ID,
                    serviceName: item.ServiceName
                })
            })
            setServices(data);
            await getDefaultValues(res.data.Items);
        } catch (error) {
            if (error.response ?.status === 401) {
                return setIsExpiredSession(true);
            }
        }
    }

    const getBlocks = async () => {
        try {
            var res = await apiGetapartmentdata();
            setApartmentData(res.data.Items);
        } catch (error) {
            if (error.response ?.status === 401) {
                return setIsExpiredSession(true);
            }
        }
    }

    useEffect(() => {
        if (!loading) {
            getServiceList();
            getBlocks();
        }
    }, [loading])

    const onNameChange = (e) => {
        const name = e.target.value;
        setDevice({
            ...device,
            deviceName: name
        });
        if (isEmpty(name)) {
            setAlert({
                ...alert,
                deviceName: t('Không được để trống')
            });
        } else if (!checkDisplayName(name)) {
            setAlert({
                ...alert,
                deviceName: t('Không được có ký tự đặc biệt')
            });
        } else if (name.length < 6 || name.length > 30) {
            setAlert({
                ...alert,
                deviceName: t('Độ dài ký tự trong khoảng từ 6 đến 30')
            });
        } else {
            setAlert({
                ...alert,
                deviceName: ''
            });
        }
    }

    const onDeviceTypeChange = (e) => {
        const value = e.target.value;
        setDevice({
            ...device,
            deviceType: value
        });
        if (isEmpty(value)) {
            setAlert({
                ...alert,
                deviceType: t('Không được để trống')
            });
        } else {
            setAlert({
                ...alert,
                deviceType: ''
            });
        }
    }

    const onDeviceIpChange = (e) => {
        const value = e.target.value;
        setDevice({ ...device, deviceIp: value });
        if (isEmpty(value)) {
            setAlert({
                ...alert,
                deviceIp: t('Không được để trống')
            });
        } else {
            if (!checkIPv4(value)) {
                setAlert({
                    ...alert,
                    deviceIp: t('IP không hợp lệ')
                });
            } else {
                setAlert({
                    ...alert,
                    deviceIp: ''
                });
            }
        }
    }

    const onSnChange = (e) => {
        const value = e.target.value;
        setDevice({
            ...device,
            deviceSn: value
        });
        if (deviceSnRef.current) clearTimeout(deviceSnRef.current);
        deviceSnRef.current = setTimeout(async () => {
            if (isEmpty(value)) {
                setAlert({
                    ...alert,
                    deviceSn: t('Không được để trống')
                });
            } else {
                try {
                    const res = await apiTommiExist({ deviceSn: value });
                    if (res.data === 'Found') {
                        setAlert({
                            ...alert,
                            deviceSn: t('Số seri của thiết bị đã tồn tại')
                        });
                    } else {
                        setAlert({
                            ...alert,
                            deviceSn: ''
                        });
                    }
                } catch (error) {

                }
            }
        }, 500);
    }

    const onTypeChange = (e) => {
        setType(e.target.value);
        setBlockPoliciesData([]);
        setRoomPoliciesData([]);
        setSelectedServiceIndex([]);
        setDevice({
            ...device,
            policies: {}
        })
    }

    const onWorkingModeChange = (e) => {
        setDevice({
            ...device,
            workingMode: e.target.value
        })
    }

    const onEnableSameFaceChange = (e) => {
        if (e.target.value === 'false') {
            setDevice({
                ...device,
                enableSameFace: false
            })
        } else {
            setDevice({
                ...device,
                enableSameFace: true
            })
        }
    }

    const onServiceChange = (e, newValue) => {
        var index = [];
        services.forEach((item, key) => {
            var arr = newValue.filter(value => value.serviceID === item.serviceID);
            if (arr.length === 1) {
                index.push(key);
            }
        })
        setSelectedServiceIndex(index);
        setSelectedService(newValue);
        setDevice({
            ...device,
            workingMode: 'online',
            policies: {
                servicePolicy: newValue
            }
        });
        if (newValue.length === 0) {
            setAlert({
                ...alert, servicePolicy: t('Không được để trống')
            });
        } else {
            setAlert({
                ...alert, servicePolicy: ''
            });
        }
    }

    const onBlockPolicyChange = (e, newValue) => {
        var data = {
            blockPolicy: newValue
        }
        setBlockPoliciesData(data.blockPolicy);
        setDevice({
            ...device,
            policies: data
        });
    }

    const onRoomPolicyChange = (e, newValue) => {
        var data = {
            roomPolicy: newValue
        }
        setRoomPoliciesData(data.roomPolicy);
        setDevice({
            ...device,
            policies: data
        });
    }

    const onSaveBlockPoliciesClick = (blockData, roomData) => {
        var data = {};
        if (type === 'block') {
            data = {
                blockPolicy: blockData
            }
            setBlockPoliciesData(blockData);
        } else if (type === 'room') {
            data = {
                roomPolicy: roomData
            }
            setRoomPoliciesData(roomData);
        }
        setAlert({
            ...alert,
            blockPolicy: '',
            roomPolicy: '',
            servicePolicy: ''
        })
        setDevice({ ...device, policies: data });
        setIsChoosePolicy(false);
    }

    const onUpdateClick = () => {
        if (isEmpty(device.deviceName)) {
            return setAlert({ ...alert, deviceName: t('Không được để trống') });
        }
        if (isEmpty(device.deviceSn)) {
            return setAlert({ ...alert, deviceSn: t('Không được để trống') });
        }
        if (isEmpty(device.deviceIp)) {
            return setAlert({ ...alert, deviceIp: t('Không được để trống') });
        }
        if (type === 'block') {
            if (device.policies.blockPolicy) {
                if (device.policies.blockPolicy.lenth === 0) {
                    return setAlert({ ...alert, blockPolicy: t('Không được để trống') });
                }
            } else {
                return setAlert({ ...alert, blockPolicy: t('Không được để trống') });
            }
        } else if (type === 'room') {
            if (device.policies.roomPolicy) {
                if (device.policies.roomPolicy.lenth === 0) {
                    return setAlert({ ...alert, roomPolicy: t('Không được để trống') });
                }
            } else {
                return setAlert({ ...alert, roomPolicy: t('Không được để trống') });
            }
        } else {
            if (device.policies.servicePolicy) {
                if (device.policies.servicePolicy.lenth === 0) {
                    return setAlert({ ...alert, servicePolicy: t('Không được để trống') });
                }
            } else {
                return setAlert({ ...alert, servicePolicy: t('Không được để trống') });
            }
        }
        if (alert.deviceName !== '' || alert.deviceSn !== '' || alert.deviceIp !== '') return;
        setIsConfirm(true);
    }

    const onSaveConfirmClick = async () => {
        try {
            setIsUpdating(true);
            await apiTommiUpdate(device);
            setAlert({
                ...alert,
                success: t('Lưu thay đổi thành công!'),
                error: ''
            })
            setLoading(false);
            setIsEdit(false);
        } catch (error) {
            if (error.response ?.status === 401) {
                return setIsExpiredSession(true);
            }
            setAlert({
                ...alert,
                error: t('Lưu không thành công, vui lòng thử lại sau!'),
                success: ''
            })
        }
        setIsUpdating(false);
        setIsConfirm(false);
    }

    const onCloseConfirmClick = () => {
        setIsConfirm(false);
    }

    const onCloseDialog = () => {
        setAlert({
            ...alert,
            success: '',
            error: ''
        });
    }

    useEffect(() => {
        if (alert.success !== '' || alert.error !== '') {
            setTimeout(() => {
                onCloseDialog();
            }, 5000);
        }
    }, [alert.success])

    const getTilte = () => {
        if (type === 'block') {
            return t('Phân quyền mở cửa tòa/ tầng');
        } else if (type === 'room') {
            return t('Phân quyền mở cửa phòng');
        }
    }

    const onOpenDoorClick = async () => {
        setIsOpenDoor(true);
        try {
            await apiTommiOpen({
                clientId: device.clientId,
                id: device.id
            });
            setAlert({
                ...alert,
                success: t('Mở cửa thành công!'),
                error: ''
            })
        } catch (error) {
            setAlert({
                ...alert,
                error: t('Không thể kết nối kết thiết bị, vui lòng thử lại sau!'),
                success: ''
            })
        }
        setIsOpenDoor(false);
    }

    const onCloseDialogClick = () => {
        setIsChoosePolicy(false);
    }

    const showServiceValue = () => {
        var data = [];
        selectedServiceIndex.forEach(item => {
            data.push(services[item]);
        })
        return data;
    }

    const onPingClick = async () => {
        setIsPinging(true);
        try {
            await apiTommiPing({
                clientId: device.clientId,
                id: device.id
            })
            setAlert({
                ...alert,
                success: t('Gửi PING thành công!'),
                error: ''
            })
        } catch (error) {
            setAlert({
                ...alert,
                error: t('Gửi PING không thành công, vui lòng thử lại sau!'),
                success: ''
            })
        }
        setIsPinging(false);
    }

    return (
        <div className={classes.page}>
            <BlockFloorRoom
                title={getTilte()}
                isChoosePolicy={isChoosePolicy}
                closeDialog={onCloseDialogClick}
                blockData={blockPoliciesData}
                roomData={roomPoliciesData}
                type={type}
                onCancelClick={onCancelBlockPolicyClick}
                onSaveClick={(blockData, roomData) => onSaveBlockPoliciesClick(blockData, roomData)}
                apartmentData={apartmentData}
                CreateButton={CreateButton}
                CancelButton={CancelButton}
            />
            <Confirm
                isOpen={isConfirm}
                handleClose={onCloseConfirmClick}
                isLoading={isUpdating}
                mess={t('Bạn có chắc chắn muốn lưu thay đổi?')}
                onConfirm={onSaveConfirmClick}
            />
            <SuccessAlert
                message={alert.success}
                onCloseDialog={onCloseDialog}
            />
            <ErrorAlert
                message={alert.error}
                onCloseDialog={onCloseDialog}
            />
            <div className={classes.displayFlex}>
                <SubDetailTitle
                    onClick={onBackClick}
                    text={isEdit ? t('Chỉnh sửa thiết bị') : t('Chi tiết thiết bị')}
                />
                <div style={{ flex: 1 }}></div>
                {!isEdit && <CreateButton
                    variant="contained"
                    className={classes.openDoorButton}
                    onClick={onOpenDoorClick}
                    disabled={isOpenDoor}
                >
                    {t('Mở cửa')}
                    {isOpenDoor && <CircularProgress size={25} style={{ position: 'absolute', color: '#0b59c8' }} />}
                </CreateButton>}
                {!isEdit && <CreateButton
                    variant="contained"
                    className={classes.openDoorButton}
                    onClick={onPingClick}
                    disabled={isPinging}
                >
                    {t('PING')}
                    {isPinging && <CircularProgress size={25} style={{ position: 'absolute', color: '#0b59c8' }} />}
                </CreateButton>}
                {
                    !isEdit &&
                    <div className={classes.actionBlock}>
                        <CreateButton
                            variant="contained"
                            onClick={onEditClick}
                        >
                            {t('Chỉnh sửa')}
                        </CreateButton>
                    </div>
                }
                {
                    isEdit &&
                    <div className={classes.actionBlock}>
                        <CancelButton
                            variant="outlined"
                            onClick={onCancelEditClick}
                        >
                            {t('Hủy')}
                        </CancelButton>
                        <CreateButton
                            variant="contained"
                            onClick={onUpdateClick}
                        >
                            {t('Lưu')}
                        </CreateButton>
                    </div>
                }
            </div>
            {request !== '' && <div className={classes.requestError}>{request}</div>}
            {!loading && <PageLoading />}
            {
                loading && request === '' &&
                <div className={classes.formBlock}>
                    <div className={classes.title}>{t('Thông tin thiết bị')}</div>
                    <div className={classes.inputBlock}>
                        <label className={classes.label}>{t('Tên thiết bị')} <span style={{ color: 'red' }}>(*)</span></label><br />
                        <TextField
                            classes={{
                                root: classes.inputStyle
                            }}
                            value={device.deviceName}
                            size="small"
                            placeholder={t("Tên thiết bị")}
                            variant="outlined"
                            onChange={onNameChange}
                            disabled={!isEdit}
                            helperText={alert.deviceName}
                            error={alert.deviceName !== ''}
                        />
                    </div>
                    <div className={clsx(classes.inputBlock, classes.select)}>
                        <FormControl required variant="outlined" classes={{ root: classes.input }}>
                            <label className={classes.label}>{t('Loại thiết bị')} </label>
                            <Select
                                value={device.deviceType}
                                style={{ height: '40px', background: '#FFFFFF'}}
                                displayEmpty
                                disabled={!isEdit}
                            >
                                <MenuItem value={device.deviceType} >{device.deviceType}</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    {/* <div className={classes.inputBlock}>
                        <label className={classes.label}>{t('Loại thiết bị')} <span style={{ color: 'red' }}>(*)</span></label><br />
                        <TextField
                            classes={{
                                root: classes.inputStyle
                            }}
                            inputProps={{
                                readOnly: true
                            }}
                            value={device.deviceType}
                            disabled={!isEdit}
                            size="small"
                            placeholder={t("Loại thiết bị")}
                            variant="outlined"
                            onChange={onDeviceTypeChange}
                        />
                    </div> */}
                    <div className={classes.inputBlock}>
                        <label className={classes.label}>{t('Số seri của thiết bị')} <span style={{ color: 'red' }}>(*)</span></label><br />
                        <TextField
                            classes={{
                                root: classes.inputStyle
                            }}
                            value={device.deviceSn}
                            disabled={!isEdit}
                            size="small"
                            placeholder={t("Số seri của thiết bị")}
                            variant="outlined"
                            onChange={onSnChange}
                            helperText={alert.deviceSn}
                            error={alert.deviceSn !== ''}
                        />
                    </div>
                    <div className={classes.inputBlock}>
                        <label className={classes.label}>{t('IP của thiết bị')} <span style={{ color: 'red' }}>(*)</span></label><br />
                        <TextField
                            classes={{
                                root: classes.inputStyle
                            }}
                            value={device.deviceIp}
                            disabled={!isEdit}
                            size="small"
                            placeholder={t("IP của thiết bị")}
                            variant="outlined"
                            onChange={onDeviceIpChange}
                            helperText={alert.deviceIp}
                            error={alert.deviceIp !== ''}
                        />
                    </div>
                    <div className={classes.inputBlock}>
                        <label className={classes.label}>{t('ClientID')}</label><br />
                        <TextField
                            classes={{
                                root: classes.inputStyle
                            }}
                            name="device_ip"
                            size="small"
                            placeholder={t("ClientID")}
                            variant="outlined"
                            value={device.clientId}
                            disabled={!isEdit}
                            disabled
                        />
                    </div>
                    <Divider className={classes.dividerStyle} />
                    <div className={classes.title}>
                        {t('Chế độ quét khuôn mặt')}
                        <LargeTooltip
                            title={
                                <div>
                                    {t('Trực tuyến')}: {t('Quét khuôn mặt, phía backend sẽ xử lý khuôn mặt, hợp lệ mới cho phép thiết bị TOMMI mở cửa')} <br />
                                    {t('Ngoại tuyến')}: {t('Quét khuôn mặt và mở cửa trên thiết bị TOMMI')}
                                </div>
                            }
                        >
                            <HelpOutlineIcon style={{ fontSize: '16px', marginLeft: '5px' }} />
                        </LargeTooltip>
                    </div>
                    <div>
                        <RadioGroup
                            row
                            onChange={onWorkingModeChange}
                            value={device.workingMode}
                        >
                            <FormControlLabel
                                value="online"
                                disabled={!isEdit}
                                control={
                                    <Radio
                                        color="primary"
                                        classes={{ root: classes.radio, checked: classes.checked }}
                                        size="small"
                                    />}
                                label={t('Trực tuyến')}
                            />
                            <FormControlLabel
                                value="offline"
                                disabled={!isEdit || device.policies.servicePolicy}
                                control={
                                    <Radio 
                                        color="primary"
                                        classes={{ root: classes.radio, checked: classes.checked }}
                                        size="small"
                                    />}
                                label={t('Ngoại tuyến')}
                            />
                        </RadioGroup>
                    </div>
                    <Divider className={classes.dividerStyle} />
                    {/* <div className={classes.title}>
                        {t('Cho phép trùng khuôn mặt trên thiết bị')}
                    </div>
                    <div>
                        <RadioGroup
                            row
                            onChange={onEnableSameFaceChange}
                            value={device.enableSameFace}
                        >
                            <FormControlLabel
                                value={true}
                                disabled={!isEdit}
                                control={
                                    <Radio
                                        color="primary"
                                        classes={{ root: classes.radio, checked: classes.checked }}
                                        size="small"
                                    />}
                                label={t('Cho phép')}
                            />
                            <FormControlLabel
                                value={false}
                                disabled={!isEdit}
                                control={
                                    <Radio color="primary"
                                        classes={{ root: classes.radio, checked: classes.checked }}
                                        size="small"
                                    />}
                                label={t('Không cho phép')}
                            />
                        </RadioGroup>
                    </div>
                    <Divider className={classes.dividerStyle} /> */}
                    <div className={classes.title}>{t('Phân quyền mở cửa')}</div>
                    <div>
                        <RadioGroup
                            row
                            onChange={onTypeChange}
                            value={type}
                            disabled={!isEdit}
                        >
                            <FormControlLabel
                                value="block"
                                disabled={!isEdit}
                                control={
                                    <Radio
                                        color="primary"
                                        classes={{ root: classes.radio, checked: classes.checked }}
                                        size="small"
                                    />}
                                label={t('Tòa/ Tầng')}
                            />
                            <FormControlLabel
                                value="room"
                                disabled={!isEdit}
                                control={
                                    <Radio
                                        color="primary"
                                        classes={{ root: classes.radio, checked: classes.checked }}
                                        size="small"
                                    />}
                                label={t('Phòng')}
                            />
                            <FormControlLabel
                                value="service"
                                disabled={!isEdit}
                                control={
                                    <Radio color="primary"
                                        classes={{ root: classes.radio, checked: classes.checked }}
                                        size="small"
                                    />}
                                label={t('Dịch vụ')}
                            />
                        </RadioGroup>
                    </div>
                    {
                        type === 'block' &&
                        <div className={classes.autoInputBlock}>
                            <MultipleAutocomplete
                                multiple
                                classes={{ inputRoot: classes.autocompleteStyle }}
                                disabled={!isEdit}
                                id="tags-outlined"
                                open={false}
                                options={blockPoliciesData}
                                value={blockPoliciesData}
                                size="small"
                                getOptionLabel={(option) => {
                                    let mess = "";
                                    if (option.block === "all") {
                                        mess = `${t("Tất cả tòa")} - `
                                    } else {
                                        mess = `${t("Tòa")} ${option.block} - `
                                    }
                                    if (option.floor === "all") {
                                        mess += `${t("Tất cả tầng")}`
                                    } else {
                                        mess += `${t("Tầng")} ${option.floor}`
                                    }
                                    return mess;
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder={blockPoliciesData.length === 0 ? t('Tòa/ Tầng') : t('')}
                                        disabled
                                        onClick={onChoosePolicyClick}
                                    />
                                )}
                                onChange={(e, newValue) => onBlockPolicyChange(e, newValue)}
                            />
                            <p className={classes.errorTextStyle}>{alert.blockPolicy}</p>
                            <p className={classes.noteStyle}>{t('Lưu ý')}: {t('Khi thay đổi phân quyền của thiết bị. Thiết bị sẽ cần một khoảng thời gian để đồng bộ.')}</p>
                        </div>
                    }
                    {
                        type === 'room' &&
                        <div className={classes.autoInputBlock}>
                            <MultipleAutocomplete
                                multiple
                                classes={{ inputRoot: classes.autocompleteStyle }}
                                disabled={!isEdit}
                                id="tags-outlined"
                                open={false}
                                options={roomPoliciesData}
                                size="small"
                                value={roomPoliciesData}
                                getOptionLabel={(option) => {
                                    let mess = "";
                                    if (option.block === "all") {
                                        mess = `${t("Tất cả tòa")} - `
                                    } else {
                                        mess = `${t("Tòa")} ${option.block} - `
                                    }
                                    if (option.floor === "all") {
                                        mess += `${t("Tất cả tầng")} - `
                                    } else {
                                        mess += `${t("Tầng")} ${option.floor} - `
                                    }
                                    if (option.room === "all") {
                                        mess += `${t("Tất cả phòng")}`
                                    } else {
                                        mess += `${t("Phòng")} ${option.room}`
                                    }
                                    return mess;
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder={roomPoliciesData.length === 0 ? t('Phòng') : t('')}
                                        disabled
                                        onClick={onChoosePolicyClick}
                                    />
                                )}
                                onChange={(e, newValue) => onRoomPolicyChange(e, newValue)}
                            />
                            <p className={classes.errorTextStyle}>{alert.roomPolicy}</p>
                            <p className={classes.noteStyle}>{t('Lưu ý')}: {t('Khi thay đổi phân quyền của thiết bị. Thiết bị sẽ cần một khoảng thời gian để đồng bộ.')}</p>
                        </div>
                    }
                    {
                        type === 'service' &&
                        <div className={classes.autoInputBlock}>
                            <MultipleAutocomplete
                                multiple
                                classes={{ inputRoot: classes.autocompleteStyle }}
                                size="small"
                                disabled={!isEdit}
                                id="tags-outlined"
                                options={services}
                                value={showServiceValue()}
                                getOptionLabel={(option) => option.serviceName}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder={selectedService.length === 0 ? t('Dịch vụ') : t('')}
                                        disabled
                                    />
                                )}
                                onChange={(e, newValue) => onServiceChange(e, newValue)}
                            />
                            <p className={classes.errorTextStyle}>{alert.servicePolicy}</p>
                            <p className={classes.noteStyle}>{t('Lưu ý')}: {t('Khi thay đổi phân quyền của thiết bị. Thiết bị sẽ cần một khoảng thời gian để đồng bộ.')}</p>
                        </div>
                    }
                    <Divider className={classes.dividerStyle} />
                </div>
            }
        </div>
    )
}

export default EditTommi;