import { FONT_FAMILY } from "../../constants/cssCommon";

const GET_CONFIG = "GET_CONFIG";
const CHANGE_STATUS_LOADING = "CHANGE_STATUS_LOADING";
const CHANGE_STATUS_LOADING_FALSE = "CHANGE_STATUS_LOADING_FALSE";
const CHANGE_IS_UPDATED_THEME = 'CHANGE_IS_UPDATED_THEME';
const initState = {
    logo:'',
    menu_background:'',
    theme_color:'',
    font_family: FONT_FAMILY,
    loading: false,
    isUpdatedTheme:false,
}
const ConfigReducer = (state = initState, action) =>{
    switch (action.type) {
        case GET_CONFIG:
            return {...state,logo:action.logo, menu_background:action.menu_background,theme_color:action.theme_color}
        case CHANGE_STATUS_LOADING:
            return {...state,loading:true}
        case CHANGE_STATUS_LOADING_FALSE:
            return {...state,loading:false}
        case CHANGE_IS_UPDATED_THEME:
            return {...state,isUpdatedTheme:!state.isUpdatedTheme}
        default:
            return state;
    }

} 

export default ConfigReducer;