import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Cookies from 'js-cookie';
import moment from 'moment-timezone';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import CircularProgress from '@material-ui/core/CircularProgress';
import SuccessAlert from '../../alert/SuccessAlert';
import ErrorAlert from '../../alert/ErrorAlert';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import makeStyles from '@material-ui/styles/makeStyles';
import axios from 'axios';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import vi from 'date-fns/locale/vi';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@material-ui/core/IconButton/IconButton';
import { apiHistoryGetAll, apiHistoriesDetails } from '../../../api/histories';

const useStyles = makeStyles(theme => ({
    dialogTitle: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '25px',
        color: '#FFFFFF',
    },
    contentDialog: {
        fontSize: '16px',
        height: '68px',
        borderBottom: '1px solid #EEEDF2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    dialogTitleBackground: theme.primaryColor,
    popupTitleStyle: {
        borderBottom: '1px solid #E0E0E0',
        height: '40px',
        position: 'relative',
        fontWeight: '600',
        fontSize: '20px',
        lineHeight: '28px'
    },
    iconCloseStyle: {
        position: 'absolute',
        right: '0'
    },
    inputBlock: {
        width: '90%',
        height: '100px',
        margin: 'auto'
    },
    label: {
        margin: '0',
        fontSize: '16px',
        lineHeight: '22px'
    },
    circularProgressStyle: {
        position: 'absolute',
        color: '#0b59c8'
    },
    pickerStyle: {
        position: 'relative',
        top: '-15px',
        width: '100%'
    },
    exportButtonStyle: {
        marginTop: '7px',
        marginLeft: '8px'
    },
    agreeButtonStyle: {
        margin: '10px 0 10px 24px'
    },
    cancelButtonStyle: {
        margin: '10px 0'
    }
}))

const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);
const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);

const ExportData = (props) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const { historyPerPage } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [isExporting, setIsExporting] = useState(false);
    const [alert, setAlert] = useState({
        success: '',
        error: '',
        fromDay: '',
        toDay: ''
    });
    const [isConfirm, setIsConfirm] = useState(false);
    const [confirmHugeExport, setConfirmHugeExport] = useState(false);
    const [dataUrl, setDataUrl] = useState('');
    const [fromDay, setFromDay] = useState(null);
    const [toDay, setToDay] = useState(null);
    const [isPopup, setIsPopup] = useState(false);
    const [isDay, setIsDay] = useState(false);
    const [isMonth, setIsMonth] = useState(false);
    const alertRef = useRef();

    const handleClose = () => {
        setAnchorEl(null)
    }

    const showApprovedBy = (data) => {
        if (data.Type === 'call')
            if (data.CalleeFullName !== '')
                if (data.CalleeType === "Receptionist") return data.CalleeFullName;
                else return `${data.Callee} - ${data.CalleeFullName}`;
            else return '';
        if (data.Type === 'qr' && data.QRType !== 'totp')
            return data.ApartmentOwner ? `${data.ApartmentOwner} - ${data.FullName}` : data.FullName;
        return '';
    }

    const showType = (data) => {
        if (data.Type === 'call') return t("Gọi Video");
        return data.Type === 'qr' ? t('Mã QR') : 'Face ID';
    }

    const getAllAccess = async () => {
        setIsExporting(true);
        var dataUrl = await apiHistoryGetAll({});
        var res = await axios.get(dataUrl.data);
        if (res.data.Count > 5000 && !confirmHugeExport) {
            setIsExporting(false);
            setConfirmHugeExport(true);
            setIsConfirm(true);
            return null;
        }
        var data = [];
        res.data.Items.forEach(item => {
            if (item.Type === 'faceid') data.push({ ...item, id: item.ID + '-' + item.Type, Time: parseInt(item.VerificationTime), IsOpenDoor: true });
            else if (item.Type === 'call')
                if (typeof (item.VerificationTime) === 'undefined' || item.VerificationTime === null) data.push({ ...item, id: item.ID + '-' + item.Type, Time: '' });
                else data.push({ ...item, id: item.ID + '-' + item.Type, Time: item.VerificationTime });
            else data.push({ ...item, id: item.ID + '-' + item.Type, Time: item.VerifyTime, IsOpenDoor: item.VerificationStatus === 'Passed' ? true : false });
        })
        setIsExporting(false);
        return data;
    }

    const handleDataToExportFile = async (all) => {
        var histories = {};
        if (all) histories = await getAllAccess();
        else histories = historyPerPage;
        if (histories === null) return null;
        const data = [];
        histories.forEach(item => {
            var name = '';
            if (item.Type === 'faceid' || (item.Type === 'qr' && item.QRType === 'totp'))
                item.ApartmentOwner ? name = `${item.ApartmentOwner} - ${item.FullName}` : name = item.FullName
            else name = t("Khách")
            var temp = '';
            if (item.Type === 'faceid' && parseInt(item.Temperature) !== -1) temp = item.Temperature;
            data.push({
                FullName: name,
                ApprovedBy: showApprovedBy(item),
                Device: item.Type === 'call' ? item.CallerFullName : item.DeviceName,
                Time: item.Time ? moment(item.Time).format('DD-MM-YYYY HH:mm:ss') : '',
                Method: showType(item),
                Temperature: temp,
                DoorStatus: item.IsOpenDoor ? t('Đã mở') : t('Từ chối'),
            })
        })
        return data;
    }

    const exportFileCSV = async (all) => {
        try {
            setAnchorEl(null);
            const historyData = await handleDataToExportFile(all);
            if (historyData === null) return;
            const header = [[
                t('Họ và tên'),
                t('Chấp nhận bởi'),
                t('Thiết bị'),
                t('Thời gian xác thực'),
                t('Phương thức'),
                t('Nhiệt độ'),
                t('Trạng thái cửa')
            ]];
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel;charset=UTF-8';
            const fileExtension = '.csv';

            const ws = XLSX.utils.book_new();
            XLSX.utils.sheet_add_aoa(ws, header);

            XLSX.utils.sheet_add_json(ws, historyData, { origin: 'A2', skipHeader: true });
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, `Lich_su_ra_vao_${moment().format('DD_MM_YYYY_HH_mm_ss')}` + fileExtension);
            setAlert({ success: t('Xuất dữ liệu thành công!'), error: '' })
            setConfirmHugeExport(false);
            setIsConfirm(false);
        } catch (error) {
            setAlert({ error: t('Xuất dữ liệu không thành công, vui lòng thử lại sau!'), success: '' });
            setIsExporting(false);
        }
    }

    const sortDataViaTime = (timeA, timeB) => {
        if (timeA > timeB) return 1;
        if (timeA < timeB) return -1;
        return 0;
    }

    const changeDateFormat = (data) => {
        var handledData = [];
        data.forEach(item => {
            handledData.push({
                ...item,
                time: moment(item.time).format('D/M/YYYY')
            })
        })
        return handledData;
    }

    const changeMonthFormat = (data) => {
        var handledData = [];
        data.forEach(item => {
            handledData.push({
                ...item,
                time: moment(item.time).format('MM-YYYY')
            })
        })
        return handledData;
    }

    const handleMonthsData = (res) => {
        var reportData = [];
        var timeArray = Object.keys(res.data.Items[0].Value);
        var values = Object.values(res.data.Items[0].Value);
        timeArray.forEach((item, key) => {
            var date = new Date(item);
            date.setHours(0, 0, 0, 0);
            if (date.getTime() >= fromDay && date.getTime() <= toDay) {
                var qr = 0;
                var call = 0;
                var faceid = 0;
                var monthData = Object.values(values[key]);
                monthData.forEach(item => {
                    qr += item.qr;
                    call += item.call;
                    faceid += item.faceid;
                })
                reportData.push({
                    time: timeArray[key],
                    faceid: faceid,
                    call: call,
                    qr: qr
                })
            }
        })
        reportData.sort((a, b) => sortDataViaTime(a.time, b.time));
        return changeMonthFormat(reportData);
    }

    const handleDaysData = (res) => {
        var reportData = [];
        var timeArray = Object.keys(res.data.Items[0].Value);
        var values = Object.values(res.data.Items[0].Value);
        values.forEach((item) => {
            var monthData = Object.values(item);
            var titles = Object.keys(item);
            titles.forEach((value, key) => {
                var date = new Date(value);
                date.setHours(0, 0, 0, 0);
                if (date.getTime() >= fromDay && date.getTime() <= toDay) {
                    reportData.push({
                        time: value,
                        faceid: monthData[key].faceid,
                        call: monthData[key].call,
                        qr: monthData[key].qr
                    })
                }

            })
        })
        reportData.sort((a, b) => sortDataViaTime(a.time, b.time));
        return changeDateFormat(reportData);
    }

    const exportReportFIle = async (type) => {
        try {
            if (isDay || isMonth) {
                if (fromDay === null) {
                    return setAlert({ ...alert, fromDay: t('Vui lòng chọn ngày') });
                }
                if (toDay === null) {
                    return setAlert({ ...alert, toDay: t('Vui lòng chọn ngày') });
                }
                if (toDay < fromDay) {
                    return setAlert({ ...alert, toDay: t('Phải sau ngày đã chọn ở trên') });
                }
            }
            setIsExporting(true);
            setAnchorEl(null);
            var res = await apiHistoriesDetails();
            var reportData = []
            if (type === 'days') {
                reportData = handleDaysData(res);
            } else {
                reportData = handleMonthsData(res);
            }
            if (reportData === null) return;
            const header = [[
                t('Thời gian'),
                t('Face ID'),
                t('Gọi Video'),
                t('QR Code')
            ]];
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel;charset=UTF-8';
            const fileExtension = '.csv';

            const ws = XLSX.utils.book_new();
            XLSX.utils.sheet_add_aoa(ws, header);

            XLSX.utils.sheet_add_json(ws, reportData, { origin: 'A2', skipHeader: true });
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, `Bao_cao_${moment().format('DD_MM_YYYY_HH_mm_ss')}` + fileExtension);
            setAlert({
                ...alert,
                success: t('Xuất dữ liệu thành công!'),
                error: ''
            });
            setIsConfirm(false);
        } catch (error) {
            setAlert({
                ...alert,
                error: t('Xuất dữ liệu không thành công, vui lòng thử lại sau!'),
                success: ''
            });
        }
        setIsExporting(false);
        setIsPopup(false);
        setIsDay(false);
        setIsMonth(false);
    }

    const onCancelClick = () => {
        setIsConfirm(!isConfirm);
        setConfirmHugeExport(false);
    }
    const onExportDayDataClick = () => {
        setIsPopup(true);
        setIsDay(true);
        setIsMonth(false);
        setAnchorEl(null);
    }

    const onExportMonthDataClick = () => {
        setIsPopup(true);
        setIsDay(false);
        setIsMonth(true);
        setAnchorEl(null);
    }

    const onClosePopupClick = () => {
        setIsPopup(false);
        setIsDay(false);
        setIsMonth(false);
    }

    const onFormDayChange = (e) => {
        const date = new Date(e);
        date.setHours(0, 0, 0, 0);
        if (!isDay) {
            date.setDate(1);
        }
        setFromDay(date.getTime());
        setAlert({ ...alert, fromDay: '' });
    }

    const onToDayChange = (e) => {
        const date = new Date(e);
        date.setHours(0, 0, 0, 0);
        if (!isDay) {
            date.setDate(1);
        }
        setToDay(date.getTime());
        setAlert({ ...alert, toDay: '' });
    }

    const onAlertClose = () => {
        setAlert({ ...alert, success: '', error: '' });
    }

    useEffect(() => {
        if (alert.success !== '' || alert.error !== '') {
            if (alertRef.current) clearTimeout(alertRef.current);
            alertRef.current = setTimeout(() => {
                onAlertClose();
            }, 5000);
        }
    }, [alert])

    const confirmExport = () => {
        return (
            <Dialog
                open={isConfirm}
                maxWidth="xl"
            >
                <DialogTitle className={classes.dialogTitleBackground}>
                    <p className={classes.dialogTitle}>{t("Thông báo")}</p>
                </DialogTitle>
                <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center', position: 'relative' }}>
                    <div className={classes.contentDialog}>
                        <p>{t("Xuất dữ liệu hơn 5000 lịch sử, bạn có muốn tiếp tục?")}</p>
                    </div>
                    <CancelButton
                        variant="outlined"
                        className={classes.cancelButtonStyle}
                        onClick={onCancelClick}
                        disabled={isExporting}
                    >
                        {t("Trở về")}
                    </CancelButton>
                    <CreateButton
                        variant="contained"
                        className={classes.agreeButtonStyle}
                        onClick={() => exportFileCSV(true)}
                        disabled={isExporting}
                    >
                        {t("Đồng ý")}
                    </CreateButton>
                    {isExporting && <CircularProgress color="primary" size={25} style={{ position: 'absolute', color: '#0b59c8', top: '10%', left: '45%' }} />}
                </DialogContent>
            </Dialog>
        )
    }

    const chooseDayDialog = () => {
        return (
            <Dialog
                open={isPopup}
                maxWidth="xl"
            >
                <DialogTitle>
                    <p className={classes.popupTitleStyle}>
                        {t("Xuất dữ liệu")}
                        <IconButton
                            size="small"
                            className={classes.iconCloseStyle}
                            onClick={onClosePopupClick}
                        >
                            <CloseIcon fontSize="medium" />
                        </IconButton>
                    </p>
                </DialogTitle>
                <DialogContent style={{ width: '448px', padding: '0', position: 'relative' }}>
                    <div className={classes.inputBlock}>
                        <label className={classes.label}>{t('Từ')}</label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === 'vi' ? vi : ''}>
                            <KeyboardDatePicker
                                inputVariant="outlined"
                                className={classes.pickerStyle}
                                size="small"
                                format={isDay ? "dd/MM/yyyy" : "MM/yyyy"}
                                margin="normal"
                                InputProps={{ readOnly: true }}
                                placeholder={t("Từ")}
                                value={fromDay}
                                onChange={onFormDayChange}
                                helperText={alert.fromDay}
                                error={alert.fromDay !== ''}
                                cancelLabel={t("Đóng")}
                                okLabel={t("Đồng ý")}
                                views={isDay ? ["date", "month", "year"] : ["month", "year"]}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className={classes.inputBlock}>
                        <label className={classes.label}>{t('Đến')}</label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === 'vi' ? vi : ''}>
                            <KeyboardDatePicker
                                inputVariant="outlined"
                                className={classes.pickerStyle}
                                size="small"
                                format={isDay ? "dd/MM/yyyy" : "MM/yyyy"}
                                margin="normal"
                                InputProps={{ readOnly: true }}
                                placeholder={t("Đến")}
                                value={toDay}
                                onChange={onToDayChange}
                                helperText={alert.toDay}
                                error={alert.toDay !== ''}
                                cancelLabel={t("Đóng")}
                                okLabel={t("Đồng ý")}
                                views={isDay ? ["date", "month", "year"] : ["month", "year"]}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div style={{ textAlign: 'right', width: '95%' }}>
                        <CreateButton
                            variant="contained"
                            className={classes.agreeButtonStyle}
                            onClick={() => exportReportFIle(isDay ? 'days' : 'months')}
                            disabled={isExporting}
                        >
                            {t("Đồng ý")}
                            {isExporting && <CircularProgress color="primary" size={25} className={classes.circularProgressStyle} />}
                        </CreateButton>

                    </div>

                </DialogContent>
            </Dialog>
        )
    }

    return (
        <div style={{ float: 'left' }}>
            {confirmExport()}
            {chooseDayDialog()}
            <SuccessAlert
                message={alert.success}
                onCloseDialog={onAlertClose}
            />
            <ErrorAlert
                message={alert.error}
                onCloseDialog={onAlertClose}
            />
            <CreateButton
                variant="contained"
                className={classes.exportButtonStyle}
                startIcon={<InsertDriveFileIcon />}
                onClick={(e) => setAnchorEl(e.target)}
                disabled={isExporting}
            >
                {t("Xuất dữ liệu")}
                {isExporting && !confirmHugeExport && !isDay && !isMonth &&
                    <CircularProgress size={25} className={classes.circularProgressStyle} />
                }
            </CreateButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem style={{ color: '#0b59c8' }} onClick={() => exportFileCSV(true)} >{t("Trong 30 ngày")}</MenuItem>
                <MenuItem style={{ color: '#0b59c8' }} onClick={onExportDayDataClick} >{t("Xuất báo cáo theo ngày")}</MenuItem>
                <MenuItem style={{ color: '#0b59c8' }} onClick={onExportMonthDataClick} >{t("Xuất báo cáo theo tháng")}</MenuItem>
            </Menu>
        </div>
    )
}

export default ExportData;
