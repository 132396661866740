import makeStyles from "@material-ui/styles/makeStyles/makeStyles";

export const styles = makeStyles(theme => ({
    addRoom: {
        position: 'fixed',
        top: '0',
        left: '0',
        zIndex: "1202",

        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
        background: 'rgba(0, 0, 0, 0.6)'
    },
    wrapper: {
        maxWidth: '400px',
        margin: 'auto',
        padding: '2rem',
        borderRadius: '8px',
        width: '80%',
        background: 'white',
    },
    title: {
        display: "flex",
        justifyContent: "center",
        fontSize: "20px",
        marginBottom: "2rem"
    },
    textFied: {
        width: '100%',
        marginBottom: '2rem !important'
    },
    btnContain: {
        display: "flex",
        justifyContent: "flex-end"
    }
}))