import makeStyles from "@material-ui/styles/makeStyles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    minHeight: "100%",
  },
  primaryColor: theme.primaryTextColor,
  secondaryTitle: theme.secondary_title,

  contentBottomForm: {
    width: "98%",
    margin: "auto",
  },
  customButton: {
    backgroundColor: "lightGray",
    fontWeight: "600",
    color: "black",
    width: "100px",
  },
  cabin: {
    borderRadius: "8px",
    backgroundColor: "#efefef",
    height: "calc(100vh - 80px)",
    padding: "1rem",
  },
  label: {
    color: "#616161",
    height: "22px",
    lineHeight: "140%",
    fontWeight: "400",
    fontSize: "16px"
  },
  info: {
    color: "black",
    marginBottom: "1rem",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "140%",
  },
  containSize: {
    display: "grid",
    gridTemplateColumns:
      "calc(25% - 16px) calc(25% - 16px) calc(25% - 16px) calc(25% - 16px)",
    gap: "16px",
  },
  times: {
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "160%",
    fontWeight: "400",
  },
}));
