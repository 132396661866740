import React,{useEffect,useState, useRef} from 'react';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import {Link,Route,useLocation} from 'react-router-dom';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import PaymentTable from './payments/PaymentTable';
import AddPaymentInfo from './payments/AddPaymentInfo';
import MomoHistory from './history/MomoHistory';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const useStyles = makeStyles((theme)=>({
    topBlock:{marginTop:'22px',marginBottom:'20px',height:'40px'},
    primaryTitle:theme.primary_title,
    secondaryTitle:theme.secondary_title,
    menu:{height:'100%',width:'20%',marginRight:'1%',background:'#FFFFFF',borderRadius:'6px'},
    content:{width:'80%',borderRadius:'6px',background:'#FFFFFF',height:'100%'},
    link:{display:'block',height:'40px',fontSize:'16px',padding:'10px',color:'#000000',position:'relative'},
    active:{background:'#50c9ff4a',},
    activeBackground:{position:'absolute',width:'100%',height:'100%',top:'0',left:'0',background:'#50c9ff4a'},
    triangle:{height:'30px',width:'30px',position:'absolute',top:'5px',right:'-5px',background:'#FFFFFF',transform:'rotate(45deg)',zIndex:'0'}
}))
const DeviceMenu = props => {
    const classes = useStyles();
    const location = useLocation();
    const [active,setActive] = useState(0);
    const [isShowSettings,setIsShowSettings] = useState(false);
    const heightRef = useRef();
    const {t} = useTranslation();

    useEffect(() => {
        if(location.pathname==='/admin/payments/add-info'){
            heightRef.current.style['height'] = '100%';
            setActive(1);
        } 
        if(location.pathname==='/admin/payments/info'){
            heightRef.current.style['height'] = '120%';
            setActive(1);
        } 
        // if(location.pathname==='/admin/payments/histories'){
        //     heightRef.current.style['height'] = '100%';
        //     setActive(2);
        // } 
        if(location.pathname==='/admin/payments/configuration/MOMO'){
            heightRef.current.style['height'] = '100%';
            setActive(3);
        } 
        if(location.pathname==='/admin/payments/configuration/VNPAY'){
            heightRef.current.style['height'] = '100%';
            setActive(4);
        } 
        if(location.pathname==='/admin/payments/configuration/ZaloPay'){
            heightRef.current.style['height'] = '110%';
            setActive(5);
        } 
    }, [location.pathname])

    const onSettingClick = () =>{
        setIsShowSettings(!isShowSettings)
    }
    
    return (
        <div style={{width:'98%',minWidth:'1250px',margin:'auto',height:'100%'}} >
            <div ref={heightRef} style={{width:'100%',height:'100%',display:'flex'}} >
                <div className={classes.menu}>
                    <div className={classes.topBlock} style={{marginBottom:'0'}}>
                        <p className={classes.secondaryTitle} style={{marginLeft:'10px'}}>{t('Quản lý thanh toán')}</p>
                    </div>
                    <Link to='/admin/payments/info' className={clsx(classes.link)}>
                        {t('Thông tin thanh toán')}
                        {active === 1 && <div className={classes.triangle}></div>}
                        {active === 1 && <div className={classes.activeBackground}></div>}
                    </Link>
                    {/* <Link to='/admin/payments/histories' className={clsx(classes.link)}>
                        {t('Lịch sử thanh toán')}
                        {active === 2 && <div className={classes.triangle}></div>}
                        {active === 2 && <div className={classes.activeBackground}></div>}
                    </Link> */}
                    <div className={clsx(classes.link)} style={{cursor:'pointer',display:'flex'}} onClick={onSettingClick}>
                        <p>{t('Cài đặt')}</p>
                        {!isShowSettings &&<ArrowDropDownIcon style={{fontSize:'30px',position:'relative',top:'-5px'}}/>}
                        {isShowSettings && <ArrowDropUpIcon style={{fontSize:'30px',position:'relative',top:'-3px'}}/>}
                    </div>
                    {isShowSettings && <Link to='/admin/payments/configuration/MOMO' className={clsx(classes.link)} style={{paddingLeft:'25px'}}>
                        <img src={window.location.origin +'/images/MoMo_Logo.svg'} style={{position:'relative',top:'-2px',left:'-5px'}} width="20px" height="20px" alt="" />
                        {t('Thanh toán MOMO')}
                        {active === 3 && <div className={classes.triangle}></div>}
                        {active === 3 && <div className={classes.activeBackground}></div>}
                    </Link>}
                    {isShowSettings && <Link to='/admin/payments/configuration/VNPAY' className={clsx(classes.link)} style={{paddingLeft:'25px'}}>
                        <img src={window.location.origin +'/images/Vnpay_Logo.svg'} style={{position:'relative',top:'0px',left:'-5px'}} width="20px" height="20px" alt="" />
                        {t('Thanh toán VNPAY')}
                        {active === 4 && <div className={classes.triangle}></div>}
                        {active === 4 && <div className={classes.activeBackground}></div>}
                    </Link>}
                    {isShowSettings && <Link to='/admin/payments/configuration/ZaloPay' className={clsx(classes.link)} style={{paddingLeft:'25px'}}>
                        <img src={window.location.origin +'/images/ZaloPay_Logo.svg'} style={{position:'relative',top:'3px',left:'-5px'}} width="20px" height="20px" alt="" />
                        {t('Thanh toán Zalo Pay')}
                        {active === 5 && <div className={classes.triangle}></div>}
                        {active === 5 && <div className={classes.activeBackground}></div>}
                    </Link>}
                </div>
                <div className={classes.content} >
                    <Route exact path='/admin/payments/info' component={PaymentTable} />
                    <Route exact path='/admin/payments/add-info' component={AddPaymentInfo} />
                    {/* <Route exact path='/admin/payments/histories' component={MomoHistory} /> */}                   
                </div>
            </div>
        </div>
    )
}

export default DeviceMenu;
