import { MenuItem, TextField } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { apiGetapartmentdata } from "../../../../api/apartment";
import {
  apiCabinetCellTypesGet,
  apiCabinetsGet,
  apiCabinetsPost,
  apiCabinetsPut,
  apiCabinetTemplatesGet,
  apiCabinetTemplatesPost,
} from "../../../../api/cabinets";
import ErrorAlert from "../../../../components/alert/ErrorAlert";
import SuccessAlert from "../../../../components/alert/SuccessAlert";
import { CreateButton } from "../../../../components/button";
import CancelButton from "../../../../components/button/cancelButton";
import { Confirm } from "../../../../components/dialog/confirm";
import { CustomLoading } from "../../../../components/grid/customLoading";
import { InformationIcon } from "../../../../components/icons";
import { EmptyRowsView } from "../../../../components/grid/emtyRowsView";
import SelectCpn from "../../../../components/input/select/select";
import LargeTooltip from "../../../../components/largeTooltip";
import { PrimaryTitle } from "../../../../components/title";
import { formatToVND } from "../../../../helper/currency";
import { deepCloneArr } from "../../../../helper/deepClone";
import { useStyles } from "./styles";


const MAX_ROWS_PER_PAGE = 100;

export default function AddEditCabinet({ changeResponseStatus }) {
  const [state, setState] = useState({
    listCabinetType: [],
    listCellType: [],
    listBlock: [],
    responseStatus: {
      succ: "",
      err: "",
    },
    isFailed: false,
    isLoading: false,
    isTableLoading: false,
    confirmType: "",
    isConfirm: false,
    isConfirmLoading: false,
    confirmMess: "",
  });
  const [cabinet, setCabinet] = useState({
    cabinetName: "",
    location: "",
    typeCabinet: "",
    typeCabinetName: "",
    countCell: "",
    overdueDate: "",
    cells: [],
  });
  const [validate, setValidate] = useState({
    cabinetName: "",
    location: "",
    typeCabinet: "",
    typeCabinetName: "",
    countCell: "",
    overdueDate: "",
  });

  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { cabinetID } = useParams();

  const cabinetRequestRef = useRef({
    cabinetName: "",
    location: "",
    typeCabinet: "",
    // countCell: 0,
    // cells: "",
    overdueDate: "",
  });
  const templateRequestRef = useRef();
  const debounce = useRef();
  const oldCabinet = useRef();

  const handleChangeResponseStatus = (succ = "", err = "") => {
    setState((prev) => ({
      ...prev,
      responseStatus: {
        succ,
        err,
      },
    }));
  };
  const onClickBackToCabinetSystem = () => {
    history.push("/admin/cabinet/system");
  };
  const onClickBackToCabinetDetails = () => {
    history.push(`/admin/cabinet/system/cabinet-details/${cabinetID}`);
  };

  const fetchApiCabinetsPost = async (body) => {
    setState((prev) => ({
      ...prev,
      isConfirmLoading: true,
    }));

    try {
      await apiCabinetsPost(body);

      changeResponseStatus(t("Thêm tủ thành công"));
      onClickBackToCabinetSystem();
    } catch (error) {
      if (error?.response?.data === "Already exist") {
        handleChangeResponseStatus("", t("Tên tủ bị trùng, Vui lòng thử lại!"));
      } else {
        handleChangeResponseStatus("", t("Thêm tủ thất bại, vui lòng thử lại"));
      }
    }

    setState((prev) => ({
      ...prev,
      isConfirm: false,
      isConfirmLoading: false,
      confirmMess: "",
    }));
  };

  const fetchApartmentList = async () => {
    setState((prev) => ({
      ...prev,
      isLoading: true,
    }));

    try {
      const { data } = await apiGetapartmentdata();
      const { Items } = data;
      let listBlock = [];

      Items.forEach((item) => listBlock.push(item.BlockID));
      listBlock = [...new Set(listBlock)];
      listBlock.sort();

      setState((prev) => ({
        ...prev,
        listBlock,
      }));
    } catch (error) {
      handleChangeResponseStatus(
        "",
        t("Tải danh sách tòa thất bại, vui lòng tải lại")
      );
      setState((prev) => ({
        ...prev,
        isFailed: true,
      }));
    }

    setState((prev) => ({
      ...prev,
      isLoading: false,
    }));
  };

  const fetchApiCabinetCellTypesGet = async () => {
    setState((prev) => ({
      ...prev,
      isLoading: true,
    }));

    try {
      const body = { language: i18n.language };

      const { data } = await apiCabinetCellTypesGet(body);
      const { Items: listCellType } = data;

      setState((prev) => ({
        ...prev,
        listCellType,
      }));
    } catch (error) {
      handleChangeResponseStatus(
        "",
        t("Tải danh sách kích thước tủ thất bại, vui lòng tải lại")
      );
      setState((prev) => ({
        ...prev,
        isFailed: true,
      }));
    }

    setState((prev) => ({
      ...prev,
      isLoading: false,
    }));
  };

  const fetchApiCabinetTemplatesGet = async () => {
    setState((prev) => ({
      ...prev,
      isLoading: true,
    }));

    try {
      const { data } = await apiCabinetTemplatesGet();
      const { Items: listCabinetType } = data;

      listCabinetType?.Cells?.forEach((item, index) => (item["id"] = index));

      setState((prev) => ({
        ...prev,
        listCabinetType,
      }));
    } catch (error) {
      handleChangeResponseStatus(
        "",
        t("Tải danh sách mẫu tủ thất bại, vui lòng tải lại")
      );
      setState((prev) => ({
        ...prev,
        isFailed: true,
      }));
    }

    setState((prev) => ({
      ...prev,
      isLoading: false,
    }));
  };

  const fetchApiCabinetTemplatesPost = async (body) => {
    setState((prev) => ({
      ...prev,
      isConfirmLoading: true,
    }));

    try {
      await apiCabinetTemplatesPost(body);

      handleChangeResponseStatus(t("Thêm mẫu tủ thành công"));
    } catch (error) {
      if (error?.response?.data === "Already exist") {
        handleChangeResponseStatus(
          "",
          t("Tên mẫu tủ vừa thêm đã có, Vui lòng thêm lại!")
        );
      } else {
        handleChangeResponseStatus("", t("Thêm mẫu tủ thất bại"));
      }
    }

    setState((prev) => ({
      ...prev,
      isConfirm: false,
      isConfirmLoading: false,
      isLoading: true,
    }));

    await fetchApiCabinetTemplatesGet();

    setState((prev) => ({
      ...prev,
      isLoading: false,
    }));
  };

  const fetchApiCabinetsGet = async () => {
    setState((prev) => ({
      ...prev,
      isLoading: true,
    }));

    try {
      const body = {
        id: cabinetID,
      };

      const { data } = await apiCabinetsGet(body);
      const { Items } = data;

      const cabinet = Items[0];

      cabinet.Cells.forEach((item, index) => (item["id"] = index));

      oldCabinet.current = {
        id: cabinet.ID,
        cabinetName: cabinet?.CabinetName,
        location: cabinet?.Location,
        typeCabinet: cabinet?.TypeCabinet,
        typeCabinetName: cabinet?.TypeCabinet,
        countCell: cabinet?.Cells?.length,
        overdueDate: cabinet?.OverdueDate,
        cells: deepCloneArr(cabinet.Cells),
      };
      cabinetRequestRef.current = {
        id: cabinet.ID,
        cabinetName: cabinet?.CabinetName,
        location: cabinet?.Location,
        typeCabinet: cabinet?.TypeCabinet,
        typeCabinetName: cabinet?.TypeCabinet,
        countCell: cabinet?.Cells?.length,
        overdueDate: cabinet?.OverdueDate,
        cells: cabinet.Cells,
      };
      setCabinet((prev) => ({
        ...prev,
        id: cabinet.ID,
        cabinetName: cabinet?.CabinetName,
        location: cabinet?.Location,
        typeCabinet: cabinet?.TypeCabinet,
        typeCabinetName: cabinet?.TypeCabinet,
        countCell: cabinet?.Cells?.length,
        overdueDate: cabinet?.OverdueDate,
        cells: cabinet.Cells,
      }));
    } catch (error) {
      if (error?.response?.data === "Cabinet not found") {
        // handle show page not found
      } else {
        handleChangeResponseStatus(
          "",
          t("Tải thông tin tủ bị lỗi, vui lòng tải lại")
        );
      }
      setState((prev) => ({
        ...prev,
        isFailed: true,
      }));
    }

    setState((prev) => ({
      ...prev,
      isLoading: false,
    }));
  };

  const fetchApiCabinetsPut = async (body) => {
    setState((prev) => ({
      ...prev,
      isConfirmLoading: true,
    }));

    try {
      await apiCabinetsPut(body);

      changeResponseStatus(t("Cập nhật tủ thành công"));
      onClickBackToCabinetDetails();
    } catch (error) {
      handleChangeResponseStatus("", t("Cập nhật tủ không thành công"));
      fetchApiCabinetsGet();
    }

    onCloseConfirm();
  };

  const onInputChange = (key, e) => {
    const value = e.target.value;
    cabinetRequestRef.current[key] = value;

    setCabinet((prev) => ({
      ...prev,
      [key]: value,
    }));

    if (key === "typeCabinet" && value !== "new") {
      setCabinet((prev) => ({
        ...prev,
        countCell: "",
      }));
    }

    //validate
    if (!value) {
      setValidate((prev) => ({
        ...prev,
        [key]: t("Không được để trống"),
      }));
    } else {
      setValidate((prev) => ({
        ...prev,
        [key]: "",
      }));
    }

    if (key === "overdueDate") {
      if (Number(value) >= 1 && Number(value) <= 10) {
        setValidate(prev => ({
          ...prev,
          [key]: ""
        }));
      } else {
        setValidate(prev => ({
          ...prev,
          [key]: t("Phải là số từ 1 - 10")
        }))
      }
    }
    //pass validate

    //render table
    if (key === 'countCell') {
      const cells = [];
      if (cabinetRequestRef.current?.countCell >= 1 && cabinetRequestRef.current?.countCell <= 200) {
        for (let i = 0; i < cabinetRequestRef.current?.countCell; i++) {
          let cabinetCellName = `${i + 1}`;
          if (i < 10) {
            cabinetCellName = `${i + 1}`.padStart(2, "0");
          }
          cells.push({
            id: `${i}`,
            cabinetCellName,
            cellTypeID: state?.listCellType[0]?.ID
          })
        }
      }

      if (debounce.current) {
        clearTimeout(debounce.current);
      }
      debounce.current = setTimeout(() => {
        setCabinet(prev => ({
          ...prev,
          cells
        }));
      }, 400);
    }


    if (key === "overdueDate") {
      if (Number(value) >= 1 && Number(value) <= 10) {
        setValidate((prev) => ({
          ...prev,
          [key]: "",
        }));
      } else {
        setValidate((prev) => ({
          ...prev,
          [key]: t("Phải là số từ 1 - 10"),
        }));
      }
    }
    //pass validate

    //render table
    if (key === "countCell") {
      const cells = [];
      if (
        cabinetRequestRef.current?.countCell >= 1 &&
        cabinetRequestRef.current?.countCell <= 200
      ) {
        for (let i = 0; i < cabinetRequestRef.current?.countCell; i++) {
          cells.push({
            id: `${i}`,
            cabinetCellName: `${i + 1}`,
            cellTypeID: state?.listCellType[0]?.ID,
          });
        }
      }

      if (debounce.current) {
        clearTimeout(debounce.current);
      }
      debounce.current = setTimeout(() => {
        setCabinet((prev) => ({
          ...prev,
          cells,
        }));
      }, 400);
    }
  };

  const onCellTypeChange = (cellsId, e) => {
    const cells = [...cabinet.cells];
    cells[cellsId].cellTypeID = e.target.value;

    setCabinet((prev) => ({
      ...prev,
      cells,
    }));
  };

  const filterFeeTypeHandler = (cellTypeID) => {
    let { PriceType } =
      state.listCellType.find((item) => item.ID === cellTypeID) || {};

    switch (PriceType) {
      case "free":
        PriceType = t("Không tính phí");
        break;
      case "hourly":
        PriceType = t("Phí cố định theo giờ");
        break;
      case "scale":
        PriceType = t("Phí thay đổi theo giờ");
        break;
      case "turn":
        PriceType = t("Phí cố định theo lượt");
        break;
      default:
        PriceType = "";
        break;
    }

    return PriceType;
  };

  const filterFeeHandler = (cellTypeID) => {
    const cellType = state.listCellType.find((item) => item.ID === cellTypeID);

    let feeInfo = "";
    switch (cellType?.PriceType) {
      case "free":
        feeInfo = t("Không tính phí");
        break;
      case "scale":
        for (let key in cellType?.PriceValue) {
          feeInfo += `, ${key} ${t("giờ")} - ${formatToVND(
            cellType?.PriceValue[key]
          )}`;
        }
        feeInfo = feeInfo.slice(2, feeInfo.length);
        break;
      case "turn":
        feeInfo = `${formatToVND(cellType?.PriceValue)}/ 1 ${t("lượt thuê")}`;
        break;
      case "hourly":
        feeInfo = `${formatToVND(cellType?.PriceValue)}/ 1 ${t("giờ")}`;
        break;
      default:
        feeInfo = "";
        break;
    }

    return feeInfo;
  };

  const onClickCancelButton = () => {
    if (cabinet?.id) {
      setCabinet(deepCloneArr(oldCabinet.current));

      for (let key in validate) {
        setValidate((prev) => ({
          ...prev,
          [key]: "",
        }));
      }
    } else {
      onClickBackToCabinetSystem();
    }
  };

  const onClickButtonSave = () => {
    if (cabinet?.id) {
      // check cabinet had changed
      // let isChanged = false;
      // for (let key in cabinetRequestRef.current) {
      //     if (key !== "cells" && cabinetRequestRef.current[key] !== oldCabinet.current[key]) {
      //         isChanged = true;
      //     }
      // }

      // if (!isChanged) {
      //     handleChangeResponseStatus("", t("Vui lòng chỉnh sửa thông tin trước khi lưu!"));
      //     return;
      // }
      //pass checking

      for (let key in cabinetRequestRef.current) {
        cabinetRequestRef.current[key] = cabinet[key];
      }
    }

    cabinetRequestRef.current.cells = cabinet.cells;

    let isBlankField = false;

    //validate empty again
    for (let key in cabinetRequestRef.current) {
      if (!cabinetRequestRef.current[key]) {
        setValidate((prev) => ({
          ...prev,
          [key]: t("Không được để trống"),
        }));
        isBlankField = true;
      }
    }
    if (cabinet.typeCabinet === "new" && !cabinet.typeCabinetName) {
      setValidate((prev) => ({
        ...prev,
        typeCabinetName: t("Không được để trống"),
      }));
      isBlankField = true;
    }
    if (cabinet.typeCabinet === "new" && !cabinet.countCell) {
      setValidate((prev) => ({
        ...prev,
        countCell: t("Không được để trống"),
      }));
      isBlankField = true;
    }

    if (isBlankField) return;
    //pass validate

    if (cabinet.typeCabinet === "new") {
      cabinetRequestRef.current.typeCabinet = cabinet.typeCabinetName;
    }

    setState((prev) => ({
      ...prev,
      isConfirm: true,
      confirmMess: cabinet?.id
        ? t("Bạn có chắc muốn lưu?")
        : t("Bạn có muốn thêm tủ?"),
      confirmType: cabinet?.id ? "edit" : "save",
    }));
  };

  const onConfirmSave = () => {
    fetchApiCabinetsPost(cabinetRequestRef.current);
  };

  const onClickButtonSaveTemplate = () => {
    templateRequestRef.current = cabinet.cells;

    if (templateRequestRef.current.typeCabinet === "new") {
      templateRequestRef.current.typeCabinet =
        templateRequestRef.current.typeCabinetName;
    }

    setState((prev) => ({
      ...prev,
      isConfirm: true,
      confirmMess: t("Bạn có muốn thêm mẫu tủ?"),
      confirmType: "saveTemplate",
    }));
  };

  const onConfirmSaveTemplate = () => {
    const body = {
      cabinetTemplateName: cabinet.typeCabinetName,
      cells: templateRequestRef.current,
    };

    fetchApiCabinetTemplatesPost(body);
  };

  const onConfirm = () => {
    if (state.confirmType === "saveTemplate") {
      onConfirmSaveTemplate();
    }
    if (state.confirmType === "save") {
      onConfirmSave();
    }
    if (state.confirmType === "edit") {
      delete cabinetRequestRef.current.typeCabinetName;
      delete cabinetRequestRef.current.countCell;

      fetchApiCabinetsPut(cabinetRequestRef.current);
    }
  };

  const onCloseConfirm = () => {
    setState((prev) => ({
      ...prev,
      isConfirm: false,
      confirmMess: "",
      isConfirmLoading: false,
      confirmType: "",
    }));
  };

  useEffect(() => {
    const fetchAllOffData = async () => {
      setState((prev) => ({
        ...prev,
        isTableLoading: true,
      }));

      await fetchApartmentList();
      await fetchApiCabinetTemplatesGet();
      await fetchApiCabinetCellTypesGet();

      if (cabinetID) {
        await fetchApiCabinetsGet();
      }

      setState((prev) => ({
        ...prev,
        isTableLoading: false,
      }));
    };

    fetchAllOffData();
  }, []);

  useEffect(() => {
    //check had cabinetType in list
    let checkHasCabinetType;
    if (cabinet?.id) {
      checkHasCabinetType = state.listCabinetType.findIndex(
        (item) =>
          item.CabinetTemplateName === cabinetRequestRef.current.typeCabinet
      );
      if (checkHasCabinetType < 0) {
        setCabinet((prev) => ({
          ...prev,
          typeCabinet: "new",
        }));
      } else {
        setCabinet((prev) => ({
          ...prev,
          typeCabinet: cabinetRequestRef.current.typeCabinet,
        }));
      }
    }

    if (checkHasCabinetType < 0) return;

    //check list cabinet and get cells to setState
    if (cabinet.id) return;

    if (cabinet.typeCabinet && cabinet.typeCabinet !== "new") {
      const typeCabinet = state.listCabinetType.find(
        (item) => item.CabinetTemplateName === cabinet.typeCabinet
      );

      const cells = typeCabinet?.Cells || [];

      setCabinet((prev) => ({
        ...prev,
        cells,
      }));
    } else {
      setCabinet((prev) => ({
        ...prev,
        cells: [],
      }));
    }
  }, [state.listCellType, cabinet.typeCabinet]);

  const columns = [
    {
      headerName: t("Số ô"),
      field: "cellName",
      sortable: false,
      cellClassName: "super-app-theme--cell",
      renderCell: (params) => (
        <LargeTooltip title={params.row?.cabinetCellName}>
          <p
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {params.row?.cabinetCellName}
          </p>
        </LargeTooltip>
      ),
      width: 150,
    },
    {
      headerName: t("Kích thước"),
      field: "cellType",
      sortable: false,
      cellClassName: "super-app-theme--cell",
      renderCell: (params) => (
        <LargeTooltip
          title={params.row?.userName ? t("Cư dân đã đặt tủ này!") : ""}
        >
          <div style={{ width: "100%" }}>
            <SelectCpn
              className={classes.input}
              disabled={
                state.isLoading || state.isFailed || params.row?.userName
              }
              value={params.row?.cellTypeID}
              onChange={(e) => onCellTypeChange(params.row?.id, e)}
            >
              {state.listCellType?.map((item) => (
                <MenuItem key={item.ID} value={item.ID}>
                  {item.CellType[0].name}
                </MenuItem>
              ))}
            </SelectCpn>
          </div>
        </LargeTooltip>
      ),
      width: 200,
    },
    {
      headerName: t("Loại phí thuê"),
      field: "feeType",
      sortable: false,
      cellClassName: "super-app-theme--cell",
      renderCell: (params) => (
        <LargeTooltip title={filterFeeTypeHandler(params.row?.cellTypeID)}>
          <p
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {filterFeeTypeHandler(params.row?.cellTypeID)}{" "}
          </p>
        </LargeTooltip>
      ),
      width: 250,
    },
    {
      headerName: t("Chi phí thuê"),
      field: "fee",
      sortable: false,
      cellClassName: "super-app-theme--cell",
      renderCell: (params) => (
        <LargeTooltip title={filterFeeHandler(params.row?.cellTypeID)}>
          <p
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {filterFeeHandler(params.row?.cellTypeID)}{" "}
          </p>
        </LargeTooltip>
      ),
      flex: 1,
    },
  ];

  return (
    <div className={classes.addCabin}>
      <div className={classes.head}>
        <div className={classes.headTitle}>
          <ArrowBackIosNewOutlinedIcon
            onClick={() => {
              if (cabinet?.id) {
                onClickBackToCabinetDetails();
              } else {
                onClickBackToCabinetSystem();
              }
            }}
          />
          <PrimaryTitle>
            {cabinetID ? t("Chỉnh sửa tủ") : t("Thêm tủ")}
          </PrimaryTitle>
        </div>

        <div className={classes.headBtn}>
          <CancelButton onClick={onClickCancelButton}>{t("Hủy")}</CancelButton>
          <CreateButton
            disabled={state.isFailed || state.isTableLoading || state.isLoading}
            onClick={onClickButtonSave}
          >
            {t("Lưu tủ")}
          </CreateButton>
          <CreateButton
            disabled={
              !cabinetRequestRef.current.typeCabinetName ||
              !cabinetRequestRef.current.countCell
            }
            onClick={onClickButtonSaveTemplate}
          >
            {t("Lưu mẫu")}
          </CreateButton>
        </div>
      </div>

      <div className={classes.containInput}>
        <div className={classes.wrapInput}>
          <label htmlFor="" className={classes.label}>
            {t("Tên tủ")}
          </label>
          <br />
          <TextField
            size="small"
            variant="outlined"
            placeholder={t("Nhập tên tủ")}
            className={classes.input}
            disabled={state.isTableLoading || state.isLoading || state.isFailed}
            error={!!validate.cabinetName}
            helperText={validate.cabinetName}
            value={cabinet.cabinetName}
            onChange={(e) => onInputChange("cabinetName", e)}
          />
        </div>

        <div className={classes.wrapInput}>
          <label htmlFor="" className={classes.label}>
            {t("Tòa")}
          </label>
          <br />
          <SelectCpn
            className={classes.input}
            disabled={state.isTableLoading || state.isLoading || state.isFailed}
            error={!!validate.location}
            helperText={validate.location}
            value={cabinet.location}
            onChange={(e) => onInputChange("location", e)}
          >
            <MenuItem value="">
              <span className={classes.placeholder}>{t("Chọn")}</span>
            </MenuItem>
            {state.listBlock.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </SelectCpn>
        </div>

        <div className={classes.wrapInput}>
          <label htmlFor="" className={classes.label}>
            {t("Mẫu tủ")}
          </label>
          <br />
          <SelectCpn
            className={classes.input}
            disabled={
              state.isTableLoading ||
              state.isLoading ||
              state.isFailed ||
              cabinet.id
            }
            error={!!validate.typeCabinet}
            helperText={validate.typeCabinet}
            value={cabinet.typeCabinet}
            onChange={(e) => onInputChange("typeCabinet", e)}
          >
            <MenuItem value="" disabled>
              <span className={classes.placeholder}>{t("Mẫu tủ")}</span>
            </MenuItem>
            {state.listCabinetType.map((item) => (
              <MenuItem key={item.ID} value={item?.CabinetTemplateName}>
                {item.CabinetTemplateName}
              </MenuItem>
            ))}
            <MenuItem value="new">{t("Tùy chọn")}</MenuItem>
          </SelectCpn>
        </div>

        {cabinet.typeCabinet === "new" && (
          <div className={classes.wrapInput}>
            <label htmlFor="" className={classes.label}>
              {t("Tên loại tủ")}
            </label>
            <br />
            <TextField
              size="small"
              variant="outlined"
              placeholder={t("Tên loại tủ")}
              className={classes.input}
              disabled={
                state.isTableLoading || state.isLoading || state.isFailed
              }
              error={!!validate.typeCabinetName}
              helperText={validate.typeCabinetName}
              value={cabinet.typeCabinetName}
              onChange={(e) => onInputChange("typeCabinetName", e)}
            />
          </div>
        )}

        {cabinet.typeCabinet === "new" && !cabinet.id && (
          <div className={classes.wrapInput}>
            <label htmlFor="" className={classes.label}>
              {t("Tổng số ô")}
            </label>
            <br />
            <TextField
              size="small"
              variant="outlined"
              placeholder={t("Tổng số ô")}
              className={classes.input}
              disabled={
                state.isTableLoading || state.isLoading || state.isFailed
              }
              error={!!validate.countCell}
              helperText={validate.countCell}
              value={cabinet.countCell}
              onChange={(e) => onInputChange("countCell", e)}
            />
          </div>
        )}

        <div className={classes.wrapInput}>
          <label htmlFor="" className={classes.label}>
            {t("Ngày quá hạn")}
          </label>

          <LargeTooltip title={t("Thời gian tối đa cư dân được gửi hàng trong tủ")}>
            <span>
              <InformationIcon />
            </span>
          </LargeTooltip>

          <br />
          <TextField
            size="small"
            variant="outlined"
            placeholder={t("Ngày quá hạn")}
            className={classes.input}
            disabled={state.isTableLoading || state.isLoading || state.isFailed}
            error={!!validate.overdueDate}
            helperText={validate.overdueDate}
            value={cabinet.overdueDate}
            onChange={(e) => onInputChange("overdueDate", e)}
          />
        </div>
      </div>

      <div className={clsx(classes.root, classes.table)}>
        <DataGrid
          // hideFooter
          disableColumnMenu
          disableSelectionOnClick
          componentsProps={{
            pagination: {
              labelRowsPerPage: t("Số dòng mỗi trang"),
              labelDisplayedRows: ({ from, to, count }) => {
                return `${from}-${to} ${t("trong số")} ${count}`;
              },
            },
          }}
          components={{
            NoRowsOverlay: () =>
              state.isLoading || cabinetID
                ? ""
                : EmptyRowsView("Vui lòng nhập đầy đủ thông tin!"),
            LoadingOverlay: CustomLoading,
          }}
          localeText={{
            footerRowSelected: (count) =>
              count + t(" hàng trong bảng đã được chọn"),
            columnHeaderSortIconLabel: t("Sắp xếp"),
          }}
          loading={state.isTableLoading || state.isLoading}
          columns={columns}
          rows={cabinet.cells}
          rowsPerPageOptions={[MAX_ROWS_PER_PAGE]}
        />
      </div>

      <Confirm
        isOpen={state.isConfirm}
        handleClose={onCloseConfirm}
        isLoading={state.isConfirmLoading}
        mess={state.confirmMess}
        onConfirm={onConfirm}
      />

      <SuccessAlert
        message={state.responseStatus.succ}
        onCloseDialog={() => handleChangeResponseStatus()}
      />

      <ErrorAlert
        message={state.responseStatus.err}
        onCloseDialog={() => handleChangeResponseStatus()}
      />
    </div>
  );
}
