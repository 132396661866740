import React, {useState, useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import MeetingRoomOutlinedIcon from '@material-ui/icons/MeetingRoomOutlined';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import SettingsIcon from '@material-ui/icons/Settings';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ReportIcon from '@material-ui/icons/Report';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import PhotoCameraFrontIcon from '@mui/icons-material/PhotoCameraFront';
import CircleIcon from '@mui/icons-material/Circle';
import PaidIcon from '@mui/icons-material/Paid';
import CssBaseline from '@material-ui/core/CssBaseline';
import {useLocation,  Link, useHistory} from 'react-router-dom';
import MenuUI from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import clsx from 'clsx';
import { useSelector,  useDispatch } from 'react-redux';
import SupervisedUserCircleOutlinedIcon from '@material-ui/icons/SupervisedUserCircleOutlined';
import { useMediaQuery } from 'react-responsive'
import withStyles from '@material-ui/styles/withStyles/withStyles';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Cookies from 'js-cookie';
import DevicesIcon from '@material-ui/icons/Devices';
import pjson from '../../../package.json';
import SuccessAlert from '../alert/SuccessAlert';
import ErrorAlert from '../alert/ErrorAlert';
import InfoAlert from '../alert/InfoAlert';
import axiosClient from '../../config/AxiosClient';
import Divider from '@mui/material/Divider/Divider';
import RefreshIcon from '@material-ui/icons/Refresh';
import moment from 'moment-timezone';
import {messaging } from '../../config/FirebaseInit';
import SyncFaceAlert from '../alert/SyncFaceAlert';
import BadgeIcon from '@mui/icons-material/Badge';
import MarginOutlinedIcon from '@mui/icons-material/MarginOutlined';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { getBookingService } from '../../api/booking';
import WarningAlert from '../alert/WarningAlert';
import { UPDATE_SERVICE_BOOKING } from '../../redux/reducers/serviceBooking';
import { CabinetIcon, PaymentIcon } from '../icons';
import LargeTooltip from '../largeTooltip/index';

const useStyles = makeStyles((theme)=>({
    appBar:{zIndex:theme.zIndex.drawer +1, background:'#FFFFFF', height:'72px'}, 
    color:{color:'#009ADA'}, 
    colorIcon:{color:'#072A3B'}, 
    background:{background:theme.menuBackground}, 
    textMenu:theme.textMenu, 
    whiteColor:{color:'#FFFFFF', marginLeft:'5px'}, 
    active:{
        background: 'rgba(40,  156,  216,  0.4)!important', 
        borderLeft:'6px solid #289CD8', 
        '&:hover':{background:'rgba(40,  156,  216,  0.4)'}
    }, 
    drawerOpen:{
        overflow:'hidden', 
        width:'280px', 
        transition: theme.transitions.create("width",  {
            easing: theme.transitions.easing.sharp, 
            duration: theme.transitions.duration.enteringScreen
        }), 
        // overflowY:'scroll'
    }, 
    drawerClose:{
        transition: theme.transitions.create("width",  {
            easing: theme.transitions.easing.sharp, 
            duration: theme.transitions.duration.leavingScreen
        }), 
        overflow: "hidden", 
        width: '70px', 
        // overflowY: "scroll", 
    }, 
    mobileDrawerClose:{
        transition: theme.transitions.create("width",  {
            easing: theme.transitions.easing.sharp, 
            duration: theme.transitions.duration.leavingScreen
        }), 
        overflow: "hidden", 
        width: '0px', 
        // overflowY:'scroll'
    }, 
    userIcon:{
        position:'absolute', 
        top:'100%', 
        right:'0'
    }, 
    notifyIcon:{
        position:'absolute', 
        top:'100%', 
        right:'0'
    }, 
    popupTitle:{fontSize:'16px', color:'#444444'}, 
    notification:{fontSize:'16px', color:'#444444', border:'1px solid #29CB97', borderRadius:'6px', margin:'0 5px', whiteSpace:'normal'}, 
    readNotification:{fontSize:'16px', color:'#444444', margin:'0 5px', whiteSpace:'normal'}, 
    listItem:{height:'64px', overflow:'hidden', width:'280px'}, 
    dialogTitle:{textAlign:'center', fontSize:'20px', fontWeight:'600', lineHeight:'25px', color:'#FFFFFF'}, 
    contentDialog:{fontSize:'16px', height:'68px', borderBottom:'1px solid #EEEDF2', display:'flex', alignItems:'center', justifyContent:'center'}, 
    errorColor:theme.errorColor, 
    primaryColor:theme.primaryColor, 
    borderActive:{position:'relative', left:'-6px'}, 
    createButton:{margin:'10px 0', background:'#0b59c8', height:'40px', padding:'8px 16px', textTransform:'none', color:'#FFFFFF', 
    '&:hover':{
        background:'#0b59c8'
        }
    }, 
    cancelButton:{margin:'10px', borderColor:'#0b59c8', height:'40px', padding:'8px 16px', textTransform:'none', color:'#0b59c8', 
    '&:hover':{
        borderColor:'#0b59c8'
        }
    }, 
    button:{
        '& .MuiButton-containedPrimary':{
            textTransform:'none', 
            background:'#0b59c8', 
            height:'40px', 
            padding:'8px 16px', 
            color:'#FFFFFF', 
            margin:'10px 0'
        }, 
        '& .MuiButton-outlinedPrimary':{
            textTransform:'none', 
            borderColor:'#0b59c8', 
            height:'40px', 
            padding:'8px 16px', 
            color:'#0b59c8', 
            margin:'10px'
        }
    }, 
    notificationCount:{position:'absolute', top:'0', right:'5px', fontSize:'10px', background:'#E01010', borderRadius:'50%', height:'20px', width:'20px', color:'#FFFFFF', display:'flex', justifyContent:'center', alignItems:'center'}, 
    notificationScrollbar:{  
        overflowY: "scroll", 
        marginBottom: '3px', 
        marginRight:'2px', 
        padding: 0, 
        listStyle: "none", 
        height: "350px", 
        '&::-webkit-scrollbar': {
            width: '6px', 
            background:'#F5F5F5', 
            borderRadius:'6px'
        }, 
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0, 0, 0, 0.4)', 
            width: '6px', 
            borderRadius:'6px'
        }
    }, 
    menuScrollbar:{  
        scrollbarWidth: "thin",
        overflowY: "auto",
        overflowX:"hidden",
        marginTop:"72px",
        listStyle: "none",
        height: "100%",
        padding: "0",
        '&::-webkit-scrollbar': {
            width: '3px',
            borderRadius:'3px'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#FFFFFF',
            width: '3px',
            borderRadius:'3px'
        },
        scrollbarWidth: "thin"
    },
	zIndex1000:{
        zIndex:10000
    },
    svgIconStyle:{
        paddingLeft: '6px'
    }
}))


const ErrorButton = withStyles(theme =>({root:theme.error_button}))(Button);

const Menu = (props) => {
    const classes = useStyles();
    const [active, setActive] = useState(-1);
    const [open, setOpen] = useState(false);
    const configState = useSelector((state) =>state.configState);
    const notiState = useSelector((state) =>state.notiState);
    const syncAllState = useSelector((state) => state.syncAllState);
    const adminUserState = useSelector((state) => state.adminUserState);
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const [role, setRole] = useState('');
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl1, setAnchorEl1] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [isMaintaining, setIsMaintaining] = useState(false);
    const [isWarning, setIsWarning] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [warnings,  setWarnings] = useState([]);
    const [reload, setReload] = useState(false);
    const [warningCount, setWarningCount] = useState(0);
    const isMobile = useMediaQuery({ query: '(max-width: 414px)' })
    const { t,  i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [alertData, setAlertData] = useState({});
    const [warningData, setWarningData] = useState({});
    const [isOpenWarning, setIsOpenWarning] = useState();

    const onIsOpenChange = () =>{
        setIsOpen(false);
    }

    const onIsOpenWarningChange = () =>{
        setIsOpenWarning(false);
    }

    messaging.onMessage((payload)=>{
        const data = JSON.parse(payload.data.GCM);
        if(data.notification.operator) dispatch({type:'CHANGE_SYNC_FACE_STATUS'});
        if(data.notification.type === 'warning'){
            setWarningData(payload.notification);
            setIsOpenWarning(true);
            return;
        }
        setAlertData({
            ...payload.notification, 
            id:data.notification.requestId, 
            deviceId:data.notification.deviceId, 
            operator:data.notification.operator,
            deviceName:data.notification.deviceName,
            result:data.notification.result
        });
        setIsOpen(true);
    })

    const [state, setState] = useState({
        responseErr: ""
    })
    
    const isChangedService = useSelector(state => state.serviceBooking.isChangedService);
    const listServiceBooking = useSelector(state => state.serviceBooking.listServiceBooking);
    const isMenu = useSelector(state => state.cabinetReducer.isMenu);

    useEffect(() => {
        if(location.pathname==='/admin/manage-residences' || location.pathname==='/admin/add-new-residence' || location.pathname.match('/admin/register-overview/*'))setActive(0);
        if(location.pathname==='/admin/manage-access' || location.pathname==='/admin/add-new-customer') setActive(1);
        if(location.pathname==='/admin/statistic-page') setActive(2);
        if(location.pathname==='/admin/dashboard') setActive(3);
        if(location.pathname === '/admin/manage-notifications' || location.pathname === '/admin/manage-costs' || location.pathname === '/admin/manage-suppliers') setActive(4);
        if(location.pathname.match('/admin/setting*')) setActive(6);
        if(location.pathname==='/admin/admin-account') setActive(5);
        if(location.pathname==='/admin/payment') setActive(7);
        if(location.pathname==='/admin/payment-history') setActive(8);
        if(location.pathname==='/admin/payments-info' || location.pathname === '/admin/add-payments-info') setActive(9);
        if(location.pathname==='/admin/my-account') setActive(10);
        if(location.pathname.match('/admin/device-management/*')) setActive(11);
        if(location.pathname==='/admin/warnings') setActive(12);
        if(location.pathname.match('/admin/staff-management/*')) setActive(15);
		if(location.pathname.match('/admin/payments/*')) setActive(13);
        if(location.pathname.match('admin/smart-camera/*')) setActive(14);        
        if(location.pathname.match('/admin/service-booking/*')) setActive(16);
        if(location.pathname==='/admin/security-camera') setActive(17);
        if(location.pathname.match('/admin/cabinet/*')) setActive(20);
        setOpen(false);
    },  [location.pathname])

    const getWarnings = async() =>{
        try {
            const offset = 10 * (currentPage-1);
            var res;
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                res = await axiosClient.get(`/people/counting/warnings?offset=${offset}&&limit=${10}`, {headers:{'Authorization': `Bearer ${Cookies.get('token')}`}}); 
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                res = await axiosClient.get(`/warning?offset=${offset}&&limit=${10}`, {headers:{'Authorization': `Bearer ${Cookies.get('token')}`}});
            }
            setWarningCount(res.data.UnreadCount);
            const data = [...warnings];
            res.data.Items.forEach((item)=>{
                if(item.ReadBy){
                    const exists = item.ReadBy.filter(value=>value === localStorage.getItem('username'));
                    exists.length>0?data.push({...item, isRead:true}):data.push({...item, isRead:false});
                }else{
                    data.push({...item, isRead:false});
                }
            })
            setWarnings(data);
        } catch (error) {
            
        }
    }
    const refreshWarnings = async() =>{
        try {
            const offset = 10 * (currentPage-1);
            var res;
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                res = await axiosClient.get(`/people/counting/warnings?offset=${offset}&&limit=${10}`, {headers:{'Authorization': `Bearer ${Cookies.get('token')}`}}); 
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                res = await axiosClient.get(`/warning?offset=${offset}&&limit=${10}`, {headers:{'Authorization': `Bearer ${Cookies.get('token')}`}});
            }
            setWarningCount(res.data.UnreadCount);
            const data = [];
            res.data.Items.forEach((item)=>{
                if(item.ReadBy){
                    const exists = item.ReadBy.filter(value=>value === localStorage.getItem('username'));
                    exists.length>0?data.push({...item, isRead:true}):data.push({...item, isRead:false});
                }else{
                    data.push({...item, isRead:false});
                }
            })
            setWarnings(data);
        } catch (error) {
            
        }
    }
    const onLoadMore = (e) =>{
        setCurrentPage(currentPage+1);
    }

    useEffect(()=>{
        if(localStorage.getItem('username') !== null && process.env.REACT_APP_IS_WARNING === 'show'){
            currentPage===1?refreshWarnings():getWarnings();
        } 
    }, [currentPage])

    useEffect(()=>{
        if(adminUserState.role !== ''){
            setRole(adminUserState.role);
            setLoading(true);
        }
    }, [adminUserState.role])

    useEffect(()=>{
        if(localStorage.getItem('restore')==='In-progress') setIsMaintaining(true);
    }, [localStorage.getItem('restore')])

    useEffect(()=>{
        if(isMobile) setIsWarning(true);
        else setIsWarning(false);
    }, [])

    const onRefeshClick = () =>{
        setCurrentPage(1);
    }
    const onReadWarning = async(id, readed) =>{
        if(readed) return;
        try {
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                await axiosClient.post(`/people/warning/update`, {'id':id, 'read':true}, {headers:{'Authorization': `Bearer ${Cookies.get('token')}`}}); 
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                await axiosClient.post('/warning/update',  {id,  read: true}, {headers:{'Authorization': `Bearer ${Cookies.get('token')}`}});
            }
            setReload(true);
            warnings.forEach(item=>{
                if(item.ID===id) item.isRead = true;
            })
            setWarningCount(warningCount-1);
            setReload(false);
            setWarningCount(warningCount-1);
        } catch (error) {
            
        }
        
    }

    const getNotifications = () =>{
        return warnings.map((value, key)=>{
            return(
                <div key={key}>
                    <MenuItem className={value.isRead?classes.readNotification:classes.notification} onClick={()=>onReadWarning(value.ID, value.isRead)}>
                        <ListItemIcon >
                            <WarningAmberIcon style={{color:'rgb(255,  152,  56)', background:'rgb(255,  244,  229)', width:'40px', height:'40px', padding:'3px', borderRadius:'50%'}} />
                        </ListItemIcon>
                        <ListItemText style={{width:'200px'}}>
                            <p>Thiết bị <b>{value.CameraName}</b> thông báo {value.Description}</p>
                            <p style={{fontSize:'12px', color:'#5E6C84', fontWeight:'600'}}>{moment(value.CreatedTime).format('DD-MM-YYYY HH:mm:ss')}</p>
                        </ListItemText>
                        {!value.isRead && <Typography variant="body2">
                            <CircleIcon fontSize="small" style={{color:'#29CB97'}} />
                        </Typography>}
                    </MenuItem>
                    <Divider style={{height:'2px', color:'rgb(51,  51,  51/ 60%)', margin:'4px 0'}}/>
                </div>
            )
        })
    }
    const onIconUserClick = (e) =>{
        setAnchorEl1(e.currentTarget);
    }
    const onIconLanguaChange = (e) =>{
        setAnchorEl(e.currentTarget);
    }
    const handleClose = () =>{
        setAnchorEl(null);
        setAnchorEl1(null);
        setAnchorEl2(null);
    }
    const onNotificationClick = (e)=>{
        if(warnings.length > 0) setAnchorEl2(e.currentTarget);
    }
    const signOut = async()=> {
        dispatch({type:'CHANGE_STATUS_LOADING_FALSE'}); 
        dispatch({
            type:'GET_CONFIG', 
            logo:'', 
            menu_background:'', 
            theme_color:'', 
            username:''
        })
        localStorage.removeItem('role');
        Cookies.remove('token', {path:''});
        localStorage.removeItem('username');
        history.push('/');
    }

    const myAccountClick = () =>{
        handleClose();
        history.push('/admin/my-account');
    }

    const onClose = ()=>{
        dispatch({
            type:'GET_SUCCESS_MESSAGE', 
            payload:''
        })
        dispatch({
            type:'GET_FAILED_MESSAGE', 
            payload:''
        })
    }

    const onSyncAllClose = () =>{
        dispatch({
            type:'UPDATE_SUCCESS_MESSAGE', 
            payload:''
        })
        dispatch({
            type:'UPDATE_FAILED_MESSAGE', 
            payload:''
        })
    }

    const onClickLinkToCabinet = (e) => {
        if(!isMenu && location.pathname.match('/admin/cabinet/*')) {
            e.preventDefault();
            dispatch({type: "CHANGE_IS_MENU", payload: true});
        }
        if((isMenu && location.pathname.match('/admin/cabinet/*')) || (!isMenu && !location.pathname.match('/admin/cabinet/*'))) {
            dispatch({type: "CHANGE_IS_MENU", payload: true});
        }
    }

    useEffect(()=>{
        if(notiState.successMessage !== '' || notiState.failedMessage !== ''){
            setTimeout(() => {
                onClose();
            },  7000);
        }
    }, [notiState])


    useEffect(()=>{
        if(syncAllState.successMessage !== '' || syncAllState.failedMessage !== ''){
            setTimeout(() => {
                onSyncAllClose();
            },  7000);
        }
    }, [syncAllState])
    
    useEffect(() => {
        if(process.env.REACT_APP_IS_BOOKING === "hide") return;
        
        getBookingService().then(rs => {
            const { Items: listServiceBooking } = rs.data;
            
            dispatch({type: UPDATE_SERVICE_BOOKING, payload: listServiceBooking});
        }).catch(err => {
            dispatch({type: UPDATE_SERVICE_BOOKING, payload: []});

            setState(prev => ({
                ...prev,
                responseErr: t("Tải danh sách dịch vụ thất bại!")
            }))
        })
    }, [isChangedService]);
    
    const maintainanceDialog = () =>{
        return(
          <Dialog 
            open={isMaintaining}
            maxWidth="xl"
          >
            <DialogTitle className={classes.errorColor}>
                <p className={classes.dialogTitle}>{t('Thông báo')}</p>
            </DialogTitle>
            <DialogContent style={{width:'448px', padding:'0', textAlign:'center'}}>
                <p className={classes.contentDialog}>{t('Hệ thống đang bảo trì')}<br />{t('Vui lòng chờ trong ít phút!')}</p>
                <ErrorButton 
                  style={{margin:'10px 0'}}
                  onClick={()=>{history.push('/maintainance')}} 
                >
                  {t('Đồng ý')}
                </ErrorButton>
            </DialogContent>
          </Dialog>
        )
    }
    const warningDialog = () =>{
        return(
            <Dialog 
                open={isWarning}
                maxWidth='sm'
                style={{width:'400px', height:'250px', textAlign:'center'}}
            >
                <DialogTitle className={classes.primaryColor}>
                    <p className={classes.dialogTitle}>{t('Thông báo')}</p>
                </DialogTitle>
                <DialogContent className={classes.button} style={{padding:'0 10px', textAlign:'center'}}>
                    <p className={classes.contentDialog}>{t('Website không thân thiện với điện thoại. Bạn có muốn tiếp tục?')}<br /></p>
                    <Button 
                        variant="outlined"
                        color="primary"
                        onClick={()=>{history.push('/')}} 
                    >
                        {t('Trở về')}
                    </Button>
                    <Button 
                        variant="contained"
                        color="primary"
                        onClick={()=>setIsWarning(false)} 
                    >
                        {t('Đồng ý')}
                    </Button>
                </DialogContent>
            </Dialog>
          )
    }
    return (
        <div>
            {maintainanceDialog()}
            {warningDialog()}
            <SyncFaceAlert 
                onCloseDialog={onIsOpenChange} 
                isOpen={isOpen} 
                alertData={alertData} 
                ms={10000}
            />
            <WarningAlert 
                onCloseDialog={onIsOpenWarningChange} 
                isOpen={isOpenWarning} 
                alertData={warningData} 
                ms={10000}
            />
            {syncAllState.isSyncFace && 
            <InfoAlert 
                message={'sync_face'} 
                onCloseDialog={()=>{
                    dispatch({type:'CHANGE_SYNC_FACE_STATUS'});
                }}
            />}
            <CssBaseline />
            {loading && <AppBar position="fixed" className={classes.appBar} style={{height:'72px'}}>
                <Toolbar style={{height:'72px'}}>
                    <IconButton onClick={()=>setOpen(!open)} edge="start" className={clsx(classes.color, classes.menuIcon)} aria-label="menu">
                        <MenuIcon style={{fontSize:'35px', color:'#009ADA'}}/>
                    </IconButton>
                    <img src={configState.logo} alt="TMA logo" width="93px" height="39px" />
                    <Typography variant="h6" style={{flexGrow:'1'}}></Typography>
                    <div aria-label="language" onClick={onIconLanguaChange} style={{display:'flex', cursor:'pointer'}}>
                        {i18n.language==='vi' && <img src={window.location.origin +'/images/vi.png'} alt="" style={{width:'15px', height:'15px', borderRadius:'100%', position:'relative', top:'3px', right:'2px'}}/>}
                        {i18n.language==='en' && <img src={window.location.origin +'/images/en.png'} alt="" style={{width:'15px', height:'15px', borderRadius:'100%', position:'relative', top:'3px', right:'2px'}}/>}
                        <p style={{fontSize:'16px', fontWeight:'bold', color:'#000000'}}>{i18n.language==='vi'?'VI':'EN'}</p>
                    </div>
                    <MenuUI
                        id="language"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        // anchorOrigin={{
                        //     vertical: 'bottom', 
                        //     horizontal: 'center', 
                        // }}
                        transformOrigin={{
                            vertical: 'bottom', 
                            horizontal: 'center', 
                        }}
                    >
                        <MenuList>
                            <MenuItem 
                                onClick={()=>{
                                   setAnchorEl(null);
                                   i18n.changeLanguage('vi'); 
                                }} 
                                className={classes.popupTitle}
                            >
                                Việt Nam
                            </MenuItem>
                            <MenuItem
                                onClick={()=>{
                                    setAnchorEl(null);
                                    i18n.changeLanguage('en');
                                }} 
                                className={classes.popupTitle}
                            >
                                English
                            </MenuItem>
                        </MenuList>
                    </MenuUI> 
                    {process.env.REACT_APP_IS_WARNING==='show' && <LargeTooltip title={t("Thông báo cảnh báo")} >
                        <IconButton edge="end" aria-label="notification" style={{margin:'0 5px'}} onClick={onNotificationClick} >
                            <NotificationsNoneOutlinedIcon className={classes.colorIcon}/>
                            {warningCount > 0 && <div className={classes.notificationCount} >{warningCount>99?'99+':warningCount}</div>}
                        </IconButton>
                    </LargeTooltip>}                  
                    {process.env.REACT_APP_IS_WARNING==='hide' && <LargeTooltip title={t("Thông báo cảnh báo")} >
                        <IconButton edge="end" aria-label="notification" style={{margin:'0 5px'}} >
                            <NotificationsNoneOutlinedIcon className={classes.colorIcon}/>
                        </IconButton>
                    </LargeTooltip>}                  
                    <MenuUI
                        id="simple-menu"
                        anchorEl={anchorEl2}
                        open={Boolean(anchorEl2)}
                        onClose={handleClose}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: 'bottom', 
                            horizontal: 'center', 
                        }}
                        transformOrigin={{
                            vertical: 'bottom', 
                            horizontal: 'center', 
                        }}
                        PaperProps={{
                            style: {
                              maxHeight: 450, 
                            }, 
                        }}
                        
                    >   
                        <MenuList className={(classes.notificationScrollbar, classes.zIndex1000)}>
                            <div style={{display:'flex', padding:'10px 15px', fontSize:'16px'}}>
                                <p style={{flex:1}}>{t('Thông báo cảnh báo')}</p>
                                <RefreshIcon onClick={onRefeshClick} style={{cursor:'pointer'}}/>
                            </div>
                            <Divider style={{height:'2px', color:'rgb(51,  51,  51/ 60%)', margin:'2px 0'}}/>
                            {getNotifications()}
                            <span onClick={onLoadMore} style={{padding:'5px', cursor:'pointer', color:'#0B59C8', fontStyle:'italic', textDecoration:'underline'}}>{t('Xem thêm')}</span>
                           
                        </MenuList>
                    </MenuUI>
             
                    <Button
 
                        startIcon={<AccountCircleOutlinedIcon />}
                        onClick={onIconUserClick}
                        style={{color:'#000000', paddingLeft:'15px', fontWeight:'bold', fontSize:'16px', textTransform:'none'}}
                    >
                        {(localStorage.getItem('fullname')==='undefined'|| isMobile)?'':localStorage.getItem('fullname')}
                    </Button>
                    <MenuUI
                        anchorEl={anchorEl1}
                        open={Boolean(anchorEl1)}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom', 
                            horizontal: 'center', 
                        }}
                        transformOrigin={{
                            vertical: 'bottom', 
                            horizontal: 'center', 
                        }}
                    >
                        <MenuList>
                            <MenuItem className={classes.popupTitle} onClick={myAccountClick}><AccountCircleOutlinedIcon style={{marginRight:'5px'}}/>{t("Tài khoản")}</MenuItem>
                            <MenuItem className={classes.popupTitle} onClick={signOut} ><ExitToAppOutlinedIcon style={{marginRight:'5px'}} />{t("Đăng xuất")}</MenuItem>
                        </MenuList>
                    </MenuUI>
                </Toolbar>
                <div style={{position:'absolute', left:'0', width:'100%', display:'flex', justifyContent:'center'}}>
                    <div style={{width:'40%'}}>
                        <SuccessAlert 
                            message={notiState.successMessage} 
                            onCloseDialog={onClose} 
                        />
                        <ErrorAlert 
                            message={notiState.failedMessage} 
                            onCloseDialog={onClose} 
                        />
                        {notiState.isPublishing && 
                        <InfoAlert 
                            message={'publishing'} 
                            onCloseDialog={()=>{}} 
                        />}
                        <SuccessAlert 
                            message={syncAllState.successMessage} 
                            onCloseDialog={onSyncAllClose} 
                        />
                        <ErrorAlert 
                            message={syncAllState.failedMessage} 
                            onCloseDialog={onSyncAllClose} 
                        />
                        {syncAllState.isSyncAll && 
                        <InfoAlert 
                            message={'syncing'} 
                            onCloseDialog={()=>{}} 
                        />}
                        <ErrorAlert 
                            message={state.responseErr} 
                            onCloseDialog={() => setState(prev => ({
                                ...prev,
                                responseErr: ""
                            }))} 
                        />
                    </div>
                </div>   
            </AppBar>}

            {loading && <Drawer 
                variant="permanent" 
                className={clsx({
                    [classes.drawerOpen]:open && !isMobile, 
                    [classes.drawerClose]:!open && !isMobile, 
                    [classes.mobileDrawerClose]:!open && isMobile, 
                })}
                classes ={{
                    paper: clsx(classes.background, {
                        [classes.drawerOpen]:open, 
                        [classes.drawerClose]:!open && !isMobile, 
                        [classes.mobileDrawerClose]:!open && isMobile, 
                    })
                }}
            >
                {role !== '' && 
                <List className={classes.menuScrollbar}>
                    <ListItem component={Link} to='/admin/statistic-page' button className={clsx(classes.listItem, {[classes.active]:active===2})}>
                        <LargeTooltip title={t("Thống kê")} ><ListItemIcon ><EqualizerIcon className={clsx(classes.whiteColor, {[classes.borderActive]:active===2})}/> </ListItemIcon></LargeTooltip>
                        <ListItemText primary={t("Thống kê")} className={clsx({[classes.borderActive]:active===2})} classes={{primary:classes.textMenu}}/>
                    </ListItem> 
                    <ListItem component={Link} to='/admin/manage-residences' button className={clsx(classes.listItem, {[classes.active]:active===0})}>
                        <LargeTooltip title={t("Quản trị cư dân")} ><ListItemIcon ><PeopleOutlineIcon className={clsx(classes.whiteColor, {[classes.borderActive]:active===0})}/> </ListItemIcon></LargeTooltip>
                        <ListItemText className={clsx({[classes.borderActive]:active===0})}  primary={t("Quản trị cư dân")} classes={{primary:classes.textMenu}}/>
                    </ListItem> 
                    {process.env.REACT_APP_IS_STAFF==='show' && <ListItem component={Link} to='/admin/staff-management/staff' button className={clsx(classes.listItem,{[classes.active]:active===15})}>
                        <LargeTooltip title={t("Quản lý nhân viên")} ><ListItemIcon ><BadgeIcon className={clsx(classes.whiteColor,{[classes.borderActive]:active===15})}/> </ListItemIcon></LargeTooltip>
                        <ListItemText className={clsx({[classes.borderActive]:active===15})}  primary={t("Quản lý nhân viên")} classes={{primary:classes.textMenu}}/>
                    </ListItem>}
                    <ListItem component={Link} to='/admin/manage-access' button className={clsx(classes.listItem, {[classes.active]:active===1})}>
                        <LargeTooltip title={t("Quản lý ra vào")} ><ListItemIcon ><MeetingRoomOutlinedIcon className={clsx(classes.whiteColor, {[classes.borderActive]:active===1})}/> </ListItemIcon></LargeTooltip>
                        <ListItemText primary={t("Quản lý ra vào")} className={clsx({[classes.borderActive]:active===1})}  classes={{primary:classes.textMenu}}/>
                    </ListItem> 
                    {process.env.REACT_APP_IS_WARNING==='show' && <ListItem component={Link} to='/admin/warnings' button className={clsx(classes.listItem, {[classes.active]:active===12})}>
                        <LargeTooltip title={t("Cảnh báo/Lỗi")} ><ListItemIcon ><ReportIcon className={clsx(classes.whiteColor, {[classes.borderActive]:active===12})}/> </ListItemIcon></LargeTooltip>
                        <ListItemText primary={t("Cảnh báo/Lỗi")} className={clsx({[classes.borderActive]:active===12})}  classes={{primary:classes.textMenu}}/>
                    </ListItem>} 
                    <ListItem component={Link} to='/admin/manage-notifications' button className={clsx(classes.listItem, {[classes.active]:active===4})}>
                        <LargeTooltip title={t("Quản lý bảng tin")} ><ListItemIcon ><DateRangeOutlinedIcon className={clsx(classes.whiteColor, {[classes.borderActive]:active===4})}/> </ListItemIcon></LargeTooltip>
                        <ListItemText primary={t("Quản lý bảng tin")} className={clsx({[classes.borderActive]:active===4})}  classes={{primary:classes.textMenu}}/>
                    </ListItem> 
                    {process.env.REACT_APP_PAYMENT==='show' && <ListItem component={Link} to='/admin/payments/info' button className={clsx(classes.listItem,{[classes.active]:active===13})}>
                        <LargeTooltip title={t("Quản lý thanh toán")} ><ListItemIcon className={clsx({[classes.svgIconStyle]:active!==13})} ><PaymentIcon color="white" className={clsx(classes.whiteColor,{[classes.borderActive]:active===13})}/> </ListItemIcon></LargeTooltip>
                        <ListItemText primary={t("Quản lý thanh toán")} className={clsx({[classes.borderActive]:active===13})}  classes={{primary:classes.textMenu}}/>
                    </ListItem>} 
                   
                    {(role === 'admin' || role==='root') &&<ListItem component={Link} to='/admin/admin-account' button className={clsx(classes.listItem, {[classes.active]:active===5})}>
                        <LargeTooltip title={t("Quản lý tài khoản")} ><ListItemIcon ><SupervisedUserCircleOutlinedIcon className={clsx(classes.whiteColor, {[classes.borderActive]:active===5})}/> </ListItemIcon></LargeTooltip>
                        <ListItemText className={clsx({[classes.borderActive]:active===5})}  primary={t("Quản lý tài khoản")} classes={{primary:classes.textMenu}}/>
                    </ListItem> }
                   {process.env.REACT_APP_IS_SMART_CAMERA==='show' && <ListItem component={Link} to='/admin/smart-camera/live' button className={clsx(classes.listItem, {[classes.active]:active===14})}>
                        <LargeTooltip title={t("Smart camera")} ><ListItemIcon ><PhotoCameraFrontIcon className={clsx(classes.whiteColor, {[classes.borderActive]:active===14})}/> </ListItemIcon></LargeTooltip>
                        <ListItemText className={clsx({[classes.borderActive]:active===14})}  primary={t("Smart camera")} classes={{primary:classes.textMenu}}/>
                    </ListItem>} 
                    {process.env.REACT_APP_IS_SECURITY_CAMERA==='show' && <ListItem component={Link} to='/admin/security-camera' button className={clsx(classes.listItem, {[classes.active]:active===17})}>
                        <LargeTooltip title={t("Camera an ninh")} ><ListItemIcon ><VideocamOutlinedIcon className={clsx(classes.whiteColor, {[classes.borderActive]:active===17})}/> </ListItemIcon></LargeTooltip>
                        <ListItemText className={clsx({[classes.borderActive]:active===17})}  primary={t("Camera an ninh")} classes={{primary:classes.textMenu}}/>
                    </ListItem>} 
                    <ListItem component={Link} to='/admin/device-management/devices' button className={clsx(classes.listItem, {[classes.active]:active===11})}>
                        <LargeTooltip title={t("Quản lý thiết bị")} ><ListItemIcon ><DevicesIcon className={clsx(classes.whiteColor, {[classes.borderActive]:active===11})}/> </ListItemIcon></LargeTooltip>
                        <ListItemText className={clsx({[classes.borderActive]:active===11})}  primary={t("Quản lý thiết bị")} classes={{primary:classes.textMenu}}/>
                    </ListItem> 
                    {
                        process.env.REACT_APP_IS_BOOKING !== "hide" && 
                        <ListItem component={Link} to={listServiceBooking.length? `/admin/service-booking/book/${listServiceBooking[0].ID}` : '/admin/service-booking'} button className={clsx(classes.listItem,{[classes.active]:active===16})}>
                            <LargeTooltip title={t("Đặt dịch vụ")} ><ListItemIcon ><EventAvailableIcon className={clsx(classes.whiteColor,{[classes.borderActive]:active===16})}/> </ListItemIcon></LargeTooltip>
                            <ListItemText className={clsx({[classes.borderActive]:active===16})}  primary={t("Đặt dịch vụ")} classes={{primary:classes.textMenu}}/>
                        </ListItem> 
                    }
                    <ListItem onClick={onClickLinkToCabinet} component={Link} to='/admin/cabinet/statistic' button className={clsx(classes.listItem,{[classes.active]:active===20})}>
                        <LargeTooltip title={t("Tủ hàng")} ><ListItemIcon ><CabinetIcon className={clsx(classes.whiteColor,{[classes.borderActive]:active===20})}/> </ListItemIcon></LargeTooltip>
                        <ListItemText className={clsx({[classes.borderActive]:active===20})}  primary={t("Tủ hàng")} classes={{primary:classes.textMenu}}/>
                    </ListItem> 
                    {(role === 'admin' || role==='root') &&<ListItem component={Link} to='/admin/setting/theme' button className={clsx(classes.listItem,{[classes.active]:active===6})}>
                        <LargeTooltip title={t("Cài đặt")} ><ListItemIcon ><SettingsIcon className={clsx(classes.whiteColor,{[classes.borderActive]:active===6})}/> </ListItemIcon></LargeTooltip>
                        <ListItemText className={clsx({[classes.borderActive]:active===6})}  primary={t("Cài đặt")} classes={{primary:classes.textMenu}}/>
                    </ListItem> }           
                </List>}
            </Drawer>}
            <div style={{position:'fixed',bottom:'0',left:'0',height:'20px',width:'100%',background:'#0b59c8',zIndex:'1100',display:'flex',justifyContent:'center',alignItems:'center',color:'#FFFFFF',fontSize:'12px'}}>
                Version {pjson.version} - TMA Innovation 
            </div>
        </div>
    )
}

export default Menu;
