import React,{useState, useEffect,useRef} from 'react';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import IconButton from '@material-ui/core/IconButton/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Button from '@material-ui/core/Button/Button';
import axiosClient from '../../config/AxiosClient';
import { SketchPicker } from 'react-color';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import withStyles from '@material-ui/core/styles/withStyles';
import { useSelector, useDispatch } from 'react-redux';
import {checkImage} from '../../validation/CheckForm';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useHistory } from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Cookies from 'js-cookie';
import SuccessAlert from '../alert/SuccessAlert';
import ErrorAlert from '../alert/ErrorAlert';

const useStyles = makeStyles((theme)=>({
    title:{fontSize:'20px',fontWeight:'700',lineHeight:'27px',color:'#000000',fontStyle:'normal',float:'left',marginRight:'24px',paddingTop:'5px'},
    topBlock:{marginTop:'22px',marginBottom:'20px',height:'40px'},
    setting:{borderRadius:'6px 0 0 6px',background:'#FFFFFF',position:'relative',height:'100%',width:'100%',float:'left'},
    buildingSetting:{borderRadius:'0 6px 6px 0',background:'#FFFFFF',position:'relative',height:'100%',width:'50%',float:'left'},
    inputBlock:{height:'80px',paddingLeft:'22px',position:'relative'},
    label:{paddingBottom:'6px',fontSize: '16px',lineHeight: '140%',color: '#000000',display: 'inline-block',width:'250px'},
    picker:{position:'absolute',top:'0px',marginLeft:'40px',zIndex:'5'},
    alert:{position:'absolute',right:'3px',top:'3px',width:'350px'},
    saveBtn:{width:'160px',height:'40px',textTransform:'none',color:'#FFFFFF',fontSize:'18px',fontWeight:'600',background:'#009ADA',marginLeft:'16px'
    ,'&:hover':{background:'rgba(0, 154, 218, 0.2)'}
    },
    createBtn:theme.create_button,
    cancelBtn:theme.cancel_button,
    primaryTitle:theme.primary_title,
    secondaryTitle:theme.secondary_title,
    avatar_logo:{width:'160px',height:'68px',position:'relative',
    '&:hover label':{transition:'0.4s',opacity:'1'},
    '& label':{opacity:'0',transition:'0.4s'}
    },
    fileBtn:{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center',position:'absolute',left:'0',top:'0'},
    overlay:{position:'fixed',width:'100%',height:'100%',top:'0',left:'0',background:'rgba(0,0,0,0.1)',zIndex:'1300'},
    imageAdding:{display:'flex',justifyContent:'center',alignItems:'center',position:'absolute',width:'100%',height:'100%',top:'0',left:'0'}
}))

const CreateButton = withStyles(theme=>({root:theme.create_button}))(Button);
const CancelButton = withStyles(theme=>({root:theme.cancel_button}))(Button);
const UiSetting = props => {
    const classes = useStyles();
    const [showPicker,setShowPicker] = useState(false);
    const [showThemeColorPicker,setShowThemeColorPicker] = useState(false);
    const [loading,setLoading] = useState(false);
    const [isUpdating,setIsUpdating] = useState(false);
    const [isImageAdding,setIsImageAdding] = useState(false);
    const [color,setColor] = useState('#FFFFFF');
    const [config,setConfig] = useState({
        username:'tsmart-building',
        menu_background:'#FFFFFF',
        logo:'images/TMALogo.png',
        theme_color:'#009ADA'
    });
    const [message,setMessage] = useState({logo:'',success:'',error:''});
    const [isEdit,setIsEdit] = useState(false);
    const configLoading = useSelector(state => state.configState);
    const dispatch = useDispatch();
    const [isExpiredSession,setIsExpiredSession] = useState(false);
    const history = useHistory();
    const {t} = useTranslation();
    const alertRef = useRef();

    useEffect(()=>{
        if(isExpiredSession){
          history.push('/login');
          dispatch({type:'IS_LOGIN_AGAIN_TRUE'});
        } 
    },[isExpiredSession]);

    useEffect(() => {
        setConfig({...config,
            menu_background:typeof(configLoading.menu_background)==='undefined'? '':configLoading.menu_background ,
            logo:typeof(configLoading.logo)==='undefined'? '':configLoading.logo,
            theme_color:typeof(configLoading.theme_color)==='undefined'? '':configLoading.theme_color,      
        })
        setColor(typeof(configLoading.menu_background)==='undefined'? '':configLoading.menu_background);
        setLoading(true);
        setShowPicker(false);
        setIsEdit(false);
    }, [configLoading.loading,loading])

    const updateImageToAws = async(logo) =>{
        try {
            var res;
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                res = await axiosClient.post('/uploadfile',JSON.stringify(logo),{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
                });
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                res = await axiosClient.post('/fileUpload/logo',JSON.stringify(logo),{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`,'Content-Type' : 'application/json'}
                });
            }
            setMessage({...message,logo:''});
            setConfig({...config,logo:res.data});
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setMessage({...message,logo:t('Đã xảy ra lỗi, vui lòng thử lại!')});
        }     
        setIsImageAdding(false);
    }

    const onLogoChange = async(e) =>{
        if(typeof(e.target.files[0])==='undefined') return;
        if(!checkImage(e.target.files[0].type)) return setMessage({...message,logo:t('Vui lòng upload file ảnh')});
        if(e.target.files[0].size > 100000) return setMessage({...message,logo:t('File phải nhỏ hơn 100KB')});
        setIsImageAdding(true);
        setMessage({...message,logo:''});
        const reader = new FileReader();
        reader.onload = () =>{
            if(reader.readyState === 2){
                const source = reader.result.slice(reader.result.indexOf('base64,')+7);
                const logo ={
                    source: source,
                    file_name:e.target.files[0].name
                }
                updateImageToAws(logo);
            }
        }
        reader.readAsDataURL(e.target.files[0]);
    }

    const changePickerClick = ()=>{
        setShowPicker(!showPicker);
    }

    const onSelectedPickerClick = () =>{
        if(showPicker){
            setShowPicker(!showPicker);
            setColor(config.menu_background);
        }
    }

    const onSaveClick = async() =>{
        try {
            if(message.logo!=='') return;
            setIsUpdating(true);
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                await axiosClient.post('/updateconfig',JSON.stringify(config),{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
                });
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                await axiosClient.post('/configs/update',JSON.stringify(config),{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`,'Content-Type': 'application/json'}
                });
            }
            dispatch({type:'CHANGE_STATUS_LOADING_FALSE'});
            dispatch({type:'CHANGE_IS_UPDATED_THEME'});      
            if(showPicker) setShowPicker(!showPicker);
            if(showThemeColorPicker) setShowThemeColorPicker(!showThemeColorPicker);
            setIsEdit(!isEdit);
            setLoading(false);
            setMessage({...message,success:t('Cập nhật chủ đề thành công!'),error:''});
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setMessage({...message,success:'',error:t('Đã xảy ra lỗi, vui lòng thử lại!')});
        }
        setIsUpdating(false);
    }

    const onCancelClick = () =>{
        setMessage({...message,logo:''});
        setLoading(!loading);
    }

    const onMessageClose = () =>{
        setMessage({...message,success:'',error:''});
    }

    useEffect(()=>{
        if(message.success !== '' || message.error !== ''){
            if(alertRef.current) clearTimeout(alertRef.current);
            alertRef.current = setTimeout(() => {
                onMessageClose();
            }, 5000);
        }
    },[message])

    return (
        <div className={classes.setting}>
            <SuccessAlert message={message.success} onCloseDialog={onMessageClose} />
            <ErrorAlert message={message.error} onCloseDialog={onMessageClose} />
            {isUpdating && <div className={classes.overlay}></div>}
            <p className={classes.secondaryTitle} style={{marginTop:'0',paddingTop:'23px',paddingBottom:'22px'}} >{t('Giao diện')}</p>
            <div className={classes.inputBlock} style={{height:'130px'}}>
                <label className={classes.label} >{t('Logo')} </label><br />
                <div className={classes.avatar_logo} >
                    <input accept="image/*" className={classes.input} hidden name="avatar" id="icon-button-file" type="file" disabled={!isEdit} onChange={onLogoChange} />
                    {<img src={config.logo} width="100%" height="100%" alt="avatar-logo"/>}
                    <label htmlFor="icon-button-file" className={classes.fileBtn} >
                        {isEdit && <IconButton aria-label="upload picture" component="span">
                            <PhotoCamera  style={{color:'#565e67',fontSize:'40px'}} />
                        </IconButton>}
                    </label>
                    {isImageAdding &&
                    <div className={classes.imageAdding}>
                        <CircularProgress size={20} style={{color:'#0b59c8'}} />
                    </div>}
                    <p style={{color:'red',width:'300px'}}>{message.logo}</p>
                </div>
            </div>
            <div className={classes.inputBlock}>
                <div >
                    <label className={classes.label}>{t('Màu nền menu')} </label><br/>
                    
                    <div style={{display:'flex'}}>
                        <div style={{width:"40px",height:'40px',background:color}}></div>
                        {!showPicker && 
                        <CreateButton 
                            variant="contained" 
                            disabled={!isEdit} 
                            style={{marginLeft:'10px'}} 
                            onClick={changePickerClick} 
                        >
                            {t('Chọn')}
                        </CreateButton>}
                        {showPicker && 
                        <CancelButton 
                            variant="outlined" 
                            style={{marginLeft:'10px'}} 
                            onClick={onSelectedPickerClick} 
                        >
                            {t('Hoàn tất')}
                        </CancelButton>}
                        {showPicker && 
                            <div style={{position:'relative'}}>
                                <SketchPicker color={config.menu_background} onChange={c => setConfig({...config,menu_background:c.hex})} className={classes.picker}/>
                                <IconButton aria-label="upload picture" component="span" style={{zIndex:'10',position:'absolute',top:'-15px',left:'25px'}} onClick={changePickerClick}>
                                    <CancelOutlinedIcon />
                                </IconButton>
                            </div>        
                        }
                    </div>
                </div>
                
            </div>
            {/* <div className={classes.inputBlock}>
                <div style={{float:'left'}}>
                    <label className={classes.label}>{t('Thay đổi màu chủ đề')} </label>
                    {!showThemeColorPicker && 
                    <CreateButton 
                        variant="contained" 
                        // color="primary" 
                        disabled={!isEdit} 
                        style={{marginLeft:'10px'}} 
                        onClick={changePickerThemeColorClick} 
                    >
                        {t('Chọn')}
                    </CreateButton>}
                    {showThemeColorPicker && 
                    <CancelButton 
                        variant="outlined" 
                        style={{marginLeft:'10px'}} 
                        onClick={onSelectedThemeColorPickerClick} 
                    >
                        {t('Hoàn tất')}
                    </CancelButton>}
                    {showThemeColorPicker && 
                        <div style={{position:'relative'}}>
                            <SketchPicker color={config.theme_color} onChange={color => setConfig({...config,theme_color:color.hex})} className={classes.picker}/>
                            <IconButton aria-label="upload picture" component="span" style={{zIndex:'10',position:'absolute',top:'-15px',left:'25px'}} onClick={changePickerThemeColorClick}>
                                <CancelOutlinedIcon />
                            </IconButton>
                        </div>        
                    }
                </div>
                <div style={{width:"25px",height:'25px',background:themeColor,float:'left',margin:'5px', borderRadius:'50%'}}></div>
            </div> */}
            
            {isEdit && 
            <div style={{padding:'50px 22px',textAlign:'left'}}>
                <CancelButton 
                    variant="outlined" 
                    onClick={onCancelClick} 
                    disabled={isUpdating}
                >
                    {t('Hủy')}
                </CancelButton>
                <CreateButton
                    variant="contained" 
                    onClick={onSaveClick} 
                    style={{textAlign:'center',position:'relative'}} 
                    disabled={isUpdating}
                >
                    {t('Lưu')}
                    {isUpdating && <CircularProgress size={25} style={{position:'absolute',color:'#0b59c8'}}/>}
                </CreateButton>          
            </div>}
            {!isEdit && 
            <div style={{padding:'50px 22px',textAlign:'left'}}>
                <CreateButton
                    variant="contained" 
                    onClick={()=>setIsEdit(true)} 
                    style={{textAlign:'center',position:'relative',margin:'0'}} 
                >
                    {t('Chỉnh sửa')}
                    {isUpdating && <CircularProgress size={25} style={{position:'absolute',color:'#0b59c8'}}/>}
                </CreateButton>          
            </div>}
        </div>

    )
}

export default UiSetting;
