import React, {useState, useEffect,useRef} from 'react';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import {useTranslation} from 'react-i18next';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import Button from '@material-ui/core/Button/Button';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { GridOverlay, DataGrid } from '@material-ui/data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import axiosClient from '../../../config/AxiosClient';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import {useHistory,Link } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import EditAd from './EditAd';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import SuccessAlert from '../../alert/SuccessAlert';
import ErrorAlert from '../../alert/ErrorAlert';
import LargeTooltip from '../../largeTooltip';

const useStyles = makeStyles((theme)=>({
    inputBlock:{height:'110px',width:'20%',margin:'auto',position:'relative',float:'left'},
    label:{margin:'0',fontSize: '14px',lineHeight: '140%',color: '#7A7171',display: 'inline-block'},
    input:{width: '100%'},
    placeholder:{color:'#B2B2B2',fontSize:'12px'},
    error:{color:'red',position:'absolute',bottom:'0',left:'20px'},
    alert:{position:'absolute', left:'3px', top:'3px',width:'99%'},
    dialogTitle:{textAlign:'center',fontSize:'20px',fontWeight:'600',lineHeight:'25px',color:'#FFFFFF'},
    contentDialog:{fontSize:'16px',height:'68px',borderBottom:'1px solid #EEEDF2',display:'flex',alignItems:'center',justifyContent:'center'},
    dialogTitleBackground:theme.primaryColor,
    errorColor:theme.errorColor,
    overlay:{position:'fixed',width:'100%',height:'100%',top:'0',left:'0',background:'rgba(0,0,0,0.1)',zIndex:'1300'},
    requestError:{width:'100%',height:'65vh',display:'flex',justifyContent:'center',alignItems:'center',fontSize:'20px',color:'#6D6D6D'},
    root:theme.dataGridStyle,
    tableTitle:{position:'relative',height:'56px',border:'1px solid rgba(224, 224, 224, 1)',borderTopLeftRadius:'6px',borderTopRightRadius:'6px'},
    alertStyle:{position:'absolute',right:'9px',bottom:'8px',padding:'0',zIndex:'10'},
    selectTitle:{fontSize:'16px',lineHeight:'22.4px',position:'absolute',bottom:'8px',right:'8px'},
}))

const CreateButton = withStyles(theme =>({root:theme.create_button}))(Button);
const CancelButton = withStyles(theme =>({root:theme.cancel_button}))(Button);
const DeleteButton = withStyles(theme =>({root:theme.delete_button}))(Button);

const Advertisements = ()=>{
    const classes = useStyles();
    const {t} = useTranslation();
    const [alert,setAlert] = useState({success:'',error:'',request:''});
    const [ads,setAds] = useState([]);
    const [advertisementID,setAdvertisementID] = useState({});
    const [rowsPerPage,setRowsPerPage] = useState(parseInt(localStorage.getItem('rowsPerPage')));
    const [isDelete,setIsDelete] = useState(false);
    const [isProcessing,setIsProcessing] = useState(false);
    const [loading,setLoading] = useState({table:false,page:false});
    const [selectedIds,setSelectedIds] = useState([]);
    const [selectedFiles,setSelectedFiles] = useState([]);
    const [zoomImage,setZoomImage] = useState(false);
    const [isEdit,setIsEdit] = useState(false);
    const [isOrder,setIsOrder] = useState(false);
    const [image,setImage] = useState('');
    const [isAction,setIsAction] = useState('');
    const [anchorEl,setAnchorEl] = useState(null);
    const [isExpiredSession,setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const alertRef = useRef();

    const columns = [
        {
            field:'Image',
            headerName:t('Hình thu nhỏ'),
            cellClassName:'super-app-theme--cell',
            width:140,
            sortable:false,
            renderCell:params => 
            <LargeTooltip title={t('Xem')} placement="right">
                <img style={{cursor:'pointer'}} onClick={()=>onViewClick(params.row.Image)} src={params.row.Image} width="96px" height="30px" alt=""/>
            </LargeTooltip>
        },
        {
            field:'Description',
            headerName:t('Mô tả'),
            cellClassName:'super-app-theme--cell',
            flex:1,
            renderCell: params => <LargeTooltip title={params.row.Description} placement="bottom-start"><p style={{textOverflow: 'ellipsis',overflow:'hidden'}}>{params.row.Description}</p></LargeTooltip>
        },
        {
            field:'AdvertisementStatus',
            headerName:t('Trạng thái'),
            cellClassName:'super-app-theme--cell',
            flex:0.4,
            renderCell:params => params.row.AdvertisementStatus==='enabled'?<p style={{color:'#2AB930'}}>{t('Đã kích hoạt')}</p>:<p style={{color:'#e01010'}}>{t('Đã vô hiệu')}</p>
        },
        {
            field:'AdvertisementDuration',
            headerName:t('Thời gian quảng cáo'),
            cellClassName:'super-app-theme--cell',
            flex:0.5,
            renderCell: params => <p>{typeof(params.row.AdvertisementDuration)!=='undefined'?params.row.AdvertisementDuration +' '+ t('giây'):'' } </p>
        },
        {
            field:'AdvertisementIndex',
            headerName:t('Thứ tự quảng cáo'),
            cellClassName:'super-app-theme--cell',
            flex:0.5,
            sortable:false,
            renderCell: params => 
            <div>   
                <IconButton disabled={isMaxIndex(params.row.AdvertisementIndex)||!isOrder} onClick={()=>onDownClick(params.row)} style={{padding:'0'}} >
                    {!isMaxIndex(params.row.AdvertisementIndex) && isOrder && <ArrowDownwardIcon style={{fontSize:'25px',color:'#FFFFFF',background:'#e01010',borderRadius:'50%'}} />} 
                    {(isMaxIndex(params.row.AdvertisementIndex) || !isOrder)&& <ArrowDownwardIcon style={{fontSize:'25px',color:'#FFFFFF',background:'gray',borderRadius:'50%'}} />} 
                </IconButton>
                <IconButton disabled={isMinIndex(params.row.AdvertisementIndex)||!isOrder} onClick={()=>onUpClick(params.row)} style={{padding:'0',marginLeft:'5px'}}>
                    {!isMinIndex(params.row.AdvertisementIndex) && isOrder && <ArrowUpwardIcon  style={{fontSize:'25px',color:'#FFFFFF',background:'#2AB930',borderRadius:'50%'}} />} 
                    {(isMinIndex(params.row.AdvertisementIndex) || !isOrder)&& <ArrowUpwardIcon  style={{fontSize:'25px',color:'#FFFFFF',background:'gray',borderRadius:'50%'}} />} 
                </IconButton>
            </div>
        },
        {
            field:'Method',
            headerName:t('Thao tác'),
            cellClassName:'super-app-theme--cell',
            flex:0.3,
            sortable:false,
            renderCell: params =>
            <div style={{position:'relative'}}>
                <Button 
                    aria-controls="simple-menu" 
                    aria-haspopup="true" 
                    onClick={(e)=>{
                        setAnchorEl(e.target);
                        setIsAction(params.row.ID);
                    }} 
                style={{fontSize:'24px',fontWeight:'900',color:'#0b59c8'}} >
                    ...  
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={params.row.ID === isAction && Boolean(anchorEl)}
                    onClose={handleClose}
                    className={classes.itemFont}
                >
                    <MenuItem 
                        style={{color:'#0b59c8'}} 
                        onClick={()=>{
                            setAnchorEl(null);
                            setIsEdit(true);
                            setAdvertisementID(params.row.ID);
                        }}
                    >
                        {t("Chỉnh sửa")}
                    </MenuItem>
                    {(params.row.AdvertisementStatus==='enabled' || params.row.ResidenceStatus==='active')&&<MenuItem style={{color:'#e01010'}} onClick={()=>onDisableClick(params.row.ID)}>{t("Vô hiệu hóa")}</MenuItem>}
                    {params.row.AdvertisementStatus==='disabled'&&<MenuItem style={{color:'#2AB930'}} onClick={()=>onEnableClick(params.row.ID)}>{t("Kích hoạt")}</MenuItem>}         
                </Menu>
            </div>
        },
    ]
    
    useEffect(()=>{
        if(isExpiredSession){
          history.push('/login');
          dispatch({type:'IS_LOGIN_AGAIN_TRUE'});
        } 
    },[isExpiredSession]);

    const isMaxIndex = (index)=>{
        var res = true;
        ads.forEach(item=>{
            if(index < item.AdvertisementIndex) res= false;
        })
        return res;
    }
    const isMinIndex = (index)=>{
        var res = true;
        ads.forEach(item=>{
            if(index > item.AdvertisementIndex) res = false;
        })
        return res;
    }
    const getDefaultValues = async() =>{
        try {
            const res = await axiosClient.get('/advertisement/list',{headers:{'Authorization': `Bearer ${Cookies.get('token')}`}});
            const data = [];
            // for(var i=0;i<res.data.Items.length;i++){
            //     const url = await axiosClient.get(`/s3/signedurl?url=${res.data.Items[i].Image}`,{headers:{'Authorization': `Bearer ${Cookies.get('token')}`}});
            //     data.push({...res.data.Items[i],id:res.data.Items[i].ID + '-'+ res.data.Items[i].Image,Thumbnail:url.data});
            // }
            res.data.Items.forEach(item=>{
                data.push({...item,id:item.ID + '-'+ item.Image});
            })
            setAds(data);
            setAlert({...alert,request:''});
            setSelectedIds([]);
            setSelectedFiles([]);
            setIsOrder(false);
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({...alert,request:t('Không lấy được dữ liệu')});
        }
        setLoading({table:true,page:true});
    }
    useEffect(()=>{
        if(!loading.table || !loading.page) getDefaultValues();
    },[loading.table,loading.page])

    const sortAd = (ad1,ad2)=>{
        if(ad1.AdvertisementIndex<ad2.AdvertisementIndex) return -1;
        return ad1.AdvertisementIndex>ad2.AdvertisementIndex?1:0
    }

    const onDownClick = async(data) =>{
        const index = data.AdvertisementIndex;
        const res = [];
        ads.forEach(item=>{
            switch (item.AdvertisementIndex) {
                case index+1: res.push({...item,AdvertisementIndex:index});
                    break;
                case index: res.push({...item,AdvertisementIndex:index+1});
                    break;
                default : res.push(item);
                    break;
            }
        })
        res.sort((v1,v2)=>sortAd(v1,v2));
        setAds(res);
        
    }
    
    const onUpClick = async(data) =>{
        const index = data.AdvertisementIndex;
        const res = [];
        ads.forEach(item=>{
            switch (item.AdvertisementIndex) {       
                case index: res.push({...item,AdvertisementIndex:index-1});
                    break;
                case index-1: res.push({...item,AdvertisementIndex:index});
                    break;
                default : res.push(item);
                    break;
            }
        })
        res.sort((v1,v2)=>sortAd(v1,v2));
        setAds(res);
    }

    const onSaveOrderClick = async() =>{
        try {
            setIsProcessing(true);
            await axiosClient.post('/advertisement/update',{'index':ads},{
                headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
            });
            setAlert({...alert,success:t('Thứ tự đã được thay đổi thành công!'),error:''});
            setIsOrder(false);
            setLoading({...loading,table:false});
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({...alert,error:t('Thay đổi không thành công, vui lòng thử lại sau!'),success:''});
        }
        setIsProcessing(false);
    }

    const handleClose = () =>{
        setAnchorEl(null);
    }
    const onEditBack = () =>{
        setLoading({...loading,table:false});
        setIsEdit(false);
    }
    const onViewClick = (data) =>{
        setImage(data);
        setZoomImage(true);
        setAnchorEl(null);
    }
    const onSelectedChange =(e)=>{
        const ids = [];
        const files = [];

        e.forEach(value =>{
            const index = value.indexOf('-');
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                ids.push(parseInt(value.slice(0,index)));
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                ids.push(value.slice(0, index));
            }
            files.push(value.slice(index+1));
        })
        setSelectedFiles(files);
        setSelectedIds(ids);
    }
    const onDeleteClick = ()=>{
        setIsDelete(true);
    }
    const onConfirmDeleteClick = async() =>{
        try {
            setIsProcessing(true);
            await axiosClient.post('/advertisement/delete',{'ids':selectedIds,'files':selectedFiles},{
                headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
            })
            setAlert({...alert,success:t('Xóa thành công!'),error:''});
            setLoading({...loading,page:false});
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({...alert,error:t('Xóa không thành công, vui lòng thử lại!'),success:''});
        }
        setIsDelete(false);
        setIsProcessing(false);
    }

    const onDisableClick = async(ID) =>{
        try {
            setAnchorEl(null);
            setIsProcessing(true);
            await axiosClient.post('/advertisement/update',{'id':ID,'status':'disabled'},{
                headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
            })
            setAlert({...alert,success:t('Vô hiệu hóa thành công!'),error:''});
            setLoading({...loading,table:false});
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({...alert,error:t('Vô hiệu hóa không thành công, vui lòng thử lại sau!'),success:''});
        }
        setIsProcessing(false);
    }
    const onEnableClick = async(ID) =>{
        try {
            setAnchorEl(null);
            setIsProcessing(true);
            await axiosClient.post('/advertisement/update',{'id':ID,'status':'enabled'},{
                headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
            })
            setAlert({...alert,success:t('Kích hoạt thành công!'),error:''});
            setLoading({...loading,table:false});
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({...alert,error:t('Kích hoạt không thành công, vui lòng thử lại sau!'),success:''});
        }
        setIsProcessing(false);
    }

    const onCloseDialog = ()=>{
        setAlert({...alert,success:'',error:''});
    }

    useEffect(()=>{
        if(alert.success !== '' || alert.error !== ''){
            if(alertRef.current) clearTimeout(alertRef.current);
            alertRef.current = setTimeout(() => {
                onCloseDialog();
            }, 5000);
        }
    },[alert])

    const ConfirmDeleteDialog = () =>{
        return(
          <Dialog 
            open={isDelete}
            maxWidth="xl"
          >
            <DialogTitle className={classes.dialogTitleBackground}>
                <p className={classes.dialogTitle}>{t('Thông báo')}</p>
            </DialogTitle>
            <DialogContent style={{width:'448px',padding:'0',textAlign:'center',position:'relative'}}>
                <p className={classes.contentDialog}>{t('Bạn có chắc chắn muốn xóa?')}</p>
                <CancelButton 
                  style={{marginLeft:'30px'}}
                  onClick={()=>setIsDelete(false)}
                  disabled={isProcessing} 
                >
                  {t('Trở về')}
                </CancelButton>
                <CreateButton 
                  style={{margin:'10px 20px'}}
                  onClick={onConfirmDeleteClick} 
                  disabled={isProcessing}
                >
                  {t('Đồng ý')}
                </CreateButton>
                {isProcessing && <CircularProgress style={{position:'absolute',top:'10%',left:'45%',color:'#0b59c8'}}/>}
            </DialogContent>
          </Dialog>
        )
    }

    const zoomImageDialog = () =>{
        return (
          <Dialog open={zoomImage} maxWidth="lg" >
            <DialogContent style={{textAlign:'right',width:'704px',height:'220px'}}>
              <IconButton style={{position:'absolute',top:'0',right:'0',zIndex:'2',borderRadius:'50%',padding:'2px',background:'gray'}} onClick={()=>setZoomImage(false)} className={classes.closeButton}>
                <CloseIcon style={{color:'white'}} /> 
              </IconButton>
              <img src={image} alt="" width='100%' height='100%' style={{position:'absolute',top:'0',left:'0',zIndex:'1'}} />
            </DialogContent>
          </Dialog>
        )
    }

    const EmptyRowsView = () => {
        return (
          <div
            style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}
          >
            <h3 style={{color:'#6D6D6D',fontSize: '16px'}}>
              {t('Không tìm thấy hình quảng cáo')}
            </h3>
          </div>
        );
    };

    const customLoading = () =>{
        return(
            <GridOverlay>
                <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                    <LinearProgress />
                </div>
            </GridOverlay>
        )
    }

    return(
        <div style={{height:'100%'}}>
            {ConfirmDeleteDialog()}     
            {zoomImageDialog()}  
            {!isEdit &&<p style={{fontSize:'22px',fontWeight:'bold',padding:'23px 0 10px 16px'}}>{t('Danh sách quảng cáo')}</p>}  
            {isProcessing && <div className={classes.overlay} ></div>}
            {alert.request !== '' && <div className={classes.requestError}>{alert.request}</div>}
            {!loading.page && alert.request === '' && 
            <div style={{width:'100%',height:'80%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                <CircularProgress style={{color:'#0b59c8'}}/>
            </div>}
            {loading.page && alert.request === '' &&
            <div style={{marginTop:'20px',width:'97%',margin:'auto',height:'77%'}}>
                {!isEdit && <div style={{height:'100%'}}> 
                    <div className={classes.tableTitle} >    
                        <SuccessAlert message={alert.success} onCloseDialog={onCloseDialog} />
                        <ErrorAlert message={alert.error} onCloseDialog={onCloseDialog} />
                        <CreateButton
                            startIcon={<AddCircleOutlineOutlinedIcon/>}
                            variant="contained"
                            style={{margin:'8px'}}
                            component={Link}
                            to='/admin/setting/add-advertisement'
                        >
                            {t("Thêm")}
                        </CreateButton>   
                        <DeleteButton
                                variant="outlined"
                                startIcon={<DeleteOutlineOutlinedIcon />}
                                onClick={onDeleteClick}             
                                disabled={selectedIds.length === 0}
                            >
                                {t("Xóa")}
                        </DeleteButton>
                        {!isOrder && <LargeTooltip style={{float:'right'}} title={t("Sắp xếp lại thứ tự quảng cáo")} >
                            <span>
                                <CreateButton
                                    variant="contained"
                                    style={{margin:'8px 8px 0 0'}}
                                    onClick={()=>{setIsOrder(true)}}
                                >
                                    {t("Sắp xếp QC")}
                                </CreateButton>
                                
                            </span>
                        </LargeTooltip> }
                        {isOrder && <CreateButton
                            variant="contained"
                            style={{margin:'8px 8px 0 0',float:'right'}}
                            onClick={onSaveOrderClick}
                        >
                            {t("Lưu")}
                        </CreateButton>}     
                    </div>
                    <div style={{height:'100%', width: '100%',paddingBottom:'15px'}} className={classes.root}>       
                        <DataGrid 
                            componentsProps={{
                                pagination: {
                                    labelRowsPerPage: t("Số dòng mỗi trang"),
                                    labelDisplayedRows:({from,to,count}) => {return `${from}-${to} ${t('trong số')} ${count}`}
                                }
                            }}
                            components={{
                                NoRowsOverlay:EmptyRowsView,
                                LoadingOverlay: customLoading,
                            }}  
                            localeText={{
                                footerRowSelected: (count) => count +t(" hàng trong bảng đã được chọn"),
                                columnHeaderSortIconLabel: t('Sắp xếp')
                            }}
                            rows={ads} 
                            columns={columns} 
                            pageSize={rowsPerPage}
                            rowHeight={45}
                            checkboxSelection 
                            rowsPerPageOptions={[10,50,100]}
                            onPageSizeChange={pageSize=>{
                                setRowsPerPage(pageSize);
                                localStorage.setItem('rowsPerPage',pageSize);
                            }}
                            onSelectionModelChange={onSelectedChange}
                            hideFooterRowCount
                            disableSelectionOnClick
                            disableColumnMenu={true}
                            loading={!loading.table}
                        />
                    </div>
                </div>}
                {isEdit && <EditAd advertisementID={advertisementID} onEditBack={()=>onEditBack()}/>}
            </div>}    
        </div>
    )
}

export default Advertisements;