import React, { useEffect, useState, useRef } from 'react';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import { Link, Route, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import SmartCamera from './live_camera/SmartCamera';
import CameraTable from './settings/CameraTable';
import HistoryTable from './history/HistoryTable';
import { useTranslation } from 'react-i18next';
import AddSmartCamera from './settings/AddSmartCamera';
import ResidentDetailInfo from '../residences/approval_history/ResidentDetailInfo';

const useStyles = makeStyles((theme)=>({
    topBlock:{
        marginTop:'22px', 
        marginBottom:'20px', 
        height:'40px'
    }, 
    buildingSetting:{
        borderRadius:'0 6px 6px 0', 
        background:'#FFFFFF', 
        position:'relative', 
        width:'100%'
    }, 
    primaryTitle:theme.primary_title, 
    secondaryTitle:theme.secondary_title, 
    menu:{
        width:'18%', 
        height:'100%', 
        marginRight:'1%', 
        background:'#FFFFFF', 
        borderRadius:'6px', 
        paddingBottom:'10px'
    }, 
    content:{
        width:'81%', 
        borderRadius:'6px', 
        background:'#FFFFFF', 
        height:'100%'
    }, 
    link:{
        display:'block', 
        height:'40px', 
        fontSize:'14px', 
        padding:'10px', 
        color:'#000000', 
        position:'relative'
    }, 
    active:{
        background:'#50c9ff4a'
    }, 
    activeBackground:{
        position:'absolute', 
        width:'100%', 
        height:'100%', 
        top:'0', 
        left:'0', 
        background:'#50c9ff4a'
    }, 
    triangle:{
        height:'30px', 
        width:'30px', 
        position:'absolute', 
        top:'5px', 
        right:'-5px', 
        background:'#FFFFFF', 
        transform:'rotate(45deg)', 
        zIndex:'0'
    }, 
    menuContainer:{
        width:'98%', 
        minWidth:'1250px', 
        margin:'auto', 
        height:'100%'
    }
}))

const Setting = props => {
    const classes = useStyles();
    const location = useLocation();
    const [active, setActive] = useState(0);
    const heightRef = useRef();
    const { t } = useTranslation();

    useEffect(() => {
        if(location.pathname.match('/admin/smart-camera/live*')){
            heightRef.current.style['height'] = '100%';
            setActive(1);
        } 
        if(location.pathname==='/admin/smart-camera/histories'){
            heightRef.current.style['height'] = '100%';
            setActive(2);
        } 
        if(location.pathname==='/admin/smart-camera/settings' || location.pathname==='/admin/smart-camera/settings/add-camera'){
            heightRef.current.style['height'] = '100%';
            setActive(3);
        } 
    }, [location.pathname])

    return (
        <div className={classes.menuContainer} >
            <div ref={heightRef} style={{width:'100%', height:'100%', display:'flex'}} >
                <div className={classes.menu}>
                    <div className={classes.topBlock} style={{marginBottom:'0'}}>
                        <p className={classes.secondaryTitle} style={{marginLeft:'10px'}}>{t('Smart camera')}</p>
                    </div>
                    <Link to='/admin/smart-camera/live' className={clsx(classes.link)}>
                        {t('Smart camera live')}
                        {active === 1 && <div className={classes.triangle}></div>}
                        {active === 1 && <div className={classes.activeBackground}></div>}
                    </Link>    
                    <Link to='/admin/smart-camera/histories' className={clsx(classes.link)}>
                        {t('Lịch sử')}
                        {active === 2 && <div className={classes.triangle}></div>}
                        {active === 2 && <div className={classes.activeBackground}></div>}
                    </Link>
                    <Link to='/admin/smart-camera/settings' className={clsx(classes.link)}>
                        {t('Cài đặt')}
                        {active === 3 && <div className={classes.triangle}></div>}
                        {active === 3 && <div className={classes.activeBackground}></div>}
                    </Link>
                </div>
                <div className={classes.content} >
                    <Route exact path='/admin/smart-camera/live' component={SmartCamera} />
                    <Route path='/admin/smart-camera/live/resident' component={ResidentDetailInfo} />
                    <Route exact path='/admin/smart-camera/histories' component={HistoryTable} />
                    <Route exact path='/admin/smart-camera/settings' component={CameraTable} />   
                    <Route exact path="/admin/smart-camera/settings/add-camera" component={AddSmartCamera}/>            
                </div>
            </div>
        </div>
    )
}

export default Setting;
