import makeStyles from '@material-ui/styles/makeStyles/makeStyles';

export const useStyles = makeStyles(() => ({
    cpnSelect: {
        height: "40px",

        "& .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input": {
            backgroundColor: "unset"
        }
    },
    helperText: {
        color: 'red'
    },
    rounded: {
        borderRadius: '40px'
    }
}));