import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { LinearProgress } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { apiCabinetsChartGet, apiCabinetsRevenuesDateChartsGet } from '../../api/cabinets';
import { apiDeviceGetAll } from '../../api/device';
import { apiHistoryGetAll } from '../../api/histories';
import { apiInfo } from '../../api/info';
import { formatToVND } from '../../helper/currency';
import DoughnutCommon from '../../pages/cabinet/statisticalAnalysis/doughnutCommon';
import AccessBarChart from './AccessBarChart';
import AccessLineChart from './AccessLineChart';
import AccessTable from './AccessTable';
import AccessTypeChart from './AccessTypeChart';
import WarningTable from './WarningTable';
import LargeTooltip from '../largeTooltip';

const useStyles = makeStyles((theme) => ({
    primaryTitle: theme.primary_title,
    table: {
        width: '100%',
        height: '500px',
        background: '#FFFFFF',
        paddingBottom: '55px',
        marginBottom: '22px',
        borderRadius: '6px'
    },
    root: theme.dataGridStyle,
    icon: {
        '& svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall': {
            color: '#2DCE8',
            fontWeight: 'bold'
        }
    },
    blockValue: {
        fontSize: '34px',
        fontWeight: 'bold',
        color: '#19191D'
    },
    blockTitle: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#9696A0'
    },
    dashboardPage: {
        minWidth: '1250px',
        paddingBottom: '30px'
    },
    partTop: {
        display: 'flex',
        width: '100%',
        margin: 'auto',
        marginTop: '15px'
    },
    partTopItem: {
        width: '23%',
        background: '#FFFFFF',
        height: '115px',
        margin: '0 1%',
        borderRadius: '6px'
    },
    leftBlock: {
        margin: '2px 0 0 14px',
        float: 'left'
    },
    rightBlock: {
        float: 'right',
        margin: '2px 14px 0 0'
    },
    horizontalBarResident: {
        height: '8px',
        background: '#F2A0BC',
        margin: '8px',
        borderRadius: '8px'
    },
    horizontalBarActiveResident: {
        height: '8px',
        background: '#3870FF',
        margin: '8px',
        borderRadius: '8px'
    },
    horizontalBarDevice: {
        height: '8px',
        background: '#29CB97',
        margin: '8px',
        borderRadius: '8px'
    },
    horizontalBarHistory: {
        height: '8px',
        background: '#FF9838',
        margin: '8px',
        borderRadius: '8px'
    },
    WarningIconStyle: {
        marginLeft: '10px',
        position: 'relative',
        bottom: '2px',
        color: 'rgb(255, 152, 0)!important',
        fontSize: '34px!important',
        position: 'relative',
        bottom: '4px'
    },
    partChart: {
        width: '100%',
        margin: 'auto',
        display: 'flex',
        marginTop: '20px'
    },
    partTable: {
        width: '98%',
        margin: 'auto',
        display: 'flex',
        marginTop: '20px'
    },
    residentIconStyle: {
        background: 'rgb(56 112 255 / 20%)',
        borderRadius: '50%'
    },
    accessText: {
        fontSize: '10px',
        color: '#333333',
        opacity: '0.6',
        padding: '10px 0 0 5px'
    },
    lineChartBlock: {
        width: '48%',
        margin: '0 1%',
        background: '#FFFFFF',
        borderRadius: '6px',
        position: 'relative'
    },
    circleChartBlock: {
        width: '23%',
        margin: '0 1%',
        background: '#FFFFFF',
        borderRadius: '6px'
    },
    leftTableBlock: {
        width: '60%',
        background: '#FFFFFF'
    },
    rightTableBlock: {
        width: '38%',
        background: '#FFFFFF',
        marginLeft: '2%'
    },
    cabinetChart: {
        display: 'flex',
        boxSizing: 'border-box',
        width: '98%',
        margin: '18px auto 0'
    },
    cabinetLine: {
        position: 'relative',
        border: '1px solid #E0E0E0',
        borderRadius: '4px',
        marginRight: '29px',
        padding: '16px',
        width: '70%',
        background: '#FFFFFF'
    },
    wrapTitleLine: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '21px'
    },
    lineTitle: {
        'font-weight': '700',
        'font-size': '18px'
    },
    lineTitleWeek: {
        marginLeft: '8px',
        color: '#9F9F9F',
        'font-weight': '400',
        'font-size': '14px'
    },
    viewMode: {
        fontWeight: '700',
        fontSize: '16px',
        paddingRight: '12px'
    },
    cabinetDoughnut: {
        position: 'relative',
        padding: '16px',
        width: '30%',
        background: '#FFFFFF',
        border: '1px solid #E0E0E0',
        borderRadius: '4px',
    },
    wrapLoading: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%"
    },
    doughnutTitle: {
        marginBottom: '34px'
    },
    wrapChartCabinetLine: {
        height: '200px'
    }
}))

const Dashboard = (props) => {
    const moment = require('moment-timezone');
    const { t } = useTranslation();
    const classes = useStyles();
    const [residentCount, setResidentCount] = useState(0);
    const [activeResident, setActiveResident] = useState(0);
    const [accessCount, setAccessCount] = useState(0);
    const [deviceInfo, setDeviceInfo] = useState('');
    const [isExpiredSession, setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const [circleData, setCircleData] = useState({});
    const [lineData, setLineData] = useState({});
    const [barData, setBarData] = useState({});
    const [offlineDeviceCount, setOfflineDeviceCount] = useState(0);
    const [state, setState] = useState({
        dataCabinetLine: {
            totalRevenue: 0,
            labels: [],
            datasets: [
                {
                    label: t('Doanh thu tủ'),
                    data: [],
                    fill: false,
                    borderColor: '#0FA7E7',
                    backgroundColor: '#0FA7E7',
                    color: '#FFFFFF'
                }
            ]
        },
        dataCabinetDoughnut: {
            labelCustoms: [t('Tủ hoạt động'), t('Tủ trống')],
            datasets: [{
                label: [t('Tủ hoạt động'), t('Tủ trống')],
                data: [0, 0],
                backgroundColor: ['#FEA54C', '#F9E8C9'],
                borderWidth: 0
            }]
        },
        isDoughnutLoading: false,
        isCabinetLineLoading: false
    })

    useEffect(() => {
        if (isExpiredSession) {
            history.push('/login');
            dispatch({ type: 'IS_LOGIN_AGAIN_TRUE' });
        }
    }, [isExpiredSession]);

    const getUsers = async () => {
        try {
            let res = await apiInfo();
            setResidentCount(res.data.Count);
            const activeUser = res.data.Items.filter(item => item.ResidenceStatus === 'active');
            setActiveResident(activeUser.length);
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setActiveResident(0);
        }
    }

    const getAccesses30day = async () => {
        try {
            var date1 = new Date(new Date().setHours(7, 0, 0, 0));
            const lastWeek = date1.setDate(date1.getDate() - 6);
            var date2 = new Date(new Date().setHours(7, 0, 0, 0));
            const lastMonth = date2.setDate(date2.getDate() - 29);
            const data = {
                isDashboard: {
                    month: lastMonth,
                    week: lastWeek
                }
            }
            let res = await apiHistoryGetAll(data);
            res.data.Items.forEach(item => {
                switch (item.Chart) {
                    case 'accesses': setAccessCount(item.Value);
                        break;
                    case 'column': setBarData(item.Value)
                        break;
                    case 'circle': setCircleData(item.Value)
                        break;
                    case 'line': setLineData(item.Value)
                        break;
                    default:
                        break;
                }
            })
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAccessCount(0);
        }
    }

    const getDevices = async () => {
        try {
            let res = await apiDeviceGetAll();
            const onlineDevice = res.data.Items.filter(item => item.DeviceStatus === 'online');
            var date = new Date();
            date.setHours(date.getHours() - 1);
            const offlineDevice = res.data.Items.filter(item => item.LastUpdatedTime < date.getTime());
            setOfflineDeviceCount(offlineDevice.length);
            const deviceTotal = res.data.Count;
            setDeviceInfo(onlineDevice.length + '/' + deviceTotal);
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setDeviceInfo('0');
        }
    }

    const fetchApiCabinetsChartGet = async () => {
        setState(prev => ({
            ...prev,
            isDoughnutLoading: true
        }))
        try {
            const { data } = await apiCabinetsChartGet();

            setState(prev => ({
                ...prev,
                dataCabinetDoughnut: {
                    ...prev.dataCabinetDoughnut,
                    datasets: [{
                        label: [t('Tủ hoạt động'), t('Tủ trống')],
                        data: [data?.totalActiveCells || 0, data?.totalEmptyCells || 0],
                        backgroundColor: ['#FEA54C', '#F9E8C9'],
                        borderWidth: 0
                    }]
                }
            }))
        } catch (error) {

        }

        setState(prev => ({
            ...prev,
            isDoughnutLoading: false
        }))
    }

    const fetchApiCabinetsRevenuesDateChartsGet = async () => {
        setState(prev => ({
            ...prev,
            isCabinetLineLoading: true
        }))

        try {
            const { data } = await apiCabinetsRevenuesDateChartsGet({ typeDate: "week" });
            let dataCabinetLine = {};

            if (data?.values) {
                const labels = data.labels.map(labelsItem => {
                    labelsItem = moment.tz(labelsItem, "Asia/Ho_Chi_Minh").format("DD/MM/YYYY");
                    return labelsItem;
                });

                dataCabinetLine = {
                    totalRevenue: data?.totalRevenue,
                    labels: labels,
                    datasets: [{
                        label: t("Doanh thu tủ"),
                        data: [...data?.values],
                        fill: false,
                        borderColor: '#0FA7E7',
                        backgroundColor: '#0FA7E7',
                        color: '#FFFFFF'
                    }]
                }
            }

            setState(prev => ({
                ...prev,
                dataCabinetLine
            }))
        } catch (error) {

        }

        setState(prev => ({
            ...prev,
            isCabinetLineLoading: false
        }))
    }

    useEffect(() => {
        getUsers();
        getAccesses30day();
        getDevices();
        fetchApiCabinetsChartGet();
        fetchApiCabinetsRevenuesDateChartsGet();
    }, [])

    const cabinetLineMemo = useMemo(() => (
        <Line
            height="70px"
            data={state.dataCabinetLine}
            options={{
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    }
                },
                scales: {
                    y: {
                        beginAtZero: true,
                        suggestedMin: 0,
                        suggestedMax: 10,
                        ticks: {
                            callback: function (value) {
                                return value < 1000 ? value : value / 1000 + 'k';
                            }
                        }
                    },
                },

            }}
        />
    ), [state.dataCabinetLine])

    return (
        <div className={classes.dashboardPage}>
            <div className={classes.partTop} >
                <div className={classes.partTopItem}>
                    <div className={classes.horizontalBarResident}></div>
                    <div className={classes.leftBlock}>
                        <Link to='/admin/manage-residences'><p className={classes.blockValue}>{new Intl.NumberFormat().format(residentCount)}</p></Link>
                        <Link to='/admin/manage-residences'><p className={classes.blockTitle}>{t('Cư dân')}</p></Link>
                    </div>
                    <div className={classes.rightBlock}>
                        <img src={window.location.origin + '/images/UserSvg.svg'} alt="" />
                    </div>
                </div>
                <div className={classes.partTopItem}>
                    <div className={classes.horizontalBarActiveResident}></div>
                    <div className={classes.leftBlock}>
                        <p className={classes.blockValue}>{new Intl.NumberFormat().format(activeResident)}</p>
                        <p className={classes.blockTitle}>{t('Đang trực tuyến')}</p>
                    </div>
                    <div className={classes.rightBlock}>
                        <div className={classes.residentIconStyle}>
                            <img src={window.location.origin + '/images/ActiveUser.svg'} width="79px" alt="" />
                        </div>
                    </div>
                </div>
                <div className={classes.partTopItem}>
                    <div className={classes.horizontalBarHistory}></div>
                    <div className={classes.leftBlock}>
                        <Link to='/admin/manage-access'><p className={classes.blockValue}>{new Intl.NumberFormat().format(accessCount)}</p> </Link>
                        <Link to='/admin/manage-access'>
                            <div style={{ display: 'flex' }}>
                                <p className={classes.blockTitle}>{t('Ra vào')}</p>
                                <p className={classes.accessText}>{t('30 ngày trước')}</p>
                            </div>
                        </Link>
                    </div>
                    <div className={classes.rightBlock}>
                        <img src={window.location.origin + '/images/ACCESS.svg'} alt="" />
                    </div>
                </div>
                <div className={classes.partTopItem}>
                    <div className={classes.horizontalBarDevice}></div>
                    <div className={classes.leftBlock}>
                        <Link to='/admin/device-management/devices'>
                            <p className={classes.blockValue}>
                                {deviceInfo}
                                {offlineDeviceCount !== 0 && <LargeTooltip title={offlineDeviceCount + ' ' + t('thiết bị đã ngoại tuyến hơn 1 giờ')} >
                                    <WarningAmberRoundedIcon className={classes.WarningIconStyle} />
                                </LargeTooltip>}
                            </p>
                        </Link>
                        <Link to='/admin/device-management/devices'><p className={classes.blockTitle}>{t('Thiết bị trực tuyến')}</p></Link>
                    </div>
                    <div className={classes.rightBlock}>
                        <img src={window.location.origin + '/images/Device.svg'} alt="" />
                    </div>
                </div>
            </div>
            <div className={classes.partChart} >
                <div className={classes.lineChartBlock}>
                    <AccessLineChart lineData={lineData} />
                </div>
                <div className={classes.circleChartBlock}>
                    <AccessTypeChart circleData={circleData} />
                </div>
                <div className={classes.circleChartBlock}>
                    <AccessBarChart barData={barData} />
                </div>
            </div>
            <div className={classes.partTable}>
                <div className={classes.leftTableBlock}>
                    <AccessTable />
                </div>
                <div className={classes.rightTableBlock}>
                    <WarningTable />
                </div>
            </div>

            <div className={classes.cabinetChart}>
                <div className={classes.cabinetLine}>
                    {state.isCabinetLineLoading && (
                        <div className={classes.wrapLoading}>
                            <LinearProgress />
                        </div>
                    )}
                    <div className={classes.wrapTitleLine}>
                        <p className={classes.lineTitle}>
                            <span>{t("Doanh thu tủ trong tuần")}</span>
                            <span className={classes.lineTitleWeek}>{t("7 ngày trước")}</span>
                        </p>
                        <div className={classes.wrapLineAction}>
                            <p className={classes.viewMode}>{t("Tổng")}: {formatToVND(state?.dataCabinetLine.totalRevenue)}</p>
                        </div>
                    </div>

                    <div className={classes.wrapChartCabinetLine}>
                        {cabinetLineMemo}
                    </div>
                </div>
                <div className={classes.cabinetDoughnut}>
                    {state.isDoughnutLoading && (
                        <div className={classes.wrapLoading}>
                            <LinearProgress />
                        </div>
                    )}
                    <p className={clsx(classes.lineTitle, classes.doughnutTitle)}>{t('Tỉ lệ tủ hoạt động')}</p>

                    <DoughnutCommon
                        data={state.dataCabinetDoughnut}
                        centerTitle={t("Tổng")}
                        centerValue={state.dataCabinetDoughnut?.datasets[0]?.data?.reduce((prev, current) => prev + current, 0)}
                    />
                </div>
            </div>
        </div>
    )
}

export default Dashboard;