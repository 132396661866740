import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button/Button';
import TextField from '@material-ui/core/TextField/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Cookies from 'js-cookie';
import Divider from '@material-ui/core/Divider/Divider';
import { apiPeopleCoutingWarning, apiPeopleCoutingWarningId } from '../../api/people';

const useStyles = makeStyles((theme) => ({
    contentBottomForm: {
        width: '98%',
        background: '#FFFFFF',
        borderRadius: '6px',
        position: 'relative',
        margin: 'auto'
    },
    content: {
        width: '100%',
        margin: 'auto',
        background: '#FFFFFF',
        borderRadius: '6px'
    },
    secondaryTitle: theme.secondary_title,
    primaryColor: theme.primaryTextColor,
    label: {
        fontSize: '14px',
        lineHeight: '140%',
        color: '#000000',
        display: 'inline-block',
        margin: '0',
        fontWeight: 'bold'
    },
    buttonStyle: {
        margin: '10px 0 10px 16px'
    }
}))

const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);
const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);

const WarningDetail = (props) => {
    const { onBackClick, onUpdateClick, id } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const [warning, setWarning] = useState({
        resolution: '',
        resolvedBy: ''
    });
    const [alert, setAlert] = useState({
        resolution: '',
        resolvedBy: '',
        request: ''
    });
    const [isUpdating, setIsUpdating] = useState(false);
    const [loading, setLoading] = useState(false);

    const getDefaultValues = async () => {
        try {
            if (!id) return;
            var res = await apiPeopleCoutingWarningId(id);
            const data = res.data.Items[0];
            setWarning({
                id: data.ID,
                description: data.Description,
                resolution: data.Resolution ? data.Resolution : '',
            })
            setAlert({ ...alert, request: '' });
        } catch (error) {
            setAlert({ ...alert, request: t('Không lấy được dữ liệu') });
        }
        setLoading(true);
    }

    useEffect(() => {
        if (!loading) getDefaultValues();
    }, [loading, id])

    const onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setWarning({ ...warning, [name]: value });
        if (value.trim() === '') setAlert({ ...alert, [name]: t('Không được để trống') });
        else setAlert({ ...alert, [name]: '' });
    }

    const onSaveClick = async () => {
        if (warning.resolution === '') return setAlert({ ...alert, resolution: t('Không được để trống') });
        if (alert.resolution !== '') return;
        setIsUpdating(true);
        await onUpdateClick({ id: warning.id, resolution: warning.resolution });
        setIsUpdating(false)
    }

    return (
        <Dialog
            open={true}
            maxWidth="xl"
        >
            {!loading &&
                <div style={{ width: '448px', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress color="primary" style={{ color: '#0b59c8' }} />
                </div>}
            {loading && <DialogContent style={{ width: '448px', padding: '0', position: 'relative' }}>
                {alert.request !== '' && <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '16px' }}>
                    <h3>{alert.request}</h3>
                </div>}
                {alert.request === '' && <div>
                    <p style={{ fontSize: '20px', padding: '15px', fontWeight: 'bold', textAlign: 'center' }}>{t('Cảnh báo/Lỗi')}</p>
                    <Divider />
                    <div style={{ width: '80%', margin: 'auto', height: '60px', marginTop: '10px' }}>
                        <label className={classes.label}>{t('Mô tả')} </label><br />
                        <p style={{ fontSize: '18px' }}>{warning.description}</p>
                    </div>
                    <div style={{ width: '80%', margin: 'auto', height: '120px' }}>
                        <label className={classes.label}>{t('Resolution')} </label><br />
                        <TextField
                            multiline
                            rows={3}
                            placeholder={t('Resolution')}
                            inputProps={{ style: { fontSize: '18px' } }}
                            variant="outlined"
                            style={{ width: '100%' }}
                            size="small"
                            name="resolution"
                            value={warning.resolution}
                            onChange={onChange}
                            disabled={isUpdating}
                            error={alert.resolution !== ''}
                        />
                        <p style={{ color: '#f44336', paddingTop: '3px', fontSize: '0.75rem' }}>{alert.resolution}</p>
                    </div>
                </div>}
                <Divider />
                <div style={{ width: '80%', textAlign: 'right', margin: 'auto' }}>
                    <CancelButton
                        variant="outlined"
                        onClick={onBackClick}
                        disabled={isUpdating}
                        className={classes.buttonStyle}
                    >
                        {t('Hủy')}
                    </CancelButton>
                    {alert.request === '' && <CreateButton
                        variant="contained"
                        onClick={onSaveClick}
                        disabled={isUpdating}
                        className={classes.buttonStyle}
                    >
                        {t('Lưu')}
                        {isUpdating && <CircularProgress color="primary" size={25} style={{ position: 'absolute', color: '#0b59c8' }} />}
                    </CreateButton>}
                </div>
            </DialogContent>}
        </Dialog>
    )
}

export default WarningDetail;