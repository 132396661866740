import makeStyles from '@material-ui/styles/makeStyles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    primaryTitle: theme.primary_title,
    layoutPage: {
        width: 'calc(100% - 1rem)',
        minWidth: '1250px',
        marginLeft: '1rem',
        minHeight: '100%',
        height: "100%"
    },
    containLayout: {
        width: '100%',
        minHeight: '100%',
        display: 'flex'
    },
    menu: {
        borderRadius: '6px',
        // marginRight: '1rem',
        width: '100%',
        height: '100%',
        background: '#FFFFFF'
    },
    closeMenu: {
        marginRight: "unset",
        width: '0',
        overflow: "hidden",
    },
    topBlock: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: '22px',
        height: '40px'
    },
    secondaryTitle: {
        ...theme.secondary_title,
        marginTop: '5px',
        marginLeft: "10px !important",
        marginBottom: "7px !important",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "270px",
    },
    btnExtendMenu: {
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "5px",
        width: "30px",
        height: "30px",
        cursor: "pointer",
        color: "#0b59c8"
    },
    content: {
        width: '83%',
        borderRadius: '6px',
        background: '#FFFFFF',
        minHeight: '100%'
    },
    contentfullWidth: {
        width: "100%",
        marginRight: '1rem',
    },
    link: {
        display: 'block',
        height: '40px',
        fontSize: '14px',
        padding: '10px',
        color: '#000000',
        position: 'relative',
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
    active: {
        height: '40px',
        width: '100%',
        background: '#50c9ff4a',
        fontWeight: 'unset !important'
    },
    activeGreyOut: {
        background: 'rgba(0, 0, 0, 0.12)'
    },

}));