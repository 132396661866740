import React, { useState, useEffect, useRef, useCallback } from 'react';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button/Button';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton/IconButton';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { GridOverlay, DataGrid } from '@material-ui/data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField/TextField';
import { isEmpty, checkDate } from '../../../validation/CheckForm';
import * as XLSX from 'xlsx';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import Cookies from 'js-cookie';
import { apiAddmultiplepayments } from '../../../api/payments';
import clsx from 'clsx';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { Confirm, FailedDialog, SuccessDialog } from '../../dialog';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Dropzone, { useDropzone } from 'react-dropzone'
import { ExcelIcon, DragIcon } from '../../icons';
import LargeTooltip from '../../largeTooltip';

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '20px',
        fontWeight: '700',
        lineHeight: '27px',
        color: '#000000',
        fontStyle: 'normal',
        float: 'left',
        marginRight: '24px',
        paddingTop: '5px'
    },
    label: {
        paddingBottom: '6px',
        fontSize: '14px',
        lineHeight: '140%',
        color: '#7A7171',
        display: 'inline-block'
    },
    alert: {
        position: 'absolute',
        left: '3px',
        top: '3px',
        width: '99%'
    },
    dialogTitleBackground: theme.primaryColor,
    dialogFailedTitleBackground: theme.errorColor,
    dialogTitle: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '25px',
        color: '#FFFFFF',
    },
    contentDialog: {
        fontSize: '16px',
        height: '68px',
        fontWeight: '600',
        borderBottom: '1px solid #EEEDF2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    contentBottomForm: {
        width: '98%',
        margin: 'auto',
        height: '100%',
        minWidth: '1280px'
    },
    root: theme.dataGridStyle,
    secondaryTitle: theme.secondary_title,
    primaryColor: theme.primaryTextColor,
    header: {
        width: '100%',
        height: '56px',
        background: '#FFFFFF',
        border: '1px solid #E0E0E0',
        position: 'relative',
        borderRadius: '6px 6px 0 0'
    },
    headerTitle: {
        marginLeft: '0',
        marginTop: '7px'
    },
    labelStyle: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '22px',
        textAlign: 'center',
        color: '#000000',
    },
    svgTitleStyle: {
        paddingTop: '83px',
        paddingBottom: '36px'
    },
    addLabelStyle: {
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '16px',
        color: '#4F4F4F'
    },
    downloadFileStyle: {
        paddingTop: '16px',
        paddingBottom: '6px',
    },
    addIconStyle: {
        color: '#4F4F4F',
        position: 'relative',
        top: '-3px'
    },
    tableBlockStyle: {
        width: '98%',
        margin: 'auto',
        height: '73%',
        maxHeight: '650px'
    },
    tableStyle: {
        width: '100%',
        height: '100%',
        background: '#ffffff'
    },
    delButtonStyle: {
        margin: '8px',
        background: '#ffffff'
    },
    contentStyle: {
        width: '100%',
        borderRadius: '6px',
        position: 'relative',
        height: '90%'
    },
    headerBlockStyle: {
        paddingBottom: '20px',
        paddingTop: '15px',
        width: '98%',
        margin: 'auto',
        display: 'flex'
    },
    backIconButtonStyle: {
        width: '24px',
        height: '24px',
        marginTop: '10px',
        marginRight: '10px'
    },
    importBlockStyle: {
        width: '98%',
        height: '358px',
        marginTop: '22px',
        margin: 'auto',
        background: '#FFFFFF',
        textAlign: 'center'
    },
    importBlock: {
        width: '98%',
        height: '100px',
        margin: 'auto',
        background: '#FFFFFF',
        padding: '0 24px'
    },
    pointer: {
        cursor: 'pointer'
    }
}))
const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);
const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);
const SecondaryTitle = withStyles(theme => ({ root: theme.secondary_title }))(Button);
const ErrorBtn = withStyles(theme => ({ root: theme.error_button }))(Button);
const DeleteButton = withStyles(theme => ({ root: theme.delete_button }))(Button);

const headerKeys = [
    { name: 'Block', index: 0 },
    { name: 'Tầng', index: 1 },
    { name: 'Phòng', index: 2 },
    { name: 'Tên hóa đơn', index: 3 },
    { name: 'Ngày đến hạn', index: 4 },
]
const headerValues = [
    'BlockNumber', 'Floor', 'Room', 'RequestInfo', 'ExpiredDate'
]

const AddPaymentInfo = props => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [paymentData, setPaymentData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [ids, setIds] = useState([]);
    const [fileName, setFileName] = useState('');
    const [filter, setFilter] = useState('');
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [isSuccessDialog, setIsSuccessDialog] = useState(false);
    const [isFailedDialog, setIsFailedDialog] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [alert, setAlert] = useState({ file: '', error: '' });
    const [isWarning, setIsWarning] = useState(false);
    const [isExpiredSession, setIsExpiredSession] = useState(false);
    const [isFireFox, setIsFireFox] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const filterRef = useRef();

    const columns = [
        {
            headerName: t('title.apartment'),
            field: 'Apartment',
            cellClassName: 'super-app-theme--cell',
            flex: 1
        },
        {
            headerName: t('Tên hóa đơn'),
            field: 'RequestInfo',
            cellClassName: 'super-app-theme--cell',
            flex: 1
        },
        {
            headerName: t('Ngày tạo'),
            field: 'RequestDate',
            cellClassName: 'super-app-theme--cell',
            flex: 1,
            renderCell: params => moment(params.row.RequestDate).format('DD-MM-YYYY')
        },
        {
            headerName: t('Ngày đến hạn'),
            field: 'ExpiredDate',
            cellClassName: 'super-app-theme--cell',
            flex: 1,
            renderCell: params => showExpiredDate(params.row.ExpiredDate, params.row.expiredDateNote)
        },
        {
            headerName: t('Tổng tiền'),
            field: 'Amount',
            cellClassName: 'super-app-theme--cell',
            flex: 1,
            renderCell: params => showAmount(params.row.Amount, params.row.amountNote)
        },
    ]

    useEffect(() => {
        if (isExpiredSession) {
            history.push('/login');
            dispatch({ type: 'IS_LOGIN_AGAIN_TRUE' });
        }
    }, [isExpiredSession]);

    useEffect(() => {
        const isFirefoxBrowser = typeof InstallTrigger !== 'undefined';
        setIsFireFox(isFirefoxBrowser);
    }, [])

    const showAmount = (amount, isAmount) => {
        if (isAmount) {
            return amount;
        } else {
            return (
                <LargeTooltip title={t('Phải là số, tối thiểu 1000')}>
                    <div style={{ color: 'red' }}>{amount}</div>
                </LargeTooltip>
            )
        }
    }

    const showExpiredDate = (expiredDate, isExpiredDate) => {
        if (!expiredDate) {
            return '-';
        }
        if (isExpiredDate) {
            return moment(expiredDate).format('DD-MM-YYYY')
        } else {
            return (
                <LargeTooltip title={t('Phải sau ngày tạo')}>
                    <div style={{ color: 'red' }}>{moment(expiredDate).format('DD-MM-YYYY')}</div>
                </LargeTooltip>
            )
        }
    }

    useEffect(() => {
        if (paymentData.length > 0) {
            setLoading(true);
            setIsProcessing(false);
            setIds([]);
        }
    }, [paymentData])

    const fileExelHandler = (e, isDrag) => {
        var file = [];
        if (isDrag) {
            file = e[0];
        } else {
            file = e.target.files[0];
        }

        if (typeof (file) === 'undefined') return;
        if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && file.type !== 'application/vnd.ms-excel') {
            setPaymentData([]);
            setFilteredData([]);
            setAlert({ ...alert, file: 'Vui lòng upload đúng file excel' });
            return;
        }
        setFileName(file.name);
        setIsProcessing(true);
        setAlert({ ...alert, file: '' });
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                const bstr = reader.result;
                const wb = XLSX.read(bstr, { type: 'binary' });

                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];

                const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
                const payment_info = data.split(/\r\n|\n/);

                processDataFromFile(payment_info);
            }
        }
        reader.readAsBinaryString(file);
    }

    const handled_key = (str) => {
        if (str === null || str === undefined) return str;
        str = str.toLowerCase();
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/ /g, "_");
        return str;
    }

    const processDataFromFile = async (dataString) => {
        var list = [];
        var headers = [];
        var detail = [];
        dataString.forEach((value, index) => {
            if (index === 0) {
                const result = value.split(',');
                result.forEach((item) => {
                    if (item) {
                        const header = headerKeys.filter(res => res.name.trim() === item.trim());
                        if (header.length !== 0) headers.push(headerValues[header[0].index]);
                        else {
                            headers.push(handled_key(item));
                            detail.push({ key: handled_key(item), label: item });
                        }

                    }
                })
            } else {
                const arrays = value.split(',');
                var obj = {};
                for (var i = 0; i < headers.length; i++) {
                    obj = {
                        ...obj,
                        [headers[i]]: arrays[i]
                    }
                }
                var time = new Date();
                if (!isEmpty(obj.BlockNumber) && !isEmpty(obj.Floor) && !isEmpty(obj.Room) && !isEmpty(obj.RequestInfo)) {
                    list.push({
                        ...obj,
                        id: index,
                        RequestDate: time.setHours(7, 0, 0),
                        Apartment: obj.BlockNumber + obj.Floor + obj.Room,
                        ExpiredDate: new Date(obj.ExpiredDate) ? obj.ExpiredDate : null
                    });
                }
            }
        });
        if (list.length === 0) {
            setPaymentData([]);
            setFilteredData([]);
            setAlert({ ...alert, file: 'File không có dữ liệu hoặc dữ liệu chưa chính xác' });
            setIsProcessing(false);
            return;
        }
        setAlert({ ...alert, file: '' });
        const handledData = addPaymentDetail(list, detail);
        const checkedData = await checkPaymentInfo(handledData);
        setPaymentData(checkedData);
        setFilteredData(checkedData);
    }

    const addPaymentDetail = (data, detailInfo) => {
        const handledData = [];
        var totalAmout = 0;
        data.forEach((item, key) => {
            var detail = [];
            var keys = Object.keys(item);
            if (detailInfo.length > 0) {
                keys.forEach(key => {
                    const res = detailInfo.filter(info => info.key === key);
                    if (res.length > 0 && item[key].trim() !== '') {
                        detail.push({ name: res[0].label, amount: Number(item[key]) });
                        totalAmout += Number(item[key]);
                    }
                })
            }
            var date = new Date(item.ExpiredDate);
            handledData.push({
                id: key,
                Amount: totalAmout,
                Apartment: item.Apartment,
                BlockNumber: item.BlockNumber,
                Floor: item.Floor,
                Room: item.Room,
                RequestDate: item.RequestDate,
                RequestInfo: item.RequestInfo,
                Detail: detail,
                PaymentStatus: 'unpaid',
                ID: v4(),
                ExpiredDate: date.setHours(7, 0, 0) + key
            });
        })
        return handledData;
    }

    const checkPaymentInfo = (data) => {
        const checkedData = [];
        data.forEach(item => {
            var expiredDateNote = true;
            var amountNote = true;
            if (item.ExpiredDate < item.RequestDate) {
                expiredDateNote = false;
            }
            if (isNaN(item.Amount) || item.Amount < 1000) {
                amountNote = false;
            }
            checkedData.push({ ...item, expiredDateNote: expiredDateNote, amountNote: amountNote });
        })
        return checkedData;
    }

    const handleResidenceData = async (data) => {
        try {
            if (data.length === 0) return;
            const limit = 20;
            var num;
            if (data.length % limit === 0) num = data.length / limit;
            else num = data.length / limit + 1;
            for (var i = 1; i <= num; i++) {
                const end = i * limit;
                const start = end - limit;
                var payments = data.slice(start, end);
                await addMultiplePayments(payments);
            }
            setIsSuccessDialog(true);
            setPaymentData([]);
            setFilteredData([]);
        } catch (error) {
            setIsFailedDialog(true);
            setAlert({ ...alert, error: t('Thêm thông tin thanh toán không thành công, vui lòng thử lại sau!') });
        }
        setConfirmDialog(false);
        setIsAdding(false);
    }

    const addMultiplePayments = async (payments) => {
        try {
            await apiAddmultiplepayments({ multiple_payments: payments });
        } catch (error) {
            if (error.response ?.data === "Invalid LoginToken") return setIsExpiredSession(true);
        }
    }

    const onAddResidencesClick = () => {
        if (alert.file !== '') return;
        setConfirmDialog(true);
    }

    const onConfirmAddPayments = () => {
        const results = paymentData.filter(item => !item.expiredDateNote || !item.amountNote);
        if (results.length > 0) {
            setAlert({ ...alert, error: `${results.length} ${t("thông tin thanh toán không hợp lệ, vui lòng xóa thông tin không hợp lệ và thử lại!")}` })
            setIsFailedDialog(true);
            return;
        }
        setIsAdding(true);
        handleResidenceData(paymentData);
    }

    const addDefaultValue = (data) => {
        const results = [];
        data.forEach((item) => {
            var date = new Date(item.ExpiredDate);
            results.push({ ...item, PaymentStatus: 'unpaid', ID: v4(), ExpiredDate: date.setHours(7, 0, 0) })
        })
        return results;
    }

    const onSelectedChange = (e) => {
        setIds(e);
    }

    const onDeleteClick = () => {
        var data = paymentData;
        ids.forEach(value => {
            data = data.filter(item => item.id !== value);
        })
        setPaymentData(data);
        setFilteredData(data);
        setLoading(false);
    }

    const onSearchChange = (e) => {
        const value = e.target.value;
        if (filterRef.current) clearTimeout(filterRef.current);
        filterRef.current = setTimeout(() => {
            setFilter(value);
        }, 500);
    }

    useEffect(() => {
        setFilteredData(paymentData.filter(item =>
            item.RequestInfo.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
            item.Apartment.toLowerCase().indexOf(filter.toLowerCase()) !== -1
        ))
    }, [filter])

    const onAgreeClick = () => {
        history.push('/admin/payments/info');
    }

    const onFailedAgreeClick = () => {
        setIsFailedDialog(false);
        setConfirmDialog(false);
    }

    const onAlterFileClick = () => {
        setIsWarning(!isWarning);
    }

    const onConfirmAlterClick = () => {
        setIsWarning(false);
        var element = document.getElementById('contained-button-file');
        element.click();
    }

    const succesDialog = () => {
        return (
            <Dialog
                open={isSuccessDialog}
                maxWidth="xl"
            >
                <DialogTitle className={classes.dialogTitleBackground}>
                    <p className={classes.dialogTitle}>{t('Thông báo')}</p>
                </DialogTitle>
                <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center' }}>
                    <p className={classes.contentDialog}>{t('Thêm thông tin thanh toán thành công!')}</p>
                    <CancelButton style={{ margin: '10px 0' }} onClick={onAgreeClick}>{t('Đồng ý')}</CancelButton>
                </DialogContent>

            </Dialog>
        )
    }

    const importFileComponent = () => {
        if (paymentData.length > 0) {
            return (
                <div className={classes.importBlock}>
                    <input
                        accept=".csv,.xlsx,.xls"
                        hidden
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={(e) => fileExelHandler(e, false)}
                        onClick={(e) => e.target.value = ''}
                    />
                    <p className={classes.downloadFileStyle}>
                        <AddIcon className={classes.addIconStyle} />
                        <a
                            href={'https://aws-sam-cli-managed-default-samclisourcebucket-public.s3-ap-southeast-1.amazonaws.com/dev/Files/template/thong_tin_thanh_toan.xlsx'}
                            target="_blank" rel="noreferrer"
                            download
                            className={classes.addLabelStyle}
                        >
                            {t('Tải tệp thay thế')}
                        </a>
                    </p>
                    <TextField
                        variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                    className={classes.pointer}
                                    onClick={onAlterFileClick}
                                >
                                    <CloseIcon />
                                </InputAdornment>
                            ),
                            startAdornment: (
                                <InputAdornment position="start">
                                    <ExcelIcon />
                                </InputAdornment>
                            ),
                            style: {
                                background: '#FFFFFF',
                                height: '40px',
                                width: '320px'
                            },
                            readOnly: true,
                        }}
                        value={fileName}
                    />
                    <p style={{ color: "red" }}>{alert.file}</p>
                </div>
            )
        } else {
            return (
                <div className={classes.importBlockStyle} >
                    <Dropzone onDrop={acceptedFiles => fileExelHandler(acceptedFiles, true)}>
                        {({ getRootProps, getInputProps }) => (
                            <div className={classes.importBlockStyle} {...getRootProps() }>
                                <div className={classes.svgTitleStyle}>
                                    <DragIcon color="#666666" />
                                </div>
                                <p className={classes.labelStyle}>{t('Kéo và thả dữ liệu')}</p>
                                <p className={classes.labelStyle}>{t('hoặc')}</p>
                                {
                                    isFireFox && 
                                    <input
                                        accept=".csv,.xlsx,.xls"
                                        hidden
                                        id="contained-button-file"
                                        multiple
                                        type="file"
                                        onChange={(e) => fileExelHandler(e, false)}
                                        onClick={(e) => e.target.value = ''}
                                    />
                                }
                                {
                                    isFireFox && 
                                    <label htmlFor="contained-button-file">
                                        <CreateButton
                                            variant="contained"
                                            color="secondary"
                                            component="span"
                                            style={{ marginLeft: '0', marginTop: '12px' }}

                                        >
                                            {t("Tải lên")}
                                        </CreateButton>
                                    </label>
                                }
                                {
                                    !isFireFox &&
                                    <CreateButton
                                        variant="contained"
                                        color="secondary"
                                        component="span"
                                        style={{ marginLeft: '0', marginTop: '12px' }}

                                    >
                                        {t("Tải lên")}
                                    </CreateButton>
                                }
                                <p style={{ color: "red" }}>{alert.file}</p>
                            </div>
                        )}
                    </Dropzone>
                </div>
            )
        }
    }
    const EmptyRowsView = () => {
        return (
            <div
                style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <h3 style={{ color: '#6D6D6D', fontSize: '16px' }}>
                    {t('Không tìm thấy thông tin thanh toán')}
                </h3>
            </div>
        );
    };

    const customLoading = () => {
        return (
            <GridOverlay>
                <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                    <LinearProgress />
                </div>
            </GridOverlay>
        )
    }

    return (
        <div className={classes.contentBottomForm}>
            {/* {succesDialog()} */}
            <SuccessDialog
                isOpen={isSuccessDialog}
                mess={t('Thêm thông tin thanh toán thành công!')}
                onConfirm={onAgreeClick}
            />
            <FailedDialog
                isOpen={isFailedDialog}
                mess={alert.error}
                onConfirm={onFailedAgreeClick}
            />
            <Confirm
                isOpen={isWarning}
                handleClose={onAlterFileClick}
                isLoading={false}
                mess={t('Tất cả những dữ liệu khi thực hiện trên tệp này sẽ bị xóa')}
                onConfirm={onConfirmAlterClick}
            />
            <Confirm
                isOpen={confirmDialog}
                handleClose={() => { setConfirmDialog(false) }}
                isLoading={isAdding}
                mess={t('Bạn có chắc chắn muốn thêm tất cả thông tin thanh toán?')}
                onConfirm={onConfirmAddPayments}
            />
            <div className={classes.headerBlockStyle}>
                <IconButton
                    size="small"
                    component={Link}
                    to="/admin/payments/info"
                    className={classes.backIconButtonStyle}
                >
                    <ArrowBackIosOutlinedIcon className={classes.primaryColor} style={{ width: '24px', height: '24px' }} />
                </IconButton>
                <p className={clsx(classes.secondaryTitle, classes.headerTitle)}>{t("Thêm thông tin thanh toán")}</p>
                <div style={{ flex: 1 }}></div>
                {paymentData.length === 0 &&
                    <CancelButton
                        variant="outlined"
                        startIcon={<ExcelIcon />}
                    >
                        <a
                            href={'https://aws-sam-cli-managed-default-samclisourcebucket-public.s3-ap-southeast-1.amazonaws.com/dev/Files/template/thong_tin_thanh_toan.xlsx'}
                            target="_blank" rel="noreferrer"
                            download
                            className={classes.primaryColor}
                        >
                            {t('Tải tệp mẫu')}
                        </a>
                    </CancelButton>}
                {paymentData.length > 0 &&
                    <CreateButton
                        variant="contained"
                        onClick={onAddResidencesClick}
                    >
                        {t('Thêm dữ liệu')}
                    </CreateButton>}
            </div>
            <div className={classes.contentStyle}>
                {importFileComponent()}
                {isProcessing && <CircularProgress style={{ marginLeft: 'calc(50% - 25px)', color: '#0b59c8' }} />}
                {loading && paymentData.length > 0 &&
                    <div className={classes.tableBlockStyle}>
                        <div >
                            <TextField
                                style={{ position: 'relative', top: '8px' }}
                                placeholder={t("Tìm kiếm")}
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                    style: {
                                        background: '#FFFFFF',
                                        height: '40px',
                                        width: '320px'
                                    }
                                }}
                                onChange={onSearchChange}
                            />
                            <DeleteButton
                                variant="outlined"
                                startIcon={<DeleteOutlineIcon />}
                                onClick={onDeleteClick}
                                className={classes.delButtonStyle}
                                disabled={ids.length === 0}
                            >
                                {t('Xóa')}
                            </DeleteButton>
                        </div>
                        <div className={clsx(classes.root, classes.tableStyle)}>
                            <DataGrid
                                componentsProps={{
                                    pagination: {
                                        labelRowsPerPage: t("Số dòng mỗi trang"),
                                        labelDisplayedRows: ({ from, to, count }) => { return `${from}-${to} ${t('trong số')} ${count}` }
                                    }
                                }}
                                components={{
                                    NoRowsOverlay: EmptyRowsView,
                                    LoadingOverlay: customLoading,
                                }}
                                rows={filteredData}
                                columns={columns}
                                pageSize={rowsPerPage}
                                rowsPerPageOptions={[10, 50, 100]}
                                onPageSizeChange={e => setRowsPerPage(e.pageSize)}
                                checkboxSelection
                                onSelectionModelChange={onSelectedChange}
                                hideFooterRowCount
                                hideFooterSelectedRowCount
                                loading={!loading}
                                disableColumnMenu={true}
                            />
                        </div>
                    </div>}
                <div style={{ height: '25px' }}></div>
            </div>
        </div>
    )
}

export default AddPaymentInfo;
