import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FitnessCenterOutlinedIcon from '@mui/icons-material/FitnessCenterOutlined';
import InfoIcon from '@mui/icons-material/Info';
import LocalBarOutlinedIcon from '@mui/icons-material/LocalBarOutlined';
import OutdoorGrillOutlinedIcon from '@mui/icons-material/OutdoorGrillOutlined';
import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined';
import { CircularProgress } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, Route, useRouteMatch } from 'react-router-dom';
import { getBookingService } from '../../api/booking';
import LargeTooltip from '../../components/largeTooltip';
import Booking from './booking';
import Appointment from './history/index';

const useStyles = makeStyles((theme) => ({
    topBlock: { marginTop: '22px', marginBottom: '20px', height: '40px' },
    primaryTitle: theme.primary_title,
    secondaryTitle: theme.secondary_title,
    menu: {
        borderRadius: '6px',
        marginRight: '1%',
        width: '20%',
        height: '100%',
        background: '#FFFFFF',
        paddingBottom: '2rem',
        overflowY: "auto",
        overflowX: "hidden",
        "&::-webkit-scrollbar": {
            width: "4px",
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#EBEBEB",
            borderRadius: "8px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: "#b3000"
        },
        scrollbarWidth: "thin"
    },
    wrapBtnExtendMenu: {
        position: "absolute",
        top: "22px",
        left: "calc(20% - 30px)",
        zIndex: '1',
        width: "30px",
        background: "white"
    },
    btnExtendMenu: {
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid rgb(0, 0, 0, 0.3)",
        borderRadius: "5px",
        width: "30px",
        height: "30px",

        cursor: "pointer",
        background: "white"
    },
    content: { width: '79%', borderRadius: '6px', background: '#FFFFFF', height: '100%' },
    link: { display: 'block', height: '40px', fontSize: '14px', padding: '10px', color: '#000000', position: 'relative' },
    activeBackground: { position: 'absolute', width: '100%', height: '100%', top: '0', left: '0', background: '#50c9ff4a' },
    triangle: { height: '30px', width: '30px', position: 'absolute', top: '5px', right: '-5px', background: '#FFFFFF', transform: 'rotate(45deg)', zIndex: '0' },
    menuLink: {
        cursor: "pointer"
    },
    active: { height: '40px', width: '100%', background: '#50c9ff4a', fontWeight: 'unset !important', borderBottom: "unset" },
    activeGreyOut: { background: 'rgba(0, 0, 0, 0.12)' },
    subLink: {
        paddingLeft: "1rem"
    },
    wrapSubLink: {
        display: "flex",
        justifyContent: "space-between",
    },
    serviceName: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
    infoIcon: {
        marginLeft: '3px'
    },
    emoji: {
        marginRight: "3px"
    },
    wrapCircularProgress: {
        display: "flex",
        justifyContent: "center"
    }
}))

const ManageBookingMenu = () => {
    const [isShow, setIsShow] = useState(true);
    const [isMenu, setIsMenu] = useState(true);
    const [state, setState] = useState({
        listService: [],
        isServiceLoading: false,
        responseInfor: {
            succ: "",
            err: ""
        }
    })
    const isChangedService = useSelector(state => state?.serviceBooking?.isChangedService);

    const classes = useStyles();
    const { t } = useTranslation();
    const { url } = useRouteMatch();

    const workingTimeRef = useRef([]);

    const handleChangeResponeInfor = (succ = "", err = "") => {
        setState(prev => ({
            ...prev,
            responseInfor: {
                succ,
                err
            }
        }))
    }

    const getBookingServiceApi = async () => {
        setState(prev => ({
            ...prev,
            isServiceLoading: true
        }));

        try {
            const { data } = await getBookingService();
            const { Items: listService } = data;

            setState(prev => ({
                ...prev,
                listService
            }));
        } catch (error) {
            handleChangeResponeInfor(t("Tải danh sách dịch vụ thất bại!"));
        }

        setState(prev => ({
            ...prev,
            isServiceLoading: false
        }));
    }

    const handleChangeIsShow = () => {
        setIsShow(!isShow);
    }
    const handleChangeMenu = () => {
        setIsMenu(!isMenu);
    }

    useEffect(() => {
        getBookingServiceApi();
    }, [isChangedService]);

    const iconOptionLabel = {
        RestaurantOutlinedIcon: <RestaurantOutlinedIcon
            style={{
                color: "#04068c",
                fontSize: "14px",
                marginRight: "4px"
            }}
        />
        ,
        FitnessCenterOutlinedIcon: <FitnessCenterOutlinedIcon
            style={{
                color: "#04068c",
                fontSize: "14px",
                marginRight: "4px"
            }}
        />
        ,
        LocalBarOutlinedIcon: <LocalBarOutlinedIcon
            style={{
                color: "#04068c",
                fontSize: "14px",
                marginRight: "4px"
            }}
        />
        ,
        OutdoorGrillOutlinedIcon: <OutdoorGrillOutlinedIcon
            style={{
                color: "#04068c",
                fontSize: "14px",
                marginRight: "4px"
            }}
        />
    };

    return (
        <div style={{ fontFamily: 'Nunito !important', width: 'calc(100% - 1rem)', minWidth: '1250px', marginLeft: '1rem', height: '100%' }} >
            <div style={{ width: '100%', height: '100%', display: 'flex' }} >
                <div
                    style={{
                        height: !isMenu && "97%",
                        left: !isMenu && "0"
                    }}
                    className={classes.wrapBtnExtendMenu}
                >
                    <div
                        className={classes.btnExtendMenu}
                        onClick={handleChangeMenu}
                        style={{
                            right: !isMenu && "-40px"
                        }}
                    >
                        <ArrowForwardIosIcon
                            style={{
                                transform: isMenu && 'rotate(180deg)'
                            }}
                        />
                    </div>
                </div>

                <div
                    className={classes.menu}
                    style={{
                        width: isMenu ? "20%" : "30px",
                    }}
                >
                    <div className={classes.topBlock} style={{ marginBottom: '0' }}>
                        <p className={classes.secondaryTitle} style={{ marginLeft: '10px', fontSize: '20px' }}>
                            {t("Đặt dịch vụ")}
                        </p>
                    </div>

                    <div
                        className={clsx(classes.link, classes.menuLink)}
                        onClick={handleChangeIsShow}
                    >
                        {t("Danh sách dịch vụ")}
                        <ArrowDropDownIcon
                            style={{
                                transform: isShow && "rotate(180deg)"
                            }}
                        />
                    </div>
                    <div style={{
                        width: "100%",
                        height: (isShow && !state.isServiceLoading) ? state.listService.length * 40 : 0,
                        overflow: 'hidden',
                        transition: '150ms'
                    }} >
                        {
                            !state.isServiceLoading && state.listService.map(service => (
                                <NavLink
                                    key={service.ID}
                                    to={{
                                        pathname: `${url}/book/${service.ID}`,
                                    }}
                                    className={isActive => {
                                        if (isActive) {
                                            workingTimeRef.current = service?.WorkingTime;
                                        }

                                        return clsx(
                                            classes.link,
                                            classes.subLink,
                                            isActive && classes.active,
                                            isActive && service.ServiceStatus === "deactivated" && classes.activeGreyOut
                                        )
                                    }}
                                >
                                    <div className={classes.wrapSubLink} >
                                        <div className={classes.serviceName}>
                                            <span>
                                                {iconOptionLabel[service?.ServiceIcon]}
                                            </span>
                                            <span style={{
                                                marginLeft: !service?.ServiceIcon && "20px"
                                            }}>
                                                {`"${service.ServiceName}"`}
                                            </span>
                                        </div>
                                        <div>
                                            <span className={classes.infoIcon} >
                                                <LargeTooltip title={service?.ServiceDescription ? service?.ServiceDescription : t("Không có miêu tả")} >
                                                    <InfoIcon
                                                        style={{
                                                            color: "rgb(0, 154, 218)",
                                                            fontSize: 14
                                                        }}
                                                    />
                                                </LargeTooltip>
                                            </span>
                                        </div>
                                    </div>
                                </NavLink>
                            ))
                        }
                    </div>
                    {
                        state.isServiceLoading && (
                            <div className={classes.wrapCircularProgress} >
                                <CircularProgress
                                    size="20px"
                                    style={{
                                        color: "#0b59c8",
                                        margin: "auto"
                                    }}
                                />
                            </div>
                        )
                    }
                    <NavLink
                        to={`${url}/appointment`}
                        className={isActive => clsx(classes.link, isActive && classes.active)}
                    >
                        {t('Lịch sử đặt dịch vụ')}
                    </NavLink>
                </div>
                <div className={classes.content}
                    style={{
                        width: !isMenu && "calc(99% - 30px)"
                    }}
                >
                    <Route
                        exact
                        path={`${url}/book/:serviceId`}
                        render={(props) => <Booking {...props} serviceWorkingTime={workingTimeRef.current} />}
                    />
                    <Route exact path={`${url}/appointment`} component={Appointment} />
                </div>
            </div>
        </div>
    )
}

export default ManageBookingMenu;