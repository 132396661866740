import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { DataGrid } from '@material-ui/data-grid';
import SearchIcon from '@material-ui/icons/Search';
import { Checkbox, InputAdornment, MenuItem } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { apiCabinetsGet } from '../../../api/cabinets';
import ErrorAlert from '../../../components/alert/ErrorAlert';
import { CreateButton } from '../../../components/button/createButton';
import { CustomLoading } from '../../../components/grid/customLoading';
import { EmptyRowsView } from '../../../components/grid/emtyRowsView';
import SelectCpn from '../../../components/input/select/select';
import LargeTooltip from '../../../components/largeTooltip';
import { PrimaryTitle } from '../../../components/title';
import { useStyles } from './styles';

const ITEM_HEIGHT = 52;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
    getContentAnchorEl: () => null
};

export default function Management() {
    const [state, setState] = useState({
        listCabinet: [],
        cabinet: {},
        listCells: [],
        cabinetIdSelected: "",
        search: "",
        listStatusSelected: [],
        responseStatus: {
            succ: "",
            err: ""
        },
        active: 0,
        isLoading: false,
        isBlockLoading: false
    });

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const { url } = useRouteMatch();
    const moment = require('moment-timezone');
    const location = useLocation();

    const oldFilterCell = location?.state?.oldFilterCell;
    const oldCabinet = location?.state?.oldCabinet;

    const filterCellsRef = useRef({
        search: "",
        isUnlock: false
    });
    const debounceRef = useRef();
    const searchRef = useRef();

    const handleChangeResponseStatus = (succ = "", err = "") => {
        setState(prev => ({
            ...prev,
            responseStatus: {
                succ,
                err
            }
        }));
    }

    const fetchApiGetListCabinets = async () => {
        try {
            setState(prev => ({
                ...prev,
                isLoading: true
            }));

            const body = {
                language: i18n.language
            }

            const { data } = await apiCabinetsGet(body);
            let { Items: listCabinet } = data;

            if (!listCabinet) {
                listCabinet = [];
            }

            listCabinet.forEach(item => {
                item.Cells.forEach(cellsItem => cellsItem["id"] = cellsItem.ID);
            });

            let cabinet = [], cabinetIdSelected = "", listCells = [];
            if (Array.isArray(listCabinet) && listCabinet.length > 0) {
                cabinet = listCabinet[0];
                cabinetIdSelected = listCabinet[0].ID;
                listCells = listCabinet[0].Cells
            }

            setState(prev => ({
                ...prev,
                listCabinet,
                cabinet,
                cabinetIdSelected,
                listCells
            }));
        } catch (error) {
            handleChangeResponseStatus("", t("Tải thông tin tủ bị lỗi, vui lòng tải lại"));
        }

        setState(prev => ({
            ...prev,
            isLoading: false
        }));
    }

    const onClickButtonToHistories = () => {
        history.push(`${url}/histories`)
    }

    const onSelectCabinetChange = (e) => {
        const indexOfCabinet = state.listCabinet?.findIndex(item => item.ID === e.target.value);

        searchRef.current.value = "";
        filterCellsRef.current = {
            search: "",
            isUnlock: false
        }
        setState(prev => ({
            ...prev,
            cabinet: state.listCabinet[indexOfCabinet] || {},
            listCells: state.listCabinet[indexOfCabinet]?.Cells || [],
            cabinetIdSelected: e.target.value,
            active: 0,
            search: "",
            listStatusSelected: []
        }))
    }

    const getStatusCell = (cell) => {
        let result = <span></span>;

        if (!cell) {
            return result;
        }

        if (!cell.startTime) {
            result = <span className={clsx(classes.cellStatus, classes.emptyStatus)}>
                {t("Trống")}
            </span>;
        } else if (!cell.deliveredTime) {
            result = <span className={clsx(classes.cellStatus, classes.waitDeliveryStatus)}>
                {t("Chờ gửi hàng")}
            </span>;
        }

        if (cell.startTime && cell.deliveredTime) {
            result = <span className={clsx(classes.cellStatus, classes.waitGetItemStatus)}>
                {t("Chờ lấy hàng")}
            </span>;
        }

        const isExpired = cell.startTime + state?.cabinet?.OverdueDate * 24 * 60 * 60 * 1000;
        if (isExpired < Date.now()) {
            result = <span className={clsx(classes.cellStatus, classes.overDueStatus)}>
                {t("Quá hạn")}
            </span>;
        }

        return result;
    }

    const getStatusCellText = (cell) => {
        let result = "";

        if (!cell) {
            return result;
        }

        if (!cell.startTime) {
            result = t("Trống");
        } else if (!cell.deliveredTime) {
            result = t("Chờ gửi hàng")
        }

        if (cell.startTime && cell.deliveredTime) {
            result = t("Chờ lấy hàng")
        }

        const isExpired = cell.startTime + state?.cabinet?.OverdueDate * 24 * 60 * 60 * 1000;
        if (isExpired < Date.now()) {
            result = t("Quá hạn")
        }

        return result;
    }

    const onSearchChange = (e) => {
        if (debounceRef.current) {
            clearTimeout(debounceRef.current);
        }

        debounceRef.current = setTimeout(() => {
            filterCellsRef.current = {
                ...filterCellsRef.current,
                search: e.target.value
            }

            setState(prev => ({
                ...prev
            }));
        }, 400);
    }

    const onStatusCellChange = (e) => {
        setState(prev => ({
            ...prev,
            listStatusSelected: e.target.value
        }))
    }

    const getCheckedFiltertatus = (valueItem) => {
        return state.listStatusSelected.indexOf(valueItem) >= 0;
    }

    const onClickTab = (key) => {
        let isUnlock = false, active = 0;

        if (key === "unlock") {
            active = 1;
            isUnlock = true;
        }

        filterCellsRef.current = {
            ...filterCellsRef.current,
            isUnlock
        }
        setState(prev => ({
            ...prev,
            active
        }));
    }

    const onRowClick = (e) => {
        history.push({
            pathname: `${url}/details`,
            state: {
                cabinetID: state.cabinet?.ID,
                cellID: e?.row?.ID,
                oldFilterCell: filterCellsRef.current,
                oldCabinet: state.cabinet
            }
        });
    }

    const sortNumber = (v1, v2) => {
        return v1.localeCompare(v2, undefined, {
            numeric: true,
            sensitivity: 'base'
        });
    }

    const handleSortStatus = (id1, id2) => {
        let element1, element2;

        state.listCells.forEach(item => {
            if(item.id === id1) {
                element1 = item;
            }
            if(item.id === id2) {
                element2 = item;
            }
        })

        if(!element1 || !element2) return 0;

        return sortNumber(getStatusCellText(element1), getStatusCellText(element2));
    }

    useEffect(() => {
        fetchApiGetListCabinets();
    }, [i18n.language]);

    useEffect(() => {
        if (!state.cabinet.ID) return;

        let listCells = [...state?.cabinet?.Cells];

        if (filterCellsRef.current.isUnlock) {
            listCells = listCells.filter(item => !item.isLocked);
        }

        if (filterCellsRef.current.search || state.listStatusSelected.length > 0) {
            listCells = listCells.filter(item => {
                if (filterCellsRef.current.search) {
                    return (item?.fullName?.toLowerCase().includes(filterCellsRef.current.search?.toLowerCase())
                        || item?.cabinetCellName?.toLowerCase().includes(filterCellsRef.current.search)
                    )
                }
                if (state.listStatusSelected.length > 0) {
                    const checked = state.listStatusSelected.findIndex(statusSelectedItem => getStatusCellText(item) === statusSelectedItem);
                    return checked >= 0;
                }
            });
        }

        setState(prev => ({
            ...prev,
            listCells
        }));
    }, [filterCellsRef.current, state.listStatusSelected])

    useEffect(() => {
        if (state.listCabinet.length === 0) return;

        if (oldCabinet) {
            setState(prev => ({
                ...prev,
                cabinet: oldCabinet,
                cabinetIdSelected: oldCabinet.ID
            }))
        }
        if (oldFilterCell) {
            filterCellsRef.current = {
                ...oldFilterCell
            }
            setState(prev => ({
                ...prev,
                active: oldFilterCell.isUnlock ? 1 : 0
            }))
        }
    }, [oldFilterCell, oldCabinet, state.listCabinet])

    const columns = [
        {
            headerName: t("Số ô"),
            field: 'cabinetCellName',
            cellClassName: 'super-app-theme--cell',
            sortComparator: (v1, v2, param1, param2) => sortNumber(v1, v2),
            renderCell: params => (
                <LargeTooltip
                    title={params?.row?.cabinetCellName}
                >
                   
                    <p style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }}>
                        {params?.row?.cabinetCellName}
                    </p>
                </LargeTooltip>
            ),
            width: 100,
        },
        {
            headerName: t("Kích thước"),
            field: 'cellType',
            cellClassName: 'super-app-theme--cell',
            sortComparator: (v1, v2, param1, param2) => sortNumber(v1?.CellType[0]?.name, v2?.CellType[0]?.name),
            renderCell: params => (
                <LargeTooltip
                    title={params?.row?.cellType?.CellType[0]?.name}
                >
                    <p style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }}>
                        {params?.row?.cellType?.CellType[0]?.name}
                    </p>
                </LargeTooltip>
            ),
            flex: 1,
        },
        {
            headerName: t("Người đặt"),
            field: 'fullName',
            flex: 1,
            cellClassName: 'super-app-theme--cell',
            renderCell: params => (
                <LargeTooltip
                    title={`${params?.row?.fullName || ""}`}
                >
                    <p style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }}>
                        {`${params?.row?.fullName || ""}`}
                    </p>
                </LargeTooltip>
            ),
        },
        {
            headerName: t("Trạng thái"),
            field: 'status',
            cellClassName: 'super-app-theme--cell',
            sortComparator: (v1, v2, param1, param2) => handleSortStatus(param1.id, param2.id),
            renderCell: params => (
                <LargeTooltip
                    title={getStatusCellText(params?.row)}
                >
                    <p style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }}>
                        {getStatusCell(params?.row)}
                    </p>
                </LargeTooltip>
            ),
            width: 170,
        },
        {
            headerName: t("Thời gian đặt"),
            field: 'startTime',
            cellClassName: 'super-app-theme--cell',
            renderCell: params => (
                <LargeTooltip
                    title={params?.row?.startTime
                        ? moment.tz(params?.row?.startTime, "Asia/Ho_Chi_Minh").format("HH:mm DD/MM/YYYY ")
                        : ""
                    }
                >
                    <p style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }}>
                        {params?.row?.startTime
                            ? moment.tz(params?.row?.startTime, "Asia/Ho_Chi_Minh").format("HH:mm DD/MM/YYYY ")
                            : ""
                        }
                    </p>
                </LargeTooltip>
            ),
            flex: 1,
        },
    ];

    return (
        <div className={classes.manage}>
            <div className={classes.manageHeader}>
                <div className={classes.wrapTitle}>
                    <PrimaryTitle>{t("Quản lý tủ")}</PrimaryTitle>
                    <Select
                        id='block'
                        size='small'
                        className={clsx(classes.select, classes.selectNoneBorder)}
                        disabled={state.isLoading}
                        value={state.cabinetIdSelected}
                        onChange={onSelectCabinetChange}

                    >
                        {state.listCabinet?.map(item => (
                            <MenuItem
                                className={classes.menuItem}
                                key={item.ID}
                                value={item.ID}
                            >
                                <LargeTooltip
                                    style={{
                                        textOverflow: "ellipsis",
                                        overflow: "hidden"
                                    }}
                                    title={`${t("Tòa")} ${item.Location} - ${t("Tủ")} ${item.CabinetName}`}

                                >
                                    <p>
                                        {t("Tòa")} {item.Location} - {t("Tủ")} {item.CabinetName}
                                    </p>
                                </LargeTooltip>
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                <CreateButton onClick={onClickButtonToHistories}>
                    {t("Lịch sử hoạt động")}
                </CreateButton>
            </div>

            <div className={classes.manageAction}>
                <TextField
                    inputRef={searchRef}
                    placeholder={t("Tìm kiếm ô tủ, người đặt tủ")}
                    variant="outlined"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        style: { background: '#FFFFFF', height: '40px', width: '320px' }
                    }}
                    // value={state.search}
                    onChange={onSearchChange}
                />

                <div className={classes.wrapFilter}>
                    <label htmlFor="">{t("Lọc bởi")}: </label>
                    <SelectCpn
                        multiple
                        className={clsx(classes.select, classes.selectStatus)}
                        MenuProps={MenuProps}
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                                return <span style={{ color: 'rgb(0, 0, 0, 0.4)' }} >{t("Trạng thái")}</span>;
                            }

                            return selected.join(', ');
                        }}
                        value={state.listStatusSelected}
                        onChange={onStatusCellChange}
                    >
                        <MenuItem value={t("Trống")}>
                            <Checkbox
                                checked={getCheckedFiltertatus(t("Trống"))}
                                style={{
                                    padding: "0 8px"
                                }}
                            />
                            {t("Trống")}
                        </MenuItem>
                        <MenuItem value={t("Chờ gửi hàng")}>
                            <Checkbox
                                checked={getCheckedFiltertatus(t("Chờ gửi hàng"))}
                                style={{
                                    padding: "0 8px"
                                }}
                            />
                            {t("Chờ gửi hàng")}
                        </MenuItem>
                        <MenuItem value={t("Chờ lấy hàng")}>
                            <Checkbox
                                checked={getCheckedFiltertatus(t("Chờ lấy hàng"))}
                                style={{
                                    padding: "0 8px"
                                }}
                            />
                            {t("Chờ lấy hàng")}
                        </MenuItem>
                        <MenuItem value={t("Quá hạn")}>
                            <Checkbox
                                checked={getCheckedFiltertatus(t("Quá hạn"))}
                                style={{
                                    padding: "0 8px"
                                }}
                            />
                            {t("Quá hạn")}
                        </MenuItem>
                    </SelectCpn>
                </div>
            </div>

            <div className={classes.tab}>
                <div
                    className={clsx(state.active === 0 && classes.active)}
                    onClick={() => onClickTab("all")}
                >
                    {t("Tất cả tủ")}
                </div>
                <div
                    className={clsx(state.active === 1 && classes.active)}
                    onClick={() => onClickTab("unlock")}
                >
                    {t("Tủ chưa đóng")}
                </div>
            </div>

            <div className={clsx(classes.table, classes.root)}>
                <DataGrid
                    disableSelectionOnClick
                    disableColumnMenu
                    componentsProps={{
                        pagination: {
                            labelRowsPerPage: t("Số dòng mỗi trang"),
                            labelDisplayedRows: ({ from, to, count }) => { return `${from}-${to} ${t("trong số")} ${count}` }
                        }
                    }}
                    components={{
                        NoRowsOverlay: () => EmptyRowsView(t("không tìm thấy dữ liệu")),
                        LoadingOverlay: CustomLoading
                    }}
                    localeText={{
                        footerRowSelected: (count) => count + t(" hàng trong bảng đã được chọn"),
                        columnHeaderSortIconLabel: t('Sắp xếp')
                    }}
                    loading={state.isLoading}
                    columns={columns}
                    rows={state.listCells}
                    rowHeight={45}
                    rowsPerPageOptions={[100]}
                    onRowClick={onRowClick}
                />
            </div>

            <ErrorAlert
                message={state.responseStatus.err}
                onCloseDialog={() => handleChangeResponseStatus()}
            />
        </div>
    )
}
