import React, { useState, useEffect, useRef } from 'react';
import { isEmpty, checkEmail, checkFullName, checkNumberPhone, checkImage } from '../../validation/CheckForm';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import Button from '@material-ui/core/Button/Button';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import md5 from 'md5';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import vi from 'date-fns/locale/vi';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import { compare } from 'natural-orderby';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import nationalities from '../../data/nationalities.json';
import { useTranslation } from 'react-i18next';
import Menu from '@material-ui/core/Menu';
import Webcam from "react-webcam";
import SuccessAlert from '../alert/SuccessAlert';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { v5 } from 'uuid';
import { authenticator } from 'otplib';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';
import MultipleAutocomplete from '@mui/material/Autocomplete';
import { apiExists } from '../../api/exists';
import { apiUserinfo } from '../../api/userinfo';
import { apiGetapartmentdata } from '../../api/apartment';
import { apiUserupdate } from '../../api/userupdate';
import { apiGetconfig } from '../../api/getconfig';
import { configurations } from '../../config/Registration';
import { PaymentConfiguration } from '../../config/enum';
import { deepCloneArr } from '../../helper/deepClone';
import { BlockFloorRoomRole } from '../policies/index';

import { Confirm } from '../dialog';
import { checkFaceImage } from '../actionImage/checkFaceID/index';
import { checkQuality } from '../../api/faceID';
import LargeTooltip from '../largeTooltip';

const provinceData = require('../../data/data.json');
const useStyles = makeStyles(theme => ({
    part_form: {
        display: 'flex',
        width: '100%',
        minWidth: '1150px',
        background: '#FFFFFF',
        flexWrap: 'wrap',
        borderRadius: '6px'
    },
    contentBottomForm: {
        width: '98%',
        margin: 'auto'
    },
    base_info_title: {
        fontSize: '20px',
        lineHeight: '28px',
        color: '#333333',
        fontWeight: '600',
        paddingTop: '10px',
        marginLeft: '4%'
    },
    avatar_control: {
        marginLeft: '4%',
        marginTop: '8px',
        marginBottom: '15px',
        position: 'relative',
        height: '125px',
        width: '136px'
    },
    avatar_logo: {
        width: '116px',
        height: '116px',
        border: '1px solid #c8c8c8'
    },
    fileBtn: {
        position: 'absolute',
        bottom: '-25px',
        right: '-2px'
    },
    inputBlock: {
        height: '79px',
        width: '96%',
        paddingLeft: '4%',
        position: 'relative'
    },
    inputRightBlock: {
        height: '80px',
        width: '32%',
        paddingLeft: '2%',
        position: 'relative',
        float: 'left'
    },
    label: {
        paddingBottom: '0px',
        fontSize: '14px',
        lineHeight: '140%',
        color: '#000000',
        display: 'inline-block',
        margin: '0'
    },
    input: {
        width: '100%',
        height: '40px'
    },
    height72: {
        height: '46px',
        width: '80%'
    },
    error: {
        fontSize: '12px',
        color: 'red',
        position: 'absolute',
        bottom: '0'
    },
    selectBlock: {
        height: '75px',
        width: '50%',
        position: 'relative',
        marginLeft: '10%'
    },
    resetBtn: {
        width: '160px',
        height: '40px',
        textTransform: 'none',
        color: '#009ADA',
        fontSize: '18px',
        fontWeight: '600',
        border: '1px solid #009ADA'
    },
    dialogTitle: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '25px',
        color: '#FFFFFF',
    },
    contentDialog: {
        fontSize: '16px',
        height: '68px',
        borderBottom: '1px solid #EEEDF2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    placeholder: theme.place_holder,
    secondaryTitle: theme.secondary_title,
    dialogTitleBackground: theme.primaryColor,
    primaryColor: theme.primaryTextColor,
    errorDialogTitleBackground: theme.errorColor,
    errorBtn: theme.error_button,
    requestError: {
        width: '100%',
        height: '65vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20px',
        color: '#6D6D6D'
    },
    imageAdding: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0'
    },
    date: {
        '& .MuiInput-underline:before': {
            border: '1px solid rgba(0, 0, 0, 0.23)',
            height: '40px',
            borderRadius: '4px',
        },
        '& .MuiInput-underline:after': {
            display: 'none'
        },
        '& input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd': {
            paddingLeft: '10px',
        },

    },
    imageDialog: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        background: 'rgba(0,0,0,0.1)',
        zIndex: '1333',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '550px'
    },
    focus: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: theme.themeColor,
            },
        },
    },
    select: {
        '& .MuiOutlinedInput-input': {
            padding: '10px 12px'
        }
    },
    checked: {},
    radio: {
        '&$checked': {
            color: theme.menuBackground
        }
    },
    formControlLabel: {
        position: 'relative',
        left: '-16px',
        '& .MuiFormControlLabel-label': {
            fontSize: '14px', lineHeight: '140%', color: '#000000'
        }
    },
    autocompleteStyle: {
        '& .MuiButtonBase-root.MuiChip-root.MuiChip-filled.Mui-disabled.MuiChip-sizeSmall.MuiChip-colorDefault.MuiChip-deletable.MuiChip-deletableColorDefault.MuiChip-filledDefault.MuiAutocomplete-tag.MuiAutocomplete-tagSizeSmall.css-r4c50g-MuiButtonBase-root-MuiChip-root': {
            opacity: '0.8'
        },
        '& input#tags-outlined': {
            minWidth: '0'
        }
    },
    hidden: {
        display: 'none'
    },
    uploadStyle: {
        display: 'block',
        width: '100%',
        margin: '0',
        cursor: 'pointer'
    }
}))

const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);
const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);
const ErrorBtn = withStyles(theme => ({ root: theme.error_button }))(Button);
const DeActButton = withStyles(theme => ({
    root: {
        height: '40px',
        width: 'auto',
        textTransform: 'none',
        color: '#FFFFFF',
        fontSize: '18px',
        background: '#e01010',
        marginLeft: '16px',
        padding: '8px 16px',
        '&:hover': { background: '#e01010' }
    }
}))(Button);

const ResidenceInfo = (props) => {
    const { username, onBackClick, title } = props;
    const classes = useStyles();
    const [residentUsername, setResidentUsername] = useState('');
    const [user, setUser] = useState({ block: '', floor: '', room: '' });
    const [districts, setDistricts] = useState([]);
    const [districtNames, setDistrictNames] = useState([]);
    const [wardNames, setWardNames] = useState([]);
    const [editUser, setEditUser] = useState({});
    const [provinces, setProvinces] = useState([]);
    const [provinceNames, setProvinceNames] = useState([]);
    const [isFailedDialog, setIsFailedDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isDialog, setIsDialog] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isEmail, setIsEmail] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isImageAdding, setIsImageAdding] = useState(false);
    const [message, setMessage] = useState({
        request: '',
        avatar: '',
        role: '',
        birthday: '',
        ward: '',
        district: '',
        province: '',
        full_name: '',
        phone: '',
        email: '',
        address: '',
        apartments: '',
        floor: '',
        room: '',
        block: '',
        nationality: '',
        issued_place: '',
        CMND: '',
        sex: '',
        career: '',
        note: '',
        success: '',
        error: '',
        deactivate: ''
    });
    const [apartmentData, setApartmentData] = useState([]);
    const [base64data, setBase64data] = useState('');
    const [isExpiredSession, setIsExpiredSession] = useState(false);
    const [isFaceID, setIsFaceID] = useState(false);
    const [oldHostInfo, setOldHostInfo] = useState({
        role: '',
        block: '',
        floor: '',
        room: ''
    });
    const [oldCMND, setOldCMND] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [isZoomAvatar, setIsZoomAvatar] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const CMNDRef = useRef();
    const { t, i18n } = useTranslation();
    const zoomRef = useRef();
    const webRef = useRef();
    const phoneRef = useRef();
    const emailRef = useRef();
    const [previewFaceID, setPreviewFaceID] = useState(null);
    const [isPreview, setIsPreview] = useState(false);
    const [isCapture, setIsCapture] = useState(false);
    const [devices, setDevices] = React.useState([]);
    const [videoConstraints, setVideoContraints] = useState({
        deviceId: '',
        width: 640,
        height: 480
    })
    const [image, setImage] = useState('');
    const [oldFaceid, setOldFaceid] = useState('');
    const [selectedCam, setSelectedCam] = useState(false);
    const [age, setAge] = useState();
    const [isDeActivated, setIsDeActivated] = useState(false);
    const [isActivated, setIsActivated] = useState(false);
    const [oldNumberPhone, setOldNumberPhone] = useState('');
    const [isAddRoom, setIsAddRoom] = useState(false);
    const [apartments, setApartments] = useState([]);
    const [oldApartments, setOldApartments] = useState([]);
    const [removeFaceID, setRemoveFaceID] = useState(-1);

    useEffect(() => {
        if (isExpiredSession) {
            history.push('/login');
            dispatch({ type: 'IS_LOGIN_AGAIN_TRUE' });
        }
    }, [isExpiredSession]);

    const getConfiguration = async () => {
        try {
            const res = await apiGetconfig();
            if (res.data.Items[0].Configuration.remove_faceid) {
                setRemoveFaceID(res.data.Items[0].Configuration.remove_faceid)
            }
        } catch (error) {
            if (error.response && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setRemoveFaceID(configurations.remove_faceid);
        }
    }

    const getDefaultValue = async () => {
        if (residentUsername === '') return;
        try {
            var res = await apiUserinfo({ username: residentUsername });
            var resident = {
                career: typeof (res.data.Career) === 'undefined' ? "" : res.data.Career,
                note: typeof (res.data.Note) === 'undefined' ? "" : res.data.Note,
                address: typeof (res.data.Address) === 'undefined' ? "" : res.data.Address,
                birthday: (typeof (res.data.Birthday) === 'undefined' || res.data.FullName === '') ? null : new Date(res.data.Birthday),
                CMND: typeof (res.data.CMND) === 'undefined' ? '' : res.data.CMND,
                email: typeof (res.data.Email) === 'undefined' ? '' : res.data.Email,
                full_name: typeof (res.data.FullName) === 'undefined' ? '' : res.data.FullName,
                nationality: typeof (res.data.Nationality) === 'undefined' ? '' : res.data.Nationality,
                phone: typeof (res.data.Phone) === 'undefined' ? '' : res.data.Phone.replace('+84', '0'),
                province: typeof (res.data.Province) === 'undefined' ? '' : res.data.Province,
                district: typeof (res.data.District) === 'undefined' ? '' : res.data.District,
                ward: typeof (res.data.Ward) === 'undefined' ? '' : res.data.Ward,
                role: typeof (res.data.ResidenceRole) === 'undefined' ? '' : res.data.ResidenceRole,
                sex: typeof (res.data.Sex) === 'undefined' ? '' : res.data.Sex,
                username: res.data.Username,
                block: typeof (res.data.BlockNumber) === 'undefined' ? '' : res.data.BlockNumber,
                floor: typeof (res.data.Floor) === 'undefined' ? '' : res.data.Floor,
                room: typeof (res.data.Room) === 'undefined' ? '' : res.data.Room,
                issued_place: typeof (res.data.IssuePlace) === 'undefined' ? '' : res.data.IssuePlace,
                issued_date: typeof (res.data.IssueDate) === 'undefined' ? '' : res.data.IssueDate,
                sub_account: typeof (res.data.SubAccount) === 'undefined' ? false : res.data.SubAccount,
                faceid: typeof (res.data.FaceID) === 'undefined' ? '' : res.data.FaceID,
                residenceStatus: res.data.ResidenceStatus,
                approver: res.data ?.Approver,
                approvedTime: res.data ?.ApprovedTime,
                action: res.data ?.Action,
                createdTime: res.data ?.CreatedTime,
                apartments: res.data ?.Apartments
            }
            setOldCMND(resident.CMND);
            if (resident.apartments) {
                setUser(resident);
                setOldApartments(resident.apartments);
                setApartments([...resident.apartments]);
            } else {
                var data = [{
                    block: resident.block,
                    floor: resident.floor,
                    room: resident.room,
                    role: resident.role,
                }];
                setUser({ ...resident, apartments: data });
                setApartments(data);
                setOldApartments(data);
            }
            setOldHostInfo({ role: resident.role, block: resident.block, floor: resident.floor, room: resident.room });
            setOldFaceid(resident.faceid);
            setImage(resident.faceid);
            setOldNumberPhone(resident.phone);
            setAge(new Date().getFullYear() - new Date(resident.birthday).getFullYear());
            setEditUser({});
            setBase64data('');
            setIsEmail(true);
            setMessage({
                ...message,
                request: '',
                avatar: '',
                role: '',
                birthday: '',
                ward: '',
                district: '',
                province: '',
                full_name: '',
                phone: '',
                email: '',
                address: '',
                floor: '',
                room: '',
                block: '',
                nationality: '',
                issued_place: '',
                CMND: '',
                sex: '',
                career: '',
                note: '',
                apartments: '',
                addApartment: ''
            })
            setLoading(true);
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setMessage({ ...message, request: t("Không lấy được dữ liệu") });
        }

    }

    const getBlocks = async () => {
        try {
            var res = await apiGetapartmentdata();
            setApartmentData(res.data.Items);
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
        }
    }

    useEffect(() => {
        if (!loading && residentUsername !== '') {
            getDefaultValue();
            getProvinceNames();
            getBlocks();
            getConfiguration();
        }
    }, [loading, residentUsername])

    useEffect(() => {
        setResidentUsername(username);
    }, [username])

    const getProvinceNames = () => {
        const data = provinceData.sort((a, b) => compare({ order: 'asc' })(a.Name, b.Name));
        var names = [];
        data.forEach(item => {
            names.push(item.Name);
        })
        setProvinces(data);
        setProvinceNames(names);
    }

    const getDistrictNames = (province) => {
        const data = provinces.filter(item => item.Name === province);
        if (data.length === 0) return setDistrictNames([]);
        var districtNams = [];
        data[0].Districts.forEach(item => {
            districtNams.push(item.Name);
        });
        districtNams.sort((a, b) => compare({ order: 'asc' })(a, b))
        setDistrictNames(districtNams);
        setDistricts(data[0].Districts);
    }

    const getWardNames = (district) => {
        const data = districts.filter(item => item.Name === district);
        if (data.length === 0) return setWardNames([]);
        var names = [];
        if (user.province === 'Thành phố Đà Nẵng' && data[0].Name === 'Huyện Hoàng Sa') names = [];
        else if (user.province === 'Thành phố Hải Phòng' && data[0].Name === 'Huyện Bạch Long Vĩ') names = [];
        else {
            data[0].Wards.forEach(item => {
                names.push(item.Name);
            });
        }
        names.sort((a, b) => compare({ order: 'asc' })(a, b))
        setWardNames(names);
    }

    useEffect(() => {
        if (user.province === '') return;
        getDistrictNames(user.province);
    }, [provinces, user.province])

    useEffect(() => {
        if (user.district === '') return;
        getWardNames(user.district);
    }, [districts, user.district])

    const onProvinceChange = (e, newValue) => {
        setDistrictNames([]);
        setWardNames([]);
        if (newValue === null) {
            setUser({ ...user, province: '', ward: '', district: '' });
            setEditUser({ ...editUser, province: '', ward: '', district: '' });
            return;
        }
        setUser({ ...user, province: newValue, ward: '', district: '' });
        setEditUser({ ...editUser, province: newValue, ward: '', district: '' });
    }

    const onDistrictChange = (e, newValue) => {
        setWardNames([]);
        if (newValue === null) {
            setUser({ ...user, district: '', ward: '' });
            setEditUser({ ...editUser, district: '', ward: '' });
            return;
        }
        setUser({ ...user, district: newValue, ward: '' });
        setEditUser({ ...editUser, district: newValue, ward: '' });
    }

    const onWardChange = (e, newValue) => {
        if (newValue === null) {
            setUser({ ...user, ward: '' });
            setEditUser({ ...editUser, ward: '' });
            return;
        }
        setUser({ ...user, ward: newValue });
        setEditUser({ ...editUser, ward: newValue });
    }

    const onChange = (e) => {
        var name = e.target.name;
        var value = e.target.value;
        setUser({ ...user, [name]: value });
        setEditUser({ ...editUser, [name]: value });
        if (isEmpty(value)) {
            setMessage({ ...message, [name]: t("Không được để trống") });
        } else {
            setMessage({ ...message, [name]: '' });
        }
    }

    const onMainAccountChange = (e) => {
        var sub_account = e.target.value;
        if (sub_account === 'false') {
            setUser({ ...user, sub_account: false, apartments: oldApartments });
            setEditUser({ ...editUser, sub_account: false, apartments: oldApartments });
        } else {
            var aprts = [];
            apartments.forEach(item => {
                if (item.role === 'host') aprts.push({ ...item, role: 'member', });
                else aprts.push(item);
            })
            if (oldNumberPhone === '') {
                setUser({ ...user, sub_account: true, role: 'member', phone: '', apartments: aprts });
                setEditUser({ ...editUser, sub_account: true, role: 'member', phone: '', apartments: aprts });
            } else {
                setUser({ ...user, sub_account: true, role: 'member', apartments: aprts });
                setEditUser({ ...editUser, sub_account: true, role: 'member', apartments: aprts });
            }
            setApartments(aprts);
            setMessage({ ...message, phone: '' });
        }
    }

    const onPhoneChange = (e) => {
        const phone = e.target.value.trim();
        setUser({ ...user, phone: phone });
        setEditUser({ ...editUser, phone: phone });
        if (phoneRef.current) clearTimeout(phoneRef.current);
        phoneRef.current = setTimeout(() => {
            if (isEmpty(phone)) {
                setMessage({ ...message, phone: t("Không được để trống") });
            } else if (!checkNumberPhone(phone)) {
                setMessage({ ...message, phone: t("Phải là số điện thoại gồm 10 số") });
            } else {
                setMessage({ ...message, phone: "" });
                if (phone !== '') checkPhone(phone);
            }
        }, 500);
    }

    const checkPhone = async (phone) => {
        try {
            const handledPhone = phone.indexOf('0') === 0 ? phone.replace('0', '+84') : phone;
            var res = await apiExists({ phone: handledPhone });
            if (res.data === 'Found') setMessage({ ...message, phone: t("Số điện thoại đã tồn tại") });
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setMessage({ ...message, phone: t("Đã xảy ra lỗi trong quá trình kiểm tra số điện thoại") });
        }
    }

    const onFullNameChange = (e) => {
        const full_name = e.target.value;
        setUser({ ...user, full_name: full_name });
        setEditUser({ ...editUser, full_name: full_name });
        if (isEmpty(full_name)) {
            setMessage({ ...message, full_name: t("Không được để trống") });
        } else if (!checkFullName(full_name)) {
            setMessage({ ...message, full_name: t("Vui lòng nhập đúng họ tên") });
        } else if (full_name.trim().length < 5 || full_name.trim().length > 26) {
            setMessage({ ...message, full_name: t("Độ dài ký tự khoảng 5 đến 26") });
        } else {
            setMessage({ ...message, full_name: '' });
        }
    }

    const onCareerChange = (e) => {
        const career = e.target.value;
        setUser({ ...user, career: career });
        setEditUser({ ...editUser, career: career })
        if (career.trim().length > 30) {
            setMessage({ ...message, career: t("Độ dài ký tự không quá 30") });
        } else if (!checkFullName(career)) {
            setMessage({ ...message, career: t("Vui lòng nhập đúng nghề nghiệp") });
        } else {
            setMessage({ ...message, career: '' });
        }
    }

    const onCMNDChange = (e) => {
        const CMND = e.target.value;
        const re = /^(\d{9}|\d{12})$/;
        const pp = /^[A-Z]{1}\d{7}$/;
        setUser({ ...user, CMND: CMND });
        setEditUser({ ...editUser, CMND: CMND });
        if (CMND === '') {
            setUser({ ...user, issued_place: '', CMND: CMND });
            setEditUser({ ...editUser, issued_place: '', CMND: CMND });
            age < 15 ? setMessage({ ...message, CMND: '' }) : setMessage({ ...message, CMND: t('Không được để trống') });
            return;
        }
        if (user.nationality === 'Việt Nam' || user.nationality === '') {
            if (!re.test(CMND) && !pp.test(CMND)) {
                setMessage({ ...message, CMND: t("CMND/CCCD/Passport không hợp lệ") });
            } else {
                setMessage({ ...message, CMND: '' });
                if (CMNDRef.current) clearTimeout(CMNDRef.current);
                CMNDRef.current = setTimeout(() => {
                    checkCMND(CMND, false);
                }, 300);
            }
        } else {
            if (CMND.trim().length < 8) {
                setMessage({ ...message, CMND: t("CMND/CCCD/Passport phải từ 8 ký tự") });
            } else {
                setMessage({ ...message, CMND: '' });
                if (CMNDRef.current) clearTimeout(CMNDRef.current);
                CMNDRef.current = setTimeout(() => {
                    checkCMND(CMND, false);
                }, 300);
            }
        }
    }

    const checkCMND = async (CMND, nationality) => {
        try {
            if (CMND === oldCMND) return;
            var res = await apiExists({ CMND: CMND });
            if (res.data === 'Found')
                nationality ? setMessage({ ...message, CMND: t("CMND/CCCD/Passport đã tồn tại"), nationality: '' }) : setMessage({ ...message, CMND: t("CMND/CCCD/Passport đã tồn tại") })
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setMessage({ ...message, CMND: t("Đã xảy ra lỗi trong quá trình kiểm tra CMND/CCCD/Passport") });
        }
    }

    const onIssuedPlaceChange = (e) => {
        setUser({ ...user, issued_place: e.target.value });
        setEditUser({ ...editUser, issued_place: e.target.value });
    }

    const onNationalityChange = (e, newValue) => {
        if (newValue === null) {
            setMessage({ ...message, nationality: t("Không được để trống") });
            setUser({ ...user, nationality: '' });
            setEditUser({ ...editUser, nationality: '' });
            return;
        }
        setUser({ ...user, nationality: newValue });
        setEditUser({ ...editUser, nationality: newValue });
        const re = /^(\d{9}|\d{12})$/;
        const pp = /^[A-Z]{1}\d{7}$/;
        if (user.CMND.trim() === '') return setMessage({ ...message, nationality: '' });
        if (newValue === 'Việt Nam') {
            if (!pp.test(user.CMND) && !re.test(user.CMND)) {
                setMessage({ ...message, CMND: t("CMND/CCCD/Passport không hợp lệ"), nationality: '' });
            } else {
                setMessage({ ...message, CMND: '', nationality: '' });
                if (CMNDRef.current) clearTimeout(CMNDRef.current);
                CMNDRef.current = setTimeout(() => {
                    checkCMND(user.CMND, true);
                }, 300);
            }
        } else {
            if (user.CMND.trim().length < 8) {
                setMessage({ ...message, CMND: t("CMND/CCCD/Passport phải từ 8 ký tự"), nationality: '' });
            } else {
                setMessage({ ...message, CMND: '', nationality: '' });
                if (CMNDRef.current) clearTimeout(CMNDRef.current);
                CMNDRef.current = setTimeout(() => {
                    checkCMND(user.CMND, true);
                }, 300);
            }
        }
    }

    const onNoteChange = (e) => {
        const note = e.target.value;
        setUser({ ...user, note: note });
        setEditUser({ ...editUser, note: note });
        if (note.trim().length > 256) {
            setMessage({ ...message, note: t("Độ dài ký tự không quá 256") });
        } else {
            setMessage({ ...message, note: '' });
        }
    }

    const onDateChange = (e) => {
        const today = new Date();
        const date = new Date(e);
        setUser({ ...user, birthday: e });
        setEditUser({ ...editUser, birthday: moment(date.getTime()).format('YYYY-MM-DD') });
        if (isNaN(date.getTime()) || e === null) {
            setMessage({ ...message, birthday: t("Thông tin chưa hợp lệ") });
        } else if (moment(today.getTime()).format('YYYY-MM-DD') < moment(date.getTime()).format('YYYY-MM-DD')) {
            setMessage({ ...message, birthday: t("Phải trước ngày hiện tại") });
        } else {
            const countAge = today.getFullYear() - date.getFullYear();
            setAge(countAge);
            if (user.CMND === '' && countAge < 15) setMessage({ ...message, birthday: '', CMND: '' });
            else setMessage({ ...message, birthday: '' });
        }
    }

    const onEmailChange = (e) => {
        var email = e.target.value;
        setUser({ ...user, email: email });
        setEditUser({ ...editUser, email: email });
        if (emailRef.current) clearTimeout(emailRef.current);
        emailRef.current = setTimeout(() => {
            if (email === '') {
                setMessage({ ...message, email: "" });
                setIsEmail(true);
                return;
            }
            if (!checkEmail(email)) {
                setMessage({ ...message, email: t("Email không hợp lệ") });
                setIsEmail(false);
            } else if (email.length < 6 || email.length > 40) {
                setMessage({ ...message, email: t("Độ dài ký tự khoảng từ 6 đến 40") });
            } else {
                setMessage({ ...message, email: "" });
                setIsEmail(true);
                checkDuplicatedEmail(email);
            }
        }, 300);
    }

    const checkDuplicatedEmail = async (email) => {
        try {
            var res = await apiExists({ 'email': email });
            if (res.data === 'Found') setMessage({ ...message, email: t("Email đã tồn tại") });
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setMessage({ ...message, email: t("Đã xảy ra lỗi trong quá trình kiểm tra email") });
        }
    }

    const onAddressChange = (e) => {
        const address = e.target.value;
        setUser({ ...user, address: address });
        setEditUser({ ...editUser, address: address });
        if (address.length > 50) {
            setMessage({ ...message, address: t("Độ dài ký tự không quá 50") });
        } else {
            setMessage({ ...message, address: '' });
        }
    }

    const onDeleteFaceClick = () => {
        setUser({ ...user, faceid: '' });
        setEditUser({ ...editUser, faceid: '' });
        setImage('');
        setAnchorEl(null);
    }

    const checkFaceID = async (base64) => {
        setIsImageAdding(true);
        try {
            const source = base64.slice(base64.indexOf('base64,') + 7);
            await checkQuality({ 'user_faces': [source] });
            setPreviewFaceID('valid');
        } catch (error) {
            var text = checkFaceImage(error.response ?.status);
            setPreviewFaceID(t(text));
        }
        setIsImageAdding(false);
    }

    const checkFaceIDForUpload = async (base64) => {
        setIsImageAdding(true);
        try {
            const source = base64.slice(base64.indexOf('base64,') + 7);
            await checkQuality({ 'user_faces': [source] });
            setMessage({ ...message, avatar: '' });
        } catch (error) {
            var text = checkFaceImage(error.response ?.status);
            setMessage({ ...message, avatar: t(text) });
        }
        setIsImageAdding(false);
    }

    const handleDevices = React.useCallback(mediaDevices => {
        if (selectedCam) return;
        const results = mediaDevices.filter(({ kind }) => kind === "videoinput");
        setVideoContraints({ ...videoConstraints, deviceId: results[0] ?.deviceId});
        setDevices(results);
    }, [devices]);

    React.useEffect(() => {
        navigator.mediaDevices.enumerateDevices().then(handleDevices);
    }, [handleDevices]);

    const getDevice = () => {
        return devices.map((value, key) => {
            return <option key={key} value={value.deviceId}>{value.label}</option>
        })
    }

    const onDeviceChange = (e) => {
        setSelectedCam(true);
        setTimeout(() => {
            setVideoContraints({ ...videoConstraints, deviceId: e.target.value });
        }, 300);
    }

    const onCaptureImage = () => {
        const base64 = webRef.current.getScreenshot();
        if (typeof (base64) === 'undefined' || base64 === null) {
            setIsCapture(false);
            setImage('');
            setBase64data('');
            setMessage({ ...message, avatar: t('Vui lòng chụp ảnh khuôn mặt của bạn') });
            return;
        }
        checkFaceID(base64);
        setBase64data(base64);
        setIsPreview(true);
    }

    const onApplyCaptureImage = async () => {
        const source = base64data.slice(base64data.indexOf('base64,') + 7);
        setImage(base64data);
        setUser({ ...user, faceid: source });
        setEditUser({ ...editUser, faceid: source });
        setMessage({ ...message, avatar: t('') });
        setPreviewFaceID(null);
        setIsPreview(false);
        setIsCapture(false);
    }

    const onFaceChange = (e) => {
        const value = e.target.files[0];
        if (!checkImage(value.type)) {
            return setMessage({ ...message, avatar: t('Vui lòng tải lên hình ảnh') });
        }
        if (value.size > 1024000) {
            return setMessage({ ...message, avatar: t('Kích thước ảnh phải nhỏ hơn 1MB') });
        }
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                const source = reader.result.slice(reader.result.indexOf('base64,') + 7);
                setUser({ ...user, faceid: source });
                setEditUser({ ...editUser, faceid: source });
                setImage(reader.result);
                checkFaceIDForUpload(reader.result);
            }
        }
        reader.readAsDataURL(value);
    }

    const captureImage = () => {
        if (isCapture) {
            return (
                <div className={classes.imageDialog}>
                    <Paper style={{ width: '640px', height: '560px', borderRadius: '6px', position: 'relative' }}>
                        <div style={{ height: '480', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 0 }}>
                            <Webcam
                                audio={false}
                                ref={webRef}
                                height={480}
                                width={640}
                                screenshotFormat="image/png"
                                screenshotQuality={1}
                                videoConstraints={videoConstraints}
                            />
                            <div style={{ height: '50px', width: '100%', textAlign: 'center', marginBottom: '10px', position: 'absolute', bottom: '0', left: '0' }}>
                                <CancelButton
                                    variant="outlined"
                                    onClick={() => setIsCapture(false)}
                                    style={{ background: '#FFFFFF', margin: '10px 5px' }}
                                >
                                    {t("Hủy")}
                                </CancelButton>
                                <CreateButton
                                    variant="contained"
                                    style={{ margin: '10px 5px' }}
                                    onClick={onCaptureImage}
                                >
                                    {t("Chụp")}
                                </CreateButton>
                            </div>
                            <div style={{ position: 'absolute', top: '5px', left: '5px' }}>
                                <select
                                    style={{ height: '30px', fontSize: '16px' }}
                                    onChange={onDeviceChange}
                                >
                                    {getDevice()}
                                </select>
                            </div>
                        </div>
                    </Paper>
                </div>
            )
        }
    }

    const previewCaptureImage = () => {
        if (isPreview) {
            return (
                <div className={classes.imageDialog}>
                    <Paper style={{ width: '640px', height: '560px', borderRadius: '6px', position: 'relative' }}>
                        <div style={{ height: '100%', width: '100%' }}>
                            <div style={{ height: '480px', width: '100%' }}>
                                <img src={base64data} alt="preview" width="100%" height="100%" />
                            </div>
                            {previewFaceID !== null && previewFaceID !== 'valid' && <p style={{ color: 'red', fontSize: '16px', height: '20px' }}>{previewFaceID}</p>}
                            {previewFaceID === 'valid' && <p style={{ color: 'rgb(42, 185, 48)', fontSize: '16px', height: '20px' }}>{t('Hình ảnh hợp lệ')}</p>}
                            {(previewFaceID === 'valid' || previewFaceID === 'Khuôn mặt đã bị trùng') &&
                                <div style={{ height: '50px', width: '100%', textAlign: 'center', marginBottom: '10px' }}>
                                    <CancelButton
                                        variant="outlined"
                                        style={{ margin: '10px 5px', background: '#FFFFFF' }}
                                        disabled={isImageAdding}
                                        onClick={() => {
                                            setIsCapture(true);
                                            setIsPreview(false);
                                            setPreviewFaceID(null)
                                        }}
                                    >
                                        {t("Chụp lại")}
                                    </CancelButton>
                                    <CreateButton
                                        variant="contained"
                                        style={{ margin: '10px 5px' }}
                                        onClick={onApplyCaptureImage}
                                        disabled={isImageAdding}
                                    >
                                        {t("Áp dụng")}
                                        {isImageAdding && <CircularProgress size={25} style={{ color: '#0b59c8', position: 'absolute' }} />}
                                    </CreateButton>
                                </div>}
                            {previewFaceID !== null && previewFaceID !== 'valid' && previewFaceID !== 'Khuôn mặt đã bị trùng' && <div style={{ height: '50px', width: '100%', textAlign: 'center', marginBottom: '10px' }}>
                                <CancelButton
                                    variant="outlined"
                                    style={{ margin: '10px 5px', background: '#FFFFFF' }}
                                    disabled={isImageAdding}
                                    onClick={() => {
                                        setIsCapture(false);
                                        setIsPreview(false);
                                        setPreviewFaceID(null)
                                    }}
                                >
                                    {t("Hủy")}
                                </CancelButton>
                                <CreateButton
                                    variant="contained"
                                    style={{ margin: '10px 5px' }}
                                    onClick={() => {
                                        setIsCapture(true);
                                        setIsPreview(false);
                                        setPreviewFaceID(null)
                                    }}
                                    disabled={isImageAdding}
                                >
                                    {t("Chụp lại")}
                                    {isImageAdding && <CircularProgress size={25} style={{ color: '#0b59c8', position: 'absolute' }} />}
                                </CreateButton>
                            </div>}
                        </div>
                    </Paper>
                </div>
            )
        }
    }

    const onApartmentChange = (e, newValue) => {
        setApartments(newValue);
        setUser({ ...user, apartments: newValue });
        setEditUser({ ...editUser, apartments: newValue })
        setMessage({ ...message, apartments: '' });
    }

    const showApartmentDialog = () => {
        if (isEdit) setIsAddRoom(true);
    }

    const onSaveRoomClick = (aparts) => {
        setUser({ ...user, apartments: deepCloneArr(aparts) });
        setEditUser({ ...editUser, apartments: deepCloneArr(aparts) });
        setApartments(deepCloneArr(aparts));
        setIsAddRoom(false);
        setMessage({ ...message, apartments: '', });
    }

    const onCloseAddApartmentClick = () => {
        setIsAddRoom(false);
    }

    const onCancelSaveRoomClick = () => {
        setIsAddRoom(false);
        setApartments(deepCloneArr(user.apartments));
    }

    const onSaveClick = async () => {
        if (message.avatar !== '') return;
        if (isEmpty(user.phone) && !user.sub_account) return setMessage({ ...message, phone: t("Không được để trống") });
        if (message.phone !== '' && !user.sub_account) return;
        if (user.apartments.length === 0) return setMessage({ ...message, apartments: t("Vui lòng nhập đầy đủ thông tin căn hộ") });
        if (isEmpty(user.full_name)) return setMessage({ ...message, full_name: t("Không được để trống") });
        if (message.full_name !== '') return;
        if (user.birthday === '' || isNaN(user.birthday.getTime())) return setMessage({ ...message, birthday: t("Không được để trống") });
        if (message.birthday !== '') return;
        if (isEmpty(user.sex)) return setMessage({ ...message, sex: t("Không được để trống") });
        if (isEmpty(user.nationality)) return setMessage({ ...message, nationality: t("Không được để trống") });
        if (isEmpty(user.CMND) && age > 14) return setMessage({ ...message, CMND: t("Không được để trống") });
        if (message.CMND !== '' || message.career !== '' || message.email !== '' || message.note !== '') return;
        if (editUser.apartments) {
            var filterdApartments = editUser.apartments;
            oldApartments.forEach(value => {
                filterdApartments = filterdApartments.filter(item => item.block !== value.block || item.floor !== value.floor || item.room !== value.room)
            })
            for (var i = 0; i < filterdApartments.length; i++) {
                const data = {
                    block: filterdApartments[i].block,
                    floor: filterdApartments[i].floor,
                    room: filterdApartments[i].room,
                    role: 'host'
                }
                var res = await apiExists(data);
                if (filterdApartments[i].role === 'host' && res.data === 'Found') return setMessage({ ...message, apartments: data.block + data.floor + data.room + ' ' + t("đã có chủ hộ") });
                if (filterdApartments[i].role === 'member' && user.sub_account && res.data !== 'Found') return setMessage({ ...message, apartments: data.block + data.floor + data.room + ' ' + t("chưa có chủ hộ") });
            }
        }
        if (!user.sub_account && oldNumberPhone === '') {
            setEditUser({ ...user, time: parseInt(user.createdTime), status: 'inactive', password: md5('123456789x@X'), username: user.phone, signing_key: v5(user.phone, v5.URL), phone: user.phone.indexOf('0') === 0 ? user.phone.replace('0', '+84') : user.phone, faceid_count: 0, private_key: authenticator.generateSecret() + authenticator.generateSecret() });
        }
        if (isEmail) setIsDialog(!isDialog);
    }

    const onEditClick = () => {
        setIsEdit(!isEdit);
    }

    const onCancelClick = () => {
        setLoading(!loading);
        setIsEdit(!isEdit);
    }

    const onConfirmClick = async () => {
        try {
            if (message.email !== '') return;
            setIsUpdating(true);
            var data = {};
            if (editUser.apartments) {
                var apartmentTemp = [];
                editUser.apartments.forEach(item => {
                    apartmentTemp.push({ block: item.block, floor: item.floor, room: item.room, role: item.role });
                })
                data = { ...editUser, username: user.username, apartments: apartmentTemp }
            } else {
                data = { ...editUser, username: user.username }
            }
            if (oldFaceid !== user.faceid) data = { ...data, oldFaceid: oldFaceid };
            if (typeof (editUser.phone) !== 'undefined') data = { ...data, phone: editUser.phone.indexOf('0') === 0 ? editUser.phone.replace('0', '+84') : editUser.phone };
            var res = await apiUserupdate(data);
            if (res.data.message === 'Created successfully') setResidentUsername(res.data.newUsername);
            setMessage({ ...message, success: t('Đã cập nhật cư dân thành công!'), error: '' });
            setLoading(!loading);
            setIsEdit(false);
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setMessage({ ...message, error: t("Đã xảy ra lỗi, vui lòng thử lại!"), success: '' })
            setIsFailedDialog(!isFailedDialog);
        }
        setIsDialog(false);
        setIsUpdating(false);
    }

    const onConfirmActivateClick = async () => {
        try {
            setIsUpdating(true);
            await apiUserupdate({ username: user.username, status: 'inactive' });
            setMessage({ ...message, success: `${t("Kích hoạt thành công tài khoản")}!` });
            setLoading(!loading);
        } catch (error) {
            if (error.response ?.data === "Invalid LoginToken") return setIsExpiredSession(true);
            switch (error.response ?.status) {
                case 477: setMessage({ ...message, success: '', error: `${t("Hình ảnh không có")}!` });
                    break;
                case 480: setMessage({ ...message, success: '', error: `${t("Hình ảnh không hợp lệ")}!` });
                    break;
                case 481: setMessage({ ...message, success: '', error: `${t("Không thể phát hiện khuôn mặt")}!` });
                    break;
                case 482: setMessage({ ...message, success: '', error: `${t("Hình ảnh mờ")}!` });
                    break;
                case 483: setMessage({ ...message, success: '', error: `${t("Khuôn mặt đã bị trùng")}!` });
                    break;
                case 484: setMessage({ ...message, success: '', error: `${t("Nhiều khuôn mặt trong một hình")}!` });
                    break;
                case 486: setMessage({ ...message, success: '', error: `${t("Khuôn mặt không đủ lớn")}!` });
                    break;
                case 487: setMessage({ ...message, success: '', error: `${t("Khuôn mặt không hợp lệ")}!` });
                    break;
                default: setMessage({ ...message, success: '', error: `${t("Kích hoạt không thành công, vui lòng thử lại sau!")}` });
                    break;
            }
            setIsFailedDialog(!isFailedDialog);
        }
        setIsActivated(false);
        setIsUpdating(false);
    }

    const onConfirmDeActivateClick = async () => {
        try {
            setIsUpdating(true);
            var data = {};
            if (message.deactivate === 'force')
                data = { username: user.username, status: 'deactivated', confirm: true };
            else
                data = { username: user.username, status: 'deactivated' };
            await apiUserupdate(data);
            setMessage({ ...message, success: `${t("Vô hiệu hóa thành công tài khoản")}!`, error: `` });
            setLoading(!loading);
        } catch (error) {
            if (error.response ?.data === "Invalid LoginToken") return setIsExpiredSession(true);
            if (error.response ?.status === 465) {
                setMessage({ ...message, deactivate: 'force' });
                setIsUpdating(false);
                return;
            }
            setMessage({ ...message, success: '', error: `${t("Vô hiệu hóa không thành công, vui lòng thử lại sau!")}` });
            setIsFailedDialog(!isFailedDialog);
        }
        setIsDeActivated(false);
        setIsUpdating(false);
    }

    const onBackDialog = () => {
        setIsDialog(!isDialog);
    }

    const showConfirmDialog = () => {
        if (isEdit) {
            setOpenConfirmDialog(!openConfirmDialog)
        } else {
            onBackClick()
        }
    }

    const onFailedAgreeClick = () => {
        setIsFailedDialog(!isFailedDialog);
    }

    const onCloseImageClick = () => {
        setIsFaceID(false);
    }

    const optionsAvatarClose = () => {
        setAnchorEl(null);
    }

    const onAvatarOptionsClick = (e) => {
        setIsZoomAvatar(true);
        setAnchorEl(null);
    }

    const onFaceOptionClick = (e) => {
        if (!isEdit && isEmpty(image)) return;
        setAnchorEl(e.target);
    }

    const onClose = () => {
        setMessage({ ...message, success: '' });
    }

    useEffect(() => {
        if (message.success !== '') {
            setTimeout(() => {
                onClose();
            }, 5000);
        }
    }, [message.success])

    useEffect(() => {
        const handleClickOutside = (e => {
            if (zoomRef.current && !zoomRef.current.contains(e.target))
                setIsZoomAvatar(false);
        })
        document.addEventListener('mousedown', handleClickOutside);

    }, [zoomRef])

    const zoomAvatar = () => {
        return (
            <Dialog
                open={isZoomAvatar}
                maxWidth="xl"
            >
                <DialogContent ref={zoomRef} style={{ padding: '0' }}>
                    <img src={image} alt="" style={{ maxHeight: '500px' }} />
                </DialogContent>
            </Dialog>
        )
    }

    const getConfirmDialog = () => {
        return (
            <Dialog
                open={isDialog}
                maxWidth="xl"
            >
                <DialogTitle className={classes.dialogTitleBackground}>
                    <p className={classes.dialogTitle}>{t("Thông báo")}</p>
                </DialogTitle>
                <DialogContent style={{ width: '448px', padding: '0', position: 'relative', textAlign: 'center' }}>
                    <div className={classes.contentDialog}>
                        <p >{t("Bạn có chắc chắn muốn lưu cư dân")}<br /> <b>{user.full_name} ?</b></p>
                    </div>
                    <CancelButton variant="outlined" disabled={isUpdating} style={{ marginLeft: '30px' }} onClick={onBackDialog} >{t("Hủy")}</CancelButton>
                    <CreateButton variant="contained" disabled={isUpdating} style={{ margin: '10px 16px' }} onClick={onConfirmClick} >{t("Đồng ý")}</CreateButton>

                    {isUpdating && <CircularProgress style={{ position: 'absolute', left: '43%', top: '10%', color: '#0b59c8' }} />}
                </DialogContent>

            </Dialog>
        )
    }

    const confirmDialog = () => {
        return (
            <Confirm
                isOpen={openConfirmDialog}
                handleClose={onBackClick}
                mess={t('Bạn muốn lưu thay đổi?')}
                onConfirm={() => setOpenConfirmDialog(false)}
            />
        )
    }

    const deActivateDialog = () => {
        return (
            <Dialog
                open={isDeActivated}
                maxWidth="xl"
            >
                <DialogTitle className={classes.dialogTitleBackground}>
                    <p className={classes.dialogTitle}>{t("Thông báo")}</p>
                </DialogTitle>
                <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center', position: 'relative' }}>
                    <div className={classes.contentDialog}>
                        {message.deactivate === 'confirm' && <p >{t("Bạn có chắc chắn muốn vô hiệu hóa tài khoản")}?</p>}
                        {message.deactivate === 'force' && <p >{t("Không tìm thấy Face ID trong cơ sở dữ liêu. Bạn có muốn tiếp tục vô hiệu tài khoản này?")}</p>}
                    </div>
                    <CancelButton
                        style={{ margin: '10px 0' }}
                        onClick={() => { setIsDeActivated(!isDeActivated) }}
                        disabled={isUpdating}
                    >
                        {t("Trở về")}
                    </CancelButton>
                    <CreateButton
                        style={{ margin: '10px 0 10px 24px' }}
                        onClick={onConfirmDeActivateClick}
                        disabled={isUpdating}
                    >
                        {t("Đồng ý")}
                    </CreateButton>
                    {isUpdating && <CircularProgress color="primary" style={{ position: 'absolute', left: '45%', top: '10%', color: '#0b59c8' }} />}
                </DialogContent>
            </Dialog>
        )
    }

    const activateDialog = () => {
        return (
            <Dialog
                open={isActivated}
                maxWidth="xl"
            >
                <DialogTitle className={classes.dialogTitleBackground}>
                    <p className={classes.dialogTitle}>{t("Thông báo")}</p>
                </DialogTitle>
                <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center', position: 'relative' }}>
                    <div className={classes.contentDialog}>
                        <p>{t("Bạn có chắc chắn muốn kích hoạt lại tài khoản")}?</p>
                    </div>
                    <CancelButton
                        style={{ margin: '10px 0' }}
                        onClick={() => { setIsActivated(!isActivated) }}
                        disabled={isUpdating}
                    >
                        {t("Trở về")}
                    </CancelButton>
                    <CreateButton
                        style={{ margin: '10px 0 10px 24px' }}
                        onClick={onConfirmActivateClick}
                        disabled={isUpdating}
                    >
                        {t("Đồng ý")}
                    </CreateButton>
                    {isUpdating && <CircularProgress color="primary" style={{ position: 'absolute', left: '45%', top: '10%', color: '#0b59c8' }} />}
                </DialogContent>
            </Dialog>
        )
    }

    const getFailedDialog = () => {
        return (
            <Dialog
                open={isFailedDialog && loading}
                maxWidth="xl"
            >
                <DialogTitle className={classes.errorDialogTitleBackground}>
                    <p className={classes.dialogTitle}>{t("Thông báo")}</p>
                </DialogTitle>
                <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center' }}>
                    <p className={classes.contentDialog}>{message.error}</p>
                    <ErrorBtn variant="outlined" style={{ margin: '10px 0' }} onClick={onFailedAgreeClick}>{t("Đồng ý")}</ErrorBtn>
                </DialogContent>

            </Dialog>
        )
    }

    const showRegisteredFaceID = () => {
        if (user.faceID === null)
            return (
                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#7A7171', fontSize: '16px' }}>
                    {t("Tài khoản đã bị vô hiệu")}
                </div>
            )
        if (user.faceID !== '')
            return (
                <img src={user.faceID} alt="" width='100%' height='100%' style={{ position: 'absolute', top: '0', left: '0', zIndex: '1' }} />
            )
        return (
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#7A7171', fontSize: '16px' }}>
                {t("Chưa đăng ký khuôn mặt")}
            </div>
        )

    }

    const getRegisteredFaceID = () => {
        return (
            <Dialog open={isFaceID} maxWidth="lg" >
                <DialogContent style={{ textAlign: 'right', width: '480px', height: '640px' }}>
                    <IconButton aria-label="close image" style={{ position: 'absolute', top: '0', right: '0', zIndex: '2' }} onClick={onCloseImageClick} className={classes.closeButton}>
                        <CloseIcon />
                    </IconButton>
                    {showRegisteredFaceID()}
                </DialogContent>
            </Dialog>
        )
    }

    const getFaceIdMenu = () => {
        switch (isEdit) {
            case true:
                if (!isEmpty(image)) {
                    if (removeFaceID === PaymentConfiguration.SHOW) {
                        return (
                            <div>
                                <MenuItem onClick={onAvatarOptionsClick}>
                                    {t('Xem ảnh')}
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setIsCapture(true);
                                        setAnchorEl(null);
                                    }}
                                >
                                    <label htmlFor="icon-button-file" style={{ position: 'relative', top: '5px' }}>{t('Chụp hình')}</label>
                                </MenuItem>
                                <MenuItem onClick={optionsAvatarClose}>
                                    <label htmlFor="contained-button-file" className={classes.uploadStyle}>
                                        <input accept="image/*" id="contained-button-file" multiple type="file" className={classes.hidden} onChange={onFaceChange} />
                                        {t('Tải hình lên')}
                                    </label>
                                </MenuItem>
                                <MenuItem onClick={onDeleteFaceClick}>
                                    {t('Xóa ảnh')}
                                </MenuItem>
                            </div>
                        )
                    } else if (removeFaceID === PaymentConfiguration.HIDE) {
                        return (
                            <div>
                                <MenuItem onClick={onAvatarOptionsClick}>
                                    {t('Xem ảnh')}
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setIsCapture(true);
                                        setAnchorEl(null);
                                    }}
                                >
                                    <label htmlFor="icon-button-file" style={{ position: 'relative', top: '5px' }}>{t('Chụp hình')}</label>
                                </MenuItem>
                                <MenuItem onClick={optionsAvatarClose}>
                                    <label htmlFor="contained-button-file" className={classes.uploadStyle}>
                                        <input accept="image/*" id="contained-button-file" multiple type="file" className={classes.hidden} onChange={onFaceChange} />
                                        {t('Tải hình lên')}
                                    </label>
                                </MenuItem>
                            </div>
                        )
                    }
                } else {
                    return (
                        <div>
                            <MenuItem
                                onClick={() => {
                                    setIsCapture(true);
                                    setAnchorEl(null);
                                }}
                            >
                                <label htmlFor="icon-button-file" style={{ position: 'relative', top: '5px' }}>{t('Chụp hình')}</label>
                            </MenuItem>
                            <MenuItem onClick={optionsAvatarClose}>
                                <label htmlFor="contained-button-file" className={classes.uploadStyle}>
                                    <input accept="image/*" id="contained-button-file" multiple type="file" className={classes.hidden} onChange={onFaceChange} />
                                    {t('Tải hình lên')}
                                </label>
                            </MenuItem>
                        </div>
                    )
                }
                break;
            default:
                if (!isEmpty(image)) {
                    return (
                        <MenuItem onClick={onAvatarOptionsClick}>
                            {t('Xem ảnh')}
                        </MenuItem>
                    )
                }
                break;
        }
    }

    return (
        <div className={clsx(classes.contentBottomForm, classes.focus)}>
            {zoomAvatar()}
            {getRegisteredFaceID()}
            {getConfirmDialog()}
            {getFailedDialog()}
            {captureImage()}
            {previewCaptureImage()}
            {deActivateDialog()}
            {activateDialog()}
            <BlockFloorRoomRole
                title={t('Thông tin căn hộ')}
                isChooseApartment={isAddRoom}
                closeDialog={onCloseAddApartmentClick}
                roomData={apartments}
                onCancelClick={onCancelSaveRoomClick}
                onSaveClick={(aparts) => onSaveRoomClick(aparts)}
                apartmentData={apartmentData}
                subAccount={user.sub_account}
                CreateButton={CreateButton}
                CancelButton={CancelButton}
            />
            {confirmDialog()}
            <SuccessAlert message={message.success} onCloseDialog={onClose} />
            {title && <div style={{ paddingBottom: '8px', marginTop: '15px', position: 'relative', left: '-9px' }}>
                <IconButton style={{ float: 'left', padding: '2px', marginRight: '5px' }} onClick={() => showConfirmDialog()} >
                    <ArrowBackIosOutlinedIcon className={classes.primaryColor} style={{ width: '28px', height: '28px' }} />
                </IconButton>
                <p className={classes.secondaryTitle}>{t("Chi tiết cư dân")}</p>
            </div>}
            {!loading && message.request === '' && <CircularProgress color="primary" style={{ position: 'absolute', top: '50%', left: '50%', color: '#0b59c8' }} />}
            {message.request !== '' && <div className={classes.requestError}>{message.request}</div>}
            {loading && message.request === '' &&
                <div className={classes.part_form}>
                    <div style={{ width: '30%' }}>
                        <p className={classes.base_info_title}>{t("Thông tin tài khoản")}</p>
                        <div style={{ display: 'flex' }}>
                            <div className={classes.avatar_control}>
                                <label className={classes.label}>{t("Face ID")}</label><br />
                                <div className={classes.avatar_logo} onClick={onFaceOptionClick} style={{ position: 'relative' }} >
                                    {isEmpty(image) && <img src={window.location.origin + '/images/User.svg'} width="100%" height="85%" alt="Face ID" />}
                                    {!isEmpty(image) && <img src={image} style={{ objectFit: 'cover' }} width="100%" height="100%" alt="Face ID" />}
                                    {isEmpty(image) && <p style={{ position: 'absolute', bottom: '0', width: '80px', textAlign: 'center', color: '#000000' }}>{t('Chụp hình')}</p>}
                                </div>
                                <label className={classes.fileBtn} >
                                    {isEdit && <IconButton size="small" aria-label="upload picture" component="span" onClick={(e) => setIsCapture(true)}>
                                        <PhotoCamera style={{ color: '#565e67', fontSize: '40px' }} />
                                    </IconButton>}
                                </label>
                                <Menu
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={optionsAvatarClose}
                                >
                                    {getFaceIdMenu()}
                                </Menu>
                                <p style={{ color: 'red', width: '300%' }}>{message.avatar}</p>
                            </div>
                            <div className={classes.label} style={{ padding: '24px 4% 0 20px', width: '100%' }} >
                                <p>{user.action === 'approve' ? t('Được duyệt bởi') : t('Được tạo bởi')}:</p>
                                <p style={{ textAlign: 'right', paddingBottom: '25px' }}> {user.approver ? user.approver : t('Không có thông tin')}</p>
                                <p> {user.action === 'approve' ? t('Được duyệt vào') : t('Được tạo vào')}:</p>
                                <p style={{ textAlign: 'right' }}>{typeof (user.approvedTime) !== 'undefined' ? moment(user.approvedTime).format('DD-MM-YYYY') : t('Không có thông tin')} </p>
                            </div>
                        </div>
                        <div className={classes.inputBlock} style={{ height: '40px', minWidth: '380px', paddingTop: '9px', marginBottom: '5px' }}>
                            <RadioGroup className={classes.formControlLabel} value={user.sub_account} onChange={onMainAccountChange} row style={{ marginLeft: '16px', height: '40px', paddingTop: '5px' }} >
                                <FormControlLabel value={false} control={<Radio disabled={!isEdit} color="primary" classes={{ root: classes.radio, checked: classes.checked }} size="small" />} label={t('Tài khoản chính')} />
                                <FormControlLabel
                                    value={true}
                                    control={<Radio
                                        disabled={!isEdit}
                                        color="primary"
                                        classes={{ root: classes.radio, checked: classes.checked }}
                                        size="small" />}
                                    label={
                                        <LargeTooltip
                                            title={<div>
                                                {t('Tài khoản phụ KHÔNG thể')}: <br />
                                                - {t('Đăng nhập')} <br />
                                                - {t('Sử dụng cuộc gọi video')} <br />
                                                - {t('Sử dụng mã QR')} <br />
                                            </div>}
                                        >
                                            <span>
                                                {t('Tài khoản phụ')}
                                                <HelpOutlineIcon style={{ fontSize: '16px', marginLeft: '5px' }} />
                                            </span>
                                        </LargeTooltip>
                                    }
                                />
                            </RadioGroup>
                        </div>
                        <div className={classes.inputBlock}>
                            <label className={classes.label}>{t("Số điện thoại")} <span style={{ color: 'red' }}>(*)</span></label><br />
                            <TextField
                                classes={{
                                    root: classes.input
                                }}
                                disabled={!isEdit || user.sub_account || oldNumberPhone !== ''}
                                value={user.phone}
                                size="small"
                                placeholder={t("Số điện thoại")}
                                variant="outlined"
                                onChange={onPhoneChange}
                                helperText={message.phone}
                                error={message.phone !== ''}
                            />
                        </div>
                        <p className={classes.base_info_title} style={{ paddingTop: '0' }}>{t("Thông tin tòa nhà")}</p>
                        <div className={classes.inputRightBlock} style={{ width: '96%', float: 'left', paddingLeft: '4%', height: 'auto' }}>
                            <label className={classes.label} style={{ marginBottom: '0' }}>{t('Căn hộ')}</label>
                            <MultipleAutocomplete
                                multiple
                                classes={{ inputRoot: classes.autocompleteStyle }}
                                id="tags-outlined"
                                open={false}
                                options={user.apartments}
                                size="small"
                                getOptionLabel={(option) => {
                                    let mess = option.block + option.floor + option.room + ' - ' + `${option.role === 'member' ? t('Người trong gia đình') : t('Chủ hộ')}`;
                                    return mess;
                                }}
                                value={user.apartments}
                                disabled={!isEdit}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        disabled
                                        onClick={showApartmentDialog}
                                        helperText={message.apartments}
                                        error={message.apartments !== ''}
                                    />
                                )}
                                onChange={(e, newValue) => onApartmentChange(e, newValue)}
                            />
                        </div>
                    </div>
                    <div style={{ width: '70%' }}>
                        <div className={clsx(classes.base_info_title, classes.height72)} style={{ paddingLeft: '2%', marginLeft: '0', width: '96%' }}>
                            {t("Thông tin cá nhân")}
                            {user.residenceStatus === 'deactivated' && <CreateButton
                                variant="contained"
                                style={{ float: 'right', position: 'relative', top: '-5px' }}
                                onClick={() => setIsActivated(true)}
                                disabled={isEdit}
                            >
                                {t('Kích hoạt')}
                            </CreateButton>}
                            {user.residenceStatus !== 'deactivated' && <DeActButton
                                variant="contained"
                                style={{ float: 'right', position: 'relative', top: '-5px' }}
                                onClick={() => {
                                    setIsDeActivated(true);
                                    setMessage({ ...message, deactivate: 'confirm' })
                                }}
                                disabled={isEdit}
                            >
                                {t('Vô hiệu hóa')}
                            </DeActButton>}
                        </div>
                        <div className={classes.inputRightBlock}>
                            <label className={classes.label}>{t("Họ và tên")} <span style={{ color: 'red' }}>(*)</span></label><br />
                            <TextField
                                classes={{
                                    root: classes.input
                                }}
                                disabled={!isEdit}
                                value={user.full_name}
                                variant="outlined"
                                size="small"
                                placeholder={t("Họ và tên")}
                                onChange={onFullNameChange}
                                error={message.full_name.length > 0}
                                helperText={message.full_name}
                            />
                        </div>
                        <div className={clsx(classes.inputRightBlock, classes.date)}>
                            <label className={classes.label} style={{ marginBottom: '0' }}>{t("Ngày tháng năm sinh")} <span style={{ color: 'red' }}>(*)</span></label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === 'vi' ? vi : ''} >
                                <KeyboardDatePicker
                                    inputVariant="outlined"
                                    style={{ position: 'relative', top: '-16px', width: '100%' }}
                                    placeholder={t("Ngày/Tháng/Năm")}
                                    size="small"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    value={user.birthday}
                                    onChange={onDateChange}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date"
                                    }}
                                    disabled={!isEdit}
                                    error={message.birthday !== ''}
                                    helperText={message.birthday}
                                    cancelLabel={t("Đóng")}
                                    okLabel={t("Đồng ý")}
                                    views={["date", "month", "year"]}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className={clsx(classes.select, classes.inputRightBlock)}>
                            <FormControl required variant="outlined" classes={{ root: classes.input }}>
                                <label className={classes.label}>{t("Giới tính")} <span style={{ color: 'red' }}>(*)</span></label>
                                <Select
                                    value={user.sex}
                                    style={{ height: '40px' }}
                                    name="sex"
                                    displayEmpty
                                    onChange={onChange}
                                    disabled={!isEdit}
                                >
                                    <MenuItem value='male'>{t('Nam')}</MenuItem>
                                    <MenuItem value='female'>{t('Nữ')}</MenuItem>
                                    <MenuItem value='other'>{t('Khác')}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className={classes.inputRightBlock}>
                            <label className={classes.label} style={{ marginBottom: '0' }}>{t("Quốc tịch")} <span style={{ color: 'red' }}>(*)</span></label>
                            <Autocomplete
                                disabled={!isEdit}
                                value={user.nationality}
                                classes={{
                                    root: classes.input
                                }}
                                style={{ height: '40px' }}
                                size="small"
                                clearText={t("Xóa")}
                                noOptionsText={t("Không có dữ liệu")}
                                openText={t("Mở")}
                                getOptionSelected={(option, value) => {
                                    return true;
                                }}
                                options={nationalities}
                                renderInput={(params) => <TextField {...params} placeholder={t("Quốc tịch")} variant="outlined" error={message.nationality !== ''} helperText={message.nationality} />}
                                onChange={(e, newValue) => onNationalityChange(e, newValue)}
                            />

                        </div>
                        <div className={classes.inputRightBlock}>
                            <label className={classes.label}>{t("CMND/CCCD/Passport")} {age > 14 && <span style={{ color: 'red' }}>(*)</span>}</label><br />
                            <TextField
                                classes={{
                                    root: classes.input
                                }}
                                disabled={!isEdit}
                                value={user.CMND}
                                size="small"
                                placeholder={t("CMND/CCCD/Passport")}
                                variant="outlined"
                                onChange={onCMNDChange}
                                error={message.CMND !== ''}
                                helperText={message.CMND}
                            />
                        </div>
                        <div className={classes.inputRightBlock}>
                            <label className={classes.label}>{t("Nơi cấp")}</label>
                            <TextField
                                classes={{
                                    root: classes.input
                                }}
                                disabled={!isEdit || user.CMND === ''}
                                value={user.issued_place}
                                size="small"
                                placeholder={t("Nơi cấp")}
                                variant="outlined"
                                onChange={onIssuedPlaceChange}
                                error={message.issued_place !== ''}
                                helperText={message.issued_place}
                            />
                        </div>
                        <div className={classes.label} style={{ width: '95%', marginLeft: '2%', paddingBottom: '1px' }}>{t("Địa chỉ thường trú")}</div>
                        <div className={classes.inputRightBlock} style={{ height: '78px' }} >
                            <label className={classes.label}>{t("Tỉnh/ Thành phố")}</label>
                            <Autocomplete
                                disabled={!isEdit}
                                value={user.province}
                                style={{ height: '40px' }}
                                size="small"
                                clearText={t("Xóa")}
                                noOptionsText={t("Không có dữ liệu")}
                                openText={t("Mở")}
                                getOptionSelected={(option, value) => {
                                    return true;
                                }}
                                options={provinceNames}
                                renderInput={(params) => <TextField {...params} placeholder={`< ${t('Chọn')} >`} variant="outlined" error={message.province !== ''} helperText={message.province} />}
                                onChange={(e, newValue) => onProvinceChange(e, newValue)}
                            />
                        </div>
                        <div className={classes.inputRightBlock} style={{ height: '78px' }}>
                            <label className={classes.label}>{t("Huyện/ Quận")}</label>
                            <Autocomplete
                                disabled={!isEdit}
                                value={user.district}
                                classes={{
                                    root: classes.input
                                }}
                                style={{ height: '40px' }}
                                size="small"
                                clearText={t("Xóa")}
                                noOptionsText={t("Không có dữ liệu")}
                                openText={t("Mở")}
                                getOptionSelected={(option, value) => {
                                    return true;
                                }}
                                options={districtNames}
                                renderInput={(params) => <TextField {...params} placeholder={`< ${t('Chọn')} >`} variant="outlined" error={message.district !== ''} helperText={message.district} />}
                                onChange={(e, newValue) => onDistrictChange(e, newValue)}
                            />
                        </div>
                        <div className={classes.inputRightBlock} style={{ height: '78px' }}>
                            <label className={classes.label} >{t("Xã/ Phường")}</label>
                            <Autocomplete
                                disabled={!isEdit}
                                value={user.ward}
                                classes={{
                                    root: classes.input
                                }}
                                style={{ height: '40px' }}
                                size="small"
                                clearText={t("Xóa")}
                                noOptionsText={t("Không có dữ liệu")}
                                openText={t("Mở")}
                                getOptionSelected={(option, value) => {
                                    return true;
                                }}
                                options={wardNames}
                                renderInput={(params) => <TextField {...params} placeholder={`< ${t('Chọn')} >`} variant="outlined" error={message.ward !== ''} helperText={message.ward} />}
                                onChange={(e, newValue) => onWardChange(e, newValue)}
                            />
                        </div>
                        <div style={{ clear: 'both', height: '29px', width: '100%' }}></div>
                        <div className={classes.inputRightBlock} style={{ width: '96%' }}>
                            <label className={classes.label}>{t("Địa chỉ")}</label><br />
                            <TextField
                                classes={{
                                    root: classes.input
                                }}
                                inputProps={{
                                    autoComplete: 'off'
                                }}
                                disabled={!isEdit}
                                value={user.address}
                                style={{ height: '40px' }}
                                name="address"
                                placeholder={t("Địa chỉ")}
                                variant="outlined"
                                size="small"
                                onChange={onAddressChange}
                                error={message.address.length > 0}
                                helperText={message.address}
                            />
                        </div>
                        <div className={classes.inputRightBlock}>
                            <label className={classes.label}>{t("Nghề nghiệp")} </label><br />
                            <TextField
                                classes={{
                                    root: classes.input
                                }}
                                value={user.career}
                                disabled={!isEdit}
                                variant="outlined"
                                size="small"
                                placeholder={t("Nghề nghiệp")}
                                onChange={onCareerChange}
                                error={message.career !== ''}
                                helperText={message.career}
                            />
                        </div>

                        <div className={classes.inputRightBlock}>
                            <label className={classes.label}>Email </label><br />
                            <TextField
                                classes={{
                                    root: classes.input
                                }}
                                disabled={!isEdit}
                                value={user.email}
                                size="small"
                                placeholder="Email"
                                variant="outlined"
                                onChange={onEmailChange}
                                error={message.email.length > 0}
                                helperText={message.email}
                            />
                        </div>
                        <div className={classes.inputRightBlock}>
                            <label className={classes.label}>{t("Ghi chú")}</label><br />
                            <TextField
                                classes={{
                                    root: classes.input
                                }}
                                value={user.note}
                                disabled={!isEdit}
                                size="small"
                                placeholder={t("Ghi chú")}
                                variant="outlined"
                                onChange={onNoteChange}
                                error={message.note !== ''}
                                helperText={message.note}
                            />
                        </div>
                        <div className={classes.inputRightBlock} style={{ width: '96%', textAlign: 'right', height: '50px' }}>
                            {isEdit &&
                                <div>
                                    <CancelButton onClick={onCancelClick} variant="outlined">{t("Hủy")}</CancelButton>
                                    <CreateButton onClick={onSaveClick} variant="contained" >{t("Lưu")}</CreateButton>
                                </div>}

                            {!isEdit && !title &&
                                <div>
                                    <CancelButton onClick={onBackClick} variant="outlined">{t("Trở về")}</CancelButton>
                                    <CreateButton onClick={onEditClick} variant="contained" >{t("Chỉnh sửa")}</CreateButton>
                                </div>}
                            {!isEdit && title && user.residenceStatus !== 'deactivated' &&
                                <div>
                                    <CreateButton onClick={onEditClick} variant="contained" >{t("Chỉnh sửa")}</CreateButton>
                                </div>}
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default ResidenceInfo;




