import React, { useState, useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button/Button';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import TextField from '@material-ui/core/TextField/TextField';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import IconButton from '@material-ui/core/IconButton/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { Link, useHistory } from 'react-router-dom';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import clsx from 'clsx';
import Camera from './Camera';
import moment from 'moment-timezone';
import ResidentDetailInfo from '../../residences/approval_history/ResidentDetailInfo';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuUI from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { switchCase } from '@babel/types';
import { apiSmartcameraDevice } from '../../../api/smartcamera';
const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: '14px',
        lineHeight: '140%',
        color: '#000000',
        display: 'inline-block',
        paddingTop: '10px'
    },
    content: {
        width: '98%',
        margin: 'auto',
        borderRadius: '6px',
        display: 'flex',
        flexWarp: 'wrap'
    },
    primaryColor: theme.primaryTextColor,
    dialogTitleBackground: theme.primaryColor,
    dialogTitle: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '25px',
        color: '#FFFFFF',
    },
    contentDialog: {
        fontSize: '16px',
        height: '68px',
        borderBottom: '1px solid #EEEDF2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    dialogFailedTitleBackground: theme.errorColor,
    overlay: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        background: 'rgba(0,0,0,0.1)',
        zIndex: '1300'
    },
    secondaryTitle: theme.secondary_title,
    focus: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: theme.themeColor,
            },
        },
    },
    textFeild: {
        fontWeight: 'bold',
        fontSize: '30px'
    },
    primaryTitle: theme.primary_title,
    topBlock: {
        height: '80px',
        width: '98%',
        marginLeft: '16px',
        display: 'flex',
        alignItems: 'center'
    },
    cameraPageStyle: {
        position: 'relative',
        paddingBottom: '12px',
        height: '100%'
    },
    title: {
        fontSize: '22px',
        fontWeight: 'bold',
        padding: '23px 0 20px 0',
        width: '98%',
        margin: 'auto'
    },
    viewFaceStyle: {
        height: '90%',
        overflowY: 'scroll'
    },
    selectStyle: {
        position: 'absolute',
        top: '5px',
        left: '5px',
        padding: '5px',
        borderRadius: '6px',
        borderColor: '#80808038'
    },
    cameraSection: {
        width: '50%',
        height: '65vh',
        position: 'relative'
    },
    cameraNameStyle: {
        fontSize: '20px',
        fontWeight: 'bold',
        padding: '5px 0'
    },
    moreButtonStyle: {
        padding: '5px'
    },
    detectFaceSection: {
        width: '48%',
        height: '65vh',
        marginLeft: '2%'
    },
    detectFaceBlock: {
        width: '100%',
        height: '100%',
        border: '1px solid #80808038'
    },
    detectFaceView: {
        height: '10%',
        background: '#FFFFFF',
        fontSize: '20px',
        padding: '0 10px',
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #80808038'
    }
}))

const CreateButton = withStyles(theme => ({ root: theme.create_button, }))(Button);
const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);
const ErrorButton = withStyles(theme => ({ root: theme.error_button }))(Button);

const SmartCamera = React.memo(props => {
    const classes = useStyles();
    const [hisotires, setHistories] = useState([]);
    const [cameras, setCameras] = useState([]);
    const [currentCamera, setCurrentCamera] = useState('');
    const [loading, setLoading] = useState(false);
    const [isUpdateData, setIsUpdateData] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [selectedUsername, setSelectedUsername] = useState('');
    const [isZoom, setIsZoom] = useState(false);
    const [isDetail, setIsDetail] = useState(false);
    const [anchorEl, setAnchorEl] = useState(false);
    const [isExpiredSession, setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const zoomRef = useRef();
    const [client, setClient] = useState(null);
    const [ws, setWs] = useState(null);
    const wsRef = useRef();

    const getFaceDetection = () => {
        const faceDetection = localStorage.getItem('faceDetection');
        const data = faceDetection !== null ? JSON.parse(faceDetection) : [];
        setHistories(data);
        setLoading(false);
    }

    const webSocketConnect = () => {
        var websocket;
        if (process.env.REACT_APP_DEPLOY_ENV === 'aws')
            websocket = new WebSocket(`${process.env.REACT_APP_ClOUD_WEBSOCKET}${process.env.REACT_APP_NODE_ENV === 'development' ? 'dev' : process.env.REACT_APP_ClOUD_WEBSOCKET}`);
        else
            websocket = new WebSocket(process.env.REACT_APP_OP_WEBSOCKET);
        websocket.onopen = () => {
            console.log('WebSocket Client Connected');
            if (websocket.readyState === websocket.OPEN) {
                websocket.send(JSON.stringify({ "type": "REGISTER", "name": "webApplication", "username": "tsmart-building" }));
            }
        };
        setWs(websocket);
    }

    useEffect(() => {
        if (!loading) getFaceDetection();
        if (ws) {
            try {
                ws.onerror = (e) => { console.log(e) };
                ws.onmessage = function (event) {
                    const data = hisotires;
                    const res = JSON.parse(event.data);
                    if (res.operator && res.operator === 'RecPush') {
                        const notification = { Face: res.info.pic, FullName: res.info.fullname ? res.info.fullname : '', Time: moment(res.info.time).format('DD-MM-YYYY HH:mm:ss'), Username: res.info.username };
                        const exists = data.filter(item => item.Time === notification.Time);
                        if (exists.length === 0) {
                            data.push(notification);
                            data.sort((a, b) => a.Time > b.Time ? -1 : a.Time < b.Time ? 1 : 0);
                            setHistories(data);
                            localStorage.setItem('faceDetection', JSON.stringify(data));
                            setIsUpdateData(!isUpdateData);
                        }
                    }
                };
                ws.close = () => { console.log('connection closed!') };
            } catch (err) {
                console.log(err);
            }
        } else {
            webSocketConnect();
        }
    }, [ws, isUpdateData])

    useEffect(() => {
        if (isExpiredSession) {
            history.push('/login');
            dispatch({ type: 'IS_LOGIN_AGAIN_TRUE' });
        }
    }, [isExpiredSession]);

    const getDefaultValues = async () => {
        try {
            const res = await apiSmartcameraDevice();
            setCameras(res.data.Items);
            if (res.data.Items.length > 0) setCurrentCamera(res.data.Items[0]);
        } catch (error) {
            if (error.response ?.data === "Invalid LoginToken") return setIsExpiredSession(true);
        }
    }

    useEffect(() => {
        getDefaultValues();
    }, [])

    const onEditClick = (username) => {
        history.replace(`/admin/smart-camera/live/resident?username=${username}`)
        setIsDetail(true);
        setSelectedUsername(username);
    }

    const onBackClick = () => {
        setIsDetail(false);
    }

    const onCameraChange = (e) => {
        const id = e.target.value;
        const res = cameras.filter(item => item.ID === parseInt(id));
        if (res[0]) setCurrentCamera(res[0]);
    }

    const getDetectFaces = () => {
        return hisotires.map((value, key) => {
            return (
                <div key={key} style={{ padding: '0 10px', margin: '2px 0', fontSize: '16px', height: '50px', display: 'flex', alignItems: 'center' }}>
                    <img src={`data:image/jpeg;base64,${value.Face}`} alt="" style={{ height: '50px', height: '50px', borderRadius: '50%', cursor: 'pointer' }} onClick={() => onImageClick(value.Face)} />
                    <p style={{ padding: '0 10px' }}><b style={{ cursor: 'pointer', color: '#0b59c8', fontStyle: 'italic' }} onClick={() => onEditClick(value.Username)}>{value.FullName}</b> vào lúc {value.Time}</p>
                </div>
            )
        })
    }

    const getCameraList = () => {
        return cameras.map((value, key) => {
            return <option key={key} value={value.ID} >{value.CameraName}</option>
        })
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const onDeleteFaceDectionClick = () => {
        localStorage.removeItem('faceDetection');
        setHistories([]);
        setIsUpdateData(!isUpdateData);
        setAnchorEl(null);
    }

    const onImageClick = (face) => {
        setIsZoom(true);
        setSelectedImage(face);
    }

    useEffect(() => {
        const handleClickOutside = (e => {
            if (zoomRef.current && !zoomRef.current.contains(e.target))
                setIsZoom(false);
        })
        document.addEventListener('mousedown', handleClickOutside);
    }, [zoomRef])

    const zoomImage = () => {
        return (
            <Dialog
                open={isZoom}
                maxWidth="xl"
            >
                <DialogContent ref={zoomRef} style={{ padding: '0' }}>
                    <img src={`data:image/jpeg;base64,${selectedImage}`} style={{ maxHeight: '500px', minHeight: '300px' }} />
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <div className={clsx(classes.focus, classes.cameraPageStyle)}>
            {zoomImage()}
            {!isDetail &&
                <p className={classes.title}>
                    {t('Smart camera live')}
                </p>}
            {!isDetail &&
                <div className={classes.content}>
                    <div className={classes.cameraSection}>
                        <Camera camera={currentCamera.RtspLink} />
                        <p className={classes.cameraNameStyle}>
                            {currentCamera.CameraName}
                        </p>
                        <select
                            className={classes.selectStyle}
                            onChange={onCameraChange}
                            value={currentCamera.ID}
                        >
                            {getCameraList()}
                        </select>
                    </div>
                    <div className={classes.detectFaceSection}>
                        <div className={classes.detectFaceBlock}>
                            <div className={classes.detectFaceView} >
                                <p style={{ flex: 1 }}>{t('Phát hiện khuôn mặt')}</p>
                                <IconButton
                                    className={classes.moreButtonStyle}
                                    onClick={(e) => setAnchorEl(e.target)}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <MenuUI
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <MenuList>
                                        <MenuItem onClick={onDeleteFaceDectionClick}>
                                            {t("Xóa tất cả")}
                                        </MenuItem>
                                    </MenuList>
                                </MenuUI>
                            </div>
                            <div className={classes.viewFaceStyle}>
                                {getDetectFaces()}
                            </div>
                        </div>
                    </div>
                </div>}
        </div>
    )
})


export default SmartCamera;


