import withStyles from '@material-ui/styles/withStyles/withStyles';
import Button from '@material-ui/core/Button/Button';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const MakeButton = withStyles(theme => ({ root: theme.delete_button }))(Button);

export const DeleteButton = ({ children, ...props }) => {
    return (
        <MakeButton
            {...props}
            variant="outlined"
        >
            <div style={{
                display: "flex",
                alignItems: "center"
            }} >
                <DeleteOutlineIcon
                    fontSize="small"
                    style={{
                        marginRight: 8
                    }}
                />
                <span>{children}</span>
            </div>
        </MakeButton>
    )
}