import React,{useState,useEffect} from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useTranslation} from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button/Button';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import axiosClient from '../../../config/AxiosClient';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import {useHistory } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import moment from 'moment-timezone';
import clsx from 'clsx';
import {MuiPickersUtilsProvider,TimePicker} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import vi from 'date-fns/locale/vi';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme)=>({
    inputBlock:{height:'70px',width:'100%',position:'relative'},
    label:{margin:'0',fontSize: '14px',lineHeight: '140%',color: '#000000',marginRight:'5px'},
    dialogTitle:{textAlign:'center',fontSize:'20px',fontWeight:'600',lineHeight:'25px',color:'#FFFFFF'},
    contentDialog:{fontSize:'16px',height:'68px',borderBottom:'1px solid #EEEDF2',display:'flex',alignItems:'center',justifyContent:'center'},
    dialogTitleBackground:theme.primaryColor,
    errorColor:theme.errorColor,
    select:{
        '& .MuiOutlinedInput-input':{padding:'6px 12px'}
    },
    focus:{
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': { 
                borderColor: theme.themeColor,
            },
        },
    },
    checkbox: {
        '&$checked': {
          color: theme.menuBackground,
        },
    },
    checked: {},
    
}))

const CreateButton = withStyles(theme =>({root:theme.create_button}))(Button);
const CancelButton = withStyles(theme =>({root:theme.cancel_button}))(Button);

const Backup = (props) =>{
    const classes = useStyles();
    const {scheduleBackup,onBackupClick,isBackingup,isScheduling} = props;
    const {t,i18n} = useTranslation();
    const [type,setType] = useState(false);
    const [alert,setAlert] = useState({time:'',file_name:'',info:'',password:''});
    const [isExpiredSession,setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const today = new Date();
    const [time,setTime] = useState({hour:'*',min:'*',day:'*',month:'*',year:'*',date:'*'});
    const [backupType,setBackupType] = useState('daily');
    const [displayTime,setDisplayTime] = useState(null);
    const [error,setError] = useState('');
    const [isEdit,setIsEdit] = useState(false);
    const [loading,setLoading] = useState(false);
    const [showPassword,setShowPassword] = useState(false);
    const [password,setPassword] = useState('');
    const [oldHour,setOldHour] = useState('');
    const [displayDay,setDisplayDay] = useState([]);

    useEffect(()=>{
        if(isExpiredSession){
          history.push('/login');
          dispatch({type:'IS_LOGIN_AGAIN_TRUE'});
        } 
    },[isExpiredSession]);

    useEffect(()=>{
        const data = [];
        if(i18n.language==='vi') for(var day=1; day< 29; day++) data.push(day);
        else{
            for(var day=1; day< 29; day++){
                if(day===1 || day===21 ){
                    data.push(day+'st');
                }else if(day===2 || day===22){
                    data.push(day+'nd');
                }else if(day===3 || day===23){
                    data.push(day+'rd');
                }else{
                    data.push(day+'th');
                }
            } 
        }
        setDisplayDay(data);
    },[i18n.language])
    const getDay = () =>{
        return displayDay.map(value=>{
            return <MenuItem key={value} value={parseInt(value)} >{value}</MenuItem>
        })  
    }
    const getSchedule = async() =>{
        try {
            const res = await axiosClient.get('/table/export/schedule/get',{
                headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
            })
            setTime({hour:(parseInt(res.data.hour)+7)%24,min:res.data.min,day:res.data.day,month:res.data.month,year:res.data.year,date:res.data.date});
            if(res.data.hour === '*' && res.data.min === '*' ){
                setBackupType('daily');
            }else if(res.data.date !== '*' && res.data.date !=='?'){
                setBackupType('weekly');
            }else if(res.data.day !== '*' && res.data.day !=='?'){
                setBackupType('monthly');
            }else{
                setBackupType('daily');
            } 
            today.setHours((parseInt(res.data.hour)+7)%24);
            var handleTime = today.setMinutes(res.data.min);
            setDisplayTime(handleTime);
            setOldHour(moment(handleTime).format('HH'));
        } catch (err) {
            if (typeof(err.response)!=='undefined' && err.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
        }     
        setLoading(true);
        setIsEdit(false);   
    }

    useEffect(()=>{
        if(!loading) getSchedule();
    },[loading])
    
    const onDateChange = (e) =>{
        setTime({...time,date:e.target.value})
    }
    const onBackupTypeChange = (e)=>{
        setBackupType(e.target.value);
    }
    const onDayChange = (e)=>{
        setTime({...time,day:e.target.value});
    }
    const onTypeChange = (e) =>{
        setType(e.target.checked);
    }
    const onTimeChange = (e)=>{
        setDisplayTime(e);
    }

    const onDataBackupClick = ()=>{   
        if(type){
            if(password.trim()==='') return setAlert({...alert,password:t('Không được để trống')});
            if(alert.password!=='') return;  
        }
        setShowPassword(false);
        onBackupClick(password);
    }

    const onPasswordChange = (e) =>{
        const value = e.target.value;
        setPassword(value);
        if(value.trim()==='') setAlert({...alert,password:t('Không được để trống')})
        else setAlert({...alert,password:''});
    }

    const onScheduleBackup = () =>{
        if(time.hour==='*' || time.min==='*') return setError(t('Vui lòng nhập đầy đủ thông tin'));
        if(backupType ==='weekly'){
            if(time.date==='*') return setError(t('Vui lòng nhập đầy đủ thông tin'));
        }else{
            if(time.data==='*') return setError(t('Vui lòng nhập đầy đủ thông tin'));
        }
        var hour;
        if(oldHour !== moment(displayTime).format('HH')) hour = moment(displayTime).tz('GMT').format('HH');
        else hour = (parseInt(oldHour)+17)%24;
        const minutes = moment(displayTime).tz('GMT').format('mm');
        scheduleBackup(backupType,{...time,hour:hour,min:minutes});
    }

    return(
        <div style={{width:'70%',position:'relative'}} className={clsx(classes.focus)}>
            <div className={classes.select}>
                <div style={{marginLeft:'16px',height:'50px',position:'relative'}}>
                    <label className={classes.label} style={{marginBottom:'0',fontSize:'16px',padding:'10px 0'}}>{t('Cấu hình sao lưu')}</label><br />
                    <div className={classes.inputRightBlock} style={{float:'left',display:'flex',marginRight:'10px'}}>
                        <p style={{fontSize:'18px',padding:'10px 5px 0 0'}}>{t('Mỗi')}</p>
                        <FormControl required variant="outlined" style={{position:'relative',padding:'0!important'}}> 
                            <Select
                                inputProps={{
                                    style: {
                                        padding: '6px 10px',
                                    },
                                }}
                                disabled={!isEdit}
                                value={backupType}
                                style={{maxHeight:'32px',background:'#FFFFFF',width:'100px'}}
                                displayEmpty
                                onChange={onBackupTypeChange}
                            >
                                <MenuItem value='' disabled ><p className={classes.placeholder} style={{fontSize:'16px'}}> {`< ${t('Chọn')} >`} </p> </MenuItem>
                                <MenuItem value='daily' ><p className={classes.placeholder}> {t('ngày')} </p> </MenuItem>
                                <MenuItem value='weekly' ><p className={classes.placeholder}> {t('tuần')} </p> </MenuItem>
                                <MenuItem value='monthly' ><p className={classes.placeholder}> {t('tháng')} </p> </MenuItem>
                            </Select>
                        </FormControl>
                        <p style={{color:'red',fontSize:'13px',paddingTop:'1px',width:'200%'}}>{error}</p>
                    </div>
                    {backupType === 'weekly' &&<div className={classes.inputRightBlock} style={{float:'left',marginRight:'10px',display:'flex'}}>
                    <p style={{fontSize:'18px',padding:'10px 5px 0 0'}}>{t('vào ngày')}</p>
                        <FormControl required variant="outlined" style={{position:'relative',padding:'0!important',float:'left'}}> 
                            <Select
                                inputProps={{
                                    style: {
                                        padding: '6px 10px',
                                    },
                                }}
                                value={time.date==='?'?'*':time.date}
                                style={{maxHeight:'32px',background:'#FFFFFF',width:'125px'}}
                                displayEmpty
                                onChange={onDateChange}
                                disabled={!isEdit}
                            >
                                <MenuItem value='*' disabled ><p className={classes.placeholder} style={{fontSize:'16px'}}> {`< ${t('Chọn')} >`} </p> </MenuItem>
                                <MenuItem value='MON' > {t('thứ hai')} </MenuItem>
                                <MenuItem value='TUE' >{t('thứ ba')} </MenuItem>
                                <MenuItem value='WED' >{t('thứ tư')} </MenuItem>
                                <MenuItem value='THU' >{t('thứ năm')} </MenuItem>
                                <MenuItem value='FRI' >{t('thứ sáu')} </MenuItem>
                                <MenuItem value='SAT' >{t('thứ bảy')} </MenuItem>
                                <MenuItem value='SUN' >{t('chủ nhật')} </MenuItem>
                            </Select>
                        </FormControl>
                    </div>}
                    {backupType === 'monthly' &&<div className={classes.inputRightBlock} style={{float:'left',marginRight:'10px',display:'flex'}}>
                        <p style={{fontSize:'18px',padding:'10px 5px 0 0'}}>{t('vào ngày')}</p>
                        <FormControl required variant="outlined" style={{position:'relative',padding:'0!important',float:'left'}}> 
                            <Select
                                disabled={!isEdit}
                                inputProps={{
                                    style: {
                                        padding: '6px 10px',
                                    },
                                }}
                                value={time.day==='?'?'*':time.day}
                                style={{maxHeight:'32px',background:'#FFFFFF',width:'125px'}}
                                displayEmpty
                                onChange={onDayChange}
                            >
                                <MenuItem value='*' disabled ><p className={classes.placeholder} style={{fontSize:'16px'}}> {`< ${t('Chọn')} >`} </p> </MenuItem>
                                {getDay()}
                            </Select>
                        </FormControl>
                    </div>}
                    <div className={classes.inputRightBlock} style={{float:'left',width:'14%',display:'flex'}}>
                        <p style={{fontSize:'18px',padding:'10px 5px 0 0'}}>{t('lúc')}</p>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language==='vi'?vi:''}>
                            <TimePicker
                                disabled={!isEdit}
                                inputVariant="outlined"
                                style={{position:'relative',top:'-16px',width:'100%',background:'#FFFFFF'}}
                                size="small" 
                                margin="normal"
                                placeholder={t("Thời gian")}
                                value={displayTime}
                                onChange={onTimeChange}
                                ampm={false}
                                cancelLabel={t("Đóng")}
                                okLabel={t("Đồng ý")}
                                minutesStep={5}
                            />
                        </MuiPickersUtilsProvider>                     
                    </div>
                    {isEdit && <div className={classes.inputRightBlock} style={{float:'left',width:'35%',}}>
                        <CancelButton style={{height:'32px',marginLeft:'16px'}} onClick={()=>setLoading(false)}>{t('Hủy')}</CancelButton> 
                        <CreateButton 
                            variant="contained"
                            style={{height:'32px'}} 
                            onClick={onScheduleBackup}
                            disabled={isScheduling}
                        >
                            {t('Lưu')}
                            {isScheduling && <CircularProgress size={25} style={{position:'absolute',color:'#0b59c8'}}/>}
                        </CreateButton>
                                        
                    </div>}
                    {!isEdit && <div className={classes.inputRightBlock} style={{float:'left'}}>
                        <CreateButton style={{height:'32px'}} onClick={()=>setIsEdit(true)}>{t('Chỉnh sửa')}</CreateButton>              
                    </div>}
                </div>
                <Divider style={{clear:'both',marginBottom:'10px',width:'200%'}}/>
                <div style={{clear:'both'}}>
                    {process.env.REACT_APP_DEPLOY_ENV !== 'on-premise' &&<label className={classes.label} style={{marginBottom:'0',fontSize:'16px',paddingLeft:'16px'}}>{t('Sao lưu')}</label>}
                    {process.env.REACT_APP_DEPLOY_ENV !== 'on-premise' &&<div style={{height:'40px'}}>
                        <FormGroup aria-label="position" row>
                            <FormControlLabel       
                                control={<Checkbox color="primary" onChange={onTypeChange} checked={type} classes={{root: classes.checkbox, checked: classes.checked}} />}
                                label={t('Mật khẩu file sao lưu')}
                                labelPlacement="start"
                            />
                        </FormGroup>
                    </div>}
                    {type && process.env.REACT_APP_DEPLOY_ENV !== 'on-premise' &&<div className={classes.inputBlock} style={{paddingLeft:'16px',width:'50%',height:'60px'}}>
                        <TextField 
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={()=>setShowPassword(!showPassword)}
                                        >
                                        {showPassword ? <Visibility fontSize="small"/> : <VisibilityOff fontSize="small"/>}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            size="small"
                            style={{background:'#FFFFFF',width:'100%'}}
                            type={showPassword?"text":"password"}
                            placeholder={t("Mật khẩu")} 
                            variant="outlined"
                            onChange={onPasswordChange}
                            error={alert.password !== ''}
                        />
                        <p style={{color:'#f44336',fontSize:'0.75rem',paddingTop:'5px'}}>{alert.password}</p>
                    </div>}
                    <CreateButton 
                        variant="contained" 
                        onClick={onDataBackupClick} 
                        style={{height:'32px'}}
                        disabled={isBackingup}
                    >
                        {t('Sao lưu')}
                        {isBackingup && <CircularProgress size={25} style={{position:'absolute',top:'10%',left:'40%',color:'#0b59c8'}}/>}
                    </CreateButton>
                </div>
            </div>
        </div>
    )
}

export default Backup;