import { Divider } from '@material-ui/core';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import CreateIcon from '@mui/icons-material/Create';
import { CircularProgress, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { apiCabinetCellTypesGet, apiCabinetCellTypesDelete } from '../../../../api/cabinets';
import ErrorAlert from '../../../../components/alert/ErrorAlert';
import SuccessAlert from '../../../../components/alert/SuccessAlert';
import { CreateButton } from '../../../../components/button';
import { DeleteIcon } from '../../../../components/icons';
import { PrimaryTitle } from '../../../../components/title';
import { ConfirmWarning } from '../../../../components/dialog';
import LargeTooltip from '../../../../components/largeTooltip'
import { formatToVND } from '../../../../helper/currency';
import { useStyles } from './styles';
import { PageLoading } from '../../../../components/loading';

const Size = () => {
    const [state, setState] = useState({
        listSize: [],
        responseStatus: {
            succ: "",
            err: ""
        },
        sizeIDWillDelete: "",
        isLoading: false,
        isConfirm: false,
        isConfirmLoading: false
    });

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const { url } = useRouteMatch();

    const getApiCabinInfor = async () => {
        setState(prev => ({
            ...prev,
            isLoading: true
        }))
        try {
            const { data } = await apiCabinetCellTypesGet({ language: i18n.language });
            const { Items } = data;

            setState(prev => ({
                ...prev,
                listSize: Items
            }))
        } catch (error) {
            handleChangeResponseStatus("", t("Tải danh sách kích thước tủ lỗi!"))
        }
        setState(prev => ({
            ...prev,
            isLoading: false
        }))
    };

    const handleChangeResponseStatus = (succ = "", err = "") => {
        setState(prev => ({
            ...prev,
            responseStatus: {
                succ,
                err
            }
        }))
    }

    const handleGoBack = () => {
        history.push('/admin/cabinet/system');
    }

    const fee = (cellType) => {
        let resultArr = [];
        if (cellType.PriceType === 'free') {
            resultArr.push(<p key={cellType.PriceType}></p>);
        } else if (cellType.PriceType === 'turn') {
            resultArr.push(
                <p key={cellType.PriceType}>
                    {formatToVND(cellType.PriceValue)} / 1 {t("lượt thuê")}
                </p>
            );
        } else if (cellType.PriceType === 'hourly') {
            resultArr.push(
                <p key={cellType.PriceType}>{formatToVND(cellType.PriceValue)} / {t("1 giờ")}</p>
            );
        } else if (cellType.PriceType === 'scale') {
            resultArr = Object.keys(cellType.PriceValue).map((key) => (
                <p key={cellType.ID + key}>
                    {key} giờ - {formatToVND(cellType.PriceValue[key])}
                </p>
            ))
        }
        return resultArr;
    };

    const upDateSize = (item) => {
        history.push(`${url}/edit-size/${item.ID}`);
    };

    const handleCloseConfirm = () => {
        setState(prev => ({
            ...prev,
            isConfirm: false,
            isConfirmLoading: false
        }))
    }

    const onClickDeleteSize = (size) => {
        setState(prev => ({
            ...prev,
            sizeIDWillDelete: size.ID,
            isConfirm: true
        }))
    }

    const onConfirmDelete = async () => {
        setState(prev => ({
            ...prev,
            isConfirmLoading: true
        }))
        try {
            const body = {
                ids: [state.sizeIDWillDelete]
            }

            await apiCabinetCellTypesDelete(body)
        } catch (error) {
            handleChangeResponseStatus("", t("Xóa kích thước tủ thất bại. Vui lòng thử lại!"))
        }

        setState(prev => ({
            ...prev,
            isConfirmLoading: false,
            isConfirm: false
        }));

        getApiCabinInfor();
    }

    useEffect(() => {
        getApiCabinInfor();
    }, [i18n.language]);

    return (
        <div className={clsx(classes.container)}>
            <Box
                display={'flex'}
                justifyContent='space-between'
                padding='22px 0 1rem'
                alignItems='center'
                paddingTop= "0"
            >
                <Box display={'flex'} alignItems='center' >
                    <ArrowBackIosOutlinedIcon
                        className={classes.primaryColor}
                        style={{
                            cursor: "pointer",
                            marginRight: "0.5rem"
                        }}
                        onClick={handleGoBack}
                    />
                    <PrimaryTitle>
                        {t('Quản lý kích thước')}
                    </PrimaryTitle>
                </Box>
                <CreateButton
                    component={Link}
                    to={`${url}/add-size`}
                >
                    {t('Thêm kích thước')}
                </CreateButton>
            </Box>

            {(state.isLoading || state.listSize.length === 0) && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="calc(100vh - 198px)"
                >
                    {state.isLoading && (
                        <PageLoading />
                    )}

                    {!state.isLoading && state.listSize.length === 0 && (
                        <p>{t("Danh sách kích thước tủ trống")}</p>
                    )}
                </Box>
            )}

            <div className={classes.containSize}>
                {!state.isLoading && state?.listSize?.map((item) => {
                    const spec = item?.Specifications;

                    return (
                        <div key={item.ID}>
                            <Box
                                backgroundColor='rgb(0, 0, 0, 0.03)'
                                borderRadius={'12px'}
                                height="100%"
                            >
                                <Box
                                    display={'flex'}
                                    justifyContent='space-between'
                                    alignItems={"center"}
                                    padding={'16px'}
                                >
                                    <LargeTooltip title={item?.CellType[0]?.name}>
                                        <Typography
                                            sx={{ fontWeight: 700, fontSize: "20px" }}
                                            textOverflow="ellipsis"
                                            whiteSpace="nowrap"
                                            overflow="hidden"
                                        >
                                            {item?.CellType[0]?.name}
                                        </Typography>
                                    </LargeTooltip>
                                    <Box
                                        display={"flex"}
                                        alignItems="center"
                                    >
                                        <Box
                                            fontSize="8px"
                                            marginRight="0.5rem"
                                            style={{
                                                cursor: "pointer"
                                            }}
                                            onClick={() => onClickDeleteSize(item)}
                                        >
                                            <DeleteIcon />
                                        </Box>
                                        <CreateIcon
                                            fontSize='small'
                                            cursor='pointer'
                                            style={{
                                                color: "rgb(102, 102, 102)"
                                            }}
                                            onClick={() => upDateSize(item)}
                                        />
                                    </Box>
                                </Box>
                                <Divider></Divider>
                                <Box padding={'16px'}>
                                    <Box className={classes.label}>
                                        {t('Thông số')}
                                    </Box>
                                    <Box className={classes.info}>
                                        {spec?.length} x {spec?.width} x {' '}
                                        {spec?.height}cm
                                    </Box>
                                    <Box className={classes.label}>
                                        {t('Phí thuê tủ')}
                                    </Box>
                                    <Box className={classes.times}>
                                        {item.PriceType === 'free'
                                            ? t('Không tính phí')
                                            : item.PriceType === 'hourly'
                                                ? t('Phí cố định theo giờ')
                                                : item.PriceType === 'turn'
                                                    ? t('Phí cố định theo lượt')
                                                    : t('Phí thay đổi theo giờ')}
                                    </Box>
                                    {fee(item)}
                                </Box>
                            </Box>
                        </div>
                    );
                })}
            </div>

            <ConfirmWarning
                isOpen={state.isConfirm}
                handleClose={handleCloseConfirm}
                isLoading={state.isConfirmLoading}
                mess={t("Xóa kích thước tủ sẽ ảnh hưởng tới thông tin tủ. Bạn có chắc muốn xóa?")}
                onConfirm={onConfirmDelete}
            />

            <SuccessAlert
                message={state.responseStatus.succ}
                onCloseDialog={() => handleChangeResponseStatus()}
            />

            <ErrorAlert
                message={state.responseStatus.err}
                onCloseDialog={() => handleChangeResponseStatus()}
            />
        </div>
    );
};

export default Size;