import React, {useState, useEffect,useRef} from 'react';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import {useTranslation} from 'react-i18next';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import Button from '@material-ui/core/Button/Button';
import clsx from 'clsx';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { GridOverlay, DataGrid } from '@material-ui/data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Backup from './Backup';
import Restore from './Restore';
import axiosClient from '../../../config/AxiosClient';
import moment from 'moment';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import {useHistory } from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import RefreshIcon from '@material-ui/icons/Refresh';
import SuccessAlert from '../../alert/SuccessAlert';
import ErrorAlert from '../../alert/ErrorAlert';
import InfoAlert from '../../alert/InfoAlert';
import LargeTooltip from '../../largeTooltip';

const useStyles = makeStyles((theme)=>({
    label:{margin:'0',fontSize: '14px',lineHeight: '140%',color: '#7A7171',display: 'inline-block'},
    input:{width: '100%'},
    error:{color:'red',position:'absolute',bottom:'0',left:'20px'},
    alert:{position:'absolute', left:'3px', top:'3px',width:'99%'},
    dialogTitle:{textAlign:'center',fontSize:'20px',fontWeight:'600',lineHeight:'25px',color:'#FFFFFF'},
    contentDialog:{fontSize:'16px',height:'68px',borderBottom:'1px solid #EEEDF2',display:'flex',alignItems:'center',justifyContent:'center'},
    dialogTitleBackground:theme.primaryColor,
    errorColor:theme.errorColor,
    overlay:{position:'fixed',width:'100%',height:'100%',top:'0',left:'0',background:'rgba(0,0,0,0.1)',zIndex:'1300'},
    requestError:{width:'100%',height:'65vh',display:'flex',justifyContent:'center',alignItems:'center',fontSize:'20px',color:'#6D6D6D'},
    root:theme.dataGridStyle,
    formControlLabel:{'& .MuiFormControlLabel-label':{fontWeight:'bold'}},
    tableTitle:{position:'relative',height:'56px',border:'1px solid rgba(224, 224, 224, 1)',borderTopLeftRadius:'6px',borderTopRightRadius:'6px'},
    alertStyle:{position:'absolute',right:'9px',bottom:'8px',padding:'0 10px',zIndex:'10'},
    selectTitle:{fontSize:'16px',lineHeight:'22.4px',position:'absolute',bottom:'8px',right:'8px'},
    radio: {
        '&$checked': {
          color: theme.menuBackground
        }
      },
    checked: {},
    primaryColor:theme.primaryTextColor
}))

const CreateButton = withStyles(theme =>({root:theme.create_button}))(Button);
const CancelButton = withStyles(theme =>({root:theme.cancel_button}))(Button);
const ErrorButton = withStyles(theme =>({root:theme.error_button}))(Button);
const DeleteButton = withStyles(theme =>({root:theme.delete_button}))(Button);

const BackupTables = ()=>{
    const classes = useStyles();
    const {t} = useTranslation();
    const [alert,setAlert] = useState({success:'',error:'',request:'',info:''});
    const [fileNames,setFileNames] = useState([]);
    const [backups,setbackups] = useState([]);
    const [backupNames,setBackupNames] = useState([]);
    const [rowsPerPage,setRowsPerPage] = useState(parseInt(localStorage.getItem('rowsPerPage')));
    const [type,setType]=useState('backup');
    const [loading,setLoading] = useState({page:false,table:false});
    const [isDelete,setIsDelete] = useState(false);
    const [isDeleting,setIsDeleting] = useState(false);
    const [isSuccess,setIsSuccess] = useState(false);
    const [isFailed,setIsFailed] = useState(false);
    const [isBackingup,setIsBackingup] = useState(false);
    const [isScheduling,setIsScheduling] = useState(false);
    const alertRef = useRef();
    const [isExpiredSession,setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const [checkBackup,setCheckBackup] = useState(false);
    const [checkApiBackup,setCheckApiBackup] = useState(false);

    const columns = [
        {
            field:'Key',
            headerName:t('Tên file'),
            cellClassName:'super-app-theme--cell',
            flex:1
        },
        {
            field:'LastModified',
            headerName:t('Ngày tạo'),
            cellClassName:'super-app-theme--cell',
            flex:0.5,
            renderCell:params => <p>{moment(params.row.LastModified).format('DD-MM-YYYY HH:mm:ss')}</p>
        },
        {
            field:'Size',
            headerName:t('Kích thước'),
            cellClassName:'super-app-theme--cell',
            flex:0.3,
            renderCell:params=><p>{(params.row.Size * 9.537 *0.0000001).toFixed(2)} MB</p>
        },
        {
            field:'url',
            headerName:t('Tải'),
            cellClassName:'super-app-theme--cell',
            flex:0.4,
            renderCell:params=><a href={params.row.url} download className={classes.primaryColor} style={{textDecoration:'underline',cursor:'pointer'}} >{t('Tải')}</a>
        },
       
    ]
    
    useEffect(()=>{
        if(isExpiredSession){
          history.push('/login');
          dispatch({type:'IS_LOGIN_AGAIN_TRUE'});
        } 
    },[isExpiredSession]);

    const getDefaultValues = async()=>{
        try {
            var res;
            res = await axiosClient.post('/table/export/list',{},{
                headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
            })
            var data = [];
            const names=[];
            res.data.Files.forEach(value=>{
                data.push({...value,id:value.Key,url:value.Url});
            })
            data = data.sort((a,b)=> sortByCreatedDay(a.LastModified,b.LastModified));
            data.forEach(value=>{
                names.push(value.Key);
            })
            setbackups(data);
            setBackupNames(names);
            setFileNames([]);
            setAlert({...alert,request:''});            
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({...alert,request:t('Không lấy được dữ liệu')});
        }
        setLoading({table:true,page:true});  
    }
    const sortByCreatedDay = (dayA,dayB) =>{
        if(dayA < dayB) return 1;
        return dayA > dayB? -1 : 0;
    }
    useEffect(()=>{
        if(!loading.table || !loading.page) getDefaultValues();
    },[loading.table,loading.page])

    const closeBackupOrRestore = () =>{
        setLoading({...loading,page:false});
    }
    const onSelectedChange =(e)=>{
        setFileNames(e);
    }
    const onTypeChange = (e)=>{
        setType(e.target.value);
    }
    const onDeleteClick = ()=>{
        setIsDelete(true);
    }

    const deleteBackups = async(data) =>{
        await axiosClient.post('/table/export/delete',{'files':data},{
            headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
        })
    }
    const onConfirmDeleteClick = async() =>{ 
        try {
            setIsDeleting(true);
            const limit = 100;
            var num;
            if(fileNames.length % limit === 0) num = fileNames.length/limit;
            else num = fileNames.length/limit + 1;
            for(var i = 1; i<=num; i++){
                const end = i * limit;
                const start = end - limit;
                var limitData = fileNames.slice(start,end);
                await deleteBackups(limitData);
            }   
            setAlert({...alert,success:t('Xóa thành công!'),error:''});
            setLoading({...loading,page:false});
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({...alert,error:t('Xóa không thành công, vui lòng thử lại!'),success:''});
        }
        setIsDelete(false);
        setIsDeleting(false);
    }

    const onBackupClick = async(password) =>{
        try {
            setIsBackingup(true);
            var data = {};
            if(password!=='') data = {password:password};
            await axiosClient.post('/table/export',JSON.stringify(data),{
                headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
            })
            setAlert({...alert,info:t('Sao lưu đang trong quá trình xử lý')});
            setCheckBackup(true);
            setCheckApiBackup(true);
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setIsFailed(true);
        }   
        setIsBackingup(false);
        setLoading({...loading,page:false});
    }

    const checkBackupStatus = async() =>{
        try {
            let status;
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                status = await axiosClient.get('/table/import/status',{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
                });
            } else {
                status = await axiosClient.get('/table/import/status?action=Backup',{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
                });
            }
            const data = status.data.Items[0];
            if(data.Action==='Backup' && data.ActionStatus === 'Done'){
                setAlert({...alert,success:t('Sao lưu thành công!'),info:''});
                setLoading({...loading,table:false});
                setCheckApiBackup(false);
            } 
            if(data.Action==='Backup' && data.ActionStatus === 'Failed') setIsFailed(true);  
        } catch (error) {
            if(typeof(error.response)==='undefined') return;
            if(error.response.data === 'Invalid LoginToken') history.push('/login');
            setIsFailed(true);
        } 
    }
    useEffect(()=>{
        if(checkApiBackup){
            checkBackupStatus();
            setTimeout(() => {
                setCheckBackup(!checkBackup);
            }, 30000);
        }
    },[checkBackup,checkApiBackup])

    const scheduleBackup = async(backup_type,time)=>{
        try {
            setIsScheduling(true);
            var data={};
            if(backup_type==='daily')
                data={hour:time.hour,min:time.min,date:'*',day:'*',month:'*',year:'*'}
            else if (backup_type ==='weekly')
                data={hour:time.hour,min:time.min,date:time.date,day:'*',month:'*',year:'*'}
            else 
                data={hour:time.hour,min:time.min,date:'*',day:time.day,month:'*',year:'*'}
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                await axiosClient.post('/table/export/schedule',JSON.stringify(data),{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
                })
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                await axiosClient.post('/table/export/schedule', data,{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
                })
            }
            setAlert({...alert,success:t('Cấu hình sao lưu thành công!')});
            setLoading({...loading,page:false});
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setIsFailed(true);      
        }
        setIsScheduling(false);
    }
    const onCloseDialog = ()=>{
        setAlert({...alert,success:'',error:'',info:''});
    }

    useEffect(()=>{
        if(alert.success !== '' || alert.error !== ''){
            if(alertRef.current) clearTimeout(alertRef.current);
            alertRef.current = setTimeout(() => {
                onCloseDialog();
            }, 5000);
        }
    },[alert])

    const onAgreeClick = () =>{
        setIsSuccess(false);
        setIsFailed(false);
        setLoading({...loading,page:false});
    }
    const ConfirmDeleteDialog = () =>{
        return(
          <Dialog 
            open={isDelete}
            maxWidth="xl"
          >
            <DialogTitle className={classes.dialogTitleBackground}>
                <p className={classes.dialogTitle}>{t('Thông báo')}</p>
            </DialogTitle>
            <DialogContent style={{width:'448px',padding:'0',textAlign:'center',position:'relative'}}>
                <p className={classes.contentDialog}>{t('Bạn có chắc chắn muốn xóa?')}</p>
                <CancelButton 
                  style={{marginLeft:'30px'}}
                  onClick={()=>setIsDelete(false)}
                  disabled={isDeleting} 
                >
                  {t('Trở về')}
                </CancelButton>
                <CreateButton 
                  style={{margin:'10px 20px'}}
                  onClick={onConfirmDeleteClick} 
                  disabled={isDeleting}
                >
                  {t('Đồng ý')}
                </CreateButton>
                {isDeleting && <CircularProgress style={{position:'absolute',top:'10%',left:'45%',color:'#0b59c8'}}/>}
            </DialogContent>
          </Dialog>
        )
    }

    const SuccessfulDialog = () =>{
        return(
          <Dialog 
            open={isSuccess}
            maxWidth="xl"
          >
            <DialogTitle className={classes.dialogTitleBackground}>
                <p className={classes.dialogTitle}>{t('Thông báo')}</p>
            </DialogTitle>    
            <DialogContent style={{width:'448px',padding:'0',textAlign:'center'}}>
                <p className={classes.contentDialog}>{t('Sao lưu đang được thực hiện, vui lòng đợi trong ít phút')}</p>
                <CreateButton 
                  style={{margin:'10px 20px'}}
                  onClick={onAgreeClick} 
                >
                  {t('Đồng ý')}
                </CreateButton>
            </DialogContent>
          </Dialog>
        )
    }

    const FailedDialog = () =>{
        return(
          <Dialog 
            open={isFailed}
            maxWidth="xl"
          >
            <DialogTitle className={classes.errorColor}>
                <p className={classes.dialogTitle}>{t('Thông báo')}</p>
            </DialogTitle>
            <DialogContent style={{width:'448px',padding:'0',textAlign:'center'}}>
                <p className={classes.contentDialog}>{t('Không thành công, vui lòng thử lại!')}</p>
                <ErrorButton 
                  style={{margin:'10px 0'}}
                  onClick={onAgreeClick} 
                >
                  {t('Đồng ý')}
                </ErrorButton>
            </DialogContent>
          </Dialog>
        )
    }

    const EmptyRowsView = () => {
        return (
          <div
            style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}
          >
            <h3 style={{color:'#6D6D6D',fontSize: '16px'}}>
              {t('Không tìm thấy bản sao lưu')}
            </h3>
          </div>
        );
    };
    const customLoading = () =>{
        return(
            <GridOverlay>
                <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                    <LinearProgress />
                </div>
            </GridOverlay>
        )
    }
    return(
        <div style={{height:'100%'}}>
            {ConfirmDeleteDialog()}
            {SuccessfulDialog()}
            {FailedDialog()}
            {(isBackingup || isScheduling)&& <div className={classes.overlay}></div>}
            <div style={{display:'flex',width:'98%'}}>
                <p style={{fontSize:'22px',fontWeight:'bold',padding:'23px 0 10px 16px',flex:1}}>{t('Sao lưu và khôi phục')}</p>
            </div>
            {loading.page && alert.request === '' &&
            <Paper style={{width:'97%',margin:'auto',background:'#FAFAFA',position:'relative',overflow:'hidden',paddingBottom:'10px'}}>
                <div style={{width:'100%',height:'100%'}}>
                    <RadioGroup className={classes.formControlLabel} value={type} onChange={onTypeChange} row style={{marginLeft:'16px',height:'40px',paddingTop:'5px'}}>
                        <FormControlLabel value='backup' control={<Radio color="primary" classes={{root: classes.radio, checked: classes.checked}} size="small" />} label={t('Sao lưu')} />
                        <FormControlLabel value='restore' control={<Radio color="primary" classes={{root: classes.radio, checked: classes.checked}} size="small" />} label={t('Khôi phục')} />
                    </RadioGroup>
                    {type==='backup' && <Backup isBackingup={isBackingup} onBackupClick={(password)=>onBackupClick(password)} scheduleBackup={(time,unit)=>scheduleBackup(time,unit)} isScheduling={isScheduling}/>}
                    {type==='restore' && <Restore backupNames={backupNames} closeBackupOrRestore={closeBackupOrRestore}/>}
                </div>
            </Paper>}
            {alert.request !== '' && <div className={classes.requestError}>{alert.request}</div>}
            {!loading.page && alert.request === '' && <div style={{width:'100%',height:'250px',display:'flex',justifyContent:'center',alignItems:'center'}}><CircularProgress style={{color:'#0b59c8'}}/></div>}
            {loading.page && alert.request === '' &&
            <div style={{marginTop:'20px',width:'97%',margin:'auto',clear:'both',paddingTop:'10px',height:'44%'}}>
                <div className={classes.tableTitle}> 
                    <SuccessAlert message={alert.success} onCloseDialog={onCloseDialog} />  
                    <ErrorAlert message={alert.error} onCloseDialog={onCloseDialog} />  
                    <InfoAlert message={alert.info} onCloseDialog={onCloseDialog} />  
                    <LargeTooltip title={t("Xóa")}>
                        <span>
                            <DeleteButton
                                    style={{margin:'8px'}}
                                    variant="outlined"
                                    startIcon={<DeleteOutlineOutlinedIcon />}
                                    onClick={onDeleteClick} 
                                    disabled={fileNames.length === 0}
                                >
                                    {t("Xóa")}
                            </DeleteButton>
                        </span>
                    </LargeTooltip>
                    <CreateButton
                        variant="contained"
                        startIcon={<RefreshIcon />}
                        onClick={()=>{setLoading({...loading,table:false})}}
                        style={{margin:'0'}}
                    >
                        {t("Làm mới")}
                    </CreateButton>
                </div>
                <div style={{ height: '100%',minHeight:'320px', width: '100%'}} className={clsx(classes.root)}>       
                    <DataGrid 
                        componentsProps={{
                            pagination: {
                                labelRowsPerPage: t("Số dòng mỗi trang"),
                                labelDisplayedRows:({from,to,count}) => {return `${from}-${to} ${t('trong số')} ${count}`}
                            }
                        }}
                        components={{
                            NoRowsOverlay:EmptyRowsView,
                            LoadingOverlay: customLoading,
                        }}  
                        localeText={{
                            footerRowSelected: (count) => count +t(" hàng trong bảng đã được chọn")
                        }}
                        rows={backups} 
                        columns={columns} 
                        pageSize={rowsPerPage}
                        checkboxSelection 
                        rowsPerPageOptions={[10,50,100]}
                        onPageSizeChange={pageSize=>{
                            setRowsPerPage(pageSize);
                            localStorage.setItem('rowsPerPage',pageSize);
                        }}
                        onSelectionModelChange={onSelectedChange}
                        hideFooterRowCount
                        disableSelectionOnClick
                        disableColumnMenu={true}
                        loading={!loading.table}
                        localeText={{columnHeaderSortIconLabel: t('Sắp xếp')}}
                        scrollbarSize={17}
                    />
                </div>
                <div style={{height:'30px'}}></div>
            </div>}
        </div>
    )
}

export default BackupTables;