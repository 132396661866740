import React,{useState,useEffect} from 'react';
import {Bar} from 'react-chartjs-2';
import {useTranslation} from 'react-i18next';

const AccessBarChart = React.memo(props =>{
    const {t} = useTranslation();
    const {barData} = props;
    const [labels,setLabels] = useState([]);
    const [deniedValues,setDeniedValues] = useState([]);
    const [visitorValues,setVisitorValues] = useState([]);
    const [residentValues,setResidentValues] = useState([]);

    useEffect(()=>{
        setLabels(barData.week_labels?.reverse());
        setDeniedValues(barData.deniedData?.reverse());
        setVisitorValues(barData.visitorData?.reverse());
        setResidentValues(barData.residentData?.reverse());
    },[barData])

    const data = {
        labels:labels,
        datasets: [
            {
                label:t('Từ chối'),
                data:deniedValues,
                backgroundColor:'#263238',
            },
            {
                label:t('Chart.Cư dân'),
                data:residentValues,
                backgroundColor:'#F2A0BC',
            },
            {
                label:t('Khách'),
                data:visitorValues,
                backgroundColor:'rgb(242 160 188 / 30%)',
            }
        ],
    }

    return(
        <div>
            <div style={{padding:'10px 20px'}}>
                <p style={{fontSize:'20px',fontWeight:'600',color:'#333333',opacity:'0.6'}}>{t('Cư dân/Khách')}</p>
                <p style={{fontSize:'14px',color:'#333333',opacity:'0.6'}}>{t('7 ngày trước')}</p>
            </div>
            <div style={{width:'90%',margin:'auto'}}>
                <Bar
                    data={data}
                    width={400}
                    height={400}
                    options={{ 
                        plugins: {
                            legend: {
                                display:true,
                                position:'bottom',
                                labels:{
                                    boxWidth:15,     
                                    usePointStyle:true,
                                    color:'#000000',
                                    font:{
                                        family:'Nunito',
                                        size:'14px',
                                    }          
                                }
                            }
                        },
                        maintainAspectRatio: true,
                        scales: {
                            y: {
                                beginAtZero: true,
                                suggestedMin: 0,
                                suggestedMax: 10,
                                stacked:true,
                                ticks: { 
                                    display:false,
                                    callback: function(value, index, values) {
                                        return value<1000?value:value/1000 +'k';
                                    }
                                },
                                grid:{
                                    display:false
                                }
                                
                            },
                            x:{
                                stacked:true,
                                grid:{
                                    display:false
                                }
                            }
                        },
                       
                    }}
                />
            </div>
        </div>
    )
})

export default AccessBarChart;