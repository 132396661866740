
import makeStyles from "@material-ui/styles/makeStyles/makeStyles";

const useStyles = makeStyles((theme) => ({
    textStyle: {
        fontSize: '22px',
        fontWeight: 'bold',
        padding: '23px 0 0 0'
    },

}))

const SubTitle = ({ text }) => {
    const classes = useStyles();
    return (
        <p className={classes.textStyle} >
            {text}
        </p>
    )
}

export default SubTitle;