import React, { useState, useEffect, useRef } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Button from '@material-ui/core/Button/Button';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import TextField from '@material-ui/core/TextField/TextField';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import IconButton from '@material-ui/core/IconButton/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { checkImage } from '../../validation/CheckForm';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { Link, useHistory } from 'react-router-dom';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import CloseIcon from '@material-ui/icons/Close';
import parse from 'html-react-parser';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditNotificationForm from './EditNotificationForm';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import clsx from 'clsx';
import { apiNotifications, apiRegisternotification } from '../../api/notification';
import { apiUploadfile } from '../../api/uploadfile';
import { FailedDialog, SuccessDialog } from '../dialog';
import LargeTooltip from '../largeTooltip';

const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: '14px',
        lineHeight: '140%',
        color: '#000000',
        display: 'inline-block',
        paddingTop: '10px'
    },
    content: {
        width: '98%',
        background: '#FFFFFF',
        marginLeft: '1%',
        borderRadius: '6px'
    },
    primaryColor: theme.primaryTextColor,
    fileBtn: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        left: '0',
        top: '0'
    },
    dialogTitleBackground: theme.primaryColor,
    dialogTitle: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '25px',
        color: '#FFFFFF',
    },
    contentDialog: {
        fontSize: '16px',
        height: '68px',
        borderBottom: '1px solid #EEEDF2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    dialogFailedTitleBackground: theme.errorColor,
    overlay: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        background: 'rgba(0,0,0,0.1)',
        zIndex: '1300'
    },
    editor: {
        '& .rdw-editor-main': {
            height: '305px',
            cursor: 'text'
        },
        '& .DraftEditor-editorContainer, .DraftEditor-root, .public-DraftEditor-content': {
            height: 'auto'
        },
        '&.rdw-editor-toolbar': {
            height: '73px',
            overflowY: 'hidden'
        }
    },
    logoBlock: {
        width: '100px',
        height: '100px',
        border: '1px solid gray',
        position: 'relative',
        '&:hover label': {
            transition: '0.4s',
            opacity: '1'
        },
        '& label': {
            opacity: '0',
            transition: '0.4s'
        }
    },
    imageAdding: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0'
    },
    secondaryTitle: theme.secondary_title,
    focus: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: theme.themeColor,
            },
        },
    },
    textFeild: {
        fontWeight: 'bold',
        fontSize: '30px'
    }
}))

const CreateButton = withStyles(theme => ({ root: theme.create_button, }))(Button);
const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);
const ErrorButton = withStyles(theme => ({ root: theme.error_button }))(Button);

const AddNotificationForm = props => {
    const classes = useStyles();
    const [editor, setEditor] = useState(EditorState.createEmpty());
    const [messages, setMessages] = useState([]);
    const [fileStr, setFileStr] = useState('');
    const [edit, setEdit] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [messageID, setMessageID] = useState('');
    const [message, setMessage] = useState({
        status: 2,
        title: '',
        content: '',
        brief_content: '',
        notification_image: '',
        attach_file: ''
    });
    const [alert, setAlert] = useState({
        avatar: '',
        title: '',
        content: '',
        brief_content: '',
        file: ''
    });
    const [isSuccessDialog, setIsSuccessDialog] = useState(false);
    const [isFailedDialog, setIsFailedDialog] = useState(false);
    const [isImageAdding, setIsImageAdding] = useState(false);
    const [isFileAdding, setIsFileAdding] = useState(false);
    const [isExpiredSession, setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const timeoutRef = useRef();
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        if (isExpiredSession) {
            history.push('/login');
            dispatch({ type: 'IS_LOGIN_AGAIN_TRUE' });
        }
    }, [isExpiredSession]);

    const getNotifications = async () => {
        try {
            var res = await apiNotifications();
            setMessages(res.data.Items);
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setMessages([]);
        }
    }
    useEffect(() => {
        getNotifications();
    }, [])

    const uploadFileToAws = async (file, name) => {
        try {
            var res = await apiUploadfile(file);
            const attachFile = res.data;
            var multiFileStr = fileStr;
            multiFileStr += `<a href=${attachFile} download >${name}</a> `;
            setFileStr(multiFileStr);
            setAlert({ ...alert, file: '' });
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({ ...alert, file: t('Đã xảy ra lỗi, vui lòng thử lại!') });
        }
        setIsFileAdding(false);
    }

    const onAttachFileChange = (e) => {
        if (typeof (e.target.files[0]) === 'undefined') return;
        const file = e.target.files[0];
        if (file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && file.type.indexOf('image/') === -1 && file.type !== 'application/pdf' && file.type !== 'application/vnd.ms-excel') return setAlert({ ...alert, file: 'Chỉ được đính kèm file word, excel, pdf và ảnh' });
        if (file.size > 5242880) return setAlert({ ...alert, file: t('File đã lớn hơn 5MB') });
        setIsFileAdding(true);
        const name = e.target.files[0].name;
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                const source = reader.result.slice(reader.result.indexOf('base64,') + 7);
                const file_info = {
                    source: source,
                    file_name: name,
                    content_type: e.target.files[0].type
                }
                uploadFileToAws(file_info, name);
            }
        }
        reader.readAsDataURL(e.target.files[0]);
    }

    const onResetFile = () => {
        setFileStr('');
    }

    const updateImageToAws = async (avatar) => {
        try {
            var res = await apiUploadfile(avatar);
            setMessage({ ...message, notification_image: res.data });
            setAlert({ ...alert, avatar: '' });
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({ ...alert, avatar: t('Đã xảy ra lỗi, vui lòng thử lại!') });
        }
        setIsImageAdding(false);
    }

    const onAvatarChange = (e) => {
        if (typeof (e.target.files[0]) === 'undefined') return;
        if (!checkImage(e.target.files[0].type)) return setAlert({ ...alert, avatar: t('Vui lòng upload file ảnh') });
        setIsImageAdding(true);
        setAlert({ ...alert, avatar: '' });
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                const source = reader.result.slice(reader.result.indexOf('base64,') + 7);
                const avatar = {
                    source: source,
                    file_name: e.target.files[0].name
                }
                updateImageToAws(avatar);
            }
        }
        reader.readAsDataURL(e.target.files[0]);
    }

    const onEditorStateChange = (editorState) => {
        setEditor(editorState);
        const value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setMessage({ ...message, content: value });
        if (value.trim() === '<p></p>') setAlert({ ...alert, content: t('Nội dung không được để trống') });
        else setAlert({ ...alert, content: '' });
    }

    const checkTitle = (title) => {
        if (messages.length === 0) return;
        const result = messages.filter(item => item.Title.toLowerCase() === title.toLowerCase());
        if (result.length > 0) return true;
        return false;
    }

    const onTitleChange = (e) => {
        var value = e.target.value;
        setMessage({ ...message, title: value });
        if (value === '') setAlert({ ...alert, title: t('Tiêu đề không được để trống') });
        else if (value.length > 100) setAlert({ ...alert, title: t('Tiêu đề không được dài quá 100 ký tự') });
        else {
            setAlert({ ...alert, title: '' });
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => {
                if (checkTitle(value)) setAlert({ ...alert, title: t('Tiêu đề đã bị trùng') });
            }, 500);
        }
    }

    const onBriefContentChange = (e) => {
        var value = e.target.value;
        setMessage({ ...message, brief_content: value });
        if (value === '') setAlert({ ...alert, brief_content: t('Nội dung tóm tắt không được để trống') });
        else setAlert({ ...alert, brief_content: '' });
    }

    const onCreateMessageClick = async () => {
        try {
            setIsAdding(true);
            var file = '';
            if (fileStr !== '') file = `<p>Tệp đính kèm:<br /></p>${fileStr}`;
            const data = { ...message, content: message.content + file };
            var res = await apiRegisternotification(data);
            setMessageID(res.data.slice(res.data.indexOf(' ') + 1));
            setIsSuccessDialog(true);
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setIsFailedDialog(true);
        }

    }

    const onSubmit = () => {
        if (alert.avatar !== '') return;
        if (message.title === '') {
            setAlert({ ...alert, title: t('Tiêu đề không được để trống') });
            return;
        }
        if (checkTitle(message.title)) {
            setAlert({ ...alert, title: t('Tiêu đề đã bị trùng') });
            return;
        }
        if (message.brief_content === '') {
            setAlert({ ...alert, brief_content: t('Nội dung tóm tắt không được để trống') });
            return;
        }
        if (message.content === '' || message.content === '<p></p>') {
            setAlert({ ...alert, content: t('Nội dung không được để trống') });
            return;
        }
        onCreateMessageClick();
    }

    const onSuccessAgreeClick = () => {
        setEdit(true);
        setIsSuccessDialog(false);
    }

    const onSuccessBackClick = () => {
        history.push('/admin/manage-notifications');
    }

    const onFailedAgreeClick = () => {
        setIsFailedDialog(false);
        setIsAdding(false);
    }

    const changeStatusEdit = () => {
        history.replace('/admin/manage-notifications');
    }

    const succesDialog = () => {
        return (
            <Dialog
                open={isSuccessDialog}
                maxWidth="xl"
            >
                <DialogTitle className={classes.dialogTitleBackground}>
                    <p className={classes.dialogTitle}>{t('Thông báo')}</p>
                </DialogTitle>
                <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center' }}>
                    <p className={classes.contentDialog}>{t('Đã lưu thông báo mới thành công!')}</p>
                    <CancelButton variant="outlined" className={classes.cancelBtn} style={{ margin: '10px' }} onClick={onSuccessBackClick}>{t('Trở về')}</CancelButton>
                    <CreateButton variant="contained" className={classes.cancelBtn} style={{ margin: '10px' }} onClick={onSuccessAgreeClick}>{t('Xem')} </CreateButton>
                </DialogContent>

            </Dialog>
        )
    }

    return (
        <div style={{ minWidth: '1080px', position: 'relative', paddingBottom: '30px' }} className={clsx(classes.focus)}>
            {succesDialog()}
            <FailedDialog
                isOpen={isFailedDialog}
                mess={t('Đã xảy ra lỗi, vui lòng thử lại!')}
                onConfirm={onFailedAgreeClick}
            />
            {!edit && <div style={{ paddingBottom: '8px', marginTop: '15px', marginLeft: '1%', position: 'relative', left: '-9px' }}>
                <IconButton style={{ float: 'left', padding: '2px' }} component={Link} to="/admin/manage-notifications"><ArrowBackIosOutlinedIcon className={classes.primaryColor} style={{ width: '28px', height: '28px' }} /></IconButton>
                <p className={classes.secondaryTitle}>{t('THÊM MỚI BẢNG TIN')}</p>
            </div>}
            {!edit &&
                <div className={classes.content}>
                    {isAdding && <div className={classes.overlay}></div>}
                    <div style={{ width: '90%', margin: 'auto', display: 'flex' }}>
                        <div style={{ height: '150px', width: '150px', position: 'relative' }}>
                            <label className={classes.label} >{t('Ảnh thông báo')}</label><br />
                            <div className={classes.logoBlock}>
                                {message.notification_image !== '' && <img src={message.notification_image} alt="notification-avatar" width='100%' height='100%' />}
                                <input accept="image/<span style={{color:'red'}}>(*)</span>" hidden name="avatar" id="icon-button-file" type="file" onChange={onAvatarChange} />
                                <label htmlFor="icon-button-file" className={classes.fileBtn} >
                                    <IconButton aria-label="upload picture" component="span">
                                        <PhotoCamera style={{ fontSize: '50px' }} />
                                    </IconButton>
                                </label>
                                {isImageAdding &&
                                    <div className={classes.imageAdding}>
                                        <CircularProgress size={20} style={{ color: '#0b59c8' }} />
                                    </div>}
                            </div>
                            <p style={{ color: 'red', position: 'absolute', width: '200px' }}>{alert.avatar}</p>
                        </div>
                        <div style={{ height: '100px', width: '90%' }}>
                            <label className={classes.label}>{t('Tiêu đề')} <span style={{ color: 'red' }}>(*)</span></label><br />
                            <TextField
                                placeholder={t("Tiêu đề thông báo")}
                                variant="outlined"
                                style={{ width: '100%' }}
                                inputProps={{ style: { fontWeight: 'bold' } }}
                                size="small"
                                onChange={onTitleChange}
                                helperText={alert.title}
                                error={alert.title !== ''}
                            />
                        </div>
                    </div>
                    <div style={{ width: '90%', margin: 'auto', height: '100px', clear: 'both' }}>
                        <label className={classes.label}>{t('Nội dung tóm tắt')} <span style={{ color: 'red' }}>(*)</span></label><br />
                        <TextField
                            placeholder={t('Nội dung tóm tắt')}
                            variant="outlined"
                            style={{ width: '100%' }}
                            size="small"
                            onChange={onBriefContentChange}
                            helperText={alert.brief_content}
                            error={alert.brief_content !== ''}
                        />
                    </div>
                    <div style={{ width: '90%', margin: 'auto', height: '450px' }}>
                        <label className={classes.label}>{t('Nội dung')} <span style={{ color: 'red' }}>(*)</span></label><br />
                        <div style={{ border: '1px solid rgb(210 203 203)', borderRadius: '6px', height: '405px', padding: '5px' }} className={classes.editor} >
                            <Editor
                                editorState={editor}
                                onEditorStateChange={onEditorStateChange}
                            />
                        </div>
                        <span style={{ color: 'red' }}>{alert.content}</span>
                    </div>
                    <div style={{ border: '1px solid rgba(128, 128, 128, 0.39)', width: '90%', margin: 'auto', borderRadius: '6px', marginBottom: '16px', position: 'relative', marginTop: '18px' }}>
                        <input accept=".xlsx, .xls, .csv ,image/*, .doc, .docx" hidden name="avatar" id="upload-file" type="file" onChange={onAttachFileChange} />
                        <label htmlFor="upload-file" style={{ paddingTop: '5px' }}>
                            <LargeTooltip title={t("Đính kèm tệp")}>
                                <IconButton aria-label="upload picture" component="span" style={{ marginLeft: '10px', padding: '0' }}>
                                    <AttachFileIcon style={{ fontSize: '20px' }} />
                                    {isFileAdding && <CircularProgress size={20} style={{ position: 'absolute', color: '#0b59c8' }} />}
                                </IconButton>
                            </LargeTooltip>
                            {parse(fileStr)}
                        </label>
                        {fileStr !== '' &&
                            <LargeTooltip title={t("Xóa tất cả tệp đính kèm")} >
                                <IconButton component="span" style={{ position: 'absolute', right: '3px', padding: '0', top: '5px' }} onClick={onResetFile}>
                                    <CloseIcon style={{ fontSize: '20px' }} />
                                </IconButton>
                            </LargeTooltip>}
                        <p style={{ color: 'red', fontSize: '13px', position: 'absolute', bottom: '-20px', left: '0' }}>{alert.file}</p>
                    </div>
                    <div style={{ width: '90%', margin: 'auto', textAlign: 'right', marginTop: '22px', paddingBottom: '22px' }}>
                        <CreateButton
                            variant="contained"
                            onClick={() => onSubmit()}
                            style={{ position: 'relative', textAlign: 'center' }}
                            disabled={isAdding}
                        >
                            {t('Lưu')}
                            {isAdding && <CircularProgress size={25} style={{ position: 'absolute', color: '#0b59c8' }} />}
                        </CreateButton>
                    </div>
                </div>}
            {edit && <EditNotificationForm messageID={messageID} messages={messages} changeStatusEdit={() => changeStatusEdit()} />}
        </div>
    )
}


export default AddNotificationForm;


