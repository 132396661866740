import React,{useEffect,useState, useRef} from 'react';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import {Link,Route,useLocation} from 'react-router-dom';
import clsx from 'clsx';
import Device from './device/Device';
import {useTranslation} from 'react-i18next';
import Accounts from './deviceAccounts/Accounts';
import Configurations from './config_device/Configurations';
import AddConfig from './config_device/AddConfig';
import LicenseList from './license/LicenseList';
import SyncFaceHistories from './sync_face/SyncFaceHistories';
import Detail from './sync_face/Detail';
import ResidentDetailInfo from '../residences/approval_history/ResidentDetailInfo';
import { useSelector } from 'react-redux';
import AddTommiDevice from './device/AddTommiDevice';
import EditTommi from './device/EditTommi';
import DetailInfo from './sync_face/DetailInfo';

const useStyles = makeStyles((theme)=>({
    topBlock:{marginTop:'22px',marginBottom:'20px',height:'40px'},
    primaryTitle:theme.primary_title,
    secondaryTitle:theme.secondary_title,
    menu:{width:'18%',marginRight:'1%',background:'#FFFFFF',borderRadius:'6px',minWidth:'240px',minHeight:'800px'},
    content:{width:'82%',borderRadius:'6px',background:'#FFFFFF',minHeight:'800px'},
    link:{display:'block',height:'40px',fontSize:'14px',padding:'10px',color:'#000000',position:'relative'},
    active:{background:'#50c9ff4a',},
    activeBackground:{position:'absolute',width:'100%',height:'100%',top:'0',left:'0',background:'#50c9ff4a'},
    triangle:{height:'30px',width:'30px',position:'absolute',top:'5px',right:'-5px',background:'#FFFFFF',transform:'rotate(45deg)',zIndex:'0'}
}))
const DeviceMenu = props => {
    const classes = useStyles();
    const location = useLocation();
    const [active,setActive] = useState(0);
    const heightRef = useRef();
    const {t} = useTranslation();
    const [role,setRole] = useState('');
    const adminUserState = useSelector((state) =>state.adminUserState);

    useEffect(() => {
        if(location.pathname==='/admin/device-management/devices'){
            heightRef.current.style['height'] = '152%';
            heightRef.current.style['maxHeight'] = '888px';
            setActive(5);
        } 
        if(location.pathname==='/admin/device-management/add-device'){
            heightRef.current.style['height'] = '100%';
            setActive(5);
        } 
        if(location.pathname.match('/admin/device-management/device/*')){
            heightRef.current.style['height'] = '100%';
            setActive(5);
        } 
        if(location.pathname==='/admin/device-management/device-accounts'){
            heightRef.current.style['height'] = '100%';
            setActive(8);
        } 
        if(location.pathname==='/admin/device-management/device-configuration' || location.pathname==='/admin/device-management/add-device-configuration'){
            heightRef.current.style['height'] = '100%';
            setActive(9);
        } 
        if(location.pathname==='/admin/device-management/licences'){
            heightRef.current.style['height'] = '100%';
            setActive(10);
        } 
        if(location.pathname==='/admin/device-management/sync-face'){
            heightRef.current.style['height'] = '100%';
            setActive(11);
        } 
        if(location.pathname.match('/admin/device-management/sync-face/detail*')){
            heightRef.current.style['height'] = 'auto';
            heightRef.current.style['minHeight'] = '800px';
            setActive(11);
        }
        if(location.pathname.match('/admin/device-management/sync-face/resident/detail*')){
            heightRef.current.style['height'] = '105%';
            setActive(11);
        } 
    }, [location.pathname])

    useEffect(()=>{
        setRole(adminUserState.role);
    },[adminUserState.role])

    return (
        <div style={{width:'98%',minWidth:'1250px',margin:'auto',height:'100%'}} >
            <div ref={heightRef} style={{width:'100%',height:'100%',display:'flex'}} >
                <div className={classes.menu}>
                    <div className={classes.topBlock} style={{marginBottom:'0'}}>
                        <p className={classes.secondaryTitle} style={{marginLeft:'10px'}}>{t('Quản lý thiết bị')}</p>
                    </div>
                    <Link to='/admin/device-management/devices' className={clsx(classes.link)}>
                        {t('Danh sách thiết bị')}
                        {active === 5 && <div className={classes.triangle}></div>}
                        {active === 5 && <div className={classes.activeBackground}></div>}
                    </Link>
                    {(role === 'admin'||role === 'root') &&<Link to='/admin/device-management/device-accounts' className={clsx(classes.link)}>
                        {t('Tài khoản quản lý')}
                        {active === 8 && <div className={classes.triangle}></div>}
                        {active === 8 && <div className={classes.activeBackground}></div>}
                    </Link>}
                    {(role === 'admin'||role==='root') &&<Link to='/admin/device-management/device-configuration' className={clsx(classes.link)}>
                        {t('Danh sách hồ sơ cấu hình')}
                        {active === 9 && <div className={classes.triangle}></div>}
                        {active === 9 && <div className={classes.activeBackground}></div>}
                    </Link>}
                    <Link to='/admin/device-management/licences' className={clsx(classes.link)}>
                        {t('Giấy phép Face ID')}
                        {active === 10 && <div className={classes.triangle}></div>}
                        {active === 10 && <div className={classes.activeBackground}></div>}
                    </Link>
                    <Link to='/admin/device-management/sync-face' className={clsx(classes.link)}>
                        {t('Đồng bộ Face ID')}
                        {active === 11 && <div className={classes.triangle}></div>}
                        {active === 11 && <div className={classes.activeBackground}></div>}
                    </Link>
                </div>
                <div className={classes.content} >
                    <Route exact path='/admin/device-management/devices' component={Device} />
                    <Route exact path='/admin/device-management/add-device' component={AddTommiDevice} />
                    <Route exact path='/admin/device-management/device/:deviceID' component={EditTommi} />
                    {(role === 'admin' || role==='root') &&<Route exact path='/admin/device-management/device-configuration' component={Configurations} />}
                    {(role === 'admin' || role==='root') &&<Route exact path='/admin/device-management/add-device-configuration' component={AddConfig} />}
                    {(role === 'admin'|| role==='root') &&<Route exact path='/admin/device-management/device-accounts' component={Accounts} />}
                    <Route exact path='/admin/device-management/licences' component={LicenseList} />
                    <Route exact path='/admin/device-management/sync-face' component={SyncFaceHistories} />
                    <Route path='/admin/device-management/sync-face/detail' component={DetailInfo} />
                    <Route path='/admin/device-management/sync-face/resident/detail' component={ResidentDetailInfo} />
                </div>
            </div>
        </div>
    )
}

export default DeviceMenu;
