import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button/Button';
import { isEmpty } from '../../../validation/CheckForm';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Confirm } from '../../dialog/index';

const useStyles = makeStyles((theme) => ({
    inputBlock: {
        height: '365px',
        width: '100%',
        padding: '0 16px',
        position: 'relative',
        float: 'left'
    },
    label: {
        margin: '0',
        fontSize: '14px',
        lineHeight: '140%',
        color: '#000000',
        display: 'inline-block'
    },
    input: {
        width: '100%'
    },
    placeholder: {
        color: '#B2B2B2',
        fontSize: '12px'
    },
    requestError: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20px',
        color: '#6D6D6D'
    },
    focus: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: theme.themeColor,
            },
        },
    },
}))

const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);
const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);

const EditAdvanceConfig = props => {
    const { deviceInfo, onRefresh, updateDevice, isProcessing, retryDevice } = props;
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [device, setDevice] = useState({ deviceName: '' });
    const [alert, setAlert] = useState({ deviceName: '', request: '', config: '', success: '', error: '', info: '' });
    const [request, setRequest] = useState('');
    const [configData, setConfigData] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [isPing, setIsPing] = useState(false);
    const { t } = useTranslation();

    const getDefaultValues = async () => {
        try {
            delete deviceInfo.Configuration.deviceName;
            setConfigData(JSON.stringify(deviceInfo.Configuration, null, 2));
            setDevice({ id: deviceInfo.ID, deviceName: deviceInfo.DeviceName, status: deviceInfo.DeviceStatus });
            setAlert({ ...alert, deviceName: '', config: '' });
        } catch (error) {
            setRequest(t('Thiết bị đang sử dụng phiên bản cũ, vui lòng cập nhật phiên bản mới'));
        }
        setIsEdit(false);
        setLoading(true);
    }

    useEffect(() => {
        getDefaultValues();
    }, [loading, deviceInfo])

    const onPingClick = () => {
        setAlert({ ...alert, info: t('Bạn có chắc chắc muốn PING đến thiết bị?') });
        setIsPing(true);
        setConfirmDialog(true);
    }

    const onConfirmPingClick = async () => {
        setConfirmDialog(false);
    }

    const onNameChange = (e) => {
        const name = e.target.value;
        setDevice({ ...device, deviceName: name });
        if (isEmpty(name)) {
            setAlert({ ...alert, deviceName: t('Không được để trống') });
        } else {
            setAlert({ ...alert, deviceName: '' });
        }
    }

    const onConfigChange = (e) => {
        const value = e.target.value;
        setConfigData(value)
        if (isEmpty(value)) {
            setAlert({ ...alert, config: t('Không được để trống') });
        } else {
            setAlert({ ...alert, config: '' });
        }
    }

    const checkFaceIdMod = (faceIdMode) => {
        try {
            if (isNaN(Number(faceIdMode))) return false;
            if (parseInt(faceIdMode) !== 0 && parseInt(faceIdMode) !== 1 && parseInt(faceIdMode) !== 2) return false;
            return true;
        } catch (error) {
            return false;
        }

    }

    const checkLedLightMode = (ledLightMode) => {
        try {
            if (isNaN(Number(ledLightMode))) return false;
            if (parseInt(ledLightMode) !== 0 && parseInt(ledLightMode) !== 1 && parseInt(ledLightMode) !== -1) return false;
            return true;
        } catch (error) {
            return false;
        }
    }

    const checkFdThreshold = (fdThreshold) => {
        try {
            if (isNaN(Number(fdThreshold))) return false;
            if (parseFloat(fdThreshold) < 0 || parseFloat(fdThreshold) > 1) return false;
            return true;
        } catch (error) {
            return false;
        }
    }

    const checkNumber = (value) => {
        try {
            if (isNaN(Number(value))) return false;
            if (parseInt(value) < 0) return false;
            return true;
        } catch (error) {
            return false;
        }
    }
    
    const chackBoolean = (value) => {
        try {
            if (value === false || value === true) return true;
            return false
        } catch (error) {
            return false;
        }
    }

    const onUpdateClick = () => {
        try {
            if (isEmpty(device.deviceName)) return setAlert({ ...alert, deviceName: t('Không được để trống') });
            if (alert.deviceName !== '') return;
            if (isEmpty(configData)) return setAlert({ ...alert, config: t('Không được để trống') });
            if (alert.config !== '') return;
            const data = JSON.parse(configData);
            if (!checkNumber(data ?.maxTemperature)) return setAlert({ ...alert, config: `maxTemperature ${t('phải lớn hơn hoặc bằng 0')}` });
            if (!checkFaceIdMod(data ?.faceIdMode)) return setAlert({ ...alert, config: `faceIdMode ${t('phải là 0, 1 hoặc 2')}` });
            if (!chackBoolean(data ?.autoDimDisplay)) return setAlert({ ...alert, config: `autoDimDisplay ${t('phải là true hoặc false')}` });
            if (!chackBoolean(data ?.restrictBlockAccess)) return setAlert({ ...alert, config: `restrictBlockAccess ${t('phải là true hoặc false')}` });
            if (!chackBoolean(data ?.isCheckEndCall)) return setAlert({ ...alert, config: `isCheckEndCall ${t('phải là true hoặc false')}` });
            if (!chackBoolean(data ?.useSpeaker)) return setAlert({ ...alert, config: `useSpeaker ${t('phải là true hoặc false')}` });
            if (!checkLedLightMode(data ?.ledLightMode)) return setAlert({ ...alert, config: `ledLightMode ${t('phải là -1, 0 hoặc 1')}` });
            if (!checkFdThreshold(data ?.fdThreshold)) return setAlert({ ...alert, config: `fdThreshold ${t('phải từ 0 đến 1')}` });
            if (!chackBoolean(data ?.useInternalRelay)) return setAlert({ ...alert, config: `isCheckEndCall ${t('phải là true hoặc false')}` });
            if (!chackBoolean(data ?.useTextToSpeech)) return setAlert({ ...alert, config: `isCheckEndCall ${t('phải là true hoặc false')}` });
            if (!checkNumber(data ?.delayAfterOpenDoor)) return setAlert({ ...alert, config: `delayAfterOpenDoor ${t('phải lớn hơn hoặc bằng 0')}` });
            if (!checkNumber(data ?.dimDisplayTimeout)) return setAlert({ ...alert, config: `dimDisplayTimeout ${t('phải lớn hơn hoặc bằng 0')}` });
            setIsPing(false);
            setAlert({ ...alert, info: t('Bạn có chắc chắn muốn lưu chỉnh sửa?') });
            setConfirmDialog(true);
        } catch (error) {
            setAlert({ ...alert, config: t('Dữ liệu nhập vào chưa hợp lệ') });
        }
    }

    const onConfirmEditClick = async () => {
        const data = { ...JSON.parse(configData), id: device.id, deviceName: device.deviceName };
        await updateDevice(data);
        setConfirmDialog(false);
    }

    return (
        <div className={clsx(classes.focus)}>
            <Confirm
                isOpen={confirmDialog}
                handleClose={() => setConfirmDialog(!confirmDialog)}
                isLoading={isProcessing}
                mess={alert.info}
                onConfirm={isPing ? onConfirmPingClick : onConfirmEditClick}
            />
            {request !== '' &&
                <div>
                    <div className={classes.requestError}>{request}</div>
                    <div style={{ textAlign: 'right' }}>
                        <CreateButton
                            variant="contained"
                            onClick={retryDevice}
                            disabled={isProcessing}
                        >
                            {t('Thử lại')}
                            {isProcessing && <CircularProgress size={25} style={{ position: 'absolute', color: '#0b59c8' }} />}
                        </CreateButton>
                    </div>
                </div>
            }
            {loading && request === '' && <div >
                <div className={classes.inputBlock} style={{ height: '80px' }}>
                    <label className={classes.label}>{t('Tên thiết bị')} <span style={{ color: 'red' }}>(*)</span></label><br />
                    <TextField
                        classes={{
                            root: classes.input
                        }}
                        name="deviceName"
                        size="small"
                        value={device.deviceName}
                        placeholder={t("Tên thiết bị")}
                        variant="outlined"
                        onChange={onNameChange}
                        error={alert.deviceName !== ''}
                        helperText={alert.deviceName}
                        disabled={!isEdit}
                    />
                </div>
                <div className={classes.inputBlock}>
                    <label className={classes.label}>{t('Cấu hình')} <span style={{ color: 'red' }}>(*)</span></label><br />
                    <TextField
                        classes={{
                            root: classes.input
                        }}
                        inputProps={{
                            autoComplete: 'off'
                        }}
                        name="name"
                        size="small"
                        multiline
                        rows={15}
                        value={configData}
                        placeholder={t("Cấu hình")}
                        variant="outlined"
                        onChange={onConfigChange}
                        error={alert.config !== ''}
                        helperText={alert.config}
                        disabled={!isEdit}
                    />
                </div>
                {!isEdit && device.status === 'online' && <div style={{ textAlign: 'right', padding: '0 16px' }}>
                    <CreateButton
                        variant="contained"
                        style={{ margin: '0' }}
                        onClick={onRefresh}
                    >
                        {t('Làm mới')}
                    </CreateButton>
                    <CreateButton
                        variant="contained"
                        onClick={() => setIsEdit(true)}
                    >
                        {t('Chỉnh sửa')}
                    </CreateButton>
                </div>}
                {isEdit && <div style={{ textAlign: 'right', padding: '0 16px' }}>
                    <CancelButton
                        variant="outlined"
                        onClick={() => {
                            setIsEdit(false);
                            setLoading(false);
                        }}
                        style={{ margin: '0' }}
                    >
                        {t('Hủy')}
                    </CancelButton>
                    <CreateButton
                        variant="contained"
                        onClick={onUpdateClick}
                    >
                        {t('Lưu')}
                    </CreateButton>
                </div>}
            </div>}
        </div>
    )
}

export default EditAdvanceConfig;
