import makeStyles from '@material-ui/styles/makeStyles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    containLine: {
        position: 'relative',
        padding: "24px 24px 0",
        border: '1px solid #E0E0E0',
        borderRadius: '4px',
        marginBottom: '24px'
    },
    wrapLoading: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%'
    },
    wrapTitleLine: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    lineTitle: {
        fontWeight: '700',
        fontSize: '20px',
        color: '#333333'
    },
    doughnutDate: {
        marginLeft: '8px',
        color: '#8E8E8E',
        fontSize: '14px'
    },
    wrapLineAction: {
        display: 'flex',
        alignItems: "center"
    },
    wrapContainDouhnut: {
        display: 'flex',
        justifyContent: 'center'
    },
    containDoughnut: {
        display: 'flex',
        overflowX: 'auto'
    },
    doughnutItem: {
        margin: '16px 38px 38px'
    },
    doughnutLabel: {
        display: 'flex',
        alignItems: 'center',

        '&:first-child': {
            marginRight: '24px'
        }
    },
    labelIcon: {
        width: '12px',
        height: '12px',
        marginRight: '8px',
        borderRadius: '12px',
    },
    labelText: {
        color: '#8E8E8E',
    }
}));