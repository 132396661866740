import React, { useEffect, useState } from 'react';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import { Link, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { getSyncFaceHistories } from '../../../api/device_management/syncFace';
import moment from 'moment-timezone';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Divider from '@mui/material/Divider/Divider';

const useStyles = makeStyles((theme) => ({
    inputBlock: {
        height: '60px',
        width: '45%',
        position: 'relative',
        marginRight: '5%',
    },
    label: {
        margin: '0',
        fontSize: '14px',
        lineHeight: '140%',
        color: '#000000',
        display: 'inline-block',
        fontWeight: 'bold',
    },
    input: { width: '100%' },
    placeholder: theme.place_holder,
    error: { color: 'red', position: 'absolute', bottom: '0', left: '20px' },
    alert: { position: 'absolute', left: '3px', top: '3px', width: '99%' },
    requestError: {
        width: '100%',
        height: '65vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20px',
        color: '#6D6D6D',
    },
    secondaryTitle: theme.secondary_title,
    dialogTitle: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '25px',
        color: '#FFFFFF',
    },
    contentDialog: {
        fontSize: '16px',
        height: '68px',
        borderBottom: '1px solid #EEEDF2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    dialogTitleBackground: theme.primaryColor,
    errorColor: theme.errorColor,
    overlay: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        background: 'rgba(0,0,0,0.1)',
        zIndex: '1300',
    },
    select: {
        '& .MuiOutlinedInput-input': { padding: '10px 12px' },
    },
    focus: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: theme.themeColor,
            },
        },
    },
    primaryColor: theme.primaryTextColor,
}));

const Detail = () => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [syncDetail, setSyncDetail] = useState({ Reasons: [] });
    const [error, setError] = useState();
    const [isExpiredSession, setIsExpiredSession] = useState(false);
    const [idInfo, setIdInfo] = useState({});
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (isExpiredSession) {
            history.push('/login');
            dispatch({ type: 'IS_LOGIN_AGAIN_TRUE' });
        }
    }, [isExpiredSession]);

    const showTriggerBy = (data) => {
        var triggerBy = '';
        switch (data) {
            case undefined:
                triggerBy = 'Tự động';
                break;
            case 'undefined (undefined)':
                triggerBy = 'Tự động';
                break;
            default:
                triggerBy = data;
                break;
        }
        return triggerBy;
    };

    const getDefaultValues = async () => {
        try {
            const data = queryString.parse(history.location.search);
            if (data.id && data.DeviceId) {
                setIdInfo({ id: data.id, DeviceId: data.DeviceId });
                const res = await getSyncFaceHistories({
                    requestId: data.id,
                    deviceId: data.DeviceId,
                });
                const action =
                    res.data.Items[0].Action === 'ADD'
                        ? 'Thêm khuôn mặt'
                        : res.data.Items[0].Action === 'DELETE'
                        ? 'Xóa khuôn mặt'
                        : 'Cập nhật khuôn mặt';
                var deviceName = '';
                const values = Object.values(res.data.Items[0].Result);
                values[0].forEach((item) => {
                    deviceName = item.DeviceName;
                });
                setSyncDetail({
                    ...res.data.Items[0],
                    deviceName: deviceName,
                    SyncResult: `${values[0].length} ${t('khuôn mặt bị lỗi')}`,
                    Reasons: values[0],
                    Action: action,
                    TriggerBy: showTriggerBy(res.data.Items[0].TriggerBy),
                });
                setError('');
            } else {
                setError(t('Không lấy được dữ liệu'));
            }
        } catch (err) {
            if (error.response && error.response.data === 'Invalid LoginToken')
                return setIsExpiredSession(true);
            setError(t('Không lấy được dữ liệu'));
        }
        setLoading(true);
    };

    useEffect(() => {
        if (!loading) getDefaultValues();
    }, [loading]);

    useEffect(() => {
        setLoading(false);
    }, [i18n.language]);

    const onDetailClick = (username) => {
        history.replace(
            `/admin/device-management/sync-face/resident/detail?id=${idInfo.id}&&DeviceId=${idInfo.DeviceId}&&username=${username}`
        );
    };
    const getReason = (reason) => {
        var result = '';
        switch (reason) {
            case 'cannot_download_image':
                result = t('Không thể tải ảnh đăng ký Face ID');
                break;
            case 'more_than_one_face':
                result = t('Có hơn 1 khuôn mặt trong khung hình');
                break;
            case 'no_face_recognized':
                result = t('Không nhận diện được khuôn mặt');
                break;
            case 'face_mask_not_allowed':
                result = t('Phát hiện đeo khẩu trang');
                break;
            default:
                result = t('Chưa rõ nguyên nhân');
                break;
        }
        return result;
    };

    const showReasons = () => {
        return syncDetail.Reasons.map((value, key) => {
            return (
                <p key={key} style={{ fontSize: '18px' }}>
                    <b
                        style={{
                            cursor: 'pointer',
                            fontStyle: 'italic',
                            color: '#0b59c8',
                        }}
                        onClick={() => onDetailClick(value.Username)}
                    >
                        {value.Username}
                    </b>{' '}
                    - {getReason(value.Reason)}
                </p>
            );
        });
    };
    return (
        <div>
            <div style={{ paddingBottom: '8px', marginTop: '23px' }}>
                <IconButton
                    style={{
                        float: 'left',
                        marginLeft: '23px',
                        padding: '2px',
                        marginRight: '5px',
                    }}
                    component={Link}
                    to='/admin/device-management/sync-face'
                >
                    <ArrowBackIosOutlinedIcon
                        className={classes.primaryColor}
                        style={{ width: '28px', height: '28px' }}
                    />
                </IconButton>
                <p className={classes.secondaryTitle}>
                    {t('Thông tin chi tiết đồng bộ Face ID')}
                </p>
            </div>
            {loading && error !== '' && (
                <div className={classes.requestError}>{error}</div>
            )}
            {!loading && (
                <div
                    style={{
                        width: '100%',
                        height: '250px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress style={{ color: '#0b59c8' }} />
                </div>
            )}
            {loading && error === '' && (
                <div
                    style={{
                        width: '94%',
                        margin: 'auto',
                        padding: '10px',
                        display: 'flex',
                        flexWrap: 'wrap',
                    }}
                >
                    <Divider style={{ width: '100%', margin: '10px 0' }} />
                    <div className={classes.inputBlock}>
                        <label className={classes.label}>{t('Thiết bị')}</label>
                        <br />
                        <p style={{ fontSize: '18px' }}>
                            {syncDetail.deviceName}
                        </p>
                    </div>
                    <div className={classes.inputBlock}>
                        <label className={classes.label}>
                            {t('Hành động')}
                        </label>
                        <br />
                        <p style={{ fontSize: '18px' }}>
                            {t(syncDetail.Action)}
                        </p>
                    </div>
                    <div
                        className={classes.inputBlock}
                        style={{ height: '70px' }}
                    >
                        <label className={classes.label}>
                            {t('Thực hiện bởi')}
                        </label>
                        <br />
                        <p style={{ fontSize: '18px' }}>
                            {t(syncDetail.TriggerBy)}
                        </p>
                    </div>
                    <div className={classes.inputBlock}>
                        <label className={classes.label}>
                            {t('Thời gian')}
                        </label>
                        <br />
                        <p style={{ fontSize: '18px' }}>
                            {moment(syncDetail.Time).format(
                                'DD-MM-YYYY HH:mm:ss'
                            )}
                        </p>
                    </div>
                    <Divider
                        style={{
                            width: '100%',
                            marginTop: '10px',
                            marginBottom: '20px',
                        }}
                    />
                    <div style={{ width: '100%' }}>
                        <div className={classes.inputBlock}>
                            <label className={classes.label}>
                                {t('Kết quả')}
                            </label>
                            <br />
                            <p style={{ fontSize: '18px' }}>
                                {syncDetail.SyncResult}
                            </p>
                        </div>
                        <div
                            className={classes.inputBlock}
                            style={{ width: '100%' }}
                        >
                            <label className={classes.label}>
                                {t('Lý do thất bại')}
                            </label>
                            <br />
                            <div>{showReasons()}</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Detail;
