import AxiosClient from "../config/AxiosClient";

export const getApartmentList = async () => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/getapartmentdata';
    } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
        url = '/apartmentrooms/get';
    }
    return await AxiosClient.get(url);
}