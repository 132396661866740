import React,{useEffect,useState} from 'react';
import {useTranslation} from 'react-i18next';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import { Link,useHistory } from 'react-router-dom';
import axiosClient from '../../config/AxiosClient';
import Cookies from 'js-cookie';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme)=>({
    inputBlock:{height:'50px',width:'100%',position:'relative',paddingTop:'10px'},
    label:{margin:'0',fontSize: '14px',lineHeight: '140%',color: '#000000',marginRight:'5px'},
    placeholder:{color:'#B2B2B2',fontSize:'12px'},
    dialogTitle:{textAlign:'center',fontSize:'20px',fontWeight:'600',lineHeight:'25px',color:'#FFFFFF'},
    contentDialog:{fontSize:'16px',height:'68px',borderBottom:'1px solid #EEEDF2',display:'flex',alignItems:'center',justifyContent:'center',fontWeight:'bold'},
    dialogTitleBackground:theme.primaryColor,
    errorColor:theme.errorColor,
}))

const CancelButton = withStyles(theme =>({root:theme.cancel_button}))(Button);
const ErrorButton = withStyles(theme =>({root:theme.error_button}))(Button);

const MaintainancePage = () =>{
    const {t} = useTranslation();
    const classes = useStyles();
    const [test,setTest] = useState(false);
    const [loading,setLoading] = useState(false);
    const history = useHistory();
    const [isFailed,setIsFailed] = useState(false);
    const checkStatus = async() =>{
        try {
            const status = await axiosClient.get('/table/import/status',{
                headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
            });
            const data = status.data.Items[0];
            if(data.ActionStatus === 'Done') history.push('/admin/statistic-page');
            if(data.Action==='Restore' && data.ActionStatus === 'Failed') setIsFailed(true);
            localStorage.setItem('restore',data.ActionStatus);
        } catch (error) {
            if(typeof(error.response)==='undefined') return;
            if(error.response.data === 'Invalid LoginToken') history.push('/login');
        }
        setLoading(true);
    }
    useEffect(()=>{  
        checkStatus();          
        setTimeout(async() => {          
            setTest(!test);
        }, 60000);
    },[test])

    const onAgreeClick = () =>{
        setIsFailed(false);
        history.push('/admin/statistic-page');
    }
    const FailedDialog = () =>{
        return(
          <Dialog 
            open={isFailed}
            maxWidth="xl"
          >
            <DialogTitle className={classes.errorColor}>
                <p className={classes.dialogTitle}>{t('Thông báo')}</p>
            </DialogTitle>
            <DialogContent style={{width:'448px',padding:'0',textAlign:'center'}}>
                <p className={classes.contentDialog}>{t('Khôi phục dữ liệu không thành công, vui lòng thử lại!')}</p>
                <ErrorButton 
                  style={{margin:'10px 0'}}
                  onClick={onAgreeClick} 
                >
                  {t('Đồng ý')}
                </ErrorButton>
            </DialogContent>
          </Dialog>
        )
    }

    return(
        <div style={{textAlign:'center',width:'100%',height:'100vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
            {FailedDialog()}
            {loading && <div>
                <p style={{fontSize:'40px',fontWeight:'bold'}}>{t('Hệ thống đang bảo trì')}</p>
                <p style={{fontSize:'40px',fontWeight:'bold'}}>{t('Vui lòng chờ trong ít phút!')}</p>
                <img src={window.location.origin+'/images/maintainance.png'} alt="" style={{marginBottom:'10px'}}/>
                <br />
                <CancelButton 
                    variant="outlined"
                    component={Link} 
                    to='/'
                    endIcon={<SubdirectoryArrowRightIcon />}
                >
                    {t('Quay lại trang chủ')}
                </CancelButton>
            </div>}
        </div>
    )
}

export default MaintainancePage;