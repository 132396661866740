import AxiosClient from "../config/AxiosClient";

export const apiNotifications = async (bodyRequest) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/notifications';
    } else {
        url = '/notifications/get';
    }
    if (bodyRequest) {
        return await AxiosClient.post(url, bodyRequest);
    } else {
        return await AxiosClient.get(url);
    }
    
}

export const apiRegisternotification = async (bodyRequest) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/registernotification';
    } else {
        url = '/notifications/create';
    }
    return await AxiosClient.post(url, bodyRequest);
}

export const apiNotification = async (bodyRequest) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/notification';
    } else {
        url = '/notifications/get';
    }
    return await AxiosClient.post(url, bodyRequest);
}

export const apiDeletenotification = async (bodyRequest) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/deletenotification';
    } else {
        url = '/notifications/delete';
    }
    return await AxiosClient.post(url, bodyRequest);
}

export const apiPublishnotification = async (bodyRequest) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/publishnotification';
    } else {
        url = '/notifications/publish';
    }
    return await AxiosClient.post(url, bodyRequest);
}

export const apiUpdatenotification = async (bodyRequest) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/updatenotification';
    } else {
        url = '/notifications/update';
    }
    return await AxiosClient.post(url, bodyRequest);
}