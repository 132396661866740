const initState = {
    username:'',
    role:'',
}

const AdminUserReducer = (state = initState,action)=>{
    switch (action.type) {
        case 'ADD_ADMIN_USER_INFO':
            return {...state,username:action.username,role:action.role}    
        default:
            return state;
    }
}

export default AdminUserReducer;