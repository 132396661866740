import makeStyles from '@material-ui/styles/makeStyles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    root: theme.dataGridStyle,
    cabinSystem: {
        minHeight: "100%",
    },
    head: {
        display: "flex",
        justifyContent: "space-between",
        padding: "0 0 22px 0"
    },
    wrapLoading: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 210px)",
    },
    wrapEmpty: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 210px)",
        flexDirection: "column"
    }
    ,
    contain: {
        display: "grid",
        gridTemplateColumns: "calc(25% - 16px) calc(25% - 16px) calc(25% - 16px) calc(25% - 16px)",
        gap: "16px"
    },
    cabinItem: {
        borderRadius: "0.5rem",
        padding: "1rem",
        background: 'rgb(0, 0, 0, 0.03)'
    },
    cabinItemHead: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #EEEEEE",
        paddingBottom: "1rem",
    },
    cabinItemHeadTitle: {
        textOverflow: "ellipsis",
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        fontWeight: "700",
        fontSize: "20px"
    },
    cabinItemHeadIcon: {
        cursor: "pointer"
    },
    info: {
        display: "flex",
        justifyContent: "space-between"
    },
    infoCommon: {
        width: "44%"
    },
    infoCellType: {
        width: "44%"
    },
    infoLabel: {
        marginTop: "1rem",
        color: "#616161"
    },
    titleEmpty: {
        fontWeight: "400",
        fontSize: "20px",
        lineHeight: "24px",
        marginTop: "52px"
    },
    infoData: {
        fontWeight: '400',
        fontSize: '16px',
    }
}));