import React,{useState,useRef} from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField/TextField';
import Button from '@material-ui/core/Button/Button';
import {isEmpty, checkEmail, checkPassword, checkDisplayName} from '../../validation/CheckForm';
import Zoom from '@material-ui/core/Zoom';
import md5 from 'md5';
import { adminStyles } from '../../styles/style';
import { useTranslation } from 'react-i18next';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import PasswordStrengthBar from 'react-password-strength-bar';
import zxcvbn from 'zxcvbn';
import clsx from 'clsx';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

const CreateButton = withStyles(theme =>({root:theme.create_button}))(Button);
const CancelButton = withStyles(theme =>({root:theme.cancel_button}))(Button);

const AddAdminForm = props => {
    const {addAdminAccount,admins,changeStatusAddClick} = props;
    const classes = adminStyles();
    const [alert, setAlert] = useState({email:'',username:'',password:'',display_name:''});
    const [account, setAccount] = useState({role:'moderator',password:'',display_name:''});
    const {t} = useTranslation();
    const emailRef = useRef();
    const [showPassword,setShowPassword] = useState(false);
    const passwordRef = useRef();

    const onNameChange = (e)=>{
        const name = e.target.value;
        setAccount({...account,display_name:name});
        if(isEmpty(name)){
            setAlert({...alert,display_name:t('Không được để trống')});
        }else if(!checkDisplayName(name)){
            setAlert({...alert,display_name:t('Không được có ký tự đặc biệt')});
        }else if (name.trim().length < 6 || name.trim().length > 30){
            setAlert({...alert,display_name:t('Độ dài ký tự trong khoảng từ 6 đến 30')});
        }else{
            setAlert({...alert,display_name:''});
        }
    }
    const checkExistingEmail = (email) =>{
        const result = admins.filter(item=>item.Email===email.trim());
        return result.length > 0;
    }
    const onEmailChange = (e) =>{
        var email = e.target.value;
        setAccount({...account,email:email,username:email});
        if(isEmpty(email)){
            setAlert({...alert,email:t("Không được để trống")});
        }else{
            setAlert({...alert,email:''});
            if(emailRef.current) clearTimeout(emailRef.current);
            emailRef.current = setTimeout(() => {
                if(checkExistingEmail(email)) setAlert({...alert,email:t("Email đã tồn tại")});
                if(!checkEmail(email)) setAlert({...alert,email:t("Email không hợp lệ")});
            }, 300);
        }
    }

    const onPasswordChange = (e) =>{
        const password = e.target.value;
        setAccount({...account,password:password});
        if(isEmpty(password)){
            setAlert({...alert,password:t("Không được để trống")});
        }else if(!checkPassword(password)){
            setAlert({...alert,password:t("Tối thiểu 8 ký tự bao gồm chữ HOA, chữ thường, số và ký tự đặc biệt")});
        }else{
            setAlert({...alert,password:''});
        }
        if(passwordRef.current) clearTimeout(passwordRef.current);
        passwordRef.current = setTimeout(() => {
            const result = zxcvbn(password);
            if(result.feedback.warning!=='') setAlert({...alert,password:t('Đã trùng mật khẩu phổ biến')});
        }, 500);
    }

    const onRoleChange = (e) =>{
        setAccount({...account,role:e.target.value});
    }
    const onCreateClick = () =>{
        if(isEmpty(account.display_name)) return setAlert({...alert,display_name:t("Không được để trống")});
        if(alert.display_name !== '') return;
        if(isEmpty(account.email)) return setAlert({...alert,email:t("Không được để trống")});
        if(alert.email !== '') return;
        if(isEmpty(account.password)) return setAlert({...alert,password:t("Không được để trống")});
        if(alert.password !== '') return;
        addAdminAccount({...account,password:md5(account.password),status:'enabled'});
    }
    
    return (
        
        <Zoom in={true} style={{transitionDelay:'300ms'}}>
            <div className={clsx(classes.addFormContent,classes.focus)} >         
                <p className={classes.secondaryTitle} style={{textAlign:'center'}} >{t("Thêm admin")}</p>
                <div style={{width:'90%',margin:'auto',height:'105px'}}>
                    <label className={classes.label}>{t('Tên người quản trị')} <span style={{color:'red'}}>(*)</span></label><br/>
                    <TextField
                        placeholder={t('Tên người quản trị')}
                        variant="outlined"
                        style={{width:'100%'}}
                        size="small"
                        onChange={onNameChange}
                        error = {alert.display_name !== ''}
                    />
                    <p style={{color:'#f44336',paddingTop:'3px',fontSize:'0.75rem'}}>{alert.display_name}</p>
                </div>
                <div style={{width:'90%',margin:'auto',height:'105px'}}>
                    <label className={classes.label}>Email <span style={{color:'red'}}>(*)</span></label><br/>
                    <TextField
                        placeholder="Email"
                        variant="outlined"
                        style={{width:'100%'}}
                        size="small"
                        onChange={onEmailChange}
                        error = {alert.email !== ''}
                    />
                    <p style={{color:'#f44336',paddingTop:'3px',fontSize:'0.75rem'}}>{alert.email}</p>
                </div>
                <div style={{width:'90%',margin:'auto',height:'115px'}}>
                    <label className={classes.label}>{t("Mật khẩu")} <span style={{color:'red'}}>(*)</span></label><br/>
                    <TextField
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={()=>setShowPassword(!showPassword)}
                                    >
                                       {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        placeholder={t("Mật khẩu")}
                        variant="outlined"
                        style={{width:'100%'}}
                        size="small"
                        type={showPassword?"text":"password"}
                        onChange={onPasswordChange}
                        error = {alert.password !== ''}
                    />
                    {account.password !== '' &&<PasswordStrengthBar scoreWords={['','','','']} shortScoreWord='' password={account.password} />}
                    <p style={{color:'#f44336',paddingTop:'3px',fontSize:'0.75rem'}}>{alert.password}</p>
                </div>
                <div style={{width:'90%',margin:'auto',height:'105px'}} className={classes.select}>
                    <FormControl required variant="outlined" classes={{root:classes.input}} style={{width:'100%'}}>
                        <label className={classes.label} >{t('Vai trò')} <span style={{color:'red'}}>(*)</span></label>
                        <Select
                            value={account.role}
                            style={{height:'40px',background:'#FFFFFF',width:'100%'}}
                            onChange={onRoleChange}
                        >
                            <MenuItem value='moderator'>{t('Người điều hành')}</MenuItem>
                            <MenuItem value='admin'>{t('Quản trị')}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div style={{width:'80%',margin:'auto',textAlign:'right',marginTop:'22px',paddingBottom:'22px'}}>
                    <CancelButton variant="outlined" onClick={changeStatusAddClick} >{t("Đóng")}</CancelButton>
                    <CreateButton variant="contained" onClick={onCreateClick} >{t("Tạo")}</CreateButton>
                </div>
            </div>
        </Zoom>
    )
}

export default AddAdminForm;
