import React,{useState,useEffect} from 'react';
import {Line} from 'react-chartjs-2';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button/Button';
import {useTranslation} from 'react-i18next';

const CreateButton = withStyles(theme =>({root:theme.create_button}))(Button);
const AccessLineChart = React.memo(props =>{
    const {t} = useTranslation();
    const {lineData} = props;
    const [dataByWeek,setDataByWeek] = useState([]);
    const [dataByDay,setDataByDay] = useState([]); 
    const [isWeek,setIsWeek] = useState(true);
    const [weekLabels,setWeekLabels] = useState([]);
    const dateLabels = ["0:00","1:00","2:00","3:00","4:00","5:00","6:00","7:00","8:00","9:00","10:00","11:00","12:00","13:00","14:00","15:00","16:00","17:00","18:00","19:00","20:00","21:00","22:00","23:00"]

    useEffect(()=>{
        setDataByDay(lineData.chartData);
        setDataByWeek(lineData.values?.reverse());
        setWeekLabels(lineData.labels?.reverse());
    },[lineData])

    const data = {
        labels: isWeek?weekLabels:dateLabels,
        datasets: [{
          label: t('Ra vào'),
          data: isWeek?dataByWeek:dataByDay,
          fill: false,
          borderColor: '#FF9838',
          backgroundColor: '#FF9838',
          tension: 0.4,
          color:'#FFFFFF'
        }]
    }
    return(
        <div>
            <div style={{display:'flex',padding:'10px 20px'}}>
                <p style={{flex:1,fontSize:'20px',fontWeight:'600',display:'flex',alignItems:'center',color:'#333333',opacity:'0.6'}}>{t('Mật độ ra vào')}</p>
                <CreateButton variant="contained" style={{height:'32px'}} disabled={isWeek} onClick={()=>setIsWeek(!isWeek)} >{t('Tuần')}</CreateButton>
                <CreateButton variant="contained" style={{height:'32px'}} disabled={!isWeek} onClick={()=>setIsWeek(!isWeek)}>{t('Ngày')}</CreateButton>
            </div>
            <div style={{width:'100%',minHeight:'300px',position:'absolute',bottom:'0'}}>
                <div style={{width:'100%'}}>
                    <Line
                        data={data}
                        width={420}
                        height={200}
                        options={{ 
                            responsive:true,
                            maintainAspectRatio: true,
                            plugins: {
                                legend: {
                                    display: false,
                                }
                            },
                            scales: {
                                y: {
                                    beginAtZero: true,
                                    suggestedMin: 0,
                                    suggestedMax: 10,
                                    ticks: { 
                                        callback: function(value, index, values) {
                                            return value<1000?value:value/1000 +'k';
                                        }
                                    }
                                },
                            },
                            
                        }}
                    />
                </div>
            </div>
        </div>
    )
})

export default AccessLineChart;