import React, {useState,useEffect,useRef} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import axiosClient from '../../../config/AxiosClient';
import { DataGrid } from '@material-ui/data-grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button/Button';
import QuicklyAddRooms from './QuicklyAddRooms';
import clsx from 'clsx';
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import FilterListIcon from '@material-ui/icons/FilterList';
import { useDispatch } from 'react-redux';
import {useHistory } from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Cookies from 'js-cookie';
import SuccessAlert from '../../alert/SuccessAlert';
import ErrorAlert from '../../alert/ErrorAlert';
import ExportApartmentFile from './ExportApartmentFile';

const useStyles = makeStyles(theme=>({
    font:{fontSize:'16px',lineHeight:'22.4px',color:'#6D6D6D'},
    status:{color:'#6D6D6D',paddingBottom:'0px'},
    dialogTitle:{textAlign:'center',fontSize:'20px',fontWeight:'600',lineHeight:'25px',color:'#FFFFFF'},
    contentDialog:{fontSize:'16px',height:'68px',borderBottom:'1px solid #EEEDF2',display:'flex',alignItems:'center',justifyContent:'center'},
    dialogTitleBackground:theme.primaryColor,
    root:theme.dataGridStyle,
    requestError:{width:'100%',height:'65vh',display:'flex',justifyContent:'center',alignItems:'center',fontSize:'20px',color:'#6D6D6D'},
    filter:{
        '& .MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-textSizeSmall.MuiButton-sizeSmall:nth-child(3)':{
            display:'none'
        },
        '& span.MuiButton-label':{
            textTransform:'none',
            color:'#6D6D6D',
            fontSize:'16px'
        },
    },
    inputRightBlock:{height:'80px',width:'25%',position:'relative',float:'left'},
    label:{paddingBottom:'0px',paddingTop:'12px',fontSize: '14px',lineHeight: '140%',fontWeight:'bold',color: '#7A7171',display: 'inline-block',margin:'0'},
    inputHeight:{
        textAlign:'left',height:'80px',margin:'10px 0',position:'relative',background:'#FAFAFA',
        '& .MuiOutlinedInput-root':{height:'32px'}
    },
    overlay:{position:'fixed',width:'100%',height:'100%',top:'0',left:'0',background:'rgba(0,0,0,0.1)',zIndex:'1300'},
    errorColor:theme.errorColor,
    tableTitle:{position:'relative',height:'56px',border:'1px solid rgba(224, 224, 224, 1)',borderTopLeftRadius:'6px',borderTopRightRadius:'6px'},
    alertStyle:{position:'absolute',right:'8px',bottom:'5px',padding:'4px 10px',zIndex:'10'},
    selectTitle:{fontSize:'16px',lineHeight:'22.4px',position:'absolute',bottom:'8px',right:'8px'},
    focus:{
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': { 
                borderColor: theme.themeColor,
            },
        },
    },
}))

const CreateButton = withStyles(theme =>({root:theme.create_button}))(Button);
const CancelButton = withStyles(theme =>({root:theme.cancel_button}))(Button);
const ErrorButton = withStyles(theme =>({root:theme.error_button}))(Button);
const DeleteButton = withStyles(theme =>({root:theme.delete_button}))(Button);

const RoomsTable = props => {
    const classes = useStyles();
    const [rowsPerPage,setRowsPerPage] = useState(parseInt(localStorage.getItem('rowsPerPage')));
    const [apartmentData,setApartmentData] = useState([]);
    const [filteredData,setFilteredData] = useState([]);
    const [message,setMessage] = useState({
        success:'',
        error:'',
        request:''
    })
    const [loading,setLoading] = useState(false);
    const [ids,setIds] = useState([]);
    const [confirmDialog,setConfirmDialog] = useState(false);
    const [isAddRoms, setIsAddRooms] = useState(false);
    const [isDeleting,setIsDeleting] = useState(false);
    const [filter,setFilter] = useState({block:'',floor:'',room:''});
    const [isFilter,setIsFilter] = useState(false);
    const [isExpiredSession,setIsExpiredSession] = useState(false);
    const [isAdding,setIsAdding] = useState(false);
    const [isFail,setIsFail] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const {t} = useTranslation();
    const alertRef = useRef();

    const columns =[
        {
            field:'BlockID',
            headerName:'Block',
            flex:1,
            cellClassName:'super-app-theme--cell',
        },
        {
            field:'FloorID',
            headerName:t('Tầng'),
            cellClassName:'super-app-theme--cell',
            flex:1,
            sortComparator:(v1,v2,params1,params2)=> sortFloor(v1,v2)
        },
        {
            field:'RoomID',
            headerName:t('Phòng'),
            cellClassName:'super-app-theme--cell',
            flex:1,
        },
    ]
    useEffect(()=>{
        if(isExpiredSession){
          history.push('/login');
          dispatch({type:'IS_LOGIN_AGAIN_TRUE'});
        } 
    },[isExpiredSession]);
    
    const sortFloor = (v1,v2)=>{
        return v1.localeCompare(v2,undefined,{
            numeric:true,
            sensitivity: 'base'
        });
    }

    const getDefaultValues = async()=>{
        try {
            var res;
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                res = await axiosClient.get('/getapartmentdata',{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
                });
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                res = await axiosClient.get('/apartmentrooms/get',{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`,'Content-Type': 'application/json'}
                });
            }
            const sortedData = res.data.Items.sort((a,b)=> sortApartment(a,b));
            var data = [];
            sortedData.forEach(item=>{
                item = {...item,id:item.ID};
                data.push(item);
            })
            setApartmentData(data);
            setFilteredData(data);
            setLoading(true);
            setIds([]);
            setMessage({...message,request:''});    
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setMessage({...message,request:t('Không lấy được dữ liệu')});
        }
    }
    const sortApartment = (apat1,part2) =>{
        if(apat1.BlockID > part2.BlockID) return 1;
        if(apat1.BlockID < part2.BlockID) return -1;
        if(parseInt(apat1.FloorID)-parseInt(part2.FloorID) > 0) return 1;
        if(parseInt(apat1.FloorID)-parseInt(part2.FloorID) < 0)  return -1;
        if(parseInt(apat1.RoomID)-parseInt(part2.RoomID) > 0) return 1;
        if(parseInt(apat1.RoomID)-parseInt(part2.RoomID) <0) return -1;
        return 0;
    }
    useEffect(() => {
        if(!loading) getDefaultValues();
    }, [loading])

    const onDeleteClick = () =>{
        setConfirmDialog(true);
    }
    const onSelectedChange = (e) =>{
        setIds(e);
    }

    const deleteRooms = async(limitData) =>{
        const data = {ids:limitData};
        if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
            await axiosClient.post('/deleteapartmentrooms',JSON.stringify(data),{
                headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
            });
        } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
            await axiosClient.post('/apartmentrooms/delete',JSON.stringify(data),{
                headers:{'Authorization': `Bearer ${Cookies.get('token')}`,'Content-Type': 'application/json'}
            });
        }
    }
    const onConfirmDeleteClick = async() =>{
        try {
            setIsDeleting(true);
            const limit = 100;
            var num;
            if(ids.length % limit === 0) num = ids.length/limit;
            else num = ids.length/limit + 1;
            for(var i = 1; i<=num; i++){
                const end = i * limit;
                const start = end - limit;
                var limitData = ids.slice(start,end);
                await deleteRooms(limitData);
            }   
            setMessage({...message,success:`${t('Xóa thành công')} ${ids.length} ${ids.length>1?t('nhiều phòng'):t('phòng')}`,error:''});
            setLoading(false);
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setMessage({...message,success:'',error:t('Xóa không thành công, vui lòng thử lại!')});
        }
        setConfirmDialog(!confirmDialog);
        setIsDeleting(false);
    }

    const changeStatusAddClick = () =>{
        setIsAddRooms(!isAddRoms);
        setIsFilter(false);
    }

    const onChange = (e) =>{
        const name = e.target.name;
        const value = e.target.value;
        setFilter({...filter,[name]:value});
    }

    const onApplyClick = () =>{
        const res = apartmentData.filter(item=>
            item.BlockID.toUpperCase().indexOf(filter.block.toUpperCase())!== -1 &&
            (item.FloorID+'').indexOf(filter.floor)!== -1 &&
            (item.RoomID+'').indexOf(filter.room)!== -1)              
        setFilteredData(res);
    }
    useEffect(()=>{
        onApplyClick();
    },[apartmentData])

    const addRoomsToDB = async(data) =>{
        try {
            setIsAdding(true);
            changeStatusAddClick();
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                await axiosClient.post('/addapartmentrooms',JSON.stringify(data),{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
                });
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                await axiosClient.post('/apartmentrooms/create',JSON.stringify(data),{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`,'Content-Type': 'application/json'}
                });
            }
            setMessage({...message,success:t('Thêm thành công!')});           
            setLoading(false);
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setIsFail(true);
        } 
        setIsAdding(false);
    }

    const onAgreeClick = () =>{
        setIsFail(false);
    }

    const onCloseDialog = () =>{
        setMessage({...message,success:'',error:''});
    }
    useEffect(() => {
        if(message.error !== '' || message.success !== ''){
            if(alertRef.current) clearTimeout(alertRef.current);
            alertRef.current = setTimeout(() => {
                onCloseDialog();
            }, 5000);
        }
    }, [message])
    
    const dialogDelete = () =>{
        return(
          <Dialog 
            open={confirmDialog}
            maxWidth="xl"
          >
            <DialogTitle className={classes.dialogTitleBackground}>
                <p className={classes.dialogTitle}>{t('Thông báo')}</p>
            </DialogTitle>
            <DialogContent style={{width:'448px',padding:'0',textAlign:'center',position:'relative'}}>
                <p className={classes.contentDialog}>{t('Bạn có chắc muốn xóa phòng?')}</p>
                <CancelButton 
                  style={{margin:'10px 0'}}
                  onClick={()=>{setConfirmDialog(!confirmDialog)}} 
                  disabled={isDeleting}
                >
                  {t('Trở về')}
                </CancelButton>
                <CreateButton 
                  style={{margin:'10px 0 10px 24px'}}
                  onClick={onConfirmDeleteClick} 
                  disabled={isDeleting}
                >
                  {t('Đồng ý')}
                </CreateButton>
                {isDeleting && <CircularProgress style={{position:'absolute',top:'10%',left:'45%',color:'#0b59c8'}}/>}
            </DialogContent>
          </Dialog>
        )
    }
    const FailedDialog = () =>{
        return(
          <Dialog 
            open={isFail}
            maxWidth="xl"
          >
            <DialogTitle className={classes.errorColor}>
                <p className={classes.dialogTitle}>{t('Thông báo')}</p>
            </DialogTitle>
            <DialogContent style={{width:'448px',padding:'0',textAlign:'center'}}>
                <p className={classes.contentDialog}>{t('Thêm không thành công, vui lòng thử lại!')}</p>
                <ErrorButton 
                  style={{margin:'10px 0'}}
                  onClick={onAgreeClick} 
                >
                  {t('Đồng ý')}
                </ErrorButton>
            </DialogContent>
          </Dialog>
        )
    }
    const EmptyRowsView = () => {
        return (
          <div
            style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}
          >
            <h3 style={{color:'#6D6D6D',fontSize: '16px'}}>
              {t('Không tìm thấy phòng')}
            </h3>
          </div>
        );
    };
    return (
        <div style={{width:'97%',margin:'auto',height:'100%'}}>
            {dialogDelete()}
            {FailedDialog()}
            {isAdding && <div className={classes.overlay} style={{display:'flex',justifyContent:'center',alignItems:'center'}}> 
                <CircularProgress style={{color:'#0b59c8'}}/>
            </div>}
            <p style={{fontSize:'22px',fontWeight:'bold',paddingTop:'23px'}}>{t('Danh sách phòng')}</p>
            {loading && message.request === '' && 
            <div style={{display:'flex'}}>
                {isAddRoms &&
                    <QuicklyAddRooms apartmentData={apartmentData} changeStatusAddClick={()=>changeStatusAddClick()} addRoomsToDB={(data)=>addRoomsToDB(data)}/>
                }
            </div>}
            {!loading && message.request === ''&& <div style={{width:'100%',height:'250px',display:'flex',justifyContent:'center',alignItems:'center'}}><CircularProgress style={{color:'#0b59c8'}}/></div>}
            {message.request !== '' &&<div className={classes.requestError}>{message.request}</div>}
            <div style={{textAlign:'right',marginBottom:'10px'}}>
              {isFilter && !isAddRoms && loading && 
              <Paper className={clsx(classes.inputHeight,classes.focus)}>
                <div className={classes.inputRightBlock}>
                  <label className={classes.label} style={{marginBottom:'0',marginLeft:'5%'}}>Block</label><br/>
                  <TextField
                    style={{background:'#FFFFFF',width:'90%',marginLeft:'5%'}}
                    variant="outlined"
                    placeholder="Block"
                    size="small"
                    name="block"
                    inputProps={{
                        autoComplete:'off'
                    }}
                    value={typeof(filter.block)!=='undefined'?filter.block:''}
                    onChange={onChange}
                  />     
                </div>
                <div className={classes.inputRightBlock}>
                  <label className={classes.label} style={{marginBottom:'0',marginLeft:'5%'}}>{t('Tầng')}</label> <br />
                  <TextField
                    style={{background:'#FFFFFF',width:'90%',marginLeft:'5%'}}
                    variant="outlined"
                    placeholder={t("Tầng")}
                    size="small"
                    name="floor"
                    inputProps={{
                        autoComplete:'off'
                    }}
                    value={typeof(filter.floor)!=='undefined'?filter.floor:''}
                    onChange={onChange}
                  />        
                </div>
                <div className={classes.inputRightBlock}>
                  <label className={classes.label} style={{marginBottom:'0',marginLeft:'5%'}}>{t('Phòng')}</label><br />
                  <TextField
                    style={{background:'#FFFFFF',width:'90%',marginLeft:'5%'}}
                    variant="outlined"
                    placeholder={t("Phòng")}
                    size="small"
                    name="room"
                    inputProps={{
                        autoComplete:'off'
                    }}
                    value={typeof(filter.room)!=='undefined'?filter.room:''}
                    onChange={onChange}
                  /> 
                </div>
                <div className={classes.inputRightBlock} style={{width:'25%',minWidth:'219px'}}>
                  <br />
                  <CreateButton 
                    variant="contained"
                    style={{marginTop:'12px',height:'32px'}}
                    onClick={onApplyClick}
                    
                  >
                    {t('Áp dụng')}
                  </CreateButton>
                  <CancelButton 
                    variant="outlined"
                    style={{height:'32px',marginLeft:'15px',marginTop:'12px'}}
                    onClick={()=>setFilter({...filter,block:'',floor:'',room:'',username:''})}
                  >
                    {t('filter.Xóa')}
                  </CancelButton>
                </div>
              </Paper>}
            </div>
            {loading && message.request === '' &&
            <div style={{height:'60%'}}>
                <div className={classes.tableTitle}>                   
                    {!isAddRoms && <CreateButton startIcon={<AddCircleOutlineIcon />} variant="contained" style={{marginLeft:'8px',float:'left',marginTop:'7px'}} onClick={changeStatusAddClick}>{t('Thêm mới')}</CreateButton>}
                    <ExportApartmentFile filter={filter}/>
                    <DeleteButton style={{margin:'8px'}} variant="outlined" startIcon={<DeleteOutlineOutlinedIcon />} onClick={onDeleteClick} disabled={ids.length===0} >
                        {t('Xóa')}
                    </DeleteButton>
                    <CancelButton 
                        variant="outlined"     
                        style={{float:'right',margin:'7px 8px 0 0'}}
                        startIcon={<FilterListIcon />} 
                        onClick={()=>setIsFilter(!isFilter)}
                    >
                        {t('Bộ lọc')}
                    </CancelButton>
                    <SuccessAlert message={message.success} onCloseDialog={onCloseDialog} />
                    <ErrorAlert message={message.error} onCloseDialog={onCloseDialog} />
                </div>
                <div style={{ height: '100%', width: '100%'}} className={clsx(classes.root,classes.filter)}>       
                    <DataGrid 
                        componentsProps={{
                            pagination: {
                                labelRowsPerPage: t("Số dòng mỗi trang"),
                                labelDisplayedRows:({from,to,count}) => {return `${from}-${to} ${t('trong số')} ${count}`}
                            }
                        }}
                        components={{
                            NoRowsOverlay:EmptyRowsView
                        }}   
                        localeText={{
                            footerRowSelected: (count) => count +t(" hàng trong bảng đã được chọn")
                        }}
                        rows={filteredData} 
                        columns={columns} 
                        pageSize={rowsPerPage}
                        rowHeight={45}
                        checkboxSelection 
                        rowsPerPageOptions={[10,50,100]}
                        onPageSizeChange={pageSize=>{
                            setRowsPerPage(pageSize);
                            localStorage.setItem('rowsPerPage',pageSize);
                        }}
                        onSelectionModelChange={onSelectedChange}
                        hideFooterRowCount
                        disableSelectionOnClick
                        disableColumnMenu
                        localeText={{columnHeaderSortIconLabel: t('Sắp xếp')}}
                        scrollbarSize={23}
                    />
                </div>
            </div>}
        </div>
    )
}

export default RoomsTable;