import React,{useState,useRef, useEffect} from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography/Typography';
import TextField from '@material-ui/core/TextField/TextField';
import Button from '@material-ui/core/Button/Button';
import { useHistory, Link} from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import md5 from 'md5';
import { loginStyles } from '../../styles/style';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { checkPassword,isEmpty } from '../../validation/CheckForm';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import axiosClient from "../../config/AxiosClient";
import {useTranslation} from 'react-i18next';
import PasswordStrengthBar from 'react-password-strength-bar';
import zxcvbn from 'zxcvbn';

const ForgetPasswordPage = () => {
    const classes = loginStyles();
    const location = useHistory();
    const [isResetPW,setIsResetPW] = useState(true);
    const [isPasswordChanging,setIsPasswordChanging] = useState(false);
    const [isChecking, setIsChecking] = useState(false);
    const [showPassword,setShowPassword] = useState(false);
    const [showRePassword,setShowRePassword] = useState(false);
    const [username,setUsername] = useState('');
    const [password,setPassword] = useState('');
    const [rePassword,setRePassword] = useState('');
    const [code,setCode] = useState('');
    const [alert,setAlert] = useState({success:'',error:'',username:'',password:'',code:'',rePassword:''});
    const {t} = useTranslation();
    const passwordRef = useRef();
    const rePasswordRef = useRef();
    const [email,setEmail] = useState(false);

    useEffect(()=>{
        if(username !== ''){
            var email = username;
            let hide = email.split("@")[0].length - 2;
            var r = new RegExp(".{"+hide+"}@", "g");
            setEmail(email.replace(r, "*****@" ));
        }
    },[username])

    const forgetPassword = async(uname) =>{
        setIsChecking(true);
        try {
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                const res = await axiosClient.post('/cognito/password/forgot',{'username':uname});
                if(res.data.code === 'LimitExceededException'){
                    setAlert({...alert, error: t('Đã quá số lận nhận mã, vui lòng thử lại sau 15 phút'), success:''});
                }else if(res.data.code==='InvalidParameterException'){
                    setAlert({...alert, error: t('Tài khoản chưa hoàn tất đăng nhập lần đầu'), success:''});
                }else if(res.data.code==='NotAuthorizedException'){
                    setAlert({...alert, error: t('Tài khoản đã bị khóa.'), success:''});
                }else{
                    setIsResetPW(!isResetPW);
                    setAlert({...alert,error:'',success:''});
                }
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                await axiosClient.post('/forgetpassword/requestmail',{ uname },{
                    headers:{
                        'Content-Type': 'application/json'
                    }
                });
                setIsResetPW(!isResetPW);
                setAlert({...alert,error:'',success:''});
            }
        } catch (error) {
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                setAlert({ ...alert, error:t('Tài khoản chưa được đăng ký'), success: ''});
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                error?.response?.data?.message === 'UserNotFoundException' ?
                    setAlert({ ...alert, error:t('Tài khoản chưa được đăng ký'), success: '' }) :
                    setAlert({ ...alert, error:t('Đã xảy ra lỗi, vui lòng thử lại!'), success: ''});
            }
        }
        setIsChecking(false);
    }

    const forgetPasswordSubmit = async(uname,pin_code,new_password)=>{
        try {
            setIsPasswordChanging(true);
            setIsChecking(true);
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                const res = await axiosClient.post('/cognito/password/confirm',{'username':uname,'code':pin_code,'new_password':new_password});
                if(res.data.code==='LimitExceededException'){
                    setAlert({ ...alert, error: t('Bạn đã nhập sai quá số lần quy định, vui lòng thử lại sau 15 phút')});
                }else if(res.data.code==='CodeMismatchException'){
                    setAlert({ ...alert, error: t('Mã không chính xác') });
                }else if(res.data.code==='ExpiredCodeException'){
                    setAlert({ ...alert, error: t('Mã đã quá hạn') });
                }else{
                    location.push('/login');
                }
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                await axiosClient.post('/forgetpassword/verifymail',{ uname, otp: pin_code, password: new_password },{
                    headers:{
                        'Content-Type': 'application/json'
                    }
                });
                location.push('/login');
            }
        } catch (err) {
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                if(typeof(err.response) !== 'undefined'){
                    if(err.response.data.code==='ExpiredCodeException'){
                        setAlert({...alert,error:t('Mã đã hết hạn')});
                    }else if(err.response.data.code === 'NotAuthorizedException'){
                        setAlert({...alert,error:t('Tài khoản không được ủy quyền')});
                    }else if(err.response.data.code === 'CodeMismatchException'){
                        setAlert({...alert,error:t('Mã không chính xác')});
                    }else{
                        setAlert({...alert,error:t('Bạn đã nhập sai quá số lần quy định, vui lòng thử lại sau 15 phút')});
                    }
                }else{
                    setAlert({...alert,error:t('Đã xảy ra lỗi, vui lòng thử lại!')});
                }
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                err?.response?.data?.message === 'CodeMismatchException' ?
                    setAlert({ ...alert, error: t('Mã không chính xác') }) :
                    setAlert({ ...alert, error: t('Mã đã quá hạn') });
            }

        }
        setIsChecking(false);
        setIsPasswordChanging(false);
    }

    const onUsernameChange = (e) =>{
        var value = e.target.value;
        setUsername(value);
        value === ''? setAlert({...alert,username:t('Không được để trống')}):setAlert({...alert,username:''});
    }

    const onPasswordChange = (e)=>{
        var value = e.target.value;
        setPassword(value);
        if(isEmpty(value)){
            setAlert({...alert,password:t('Không được để trống')});
        }else{
            setAlert({...alert,password:'',error:''});
        }
        if(passwordRef.current) clearTimeout(passwordRef.current);
        passwordRef.current = setTimeout(() => {
            const result = zxcvbn(value);
            if(result.feedback.warning !== '') return setAlert({...alert,password:t('Đã trùng mật khẩu phổ biến')});
            if(rePassword === '') return;
            if(value === rePassword) setAlert({...alert,rePassword:''});
            else setAlert({...alert,rePassword:t('Mật khẩu không giống nhau')});
        }, 500);
    }
    const onRePasswordChange = (e)=>{
        var value = e.target.value;
        setRePassword(value);
        if(isEmpty(value)){
            setAlert({...alert,rePassword:t('Không được để trống')});
        }else{
            setAlert({...alert,rePassword:''});
            if(rePasswordRef.current) clearTimeout(rePasswordRef.current);
            rePasswordRef.current = setTimeout(() => {
                if(value !== password) setAlert({...alert,rePassword:t('Mật khẩu không giống nhau')});
            }, 500);
        }
        
    }
    const onCodeChange = (e) =>{
        var value = e.target.value;
        setCode(value);
        value === ''? setAlert({...alert,code:t('Không được để trống')}):setAlert({...alert,code:''});
    }
    const onSendCodeClick = () =>{
        if(username.trim() === ''){
            setAlert({...alert,username:t('Không được để trống')});
            return;
        }
        forgetPassword(username);
    }
    const onSubmitClick = () =>{
        if(isEmpty(code)) return setAlert({...alert,code:t('Không được để trống')});
        if(isEmpty(password)) return setAlert({...alert,password:t('Không được để trống')});
        if(!checkPassword(password)) return setAlert({...alert,error:t('Mật khẩu tối thiểu 8 ký tự bao gồm chữ HOA, chữ thường, số và ký tự đặc biệt')});
        if(alert.password !== '' || alert.rePassword !== '') return;
        if(rePassword !== password) return setAlert({...alert,rePassword:t('Mật khẩu không giống nhau')});
        forgetPasswordSubmit(username,code,md5(password));
    }

    const handleClickShowPassword = () =>{
        setShowPassword(!showPassword);
    }
    const handleClickShowRePassword = () =>{
        setShowRePassword(!showRePassword);
    }
    

    const resetForm = ()=>{
        return(
        <Paper className={clsx(classes.paper)} >
            <Typography variant="h3"  className={classes.title}>{t('Quên mật khẩu')}</Typography>
            <Divider style={{width:'80px',textAlign:'center',height:'5px',background:'#072A3B',margin:'auto',marginBottom:'10px'}} />
            <div className={classes.createNew}>
                {alert.error !=='' && <p style={{color:'red'}}>{alert.error}</p>}    
            </div>
            <div className={classes.inputBlock}>
                <label className={classes.label}>{t('Tên đăng nhập')} <span style={{color:'red'}}>(*)</span></label>
                <TextField 
                    classes={{
                        root:classes.input
                    }}
                    name='username' 
                    variant="outlined" 
                    placeholder={t("Nhập tên đăng nhập của bạn")}
                    onChange={onUsernameChange}
                    error={alert.username.length > 0}
                    helperText={alert.username}
                />
            </div>
            <div className={classes.action}>
                <Button 
                    variant="contained"  
                    color="primary" 
                    className={classes.signinAction} 
                    onClick={onSendCodeClick}
                    disabled={isChecking}
                >
                   {t('Gửi mã')}
                   {isChecking && <CircularProgress style={{position:'absolute',textAlign:'center',color:'#0b59c8'}} />}
                </Button>
                <Link to="/login" className={classes.forgetPassword}>{t('Trở về trang đăng nhập')}</Link>
            </div>
        </Paper>
        )
    }

    const changePasswordForm = () =>{
        return(
        <Paper className={clsx(classes.paper,classes.divider)} >
            <Typography variant="h3"  className={classes.title}>{t('Quên mật khẩu')}</Typography>
            <Divider style={{width:'80px',textAlign:'center',height:'5px',background:'#072A3B',margin:'auto',marginBottom:'10px'}} />
            <div className={classes.createNew}>
                {alert.error !=='' && <p style={{color:'red',fontSize:'14px'}}>{alert.error}</p>      }    
                {alert.success !=='' && <p style={{color:'green'}}>{alert.success}</p>      }    
            </div> 
            <div className={classes.inputBlock} style={{height:'125px'}}>
                <label className={classes.label}>
                    <p >{t('Mã xác thực đã được gửi đến ') + email}</p>
                    {t('Mã xác thực')} <span style={{color:'red'}}>(*)</span>        
                </label>
                
                <TextField 
                    classes={{
                        root:classes.input
                    }}
                    name='code' 
                    variant="outlined" 
                    placeholder={t('Nhập mã xác thực')}
                    onChange={onCodeChange}
                />
                <p style={{color:'#f44336',paddingTop:'3px',fontSize:'0.75rem'}}>{alert.code}</p>
            </div>
            <div className={classes.inputBlock} style={{height:'117px'}}>
                <label className={classes.label}>{t('Mật khẩu mới')} <span style={{color:'red'}}>(*)</span></label>
                <TextField 
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                >
                                   {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    classes={{
                        root:classes.input
                    }}
                    name='new_password' 
                    variant="outlined" 
                    placeholder={t("Nhập mật khẩu mới")}
                    type={showPassword?"text":"password"}
                    onChange={onPasswordChange}
                    error={alert.password.length > 0}
                />
                {password!=='' && 
                <PasswordStrengthBar 
                    scoreWords={['','','','']} 
                    shortScoreWord='' 
                    password={password} 
                />}
                <p style={{color:'#f44336',paddingTop:'3px',fontSize:'0.75rem'}}>{alert.password}</p>
            </div>
            <div className={classes.inputBlock} style={{height:'90px'}}>
                <label className={classes.label}>{t('Nhập lại mật khẩu')} <span style={{color:'red'}}>(*)</span></label>
                <TextField 
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowRePassword}
                                >
                                   {showRePassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    classes={{
                        root:classes.input
                    }}
                    name='re_password' 
                    variant="outlined" 
                    placeholder={t("Nhập lại mật khẩu")}
                    type={showRePassword?"text":"password"}
                    onChange={onRePasswordChange}
                    error={alert.rePassword !== ''}
                />
                <p style={{color:'#f44336',paddingTop:'3px',fontSize:'0.75rem'}}>{alert.rePassword}</p>
            </div>
            <div className={classes.action}>
                <Button 
                    variant="contained" 
                    color="primary" 
                    className={classes.signinAction} 
                    onClick={onSubmitClick}
                    disabled={isChecking}
                >
                    {t('Gửi')}
                   {isChecking && <CircularProgress style={{position:'absolute',textAlign:'center',color:'#0b59c8'}} />}
                </Button>
                <Link to="/login" className={classes.forgetPassword}>{t('Trở về trang đăng nhập')}</Link>
            </div>
        </Paper>
        )
    }

    return (
        <div style={{height:'100vh',width:'100%',overflow:'hidden',display:'flex',justifyContent:'center',alignItems:'center',minWidth:'500px'}}>
            {isPasswordChanging && <div className={classes.overlay}></div>}
            {isResetPW && resetForm()}
            {!isResetPW && changePasswordForm()}
        </div>
    )
}

export default ForgetPasswordPage;
