import { FaceError } from "../../../config/enum";

export const checkFaceImage = (reason) => {
    var text = '';
    switch (reason) {
        case FaceError.IMAGE_IS_NONE:
            text = 'Hình ảnh không có';
            break;
        case FaceError.INVALID_IMAGE:
            text = 'Hình ảnh không hợp lệ';
            break;
        case FaceError.CANNOT_DETECT_FACE:
            text = 'Không thể phát hiện khuôn mặt';
            break;
        case FaceError.BLURRY_IMAGE:
            text = 'Hình ảnh mờ';
            break;
        case FaceError.DUPLICATED:
            text = 'Khuôn mặt đã bị trùng';
            break;
        case FaceError.MORE_THAN_ONE_FACE_IN_FRAME:
            text = 'Nhiều khuôn mặt trong một hình';
            break;
        case FaceError.NO_FACE_LARGE_ENOUGH:
            text = 'Khuôn mặt không đủ lớn';
            break;
        case FaceError.INVALID_FACE:
            text = 'Khuôn mặt không hợp lệ';
            break;
        case FaceError.HOST_EXISTS:
            text = 'Trùng chủ hộ';
            break;
        case FaceError.CAN_NOT_FIND_OUT_MAIN_RESIDENT:
            text = 'Phòng chưa có chủ hộ';
            break;
        case FaceError.EMAIL_EXISTS:
            text = 'Email đã tồn tại';
            break;
        default: 
            if (reason.match('Can not find out Resident*')) {
                text = 'Không tìm thấy cư dân';
            } else {
                text = 'Đã xảy ra lỗi, vui lòng thử lại!';
            }
            break;
        
    }
    return text;
}