import React, { useState, useEffect, useRef, useCallback } from 'react';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton/IconButton';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button/Button';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import axiosClient from '../../../config/AxiosClient';
import Cookies from 'js-cookie';
import { checkImage, isEmpty } from '../../../validation/CheckForm';
import Alert from '@material-ui/lab/Alert';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import clsx from 'clsx';
import { apiSmartcameraDeviceCreate } from '../../../api/smartcamera';
import { FONT_FAMILY } from '../../../constants/cssCommon';

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '20px',
        fontWeight: '700',
        lineHeight: '27px',
        color: '#000000',
        fontFamily: FONT_FAMILY,
        fontStyle: 'normal',
        float: 'left',
        marginRight: '24px',
        paddingTop: '5px'
    },
    inputBlock: {
        paddingLeft: '22px',
        position: 'relative',
        height: '90px'
    },
    label: {
        fontSize: '16px',
        lineHeight: '140%',
        color: '#000000',
        display: 'inline-block',
        width: '250px',
        margin: '0'
    },
    alert: {
        position: 'absolute',
        right: '3px',
        top: '3px',
        width: '350px'
    },
    secondaryTitle: theme.secondary_title,
    overlay: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        background: 'rgba(0, 0, 0, 0.1)',
        zIndex: '1300'
    },
    primaryColor: theme.primaryTextColor,
    dialogTitle: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '25px',
        color: '#FFFFFF',
        fontFamily: FONT_FAMILY
    },
    contentDialog: {
        fontSize: '16px',
        height: '68px',
        borderBottom: '1px solid #EEEDF2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    dialogTitleBackground: theme.primaryColor,
    errorColor: theme.errorColor,
    imageDialog: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: '0', left: '0',
        background: 'rgba(0, 0, 0, 0.1)',
        zIndex: '1333',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '550px'
    },
    addButtonStyle: {
        margin: '0'
    },
    heightAuto: {
        height: 'auto'
    },
    positionRelative: {
        position: 'relative'
    },
    arrowBackIconStyle: {
        width: '28px',
        height: '28px'
    },
    inputStyle: {
        width: '50%',
        minWidth: '320px'
    }
}))

const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);
const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);
const ErrorButton = withStyles(theme => ({ root: theme.error_button }))(Button);

const AddSamrtCamera = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [camera, setCamera] = useState({ camera_name: '', rtsp_link: '', mqtt_topic_prefix: '', device_token: '' });
    const [alert, setAlert] = useState({ camera_name: '', rtsp_link: '', mqtt_topic_prefix: '', device_token: '', request: '' });
    const [isAdding, setIsAdding] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [isSuccessDialog, setIsSuccessDialog] = useState(false);
    const [isExpiredSession, setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (isExpiredSession) {
            history.push('/login');
            dispatch({ type: 'IS_LOGIN_AGAIN_TRUE' });
        }
    }, [isExpiredSession]);

    const onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setCamera({ ...camera, [name]: value })
        if (value.trim() === '') setAlert({ ...alert, [name]: t('Không được để trống') });
        else setAlert({ ...alert, [name]: '' });
    }

    const onAddCameraClick = async () => {
        if (isEmpty(camera.camera_name)) return setAlert({ ...alert, camera_name: t('Không được để trống') });
        if (alert.camera_name !== '') return;
        if (isEmpty(camera.mqtt_topic_prefix)) return setAlert({ ...alert, mqtt_topic_prefix: t('Không được để trống') });
        if (alert.mqtt_topic_prefix !== '') return;
        if (isEmpty(camera.device_token)) return setAlert({ ...alert, device_token: t('Không được để trống') });
        if (alert.device_token !== '') return;
        if (isEmpty(camera.rtsp_link)) return setAlert({ ...alert, rtsp_link: t('Không được để trống') });
        if (alert.rtsp_link !== '') return;
        try {
            setIsAdding(true);
            await apiSmartcameraDeviceCreate(camera);
            setIsSuccessDialog(true);
        } catch (error) {
            if (error.response ?.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setIsFailed(true);
        }
        setIsAdding(false)
    }

    const FailedDialog = () => {
        return (
            <Dialog
                open={isFailed}
                maxWidth="xl"
            >
                <DialogTitle className={classes.errorColor}>
                    <p className={classes.dialogTitle}>{t('Thông báo')}</p>
                </DialogTitle>
                <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center' }}>
                    <p className={classes.contentDialog}>{t('Thêm máy ảnh không thành công, vui lòng thử lại!')}</p>
                    <ErrorButton
                        style={{ margin: '10px 0' }}
                        onClick={() => setIsFailed(false)}
                    >
                        {t('Đồng ý')}
                    </ErrorButton>
                </DialogContent>
            </Dialog>
        )
    }

    const successDialog = () => {
        return (
            <Dialog
                open={isSuccessDialog}
                maxWidth="xl"
            >
                <DialogTitle className={classes.dialogTitleBackground}>
                    <p className={classes.dialogTitle}>{t("Thông báo")}</p>
                </DialogTitle>
                <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center' }}>
                    <p className={classes.contentDialog}>{t("Thêm máy ảnh thành công!")}</p>
                    <CancelButton
                        variant="outlined"
                        style={{ margin: '10px 0' }}
                        onClick={() => history.push('/admin/smart-camera/settings')}
                    >
                        {t("Đồng ý")}
                    </CancelButton>
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <div className={classes.positionRelative}>
            {successDialog()}
            {FailedDialog()}
            {isAdding && <div className={classes.overlay}></div>}
            <div style={{ paddingBottom: '8px', marginTop: '23px' }}>
                <IconButton
                    style={{
                        float: 'left',
                        marginLeft: '15px',
                        padding: '2px',
                        marginRight: '5px'
                    }}
                    component={Link}
                    to="/admin/smart-camera/settings"
                >
                    <ArrowBackIosOutlinedIcon className={clsx(classes.arrow, classes.primaryColor)} />
                </IconButton>
                <p className={classes.secondaryTitle}>{t("Thêm máy ảnh")}</p>
            </div>
            <div>
                <div className={classes.inputBlock}>
                    <label className={classes.label}>{t('Tên máy ảnh')} </label><br />
                    <TextField
                        placeholder={t('Tên máy ảnh')}
                        inputProps={{ style: { fontSize: '18px' } }}
                        variant="outlined"
                        className={classes.inputStyle}
                        size="small"
                        name="camera_name"
                        value={camera.camera_name}
                        onChange={onChange}
                        disabled={isAdding}
                        error={alert.camera_name !== ''}
                        helperText={alert.camera_name}
                    />
                </div>
                <div className={classes.inputBlock}>
                    <label className={classes.label}>{t('Tiền tố MQTT')} </label><br />
                    <TextField
                        placeholder={t('Tiền tố MQTT')}
                        inputProps={{ style: { fontSize: '18px' } }}
                        variant="outlined"
                        className={classes.inputStyle}
                        size="small"
                        name="mqtt_topic_prefix"
                        value={camera.mqtt_topic_prefix}
                        onChange={onChange}
                        disabled={isAdding}
                        error={alert.mqtt_topic_prefix !== ''}
                        helperText={alert.mqtt_topic_prefix}
                    />
                </div>
                <div className={classes.inputBlock}>
                    <label className={classes.label}>{t('Mã thiết bị')} </label><br />
                    <TextField
                        placeholder={t('Mã thiết bị')}
                        inputProps={{ style: { fontSize: '18px' } }}
                        variant="outlined"
                        className={classes.inputStyle}
                        size="small"
                        name="device_token"
                        value={camera.device_token}
                        onChange={onChange}
                        disabled={isAdding}
                        error={alert.device_token !== ''}
                        helperText={alert.device_token}
                    />
                </div>
                <div className={classes.inputBlock} style={{ height: '130px' }}>
                    <label className={classes.label}>{t('RTSP')} </label><br />
                    <TextField
                        multiline
                        rows={3}
                        placeholder={t('RTSP')}
                        inputProps={{ style: { fontSize: '18px' } }}
                        variant="outlined"
                        className={classes.inputStyle}
                        size="small"
                        name="rtsp_link"
                        value={camera.rtsp_link}
                        onChange={onChange}
                        disabled={isAdding}
                        error={alert.rtsp_link !== ''}
                        helperText={alert.rtsp_link}
                        helperText={alert.rtsp_link}
                    />
                </div>
            </div>
            <div className={clsx(classes.inputBlock, classes.heightAuto)}>
                <CreateButton
                    variant="contained"
                    className={classes.addButtonStyle}
                    onClick={onAddCameraClick}
                    disabled={isAdding}
                >
                    {t('Thêm')}
                    {isAdding &&
                        <CircularProgress
                            size={30}
                            style={{ position: 'absolute' }}
                        />}
                </CreateButton>
            </div>
        </div>
    )
}

export default AddSamrtCamera;