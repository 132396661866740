import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import Button from '@material-ui/core/Button/Button';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Cookies from 'js-cookie';
import SuccessAlert from '../../alert/SuccessAlert';
import ErrorAlert from '../../alert/ErrorAlert';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { configurations } from '../../../config/Registration';
import Paper from '@material-ui/core/Paper';
import { styled } from '@mui/material/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { apiGetconfig } from '../../../api/getconfig';
import { apiUpdateconfig } from '../../../api/updateconfig';
import clsx from 'clsx';
import { PaymentConfiguration } from '../../../config/enum';

const useStyles = makeStyles((theme) => ({
    primaryTextColor: theme.primaryTextColor,
    label: {
        margin: '0',
        fontSize: '18px',
        lineHeight: '140%',
        color: '#000000',
        width: '80%',
        display: 'flex',
        alignItems: 'end',
    },
    contentDialog: {
        fontSize: '16px',
        height: '68px',
        borderBottom: '1px solid #EEEDF2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    dialogTitle: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '25px',
        color: '#FFFFFF',
    },
    dialogTitleBackground: theme.primaryColor,
    switchStyle: {
        width: '20%',
        '& .Mui-checked+.MuiSwitch-track': {
            backgroundColor: '#0b59c8',
            opacity: 0.5,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    },
    select: {
        '& .MuiOutlinedInput-input': {
            padding: '6px 12px',
        },
    },
    inputBlockStyle: {
        display: 'flex',
        width: '100%',
        marginTop: '15px',
    },
    inputStyle: {
        maxHeight: '32px',
        background: '#FFFFFF',
        width: '100%',
    },
    actionBlockStyle: {
        textAlign: 'right',
        width: '100%',
        padding: '20px 0',
    },
    errorStyle: {
        color: 'red',
        position: 'absolute',
        fontSize: '11px',
        bottom: '-17px',
    },
    formControlStyle: {
        position: 'relative',
        padding: '0!important',
        width: '100%',
    },
    paperStyle: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        background: 'rgb(250 250 250)',
        padding: '10px',
        borderRadius: '6px',
    },
    titleStyle: {
        fontSize: '22px',
        fontWeight: 'bold',
        padding: '23px 0 10px 16px',
    },
    contentStyle: {
        width: '97%',
        margin: 'auto',
        display: 'flex',
        flexWrap: 'wrap',
    },
    switchbtn: {
        float: 'right',
    },
    circularProgressBlockStyle: {
        width: '100%',
        height: '80%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const SwitchUI = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-track': {
        opacity: 0.5,
        backgroundColor: '#0b59c8',
    },
}));

const CreateButton = withStyles((theme) => ({ root: theme.create_button }))(
    Button
);
const CancelButton = withStyles((theme) => ({ root: theme.cancel_button }))(
    Button
);
const DeleteButton = withStyles((theme) => ({ root: theme.delete_button }))(
    Button
);

const Configuration = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const username = 'tsmart-building';
    const [isEdit, setIsEdit] = useState(false);
    const [configs, setConfigs] = useState({});
    const [saveConfig, setSaveConfig] = useState({});
    const [alert, setAlert] = useState({
        success: '',
        error: '',
        age: '',
    });
    const [isConfirm, setIsConfirm] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isExpiredSession, setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const alertRef = React.useRef();

    useEffect(() => {
        if (isExpiredSession) {
            history.push('/login');
            dispatch({ type: 'IS_LOGIN_AGAIN_TRUE' });
        }
    }, [isExpiredSession]);

    const getDefaultValues = async () => {
        try {
            let res = await apiGetconfig();
            setConfigs(
                res.data.Items[0].Configuration
                    ? res.data.Items[0].Configuration
                    : configurations
            );
            setAlert({ ...alert, age: '' });
        } catch (error) {
            if (
                typeof error.response !== 'undefined' &&
                error.response.data === 'Invalid LoginToken'
            )
                return setIsExpiredSession(true);
            setConfigs(configurations);
        }
        setLoading(true);
    };

    useEffect(() => {
        if (!loading) getDefaultValues();
    }, [loading]);

    const onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setConfigs({ ...configs, [name]: parseInt(value) });
        setSaveConfig({ ...saveConfig, [name]: value });
    };

    const onAllowedFaceRegistrationWithMaskChange = (e) => {
        const value = e.target.checked;
        setConfigs({ ...configs, allowFaceMaskRegistration: value });
        setSaveConfig({ ...saveConfig, allowFaceMaskRegistration: value });
    };

    function onBooleanChange(e) {
        const name = e.target.name;
        const value = e.target.value;
        setConfigs({ ...configs, [name]: value });
        setSaveConfig({ ...saveConfig, [name]: value });
    }

    const onAgeChange = (e) => {
        const age = e.target.value;
        setConfigs({ ...configs, CMND_threshold: parseInt(age) });
        setSaveConfig({ ...saveConfig, CMND_threshold: parseInt(age) });
        if (age === '') setAlert({ ...alert, age: t('Không được để trống') });
        else if (parseInt(age) < 14)
            setAlert({ ...alert, age: t('Tuổi phải lớn hơn 14') });
        else setAlert({ ...alert, age: '' });
    };

    const allowResidentUpdateFaceId = (e) => {
        const value = e.target.value;
        setConfigs({ ...configs, allowResidentUpdateFaceId: parseInt(value) });
        setSaveConfig({ ...saveConfig, allowResidentUpdateFaceId: parseInt(value) })
    };

    const onEditClick = () => {
        setIsEdit(true);
    };

    const onCancelClick = () => {
        setIsEdit(false);
        setLoading(false);
    };

    const onSaveClick = () => {
        if (configs.CMND === 1) {
            if (isNaN(parseInt(configs.CMND)))
                return setAlert({ ...alert, age: t('Không được để trống') });
            if (alert.age !== '') return;
        }
        setIsConfirm(true);
    };

    const onConfirmSaveClick = async () => {
        try {
            setIsProcessing(true);
            const data = {
                username: username,
                configuration: saveConfig,
            };
            await apiUpdateconfig(data);
            setIsEdit(!isEdit);
            setAlert({
                ...alert,
                success: t('Lưu cấu hình thành công!'),
                error: '',
            });
            setLoading(false);
        } catch (error) {
            if (
                typeof error.response !== 'undefined' &&
                error.response.data === 'Invalid LoginToken'
            )
                return setIsExpiredSession(true);
            setAlert({
                ...alert,
                success: '',
                error: t('Đã xảy ra lỗi, vui lòng thử lại!'),
            });
        }
        setIsConfirm(false);
        setIsProcessing(false);
    };

    const onCloseDialog = () => {
        setAlert({ ...alert, success: '', error: '' });
    };

    useEffect(() => {
        if (alert.success !== '' || alert.error !== '') {
            if (alertRef.current) clearTimeout(alertRef.current);
            alertRef.current = setTimeout(() => {
                onCloseDialog();
            }, 5000);
        }
    }, [alert]);

    const confirmSaveDialog = () => {
        return (
            <Dialog open={isConfirm} maxWidth='xl'>
                <DialogTitle className={classes.dialogTitleBackground}>
                    <p className={classes.dialogTitle}>{t('Thông báo')}</p>
                </DialogTitle>
                <DialogContent
                    style={{
                        width: '448px',
                        padding: '0',
                        textAlign: 'center',
                        position: 'relative',
                    }}
                >
                    <p className={classes.contentDialog}>
                        {t('Bạn có chắc chắn muốn lưu thay đổi?')}
                    </p>
                    <CancelButton
                        style={{ marginLeft: '30px' }}
                        onClick={() => setIsConfirm(false)}
                        disabled={isProcessing}
                    >
                        {t('Trở về')}
                    </CancelButton>
                    <CreateButton
                        style={{ margin: '10px 20px' }}
                        onClick={onConfirmSaveClick}
                        disabled={isProcessing}
                    >
                        {t('Đồng ý')}
                    </CreateButton>
                    {isProcessing && (
                        <CircularProgress
                            style={{
                                position: 'absolute',
                                top: '10%',
                                left: '45%',
                                color: '#0b59c8',
                            }}
                        />
                    )}
                </DialogContent>
            </Dialog>
        );
    };
    return (
        <div style={{ height: '100%' }}>
            {confirmSaveDialog()}
            <SuccessAlert
                message={alert.success}
                onCloseDialog={onCloseDialog}
            />
            <ErrorAlert message={alert.error} onCloseDialog={onCloseDialog} />
            <p className={classes.titleStyle}>{t('Cấu hình cư dân')}</p>
            {!loading && (
                <div className={classes.circularProgressBlockStyle}>
                    <CircularProgress style={{ color: '#0b59c8' }} />
                </div>
            )}
            {loading && (
                <div className={classes.contentStyle}>
                    <Paper
                        className={clsx(
                            classes.switchStyle,
                            classes.paperStyle
                        )}
                    >
                        <div className={classes.inputBlockStyle}>
                            <p className={classes.label}>
                                {t('Face ID khi đăng ký')}
                            </p>
                            <div className={classes.switchStyle}>
                                <FormControl
                                    required
                                    variant='outlined'
                                    className={clsx(
                                        classes.select,
                                        classes.formControlStyle
                                    )}
                                >
                                    <Select
                                        inputProps={{
                                            style: {
                                                padding: '6px 10px',
                                            },
                                        }}
                                        name='faceid'
                                        disabled={!isEdit}
                                        value={configs.faceid}
                                        className={classes.inputStyle}
                                        onChange={onChange}
                                    >
                                        <MenuItem value={-1}>
                                            <p className={classes.placeholder}>
                                                {t('Ẩn')}
                                            </p>
                                        </MenuItem>
                                        <MenuItem value={0}>
                                            <p className={classes.placeholder}>
                                                {t('Không bắt buộc')}
                                            </p>
                                        </MenuItem>
                                        <MenuItem value={1}>
                                            <p className={classes.placeholder}>
                                                {t('Bắt buộc')}
                                            </p>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className={classes.inputBlockStyle}>
                            <p className={classes.label}>{t('Xóa Face ID')}</p>
                            <div className={classes.switchStyle}>
                                <FormControl
                                    required
                                    variant='outlined'
                                    className={clsx(
                                        classes.select,
                                        classes.formControlStyle
                                    )}
                                >
                                    <Select
                                        inputProps={{
                                            style: {
                                                padding: '6px 10px',
                                            },
                                        }}
                                        name='remove_faceid'
                                        disabled={!isEdit}
                                        value={configs.remove_faceid}
                                        className={classes.inputStyle}
                                        onChange={onBooleanChange}
                                    >
                                        <MenuItem value={-1}>
                                            <p className={classes.placeholder}>
                                                {t('Ẩn')}
                                            </p>
                                        </MenuItem>
                                        <MenuItem value={2}>
                                            <p className={classes.placeholder}>
                                                {t('Hiển thị')}
                                            </p>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className={classes.inputBlockStyle}>
                            <p
                                className={classes.label}
                                style={{ width: '58%' }}
                            >
                                {t('CMND/CCCD/Passport khi đăng ký')}
                            </p>
                            <div className={classes.switchStyle}>
                                {configs.CMND === 1 && (
                                    <TextField
                                        inputProps={{
                                            autoComplete: 'off',
                                            style: { height: '10px' },
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    {t('Tuổi')}
                                                </InputAdornment>
                                            ),
                                        }}
                                        disabled={!isEdit}
                                        type='number'
                                        className={classes.inputStyle}
                                        size='small'
                                        placeholder={t('yêu cầu')}
                                        variant='outlined'
                                        value={configs.CMND_threshold}
                                        onChange={onAgeChange}
                                        error={alert.age !== ''}
                                    />
                                )}
                                {configs.CMND === 1 && (
                                    <p className={classes.errorStyle}>
                                        {alert.age}
                                    </p>
                                )}
                            </div>
                            <div style={{ width: '2%' }}></div>
                            <div className={classes.switchStyle}>
                                <FormControl
                                    required
                                    variant='outlined'
                                    className={clsx(
                                        classes.select,
                                        classes.formControlStyle
                                    )}
                                >
                                    <Select
                                        inputProps={{
                                            style: {
                                                padding: '6px 10px',
                                            },
                                        }}
                                        name='CMND'
                                        disabled={!isEdit}
                                        value={configs.CMND}
                                        className={classes.inputStyle}
                                        onChange={onchange}
                                    >
                                        <MenuItem value={-1}>
                                            <p className={classes.placeholder}>
                                                {t('Ẩn')}
                                            </p>
                                        </MenuItem>
                                        <MenuItem value={0}>
                                            <p className={classes.placeholder}>
                                                {t('Không bắt buộc')}
                                            </p>
                                        </MenuItem>
                                        <MenuItem value={1}>
                                            <p className={classes.placeholder}>
                                                {t('Bắt buộc')}
                                            </p>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className={classes.inputBlockStyle}>
                            <p className={classes.label}>
                                {t(
                                    'Thông báo cho cư dân khi đăng ký được duyệt hoặc bị từ chối'
                                )}
                            </p>
                            <div className={classes.switchStyle}>
                                <FormControl
                                    required
                                    variant='outlined'
                                    className={clsx(
                                        classes.select,
                                        classes.formControlStyle
                                    )}
                                >
                                    <Select
                                        inputProps={{
                                            style: {
                                                padding: '6px 10px',
                                            },
                                        }}
                                        name='notification'
                                        disabled={!isEdit}
                                        value={configs.notification}
                                        className={classes.inputStyle}
                                        onChange={onChange}
                                    >
                                        <MenuItem value={0}>
                                            <p className={classes.placeholder}>
                                                {t('Hỏi khi thực hiện')}
                                            </p>
                                        </MenuItem>
                                        <MenuItem value={-1}>
                                            <p className={classes.placeholder}>
                                                {t('Tắt')}
                                            </p>
                                        </MenuItem>
                                        <MenuItem value={1}>
                                            <p className={classes.placeholder}>
                                                {t('Bật')}
                                            </p>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className={classes.inputBlockStyle}>
                            <p className={classes.label}>
                                {t('Đăng ký thêm căn hộ trên T-Building')}
                            </p>
                            <div className={classes.switchStyle}>
                                <FormControl
                                    required
                                    variant='outlined'
                                    className={clsx(
                                        classes.select,
                                        classes.formControlStyle
                                    )}
                                >
                                    <Select
                                        inputProps={{
                                            style: {
                                                padding: '6px 10px',
                                            },
                                        }}
                                        name='add_more_apartment'
                                        disabled={!isEdit}
                                        value={configs.add_more_apartment}
                                        className={classes.inputStyle}
                                        onChange={onBooleanChange}
                                    >
                                        <MenuItem value={-1}>
                                            <p className={classes.placeholder}>
                                                {t('Ẩn')}
                                            </p>
                                        </MenuItem>
                                        <MenuItem value={2}>
                                            <p className={classes.placeholder}>
                                                {t('Hiển thị')}
                                            </p>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className={classes.inputBlockStyle}>
                            <p className={classes.label}>
                                {t(
                                    'Cho phép đăng ký Face ID khi đeo khẩu trang'
                                )}
                            </p>
                            <div className={classes.switchStyle}>
                                <div className={classes.switchbtn}>
                                    <FormControl>
                                        <Switch
                                            disabled={!isEdit}
                                            checked={configs.allowFaceMaskRegistration}
                                            onChange={onAllowedFaceRegistrationWithMaskChange}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <div className={classes.inputBlockStyle}>
                            <p className={classes.label}>
                                {t('Cho phép thay đổi Face ID')}
                            </p>
                            <div className={classes.switchStyle}>
                                <FormControl
                                    required
                                    variant='outlined'
                                    className={clsx(
                                        classes.select,
                                        classes.formControlStyle
                                    )}
                                >
                                    <Select
                                        defaultValue={4}
                                        inputProps={{
                                            style: {
                                                padding: '6px 10px',
                                            },
                                        }}
                                        name='allowResidentUpdateFaceId'
                                        disabled={!isEdit}
                                        value={configs.allowResidentUpdateFaceId}
                                        className={classes.inputStyle}
                                        onChange={allowResidentUpdateFaceId}
                                    >
                                        <MenuItem value={3}>
                                            <p className={classes.placeholder}>
                                                {t('Cho phép')}
                                            </p>
                                        </MenuItem>
                                        <MenuItem value={4}>
                                            <p className={classes.placeholder}>
                                                {t('Cần duyệt')}
                                            </p>
                                        </MenuItem>
                                        <MenuItem value={5}>
                                            <p className={classes.placeholder}>
                                                {t('Không cho phép')}
                                            </p>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className={classes.inputBlockStyle}>
                            <p className={classes.label}>
                                {t('Chức năng camera an ninh trên T-Building')}
                            </p>
                            <div className={classes.switchStyle}>
                                <FormControl
                                    required
                                    variant='outlined'
                                    className={clsx(
                                        classes.select,
                                        classes.formControlStyle
                                    )}
                                >
                                    <Select
                                        inputProps={{
                                            style: {
                                                padding: '6px 10px',
                                            },
                                        }}
                                        name='security_camera'
                                        disabled={!isEdit}
                                        value={configs.security_camera}
                                        className={classes.inputStyle}
                                        onChange={onBooleanChange}
                                    >
                                        <MenuItem value={PaymentConfiguration.HIDE}>
                                            <p className={classes.placeholder}>
                                                {t('Ẩn')}
                                            </p>
                                        </MenuItem>
                                        <MenuItem value={PaymentConfiguration.SHOW}>
                                            <p className={classes.placeholder}>
                                                {t('Hiển thị')}
                                            </p>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className={classes.inputBlockStyle}>
                            <p className={classes.label}>
                                {t('Chức năng tạo QR Code trên T-Building')}
                            </p>
                            <div className={classes.switchStyle}>
                                <FormControl
                                    required
                                    variant='outlined'
                                    className={clsx(
                                        classes.select,
                                        classes.formControlStyle
                                    )}
                                >
                                    <Select
                                        inputProps={{
                                            style: {
                                                padding: '6px 10px',
                                            },
                                        }}
                                        name='create_open_door_qr_code'
                                        disabled={!isEdit}
                                        value={configs.create_open_door_qr_code}
                                        className={classes.inputStyle}
                                        onChange={onBooleanChange}
                                    >
                                        <MenuItem value={PaymentConfiguration.ALLOW}>
                                            <p className={classes.placeholder}>
                                                {t('Cho phép')}
                                            </p>
                                        </MenuItem>
                                        <MenuItem value={PaymentConfiguration.DENIED}>
                                            <p className={classes.placeholder}>
                                                {t('Không cho phép')}
                                            </p>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </Paper>
                    {!isEdit && (
                        <div className={classes.actionBlockStyle}>
                            <CreateButton
                                variant='contained'
                                onClick={onEditClick}
                            >
                                {t('Chỉnh sửa')}
                            </CreateButton>
                        </div>
                    )}
                    {isEdit && (
                        <div className={classes.actionBlockStyle}>
                            <CancelButton
                                variant='outlined'
                                onClick={onCancelClick}
                            >
                                {t('Hủy')}
                            </CancelButton>
                            <CreateButton
                                variant='contained'
                                onClick={onSaveClick}
                            >
                                {t('Lưu')}
                            </CreateButton>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Configuration;
