import React, { useRef, useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { isEmpty } from '../../../validation/CheckForm';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    imgStyle: {
        maxHeight: '500px',
        minWidth: '300px'
    },
    warningStyle: {
        textAlign: 'center',
        color: 'red'
    }
}))

export const ZoomImage = ({
    isOpen,
    image,
    defaultImage,
    onClose
}) => {
    const zoomRef = useRef();
    const { t } = useTranslation();
    const classes = useStyles();
    const [warning, setWarning] = useState('');

    const onError = (e) => {
        e.target.src = defaultImage;
        setWarning(t('Không tìm thấy hình'));
    }

    useEffect(()=>{
        setWarning('');
    },[image])

    useEffect(() => {
        const handleClickOutside = (e => {
            if (zoomRef.current && !zoomRef.current.contains(e.target)) {
                onClose();
            }
        })
        document.addEventListener('mousedown', handleClickOutside);
    }, [zoomRef])

    return (
        <Dialog
            open={isOpen}
            maxWidth="xl"
        >
            <DialogContent ref={zoomRef} style={{ padding: '0' }}>
                <img src={isEmpty(image) ? defaultImage : image} alt="Face ID" className={classes.imgStyle} onError={onError} />
                {isEmpty(image) && <p>{t('Chưa có ảnh Face ID')}</p>}
                <p className={classes.warningStyle} >{warning}</p>
            </DialogContent>
        </Dialog>
    )
}