export const PRICE_TYPE = {
    FREE: 'free',
    SCALE: 'scale',
    FIXED: 'FIXED',
    HOURLY: 'hourly',
    TURN: 'turn'
}

export const CABINET_ROUTE_PATHS = {
    CABINET_SYSTEM_SIZE: '/admin/cabinet/system/size'
}