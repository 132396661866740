import * as Flashphoner from '@flashphoner/websdk/src/flashphoner-core.js';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import StopOutlinedIcon from '@mui/icons-material/StopOutlined';
import VolumeOffOutlinedIcon from '@mui/icons-material/VolumeOffOutlined';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import { CircularProgress } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorAlert from '../alert/ErrorAlert';
import useStyles from './styles';

//Status constants
var SESSION_STATUS = Flashphoner.constants.SESSION_STATUS;
var STREAM_STATUS = Flashphoner.constants.STREAM_STATUS;

const StreamRtsp = React.memo(({
    rtsp,
    isControl,
    id = "myVideo"
}) => {
    const [state, setState] = useState({
        response: {
            succ: "",
            err: ""
        },
        isLoading: false,
        isMute: true,
        isPlay: true
    })

    const sessionRef = useRef();
    const streamRef = useRef();

    const classes = useStyles();
    const { t } = useTranslation();

    const handleChangeResponseStatus = (err = "", succ = "") => {
        setState(prev => ({
            ...prev,
            response: {
                err,
                succ
            }
        }))
    }

    const init_api = () => {
        setState(prev => ({
            ...prev,
            isLoading: true
        }));

        Flashphoner.init({});
        connect();
    }

    const connect = () => {
        sessionRef.current = Flashphoner.createSession({
            urlServer: "wss://demo.flashphoner.com"
        }).on(SESSION_STATUS.ESTABLISHED, function (session) {
            playStream(session);
        }).on(SESSION_STATUS.DISCONNECTED, function (session) {
            session.disconnect();

            setState(prev => ({
                ...prev,
                isLoading: false
            }));
        }).on(SESSION_STATUS.FAILED, function (session) {
            session.disconnect();

            setState(prev => ({
                ...prev,
                isLoading: false
            }));
            handleChangeResponseStatus(t("Có lỗi xảy ra khi tải video, vui lòng thử lại!"));
        });
    }

    const playStream = () => {
        var options = {
            name: rtsp,
            display: document.getElementById(id)
        }
        streamRef.current = sessionRef.current.createStream(options)
            .on(STREAM_STATUS.PLAYING, function (stream) {
                stream.muteAudio();
                stream.muteVideo();
                stream.muteRemoteAudio();

                setState(prev => ({
                    ...prev,
                    isMute: true
                }))
            }).on(STREAM_STATUS.FAILED, function (stream) {
                stream.stop();
                handleChangeResponseStatus(t("Có lỗi xảy ra khi tải video, vui lòng thử lại!"));
            });

        setState(prev => ({
            ...prev,
            isLoading: false
        }));

        streamRef.current?.play();
    }

    const onPlay = () => {
        setState(prev => ({
            ...prev,
            isPlay: true
        }));

        streamRef.current?.stop();
        init_api();
    }
    const onStop = () => {
        streamRef.current?.stop();

        setState(prev => ({
            ...prev,
            isPlay: false
        }));
    }
    const onMute = () => {
        streamRef.current?.muteAudio();
        streamRef.current?.muteVideo();
        streamRef.current?.muteRemoteAudio();

        setState(prev => ({
            ...prev,
            isMute: true
        }));
    }
    const onUnMute = () => {
        streamRef.current?.unmuteAudio();
        streamRef.current?.unmuteVideo();
        streamRef.current?.unmuteRemoteAudio();

        setState(prev => ({
            ...prev,
            isMute: false
        }));
    }

    useEffect(() => {
        if (rtsp) {
            init_api();
        }

        return () => {
            if (sessionRef.current) {
                sessionRef.current.disconnect();
            }
        }
    }, [rtsp, id]);

    return (
        <>
            <div
                id={id}
                className={classes.myVideo}
            >
                {
                    state.isLoading && (
                        <div className={classes.cameraLoading}>
                            <CircularProgress />
                        </div>
                    )
                }

                {
                    isControl && !state.isLoading && (
                        <div className={classes.control}>
                            {
                                !state.isPlay && (
                                    <button onClick={onPlay}>
                                        <PlayArrowOutlinedIcon />
                                    </button>
                                )
                            }
                            {
                                state.isPlay && (
                                    <button onClick={onStop}>
                                        <StopOutlinedIcon />
                                    </button>
                                )
                            }
                            {
                                state.isMute && (
                                    <button onClick={onUnMute}>
                                        <VolumeOffOutlinedIcon />
                                    </button>
                                )
                            }
                            {
                                !state.isMute && (
                                    <button onClick={onMute}>
                                        <VolumeUpOutlinedIcon />
                                    </button>
                                )
                            }
                        </div>
                    )
                }
            </div>
            <ErrorAlert
                message={state.response.err}
                onCloseDialog={(e) => {
                    e?.stopPropagation();
                    handleChangeResponseStatus();
                }}
            />
        </>
    )
})

export default StreamRtsp;