import makeStyles from "@material-ui/styles/makeStyles/makeStyles";

export const useStyles = makeStyles(theme => ({
    root: theme.dataGridStyle,
    addCamera: {
        position: "absolute",
        top: "0",
        left: "0",

        padding: "1rem 2rem 0 2rem",
        width: "100%",
        minWidth: "1280px",
        minHeight: "calc(100vh - 70px)",
        background: "white"
    },
    wrapTitle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "1rem 0"
    },
    title: {
        display: "flex",
        alignItems: "center"
    },
    titleIcon: {
        paddingTop: "5px",
        color: "#0b59c8",
        cursor: "pointer"
    },
    contain: {
        display: 'flex',
        justifyContent: "space-between",
        paddingBottom: "2rem"
    },
    colunm: {
        width: "31%"
    },
    colunmTitle: {
        fontSize: "1.25rem",
        fontWeight: "bold",
        marginBottom: "1rem"
    },
    textField: {
        width: "100%",
        marginBottom: "1rem !important"
    },
    required: {
        color: "red"
    },
    colunmWrap: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "1rem"
    },
    wrapThumb: {
        position: "relative",
        marginBottom: "2rem",
        width: "100%",
        height: "200px",
    },
    wrapIputThumb: {
        position: "absolute",
        top: "-0px",
        left: "0px",
        overflow: "hidden",
        
        width: "200px",
        height: "200px",
    },
    thumbTextField: {
        position: "absolute !important",
        zIndex: "2",
        top: "-55px",
        left: "-10px",
        width: "220px",
        height: "260px !important",
        '& > div': {
            height: "100%",
            alignItems: "flex-start",
            '& > input': {
                height: "100%"
            }
        }
    },
    thumbnailVitual: {
        position: "absolute",
        top: "0",
        left: "0",

        display: "flex",
        alignItems: "center",
        width: "200px",
        height: '200px',
        background: "#C4C4C4",
        cursor: "pointer"
    },
    thumbnailIcon: {
        position: "absolute",
        top: "calc(50% - 17.5px)",
        left: "calc(100px - 17.5px)",
    },
    error: {
        position: "absolute",
        left: "1rem",
        top: "calc(100% + 4px)",
        color: "red",
        fontSize: "12px"
    },
    liveCamrea: {
        width: "100%",
        height: "300px",
        background: "#C4C4C4"
    }
}));