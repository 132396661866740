import axios from 'axios';
import * as tunnel from 'tunnel';
import Cookies from 'js-cookie';

const agent = tunnel.httpsOverHttp({
    proxy: {
        host: process.env.REACT_APP_PROXY,
        port: 8080,
    },
});
var baseUrl;

if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
    if (process.env.REACT_APP_NODE_ENV === 'development')
        baseUrl = 'https://api.tsmartbuilding.com/dev/web';
    else if (process.env.REACT_APP_NODE_ENV === 'beta')
        baseUrl = 'https://api.tsmartbuilding.com/beta/web';
    else if (process.env.REACT_APP_NODE_ENV === 'production')
        baseUrl = 'https://api.tsmartbuilding.com/prod/web';
    else if (process.env.REACT_APP_NODE_ENV === 'royal')
        baseUrl = 'https://api.tsmartbuilding.com/royal/web';
    else if (process.env.REACT_APP_NODE_ENV === 'tma')
        baseUrl = 'https://api.tsmartbuilding.com/tma/web';
} else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
    if (process.env.REACT_APP_NODE_ENV === 'dev')
        baseUrl = 'http://103.199.5.188/api/web/v1';
    else if (process.env.REACT_APP_NODE_ENV === 'beta')
        baseUrl = 'https://tsmartbuilding.innovation.com.vn/api/web/v1';
    else if (process.env.REACT_APP_NODE_ENV === 'local')
        baseUrl = 'http://localhost:5000/api/web/v1';
}
const axiosReceptionist = axios.create({
    baseURL: baseUrl,
    httpsAgent: agent,
    proxy: false,
});

// Add a request interceptor
axiosReceptionist.interceptors.request.use(function (config) {
    config.headers = {
        'Authorization': `Bearer ${Cookies.get('receptionistToken')}`,
        'Content-Type': 'application/json'
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
axiosReceptionist.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});

export default axiosReceptionist;