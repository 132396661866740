import React, { useState, useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton/IconButton';
import clsx from 'clsx';
import EditNotificationForm from './EditNotificationForm';
import { Link, useHistory } from 'react-router-dom';
import { GridOverlay, DataGrid } from '@material-ui/data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';
import { useSelector, useDispatch } from 'react-redux';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import vi from 'date-fns/locale/vi';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SuccessAlert from '../alert/SuccessAlert';
import ErrorAlert from '../alert/ErrorAlert';
import { apiNotifications, apiDeletenotification } from '../../api/notification';
import { Confirm } from '../dialog';
import { CustomLoading, EmptyRowsView } from '../grid/index';
import LargeTooltip from '../largeTooltip';

const useStyles = makeStyles(theme => ({
    content: {
        width: '98%',
        margin: 'auto',
        borderRadius: '6px',
        position: 'relative'
    },
    primaryTitle: theme.primary_title,
    topBlock: {
        marginTop: '22px',
        marginBottom: '20px',
        height: '40px',
        width: '98%',
        marginLeft: '16px'
    },
    actionTable: {
        width: '100%',
        height: '56px',
        background: '#FFFFFF',
        borderBottom: '1px solid #E0E0E0',
        borderRadius: '6px 6px 0 0',
        position: 'relative'
    },
    draftColor: {
        color: '#6D6D6D',
        fontSize: '16px'
    },
    publishedColor: {
        color: '#41BD4D',
        fontSize: '16px'
    },
    deletedColor: {
        color: '#0b59c8',
        fontSize: '16px'
    },
    deActivatedColor: {
        color: '#E4021D',
        fontSize: '16px'
    },
    action: {
        fontSize: '16px',
        fontWeight: '600',
        color: '#009ADA',
        textTransform: 'none',
        position: 'relative',
        left: '-5px'
    },
    tableContent: {
        paddingBottom: '55px',
        width: '100%',
        height: '690px',
        background: '#FFFFFF',
        borderRadius: '6px',
        position: 'relative'
    },
    root: theme.dataGridStyle,
    requestError: {
        width: '100%',
        height: '65vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20px',
        color: '#6D6D6D'
    },
    selectInput: {
        '& .MuiInput-underline:before': {
            display: 'none'
        },
        '& .MuiInput-underline:after': {
            display: 'none'
        }
    },
    filter: {
        position: 'absolute',
        top: '100%',
        left: '0',
        width: '750px',
        height: '100px',
        zIndex: '1'
    },
    inputRightBlock: {
        height: '80px',
        width: '320px',
        position: 'relative',
        float: 'left'
    },
    label: {
        paddingBottom: '0px',
        paddingTop: '8px',
        fontSize: '14px',
        lineHeight: '140%',
        fontWeight: 'bold',
        color: '#7A7171',
        display: 'inline-block',
        margin: '0'
    },
    inputHeight: {
        textAlign: 'left',
        height: '82px',
        margin: '10px 0',
        position: 'relative',
        '& .MuiOutlinedInput-root': {
            height: '32px'
        }
    },
    alertStyle: {
        position: 'absolute',
        right: '8px',
        bottom: '5px',
        padding: '4px 10px',
        zIndex: '10'
    },
    focus: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: theme.themeColor,
            },
        },
    },

}))

const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);
const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);
const DeleteButton = withStyles(theme => ({ root: theme.delete_button }))(Button);
const TextButton = withStyles(theme => ({ root: theme.moreDetail }))(Button);

const NotificationsTable = () => {
    const classes = useStyles();
    const [messages, setMessages] = useState([]);
    const [alert, setAlert] = useState({ success: '', error: '', request: '' });
    const [messageID, setMessageID] = useState('');
    const [loading, setLoading] = useState({ page: false, table: false });
    const [isDetail, setIsDetail] = useState(false);
    const [messageIDs, setMessageIDs] = useState([]);
    const [isDeleting, setIsDeleting] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rowsTotal, setRowsTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageTotal, setPageTotal] = useState(0);
    const [publishedDate, setPublishedDate] = useState(null);
    const [createdDate, setCreatedDate] = useState(null);
    const [searchTitle, setSearchTitle] = useState('');
    const [searchTitleValue, setSearchTitleValue] = useState('');
    const [files, setFiles] = useState([]);
    const [isExpiredSession, setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const notiState = useSelector(state => state.notiState);
    const searchRef = useRef();
    const alertRef = useRef();
    const [isFilter, setIsFilter] = useState(false);
    const { t, i18n } = useTranslation();

    const columns = [
        {
            headerName: t('Hình đại diện'),
            field: 'NotificationImage',
            width: 130,
            sortable: false,
            filterable: false,
            cellClassName: 'super-app-theme--cell',
            renderCell: params => params.row.NotificationImage !== '' ? <img src={params.row.NotificationImage} alt="" width="32px" height="32px" /> : ''
        },
        {
            headerName: t('Tiêu đề'),
            field: 'Title',
            flex: 1,
            cellClassName: 'super-app-theme--cell',
            renderCell: params => <LargeTooltip title={params.row.Title} placement="bottom-start" ><p style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{params.row.Title}</p></LargeTooltip>,
            sortComparator: (v1, v2, params1, params2) => sortNotification(v1, v2)
        },
        {
            headerName: t('Nội dung tóm tắt'),
            field: 'BriefContent',
            flex: 1,
            cellClassName: 'super-app-theme--cell',
            sortComparator: (v1, v2, params1, params2) => sortNotification(v1, v2),
            renderCell: params => <LargeTooltip title={params.row.BriefContent} placement="bottom-start" ><p style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{params.row.BriefContent}</p></LargeTooltip>
        },
        {
            headerName: t('Ngày tạo'),
            field: 'CreatedDate',
            width: 200,
            cellClassName: 'super-app-theme--cell',
            renderCell: params => <p>{moment(params.row.CreatedDate).format('DD-MM-YYYY HH:mm:ss')}</p>
        },
        {
            headerName: t('Ngày phát'),
            field: 'PublishedDate',
            width: 200,
            cellClassName: 'super-app-theme--cell',
            renderCell: params => !isNaN(moment(params.row.PublishedDate)) ? <p>{moment(params.row.PublishedDate).format('DD-MM-YYYY HH:mm:ss')}</p> : ''
        },
        {
            headerName: t('Trạng thái'),
            field: 'NotificationStatus',
            width: 170,
            cellClassName: 'super-app-theme--cell',
            renderCell: params => showStatus(params.row.NotificationStatus)
        },
        {
            headerName: t('Thao tác'),
            field: 'Action',
            width: 120,
            sortable: false,
            filterable: false,
            cellClassName: 'super-app-theme--cell',
            renderCell: params => <TextButton onClick={() => onDetailClick(params.row.id)} >{t("Xem chi tiết")}</TextButton>
        }
    ]

    useEffect(() => {
        if (isExpiredSession) {
            history.push('/login');
            dispatch({ type: 'IS_LOGIN_AGAIN_TRUE' });
        }
    }, [isExpiredSession]);

    const showStatus = (status) => {
        if (status === 2) return <div style={{ color: '#41BD4D' }}>{t('Đã lưu')}</div>;
        return status === 3 ? <div style={{ color: '#0b59c8' }} >{t('Đã phát thông báo')}</div> : <div style={{ color: '#E4021D' }}>{t('Đã quá hạn')}</div>;
    }

    const sortNotification = (v1, v2) => {
        return v1.localeCompare(v2, undefined, {
            numeric: true,
            sensitivity: 'base'
        });
    }

    const getDefaultValues = async () => {
        try {
            var filter = {
                title: searchTitle,
                published_date: publishedDate,
                created_date: createdDate
            };
            if (publishedDate === null) delete filter.published_date;
            if (createdDate === null) delete filter.created_date;
            const end = currentPage * rowsPerPage;
            const start = end - rowsPerPage;
            const query = {
                start_index: start,
                end_index: end,
                filter: filter
            };
            var res = await apiNotifications(query);
            setRowsTotal(res.data.Count);
            if (res.data.Count > rowsPerPage && res.data.Count % rowsPerPage === 0) setPageTotal(res.data.Count / rowsPerPage);
            else if (res.data.Count > rowsPerPage && res.data.Count % rowsPerPage !== 0) setPageTotal(parseInt(res.data.Count / rowsPerPage) + 1);
            else setPageTotal(1);
            const data = [];
            res.data.Items.forEach(item => {
                data.push({ ...item, id: item.ID })
            })
            setMessages(data);
            setFilteredData(data);
            setLoading({ page: true, table: true });
            setIsDetail(false);
            setMessageIDs([]);
            setAlert({ ...alert, request: '' });
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({ ...alert, request: t('Không lấy được dữ liệu') });
        }
    }

    useEffect(() => {
        if (!loading.table || !loading.page) getDefaultValues();
    }, [currentPage, loading.page, loading.table])

    useEffect(() => {
        setCurrentPage(1);
        setLoading({ ...loading, table: false });
    }, [rowsPerPage, searchTitle])

    useEffect(() => {
        if (notiState.failedMessage !== '' || notiState.successMessage !== '') {
            setLoading({ ...loading, table: false });
        }
    }, [notiState])

    const onDetailClick = (ID) => {
        setMessageID(ID);
        setIsDetail(!isDetail);
    }

    const changeStatusEdit = () => {
        setIsDetail(!isDetail);
        if (isDetail) setLoading({ ...loading, table: false });
    }

    const onSelectedChange = (e) => {
        setMessageIDs(e);
        var fileData = [];
        e.forEach(value => {
            const results = filteredData.filter(item => item.ID === value);
            fileData.push(results[0].NotificationImage ? results[0].NotificationImage : '')
            var data = results[0].Content.split('<a href=');
            data.forEach(image => {
                if (image.indexOf('https://aws-sam-cli') !== -1) {
                    fileData.push(image.slice(0, image.indexOf(' download')))
                }
            })
        })
        setFiles(fileData)
    }

    const onDeleteClick = () => {
        setDeleteDialog(!deleteDialog);
    }

    const onConfirmDeleteClick = async () => {
        try {
            setIsDeleting(true);
            await apiDeletenotification({ 'ids': messageIDs, 'files': files });
            setAlert({ ...alert, success: `${t("Xóa thành công")} ${messageIDs.length} ${messageIDs.length > 1 ? t("nhiều thông báo") : t("thông báo")}`, error: '' });
            setMessageIDs([]);
            setLoading({ ...loading, table: false });
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({ ...alert, success: '', error: t('Xóa không thành công, vui lòng thử lại sau!') });
        }
        setDeleteDialog(!deleteDialog);
        setIsDeleting(false);
    }

    const onSearchChange = (e) => {
        setSearchTitleValue(e.target.value);
        if (searchRef.current) clearTimeout(searchRef.current);
        searchRef.current = setTimeout(() => {
            setSearchTitle(e.target.value);
        }, 500)
    }

    const onPublishedDateChange = (e) => {
        if (e === null) setPublishedDate(null);
        else {
            const date = new Date(e).getTime();
            setPublishedDate(date);
        }
    }

    const onCreatedDateChange = (e) => {
        if (e === null) setCreatedDate(null);
        else {
            const date = new Date(e).getTime();
            setCreatedDate(date);
        }
    }

    const onCloseMessage = () => {
        setAlert({ ...alert, success: '', error: '' });
    }

    useEffect(() => {
        if (alert.success !== '' || alert.error !== '') {
            if (alertRef.current) clearTimeout(alertRef.current);
            alertRef.current = setTimeout(() => {
                onCloseMessage()
            }, 5000);
        }
    }, [alert])

    return (
        <div className={classes.focus} style={{ minWidth: '1080px', paddingBottom: '30px' }}>
            {!isDetail &&
                <div className={classes.topBlock}>
                    <p className={classes.primaryTitle} >{t('Quản lý bảng tin')}</p>
                    {!isDetail && loading.page &&
                        <TextField
                            placeholder={t("Tìm kiếm theo tiêu đề")}
                            variant="outlined"
                            value={searchTitleValue}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                style: { background: '#FFFFFF', height: '40px', width: '320px' }
                            }}
                            onChange={onSearchChange}
                        />}
                </div>}
            {!loading.page && alert.request === '' && <CircularProgress color="primary" style={{ marginLeft: '50%', color: '#0b59c8' }} />}
            {!isDetail && loading.page &&
                <div style={{ textAlign: 'right', marginBottom: '10px', width: '98%', margin: 'auto' }}>
                    {isFilter &&
                        <Paper className={clsx(classes.inputHeight, classes.picker)}>
                            <div style={{ width: '100%', margin: 'auto' }}>
                                <div className={classes.inputRightBlock}>
                                    <label className={classes.label} style={{ marginBottom: '0', marginLeft: '5%' }}>{t('Ngày tạo thông báo')}</label><br />
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === 'vi' ? vi : ''}>
                                        <KeyboardDatePicker
                                            inputVariant="outlined"
                                            style={{ position: 'relative', top: '-15px', width: '90%', marginLeft: '5%' }}
                                            size="small"
                                            format="dd/MM/yyyy"
                                            margin="normal"
                                            InputProps={{ readOnly: true }}
                                            placeholder={t("Ngày tạo thông báo")}
                                            value={createdDate}
                                            onChange={onCreatedDateChange}
                                            helperText={isNaN(createdDate) ? t('Thông tin chưa hợp lệ') : ''}
                                            cancelLabel={t("Đóng")}
                                            okLabel={t("Đồng ý")}
                                            views={["date", "month", "year"]}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className={classes.inputRightBlock}>
                                    <label className={classes.label} style={{ marginBottom: '0', marginLeft: '5%' }}>{t('Ngày phát thông báo')}</label><br />
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === 'vi' ? vi : ''}>
                                        <KeyboardDatePicker
                                            inputVariant="outlined"
                                            style={{ position: 'relative', top: '-15px', width: '90%', marginLeft: '5%' }}
                                            size="small"
                                            format="dd/MM/yyyy"
                                            margin="normal"
                                            InputProps={{ readOnly: true }}
                                            placeholder={t("Ngày phát thông báo")}
                                            value={publishedDate}
                                            onChange={onPublishedDateChange}
                                            helperText={isNaN(publishedDate) ? 'Thông tin chưa hợp lệ' : ''}
                                            cancelLabel={t("Đóng")}
                                            okLabel={t("Đồng ý")}
                                            views={["date", "month", "year"]}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>

                                <div className={classes.inputRightBlock} style={{ width: '30%' }}>
                                    <br />
                                    <CreateButton
                                        variant="contained"
                                        style={{ height: '32px', marginTop: '8px' }}
                                        onClick={() => {
                                            setLoading({ ...loading, table: false });
                                            setCurrentPage(1);
                                        }}

                                    >
                                        {t('Áp dụng')}
                                    </CreateButton>
                                    <CancelButton
                                        variant="outlined"
                                        style={{ marginLeft: '15px', height: '32px', marginTop: '8px' }}
                                        onClick={() => {
                                            setCreatedDate(null);
                                            setPublishedDate(null);
                                        }}
                                    >
                                        {t('filter.Xóa')}
                                    </CancelButton>
                                </div>
                            </div>
                        </Paper>}
                </div>}
            {!isDetail && <div className={classes.content}>
                <Confirm
                    isOpen={deleteDialog}
                    handleClose={() => setDeleteDialog(!deleteDialog)}
                    isLoading={isDeleting}
                    mess={t("Bạn có chắc muốn xóa thông báo?")}
                    onConfirm={onConfirmDeleteClick}
                />
                {alert.request !== '' && <div className={classes.requestError}>{alert.request}</div>}
                {loading.page && alert.request === '' &&
                    <div className={clsx(classes.tableContent, classes.root)}>
                        <div className={classes.actionTable}>
                            <CreateButton
                                component={Link}
                                to='/admin/add-notification'
                                variant="contained"
                                style={{ float: 'left', margin: '7px 0 0 8px' }}
                                startIcon={<AddCircleOutlineIcon />}
                            >
                                {t('Thêm')}
                            </CreateButton>
                            <LargeTooltip title={t("Xóa")} style={{ float: 'left' }}>
                                <span>
                                    <DeleteButton style={{ margin: '8px' }} startIcon={<DeleteOutlineOutlinedIcon />} variant="outlined" onClick={onDeleteClick} disabled={messageIDs.length === 0} >
                                        {t('Xóa')}
                                    </DeleteButton>
                                </span>
                            </LargeTooltip>
                            <CancelButton
                                style={{ float: 'right', margin: '7px 8px 0 0' }}
                                variant="outlined"
                                startIcon={<FilterListIcon />}
                                onClick={() => setIsFilter(!isFilter)}
                            >
                                {t('Bộ lọc')}
                            </CancelButton>
                            <SuccessAlert message={alert.success} onCloseDialog={onCloseMessage} />
                            <ErrorAlert message={alert.error} onCloseDialog={onCloseMessage} />
                        </div>
                        <DataGrid
                            localeText={{
                                footerRowSelected: (count) => count + t(" hàng trong bảng đã được chọn"),
                                columnHeaderSortIconLabel: t('Sắp xếp')
                            }}
                            columns={columns}
                            rows={filteredData}
                            pageSize={rowsPerPage}
                            rowsPerPageOptions={[10, 50, 100]}
                            onPageSizeChange={(pageSize) => setRowsPerPage(pageSize)}
                            checkboxSelection
                            onSelectionModelChange={onSelectedChange}
                            hideFooterRowCount
                            disableSelectionOnClick
                            disableColumnMenu={true}
                            hideFooterPagination
                            loading={!loading.table}
                            components={{
                                NoRowsOverlay: () => EmptyRowsView("không tìm thấy dữ liệu"),
                                LoadingOverlay: CustomLoading
                            }}
                        />
                        <div style={{ position: 'absolute', bottom: '3px', right: '5px', display: 'flex' }} className={classes.selectInput}>
                            <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0 20px' }} >{t('Số dòng mỗi trang')}</p>
                            <Select
                                value={rowsPerPage}
                                onChange={(e) => { setRowsPerPage(e.target.value) }}
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                            <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0 20px' }} >
                                {rowsTotal === 0 ? 0 : (currentPage * rowsPerPage) - rowsPerPage + 1}-{currentPage * rowsPerPage > rowsTotal ? rowsTotal : currentPage * rowsPerPage} {t('trong số')} {rowsTotal}
                            </p>
                            <IconButton
                                disabled={currentPage === 1}
                                onClick={() => {
                                    setCurrentPage(currentPage - 1);
                                    setLoading({ ...loading, table: false });
                                }}
                            >
                                <KeyboardArrowLeftIcon />
                            </IconButton>
                            <IconButton
                                disabled={currentPage === pageTotal}
                                onClick={() => {
                                    setCurrentPage(currentPage + 1);
                                    setLoading({ ...loading, table: false });
                                }}
                            >
                                <KeyboardArrowRightIcon />
                            </IconButton>
                        </div>
                    </div>
                }
            </div>}
            {isDetail && <EditNotificationForm messageID={messageID} changeStatusEdit={() => changeStatusEdit()} messages={messages} />}
        </div>
    )
}

export default NotificationsTable;

