import React, { useState, useEffect, useRef } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Button from '@material-ui/core/Button/Button';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import {DataGrid } from '@material-ui/data-grid';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import SuccessAlert from '../../alert/SuccessAlert';
import ErrorAlert from '../../alert/ErrorAlert';
import moment from 'moment-timezone';
import { apiDeviceLogList, apiDeviceLogDelete } from '../../../api/device';
import { EmptyRowsView, CustomLoading } from '../../grid';
import { PageLoading } from '../../loading/index';
import { Confirm, FailedDialog } from '../../dialog';

const useStyles = makeStyles((theme) => ({
    root: theme.dataGridStyle,
    overlay: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        background: 'rgba(0,0,0,0.1)',
        zIndex: '1300'
    },
    requestError: {
        width: '100%',
        height: '65vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20px',
        color: '#6D6D6D'
    },
    font: {
        fontSize: '16px',
        lineHeight: '22.4px',
        color: '#6D6D6D'
    },
    tableTitle: {
        position: 'relative',
        height: '56px',
        border: '1px solid rgba(224, 224, 224, 1)',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px'
    },
}))

const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);
const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);
const ErrorButton = withStyles(theme => ({ root: theme.error_button }))(Button);
const DeleteButton = withStyles(theme => ({ root: theme.delete_button }))(Button);

const Logs = props => {
    const classes = useStyles();
    const { deviceId } = props;
    const [logs, setLogs] = useState([]);
    const [alert, setAlert] = useState({
        name: '',
        device_id: '',
        success: '',
        error: '',
        request: '',
        dialogMessage: ''
    });
    const [fileNames, setFileNames] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(parseInt(localStorage.getItem('rowsPerPage')));
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [loading, setLoading] = useState({ table: false, page: false });
    const [isFailed, setIsFailed] = useState(false);
    const [isExpiredSession, setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const alertRef = useRef();
    const { t } = useTranslation();

    const columns = [
        {
            field: 'Key',
            headerName: t('Tên file'),
            cellClassName: 'super-app-theme--cell',
            flex: 1
        },
        {
            field: 'LastModified',
            headerName: t('Ngày tạo'),
            cellClassName: 'super-app-theme--cell',
            width: 200,
            renderCell: params => <p>{moment(params.row.LastModified).format('DD-MM-YYYY HH:mm:ss')}</p>
        },
        {
            field: 'Size',
            headerName: t('Kích thước'),
            cellClassName: 'super-app-theme--cell',
            width: 150,
            renderCell: params => <p>{(params.row.Size * 9.537 * 0.0000001).toFixed(2)} MB</p>
        },
        {
            field: 'Method',
            headerName: t('Thao tác'),
            cellClassName: 'super-app-theme--cell',
            width: 150,
            sortable: false,
            renderCell: params => <a href={params.row.url} download className={classes.primaryColor} style={{ textDecoration: 'underline', cursor: 'pointer' }} >{t('Tải')}</a>
        },
    ]

    const sortByDate = (d1, d2) => {
        if (d1 < d2) return 1;
        if (d1 > d2) return -1;
        return 0;
    }

    useEffect(() => {
        if (isExpiredSession) {
            history.push('/login');
            dispatch({ type: 'IS_LOGIN_AGAIN_TRUE' });
        }
    }, [isExpiredSession]);

    const getDefaultValues = async () => {
        try {
            let res = await apiDeviceLogList({ deviceId: deviceId });
            const data = [];
            res.data.Files.forEach(item => {
                data.push({...item,id:item.Key,url:res.data.S3_URL+item.Key});
            });
            data.sort((a, b) => sortByDate(a.LastModified, b.LastModified));
            setLogs(data);
            setFileNames([]);
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({ ...alert, request: t('Không lấy được dữ liệu') });
        }
        setLoading({ table: true, page: true });
    }

    useEffect(() => {
        if (!loading.page || !loading.table) {
            getDefaultValues();
        }
    }, [loading.page, loading.table, deviceId])

    const onSelectedChange = (e) => {
        setFileNames(e);
    }

    const onDeleteClick = () => {
        setConfirmDialog(true);
    }

    const onConfirmDeleteClick = async () => {
        try {
            setIsDeleting(true);
            await apiDeviceLogDelete({ files: fileNames });
            setAlert({ ...alert, error: '', success: t('Xóa thành công!') });
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({ ...alert, error: t('Xoá không thành công, vui lòng thử lại!'), success: '' });
        }
        setConfirmDialog(false);
        setIsDeleting(false);
        setLoading({ ...loading, table: false });
    }

    const onCloseDialog = () => {
        setAlert({ ...alert, success: '', error: '' });
    }

    useEffect(() => {
        if (alert.success !== '' || alert.error !== '') {
            if (alertRef.current) clearTimeout(alertRef.current);
            alertRef.current = setTimeout(() => {
                onCloseDialog();
            }, 5000);
        }
    }, [alert])

    const onAgreeClick = () => {
        setIsFailed(false);
        setLoading({ ...loading, page: false });
    }

    return (
        <div style={{ position: 'relative', width: '97%', margin: 'auto', height: '100%' }} >
            <FailedDialog
                isOpen={isFailed}
                mess={alert.dialogMessageo}
                onConfirm={onAgreeClick}
            />
            <Confirm
                isOpen={confirmDialog}
                handleClose={() => setConfirmDialog(!confirmDialog)}
                isLoading={isDeleting}
                mess={t('Bạn có chắc chắn muốn xóa?')}
                onConfirm={onConfirmDeleteClick}
            />
            <SuccessAlert
                message={alert.success}
                onCloseDialog={onCloseDialog}
            />
            <ErrorAlert
                message={alert.error}
                onCloseDialog={onCloseDialog}
            />
            {alert.request !== '' && <div className={classes.requestError}>{alert.request}</div>}
            {!loading.page && alert.request === '' && <PageLoading />}
            {loading.page && alert.request === '' &&
                <div style={{ marginTop: '10px', width: '100%', clear: 'both', position: 'relative', height: '78%' }}>
                    <div className={classes.tableTitle}>
                        <DeleteButton variant="outlined" style={{ margin: '8px' }} startIcon={<DeleteOutlineOutlinedIcon />} disabled={fileNames.length === 0} onClick={onDeleteClick} >
                            {t('Xóa')}
                        </DeleteButton>
                        <CreateButton
                            variant="contained"
                            style={{ margin: '0' }}
                            onClick={() => setLoading({ ...loading, table: false })}
                        >
                            {t('Làm mới')}
                        </CreateButton>
                    </div>
                    <div style={{ height: '500px', width: '100%', paddingBottom: '10px' }} className={clsx(classes.root)}>
                        <DataGrid
                            componentsProps={{
                                pagination: {
                                    labelRowsPerPage: t("Số dòng mỗi trang"),
                                    labelDisplayedRows: ({ from, to, count }) => { return `${from}-${to} ${t('trong số')} ${count}` }
                                }
                            }}
                            components={{
                                NoRowsOverlay: () => EmptyRowsView(t('Không tìm thấy log')),
                                LoadingOverlay: CustomLoading,
                            }}
                            localeText={{
                                footerRowSelected: (count) => count + t(" hàng trong bảng đã được chọn"),
                                columnHeaderSortIconLabel: t('Sắp xếp')
                            }}
                            rows={logs}
                            columns={columns}
                            pageSize={rowsPerPage}
                            checkboxSelection
                            rowsPerPageOptions={[10, 50, 100]}
                            onPageSizeChange={pageSize => {
                                setRowsPerPage(pageSize);
                                localStorage.setItem('rowsPerPage', pageSize);
                            }}
                            onSelectionModelChange={onSelectedChange}
                            hideFooterRowCount
                            disableSelectionOnClick
                            disableColumnMenu={true}
                            loading={!loading.table}
                        />
                    </div>
                </div>}
        </div>
    )
}

export default Logs;