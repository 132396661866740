import makeStyles from "@material-ui/styles/makeStyles/makeStyles";

export const useStyles = makeStyles(theme => ({
    root: theme.dataGridStyle,
    camera: {
        padding: "0 1rem",
        minWidth: "1280px",
    },
    cameraTitle: {
        display: "flex",
        justifyContent: "space-between",
        margin: "1rem 0.25rem"
    },
    tableCamera: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        height: "calc(100vh - 224px)"
    },
    cameraItem: {
        position: "relative",
        margin: "0.25rem",
        width: "calc(50% - 0.5rem)",
        height: "calc(50% - 0.5rem)",
        cursor: "pointer"
    },
    cameraItem8: {
        width: "calc(25% - 0.5rem)",
        height: "calc(50% - 0.5rem)",
    },
    cameraItem12: {
        width: "calc(25% - 0.5rem)",
        height: "calc(33% - 0.5rem)",
    },
    cameraItem16: {
        width: "calc(25% - 0.5rem)",
        height: "calc(25% - 0.5rem)",
    },
    cameraItemUrl: {
        width: "100%",
        height: "100%",
        background: "#C4C4C4",
    },
    cameraItemName: {
        position: "absolute",
        bottom: "0.25rem",
        left: "0.25rem",
        borderRadius: "16px",
        padding: "2px 8px",
        background: "black",
        color: "white"
    },
    cameraFooter: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    cameraFooterBtn: {
        outline: "unset",
        border: "unset",
        borderRadius: "50%",
        width: "50px",
        height: "50px",
        background: "white",

        "&:hover": {
            background: "rgba(0, 0, 0, 0.05)"
        }
    },
    wrapLeftFooter: {
        display: "flex",
        alignItems: "center"
    },
    wrapPageSize: {
        display: "flex",
        alignItems: "center"
    },
    pageSizeInpt: {
        "& > div": {
            marginTop: "1px",
            marginRight: "2rem",
            "&::before": {
                border: "unset !important"
            },
            "&::after": {
                border: "unset !important"
            }
        },
        "& > div > div": {
            marginLeft: "1rem",
            padding: "unset",
            fontSize: "0.875rem",
            fontWeight: "400",
            background: "unset !important"
        }
    }
}));