import makeStyles from '@material-ui/styles/makeStyles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    root: theme.dataGridStyle,
    wrapHead: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '22px',
    },
    wrapAct: {
        display: 'flex'
    },
    table: {
        position: 'relative',
        borderRadius: '6px',
        width: '100%',
        height: 'calc(100vh - 275px)',
        minHeight: "500px",
        background: '#FFFFFF',
        
        "& .Mui-even.MuiDataGrid-row, .Mui-odd.MuiDataGrid-row": {
            cursor: "pointer"
        }
    },
}));