import React,{useState,useEffect,useRef} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button/Button';
import {useTranslation} from 'react-i18next';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DataGrid } from '@material-ui/data-grid';
import AddAccount from './AddAccount';
import axiosClient from '../../../config/AxiosClient';
import base64 from 'base-64';
import md5 from 'md5';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import {useHistory } from 'react-router-dom';
import EditAccount from './EditAccount';
import SuccessAlert from '../../alert/SuccessAlert';
import ErrorAlert from '../../alert/ErrorAlert';
import LargeTooltip from '../../largeTooltip';

const useStyles = makeStyles((theme)=>({
    error:{color:'red',position:'absolute',bottom:'0',left:'20px'},
    alert:{position:'absolute', left:'3px', top:'3px',width:'99%'},
    dialogTitle:{textAlign:'center',fontSize:'20px',fontWeight:'600',lineHeight:'25px',color:'#FFFFFF'},
    contentDialog:{fontSize:'16px',height:'68px',borderBottom:'1px solid #EEEDF2',display:'flex',alignItems:'center',justifyContent:'center'},
    dialogTitleBackground:theme.primaryColor,
    errorColor:theme.errorColor,
    root:theme.dataGridStyle,
    overlay:{position:'fixed',width:'100%',height:'100%',top:'0',left:'0',background:'rgba(0,0,0,0.1)',zIndex:'1300'},
    requestError:{width:'100%',height:'65vh',display:'flex',justifyContent:'center',alignItems:'center',fontSize:'20px',color:'#6D6D6D'},
    tableTitle:{position:'relative',height:'56px',border:'1px solid rgba(224, 224, 224, 1)',borderTopLeftRadius:'6px',borderTopRightRadius:'6px'},
    alertStyle:{position:'absolute',right:'9px',bottom:'8px',padding:'0 10px',zIndex:'10'},
    selectTitle:{fontSize:'16px',lineHeight:'22.4px',position:'absolute',bottom:'8px',right:'8px'},
}))

const CreateButton = withStyles(theme =>({root:theme.create_button}))(Button);
const CancelButton = withStyles(theme =>({root:theme.cancel_button}))(Button);
const DeleteButton = withStyles(theme =>({root:theme.delete_button}))(Button);
const ErrorButton = withStyles(theme =>({root:theme.error_button}))(Button);
const TextButton = withStyles(theme =>({root:theme.moreDetail}))(Button);

const Accounts = props =>{
    const classes = useStyles();
    const {t} = useTranslation();
    const [username,setUsername] = useState('');
    const [usernames,setUsernames] = useState([]);
    const [rowsPerPage,setRowsPerPage] = useState(parseInt(localStorage.getItem('rowsPerPage')));
    const [accounts,setAccounts] = useState([]);
    const [isFailed,setIsFailed] = useState(false);
    const [loading,setLoading] = useState(false);
    const [isAdding,setIsAdding] = useState(false);
    const [alert,setAlert] = useState({success:'',error:'',request:'',errorDialog:''});
    const [isDelete,setIsDelete] = useState(false);
    const [isDeleting,setIsDeleting] = useState(false);
    const [isEdit,setIsEdit] = useState(false);
    const alertRef = useRef();
    const [isExpiredSession,setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const columns = [
        {
            field:'DisplayName',
            headerName:t('Tên tài khoản'),
            cellClassName:'super-app-theme--cell',
            flex:1
        },
        {
            field:'Username',
            headerName:t('Tên đăng nhập'),
            cellClassName:'super-app-theme--cell',
            flex:1
        },
        {
            field:'Method',
            headerName:t('Thao tác'),
            cellClassName:'super-app-theme--cell',
            width:150,
            sortable:false,
            renderCell: params => <TextButton onClick={()=>onEditClick(params.row.Username)} >{t('Chỉnh sửa')}</TextButton>
        },
    ]

    useEffect(()=>{
        if(isExpiredSession){
          history.push('/login');
          dispatch({type:'IS_LOGIN_AGAIN_TRUE'});
        } 
    },[isExpiredSession]);

    const getDefaultValues = async() =>{
        try {
            const res = await axiosClient.get('/manager/list',{
                headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
            })
            const data = [];
            res.data.Items.forEach((value)=>{
                data.push({...value,id:value.Username});
            })
            setAccounts(data);
            setUsernames([]);
            setAlert({...alert,request:'',errorDialog:''});
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({...alert,request:t('Không lấy được dữ liệu')});
        }
        setLoading(true);
    }

    useEffect(()=>{
        if(!loading) getDefaultValues();
    },[loading])

    const onChangeEditStatus= ()=>{
        setIsEdit(false);
    }

    const onEditClick = (uname) =>{
        setUsername(uname);
        setIsEdit(true);
    }
    const onCreateclick = async(data)=>{
        try {
            setIsAdding(true);
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                await axiosClient.post('/manager/create',JSON.stringify({...data,password:md5(data.password),authen:base64.encode(data.username+':'+data.password)}),{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
                })
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                await axiosClient.post('/manager/create',{...data,password:md5(data.password),authen:base64.encode(data.username+':'+data.password)},{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
                })
            }
            setAlert({...alert,success:t('Thêm thành công!')});
            setLoading(false);
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({...alert,errorDialog:t('Thêm không thành công, vui lòng thử lại!')})
            setIsFailed(true);
        }
        setIsAdding(false);
        
    }
    const onEditlick = async(data)=>{
        try {   
            setIsAdding(true);  
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                await axiosClient.post('/manager/update',JSON.stringify(data),{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
                })
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                await axiosClient.post('/manager/update',data,{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
                })
            }

            setAlert({...alert,success:t('Lưu thành công!')});
            setLoading(false);
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({...alert,errorDialog:t('Lưu không thành công, vui lòng thử lại!')})
            setIsFailed(true);
        }
        setIsEdit(false);
        setIsAdding(false);
    }
    const onSelectedChange = (e) =>{
        setUsernames(e);
    }
    const onDeleteClick = () =>{
        setIsDelete(true);
    }
    const onConfirmDeleteClick = async() =>{
        try {
            setIsDeleting(true);
            await axiosClient.post('/manager/delete',{'usernames':usernames},{
                headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
            })
            setAlert({...alert,success:t('Xóa thành công!'),error:''});
            setLoading(false);
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({...alert,success:'',error:t('Xóa không thành công, vui lòng thử lại!')});
        }
        setIsDeleting(false);
        setIsDelete(false);
    }
    const onCloseDialog = ()=>{
        setAlert({...alert,success:'',error:''});
    }

    useEffect(()=>{
        if(alert.success !== '' || alert.error !== ''){
            if(alertRef.current) clearTimeout(alertRef.current);
            alertRef.current = setTimeout(() => {
                onCloseDialog();
            }, 5000);
        }
    },[alert])

    const onAgreeClick = () =>{
        setIsFailed(false);
    }

    const deleteDialog = () =>{
        return(
          <Dialog 
            open={isDelete}
            maxWidth="xl"
          >
            <DialogTitle className={classes.dialogTitleBackground}>
                <p className={classes.dialogTitle}>{t('Thông báo')}</p>
            </DialogTitle>
            <DialogContent style={{width:'448px',padding:'0',textAlign:'center',position:'relative'}}>
                <p className={classes.contentDialog}>{t('Bạn có chắc chắn muốn xóa?')}</p>
                <CancelButton 
                  style={{marginLeft:'20px'}}
                  onClick={()=>{setIsDelete(false)}} 
                  disabled={isDeleting}
                >
                  {t('Trở về')}
                </CancelButton>
                <CreateButton 
                  style={{margin:'10px 0 10px 24px'}}
                  onClick={onConfirmDeleteClick} 
                  disabled={isDeleting}
                >
                  {t('Đồng ý')}
                </CreateButton>
                {isDeleting && <CircularProgress style={{position:'absolute',top:'10%',left:'45%',color:'#0b59c8'}}/>}
            </DialogContent>
          </Dialog>
        )
    }

    const FailedDialog = () =>{
        return(
          <Dialog 
            open={isFailed}
            maxWidth="xl"
          >
            <DialogTitle className={classes.errorColor}>
                <p className={classes.dialogTitle}>{t('Thông báo')}</p>
            </DialogTitle>
            <DialogContent style={{width:'448px',padding:'0',textAlign:'center'}}>
                <p className={classes.contentDialog}>{alert.errorDialog}</p>
                <ErrorButton 
                  style={{margin:'10px 0'}}
                  onClick={onAgreeClick} 
                >
                  {t('Đồng ý')}
                </ErrorButton>
            </DialogContent>
          </Dialog>
        )
    }

    const EmptyRowsView = () => {
        return (
          <div
            style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}
          >
            <h3 style={{color:'#6D6D6D',fontSize: '16px'}}>
              {t('Không tìm thấy tài khoản')}
            </h3>
          </div>
        );
    };

    return (
        <div style={{height:'100%'}}>
            {FailedDialog()}
            {deleteDialog()}
            {isAdding && <div className={classes.overlay}></div>}
            <p style={{fontSize:'22px',fontWeight:'bold',padding:'23px 0 0px 16px'}}>{t('Tài khoản quản lý')}</p>
            {loading && alert.request==='' &&<div >
                {loading && !isEdit && <AddAccount accounts={accounts} onCreateclick={(data)=>onCreateclick(data)} isAdding={isAdding} />}
                {loading && isEdit &&  <EditAccount onEditlick={(data) =>onEditlick(data)} username={username} onChangeEditStatus={onChangeEditStatus} isAdding={isAdding} />}
            </div>}
            {!loading && alert.request==='' && <div style={{width:'100%',height:'250px',display:'flex',justifyContent:'center',alignItems:'center'}}><CircularProgress style={{color:'#0b59c8'}}/></div>}
            {alert.request !== '' && <div className={classes.requestError}>{alert.request}</div>}
            {loading && alert.request==='' &&
            <div style={{width:'97%',margin:'auto',marginTop:'10px',height:'55%'}}  >
                <div className={classes.tableTitle}>   
                    <SuccessAlert message={alert.success} onCloseDialog={onCloseDialog} />
                    <ErrorAlert message={alert.error} onCloseDialog={onCloseDialog} />
                    <LargeTooltip title={t("Xóa")}>
                        <span>
                            <DeleteButton variant="outlined" style={{margin:'8px'}} disabled={usernames.length===0} onClick={onDeleteClick} startIcon={<DeleteOutlineOutlinedIcon />}  >
                                {t('Xóa')}
                            </DeleteButton>
                        </span>
                    </LargeTooltip>
                </div>
                <div className={clsx(classes.root)} style={{width: '100%',paddingBottom:'10px', minHeight: '300px',height:'100%'}} >       
                    <DataGrid 
                        componentsProps={{
                            pagination: {
                                labelRowsPerPage: t("Số dòng mỗi trang"),
                                labelDisplayedRows:({from,to,count}) => {return `${from}-${to} ${t('trong số')} ${count}`}
                            }
                        }}
                        components={{
                            NoRowsOverlay:EmptyRowsView
                        }}  
                        localeText={{
                            footerRowSelected: (count) => count +t(" hàng trong bảng đã được chọn"),
                            columnHeaderSortIconLabel: t('Sắp xếp')
                        }}
                        rows={accounts} 
                        columns={columns} 
                        pageSize={rowsPerPage}
                        rowHeight={45}
                        checkboxSelection 
                        rowsPerPageOptions={[10,50,100]}
                        onPageSizeChange={pageSize=>{
                            setRowsPerPage(pageSize);
                            localStorage.setItem('rowsPerPage',pageSize);
                        }}
                        onSelectionModelChange={onSelectedChange}
                        hideFooterRowCount
                        disableSelectionOnClick
                        disableColumnMenu={true}
                        scrollbarSize={20}
                    />
                </div>
            </div>}
        </div>
    )
}

export default Accounts;
