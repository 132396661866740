import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { GridOverlay, DataGrid } from '@material-ui/data-grid';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button/Button';
import TextField from '@material-ui/core/TextField';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import { v1 } from 'uuid';
import IconButton from '@material-ui/core/IconButton/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { EmptyRowsView, CustomLoading } from '../../grid';
import { apiSyncFaceidResult } from '../../../api/sync';
import LargeTooltip from '../../largeTooltip';

const useStyles = makeStyles((theme) => ({
    error: {
        color: 'red',
        position: 'absolute',
        bottom: '0',
        left: '20px'
    },
    alert: {
        position: 'absolute',
        left: '3px',
        top: '3px',
        width: '99%'
    },
    dialogTitle: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '25px',
        color: '#FFFFFF',
    },
    contentDialog: {
        fontSize: '16px',
        height: '68px',
        borderBottom: '1px solid #EEEDF2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    dialogTitleBackground: theme.primaryColor,
    errorColor: theme.errorColor,
    root: theme.dataGridStyle,
    overlay: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        background: 'rgba(0,0,0,0.1)',
        zIndex: '1300'
    },
    requestError: {
        width: '100%',
        height: '65vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20px',
        color: '#6D6D6D'
    },
    tableTitle: {
        position: 'relative',
        height: '56px',
        border: '1px solid rgba(224, 224, 224, 1)',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px'
    },
    alertStyle: {
        position: 'absolute',
        right: '9px',
        bottom: '8px',
        padding: '0 10px',
        zIndex: '10'
    },
    selectTitle: {
        fontSize: '16px',
        lineHeight: '22.4px',
        position: 'absolute',
        bottom: '8px',
        right: '8px'
    },
    inputBlock: {
        height: '60px',
        width: '90%',
        margin: 'auto',
        position: 'relative'
    },
    label: {
        margin: '0',
        fontSize: '14px',
        lineHeight: '140%',
        color: '#000000',
        display: 'inline-block',
        fontWeight: 'bold'
    },
    input: {
        width: '100%'
    },
    selectInput: {
        '& .MuiInput-underline:before': {
            display: 'none'
        },
        '& .MuiInput-underline:after': {
            display: 'none'
        }
    },
    tableStyle: {
        width: '100%',
        maxHeight: '590px',
        paddingBottom: '10px',
        minHeight: '300px',
        height: '90%',
        position: 'relative'
    },
    tableBlock: {
        width: '97%',
        margin: 'auto',
        marginTop: '10px',
        height: '85%'
    },
    textCellStyle: {
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    textCellErrorStyle: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        color: '#E01010'
    }
}))

const TextButton = withStyles(theme => ({ root: theme.moreDetail }))(Button);
const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);

const SyncFaceHistories = () => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [histories, setHistories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [rowsTotal, setRowsTotal] = useState(0);
    const [isExpiredSession, setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const columns = [
        {
            field: 'Device',
            headerName: t('Thiết bị'),
            cellClassName: 'super-app-theme--cell',
            flex: 1,
            // renderCell: params => params.row.DeviceName === 'All' ? t('Tất cả thiết bị') : params.row.DeviceName
            renderCell: params => <p>{params.row.Device} {t('sync.device')}</p>
        },
        {
            field: 'Time',
            headerName: t('Thời gian'),
            cellClassName: 'super-app-theme--cell',
            flex: 1,
            renderCell: params => params.row.Time && moment(params.row.Time).format('DD-MM-YYYY HH:mm:ss')
        },
        {
            field: 'TriggerBy',
            headerName: t('Thực hiện bởi'),
            cellClassName: 'super-app-theme--cell',
            flex: 1,
            renderCell: params =>
                <LargeTooltip title={showTriggerBy(params.row.TriggerBy)} placement="bottom-start">
                    <p className={classes.textCellStyle}>{showTriggerBy(params.row.TriggerBy)}</p>
                </LargeTooltip>
        },
        // {
        //     field: 'TriggerBy',
        //     headerName: t('Thực hiện bởi'),
        //     cellClassName: 'super-app-theme--cell',
        //     flex: 1,
        //     renderCell: params =>
        //         <LargeTooltip title={showTriggerBy(params.row.TriggerBy)} placement="bottom-start">
        //             <p style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{showTriggerBy(params.row.TriggerBy)}</p>
        //         </LargeTooltip>
        // },
        {
            field: 'SyncResult',
            headerName: t('Kết quả'),
            cellClassName: 'super-app-theme--cell',
            flex: 1,
            sortable: false,
            // renderCell: params =>
            //     <LargeTooltip title={params.row.SyncResult === 'success' ? `${params.row.total} ${t('khuôn mặt đã được đồng bộ thành công')}` : params.row.SyncResult} placement="bottom-start">
            //         {
            //             params.row.success ?
            //                 <p className={classes.textCellStyle}>{params.row.SyncResult}</p> :
            //                 <p className={classes.textCellErrorStyle}>{params.row.SyncResult}</p>
            //         }
            //     </LargeTooltip>
            renderCell: params =>
                <LargeTooltip title={params.row.Result} placement="bottom-start">
                    {showResult(params.row.Result)}
                </LargeTooltip>
        },
        {
            field: 'Method',
            headerName: t('Thao tác'),
            cellClassName: 'super-app-theme--cell',
            width: 150,
            sortable: false,
            // renderCell: params => !params.row.success && <TextButton onClick={() => history.replace(`/admin/device-management/sync-face/detail?id=${params.row.RequestID}&&DeviceId=${params.row.DeviceId}`)} >{t('Xem chi tiết')}</TextButton>
            renderCell: params => <TextButton onClick={() => history.replace(`/admin/device-management/sync-face/detail?id=${params.row.RequestID}`)} >{t('Xem chi tiết')}</TextButton>
        },
    ]

    const showResult = (result) => {
        if (result.indexOf('khuôn mặt bị lỗi') !== -1 || result.indexOf('face error(s)') !== -1) {
            return <p className={classes.textCellErrorStyle}>{result}</p>
        } else {
            return <p className={classes.textCellStyle}>{result}</p>
        }
    }

    const showTriggerBy = (data) => {
        var triggerBy = '';
        switch (data) {
            case undefined: triggerBy = t('Tự động');
                break;
            case 'undefined (undefined)': triggerBy = t('Tự động');
                break;
            default: triggerBy = data;
                break;
        }
        return triggerBy;
    }

    useEffect(() => {
        if (isExpiredSession) {
            history.push('/login');
            dispatch({ type: 'IS_LOGIN_AGAIN_TRUE' });
        }
    }, [isExpiredSession]);

    const sortNumber = (numberA, numberB) => {
        if (numberA > numberB) return -1;
        if (numberA < numberB) return 1;
        return 0;
    }

    // const handleSyncFaceHistories = (data) => {
    //     var historyData = [];
    //     data.forEach((value) => {
    //         var record = {
    //             RequestID: value.RequestID,
    //             Time: value.Time ? value.Time : '',
    //             TriggerBy: value.TriggerBy ? value.TriggerBy : t('Tự động')
    //         }
    //         let values = Object.values(value.Result);
    //         let keys = Object.keys(value.Result);
    //         if (values.length > 0) {
    //             values.forEach((item, key) => {
    //                 if (item.length > 0) {
    //                     historyData.push({
    //                         ...record,
    //                         DeviceId: item[0].DeviceId,
    //                         DeviceName: item[0].DeviceName,
    //                         id: v1(),
    //                         SyncResult: `${item.length} ${t('khuôn mặt bị lỗi')}`
    //                     });
    //                 } else {
    //                     var deviceName = keys[key].slice(keys[key].indexOf('(') + 1, keys[key].indexOf(')'));
    //                     var deviceId = keys[key].slice(0, keys[key].indexOf('('));
    //                     historyData.push({
    //                         ...record,
    //                         DeviceId: deviceId,
    //                         DeviceName: deviceName,
    //                         id: v1(),
    //                         SyncResult: `${value.Total} ${t('khuôn mặt đã được đồng bộ thành công')}`,
    //                         success: true
    //                     });
    //                 }
    //             })
    //         }
    //     })
    //     historyData.sort((a, b) => sortNumber(a.Time, b.Time));
    //     return historyData;
    // }

    const handleSyncFaceHistories = (data) => {
        var historyData = [];
        data.forEach(value => {
            var result = 0;
            var record = {
                RequestID: value.RequestID,
                id: value.RequestID,
                Time: value.Time ? value.Time : '',
                TriggerBy: value.TriggerBy ? value.TriggerBy : t('Tự động'),
            }
            let values = Object.values(value.Result);
            if (values.length > 0) {
                for (var i = 0; i < values.length; i++) {
                    if (result < values[i].length) {
                        result = values[i].length;
                    }
                }
            }
            historyData.push({
                ...record,
                Device: values.length,
                Result: result === 0 ? `${value.Total} ${t('khuôn mặt đã được đồng bộ thành công')}` : `${result} ${t('khuôn mặt bị lỗi')}`
            })
        })
        return historyData;
    }

    const getDefaultValues = async () => {
        try {
            const end = (currentPage + 1) * rowsPerPage;
            const start = end - rowsPerPage;
            const query = {
                start_index: start,
                end_index: end
            };
            const res = await apiSyncFaceidResult(query);
            setRowsTotal(res.data.Count);
            const data = handleSyncFaceHistories(res.data.Items);
            setHistories(data);
        } catch (error) {
            if (error.response && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
        }
        setLoading(true);
    }

    useEffect(() => {
        if (!loading) getDefaultValues();
    }, [loading])

    useEffect(() => {
        setLoading(false);
    }, [i18n.language])

    const onPageChange = (newPage) => {
        setCurrentPage(newPage);
        setLoading(false);
    }

    const onPageSizeChange = (pageSize) => {
        setRowsPerPage(pageSize);
        localStorage.setItem('rowsPerPage', pageSize);
        setLoading(false);
    }

    return (
        <div style={{ height: '100%' }}>
            <p style={{ fontSize: '22px', fontWeight: 'bold', padding: '23px 0 0px 16px' }}>{t('Đồng bộ Face ID')}</p>
            <div className={classes.tableBlock}  >
                <div className={classes.tableTitle}>
                    <CreateButton
                        variant="contained"
                        onClick={() => setLoading(false)}
                        style={{ margin: '7px' }}
                    >
                        {t('Làm mới')}
                    </CreateButton>
                </div>
                <div className={clsx(classes.root, classes.tableStyle)} >
                    <DataGrid
                        componentsProps={{
                            pagination: {
                                labelRowsPerPage: t("Số dòng mỗi trang"),
                                labelDisplayedRows: ({ from, to, count }) => { return `${from}-${to} ${t('trong số')} ${count}` }
                            }
                        }}
                        components={{
                            NoRowsOverlay: () => EmptyRowsView(t('Không tìm thấy lịch sử đồng bộ')),
                            LoadingOverlay: CustomLoading,
                        }}
                        rows={histories}
                        columns={columns}
                        pageSize={rowsPerPage}
                        rowHeight={45}
                        rowsPerPageOptions={[10, 50, 100]}
                        onPageSizeChange={pageSize => onPageSizeChange(pageSize)}
                        hideFooterRowCount
                        disableSelectionOnClick
                        disableColumnMenu={true}
                        scrollbarSize={20}
                        loading={!loading}
                        page={currentPage}
                        rowCount={rowsTotal}
                        paginationMode="server"
                        onPageChange={(newPage) => onPageChange(newPage)}
                    />
                </div>
            </div>
        </div>
    )
}

export default SyncFaceHistories;