import React, { useState, useEffect, useRef } from 'react';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import Button from '@material-ui/core/Button/Button';
import TextField from '@material-ui/core/TextField/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import * as XLSX from 'xlsx';
import md5 from 'md5';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import { isEmpty } from '../../validation/CheckForm';
import { v5 } from 'uuid';
import IconButton from '@material-ui/core/IconButton/IconButton';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DataGrid } from '@material-ui/data-grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { authenticator } from 'otplib';
import SuccessAlert from '../alert/SuccessAlert';
import { apiInfo } from '../../api/info';
import { apiGetapartmentdata } from '../../api/apartment';
import { apiRegisterresidences } from '../../api/registerresidences';
import LargeTooltip from '../largeTooltip';

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '20px',
        fontWeight: '700',
        lineHeight: '27px',
        color: '#000000',
        fontStyle: 'normal',
        float: 'left',
        marginRight: '24px',
        paddingTop: '5px'
    },
    label: {
        paddingBottom: '6px',
        fontSize: '14px',
        lineHeight: '140%',
        color: '#7A7171',
        display: 'inline-block'
    },
    alert: {
        position: 'absolute',
        left: '3px', top: '3px',
        width: '99%'
    },
    dialogTitleBackground: theme.primaryColor,
    dialogFailedTitleBackground: theme.errorColor,
    dialogTitle: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '25px',
        color: '#FFFFFF',
    },
    contentDialog: {
        fontSize: '16px',
        height: '68px',
        fontWeight: '600',
        borderBottom: '1px solid #EEEDF2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    contentBottomForm: {
        width: '97%',
        minWidth: '980px',
        margin: 'auto',
        paddingBottom: '10px'
    },
    root: theme.dataGridStyle,
    secondaryTitle: theme.secondary_title,
    tableTitle: {
        position: 'relative',
        height: '56px',
        border: '1px solid rgba(224, 224, 224, 1)',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px'
    },
    alertStyle: {
        position: 'absolute',
        right: '9px',
        bottom: '8px',
        padding: '0 10px',
        zIndex: '10'
    },
    selectTitle: {
        fontSize: '16px',
        lineHeight: '22.4px',
        position: 'absolute',
        bottom: '8px',
        right: '8px'
    },
    primaryColor: theme.primaryTextColor
}))

const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);
const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);
const ErrorBtn = withStyles(theme => ({ root: theme.error_button }))(Button);
const DeleteButton = withStyles(theme => ({ root: theme.delete_button }))(Button);

const headersKey = [
    { name: "Vai trò", index: 0 },
    { name: "Họ và tên", index: 1 },
    { name: "Email", index: 2 },
    { name: "Số điện thoại", index: 3 },
    { name: "Block", index: 4 },
    { name: "Tầng", index: 5 },
    { name: "Phòng", index: 6 },
    { name: "Quốc tịch", index: 7 },
    { name: "Số CMND/CCCD/Passport", index: 8 },
    { name: "Nơi Cấp", index: 9 },
    { name: "Ngày tháng năm sinh", index: 10 },
    { name: "Giới tính", index: 11 },
    { name: "Nghề nghiệp", index: 12 },
    { name: "Ghi chú", index: 13 },
    { name: 'Tỉnh/Thành Phố', index: 14 },
    { name: 'Huyện/Quận', index: 15 },
    { name: 'Xã/Phường', index: 16 },
    { name: 'Số nhà', index: 17 },
    { name: 'Tài khoản phụ', index: 18 },
]
const headersValue = [
    'ResidenceRole',
    'FullName',
    'Email',
    'Phone',
    'BlockNumber',
    'Floor',
    'Room',
    'Nationality',
    'CMND',
    'IssuePlace',
    'Birthday',
    'Sex',
    'Career',
    'Note',
    'Province',
    'District',
    'Ward',
    'Address',
    'SubAccount'
]

const UserSetting = props => {
    const classes = useStyles();
    const [residences, setResidences] = useState([]);
    const [apartments, setApartments] = useState([]);
    const [residenceData, setResidenceData] = useState([]);
    const [alert, setAlert] = useState({ file: '', error: '', success: '' })
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [isFailedDialog, setIsFailedDialog] = useState(false);
    const [ids, setIds] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filter, setFilter] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [isAdding, setIsAdding] = useState(false);
    const [isExpiredSession, setIsExpiredSession] = useState(false);
    const [fileName, setFileName] = useState('');
    const dispatch = useDispatch();
    const history = useHistory();
    const searchRef = useRef();
    const { t } = useTranslation();

    const columns = [
        {
            headerName: t("Họ và tên"),
            field: 'FullName',
            width: 180,
            cellClassName: 'super-app-theme--cell',
        },
        {
            headerName: t("Vai trò"),
            field: 'ResidenceRole',
            width: 170,
            cellClassName: 'super-app-theme--cell',
            renderCell: params => params.row.ResidenceRole === 'host' ? t('Chủ hộ') : t('Người trong gia đình')
        },
        {
            headerName: t("Số điện thoại"),
            field: 'Phone',
            width: 150,
            cellClassName: 'super-app-theme--cell',
            renderCell: params => showPhone(params.row)
        },
        {
            headerName: t("Phòng"),
            field: 'roomInfo',
            width: 110,
            cellClassName: 'super-app-theme--cell',
            renderCell: params => showInfoRoom(params.row),
            sortComparator: (v1, v2, params1, params2) =>
                v1.localeCompare(v2, undefined, {
                    numeric: true,
                    sensitivity: 'base'
                }),
        },
        {
            headerName: t("Ngày tháng năm sinh"),
            field: 'Birthday',
            cellClassName: 'super-app-theme--cell',
            width: 150
        },
        {
            headerName: t("Giới tính"),
            field: 'Sex',
            width: 120,
            cellClassName: 'super-app-theme--cell',
            renderCell: params => params.row.Sex === 'male' ? t('Nam') : params.row.Sex === 'female' ? t('Nữ') : t('Khác')
        },
        {
            headerName: t("CMND/CCCD/Passport"),
            field: 'CMND',
            width: 150,
            cellClassName: 'super-app-theme--cell',
            renderCell: params => showCMND(params.row)
        },
        {
            headerName: t("Tài khoản phụ"),
            field: 'SubAccount',
            width: 170,
            cellClassName: 'super-app-theme--cell',
            renderCell: params => <p>{params.row.SubAccount === 'yes' ? t("Có") : t("Không")}</p>
        },
        {
            headerName: t("Nghề nghiệp"),
            field: 'Career',
            cellClassName: 'super-app-theme--cell',
            width: 150
        },
        {
            headerName: t("Quốc tịch"),
            field: 'Nationality',
            cellClassName: 'super-app-theme--cell',
            width: 150
        },
        {
            headerName: t("Tỉnh/ Thành phố"),
            field: 'Province',
            cellClassName: 'super-app-theme--cell',
            width: 150
        },
        {
            headerName: t("Huyện/ Quận"),
            field: 'District',
            cellClassName: 'super-app-theme--cell',
            width: 150
        },
        {
            headerName: t("Xã/ Phường"),
            field: 'Ward',
            cellClassName: 'super-app-theme--cell',
            width: 150
        },
        {
            headerName: t("Địa chỉ"),
            field: 'Address',
            cellClassName: 'super-app-theme--cell',
            width: 150
        },

        {
            headerName: t("Nơi cấp"),
            field: 'IssuePlace',
            cellClassName: 'super-app-theme--cell',
            width: 150
        },

        {
            headerName: 'Email',
            field: 'Email',
            cellClassName: 'super-app-theme--cell',
            width: 150,
            renderCell: params => <LargeTooltip title={params.row.Email}><p>{params.row.Email}</p></LargeTooltip>
        },

        {
            headerName: t("Ghi chú"),
            field: 'Note',
            width: 180,
            cellClassName: 'super-app-theme--cell',
            renderCell: params => <LargeTooltip title={params.row.Note}><p>{params.row.Note}</p></LargeTooltip>
        },
    ];

    useEffect(() => {
        if (isExpiredSession) {
            history.push('/login');
            dispatch({ type: 'IS_LOGIN_AGAIN_TRUE' });
        }
    }, [isExpiredSession]);

    const showCMND = (data) => {
        switch (data ?.CMNDNote) {
            case 'existed': return <LargeTooltip title={t("Đã bị trùng")}><p style={{ color: 'red' }}>{data.CMND}</p></LargeTooltip>
            case 'invalid': return <LargeTooltip title={t("CMND/CCCD/Passport không hợp lệ")}><p style={{ color: 'red' }}>{data.CMND}</p></LargeTooltip>
            default: return <p>{data.CMND}</p>
        }
    }

    const showPhone = (data) => {
        switch (data ?.PhoneNote) {
            case 'existed': return <LargeTooltip title={t("Đã bị trùng")}><p style={{ color: 'red' }}>{data.Phone}</p></LargeTooltip>
            case 'invalid': return <LargeTooltip title={t("Số điện thoại không hợp lệ")}><p style={{ color: 'red' }}>{data.Phone}</p></LargeTooltip>
            default: return <p>{data.Phone}</p>
        }
    }

    const showInfoRoom = (info) => {
        if (info.RoomNote && info.RoomNote !== '')
            if (info.RoomNote === 'no-host')
                return (
                    <LargeTooltip title={t("Phòng chưa có chủ hộ")}>
                        <p style={{ color: 'red' }}>{info.roomInfo}</p>
                    </LargeTooltip>
                )
            else
                return (
                    <LargeTooltip title={t("Trùng chủ hộ")}>
                        <p style={{ color: 'red' }}>{info.roomInfo}</p>
                    </LargeTooltip>
                )

        else if (info.EmptyRoom !== '')
            return (
                <LargeTooltip title={t("Phòng không tồn tại")}>
                    <p style={{ color: 'red' }}>{info.roomInfo}</p>
                </LargeTooltip>
            )
        else
            return (
                <LargeTooltip title={'Block: ' + info.BlockNumber + ' ' + t("Tầng") + ': ' + info.Floor + ' ' + t("Phòng") + ': ' + info.Room}>
                    <p>{info.roomInfo}</p>
                </LargeTooltip>
            )
    }

    const getDefaultValues = async () => {
        try {
            var res = await apiInfo({});
            setResidences(res.data.Items);
            var values = await apiGetapartmentdata();
            setApartments(values.data.Items);
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
        }
    }

    useEffect(() => {
        getDefaultValues();
    }, [residenceData]);

    useEffect(() => {
        if (residenceData.length > 0) {
            setLoading(true);
            setIsProcessing(false);
        }
    }, [residenceData])

    const onSearchChange = (e) => {
        const value = e.target.value;
        if (searchRef.current) clearTimeout(searchRef.current);
        searchRef.current = setTimeout(() => {
            setFilter(value);
        }, 500);
    }

    useEffect(() => {
        if (residenceData.length === 0) return;
        const data = residenceData.filter(item =>
            item.FullName.toLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1 ||
            item.Username.toLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1
        );
        setFilteredData(data);
    }, [filter])

    const addMultipleResidences = async (residencs) => {
        await apiRegisterresidences({ multiple_residences: residencs });
    }

    const handleResidenceData = async (data) => {
        try {
            if (data.length === 0) return;
            const res = data.filter(item => !isEmpty(item.CMNDNote) || !isEmpty(item.PhoneNote) || !isEmpty(item.RoomNote) || !isEmpty(item.EmptyRoom));
            if (res.length > 0) {
                setAlert({ ...alert, error: `${res.length} ${res.length > 1 ? t('nhiều cư dân') : t('cư dân')} ${t("đã bị trùng thông tin quan trọng, vui lòng xóa cư dân và thử lại!")}` })
                setIsFailedDialog(true);
                return;
            }
            setIsAdding(true);
            const limit = 20;
            var num;
            if (data.length % 20 === 0) num = data.length / limit;
            else num = data.length / limit + 1;
            for (var i = 1; i <= num; i++) {
                const end = i * limit;
                const start = end - limit;
                var residencs = data.slice(start, end);
                await addMultipleResidences(residencs);
            }
            setAlert({ ...alert, success: t('Tất cả cư dân đã được thêm thành công!') });
            setResidenceData([]);
            setFilteredData([]);
            setFileName([]);
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({ ...alert, error: t("Thêm không thành công, vui lòng thử lại!") })
            setIsFailedDialog(true);
        }
        setIsAdding(false);
        setConfirmDialog(false);
    }

    const addDefaultFeild = (data) => {
        try {
            var residents = [];
            data.forEach(item => {
                var resident = {
                    Address: item.Address,
                    Apartments: item.Apartments,
                    Birthday: item.Birthday,
                    CMND: item.CMND,
                    Career: item.Career,
                    CreatedTime: new Date().getTime(),
                    Province: item.Province,
                    District: item.District,
                    Ward: item.Ward,
                    Email: item.Email,
                    FullName: item.FullName,
                    IssuePlace: item.IssuePlace,
                    Nationality: item.Nationality,
                    Note: item.Note,
                    Username: item.Username,
                    Sex: item.Sex,
                    ResidenceStatus: 'inactive',
                    FaceIdCount: 0,
                    Password: md5('123456789x@X'),
                    SigningKey: v5(item.Username, v5.URL),
                    SubAccount: item.SubAccount === 'yes' ? true : false,
                    PrivateKey: authenticator.generateSecret() + authenticator.generateSecret()
                }
                if (item.Phone === '')
                    residents.push({ ...resident, Phone: item.Phone });
                else
                    residents.push({ ...resident, Phone: item.Phone.indexOf('0') === 0 ? item.Phone.replace('0', '+84') : '+84' + item.Phone });
            })
            return residents;
        } catch (error) {
            setIsFailedDialog(true);
            return [];
        }
    }

    const checkPhone = (phone) => {
        const _9number = /^[1-9][0-9]{8}$/
        const _10number = /^0[0-9]{9}$/
        if (_9number.test(phone)) return 1;
        return _10number.test(phone) ? 2 : -1;
    }

    const checkCMND = (CMND, nationality) => {
        const re = /^(\d{9}|\d{12})$/;
        const pp = /^[A-Z]{1}\d{7}$/;
        if ((re.test(CMND) || pp.test(CMND)) && nationality === 'Việt Nam') return 1;
        if (CMND.length > 7) return 1;
        return 0;
    }

    const processDataFromFile = async (dataString) => {
        var list = [];
        var headers = [];
        for (var key = 0; key < dataString.length; key++) {
            if (key === 0) {
                const result = dataString[key].split(',');
                await result.forEach((value) => {
                    if (typeof (value) !== 'undefined') {
                        const header = headersKey.filter(item => (
                            item.name.trim() === value.trim()
                        ));
                        if (header.length !== 0) headers.push(headersValue[header[0].index]);
                    }

                })
            } else {
                const arrays = dataString[key].split(',');
                var obj = {};
                for (var i = 0; i < headers.length; i++) {
                    obj = {
                        ...obj,
                        [headers[i]]: arrays[i]
                    }
                }
                if (!isEmpty(obj.ResidenceRole) && !isEmpty(obj.BlockNumber) && !isEmpty(obj.Floor) && !isEmpty(obj.Room) && !isEmpty(obj.SubAccount) && !isEmpty(obj.Nationality) && !isEmpty(obj.Sex) && !isEmpty(obj.Birthday)) {
                    const age = new Date().getFullYear() - new Date(obj.Birthday).getFullYear();
                    if (age < 15 || (age > 14 && !isEmpty(obj.CMND))) {
                        var resident = { ...obj, id: key, Username: checkPhone(obj.Phone) === 1 ? '0' + obj.Phone : obj.Phone, Phone: checkPhone(obj.Phone) === 1 ? '0' + obj.Phone : obj.Phone, ResidenceRole: obj.ResidenceRole === 'Chủ hộ' ? 'host' : 'member', Sex: obj.Sex === 'Nam' ? 'male' : obj.Sex === 'Nữ' ? 'female' : 'other' };
                        if ((obj.SubAccount === 'no' && !isEmpty(obj.Phone)) || (obj.SubAccount === 'yes' && obj.ResidenceRole === 'Người trong gia đình')) {
                            list.push({ ...resident, Apartments: [{ block: obj.BlockNumber, floor: obj.Floor, room: obj.Room, role: obj.ResidenceRole === 'Chủ hộ' ? 'host' : 'member' }], Room: obj.Room, roomInfo: obj.BlockNumber + obj.Floor + obj.Room });
                        }
                    }
                }
            }
        }

        if (list.length === 0) {
            setResidenceData([]);
            setFilteredData([]);
            setAlert({ ...alert, file: t("File không có dữ liệu hoặc dữ liệu không chính xác") });
            setIsProcessing(false);
            return;
        }
        setAlert({ ...alert, file: '' });
        var handledData = checkExistedUserInfo(list);
        setResidenceData(handledData);
        setFilteredData(handledData);
    }

    const fileExelHandler = async (e) => {
        const file = e.target.files[0];
        if (typeof (file) === 'undefined') return;
        if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && file.type !== 'application/vnd.ms-excel') {
            setResidenceData([]);
            setFilteredData([]);
            setAlert({ ...alert, file: t("Vui lòng upload file excel!") });
            return;
        }
        setIsProcessing(true);
        setAlert({ ...alert, file: '' });
        setFileName(file.name);
        const reader = new FileReader();
        reader.onload = (evt) => {
            if (reader.readyState === 2) {
                const bstr = reader.result;
                const wb = XLSX.read(bstr, { type: 'binary' });

                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];

                const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
                const residence_data = data.split(/\r\n|\n/);

                processDataFromFile(residence_data);
            }
        }
        reader.readAsBinaryString(file);
    }

    const onAddResidencesClick = () => {
        if (alert.file !== '') return;
        setConfirmDialog(!confirmDialog);
    }

    const onConfirmAddResidences = () => {
        var data = addDefaultFeild(residenceData);
        data = data.sort((a, b) => sortData(a, b));
        handleResidenceData(data);
    }

    const sortData = (a, b) => {
        if (a.ResidenceRole > b.ResidenceRole) return 1;
        if (a.ResidenceRole < b.ResidenceRole) return -1;
        if (a.SubAccount > b.SubAccount) return 1;
        if (a.SubAccount < b.SubAccount) return -1;
        return 0;
    }

    const onAgreeClick = () => {
        setIsFailedDialog(false);
        setConfirmDialog(false);
    }

    const onSelectedChange = (e) => {
        setIds(e);
    }

    const checkExistedUserInfo = (data) => {
        var result = [];
        data.forEach(item => {
            const phone = residences.filter(value => value.Phone === item.Phone.replace('0', '+84'));
            var CMND = [];
            var CMNDFile = [];
            const room = apartments.filter(value => value.BlockID === item.BlockNumber && value.FloorID === item.Floor && value.RoomID === item.Room);
            var roomFile = data.filter(value => value.BlockNumber === item.BlockNumber && value.Floor === item.Floor && value.Room === item.Room && value.ResidenceRole === 'host');
            var roomDB = residences.filter(value => value.BlockNumber === item.BlockNumber && value.Floor === item.Floor && value.Room === item.Room && value.ResidenceRole === 'host');
            if (item.CMND !== '') {
                CMND = residences.filter(value => value.CMND === item.CMND);
                CMNDFile = data.filter(value => value.CMND === item.CMND);
            }
            if (item.ResidenceRole === 'host') {
                if (roomFile.length > 1 || roomDB.length > 0) item = { ...item, RoomNote: 'existed' };
                else item = { ...item, RoomNote: '' };
            }
            if (item.ResidenceRole === 'member' && item.SubAccount === 'yes') {
                if (roomFile.length === 0 && roomDB.length === 0) item = { ...item, RoomNote: 'no-host' };
                else item = { ...item, RoomNote: '' };
            }
            if (item.SubAccount === 'no') {
                const phoneFile = data.filter(value => value.Phone === item.Phone);
                if (phoneFile.length > 1 || phone.length > 0) item = { ...item, PhoneNote: 'existed' };
                else if (checkPhone(item.Phone) === -1 && item.Phone !== '') item = { ...item, PhoneNote: 'invalid' };
                else item = { ...item, PhoneNote: '' };
            }
            if (CMND.length > 0 || CMNDFile.length > 1) item = { ...item, CMNDNote: 'existed' };
            else if (checkCMND(item.CMND, item.Nationality) === 0 && item.CMND !== '') item = { ...item, CMNDNote: 'invalid' };
            else item = { ...item, CMNDNote: '' };
            if (room.length === 0) item = { ...item, EmptyRoom: 'yes' }
            else item = { ...item, EmptyRoom: '' };
            result.push(item);
        })
        return result;
    }

    const onDeleteClick = () => {
        var data = residenceData;
        ids.forEach(value => {
            data = data.filter(item => item.id !== value);
        })
        const handledData = checkExistedUserInfo(data);
        setResidenceData(handledData);
        setFilteredData(handledData);
        setIds([]);
        setLoading(false);
    }

    const onClose = () => {
        setAlert({ ...alert, success: '' });
    }

    useEffect(() => {
        if (alert.success !== '') setTimeout(() => { onClose() }, 5000);
    }, [alert.success]);

    const dialogAddResidences = () => {
        return (
            <Dialog
                open={confirmDialog}
                maxWidth="xl"
            >
                <DialogTitle className={classes.dialogTitleBackground}>
                    <p className={classes.dialogTitle}>{t("Thông báo")}</p>
                </DialogTitle>
                <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center', position: 'relative' }}>
                    <p className={classes.contentDialog}>{t("Bạn có chắc chắn muốn thêm tất cả cư dân?")}</p>
                    <CancelButton
                        style={{ margin: '10px 0' }}
                        onClick={() => { setConfirmDialog(!confirmDialog) }}
                        disabled={isAdding}
                    >
                        {t("Trở về")}
                    </CancelButton>
                    <CreateButton
                        style={{ margin: '10px 0 10px 24px' }}
                        onClick={onConfirmAddResidences}
                        disabled={isAdding}
                    >
                        {t("Đồng ý")}
                    </CreateButton>
                    {isAdding && <CircularProgress style={{ position: 'absolute', left: '45%', top: '10%', color: '#0b59c8' }} />}
                </DialogContent>
            </Dialog>
        )
    }

    const failedDialog = () => {
        return (
            <Dialog
                open={isFailedDialog}
                maxWidth="xl"
            >
                <DialogTitle className={classes.dialogFailedTitleBackground}>
                    <p className={classes.dialogTitle}>{t("Thông báo")}</p>
                </DialogTitle>
                <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center' }}>
                    <p className={classes.contentDialog}>{alert.error}</p>
                    <ErrorBtn style={{ margin: '10px 0' }} onClick={onAgreeClick}>{t("Đồng ý")}</ErrorBtn>
                </DialogContent>

            </Dialog>
        )
    }

    const EmptyRowsView = () => {
        return (
            <div
                style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <h3 style={{ color: '#6D6D6D', fontSize: '16px', fontWeight: 'bold' }}>
                    {t("Không có dữ liệu")}
                </h3>
            </div>
        );
    };

    return (
        <div className={classes.contentBottomForm}>
            {failedDialog()}
            {dialogAddResidences()}
            <SuccessAlert message={alert.success} onCloseDialog={onClose} />
            <div style={{ paddingBottom: '8px', marginTop: '15px', position: 'relative', left: '-7px' }}>
                <IconButton style={{ float: 'left', padding: '2px', marginRight: '5px' }} component={Link} to="/admin/manage-residences" >
                    <ArrowBackIosOutlinedIcon className={classes.primaryColor} style={{ width: '28px', height: '28px' }} />
                </IconButton>
                <p className={classes.secondaryTitle}>{t("Thêm cư dân mới từ file")}</p>
            </div>
            <div style={{ background: '#FFFFFF', padding: '10px 0 20px 0', width: '100%', minWidth: '980px', borderRadius: '6px', position: 'relative' }}>

                <div style={{ width: '98%', height: '70px', marginTop: '22px', margin: 'auto' }}>
                    <input accept=".csv,.xlsx,.xls" hidden id="contained-button-file" multiple type="file" onChange={fileExelHandler} onClick={(e) => e.target.value = ''} />
                    <TextField
                        classes={{
                            root: classes.input
                        }}
                        style={{ width: '320px' }}
                        InputProps={{
                            readOnly: true,
                        }}
                        value={fileName}
                        size="small"
                        placeholder={t("Tên file")}
                        variant="outlined"
                    />
                    <label htmlFor="contained-button-file">
                        <CreateButton variant="contained" color="secondary" component="span">
                            {t("Import")}
                        </CreateButton>
                    </label>
                    <br />
                    <a href="https://aws-sam-cli-managed-default-samclisourcebucket-10b0lj7tjg0nx.s3-ap-southeast-1.amazonaws.com/dev/template/thongtincudan_mau.xlsx" target="_blank" rel="noreferrer" download className={classes.primaryColor} style={{ position: 'relative', top: '-5px' }}>{t("Tải tệp mẫu")}</a>
                    <p style={{ color: "red" }}>{alert.file}</p>
                </div>
                {isProcessing && <CircularProgress style={{ marginLeft: '45%', color: '#0b59c8' }} />}
                {loading && residenceData.length > 0 &&
                    <div style={{ width: '98%', margin: 'auto', minWidth: '980px' }}>
                        <div className={classes.tableTitle}>

                            <LargeTooltip title="Xóa" >
                                <span>
                                    <DeleteButton
                                        variant="outlined"
                                        onClick={onDeleteClick}
                                        disabled={ids.length === 0}
                                        startIcon={<DeleteOutlineOutlinedIcon />}
                                        style={{ float: 'left', margin: '8px 8px 0 8px' }}
                                    >
                                        {t('Xóa')}
                                    </DeleteButton>
                                </span>
                            </LargeTooltip>
                            <TextField
                                style={{ position: 'relative', top: '8px', float: 'left' }}
                                placeholder={t("Tìm kiếm")}
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                    style: { background: '#FFFFFF', height: '40px', width: '320px' }
                                }}
                                onChange={onSearchChange}
                            />
                            <CreateButton variant="contained" onClick={onAddResidencesClick} style={{ float: 'right', margin: '8px 8px 0 8px' }} >{t("Thêm tất cả")}</CreateButton>
                            <CancelButton component={Link} to='/admin/manage-residences' style={{ float: 'right', marginTop: '8px' }}>{t("Hủy")}</CancelButton>
                        </div>
                        <div style={{ width: '100%', minWidth: '980px', height: '660px' }} className={classes.root}>
                            <DataGrid
                                componentsProps={{
                                    pagination: {
                                        labelRowsPerPage: t("Số dòng mỗi trang"),
                                        labelDisplayedRows: ({ from, to, count }) => { return `${from}-${to} ${t("trong số")} ${count}` }
                                    }
                                }}
                                localeText={{
                                    footerRowSelected: (count) => count + t(" hàng trong bảng đã được chọn")
                                }}
                                rows={filteredData}
                                columns={columns}
                                pageSize={rowsPerPage}
                                rowsPerPageOptions={[10, 50, 100]}
                                onPageSizeChange={e => setRowsPerPage(e.pageSize)}
                                checkboxSelection
                                onSelectionModelChange={onSelectedChange}
                                hideFooterRowCount
                                disableColumnMenu
                                components={{
                                    NoRowsOverlay: EmptyRowsView
                                }}
                            />
                        </div>
                    </div>}
            </div>
        </div>
    )
}

export default UserSetting;
