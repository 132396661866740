import React, { useState, useEffect } from 'react';
import * as Flashphoner from '@flashphoner/websdk/src/flashphoner-core.js';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';

const useStyles = makeStyles((theme) => ({
    styleCamera: {
        '& video': {
            width: '100%',
            height: '100%',
        },
        width: '100%',
        height: '100%'
    }
}))

//Status constants
var SESSION_STATUS = Flashphoner.constants.SESSION_STATUS;
var STREAM_STATUS = Flashphoner.constants.STREAM_STATUS;
var PRELOADER_URL = "https://github.com/flashphoner/flashphoner_client/raw/wcs_api-2.0/examples/demo/dependencies/media/preloader.mp4";

const Camera = React.memo((props) => {
    const { camera, id } = props;
    const classes = useStyles();
    var session;

    useEffect(() => {
        if (camera) init_api();
    }, [camera])

    const init_api = () => {
        Flashphoner.init({});
        connect();
    }

    const connect = () => {
        session = Flashphoner.createSession({
            urlServer: "wss://demo.flashphoner.com"
        }).on(SESSION_STATUS.ESTABLISHED, function (session) {
            playStream(session)
        });
    }

    //Detect browser
    // var Browser = {
    //     isSafari: function() {
    //         return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    //     },
    // }

    const playStream = () => {
        var options = {
            name: camera,
            display: document.getElementById(id || "myVideo")
        }
        var stream = session.createStream(options).on(STREAM_STATUS.PLAYING, function () {
            console.log('playing...')
        })
        stream.play();
    }

    return (
        <div className={classes.styleCamera}>
            <div id={id || "myVideo"} style={{ width: '100%', height: '100%', border: '1px solid #80808038' }}></div>
        </div>
    )
})

export default Camera;