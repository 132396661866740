import { DataGrid } from '@material-ui/data-grid';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { apiTlockerProfileDelete, apiTlockerProfileGet } from '../../../api/cabinets';
import ErrorAlert from '../../../components/alert/ErrorAlert';
import SuccessAlert from '../../../components/alert/SuccessAlert';
import { AddButton, DeleteButton } from '../../../components/button';
import { Confirm } from '../../../components/dialog';
import { CustomLoading } from '../../../components/grid/customLoading';
import { EmptyRowsView } from '../../../components/grid/emtyRowsView';
import LargeTooltip from '../../../components/largeTooltip';
import { PrimaryTitle } from '../../../components/title';
import { useStyles } from './styles';

export default function DeviceConfig() {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const { url } = useRouteMatch();

    const [state, setState] = useState({
        listDeviceConfig: [],
        listProfilesSelected: [],
        listLogosWillDeleted: [],
        responseStatus: {
            succ: '',
            err: ''
        },
        isLoading: false,
        isConfirm: false,
        isConfirmLoading: false
    })
    const [pagination, setPagination] = useState({
        page: 0,
        pageSize: 10,
        rowCount: 0
    })

    const fetchApiTlockerProfileGet = async () => {
        setState(prev => ({
            ...prev,
            isLoading: true
        }))

        try {
            const { data } = await apiTlockerProfileGet();
            const listDeviceConfig = data?.Items?.map(item => {
                item.id = item.ProfileName;
                return item;
            })

            setState(prev => ({
                ...prev,
                listDeviceConfig: listDeviceConfig || []
            }));
        } catch (error) {
            handleChangeResponseStatus("", t("Tải danh sách cấu hình thất bại. Vui lòng tải lại!"));
        }

        setState(prev => ({
            ...prev,
            isLoading: false
        }))
    }

    const fetchApiTlockerProfileDelete = async (body) => {
        setState(prev => ({
            ...prev,
            isConfirmLoading: true
        }));

        try {
            await apiTlockerProfileDelete(body);

            handleChangeResponseStatus(t("Xóa thành công mục đã chọn"));
            fetchApiTlockerProfileGet();
        } catch (error) {
            handleChangeResponseStatus("", t("Xóa không thành công mục đã chọn"));
        }

        handleCloseConfirm();
    }

    const handleChangeResponseStatus = (succ = '', err = '') => {
        setState(prev => ({
            ...prev,
            responseStatus: {
                succ,
                err
            }
        }))
    }

    const handleOnClickAddDevice = () => {
        history.push(`${url}/add`);
    }

    const handleOnClickToDetails = (ProfileName) => {
        history.push(`${url}/edit/${ProfileName}`);
    }

    const handleOnSelectionModelChange = (arrayProfiles) => {
        const listLogosWillDeleted = [];

        arrayProfiles.forEach(profileNameItem => {
            state.listDeviceConfig.findIndex(deviceConfigItem => {
                if (profileNameItem === deviceConfigItem.ProfileName) {
                    listLogosWillDeleted.push(deviceConfigItem?.Payload?.profileLogo);
                    return true;
                }
            })
        })

        setState(prev => ({
            ...prev,
            listProfilesSelected: arrayProfiles,
            listLogosWillDeleted
        }))
    }

    const handleCloseConfirm = () => {
        setState(prev => ({
            ...prev,
            isConfirm: false,
            isConfirmLoading: false
        }))
    }

    const handleOnConfirm = () => {
        const body = {
            profiles: state.listProfilesSelected || [],
            logos: state.listLogosWillDeleted || []
        };

        fetchApiTlockerProfileDelete(body);
    }

    const handleOnClickDelete = () => {
        if (!state?.listProfilesSelected) return;

        setState(prev => ({
            ...prev,
            isConfirm: true
        }))
    }

    const onPageSizeChange = (pageSize) => {
        setPagination(prev => ({
            ...prev,
            pageSize
        }))
    }

    useEffect(() => {
        fetchApiTlockerProfileGet();
    }, [])

    const columns = [
        {
            headerName: t("Tên cấu hình"),
            field: 'ProfileName',
            cellClassName: 'super-app-theme--cell',
            // sortComparator: (v1, v2) => sortNumber(v1, v2),
            renderCell: params => (
                <LargeTooltip
                    title={params?.row?.ProfileName}
                >
                    <p style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }}>
                        {params?.row?.ProfileName}
                    </p>
                </LargeTooltip>
            ),
            flex: 1,
        },
        {
            headerName: t("Miêu tả"),
            field: 'Description',
            cellClassName: 'super-app-theme--cell',
            // sortComparator: (v1, v2) => sortNumber(v1, v2),
            renderCell: params => (
                <LargeTooltip
                    title={params?.row?.Description}
                >
                    <p style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }}>
                        {params?.row?.Description}
                    </p>
                </LargeTooltip>
            ),
            flex: 1,
        },
        {
            headerName: t("Thao tác"),
            field: 'action',
            cellClassName: 'super-app-theme--cell',
            // sortComparator: (v1, v2) => sortNumber(v1, v2),
            renderCell: params => (
                <p
                    style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        color: '#0b59c8',
                        cursor: 'pointer'
                    }}
                    onClick={() => handleOnClickToDetails(params?.row?.ProfileName)}
                >
                    {t("Xem chi tiết")}
                </p>
            ),
            width: 200,
        },
    ]

    return (
        <div>
            <div className={classes.wrapHead}>
                <PrimaryTitle>{t("Danh sách cấu hình thiết bị")}</PrimaryTitle>
                <div className={classes.wrapAct}>
                    <DeleteButton
                        disabled={!state.listProfilesSelected.length}
                        onClick={handleOnClickDelete}
                    >
                        {t("Xóa")}
                    </DeleteButton>
                    <AddButton
                        onClick={handleOnClickAddDevice}
                    >
                        {t("Thêm")}
                    </AddButton>
                </div>
            </div>

            <div className={clsx(classes.table, classes.root)}>
                <DataGrid
                    disableSelectionOnClick
                    disableColumnMenu
                    checkboxSelection
                    componentsProps={{
                        pagination: {
                            labelRowsPerPage: t("Số dòng mỗi trang"),
                            labelDisplayedRows: ({ from, to, count }) => { return `${from}-${to} ${t("trong số")} ${count}` }
                        }
                    }}
                    components={{
                        NoRowsOverlay: () => EmptyRowsView(t("không tìm thấy dữ liệu")),
                        LoadingOverlay: CustomLoading
                    }}
                    localeText={{
                        footerRowSelected: (count) => count + t(" hàng trong bảng đã được chọn"),
                        columnHeaderSortIconLabel: t('Sắp xếp')
                    }}
                    loading={state.isLoading}
                    columns={columns}
                    rows={state.listDeviceConfig}
                    // page={pagination.page}
                    pageSize={pagination.pageSize}
                    // rowCount={pagination.rowCount}
                    rowHeight={45}
                    rowsPerPageOptions={[10, 50, 100]}
                    // paginationMode="server"
                    onPageSizeChange={(pageSize) => onPageSizeChange(pageSize)}
                    // onPageChange={(newPage) => onPageChange(newPage)}
                    // onRowClick={(e) => onRowClick(e)}
                    selectionModel={state.listProfilesSelected}
                    onSelectionModelChange={handleOnSelectionModelChange}
                />
            </div>

            <SuccessAlert
                message={state.responseStatus.succ}
                onCloseDialog={() => handleChangeResponseStatus()}
            />

            <ErrorAlert
                message={state.responseStatus.err}
                onCloseDialog={() => handleChangeResponseStatus()}
            />

            <Confirm
                isOpen={state.isConfirm}
                handleClose={handleCloseConfirm}
                isLoading={state.isConfirmLoading}
                mess={t("Bạn có chắc muốn xóa?")}
                onConfirm={handleOnConfirm}
            />
        </div>
    )
}
