import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { apiCabinetsRevenueDateChartsGet } from '../../../../api/cabinets';
import { useStyles } from './styles';

const CabinetStatisLine = React.memo(({
    cabinetId,
    date,
    handleToggleCabinetLineLoading,
    handleChangeTotalRevenue
}) => {
    const classes = useStyles();
    const moment = require('moment-timezone');
    const { t } = useTranslation();

    const [state, setState] = useState({
        dataLine: {
            labels: [],
            datasets: [
                {
                    label: t("Doanh thu tủ"),
                    data: [],
                    fill: false,
                    borderColor: '#0FA7E7',
                    backgroundColor: '#0FA7E7',
                    color: '#FFFFFF'
                }
            ]
        }
    })

    const fetchApiCabinetsRevenueDateChartsGet = async (body) => {
        handleToggleCabinetLineLoading();

        try {
            const { data } = await apiCabinetsRevenueDateChartsGet(body);
            let dataLine = {};

            if (data?.values) {
                let labels = data.labels;

                if (labels.length > 25) {
                    labels = data.labels.map(labelsItem => moment.tz(labelsItem, "Asia/Ho_Chi_Minh").format("DD/MM"));
                }

                dataLine = {
                    labels: labels,
                    datasets: [{
                        label: t("Doanh thu tủ"),
                        data: [...data?.values],
                        fill: false,
                        borderColor: '#0FA7E7',
                        backgroundColor: '#0FA7E7',
                        color: '#FFFFFF'
                    }]
                }
            }

            handleChangeTotalRevenue(data?.totalRevenue);
            setState(prev => ({
                ...prev,
                dataLine
            }))
        } catch (error) {

        }

        handleToggleCabinetLineLoading();
    }

    useEffect(() => {
        if (!date || !cabinetId) return;

        fetchApiCabinetsRevenueDateChartsGet({ date, cabinetId });
    }, [cabinetId, date])

    return (
        <div className={classes.line}>
            <Line
                height="70px"
                data={state.dataLine}
                options={{
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false,
                        }
                    },
                    scales: {
                        y: {
                            beginAtZero: true,
                            suggestedMin: 0,
                            suggestedMax: 10,
                            ticks: {
                                callback: function (value) {
                                    return value < 1000 ? value : value / 1000 + 'k';
                                }
                            }
                        },
                    },

                }}
            />
        </div>
    )
})

export default CabinetStatisLine;