import React,{useState, useEffect} from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField/TextField';
import Button from '@material-ui/core/Button/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import axiosClient from '../../config/AxiosClient';
import Zoom from '@material-ui/core/Zoom/Zoom';
import { adminStyles } from '../../styles/style';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import { useDispatch } from 'react-redux';
import {useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import clsx from 'clsx';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

const CreateButton = withStyles(theme =>({root:theme.create_button,}))(Button);
const CancelButton = withStyles(theme => ({root:theme.cancel_button}))(Button);
const DeleteButton = withStyles(theme => ({root:{height:'40px',
    width:'auto',
    textTransform:'none',
    color:'#FFFFFF',
    fontSize:'18px',
    background:'#e01010',
    marginLeft:'16px',
    padding:'8px 16px',
    '&:hover':{background:'#e01010'}
}}))(Button);

const EditAdminForm = props => {
    const {changeStatusEdit,username,lockAdminUser,unLockAdminUser,updateAdminUserToAws} = props;
    const classes = adminStyles();
    const [alert, setAlert] = useState({email:'',username:'',password:'',request:'',display_name:''});
    const [account, setAccount] = useState({email:'',username:'',password:'',display_name:'',role:'',status:''});
    const [loading,setLoading] = useState(false);
    const [edit,setEdit] = useState(false);
    const [isResetPassword,setIsResetPassword] = useState(false);
    const [isLock,setIsLock] = useState(false);
    const [isUnLock,setIsUnlock] = useState(false);
    const [isExpiredSession,setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const {t} = useTranslation();
    
    useEffect(()=>{
        if(isExpiredSession){
          history.push('/login');
          dispatch({type:'IS_LOGIN_AGAIN_TRUE'});
        } 
    },[isExpiredSession]);
    
    const getDefaultValues = async()=>{
        try {
            var res;
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
              res = await axiosClient.post('/adminuser',{'username':username},{
                headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
              });
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
              res = await axiosClient.post('/adminusers/get',{'username':username},{
                headers:{'Authorization': `Bearer ${Cookies.get('token')}`,'Content-Type': 'application/json'}
              });
            }
            const data = res.data.Items[0];
            setAccount({
                display_name:typeof(data.DisplayName)!=='undefined'?data.DisplayName:'',
                email:data.Email,
                username:data.Username,
                password:data.Password,
                role:data.AdminRole,
                status:data.AdminStatus
            });
            setLoading(true);
            setAlert({email:'',password:'',ussername:'',request:'',display_name:''});
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);    
            setAlert({...alert,request:t("Không lấy được dữ liệu")});
        }
    }
    useEffect(() => {
        if(!loading) getDefaultValues();
    }, [loading])
    useEffect(()=>{
        setLoading(false);
    },[username])
    const onSaveClick = () =>{
        setIsResetPassword(true);
    }
    const onConfirmResetPassword = () =>{
        updateAdminUserToAws(account);
        changeStatusEdit();
    }
    
    const onConfirmLockClick = () =>{
        lockAdminUser(account.username);
        changeStatusEdit();
    }
    const onConfirmUnLockClick = () =>{
        unLockAdminUser(account.username);
        changeStatusEdit();
    }
    const changeEditStatus = () =>{
        setEdit(!edit);
        if(edit) setLoading(false);
    }

    const onRoleChange = (e)=>{
        setAccount({...account,role:e.target.value});
    }
    
    const confirmLockDialog = () =>{
        return(
            <Dialog 
              open={isLock}
              maxWidth="xl"
            >
              <DialogTitle className={classes.dialogTitleBackground}>
                  <p className={classes.dialogTitle}>{t("Thông báo")}</p>
              </DialogTitle>
              <DialogContent style={{width:'448px',padding:'0',textAlign:'center',position:'relative'}}>
                    <div className={classes.contentDialog}>
                        <p>{t("Bạn có chắc chắn muốn khóa tài khoản")} <br /> <b>{account.username} ?</b></p>
                        
                    </div>
                  <CancelButton 
                    style={{marginLeft:'20px'}}
                    variant="outlined" 
                    onClick={()=>{setIsLock(false)}} 
                  >
                    {t("Trở về")}
                  </CancelButton>
                  <CreateButton 
                    variant="contained" 
                    style={{margin:'10px 16px'}} 
                    onClick={onConfirmLockClick} 
                  >
                    {t("Đồng ý")}
                  </CreateButton>
              </DialogContent>
            </Dialog>
        )
    }
    const confirmUnLockDialog = () =>{
        return(
            <Dialog 
              open={isUnLock}
              maxWidth="xl"
            >
              <DialogTitle className={classes.dialogTitleBackground}>
                  <p className={classes.dialogTitle}>{t("Thông báo")}</p>
              </DialogTitle>
              <DialogContent style={{width:'448px',padding:'0',textAlign:'center',position:'relative'}}>
                    <div className={classes.contentDialog}>
                        <p>{t("Bạn có chắc chắn muốn mở khóa tài khoản")} <br /> <b>{account.username} ?</b></p>
                        
                    </div>
                  <CancelButton 
                    style={{marginLeft:'20px'}}
                    variant="outlined" 
                    onClick={()=>{setIsUnlock(false)}} 
                  >
                    {t("Trở về")}
                  </CancelButton>
                  <CreateButton 
                    variant="contained" 
                    style={{margin:'10px 16px'}} 
                    onClick={onConfirmUnLockClick} 
                  >
                    {t("Đồng ý")}
                  </CreateButton>
              </DialogContent>
            </Dialog>
        )
    }

    const getConfirmResetPassword = () =>{
        return(
            <Dialog 
              open={isResetPassword}
              maxWidth="xl"
            >
              <DialogTitle className={classes.dialogTitleBackground}>
                  <p className={classes.dialogTitle}>{t("Thông báo")}</p>
              </DialogTitle>
              <DialogContent style={{width:'448px',padding:'0',textAlign:'center',position:'relative'}}>
                    <div className={classes.contentDialog}>
                        <p>{t("Bạn có chắc chắn muốn lưu thay đổi?")}</p>      
                    </div>
                  <CancelButton 
                    style={{marginLeft:'20px'}}
                    variant="outlined" 
                    onClick={()=>{setIsResetPassword(false)}} 
                  >
                    {t("Trở về")}
                  </CancelButton>
                  <CreateButton 
                    variant="contained" 
                    style={{margin:'10px 16px'}} 
                    onClick={onConfirmResetPassword} 
                  >
                    {t("Đồng ý")}
                  </CreateButton>
              </DialogContent>
            </Dialog>
        )
    }
    return (
        <Zoom in={true} style={{transitionDelay:'300ms'}}>    
            <div className={clsx(classes.formContent,classes.focus)}>       
                {getConfirmResetPassword()} 
                {confirmLockDialog()}
                {confirmUnLockDialog()}  
                <div style={{height:'37px',marginTop:'5px'}}>
                    <IconButton style={{float:'left',padding:'4px',marginLeft:'10px'}} onClick={()=>changeStatusEdit()} >
                        <ArrowBackIosOutlinedIcon className={classes.primaryTextColor} style={{width:'26px',height:'26px'}} />
                    </IconButton>
                    <p className={classes.primaryTitle}  style={{marginLeft:'10px',position:'relative',left:'-8px'}}  >
                        {t('ĐÓNG')}
                    </p>
                </div>
                {!loading && alert.request==='' && <CircularProgress style={{position:'absolute',left:'50%',color:'#0b59c8'}} />}
                {alert.request !=='' && <div className={classes.requestError}>{alert.request}</div>}
                {loading && alert.request==='' &&
                <form>   
                    <div style={{width:'90%',margin:'auto',height:'80px'}}>
                        <label className={classes.label}>{t('Tên người quản trị')} </label><br/>
                        <TextField
                            placeholder={t('Tên người quản trị')}
                            variant="outlined"
                            style={{width:'100%'}}
                            size="small"
                            value={account.display_name}
                            disabled
                        />
                    </div>
                    <div style={{width:'90%',margin:'auto',height:'80px'}}>
                        <label className={classes.label}>Email </label><br/>
                        <TextField
                            name="email"
                            disabled
                            value={account.email}
                            placeholder="Email"
                            variant="outlined"
                            style={{width:'100%'}}
                            size="small"
                        />
                    </div>
                    <div style={{width:'90%',margin:'auto',height:'80px'}}>
                        <label className={classes.label}>{t("Mật khẩu")}  </label><br/>
                        <TextField
                            name="password"
                            disabled
                            value={account.password}
                            placeholder={t("Mật khẩu")}
                            variant="outlined"
                            style={{width:'100%'}}
                            size="small"
                            type={"password"}
                        />
                    </div>
                    <div style={{width:'90%',margin:'auto',height:'100px'}} className={classes.select}>
                        <FormControl required variant="outlined" classes={{root:classes.input}} style={{width:'100%'}}>
                            <label className={classes.label} >{t('Vai trò')} </label>
                            <Select
                                value={account.role}
                                style={{height:'40px',background:'#FFFFFF',width:'100%'}}
                                name="role"
                                onChange={onRoleChange}
                                disabled={!edit}
                            >
                                <MenuItem value='moderator'>{t('Người điều hành')}</MenuItem>
                                <MenuItem value='admin'>{t('Quản trị')}</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div style={{width:'90%',margin:'auto',textAlign:'right',paddingBottom:'10px'}}>
                        {account.role !== 'root' && account.status ==='active'&& !edit && 
                        <DeleteButton variant="contained" onClick={()=>{setIsLock(true)}} style={{marginRight:'16px'}} >
                            {t('Khóa')}
                        </DeleteButton>}
                        {account.role !== 'root' && account.status ==='deactivated'&& !edit && 
                        <CreateButton variant="contained" onClick={()=>{setIsUnlock(true)}} style={{marginRight:'16px'}} >
                            {t('Mở khóa')}
                        </CreateButton>}
                        {!edit && localStorage.getItem('username') !== account.username &&<CreateButton
                            variant="contained"
                            onClick={changeEditStatus}
                            style={{margin:'0'}}
                        >
                            {t('Chỉnh sửa')}
                        </CreateButton>}
                        {edit && <CancelButton
                            variant='outlined'
                            onClick={changeEditStatus}
                        >
                            {t('Hủy')}
                        </CancelButton>}
                        {edit && <CreateButton
                            variant="contained"
                            onClick={onSaveClick}
                        >
                            {t('Lưu')}
                        </CreateButton>}
                    </div>
                </form>}
            </div>
        </Zoom>
    )
}

export default EditAdminForm;
