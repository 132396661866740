import React, { useState, useRef, useEffect } from 'react';
import SuccessAlert from '../../alert/SuccessAlert';
import ErrorAlert from '../../alert/ErrorAlert';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import { apiGetmultiplepayments } from '../../../api/payments';
import axios from 'axios';

const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);

const ExportPaymentFile = () => {
    const { t } = useTranslation();
    const [alert, setAlert] = useState({
        success: '',
        error: ''
    });
    const [isExporting, setIsExporting] = useState(false);
    const alertRef = useRef();

    const getStatusPayment = (status) => {
        switch (status) {
            case 'unpaid': return t('Chưa thanh toán');
            case 'paid': return t('Đã thanh toán')
            default: return t('Quá hạn');
        }
    }

    const getpaymentInfo = async () => {
        var res = await apiGetmultiplepayments({});
        const fileData = await axios.get(res.data);
        const data = [];
        fileData.data.Items.forEach(item => {
            data.push({
                Apartment: item.Apartment,
                RequestInfo: item.RequestInfo,
                RequestDate: moment(item.RequestDate).format('DD-MM-YYYY'),
                ExpiredDate: item.ExpiredDate ? moment(item.ExpiredDate).format('DD-MM-YYYY') : '',
                PaymentDate: item.PaymentDate ? moment(item.PaymentDate).format('DD-MM-YYYY') : '',
                Amount: item.Amount,
                Status: getStatusPayment(item.PaymentStatus)
            });
        })
        return data;
    }

    const exportFileCSV = async () => {
        setIsExporting(true);
        try {
            var payments = await getpaymentInfo();
            if (payments === null) return null;
            const header = [[
                t('title.apartment'),
                t('Tên hóa đơn'),
                t('Ngày tạo'),
                t('Ngày đến hạn'),
                t('Ngày thanh toán'),
                t('Tổng tiền'),
                t('Trạng thái')
            ]];
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel;charset=UTF-8';
            const fileExtension = '.xlsx';

            const ws = XLSX.utils.book_new();
            XLSX.utils.sheet_add_aoa(ws, header);

            XLSX.utils.sheet_add_json(ws, payments, { origin: 'A2', skipHeader: true });
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, `Thong_tin_thanh_toan_${moment().format('DD_MM_YYYY_HH_mm_ss')}` + fileExtension);
            setAlert({ success: t('Export file thành công!'), error: '' })
        } catch (error) {
            setAlert({
                error: t('Export file không thành công, vui lòng thử lại sau!'),
                success: ''
            })
        }
        setIsExporting(false);
    }

    const onAlertClose = () => {
        setAlert({
            ...alert,
            success: '',
            error: ''
        });
    }

    useEffect(() => {
        if (alert.success !== '' || alert.error !== '') {
            if (alertRef.current) clearTimeout(alertRef.current);
            alertRef.current = setTimeout(() => {
                onAlertClose();
            }, 5000);
        }
    }, [alert])

    return (
        <div >
            <SuccessAlert
                message={alert.success}
                onCloseDialog={onAlertClose}
            />
            <ErrorAlert
                message={alert.error}
                onCloseDialog={onAlertClose}
            />
            <CreateButton
                variant="contained"
                style={{ margin: '8px 0 0 0' }}
                disabled={isExporting}
                onClick={exportFileCSV}
            >
                {t("Xuất dữ liệu")}
                {isExporting && <CircularProgress size={25} style={{ position: 'absolute', color: '#0b59c8' }} />}
            </CreateButton>
        </div>
    )
}

export default ExportPaymentFile;