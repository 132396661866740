import React, { useState, useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button/Button';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import TextField from '@material-ui/core/TextField/TextField';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import IconButton from '@material-ui/core/IconButton/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import axiosClient from '../../../config/AxiosClient';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { Link, useHistory } from 'react-router-dom';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { GridOverlay, DataGrid } from '@material-ui/data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import SuccessAlert from '../../alert/SuccessAlert';
import ErrorAlert from '../../alert/ErrorAlert';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { apiSmartcameraDevice, apiSmartcameraDeviceDelete, apiSmartcameraSyncface } from '../../../api/smartcamera';
import { FONT_FAMILY } from '../../../constants/cssCommon';

const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: '14px',
        lineHeight: '140%',
        color: '#000000',
        display: 'inline-block',
        paddingTop: '10px'
    },
    content: {
        width: '98%',
        margin: 'auto',
        borderRadius: '6px',
        display: 'flex',
        flexWarp: 'wrap'
    },
    primaryColor: theme.primaryTextColor,
    dialogTitleBackground: theme.primaryColor,
    dialogTitle: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '25px',
        color: '#FFFFFF',
        fontFamily: FONT_FAMILY
    },
    contentDialog: {
        fontSize: '16px',
        height: '68px',
        borderBottom: '1px solid #EEEDF2',
        display: 'flex'
        , alignItems: 'center',
        justifyContent: 'center'
    },
    dialogFailedTitleBackground: theme.errorColor,
    overlay: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        background: 'rgba(0, 0, 0, 0.1)',
        zIndex: '1300'
    },
    secondaryTitle: theme.secondary_title,
    focus: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: theme.themeColor,
            },
        },
    },
    textFeild: {
        fontWeight: 'bold',
        fontSize: '30px'
    },
    primaryTitle: theme.primary_title,
    topBlock: {
        marginTop: '22px',
        marginBottom: '20px',
        height: '40px',
        width: '98%',
        marginLeft: '16px',
        display: 'flex'
    },
    root: theme.dataGridStyle,
    tableHeader: {
        width: '100%',
        height: '56px',
        background: '#FFFFFF',
        border: '1px solid #E0E0E0',
        borderRadius: '6px 6px 0 0',
        position: 'relative'
    },
    tableSection: {
        height: '80%',
        width: '98%',
        paddingBottom: '15px',
        margin: 'auto'
    },
    tableItem: {
        height: '98%',
        background: '#FFFFFF'
    },
    syncStyle: {
        margin: '7px',
        float: 'right'
    },
    addButtonStyle: {
        margin: '8px!important'
    },
    title: {
        fontSize: '22px',
        fontWeight: 'bold',
        padding: '23px 0 15px 0',
        width: '98%',
        margin: 'auto'
    },
    cameraPage: {
        position: 'relative',
        paddingBottom: '12px',
        height: '100%'
    }
}))

const CreateButton = withStyles(theme => ({ root: theme.create_button, }))(Button);
const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);
const ErrorButton = withStyles(theme => ({ root: theme.error_button }))(Button);
const DeleteButton = withStyles(theme => ({ root: theme.delete_button }))(Button);
const TextButton = withStyles(theme => ({ root: theme.moreDetail }))(Button);

const CameraTable = props => {
    const classes = useStyles();
    const [cameras, setCameras] = useState([]);
    const [loading, setLoading] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedIds, setSelectedIds] = useState('');
    const [isZoom, setIsZoom] = useState(false);
    const [fileStr, setFileStr] = useState('');
    const [alert, setAlert] = useState({ success: '', error: '' });
    const [isSuccessDialog, setIsSuccessDialog] = useState(false);
    const [isFailedDialog, setIsFailedDialog] = useState(false);
    const [synConfirmation, setSyncConfirmation] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [isExpiredSession, setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const zoomRef = useRef();
    const alertRef = useRef();

    const columns = [
        {
            field: 'CameraName',
            headerName: t('Tên máy ảnh'),
            cellClassName: 'super-app-theme--cell',
            flex: 1
        },
        {
            field: 'MqttTopicPrefix',
            headerName: t('Tiền tố MQTT'),
            cellClassName: 'super-app-theme--cell',
            flex: 1
        },
        {
            field: 'RtspLink',
            headerName: t('RTSP'),
            cellClassName: 'super-app-theme--cell',
            flex: 1,
        },
    ]

    useEffect(() => {
        if (isExpiredSession) {
            history.push('/login');
            dispatch({ type: 'IS_LOGIN_AGAIN_TRUE' });
        }
    }, [isExpiredSession]);

    const getDefaultValues = async () => {
        try {
            const res = await apiSmartcameraDevice();
            const data = [];
            res.data.Items.forEach(item => {
                data.push({ ...item, id: item.ID });
            })
            setCameras(data);
        } catch (error) {
            if (error.response ?.data === "Invalid LoginToken") return setIsExpiredSession(true);
        }
        setLoading(true);
    }

    useEffect(() => {
        if (!loading) getDefaultValues();
    }, [loading])

    const onSelectedChange = (e) => {
        setSelectedIds(e);
    }

    const onDelComfirmationClick = async () => {
        try {
            setIsProcessing(true);
            await apiSmartcameraDeviceDelete({ ids: selectedIds });
            setAlert({ ...alert, success: t('Xóa máy ảnh thành công!'), error: '' });
            setSelectedIds([]);
            setLoading(false);
        } catch (error) {
            if (error.response ?.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({ ...alert, error: t('Xóa không thành công, vui lòng thử lại sau!'), success: '' });
        }
        setIsDelete(false);
        setIsProcessing(false);
    }

    const onSyncConfirmationClick = async () => {
        try {
            setIsProcessing(true);
            await apiSmartcameraSyncface();
            dispatch({ type: 'CHANGE_SYNC_FACE_STATUS' });
            setLoading(false);
        } catch (error) {
            if (error.response ?.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({ ...alert, error: t('Đồng bộ dữ liệu không thành công, vui lòng thử lại sau!') });
        }
        setSyncConfirmation(false);
        setIsProcessing(false);
    }

    const onAlertClose = () => {
        setAlert({ ...alert, success: '', error: '' });
    }

    useEffect(() => {
        if (alert.success !== '' || alert.error !== '') {
            if (alertRef.current) clearTimeout(alertRef.current);
            alertRef.current = setTimeout(() => {
                onAlertClose();
            }, 5000);
        }
    }, [alert])

    const syncConfirmDialog = () => {
        return (
            <Dialog
                open={synConfirmation}
                maxWidth="xl"
            >
                <DialogTitle className={classes.dialogTitleBackground}>
                    <p className={classes.dialogTitle}>{t("Thông báo")}</p>
                </DialogTitle>
                <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center', position: 'relative' }}>
                    <div className={classes.contentDialog}>
                        <p >{t("Bạn có chắc chắn muốn đồng bộ dữ liệu khuôn mặt?")}</p>
                    </div>
                    <CancelButton
                        style={{ margin: '10px 0' }}
                        onClick={() => { setSyncConfirmation(!synConfirmation) }}
                        disabled={isProcessing}
                    >
                        {t("Trở về")}
                    </CancelButton>
                    <CreateButton
                        style={{ margin: '10px 0 10px 24px' }}
                        onClick={onSyncConfirmationClick}
                        disabled={isProcessing}
                    >
                        {t("Đồng ý")}
                    </CreateButton>
                    {isProcessing && <CircularProgress color="primary" style={{ position: 'absolute', left: '45%', top: '10%', color: '#0b59c8' }} />}
                </DialogContent>
            </Dialog>
        )
    }

    const deleteConfirmationDialog = () => {
        return (
            <Dialog
                open={isDelete}
                maxWidth="xl"
            >
                <DialogTitle className={classes.dialogTitleBackground}>
                    <p className={classes.dialogTitle}>{t("Thông báo")}</p>
                </DialogTitle>
                <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center', position: 'relative' }}>
                    <div className={classes.contentDialog}>
                        <p >{t("Bạn có chắc chắn muốn xóa máy ảnh?")}</p>
                    </div>
                    <CancelButton
                        style={{ margin: '10px 0' }}
                        onClick={() => { setIsDelete(!isDelete) }}
                        disabled={isProcessing}
                    >
                        {t("Trở về")}
                    </CancelButton>
                    <CreateButton
                        style={{ margin: '10px 0 10px 24px' }}
                        onClick={onDelComfirmationClick}
                        disabled={isProcessing}
                    >
                        {t("Đồng ý")}
                    </CreateButton>
                    {isProcessing && <CircularProgress color="primary" style={{ position: 'absolute', left: '45%', top: '10%', color: '#0b59c8' }} />}
                </DialogContent>
            </Dialog>
        )
    }

    const EmptyRowsView = () => {
        return (
            <div
                style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <h3 style={{ color: '#6D6D6D', fontSize: '16px' }}>
                    {t('Không tìm thấy máy ảnh')}
                </h3>
            </div>
        );
    };

    const customLoading = () => {
        return (
            <GridOverlay>
                <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                    <LinearProgress />
                </div>
            </GridOverlay>
        )
    }

    return (
        <div className={clsx(classes.focus, classes.cameraPage)}>
            {syncConfirmDialog()}
            {deleteConfirmationDialog()}
            <SuccessAlert
                message={alert.success}
                onCloseDialog={onAlertClose}
            />
            <ErrorAlert
                message={alert.error}
                onCloseDialog={onAlertClose}
            />
            <p className={classes.title}>
                {t('Cài đặt máy ảnh')}
            </p>
            <div className={clsx(classes.tableSection, classes.root)}>
                <div className={classes.tableHeader}>
                    <CreateButton
                        variant="contained"
                        startIcon={<AddCircleOutlineIcon />}
                        className={classes.addButtonStyle}
                        component={Link}
                        to='/admin/smart-camera/settings/add-camera'
                    >
                        {t("Thêm")}
                    </CreateButton>
                    <DeleteButton
                        variant='outlined'
                        startIcon={<DeleteOutlineOutlinedIcon />}
                        disabled={selectedIds.length === 0}
                        onClick={() => setIsDelete(true)}
                    >
                        {t('Xóa')}
                    </DeleteButton>
                    <CreateButton
                        variant="contained"
                        className={classes.syncStyle}
                        onClick={() => setSyncConfirmation(true)}
                    >
                        {t("Đồng bộ")}
                    </CreateButton>
                </div>
                <div className={classes.tableItem} >
                    <DataGrid
                        componentsProps={{
                            pagination: {
                                labelRowsPerPage: t("Số dòng mỗi trang"),
                                labelDisplayedRows: ({ from, to, count }) => { return `${from}-${to} ${t('trong số')} ${count}` }
                            }
                        }}
                        components={{
                            NoRowsOverlay: EmptyRowsView,
                            LoadingOverlay: customLoading,
                        }}
                        localeText={{
                            footerRowSelected: (count) => count + t(" hàng trong bảng đã được chọn"),
                            columnHeaderSortIconLabel: t('Sắp xếp')
                        }}
                        rows={cameras}
                        columns={columns}
                        pageSize={rowsPerPage}
                        rowHeight={45}
                        checkboxSelection
                        rowsPerPageOptions={[10, 50, 100]}
                        onPageSizeChange={pageSize => {
                            setRowsPerPage(pageSize);
                            localStorage.setItem('rowsPerPage', pageSize);
                        }}
                        onSelectionModelChange={onSelectedChange}
                        hideFooterRowCount
                        disableSelectionOnClick
                        disableColumnMenu={true}
                        loading={!loading}
                    />
                </div>
            </div>
            <div style={{ height: '30px' }}></div>
        </div>
    )
}


export default CameraTable;


