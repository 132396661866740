import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';

const useStyles = makeStyles(theme => ({
    filterBlock: {
        borderTop: '1px solid #E0E0E0',
        display: 'flex'
    },
    filterTitle: {
        margin: '16px 16px 16px 0',
        fontWeight: '700',
        fontSize: '20px',
        lineHeight: '28px'
    },
    filterValBlock: {
        display: 'flex',
        background: '#EBEBEB',
        margin: '16px 0',
        borderRadius: '50px',
        padding: '0 12px',
        marginRight: '12px'
    },
    filterVal: {
        margin: '7px 0',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '16px'
    },
    filterKey: {
        margin: '7px 0',
        color: '#616161',
        fontSize: '14px',
        lineHeight: '16px'
    },
    closeIconStyle: {
        marginTop: '7px',
        fontSize: '16px!important',
        marginLeft: '8px',
        cursor: 'pointer'
    }
}));

export const ShowFilterValues = ({
    filterValues, onRemoveItemFilterClick
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const showFilterValues = (key, value) => {
        var text = '';
        switch (key) {
            case 'expiredDate':
                text = moment(value).format('DD/MM/YYYY');
                break;
            case 'requestDate':
                text = moment(value).format('DD/MM/YYYY');
                break;
            case 'paymentDate':
                text = moment(value).format('DD/MM/YYYY');
                break;
            case 'status':
                if (value === 'unpaid') {
                    text = t('Chưa thanh toán');
                } else if (value === 'paid') {
                    text = t('Đã thanh toán')
                } else {
                    text = t('Quá hạn')
                }
                break;
            case 'registration_type':
                if (value === 'new') {
                    text = t('Đăng ký mới');
                } else if (value === 'faceid') {
                    text = t('Cập nhật Face ID');
                } else {
                    text = t('Cập nhật thông tin');
                }
                break;
            case 'register_time':
                text = moment(value).format('DD/MM/YYYY');
                break;
            default: text = value;
                break;
        }
        return text;
    }

    const showFilterKey = (key) => {
        var text = '';
        switch (key) {
            case 'apartment': text = t('Căn hộ');
                break;
            case 'expiredDate': text = t('Ngày đến hạn');
                break;
            case 'requestDate': text = t('Ngày tạo');
                break;
            case 'paymentDate': text = t('Ngày thanh toán');
                break;
            case 'status': text = t('Trạng thái');
                break;
            case 'requestInfo': text = t('Tên hóa đơn');
                break;
            case 'register_time': text = t('Thời gian đăng ký');
                break;
            case 'registration_type': text = t('Loại yêu cầu');
                break;
            case 'block': text = 'Block';
                break;
            case 'floor': text = t('Tầng');
                break;
            case 'room': text = t('Phòng');
                break;
            default: text = key;
                break;
        }
        return text;
    }

    const getFilterValues = () => {
        var keys = Object.keys(filterValues);
        var values = Object.values(filterValues);
        return values.map((item, key) => {
            if (item !== '' && item !== null) {
                return (
                    <div key={key} className={classes.filterValBlock}>
                        <p className={classes.filterKey}>{showFilterKey(keys[key]) + ':  '}</p>
                        <p className={classes.filterVal}>{showFilterValues(keys[key], values[key])}</p>
                        <CloseIcon fontSize="small" onClick={() => onRemoveItemFilterClick(keys[key])} className={classes.closeIconStyle} />
                    </div>
                )
            }
        })
    }

    return (
        <div className={classes.filterBlock}>
            <div className={classes.filterTitle}>{t('Lọc bởi')}</div>
            {getFilterValues()}
        </div>
    )
}