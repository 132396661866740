import React, { useEffect, useState, useRef } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import axiosClient from '../../../config/AxiosClient';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton/IconButton';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import { GridOverlay, DataGrid } from '@material-ui/data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import vi from 'date-fns/locale/vi';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment-timezone';
import LargeTooltip from '../../largeTooltip';

const useStyles = makeStyles(theme => ({
    fontWeight400: {
        fontWeight: '400'
    },
    fontWeight600: {
        fontWeight: '600'
    },
    font: {
        fontSize: '16px',
        lineHeight: '22.4px',
        color: '#6D6D6D'
    },
    status: {
        color: '#6D6D6D',
        paddingBottom: '0px'
    },
    statusActive: {
        color: '#2AB930',
        paddingBottom: '0px'
    },
    statusDeActived: {
        color: '#e01010',
        paddingBottom: '0px'
    },
    primaryTitle: theme.primary_title,
    createBtn: theme.create_button,
    dialogTitleBackground: theme.primaryColor,
    cancelBtn: theme.cancel_button,
    topBlock: {
        marginTop: '22px',
        marginBottom: '20px',
        height: '40px',
        width: '100%'
    },
    mobileTopBlock: {
        marginTop: '22px',
        marginBottom: '20px',
        width: '100%',
        height: '100px'
    },
    selectTitle: {
        fontSize: '18px',
        lineHeight: '22.4px',
        float: 'left',
        marginTop: '29px',
        padding: '0 16px'
    },
    dialogTitle: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '25px',
        color: '#FFFFFF',
    },
    contentDialog: {
        fontSize: '16px!important',
        height: '68px',
        borderBottom: '1px solid #EEEDF2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    header: {
        width: '100%',
        height: '56px',
        background: '#FFFFFF',
        borderBottom: '1px solid #E0E0E0',
        position: 'relative',
        borderRadius: '6px 6px 0 0'
    },
    table: {
        width: '100%',
        height: '590px',
        background: '#FFFFFF',
        paddingBottom: '55px',
        borderRadius: '6px',
        position: 'relative'
    },
    tableContent: {
        paddingBottom: '50px',
        width: '100%',
        minWidth: '980px',
        height: '690px',
        background: '#FFFFFF',
        borderRadius: '6px',
        position: 'relative'
    },
    root: theme.dataGridStyle,
    columnHeader: {
        '& .MuiDataGrid-root .MuiDataGrid-columnsContainer': {
            flexDirection: 'row'
        }
    },
    requestError: {
        width: '100%',
        height: '65vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20px',
        color: '#6D6D6D'
    },
    selectInput: {
        '& .MuiInput-underline:before': { display: 'none' },
        '& .MuiInput-underline:after': { display: 'none' }
    },
    select: {
        '& .MuiOutlinedInput-input': {
            padding: '8px 12px'
        }
    },
    action: {
        '& .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded': {
            boxShadow: '0px 5px 5px -3px '
        }
    },
    inputRightBlock: {
        height: '80px',
        width: '19%',
        position: 'relative',
        float: 'left',
        marginLeft: '2%'
    },
    label: {
        paddingBottom: '0px',
        paddingTop: '12px',
        fontSize: '14px',
        lineHeight: '140%',
        color: '#000000',
        display: 'inline-block',
        margin: '0'
    },
    inputHeight: {
        textAlign: 'left',
        height: '80px',
        margin: '10px 0',
        position: 'relative',
        '& .MuiOutlinedInput-root': {
            height: '32px'
        }
    },
    alertStyle: {
        position: 'absolute',
        right: '6px',
        bottom: '6px',
        zIndex: '10'
    },
    focus: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: theme.themeColor,
            },
        },
    },
    primaryTextColor: theme.primaryTextColor,
    placeholder: {
        color: '#B2B2B2',
        fontSize: '16px'
    },
    secondaryTitle: theme.secondary_title,
}))

const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);
const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);

const ApprovalHistories = () => {
    const classes = useStyles();
    const [residences, setResidences] = useState({});
    const [loadPage, setLoadPage] = useState({ table: false, page: false });
    const [message, setMessage] = useState({
        success: '',
        error: '',
        request: '',
        info: '',
        admin: ''
    });
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rowsTotal, setRowsTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageTotal, setPageTotal] = useState(0);
    const [isFilter, setIsFilter] = useState(false);
    const [searchString, setSearchString] = useState('');
    const { t, i18n } = useTranslation();
    const [historyTime, setHistoryTime] = useState(null);
    const [action, setAction] = useState('');
    const [showAdmin, setShowAdmin] = useState(false);
    const [account, setAccount] = useState({});
    const [adminUsername, setAdminUsername] = useState('');
    const [isExpiredSession, setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const searchRef = useRef();
    const alertRef = useRef();

    const columns = [
        {
            headerName: t('Nội dung'),
            field: 'Resident_FullName',
            flex: 1,
            cellClassName: 'super-app-theme--cell',
            renderCell: params => showContent(params.row)
        },
        {
            headerName: t("Căn hộ"),
            field: 'Apartment',
            flex: 0.5,
            cellClassName: 'super-app-theme--cell',
            renderCell: params =>
                <LargeTooltip
                    title={<div>{showAparments(params.row, true)}</div>}
                >
                    {showAparments(params.row, false)}
                </LargeTooltip>
        },
        {
            headerName: t('Thời gian'),
            field: 'ActionTime',
            width: 180,
            cellClassName: 'super-app-theme--cell',
            renderCell: params => moment(params.row.ActionTime).format('DD-MM-YYYY HH:mm:ss')
        },

    ];

    useEffect(() => {
        if (isExpiredSession) {
            history.push('/login');
            dispatch({ type: 'IS_LOGIN_AGAIN_TRUE' });
        }
    }, [isExpiredSession]);

    const showAparments = (data, showAll) => {
        var residentRole = '';
        if (data.Apartments)
            if (showAll)
                return data.Apartments.map((value, key) => {
                    return <p key={key}>{value.block + value.floor + value.room} - {value.role === 'member' ? t('Người trong gia đình') : t('Chủ hộ')}</p>
                })
            else {
                if (data.Apartments.length > 1)
                    return <p style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}><b>{data.Apartments[0].block + data.Apartments[0].floor + data.Apartments[0].room}</b> - {data.Apartments[0].role === 'member' ? t('Người trong gia đình') : t('Chủ hộ')}...</p>
                else
                    return <p style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}><b>{data.Apartments[0].block + data.Apartments[0].floor + data.Apartments[0].room}</b> - {data.Apartments[0].role === 'member' ? t('Người trong gia đình') : t('Chủ hộ')}</p>
            }
        else
            return <p></p>;
    }

    const showContent = (data) => {
        switch (data.Action) {
            case 'approve':
                return (
                    <div>
                        <span style={{ cursor: 'pointer', textOverflow: 'ellipsis', overflow: 'hidden', textDecoration: 'underline', color: '#0b59c8' }} onClick={() => onDetailClick(data.Resident_Username)}>{data.Resident_FullName}</span>
                        {t(' đã được duyệt bởi ')}
                        <span style={{ cursor: 'pointer', textOverflow: 'ellipsis', overflow: 'hidden', textDecoration: 'underline', color: '#0b59c8' }} onClick={() => onAdminDetailClick(data.Staff_Email)}>{data.Staff_FullName} </span>
                    </div>
                )
            case 'create':
                return (
                    <div>
                        <span style={{ cursor: 'pointer', textOverflow: 'ellipsis', overflow: 'hidden', textDecoration: 'underline', color: '#0b59c8' }} onClick={() => onDetailClick(data.Resident_Username)}>{data.Resident_FullName}</span>
                        {t(' đã được tạo bởi ')}
                        <span style={{ cursor: 'pointer', textOverflow: 'ellipsis', overflow: 'hidden', textDecoration: 'underline', color: '#0b59c8' }} onClick={() => onAdminDetailClick(data.Staff_Email)}>{data.Staff_FullName} </span>
                    </div>
                )
            case 'update':
                return (
                    <div>
                        <span style={{ cursor: 'pointer', textOverflow: 'ellipsis', overflow: 'hidden', textDecoration: 'underline', color: '#0b59c8' }} onClick={() => onDetailClick(data.Resident_Username)}>{data.Resident_FullName}</span>
                        {t(' đã được cập nhật bởi ')}
                        <span style={{ cursor: 'pointer', textOverflow: 'ellipsis', overflow: 'hidden', textDecoration: 'underline', color: '#0b59c8' }} onClick={() => onAdminDetailClick(data.Staff_Email)}>{data.Staff_FullName} </span>
                    </div>
                )
            case 'reject':
                return (
                    <div>
                        {data.Resident_FullName} {t(' đã bị từ chối bởi ')} <span style={{ cursor: 'pointer', textOverflow: 'ellipsis', overflow: 'hidden', textDecoration: 'underline', color: '#0b59c8' }} onClick={() => onAdminDetailClick(data.Staff_Email)}>{data.Staff_FullName} </span>. {data.Reason ? `${t('Lý do')}: ${data.Reason}` : ''}
                    </div>
                )
            default: return '';
        }
    }

    const getDefaultValues = async () => {
        try {
            var filterData = {
                action: action,
                time: historyTime,
                search: searchString
            };
            if (action === '') delete filterData.action;
            if (searchString.trim() === '') delete filterData.search;
            if (historyTime === null) delete filterData.time;
            const end = currentPage * rowsPerPage;
            const start = end - rowsPerPage;
            const query = {
                start_index: start,
                end_index: end,
                filter: filterData
            };
            var res = await axiosClient.post('/censorship/get', process.env.REACT_APP_DEPLOY_ENV === 'aws' ?
                JSON.stringify(query) : query, {
                    headers: { 'Authorization': `Bearer ${Cookies.get('token')}` }
                });
            setRowsTotal(res.data.Count);
            if (res.data.Count > rowsPerPage && res.data.Count % rowsPerPage === 0) setPageTotal(res.data.Count / rowsPerPage);
            else if (res.data.Count > rowsPerPage && res.data.Count % rowsPerPage !== 0) setPageTotal(parseInt(res.data.Count / rowsPerPage) + 1);
            else setPageTotal(1);
            var data = [];
            res.data.Items.forEach(item => {
                data.push({ ...item, id: item.ID });
            })
            setResidences(data);
            setLoadPage({ page: true, table: true });
            setMessage({ ...message, request: '' });
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setMessage({ ...message, request: t("Không lấy được dữ liệu") });
        }
    }

    useEffect(() => {
        if (!loadPage.page || !loadPage.table) getDefaultValues();
    }, [currentPage, loadPage.page, loadPage.table])

    useEffect(() => {
        setCurrentPage(1);
        setLoadPage({ ...loadPage, table: false });
    }, [rowsPerPage, searchString])

    const getAdminUser = async () => {
        try {
            let res;
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                res = await axiosClient.post('/adminuser', { 'username': adminUsername }, {
                    headers: { 'Authorization': `Bearer ${Cookies.get('token')}` }
                });
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                res = await axiosClient.post('/adminusers/get', { username: adminUsername }, {
                    headers: { 'Authorization': `Bearer ${Cookies.get('token')}`, 'Content-Type': 'application/json' }
                });
            }
            const data = res.data.Items[0];
            setAccount(data);
            setMessage({ ...message, admin: '' });
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setMessage({ ...message, admin: t("Không lấy được dữ liệu") });
        }
        setShowAdmin(true);
    }

    useEffect(() => {
        if (adminUsername !== '') getAdminUser();
    }, [adminUsername])

    const onDetailClick = (username) => {
        history.replace(`/admin/approval-history/resident?username=${username}`);
    }

    const onAdminDetailClick = (username) => {
        setAdminUsername(username)
    }

    useEffect(() => {
        if (message.success !== '' || message.error !== '') {
            if (alertRef.current) clearTimeout(alertRef.current);
            alertRef.current = setTimeout(() => {
                onCloseDialog()
            }, 5000);
        }
    }, [message])

    const onCloseDialog = () => {
        setMessage({ ...message, success: '', error: '', info: '' });
    }

    const onSearchChange = (e) => {
        var value = e.target.value;
        if (searchRef.current) clearTimeout(searchRef.current);
        searchRef.current = setTimeout(() => {
            setSearchString(value);
        }, 500)
    }

    const onActionChange = (e) => {
        setAction(e.target.value);
    }

    const onDateFilterChange = (e) => {
        if (e === null) setHistoryTime(null);
        else {
            const date = new Date(e).getTime();
            setHistoryTime(date);
        }
    }

    const EmptyRowsView = () => {
        return (
            <div
                style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <h3 style={{ color: '#6D6D6D', fontSize: '16px' }}>
                    {t("Không tìm thấy cư dân")}
                </h3>
            </div>
        );
    };

    const customLoading = () => {
        return (
            <GridOverlay>
                <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                    <LinearProgress />
                </div>
            </GridOverlay>
        )
    }

    const showAdminUser = () => {
        return (
            <Dialog
                open={showAdmin}
                maxWidth="xl"
            >
                <DialogContent style={{ width: '448px', padding: '0' }}>
                    {message.admin === '' && <div>
                        <p className={classes.secondaryTitle} style={{ textAlign: 'center', paddingTop: '10px' }}>{t('Thông tin người duyệt')}</p>
                        <div style={{ width: '90%', margin: 'auto', height: '105px' }}>
                            <label className={classes.label}>{t('Tên người quản trị')} <span style={{ color: 'red' }}>(*)</span></label><br />
                            <TextField
                                placeholder={t('Tên người quản trị')}
                                variant="outlined"
                                style={{ width: '100%' }}
                                size="small"
                                value={account.DisplayName}
                                disabled
                            />
                        </div>
                        <div style={{ width: '90%', margin: 'auto', height: '105px' }}>
                            <label className={classes.label}>Email <span style={{ color: 'red' }}>(*)</span></label><br />
                            <TextField
                                placeholder="Email"
                                variant="outlined"
                                style={{ width: '100%' }}
                                size="small"
                                value={account.Email}
                                disabled
                            />
                        </div>
                        <div style={{ width: '90%', margin: 'auto', height: '105px' }} className={classes.select}>
                            <FormControl required variant="outlined" classes={{ root: classes.input }} style={{ width: '100%' }}>
                                <label className={classes.label} >{t('Vai trò')} <span style={{ color: 'red' }}>(*)</span></label>
                                <Select
                                    value={account.AdminRole}
                                    style={{ height: '40px', background: '#FFFFFF', width: '100%' }}
                                    disabled
                                >
                                    <MenuItem value='moderator'>{t('Người điều hành')}</MenuItem>
                                    <MenuItem value='admin'>{t('Quản trị')}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>}
                    {message.admin !== '' && <div style={{ height: '400px', width: '448px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '20px', color: '#6D6D6D' }}>
                        {message.admin}
                    </div>}
                    <div style={{ width: '80%', margin: 'auto', textAlign: 'right', marginTop: '22px', paddingBottom: '22px' }}>
                        <CancelButton
                            variant="outlined"
                            onClick={() => {
                                setShowAdmin(false);
                                setAdminUsername('');
                            }}
                        >
                            {t("Đóng")}

                        </CancelButton>
                    </div>
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <div style={{ width: '100%', minWidth: '980px', paddingBottom: '1%' }} className={classes.focus}>
            {showAdminUser()}
            <div style={{ width: '98%', height: '80%', margin: 'auto', position: 'relative' }}>
                <div className={classes.topBlock}>
                    <div>
                        <IconButton style={{ float: 'left', padding: '2px' }} component={Link} to="/admin/register-overview" >
                            <ArrowBackIosOutlinedIcon className={classes.primaryTextColor} style={{ width: '28px', height: '28px' }} />
                        </IconButton>
                        <p className={classes.primaryTitle}>{t("Lịch sử duyệt")}</p>
                    </div>
                    {loadPage.page && message.request === '' &&
                        <div>
                            <TextField
                                placeholder={t("Tìm kiếm")}
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                    style: { background: '#FFFFFF', height: '40px', width: '320px' }
                                }}
                                onChange={onSearchChange}
                            />
                        </div>}
                </div>
                {!loadPage.page && message.request === '' && <CircularProgress color="primary" style={{ marginLeft: '50%', color: '#0b59c8' }} />}
                {message.request !== '' && <div className={classes.requestError}>{message.request}</div>}
                {loadPage.page && message.request === '' &&
                    <div style={{ textAlign: 'right', marginBottom: '10px' }}>
                        {isFilter &&
                            <Paper className={clsx(classes.inputHeight)}>
                                <div className={clsx(classes.select, classes.inputRightBlock)}>
                                    <label className={classes.label} style={{ marginBottom: '0' }}>{t("Thao tác")}</label><br />
                                    <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                                        <Select
                                            value={action}
                                            displayEmpty
                                            onChange={onActionChange}
                                        >
                                            <MenuItem value='' disabled ><p className={classes.placeholder}> {t("Thao tác")} </p> </MenuItem>
                                            <MenuItem value='approve'>{t("Đã duyệt")}</MenuItem>
                                            <MenuItem value='reject'>{t('Đã từ chối')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className={classes.inputRightBlock}>
                                    <label className={classes.label} style={{ marginBottom: '0' }}>{t("Thời gian")}</label><br />
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === 'vi' ? vi : ''} >
                                        <KeyboardDatePicker
                                            inputVariant="outlined"
                                            style={{ position: 'relative', top: '-16px', width: '100%' }}
                                            size="small"
                                            format="dd/MM/yyyy"
                                            margin="normal"
                                            placeholder={t("Thời gian")}
                                            InputProps={{ readOnly: true }}
                                            value={historyTime}
                                            onChange={onDateFilterChange}
                                            helperText={isNaN(historyTime) ? t("Thông tin chưa hợp lệ") : ''}
                                            cancelLabel={t("Hủy")}
                                            okLabel={t("Đồng ý")}
                                            views={["date", "month", "year"]}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className={classes.inputRightBlock} style={{ minWidth: '210px', paddingTop: '12px' }}>
                                    <br />
                                    <CreateButton
                                        variant="contained"
                                        style={{ height: '32px' }}
                                        onClick={() => setLoadPage({ ...loadPage, table: false })}

                                    >
                                        {t("Áp dụng")}
                                    </CreateButton>
                                    <CancelButton
                                        variant="outlined"
                                        style={{ height: '32px', marginLeft: '15px' }}
                                        onClick={() => {
                                            setAction('');
                                            setHistoryTime(null);
                                        }}
                                    >
                                        {t("filter.Xóa")}
                                    </CancelButton>
                                </div>
                            </Paper>}
                    </div>}
                {loadPage.page && message.request === '' &&
                    <div className={clsx(classes.tableContent, classes.root, classes.columnHeader)}>
                        <div className={classes.header} >
                            <CancelButton
                                style={{ float: 'right', margin: '7px' }}
                                variant="outlined"
                                startIcon={<FilterListIcon />}
                                onClick={() => setIsFilter(!isFilter)}
                            >
                                {t("Bộ lọc")}
                            </CancelButton>
                        </div>
                        <DataGrid
                            localeText={{
                                footerRowSelected: (count) => count + t(" hàng trong bảng đã được chọn"),
                                columnHeaderSortIconLabel: t('Sắp xếp')
                            }}
                            columns={columns}
                            rows={residences}
                            pageSize={rowsPerPage}
                            rowsPerPageOptions={[10, 50, 100]}
                            onPageSizeChange={(pageSize) => setRowsPerPage(pageSize)}
                            hideFooterRowCount
                            components={{
                                NoRowsOverlay: EmptyRowsView,
                                LoadingOverlay: customLoading,
                            }}
                            disableSelectionOnClick
                            disableColumnMenu={true}
                            hideFooterPagination
                            loading={!loadPage.table}
                            disableColumnReorder={true}
                        />
                        <div style={{ position: 'absolute', bottom: '3px', right: '5px', display: 'flex' }} className={classes.selectInput}>
                            <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0 20px' }} >{t("Số dòng mỗi trang")}</p>
                            <Select
                                value={rowsPerPage}
                                onChange={(e) => { setRowsPerPage(e.target.value) }}
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                            <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0 20px' }} >
                                {rowsTotal === 0 ? 0 : (currentPage * rowsPerPage) - rowsPerPage + 1}-{currentPage * rowsPerPage > rowsTotal ? rowsTotal : currentPage * rowsPerPage} {t("trong số")} {rowsTotal}
                            </p>
                            <IconButton
                                disabled={currentPage === 1}
                                onClick={() => {
                                    setCurrentPage(currentPage - 1);
                                    setLoadPage({ ...loadPage, table: false });
                                }}
                            >
                                <KeyboardArrowLeftIcon />
                            </IconButton>
                            <IconButton
                                disabled={currentPage === pageTotal}
                                onClick={() => {
                                    setCurrentPage(currentPage + 1);
                                    setLoadPage({ ...loadPage, table: false });
                                }}
                            >
                                <KeyboardArrowRightIcon />
                            </IconButton>
                        </div>
                    </div>
                }
            </div>
            <div style={{ height: '20px' }}></div>
        </div>
    )
};

export default ApprovalHistories;
