import { InputAdornment, MenuItem, Select, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DataGrid } from '@material-ui/data-grid';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import makeStyles from '@material-ui/styles/makeStyles';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { apiGetapartmentdata } from '../../../api/apartment';
import { deleteStaffByListId, getListStaff } from '../../../api/staffs';
import ErrorAlert from '../../alert/ErrorAlert';
import SuccessAlert from '../../alert/SuccessAlert';
import FilterButton from '../../button/filterButton';
import AddStaff from './AddStaff';
import EditStaff from './EditStaff';

const useStyles = makeStyles((theme) => ({
    error: { color: 'red', position: 'absolute', bottom: '0', left: '20px' },
    alert: { position: 'absolute', left: '3px', top: '3px', width: '99%' },
    dialogTitle: { textAlign: 'center', fontSize: '20px', fontWeight: '600', lineHeight: '25px', color: '#FFFFFF', fontFamily: 'Source Sans Pro!important' },
    contentDialog: { fontSize: '16px', height: '68px', borderBottom: '1px solid #EEEDF2', display: 'flex', alignItems: 'center', justifyContent: 'center' },
    dialogTitleBackground: theme.primaryColor,
    errorColor: theme.errorColor,
    root: theme.dataGridStyle,
    overlay: { position: 'fixed', width: '100%', height: '100%', top: '0', left: '0', background: 'rgba(0,0,0,0.1)', zIndex: '1300' },
    requestError: { width: '100%', height: '65vh', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '20px', color: '#6D6D6D' },
    font: { fontSize: '16px', lineHeight: '22.4px', color: '#6D6D6D' },
    status: { color: '#6D6D6D', paddingBottom: '0px' },
    statusActive: { color: '#2AB930', paddingBottom: '0px' },
    statusDeActived: { color: '#e01010', paddingBottom: '0px' },
    tableTitle: {
        position: 'relative',
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingRight: "10px",
        height: '56px',
        border: '1px solid rgba(224, 224, 224, 1)',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px'
    },
    wrapTitle: {
        display: "flex",
        alignItems: "center",
        padding: '23px 0 1rem 0'
    },
    wrapSearch: {
        marginLeft: "1rem"
    },
    containFilter: {
        position: "fixed",
        top: "0",
        left: "0",
        zIndex: '1201',

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        background: "rgb(0, 0, 0, 0.6)"
    },
    filter: {
        borderRadius: "0.5rem",
        padding: "1rem",
        width: "400px",
        background: "white"
    },
    filterTitleWrap: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "1rem"
    },
    filterTitle: {
        fontSize: "20px",
        fontWeight: "700",
    },
    filterClose: {
        cursor: "pointer"
    },
    textField: {
        marginBottom: "1rem",
        width: "100%",
        height: "40px",
    },
    filterBtn: {
        display: "flex",
        justifyContent: "flex-end"
    },
    placeholder: {
        color: "rgb(0, 0, 0, 0.4)"
    },
    filterShow: {
        display: "flex",
        alignItems: "center",
        borderTop: "1px solid rgb(0, 0, 0, 0.1)",
        padding: "1rem 0"
    },
    filterShowTitle: {
        fontSize: "20px",
        fontWeight: "700"
    },
    filterShowValue: {
        fontWeight: "700"
    },
    filterShowItem: {
        borderRadius: "16px",
        marginLeft: "1rem",
        padding: "2px 8px",
        background: "#EBEBEB",
    },
    filterShowDelete: {
        cursor: "pointer"
    }
}))

const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);
const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);
const ErrorButton = withStyles(theme => ({ root: theme.error_button }))(Button);
const DeleteButton = withStyles(theme => ({ root: theme.delete_button }))(Button);
const TextButton = withStyles(theme => ({ root: theme.moreDetail }))(Button);

const Staff = props => {
    const [state, setState] = useState({
        listBlock: [],
        showFilter: {
            username: "",
            role: "",
            block: "",
            status: ""
        },
        page: 0,
        rowCount: 0,
        offset: 0,
        pageSize: 10,
        isFilter: false,
        isShowFilter: true
    });
    const [filterForm, setFilterForm] = useState({
        username: "",
        role: "",
        block: "",
        status: ""
    })

    const [receptionists, setReceptionists] = useState([]);
    const [blocks, setBlocks] = useState([]);
    const [floors, setFloors] = useState([]);
    const [receptionist, setReceptionist] = useState({ name: '', username: '', phone: '', password: '', block: '' });
    const [alert, setAlert] = useState({ name: '', username: '', password: '', phone: '', block: '', success: '', error: '', request: '', dialogMessage: '' });
    const [ids, setIds] = useState([]);
    const [files, setFiles] = useState([]);
    const [id, setId] = useState('');
    const [isAdd, setIsAdd] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [isZoomFaceID, setIsZoomFaceID] = useState(false);
    const [selectedFaceID, setSelectedFaceID] = useState('');
    const [isExpiredSession, setIsExpiredSession] = useState(false);

    const alerRef = useRef();
    const pageSizeRef = useRef(10);
    const filterRef = useRef({
        username: "",
        role: "",
        block: "",
        status: ""
    })

    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const classes = useStyles();

    const columns = [
        {
            field: 'FaceId',
            headerName: t('Face ID'),
            cellClassName: 'super-app-theme--cell',
            width: 100,
            sortable: false,
            renderCell: params => showFaceID(params.row.FaceId)
        },
        {
            field: 'FullName',
            headerName: t('Họ và tên'),
            cellClassName: 'super-app-theme--cell',
            flex: 1
        },
        {
            field: 'Username',
            headerName: t('Tên người dùng'),
            cellClassName: 'super-app-theme--cell',
            flex: 1
        },
        {
            field: 'AdminRole',
            headerName: t('Vai trò'),
            cellClassName: 'super-app-theme--cell',
            renderCell: params => t(`${params.row.StaffRole}`),
            flex: 1
        },
        {
            field: 'BlockNumber',
            headerName: t("Tòa"),
            cellClassName: 'super-app-theme--cell',
            width: 120,
        },
        {
            field: 'AdminStatus',
            headerName: t('Trạng thái'),
            flex: 1,
            cellClassName: 'super-app-theme--cell',
            sortComparator: (v1, v2, params1, params2) => sortStatus(v1, v2),
            renderCell: params => showStatus(params.row.StaffStatus),

        },
        {
            field: 'Method',
            headerName: t('Thao tác'),
            width: 130,
            sortable: false,
            renderCell: params => <TextButton onClick={() => onEditClick(params.row.id)} >{t('Xem chi tiết')}</TextButton>
        },
    ]

    const showFaceID = (faceid) => {
        switch (faceid) {
            case '':
                return (
                    <img
                        src={window.location.origin + '/images/User.svg'}
                        style={{ objectFit: 'cover', borderRadius: '20px' }}
                        alt="" width='36px' height='36px'
                    />
                )
            case undefined:
                return (
                    <img
                        src={window.location.origin + '/images/User.svg'}
                        style={{ objectFit: 'cover', borderRadius: '20px' }}
                        alt="" width='36px' height='36px'
                    />
                )
            default:
                return (
                    <img
                        src={faceid}
                        style={{ objectFit: 'cover', cursor: 'pointer', borderRadius: '20px' }}
                        alt="" width='40px' height='40px'
                        onClick={() => {
                            setIsZoomFaceID(true);
                            setSelectedFaceID(faceid);
                        }}
                    />
                )
        }
    }
    useEffect(() => {
        if (isExpiredSession) {
            history.push('/login');
            dispatch({ type: 'IS_LOGIN_AGAIN_TRUE' });
        }
    }, [isExpiredSession]);

    const sortStatus = (v1, v2) => {
        if (v1 > v2) return -1;
        return v1 < v2 ? 1 : 0;
    }
    const showStatus = (status) => {
        switch (status) {
            case 'inactive':
                return (<div className={clsx(classes.status, classes.font)}>
                    <FiberManualRecordIcon style={{ position: 'relative', top: '0px' }} />
                    {t('Chưa đổi mật khẩu')}
                </div>)
            case 'active':
                return (<div className={clsx(classes.statusActive, classes.font)}>
                    <FiberManualRecordIcon style={{ position: 'relative', top: '0px', color: '#2AB930' }} />
                    {t('Đã đổi mật khẩu')}
                </div>)
            case 'deactived':
                return (<div className={clsx(classes.statusDeActived, classes.font)}>
                    <FiberManualRecordIcon style={{ position: 'relative', top: '0px', color: '#e01010' }} />
                    {t('Đã vô hiệu')}
                </div>)
            default: return '';
        }
    }

    const getDefaultBlock = async () => {
        try {
            setLoading(true);

            const { data } = await apiGetapartmentdata();
            const listBlock = data?.Items.map(item => item.BlockID);

            setState(prev => ({
                ...prev,
                listBlock: [...new Set(listBlock)]
            }));
        } catch (error) {
            setAlert({ ...alert, error: t("Tải danh sách tòa thất bại!") });
        }

        setLoading(false);
    }

    const getDefaultValues = async (offset = 0, limit = state.pageSize) => {
        try {
            setLoading(true);
            if (offset === 0) {
                setState(prev => ({
                    ...prev,
                    page: 0
                }));
            };

            const body = {
                offset,
                limit
            };

            for (let key in filterRef.current) {
                if (filterRef.current[key]) {
                    body[key] = filterRef.current[key]
                }
            };

            const res = await getListStaff(body);
            const data = [];
            res.data.Items.forEach(item => {
                data.push({ ...item, id: item.Username });
            });
            setState(prev => ({
                ...prev,
                rowCount: res?.data?.Count
            }));
            setReceptionists(data);
            setReceptionist({ ...receptionist, name: '', username: '', phone: '', password: '', block: '' });
            setIds([]);
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({ ...alert, request: t('Không lấy được dữ liệu') });
        }
        setLoading(false);
    }

    const onClickChangeFilter = () => {
        if (!state.isFilter) {
            setFilterForm({ ...state.showFilter });
        }
        setState(prev => ({
            ...prev,
            isFilter: !prev.isFilter
        }));
    }

    const onPageChange = (newPage) => {
        if (pageSizeRef.current !== state.pageSize) {
            return;
        }

        const offset = newPage * state.pageSize;
        setState(prev => ({
            ...prev,
            page: newPage
        }))

        getDefaultValues(offset);
    }

    const onPageSizeChange = (pageSize) => {
        pageSizeRef.current = pageSize;
        setState(prev => ({
            ...prev,
            page: 0,
            pageSize
        }));

        getDefaultValues(0, pageSize);
    }

    const onTextFieldChange = (key, e) => {
        setFilterForm(prev => ({
            ...prev,
            [key]: e?.target?.value
        }));
    }
    const onClickClearFilter = () => {
        setFilterForm({
            username: "",
            role: "",
            block: "",
            status: ""
        });
    }
    const onClickApplyFilter = () => {
        setState(prev => ({
            ...prev,
            showFilter: { ...filterForm }
        }));
        filterRef.current = { ...filterForm };

        onClickChangeFilter();
        getDefaultValues();
    }

    const onClickDeleteFilterItem = (key) => {
        setState(prev => ({
            ...prev,
            showFilter: {
                ...prev.showFilter,
                [key]: ""
            }
        }));

        filterRef.current = {
            ...filterRef.current,
            [key]: ""
        }

        getDefaultValues();
    }

    useEffect(() => {
        getDefaultValues();
        getDefaultBlock();
    }, []);

    useEffect(() => {
        const checkShowFilter = Object.keys(state.showFilter).findIndex(key => {
            return state.showFilter[key];
        });
        if (checkShowFilter >= 0) {
            setState(prev => ({
                ...prev,
                isShowFilter: true
            }));
        } else {
            setState(prev => ({
                ...prev,
                isShowFilter: false
            }));
        }
    }, [state.showFilter])

    const onCloseAddClick = () => {
        setIsAdd(false);
    }
    const onEditClick = (receptionistID) => {
        setId(receptionistID);
        setIsUpdate(true);
    }
    const onCloseEditClick = () => {
        setIsUpdate(false);
        setIsAdd(false);
        getDefaultValues(state.page * state.pageSize, state.pageSize);
    }

    const onDeleteClick = () => {
        setConfirmDialog(true);
    }
    const onSelectedChange = (e) => {
        var faceIDs = [];
        e.forEach(item => {
            receptionists.forEach(recep => {
                if (item === recep?.Username) {
                    if (!recep?.ResidentID) {
                        faceIDs.push({
                            username: item,
                            faceid: recep?.FaceId
                        });
                    } else {
                        faceIDs.push({
                            username: item
                        });
                    }
                }
            })
        })
        setFiles(faceIDs);
        setIds(e);
    }
    const onConfirmDeleteClick = async () => {
        try {
            setIsDeleting(true);
            const data = { usernames: files };
            await deleteStaffByListId(data);
            setAlert({ ...alert, error: '', success: `${t('Xóa nhân viên thành công')}` });
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({ ...alert, error: t('Xoá không thành công, vui lòng thử lại!'), success: '' });
            console.log(error);
        }
        setConfirmDialog(false);
        setIsDeleting(false);
        getDefaultValues();
    }

    const onUpdateAlert = (success, error = "") => {
        setIsAdd(false);
        setAlert({ ...alert, success, error });
        getDefaultValues();
    }
    const onCloseDialog = () => {
        setAlert({ ...alert, success: '', error: '' });
    }

    const onAgreeClick = () => {
        setIsFailed(false);
    }

    const FailedDialog = () => {
        return (
            <Dialog
                open={isFailed}
                maxWidth="xl"
            >
                <DialogTitle className={classes.errorColor}>
                    <p className={classes.dialogTitle}>{t('Thông báo')}</p>
                </DialogTitle>
                <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center' }}>
                    <p className={classes.contentDialog}>{alert.dialogMessage}</p>
                    <ErrorButton
                        style={{ margin: '10px 0' }}
                        onClick={onAgreeClick}
                    >
                        {t('Đồng ý')}
                    </ErrorButton>
                </DialogContent>
            </Dialog>
        )
    }

    const dialogDelete = () => {
        return (
            <Dialog
                open={confirmDialog}
                maxWidth="xl"
            >
                <DialogTitle className={classes.dialogTitleBackground}>
                    <p className={classes.dialogTitle}>{t('Thông báo')}</p>
                </DialogTitle>
                <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center', position: 'relative' }}>
                    <p className={classes.contentDialog}>{t('Bạn có chắc muốn xóa nhân viên?')}</p>
                    <CancelButton
                        style={{ margin: '10px 0' }}
                        onClick={() => { setConfirmDialog(!confirmDialog) }}
                        disabled={isDeleting}
                    >
                        {t('Trở về')}
                    </CancelButton>
                    <CreateButton
                        style={{ margin: '10px 0 10px 24px' }}
                        onClick={onConfirmDeleteClick}
                        disabled={isDeleting}
                    >
                        {t('Đồng ý')}
                    </CreateButton>
                    {isDeleting && <CircularProgress style={{ position: 'absolute', top: '10%', left: '45%', color: '#0b59c8' }} />}
                </DialogContent>
            </Dialog>
        )
    }
    const EmptyRowsView = () => {
        return (
            <div
                style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <h3 style={{ color: '#6D6D6D', fontSize: '16px' }}>
                    {t('Không tìm thấy nhân viên')}
                </h3>
            </div>
        );
    };
    return (
        <div style={{ position: 'relative', width: '100%', minWidth: "1080px", margin: 'auto', padding: "0 1rem", height: '100%' }}>
            {isAdding && <div className={classes.overlay}></div>}
            {FailedDialog()}
            {dialogDelete()}
            {
                (!isUpdate && !isAdd) && <div className={classes.wrapTitle}>
                    <p style={{ fontSize: '22px', fontWeight: 'bold' }}>
                        {t('Danh sách nhân viên')}
                    </p>
                    <div className={classes.wrapSearch}>
                        <TextField
                            placeholder={t("Tìm kiếm")}
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchOutlinedIcon />
                                    </InputAdornment>
                                ),
                                style: { background: '#FFFFFF', height: '40px', width: '320px' }
                            }}
                        // value={searchString}
                        // onChange={onSearchChange}
                        />
                    </div>
                </div>
            }
            {alert.request !== '' && <div className={classes.requestError}>{alert.request}</div>}
            {loading && alert.request === '' && <div style={{ width: '100%', height: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress style={{ color: '#0b59c8' }} /></div>}
            {!loading && !isUpdate && !isAdd && alert.request === '' && <div style={{ marginTop: '10px', width: '100%', clear: 'both', height: '80%' }}>
                {
                    state.isShowFilter && (
                        <div className={classes.filterShow}>
                            <div className={classes.filterShowTitle}>{t("Lọc bởi")}</div>
                            {
                                Object.keys(state.showFilter).map(key => state.showFilter[key] && (
                                    <div className={classes.filterShowItem}>
                                        <span>{t(key)}: </span>
                                        <span className={classes.filterShowValue}>
                                            {t(state.showFilter[key])}
                                        </span>
                                        <span
                                            className={classes.filterShowDelete}
                                            onClick={() => onClickDeleteFilterItem(key)}
                                        >
                                            <ClearOutlinedIcon fontSize='small' />
                                        </span>
                                    </div>
                                ))
                            }
                        </div>
                    )
                }

                <div className={classes.tableTitle}>
                    <div>
                        <CreateButton
                            variant="contained"
                            style={{ float: 'left', margin: '7px 0 0 10px' }}
                            startIcon={<AddCircleOutlineIcon />}
                            onClick={() => setIsAdd(true)}
                        >
                            {t("Thêm")}
                        </CreateButton>
                        <DeleteButton variant="outlined" style={{ margin: '8px' }} startIcon={<DeleteOutlineOutlinedIcon />} disabled={ids.length === 0} onClick={onDeleteClick} >
                            {t('Xóa')}
                        </DeleteButton>
                    </div>
                    <FilterButton onClick={onClickChangeFilter}>
                        {t("Bộ lọc")}
                    </FilterButton>
                    <SuccessAlert message={alert.success} onCloseDialog={onCloseDialog} />
                    <ErrorAlert message={alert.error} onCloseDialog={onCloseDialog} />
                </div>
                <div style={{ height: '90%', width: '100%', paddingBottom: '15px' }} className={classes.root}>
                    <DataGrid
                        componentsProps={{
                            pagination: {
                                labelRowsPerPage: t("Số dòng mỗi trang"),
                                labelDisplayedRows: ({ from, to, count }) => { return `${from}-${to} ${t('trong số')} ${count}` }
                            }
                        }}
                        components={{
                            NoRowsOverlay: EmptyRowsView
                        }}
                        localeText={{
                            footerRowSelected: (count) => count + t(" hàng trong bảng đã được chọn")
                        }}
                        paginationMode="server"
                        rowCount={state.rowCount}
                        rows={receptionists}
                        columns={columns}
                        page={state.page}
                        pageSize={state.pageSize}
                        rowHeight="45"
                        checkboxSelection
                        rowsPerPageOptions={[10, 50, 100]}
                        onPageSizeChange={pageSize => onPageSizeChange(pageSize)}
                        onPageChange={(newPage) => onPageChange(newPage)}
                        onSelectionModelChange={onSelectedChange}
                        disableSelectionOnClick
                        disableColumnMenu={true}
                        scrollbarSize={20}
                    />
                </div>
            </div>}
            {
                isAdd && (
                    <AddStaff
                        onUpdateAlert={onUpdateAlert}
                        onCloseAddClick={onCloseAddClick}
                    />
                )
            }
            {isUpdate && (
                <EditStaff
                    id={id}
                    onCloseEditClick={() => onCloseEditClick()}
                />
            )}
            {
                state.isFilter && (
                    <div className={classes.containFilter}>
                        <div className={classes.filter}>
                            <div className={classes.filterTitleWrap}>
                                <p className={classes.filterTitle}>
                                    {t('Danh sách nhân viên')}
                                </p>
                                <div
                                    className={classes.filterClose}
                                    onClick={onClickChangeFilter}
                                >
                                    <ClearOutlinedIcon />
                                </div>
                            </div>

                            <label htmlFor='' >{t("Tên người dùng")}</label>
                            <br />
                            <TextField
                                placeholder={t("Tên người dùng")}
                                variant="outlined"
                                size='small'
                                className={classes.textField}
                                value={filterForm.username}
                                onChange={(e) => onTextFieldChange("username", e)}
                            />

                            <label htmlFor='' >{t("Vai trò")}</label>
                            <br />
                            <Select
                                displayEmpty
                                variant="outlined"
                                size='small'
                                className={classes.textField}
                                value={filterForm.role}
                                onChange={(e) => onTextFieldChange("role", e)}
                            >
                                <MenuItem value="">
                                    <span className={classes.placeholder}>
                                        {t("Vai trò")}
                                    </span>
                                </MenuItem>
                                <MenuItem value="Staff">{t("Nhân viên")}</MenuItem>
                                <MenuItem value="Guard">{t("Bảo vệ")}</MenuItem>
                                <MenuItem value="Management staff">{t("Nhân viên quản lý")}</MenuItem>
                            </Select>

                            <label htmlFor='' >{t("Tòa")}</label>
                            <br />
                            <Select
                                displayEmpty
                                variant="outlined"
                                size='small'
                                className={classes.textField}
                                value={filterForm.block}
                                onChange={(e) => onTextFieldChange("block", e)}
                            >
                                <MenuItem value="">
                                    <span className={classes.placeholder}>
                                        {t("Tòa")}
                                    </span>
                                </MenuItem>
                                {
                                    state.listBlock.map(item => (
                                        <MenuItem key={item} value={item}>{item}</MenuItem>
                                    ))
                                }
                            </Select>

                            <label htmlFor='' >{t("Trạng thái")}</label>
                            <br />
                            <Select
                                displayEmpty
                                variant="outlined"
                                size='small'
                                className={classes.textField}
                                value={filterForm.status}
                                onChange={(e) => onTextFieldChange("status", e)}
                            >
                                <MenuItem value="">
                                    <span className={classes.placeholder}>
                                        {t("Trạng thái")}
                                    </span>
                                </MenuItem>
                                <MenuItem value="inactive">{t("Chưa đổi mật khẩu")}</MenuItem>
                                <MenuItem value="active">{t("Đã đổi mật khẩu")}</MenuItem>
                                <MenuItem value="deactived">{t("Đã vô hiệu")}</MenuItem>
                            </Select>

                            <div className={classes.filterBtn}>
                                <CancelButton onClick={onClickClearFilter}>
                                    {t("Xóa bộ lọc")}
                                </CancelButton>
                                <CreateButton onClick={onClickApplyFilter}>
                                    {t("Áp dụng")}
                                </CreateButton>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default Staff;