import AxiosClient from "../config/AxiosClient";

export const apiDeviceGetAll = async () => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/device/getall';
    } else {
        url = '/devices/get';
    }
    return await AxiosClient.get(url);
}

export const apiDeviceGet = async (requestBody) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/device/get';
    }
    else {
        url = '/devices/get';
    }
    return await AxiosClient.post(url, requestBody);
}

export const apiDeviceAdd = async (requestBody) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/device/add';
    }
    else {
        url = '/devices/create';
    }
    return await AxiosClient.post(url, requestBody);
}

export const apiDeviceDelete = async (requestBody) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/device/delete';
    }
    else {
        url = '/devices/delete';
    }
    return await AxiosClient.post(url, requestBody);
}

export const apiDeviceUpdate = async (requestBody) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/device/update';
    }
    else {
        url = '/devices/update';
    }
    return await AxiosClient.post(url, requestBody);
}

export const apiDevicePing = async (requestBody) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/device/ping';
    }
    else {
        url = '/devices/ping';
    }
    return await AxiosClient.post(url, requestBody);
}

export const apiDeviceLogs = async (requestBody) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/device/logs';
    }
    else {
        url = '/devices/logs';
    }
    return await AxiosClient.post(url, requestBody);
}

export const apiDeviceLogList = async (requestBody) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/device/log/list';
    }
    else {
        url = '/devices/log/list';
    }
    return await AxiosClient.post(url, requestBody);
}

export const apiDeviceLogDelete = async (requestBody) => {
    var url = '/device/log/delete';
    return await AxiosClient.post(url, requestBody);

}