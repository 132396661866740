import React,{useState,useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import CssBaseline from '@material-ui/core/CssBaseline';
import {useHistory} from 'react-router-dom';
import MenuUI from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive'
import withStyles from '@material-ui/styles/withStyles/withStyles';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Cookies from 'js-cookie';
import { getConfigurations } from '../../api/residentRegistrations';
import LargeTooltip from '../largeTooltip';

const useStyles = makeStyles((theme)=>({
    appBar:{zIndex:theme.zIndex.drawer +1,background:'#FFFFFF',height:'72px'},
    color:{color:'#009ADA'},
    colorIcon:{color:'#072A3B'},
    background:{background:theme.menuBackground},
    textMenu:theme.textMenu,
    whiteColor:{color:'#FFFFFF',marginLeft:'5px'},
    active:{
        background: 'rgba(40, 156, 216, 0.4)!important',
        borderLeft:'6px solid #289CD8',
        '&:hover':{background:'rgba(40, 156, 216, 0.4)'}
    },
    drawerOpen:{
        overflow:'hidden',
        width:'280px',
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        // overflowY:'scroll'
    },
    drawerClose:{
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflow: "hidden",
        width: '70px',
        // overflowY:'scroll'
    },
    mobileDrawerClose:{
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflow: "hidden",
        width: '0px',
        // overflowY:'scroll'
    },
    userIcon:{
        position:'absolute',
        top:'100%',
        right:'0'
    },
    notifyIcon:{
        position:'absolute',
        top:'100%',
        right:'0'
    },
    popupTitle:{fontSize:'16px',color:'#444444'},
    listItem:{height:'64px',overflow:'hidden',width:'280px'},
    dialogTitle:{textAlign:'center',fontSize:'20px',fontWeight:'600',lineHeight:'25px',color:'#FFFFFF'},
    contentDialog:{fontSize:'16px',height:'68px',borderBottom:'1px solid #EEEDF2',display:'flex',alignItems:'center',justifyContent:'center'},
    errorColor:theme.errorColor,
    primaryColor:theme.primaryColor,
    borderActive:{position:'relative',left:'-6px'},
    createButton:{margin:'10px 0',background:'#0b59c8',height:'40px',padding:'8px 16px',textTransform:'none',color:'#FFFFFF',
    '&:hover':{
        background:'#0b59c8'
        }
    },
    cancelButton:{margin:'10px',borderColor:'#0b59c8',height:'40px',padding:'8px 16px',textTransform:'none',color:'#0b59c8',
    '&:hover':{
        borderColor:'#0b59c8'
        }
    },
    button:{
        '& .MuiButton-containedPrimary':{
            textTransform:'none',
            background:'#0b59c8',
            height:'40px',
            padding:'8px 16px',
            color:'#FFFFFF',
            margin:'10px 0'
        },
        '& .MuiButton-outlinedPrimary':{
            textTransform:'none',
            borderColor:'#0b59c8',
            height:'40px',
            padding:'8px 16px',
            color:'#0b59c8',
            margin:'10px'
        }
    }
}))

const TopBar = (props) => {
    const classes = useStyles();
    const [open,setOpen] = useState(false);
    const notiState = useSelector((state) =>state.notiState);
    const syncAllState = useSelector((state) => state.syncAllState);
    const dispatch = useDispatch();
    const history = useHistory();
    const [anchorEl,setAnchorEl] = useState(null);
    const [anchorEl1,setAnchorEl1] = useState(null);
    const [isWarning,setIsWarning] = useState(false);
    const isMobile = useMediaQuery({ query: '(max-width: 414px)' })
    const { t, i18n } = useTranslation();
    const [logo,setLogo] = useState('');
    const [loading,setLoading] = useState(false);

    const getDefaultValues = async() =>{
        try {
            let res;
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') res = await getConfigurations({isReceptionist:true,username:'tsmart-building'});
            else res = await getConfigurations({username:'tsmart-building'});
            setLogo(res.data.Items[0].Logo?res.data.Items[0].Logo:`${window.origin}/images/TMAlogo.png`);
        } catch (error) {
            setLogo(`${window.origin}/images/TMAlogo.png`);
        }
        setLoading(true);
    }

    useEffect(()=>{
        if(!loading) getDefaultValues();
    },[loading])

    useEffect(()=>{
        if(isMobile) setIsWarning(true);
        else setIsWarning(false);
    },[])

    const onIconUserClick = (e) =>{
        setAnchorEl1(e.currentTarget);
    }
    const onIconLanguaChange = (e) =>{
        setAnchorEl(e.currentTarget);
    }
    const handleClose = () =>{
        setAnchorEl(null);
        setAnchorEl1(null);
    }
    const signOut = async()=> {
        Cookies.remove('receptionistToken',{path:''});
        localStorage.removeItem('receptionistName');
        localStorage.removeItem('receptionistID');
        history.push('/receptionist-login');
    }

    const onClose = ()=>{
        dispatch({
            type:'GET_SUCCESS_MESSAGE',
            payload:''
        })
        dispatch({
            type:'GET_FAILED_MESSAGE',
            payload:''
        })
    }

    const onSyncAllClose = () =>{
        dispatch({
            type:'UPDATE_SUCCESS_MESSAGE',
            payload:''
        })
        dispatch({
            type:'UPDATE_FAILED_MESSAGE',
            payload:''
        })
    }
    useEffect(()=>{
        if(notiState.successMessage !== '' || notiState.failedMessage !== ''){
            setTimeout(() => {
                onClose();
            }, 5000);
        }
    },[notiState])


    useEffect(()=>{
        if(syncAllState.successMessage !== '' || syncAllState.failedMessage !== ''){
            setTimeout(() => {
                onSyncAllClose();
            }, 5000);
        }
    },[syncAllState])
    
    const warningDialog = () =>{
        return(
            <Dialog 
              open={isWarning}
            >
              <DialogTitle className={classes.primaryColor}>
                  <p className={classes.dialogTitle}>{t('Thông báo')}</p>
              </DialogTitle>
              <DialogContent className={classes.button} style={{padding:'0 10px',textAlign:'center'}}>
                  <p className={classes.contentDialog}>{t('Website không thân thiện với điện thoại. Bạn có muốn tiếp tục?')}<br /></p>
                  <Button 
                    variant="outlined"
                    color="primary"
                    onClick={()=>{history.push('/')}} 
                  >
                    {t('Trở về')}
                  </Button>
                  <Button 
                    variant="contained"
                    color="primary"
                    onClick={()=>setIsWarning(false)} 
                  >
                    {t('Đồng ý')}
                  </Button>
              </DialogContent>
            </Dialog>
          )
    }
    return (
        <div>
            {warningDialog()}
            <CssBaseline />
            {<AppBar position="fixed" className={classes.appBar} style={{height:'72px'}}>
                <Toolbar style={{height:'72px'}}>
                    <IconButton disabled onClick={()=>setOpen(!open)} edge="start" className={clsx(classes.color,classes.menuIcon)} aria-label="menu">
                        <MenuIcon style={{fontSize:'35px',color:'#009ADA'}}/>                
                    </IconButton>
                    {loading && <img src={logo} alt="TMA logo" width="93px" height="39px" style={{cursor:'pointer'}} onClick={()=>history.replace('/')} />}
                    <Typography variant="h6" style={{flexGrow:'1'}}></Typography>
                    <div aria-label="language" onClick={onIconLanguaChange} style={{display:'flex',cursor:'pointer'}}>
                        {i18n.language==='vi' && <img src={window.location.origin +'/images/vi.png'} alt="" style={{width:'15px',height:'15px',borderRadius:'100%',position:'relative',top:'3px',right:'2px'}}/>}
                        {i18n.language==='en' && <img src={window.location.origin +'/images/en.png'} alt="" style={{width:'15px',height:'15px',borderRadius:'100%',position:'relative',top:'3px',right:'2px'}}/>}
                        <p style={{fontSize:'16px',fontWeight:'bold',color:'#000000'}}>{i18n.language==='vi'?'VI':'EN'}</p>
                    </div>
                    <MenuUI
                        id="language"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuList>
                            <MenuItem 
                                onClick={()=>{
                                   setAnchorEl(null);
                                   i18n.changeLanguage('vi'); 
                                }} 
                                className={classes.popupTitle}
                            >
                                Việt Nam
                            </MenuItem>
                            <MenuItem
                                onClick={()=>{
                                    setAnchorEl(null);
                                    i18n.changeLanguage('en');
                                }} 
                                className={classes.popupTitle}
                            >
                                English
                            </MenuItem>
                        </MenuList>
                    </MenuUI> 
                    <LargeTooltip title={t("Thông báo")} >
                        <IconButton edge="end" aria-label="notification" >
                            <NotificationsNoneOutlinedIcon className={classes.colorIcon}/>
                        </IconButton>
                    </LargeTooltip>         
                    <Button
                        startIcon={<AccountCircleOutlinedIcon />}
                        onClick={onIconUserClick}
                        style={{color:'#000000',paddingLeft:'15px',fontWeight:'bold',fontSize:'16px',textTransform:'none'}}
                    >
                        {(localStorage.getItem('receptionistName')==='undefined'|| isMobile)?'':localStorage.getItem('receptionistName')}
                    </Button>
                    <MenuUI
                        anchorEl={anchorEl1}
                        keepMounted
                        open={Boolean(anchorEl1)}
                        onClose={handleClose}
                    >
                        <MenuList>
                            <MenuItem className={classes.popupTitle} onClick={()=>history.push('/my-receptionist')} ><AccountCircleOutlinedIcon style={{marginRight:'5px'}} />{t("Tài khoản")}</MenuItem>
                            <MenuItem className={classes.popupTitle} onClick={signOut} ><ExitToAppOutlinedIcon style={{marginRight:'5px'}} />{t("Đăng xuất")}</MenuItem>
                        </MenuList>
                    </MenuUI>
                </Toolbar>
            </AppBar>}
        </div>
    )
}

export default TopBar;
