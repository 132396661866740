import makeStyles from "@material-ui/styles/makeStyles/makeStyles";

export const useStyles = makeStyles(theme => ({
    root: theme.dataGridStyle,
    wrapLoading: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "90vh"
    }, 
    singleCamera: {
        padding: "1rem 2rem 2rem 2rem",
        minWidth: "1280px"
    },
    wrapTitle: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        marginBottom: "1rem"
    },
    titleIcon: {
        color: "#0B59C8",
        paddingTop: "6px"
    },
    live: {
        width: "100%",
        height: "calc(100vh - 179px)",
        background: "#C4C4C4"
    }
}));