import React,{useState,useEffect,useRef} from 'react';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import TextField from '@material-ui/core/TextField/TextField';
import axiosClient from '../../../config/AxiosClient';
import {useTranslation} from 'react-i18next';
import { useDispatch } from 'react-redux';
import {useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import SuccessAlert from '../../alert/SuccessAlert';
import ErrorAlert from '../../alert/ErrorAlert';

const useStyles = makeStyles((theme)=>({
    topBlock:{marginTop:'22px',marginBottom:'20px',height:'60px'},
    secondaryTitle:theme.secondary_title,
    title:{fontSize:'16px',color:'#000000',width:'200px',marginLeft:'10px',float:'left'},
    overlay:{position:'fixed',width:'100%',height:'100%',top:'0',left:'0',background:'rgba(0,0,0,0.1)',zIndex:'1300',display:'flex',justifyContent:'center',alignItems:'center'},
    focus:{
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': { 
                borderColor: theme.themeColor,
            },
        },
    },
    primaryColor:theme.primaryTextColor,
    requestError:{width:'100%',height:'65vh',display:'flex',justifyContent:'center',alignItems:'center',fontSize:'20px',color:'#6D6D6D'},
}))

const ApiKey = () =>{
    const classes = useStyles();
    const [isEditing,setIsEditing] = useState(false);
    const [twilioEdit,setTwilioEdit] = useState(false);
    const [twilioShow,setTwilioShow] = useState(false);
    const [tableKeyEdit,setTableKeyEdit] = useState(false);
    const [tableKeyShow,setTableKeyShow] = useState(false);
    const [twilioKey,setTwilioKey] = useState('');
    const [tableKey,setTableKey] = useState('');
    const [alert,setAlert] = useState({
        twilio:'',
        tableKey:'',
        success:'',
        error:'',
        request:''
    })
    const [loading,setLoadig] = useState(false);
    const alertRef = useRef();
    const {t} = useTranslation();
    const [isExpiredSession,setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(()=>{
        if(isExpiredSession){
          history.push('/login');
          dispatch({type:'IS_LOGIN_AGAIN_TRUE'});
        } 
    },[isExpiredSession]);

    const getDefaultValues = async()=>{
        try {
            let res;
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                res = await axiosClient.get('/deployment/keys',{headers:{'Authorization': `Bearer ${Cookies.get('token')}`}});
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                res = await axiosClient.get('/apikeys/get',{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`,'Content-Type': 'application/json'}
                });
            }
            setTableKey(res.data.APIKey);
            setTwilioKey(res.data.TwilioKey);
            setAlert({...alert,twilio:'',tableKey:'',request:''});
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({...alert,request:t('Không lấy được dữ liệu'),twilio:'',tableKey:''});
        }
        setLoadig(true);
    }
    useEffect(()=>{
        if(!loading) getDefaultValues();
    },[loading])

    const onTwilioKeyChange = (e) =>{
        const value = e.target.value;
        setTwilioKey(value);
        if(value.trim() === ''){
            setAlert({...alert,twilio:t('Không được để trống')});
        }else{
            setAlert({...alert,twilio:''});
        }
    }

    const onTwilioKeySave = async() =>{
        try {
            if(alert.twilio !== '') return;
            setIsEditing(true);
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                await axiosClient.post('/deployment/updatekey',{'twilio_key':twilioKey},{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
                })
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                await axiosClient.post('/apikeys/update',{'twilio_key':twilioKey},{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`,'Content-Type': 'application/json'}
                })
            }
            setAlert({...alert,success:t('Thay đổi Twilio Key thành công!'),error:''});
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({...alert,error:t('Thay đổi Twilio Key không thành công!'),success:''});
        }
        twilioKeyCancel();
        setIsEditing(false);
    }
    const onTableKeySave = async() =>{
        try {
            if(alert.tableKey !== '') return;
            setIsEditing(true);
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                await axiosClient.post('/deployment/updatekey',{'api_key':tableKey},{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
                })
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                await axiosClient.post('/apikeys/update',{'api_key':tableKey},{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`,'Content-Type': 'application/json'}
                })
            }
            setAlert({...alert,success:t('Thay đổi Unified User Table Key thành công!'),error:''});
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({...alert,error:t('Thay đổi Unified User Table Key không thành công!'),success:''});
        }
        tableKeyCancel();
        setIsEditing(false);
    }

    const onTableKeyhange = (e) =>{
        const value = e.target.value;
        setTableKey(value);
        if(value.trim() === ''){
            setAlert({...alert,tableKey:t('Không được để trống')});
        }else{
            setAlert({...alert,tableKey:''});
        }
    }
    const twilioKeyCancel = () =>{
        setTwilioEdit(false);
        setTwilioShow(false);
        setLoadig(false);
    }

    const tableKeyCancel = () =>{
        setTableKeyEdit(false);
        setTableKeyShow(false);
        setLoadig(false);
    }

    const onCloseDialog = () =>{
        setAlert({...alert,success:'',error:''});
    }

    useEffect(()=>{
        if(alert.success !== '' || alert.error !== ''){
            if(alertRef.current) clearTimeout(alertRef.current);
            alertRef.current =  setTimeout(() => {
                onCloseDialog();
            }, 5000);
        }
    },[alert])
    return(
        <div style={{position:'relative'}}>
            {isEditing && <div className={classes.overlay}><CircularProgress style={{color:'#0b59c8'}}/></div>}
            <SuccessAlert message={alert.success} onCloseDialog={onCloseDialog} />
            <ErrorAlert message={alert.error} onCloseDialog={onCloseDialog} />
            <div  style={{marginBottom:'0'}}>
                <p className={classes.secondaryTitle} style={{marginLeft:'16px',paddingTop:'23px',marginTop:'0'}}>{t('Danh sách Key')}</p>
            </div>
            {alert.request !== '' && <div className={classes.requestError}>{alert.request}</div>}
            {!loading && alert.request === '' && 
            <div style={{width:'100%',height:'400px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                <CircularProgress style={{color:'#0b59c8'}}/>
            </div>}
            {loading && alert.request === '' && <div>
                <div className={clsx(classes.topBlock,classes.focus)} style={{marginBottom:'0',display:'flex',marginLeft:'6px'}}>
                    <p className={classes.title}>Twilio:</p>
                    <div style={{width:'350px'}}>
                        {!twilioEdit && !twilioShow &&<p style={{float:'left'}}>***************************************</p>}
                        {(twilioEdit || twilioShow) && <div style={{float:'left',width:'100%',position:'relative',top:'-10px'}}>
                            <TextField
                                placeholder="Twilio Key"
                                variant="outlined"
                                style={{width:'100%'}}
                                size="small"
                                disabled={!twilioEdit}
                                multiline
                                rows={2}
                                value={twilioKey}
                                onChange={onTwilioKeyChange}
                                helperText={alert.twilio}
                                error = {alert.twilio !== ''}
                            />
                        </div>      }      
                    </div>  
                    {!twilioEdit && <p className={classes.primaryColor} style={{padding:'0 20px',fontSize:'16px',cursor:'pointer',textDecoration:'underline'}} onClick={()=>setTwilioEdit(true)}>{t('Chỉnh sửa')}</p>}
                    {twilioEdit && <p className={classes.primaryColor} style={{padding:'0 20px',fontSize:'16px',cursor:'pointer',textDecoration:'underline'}} onClick={onTwilioKeySave}>{t('Lưu')}</p>}
                    {twilioEdit && <p className={classes.primaryColor} style={{fontSize:'16px',cursor:'pointer',textDecoration:'underline'}} onClick={twilioKeyCancel}>{t('Hủy')}</p>}
                    {!twilioEdit &&<p className={classes.primaryColor} style={{padding:'0 20px',fontSize:'16px',cursor:'pointer',textDecoration:'underline'}} onClick={()=>setTwilioShow(!twilioShow)}>{twilioShow?t('Ẩn'):t('Hiển thị')}</p>}
                </div>
                <div className={classes.topBlock} style={{marginBottom:'0',display:'flex',marginLeft:'6px'}}>
                    <p className={classes.title}>Unified User Table:</p>
                    <div style={{width:'350px'}}>
                        {(!tableKeyEdit && !tableKeyShow) &&<p style={{width:'100px'}}>***************************************</p>}
                        {(tableKeyEdit || tableKeyShow ) && <div style={{width:'100%',position:'relative',top:'-10px'}}>
                            <TextField
                                placeholder="Unified User Table Key"
                                variant="outlined"
                                style={{width:'100%'}}
                                size="small"
                                disabled={!tableKeyEdit}
                                multiline
                                rows={2}
                                value={tableKey}
                                onChange={onTableKeyhange}
                                helperText={alert.tableKey}
                                error = {alert.tableKey !== ''}
                            />
                        </div>      }     
                    </div>   
                    {!tableKeyEdit && <p className={classes.primaryColor} style={{padding:'0 20px',fontSize:'16px',cursor:'pointer',textDecoration:'underline'}} onClick={()=>setTableKeyEdit(true)}>{t('Chỉnh sửa')}</p>}
                    {tableKeyEdit && <p className={classes.primaryColor} style={{padding:'0 20px',fontSize:'16px',cursor:'pointer',textDecoration:'underline'}}onClick={onTableKeySave}>{t('Lưu')}</p>}
                    {tableKeyEdit && <p className={classes.primaryColor} style={{fontSize:'16px',cursor:'pointer',textDecoration:'underline'}} onClick={tableKeyCancel}>{t('Hủy')}</p>}
                    {!tableKeyEdit &&<p className={classes.primaryColor} style={{padding:'0 20px',fontSize:'16px',cursor:'pointer',textDecoration:'underline'}} onClick={()=>setTableKeyShow(!tableKeyShow)}>{tableKeyShow?t('Ẩn'):t('Hiển thị')}</p>}
                </div>
            </div>}
        </div>
    )
}

export default ApiKey;