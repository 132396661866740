import React,{useState,useEffect,useRef} from 'react';
import {useTranslation} from 'react-i18next';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import axiosClient from '../../../config/AxiosClient';
import Cookies from 'js-cookie';
import moment from 'moment-timezone';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import CircularProgress from '@material-ui/core/CircularProgress';
import SuccessAlert from '../../alert/SuccessAlert';
import ErrorAlert from '../../alert/ErrorAlert';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    dialogTitle:{textAlign:'center',fontSize:'20px',fontWeight:'600',lineHeight:'25px',color:'#FFFFFF'},
    contentDialog:{fontSize:'16px',height:'68px',borderBottom:'1px solid #EEEDF2',display:'flex',alignItems:'center',justifyContent:'center'},
    dialogTitleBackground:theme.primaryColor,
}))

const CreateButton = withStyles(theme =>({root:theme.create_button}))(Button);
const CancelButton = withStyles(theme =>({root:theme.cancel_button}))(Button);

const ExportApartmentFile = (props) =>{
    const {t} = useTranslation();
    const classes = useStyles();
    const {filter} = props;
    const [anchorEl,setAnchorEl] = useState(null);
    const [isExporting,setIsExporting] = useState(false);
    const [alert,setAlert] = useState({success:'',error:''});
    const [isConfirm,setIsConfirm] = useState(false);
    const [confirmHugeExport,setConfirmHugeExport]=useState(false);
    const alertRef = useRef();
    const handleClose =() =>{
        setAnchorEl(null)
    }

    const sortApartment = (apat1,part2) =>{
        if(apat1.Block > part2.Block) return 1;
        if(apat1.Block < part2.Block) return -1;
        if(parseInt(apat1.Floor)-parseInt(part2.Floor) > 0) return 1;
        if(parseInt(apat1.Floor)-parseInt(part2.Floor) < 0)  return -1;
        if(parseInt(apat1.Room)-parseInt(part2.Room) > 0) return 1;
        if(parseInt(apat1.Room)-parseInt(part2.Room) <0) return -1;
        return 0;
    }

    const getAllApartments = async() =>{
        setIsExporting(true);
        const query = {
            filter:{
                block:filter.block,
                floor:filter.floor
            }
        }
        var res = await axiosClient.post('/apartment/noregistration',JSON.stringify(query),{headers:{'Authorization': `Bearer ${Cookies.get('token')}`}});
        if(res.data.Count > 5000 && !confirmHugeExport) {
            setIsExporting(false);
            setConfirmHugeExport(true);
            setIsConfirm(true);
            return null;
        }
        var data = [];
        res.data.forEach(item=>{
            data.push({Block:item.BlockID,Floor:item.FloorID,Room:item.RoomID,ApartmentID:item.ApartmentID,Host:item.FullName?item.FullName:'',Phone:item.Username?item.Username:''});
        })
        const sortedData = data.sort((a,b)=> sortApartment(a,b));
        setIsExporting(false);
        return sortedData;
    }

    const exportFileCSV = async() =>{
        try {
            setAnchorEl(null);
            const apartmentData = await getAllApartments();
            if(apartmentData===null) return;
            var header=[[t('Tòa'),t('Tầng'),t('Phòng'),t('Mã căn hộ'),t('Chủ hộ'),t('Số điện thoại')]];
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.book_new();
            XLSX.utils.sheet_add_aoa(ws,header);
            XLSX.utils.sheet_add_json(ws,apartmentData,{origin:'A2',skipHeader:true});
            const wb = {Sheets: {'data':ws}, SheetNames:['data']};
            const excelBuffer = XLSX.write(wb,{bookType:'xlsx',type:'array'});
            const data = new Blob([excelBuffer],{type:fileType});
            FileSaver.saveAs(data,`Danh_sach_can_ho_${moment().format('DD_MM_YYYY_HH_mm_ss')}`+fileExtension);
            setAlert({success:t('Xuất dữ liệu thành công!'),error:''})
            setConfirmHugeExport(false);
            setIsConfirm(false);
        } catch (error) {
            setAlert({error:t('Xuất dữ liệu không thành công, vui lòng thử lại sau!'),success:''})
            setIsExporting(false);
        }
        
    }
    const onAlertClose = () =>{
        setAlert({...alert,success:'',error:''});
    }
  
    useEffect(()=>{
    if(alert.success !== '' || alert.error !== ''){
        if(alertRef.current) clearTimeout(alertRef.current);
        alertRef.current = setTimeout(() => {
        onAlertClose();
        }, 5000);
    }
    },[alert])

    const confirmExport = () =>{
        return(
          <Dialog 
            open={isConfirm}
            maxWidth="xl"
          >
            <DialogTitle className={classes.dialogTitleBackground}>
                <p className={classes.dialogTitle}>{t("Thông báo")}</p>
            </DialogTitle>
            <DialogContent style={{width:'448px',padding:'0',textAlign:'center',position:'relative'}}>
                <div className={classes.contentDialog}>
                  <p>{t("Export file hơn 5000 lịch sử, bạn có muốn tiếp tục?")}</p>
                </div>
                <CancelButton 
                    style={{margin:'10px 0'}}
                    onClick={()=>{
                        setIsConfirm(!isConfirm);
                        setConfirmHugeExport(false);
                    }}
                    disabled={isExporting}
                >
                  {t("Trở về")}
                </CancelButton>
                <CreateButton 
                  style={{margin:'10px 0 10px 24px'}}
                  onClick={()=>{
                    exportFileCSV(true);
                  }} 
                  disabled={isExporting}
                >
                  {t("Đồng ý")}
                </CreateButton>
                {isExporting && <CircularProgress color="primary" size={25} style={{position:'absolute',color:'#0b59c8',top:'10%',left:'45%'}}/>}
            </DialogContent>
          </Dialog>
          )
    }
    return (
        <div style={{float:'left'}}>
            {confirmExport()}
            <SuccessAlert message={alert.success} onCloseDialog={onAlertClose} />
            <ErrorAlert message={alert.error} onCloseDialog={onAlertClose} />
            <CreateButton
                variant="contained"
                style={{marginTop:'7px',marginLeft:'8px'}}
                startIcon={<InsertDriveFileIcon />}
                onClick={exportFileCSV}
                disabled={isExporting}
            >
                {t("Xuất dữ liệu")}
                {isExporting && !confirmHugeExport && <CircularProgress size={25} style={{position:'absolute',color:'#0b59c8'}} />}
            </CreateButton>
        </div>
    )
}

export default ExportApartmentFile;
