import React from "react";
import { useSelector } from "react-redux";
import { useStyles } from "../styles";
import clsx from "clsx";

export default function LayoutContent({ children }) {
  const isMenu = useSelector((state) => state.cabinetReducer.isMenu);
  const classes = useStyles();
  return (
    <div className={clsx(classes.content, !isMenu && classes.contentfullWidth)}>
      {children}
    </div>
  );
}
