import React,{useState, useRef, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import AddAdminForm from './AddAdminForm';
import EditAdminForm from './EditAdminForm';
import axiosClient from '../../config/AxiosClient';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DataGrid } from '@material-ui/data-grid';
import clsx from 'clsx';
import { adminStyles } from '../../styles/style';
import withStyles from '@material-ui/core/styles/withStyles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useDispatch } from 'react-redux';
import {useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SuccessAlert from '../alert/SuccessAlert';
import ErrorAlert from '../alert/ErrorAlert';

const CreateButton = withStyles(theme =>({root:theme.create_button}))(Button);
const CancelButton = withStyles(theme =>({root:theme.cancel_button}))(Button);
const DeleteButton = withStyles(theme =>({root:theme.delete_button}))(Button);
const TextButton = withStyles(theme =>({root:theme.moreDetail}))(Button);
const AdminTable = props => {
    const classes = adminStyles();
    const [isAddForm, setIsAddForm] = useState(false);
    const [isEdit,setIsEdit] = useState(false);
    const [loading,setLoading] = useState(false);
    const [alert,setAlert] = useState({
        success:'',
        error:'',
        request:''
    })
    const [admins,setAdmins] = useState([]);
    const [adminsForFilter,setAdminsForFilter] = useState([]);
    const [username,setUsername] = useState('');
    const [password,setPassword] = useState('');
    const [isDeleteDialog,setIsDeleteDialog] = useState(false);
    const [filter,setFilter] = useState('');
    const [rowsPerPage,setRowsPerPage] = useState(10);
    const [isAdding,setIsAdding] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isDeleting,setIsDeleting] = useState(false);
    const [usernames,setUsernames] = useState([]);
    const [isExpiredSession,setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const filterRef = useRef();
    const alertRef = useRef();
    const resizeWidth = useRef();
    const {t} = useTranslation();

    const columns = [
        {
            headerName:t("Tên người quản trị"),
            field:'DisplayName',
            cellClassName:'super-app-theme--cell',
            flex:1,
        },
        {
            headerName:t("Tên đăng nhập"),
            field:'Username',
            cellClassName:'super-app-theme--cell',
            flex:1,
        },
        {
            headerName:'Email',
            field:'Email',
            cellClassName:'super-app-theme--cell',
            flex:1,
        },
        {
            headerName:t("Vai trò"),
            field:'AdminRole',
            cellClassName:'super-app-theme--cell',
            flex:1,
            renderCell: params=> showRole(params.row.AdminRole)
        },
        {
            headerName:t("Trạng thái"),
            field:'AdminStatus',
            cellClassName:'super-app-theme--cell',
            width:180,
            sortComparator:(v1,v2,params1,params2)=>sortStatus(v1,v2),
            renderCell: params => showAdminStatus(params.row.AdminStatus),
        },
        {
            headerName:t("Thao tác"),
            field:'Action',
            sortable:false,
            width:150,
            renderCell: params =>params.row.AdminRole!=='root' && params.row.Username!==localStorage.getItem('username') && <TextButton onClick={()=>onDetailClick(params.row.Username,params.row.Password)} >{t("Chỉnh sửa")}</TextButton>
        },
    ]

    useEffect(()=>{
        if(isExpiredSession){
          history.push('/login');
          dispatch({type:'IS_LOGIN_AGAIN_TRUE'});
        } 
    },[isExpiredSession]);

    const showRole = (roleName)=>{
        if(roleName==='root') return 'Root';
        return roleName==='admin'?t('Quản trị'):t('Người điều hành');
    }
    const sortStatus = (v1,v2)=>{
        if(v1==='active'&&v2==='inactive') return 1;
        if(v1==='deactivated'&&v2==='active') return 1;
        if(v1==='deactivated'&&v2==='inactive') return 1;
        if(v1==='inactive'&&v2==='active') return -1
        if(v1==='active'&&v2==='deactivated') return -1;
        if(v1==='inactive'&&v2==='deactivated') return -1;
        return 0;
    }
    const showAdminStatus = (status) =>{
        switch (status) {
            case 'active':
                return (
                <div className={clsx(classes.statusActive,classes.font)}>
                    <FiberManualRecordIcon style={{position:'relative',top:'0px',color:'#2AB930'}} />
                    {t("Đã đổi mật khẩu")}
                </div>
                )
            case 'deactivated':
                return(
                <div className={clsx(classes.statusDeActived,classes.font)}>
                    <FiberManualRecordIcon style={{position:'relative',top:'0px',color:'#e01010'}} />
                    {t("Đã bị khóa")}
                </div>
                )
            case 'inactive':
                return(
                <div className={clsx(classes.status,classes.font)}>
                    <FiberManualRecordIcon style={{position:'relative',top:'0px',color:'#6D6D6D'}} />
                    {t("Chưa đổi mật khẩu")}
                </div>
                )
            default:
                return ''
        }
    }

    const getDefaultValues = async()=>{
        try {
            var res;
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                res = await axiosClient.get('/adminusers',{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
                });
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
              res = await axiosClient.post('/adminusers/get',{},{
                headers:{'Authorization': `Bearer ${Cookies.get('token')}`,'Content-Type': 'application/json'}
              });
            }
            const data = [];
            res.data.Items.forEach(item=>{
                data.push({...item,id:item.Username});
            })
            setAdmins(data);
            setAdminsForFilter(data);
            setUsernames([]);
            setLoading(true);
            setIsEdit(false);
            setIsAddForm(false);
            setAlert({...alert,request:''});
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);    
            setAlert({...alert,request:t("Không lấy được dữ liệu")});
        }
    }  

    useEffect(()=>{
        if(!loading) getDefaultValues();
    },[loading])

    const onFilterChange = (e)=>{
        if(filterRef.current) clearTimeout(filterRef.current);
        filterRef.current = setTimeout(() => {
            setFilter(e.target.value);
        }, 500);
    }

    useEffect(() => {
        setAdminsForFilter(admins.filter(item => 
            item.Username.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
            item.Email.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
            item.Password.toLowerCase().indexOf(filter.toLowerCase()) !== -1 
        ))
    }, [filter])

    const onDetailClick = (uname,pw) =>{
        setUsername(uname);
        setPassword(pw);
        if(!isEdit) changeStatusEdit();
    }

    const lockAdminUser = async (uname) =>{
        if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
            try {
                setIsUpdating(true);
                await axiosClient.post('/disableadminuser',{'username':uname},{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
                })
                setAlert({...alert,success:`${t("Khóa thành công tài khoản")} ${uname}`,err:''});
                setLoading(false);
            } catch (error) {
                setAlert({...alert,error:`${t("Khóa không thành công tài khoản")} ${uname}`,success:''});
            }
            setIsUpdating(false);
        } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
            try {
                setIsUpdating(true);
                await axiosClient.post('/auth/lock',{'username':uname},{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`,'Content-Type': 'application/json'}
                });
                setAlert({...alert,success:`${t("Khóa thành công tài khoản")} ${uname}`,err:''});
                setLoading(false);
            } catch (e) {
                setAlert({...alert,error:`${t("Khóa không thành công tài khoản")}${uname}`,success:''});
            }
            setIsUpdating(false);
        }
        
    }

    const unLockAdminUser = async (uname) =>{
        if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
            try {
                setIsUpdating(true);
                await axiosClient.post('/enableadminuser',{'username':uname},{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
                })
                setAlert({...alert,success:`${t("Mở khóa thành công tài khoản")} ${uname}`,err:''});
                setLoading(false);
            } catch (error) {
                setAlert({...alert,error:`${t("Mở khóa không thành công tài khoản")} ${uname}`,success:''});
            }
            setIsUpdating(false);
        } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
            try {
                setIsUpdating(true);
                await axiosClient.post('/auth/unlock',{'username':uname},{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`,'Content-Type': 'application/json'}
                });
                setAlert({...alert,success:`${t("Mã xác thực tài khoản đã được gửi đến ")} ${uname}`,err:''});
                setLoading(false);
            } catch (e) {
                setAlert({...alert,error:`${t("Mở khóa không thành công tài khoản")} ${uname}`,success:''});
            }
            setIsUpdating(false);
        }
    }

    const addAdminAccount = async(account) =>{
        try {
            setIsAdding(true);
            changeStatusAddClick();
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
              await axiosClient.post('/createadminuser',JSON.stringify(account),{
                headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
              });
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
              await axiosClient.post('/auth/signup',JSON.stringify(account),{
                headers:{'Authorization': `Bearer ${Cookies.get('token')}`,'Content-Type': 'application/json'}
              });
            }
            setAlert({...alert,error:'',success:t("Mã xác thực tài khoản đã được gửi đến ")+account.email+'!'});          
            setLoading(!loading);
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);    
            setAlert({...alert,error:t("Đã xảy ra lỗi, vui lòng thử lại!"),success:''});
        }
        setIsAdding(false);
    }

    const updateAdminUserToAws = async(data)=>{
        try {
            setIsUpdating(true);
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
              await axiosClient.post('/updateadminuser',JSON.stringify(data),{headers:{'Authorization': `Bearer ${Cookies.get('token')}`,'Content-Type': 'application/json'}});     
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
              await axiosClient.post('/adminusers/update',JSON.stringify(data),{
                headers:{'Authorization': `Bearer ${Cookies.get('token')}`,'Content-Type': 'application/json'}
              });     
            }
            setAlert({...alert,success:`${t("Lưu thành công!")}`,error:''});
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);      
            setAlert({...alert,error:t("Đã xảy ra lỗi, vui lòng thử lại!"),success:''});
        }
        setIsUpdating(false);
        setLoading(false);
    }

    const updateAdminUser = async(oldPassword,data) =>{
        try {
            setIsUpdating(true);
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                await axiosClient.post('/cognito/password/change',JSON.stringify({username:data.username,password:oldPassword,new_password:data.password}));
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
              await axiosClient.post('/auth/changepassword',JSON.stringify({username:data.username, oldpassword: oldPassword, newpassword:data.password}),{
                headers:{'Authorization': `Bearer ${Cookies.get('token')}`,'Content-Type': 'application/json'}
              });     
            }
            updateAdminUserToAws({...data,failure_login:0});
            setAlert({...alert,success:`${t("Lưu thành công!")}`,error:''});
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true); 
            setAlert({...alert,error:t("Tài khoản chưa được xác thực, vui lòng thử lại sau!"),success:''});
        }      
        setIsUpdating(false);
    }
    
    const changeStatusEdit = () =>{
        setIsEdit(!isEdit);
        isAddForm?setIsAddForm(!isAddForm):resizeWidthForm();
    }

    const changeStatusAddClick = () =>{
        setIsAddForm(!isAddForm);
        isEdit? setIsEdit(!isEdit):resizeWidthForm();
    }
    const resizeWidthForm = () =>{
        if(resizeWidth.current.style['width']==='100%') resizeWidth.current.style['width']='64%';
        else resizeWidth.current.style['width']='100%';
    }  

    const onAlertClose = () =>{
        setAlert({...alert,success:'',error:''});
    }

    useEffect(() => {
        if(alert.success !== '' || alert.error !== ''){
            if(alertRef.current) clearTimeout(alertRef.current);
            alertRef.current = setTimeout(() => {
                onAlertClose();
            }, 5000);
        }
    }, [alert])

    const onSelectedChange = (e) =>{
        setUsernames(e);
    }

    const onDeleteClick = () =>{
        setIsDeleteDialog(true);
    }

    const onConfirmDeleteClick = async()=>{
        try {
            setIsDeleting(true);
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
              await axiosClient.post('/removeadminusers',{'usernames':usernames},{
                headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
              });
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
              await axiosClient.post('/adminusers/delete',{'username':usernames},{
                headers:{'Authorization': `Bearer ${Cookies.get('token')}`,'Content-Type': 'application/json'}
              });
            }
            setAlert({...alert,success:`${t("Đã xóa thành công tài khoản")}!`,error:''});      
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);    
            setAlert({...alert,success:'',error:t("Đã xảy ra lỗi, vui lòng thử lại!")});
        }   
        setIsDeleteDialog(false);
        setLoading(!loading);
        setIsDeleting(false);
    }

    const deleteConfirmDialog = ()=>{
        return(
            <Dialog 
              open={isDeleteDialog}
              maxWidth="xl"
            >
              <DialogTitle className={classes.dialogTitleBackground}>
                  <p className={classes.dialogTitle}>{t("Thông báo")}</p>
              </DialogTitle>
              <DialogContent style={{width:'448px',padding:'0',textAlign:'center',position:'relative'}}>
                    <div className={classes.contentDialog}>
                        <p>{t("Bạn có chắc chắn muốn xóa tài khoản quản trị?")}  </p>
                    </div>
                    <CancelButton 
                        style={{margin:'10px 0'}}
                        variant="outlined" 
                        onClick={()=>{setIsDeleteDialog(!isDeleteDialog)}} 
                        disabled={isDeleting}
                    >
                        {t("Trở về")}
                    </CancelButton>
                    <CreateButton 
                        variant="contained" 
                        style={{margin:'10px 16px'}} 
                        onClick={onConfirmDeleteClick} 
                        disabled={isDeleting}
                    >
                        {t("Đồng ý")}
                    </CreateButton>
                    {isDeleting && <CircularProgress style={{position:'absolute',left:'45%',top:'10%',color:'#0b59c8'}}/>}
              </DialogContent>
            </Dialog>
        )
    }
    const EmptyRowsView = () => {
        return (
          <div
            style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}
          >
            <h3 style={{color:'#6D6D6D',fontSize: '16px'}}>
            {t("Không tìm thấy admin")}
            </h3>
          </div>
        );
    };
    return (
        <div>
            {deleteConfirmDialog()}
            {(isAdding || isUpdating) && <div className={classes.overlay}><CircularProgress style={{color:'#0b59c8'}}/></div>}
            <div className={clsx(classes.focus,classes.topBlock)}>
                <p className={classes.primaryTitle} style={{marginLeft:'16px'}}>{t("Quản lý tài khoản")}</p>
                {loading && alert.request === '' &&
                <TextField
                    placeholder={t("Tìm kiếm")}
                    variant="outlined"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        style:{background:'#FFFFFF',height:'40px',width:'320px'}
                    }}
                    onChange={onFilterChange}
                />}
            </div>
            <div className={classes.content}>
                {!loading && alert.request === '' && 
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',position:'fixed',width:'100%',height:'100%',top:'0',left:'0'}}>
                    <CircularProgress color="primary" style={{color:'#0b59c8'}}/>
                </div>
                }
                {alert.request !== '' && <div className={classes.requestError}>{alert.request}</div>}
                {loading && alert.request === '' &&
                <div style={{width:'100%'}}>
                    <div ref={resizeWidth} className={clsx(classes.tableContent,classes.root)} style={{width:'100%'}}>
                        <div className={classes.actionTable}>                   
                            {/* <p className={classes.selectTitle} > {t("Danh sách tài khoản admin")}</p> */}
                            <CreateButton variant="contained" startIcon={<AddCircleOutlineIcon />} onClick={changeStatusAddClick} style={{margin:'8px'}}>{t("Thêm")}</CreateButton>
                            <DeleteButton
                                onClick={onDeleteClick}
                                variant='outlined'
                                startIcon={<DeleteOutlineOutlinedIcon />}
                                disabled={usernames.length === 0}
                            >
                                {t('Xóa')}
                            </DeleteButton>
                            <SuccessAlert message={alert.success} onCloseDialog={onAlertClose} />
                            <ErrorAlert message={alert.error} onCloseDialog={onAlertClose} />
                            {/* { alert.success !=='' && <Alert className={classes.alertStyle} icon={false} onClose={onAlertClose}>{alert.success}</Alert>}
                            { alert.error !=='' && <Alert className={classes.alertStyle} severity="error" onClose={onAlertClose}>{alert.error}</Alert>} */}
                        </div>
                        <DataGrid 
                            componentsProps={{
                                pagination: {
                                    labelRowsPerPage: t("Số dòng mỗi trang"),
                                    labelDisplayedRows:({from,to,count}) => {return `${from}-${to} ${t("trong số")} ${count}`}
                                }
                            }}
                            components={{
                                NoRowsOverlay:EmptyRowsView
                            }}  
                            localeText={{
                                footerRowSelected: (count) => count +t(" hàng trong bảng đã được chọn"),
                                columnHeaderSortIconLabel: t('Sắp xếp')
                            }}
                            columns={columns}
                            rows={adminsForFilter}
                            pageSize={rowsPerPage}
                            rowHeight={45}
                            rowsPerPageOptions={[10,50,100]}
                            onPageSizeChange={(pageSize)=>setRowsPerPage(pageSize)}
                            checkboxSelection
                            disableSelectionOnClick
                            isRowSelectable={(params) => params.row.AdminRole !=='root' && params.row.Username !== localStorage.getItem('username')}
                            onSelectionModelChange={onSelectedChange}
                        />
                        <div style={{height:'30px'}}></div>
                    </div>
                    
                    {isAddForm && <AddAdminForm changeStatusAddClick={()=>changeStatusAddClick()} addAdminAccount={(account)=>addAdminAccount(account)} admins={admins}  />  }
                    {isEdit && 
                    <EditAdminForm  
                        changeStatusEdit={()=>changeStatusEdit()} 
                        username={username} 
                        password={password}
                        updateAdminUser={(oldPassword,data)=>updateAdminUser(oldPassword,data)}
                        lockAdminUser={(uname)=>lockAdminUser(username)}
                        unLockAdminUser={(uname)=>unLockAdminUser(username)}
                        updateAdminUserToAws={(data)=>updateAdminUserToAws(data)}
                    /> }       
                </div>}
            </div>
            
        </div>
    )
}


export default AdminTable;

