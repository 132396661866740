import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import Button from '@material-ui/core/Button/Button';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import axiosClient from '../../../config/AxiosClient';
import Cookies from 'js-cookie';
import SuccessAlert from '../../alert/SuccessAlert';
import ErrorAlert from '../../alert/ErrorAlert';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { configurations } from '../../../config/Registration';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import clsx from 'clsx';
import { apiGetconfig } from '../../../api/getconfig';
import { apiUpdateconfig } from '../../../api/updateconfig';
import { PaymentConfiguration } from '../../../config/enum';

const useStyles = makeStyles((theme) => ({
    titleStyle: {
        fontSize: '22px',
        fontWeight: 'bold',
        padding: '23px 0 15px 0'
    },
    primaryTextColor: theme.primaryTextColor,
    label: {
        margin: '0',
        fontSize: '18px',
        lineHeight: '140%',
        color: '#000000',
        width: '80%',
        display: 'flex',
        alignItems: 'center'
    },
    contentDialog: {
        fontSize: '16px',
        height: '68px',
        borderBottom: '1px solid #EEEDF2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    dialogTitle: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '25px',
        color: '#FFFFFF',
    },
    dialogTitleBackground: theme.primaryColor,
    switchStyle: {
        '& .Mui-checked+.MuiSwitch-track': {
            backgroundColor: '#0b59c8',
            opacity: 0.5,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    },
    paperStyle: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        background: 'rgb(250 250 250)',
        padding: '10px',
        borderRadius: '6px'
    },
    select: {
        '& .MuiOutlinedInput-input': {
            padding: '6px 12px'
        }
    },
    selectStyle: {
        maxHeight: '32px',
        background: '#FFFFFF',
        width: '100%'
    },
    circularProgressBackground: {
        width: '100%',
        height: '80%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    circularProgressColor: {
        color: '#0b59c8'
    },
    circularProgressStyle: {
        position: 'absolute',
        top: '10%',
        left: '45%',
        color: '#0b59c8'
    },
    actionBlock: {
        textAlign: 'right',
        width: '100%',
        padding: '20px 0'
    },
    formControlStyle: {
        position: 'relative',
        padding: '0!important',
        width: '100%'
    },
    rowStyle: {
        width: '100%',
        margin: 'auto',
        display: 'flex',
        flexWrap: 'wrap'
    },
    dialogContentStyle: {
        width: '448px',
        padding: '0',
        textAlign: 'center',
        position: 'relative'
    },
    confirmStyle: {
        margin: '10px 20px'
    },
    cancelStyle: {
        marginLeft: '30px'
    }
}));

const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);
const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);
const DeleteButton = withStyles(theme => ({ root: theme.delete_button }))(Button);

const PaymentConfig = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const username = 'tsmart-building';
    const [loading, setLoading] = useState(false);
    const [isExpiredSession, setIsExpiredSession] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [alert, setAlert] = useState({
        success: '',
        error: ''
    })
    const [paymentConfig, setPaymentConfig] = useState(-1);
    const [isSaving, setIsSaving] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const alertRef = React.useRef();

    useEffect(() => {
        if (isExpiredSession) {
            history.push('/login');
            dispatch({ type: 'IS_LOGIN_AGAIN_TRUE' });
        }
    }, [isExpiredSession]);

    const getDefaultValues = async () => {
        try {
            let res = await apiGetconfig();
            if (res.data.Items[0].Configuration.payment) {
                setPaymentConfig(res.data.Items[0].Configuration.payment);
            }
        } catch (error) {
            if (error.response && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
        }
        setIsEdit(false);
        setLoading(true);
    }

    useEffect(() => {
        if (!loading) getDefaultValues();
    }, [loading])

    const onChange = (e) => {
        setPaymentConfig(e.target.value);
    }

    const onSaveClick = () => {
        setIsConfirm(true);
    }

    const onConfirmSaveClick = async () => {
        try {
            setIsSaving(true);
            const data = {
                username: username,
                configuration: {
                    payment: paymentConfig
                }
            }
            await apiUpdateconfig(data);
            setIsEdit(false);
            setLoading(false);
            setLoading(false);
            setAlert({
                ...alert,
                success: t('Lưu cấu hình thành công!'),
                error: ''
            })
        } catch (error) {
            if (error.response && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({
                ...alert,
                success: t('Lưu không thành công, vui lòng thử lại sau!'),
                error: ''
            })
        }
        setIsSaving(false);
        setIsConfirm(false);
    }

    const onCloseDialog = () => {
        setAlert({ ...alert, success: '', error: '' });
    }

    useEffect(() => {
        if (alert.success !== '' || alert.error !== '') {
            if (alertRef.current) clearTimeout(alertRef.current);
            alertRef.current = setTimeout(() => {
                onCloseDialog();
            }, 5000);
        }
    }, [alert])

    const confirmSaveDialog = () => {
        return (
            <Dialog
                open={isConfirm}
                maxWidth="xl"
            >
                <DialogTitle className={classes.dialogTitleBackground}>
                    <p className={classes.dialogTitle}>{t('Thông báo')}</p>
                </DialogTitle>
                <DialogContent className={classes.dialogContentStyle}>
                    <p className={classes.contentDialog}>{t('Bạn có chắc chắn muốn lưu thay đổi?')}</p>
                    <CancelButton
                        className={classes.cancelStyle}
                        onClick={() => setIsConfirm(false)}
                        disabled={isSaving}
                    >
                        {t('Trở về')}
                    </CancelButton>
                    <CreateButton
                        className={classes.confirmStyle}
                        onClick={onConfirmSaveClick}
                        disabled={isSaving}
                    >
                        {t('Đồng ý')}
                    </CreateButton>
                    {isSaving && <CircularProgress className={classes.circularProgressStyle} />}
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <div style={{ width: '98%', margin: 'auto' }}>
            {confirmSaveDialog()}
            <SuccessAlert
                message={alert.success}
                onCloseDialog={onCloseDialog}
            />
            <ErrorAlert
                message={alert.error}
                onCloseDialog={onCloseDialog}
            />
            <p className={classes.titleStyle}>{t('Cấu hình thanh toán')}</p>
            <div className={classes.rowStyle}>
                <Paper className={clsx(classes.switchStyle, classes.paperStyle)}>
                    <div style={{ display: 'flex', width: '100%' }} >
                        <p className={classes.label}>{t('Chức năng thanh toán trên T-Building')}</p>
                        <div style={{ width: '20%' }} className={classes.switchStyle}>
                            <FormControl
                                required
                                variant="outlined"
                                className={clsx(classes.select, classes.formControlStyle)}
                            >
                                <Select
                                    inputProps={{
                                        style: {
                                            padding: '6px 10px',
                                        },
                                    }}
                                    name="ringCall"
                                    disabled={!isEdit}
                                    value={paymentConfig}
                                    className={classes.selectStyle}
                                    onChange={onChange}
                                >
                                    <MenuItem value={PaymentConfiguration.HIDE} >
                                        <p className={classes.placeholder}>{t('Ẩn')} </p>
                                    </MenuItem>
                                    <MenuItem value={PaymentConfiguration.SHOW} >
                                        <p className={classes.placeholder}> {t('Hiển thị')} </p>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </Paper>
                {!isEdit &&
                    <div className={classes.actionBlock}>
                        <CreateButton
                            variant="contained"
                            onClick={() => setIsEdit(true)}
                        >
                            {t('Chỉnh sửa')}
                        </CreateButton>
                    </div>}
                {isEdit &&
                    <div className={classes.actionBlock}>
                        <CancelButton
                            variant="outlined"
                            onClick={() => {
                                setIsEdit(false);
                                setLoading(false);
                            }}
                        >
                            {t('Hủy')}
                        </CancelButton>
                        <CreateButton
                            variant="contained"
                            onClick={onSaveClick}
                        >
                            {t('Lưu')}
                        </CreateButton>
                    </div>}
            </div>
        </div>
    )
}

export default PaymentConfig;