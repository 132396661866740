import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
    },
    hiddenScroll: {
        overflow: "hidden"
    },
    contain: {
        position: "absolute",
        top: "0",
        right: "0",
        zIndex: "1202",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        background: "rgb(0, 0, 0, 0.5)"
    },
    wrapSearch: {
        position: "relative"
    },
    searchIcon: {
        position: "absolute",
        top: "8px",
        right: "9px",
        color: "rgb(0, 0, 0, 0.6)",
        background: "white"
    },
    form: {
        position: "relative",
        borderRadius: "5px",
        padding: "1.5rem",
        width: "440px",
        background: "white"
    },
    roomName: {
        fontSize: "1.25rem",
    },
    date: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
        marginTop: "1.5rem",
        width: "100%",
    },
    dateStart: {
        width: "45%"
    },
    dateEnd: {
        width: "45%"
    },
    time: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "1.5rem",
        width: "100%",

        "& div": {
            width: "45%",

            "& div": {
                width: "100%",
                "& > div": {
                    background: "unset !important"
                }
            }
        }
    },
    isAvailableTime: {
        color: "red"
    },
    textField: {
        width: "100%",
        marginTop: "1.5rem"
    },
    btnContain: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "1.5rem"
    }
}));

export default useStyles;