import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NoneInforIcon } from '../../../../components/icons';
import { formatToVND } from '../../../../helper/currency';
import { capitalizeFirstLetter } from '../../../../helper/strings';
import { useStyles } from './styles';

export default function CommonDetail({ cell }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const moment = require('moment-timezone');

    const getStatusCell = (cell) => {
        let result = <span></span>;

        if (!cell) {
            return result;
        }

        //cabinet cell details
        if (!cell.startTime) {
            result = <span className={clsx(classes.cellStatus, classes.emptyStatus)}>
                {t("Trống")}
            </span>;
        } else if (!cell.deliveredTime) {
            result = <span className={clsx(classes.cellStatus, classes.waitDeliveryStatus)}>
                {t("Chờ gửi hàng")}
            </span>;
        }
        if (cell.startTime && cell.deliveredTime) {
            result = <span className={clsx(classes.cellStatus, classes.waitGetItemStatus)}>
                {t("Chờ lấy hàng")}
            </span>;
        }

        const isExpired = cell.startTime + cell?.overdueDate * 24 * 60 * 60 * 1000;
        if (isExpired < Date.now()) {
            result = <span className={clsx(classes.cellStatus, classes.overDueStatus)}>
                {t("Quá hạn")}
            </span>;
        }

        //cabinet history cell details
        if (cell?.UserName) {
            result = <p
                style={{
                    position: "relative",
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    paddingLeft: "1rem",
                    color: cell.PaymentStatus === "pending"
                        ? "#EE8306"
                        : cell.PaymentStatus === "paid"
                            ? "#29A62B"
                            : "#ED1626"
                }}
                className={clsx(
                    cell.PaymentStatus && classes[`statusColumn${cell.PaymentStatus}`]
                )}
            >
                {cell.PaymentStatus
                    ? t(capitalizeFirstLetter(cell.PaymentStatus))
                    : ""}
            </p>
        }

        return result;
    }

    const getTotalHourColunm = (cell) => {
        let result = "";

        const mess = (cell.endTime || cell.EndTime) ? moment
            .duration((cell.endTime || cell.EndTime) - cell.StartTime, 'milliseconds')
            .humanize({ h: Infinity }) : "";

        if (mess && isNaN(mess.split(' ')[0])) {
            result = 1;
        }
        if (mess && !isNaN(mess.split(' ')[0])) {
            if (mess.split(' ')[1] === "hours") {
                result = mess.split(' ')[0];
            } else {
                result = 1;
            }
        }

        return result;
    }

    return !cell?.ID ? null : (
        <div className={classes.commonDetail}>
            <div className={classes.detailBooker}>
                <p className={classes.detailTitle}>{t("Thông tin người đặt")}</p>

                {(cell?.fullName || cell?.FullName) && (
                    <>
                        <p className={classes.detailLabel}>{t("Họ và tên")}</p>
                        <p className={classes.detailInfo}>{cell?.fullName || cell?.FullName}</p>
                    </>
                )}

                {(cell?.userName || cell?.UserName) && (
                    <>
                        <p className={classes.detailLabel}>{t("Số điện thoại")}</p>
                        <p className={classes.detailInfo}>{cell?.userName || cell?.UserName}</p>
                    </>
                )}

                {cell?.userName && cell?.apartments.length > 0 && (
                    <>
                        <p className={classes.detailLabel}>{t("Địa chỉ")}</p>
                        <p className={classes.detailInfo}>
                            {`${t("Căn hộ")} `}
                            {cell?.apartments[0]?.floor + cell?.apartments[0]?.room}
                            {`${" - "}`}
                            {`${t("Tòa")} `}
                            {cell?.apartments[0]?.floor + cell?.apartments[0]?.block}
                        </p>
                    </>
                )}

                {(!cell?.userName && !cell?.UserName) && (
                    <div className={classes.noneBooker}>
                        <NoneInforIcon />
                        <p>{t("Không có thông tin người đặt")}</p>
                    </div>
                )}
            </div>
            <div className={classes.detailCabin}>
                <p className={classes.detailTitle}>{t("Thông tin tủ")}</p>

                <div className={classes.wrapCabinInfo}>
                    <div className={classes.left}>
                        <p className={classes.detailLabel}>{t("Tên tủ")}</p>
                        <p className={classes.detailInfo}>{cell?.cabinetName || cell?.CabinetName}</p>

                        <p className={classes.detailLabel}>{t("Số ô")}</p>
                        <p className={classes.detailInfo}>{cell?.cabinetCellName || cell?.CabinetCellName}</p>

                        <p className={classes.detailLabel}>{t("Kích thước")}</p>
                        <p className={classes.detailInfo}>{cell?.cellTypeID?.CellType[0]?.name || cell?.CellType[0]?.name}</p>

                        <p className={classes.detailLabel}>{t("Trạng thái")}</p>
                        <div className={classes.detailInfo}>{getStatusCell(cell)}</div>
                    </div>

                    <div className={classes.right}>
                        {(cell?.startTime || cell?.StartTime) && (
                            <>
                                <p className={classes.detailLabel}>{t("Thời gian đặt tủ")}</p>
                                <p className={classes.detailInfo}>
                                    {moment.tz((cell?.startTime || cell?.StartTime), "Asia/Ho_Chi_Minh").format("HH:mm DD/MM/YYYY ")}
                                </p>
                            </>
                        )}

                        {cell?.PaymentStatus !== "pending" && (cell.endTime || cell.EndTime) && (
                            <>
                                <p className={classes.detailLabel}>{t("Thời gian trả tủ")}</p>
                                <p className={classes.detailInfo}>
                                    {moment.tz((cell.endTime || cell.EndTime), "Asia/Ho_Chi_Minh").format("HH:mm DD/MM/YYYY ")}
                                </p>
                            </>
                        )}

                        {cell?.expiredTime && (
                            <>
                                <p className={classes.detailLabel}>{t("Thời gian quá hạn")}</p>
                                <p className={classes.detailInfo}>
                                    {moment.tz(cell?.expiredTime, "Asia/Ho_Chi_Minh").format("HH:mm DD/MM/YYYY ")}
                                </p>
                            </>
                        )}

                        {cell?.endTime && cell?.StartTime && (
                            <>
                                <p className={classes.detailLabel}>{t("Tổng giờ")}</p>
                                <p className={classes.detailInfo}>
                                    {getTotalHourColunm(cell)}
                                </p>
                            </>
                        )}


                        {(cell?.totalPrice == 0 || cell?.totalPrice) && (
                            <>
                                <p className={classes.detailLabel}>{t("Tổng thanh toán")}</p>
                                <p className={classes.detailInfo}>
                                    {formatToVND(cell?.totalPrice)}
                                </p>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
