import Select from '@material-ui/core/Select';
import { MenuItem } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiCabinetListGet } from '../../../api/cabinets';
import ErrorAlert from '../../../components/alert/ErrorAlert';
import LargeTooltip from '../../../components/largeTooltip';
import { PrimaryTitle } from '../../../components/title';
import CabinetBar from './bookedCellBar';
import CommonCabinetChart from './commonChart';
import CabinetStatisDoughnut from './doughnut';
import CabinetStatisLine from './line';
import CabinetSizeBar from './sizeBar';
import { useStyles } from './styles';

export default function StatisCabinet() {
  const [state, setState] = useState({
    listCabinet: [],
    cabinetIdSelected: '',
    responseStatus: {
      succ: '',
      err: ''
    },
    isLoading: false,
    isCabinetBarLoading: false,
    isCabinetSizeBarLoading: false,
    isCabinetLineLoading: false
  })

  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const fetchApiCabinetListGet = async () => {
    try {
      setState(prev => ({
        ...prev,
        isLoading: true
      }));

      const { data } = await apiCabinetListGet();
      const listCabinet = data?.Items || [];

      let cabinetIdSelected = "";
      if (Array.isArray(listCabinet) && listCabinet.length > 0) {
        cabinetIdSelected = listCabinet[0].ID;
      }

      setState(prev => ({
        ...prev,
        listCabinet,
        cabinetIdSelected,
      }));
    } catch (error) {
      handleChangeResponseStatus("", t("Tải thông tin tủ bị lỗi, vui lòng tải lại"));
    }

    setState(prev => ({
      ...prev,
      isLoading: false
    }));
  }

  const handleChangeResponseStatus = (succ = '', err = '') => {
    setState(prev => ({
      ...prev,
      responseStatus: {
        succ,
        err
      }
    }))
  }

  const onSelectCabinetChange = (e) => {
    setState(prev => ({
      ...prev,
      cabinetIdSelected: e.target.value,
    }));
  }

  const handleToggleCabinetBarLoading = () => {
    setState(prev => ({
      ...prev,
      isCabinetBarLoading: !prev.isCabinetBarLoading
    }))
  }

  const handleToggleCabinetSizeBarLoading = () => {
    setState(prev => ({
      ...prev,
      isCabinetSizeBarLoading: !prev.isCabinetSizeBarLoading
    }))
  }

  const handleToggleCabinetLineLoading = () => {
    setState(prev => ({
      ...prev,
      isCabinetLineLoading: !prev.isCabinetLineLoading
    }))
  }

  useEffect(() => {
    fetchApiCabinetListGet();
  }, [i18n.language]);

  const CabinetBarMemo = useMemo(() => (
    <CommonCabinetChart
      isHiddenTotal
      isLoading={state.isLoading || state.isCabinetBarLoading}
      title={t("Số lượng đặt tủ")}
      ChartNode={
        <CabinetBar
          cabinetId={state.cabinetIdSelected}
          handleToggleCabinetBarLoading={handleToggleCabinetBarLoading}
        />
      }
    />
  ), [state.isLoading, state.isCabinetBarLoading, state.cabinetIdSelected, t])

  const CabinetSizeBarMemo = useMemo(() => (
    <CommonCabinetChart
      isHiddenTotal
      isUnsetPaddingBottom
      isLoading={state.isLoading || state.isCabinetSizeBarLoading}
      title={t("Số lượng đặt tủ theo từng loại")}
      ChartNode={
        <CabinetSizeBar
          isLoading={state.isLoading || state.isCabinetSizeBarLoading}
          cabinetId={state.cabinetIdSelected}
          handleToggleCabinetSizeBarLoading={handleToggleCabinetSizeBarLoading}
        />
      }
    />
  ), [state.isLoading, state.isCabinetSizeBarLoading, state.cabinetIdSelected, t]);

  const CabinetLineMemo = useMemo(() => (
    <CommonCabinetChart
      isLoading={state.isLoading || state.isCabinetLineLoading}
      isChartLine
      title={t("Doanh thu tủ")}
      ChartNode={
        <CabinetStatisLine
          cabinetId={state.cabinetIdSelected}
          handleToggleCabinetLineLoading={handleToggleCabinetLineLoading}
        />
      }
    />
  ), [state.isLoading, state.isCabinetLineLoading, state.cabinetIdSelected, t])

  return (
    <div className={classes.statistic}>
      <div className={classes.head}>
        <PrimaryTitle>{t("Báo cáo thống kê")}</PrimaryTitle>
        <Select
          id='block'
          size='small'
          className={clsx(classes.select, classes.selectNoneBorder)}
          disabled={state.isLoading}
          value={state.cabinetIdSelected}
          onChange={onSelectCabinetChange}
        >
          {state.listCabinet?.map(item => (
            <MenuItem
              className={classes.menuItem}
              key={item.ID}
              value={item.ID}
            >
              <LargeTooltip
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
                title={`${t("Tòa")} ${item.Location} - ${t("Tủ")} ${item.CabinetName}`}

              >
                <p>
                  {t("Tòa")} {item.Location} - {t("Tủ")} {item.CabinetName}
                </p>
              </LargeTooltip>
            </MenuItem>
          ))}
        </Select>
      </div>

      <CabinetStatisDoughnut
        cabinetID={state.cabinetIdSelected}
        isCabinetLoading={state.isLoading}
      />

      {CabinetBarMemo}

      {CabinetSizeBarMemo}

      {CabinetLineMemo}

      <ErrorAlert
        message={state.responseStatus.err}
        onCloseDialog={() => handleChangeResponseStatus()}
      />
    </div>
  )
}
