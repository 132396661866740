import AxiosClient from "../config/AxiosClient";

//service booking
export const createBookingService = (body) => {
    /*
        body = {
            service_name: string,   required
            service_type: string,   required
            description: string,
            service_icon: string
        }
    */
    const url = "/bookings/services/create";
    return AxiosClient.post(url, body);
}
export const getBookingService = () => {
    /*
        data = {
            Count: number,
            Items: [
                {
                    ID: "",
                    Description: "",
                    ServiceIcon: "",
                    ServiceName: "",
                    ServiceType: ""
                    WorkingTime: [{
                        day: 0,
                        value: [
                            {
                               from: "",
                               to: "" 
                            }
                        ]
                    }]
                }
            ]
        }
    */
    const url = "/bookings/services/get";
    return AxiosClient.get(url);
}
export const updateBookingService = (body) => {
    /*
        body = {
            service_name: string,   required
            service_type: string,   required
            description: string,
            service_icon: string,
            woking_time: [{
                day: 0,
                value: [
                    // {
                    //    from: "",
                    //    to: "" 
                    // }
                ]
            }]
        }
    */
    const url = "/bookings/services/update";
    return AxiosClient.put(url, body);
}
export const deleteBookingService = (body) => {
    /*
        body = {
            id: "" | ids: []
        }
    */
    const url = "/bookings/services/delete";
    return AxiosClient.delete(url, { data: body });
}

export const createServiceRoom = (body) => {
    /*
        body = {
            service_id: ""
            room_name: "",
            amount_people: number,
            description: "", option
        }
    */
    const url = "/bookings/rooms/create";
    return AxiosClient.post(url, body);
}
export const getServiceRoom = (body) => {
    /*
        body = {
            service_id: "",     idservice
            start_index: number,
            end_index: number
        }
    */
    const url = "/bookings/rooms/get";
    
    return AxiosClient.post(url, body);
}
export const updateServiceRoom = (body) => {
    /*
        body = {
            id: "",
            room_name: "",
            amount_people: number,
            description: "", option,
            status: "working" | "suspend"
        }
     */
    const url = "/bookings/rooms/update";
    return AxiosClient.put(url, body);
}
export const deleteServiceRoom = (body) => {
    /*
        body = {
            id: "" || ids: []
        }
    */
    const url = "/bookings/rooms/delete";
    return AxiosClient.delete(url, { data: body });
}
/***************************** */

//booking
export const apiBookingAppointmentsList = (body) => {
    /*
        body = {
            service_id: ""
            date: "",
            filter: {
                room_name: "",
                customer_phone: "",
                search: ""
            }
        }
    */
    const url = '/bookings/appointments/list';
    return AxiosClient.post(url, body);
}
/***************************** */

//appointment
export const apiBookingAppointmentsCreate = (body) => {
    /*
        body = {
            room_id: "",    rq
            service_id: "", rq
            start_date: "", ex:2020-02-10
            end_date: "",
            start_time: "" ex: 05:00,   rq
            end_time: "",   rq
            customer_fullname: "",  rq
            customer_phone: "", rq
            customer_email: "",
            note: ""
        }   
    */
    const url = '/bookings/appointments/create';
    return AxiosClient.post(url, body);
}
export const apiBookingAppointmentsHistory = (body) => {
    /*
        option
        body = {
            service_name: "",
            room_name: "",
            customer_phone: "",
            status: "",
            booking_date: ""
        }
    */
    const url = '/bookings/appointments/history';
    return AxiosClient.post(url, body);
}
export const apiBookingAppointmentsUpdate = (body) => {
    /*
        body = {
            same create, put field need to update
        }   
    */
    const url = '/bookings/appointments/update';
    return AxiosClient.put(url, body);
}
export const apiBookingAppointmentsCancel = (id) => {
    const body = {
        id,
        status: "canceled",
    }
    const url = '/bookings/appointments/update';
    return AxiosClient.put(url, body);
}
export const apiBookingAppointmentsDelete = (body) => {
    /*
        body = {
            id | ids: []
        }
    */
    const url = '/bookings/appointments/delete';
    return AxiosClient.delete(url, { data: body });
}
/***************************** */
