import LinearProgress from '@mui/material/LinearProgress';
import { GridOverlay } from '@material-ui/data-grid';

export const CustomLoading = () => {
    return (
        <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress />
            </div>
        </GridOverlay>
    )
}