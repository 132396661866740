import React, { useState, useEffect, useRef } from 'react';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import Button from '@material-ui/core/Button/Button';
import { GridOverlay, DataGrid } from '@material-ui/data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import ErrorAlert from '../alert/ErrorAlert';
import SuccessAlert from '../alert/SuccessAlert';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import WarningDetail from './WarningDetail';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Cookies from 'js-cookie';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton/IconButton';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import moment from 'moment-timezone';
import { apiPeopleCoutingWarning, apiPeopleCoutingWarningOffsetLimit, apiPeopleWarningDelete, apiPeopleWarningUpdate } from '../../api/people';
import { apiGetconfig } from '../../api/getconfig';
import { apiUpdateconfig } from '../../api/updateconfig';
import LargeTooltip from '../largeTooltip';

const useStyles = makeStyles((theme) => ({
    primaryTitle: theme.primary_title,
    table: {
        width: '100%',
        height: '690px',
        background: '#FFFFFF',
        paddingBottom: '55px',
        borderRadius: '6px',
        position: 'relative'
    },
    root: theme.dataGridStyle,
    icon: {
        '& svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall': {
            color: '#2DCE8', fontWeight: 'bold'
        }
    },
    tableTitle: {
        position: 'relative',
        height: '48px',
        border: '1px solid rgba(224, 224, 224, 1)',
        borderBottom: 'none',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px'
    },
    focus: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: theme.themeColor,
            },
        },
    },
    topBlock: {
        marginTop: '22px',
        marginBottom: '20px',
        height: '40px',
        width: '100%'
    },
    header: {
        width: '100%',
        height: '56px',
        background: '#FFFFFF',
        borderBottom: '1px solid #E0E0E0',
        position: 'relative',
        borderRadius: '6px 6px 0 0'
    },
    dialogTitle: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '25px',
        color: '#FFFFFF',
    },
    contentDialog: {
        fontSize: '16px!important',
        height: '68px',
        borderBottom: '1px solid #EEEDF2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    dialogTitleBackground: theme.primaryColor,
    selectInput: {
        '& .MuiInput-underline:before': { display: 'none' },
        '& .MuiInput-underline:after': { display: 'none' }
    },
}))

const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);
const DeleteButton = withStyles(theme => ({ root: theme.delete_button }))(Button);
const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);
const TextButton = withStyles(theme => ({ root: theme.moreDetail }))(Button);

const WarningTable = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [warnings, setWarnings] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsTotal, setRowsTotal] = useState(0);
    const [pageTotal, setPageTotal] = useState(0);
    const [filter, setFilter] = useState('');
    const [alert, setAlert] = useState({ success: '', error: '' });
    const [selectedWarnings, setSelectedWarnings] = useState([]);
    const [selectedId, setSelectedId] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [isZoomImage, setIsZoomImage] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [loading, setLoading] = useState({ page: false, table: false });
    const [files, setFiles] = useState([]);
    const [warningConfig, setWarningConfig] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const filterRef = useRef();
    const alertRef = useRef();
    const zoomRef = useState();

    const columns = [
        {
            headerName: t("Cảnh báo/Lỗi"),
            field: 'Description',
            flex: 1,
            cellClassName: 'super-app-theme--cell',
        },
        {
            headerName: t("Độ ưu tiên"),
            field: 'Priority',
            width: 130,
            cellClassName: 'super-app-theme--cell',
            renderCell: params => showPriority(params.row.Priority)
        },
        {
            headerName: t("Resolution"),
            field: 'Resolution',
            flex: 0.8,
            cellClassName: 'super-app-theme--cell',
            renderCell: params =>
                <LargeTooltip title={showResolution(params.row.Resolution)} placement="bottom-start">
                    <p style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{showResolution(params.row.Resolution)}</p>
                </LargeTooltip>
        },
        {
            headerName: t("Giải quyết bởi"),
            field: 'ResolvedBy',
            flex: 0.8,
            cellClassName: 'super-app-theme--cell',
        },
        {
            headerName: t("Thời gian"),
            field: 'CreatedTime',
            flex: 0.8,
            cellClassName: 'super-app-theme--cell',
            renderCell: params => moment(params.row.CreatedTime).format('DD-MM-YYYY HH:mm:ss')
        },
        {
            headerName: t("Hình ảnh"),
            field: 'ImageUrl',
            width: 120,
            sortable: false,
            cellClassName: 'super-app-theme--cell',
            renderCell: params => showImage(params.row.ImageUrl)
        },
        {
            headerName: t("Thao tác"),
            field: 'method',
            width: 130,
            sortable: false,
            cellClassName: 'super-app-theme--cell',
            renderCell: params => <TextButton onClick={() => onEditClick(params.row.id)}>{t("Chỉnh sửa")}</TextButton>
        },

    ]

    const showPriority = (priority) => {
        switch (priority) {
            case 'normal': return t('Bình thường');
            default: return '';
        }
    }

    const showImage = (thumbnail) => {
        if (thumbnail)
            return (
                <LargeTooltip title={t('Xem')} >
                    <img
                        src={thumbnail}
                        style={{ objectFit: 'cover', cursor: 'pointer', borderRadius: '4px' }}
                        alt="" width='40px' height='40px'
                        onClick={() => {
                            setIsZoomImage(true);
                            setSelectedImage(thumbnail);
                        }}
                    />
                </LargeTooltip>
            )
    }

    const showResolution = (resolution) => {
        switch (resolution) {
            case '':
                return <i>{t('Chưa xử lý')}</i>
            case undefined:
                return <i>{t('Chưa xử lý')}</i>
            default:
                return resolution;
        }
    }

    const onFilterChange = (e) => {
        if (filterRef.current) clearTimeout(filterRef.current);
        filterRef.current = setTimeout(() => {
            setFilter(e.target.value);
        }, 500);
    }

    const getDefaultValues = async () => {
        try {
            const offset = rowsPerPage * (currentPage - 1);
            let res = await apiPeopleCoutingWarningOffsetLimit(offset, rowsPerPage)
            setRowsTotal(res.data.Count);
            if (res.data.Count > rowsPerPage && res.data.Count % rowsPerPage === 0) setPageTotal(res.data.Count / rowsPerPage);
            else if (res.data.Count > rowsPerPage && res.data.Count % rowsPerPage !== 0) setPageTotal(parseInt(res.data.Count / rowsPerPage) + 1);
            else setPageTotal(1);
            const data = [];
            res.data.Items.forEach(item => {
                data.push({ ...item, id: item.ID });
            })
            setWarnings(data);
        } catch (error) {

        }
        setLoading({ page: true, table: true });
    }

    const getWarningConfig = async () => {
        try {
            var res = await apiGetconfig();
            setWarningConfig(res.data.Items[0] ?.Configuration);
        } catch (error) {

        }
    }

    useEffect(() => {
        if (!loading.page || !loading.table) {
            getWarningConfig();
            getDefaultValues();
        }
    }, [loading.page, loading.table])

    useEffect(() => {
        setCurrentPage(1);
        setLoading({ ...loading, table: false });
    }, [rowsPerPage])

    const onBackClick = () => {
        setIsEdit(false);
    }

    const onEditClick = (id) => {
        setIsEdit(true);
        setSelectedId(id)
    }

    const onSelectedChange = (e) => {
        setSelectedWarnings(e);
        var selectedImages = [];
        e.forEach(item => {
            const res = warnings.filter(value => value.ID === item);
            selectedImages.push(res[0].ImageUrl);
        })
        setFiles(selectedImages);
    }

    const onDeleteConfirmation = async (e) => {
        try {
            setIsDeleting(true);
            await apiPeopleWarningDelete({ ids: selectedWarnings, files: files });
            setAlert({ ...alert, success: t('Xóa thành công!') });
            setLoading({ ...loading, table: false });
        } catch (error) {
            setAlert({ ...alert, error: t('Xóa không thành công, vui lòng thử lại sau!') });
        }
        setDeleteConfirmation(false);
        setIsDeleting(false);
    }

    const onUpdateClick = async (data) => {
        try {
            await apiPeopleWarningUpdate(data)
            setAlert({ ...alert, success: t('Lưu thành công!') });
            setIsEdit(false);
        } catch (error) {
            setAlert({ ...alert, error: t('Lưu không thành công, vui lòng thử lại sau!') });
        }
        setLoading({ ...loading, table: false });
    }

    const turnOnNotification = async () => {
        try {
            setIsUpdating(true);
            const data = {
                username: 'tsmart-building',
                configuration: { ...warningConfig, warning: true }
            }
            await apiUpdateconfig(data);
            setAlert({ ...alert, success: t('Thông báo đã được bật!') });
        } catch (error) {
            setAlert({ ...alert, error: t('Bật thông báo không thành công, vui lòng thử lại sau!') });
        }
        setIsUpdating(false);
        setLoading({ ...loading, page: false });
    }

    const turnOffNotification = async () => {
        try {
            setIsUpdating(true);
            const data = {
                username: 'tsmart-building',
                configuration: { ...warningConfig, warning: false }
            }
            await apiUpdateconfig(data);
            setAlert({ ...alert, success: t('Thông báo đã được tắt!') });
        } catch (error) {
            setAlert({ ...alert, error: t('Tắt thông báo không thành công, vui lòng thử lại sau!') });
        }
        setIsUpdating(false);
        setLoading({ ...loading, page: false });
    }

    const onAlertClose = () => {
        setAlert({ ...alert, success: '', error: '' });
    }

    useEffect(() => {
        if (alert.success !== '' || alert.error !== '') {
            if (alertRef.current) clearTimeout(alertRef.current);
            alertRef.current = setTimeout(() => {
                onAlertClose();
            }, 5000);
        }
    }, [alert])

    useEffect(() => {
        const handleClickOutside = (e => {
            if (zoomRef.current && !zoomRef.current.contains(e.target))
                setIsZoomImage(false);
        })
        document.addEventListener('mousedown', handleClickOutside);
    }, [zoomRef])

    const zoomImage = () => {
        return (
            <Dialog
                open={isZoomImage}
                maxWidth="xl"
            >
                <DialogContent ref={zoomRef} style={{ padding: '0' }}>
                    <img src={selectedImage} alt="" style={{ height: '500px' }} />
                </DialogContent>
            </Dialog>
        )
    }

    const deleteConfirmationDialog = () => {
        return (
            <Dialog
                open={deleteConfirmation}
                maxWidth="xl"
            >
                <DialogTitle className={classes.dialogTitleBackground}>
                    <p className={classes.dialogTitle}>{t("Thông báo")}</p>
                </DialogTitle>
                <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center', position: 'relative' }}>
                    <div className={classes.contentDialog}>
                        <p >{t("Bạn có chắc chắn muốn xóa cảnh báo/lỗi?")}</p>
                    </div>
                    <CancelButton
                        style={{ margin: '10px 0' }}
                        onClick={() => {
                            setDeleteConfirmation(!deleteConfirmation);
                        }}
                        disabled={isDeleting}
                    >
                        {t("Trở về")}
                    </CancelButton>
                    <CreateButton
                        style={{ margin: '10px 0 10px 24px' }}
                        onClick={onDeleteConfirmation}
                        disabled={isDeleting}
                    >
                        {t("Đồng ý")}
                    </CreateButton>
                    {isDeleting && <CircularProgress color="primary" style={{ position: 'absolute', left: '45%', top: '10%', color: '#0b59c8' }} />}
                </DialogContent>
            </Dialog>
        )
    }

    const EmptyRowsView = () => {
        return (
            <div
                style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <h3 style={{ fontSize: '16px' }}>
                    {t('Không tìm thấy cảnh báo/lỗi')}
                </h3>
            </div>
        );
    };

    const customLoading = () => {
        return (
            <GridOverlay>
                <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                    <LinearProgress />
                </div>
            </GridOverlay>
        )
    }

    return (
        <div style={{ width: '98%', height: '80%', margin: 'auto', position: 'relative' }}>
            {zoomImage()}
            {deleteConfirmationDialog()}
            <SuccessAlert message={alert.success} onCloseDialog={onAlertClose} />
            <ErrorAlert message={alert.error} onCloseDialog={onAlertClose} />
            {isEdit && <WarningDetail onBackClick={onBackClick} onUpdateClick={onUpdateClick} id={selectedId} />}
            <div>
                <div className={clsx(classes.focus, classes.topBlock)}>
                    <p className={classes.primaryTitle}>{t("Cảnh báo/Lỗi")}</p>
                    {/* {
                    <TextField
                        placeholder={t("Tìm kiếm")}
                        variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            style:{background:'#FFFFFF',height:'40px',width:'320px'}
                        }}
                        onChange={onFilterChange}
                    />} */}
                </div>
                <div style={{ width: '100%', height: '100%' }}>
                    <div className={clsx(classes.table, classes.root)} style={{ width: '100%' }}>
                        <div className={classes.header}>
                            <DeleteButton
                                onClick={() => setDeleteConfirmation(true)}
                                variant='outlined'
                                style={{ margin: '8px' }}
                                startIcon={<DeleteOutlineOutlinedIcon />}
                                disabled={selectedWarnings.length === 0}
                            >
                                {t('Xóa')}
                            </DeleteButton>
                            <CreateButton
                                variant="contained"
                                style={{ margin: '0px' }}
                                onClick={() => setLoading({ ...loading, table: false })}
                            >
                                {t('Làm mới')}
                            </CreateButton>
                            {warningConfig !== null && !warningConfig.warning && <CreateButton
                                variant="contained"
                                style={{ margin: '8px', float: 'right' }}
                                disabled={isUpdating}
                                onClick={turnOnNotification}
                            >
                                {t('Bật thông báo')}
                                {isUpdating && <CircularProgress color="primary" size={25} style={{ position: 'absolute', color: '#0b59c8' }} />}
                            </CreateButton>}
                            {warningConfig !== null && warningConfig.warning && <CancelButton
                                variant="outlined"
                                style={{ margin: '8px', float: 'right' }}
                                disabled={isUpdating}
                                onClick={turnOffNotification}
                            >
                                {t('Tắt thông báo')}
                                {isUpdating && <CircularProgress color="primary" size={25} style={{ position: 'absolute', color: '#0b59c8' }} />}
                            </CancelButton>}
                        </div>
                        <DataGrid
                            componentsProps={{
                                pagination: {
                                    labelRowsPerPage: t("Số dòng mỗi trang"),
                                    labelDisplayedRows: ({ from, to, count }) => { return `${from}-${to} ${t('trong số')} ${count}` }
                                }
                            }}
                            components={{
                                NoRowsOverlay: EmptyRowsView,
                                LoadingOverlay: customLoading,
                            }}
                            localeText={{
                                footerRowSelected: (count) => count + t(" hàng trong bảng đã được chọn"),
                                columnHeaderSortIconLabel: t('Sắp xếp')
                            }}
                            rows={warnings}
                            columns={columns}
                            pageSize={rowsPerPage}
                            checkboxSelection
                            rowsPerPageOptions={[10, 50, 100]}
                            onPageSizeChange={pageSize => { setRowsPerPage(pageSize); }}
                            onSelectionModelChange={onSelectedChange}
                            hideFooterRowCount
                            disableSelectionOnClick
                            disableColumnMenu={true}
                            hideFooterPagination
                            loading={!loading.table}
                        />
                        <div style={{ position: 'absolute', bottom: '3px', right: '5px', display: 'flex' }} className={classes.selectInput}>
                            <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0 20px' }} >{t("Số dòng mỗi trang")}</p>
                            <Select
                                value={rowsPerPage}
                                onChange={(e) => { setRowsPerPage(e.target.value) }}
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                            <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0 20px' }} >
                                {rowsTotal === 0 ? 0 : (currentPage * rowsPerPage) - rowsPerPage + 1}-{currentPage * rowsPerPage > rowsTotal ? rowsTotal : currentPage * rowsPerPage} {t("trong số")} {rowsTotal}
                            </p>
                            <IconButton
                                disabled={currentPage === 1}
                                onClick={() => {
                                    setCurrentPage(currentPage - 1);
                                    setLoading({ ...loading, table: false });
                                }}
                            >
                                <KeyboardArrowLeftIcon />
                            </IconButton>
                            <IconButton
                                disabled={currentPage === pageTotal}
                                onClick={() => {
                                    setCurrentPage(currentPage + 1);
                                    setLoading({ ...loading, table: false });
                                }}
                            >
                                <KeyboardArrowRightIcon />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ height: '25px' }}></div>
        </div>
    )
}

export default WarningTable;