import makeStyles from '@material-ui/styles/makeStyles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    containBar: {
        display: 'flex',
        justifyContent: 'center'
    },
    bar: {
        display: 'flex',
        maxWidth: '100%',
        overflowX: 'auto'
    },
    barItem: {
        marginRight: '20px',

        '&:last-child': {
            marginRight: 0
        }
    },
    barItemLabel: {
        fontWeight: '700',
        marginTop: '14px',
        textAlign: 'center',
        marginBottom: '24px'
    }
}));