import makeStyles from '@material-ui/styles/makeStyles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    details: {

    },
    detailsHeader: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "1.5rem"
    },
    wrapTitle: {
        display: "flex",
        alignItems: "center",
    },
    wrapTitleIcon: {
        marginRight: "0.5rem",
        color: "#0b59c8",
        cursor: "pointer"
    },
    wrapLoading: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        height: "calc(100vh - 200px)"
    },
    openCell: {
        padding: "1.5rem",
        minWidth: "500px"
    },
    openCellTitle: {
        margin: "0 0 1rem 0",
        paddingBottom: "1rem",
        borderBottom: "1px solid #E0E0E0",
        fontSize: '20px',
        fontWeight: '600'
    },
    openCellAction: {
        display: "flex",
        justifyContent: "flex-end"
    },
    openCellHeading: {
        fontWeight: '600',
        marginBottom: '16px'
    },
    openCellRow: {
        display: 'flex'
    },
    openCellColumn: {
        width: '50%'
    },
    openCellLabel: {
        marginBottom: '8px',
        color: '#616161',
    },
    openCellValue: {
        color: '#000000',
        marginBottom: '16px'
    },
    openCellRadio: {
        '& span': {
            '&:first-child': {
                padding: '0 9px'
            }
        }
    },
    wrapConfirmLoading: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '8px'
    }
}));