import React, {useState, useEffect,useRef} from 'react';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import {useTranslation} from 'react-i18next';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import Button from '@material-ui/core/Button/Button';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DataGrid } from '@material-ui/data-grid';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import {Link,useHistory} from 'react-router-dom';
import axiosClient from '../../../config/AxiosClient';
import EditConfig from './EditConfig';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import SuccessAlert from '../../alert/SuccessAlert';
import ErrorAlert from '../../alert/ErrorAlert';
import LargeTooltip from '../../largeTooltip';

const useStyles = makeStyles((theme)=>({
    inputBlock:{height:'110px',width:'20%',margin:'auto',position:'relative',float:'left'},
    label:{margin:'0',fontSize: '14px',lineHeight: '140%',color: '#7A7171',display: 'inline-block'},
    input:{width: '100%'},
    placeholder:{color:'#B2B2B2',fontSize:'12px'},
    error:{color:'red',position:'absolute',bottom:'0',left:'20px'},
    alert:{position:'absolute', left:'3px', top:'3px',width:'99%'},
    dialogTitle:{textAlign:'center',fontSize:'20px',fontWeight:'600',lineHeight:'25px',color:'#FFFFFF'},
    contentDialog:{fontSize:'16px',height:'68px',borderBottom:'1px solid #EEEDF2',display:'flex',alignItems:'center',justifyContent:'center'},
    dialogTitleBackground:theme.primaryColor,
    errorColor:theme.errorColor,
    overlay:{position:'fixed',width:'100%',height:'100%',top:'0',left:'0',background:'rgba(0,0,0,0.1)',zIndex:'1300'},
    requestError:{width:'100%',height:'65vh',display:'flex',justifyContent:'center',alignItems:'center',fontSize:'20px',color:'#6D6D6D'},
    root:theme.dataGridStyle,
    tableTitle:{position:'relative',height:'56px',border:'1px solid rgba(224, 224, 224, 1)',borderTopLeftRadius:'6px',borderTopRightRadius:'6px'},
    alertStyle:{position:'absolute',right:'9px',bottom:'8px',padding:'0',zIndex:'10'},
    selectTitle:{fontSize:'16px',lineHeight:'22.4px',position:'absolute',bottom:'8px',right:'8px'},
}))

const CreateButton = withStyles(theme =>({root:theme.create_button}))(Button);
const CancelButton = withStyles(theme =>({root:theme.cancel_button}))(Button);
const DeleteButton = withStyles(theme =>({root:theme.delete_button}))(Button);
const TextButton = withStyles(theme =>({root:theme.moreDetail}))(Button);

const Configurations = ()=>{
    const classes = useStyles();
    const {t} = useTranslation();
    const [alert,setAlert] = useState({success:'',error:'',request:''});
    const [fileNames,setFileNames] = useState([]);
    const [configs,setConfigs] = useState([]);
    const [rowsPerPage,setRowsPerPage] = useState(parseInt(localStorage.getItem('rowsPerPage')));
    const [isDelete,setIsDelete] = useState(false);
    const [isDeleting,setIsDeleting] = useState(false);
    const [loading,setLoading] = useState(false);
    const [isEdit,setIsEdit] = useState(false);
    const [profileName,setProfileName] = useState(false);
    const alertRef = useRef();
    const [isExpiredSession,setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const columns = [
        {
            field:'ProfileName',
            headerName:t('Tên cấu hình'),
            cellClassName:'super-app-theme--cell',
            flex:0.5
        }, 
        {
            field:'Block',
            headerName:t('Block'),
            cellClassName:'super-app-theme--cell',
            flex:0.4
        },
        {
            field:'Description',
            headerName:t('Mô tả'),
            cellClassName:'super-app-theme--cell',
            flex:0.7,
            renderCell: params => <LargeTooltip title={t(params.row.Description)} placement="bottom-start"><p style={{textOverflow: 'ellipsis',overflow:'hidden'}}>{t(params.row.Description)}</p></LargeTooltip>
        },
        {
            field:'Method',
            headerName:t('Thao tác'),
            cellClassName:'super-app-theme--cell',
            flex:0.4,
            sortable:false,
            renderCell: params => <TextButton onClick={()=>onEditClick(params.row.ProfileName)} >{t('Xem chi tiết')}</TextButton>
        },
    ]
    
    useEffect(()=>{
        if(isExpiredSession){
          history.push('/login');
          dispatch({type:'IS_LOGIN_AGAIN_TRUE'});
        } 
    },[isExpiredSession]);

    const getDefaultValues = async() =>{
        try {
            const res = await axiosClient.get('/profile/list',{headers:{'Authorization': `Bearer ${Cookies.get('token')}`}});
            const data = [];
            res.data.Items.forEach((value,key)=>{
                data.push({...value,id:value.ProfileName,Block:value.Payload.deviceBlock,Descriptoion:value.Descriptoion});
            })
            setConfigs(data);
            setFileNames([]);
            setAlert({...alert,request:''});
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({...alert,request:t('Không lấy được dữ liệu')});
        }
        setLoading(true);
    }
    useEffect(()=>{
        if(!loading) getDefaultValues();
    },[loading])

    const onEditClick = (profile_name) =>{
        setProfileName(profile_name);
        setIsEdit(!isEdit);
    }
    const onEditStatusChange = ()=>{
        setIsEdit(!isEdit);
        setLoading(false);
    }
    const onSelectedChange =(e)=>{
        setFileNames(e);
    }
    const onDeleteClick = ()=>{
        setIsDelete(true);
    }
    const onConfirmDeleteClick = async() =>{
        try {
            setIsDeleting(true);
            await axiosClient.post('/profile/delete',{'profiles':fileNames},{
                headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
            })
            setAlert({...alert,success:t('Xóa thành công!'),error:''});
            setLoading(false);
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({...alert,error:t('Xóa không thành công, vui lòng thử lại!'),success:''});
        }
        setIsDelete(false);
        setIsDeleting(false);
    }
    const onCloseDialog = ()=>{
        setAlert({...alert,success:'',error:''});
    }

    useEffect(()=>{
        if(alert.success !== '' || alert.error !== ''){
            if(alertRef.current) clearTimeout(alertRef.current);
            alertRef.current = setTimeout(() => {
                onCloseDialog();
            }, 5000);
        }
    },[alert])

    const ConfirmDeleteDialog = () =>{
        return(
          <Dialog 
            open={isDelete}
            maxWidth="xl"
          >
            <DialogTitle className={classes.dialogTitleBackground}>
                <p className={classes.dialogTitle}>{t('Thông báo')}</p>
            </DialogTitle>
            <DialogContent style={{width:'448px',padding:'0',textAlign:'center',position:'relative'}}>
                <p className={classes.contentDialog}>{t('Bạn có chắc chắn muốn xóa?')}</p>
                <CancelButton 
                  style={{marginLeft:'30px'}}
                  onClick={()=>setIsDelete(false)}
                  disabled={isDeleting} 
                >
                  {t('Trở về')}
                </CancelButton>
                <CreateButton 
                  style={{margin:'10px 20px'}}
                  onClick={onConfirmDeleteClick} 
                  disabled={isDeleting}
                >
                  {t('Đồng ý')}
                </CreateButton>
                {isDeleting && <CircularProgress style={{position:'absolute',top:'10%',left:'45%',color:'#0b59c8'}}/>}
            </DialogContent>
          </Dialog>
        )
    }

    const EmptyRowsView = () => {
        return (
          <div
            style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}
          >
            <h3 style={{color:'#6D6D6D',fontSize: '16px'}}>
              {t('Không tìm thấy cấu hình thiết bị')}
            </h3>
          </div>
        );
    };
    return(
        <div style={{height:'100%',}}>
            {ConfirmDeleteDialog()}     
            {!isEdit && <p style={{fontSize:'22px',fontWeight:'bold',padding:'23px 0 10px 16px'}}>{t('Danh sách hồ sơ cấu hình')}</p>}  
            {alert.request !== '' && <div className={classes.requestError}>{alert.request}</div>}
            {!loading && alert.request === '' && <div style={{width:'100%',height:'250px',display:'flex',justifyContent:'center',alignItems:'center'}}><CircularProgress style={{color:'#0b59c8'}}/></div>}
            {loading && !isEdit && alert.request === '' &&
            <div style={{marginTop:'20px',width:'97%',margin:'auto',clear:'both',height:'77%'}}>
                  
                <div className={classes.tableTitle} > 
                    <SuccessAlert message={alert.success} onCloseDialog={onCloseDialog} />
                    <ErrorAlert message={alert.error} onCloseDialog={onCloseDialog} />
                    <CreateButton
                        startIcon={<AddCircleOutlineOutlinedIcon/>}
                        variant="contained"
                        style={{margin:'8px'}}
                        component={Link}
                        to='/admin/device-management/add-device-configuration'
                    >
                        {t("Thêm")}
                    </CreateButton>   
                    <LargeTooltip title={t("Xóa")}>
                        <span>
                            <DeleteButton
                                    variant="outlined"
                                    startIcon={<DeleteOutlineOutlinedIcon />}
                                    onClick={onDeleteClick}             
                                    disabled={fileNames.length === 0}
                                >
                                    {t("Xóa")}
                            </DeleteButton>
                        </span>
                    </LargeTooltip>
                           
                </div>
                <div style={{height:'100%', width: '100%',paddingBottom:'15px'}} className={classes.root}>       
                    <DataGrid 
                        componentsProps={{
                            pagination: {
                                labelRowsPerPage: t("Số dòng mỗi trang"),
                                labelDisplayedRows:({from,to,count}) => {return `${from}-${to} ${t('trong số')} ${count}`}
                            }
                        }}
                        components={{
                            NoRowsOverlay:EmptyRowsView
                        }}  
                        localeText={{
                            footerRowSelected: (count) => count +t(" hàng trong bảng đã được chọn"),
                            columnHeaderSortIconLabel: t('Sắp xếp')
                        }}
                        rows={configs} 
                        columns={columns} 
                        pageSize={rowsPerPage}
                        rowHeight={45}
                        checkboxSelection 
                        rowsPerPageOptions={[10,50,100]}
                        onPageSizeChange={pageSize=>{
                            setRowsPerPage(pageSize);
                            localStorage.setItem('rowsPerPage',pageSize);
                        }}
                        onSelectionModelChange={onSelectedChange}
                        hideFooterRowCount
                        disableSelectionOnClick
                        disableColumnMenu={true}
                    />
                </div>
            </div>}
            {loading && isEdit && <EditConfig onEditStatusChange={onEditStatusChange} profileName={profileName} configs={configs}/>}
        </div>
    )
}

export default Configurations;