import makeStyles from '@material-ui/styles/makeStyles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    details: {
        
    },
    detailsHeader: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "1.5rem",
        marginTop: "4px",
    },
    wrapTitle: {
        display: "flex",
        alignItems: "center",
    },
    wrapTitleIcon: {
        marginRight: "0.5rem",
        color: "#0b59c8",
        cursor: "pointer"
    },
    detailsContain: {

    }
}));