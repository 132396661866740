import React,{useState,useRef,useEffect} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import {useTranslation} from 'react-i18next';
import IconButton from '@material-ui/core/IconButton/IconButton';
import PasswordStrengthBar from 'react-password-strength-bar';
import {checkDisplayName, isEmpty, checkPassword } from '../../../validation/CheckForm';
import zxcvbn from 'zxcvbn';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InputAdornment from '@material-ui/core/InputAdornment';
import axiosClient from '../../../config/AxiosClient';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import {useHistory } from 'react-router-dom';
import base64 from 'base-64';
import md5 from 'md5';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme)=>({
    inputBlock:{height:'110px',width:'24%',margin:'auto',position:'relative',float:'left'},
    label:{margin:'0',fontSize: '14px',lineHeight: '140%',color: '#000000',display: 'inline-block'},
    input:{width: '100%'},
    placeholder:{color:'#B2B2B2',fontSize:'12px'},
    error:{color:'red',position:'absolute',bottom:'0',left:'20px'},
    alert:{position:'absolute', left:'3px', top:'3px',width:'99%'},
    requestError:{width:'100%',height:'65vh',display:'flex',justifyContent:'center',alignItems:'center',fontSize:'20px',color:'#6D6D6D'},
    focus:{
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': { 
                borderColor: theme.themeColor,
            },
        },
    },
}))

const CreateButton = withStyles(theme =>({root:theme.create_button}))(Button);
const CancelButton = withStyles(theme =>({root:theme.cancel_button}))(Button);

const EditAccount = props =>{
    const classes = useStyles();
    const {onChangeEditStatus,username,onEditlick,isAdding} = props;
    const {t} = useTranslation();
    const [account,setAccount] = useState({name:'',username:'',password:''});
    const [alert,setAlert] = useState({name:'',username:'',password:'',request:''});
    const [showPassword,setShowPassword] = useState(false);
    const [newPassword,setNewPassword] = useState('');
    const [loading,setLoading] = useState(false);
    const [isExpiredSession,setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const passwordRef = useRef();

    useEffect(()=>{
        if(isExpiredSession){
          history.push('/login');
          dispatch({type:'IS_LOGIN_AGAIN_TRUE'});
        } 
    },[isExpiredSession]);

    const getDefaultValues = async()=>{
        try {
            const res = await axiosClient.post('/manager/get',{'username':username},{
                headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
            })
            setAccount({name:res.data.Items[0].DisplayName,username:res.data.Items[0].Username,password:res.data.Items[0].Password})
            setNewPassword('');
            setAlert({...alert,request:t('')});
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({...alert,request:t('Không lấy được dữ liệu')});
        }
        setLoading(true);
    }

    useEffect(()=>{
        if(!loading) getDefaultValues();
    },[loading])
    useEffect(()=>{
        setLoading(false);
    },[username])
    const onNameChange = (e)=>{
        const name = e.target.value;
        setAccount({...account,name:name});
        if(isEmpty(name)){
            setAlert({...alert,name:t('Không được để trống')});
        }else if(!checkDisplayName(name)){
            setAlert({...alert,name:t('Không được có ký tự đặc biệt')});
        }else if (name.trim().length < 6 || name.trim().length > 30){
            setAlert({...alert,name:t('Độ dài ký tự trong khoảng từ 6 đến 30')});
        }else{
            setAlert({...alert,name:''});
        }
    }

    const onPasswordChange = (e) =>{
        const password = e.target.value;
        setAccount({...account,password:password});
        setNewPassword(password);
        if(isEmpty(password)){
            setAlert({...alert,password:t('Không được để trống')});
        }else if(!checkPassword(password)){
            setAlert({...alert,password:t('Tối thiểu 8 ký tự bao gồm chữ HOA, chữ thường, số và ký tự đặc biệt')});
        }else{
            setAlert({...alert,password:''});
            if(passwordRef.current) clearTimeout(passwordRef.current);
            passwordRef.current = setTimeout(() => {
                const result = zxcvbn(password);
                if(result.feedback.warning !== '') setAlert({...alert,password:t('Đã trùng mật khẩu phổ biến')});
            }, 300);
        }
    }

    const onEditClick = () =>{
        if(isEmpty(account.name)) return setAlert({...alert,name:t('Không được để trống')});
        if(alert.name !== '') return;
        if(isEmpty(account.password)) return setAlert({...alert,password:t('Không được để trống')});
        if(alert.password !== '') return;
        var data = {};
        if(newPassword !== '') data = {...account,authen:base64.encode(account.username+':'+account.password),password:md5(account.password)}
        else data = account;
        onEditlick(data);
    }
    return (
        <div className={classes.focus}>
            <Paper style={{fontSize:'24px',height:'122px',width:'97%',margin:'auto',marginTop:'5px',background:'#FAFAFA'}}>
                {alert.request!=='' && <div className={classes.requestError}>{alert.request}</div>}
                {!loading && alert.request==='' && <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}><CircularProgress style={{color:'#0b59c8'}}/></div>}
                {loading && alert.request==='' &&<div>
                    <div className={classes.inputBlock} style={{marginLeft:'2%'}}>
                        <label className={classes.label}>{t('Tên hiển thị')} <span style={{color:'red'}}>(*)</span></label><br />
                        <TextField 
                            classes={{
                                root:classes.input
                            }}
                            style={{background:'#FFFFFF'}}
                            size="small"
                            value={account.name}
                            placeholder={t("Tên hiển thị")} 
                            variant="outlined" 
                            onChange={onNameChange}
                        />
                        <p style={{color:'red',fontSize:'13px',paddingTop:'4px'}}>{alert.name}</p>
                    </div>
                    <div className={classes.inputBlock} style={{marginLeft:'1%'}}>
                        <label className={classes.label}>{t('Tên đăng nhập')} <span style={{color:'red'}}>(*)</span></label><br />
                        <TextField 
                            classes={{
                                root:classes.input
                            }}
                            disabled={true}
                            style={{background:'#FFFFFF'}}
                            size="small"
                            value={account.username}
                            placeholder={t("Tên đăng nhập")} 
                            variant="outlined" 
                        />
                    </div>
                    <div className={classes.inputBlock} style={{marginLeft:'1%'}}>
                        <label className={classes.label}>{t('Mật khẩu')} <span style={{color:'red'}}>(*)</span></label><br />
                        <TextField 
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {newPassword !== '' &&<IconButton
                                            aria-label="toggle password visibility"
                                            onClick={()=>setShowPassword(!showPassword)}
                                        >
                                        {showPassword ? <Visibility fontSize="small"/> : <VisibilityOff fontSize="small"/>}
                                        </IconButton>}
                                    </InputAdornment>
                                ),
                            }}
                            classes={{
                                root:classes.input
                            }}
                            style={{background:'#FFFFFF'}}
                            size="small"
                            value={account.password}
                            placeholder={t("Mật khẩu")}
                            type={showPassword?"text":"password"} 
                            variant="outlined" 
                            onChange={onPasswordChange}
                        />
                        {newPassword !=='' && 
                        <PasswordStrengthBar 
                            scoreWords={['','','','']} 
                            shortScoreWord='' 
                            password={account.password} 
                        />}
                        <p style={{color:'red',fontSize:'13px',paddingTop:'0px',width:'200%'}}>{alert.password}</p>
                    </div>            
                    <div className={classes.inputBlock} style={{minWidth:'220px',width:'20%'}}>       
                        <CreateButton 
                            variant="contained" 
                            onClick={onEditClick} 
                            style={{margin:'0',marginTop:'34px',marginLeft:'16px'}}
                            disabled={isAdding}
                        >
                            {t('Lưu')}
                            {isAdding && <CircularProgress size={25} style={{position:'absolute',color:'#0b59c8'}}/>}
                        </CreateButton>
                        <CancelButton 
                            style={{marginLeft:'16px',marginTop:'34px'}}
                            variant="outlined" 
                            onClick={onChangeEditStatus}
                            disabled={isAdding}
                        >
                            {t('Hủy')}         
                        </CancelButton>
                    </div>
                </div>}
            </Paper>
        </div>
    )
}

export default EditAccount;
