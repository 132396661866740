import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from '@aws-amplify/core';

const useStyles = makeStyles((theme) => ({
    snackbar: {
        background: 'rgb(237, 247, 237) !important', 
        color: 'rgb(42, 185, 48) !important'
    },
    title: {
        fontWeight: 'bold',
        fontSize: '16px',
        paddingBottom: '5px'
    }
}));

const SuccessAlert = React.memo((props) => {
    const { message, onCloseDialog, ms = 5000 } = props;

    const deBounceRef = useRef();

    const { t } = useTranslation();
    const classes = useStyles();

    useEffect(() => {
        deBounceRef.current = setTimeout(() => {
            if(!isEmpty(message)){
                onCloseDialog();
            }
        }, ms);

        return () => {
            if (deBounceRef.current) {
                clearTimeout(deBounceRef.current);
            }
        }
    }, [ms, message]);

    return (
        <Snackbar
            open={message !== ''}
            TransitionComponent={Slide}
        >
            <SnackbarContent
                className={classes.snackbar}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={onCloseDialog}
                    >
                        <CloseIcon />
                    </IconButton>
                }
                message={
                    <div>
                        <p className={classes.title}>{t('Thành công')}</p>
                        <p>{message}</p>
                    </div>
                }
            />
        </Snackbar>
    )
})

export default SuccessAlert;