import { PaymentConfiguration } from "./enum";

export const configurations = {
    faceid: PaymentConfiguration.NOT_REQURIRE,
    phone: PaymentConfiguration.REQUIRE,
    role: PaymentConfiguration.REQUIRE,
    block: PaymentConfiguration.REQUIRE,
    floor: PaymentConfiguration.REQUIRE,
    room: PaymentConfiguration.REQUIRE,
    full_name: PaymentConfiguration.REQUIRE,
    birthday: PaymentConfiguration.REQUIRE,
    sex: PaymentConfiguration.REQUIRE,
    nationality: PaymentConfiguration.REQUIRE,
    CMND: PaymentConfiguration.REQUIRE,
    CMND_threshold: 15,
    issued_place: PaymentConfiguration.NOT_REQURIRE,
    province: PaymentConfiguration.NOT_REQURIRE,
    district: PaymentConfiguration.NOT_REQURIRE,
    ward: PaymentConfiguration.NOT_REQURIRE,
    address: PaymentConfiguration.NOT_REQURIRE,
    career: PaymentConfiguration.NOT_REQURIRE,
    email: PaymentConfiguration.NOT_REQURIRE,
    note: PaymentConfiguration.NOT_REQURIRE,
    notification: PaymentConfiguration.NOT_REQURIRE,
    remove_faceid: PaymentConfiguration.HIDE,
    add_more_apartment: PaymentConfiguration.HIDE,
    allowFaceMaskRegistration: false,
    security_camera: PaymentConfiguration.HIDE,
    create_open_door_qr_code: PaymentConfiguration.DENIED
}
