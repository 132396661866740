import Button from '@material-ui/core/Button/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider/Divider';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axiosClient from '../../../config/AxiosClient';
import ErrorAlert from '../../alert/ErrorAlert';
import SuccessAlert from '../../alert/SuccessAlert';
import useStyles from './styles';

const TextButton = withStyles(theme => ({ root: theme.moreDetail }))(Button);
const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);
const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);
const LicenseList = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [alert, setAlert] = useState({ success: '', error: '', license: '' });
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [licenses, setLicenses] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedLicense, setSelectedLicense] = useState({});
    const [isUpdating, setIsUpdating] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isExpiredSession, setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const alertRef = useRef();


    const columns = [
        {
            field: 'SdkDeviceID',
            headerName: t('ID thiết bị'),
            cellClassName: 'super-app-theme--cell',
            flex: 1
        },
        {
            field: 'License',
            headerName: t('Giấy phép'),
            cellClassName: 'super-app-theme--cell',
            flex: 1
        },
        {
            field: 'Method',
            headerName: t('Thao tác'),
            cellClassName: 'super-app-theme--cell',
            flex: 0.4,
            sortable: false,
            renderCell: params => <TextButton onClick={() => onEditClick(params.row)} >{t('Chỉnh sửa')}</TextButton>
        },
    ]

    useEffect(() => {
        if (isExpiredSession) {
            history.push('/login');
            dispatch({ type: 'IS_LOGIN_AGAIN_TRUE' });
        }
    }, [isExpiredSession]);

    const getDefaultValues = async () => {
        try {
            const res = await axiosClient.post('/sdk/deviceid/get', {}, {
                headers: { 'Authorization': `Bearer ${Cookies.get('token')}` }
            })
            const data = [];
            res.data.Items.forEach(item => {
                data.push({ ...item, id: item.SdkDeviceID, license: item.License });
            })
            setLicenses(data);
            setAlert({ ...alert, license: '' });
        } catch (error) {
            if (error.response && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
        }
        setLoading(true);
    }

    useEffect(() => {
        if (!loading) getDefaultValues();
    }, [loading])
    const onEditClick = (data) => {
        setIsEdit(true);
        setSelectedLicense(data);
    }

    const onLicenseChange = (e) => {
        setSelectedLicense({ ...selectedLicense, license: e.target.value });
        if (e.target.value.trim() === '')
            setAlert({ ...alert, license: t('Không được để trống') });
        else
            setAlert({ ...alert, license: '' });
    }
    const onSaveClick = async () => {
        const data = {
            sdkDeviceId: selectedLicense.SdkDeviceID,
            license: selectedLicense.license
        }
        try {
            setIsUpdating(true);
            const res = await axiosClient.post('/sdk/deviceid/update', JSON.stringify(data), {
                headers: { 'Authorization': `Bearer ${Cookies.get('token')}` }
            })
            setAlert({ ...alert, success: t('Lưu thành công!') });
            setIsEdit(false);
            setLoading(false);
        } catch (error) {
            if (error.response && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({ ...alert, error: t('Lưu không thành công, vui lòng thử lại sau!') });
        }
        setIsUpdating(false);
    }

    const onCloseDialog = () => {
        setAlert({ ...alert, success: '', error: '' });
    }

    useEffect(() => {
        if (alert.success !== '' || alert.error !== '') {
            if (alertRef.current) clearTimeout(alertRef.current);
            alertRef.current = setTimeout(() => {
                onCloseDialog();
            }, 5000);
        }
    }, [alert])

    const editForm = () => {
        return (
            <Dialog
                open={isEdit}
                maxWidth="xl"
            >
                <DialogContent style={{ width: '600px', padding: '0', position: 'relative' }}>
                    <div>
                        <p style={{ textAlign: 'center', padding: '15px', fontSize: '20px', fontWeight: 'bold' }}>{t('Thông tin giấy phép Face ID')}</p>
                        <Divider />
                        <div className={classes.inputBlock} style={{ marginTop: '10px' }}>
                            <label className={classes.label}>{t('ID thiết bị')}</label><br />
                            <p style={{ fontSize: '18px' }}>{selectedLicense.SdkDeviceID}</p>
                            {/* <TextField 
                            classes={{
                                root:classes.input
                            }}
                            style={{background:'#FFFFFF',width:'100%'}}
                            size="small"
                            value={selectedLicense.SdkDeviceID}
                            placeholder={t("ID Thiết bị")} 
                            variant="outlined" 
                            disabled
                        /> */}
                        </div>
                        <div className={classes.inputBlock} style={{ height: '200px' }}>
                            <label className={classes.label}>{t('Giấy phép')}</label><br />
                            <TextField
                                classes={{
                                    root: classes.input
                                }}
                                style={{ background: '#FFFFFF', width: '100%' }}
                                size="small"
                                multiline
                                rows={7}
                                value={selectedLicense.license}
                                onChange={onLicenseChange}
                                placeholder={t("Giấy phép")}
                                variant="outlined"
                                error={alert.license !== ''}
                                helperText={alert.license}
                            />
                        </div>
                    </div>
                    <Divider />
                    <div style={{ width: '90%', margin: 'auto', textAlign: 'right' }}>
                        <CancelButton
                            variant="outlined"
                            style={{ marginLeft: '20px' }}
                            onClick={() => {
                                setIsEdit(false);
                                setLoading(false);
                            }}
                            disabled={isUpdating}
                        >
                            {t('Hủy')}
                        </CancelButton>
                        <CreateButton
                            variant="contained"
                            style={{ margin: '10px 0 10px 14px' }}
                            disabled={isUpdating || selectedLicense.license === ''}
                            onClick={onSaveClick}
                        >
                            {t('Lưu')}
                            {isUpdating && <CircularProgress size={24} style={{ position: 'absolute', color: '#0b59c8' }} />}
                        </CreateButton>

                    </div>
                </DialogContent>
            </Dialog>
        )
    }

    const EmptyRowsView = () => {
        return (
            <div
                style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <h3 style={{ color: '#6D6D6D', fontSize: '16px' }}>
                    {t('Không tìm thấy giấy phép Face ID')}
                </h3>
            </div>
        );
    };

    const customLoading = () => {
        return (
            <GridOverlay>
                <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                    <LinearProgress />
                </div>
            </GridOverlay>
        )
    }

    return (
        <div style={{ height: '100%' }}>
            {editForm()}
            <p style={{ fontSize: '22px', fontWeight: 'bold', padding: '23px 0 0px 16px' }}>{t('Giấy phép Face ID')}</p>
            <SuccessAlert message={alert.success} onCloseDialog={onCloseDialog} />
            <ErrorAlert message={alert.error} onCloseDialog={onCloseDialog} />
            <div style={{ width: '97%', margin: 'auto', marginTop: '10px', height: '85%' }}  >
                <div className={classes.tableTitle}>
                    <CreateButton
                        variant="contained"
                        onClick={() => setLoading(false)}
                        style={{ margin: '7px' }}
                    >
                        {t('Làm mới')}
                    </CreateButton>
                </div>
                <div className={clsx(classes.root)} style={{width: '100%',paddingBottom:'10px', minHeight: '300px',height:'90%'}} >       
                    <DataGrid 
                        componentsProps={{
                            pagination: {
                                labelRowsPerPage: t("Số dòng mỗi trang"),
                                labelDisplayedRows: ({ from, to, count }) => { return `${from}-${to} ${t('trong số')} ${count}` }
                            }
                        }}
                        components={{
                            NoRowsOverlay: EmptyRowsView,
                            LoadingOverlay: customLoading,
                        }}
                        localeText={{
                            footerRowSelected: (count) => count + t(" hàng trong bảng đã được chọn"),
                            columnHeaderSortIconLabel: t('Sắp xếp')
                        }}
                        rows={licenses}
                        columns={columns}
                        pageSize={rowsPerPage}
                        rowHeight={45}
                        rowsPerPageOptions={[10, 50, 100]}
                        onPageSizeChange={pageSize => {
                            setRowsPerPage(pageSize);
                            localStorage.setItem('rowsPerPage', pageSize);
                        }}
                        hideFooterRowCount
                        disableSelectionOnClick
                        disableColumnMenu={true}
                        scrollbarSize={20}
                        loading={!loading}
                    />
                </div>
            </div>
        </div>
    )
}

export default LicenseList;