import makeStyles from "@material-ui/styles/makeStyles/makeStyles";

export const useStyles = makeStyles(theme => ({
    root: theme.dataGridStyle,
    placeholder: {
        color: '#B2B2B2',
        fontSize: '16px'
    },
    filterTextFeild: {
        "& > label": {
            marginBottom: "-5px",
            fontWeight: "bold"
        },
        '& .MuiOutlinedInput-root': {
            height: '32px'
        }
    },
    ManagementBBQ: {
        padding: '0 1rem 1rem 1rem',
        height: "calc(100% - 124px)"
    },
    title: {
        margin: "22px 0"
    },
    containFilter: {
        border: "1px solid rgb(0, 0, 0 , 0.2)",
        borderRadius: "4px",
        marginBottom: "1rem",
        padding: "1rem",
        boxShadow: "2px 2px 4px 2px rgb(0, 0, 0 , 0.08)"
    },
    wrapFilter: {
        display: "flex",
        justifyContent: "space-between"
    },
    wrapFilterBtn: {
        position: "relative",
        height: "56px"
    },
    filterBtn: {
        position: "absolute",
        right: "0",
        marginTop: "1rem"
    },
    btnDelelte: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: '0.5rem'
    },
    table: {
        position: 'relative',
        borderRadius: '6px',
        paddingBottom: '16px',
        width: '100%',
        height: '100%',
        background: '#FFFFFF'
    },
}))