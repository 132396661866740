import AxiosClient from "../config/AxiosClient";

export const apiResidentTempUpdate = async (requestBody) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') url = '/resident/temp/update';
    else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') url = '/residencetemp/update';
    return await AxiosClient.post(url,requestBody);
}

export const apiResidentsApprove = async (requestBody) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') url = '/residents/approve';
    else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') url = '/residencetemp/approve';
    return await AxiosClient.post(url,requestBody);
}

