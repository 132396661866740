import makeStyles from "@material-ui/styles/makeStyles/makeStyles";

const useStyles = makeStyles(theme => ({
    root: theme.dataGridStyle,
    ManagementBBQ: {
        position: "relative",
        padding: '0 1rem',
        height: "100%"
    },
    title: {
        padding: "20px 0"
    },
    top: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '20px 0'
    },
    table: {
        position: 'relative',
        borderRadius: '6px',
        width: '100%',
        height: 'calc(100% - 191.99px)',
        background: '#FFFFFF'
    },
}));

export default useStyles;