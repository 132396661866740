import { MenuItem } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getApartmentList } from '../../../api/apartments';
import { securitycameraDeviceCreate, securitycameraDeviceUpdate } from '../../../api/securityCamera';
import ErrorAlert from '../../../components/alert/ErrorAlert';
import CancelButton from '../../../components/button/cancelButton';
import { CreateButton } from '../../../components/button/createButton';
import { Confirm } from '../../../components/dialog';
import StreamRtsp from '../../../components/streamRtsp';
import { PrimaryTitle } from '../../../components/title';
import { checkImage } from '../../../validation/CheckForm';
import Permission from './permission';
import { useStyles } from './styles';

export default function AddEditCamera({
    handleChangeIsAddCamera,
    fetchSecuritycameraDeviceGet,
    handleChangeResponseStatus,
    inforCamera
}) {
    const [state, setState] = useState({
        listAccessPermission: [],
        listBlock: [],
        thumbnail: "",
        blockSelected: inforCamera?.BlockNumber || "",
        rtsp_link: "",
        responseStatus: {
            succ: "",
            err: ""
        },
        isCheckRtsp: false,
        isEdit: false,
        isPermission: false,
        isLoading: false,
        isConfirm: false,
        isConfirmLoading: false
    });
    const [validateState, setValidateState] = useState({
        camera_name: "",
        rtsp_link: "",
        thumbnail: ""
    });

    const newBodyRequestRef = useRef({
        camera_name: inforCamera?.CameraName || "",
        rtsp_link: inforCamera?.RtspLink || "",
        camera_note: inforCamera?.CameraNote || "",
        block: inforCamera?.BlockNumber || "",
        thumbnail: inforCamera?.Thumbnail || "",
        location: inforCamera?.CameraLocation || "",
        access_permission: inforCamera?.AccessPermission || [],
    });
    const bodyRequestRef = useRef({
        camera_name: inforCamera?.CameraName || "",
        rtsp_link: inforCamera?.RtspLink || "",
        camera_note: inforCamera?.CameraNote || "",
        block: inforCamera?.BlockNumber || "",
        thumbnail: inforCamera?.Thumbnail || "",
        location: inforCamera?.CameraLocation || "",
        access_permission: inforCamera?.AccessPermission || [],
    });

    const classes = useStyles();
    const { t } = useTranslation();

    const fetchApartmentList = async () => {
        try {
            setState(prev => ({
                ...prev,
                isLoading: true
            }));

            const { Items } = await (await getApartmentList()).data;
            const block = [];

            Items.forEach(item => {
                block.push(item.BlockID);
            })

            setState(prev => ({
                ...prev,
                listBlock: [...new Set(block)],
            }));
        } catch (error) {
            setState(prev => ({
                ...prev,
                listBlock: [inforCamera.BlockNumber],
                responseStatus: {
                    succ: "",
                    err: t("Tải danh sách tòa thất bại. Vui lòng tải lại!")
                }
            }));
        }

        setState(prev => ({
            ...prev,
            isLoading: false
        }));
    }

    const handleChangeIsPermission = () => {
        if (inforCamera && !state.isEdit) return;

        setState(prev => ({
            ...prev,
            isPermission: !prev.isPermission
        }))
    }
    const onInputChange = (key, value) => {
        if (key === "camera_name" || key === "rtsp_link") {
            if (!value) {
                setValidateState(prev => ({
                    ...prev,
                    [key]: t("Không được để trống")
                }));
            } else {
                setValidateState(prev => ({
                    ...prev,
                    [key]: ""
                }));
            }

            if(key === "rtsp_link") {
                setState(prev => ({
                    ...prev,
                    rtsp_link: ""
                }))
            }
        }
        if (key === "block") {
            setState(prev => ({
                ...prev,
                blockSelected: value
            }))
        }

        bodyRequestRef.current = {
            ...bodyRequestRef.current,
            [key]: value
        }
    }
    const onThumbnailChange = (e) => {
        if (typeof (e.target.files[0]) === 'undefined') return;
        if (!checkImage(e.target.files[0].type)) {
            setValidateState(prev => ({
                ...prev,
                thumbnail: t("Chọn tệp thất bại. Vui lòng chọn tệp tin hình ảnh!")
            }))
            return;
        } else {
            setValidateState(prev => ({
                ...prev,
                thumbnail: ""
            }))
        }
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                const source = reader.result.slice(reader.result.indexOf('base64,') + 7);
                const thumbnail = source

                setState(prev => ({
                    ...prev,
                    thumbnail
                }));
                bodyRequestRef.current.thumbnail = thumbnail;
            }
        }
        reader.readAsDataURL(e.target.files[0]);
    }

    const onClickCheckRtsp = () => {
        if(bodyRequestRef.current?.rtsp_link) {
            setState(prev => ({
                ...prev,
                rtsp_link: bodyRequestRef.current?.rtsp_link
            }))
        } else {
            setState(prev => ({
                ...prev,
                rtsp_link: ""
            }));
            setValidateState(prev => ({
                ...prev,
                rtsp_link: t("Không được để trống")
            }));
        }
    }

    const onAutoCompleteChange = (newValue) => {
        setState(prev => ({
            ...prev,
            listAccessPermission: newValue
        }));
        bodyRequestRef.current.access_permission = newValue;
    }

    const onClickSave = async () => {
        if (!bodyRequestRef.current.camera_name) {
            setValidateState(prev => ({
                ...prev,
                camera_name: t("Không được để trống")
            }));
            return;
        }
        if (!bodyRequestRef.current.rtsp_link) {
            setValidateState(prev => ({
                ...prev,
                rtsp_link: t("Không được để trống")
            }));
            return;
        }
        if (validateState.thumbnail) return;

        if (state.isEdit) {
            setState(prev => ({
                ...prev,
                isConfirm: true
            }));
            return;
        }

        setState(prev => ({
            ...prev,
            isLoading: true
        }))
        try {
            await securitycameraDeviceCreate(bodyRequestRef.current);

            handleChangeResponseStatus(t("Thêm camera an ninh thành công"));
            handleChangeIsAddCamera();
            fetchSecuritycameraDeviceGet();
        } catch (error) {
            setState(prev => ({
                ...prev,
                responseStatus: {
                    succ: "",
                    err: t("Thêm camera an ninh thất bại!")
                }
            }))
        }
        setState(prev => ({
            ...prev,
            isEdit: false,
            isLoading: false
        }))
    }
    const onConfirm = async () => {
        for (let key in newBodyRequestRef.current) {
            if (newBodyRequestRef.current[key] === bodyRequestRef.current[key]) {
                delete newBodyRequestRef.current[key];
            } else {
                newBodyRequestRef.current[key] = bodyRequestRef.current[key]
            }
        }

        newBodyRequestRef.current.id = inforCamera.ID;

        setState(prev => ({
            ...prev,
            isConfirmLoading: true
        }))
        try {
            await securitycameraDeviceUpdate(newBodyRequestRef.current);

            handleChangeResponseStatus(t("Cập nhật camera an ninh thành công"));
            handleChangeIsAddCamera();
            fetchSecuritycameraDeviceGet();
        } catch (error) {
            setState(prev => ({
                ...prev,
                responseStatus: {
                    succ: "",
                    err: t("Cập nhật camera an ninh thất bại!")
                }
            }))
        }
        newBodyRequestRef.current = {
            camera_name: inforCamera?.CameraName || "",
            rtsp_link: inforCamera?.RtspLink || "",
            camera_note: inforCamera?.CameraNote || "",
            block: inforCamera?.BlockNumber || "",
            thumbnail: inforCamera?.Thumbnail || "",
            location: inforCamera?.CameraLocation || "",
            access_permission: inforCamera?.AccessPermission || [],
        };
        setState(prev => ({
            ...prev,
            isConfirm: false,
            isConfirmLoading: false
        }))
    }

    useEffect(() => {
        fetchApartmentList();
    }, [])

    useEffect(() => {
        setState(prev => ({
            ...prev,
            listAccessPermission: [...bodyRequestRef.current.access_permission]
        }))
    }, [bodyRequestRef.current.access_permission])

    return (
        <>
            <div className={classes.addCamera}>
                <div className={classes.wrapTitle}>
                    <div className={classes.title}>
                        <div
                            className={classes.titleIcon}
                            onClick={handleChangeIsAddCamera}
                        >
                            <ArrowBackIosNewOutlinedIcon />
                        </div>
                        <PrimaryTitle>
                            {!inforCamera ? t("Thêm camera") : t("Chi tiết camera")}
                        </PrimaryTitle>
                    </div>

                    <div>
                        {
                            (inforCamera && !state.isEdit) && (
                                <CreateButton
                                    onClick={() => setState(prev => ({
                                        ...prev,
                                        isEdit: true
                                    }))}
                                >
                                    {t("Sửa")}
                                </CreateButton>
                            )
                        }
                        <CreateButton
                            disabled={state.isLoading || (inforCamera && !state.isEdit)}
                            onClick={onClickSave}
                        >
                            {t("Lưu")}
                        </CreateButton>
                    </div>
                </div>

                <div className={classes.contain}>
                    <div className={classes.colunm}>
                        <p className={classes.colunmTitle}>{t("Thông tin chung")}</p>

                        <label htmlFor="thumbnail">{t("Thumbnail")}</label>
                        <br />
                        <div className={classes.wrapThumb}>
                            <div className={classes.wrapIputThumb}>
                                <TextField
                                    hiddenLabel
                                    name='thumbnail'
                                    variant="outlined"
                                    placeholder={t("Thumbnail")}
                                    type="file"
                                    disabled={inforCamera && !state.isEdit}
                                    className={clsx(classes.textField, classes.thumbTextField)}
                                    onChange={onThumbnailChange}
                                />
                            </div>
                            <div className={classes.thumbnailVitual}>
                                <div className={classes.thumbnailChild}>
                                    {

                                        !state.thumbnail && inforCamera?.Thumbnail && (
                                            <img
                                                src={inforCamera?.Thumbnail}
                                                alt="thumbnail"
                                                width="100%"
                                                height="100%"
                                                style={{
                                                    objectFit: "contain"
                                                }}
                                            />
                                        )
                                    }
                                    {
                                        state.isEdit && state.thumbnail && (
                                            <img
                                                src={`data:image/png;base64, ${state.thumbnail}`}
                                                alt="thumbnail"
                                                width="100%"
                                                height="100%"
                                                style={{
                                                    objectFit: "contain"
                                                }}
                                            />
                                        )
                                    }
                                    {
                                        !state.isEdit && state.thumbnail && (
                                            <img
                                                src={`data:image/png;base64, ${state.thumbnail}`}
                                                alt="thumbnail"
                                                width="100%"
                                                height="100%"
                                                style={{
                                                    objectFit: "contain"
                                                }}
                                            />
                                        )
                                    }
                                </div>
                            </div>

                            <div className={classes.thumbnailIcon}>
                                {
                                    (state.isEdit && !state?.thumbnail) && (
                                        <CameraAltOutlinedIcon fontSize='large' />
                                    )
                                }
                            </div>
                            {
                                !!validateState.thumbnail && (
                                    <p className={classes.error}>
                                        {validateState.thumbnail}
                                    </p>
                                )
                            }
                        </div>

                        <label htmlFor="camera_name">
                            {t("Tên camera")}
                            <span className={classes.required}> (*)</span>
                        </label>
                        <br />
                        <TextField
                            name='camera_name'
                            variant="outlined"
                            size='small'
                            placeholder={t("Tên camera")}
                            className={classes.textField}
                            error={!!validateState.camera_name}
                            helperText={validateState.camera_name}
                            disabled={inforCamera && !state.isEdit}
                            defaultValue={inforCamera?.CameraName}
                            onChange={(e) => onInputChange("camera_name", e.target.value)}
                        />

                        <label htmlFor="rtsp">
                            {t("RTSP")}
                            <span className={classes.required}> (*)</span>
                        </label>
                        <br />
                        <TextField
                            name='rtsp'
                            variant="outlined"
                            size='small'
                            placeholder={t("RTSP")}
                            className={classes.textField}
                            error={!!validateState.rtsp_link}
                            helperText={validateState.rtsp_link}
                            disabled={inforCamera && !state.isEdit}
                            defaultValue={inforCamera?.RtspLink}
                            onChange={(e) => onInputChange("rtsp_link", e.target.value)}
                        />

                        <label htmlFor="camera_note">{t("Ghi chú")}</label>
                        <br />
                        <TextField
                            name='camera_note'
                            variant="outlined"
                            size='small'
                            placeholder={t("Ghi chú")}
                            className={classes.textField}
                            disabled={inforCamera && !state.isEdit}
                            defaultValue={inforCamera?.CameraNote}
                            onChange={(e) => onInputChange("camera_note", e.target.value)}
                        />
                    </div>

                    <div className={classes.colunm}>
                        <p className={classes.colunmTitle}>{t("Vị trí đặt camera")}</p>

                        <label htmlFor="block">{t("Tòa")}</label>
                        <br />
                        <Select
                            displayEmpty
                            name='block'
                            variant="outlined"
                            size='small'
                            placeholder={t("Tòa")}
                            className={classes.textField}
                            disabled={(inforCamera && !state.isEdit) || state.isLoading}
                            value={state.blockSelected}
                            style={{
                                height: 40
                            }}
                            onChange={(e) => onInputChange("block", e.target.value)}
                        >
                            <MenuItem disabled value="">{t("<Chọn>")}</MenuItem>
                            {
                                state.listBlock?.map(item => (
                                    <MenuItem key={item} value={item} >{item}</MenuItem>
                                ))
                            }
                        </Select>

                        <label htmlFor="detailPosition">{t("Vị trí chi tiết")}</label>
                        <br />
                        <TextField
                            name='detailPosition'
                            variant="outlined"
                            size='small'
                            placeholder={t("Vị trí chi tiết")}
                            className={classes.textField}
                            disabled={inforCamera && !state.isEdit}
                            defaultValue={inforCamera?.CameraLocation}
                            onChange={(e) => onInputChange("location", e.target.value)}
                        />

                        <div className={classes.colunmWrap}>
                            <p
                                className={classes.colunmTitle}
                                style={{
                                    margin: "unset"
                                }}
                            >
                                {t("Xem trước")}
                            </p>
                            <CancelButton onClick={onClickCheckRtsp}>
                                {t("Kiểm tra")}
                            </CancelButton>
                        </div>
                        <div className={classes.liveCamrea}>
                            {
                                state.rtsp_link && (
                                    <StreamRtsp
                                        rtsp={state.rtsp_link}
                                    />
                                )
                            }
                        </div>
                    </div>

                    <div className={classes.colunm}>
                        <p className={classes.colunmTitle}>{t("Phân quyền camera")}</p>

                        <label htmlFor="permissCamera">{t("Phân quyền")}</label>
                        <br />
                        <Autocomplete
                            multiple
                            disabled={inforCamera && !state.isEdit}
                            disableClearable
                            open={false}
                            name='permissCamera'
                            variant="outlined"
                            size='small'
                            className={classes.textField}
                            options={state.listAccessPermission}
                            value={state.listAccessPermission}
                            getOptionLabel={(value) => `${t("Tòa")} ${value.block} - ${t("Tầng")} ${value.floor}`}
                            onClick={handleChangeIsPermission}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    disabled
                                    onClick={handleChangeIsPermission}

                                />
                            )}
                            onChange={(e, newValue) => onAutoCompleteChange(newValue)}

                        />
                    </div>
                </div>
            </div>

            {
                state.isPermission && (
                    <Permission
                        handleChangeIsPermission={handleChangeIsPermission}
                        bodyRequestRef={bodyRequestRef}
                    />
                )
            }

            <Confirm
                isOpen={state.isConfirm}
                handleClose={() => setState(prev => ({
                    ...prev,
                    isConfirm: false
                }))}
                isLoading={state.isConfirmLoading}
                mess={t("Bạn có chắc muốn cập nhật?")}
                onConfirm={onConfirm}
            />

            <ErrorAlert
                message={state.responseStatus.err}
                onCloseDialog={() => setState(prev => ({
                    ...prev,
                    responseStatus: {
                        err: "",
                        succ: ""
                    }
                }))}
            />
        </>
    )
}
