import { MenuItem, TextField } from '@material-ui/core';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { CircularProgress } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { securitycameraDeviceGet } from '../../api/securityCamera';
import ErrorAlert from '../../components/alert/ErrorAlert';
import { CreateButton } from '../../components/button/createButton';
import StreamRtsp from '../../components/streamRtsp';
import { PrimaryTitle } from '../../components/title';
import { useStyles } from './styles';

export default function SecurityCamera() {
    const [state, setState] = useState({
        listCamera: [],
        listDummyCamera: [],
        page: 0,
        pageSize: 8,
        Count: 0,
        responseStatus: {
            err: "",
            succ: ""
        },
        isLoading: false
    });

    const classes = useStyles();
    const { t } = useTranslation();
    const { url } = useRouteMatch();
    const history = useHistory();

    const fetchSecuritycameraDeviceGet = async (body) => {
        setState(prev => ({
            ...prev,
            isLoading: true
        }));
        try {
            const { data } = await securitycameraDeviceGet(body);
            const { Items, Count } = data;

            const listCamera = Items.map(item => {
                item.id = item.ID;
                return item;
            })
            setState(prev => ({
                ...prev,
                listCamera,
                Count
            }))
        } catch (error) {
            setState(prev => ({
                ...prev,
                listCamera: [],
                responseStatus: {
                    succ: "",
                    err: t("Tải danh sách camera thất bại!")
                }
            }))
        }

        setState(prev => ({
            ...prev,
            isLoading: false
        }));
    }

    const onPageChange = async (newPage) => {
        if (newPage < 0 || newPage * state.pageSize >= state.Count) return;

        setState(prev => ({
            ...prev,
            page: newPage
        }))
    }

    useEffect(() => {
        fetchSecuritycameraDeviceGet({ offset: state.page * state.pageSize, limit: state.pageSize });
    }, [state.page]);

    useEffect(() => {
        setState(prev => ({
            ...prev,
            page: 0
        }));
        
        fetchSecuritycameraDeviceGet({ offset: 0, limit: state.pageSize });
    }, [state.pageSize]);

    useEffect(() => {
        const listDummyCamera = [];

        if(state.listCamera.length % 4 !== 0 ) {
            for(let i = 0; i < 4 - (state.listCamera.length % 4); i++) {
                listDummyCamera.push(i);
            }
        }
        
        setState(prev => ({
            ...prev,
            listDummyCamera
        }))
    }, [state.listCamera]);

    return (
        <>
            <div className={classes.camera}>
                <div className={classes.cameraTitle}>
                    <PrimaryTitle>{t("Camera live stream")}</PrimaryTitle>
                    <CreateButton onClick={() => {
                        history.push(`${url}/manage`)
                    }}>
                        {t("Quản lý camera")}
                    </CreateButton>
                </div>


                <div className={classes.tableCamera}>
                    {
                        state.isLoading && (
                            <CircularProgress />
                        )
                    }
                    {
                        !state.isLoading && !state?.listCamera.length && (
                            <div>{t("Không có dữ liệu")}</div>
                        )
                    }
                    {
                        !state.isLoading && state?.listCamera?.map(item => (
                            <div
                                key={item.ID}
                                className={clsx(
                                    classes.cameraItem,
                                    state?.listCamera?.length > 4 && classes.cameraItem8,
                                    state?.listCamera?.length > 8 && classes.cameraItem12,
                                    state?.listCamera?.length > 12 && classes.cameraItem16,
                                )}
                                onClick={() => {
                                    if (item) {
                                        history.push(`${url}/live/${item.ID}`)
                                    }
                                }}
                            >
                                <div className={classes.cameraItemUrl}>
                                    <StreamRtsp
                                        rtsp={item?.RtspLink}
                                        id={item.ID}
                                    />
                                </div>
                                <span className={classes.cameraItemName}>
                                    {item.CameraName}
                                </span>
                            </div>
                        ))
                    }
                    {

                        !state.isLoading && state?.listDummyCamera?.map((item) => (
                            <div
                                key={item}
                                className={clsx(
                                    classes.cameraItem,
                                    state?.listCamera?.length > 4 && classes.cameraItem8,
                                    state?.listCamera?.length > 8 && classes.cameraItem12,
                                    state?.listCamera?.length > 12 && classes.cameraItem16,
                                )}
                                style={{
                                    cursor: "unset"
                                }}
                            >
                                <div className={classes.cameraItemUrl} />
                                <span className={classes.cameraItemName}>
                                    {t("Không có camera")}
                                </span>
                            </div>
                        ))
                    }
                </div>

                {
                    !state.isLoading && !!state.listCamera.length && (
                        <div className={classes.cameraFooter}>
                            <div className={classes.wrapLeftFooter}>
                                <div className={classes.wrapPageSize}>
                                    <p>
                                        {t("Số camera mỗi trang")}
                                    </p>
                                    <div>
                                        <TextField
                                            select
                                            variant='standard'
                                            className={classes.pageSizeInpt}
                                            value={state.pageSize}
                                            onChange={(e) => setState(prev => ({
                                                ...prev,
                                                pageSize: e.target.value
                                            }))}
                                        >
                                            <MenuItem value={4}>4</MenuItem>
                                            <MenuItem value={8}>8</MenuItem>
                                            <MenuItem value={12}>12</MenuItem>
                                            <MenuItem value={16}>16</MenuItem>
                                        </TextField>
                                    </div>
                                </div>
                                <div>
                                    {(state.page * state.pageSize) + 1} - {(state.page * state.pageSize + state.pageSize) >= state.Count ? state.Count : state.page * state.pageSize + state.pageSize} {t("trong số")} {state.Count}
                                </div>
                            </div>
                            <div>
                                <button
                                    className={classes.cameraFooterBtn}
                                    disabled={state.page <= 0}
                                    style={{
                                        background: state.page <= 0 && "unset",
                                    }}
                                    onClick={() => onPageChange(state.page - 1)}
                                >
                                    <ArrowBackIosNewOutlinedIcon
                                        style={{
                                            color: state.page <= 0 ? "rgba(0, 0, 0, 0.26)" : "rgba(0, 0, 0, 0.6)",
                                            fontSize: "14px"
                                        }}
                                    />
                                </button>
                                <button
                                    className={classes.cameraFooterBtn}
                                    disabled={state.page * state.pageSize + state.pageSize >= state.Count}
                                    style={{
                                        background: state.page * state.pageSize + state.pageSize >= state.Count && "unset",
                                    }}
                                    onClick={() => onPageChange(state.page + 1)}
                                >
                                    <ArrowForwardIosOutlinedIcon
                                        style={{
                                            color: state.page * state.pageSize + state.pageSize >= state.Count ? "rgba(0, 0, 0, 0.26)" : "rgba(0, 0, 0, 0.6)",
                                            fontSize: "14px"
                                        }}
                                    />
                                </button>
                            </div>
                        </div>
                    )
                }
            </div>

            <ErrorAlert
                message={state.responseStatus.err}
                onCloseDialog={() => setState(prev => ({
                    ...prev,
                    responseStatus: {
                        err: "",
                        suc: ""
                    }
                }))}
            />
        </>
    )
}
