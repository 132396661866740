import { MenuItem, Select } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { DataGrid } from '@material-ui/data-grid';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { InputAdornment } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { apiGetapartmentdata } from '../../../api/apartment';
import { securitycameraDeviceDelete, securitycameraDeviceGet } from '../../../api/securityCamera';
import ErrorAlert from '../../../components/alert/ErrorAlert';
import SuccessAlert from '../../../components/alert/SuccessAlert';
import CancelButton from '../../../components/button/cancelButton';
import { CreateButton } from '../../../components/button/createButton';
import { DeleteButton } from '../../../components/button/deleteButton';
import FilterButton from '../../../components/button/filterButton';
import { Confirm } from '../../../components/dialog';
import { CustomLoading } from '../../../components/grid/customLoading';
import { EmptyRowsView } from '../../../components/grid/emtyRowsView';
import LargeTooltip from '../../../components/largeTooltip';
import { PrimaryTitle } from '../../../components/title';
import AddEditCamera from '../addEditCamera';
import { useStyles } from './styles';

export default function ManageCamera() {
    const [state, setState] = useState({
        listCamera: [],
        arrIdSelected: [],
        inforCamera: null,
        filter: {
            block: ""
        },
        applyFilter: {
            block: ""
        },
        page: 0,
        rowsPerPage: 10,
        Count: 0,
        responseStatus: {
            succ: "",
            err: ""
        },
        isFilter: false,
        isAddEditCamera: false,
        isLoading: false,
        isConfirm: false,
        isConfirmLoading: false
    });

    const pageSizeRef = useRef(10);
    const searchBounceRef = useRef();
    const searchRef = useRef({})

    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();

    const fetchSecuritycameraDeviceGet = async (body) => {
        setState(prev => ({
            ...prev,
            isLoading: true
        }));
        try {
            for (let key in searchRef.current) {
                if (searchRef.current[key]) {
                    body[key] = searchRef.current[key]
                }
            };

            const { data } = await securitycameraDeviceGet(body);
            const { Count, Items } = data;

            const listCamera = Items.map(item => {
                item.id = item.ID;
                return item;
            })
            setState(prev => ({
                ...prev,
                listCamera,
                Count
            }))
        } catch (error) {
            handleChangeResponseStatus("", t("Tải danh sách camera thất bại!"));
        }

        setState(prev => ({
            ...prev,
            isLoading: false
        }));
    }
    const fetchApiGetapartmentdata = async () => {
        try {
            setState(prev => ({
                ...prev,
                isLoading: true
            }));

            const { data } = await apiGetapartmentdata();
            const listBlock = [];

            data?.Items?.forEach(item => {
                listBlock.push(item.BlockID);
            });

            setState(prev => ({
                ...prev,
                listBlock: [...new Set(listBlock)]
            }))
        } catch (error) {
            handleChangeResponseStatus("", t("Tải danh sách tòa thất bại. Vui lòng tải lại!"));
        }

        setState(prev => ({
            ...prev,
            isLoading: false
        }));
    }

    const handleChangeIsAddCamera = () => {
        setState(prev => ({
            ...prev,
            isAddEditCamera: !prev.isAddEditCamera,
            inforCamera: null
        }))
    }
    const onSelectedChange = (arrId) => {
        setState(prev => ({
            ...prev,
            arrIdSelected: arrId
        }))
    }
    function handleChangeResponseStatus(succ, err = "") {
        setState(prev => ({
            ...prev,
            responseStatus: {
                succ,
                err
            }
        }))
    }

    const onPageChange = (newPage) => {
        if (pageSizeRef.current !== state.rowsPerPage) return;

        setState(prev => ({
            ...prev,
            page: newPage
        }));

        const body = {
            offset: newPage * state.rowsPerPage,
            limit: state.rowsPerPage
        }

        fetchSecuritycameraDeviceGet(body);
    }
    const onPageSizeChange = (pageSize) => {
        pageSizeRef.current = pageSize;
        setState(prev => ({
            ...prev,
            rowsPerPage: pageSize,
            page: 0
        }));

        const body = {
            offset: 0,
            limit: pageSize
        }

        fetchSecuritycameraDeviceGet(body);
    }

    const onSearchChange = (e) => {
        if (searchBounceRef.current) {
            clearTimeout(searchBounceRef.current);
        }

        searchBounceRef.current = setTimeout(() => {
            searchRef.current.camera_name = e.target.value;
            const body = {
                offset: 0,
                limit: state.rowsPerPage
            };

            fetchSecuritycameraDeviceGet(body);
        }, 600)
    }

    const onFilterChange = (e) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                block: e.target.value
            }
        }));
        searchRef.current.block = e.target.value;
    }
    const onApplyFilter = () => {
        const body = {
            offset: 0,
            limit: state.rowsPerPage
        };

        setState(prev => ({
            ...prev,
            applyFilter: {
                ...prev.applyFilter,
                block: prev.filter.block
            },
            isFilter: false,
            isApplyFilter: !!prev.filter.block
        }))

        fetchSecuritycameraDeviceGet(body);
    }
    const onClearFilter = () => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                block: ""
            }
        }));
        searchRef.current.block = "";
    }

    const onConfirm = async () => {
        setState(prev => ({
            ...prev,
            isConfirmLoading: true
        }));
        try {
            const body = {
                ids: [...state.arrIdSelected],
                thumbnails: []
            }

            body.ids.forEach(item => {
                state.listCamera.forEach(camera => {
                    if (item === camera.ID) {
                        body.thumbnails.push(camera.Thumbnail)
                    }
                })
            })

            await securitycameraDeviceDelete(body);

            handleChangeResponseStatus(t("Xóa camera đã chọn thành công"));
        } catch (error) {
            handleChangeResponseStatus("", t("Xóa camera đã chọn thất bại!"));
        }

        setState(prev => ({
            ...prev,
            isConfirm: false,
            isConfirmLoading: false,
            arrIdSelected: []
        }));

        const body = {
            offset: state.page * state.rowsPerPage,
            limit: state.rowsPerPage
        }
        fetchSecuritycameraDeviceGet(body);
    }

    useEffect(() => {
        fetchSecuritycameraDeviceGet({ offset: 0, limit: 10 });
        fetchApiGetapartmentdata();
    }, [])

    const columns = [
        {
            headerName: "Thumbnail",
            field: 'Thumbnail',
            sortable: false,
            cellClassName: 'super-app-theme--cell',
            renderCell: params => (
                <img
                    src={params?.row?.Thumbnail}
                    alt=""
                    width="30"
                    height="30"
                />
            ),
            width: 35,
        },
        {
            headerName: t("Tên camera"),
            field: 'name',
            sortable: false,
            cellClassName: 'super-app-theme--cell',
            renderCell: params => (
                <LargeTooltip
                    title={params?.row?.CameraName}
                >
                    <p style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }}>
                        {params?.row?.CameraName}
                    </p>
                </LargeTooltip>
            ),
            flex: 1,
        },
        {
            headerName: t("RTSP"),
            field: 'RTSP',
            sortable: false,
            cellClassName: 'super-app-theme--cell',
            renderCell: params => (
                <LargeTooltip
                    title={params?.row?.RtspLink}
                >
                    <p style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }}>
                        {params?.row?.RtspLink}
                    </p>
                </LargeTooltip>
            ),
            flex: 1,
        },
        {
            headerName: t("Tòa"),
            field: 'block',
            sortable: false,
            cellClassName: 'super-app-theme--cell',
            renderCell: params => (
                <LargeTooltip
                    title={params?.row?.BlockNumber || ""}
                >
                    <p style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }}>
                        {params?.row?.BlockNumber}
                    </p>
                </LargeTooltip>
            ),
            flex: 1,
        },
        {
            headerName: t("Ghi chú"),
            field: 'Note',
            sortable: false,
            cellClassName: 'super-app-theme--cell',
            renderCell: params => (
                <LargeTooltip
                    title={params?.row?.CameraNote || ""}
                >
                    <p style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }}>
                        {params?.row?.CameraNote}
                    </p>
                </LargeTooltip>
            ),
            flex: 1,
        },
        {
            headerName: t("Thao tác"),
            field: 'action',
            sortable: false,
            cellClassName: 'super-app-theme--cell',
            renderCell: (params) => (
                <LargeTooltip
                    title={t("Xem chi tiết")}
                >
                    <p
                        style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            color: "#0b59c8",
                            cursor: "pointer"
                        }}
                        onClick={() => setState(prev => ({
                            ...prev,
                            isAddEditCamera: true,
                            inforCamera: params?.row
                        }))}
                    >
                        {t("Xem chi tiết")}
                    </p>
                </LargeTooltip>
            ),
            width: 170,
        },
    ]

    return (
        <>
            <div className={classes.manageCamera}>
                <div className={classes.wrapTitle}>
                    <div className={classes.title}>
                        <div
                            className={classes.titleIcon}
                            onClick={() => history.push('/admin/security-camera')}
                        >
                            <ArrowBackIosNewOutlinedIcon />
                        </div>
                        <PrimaryTitle>
                            {t("Quản lý camera")}
                        </PrimaryTitle>
                    </div>

                    <CreateButton onClick={handleChangeIsAddCamera}>
                        {t("Thêm")}
                    </CreateButton>
                </div>

                <div className={classes.wrapSearch}>
                    <div>
                        <TextField
                            placeholder={t("Tìm kiếm")}
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchOutlinedIcon />
                                    </InputAdornment>
                                ),
                                style: { background: '#FFFFFF', height: '40px', width: '320px' }
                            }}
                            onChange={onSearchChange}
                        />
                        <DeleteButton
                            disabled={!state.arrIdSelected.length}
                            style={{
                                marginLeft: "1rem"
                            }}
                            onClick={() => setState(prev => ({
                                ...prev,
                                isConfirm: true
                            }))}
                        >
                            {t("Xóa")}
                        </DeleteButton>
                    </div>
                    <FilterButton onClick={() => setState(prev => ({
                        ...prev,
                        isFilter: !prev.isFilter
                    }))}>
                        {t("Bộ lọc")}
                    </FilterButton>
                </div>
                {
                    state.applyFilter.block && (
                        <div className={classes.wrapShowFilter}>
                            <span className={classes.showFilterTitle}>{t("Lọc bởi")} </span>

                            <span className={classes.showFilter}>
                                <span>
                                    {t("Tòa")}:
                                </span>
                                <span className={classes.showFilterInput}>
                                    {state.applyFilter.block}
                                </span>
                                <span
                                    className={classes.showFilterClear}
                                    onClick={() => {
                                        onClearFilter();
                                        onApplyFilter();
                                    }}
                                >
                                    <ClearOutlinedIcon fontSize='small' />
                                </span>
                            </span>
                        </div>
                    )
                }

                <div className={clsx(classes.table, classes.root)}>
                    <DataGrid
                        disableColumnFilter
                        disableColumnMenu
                        componentsProps={{
                            pagination: {
                                labelRowsPerPage: t("Số dòng mỗi trang"),
                                labelDisplayedRows: ({ from, to, count }) => { return `${from}-${to} ${t("trong số")} ${count}` }
                            }
                        }}
                        components={{
                            NoRowsOverlay: () => EmptyRowsView("không tìm thấy dữ liệu"),
                            LoadingOverlay: CustomLoading
                        }}
                        localeText={{
                            footerRowSelected: (count) => count + t(" hàng trong bảng đã được chọn"),
                            columnHeaderSortIconLabel: t('Sắp xếp')
                        }}
                        loading={state.isLoading}
                        columns={columns}
                        rows={state.listCamera}
                        page={state.page}
                        pageSize={state.rowsPerPage}
                        rowHeight={40}
                        rowsPerPageOptions={[10, 50, 100]}
                        rowCount={state.Count}
                        checkboxSelection
                        disableSelectionOnClick
                        paginationMode="server"
                        onPageSizeChange={(pageSize) => onPageSizeChange(pageSize)}
                        onPageChange={(newPage) => onPageChange(newPage)}
                        isRowSelectable={(params) => params.row.AdminRole !== 'root' && params.row.Username !== localStorage.getItem('username')}
                        selectionModel={state.arrIdSelected}
                        onSelectionModelChange={onSelectedChange}
                    />
                </div>

                {
                    state.isAddEditCamera && (
                        <AddEditCamera
                            handleChangeIsAddCamera={handleChangeIsAddCamera}
                            fetchSecuritycameraDeviceGet={() => fetchSecuritycameraDeviceGet({ offset: state.page * state.rowsPerPage, limit: state.rowsPerPage })}
                            handleChangeResponseStatus={handleChangeResponseStatus}
                            inforCamera={state.inforCamera}
                        />
                    )
                }
            </div>

            {
                state.isFilter && (
                    <div className={classes.filter}>
                        <div className={classes.filterContain}>
                            <div className={classes.filterTitle}>
                                <PrimaryTitle>{t("Bộ lọc")}</PrimaryTitle>
                                <div
                                    className={classes.filterClose}
                                    onClick={() => setState(prev => ({
                                        ...prev,
                                        isFilter: false
                                    }))}
                                >
                                    <ClearOutlinedIcon />
                                </div>
                            </div>

                            <label htmlFor="block">{t("Tòa")}</label>
                            <Select
                                displayEmpty
                                select
                                variant='outlined'
                                size='small'
                                placeholder={t("Tòa")}
                                className={classes.filterTextField}
                                value={state.filter.block}
                                onChange={onFilterChange}
                            >
                                <MenuItem
                                    disabled
                                    value=""
                                >
                                    {t("<Chọn>")}
                                </MenuItem>
                                {
                                    state.listBlock?.map(item => (
                                        <MenuItem key={item} value={item}>
                                            {item}
                                        </MenuItem>
                                    ))
                                }
                            </Select>

                            <div className={classes.filterWrapBtn}>
                                <CancelButton onClick={onClearFilter}>
                                    {t("Xóa bộ lọc")}
                                </CancelButton>
                                <CreateButton onClick={onApplyFilter}>
                                    {t("Áp dụng")}
                                </CreateButton>
                            </div>
                        </div>
                    </div>
                )
            }

            <Confirm
                isOpen={state.isConfirm}
                handleClose={() => setState(prev => ({
                    ...prev,
                    isConfirm: false
                }))}
                isLoading={state.isConfirmLoading}
                mess={t("Bạn có chắc muốn xóa?")}
                onConfirm={onConfirm}
            />

            <SuccessAlert
                message={state.responseStatus.succ}
                onCloseDialog={() => setState(prev => ({
                    ...prev,
                    responseStatus: {
                        err: "",
                        succ: ""
                    }
                }))}
            />

            <ErrorAlert
                message={state.responseStatus.err}
                onCloseDialog={() => setState(prev => ({
                    ...prev,
                    responseStatus: {
                        err: "",
                        succ: ""
                    }
                }))}
            />
        </>
    )
}