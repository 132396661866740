export const EmptyRowsView = (mess) => {
    return (
        <div
            style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <h3 style={{ color: '#6D6D6D', fontSize: '16px' }}>
                {mess}
            </h3>
        </div>
    );
};