import AxiosClient from "../config/AxiosClient";

export const apiGetmultiplepayments = async (requestBody) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/getmultiplepayments';
    } else {
        url = '/paymentinfos/get';
    }
    return await AxiosClient.post(url, requestBody);
}

export const apiPayment = async (requestBody) => {
    var url = url = '/payment';
    return await AxiosClient.post(url, requestBody);
}

export const apiPaymentUpdate = async (requestBody) => {
    var url = url = '/payment/update';
    return await AxiosClient.post(url, requestBody);
}

export const apiDeletepayments = async (requestBody) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/deletepayments';
    } else {
        url = '/paymentinfos/delete';
    }
    return await AxiosClient.post(url, requestBody);
}

export const apiAddmultiplepayments = async (requestBody) => {
    var url = '/addmultiplepayments';
    return await AxiosClient.post(url, requestBody);
}

export const apiApaymentGetmomo = async () => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/payment/getmomo';
    } else {
        url = '/histories/payments/momo/get';
    }
    return await AxiosClient.get(url);
}