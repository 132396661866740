import AxiosClient from "../../config/AxiosClient";

export const getSyncFaceHistories = async (bodyRequest) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') url = '/sync/faceid/result';
    else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') url = '';
    return await AxiosClient.post(url,bodyRequest);
}
export const sendNotificationToken = async (bodyRequest) => {
    var url = '';
    // bodyRequest={
    //     token:''
    // }
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') url = '/admin/token';
    else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') url = '';
    return await AxiosClient.post(url,bodyRequest);
}