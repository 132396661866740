import makeStyles from '@material-ui/styles/makeStyles/makeStyles';

const useStyles = makeStyles((theme) => ({
    myVideo: {
        position: "relative",
        width: '100%',
        height: '100%',
        background: "black",
        "& > video": {
            width: "100%",
            height: "100%"
        }
    },
    cameraLoading: {
        position: "absolute",
        top: "0",
        left: "0",
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center"
    },
    control: {
        position: "absolute",
        bottom: "0",
        left: "0",
        zIndex: "10",

        width: "100%",
        // background: "rgb(0, 0, 0, 0.3)",
        "& > button": {
            marginLeft: "1rem",
            outline: "unset",
            background: "unset",
            border: "unset",
            color: "white",
            cursor: "pointer"
        }
    }
}));

export default useStyles;