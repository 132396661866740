import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button/Button';
import { isEmpty } from '../../../validation/CheckForm';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { apiGetapartmentdata } from '../../../api/apartment';
import { apiReceptionistGetall } from '../../../api/receptionist';
import { Confirm } from '../../dialog/index';

const useStyles = makeStyles((theme) => ({
    inputBlock: {
        height: '80px',
        width: '100%',
        position: 'relative'
    },
    label: {
        margin: '0',
        fontSize: '14px',
        lineHeight: '140%',
        color: '#000000',
        display: 'inline-block'
    },
    input: {
        width: '100%'
    },
    placeholder: theme.place_holder,
    requestError: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20px',
        color: '#6D6D6D'
    },
    focus: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: theme.themeColor,
            },
        },
    },
    select: {
        '& .MuiOutlinedInput-input': {
            padding: '10px 12px'
        }
    },
}))

const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);
const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);

const EditDeficeConfig = props => {
    const classes = useStyles();
    const { deviceInfo, onRefresh, updateDevice, isProcessing, pingDevice, onGetLogClick, retryDevice } = props;
    const [device, setDevice] = useState({
        fdCamera: '',
        fdRotateImageAngle: '',
        fdThreshold: 0,
        fdResolution: '',
        callCamera: '',
        callCameraResolution: '',
        useSpeaker: true,
        audioMode: '',
        receptionistNumber: '',
        deviceName: '',
        deviceBlock: '',
    })
    const [alert, setAlert] = useState({
        deviceName: '',
        deviceBlock: '',
        fdThreshold: '',
        receptionistNumber: '',
        callCamera: '',
        success: '',
        error: '',
        info: '',
        delayAfterOpenDoor: '',
        maxTemperature: '',
        dimDisplayTimeout: '',
        faceIdMode: ''
    });
    const [request, setRequest] = useState('');
    const [blocks, setBlocks] = useState([]);
    const [receptionists, setReceptionists] = useState([]);
    const [isUpdate, setIsUpdate] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [displayOptional, setDisplayOptional] = useState(false);
    const [faceidOptional, setFaceidOptional] = useState(false);
    const [callingOptional, setCallingOptional] = useState(false);
    const [isPing, setIsPing] = useState(false);
    const [isGetLogging, setIsGetLogging] = useState(false);
    const history = useHistory();
    const { t } = useTranslation();
    const [isExpiredSession, setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isExpiredSession) {
            history.push('/login');
            dispatch({ type: 'IS_LOGIN_AGAIN_TRUE' });
        }
    }, [isExpiredSession]);

    const getBlocks = async () => {
        try {
            var res = await apiGetapartmentdata();
            var blockArr = [];
            res.data.Items.forEach(item => {
                blockArr.push(item.BlockID);
            })
            blockArr.sort();
            blockArr = [...new Set(blockArr)];
            setBlocks(blockArr);
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
        }
    }

    const getReceptionists = async () => {
        try {
            var res = await apiReceptionistGetall();
            setReceptionists(res.data.Items);
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
        }

    }

    const getDefaultValues = async () => {
        try {
            if (deviceInfo === null) return;
            setDevice({
                // fdCamera: deviceInfo.Configuration.fdCamera,
                // fdRotateImageAngle: deviceInfo.Configuration.fdRotateImageAngle,
                fdThreshold: deviceInfo.Configuration ?.fdThreshold,
                // fdResolution: deviceInfo.Configuration.fdResolution,
                callCamera: deviceInfo.Configuration ?.callCamera,
                callCameraResolution: deviceInfo.Configuration ?.callCameraResolution,
                useSpeaker: deviceInfo.Configuration ?.useSpeaker,
                receptionistNumber: deviceInfo.Configuration.receptionistNumber,
                deviceName: deviceInfo.DeviceName,
                deviceBlock: deviceInfo.Configuration.deviceBlock,
                ledLightMode: deviceInfo.Configuration ?.ledLightMode,
                restrictBlockAccess: deviceInfo.Configuration ?.restrictBlockAccess,
                faceIdMode: deviceInfo.Configuration ?.faceIdMode,
                isCheckEndCall: deviceInfo.Configuration ?.isCheckEndCall,
                audioMode: deviceInfo.Configuration ?.audioMode,
                delayAfterOpenDoor: deviceInfo.Configuration ?.delayAfterOpenDoor,
                // microphone:deviceInfo.Configuration?.microphone,
                maxTemperature: deviceInfo.Configuration ?.maxTemperature,
                dimDisplayTimeout: deviceInfo.Configuration ?.dimDisplayTimeout / 1000 / 60,
                autoDimDisplay: deviceInfo.Configuration ?.autoDimDisplay,
                useTextToSpeech: deviceInfo.Configuration ?.useTextToSpeech,
                id: deviceInfo.ID,
                status: deviceInfo.DeviceStatus,
                deviceId: deviceInfo.DeviceID
            })
            setAlert({ ...alert, deviceName: '', deviceBlock: '', fdThreshold: '', receptionistNumber: '', delayAfterOpenDoor: '', maxTemperature: '', dimDisplayTimeout: '', faceIdMode: '' })
        } catch (error) {
            setRequest(t('Thiết bị đang sử dụng phiên bản cũ, vui lòng cập nhật phiên bản mới'));
        }
        setIsEdit(false);
        setLoading(true);
    }

    useEffect(() => {
        getDefaultValues();
    }, [loading, deviceInfo])

    useEffect(() => {
        getBlocks();
        getReceptionists();
    }, [])

    const getReceptionistPhoneOptions = () => {
        return receptionists.map((item, key) => {
            return (
                <MenuItem key={key} value={item.Phone}>{item.Phone} - {item.DisplayName}</MenuItem>
            )
        })
    }

    const onBlockChange = (e, newValue) => {
        if (newValue === null) {
            setAlert({ ...alert, deviceBlock: t('Không được để trống') });
            setDevice({ ...device, deviceBlock: '' });
            return;
        }
        setAlert({ ...alert, deviceBlock: "" });
        setDevice({ ...device, deviceBlock: newValue });
    }

    const onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setDevice({ ...device, [name]: value });
    }

    const onNumberChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setDevice({ ...device, [name]: parseInt(value) });
        if (parseInt(value) < 0) setAlert({ ...alert, [name]: t('Phải lớn hơn hoặc bằng 0') });
        else setAlert({ ...alert, [name]: t('') });
    }

    const onMaxTemperatureChange = (e) => {
        const value = e.target.value;
        setDevice({ ...device, maxTemperature: value });
        if (parseInt(value) < 0) setAlert({ ...alert, maxTemperature: t('Phải lớn hơn hoặc bằng 0') });
        else setAlert({ ...alert, maxTemperature: t('') });
    }

    const onFdThresholdChange = (e) => {
        const value = e.target.value;
        setDevice({ ...device, fdThreshold: value });
        if (parseFloat(value) < 0 || parseFloat(value) > 1) setAlert({ ...alert, fdThreshold: t('Phải từ 0 tới 1') });
        else setAlert({ ...alert, fdThreshold: t('') });
    }

    const onRequireChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setDevice({ ...device, [name]: value });
        if (isEmpty(value)) setAlert({ ...alert, [name]: t('Không được để trống') });
        else setAlert({ ...alert, [name]: '' });
    }

    const onBooleanChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setDevice({ ...device, [name]: value });
        setAlert({ ...alert, [name]: '' });
    }

    const onEditClick = () => {
        setIsEdit(!isEdit);
        if (isEdit) setLoading(false);
    }

    const onUpdateClick = async () => {
        if (isEmpty(device.deviceName)) return setAlert({ ...alert, deviceName: t('Không được để trống') });
        if (alert.deviceName !== '') return;
        if (isEmpty(device.deviceBlock)) return setAlert({ ...alert, deviceBlock: t('Không được để trống') });
        if (alert.deviceBlock !== '') return;
        if (isEmpty(device.receptionistNumber)) return setAlert({ ...alert, receptionistNumber: t('Không được để trống') });
        if (alert.fdThreshold !== '' || alert.dimDisplayTimeout !== '' || alert.faceIdMode !== '' || alert.maxTemperature !== '' || alert.delayAfterOpenDoor !== '') return;
        setIsPing(false);
        setAlert({ ...alert, info: t('Bạn có chắc chắn muốn lưu thay đổi?') });
        setIsUpdate(true);
    }

    const onConfirmUpdateClick = async() =>{
        var receptionist = receptionists.filter(item => item.Phone === device.receptionistNumber);
        await updateDevice({...device,dimDisplayTimeout:device.dimDisplayTimeout*1000*60, receptionistName: receptionist[0].DisplayName});
        setIsUpdate(false);
    }

    const onPingClick = () => {
        setAlert({ ...alert, info: t('Bạn có chắc chắn muốn PING đến thiết bị?') });
        setIsPing(true);
        setIsUpdate(true);
    }

    const onConfirmPingClick = async () => {
        await pingDevice(device.deviceId);
        setIsUpdate(false);
    }

    const onConfirmGetLog = async () => {
        setIsGetLogging(true);
        await onGetLogClick();
        setIsGetLogging(false);
    }

    return (
        <div>
            <Confirm
                isOpen={isUpdate}
                handleClose={() => setIsUpdate(false)}
                isLoading={isProcessing}
                mess={alert.info}
                onConfirm={isPing ? onConfirmPingClick : onConfirmUpdateClick}
            />
            {request !== '' &&
                <div>
                    <div className={classes.requestError}>{request}</div>
                    <div style={{ textAlign: 'right' }}>
                        <CreateButton
                            variant="contained"
                            onClick={retryDevice}
                            disabled={isProcessing}
                        >
                            {t('Thử lại')}
                            {isProcessing && <CircularProgress size={25} style={{ position: 'absolute', color: '#0b59c8' }} />}
                        </CreateButton>
                    </div>
                </div>
            }
            {loading && request === '' && <div style={{ padding: '0 16px' }} className={clsx(classes.focus)}>
                <p style={{ fontWeight: 'bold', margin: '5px 2% 5px 16px' }}>{t('Cài đặt chung')}</p>
                <div style={{ padding: '0 16px', paddingBottom: '0', width: '33%', float: 'left' }}>
                    <div className={classes.inputBlock} style={{ marginRight: '2%' }}>
                        <label className={classes.label}>{t('Tên thiết bị')} <span style={{ color: 'red' }}>(*)</span></label><br />
                        <TextField
                            classes={{
                                root: classes.input
                            }}
                            name="deviceName"
                            size="small"
                            placeholder={t("Tên thiết bị")}
                            variant="outlined"
                            onChange={onRequireChange}
                            value={device.deviceName}
                            disabled={!isEdit}
                        />
                        <p style={{ color: 'red', fontSize: '13px', paddingTop: '4px' }}>{alert.deviceName}</p>
                    </div>
                </div>
                <div style={{ padding: '0 16px', paddingBottom: '0', width: '33%', float: 'left' }}>
                    <div className={classes.inputBlock} style={{ marginRight: '2%' }}>
                        <label className={classes.label} >Block <span style={{ color: 'red' }}>(*)</span></label>
                        <Autocomplete
                            style={{ position: 'relative', top: '-16px' }}
                            size="small"
                            clearText={t("Xóa")}
                            noOptionsText={t("Không có dữ liệu")}
                            openText={t("Mở")}
                            options={blocks}
                            value={device.deviceBlock}
                            disabled={!isEdit}
                            renderInput={(params) => (
                                <TextField {...params} style={{ background: '#FFFFFF' }} placeholder="block" margin="normal" variant="outlined" />
                            )}
                            onChange={(e, newValue) => onBlockChange(e, newValue)}

                        />
                        <p style={{ color: 'red', fontSize: '13px', position: 'absolute', bottom: '0' }}>{alert.deviceBlock}</p>
                    </div>
                </div>

                <Divider style={{ clear: 'both' }} />
                <div style={{ display: 'flex', margin: '5px 2% 5px 16px' }}>
                    <p style={{ flex: 1, fontWeight: 'bold' }}>{t('Hiển thị')}</p>
                    {!displayOptional && <p style={{ cursor: 'pointer', color: '#0b59c8' }} onClick={() => setDisplayOptional(!displayOptional)} >{t('Tùy chọn')}</p>}
                    {displayOptional && <p style={{ cursor: 'pointer', color: '#0b59c8' }} onClick={() => setDisplayOptional(!displayOptional)} >{t('Ẩn')}</p>}
                </div>
                {displayOptional &&
                    <div>
                        <div style={{ padding: '0 16px', paddingBottom: '0', width: '33%', float: 'left' }}>
                            <div className={clsx(classes.inputBlock, classes.select)} style={{ marginRight: '2%' }}>
                                <FormControl required variant="outlined" classes={{ root: classes.input }}>
                                    <label className={classes.label}>{t('Tự động tắt đèn nền')} </label>
                                    <Select
                                        value={device.autoDimDisplay}
                                        style={{ height: '40px', background: '#FFFFFF' }}
                                        name="autoDimDisplay"
                                        displayEmpty
                                        onChange={onChange}
                                        disabled={!isEdit}
                                    >
                                        <MenuItem value={true} >{t('Bật')}</MenuItem>
                                        <MenuItem value={false} >{t('Tắt')}</MenuItem>

                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div style={{ padding: '0 16px', paddingBottom: '0', width: '33%', float: 'left' }}>
                            <div className={classes.inputBlock} style={{ marginRight: '2%' }}>
                                <label className={classes.label}>{t('Tắt đèn nền sau')} </label><br />
                                <TextField
                                    classes={{
                                        root: classes.input
                                    }}
                                    name="dimDisplayTimeout"
                                    size="small"
                                    type="number"
                                    placeholder={t("Tắt đèn nền sau")}
                                    variant="outlined"
                                    onChange={onNumberChange}
                                    value={device.dimDisplayTimeout}
                                    disabled={!isEdit}
                                />
                                <p style={{ color: 'red', fontSize: '13px', paddingTop: '4px' }}>{alert.dimDisplayTimeout}</p>
                            </div>
                        </div>
                        <div style={{ padding: '0 16px', paddingBottom: '0', width: '33%', float: 'left' }}>
                            <div className={clsx(classes.inputBlock, classes.select)} style={{ marginRight: '2%' }}>
                                <FormControl required variant="outlined" classes={{ root: classes.input }}>
                                    <label className={classes.label}>{t('Sử dụng Text-to-speech')} </label>
                                    <Select
                                        value={device.useTextToSpeech}
                                        style={{ height: '40px', background: '#FFFFFF' }}
                                        name="useTextToSpeech"
                                        displayEmpty
                                        onChange={onChange}
                                        disabled={!isEdit}
                                    >
                                        <MenuItem value={true} >{t('Bật')}</MenuItem>
                                        <MenuItem value={false} >{t('Tắt')}</MenuItem>

                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>}
                <Divider style={{ clear: 'both' }} />
                <div style={{ display: 'flex', margin: '5px 2% 5px 16px' }}>
                    <p style={{ flex: 1, fontWeight: 'bold' }}>{t('Nhận diện khuôn mặt')}</p>
                    {!faceidOptional && <p style={{ cursor: 'pointer', color: '#0b59c8' }} onClick={() => setFaceidOptional(!faceidOptional)} >{t('Tùy chọn')}</p>}
                    {faceidOptional && <p style={{ cursor: 'pointer', color: '#0b59c8' }} onClick={() => setFaceidOptional(!faceidOptional)} >{t('Ẩn')}</p>}
                </div>
                {faceidOptional &&
                    <div>
                        <div style={{ padding: '0 16px', paddingBottom: '0', width: '33%', float: 'left' }}>
                            <div className={classes.inputBlock} style={{ marginRight: '2%' }}>
                                <label className={classes.label}>{t('Ngưỡng nhận diện khuôn mặt')} </label><br />
                                <TextField
                                    classes={{
                                        root: classes.input
                                    }}
                                    name="fdThreshold"
                                    size="small"
                                    type="number"
                                    placeholder={t("Ngưỡng nhận diện khuôn mặt") + ' (0~1)'}
                                    variant="outlined"
                                    onChange={onFdThresholdChange}
                                    value={device.fdThreshold}
                                    disabled={!isEdit}
                                />
                                <p style={{ color: 'red', fontSize: '13px', paddingTop: '4px' }}>{alert.fdThreshold}</p>
                            </div>
                        </div>
                        <div style={{ padding: '0 16px', paddingBottom: '0', width: '33%', float: 'left' }}>
                            <div className={clsx(classes.inputBlock, classes.select)} style={{ marginRight: '2%' }}>
                                <FormControl required variant="outlined" classes={{ root: classes.input }}>
                                    <label className={classes.label}>{t('Chế độ LED')} </label>
                                    <Select
                                        value={device.ledLightMode}
                                        style={{ height: '40px', background: '#FFFFFF' }}
                                        name="ledLightMode"
                                        displayEmpty
                                        onChange={onChange}
                                        disabled={!isEdit}
                                    >
                                        <MenuItem value={0} >{t('Tự động')}</MenuItem>
                                        <MenuItem value={1} >{t('Luôn luôn bật')}</MenuItem>
                                        <MenuItem value={-1} >{t('Luôn luôn tắt')}</MenuItem>

                                    </Select>
                                </FormControl><br />
                            </div>
                        </div>
                        <div style={{ padding: '0 16px', paddingBottom: '0', width: '33%', float: 'left' }}>
                            <div className={clsx(classes.inputBlock, classes.select)} style={{ marginRight: '2%' }}>
                                <FormControl required variant="outlined" classes={{ root: classes.input }}>
                                    <label className={classes.label}>{t('Chế độ Face ID')} </label>
                                    <Select
                                        value={device.faceIdMode}
                                        style={{ height: '40px', background: '#FFFFFF' }}
                                        name="faceIdMode"
                                        displayEmpty
                                        onChange={onChange}
                                        disabled={!isEdit}
                                    >
                                        <MenuItem value='0' >0</MenuItem>
                                        <MenuItem value='1' >1</MenuItem>
                                        <MenuItem value='2' >2</MenuItem>
                                    </Select>
                                </FormControl><br />
                            </div>
                        </div>
                        <div style={{ clear: 'both' }}></div>
                        {/* <div style={{padding:'0 16px',paddingBottom:'0',width:'33%', float:'left'}}>
                        <div className={clsx(classes.inputBlock,classes.select)} style={{marginRight:'2%'}}>
                            <FormControl required variant="outlined" classes={{root:classes.input}}>
                                <label className={classes.label}>{t('Máy ảnh')} </label>
                                <Select
                                    value={device.fdCamera}
                                    style={{height:'40px',background:'#FFFFFF'}}
                                    name="fdCamera"
                                    displayEmpty
                                    onChange={onChange}
                                    disabled={!isEdit}
                                >
                                    <MenuItem value='' disabled ><p className={classes.placeholder}> {`< ${t('Chọn')} >`} </p> </MenuItem>
                                    <MenuItem value='front' >{t('trước')}</MenuItem>
                                    <MenuItem value='rear' >{t('sau')}</MenuItem>
                                    
                                </Select>
                            </FormControl><br />
                        </div> 
                    </div>
                    <div style={{padding:'0 16px',paddingBottom:'0',width:'33%', float:'left'}}>
                        <div className={clsx(classes.inputBlock,classes.select)} style={{marginRight:'2%'}}>
                            <FormControl required variant="outlined" classes={{root:classes.input}}>
                                <label className={classes.label}>{t('Xoay hình ảnh')} </label>
                                <Select
                                    value={device.fdRotateImageAngle}
                                    style={{height:'40px',background:'#FFFFFF'}}
                                    name="fdRotateImageAngle"
                                    displayEmpty
                                    onChange={onChange}
                                    disabled={!isEdit}
                                >
                                    <MenuItem value='' disabled ><p className={classes.placeholder}> {`< ${t('Chọn')} >`} </p> </MenuItem>
                                    <MenuItem value={0} >0</MenuItem>
                                    <MenuItem value={90} >90</MenuItem>
                                    <MenuItem value={180} >180</MenuItem>
                                    <MenuItem value={270} >270</MenuItem>                      
                                </Select>
                            </FormControl><br />
                        </div>
                    </div>
                    <div style={{padding:'0 16px',paddingBottom:'0',width:'33%', float:'left'}}>
                        <div className={clsx(classes.inputBlock,classes.select)} style={{marginRight:'2%'}}>
                            <FormControl required variant="outlined" classes={{root:classes.input}}>
                                <label className={classes.label}>{t('Độ phân giải')} </label>
                                <Select
                                    value={device.fdResolution}
                                    style={{height:'40px',background:'#FFFFFF'}}
                                    name="fdResolution"
                                    displayEmpty
                                    onChange={onChange}
                                    disabled={!isEdit}
                                >
                                    <MenuItem value='' disabled ><p className={classes.placeholder}> {`< ${t('Chọn')} >`} </p> </MenuItem>
                                    <MenuItem value='480x640' >480x640</MenuItem>
                                    <MenuItem value='720x1280' >720x1280</MenuItem>
                                    <MenuItem value='1024x1280' >1024x1280</MenuItem>
                                    <MenuItem value='1080x1920' >1080x1920</MenuItem>                      
                                </Select>
                            </FormControl><br />
                        </div>
                    </div> */}
                        <div style={{ clear: 'both' }}></div>
                        <div style={{ padding: '0 16px', paddingBottom: '0', width: '33%', float: 'left', position: 'relative' }}>
                            <div className={classes.inputBlock} style={{ marginRight: '2%' }}>
                                <label className={classes.label}>{t('Thân nhiệt tối đa')} </label><br />
                                <TextField
                                    classes={{
                                        root: classes.input
                                    }}
                                    name="maxTemperature"
                                    size="small"
                                    type="number"
                                    placeholder={t("Thân nhiệt tối đa")}
                                    variant="outlined"
                                    onChange={onMaxTemperatureChange}
                                    value={device.maxTemperature}
                                    disabled={!isEdit}
                                />
                            </div>
                            <p style={{ color: 'red', fontSize: '13px', position: 'absolute', bottom: '0' }}>{alert.maxTemperature}</p>
                        </div>
                        <div style={{ padding: '0 16px', paddingBottom: '0', width: '33%', float: 'left' }}>
                            <div className={clsx(classes.inputBlock, classes.select)} style={{ marginRight: '2%' }}>
                                <FormControl required variant="outlined" classes={{ root: classes.input }}>
                                    <label className={classes.label}>{t('Hạn chế theo Block')} </label>
                                    <Select
                                        value={device.restrictBlockAccess}
                                        style={{ height: '40px', background: '#FFFFFF' }}
                                        name="restrictBlockAccess"
                                        displayEmpty
                                        onChange={onChange}
                                        disabled={!isEdit}
                                    >
                                        {/* <MenuItem value='' disabled ><p className={classes.placeholder}> {`< ${t('Chọn')} >`} </p> </MenuItem> */}
                                        <MenuItem value={true} >{t('Có')}</MenuItem>
                                        <MenuItem value={false} >{t('Không')}</MenuItem>
                                    </Select>
                                </FormControl><br />
                            </div>
                        </div>
                    </div>}
                <Divider style={{ clear: 'both' }} />
                <div style={{ display: 'flex', margin: '5px 2% 5px 16px' }}>
                    <p style={{ flex: 1, fontWeight: 'bold' }}>{t('Cuộc gọi')}</p>
                    {!callingOptional && <p style={{ cursor: 'pointer', color: '#0b59c8' }} onClick={() => setCallingOptional(!callingOptional)} >{t('Tùy chọn')}</p>}
                    {callingOptional && <p style={{ cursor: 'pointer', color: '#0b59c8' }} onClick={() => setCallingOptional(!callingOptional)} >{t('Ẩn')}</p>}
                </div>
                <div style={{ padding: '0 16px', paddingBottom: '0', width: '33%', float: 'left' }}>
                    <div className={clsx(classes.inputBlock, classes.select)} style={{ marginRight: '2%' }}>
                        <FormControl required variant="outlined" classes={{ root: classes.input }}>
                            <label className={classes.label}>{t('Số điện thoại lễ tân')} <span style={{ color: 'red' }}>(*)</span></label>
                            <Select
                                value={device.receptionistNumber}
                                style={{ height: '40px', background: '#FFFFFF' }}
                                name="receptionistNumber"
                                displayEmpty
                                onChange={onRequireChange}
                                disabled={!isEdit}
                            >
                                <MenuItem value='' disabled ><p className={classes.placeholder}> {`< ${t('Chọn')} >`} </p> </MenuItem>
                                {getReceptionistPhoneOptions()}
                            </Select>
                        </FormControl><br />
                        <p style={{ color: 'red', fontSize: '13px', paddingTop: '4px' }}>{alert.receptionistNumber}</p>
                    </div>
                </div>
                {callingOptional && <div style={{ padding: '0 16px', paddingBottom: '0', width: '33%', float: 'left' }}>
                    <div className={clsx(classes.inputBlock, classes.select)} style={{ marginRight: '2%' }}>
                        <FormControl required variant="outlined" classes={{ root: classes.input }}>
                            <label className={classes.label}>{t('Máy ảnh')} </label>
                            <Select
                                value={device.callCamera}
                                style={{ height: '40px', background: '#FFFFFF' }}
                                name="callCamera"
                                displayEmpty
                                onChange={onChange}
                                disabled={!isEdit}
                            >
                                <MenuItem value='' disabled ><p className={classes.placeholder}> {`< ${t('Chọn')} >`} </p> </MenuItem>
                                <MenuItem value='environment' >{t('trước')}</MenuItem>
                                <MenuItem value='user' >{t('sau')}</MenuItem>
                            </Select>
                        </FormControl><br />
                    </div>
                </div>}
                {callingOptional && <div style={{ padding: '0 16px', paddingBottom: '0', width: '33%', float: 'left' }}>
                    <div className={clsx(classes.inputBlock, classes.select)} style={{ marginRight: '2%' }}>
                        <FormControl required variant="outlined" classes={{ root: classes.input }}>
                            <label className={classes.label}>{t('Độ phân giải')} </label>
                            <Select
                                value={device.callCameraResolution}
                                style={{ height: '40px', background: '#FFFFFF' }}
                                name="callCameraResolution"
                                displayEmpty
                                onChange={onChange}
                                disabled={!isEdit}
                            >
                                <MenuItem value='' disabled ><p className={classes.placeholder}> {`< ${t('Chọn')} >`} </p> </MenuItem>
                                <MenuItem value='480x640' >480x640</MenuItem>
                                <MenuItem value='720x1280' >720x1280</MenuItem>
                                <MenuItem value='1024x1280' >1024x1280</MenuItem>
                                <MenuItem value='1080x1920' >1080x1920</MenuItem>
                            </Select>
                        </FormControl><br />
                    </div>
                </div>}
                {callingOptional &&
                    <div style={{ clear: 'both' }}>

                        <div style={{ padding: '0 16px', paddingBottom: '0', width: '33%', float: 'left' }}>
                            <div className={clsx(classes.inputBlock, classes.select)} style={{ marginRight: '2%' }}>
                                <FormControl required variant="outlined" classes={{ root: classes.input }}>
                                    <label className={classes.label}>{t('Loa')}</label>
                                    <Select
                                        value={device.useSpeaker}
                                        style={{ height: '40px', background: '#FFFFFF', marginTop: '1px' }}
                                        name="useSpeaker"
                                        displayEmpty
                                        onChange={onBooleanChange}
                                        disabled={!isEdit}
                                    >
                                        <MenuItem value='' disabled ><p className={classes.placeholder}> {`< ${t('Chọn')} >`} </p> </MenuItem>
                                        <MenuItem value={true} >{t('Có')}</MenuItem>
                                        <MenuItem value={false} >{t('Không')}</MenuItem>
                                    </Select>
                                </FormControl><br />
                                <p style={{ color: 'red', fontSize: '13px', paddingTop: '4px' }}>{alert.useSpeaker}</p>
                            </div>
                        </div>

                        <div style={{ padding: '0 16px', paddingBottom: '0', width: '33%', float: 'left' }}>
                            <div className={clsx(classes.inputBlock, classes.select)} style={{ marginRight: '2%' }}>
                                <FormControl required variant="outlined" classes={{ root: classes.input }}>
                                    <label className={classes.label}>{t('Chế độ audio')} </label>
                                    <Select
                                        value={device.audioMode}
                                        style={{ height: '40px', background: '#FFFFFF' }}
                                        name="audioMode"
                                        displayEmpty
                                        onChange={onChange}
                                        disabled={!isEdit}
                                    >
                                        <MenuItem value='' disabled ><p className={classes.placeholder}> {`< ${t('Chọn')} >`} </p> </MenuItem>
                                        <MenuItem value='MODE_CURRENT' >MODE_CURRENT</MenuItem>
                                        <MenuItem value='MODE_INVALID' >MODE_INVALID</MenuItem>
                                        <MenuItem value='MODE_IN_CALL' >MODE_IN_CALL</MenuItem>
                                        <MenuItem value='MODE_IN_COMMUNICATION' >MODE_IN_COMMUNICATION</MenuItem>
                                        <MenuItem value='MODE_NORMAL' >MODE_NORMAL</MenuItem>
                                        <MenuItem value='MODE_RINGTONE' >MODE_RINGTONE</MenuItem>
                                    </Select>
                                </FormControl><br />
                            </div>
                        </div>
                        {/* <div style={{padding:'0 16px',paddingBottom:'0',width:'33%', float:'left'}}>
                        <div className={classes.inputBlock} style={{marginRight:'2%'}}>
                            <label className={classes.label}>{t('Microphone')} </label><br />
                            <TextField 
                                classes={{
                                    root:classes.input
                                }}
                                name="microphone"
                                size="small"
                                type="number"
                                placeholder={t("Microphone")}
                                variant="outlined" 
                                onChange={onNumberChange}
                                value={device.microphone}
                                disabled={!isEdit}
                            />
                        </div>
                    </div> */}
                        <div style={{ padding: '0 16px', paddingBottom: '0', width: '33%', float: 'left' }}>
                            <div className={clsx(classes.inputBlock, classes.select)} style={{ marginRight: '2%' }}>
                                <FormControl required variant="outlined" classes={{ root: classes.input }}>
                                    <label className={classes.label}>{t('Kết thúc cuộc gọi sau khi mở cửa')} </label>
                                    <Select
                                        value={device.isCheckEndCall}
                                        style={{ height: '40px', background: '#FFFFFF' }}
                                        name="isCheckEndCall"
                                        displayEmpty
                                        onChange={onChange}
                                        disabled={!isEdit}
                                    >
                                        {/* <MenuItem value='' disabled ><p className={classes.placeholder}> {`< ${t('Chọn')} >`} </p> </MenuItem> */}
                                        <MenuItem value={true} >{t('Bật')}</MenuItem>
                                        <MenuItem value={false} >{t('Tắt')}</MenuItem>
                                    </Select>
                                </FormControl><br />
                            </div>
                        </div>
                        <div style={{ padding: '0 16px', paddingBottom: '0', width: '33%', float: 'left', position: 'relative' }}>
                            <div className={classes.inputBlock} style={{ marginRight: '2%' }}>
                                <label className={classes.label}>{t('Kết thúc cuộc gọi sau')} </label><br />
                                <TextField
                                    classes={{
                                        root: classes.input
                                    }}
                                    name="delayAfterOpenDoor"
                                    size="small"
                                    type="number"
                                    placeholder={t("Kết thúc cuộc gọi sau") + t('giây')}
                                    variant="outlined"
                                    onChange={onNumberChange}
                                    value={device.delayAfterOpenDoor}
                                    disabled={!isEdit}
                                />
                            </div>
                            <p style={{ color: 'red', fontSize: '13px', position: 'absolute', bottom: '0' }}>{alert.delayAfterOpenDoor}</p>
                        </div>
                    </div>}
                <Divider style={{ clear: 'both', marginBottom: '10px' }} />
                {isEdit && <div className={classes.inputBlock} style={{ width: '100%', height: '50px', textAlign: 'right', clear: 'both' }}>
                    <CancelButton
                        variant="outlined"
                        onClick={() => {
                            setIsEdit(false);
                            setLoading(false);
                        }}
                        style={{ marginRight: '0' }}
                    >
                        {t('Hủy')}
                    </CancelButton>
                    <CreateButton
                        variant="contained"
                        onClick={onUpdateClick}
                    >
                        {t('Lưu')}
                    </CreateButton>
                </div>}
                {!isEdit && <div className={classes.inputBlock} style={{ width: '100%', height: '50px', textAlign: 'right', clear: 'both' }}>
                    {device.status === 'online' && 
                    <CreateButton
                        variant="contained"
                        onClick={onConfirmGetLog}
                        disabled={isGetLogging}
                    >
                        {t('Lấy log')}
                        {isGetLogging && <CircularProgress size={25} style={{ position: 'absolute', color: '#0b59c8' }} />}
                    </CreateButton>}
                    <CreateButton
                        variant="contained"
                        onClick={onPingClick}
                    >
                        {t('Ping')}
                    </CreateButton>
                    <CreateButton
                        variant="contained"
                        onClick={onRefresh}
                    >
                        {t('Làm mới')}
                    </CreateButton>
                    {device.status === 'online' && <CreateButton
                        variant="contained"
                        onClick={onEditClick}
                    >
                        {t('Chỉnh sửa')}
                    </CreateButton>}
                </div>}
            </div>}
        </div>
    )
}

export default EditDeficeConfig;