import React, {useState,useEffect} from 'react';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import {useTranslation} from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton/IconButton';
import TextField from '@material-ui/core/TextField';   
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button/Button';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import axiosClient from '../../../config/AxiosClient';
import Cookies from 'js-cookie';
import {isEmpty } from '../../../validation/CheckForm';
import Alert from '@material-ui/lab/Alert';
import { useDispatch } from 'react-redux';
import {useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme)=>({
    title:{fontSize:'20px',fontWeight:'700',lineHeight:'27px',color:'#000000',fontStyle:'normal',float:'left',marginRight:'24px',paddingTop:'5px'},
    inputBlock:{position:'relative',height:'140px'},
    label:{fontSize: '16px',lineHeight: '140%',color: '#000000',display: 'inline-block',width:'250px',margin:'0'},
    alert:{position:'absolute',right:'3px',top:'3px',width:'350px'},
    secondaryTitle:theme.secondary_title,
    overlay:{position:'fixed',width:'100%',height:'100%',top:'0',left:'0',background:'rgba(0,0,0,0.1)',zIndex:'1300'},
    primaryColor:theme.primaryTextColor,
    dialogTitle:{textAlign:'center',fontSize:'20px',fontWeight:'600',lineHeight:'25px',color:'#FFFFFF'},
    contentDialog:{fontSize:'16px',height:'68px',borderBottom:'1px solid #EEEDF2',display:'flex',alignItems:'center',justifyContent:'center'},
    dialogTitleBackground:theme.primaryColor,
    errorColor:theme.errorColor,
    requestError:{width:'100%',height:'65vh',display:'flex',justifyContent:'center',alignItems:'center',fontSize:'20px',color:'#6D6D6D'},
}))

const CancelButton = withStyles(theme =>({root:theme.cancel_button}))(Button);
const CreateButton = withStyles(theme =>({root:theme.create_button}))(Button);
const ErrorButton = withStyles(theme =>({root:theme.error_button}))(Button);

const EditAd = (props) =>{
    const {advertisementID,onEditBack} = props;
    const classes = useStyles();
    const {t} = useTranslation();
    const [isEdit,setIsEdit] = useState(false);
    const [advertisement,setAdvertisement] = useState({description:'',image:'',time:0});
    const [alert,setAlert] = useState({description:'',image:'',url:'',time:'',request:''});
    const [warning,setWarning] = useState('');
    const [isUpdating,setIsUpdating] = useState(false);
    const [isSuccessDialog,setIsSuccessDialog] = useState(false);
    const [isFailed,setIsFailed] = useState(false);
    const [loading,setLoading] = useState(false);
    const [isExpiredSession,setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(()=>{
        if(isExpiredSession){
          history.push('/login');
          dispatch({type:'IS_LOGIN_AGAIN_TRUE'});
        } 
    },[isExpiredSession]);


    const getDefaultValues = async()=>{
        try {
            const res = await axiosClient.post('/advertisement/get',{'id':advertisementID},{headers:{'Authorization': `Bearer ${Cookies.get('token')}`}});
            const data = res.data.Items[0];
            setAdvertisement({id:data.ID,description:data.Description,image:data.Image,time:data.AdvertisementDuration});
            setAlert({...alert,request:'',description:'',time:''});
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({...alert,request:t('Không lấy được dữ liệu')});
        }
        setLoading(true);
    } 

    useEffect(()=>{
        if(!loading) getDefaultValues();
    },[loading])
    const onDescriptionChange = (e) =>{
        setAdvertisement({...advertisement,description:e.target.value});
        if(e.target.value.trim()===''){
            setAlert({...alert,description:t('Không được để trống')});
        }else{
            setAlert({...alert,description:''});
        }
    }

    const onTimeChange =(e) =>{
        const value = e.target.value;
        setAdvertisement({...advertisement,time:value});
        if(value === '') {
            setAlert({...alert,time:t('Không được để trống')});
        }else if(parseInt(value) < 5 || parseInt(value) > 60){
            setAlert({...alert,time:t('Tối thiểu 5 giây và tối đa 60 giây')});
        }else{
            setAlert({...alert,time:''});
        }
    }
    const onSaveClick = () =>{
        if(isEmpty(advertisement.description)) return setAlert({...alert,description:t('Không được để trống')});
        if(alert.description !== '' || alert.time !== '') return;
        updateToAws(advertisement);
    }

    const updateToAws = async (data) =>{
        try {
            setIsUpdating(true);
            await axiosClient.post('/advertisement/update',{'id':data.id,'description':data.description,'time':parseInt(data.time)},{
                headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
            });
            setIsSuccessDialog(true);
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setIsFailed(true);
        }
        setIsUpdating(false);
    }

    const onCloseDialog = ()=>{
        setWarning('');
    }

    const FailedDialog = () =>{
        return(
          <Dialog 
            open={isFailed}
            maxWidth="xl"
          >
            <DialogTitle className={classes.errorColor}>
                <p className={classes.dialogTitle}>{t('Thông báo')}</p>
            </DialogTitle>
            <DialogContent style={{width:'448px',padding:'0',textAlign:'center'}}>
                <p className={classes.contentDialog}>{t('Lưu không thành công, vui lòng thử lại!')}</p>
                <ErrorButton 
                  style={{margin:'10px 0'}}
                  onClick={()=>setIsFailed(false)} 
                >
                  {t('Đồng ý')}
                </ErrorButton>
            </DialogContent>
          </Dialog>
        )
    }
    const successDialog = () =>{
        return(
            <Dialog 
                open={isSuccessDialog}
                maxWidth="xl"
            >
                <DialogTitle className={classes.dialogTitleBackground}>
                    <p className={classes.dialogTitle}>{t("Thông báo")}</p>
                </DialogTitle>
                <DialogContent style={{width:'448px',padding:'0',textAlign:'center'}}>
                    <p className={classes.contentDialog}>{t("Lưu thành công!")}</p>
                    <CancelButton
                        variant="outlined" 
                        style={{margin:'10px 0'}} 
                        onClick={onEditBack}
                     >
                        {t("Đồng ý")}
                     </CancelButton>
                </DialogContent>

            </Dialog>
        )
    }
    
    return(
        <div style={{position:'relative'}}>
            {successDialog()}
            {FailedDialog()}
            
            {warning !== '' && <Alert icon={false} severity="warning" onClose={onCloseDialog} style={{position:'absolute',right:'9px',top:'0',zIndex:'10'}}>{warning}</Alert>}
            {isUpdating && <div className={classes.overlay}></div>}
            <div style={{paddingBottom:'8px',paddingTop:'15px',position:'relative',left:'-8px'}}>
                <IconButton style={{float:'left',padding:'2px',position:'relative',top:'5px'}} onClick={onEditBack} >
                    <ArrowBackIosOutlinedIcon className={classes.primaryColor} style={{width:'28px',height:'28px',}} />
                </IconButton>
                <p className={classes.secondaryTitle}>{t("Chỉnh sửa quảng cáo")}</p>
            </div>
            {alert.request !== '' && <div className={classes.requestError}>{alert.request}</div>}
            {!loading && alert.request === '' && <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}><CircularProgress style={{color:'#0b59c8'}}/></div>}
            {loading && alert.request === '' && <div>
                <div className={classes.inputBlock}>
                    <label className={classes.label} >{t('Mô tả')} <span style={{color:'red'}}>(*)</span> </label><br />
                    <TextField 
                        style={{width:'40%',minWidth:'320px'}}
                        inputProps={{
                            autoComplete:'off'
                        }}
                        size="small"
                        disabled={!isEdit}
                        value={advertisement.description}
                        multiline
                        rows={4}
                        placeholder={t("Mô tả")} 
                        disabled={!isEdit}
                        variant="outlined" 
                        onChange={onDescriptionChange}
                        helperText={alert.description}
                        error={alert.description !==''}
                    />
                </div>
                <div className={classes.inputBlock} style={{height:'90px'}}>
                    <label className={classes.label} >{t('Url')}</label><br />
                    <TextField 
                        style={{width:'40%',minWidth:'320px'}}
                        inputProps={{
                            autoComplete:'off'
                        }}
                        size="small"
                        placeholder={t("Hình")} 
                        variant="outlined" 
                        value={advertisement.image}
                        disabled
                    />
                </div>
                <div className={classes.inputBlock} style={{height:'90px'}}>
                    <label className={classes.label} >{t('Thời gian quảng cáo')} {t('(giây)')} <span style={{color:'red'}}>(*)</span> </label><br />
                    <TextField 
                        style={{width:'40%',minWidth:'320px'}}
                        inputProps={{
                            autoComplete:'off'
                        }}
                        size="small"
                        type="number"
                        disabled={!isEdit}
                        placeholder={t("Thời gian quảng cáo")} 
                        variant="outlined" 
                        value={advertisement.time}
                        onChange={onTimeChange}
                        helperText={alert.time}
                        error={alert.time !==''}
                    />
                </div>
                {isEdit && <div className={classes.inputBlock} style={{height:'130px'}}>
                    <CancelButton
                        variant="outlined"
                        onClick={()=>{
                            setIsEdit(false);
                            setLoading(false);
                        }}
                        disabled={isUpdating}
                    >
                        {t('Hủy')}
                    </CancelButton>
                    <CreateButton
                        variant="contained"
                        onClick={onSaveClick}
                        disabled={isUpdating}
                    >
                        {t('Lưu')}
                        {isUpdating && <CircularProgress size={30} style={{position:'absolute'}}/>} 
                    </CreateButton>
                </div>}
                {!isEdit && <div className={classes.inputBlock} style={{height:'130px'}}>
                    <CreateButton
                        variant="contained"
                        style={{margin:'0'}}
                        onClick={()=>setIsEdit(true)}
                    >
                        {t('Chỉnh sửa')}
                    </CreateButton>
                </div>}
            </div>}
        </div>
    )
}

export default EditAd;