export const IS_CHANGED_SERVICES = "IS_CHANGED_SERVICES";
export const UPDATE_SERVICE_BOOKING = "UPDATE_SERVICE_BOOKING";

const initState = {
    listServiceBooking: [],
    isChangedService: false
}
const serviceBooking = (state = initState, action) => {
    switch (action.type) {
        case UPDATE_SERVICE_BOOKING:
            return { ...state, listServiceBooking: action.payload }
        case IS_CHANGED_SERVICES:
            return { ...state, isChangedService: !state.isChangedService }
        default:
            return state;
    }
}

export default serviceBooking;