import React, { useEffect, useState, useRef } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton/IconButton';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import { GridOverlay, DataGrid } from '@material-ui/data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useTranslation } from 'react-i18next';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import RegisterDetail from './RegisterDetail';
import SuccessAlert from '../alert/SuccessAlert';
import ErrorAlert from '../alert/ErrorAlert';
import moment from 'moment-timezone';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import vi from 'date-fns/locale/vi';
import { isEmpty } from '../../validation/CheckForm';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import { apiTempInfo, apiTempDelete, apiTempReject } from '../../api/temp';
import { apiGetconfig } from '../../api/getconfig';
import { apiResidentsApprove } from '../../api/resident';
import { EmptyRowsView, CustomLoading } from '../grid';
import ChangedFaceDetail from './ChangedFaceDetail';
import FormControl from '@material-ui/core/FormControl';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import LargeTooltip from '../largeTooltip';

const useStyles = makeStyles(theme => ({
  fontWeight400: {
    fontWeight: '400'
  },
  fontWeight600: {
    fontWeight: '600'
  },
  font: {
    fontSize: '16px',
    lineHeight: '22.4px',
    color: '#6D6D6D'
  },
  status: {
    color: '#6D6D6D',
    paddingBottom: '0px'
  },
  statusActive: {
    color: '#2AB930',
    paddingBottom: '0px'
  },
  statusDeActived: {
    color: '#e01010',
    paddingBottom: '0px'
  },
  primaryTitle: theme.primary_title,
  createBtn: theme.create_button,
  dialogTitleBackground: theme.primaryColor,
  cancelBtn: theme.cancel_button,
  topBlock: {
    marginTop: '22px',
    marginBottom: '20px',
    height: '40px',
    width: '100%'
  },
  mobileTopBlock: {
    marginTop: '22px',
    marginBottom: '20px',
    width: '100%',
    height: '100px'
  },
  selectTitle: {
    fontSize: '18px',
    lineHeight: '22.4px',
    float: 'left',
    marginTop: '29px',
    padding: '0 16px'
  },
  dialogTitle: {
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '25px',
    color: '#FFFFFF',
  },
  contentDialog: {
    fontSize: '16px!important',
    marginTop: '20px',
    width: '80%',
    margin: 'auto'
  },
  content: {
    fontSize: '16px',
    borderBottom: '1px solid #EEEDF2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    paddingBottom: '10px'
  },
  header: {
    width: '100%',
    height: '56px',
    background: '#FFFFFF',
    borderBottom: '1px solid #E0E0E0',
    position: 'relative',
    borderRadius: '6px 6px 0 0'
  },
  table: {
    width: '100%',
    height: '590px',
    background: '#FFFFFF',
    paddingBottom: '55px',
    borderRadius: '6px',
    position: 'relative'
  },
  root: theme.dataGridStyle,
  requestError: {
    width: '100%',
    height: '65vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
    color: '#6D6D6D'
  },
  selectInput: {
    '& .MuiInput-underline:before': { display: 'none' },
    '& .MuiInput-underline:after': { display: 'none' }
  },
  action: {
    '& .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded': {
      // boxShadow:'0px 5px 5px -3px rgb(0 0 0 / 20%)'
      boxShadow: '0px 5px 5px -3px '
    }
  },
  inputRightBlock: {
    height: '80px',
    width: '15%',
    position: 'relative',
    float: 'left'
  },
  label: {
    paddingBottom: '0px',
    paddingLeft: '5%',
    paddingTop: '12px',
    fontSize: '14px',
    lineHeight: '140%',
    color: '#000000',
    display: 'inline-block',
    margin: '0'
  },
  inputHeight: {
    textAlign: 'left',
    height: '80px',
    margin: '10px 0',
    position: 'relative',
    '& .MuiOutlinedInput-root': {
      height: '32px'
    }
  },
  alertStyle: {
    position: 'absolute',
    right: '6px',
    bottom: '6px',
    zIndex: '10'
  },
  focus: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.themeColor,
      },
    },
  },
  primaryTextColor: theme.primaryTextColor,
  checkbox: {
    '&$checked': {
      color: theme.themeColor,
    }
  },
  disabledCheckbox: {
    '&$checked': {
      color: 'rgba(0, 0, 0, 0.26)!important',
      backgroundColor: 'transparent'
    }
  },
  checked: {},
  controlLabel: {
    color: '#000000!important'
  },
  textRowStyle: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  greenColor: {
    color: '#29CB97'
  },
  blueColor: {
    color: '#3870FF'
  },
  orangeColor: {
    color: '#FF9838'
  },
  cancelButtonStyle: {
    margin: '10px 0'
  },
  createButtonStyle: {
    margin: '10px 0 10px 24px'
  },
  approveButtonStyle: {
    float: 'left',
    margin: '7px 10px 0 10px!important'
  },
  removeButtonStyle: {
    marginTop: '7px!important',
    float: 'left'
  },
  refreshButtonStyle: {
    margin: '7px!important',
    float: 'left'
  },
  select: {
    '& .MuiOutlinedInput-input': {
      padding: '8px 12px'
    }
  },
  placeholder: {
    color: '#B2B2B2',
    fontSize: '16px'
  },
  rejectButtonStyle: {
    margin: '7px 8px 0 0',
    float: 'left'
  }
}))

const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);
const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);
const DeleteButton = withStyles(theme => ({ root: theme.delete_button }))(Button);
const TextButton = withStyles(theme => ({ root: theme.moreDetail }))(Button);

const RegisterOverview = () => {
  const classes = useStyles();
  const [residences, setResidences] = useState({});
  const [loadPage, setLoadPage] = useState({
    table: false,
    page: false
  });
  const [detail, setDetail] = useState(false);
  const [residentID, setResidentID] = useState('');
  const [userIDs, setUserIDs] = useState([]);
  const [avatars, setAvatars] = useState([]);
  const [message, setMessage] = useState({
    success: '',
    error: '',
    request: '',
    info: '',
    reason: ''
  });
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowsTotal, setRowsTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageTotal, setPageTotal] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [filter, setFilter] = useState({
    registration_type: '',
    block: '',
    floor: '',
    room: ''
  });
  const [registerTime, setRegisterTime] = useState(null);
  const [isExpiredSession, setIsExpiredSession] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const searchRef = useRef();
  const alertRef = useRef();
  const [isFilter, setIsFilter] = useState(false);
  const [searchString, setSearchString] = useState('');
  const { t, i18n } = useTranslation();
  const [isApprove, setIsApprove] = useState(false);
  const [selectedApprove, setSelectedApprove] = useState('');
  const [singleApprove, setSingleApprove] = useState(false);
  const [reason, setReason] = useState('');
  const [notification, setNotification] = useState();
  const [isNotification, setIsNotification] = useState(true);
  const [actionType, setActionType] = useState(false);
  const [isReject, setIsReject] = useState(false);

  const columns = [
    {
      headerName: t('Loại yêu cầu'),
      field: 'RequestType',
      width: 180,
      cellClassName: 'super-app-theme--cell-payment',
      renderCell: params => showTypeOfRequest(params.row.RequestType)
    },
    {
      headerName: t("Họ và tên"),
      field: 'FullName',
      flex: 1,
      cellClassName: 'super-app-theme--cell-payment',
      renderCell: params => <LargeTooltip title={params.row.FullName} placement="bottom-start">
        <p className={classes.textRowStyle}>{params.row.FullName}</p>
      </LargeTooltip>
    },
    {
      headerName: t("Số điện thoại"),
      field: 'Phone',
      width: 180,
      cellClassName: 'super-app-theme--cell-payment',
      renderCell: params => (typeof (params.row.Phone) === 'undefined' || params.row.Phone === '') ? '' : `(${params.row.Phone.slice(0, 3)}) ${params.row.Phone.slice(3, 6)} ${params.row.Phone.slice(6)}`
    },
    {
      headerName: t("Phòng"),
      field: 'ResidenceRole',
      flex: 1,
      cellClassName: 'super-app-theme--cell-payment',
      renderCell: params => <LargeTooltip title={<div>{showResidentRole(params.row, true)}</div>}>{showResidentRole(params.row, false)}</LargeTooltip>
    },
    {
      headerName: 'Email',
      field: 'Email',
      flex: 1,
      cellClassName: 'super-app-theme--cell-payment',
      renderCell: params => <LargeTooltip title={typeof (params.row.Email) === 'undefined' ? '' : params.row.Email} placement="bottom-start"><p style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{params.row.Email}</p></LargeTooltip>
    },
    {
      headerName: t('Thời gian đăng ký'),
      field: 'CreatedTime',
      width: 200,
      cellClassName: 'super-app-theme--cell-payment',
      renderCell: params => params.row.CreatedTime && moment(parseInt(params.row.CreatedTime)).format('DD-MM-YYYY HH:mm:ss')
    },
    {
      field: 'ApproveStatus',
      headerName: t('Lỗi phê duyệt'),
      flex: 1,
      sortable: false,
      cellClassName: 'super-app-theme--cell-payment',
      renderCell: params =>
        <LargeTooltip title={showNote(params.row.ApproveStatus)} placement="bottom-start"><p style={{ textOverflow: 'ellipsis', overflow: 'hidden', color: '#e01010' }}>{showNote(params.row.ApproveStatus)}</p></LargeTooltip>
    },
  ];

  useEffect(() => {
    if (isExpiredSession) {
      history.push('/login');
      dispatch({ type: 'IS_LOGIN_AGAIN_TRUE' });
    }
  }, [isExpiredSession]);

  const showResidentRole = (data, showAll) => {
    var residentRole = '';
    if (data.Apartments)
      if (showAll)
        return data.Apartments.map((value, key) => {
          return <p key={key}>{value.block + value.floor + value.room} - {value.role === 'member' ? t('Người trong gia đình') : t('Chủ hộ')}</p>
        })
      else {
        if (data.Apartments.length > 1)
          return <p className={classes.textRowStyle}><b>{data.Apartments[0].block + data.Apartments[0].floor + data.Apartments[0].room}</b> - {data.Apartments[0].role === 'member' ? t('Người trong gia đình') : t('Chủ hộ')}...</p>
        else
          return <p className={classes.textRowStyle}><b>{data.Apartments[0].block + data.Apartments[0].floor + data.Apartments[0].room}</b> - {data.Apartments[0].role === 'member' ? t('Người trong gia đình') : t('Chủ hộ')}</p>
      }
    else
      return <p className={classes.textRowStyle}><b>{data.BlockNumber + data.Floor + data.Room}</b> - {data.ResidentRole === 'member' ? t('Người trong gia đình') : t('Chủ hộ')}</p>
  }

  const showTypeOfRequest = (requestType) => {
    switch (requestType) {
      case 'Cập nhật Face ID':
        return <p className={classes.blueColor}>{t('Cập nhật Face ID')}</p>
      case 'Cập nhật thông tin':
        return <p className={classes.orangeColor}>{t('Cập nhật thông tin')}</p>
      case 'Đăng ký mới':
        return <p className={classes.greenColor}>{t('Đăng ký mới')}</p>
      default: return '';
    }
  }

  const showApartment = (data) => {
    var apartments = '';
    if (data.Apartments) {
      data.Apartments.forEach(value => {
        apartments += value.block + value.floor + value.room + ' ';
      })
    } else {
      apartments = data.RoomInfo
    }
    return apartments;
  }

  const sortNumber = (v1, v2) => {
    return v1.localeCompare(v2, undefined, {
      numeric: true,
      sensitivity: 'base'
    });
  }

  const showNote = (note) => {
    switch (note) {
      case 477: return t('Hình ảnh không có');
      case 480: return t('Hình ảnh không hợp lệ');
      case 481: return t('Không thể phát hiện khuôn mặt');
      case 482: return t('Hình ảnh mờ');
      case 483: return t('Khuôn mặt đã bị trùng');
      case 484: return t('Nhiều khuôn mặt trong một hình');
      case 486: return t('Khuôn mặt không đủ lớn');
      case 487: return t('Khuôn mặt không hợp lệ');
      case 'host exists': return t('Trùng chủ hộ');
      case 'Can not find out Main Residence': return t('Phòng chưa có chủ hộ');
      case 'Username or Phone exists': return t('Số điện thoại đã tồn tại');
      case 'CMND exists': return t('CMND/CCCD/Passport đã tồn tại');
      case 'Email exists': return t('Email đã tồn tại');
      default: {
        if (note && note.match('Can not find out Resident*')) {
          return t('Không tìm thấy cư dân');
        } else {
          return '';
        }
      }
    }
  }

  const getDefaultValues = async () => {
    try {
      const end = (currentPage + 1) * rowsPerPage;
      const start = end - rowsPerPage;
      var query = {};
      if (registerTime === null) {
        query = {
          start_index: start,
          end_index: end,
          isReject: false,
          filter: { ...filter }
        };
      } else {
        query = {
          start_index: start,
          end_index: end,
          isReject: false,
          filter: {
            ...filter,
            register_time: registerTime
          }
        };
      }
      if (filter.registration_type === '') {
        delete query.filter.registration_type
      }
      if (filter.block === '') {
        delete query.filter.block
      }
      if (filter.floor === '') {
        delete query.filter.floor
      }
      if (filter.room === '') {
        delete query.filter.room
      }
      if (filter.username === '') {
        delete query.filter.username
      }
      if (filter.register_time === '') {
        delete query.filter.register_time
      }
      var res = await apiTempInfo(query);
      setRowsTotal(res.data.Count);
      var data = [];
      res.data.Items.forEach(item => {
        if (item.IsResident) {
          if (item.RegistrationType && item.RegistrationType === 'faceid') {
            data.push({
              ...item,
              id: item.ID,
              RequestType: 'Cập nhật Face ID'
            });
          } else {
            data.push({
              ...item,
              id: item.ID,
              RequestType: 'Cập nhật thông tin'
            });
          }
        } else {
          data.push({
            ...item,
            id: item.ID,
            RequestType: 'Đăng ký mới'
          });
        }
      })
      setResidences(data);
      setLoadPage({
        page: true,
        table: true
      });
      setUserIDs([]);
      setAvatars([]);
      setMessage({
        ...message,
        request: ''
      });
    } catch (error) {
      if (error.response && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
      setMessage({
        ...message,
        request: t("Không lấy được dữ liệu")
      });
    }
  }

  const getNotificationConfig = async () => {
    try {
      let res = await apiGetconfig();
      const notificationConf = res.data.Items[0].Configuration ? res.data.Items[0].Configuration.notification : 0;
      if (notificationConf === -1) {
        setIsNotification(false);
      }
      setNotification(notificationConf);
    } catch (error) {
      if (error.response && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
      setNotification(0);
    }
  }

  useEffect(() => {
    if (!loadPage.page || !loadPage.table) {
      getDefaultValues();
      getNotificationConfig();
    }
  }, [currentPage, loadPage.page, loadPage.table])

  useEffect(() => {
    setCurrentPage(0);
    setLoadPage({ ...loadPage, table: false });
  }, [rowsPerPage, filter.search, filter.phone])

  const onPageChange = (newPage) => {
    setCurrentPage(newPage);
    setLoadPage({ ...loadPage, table: false });
  }

  const onRefreshClick = () => {
    setLoadPage({ ...loadPage, table: false });
  }

  const onSelectedChange = (e) => {
    const values = e;
    var usernames = [];
    const files = [];
    values.forEach(item => {
      usernames.push(item);
      const res = residences.filter(value => value.ID === item);
      if (res[0].FaceID !== '' && typeof (res[0].FaceID) !== 'undefined') files.push(res[0].FaceID);
    })
    setUserIDs(usernames);
    setAvatars(files);
  }

  const onRowClick = (e) => {
    setResidentID(e.id);
    setActionType(e.row.RegistrationType);
    setDetail(true);
  }

  const onDeleteClick = () => {
    setConfirmDialog(!confirmDialog);
  }

  const onRejectClick = () => {
    setIsReject(true);
  }

  const onReasonChange = (e) => {
    const value = e.target.value;
    setReason(value);
    if (value.trim().length > 50) setMessage({ ...message, reason: t('Độ dài ký tự không quá 50') });
    else setMessage({ ...message, reason: '' });
  }

  const onNotificationChange = (e) => {
    if (notification === 1) return;
    setIsNotification(e.target.checked);
  }

  const onConfirmDeleteClick = async () => {
    try {
      if (isEmpty(reason)) return setMessage({ ...message, reason: t('Không được để trống') });
      if (message.reason !== '') return;
      setIsDeleting(true);
      var data = { ids: userIDs, files: avatars, reason: reason };
      if (notification === 1) data = { ...data, notification: true };
      else if (notification === -1) data = { ...data, notification: false };
      else data = { ...data, notification: isNotification };
      await apiTempDelete(data);
      setMessage({ ...message, success: `${t("Xóa thành công")} ${userIDs.length} ${userIDs.length > 1 ? t("nhiều cư dân") : t("cư dân")}!`, error: '' });
      setLoadPage({ ...loadPage, page: false });
    } catch (error) {
      if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
      setMessage({ ...message, success: '', error: t("Xóa không thành công, vui lòng thử lại sau!") });
    }
    setIsDeleting(false);
    setConfirmDialog(!confirmDialog);
  }

  const onConfirmRejectClick = async () => {
    try {
      setIsDeleting(true);
      var data = { ids: userIDs, reason: reason };
      // if (notification === 1) data = { ...data, notification: true };
      // else if (notification === -1) data = { ...data, notification: false };
      // else data = { ...data, notification: isNotification };
      await apiTempReject(data);
      setMessage({ ...message, success: `${t("Từ chối thành công")} ${userIDs.length} ${userIDs.length > 1 ? t("nhiều cư dân") : t("cư dân")}!`, error: '' });
      setLoadPage({ ...loadPage, page: false });
    } catch (error) {
      if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
      setMessage({ ...message, success: '', error: t("Từ chối không thành công, vui lòng thử lại sau!") });
    }
    setIsDeleting(false);
    setIsReject(false);
  }

  const onConfirmApproveClick = async () => {
    try {
      setIsDeleting(true);
      var ids = [];
      if (singleApprove) ids = [selectedApprove];
      else ids = userIDs;
      var data = {};
      if (notification === 1) data = { notification: true, ids: ids };
      else if (notification === -1) data = { notification: false, ids: ids };
      else data = { notification: isNotification, ids: ids };
      let res = await apiResidentsApprove(data);
      if (res.data.failedIDs.length > 0) {
        var temp = '';
        res.data.failedIDs.forEach((value) => {
          if (value.Username && temp.indexOf(value.Username) === -1)
            temp += `${value.Username ? value.Username : ''} `;
        })
        setMessage({ ...message, info: t("Duyệt thất bại") + ' ' + temp, error: '', success: '' });
      } else {
        setMessage({ ...message, success: t("Cư dân đã được duyệt thành công!"), error: '' });
      }
      setLoadPage({ ...loadPage, page: false });
    } catch (error) {

      if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
      setMessage({ ...message, success: '', error: t("Duyệt không thành công, vui lòng thử lại!") });
    }
    setSelectedApprove('');
    setSingleApprove(false);
    setIsDeleting(false);
    setIsApprove(!isApprove);
  }

  const onBackClick = () => {
    setDetail(!detail);
    setLoadPage({ ...loadPage, table: false });
    setMessage({ ...message, info: '' });
  }

  useEffect(() => {
    if (message.success !== '' || message.error !== '') {
      if (alertRef.current) clearTimeout(alertRef.current);
      alertRef.current = setTimeout(() => {
        onCloseDialog()
      }, 5000);
    }
  }, [message])

  const onCloseDialog = () => {
    setMessage({ ...message, success: '', error: '', info: '' });
  }

  const onSearchChange = (e) => {
    var value = e.target.value;
    setSearchString(value);
    if (searchRef.current) clearTimeout(searchRef.current);
    searchRef.current = setTimeout(() => {
      setFilter({ ...filter, search: value });
    }, 500)
  }

  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFilter({ ...filter, [name]: value });
  }

  const onRegisterTimeChange = (e) => {
    if (e === null) setRegisterTime(null);
    else {
      const date = new Date(e).getTime();
      setRegisterTime(date);
    }
  }

  const onClearFilterClick = () => {
    setFilter({
      ...filter,
      block: '',
      floor: '',
      room: '',
      username: '',
      registration_type: ''
    });
    setRegisterTime(null);
  }

  const onDeletedCancelClick = () => {
    setConfirmDialog(!confirmDialog);
    setMessage({ ...message, reason: '' });
  }

  const onRejectCancelClick = () => {
    setIsReject(false);
  }

  const dialogDelete = () => {
    return (
      <Dialog
        open={isReject}
        maxWidth="xl"
      >
        <DialogTitle className={classes.dialogTitleBackground}>
          <p className={classes.dialogTitle}>{t("Thông báo")}</p>
        </DialogTitle>
        <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center', position: 'relative' }}>
          <div className={classes.contentDialog}>
            <p style={{ fontSize: '18px' }} >{t("Vui lòng nhập lý do từ chối")}:</p>
            <div style={{ height: '70px' }}>
              <TextField
                style={{ background: '#FFFFFF', width: '100%', marginTop: '5px' }}
                variant="outlined"
                placeholder={t("Không có Face ID, Số điện thoại đã bị trùng,...")}
                size="small"
                name="reason"
                onChange={onReasonChange}
                helperText={message.reason}
                error={message.reason !== ''}
              />
            </div>
            <div style={{ height: '30px', width: '100%', position: 'relative', top: '-15px', display: 'flex', justifyContent: 'center' }}>
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  classes={{ label: classes.controlLabel }}
                  control={
                    <Checkbox color="primary"
                      onChange={onNotificationChange}
                      checked={isNotification}
                      classes={{ root: notification === 0 ? classes.checkbox : classes.disabledCheckbox, checked: classes.checked }}
                      disabled={notification !== 0}
                    />}
                  label={t('Gửi thông báo đến cư dân')}
                  labelPlacement="end"
                />
              </FormGroup>
            </div>
          </div>
          <div style={{ borderBottom: '1px solid #EEEDF2' }} ></div>
          <CancelButton
            variant="outlined"
            className={classes.cancelButtonStyle}
            onClick={onRejectCancelClick}
            disabled={isDeleting}
          >
            {t("Trở về")}
          </CancelButton>
          <CreateButton
            className={classes.createButtonStyle}
            onClick={onConfirmRejectClick}
            disabled={isDeleting || reason === ''}
            variant="contained"
          >
            {t("Đồng ý")}
          </CreateButton>
          {isDeleting && <CircularProgress color="primary" style={{ position: 'absolute', left: '45%', top: '25%', color: '#0b59c8' }} />}
        </DialogContent>
      </Dialog>
    )
  }

  const getFullName = (id) => {
    if (id) {
      const data = residences.filter(item => item.ID === id);
      return data[0].FullName;
    }
  }

  const getApartments = (id) => {
    if (id) {
      const data = residences.filter(item => item.ID === id);
      var apartments = '';
      if (data[0].Apartments.length === 1) return apartments;
      data[0].Apartments.forEach(value => {
        apartments += value.block + value.floor + value.room + ' ';
      })
      return apartments.trim();
    }
  }

  const handleNotification = (ids) => {
    var names = [];
    ids.forEach(item => {
      const exists = residences.filter(value => value.ID === item);
      if (exists[0])
        if (exists[0].Apartments && exists[0].Apartments.length > 1)
          names.push(exists[0].FullName);
    })
    return names;
  }

  const getNotificationConfirm = () => {
    var names = []
    if (selectedApprove !== '') names = handleNotification([selectedApprove]);
    else names = handleNotification(userIDs);
    if (names.length < 1)
      return <p>{t("Bạn có chắc chắn muốn duyệt?")}</p>
    else if (names.length === 1) {
      return (
        <div>
          <p><b>{names.length}</b> {t('1 cư dân thuộc nhiều căn hộ')}: <b>{names[0]}</b></p>
          <p>{t('Bạn có chắc chắn muốn duyệt?')}</p>
        </div>
      )
    } else if (names.length > 5) {
      var num = 0;
      return (
        <div>
          <p><b>{names.length}</b> {t('cư dân thuộc nhiều căn hộ')}:</p>
          {names.map((value, key) => {
            if (num > 4) return;
            num++;
            return <p style={{ textAlign: 'left', paddingLeft: '25%', color: '#000000DE' }} key={key}>- <b>{value}</b></p>
          })}
          <p style={{ textAlign: 'left', paddingLeft: '25%' }}>...</p>
          <p>{t('Bạn có chắc chắn muốn duyệt?')}</p>
        </div>
      )
    } else {
      return (
        <div >
          <p><b>{names.length}</b> {t('cư dân thuộc nhiều căn hộ')}:</p>
          {names.map((value, key) => <p style={{ textAlign: 'left', paddingLeft: '25%' }} key={key}> - <b>{value}</b></p>)}
          <p>{t('Bạn có chắc chắn muốn duyệt?')}</p>
        </div>
      )
    }
  }

  const confirmApprove = () => {
    return (
      <Dialog
        open={isApprove}
        maxWidth="xl"
      >
        <DialogTitle className={classes.dialogTitleBackground}>
          <p className={classes.dialogTitle}>{t("Thông báo")}</p>
        </DialogTitle>
        <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center', position: 'relative' }}>
          <div className={classes.content}>
            <div style={{ width: '100%', marginTop: '10px', fontSize: '18px' }}>
              {getNotificationConfirm()}
              <div style={{ height: '30px', paddingTop: '20px', paddingLeft: '20%', display: 'flex', alignItems: 'center' }}>
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    classes={{ label: classes.controlLabel }}
                    control={
                      <Checkbox
                        color="primary"
                        onChange={onNotificationChange}
                        checked={isNotification}
                        classes={{ root: notification === 0 ? classes.checkbox : classes.disabledCheckbox, checked: classes.checked }}
                        disabled={notification !== 0}
                      />}
                    label={t('Gửi thông báo đến cư dân')}
                    labelPlacement="end"
                  />
                </FormGroup>
              </div>
            </div>

          </div>
          <div>
            <CancelButton
              style={{ margin: '10px 0' }}
              onClick={() => {
                setIsApprove(!isApprove);
                setSingleApprove(false);
                setSelectedApprove('');
              }}
              disabled={isDeleting}
            >
              {t("Trở về")}
            </CancelButton>
            <CreateButton
              style={{ margin: '10px 0 10px 24px' }}
              onClick={onConfirmApproveClick}
              disabled={isDeleting}
            >
              {t("Đồng ý")}
            </CreateButton>
            {isDeleting && <CircularProgress color="primary" style={{ position: 'absolute', left: '45%', top: '10%', color: '#0b59c8' }} />}
          </div>
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <div style={{ width: '100%', minWidth: '980px', paddingBottom: '1%' }} className={classes.focus}>
      {dialogDelete()}
      {confirmApprove()}
      <div style={{ width: '98%', height: '80%', margin: 'auto', position: 'relative' }}>
        {!detail && <div className={classes.topBlock}>
          <div style={{ float: 'left' }}>
            <IconButton style={{ float: 'left', padding: '2px' }} component={Link} to="/admin/manage-residences" >
              <ArrowBackIosOutlinedIcon className={classes.primaryTextColor} style={{ width: '28px', height: '28px' }} />
            </IconButton>
            <p className={classes.primaryTitle}>{t("Danh sách chờ duyệt")}</p>
          </div>
          {loadPage.page && message.request === '' &&
            <div style={{ float: 'left' }}>
              <TextField
                placeholder={t("Tìm kiếm")}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  style: { background: '#FFFFFF', height: '40px', width: '320px' }
                }}
                value={searchString}
                onChange={onSearchChange}
              />
            </div>}
          <CreateButton
            variant="contained"
            style={{ float: 'right' }}
            component={Link}
            to='/admin/approval-history'
          >
            {t('Lịch sử duyệt')}
          </CreateButton>
          <CreateButton
            variant="contained"
            style={{ float: 'right' }}
            component={Link}
            to='/admin/rejected-registration'
          >
            {t('Danh sách từ chối')}
          </CreateButton>
        </div>}
        {!loadPage.page && message.request === '' && <CircularProgress color="primary" style={{ marginLeft: '50%', color: '#0b59c8' }} />}
        {!detail && message.request !== '' && <div className={classes.requestError}>{message.request}</div>}
        {!detail && loadPage.page && message.request === '' &&
          <div style={{ textAlign: 'right', marginBottom: '10px' }}>
            {isFilter &&
              <Paper className={clsx(classes.inputHeight)}>
                <div className={clsx(classes.select, classes.inputRightBlock)}>
                  <label className={classes.label} style={{ marginBottom: '0' }}>{t("Loại yêu cầu")}</label><br />
                  <FormControl variant="outlined" size="small" style={{ width: '90%', marginLeft: '5%' }}>
                    <Select
                      name="registration_type"
                      value={filter.registration_type}
                      displayEmpty
                      onChange={onChange}
                    >
                      <MenuItem value='' disabled ><p className={classes.placeholder}> {t("Loại yêu cầu")} </p> </MenuItem>
                      <MenuItem value={'new'}>{t("Đăng ký mới")}</MenuItem>
                      <MenuItem value={'faceid'}>{t("Cập nhật Face ID")}</MenuItem>
                      <MenuItem value={'update'}>{t("Cập nhật thông tin")}</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className={classes.inputRightBlock}>
                  <label className={classes.label} style={{ marginBottom: '0' }}>Block</label><br />
                  <TextField
                    style={{ background: '#FFFFFF', width: '90%', marginLeft: '5%' }}
                    variant="outlined"
                    placeholder="Block"
                    size="small"
                    name="block"
                    value={typeof (filter.block) !== 'undefined' ? filter.block : ''}
                    onChange={onChange}
                  />
                </div>
                <div className={classes.inputRightBlock}>
                  <label className={classes.label} style={{ marginBottom: '0' }}>{t("Tầng")}</label> <br />
                  <TextField
                    style={{ background: '#FFFFFF', width: '90%', marginLeft: '5%' }}
                    variant="outlined"
                    placeholder={t("Tầng")}
                    size="small"
                    name="floor"
                    value={typeof (filter.floor) !== 'undefined' ? filter.floor : ''}
                    onChange={onChange}
                  />
                </div>
                <div className={classes.inputRightBlock}>
                  <label className={classes.label} style={{ marginBottom: '0' }}>{t("Phòng")}</label><br />
                  <TextField
                    style={{ background: '#FFFFFF', width: '90%', marginLeft: '5%' }}
                    variant="outlined"
                    placeholder={t("Phòng")}
                    size="small"
                    name="room"
                    value={typeof (filter.room) !== 'undefined' ? filter.room : ''}
                    onChange={onChange}
                  />
                </div>
                <div className={classes.inputRightBlock}>
                  <label className={classes.label} style={{ marginBottom: '0' }}>{t('Thời gian đăng ký')}</label><br />
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === 'vi' ? vi : ''}>
                    <KeyboardDatePicker
                      inputVariant="outlined"
                      style={{ position: 'relative', top: '-15px', width: '90%', marginLeft: '5%' }}
                      size="small"
                      format="dd/MM/yyyy"
                      margin="normal"
                      InputProps={{ readOnly: true }}
                      placeholder={t("Thời gian đăng ký")}
                      value={registerTime}
                      onChange={onRegisterTimeChange}
                      helperText={isNaN(registerTime) ? 'Thông tin chưa hợp lệ' : ''}
                      cancelLabel={t("Đóng")}
                      okLabel={t("Đồng ý")}
                      views={["date", "month", "year"]}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div className={classes.inputRightBlock} style={{ minWidth: '210px', marginTop: '12px', height: 'auto' }}>
                  <br />
                  <CreateButton
                    variant="contained"
                    style={{ height: '32px' }}
                    onClick={() => setLoadPage({ ...loadPage, table: false })}

                  >
                    {t("Áp dụng")}
                  </CreateButton>
                  <CancelButton
                    variant="outlined"
                    style={{ height: '32px', marginLeft: '15px' }}
                    onClick={onClearFilterClick}
                  >
                    {t("filter.Xóa")}
                  </CancelButton>
                </div>
              </Paper>}
          </div>}
        {!detail && loadPage.page && message.request === '' &&
          <div className={clsx(classes.table, classes.root)}>
            <div className={classes.header} >
              <CreateButton
                variant="contained"
                className={classes.approveButtonStyle}
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => setIsApprove(true)}
                disabled={userIDs.length === 0}
              >
                {t("Duyệt")}
              </CreateButton>
              <DeleteButton
                variant="outlined"
                className={classes.rejectButtonStyle}
                startIcon={<DoDisturbAltIcon />}
                onClick={onRejectClick}
                disabled={userIDs.length === 0}
              >
                {t("Từ chối")}
              </DeleteButton>
              {/* {loadPage.page && !detail &&
                <DeleteButton
                  variant="outlined"
                  className={classes.removeButtonStyle}
                  startIcon={<DeleteOutlineOutlinedIcon />}
                  onClick={onDeleteClick}
                  disabled={userIDs.length === 0}
                >
                  {t("Xóa")}
                </DeleteButton>
              } */}
              <CreateButton
                variant="contained"
                className={classes.refreshButtonStyle}
                onClick={onRefreshClick}
                disabled={!loadPage.table}
              >
                {t('Làm mới')}
              </CreateButton>
              <CancelButton
                style={{ float: 'right', margin: '7px' }}
                variant="outlined"
                startIcon={<FilterListIcon />}
                onClick={() => setIsFilter(!isFilter)}
              >
                {t("Bộ lọc")}
              </CancelButton>
              <SuccessAlert message={message.success} onCloseDialog={onCloseDialog} />
              <ErrorAlert message={message.error} onCloseDialog={onCloseDialog} />
              <ErrorAlert message={message.info} onCloseDialog={onCloseDialog} />
            </div>
            <DataGrid
              localeText={{
                footerRowSelected: (count) => count + t(" hàng trong bảng đã được chọn"),
                columnHeaderSortIconLabel: t('Sắp xếp')
              }}
              columns={columns}
              rows={residences}
              pageSize={rowsPerPage}
              rowsPerPageOptions={[10, 50, 100]}
              onPageSizeChange={(pageSize) => setRowsPerPage(pageSize)}
              checkboxSelection
              onSelectionModelChange={onSelectedChange}
              hideFooterRowCount
              rowHeight={42}
              components={{
                NoRowsOverlay: () => EmptyRowsView(t('Không tìm thấy cư dân')),
                LoadingOverlay: CustomLoading,
              }}
              disableSelectionOnClick
              disableColumnMenu={true}
              loading={!loadPage.table}
              disableColumnReorder={true}
              onRowClick={onRowClick}
              page={currentPage}
              rowCount={rowsTotal}
              paginationMode="server"
              onPageChange={(newPage) => onPageChange(newPage)}
            />
          </div>
        }
        {
          detail && actionType !== 'faceid' &&
          <RegisterDetail residentID={residentID} onBackClick={() => onBackClick()} title={true} />
        }
        {
          detail && actionType === 'faceid' &&
          <ChangedFaceDetail residentID={residentID} onBackClick={() => onBackClick()} title={true} />
        }
      </div>
      <div style={{ height: '15px' }}></div>
    </div>
  )
};

export default RegisterOverview;