import makeStyles from '@material-ui/styles/makeStyles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    root: theme.dataGridStyle,
    wrapHead: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    wrapTitle: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '4px'
    },
    wrapIconback: {
        marginRight: '16px',
        cursor: 'pointer',
        color: '#0b59c8'
    },
    contain: {
        display: 'flex',
        padding: '16px 0 0'
    },
    wrapLeftForm: {
        marginRight: "64px"
    },
    wrapRightForm: {
        width: '50%'
    },
    textField: {
        width: '400px',
        height: '40px',
        marginBottom: '32px',
    },
    labelForce: {
        color: 'red',
        marginLeft: '4px'
    },
    checkbox: {
        margin: '0 0 16px'
    },
    wrapImg: {
        position: 'relative',
        width: '200px',
        height: '200px',
        background: 'rgb(0, 0, 0, 0.2)'
    },
    wrapInputFile: {
        position: 'absolute',
        top: 'calc(50% - 27px)',
        left: 'calc(50% - 27px)',
        zIndex: 2
    },
    btnPhoto: {
        borderRadius: '50%',
        padding: '12px',
        minWidth: 'unset',
        '&:hover': {
            background: 'rgb(0, 0, 0, 0.1)'
        }
    },
    btnPhotoLabel: {
        margin: 0,
        zIndex: 2,
        cursor: 'pointer'
    },
}));