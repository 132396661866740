export const isEmpty = (s)=>{
    if(s === null) return true;
    if(typeof(s) === 'undefined') return true;
    if(s.trim() === ''){
        return true;
    }
    return false;
}
export const checkEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
}

export const checkImage = (fileType) => {
    var re = /^image*/;
    if (re.test(fileType)) {
        return true;
    }
    return false;
}

export const checkDate = (date) => {
    var re = /^\d{4}-(((0)[0-9])|((1)[0-2]))-([0-2][0-9]|(3)[0-1])$/;
    if (re.test(date)) return true;
    return false;
}

export const checkNumberPhone = (number) => {
    var re = /^[0]\d{9}$/;
    if (re.test(number)) return true;
    return false;
}

const removeAscent = (str) => {
    if (str === null || str === undefined) return str;
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    return str;
}

export const checkFullName = (fullname) => {
    var re = /^[A-Za-z\s]*$/g // regex here
    return re.test(removeAscent(fullname))
}

export const checkBuilding = (block) => {
    var re = /^[0-9a-zA-Z]+$/
    return re.test(block);
}

export const checkUsername = (username) => {
    var re = /^[0-9a-zA-Z]+$/;
    return re.test(username);
}
export const checkDisplayName = (displayName) => {
    var re = /^[0-9A-Za-z\s]*$/g
    return re.test(removeAscent(displayName));
}

export const checkPassword = (password) => {
    // const re = /^(?=.*[A-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z \d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{12,}$/;
    const re = /^(?=.*[A-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z \d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/;
    return re.test(password);
}

export const checkUrl = (url) => {
    const re = /^(http?|https?):\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
    return re.test(url);
}

export const checkIPv4 = (ip) => {
    const re = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return re.test(ip);
}