import firebase from 'firebase/app';
import 'firebase/messaging';

const config ={
    apiKey: "AIzaSyBrlj3jYXC419A6MUg0yPzyecRdnRZr-eY",
    authDomain: "residentapp-5e62e.firebaseapp.com",
    projectId: "residentapp-5e62e",
    storageBucket: "residentapp-5e62e.appspot.com",
    messagingSenderId: "861038043095",
    appId: "1:861038043095:web:5ff55666018053dad33592"
}

firebase.initializeApp(config);
export const messaging = firebase.messaging();

export const getToken = async() =>{
    var currentToken;
    try {
        currentToken = await messaging.requestPermission().then(()=>messaging.getToken());
    } catch (error) {
        console.log("Have an error: ",error);
    }
    return currentToken;
}

