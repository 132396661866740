import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        minHeight: '100%',
    },
    primaryColor: theme.primaryTextColor,
    secondaryTitle: theme.secondary_title,

    contentBottomForm: {
        width: '98%',
        margin: 'auto',
    },
    customButton: {
        backgroundColor: 'lightGray',
        fontWeight: '600',
        color: 'black',
        width: '100px',
    },
    customSelect: {
        backgroundColor: 'unset',
        padding: '0.4rem',
    },
    cabin: {
        borderRadius: '8px',
        backgroundColor: 'rgb(0, 0, 0, 0.03)',
        width: '100%',
        padding: '1rem',
    },
    form: {
        background: '#fff',
        borderRadius: '6px',
        minHeight: '85%',
        margin: '1rem',
    },
    select: {
        '& .MuiOutlinedInput-input': {
            padding: '10px 12px',
        },
    },
    checked: {},
    radio: {
        '&$checked': {
            color: theme.menuBackground,
        },
    },
    btnCancel: {
        width: "150px",
        height: "40px",
        backgroundColor: 'rgba(0 , 0 , 0 , 0.15)',
        borderRadius: '2px',
        border: 'none',
        fontWeight: 'bold',
        color: 'black'
    },
    btnSave: {
        width: "150px",
        height: "40px",
        background: "#0B59C8",
        opacity: "0.4",
        borderRadius: "2px",
    },
    inputRightBlock: {
        height: '80px',
        width: '32%',
        paddingLeft: '2%',
        position: 'relative',
        float: 'left',
    },
    input: {
        width: '100%',
        height: '40px',
    },
    inputBlock: {
        height: '79px',
        width: '96%',
        paddingLeft: '4%',
        position: 'relative',
    },
    selectBlock: {
        height: '75px',
        width: '50%',
        position: 'relative',
        marginLeft: '10%',
    },
    label: {
        paddingBottom: '0px',
        fontSize: '14px',
        lineHeight: '140%',
        color: '#000000',
        display: 'inline-block',
        margin: '0',
    },
    height72: {
        height: '46px',
        width: '80%',
    },
    error: {
        fontSize: '12px',
        color: 'red',
        position: 'absolute',
        bottom: '0',
    },
    resetBtn: {
        width: '160px',
        height: '40px',
        textTransform: 'none',
        color: '#009ADA',
        fontSize: '18px',
        fontWeight: '600',
        border: '1px solid #009ADA',
    },
    errorBlock: {
        fontSize: '12px',
        color: 'red',
        position: 'absolute',
        left: '0',
        bottom: '0',
    },
    dialogTitle: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '25px',
        color: '#FFFFFF',
    },
    contentDialog: {
        fontSize: '16px',
        height: '68px',
        borderBottom: '1px solid #EEEDF2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    placeholder: theme.place_holder,
    cancelBtn: theme.cancel_button,
    createBtn: theme.create_button,
    dialogTitleBackground: theme.primaryColor,
    errorDialogTitleBackground: theme.errorColor,
    errorBtn: theme.error_button,
    requestError: {
        width: '100%',
        height: '65vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20px',
        color: '#6D6D6D',
    },
    imageAdding: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
    },
    fontNunito: {
        '& .MuiInputBase-root': theme.fontNunito,
    },
    focus: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: theme.themeColor,
            },
        },
    },
    formControlLabel: {
        position: 'relative',
        left: '-16px',
        '& .MuiFormControlLabel-label': {
            fontSize: '14px',
            lineHeight: '140%',
            color: '#000000',
        },
    },
}));