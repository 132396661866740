import makeStyles from '@material-ui/styles/makeStyles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    containLine: {
        position: 'relative',
        padding: "24px",
        border: '1px solid #E0E0E0',
        borderRadius: '4px',
        marginBottom: '24px'
    },
    wrapLoading: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%'
    },
    wrapTitleLine: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    lineTitle: {
        fontWeight: '700',
        fontSize: '20px',
        color: '#333333'
    },
    wrapLineAction: {
        display: 'flex',
        alignItems: "center"
    },
    viewMode: {
        color: '#8E8E8E',
        marginRight: '16px'
    },
    lineSelect: {
        marginRight: '16px'
    },
    inputDate: {
        '& div': {
            borderRadius: '40px'
        }
    },
    lineTotal: {
        marginBottom: '32px',
    }
}));