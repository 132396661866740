import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
    },
    hiddenScroll: {
        overflow: "hidden"
    },
    isIconActive: {
        border: "2px solid blue"
    },
    service: {
        position: "absolute",
        top: "0",
        left: "0",
        zIndex: "1202",

        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "100vh",
        background: "rgb(0, 0, 0, 0.4)"
    },
    title: {
        display: "flex",
        justifyContent: "center",
        marginBottom: "1.5rem"
    },
    form: {
        borderRadius: '7px',
        padding: "1.5rem",
        width: "400px",
        margin: "auto",
        background: "white"
    },
    textField: {
        width: "100%",
        marginBottom: "1.5rem !important"
    },
    required: {
        color: "red"
    },
    wrapInput: {
        position: "relative",
        display: "flex"
    },
    scheTypeAdd: {
        position: "absolute",
        top: "8px",
        left: "85%",
        cursor: "pointer"
    },
    wrapIcon: {
        display: 'flex',
        alignItems: "center",
        border: '1.5px solid rgb(0 , 0, 0, 0.2)',
        borderRadius: '4px',
        marginBottom: "1.5rem",
        padding: "8.5px 14px",
        height: '40px',
        fontSize: "1rem",
        color: "rgb(0 , 0, 0, 0.6)"
    },
    icon: {
        marginLeft: "1rem",
        cursor: "pointer"
    },
    wrapBtn: {
        display: "flex",
        justifyContent: "flex-end"
    }
}));

export default useStyles;