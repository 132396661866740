import React, { useState, useEffect } from 'react';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';
import Divider from '@material-ui/core/Divider/Divider';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import Button from '@mui/material/Button/Button';
import { compare } from 'natural-orderby';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { deepCloneArr } from '../../../helper/deepClone';

const useStyles = makeStyles((theme) => ({
    inputBlockPolicyBlock: {
        height: '80px',
        width: '40%',
        float: 'left'
    },
    homeIconStyle: {
        fontSize: '30px!important',
        position: 'relative',
        top: '-5px!important'
    },
    closeIconStyle: {
        cursor: 'pointer'
    },
    closeIconBlock: {
        textDecoration: 'underline',
        color: 'rgba(0, 0, 0, 0.12)',
        position: 'absolute',
        right: '10px',
        top: '5px'
    },
    apartmentRowStyle: {
        width: '100%',
        fontSize: '18px',
        cursor: 'pointer',
        height: '35px',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        top: '3px',
        paddingLeft: '5px'
    },
    selectingRow: {
        border: '1px solid red',
        margin: '0 4%',
        marginBottom: '5px',
        position: 'relative'
    },
    rowApartmentBlock: {
        border: '1px solid rgba(0, 0, 0, 0.12)',
        margin: '0 4%',
        marginBottom: '5px',
        position: 'relative'
    },
    dialogContent: {
        width: '600px',
        padding: '0'
    },
    base_info_title: {
        fontSize: '20px',
        lineHeight: '28px',
        color: '#333333',
        fontWeight: '600',
        paddingLeft: '17px',
        padding: '15px 0 5px 4%'
    },
    dividerStyle: {
        margin: '0 4% 10px 4%',
        padding: '1px',
        clear: 'both'
    },
    inputRightBlock: {
        height: '80px',
        width: '32%',
        float: 'left',
        paddingLeft: '4%'
    },
    select: {
        '& .MuiOutlinedInput-input': { padding: '10px 12px' }
    },
    inputSelectBlock: {
        height: '80px',
        width: '64%',
        float: 'left',
        paddingLeft: '4%'
    },
    actionBlock: {
        margin: '0 4%',
        textAlign: 'right'
    },
    rowActionStyle: {
        float: 'right',
        height: '80px',
        paddingTop: '28px',
        marginRight: '4%'
    },
    input: {
        width: '100%'
    },
    height40: {
        height: '40px'
    },
    redColor: {
        color: 'red'
    },
    saveButtonStyle: {
        margin: '10px'
    },
    cancelButtonStyle: {
        margin: '10px 0'
    },
    placeholder: theme.place_holder,
}))

export const BlockFloorRoomRole = ({
    title,
    isChooseApartment,
    closeDialog,
    roomData,
    onCancelClick,
    onSaveClick,
    apartmentData,
    subAccount,
    CreateButton,
    CancelButton
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [apartmentInfo, setApartmentInfo] = useState({
        block: '',
        floor: '',
        room: '',
        role: ''
    })
    const [blocks, setBlocks] = useState([]);
    const [floors, setFloors] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [oldSelectedApartment, setOldSelectedApartment] = useState({
        block: '',
        floor: '',
        room: '',
        role: ''
    })
    const [rowSelecting, setRowSelecting] = useState(-1);
    const [isEditApartmentRow, setIsEditApartmentRow] = useState(false);
    const [apartments, setApartments] = useState([]);

    const getBlocks = async () => {
        var names = [];
        apartmentData.forEach(item => {
            names.push(item.BlockID);
        });
        names.sort((a, b) =>
            a.localeCompare(b, undefined, {
                numeric: true,
                sensitivity: 'base'
            })
        );
        setBlocks([...new Set(names)]);
    }

    const getFloors = async (blockID) => {
        const data = apartmentData.filter(item => item.BlockID === blockID);
        if (data.length === 0) return setFloors([]);
        var names = [];
        data.forEach(item => {
            names.push(item.FloorID + '');
        });
        names.sort((a, b) =>
            a.localeCompare(b, undefined, {
                numeric: true,
                sensitivity: 'base'
            })
        );
        setFloors([...new Set(names)]);
    }

    const getRooms = async (blockID, floorID) => {
        var data = apartmentData.filter(item => item.BlockID === blockID && item.FloorID === floorID);
        if (data.length === 0) return setRooms([]);
        var names = [];
        data.forEach(item => {
            names.push(item.RoomID + '');
        });
        names.sort((a, b) =>
            a.localeCompare(b, undefined, {
                numeric: true,
                sensitivity: 'base'
            })
        );
        setRooms([...new Set(names)]);
    }

    const onRoleChange = (e) => {
        setApartmentInfo({
            ...apartmentInfo,
            role: e.target.value
        });
    }

    useEffect(() => {
        getBlocks();
    }, [apartmentData])

    useEffect(() => {
        if (apartmentInfo.block !== '') getFloors(apartmentInfo.block);
    }, [apartmentInfo.block])

    useEffect(() => {
        if (apartmentInfo.floor === '') return;
        getRooms(apartmentInfo.block, apartmentInfo.floor);
    }, [apartmentInfo.floor])

    useEffect(() => {
        setApartments(roomData);
    }, [roomData])

    useEffect(() => {
        if (subAccount) {
            setApartmentInfo({
                ...apartmentInfo,
                role: 'member'
            })
        }else{
            setApartmentInfo({
                ...apartmentInfo,
                role: ''
            }) 
        }
    }, [subAccount])

    const onBlockChange = (e, newValue) => {
        setFloors([]);
        setRooms([]);
        if (newValue === null) {
            setApartmentInfo({
                ...apartmentInfo,
                block: '',
                floor: '',
                room: ''
            });
            return;
        }
        setApartmentInfo({
            ...apartmentInfo,
            block: newValue,
            floor: '',
            room: ''
        });
    }

    const onFloorChange = (e, newValue) => {
        setRooms([]);
        if (newValue === null) {
            setApartmentInfo({
                ...apartmentInfo,
                floor: '',
                room: ''
            });
            return;
        }
        setApartmentInfo({
            ...apartmentInfo,
            floor: newValue,
            room: ''
        });
    }

    const onRoomChange = (e, newValue) => {
        if (newValue === null) {
            setApartmentInfo({
                ...apartmentInfo,
                room: ''
            });
            return;
        }
        setApartmentInfo({
            ...apartmentInfo,
            room: newValue
        });
    }

    const onAddApartmentClick = () => {
        const data = apartments;
        const exists = apartments.filter(item => item.block === apartmentInfo.block && item.floor === apartmentInfo.floor && item.room === apartmentInfo.room && item.role === apartmentInfo.role);
        if (subAccount) {
            if (exists.length === 0) {
                data.push({
                    ...apartmentInfo,
                    role: 'member',
                });
            }
            setApartmentInfo({
                block: '',
                floor: '',
                room: '',
                role: 'member'
            });
        } else {
            if (exists.length === 0) {
                data.push(apartmentInfo);
            }
            setApartmentInfo({
                block: '',
                floor: '',
                room: '',
                role: ''
            });
        }
        setApartments(data);

    }

    const onEditApartmentClick = (data) => {
        var data = deepCloneArr(apartments.filter(item => item.block !== apartmentInfo.block || item.floor !== apartmentInfo.floor || item.room !== apartmentInfo.room || item.role !== apartmentInfo.role));
        data.forEach(item => {
            if (item.block === oldSelectedApartment.block && item.floor === oldSelectedApartment.floor && item.room === oldSelectedApartment.room && item.role === oldSelectedApartment.role) {
                item.block = apartmentInfo.block;
                item.floor = apartmentInfo.floor;
                item.room = apartmentInfo.room;
                item.role = apartmentInfo.role;
            }
        })
        if (subAccount) {
            setApartmentInfo({
                block: '',
                floor: '',
                room: '',
                role: 'member'
            })
        } else {
            setApartmentInfo({
                block: '',
                floor: '',
                room: '',
                role: ''
            })
        }
        setIsEditApartmentRow(false);
        setRowSelecting(-1);
        setApartments(data);
    }

    const onSaveSelectedClick = () => {
        setIsEditApartmentRow(false);
        setRowSelecting(-1);
        setApartmentInfo({
            block: '',
            floor: '',
            room: '',
            role: ''
        });
        onSaveClick(apartments);
    }

    const onCancelSelectedClick = () => {
        if (isEditApartmentRow) {
            if (subAccount) {
                setApartmentInfo({
                    block: '',
                    floor: '',
                    room: '',
                    role: 'member'
                })
            } else {
                setApartmentInfo({
                    block: '',
                    floor: '',
                    room: '',
                    role: ''
                })
            }
        }
        setIsEditApartmentRow(false);
        setRowSelecting(-1);
        onCancelClick();
    }

    const onApartmentClick = (value, key) => {
        setRowSelecting(key);
        setIsEditApartmentRow(true);
        setApartmentInfo({
            block: value.block,
            floor: value.floor,
            room: value.room,
            role: value.role
        })
        setOldSelectedApartment({
            block: value.block,
            floor: value.floor,
            room: value.room,
            role: value.role
        })
    }

    const deleteApartmentClick = (apart) => {
        const data = apartments.filter(item => item.block !== apart.block || item.floor !== apart.floor || item.room !== apart.room || item.role !== apart.role);
        if (data.length === 0) setApartmentInfo({ ...apartmentInfo, block: '', floor: '', room: '' });
        setApartments(data);
        setRowSelecting(-1);
        setIsEditApartmentRow(false);
    }

    const grayOutAddButton = () => {
        var flag = false;
        if (subAccount) {
            if (apartmentInfo.block === '' || apartmentInfo.floor === '' || apartmentInfo.room === '') {
                flag = true;
            }
        } else {
            if (apartmentInfo.block === '' || apartmentInfo.floor === '' || apartmentInfo.room === '' || apartmentInfo.role === '') {
                flag = true;
            }
        }
        return flag;
    }

    const getApartments = () => {
        return apartments.map((value, key) => {
            return (
                <div
                    key={key}
                    className={rowSelecting === key ? classes.selectingRow : classes.rowApartmentBlock}
                >
                    <p className={classes.apartmentRowStyle} onClick={() => onApartmentClick(value, key)}>
                        <HomeOutlinedIcon className={classes.homeIconStyle} />
                        {value.block}{value.floor}{value.room} - {value.role == 'host' ? t('Chủ hộ') : t('Người trong gia đình')}
                    </p>
                    <p className={classes.closeIconBlock} >
                        <CloseIcon
                            onClick={() => deleteApartmentClick(value)}
                            className={classes.closeIconStyle}
                        />
                    </p>
                </div>
            )
        })
    }

    return (
        <Dialog
            open={isChooseApartment}
            maxWidth="xl"
        >
            <DialogContent className={classes.dialogContent}>
                <div>
                    <p className={classes.base_info_title}>{title}</p>
                    <Divider className={classes.dividerStyle} />
                    <div className={classes.inputRightBlock}>
                        <label className={classes.label} style={{ marginBottom: '0' }}>Block <span className={classes.redColor}>(*)</span></label>
                        <Autocomplete
                            value={apartmentInfo.block}
                            classes={{
                                root: classes.input
                            }}
                            className={classes.height40}
                            size="small"
                            clearText={t("Xóa")}
                            noOptionsText={t("Không có dữ liệu")}
                            openText={t("Mở")}
                            options={blocks}
                            getOptionSelected={(option, value) => {
                                return true;
                            }}
                            renderInput={(params) => <TextField {...params} placeholder="Block" variant="outlined" />}
                            onChange={(e, newValue) => onBlockChange(e, newValue)}
                        />
                    </div>
                    <div className={classes.inputRightBlock}>
                        <label className={classes.label} style={{ marginBottom: '0' }}>{t("Tầng")} <span className={classes.redColor}>(*)</span></label>
                        <Autocomplete
                            value={apartmentInfo.floor}
                            classes={{
                                root: classes.input
                            }}
                            className={classes.height40}
                            size="small"
                            clearText={t("Xóa")}
                            noOptionsText={t("Không có dữ liệu")}
                            openText={t("Mở")}
                            getOptionSelected={(option, value) => {
                                return true;
                            }}
                            options={floors}
                            renderInput={(params) => <TextField {...params} placeholder={t("Tầng")} variant="outlined" />}
                            onChange={(e, newValue) => onFloorChange(e, newValue)}
                        />
                    </div>
                    <div className={classes.inputRightBlock}>
                        <label className={classes.label} style={{ marginBottom: '0' }}>{t("Phòng")} <span className={classes.redColor}>(*)</span></label>
                        <Autocomplete
                            value={apartmentInfo.room}
                            classes={{
                                root: classes.input
                            }}
                            className={classes.height40}
                            size="small"
                            clearText={t("Xóa")}
                            noOptionsText={t("Không có dữ liệu")}
                            openText={t("Mở")}
                            options={rooms}
                            getOptionSelected={(option, value) => {
                                return true;
                            }}
                            renderInput={(params) => <TextField {...params} placeholder={t("Phòng")} variant="outlined" />}
                            onChange={(e, newValue) => onRoomChange(e, newValue)}
                        />
                    </div>
                    <div className={clsx(classes.select, classes.inputSelectBlock)}>
                        <FormControl
                            required
                            variant="outlined"
                            classes={{ root: classes.input }}
                        >
                            <label className={classes.label}>{t("Vai trò")} <span className={classes.redColor}>(*)</span></label>
                            <Select
                                value={subAccount ? 'member' : apartmentInfo.role}
                                value={apartmentInfo.role}
                                className={classes.height40}
                                name="role"
                                displayEmpty
                                onChange={onRoleChange}
                                disabled={subAccount}
                            >
                                <MenuItem value='' disabled >
                                    <p className={classes.placeholder} style={{ fontSize: '16px' }}> {`< ${t('Chọn')} >`} </p>
                                </MenuItem>
                                <MenuItem value='host'>{t('Chủ hộ')}</MenuItem>
                                <MenuItem value='member'>{t('Người trong gia đình')}</MenuItem>
                            </Select>
                        </FormControl>
                        <br />
                    </div>
                    <div className={classes.rowActionStyle}>
                        {
                            !isEditApartmentRow &&
                            <CreateButton
                                variant="contained"
                                onClick={onAddApartmentClick}
                                disabled={grayOutAddButton()}
                            >
                                {t('Thêm')}
                            </CreateButton>
                        }
                        {
                            isEditApartmentRow &&
                            <CreateButton
                                variant="contained"
                                onClick={onEditApartmentClick}
                                disabled={grayOutAddButton()}
                            >
                                {t('Sửa')}
                            </CreateButton>
                        }
                    </div>
                    <Divider className={classes.dividerStyle} />
                    <div>
                        {getApartments()}
                    </div>
                    <div className={classes.actionBlock}>
                        <CreateButton
                            variant="contained"
                            className={classes.saveButtonStyle}
                            onClick={onSaveSelectedClick}
                        >
                            {t("Lưu")}
                        </CreateButton>
                        <CancelButton
                            variant="outlined"
                            className={classes.cancelButtonStyle}
                            onClick={onCancelSelectedClick}
                        >
                            {t("Hủy")}
                        </CancelButton>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}