import React, { useState, useEffect, useRef } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button/Button';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import parse from 'html-react-parser';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField/TextField';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import IconButton from '@material-ui/core/IconButton/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { checkImage } from '../../validation/CheckForm';
import htmlToDraft from 'html-to-draftjs';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import withStyles from '@material-ui/core/styles/withStyles';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import SuccessAlert from '../alert/SuccessAlert';
import { apiNotification, apiPublishnotification, apiUpdatenotification } from '../../api/notification';
import { apiUploadfile } from '../../api/uploadfile';
import { Confirm, failedDialog, FailedDialog } from '../dialog';
import LargeTooltip from '../largeTooltip';

const useStyles = makeStyles((theme) => ({
    contentBottomForm: {
        width: '98%',
        background: '#FFFFFF',
        borderRadius: '6px',
        position: 'relative',
        margin: 'auto'
    },
    content: {
        width: '100%',
        margin: 'auto',
        background: '#FFFFFF',
        borderRadius: '6px'
    },
    button: {
        width: '120px',
        height: '30px',
        borderRadius: '6px',
        color: '#FFFFFF',
        fontSize: '16px',
        lineHeight: '22.4px',
        textTransform: 'none',
        marginRight: '20px'
    },
    saveBg: {
        background: '#39C22D',
        width: '100%',
        '&:hover': {
            background: '#39C22D'
        }
    },
    publishBg: {
        background: '#154FCA',
        width: '100%',
        '&:hover': {
            background: '#154FCA'
        }
    },
    deActivateBg: {
        background: '#DE265D',
        width: '100%',
        '&:hover': {
            background: '#DE265D'
        }
    },
    label: {
        fontSize: '14px',
        lineHeight: '140%',
        color: '#000000',
        display: 'inline-block',
        paddingTop: '10px'
    },
    contentValue: {
        width: '100%',
        height: '90%',
        border: '1px solid #c7c7c7',
        overflowY: 'scroll',
        paddingLeft: '10px',
        borderRadius: '6px',
        color: '#808080b3',
        fontSize: '16px'
    },
    secondaryTitle: theme.secondary_title,
    primaryColor: theme.primaryTextColor,
    fileBtn: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        left: '0',
        top: '0'
    },
    overlay: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        background: 'rgba(0,0,0,0.1)',
        zIndex: '1300'
    },
    editor: {
        '& .rdw-editor-main': {
            height: '250px',
            cursor: 'text'
        },
        '& .DraftEditor-editorContainer, .DraftEditor-root, .public-DraftEditor-content': {
            height: 'auto'
        },
        '&.rdw-editor-toolbar': {
            height: '73px',
            overflowY: 'hidden'
        }
    },
    logoBlock: {
        width: '100px',
        height: '100px',
        border: '1px solid gray',
        position: 'relative',
        '&:hover label': {
            transition: '0.4s',
            opacity: '1'
        },
        '& label': {
            opacity: '0',
            transition: '0.4s'
        }
    },
    imageAdding: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0'
    },
    requestError: {
        width: '100%',
        height: '65vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20px',
        color: '#6D6D6D'
    },
    focus: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: theme.themeColor,
            },
        },
    },
}))

const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);
const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);
const ErrorButton = withStyles(theme => ({ root: theme.error_button }))(Button);
const DeleteButton = withStyles(theme => ({
    root: {
        height: '40px',
        width: 'auto',
        textTransform: 'none',
        color: '#FFFFFF',
        fontSize: '18px',
        background: '#e01010',
        marginLeft: '16px',
        padding: '8px 16px',
        '&:hover': { background: '#e01010' }
    }
}))(Button);

const EditNotificationForm = props => {
    const { messageID, changeStatusEdit, messages } = props;
    const classes = useStyles();
    const [message, setMessage] = useState({});
    const [loading, setLoading] = useState(false);
    const [defaultStatus, setDefaultStatus] = useState(0);
    const [editor, setEditor] = useState();
    const [edit, setEdit] = useState(false);
    const [editPermission, setEditPermission] = useState(true);
    const [alert, setAlert] = useState({ avatar: '', title: '', content: '', brief_content: '', file: '', success: '', request: '' });
    const [fileStr, setFileStr] = useState('');
    const [isFailedDialog, setIsFailedDialog] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isImageAdding, setIsImageAdding] = useState(false);
    const [isFileAdding, setIsFileAdding] = useState(false);
    const [isPublish, setIsPublish] = useState(false);
    const [isDeActivated, setIsDeActivated] = useState(false);
    const [isDeActivating, setIsDeActivating] = useState(false);
    const [isExpiredSession, setIsExpiredSession] = useState(false);
    const history = useHistory();
    const notiState = useSelector(state => state.notiState);
    const dispatch = useDispatch();
    const titleRef = useRef();
    const { t } = useTranslation();

    useEffect(() => {
        if (isExpiredSession) {
            history.push('/login');
            dispatch({ type: 'IS_LOGIN_AGAIN_TRUE' });
        }
    }, [isExpiredSession]);

    const getDefaultValues = async () => {
        try {
            var res = await apiNotification({ 'id': messageID });
            if (typeof (res.data.Items[0]) === 'undefined') return;
            var data = res.data.Items[0];
            setMessage({
                id: messageID,
                title: data.Title,
                content: data.Content,
                status: data.NotificationStatus,
                brief_content: data.BriefContent,
                notification_image: data.NotificationImage
            })
            if (parseInt(data.NotificationStatus) > 2) {
                setEditPermission(false);
            } else {
                setEditPermission(true);
            }
            setDefaultStatus(data.NotificationStatus);
            const text = htmlToDraft(data.Content);
            setEditor(EditorState.createWithContent(ContentState.createFromBlockArray(text.contentBlocks, text.entityMap)));
            setAlert({ ...alert, avatar: '', title: '', content: '', brief_content: '', file: '' });
            setLoading(true);
            setEdit(false);
            setFileStr('');
            setAlert({ ...alert, request: '' })
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({ ...alert, request: t('Không lấy được dữ liệu') })
        }

    }

    useEffect(() => {
        if (!loading) getDefaultValues();
    }, [loading])

    useEffect(() => {
        if (notiState.successMessage !== '' || notiState.failedMessage !== '') {
            setLoading(false);
            setEdit(false);
        }
    }, [notiState])

    const onSaveClick = () => {
        setMessage({ ...message, status: 2 });
        if (!edit) setEdit(!edit);
    }

    const onResetFile = () => {
        setFileStr('');
    }

    const uploadFileToAws = async (file, name) => {
        try {
            var res = await apiUploadfile(file);
            const attachFile = res.data;
            var files = fileStr;
            files += `<a href=${attachFile} download >${name}</a> `;
            setFileStr(files);
            setAlert({ ...alert, file: '' });
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({ ...alert, file: t('Đã xảy ra lỗi, vui lòng thử lại') });
        }
        setIsFileAdding(false);
    }

    const onAttachFileChange = (e) => {
        if (typeof (e.target.files[0]) === 'undefined') return;
        const file = e.target.files[0];
        if (file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && file.type.indexOf('image/') === -1 && file.type !== 'application/pdf' && file.type !== 'application/vnd.ms-excel') return setAlert({ ...alert, file: t('Chỉ được đính kèm file word, excel, pdf và ảnh') });
        if (file.size > 5242880) return setAlert({ ...alert, file: 'File đã lớn hơn 5MB' });
        setIsFileAdding(true);
        const name = e.target.files[0].name;
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                const source = reader.result.slice(reader.result.indexOf('base64,') + 7);
                const file_info = {
                    source: source,
                    file_name: name,
                    content_type: e.target.files[0].type
                }
                uploadFileToAws(file_info, name);
            }
        }
        reader.readAsDataURL(e.target.files[0]);
    }

    const updateImageToAws = async (avatar) => {
        try {
            var res = await apiUploadfile(avatar);
            setMessage({ ...message, notification_image: res.data });
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({ ...alert, avatar: t('Đã xảy ra lỗi, vui lòng thử lại') });
        }
        setIsImageAdding(false);
    }

    const onAvatarChange = (e) => {
        if (typeof (e.target.files[0]) === 'undefined') return;
        if (!checkImage(e.target.files[0].type)) return setAlert({ ...alert, avatar: t('Vui lòng upload file ảnh') });
        setIsImageAdding(true);
        setAlert({ ...alert, avatar: '' });
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                const source = reader.result.slice(reader.result.indexOf('base64,') + 7);
                const avatar = {
                    source: source,
                    file_name: e.target.files[0].name
                }
                updateImageToAws(avatar);
            }
        }
        reader.readAsDataURL(e.target.files[0]);
    }

    const onTitleChange = (e) => {
        var value = e.target.value;
        setMessage({ ...message, title: value });
        if (value === '') setAlert({ ...alert, title: t('Tiêu đề không được để trống') });
        else if (value.length > 100) setAlert({ ...alert, title: t('Tiêu đề không được dài quá 100 ký tự') });
        else {
            setAlert({ ...alert, title: '' });
            if (titleRef.current) clearTimeout(titleRef.current);
            titleRef.current = setTimeout(() => {
                const existingNoti = messages.filter(item => item.Title === value.trim());
                if (existingNoti.length > 0) setAlert({ ...alert, title: t('Tiêu đề đã bị trùng') });
            }, 500);
        }
    }

    const onBriefContentChange = (e) => {
        var value = e.target.value;
        setMessage({ ...message, brief_content: value });
        if (value === '') setAlert({ ...alert, brief_content: t('Nội dung tóm tắt không được để trống') });
        else setAlert({ ...alert, brief_content: '' });
    }

    const onEditorStateChange = (editorState) => {
        setEditor(editorState);
        const value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setMessage({ ...message, content: value });
        if (value.trim() === '<p></p>') setAlert({ ...alert, content: t('Nội dung không được để trống') });
        else setAlert({ ...alert, content: '' });
    }

    const onConfirmClick = () => {
        if (alert.avatar !== '' || alert.title !== '') return;
        if (message.title === '') {
            setAlert({ ...alert, title: t('Tiêu đề không được để trống') });
            return;
        }
        if (message.brief_content === '') {
            setAlert({ ...alert, brief_content: t('Nội dung tóm tắt không được để trống') });
            return;
        }
        if (message.content === '' || message.content === '<p></p>') {
            setAlert({ ...alert, content: t('Nội dung không được để trống') });
            return;
        }
        onUpdateConfirmClick();
    }

    const publishNotification = async (data) => {
        try {
            setIsPublish(false);
            dispatch({ type: 'GET_STATUS_PUBLISHING' });
            var publishInfo = {
                id: data.id,
                title: data.title,
                brief_content: data.brief_content,
                notification_image: data.notification_image
            }
            await apiUpdatenotification(data);
            await apiPublishnotification(publishInfo);
            dispatch({
                type: 'GET_SUCCESS_MESSAGE',
                payload: t('Phát thông báo thành công!')
            })
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            dispatch({
                type: 'GET_FAILED_MESSAGE',
                payload: t('Phát thông báo không thành công, vui lòng thử lại!')
            })
        }
        dispatch({ type: 'GET_STATUS_PUBLISHING' });
    }

    const onUpdateConfirmClick = async () => {
        var files = '';
        if (fileStr !== '') {
            if (message.content.indexOf('Tệp đính kèm') !== -1) files = fileStr;
            else files = `<p>Tệp đính kèm:</p> ${fileStr}`;
        }
        const data = { ...message, content: message.content + files };
        setMessage({ ...message, content: message.content + files });
        updateNotificationStatus(data);
    }

    const updateNotificationStatus = async (data) => {
        try {
            setIsUpdating(true);
            await apiUpdatenotification(data);
            setAlert({ ...alert, success: t('Cập nhật thông báo thành công!'), error: '' });
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({ ...alert, success: ``, error: t('Cập nhật thông báo không thành công!') });
            setIsFailedDialog(true);
        }
        setLoading(false);
        setIsUpdating(false);
    }

    const onPublishClick = () => {
        setIsPublish(true);
        setMessage({ ...message, status: 3 });
    }

    const onBackPublishClick = () => {
        setIsPublish(false);
        setMessage({ ...message, status: 2 });
    }

    const onAgreePulishClick = async () => {
        await publishNotification(message);
    }

    const onDeActivateClick = () => {
        setIsDeActivated(true);
        setMessage({ ...message, status: 4 });
    }

    const onBackDeActivateClick = () => {
        setIsDeActivated(false);
        setMessage({ ...message, status: 3 });
    }

    const onAgreeDeActivateClick = async () => {
        setIsDeActivating(true);
        await updateNotificationStatus(message);
        setIsDeActivating(false);
        setIsDeActivated(false);
    }

    const onCancelClick = () => {
        setLoading(false);
        setEdit(!edit);
    }

    const onBackClick = () => {
        changeStatusEdit();
    }

    const onFailedAgreeClick = () => {
        setIsFailedDialog(false);

    }

    const onCloseMessage = () => {
        setAlert({ ...alert, success: '' })
    }

    useEffect(() => {
        if (alert.success !== '') {
            setTimeout(() => { onCloseMessage() }, 5000);
        }
    }, [alert.success])

    return (
        <div style={{ minWidth: '1080px' }} className={clsx(classes.focus)}>
            {isUpdating && <div className={classes.overlay}></div>}
            <FailedDialog 
                isOpen={isFailedDialog}
                mess={alert.error}
                onConfirm={onFailedAgreeClick}
            />
            <Confirm 
                isOpen={isDeActivated}
                handleClose={onBackDeActivateClick}
                isLoading={isDeActivating}
                mess={t('Bạn có chắc chắn muốn ngừng phát thông báo?')}
                onConfirm={onAgreeDeActivateClick}
            />
            <Confirm 
                isOpen={isPublish}
                handleClose={onBackPublishClick}
                isLoading={false}
                mess={t('Bạn có chắc chắn muốn phát thông báo?')}
                onConfirm={onAgreePulishClick}
            />
            <div style={{ paddingBottom: '8px', marginTop: '15px', width: '98%', margin: 'auto', position: 'relative', left: '-8px' }}>
                <IconButton style={{ float: 'left', padding: '2px', marginRight: '5px' }} onClick={onBackClick} >
                    <ArrowBackIosOutlinedIcon className={classes.primaryColor} style={{ width: '28px', height: '28px' }} />
                </IconButton>
                <p className={classes.secondaryTitle}>{t('CHI TIẾT BẢNG TIN')}</p>
            </div>
            {!loading && alert.request === '' && <CircularProgress style={{ position: 'absolute', left: '50%', top: '20%', color: '#0b59c8' }} />}
            {alert.request !== '' && <div className={classes.requestError}>{alert.request}</div>}
            {loading && alert.request === '' &&
                <div className={classes.contentBottomForm}>
                    <div style={{ position: 'relative' }}>
                        <SuccessAlert message={alert.success} onCloseDialog={onCloseMessage} />
                    </div>
                    <div className={classes.content}>
                        {(!edit || !editPermission) &&
                            <div style={{ width: '40%', margin: 'auto' }}>
                                <h2 style={{ fontWeight: 'bolder', marginTop: '0', paddingTop: '10px' }}>{message.title}</h2>
                                <p style={{ fontSize: '18px', color: '#6D6D6D' }}>{message.brief_content}</p>
                                {message.notification_image !== '' &&
                                    <div style={{ textAlign: 'center', padding: '14px 0' }}>
                                        <img src={message.notification_image} alt="" width="auto" height="250px" />
                                    </div>
                                }
                                <div>{parse(message.content)}</div>
                                <div style={{ width: '100%', padding: '22px 0' }}>
                                    {!edit && editPermission &&
                                        <CreateButton
                                            variant='contained'
                                            style={{ marginLeft: '0' }}
                                            onClick={() => { setEdit(!edit) }}
                                        >
                                            {t('Chỉnh sửa')}
                                        </CreateButton>}
                                    {defaultStatus === 1 && <Button variant="contained" disabled={message.status === 2} color="primary" className={clsx(classes.button, classes.saveBg)} onClick={onSaveClick} >{t('Lưu trạng thái thông báo')}</Button>}
                                    {defaultStatus === 2 && <CreateButton variant="contained" disabled={message.status === 3 || notiState.isPublishing} onClick={onPublishClick}>{t('Phát thông báo')}</CreateButton>}
                                    {defaultStatus === 3 && <DeleteButton variant="contained" disabled={message.status === 4} onClick={onDeActivateClick} style={{ margin: '0' }}>{t('Vô hiệu thông báo')}</DeleteButton>}
                                </div>
                            </div>}
                        {edit && editPermission &&
                            <div>
                                <div style={{ display: 'flex', width: '90%', margin: 'auto' }}>
                                    <div style={{ height: '150px', width: '150px', position: 'relative' }}>
                                        <label className={classes.label} >{t('Ảnh thông báo')}</label><br />
                                        <div className={classes.logoBlock}>
                                            <input accept="image/*" hidden name="avatar" id="icon-button-file" type="file" onChange={onAvatarChange} disabled={!edit || !editPermission} />
                                            {message.notification_image !== '' && <img src={message.notification_image} alt="notification-avatar" width='100%' height='100%' />}
                                            <label htmlFor="icon-button-file" className={classes.fileBtn} >
                                                <IconButton aria-label="upload picture" component="span" disabled={!edit || !editPermission}>
                                                    <PhotoCamera style={{ fontSize: '50px' }} />
                                                </IconButton>
                                            </label>
                                            {isImageAdding &&
                                                <div className={classes.imageAdding}>
                                                    <CircularProgress size={20} style={{ color: '#0b59c8' }} />
                                                </div>}
                                        </div>
                                        <p style={{ color: 'red', position: 'absolute', width: '200px' }}>{alert.avatar}</p>
                                    </div>
                                    <div style={{ width: '90%', height: '100px', float: 'left' }}>
                                        <label className={classes.label}>{t('Tiêu đề')} <span style={{ color: 'red' }}>(*)</span></label><br />
                                        <TextField
                                            placeholder={t("Tiêu đề thông báo")}
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            size="small"
                                            inputProps={{
                                                style: { fontWeight: 'bold' }
                                            }}
                                            onChange={onTitleChange}
                                            value={message.title}
                                            helperText={alert.title}
                                            error={alert.title !== ''}
                                        />
                                    </div>
                                </div>
                                <div style={{ width: '90%', margin: 'auto', height: '100px', clear: 'both' }}>
                                    <label className={classes.label}>{t('Nội dung tóm tắt')} <span style={{ color: 'red' }}>(*)</span></label><br />
                                    <TextField
                                        placeholder={t("Nội dung tóm tắt")}
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        size="small"
                                        onChange={onBriefContentChange}
                                        value={message.brief_content}
                                        helperText={alert.brief_content}
                                        error={alert.brief_content !== ''}
                                    />
                                </div>
                                <div style={{ width: '90%', margin: 'auto', height: '400px' }}>
                                    <label className={classes.label}>{t('Nội dung')} <span style={{ color: 'red' }}>(*)</span></label><br />
                                    <div style={{ border: '1px solid rgb(210 203 203)', borderRadius: '6px', height: '350px', padding: '5px' }} className={classes.editor}>
                                        <Editor
                                            editorState={editor}
                                            onEditorStateChange={onEditorStateChange}
                                        />
                                    </div>
                                    <p style={{ color: 'red' }}>{alert.content}</p>
                                </div>
                                <div style={{ border: '1px solid rgba(128, 128, 128, 0.39)', width: '90%', margin: 'auto', borderRadius: '6px', marginTop: '18px', position: 'relative' }}>
                                    <input accept=".xlsx, .xls, .csv ,image/*, .doc, .docx" hidden name="avatar" id="upload-file" type="file" onChange={onAttachFileChange} />
                                    <label htmlFor="upload-file" style={{ paddingTop: '5px' }}>
                                        <LargeTooltip title={t("Đính kèm tệp")}>
                                            <IconButton aria-label="upload picture" component="span" style={{ padding: '0' }}>
                                                <AttachFileIcon style={{ fontSize: '20px' }} />
                                                {isFileAdding && <CircularProgress size={20} style={{ position: 'absolute', color: '#0b59c8' }} />}
                                            </IconButton>
                                        </LargeTooltip>
                                        {parse(fileStr)}
                                    </label>
                                    {fileStr !== '' &&
                                        <LargeTooltip title={t("Xóa tất cả tệp đính kèm")}>
                                            <IconButton style={{ position: 'absolute', right: '3px', padding: '0', top: '5px' }} onClick={onResetFile}>
                                                <CloseIcon style={{ fontSize: '20px' }} />
                                            </IconButton>
                                        </LargeTooltip>}
                                    <p style={{ color: 'red', fontSize: '13px', position: 'absolute', bottom: '-20px', left: '0' }}>{alert.file}</p>
                                </div>
                                <div style={{ width: '90%', margin: 'auto', padding: '24px 0', textAlign: 'right' }}>
                                    <CancelButton
                                        variant='outlined'
                                        onClick={onCancelClick}
                                    >
                                        {t('Hủy')}
                                    </CancelButton>
                                    <CreateButton
                                        variant='contained'
                                        onClick={onConfirmClick}
                                        disabled={isUpdating}
                                    >
                                        {t('Lưu')}
                                        {isUpdating && <CircularProgress size={25} style={{ position: 'absolute', color: '#0b59c8' }} />}

                                    </CreateButton>
                                </div>
                            </div>}
                    </div>
                </div>}
        </div>
    )
}

export default EditNotificationForm;
