import AxiosClient from "../config/AxiosClient";

export const getResidents = async (bodyRequest) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') url = '/info';
    else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') url = '/residences/get';
    return await AxiosClient.post(url, bodyRequest);
}

export const getResident = async (bodyRequest) => {
    // var url = '';
    // if (process.env.REACT_APP_DEPLOY_ENV === 'aws') url = '/info';
    // else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') url = '/residences/get';
    // return await AxiosClient.post(url, bodyRequest);
}
export const addResident = async (bodyRequest) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') url = '/register';
    else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') url = '/residences/create';
    return await AxiosClient.post(url, bodyRequest);
}

export const deleteResidents = async (bodyRequest) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') url = '/deleteusers';
    else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') url = '/residences/delete';
    return await AxiosClient.post(url, bodyRequest);
}

export const syncResidents = async (bodyRequest) =>{
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') url = '/unified/sync';
    else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') url = '/residences/sync';
    return await AxiosClient.post(url, bodyRequest);
}

export const updateResident = async (bodyRequest) =>{
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') url = '/userupdate';
    else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') url = '/residences/update';
    return await AxiosClient.post(url, bodyRequest);
}

export const checkExists = async (bodyRequest) =>{
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') url = '/exists';
    else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') url = '/residences/exist';
    return await AxiosClient.post(url, bodyRequest);
}

 



