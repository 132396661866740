import makeStyles from '@material-ui/styles/makeStyles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    root: theme.dataGridStyle,
    manage: {
        // padding: "22px 1rem 2rem 1rem",
        minHeight: "100%"
    },
    manageHeader: {
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: "1.5rem"
    },
    wrapTitle: {
        display: "flex",
        alignItems: "center"
    },
    wrapTitleIcon: {
        marginRight: "0.5rem",
        color: "#0b59c8",
        cursor: "pointer"
    },
    select: {
        width: "250px",
        height: "40px",
        '& div': {
            background: "unset !important"
        }
    },
    selectNoneBorder: {
        width: "auto",
        maxWidth: '250px',
        overflow: 'hidden',
        '&::before': {
            border: 'unset !important'
        },
        '&::after': {
            border: 'unset !important'
        },
    },
    menuItem: {
        maxWidth: "250px",
        "& p": {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden"
        }
    },
    manageAction: {
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: "1rem",
        borderBottom: "1px solid #E0E0E0"
    },
    ActionBtn: {
        display: "flex"
    },
    wrapExtendFilter: {
        // position: "relative",
        marginLeft: "1rem"
    },
    filterBtn: {
        marginLeft: "1rem !important"
    },
    filterShow: {
        display: "flex",
        alignItems: "center",
        marginBottom: "1rem",
    },
    showTitle: {
        marginTop: "1rem",
        minWidth: "fit-content",
        fontWeight: "700",
        fontSize: "16px"
    },
    showDetails: {
        display: "flex",
        flexWrap: "wrap"
    },
    showItem: {
        marginTop: "1rem",
        marginLeft: "1rem",
        padding: "0.5rem",
        borderRadius: "16px",
        width: "fit-content",
        background: "#EBEBEB"
    },
    showItemKey: {
        color: "#616161"
    },
    showItemValue: {
        fontWeight: "600"
    },
    showItemDelete: {
        fontSize: "1.25rem !important",
        cursor: "pointer"
    },
    table: {
        position: 'relative',
        borderRadius: '6px',
        paddingBottom: '16px',
        width: '100%',
        height: 'calc(100vh - 275px)',
        minHeight: "500px",
        background: '#FFFFFF',
        
        "& .Mui-even.MuiDataGrid-row, .Mui-odd.MuiDataGrid-row": {
            cursor: "pointer"
        }
    },
    statusColumnpending: {
        "&::before": {
            content: "''",
            position: "absolute",
            top: "calc(50% - 4px)",
            left: "0",
            width: "8px",
            height: "8px",
            borderRadius: "8px",
            background: "#EE8306"
        }
    },
    statusColumnpaid: {
        "&::before": {
            content: "''",
            position: "absolute",
            top: "calc(50% - 4px)",
            left: "0",
            width: "8px",
            height: "8px",
            borderRadius: "8px",
            background: "#29A62B"
        }
    },
    statusColumncancelled: {
        "&::before": {
            content: "''",
            position: "absolute",
            top: "calc(50% - 4px)",
            left: "0",
            width: "8px",
            height: "8px",
            borderRadius: "8px",
            background: "#ED1626"
        }
    },
    total: {
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: "10%",
        fontSize: "1.5rem"
    },
    filter: {
        position: 'relative',
        padding: "1rem",
        width: "500px"
    },
    filterTitle: {
        fontWeight: "600",
        fontSize: "20px",
        paddingBottom: "1rem",
        borderBottom: "1px solid #E0E0E0"
    },
    filterClose: {
        position: "absolute",
        right: "1rem",
        top: "1rem",
        cursor: "pointer"
    },
    filterLabel: {
        marginTop: "1rem",
        fontWeight: "600",
        fontSize: "16px"
    },
    filterRadioGroup: {
        display: "flex",
        flexDirection: "row !important"
    },
    filterWrapDate: {
        display: "flex",
        justifyContent: "space-between"
    },
    filterDate: {
        width: "48%"
    },
    filterAct: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "2rem"
    },
}));