import React, { useState, useEffect } from 'react';
import EditDeviceConfig from './EditDeviceConfig';
import EditAdvanceConfig from './EditAdvanceConfig';
import Tabs from '@material-ui/core/Tabs/Tabs';
import Divider from '@material-ui/core/Divider';
import Tab from '@material-ui/core/Tab/Tab';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Logs from './Logs';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import SnackbarContent from '@mui/material/SnackbarContent';
import Alert from '@mui/material/Alert';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SuccessAlert from '../../alert/SuccessAlert';
import InfoAlert from '../../alert/InfoAlert';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button/Button';
import SubDetailTitle from '../../title/subDetailTitle/index';
import { PageLoading } from '../../loading';
import { FailedDialog } from '../../dialog';
import { apiDeviceGet, apiDevicePing, apiDeviceUpdate, apiDeviceLogs } from '../../../api/device';
import { apiTableImportStatus } from '../../../api/table';

const useStyles = makeStyles((theme) => ({
    table: {
        width: '98%',
        margin: 'auto',
        minWidth: '1080px'
    },
    indicator: {
        backgroundColor: '#0b59c8',
        height: '4px',
        borderRadius: '6px 6px 0 0'
    },
    fontTab: {
        color: '#000000',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '22.4px',
        textTransform: 'none'
    },
    requestError: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20px',
        color: '#6D6D6D'
    },
    tabBlock: {
        width: '98%',
        margin: 'auto',
        marginBottom: '16px'
    }
}));

const ErrorButton = withStyles(theme => ({ root: theme.error_button }))(Button);

const Edit = (props) => {
    const { id, onEditStatusChange } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const [tabValue, setTabValue] = useState(0);
    const [status, setStatus] = useState('online');
    const [deviceId, setDeviceId] = useState('');
    const [deviceInfo, setDeviceInfo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState('');
    const [alert, setAlert] = useState({ success: '', error: '', info: '' });
    const [isProcessing, setIsProcessing] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [checkApiGetLog, setCheckApiGetLog] = useState(false);
    const [countCheckLog, setCountCheckLog] = useState(0);
    const [isExpiredSession, setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (isExpiredSession) {
            history.push('/login');
            dispatch({ type: 'IS_LOGIN_AGAIN_TRUE' });
        }
    }, [isExpiredSession]);

    const getDeviceInfo = async () => {
        try {
            let res = await apiDeviceGet({ id: id });
            const data = res.data.Items[0];
            setStatus(data.DeviceStatus);
            setDeviceId(data.DeviceID);
            setDeviceInfo(data);
            setLoading(true);
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setRequest(t('Không lấy được dữ liệu'));
        }
    }

    useEffect(() => {
        if (!loading) getDeviceInfo();
    }, [id, loading])

    const onRefresh = () => {
        setLoading(false);
    }

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const pingDevice = async (deviceId) => {
        try {
            setIsProcessing(true);
            await apiDevicePing({ deviceId: deviceId });
            setAlert({ ...alert, success: t('PING thành công!'), error: '' });
            setLoading(false);
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({ ...alert, error: t('PING không thành công, vui lòng thử lại sau!'), success: '' })
            setIsFailed(true);
        }
        setIsProcessing(false);
    }

    const retryDevice = async () => {
        try {
            setIsProcessing(true);
            await apiDevicePing({ 'deviceId': deviceId });
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
        }
        setLoading(false);
        setIsProcessing(false);
    }

    const updateDevice = async (device) => {
        try {
            setIsProcessing(true);
            let res = await apiDeviceUpdate(device);
            switch (res.data) {
                case 'Can not pubish new configuration to TAccess Device':
                    setAlert({ ...alert, error: t('Kết nối đến thiết bị thất bại, vui lòng thử lại sau!'), success: '' });
                    setIsFailed(true);
                    break;
                default:
                    setAlert({ ...alert, success: t('Đã cập nhật cấu hình thành công!'), error: '' });
                    setLoading(false);
                    break;
            }
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({ ...alert, error: t('Cập nhật cấu hình không thành công, vui lòng thử lại!'), success: '' })
            setIsFailed(true);
        }
        setIsProcessing(false);
    }

    const onGetLogClick = async () => {
        try {
            await apiDeviceLogs({ deviceId: deviceId });
            setAlert({ ...alert, info: t('Lấy Log đang trong quá trình xử lý') });
            setCountCheckLog(countCheckLog + 1);
            setCheckApiGetLog(true);
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({ ...alert, success: '', error: t('Đã xảy ra lỗi, vui lòng thử lại sau!') });
        }
        setLoading({ table: true, page: true });
    }

    const checkGetLogStatus = async () => {
        try {
            const status = await apiTableImportStatus({ 'deviceId': deviceId });
            const data = status.data.Items[0];
            if (data.Action === 'GetLogs' && data.ActionStatus === 'Done') {
                setAlert({ ...alert, success: t('Lấy log thành công!'), info: '' });
                setLoading({ ...loading, table: false });
                setCheckApiGetLog(false);
            }
            if (data.Action === 'GetLogs' && data.ActionStatus === 'Failed') {
                setAlert({ ...alert, success: '', error: t('Lấy log không thành công, vui lòng thử lại sau!') });
                setIsFailed(true);
            }
        } catch (error) {
            if (typeof (error.response) === 'undefined') return;
            if (error.response.data === 'Invalid LoginToken') history.push('/login');
            setAlert({ ...alert, success: '', error: t('Đã xảy ra lỗi, vui lòng thử lại sau') + '!' });
            setIsFailed(true);
        }
    }

    useEffect(() => {
        if (countCheckLog === -1) return;
        if (countCheckLog > 20) {
            setAlert({ ...alert, success: '', info: '', error: t('Lấy log không thành công, vui lòng thử lại sau!') });
            setIsFailed(true);
            setCountCheckLog(-1);
            return;
        }
        if (checkApiGetLog) {
            checkGetLogStatus();
            setTimeout(() => {
                setCountCheckLog(countCheckLog + 1);
            }, 18000);
        }

    }, [countCheckLog, checkApiGetLog])

    const onCloseDialog = () => {
        setAlert({ ...alert, success: '', error: '' });
    }

    useEffect(() => {
        if (alert.success !== '') {
            setTimeout(() => {
                onCloseDialog();
            }, 5000);
        }
    }, [alert.success])

    const alertOffline = () => {
        const vertical = 'top';
        const horizontal = 'center';
        return (
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={status === 'offline'}
                TransitionComponent={Slide}
            >
                <SnackbarContent
                    style={{ background: 'rgb(255, 244, 229)', color: 'rgb(102, 60, 0)' }}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            onClick={() => setStatus('')}
                        >
                            <CloseIcon />
                        </IconButton>
                    }
                    message={<Alert severity="warning" style={{ background: 'rgb(255, 244, 229)', color: 'rgb(102, 60, 0)' }}>{t('Thiết bị ngoại tuyến')}</Alert>}
                />
            </Snackbar>
        )
    }

    return (
        <div>
            <FailedDialog
                isOpen={isFailed}
                mess={alert.error}
                onConfirm={() => setIsFailed(false)}
            />
            <SuccessAlert
                message={alert.success}
                onCloseDialog={onCloseDialog}
            />
            <InfoAlert
                message={alert.info}
                onCloseDialog={() => setAlert({ ...alert, info: '' })}
            />
            <SubDetailTitle
                onClick={onEditStatusChange}
                text={t("Chi tiết")}
            />
            <div className={classes.tabBlock}>
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    style={{ color: 'green' }}
                    classes={{ indicator: classes.indicator }}
                >
                    <Tab
                        classes={{ root: classes.fontTab }}
                        style={tabValue === 0 ? { color: '#0b59c8', fontWeight: 'bold' } : { color: '#000000', fontWeight: 'bold' }}
                        label={t("Thông tin")}
                    />
                    <Tab
                        classes={{ root: classes.fontTab }}
                        style={tabValue === 1 ? { color: '#0b59c8', fontWeight: 'bold' } : { color: '#000000', fontWeight: 'bold' }}
                        label={t("Logs")}
                    />
                    <Tab
                        classes={{ root: classes.fontTab }}
                        style={tabValue === 2 ? { color: '#0b59c8', fontWeight: 'bold' } : { color: '#000000', fontWeight: 'bold' }}
                        label={t("Nâng cao")}
                    />
                </Tabs>
                <Divider />
            </div>
            {request !== '' && <div className={classes.requestError}>{request}</div>}
            {!loading && request === '' && <PageLoading />}
            {loading && request === '' && <div>
                {alertOffline()}
                {tabValue === 0 &&
                    <EditDeviceConfig
                        deviceInfo={deviceInfo}
                        onRefresh={onRefresh}
                        updateDevice={updateDevice}
                        isProcessing={isProcessing}
                        pingDevice={pingDevice}
                        onGetLogClick={onGetLogClick}
                        retryDevice={retryDevice}
                    // blockData={blocks}
                    // receptionistData={receptionists}
                    />}
                {tabValue === 1 && <Logs deviceId={deviceId} />}
                {tabValue === 2 &&
                    <EditAdvanceConfig
                        deviceInfo={deviceInfo}
                        onRefresh={onRefresh}
                        updateDevice={updateDevice}
                        isProcessing={isProcessing}
                        retryDevice={retryDevice}
                    />}
            </div>}
        </div>
    )
}

export default Edit;