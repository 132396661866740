import makeStyles from "@material-ui/core/styles/makeStyles";
import { FONT_FAMILY } from "../constants/cssCommon";

export const accessStyles = makeStyles((theme)=>({  
    tableTitle:{borderRadius:'6px 6px 0 0',fontSize:'16px',lineHeight:'22.4px',height:'52px',background:'#FFFFFF',color:'#000000',fontWeight:'400',marginBottom:'0',position:'relative',borderBottom:'1px solid #E0E0E0'},
    cancelBtn:theme.cancel_button,
    dialogTitleBackground:theme.primaryColor,
    dialogTitle:{textAlign:'center',fontSize:'20px',fontWeight:'600',lineHeight:'25px',color:'#FFFFFF',fontFamily:FONT_FAMILY},
    contentDialog:{fontSize:'16px',height:'68px',borderBottom:'1px solid #EEEDF2',display:'flex',alignItems:'center',justifyContent:'center'},
    tableContent:{paddingBottom:'50px',width:'100%',minWidth:'980px',height:'690px',background:'#FFFFFF',borderRadius:'6px',position:'relative'},
    root:theme.dataGridStyle,
    requestError:{width:'100%',height:'65vh',display:'flex',justifyContent:'center',alignItems:'center',fontSize:'20px',color:'#6D6D6D'},
    overlay:{position:'fixed',width:'100%',height:'100%',top:'0',left:'0',background:'rgba(0,0,0,0.1)',zIndex:'1300'},
    selectInput:{
        '& .MuiInput-underline:before':{display:'none'},
        '& .MuiInput-underline:after':{display:'none'}
    },
    noUser:{height:'200px',display:'flex',alignItems:'center',fontSize:'20px',color:'#6D6D6D'},
    filter:{position:'absolute',top:'100%',left:'0',width:'700px',height:'100px',zIndex:'1'},
    smallerFilter:{position:'absolute',top:'100%',left:'0',width:'100%',height:'100px',zIndex:'1'},
    inputRightBlock:{height:'80px',width:'200px',position:'relative',float:'left',marginLeft:'1%'},
    label:{paddingBottom:'0px',paddingTop:'8px',fontSize: '14px',lineHeight: '140%',fontWeight:'bold',color: '#000000',display: 'inline-block',margin:'0'},
    inputHeight:{
        textAlign:'left',height:'80px',margin:'10px 0',position:'relative',
        '& .MuiOutlinedInput-root':{height:'32px'}
    },
    placeholder:{color:'#B2B2B2',fontSize:'16px'},
    fontNunito:{
        '& .MuiInputBase-root':theme.fontNunito
    },
    select:{
        '& .MuiOutlinedInput-input':{padding:'8px 12px'}
    },
    focus:{
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': { 
                borderColor: theme.themeColor,
            },
        },
    },
    secondaryTitle:theme.secondary_title,
    degree37:{display:'flex',justifyContent:'center',alignItems:'center',color:'#FFFFFF',height:'30px',padding:'2px 10px 0px 10px',borderRadius:'15px',fontFamily: FONT_FAMILY,background:'rgb(42, 185, 48)'},
    degree38:{display:'flex',justifyContent:'center',alignItems:'center',color:'#FFFFFF',height:'30px',padding:'2px 10px 0px 10px',borderRadius:'15px',fontFamily: FONT_FAMILY,background:'rgb(255, 152, 56)'},
    degree39:{display:'flex',justifyContent:'center',alignItems:'center',color:'#FFFFFF',height:'30px',padding:'2px 10px 0px 10px',borderRadius:'15px',fontFamily: FONT_FAMILY,background:'#e01010'},
}))

export const adminStyles = makeStyles(theme => ({
    content:{width:'98%',minWidth:'1080px', margin:'auto',borderRadius:'6px',position:'relative'},
    createBtn:theme.create_button,   
    closeBtn:theme.cancel_button,
    primaryTitle:theme.primary_title,
    dialogTitleBackground:theme.primaryColor,
    primaryTextColor:theme.primaryTextColor,
    topBlock:{marginTop:'22px',minWidth:'1080px',marginBottom:'20px',height:'40px'},
    selectTitle:{fontSize:'16px',lineHeight:'22.4px',float:'left',margin:'16px 0', padding:'0 16px'},
    dialogTitle:{textAlign:'center',fontSize:'20px',fontWeight:'600',lineHeight:'25px',color:'#FFFFFF',fontFamily:FONT_FAMILY},
    contentDialog:{fontSize:'16px',height:'68px',borderBottom:'1px solid #EEEDF2',display:'flex',alignItems:'center',justifyContent:'center'},
    actionTable:{width:'100%',height:'56px',background:'#FFFFFF',borderBottom:'1px solid #E0E0E0',borderRadius:'6px 6px 0 0',position:'relative'},
    action:{fontSize:'16px',fontWeight:'600',color:'#0b59c8',textTransform:'none',position:'relative',left:'-5px'},
    tableContent:{margin:'auto',float:'left',height:'620px',background:'#FFFFFF',borderRadius:'6px',paddingBottom:'55px',
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    root:theme.dataGridStyle,
    requestError:{width:'100%',height:'65vh',display:'flex',justifyContent:'center',alignItems:'center',fontSize:'20px',color:'#6D6D6D'},
    overlay:{position:'fixed',width:'100%',height:'100%',top:'0',left:'0',background:'rgba(0,0,0,0.2)',zIndex:'1300',display:'flex',justifyContent:'center',alignItems:'center'},
    label:{fontSize: '14px',lineHeight: '140%',color: '#000000',display: 'inline-block',paddingTop:'10px'},
    formContent:{width:'35%',background:'#FFFFFF',float:'left',marginLeft:'1%', borderRadius:'6px',position:'relative',height:'450px'},
    addFormContent:{width:'35%',background:'#FFFFFF',float:'left',marginLeft:'1%', borderRadius:'6px',position:'relative'},
    secondaryTitle:theme.secondary_title,   
    font:{fontSize:'16px',lineHeight:'22.4px',color:'#6D6D6D'},
    status:{color:'#6D6D6D',paddingBottom:'0px'},
    statusActive:{color:'#2AB930',paddingBottom:'0px'},
    statusDeActived:{color:'#e01010',paddingBottom:'0px'},
    fontNunito:{
        '& .MuiInputBase-root':theme.fontNunito
    },
    alertStyle:{position:'absolute',right:'9px',bottom:'8px',padding:'0 10px',zIndex:'10'},
    focus:{
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': { 
                borderColor: theme.themeColor,
            },
        },
    },
    select:{
        '& .MuiOutlinedInput-input':{padding:'8px 12px'}
    },
}))

export const loginStyles = makeStyles((theme)=>({
    paper:{width:'460px',borderRadius:'6px',margin:'10px'},
    title:{textAlign:'center',padding:'24px 0 10px 0',color:'#072A3B',fontFamily:FONT_FAMILY, fontWeight:'500'},
    inputBlock:{width:'80%', margin:'auto',height:'110px'},
    input:{width:'100%'},
    action:{width:'80%',padding:'24px 0',margin:'auto',textAlign:'center'},
    signinAction:{
        marginRight:'10%',
        backgroundColor:'#0b59c8',
        width:'100%',
        height:'56px',
        marginBottom:'10px',
        fontSize:'22px!important',
        color:'#FFFFFF',
        '&:hover':{
            background:'#0b59c8'
        }
    },
    label:{fontSize:'16px',color:'#6D6D6D',lineHeight:'22.4px',display:'block'},
    forgetPassword:{color:'#0b59c8',fontSize:'16px',lineHeight:'22.4px',cursor:'pointer'},
    createNew:{height:'45px',textAlign:'center',color:'#6D6D6D',fontSize:'16px',lineHeight:'22.4px'},
    overlay:{position:'fixed',width:'100%',height:'100%',top:'0',left:'0',background:'rgba(0,0,0,0.1)',zIndex:'1300'},
    dialogFailedTitleBackground:theme.errorColor,
    errorBtn:theme.error_button,
    dialogTitle:{textAlign:'center',fontSize:'20px',fontWeight:'600',lineHeight:'25px',color:'#FFFFFF',fontFamily:FONT_FAMILY},
    contentDialog:{fontSize:'16px',height:'68px',borderBottom:'1px solid #EEEDF2',display:'flex',alignItems:'center',justifyContent:'center'},
    fontNunito:{
        '& .MuiInputBase-root':theme.fontNunito
    },
    dialogTitleBackground:theme.primaryColor,
}))