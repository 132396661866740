import React, { useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { apiCabinetsTypesDateChartsGet } from '../../../../api/cabinets';
import { useStyles } from './styles';

function CabinetSizeBar({ date, cabinetId, handleToggleCabinetSizeBarLoading, isLoading }) {
    const [state, setState] = useState({
        dataBar: [
            // {
            //     id: '0',
            //     labels: [],
            //     cellName: '',
            //     datasets: [
            //         {
            //             data: [],
            //             backgroundColor: '#24CA72',
            //         }
            //     ],
            // },
        ]
    });

    const { t } = useTranslation();
    const classes = useStyles();
    const moment = require('moment-timezone');

    const fetchApiCabinetsTypesDateChartsGet = async (body) => {
        handleToggleCabinetSizeBarLoading();

        try {
            const { data } = await apiCabinetsTypesDateChartsGet(body);
            const dataBar = [];

            if (Array.isArray(data)) {
                data.forEach(item => {
                    let labels = item.labels;

                    if (labels.length > 25) {
                        labels = item.labels.map(labelsItem => {
                            labelsItem = moment.tz(labelsItem, "Asia/Ho_Chi_Minh").format("DD/MM");
                            return labelsItem;
                        });
                    }

                    dataBar.push({
                        id: item.cellTypeId,
                        labels: labels,
                        cellName: item.cellTypeName.name,
                        datasets: [{
                            data: [...item.values],
                            backgroundColor: '#24CA72',
                        }]
                    })
                })
            }

            setState(prev => ({
                ...prev,
                dataBar: dataBar || []
            }));
        } catch (error) {

        }

        handleToggleCabinetSizeBarLoading();
    }

    useEffect(() => {
        if (!date || !cabinetId) return;

        fetchApiCabinetsTypesDateChartsGet({ date, cabinetId })
    }, [date, cabinetId])

    const ArrayCabinetSizeBar = useMemo(() => (
        <>
            {
                state.dataBar.map(item => (
                    <div key={item.id} className={classes.barItem}>
                        <Bar
                            data={item}
                            width={500}
                            height={200}
                            options={{
                                plugins: {
                                    legend: {
                                        display: false,
                                        position: 'bottom'
                                    }
                                },
                                maintainAspectRatio: true,
                                scales: {
                                    y: {
                                        beginAtZero: true,
                                        suggestedMin: 0,
                                        suggestedMax: 10,
                                        stacked: true,
                                        ticks: {
                                            display: true,
                                            callback: function (value, index, values) {
                                                return value < 1000 ? value : value / 1000 + 'k';
                                            }
                                        },
                                        grid: {
                                            display: true
                                        }

                                    },
                                    x: {
                                        stacked: true,
                                        grid: {
                                            display: false
                                        }
                                    }
                                },

                            }}
                        />
                        <p className={classes.barItemLabel}>{item.cellName}</p>
                    </div>
                ))
            }
        </>
    ), [state.dataBar])

    return (
        <div className={classes.containBar}>
            <div
                className={classes.bar}
                style={{
                    height: state.dataBar.length === 0 && '278px',
                    alignItems: state.dataBar.length === 0 && 'center'
                }}
            >
                {!isLoading && state.dataBar.length === 0 && (
                    <p>{t("Chưa có dữ liệu")}</p>
                )}

                {ArrayCabinetSizeBar}
            </div>
        </div>
    )
}

export default React.memo(CabinetSizeBar);