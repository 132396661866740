import React, {useState,useEffect,useRef,useCallback} from 'react';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import {useTranslation} from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton/IconButton';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button/Button';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import axiosClient from '../../../config/AxiosClient';
import Cookies from 'js-cookie';
import { checkImage, isEmpty } from '../../../validation/CheckForm';
import Alert from '@material-ui/lab/Alert';
import { useDispatch } from 'react-redux';
import {useHistory,Link } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const useStyles = makeStyles((theme)=>({
    title:{fontSize:'20px',fontWeight:'700',lineHeight:'27px',color:'#000000',fontStyle:'normal',float:'left',marginRight:'24px',paddingTop:'5px'},
    inputBlock:{paddingLeft:'22px',position:'relative',height:'140px'},
    label:{fontSize: '16px',lineHeight: '140%',color: '#000000',display: 'inline-block',width:'250px',margin:'0'},
    alert:{position:'absolute',right:'3px',top:'3px',width:'350px'},
    secondaryTitle:theme.secondary_title,
    overlay:{position:'fixed',width:'100%',height:'100%',top:'0',left:'0',background:'rgba(0,0,0,0.1)',zIndex:'1300'},
    primaryColor:theme.primaryTextColor,
    dialogTitle:{textAlign:'center',fontSize:'20px',fontWeight:'600',lineHeight:'25px',color:'#FFFFFF'},
    contentDialog:{fontSize:'16px',height:'68px',borderBottom:'1px solid #EEEDF2',display:'flex',alignItems:'center',justifyContent:'center'},
    dialogTitleBackground:theme.primaryColor,
    errorColor:theme.errorColor,
    imageDialog:{position:'fixed',width:'100%',height:'100%',top:'0',left:'0',background:'rgba(0,0,0,0.1)',zIndex:'1333',display:'flex',justifyContent:'center',alignItems:'center',minWidth:'550px'},
}))

const CancelButton = withStyles(theme =>({root:theme.cancel_button}))(Button);
const CreateButton = withStyles(theme =>({root:theme.create_button}))(Button);
const ErrorButton = withStyles(theme =>({root:theme.error_button}))(Button);

const AddAd = () =>{
    const classes = useStyles();
    const {t} = useTranslation();
    const [type,setType] = useState('upload');
    const [ad,setAd] = useState({description:'',image:'',url:'',content_type:'',file_name:'',time:15});
    const [alert,setAlert] = useState({description:'',image:'',url:'',time:''});
    const [warning,setWarning] = useState('');
    const [showFileName,setShowFileName] = useState('');
    const [isAdding,setIsAdding] = useState(false);
    const [isSuccessDialog,setIsSuccessDialog] = useState(false);
    const [isFailed,setIsFailed] = useState(false);
    const [isExpiredSession,setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const [upImg, setUpImg] = useState();
    const [isCrop,setIsCrop] = useState(false);
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState({ unit: "px", width:32,height:10, aspect: 16 / 5 });
    const [completedCrop, setCompletedCrop] = useState(null);
    const [base64Image,setBase64Image] = useState('');
    const [imageType,setImageType]= useState('');
    const [autoScale,setAutoScale] = useState(false);

    useEffect(()=>{
        if(isExpiredSession){
          history.push('/login');
          dispatch({type:'IS_LOGIN_AGAIN_TRUE'});
        } 
    },[isExpiredSession]);

    const onDescriptionChange = (e) =>{
        setAd({...ad,description:e.target.value});
        if(e.target.value.trim()===''){
            setAlert({...alert,description:t('Không được để trống')});
        }else{
            setAlert({...alert,description:''});
        }
    }

    const onAdImageChange = (e) =>{
        const file = e.target.files[0];
        if(typeof(file)==='undefined') return;
        if(!checkImage(e.target.files[0].type)){
            setAlert({...alert,image:t("Vui lòng upload file ảnh")});
            return;
        } 
        setAlert({...alert,image:''});
        setImageType(file.type);
        const reader = new FileReader();
        reader.onload = () =>{
            if(reader.readyState === 2){
                const file_name = e.target.files[0].name;
                const content_type = e.target.files[0].type;
                setAd({...ad,content_type:content_type,file_name:file_name});
                setShowFileName(file_name);
                setUpImg(reader.result);
                setIsCrop(true);
            }
        }
        reader.readAsDataURL(e.target.files[0]);
    }

    const onUrlChange = (e) =>{
        const value = e.target.value;
        setAd({...ad,url:value});
        var imageInfo = new Image();
        imageInfo.src=value;
        imageInfo.onload = ()=> {
            setAlert({...alert,url:''});
        } 
        imageInfo.onerror = () =>{
            setAlert({...alert,url:t('URL phải là một hình ảnh')});
        }
        
    }
    const onTypeChange = (e) =>{
        setType(e.target.value)
    }   
    const onTimeChange =(e) =>{
        const value = e.target.value;
        setAd({...ad,time:value});
        if(value === '') {
            setAlert({...alert,time:t('Không được để trống')});
        }else if(parseInt(value) < 5 || parseInt(value) > 60){
            setAlert({...alert,time:t('Tối thiểu 5 giây và tối đa 60 giây')});
        }else{
            setAlert({...alert,time:''});
        }
    }
    const onAddClick = () =>{
        if(isEmpty(ad.description)) return setAlert({...alert,description:t('Không được để trống')});
        if(alert.description !== '') return;
        if(type==='upload'){
            if(isEmpty(ad.image)) return setAlert({...alert,image:t('Không được để trống')});
            if(alert.image !== '') return;
        }else{
            if(isEmpty(ad.url)) return setAlert({...alert,url:t('Không được để trống')});
            if(alert.url !== '') return;
        }
        if(alert.time !=='') return;
        addAdToAws(ad,type);
    }

    const addAdToAws = async (data,upload_type) =>{
        try {
            setIsAdding(true);
            var request = {};
            switch (upload_type) {
                case 'upload':  request={
                                    image:data.image,
                                    description:data.description,
                                    file_name:data.file_name,
                                    content_type:data.content_type,
                                    time:parseInt(data.time),
                                }
                    break;
                case 'url': request = {
                                url:data.url,
                                description:data.description,
                                time:parseInt(data.time),
                            }
                    break;
                default:
                    break;
            }
            if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
                await axiosClient.post('/advertisement/add',JSON.stringify(request),{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
                });
            } else if (process.env.REACT_APP_DEPLOY_ENV === 'on-premise') {
                await axiosClient.post('/advertisement/add', request,{
                    headers:{'Authorization': `Bearer ${Cookies.get('token')}`}
                });
            }
            setIsSuccessDialog(true);
        } catch (error) {
            if (typeof(error.response)!=='undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setIsFailed(true);
        }
        setIsAdding(false);
    }

    const onCloseDialog = ()=>{
        setWarning('');
    }

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }
        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        canvas.width = 704;
        canvas.height = 220;
        const cropedImage = completedCrop;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        canvas.width = 704 ;
        canvas.height = 220 ;

        ctx.imageSmoothingQuality = 'high';
        if(!autoScale)
            ctx.drawImage(
                image,
                cropedImage.x * scaleX,
                cropedImage.y * scaleY,
                cropedImage.width * scaleX,
                cropedImage.height * scaleY,
                0,
                0,
                704,
                220
            );
        else ctx.drawImage(image,0,0,704,220);
        const base64 = canvas.toDataURL(imageType);
        setBase64Image(base64);
    }, [completedCrop,autoScale]);

    const uploadImageClick = () =>{
        const source = base64Image.slice(base64Image.indexOf('base64,')+7);
        setAd({...ad,image:source});
        setIsCrop(false);
    }

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    const onCropImageBack = () =>{
        setIsCrop(false);
        setAutoScale(false);
        setShowFileName('');
        setAd({...ad,file_name:''});
    }

    const cropImageDialog = () =>{
        if(isCrop){
            return (
                <div className={classes.imageDialog}>
                    <Paper style={{width:'30%',minWidth:'400px',borderRadius:'6px'}}>
                        <div style={{width:'100%',minHeight:'150px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <div style={{margin:'5px',minHeight:'150px',width:'90%',maxHeight:'300px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <ReactCrop
                                    style={{minWidth:'64px',maxHeight:'300px'}}
                                    src={upImg}
                                    onImageLoaded={onLoad}
                                    crop={autoScale?'':crop}
                                    onChange={(c) => setCrop(c)}
                                    onComplete={(c) => setCompletedCrop(c)}
                                />
                            </div>
                        </div>
                        <hr />
                        <div style={{width:'90%',margin:'auto'}}>
                            <div style={{display:'flex'}}>
                                <p style={{flex:1,fontSize:'16px',paddingBottom:'3px'}}>{t("Hình sau khi cắt")}</p>
                                <p style={{fontSize:'16px',color:'#0b59c8',cursor:'pointer'}} onClick={()=>setAutoScale(!autoScale)}>
                                    {!autoScale?t("Tỉ lệ tự động"):t('Hủy tỉ lệ tự động')}
                                </p>
                            </div>
                            <div style={{textAlign:'center'}}>
                                <div style={{width:'100%',margin:'auto'}}>
                                    <canvas
                                        ref={previewCanvasRef}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            border:'2px solid gray'
                                        }}
                                    />  
                                </div>
                                <p style={{textAlign:'left',fontSize:'12px'}}>{t('Hình có chất lượng tốt nhất là 704x220(px)')}</p>
                            </div>
                            <div style={{textAlign:'right'}}>
                                <CancelButton 
                                    variant="outlined" 
                                    style={{margin:'10px 5px'}} 
                                    onClick={onCropImageBack}
                                >
                                    {t("Hủy")}
                                </CancelButton>
                                <CreateButton 
                                    variant="contained" 
                                    style={{margin:'10px 0px'}} 
                                    onClick={uploadImageClick}
                                    disabled={base64Image.indexOf('base64') === -1}
                                >
                                    {t("Lưu")}
                                </CreateButton> 
                            </div>
                        </div>
                    </Paper>   
                </div>
            )
        }
    }

    const FailedDialog = () =>{
        return(
          <Dialog 
            open={isFailed}
            maxWidth="xl"
          >
            <DialogTitle className={classes.errorColor}>
                <p className={classes.dialogTitle}>{t('Thông báo')}</p>
            </DialogTitle>
            <DialogContent style={{width:'448px',padding:'0',textAlign:'center'}}>
                <p className={classes.contentDialog}>{t('Thêm không thành công, vui lòng thử lại!')}</p>
                <ErrorButton 
                  style={{margin:'10px 0'}}
                  onClick={()=>setIsFailed(false)} 
                >
                  {t('Đồng ý')}
                </ErrorButton>
            </DialogContent>
          </Dialog>
        )
    }
    const successDialog = () =>{
        return(
            <Dialog 
                open={isSuccessDialog}
                maxWidth="xl"
            >
                <DialogTitle className={classes.dialogTitleBackground}>
                    <p className={classes.dialogTitle}>{t("Thông báo")}</p>
                </DialogTitle>
                <DialogContent style={{width:'448px',padding:'0',textAlign:'center'}}>
                    <p className={classes.contentDialog}>{t("Thêm thành công!")}</p>
                    <CancelButton
                        variant="outlined" 
                        style={{margin:'10px 0'}} 
                        onClick={()=>history.push('/admin/setting/advertisement')}
                     >
                        {t("Đồng ý")}
                     </CancelButton>
                </DialogContent>

            </Dialog>
        )
    }
    
    return(
        <div style={{position:'relative'}}>
            {successDialog()}
            {FailedDialog()}
            {cropImageDialog()}
            {warning !== '' && <Alert icon={false} severity="warning" onClose={onCloseDialog} style={{position:'absolute',right:'9px',top:'0',zIndex:'10'}}>{warning}</Alert>}
            {isAdding && <div className={classes.overlay}></div>}
            <div style={{paddingBottom:'8px',marginTop:'23px'}}>
                <IconButton style={{float:'left',marginLeft:'15px',padding:'2px',marginRight:'5px'}} component={Link} to="/admin/setting/advertisement" >
                    <ArrowBackIosOutlinedIcon className={classes.primaryColor} style={{width:'28px',height:'28px'}} />
                </IconButton>
                <p className={classes.secondaryTitle}>{t("Thêm quảng cáo")}</p>
            </div>
            <div className={classes.inputBlock}>
                <label className={classes.label} >{t('Mô tả')} <span style={{color:'red'}}>(*)</span> </label><br />
                <TextField 
                    style={{width:'40%',minWidth:'320px'}}
                    inputProps={{
                        autoComplete:'off'
                    }}
                    size="small"
                    multiline
                    rows={4}
                    placeholder={t("Mô tả")} 
                    variant="outlined" 
                    value={ad.description}
                    onChange={onDescriptionChange}
                    helperText={alert.description}
                    error={alert.description !==''}
                />
            </div>
            <div className={classes.inputBlock} style={{height:'90px'}}>
                <label className={classes.label} >{t('Hình ảnh')} <span style={{color:'red'}}>(*)</span> </label><br />
                <input accept="image/*" className={classes.input} hidden name="avatar" id="icon-button-file" type="file"  onChange={onAdImageChange} onClick={(e)=>e.target.value=''} />
                {/* <RadioGroup className={classes.formControlLabel} value={type} onChange={onTypeChange} row style={{height:'40px',paddingTop:'5px'}}>
                        <FormControlLabel value='upload' control={<Radio color="primary" classes={{root: classes.radio, checked: classes.checked}} size="small" />} label={t('Tải lên')} />
                        <FormControlLabel value='url' control={<Radio color="primary" classes={{root: classes.radio, checked: classes.checked}} size="small" />} label={t('URL')} />
                </RadioGroup> */}
                {type === 'url' && <TextField 
                    style={{width:'40%',minWidth:'320px'}}
                    inputProps={{
                        autoComplete:'off'
                    }}
                    size="small"
                    placeholder={t("URL image")} 
                    variant="outlined" 
                    value={ad.url}
                    onChange={onUrlChange}
                    error={alert.url!==''}
                    helperText={alert.url}
                />}
                {type==='upload' &&<div>
                    <TextField 
                        style={{width:'40%',minWidth:'320px'}}
                        inputProps={{
                            autoComplete:'off'
                        }}
                        value={showFileName}
                        disabled
                        size="small"
                        placeholder={t("Tên file")} 
                        variant="outlined" 
                        helperText={alert.image}
                        error={alert.image !==''}
                    />
                    <CancelButton
                        variant="outlined"
                        style={{marginLeft:'16px'}}
                    >
                        <label htmlFor="icon-button-file" style={{margin:'0'}}>
                            {t('Chọn file')}
                        </label>         
                    </CancelButton>
                </div>}
            </div>
            <div className={classes.inputBlock} style={{height:'90px'}}>
                <label className={classes.label} >{t('Thời gian quảng cáo')} {t('(giây)')} <span style={{color:'red'}}>(*)</span> </label><br />
                <TextField 
                    style={{width:'40%',minWidth:'320px'}}
                    inputProps={{
                        autoComplete:'off'
                    }}
                    size="small"
                    type="number"
                    placeholder={t("Thời gian quảng cáo")} 
                    variant="outlined" 
                    value={ad.time}
                    onChange={onTimeChange}
                    helperText={alert.time}
                    error={alert.time !==''}
                />
            </div>
            <div className={classes.inputBlock} style={{height:'auto'}}>
                <CreateButton
                    variant="contained"
                    style={{margin:'0'}}
                    onClick={onAddClick}
                    disabled={isAdding}
                >
                    {t('Thêm')}
                    {isAdding && <CircularProgress size={30} style={{position:'absolute'}}/>} 
                </CreateButton>
            </div>
        </div>
    )
}

export default AddAd;