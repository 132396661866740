import withStyles from '@material-ui/styles/withStyles/withStyles';
import Button from '@material-ui/core/Button/Button';

const MakeButton = withStyles(theme => ({ root: theme.create_button }))(Button);

export const CreateButton = ({ children, ...props }) => {
    return (
        <MakeButton
            variant="contained"
            style={{
                width: "max-content"
            }}
            {...props}
        >
            {children}
        </MakeButton>
    )
}