import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import AddIcon from '@mui/icons-material/Add';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { apiCabinetCellTypesPost } from '../../../../../api/cabinets';
import ErrorAlert from '../../../../../components/alert/ErrorAlert';
import { CreateButton } from '../../../../../components/button';
import CancelButton from '../../../../../components/button/cancelButton';
import { Confirm } from '../../../../../components/dialog';
import SelectCpn from '../../../../../components/input/select/select';
import { PrimaryTitle } from '../../../../../components/title';
import { CABINET_ROUTE_PATHS, PRICE_TYPE } from '../../../../../constants/cabinet';
import { useStyles } from './styles';

const CanbinSection = ({
    cabinType,
    cabinTimeType,
    getPriceValueFromCabinSection,
    getPriceFromCabinSection,
}) => {
    const getPriceValueFromCabinFeeChagne = (priceValue) => {
        getPriceValueFromCabinSection(priceValue);
    };
    const getPriceFromCabinWithoutChange = (event) => {
        getPriceFromCabinSection(event);
    };

    return (
        <div>
            {cabinType === PRICE_TYPE.SCALE ? (
                <CabinFeeChange
                    getPriceValue={getPriceValueFromCabinFeeChagne}
                />
            ) : (
                <></>
            )}
            {cabinType === PRICE_TYPE.FIXED ? (
                <CabinFeeWhithoutChange
                    time={cabinTimeType}
                    getPrice={getPriceFromCabinWithoutChange}
                />
            ) : (
                <></>
            )}
        </div>
    );
};

const CustomInput = (props) => {
    return (
        <TextField
            {...props}
            variant='outlined'
            autoComplete='off'
            InputProps={{
                endAdornment: (
                    <InputAdornment position='end'>
                        {props?.affix}
                    </InputAdornment>
                ),
                style: {
                    background: '#FFFFFF',
                    height: '40px',
                },
            }}
        />
    );
};

const CabinFeeChange = ({ getPriceValue }) => {
    const classes = useStyles();
    const [feeChange, setFeeChange] = useState([]);
    const [addtimeline, setAddtimeline] = useState([]);
    const [validate, setValidate] = useState([]);

    const { t, i18n } = useTranslation();

    const onClickAddTimeLine = () => {
        setValidate(prev => [...prev, {
            hour: "",
            fee: ""
        }]);
        setAddtimeline((addtimeline) => [...addtimeline, '']);
        setFeeChange(prev => [
            ...prev,
            { "": "" }
        ])
    }

    const onKeyChange = (event) => {
        const { name: index, value } = event.target;
        const temp = [...feeChange];
        let result = {};

        temp[index] = { [value]: Object.values(temp[index])[0] };
        setFeeChange(temp);

        //validate
        if (!value || isNaN(value) || Number(value) < 1 || !Number.isInteger(Number(value))) {
            const validateTemp = [...validate];
            validateTemp[index].hour = t("Phải là số nguyên và lớn hơn 0");
            setValidate(prev => [
                ...prev,
                validateTemp
            ]);

            result = null;
        } else {
            const validateTemp = [...validate];
            validateTemp[index].hour = "";
            setValidate(prev => [
                ...prev,
                validateTemp
            ]);

            //get data and set for addSize
            temp.forEach((item, index) => {
                for (let key in item) {
                    result[key] = item[key];
                }
            });
        }


        getPriceValue(result);
    };

    const onValueChange = (event) => {
        const { name: index, value } = event.target;
        const temp = [...feeChange];
        let result = {};

        Object.keys(temp[index]).forEach((key) => {
            temp[index][key] = value;
        });
        setFeeChange(temp);

        //validate
        if (!value || isNaN(value) || Number(value) < 1000) {
            const validateTemp = [...validate];
            validateTemp[index].fee = t("Phải là số và lớn hơn 1000");
            setValidate(prev => [
                ...prev,
                validateTemp
            ])

            result = null;
        } else {
            const validateTemp = [...validate];
            validateTemp[index].fee = "";
            setValidate(prev => [
                ...prev,
                validateTemp
            ]);

            //get result to addSize
            temp.forEach((item, index) => {
                for (let key in item) {
                    if (Number(key) && Number(item[key])) {
                        result[key] = item[key];
                    }
                }
            });
        }


        getPriceValue(result);
    };

    return (
        <div className={clsx(classes.cabin)}>
            <Grid container spacing={2}>
                <Grid item xs={5}>
                    <Typography sx={{ fontWeight: 600 }}>
                        {t('Thời gian')}
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography sx={{ fontWeight: 600 }}>
                        {t('Phí thuê')}
                    </Typography>
                </Grid>
                {addtimeline.map((e, i) => {
                    return (
                        <React.Fragment key={`${i}`}>
                            <Grid item xs={4}>
                                <CustomInput
                                    type="number"
                                    error={!!validate[i].hour}
                                    helperText={validate[i].hour}
                                    affix={i18n.language === "vi" ? t("Giờ") : `${t("Giờ")}(s)`}
                                    name={`${i}`}
                                    onChange={(event) => onKeyChange(event)}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Box style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    paddingTop: "0.5rem"
                                }}>-</Box>
                            </Grid>
                            <Grid item xs={7}>
                                <CustomInput
                                    type="number"
                                    disabled={!!!Object.keys(feeChange[i])[0]}
                                    error={!!validate[i].fee}
                                    helperText={validate[i].fee}
                                    affix='VNĐ'
                                    name={`${i}`}
                                    onChange={(event) => onValueChange(event)}
                                />
                            </Grid>
                        </React.Fragment>
                    );
                })}
            </Grid>
            <Box
                sx={{
                    display: 'flex',
                    alignItem: 'center',
                    marginTop: '1.5rem',
                    cursor: 'pointer',
                }}
                onClick={onClickAddTimeLine}
            >
                <AddIcon
                    sx={{
                        position: 'relative',
                        marginTop: '1px',
                        fontSize: 'medium',
                    }}
                />
                <Box
                    sx={{
                        display: 'flex',
                    }}
                >
                    {t('Thêm mốc thời gian')}
                </Box>
            </Box>
            <Box>
                <Typography
                    sx={{
                        fontWeight: '600',
                        fontStyle: 'italic',
                        fontSize: '14px',
                    }}
                >
                    {t("Lưu ý")}: {t("Hệ thống sẽ ngưng tính phí khi hàng ở trong tủ quá mốc thời gian lớn nhất được cài đặt.")}
                </Typography>
            </Box>
        </div>
    );
};
const CabinFeeWhithoutChange = ({ time, getPrice }) => {
    const [validateAmount, setValidateAmount] = useState("");

    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const priceChange = (event) => {
        const value = event.target.value;

        if (!value || isNaN(value) || Number(value) < 1000) {
            setValidateAmount(t("Phải là số và lớn hơn 1000"));
        } else {
            getPrice(event.target.value);
            setValidateAmount("");
        }
    };

    return (
        <div className={clsx(classes.cabin)}>
            <Grid container spacing={2} sx={{ padding: '1rem 0 1rem 0' }}>
                <Grid item xs={7}>
                    <CustomInput
                        type="number"
                        error={!!validateAmount}
                        helperText={validateAmount}
                        placeholder={t('Nhập số tiền')}
                        affix='VNĐ'
                        onChange={(event) => priceChange(event)}
                    />
                </Grid>
                <Grid
                    item
                    xs={5}
                    sx={{ display: 'flex', alignItems: 'center' }}
                >
                    /1 {t(time)}
                </Grid>
            </Grid>
        </div>
    );
};
const AddSize = ({ changeResponseStatus }) => {
    const [cabinType, setCabinType] = useState(PRICE_TYPE.FREE);
    const [cabinTimeType, setCabinTimeType] = useState('Lượt thuê');
    const [openConfirmDialog, setConfirmDialog] = useState(false);
    const [rent, setRent] = useState(true);
    const [message, setMessage] = useState('');
    const [addSize, setAddSize] = useState({
        cellType: [
            {
                language: 'vi',
                name: '',
            },
            {
                language: 'en',
                name: '',
            },
        ],
        specifications: {
            length: '',
            width: '',
            height: '',
        },
        priceType: 'free',
        priceValue: 0,
    });
    const [validate, setValidate] = useState({
        name: "",
        width: "",
        height: "",
        length: ""
    });
    const [isConfirmLoading, setIsConfirmLoading] = useState(false);

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const history = useHistory();

    const selectChange = (event) => {
        setCabinTimeType(event.target.value);
        switch (event.target.value) {
            case 'Lượt thuê':
                setAddSize({
                    ...addSize,
                    priceType: 'turn',
                });

                break;
            case 'Giờ':
                setAddSize({
                    ...addSize,
                    priceType: 'hourly',
                });
                break;
            default:
        }
    };

    const radioChange = (event) => {
        setCabinType(event.target.value);
        switch (event.target.value) {
            case PRICE_TYPE.FREE:
                setAddSize({
                    ...addSize,
                    priceType: 'free',
                    priceValue: 0,
                });
                break;
            case PRICE_TYPE.SCALE:
                setAddSize({
                    ...addSize,
                    priceType: 'scale',
                    priceValue: null,
                });
                break;
            case PRICE_TYPE.FIXED:
                setAddSize({
                    ...addSize,
                    priceType: 'turn',
                    priceValue: 0
                });
            default:
        }
    };

    const onCancelClick = () => {
        goBack();
    };

    const goBack = () => {
        history.push(CABINET_ROUTE_PATHS.CABINET_SYSTEM_SIZE);
    };

    const onBackClick = () => {
        setConfirmDialog(false);
    };

    const sizename = (event) => {
        setAddSize({
            ...addSize,
            cellType: [
                {
                    language: 'vi',
                    name: event.target.value,
                },
                {
                    language: 'en',
                    name: event.target.value,
                },
            ],
        });
        setValidate(prev => ({
            ...prev,
            name: ""
        }))
    };

    const spec = (event) => {
        const getspec = addSize;
        getspec.specifications[event.target.name] = event.target.value;

        setAddSize(getspec);
        setValidate(prev => ({
            ...prev,
            [event.target.name]: ""
        }))
    };

    const onPriceValueChange = (priceValue) => {
        setAddSize({
            ...addSize,
            priceValue
        });
        setValidate(prev => ({
            ...prev,
            priceValue: ""
        }))
    };

    const onClose = () => {
        setMessage('');
    };

    const onSaveClick = () => {
        //validate
        const validate = {
            name: "", width: "", length: "", height: ""
        };
        if (!addSize.cellType[0].name) {
            validate.name = t("Không được để trống")
        }
        if (!addSize.specifications.length) {
            validate.length = t("Không được để trống")
        } else if (Number(addSize.specifications.length) < 10) {
            validate.length = t("Phải là số lớn hơn 9")
        }
        if (!addSize.specifications.width) {
            validate.width = t("Không được để trống")
        } else if (Number(addSize.specifications.width) < 10) {
            validate.width = t("Phải là số lớn hơn 9")
        }
        if (!addSize.specifications.height) {
            validate.height = t("Không được để trống")
        } else if (Number(addSize.specifications.height) < 10) {
            validate.height = t("Phải là số lớn hơn 9")
        }

        setValidate(validate);

        for (let key in validate) {
            if (validate[key]) {
                return;
            }
        }
        //pass a half validate
        if (addSize.priceType !== "free" && !addSize.priceValue) {
            setMessage(t("Vui lòng nhập đầy đủ và chính xác phí thuê tủ"));
            return;
        }
        //pass validate
        setConfirmDialog(true);
    }

    const onConfirmSave = async () => {
        setIsConfirmLoading(true);
        try {
            await apiCabinetCellTypesPost(addSize);
            goBack();
            changeResponseStatus(t("Thêm kích thước tủ thành công"))
        } catch (error) {
            setMessage(t("Lưu kích thước tủ lỗi. Vui lòng thử lại!"));
        }
        setIsConfirmLoading(false);
        setConfirmDialog(false);
    };

    const getPrice = (value) => {
        switch (addSize.priceType) {
            case 'turn':
                setAddSize({
                    ...addSize,
                    priceValue: value,
                });
                break;
            case 'hourly':
                setAddSize({
                    ...addSize,
                    priceValue: value,
                });
            default:
        }
        setValidate(prev => ({
            ...prev,
            priceValue: ""
        }))
    };

    return (
        <div className={clsx(classes.container)}>
            <Confirm
                isOpen={openConfirmDialog}
                mess={t('Bạn chắc chắn muốn lưu kích thước?')}
                handleClose={onBackClick}
                onConfirm={onConfirmSave}
                isLoading={isConfirmLoading}
            />
            <ErrorAlert message={message} onCloseDialog={onClose} />
            <Box display={'flex'} justifyContent='space-between'>
                <Box display={'flex'} alignItems='center'>
                    <IconButton
                        style={{
                            float: 'left',
                            padding: '2px',
                            marginRight: "0.5rem"
                        }}
                        onClick={goBack}
                    >
                        <ArrowBackIosOutlinedIcon
                            className={classes.primaryColor}
                        />
                    </IconButton>

                    <PrimaryTitle>
                        {t('Thêm kích thước')}
                    </PrimaryTitle>
                </Box>
                <Box
                    display={'flex'}
                    alignItems='center'
                    justifyContent='center'
                    style={{ float: 'right' }}
                >
                    <CancelButton
                        variant='outlined'
                        style={{ marginRight: '0.2rem' }}
                        onClick={onCancelClick}
                    >
                        {t('Hủy')}
                    </CancelButton>
                    <CreateButton
                        variant='contained'
                        style={{ marginRight: '1rem' }}
                        onClick={onSaveClick}
                    >
                        {t('Lưu')}
                    </CreateButton>
                </Box>
            </Box>
            <div className={classes.form}>
                <div>
                    <Box padding={'16px'}>
                        <Box padding={'8px'}>
                            <Typography sx={{ fontWeight: 600 }}>
                                {t('Tên kích thước')}
                            </Typography>
                        </Box>
                        <Box display={'flex'}>
                            <Box
                                style={{
                                    padding: '2px',
                                }}
                            >
                                <TextField
                                    variant='outlined'
                                    size='small'
                                    placeholder={t('Nhập tên kích thước')}
                                    error={!!validate.name}
                                    helperText={validate.name}
                                    onChange={(event) => sizename(event)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                {'  '}
                                            </InputAdornment>
                                        ),
                                        style: {
                                            background: '#FFFFFF',
                                            width: '250px',
                                        },
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </div>
                <div>
                    <Box padding={'16px'}>
                        <Box padding={'8px'}>
                            <Typography sx={{ fontWeight: 600 }}>
                                {t('Thông số')}
                            </Typography>
                        </Box>
                        <Box display={'flex'}>
                            <Box>
                                <TextField
                                    type='number'
                                    placeholder={t('Nhập chiều dài')}
                                    variant='outlined'
                                    size='small'
                                    name='length'
                                    error={!!validate.length}
                                    helperText={validate.length}
                                    onChange={(event) => spec(event)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                cm
                                            </InputAdornment>
                                        ),
                                        style: {
                                            background: '#FFFFFF',
                                            width: '250px',
                                        },
                                    }}
                                />
                            </Box>
                            <Box
                                padding={'8px'}
                            >
                                x
                            </Box>
                            <Box>
                                <TextField
                                    type='number'
                                    placeholder={t('Nhập chiều rộng')}
                                    variant='outlined'
                                    size='small'
                                    name='width'
                                    error={!!validate.width}
                                    helperText={validate.width}
                                    onChange={(event) => spec(event)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                cm
                                            </InputAdornment>
                                        ),
                                        style: {
                                            background: '#FFFFFF',
                                            width: '250px',
                                        },
                                    }}
                                />
                            </Box>
                            <Box
                                padding={'8px'}
                            >
                                x
                            </Box>
                            <Box>
                                <TextField
                                    type='number'
                                    placeholder={t('Nhập chiều cao')}
                                    variant='outlined'
                                    size='small'
                                    name='height'
                                    error={!!validate.height}
                                    helperText={validate.height}
                                    onChange={(event) => spec(event)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                cm
                                            </InputAdornment>
                                        ),
                                        style: {
                                            background: '#FFFFFF',
                                            width: '250px',
                                        },
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </div>
                <div>
                    <Box
                        style={{
                            padding: '16px',
                            width: '830px',
                        }}
                    >
                        <Box padding={'8px'}>
                            <Typography sx={{ fontWeight: 600 }}>
                                {t('Phí thuê tủ')}
                            </Typography>
                        </Box>
                        <div>
                            <RadioGroup
                                row
                                aria-labelledby='demo-row-radio-buttons-group-label'
                                name='row-radio-buttons-group'
                                defaultValue={PRICE_TYPE.FREE}
                                style={{
                                    width: '100%',
                                    justifyContent: "space-between"
                                }}
                                onChange={radioChange}
                            >
                                <FormControlLabel
                                    value={PRICE_TYPE.FREE}
                                    control={<Radio />}
                                    label={t('Không tính phí')}
                                />
                                <FormControlLabel
                                    value={PRICE_TYPE.SCALE}
                                    control={<Radio />}
                                    label={t('Phí thay đổi theo giờ')}
                                />
                                <div>
                                    <FormControlLabel
                                        value={PRICE_TYPE.FIXED}
                                        control={<Radio />}
                                        label={t('Phí cố định theo')}
                                    />
                                    <FormControl
                                        disabled={rent}
                                        required
                                        variant='outlined'
                                    >
                                        <SelectCpn
                                            variant='outlined'
                                            style={{ width: "150px", height: '40px' }}
                                            className={classes.customSelect}
                                            disabled={cabinType === PRICE_TYPE.FREE || cabinType === PRICE_TYPE.SCALE}
                                            value={cabinTimeType}
                                            onChange={selectChange}
                                        >
                                            <MenuItem value='Lượt thuê'>
                                                {t('Lượt thuê')}
                                            </MenuItem>
                                            <MenuItem value='Giờ'>
                                                {t('Giờ')}
                                            </MenuItem>
                                        </SelectCpn>
                                    </FormControl>
                                </div>
                            </RadioGroup>
                        </div>
                        <CanbinSection
                            cabinType={cabinType}
                            cabinTimeType={cabinTimeType}
                            getPriceValueFromCabinSection={onPriceValueChange}
                            getPriceFromCabinSection={getPrice}
                        />
                    </Box>
                </div>
            </div>
        </div>
    );
};

export default AddSize;
