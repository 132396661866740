import React,{useState,useRef} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import {useTranslation} from 'react-i18next';
import IconButton from '@material-ui/core/IconButton/IconButton';
import PasswordStrengthBar from 'react-password-strength-bar';
import { checkUsername, checkDisplayName, isEmpty, checkPassword } from '../../../validation/CheckForm';
import zxcvbn from 'zxcvbn';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme)=>({
    inputBlock:{height:'110px',width:'24%',margin:'auto',position:'relative',float:'left'},
    label:{margin:'0',fontSize: '14px',lineHeight: '140%',color: '#000000',display: 'inline-block'},
    input:{width: '100%'},
    placeholder:{color:'#B2B2B2',fontSize:'12px'},
    error:{color:'red',position:'absolute',bottom:'0',left:'20px'},
    alert:{position:'absolute', left:'3px', top:'3px',width:'99%'},
    requestError:{width:'100%',height:'65vh',display:'flex',justifyContent:'center',alignItems:'center',fontSize:'20px',color:'#6D6D6D'},
    focus:{
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': { 
                borderColor: theme.themeColor,
            },
        },
    },
}))

const CreateButton = withStyles(theme =>({root:theme.create_button}))(Button);

const AddAccount = props =>{
    const classes = useStyles();
    const {onCreateclick,accounts,isAdding} = props;
    const {t} = useTranslation();
    const [account,setAccount] = useState({name:'',username:'',password:''});
    const [alert,setAlert] = useState({name:'',username:'',password:''});
    const [showPassword,setShowPassword] = useState(false);
    const usernameRef = useRef();
    const passwordRef = useRef();
    
    const onNameChange = (e)=>{
        const name = e.target.value;
        setAccount({...account,name:name});
        if(isEmpty(name)){
            setAlert({...alert,name:t('Không được để trống')});
        }else if(!checkDisplayName(name)){
            setAlert({...alert,name:t('Không được có ký tự đặc biệt')});
        }else if (name.trim().length < 6 || name.trim().length > 30){
            setAlert({...alert,name:t('Độ dài ký tự trong khoảng từ 6 đến 30')});
        }else{
            setAlert({...alert,name:''});
        }
    }

    const onUsernameChange = (e)=>{
        const username = e.target.value.trim();
        setAccount({...account,username:username});
        if(isEmpty(username)){
            setAlert({...alert,username:t('Không được để trống')});
        }else if (!checkUsername(username)){
            setAlert({...alert,username:t('Không được có ký tự đặc biệt')});
        }else if (username.trim().length < 6 || username.trim().length > 24){
            setAlert({...alert,username:t('Độ dài ký tự trong khoảng từ 6 đến 24')});
        }else{
            setAlert({...alert,username:''});
            if(usernameRef.current) clearTimeout(usernameRef.current);
            usernameRef.current = setTimeout(() => {
                const res = accounts.filter(item => item.Username === username);
                if(res.length > 0) setAlert({...alert,username:t('Tên đăng nhập đã tồn tại')});
            }, 500);
        } 
    }

    const onPasswordChange = (e) =>{
        const password = e.target.value.trim();
        setAccount({...account,password:password});
        if(isEmpty(password)){
            setAlert({...alert,password:t('Không được để trống')});
        }else if(!checkPassword(password)){
            setAlert({...alert,password:t('Tối thiểu 8 ký tự bao gồm chữ HOA, chữ thường, số và ký tự đặc biệt')});
        }else{
            setAlert({...alert,password:''});
            if(passwordRef.current) clearTimeout(passwordRef.current);
            passwordRef.current = setTimeout(() => {
                const result = zxcvbn(password);
                if(result.feedback.warning!=='') setAlert({...alert,password:t('Đã trùng mật khẩu phổ biến')});
            }, 300);
        }
    }

    const onAddClick = () =>{
        if(isEmpty(account.name)) return setAlert({...alert,name:t('Không được để trống')});
        if(alert.name !== '') return;
        if(isEmpty(account.username)) return setAlert({...alert,username:t('Không được để trống')});
        if(alert.username !== '') return;
        if(isEmpty(account.password)) return setAlert({...alert,password:t('Không được để trống')});
        if(alert.password !== '') return;
        onCreateclick(account);
    }
    return (
        <div className={classes.focus}>
            <Paper style={{fontSize:'24px',height:'122px',width:'97%',margin:'auto',marginTop:'5px',background:'#FAFAFA'}}>
                <div className={classes.inputBlock} style={{marginLeft:'2%'}}>
                    <label className={classes.label}>{t('Tên tài khoản')} <span style={{color:'red'}}>(*)</span></label><br />
                    <TextField 
                        classes={{
                            root:classes.input
                        }}
                        inputProps={{
                            autoComplete:'off'
                        }}
                        style={{background:'#FFFFFF'}}
                        size="small"
                        placeholder={t("Tên tài khoản")} 
                        variant="outlined" 
                        onChange={onNameChange}
                    />
                    <p style={{color:'red',fontSize:'13px',paddingTop:'4px'}}>{alert.name}</p>
                </div>
                <div className={classes.inputBlock} style={{marginLeft:'1%'}}>
                    <label className={classes.label}>{t('Tên đăng nhập')} <span style={{color:'red'}}>(*)</span></label><br />
                    <TextField 
                        classes={{
                            root:classes.input
                        }}
                        inputProps={{
                            autoComplete:'new-username'
                        }}
                        style={{background:'#FFFFFF'}}
                        size="small"
                        placeholder={t("Tên đăng nhập")} 
                        variant="outlined" 
                        onChange={onUsernameChange}
                    />
                    <p style={{color:'red',fontSize:'13px',paddingTop:'4px'}}>{alert.username}</p>
                </div>
                <div className={classes.inputBlock} style={{marginLeft:'1%'}}>
                    <label className={classes.label}>{t('Mật khẩu')} <span style={{color:'red'}}>(*)</span></label><br />
                    <TextField 
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={()=>setShowPassword(!showPassword)}
                                    >
                                    {showPassword ? <Visibility fontSize="small"/> : <VisibilityOff fontSize="small"/>}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        classes={{
                            root:classes.input
                        }}
                        inputProps={{
                            autoComplete:'new-password'
                        }}
                        style={{background:'#FFFFFF'}}
                        size="small"
                        placeholder={t("Mật khẩu")}
                        type={showPassword?"text":"password"} 
                        variant="outlined" 
                        onChange={onPasswordChange}
                    />
                    {account.password !=='' && 
                    <PasswordStrengthBar 
                        scoreWords={['','','','']} 
                        shortScoreWord='' 
                        password={account.password} 
                    />}
                    <p style={{color:'red',fontSize:'13px',paddingTop:'0px',width:'200%'}}>{alert.password}</p>
                </div>
                        
                <div className={classes.inputBlock} style={{width:'100px'}}>       
                    <CreateButton 
                        variant="contained" 
                        onClick={onAddClick} 
                        style={{margin:'0',marginTop:'34px',marginLeft:'16px'}}
                        disabled={isAdding}
                    >
                        {t('Thêm')}
                        {isAdding && <CircularProgress size={25} style={{position:'absolute',color:'#0b59c8'}}/>}
                    </CreateButton>
                </div>
            </Paper>
        </div>
    )
}

export default AddAccount;
