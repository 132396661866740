import React, { useState, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import Button from '@material-ui/core/Button/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { apiUserinfo } from '../../api/userinfo';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import { apiResidentsApprove } from '../../api/resident';
import { SuccessDialog, FailedDialog, CheckboxConfirm } from '../dialog';
import { apiGetconfig } from '../../api/getconfig';
import { configurations } from '../../config/Registration';
import { checkFaceImage } from '../actionImage';

const useStyles = makeStyles(theme => ({
    part_form: {
        display: 'flex',
        width: '100%',
        minWidth: '1150px',
        background: '#FFFFFF',
        flexWrap: 'wrap',
        borderRadius: '6px'
    },
    primaryColor: theme.primaryTextColor,
    secondaryTitle: theme.secondary_title,
    page: {
        width: '98%',
        margin: 'auto',
    },
    contentBlock: {
        display: 'flex',
        width: '100%',
        minWidth: '1150px',
        background: '#FFFFFF',
        flexWrap: 'wrap',
        borderRadius: '6px',
        paddingBottom: '20px'
    },
    base_info_title: {
        fontSize: '20px',
        lineHeight: '28px',
        color: '#333333',
        fontWeight: '600',
        paddingTop: '10px',
        width: '100%',
        textAlign: 'center'
    },
    faceBlock: {
        width: '45%'
    },
    faceStyle: {
        width: '100%',
        maxWidth: '500px',
        margin: 'auto',
        display: 'block',
        maxHeight: '700px',
        objectFit: 'cover'
    },
    noFaceText: {
        fontSize: '20px',
        lineHeight: '28px',
        color: '#333333',
        paddingTop: '10px',
        width: '100%',
        textAlign: 'center'
    },
    partArrowRight: {
        width: '10%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    arrowRightIcon: {
        fontSize: '100px!important',
        color: '#2AB930'
    },
    titleBlock: {
        paddingBottom: '8px',
        marginTop: '15px',
        position: 'relative',
        left: '-9px',
        display: 'flex'
    },
    titleItemStyle: {
        float: 'left',
        marginRight: '2px',
        marginTop: '5px',
        height: '32px',
        width: '32px'
    },
    titleIcon: {
        width: '28px',
        height: '28px'
    },
    requestError: {
        width: '100%',
        height: '65vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20px',
        color: '#6D6D6D'
    },
    requestError: {
        width: '100%',
        height: '65vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20px',
        color: '#6D6D6D'
    },
    marginLeft0: {
        marginLeft: '0',
        flex: 1
    },
    primaryColor: theme.primaryTextColor,
    errorBtn: theme.error_button,
    warningText: {
        color: 'red',
        textAlign: 'center',
        fontSize: '20px',
    }
}))

const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);
const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);

const ChangedFaceDetail = props => {
    // const { residentID, onBackClick, title } = props;
    const { residentID } = useParams();
    const { t } = useTranslation();
    const classes = useStyles();
    const [face, setFace] = useState('');
    const [oldFace, setOldFace] = useState('');
    const [loading, setLoading] = useState(false);
    const [isFailedDialog, setIsFailedDialog] = useState(false);
    const [isSuccessDialog, setIsSuccessDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isApprove, setIsApprove] = useState(false);
    const [isApproving, setIsApproving] = useState(false);
    const [error, setError] = useState(false);
    const [user, setUser] = useState({
        full_name: '',
        apartments: []
    });
    const [notification, setNotification] = useState();
    const [isNotification, setIsNotification] = useState(false);
    const [oldFaceWarning, setOldFaceWarning] = useState(false);
    const [newFaceWarning, setNewFaceWarning] = useState(false);
    const [isExpiredSession, setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (isExpiredSession) {
            history.push('/login');
            dispatch({ type: 'IS_LOGIN_AGAIN_TRUE' });
        }
    }, [isExpiredSession]);

    const getNewFace = async () => {
        var res = await apiUserinfo({ type: 'ResidenceTemp', id: residentID });
        setFace(res.data.FaceID ? res.data.FaceID : '');
        setUser({
            full_name: res.data.FullName,
            apartments: res.data.Apartments
        })
        await getOldFace(res.data.Username);
    }

    const getOldFace = async (username) => {
        const res = await apiUserinfo({ username: username });
        setOldFace(res.data.FaceID ? res.data.FaceID : '');
    }

    const getNotificationConfig = async () => {
        var remove_faceid;
        try {
            const res = await apiGetconfig();
            const notificationConf = res.data.Items[0].Configuration ? res.data.Items[0].Configuration.notification : 0;
            if (notificationConf === -1) {
                setIsNotification(false);
            }
            setNotification(notificationConf);
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setNotification(0);
        }
    }

    const getDefaultValues = async () => {
        try {
            await getNewFace();
            await getNotificationConfig();
            setError('');
        } catch (error) {
            if (error.response && error.response.data === "Invalid LoginToken") {
                return setIsExpiredSession(true);
            }
            setError(t('Không lấy được dữ liệu'))
        }
        setLoading(true);
    }

    useEffect(() => {
        if (!loading) {
            getDefaultValues();
        }
    }, [loading])

    const onFailedAgreeClick = () => {
        setIsFailedDialog(!isFailedDialog);
    }

    const onNotificationChange = (e) => {
        if (notification === 1) return;
        setIsNotification(e.target.checked);
    }

    const onError = (e, old) => {
        console.log(e)
        // e.target.src = `${window.location.origin}/images/User.svg`;
        // if (old) {
        //     setOldFaceWarning(t('Không tìm thấy hình'));
        // } else {
        //     setNewFaceWarning(t('Không tìm thấy hình'));
        // }
    }

    const showApartments = () => {
        var data = '';
        if (user.apartments)
            user.apartments.forEach(value => {
                data += value.block + value.floor + value.room + '  ';
            })
        else
            data = user.block + user.floor + user.room;
        return data;
    }

    const onApproveClick = async () => {
        setIsApproving(true);
        try {
            var res = await apiResidentsApprove({
                ids: [residentID],
                notification: isNotification
            });
            if (res.data.failedIDs.length > 0) {
                var text = checkFaceImage(res.data.failedIDs[0].Reason);
                setErrorMessage(t(text));
                setIsFailedDialog(true);
            } else {
                setIsSuccessDialog(true);
            }
        } catch (error) {
            setErrorMessage(t('Đã xảy ra lỗi, vui lòng thử lại!'));
            setIsFailedDialog(true);
        }
        setIsApprove(false);
        setIsApproving(false);
    }

    const onBackClick = () =>{
        history.replace('/admin/register-overview');
    }

    return (
        <div className={classes.page}>
            <CheckboxConfirm
                isOpen={isApprove}
                handleClose={() => setIsApprove(!isApprove)}
                isLoading={isApproving}
                mess={t("Bạn có chắc chắn muốn duyệt")}
                apartmentList={`(${t('Căn hộ')}: ${showApartments().trim()})`}
                onConfirm={onApproveClick}
                user={user}
                onNotificationChange={onNotificationChange}
                isNotification={isNotification}
                notification={notification}
            />
            <SuccessDialog
                isOpen={isSuccessDialog}
                mess={t('Duyệt thành công!')}
                onConfirm={onBackClick}
            />
            <FailedDialog
                isOpen={isFailedDialog}
                mess={errorMessage}
                onConfirm={onFailedAgreeClick}
            />
            <div className={classes.titleBlock}>
                <IconButton className={classes.titleItemStyle} onClick={onBackClick} >
                    <ArrowBackIosOutlinedIcon className={clsx(classes.primaryColor, classes.titleIcon)} />
                </IconButton>
                <p className={clsx(classes.secondaryTitle, classes.marginLeft0)}>{t("Chi tiết")}</p>
                <CreateButton
                    variant="contained"
                    style={{ float: 'right', position: 'relative', right: '-5px' }}
                    onClick={() => setIsApprove(true)}
                >
                    {t('Duyệt')}
                </CreateButton>
            </div>
            {
                !loading &&
                <div className={classes.requestError}>
                    <CircularProgress style={{ color: '#0b59c8' }} />
                </div>
            }
            {
                loading && error !== '' &&
                <div className={classes.requestError}>
                    {error}
                </div>
            }
            {
                loading && error === '' &&
                <div className={classes.contentBlock}>
                    <div className={classes.faceBlock}>
                        <p className={classes.base_info_title}>{t("Face ID cũ")}</p>
                        <img src={oldFace === '' ? `${window.location.origin}/images/User.svg` : oldFace} className={classes.faceStyle} alt="Face ID" onError={(e) => onError(e, true)} />
                        {oldFace === '' && <p className={classes.noFaceText}>{t('Chưa có ảnh Face ID')}</p>}
                        <p className={classes.warningText}>{oldFaceWarning}</p>
                    </div>
                    <div className={classes.partArrowRight}>
                        <ArrowForwardIosRoundedIcon className={classes.arrowRightIcon} />
                    </div>
                    <div className={classes.faceBlock}>
                        <p className={classes.base_info_title}>{t("Face ID mới")}</p>
                        <img src={face === '' ? `${window.location.origin}/images/User.svg` : face} className={classes.faceStyle} alt="Face ID" onError={(e) => onError(e, false)} />
                        {face === '' && <p className={classes.noFaceText}>{t('Chưa có ảnh Face ID')}</p>}
                        <p className={classes.warningText}>{newFaceWarning}</p>
                    </div>
                </div>
            }
        </div>
    )
}

export default ChangedFaceDetail;