import makeStyles from '@material-ui/styles/makeStyles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    doughnut: {
        position: 'relative',
        zIndex: '2'
    },
    containDoughnut: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapDoughnut: {
        position: 'relative',
        width: '50%'
    },
    totalCells: {
        position: 'absolute',
        top: '0',
        left: '0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        fontSize: '16px',

        '& p': {
            width: 'fit-content',

            '&:first-child': {
                color: '#8E8E8E'
            },

            '&:last-child': {
                fontSize: '18px',
                fontWeight: '600'
            }
        }
    },
    doughnutLabel: {
        width: '50%',
        padding: '24px'
    },
    labelText: {
        fontSize: '16px',
        color: '#8E8E8E'
    },
    value: {
        fontSize: '18px',
        marginLeft: '18px',
    },
    wrapLabel: {
        marginBottom: '16px',

        '&:last-child': {
            marginBottom: '0',
        }
    }
}));