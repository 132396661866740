import makeStyles from '@material-ui/styles/makeStyles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    primaryTitle: theme.primary_title,
    link: {
        display: 'block',
        height: '40px',
        fontSize: '14px',
        padding: '10px',
        color: '#000000',
        position: 'relative',
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
    active: {
        height: '40px',
        width: '100%',
        background: '#50c9ff4a',
        fontWeight: 'unset !important'
    },
    title: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
    }
}));