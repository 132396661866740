import { CircularProgress } from '@material-ui/core';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { securitycameraDeviceGet } from '../../../api/securityCamera';
import ErrorAlert from '../../../components/alert/ErrorAlert';
import StreamRtsp from '../../../components/streamRtsp';
import { PrimaryTitle } from '../../../components/title';
import { useStyles } from './styles';

export default function SingleCamera() {
    const [state, setState] = useState({
        camera: {},
        responseStatus: {
            succ: "",
            err: ""
        },
        isLoading: false
    })

    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const { cameraID } = useParams();

    const fetchSecuritycameraDeviceGet = async (body) => {
        setState(prev => ({
            ...prev,
            isLoading: true
        }));
        try {
            const { data } = await securitycameraDeviceGet(body);
            const { Items, Count } = data;

            const listCamera = Items.map(item => {
                item.id = item.ID;
                return item;
            })
            setState(prev => ({
                ...prev,
                camera: listCamera[0],
                Count
            }))
        } catch (error) {
            setState(prev => ({
                ...prev,
                responseStatus: {
                    succ: "",
                    err: t("Tải thông tin camera thất bại!")
                }
            }))
        }

        setState(prev => ({
            ...prev,
            isLoading: false
        }));
    }

    useEffect(() => {
        fetchSecuritycameraDeviceGet({ id: cameraID })
    }, [])

    return (
        <>
            {
                state.isLoading && (
                    <div className={classes.wrapLoading}>
                        <CircularProgress />
                    </div>
                )
            }
            {
                !state.isLoading && (
                    <div className={classes.singleCamera}>
                        <div className={classes.wrapTitle}>
                            <div
                                className={classes.titleIcon}
                                onClick={() => history.push('/admin/security-camera')}
                            >
                                <ArrowBackIosNewOutlinedIcon />
                            </div>
                            <PrimaryTitle>
                                {state?.camera?.BlockNumber ? `${t("Tòa")} ${state?.camera?.BlockNumber}` : `Camera "${state?.camera?.CameraName}"`}
                            </PrimaryTitle>
                        </div>

                        <div className={classes.live}>
                            <StreamRtsp
                                rtsp={state.camera?.RtspLink}
                                isControl
                            />
                        </div>
                    </div>
                )
            }

            <ErrorAlert
                message={state.responseStatus.err}
                onCloseDialog={() => setState(prev => ({
                    ...prev,
                    responseStatus: {
                        err: "",
                        suc: ""
                    }
                }))}
            />
        </>
    )
}
