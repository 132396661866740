import React, { useState, useEffect, useRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SnackbarContent from '@mui/material/SnackbarContent';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    messageStyle: {
        fontWeight: 'bold',
        fontSize: '16px',
        paddingBottom: '5px'
    },
    snackbarContentStyle: {
        background: 'rgb(255, 244, 229)!important', 
        color: 'rgb(102, 60, 0)!important'
    },
    colorRed: {
        color: '#e01010'
    }
}));

const WarningAlert = React.memo((props) => {
    const classes = useStyles();
    const { alertData, onCloseDialog, isOpen, ms } = props;
    const [notification, setNotification] = useState({});
    const { t } = useTranslation();
    const history = useHistory();
    const alertRef = useRef();

    useEffect(() => {
        if (isOpen) {
            if (alertRef.current) clearTimeout(alertRef.current);
            alertRef.current = setTimeout(() => {
                onCloseDialog();
            }, ms);
        }
    }, [isOpen])

    return (
        <Snackbar
            open={isOpen}
            TransitionComponent={Slide}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
        >
            <SnackbarContent
                className={classes.snackbarContentStyle}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={onCloseDialog}
                    >
                        <CloseIcon />
                    </IconButton>
                }
                message={
                    <div>
                        <p className={classes.messageStyle} >{alertData.title}</p>
                        <p>{alertData.body}</p>
                    </div>
                }
            />
        </Snackbar>
    )
})

export default WarningAlert;