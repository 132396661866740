import React, {useState, useEffect, useRef} from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography/Typography';
import TextField from '@material-ui/core/TextField/TextField';
import Button from '@material-ui/core/Button/Button';
import { useHistory, Link} from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import {isEmpty, checkPassword} from '../../validation/CheckForm';
import md5 from 'md5';
import { loginStyles } from '../../styles/style';
import Checkbox from '@material-ui/core/Checkbox';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useMediaQuery } from 'react-responsive'
import AWS from 'aws-sdk';
import {useTranslation} from 'react-i18next';
import PasswordStrengthBar from 'react-password-strength-bar';
import Cookies from 'js-cookie';
import zxcvbn from 'zxcvbn';
import clsx from 'clsx';
import {loginReceptionist, changePasswordReceptionist } from '../../api/residentRegistrations';
import CryptoJS from 'crypto-js';

AWS.config.update({
    region:'ap-southeast-1', 
    accessKeyId:process.env.REACT_APP_ACCESS_KEY_ID, 
    secretAccessKey:process.env.REACT_APP_SECRET_ACCESS_KEY
});

const ReceptionistLogin = props => {
    const classes = loginStyles();    
    const [account, setAccount] = useState({username:'', password:'', id:''});
    const [message, setMessage] = useState('');
    const [err, setErr] = useState({
        username:'', 
        password:'', 
        enterPassword:'', 
        reEnterPassword:''
    })
    const [checking, setChecking] = useState(false);
    const [isLogin, setIsLogin] = useState(true);
    const [isChangePassword, setIsChangePassword] = useState(false);
    const [isRemeber, setIsRemember] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showEnterPassword, setShowEnterPassword] = useState(false);
    const [showReEnterPassword, setShowReEnterPassword] = useState(false);
    const [enterPassword, setEnterPassword] = useState('');
    const [reEnterPassword, setReEnterPassword] = useState('');
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const isDesktopOrLaptop  = useMediaQuery({ query: '(min-width: 1224px)' });
    const history = useHistory();
    const {t} = useTranslation();
    const passwordRef = useRef();
    const [receptionistToken, setReceptionistToken] = useState('');

    useEffect(() => {
        Cookies.remove('receptionistToken', {path:''});
        localStorage.removeItem('receptionistName');
        localStorage.removeItem('receptionistID');
        if(localStorage.getItem('rememberReceptionist')==='true'){
            setIsRemember(true);
            var bytes = CryptoJS.AES.decrypt(localStorage.getItem('receptionist_password'), process.env.REACT_APP_PASSWORD_SECRET_KEY);
            setAccount({...account, username:localStorage.getItem('receptionist_username'), password:bytes.toString(CryptoJS.enc.Utf8)});
        }else{
            setIsRemember(false);
            setAccount({...account, username:'', password:''});
        }     
    }, []);

    useEffect(()=>{
        setMessage('');
        setShowPassword(false);
    }, [isLogin])

    const rememberPassword = (username, password) =>{
        if(isRemeber){
            localStorage.setItem('rememberReceptionist', true);
            localStorage.setItem('receptionist_username', username);
            localStorage.setItem('receptionist_password', CryptoJS.AES.encrypt(password,  process.env.REACT_APP_PASSWORD_SECRET_KEY).toString());
        }else{
            localStorage.setItem('rememberReceptionist', false);
            localStorage.setItem('receptionist_username', '');
            localStorage.setItem('receptionist_password', '');
        }
    }

    const signIn = async(username, password) =>{
        setChecking(true);
        try {
            const res = await loginReceptionist({username:username, password:md5(password)});
            const token = res.data.loginToken;
            switch (res.data.userStatus) {
                case 'inactive': 
                    setMessage(t(''));
                    setIsChangePassword(true);
                    setIsLogin(false);
                    setReceptionistToken(token);
                    setAccount({...account, id:res.data.ID});
                    break;
                case 'deactivated': setMessage(t('Tài khoản đã bị vô hiệu'));
                    break;
                case 'active':
                    Cookies.set('receptionistToken', token, {secure:true, sameSite:'strict'});
                    localStorage.setItem('receptionistName', res.data.fullName);
                    localStorage.setItem('receptionistID', res.data.ID);
                    rememberPassword(username, password);
                    history.push('/register');
                    break;
                default:
                    break;
            }
        } catch (error) {
            switch (error.response.data) {
                case 'Incorrect Username or Password': setMessage(t('Tài khoản hoặc mật khẩu không chính xác'))
                    break;
                case 'Incorrect Password': setMessage(t('Tài khoản hoặc mật khẩu không chính xác'))
                    break;
                default:setMessage(t('Đã xảy ra lỗi,  vui lòng thử lại sau'))
                    break;
            }
        }
        setChecking(false);
    }

    const onUsernameChange = (e)=>{
        var value = e.target.value;
        setAccount({...account, username:value});
        if(value.trim()===''){
            setErr({...err, username:t('Vui lòng nhập tên đăng nhập')});
        }else{
            setErr({...err, username:''});
        }
    }

    const onPasswordChange = (e) =>{
        var value = e.target.value;
        setAccount({...account, password:value});
        if(value.trim()===''){
            setErr({...err, password:t('Vui lòng nhập mật khẩu')});
        }else{
            setErr({...err, password:''});
        }
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
      
    const onRememberPasswordChange = (e) =>{
        setIsRemember(e.target.checked);
    }

    const loginSubmit = (e) =>{
        e.preventDefault();
        if(isEmpty(account.username) && isEmpty(account.password)) return setMessage(t('Vui lòng nhập tên đăng nhập và mật khẩu'));
        if(isEmpty(account.username)) return setErr({...err, username:t('Vui lòng nhập tên đăng nhập')});
        if(isEmpty(account.password)) return setErr({...err, password:t('Vui lòng nhập mật khẩu')});
        signIn(account.username, account.password);   
    }

    const loginForm = () =>{
        if(isLogin){
            return(
                <Paper className={clsx(classes.paper)} >
                    
                    {isTabletOrMobile && <Typography variant="h4"  className={classes.title}>{t('Lễ tân')}</Typography>}
                    {isDesktopOrLaptop && <Typography variant="h3"  className={classes.title}>{t('Lễ tân')}</Typography>}
                    <Divider style={{width:'80px', textAlign:'center', height:'5px', background:'#072A3B', margin:'auto', marginBottom:'10px'}} />
                    <div className={classes.createNew}>
                        {message !=='' && <p style={{color:'red'}}>{message}</p>      }    
                    </div>    
                    <form>
                        <div className={classes.inputBlock}>
                            <label className={classes.label}>{t('Tên đăng nhập')} *</label>
                            <TextField 
                                classes={{
                                    root:classes.input
                                }}
                                value={account.username}
                                name='username' 
                                variant="outlined" 
                                placeholder={t("Nhập tên đăng nhập của bạn")}
                                onChange={onUsernameChange}
                                error={err.username!==''}
                                helperText={err.username}
                            />
                        </div>
                        <div className={classes.inputBlock}>
                            <label className={classes.label}>{t('Mật khẩu')} *</label>
                            <TextField 
                                classes={{
                                    root:classes.input
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ) 
                                }}
                                value={account.password}
                                name='password' 
                                variant="outlined" 
                                placeholder={t("Nhập mật khẩu của bạn")}
                                type={!showPassword?'password':'text'}
                                onChange={onPasswordChange}
                                error={err.password!==''}
                                helperText={err.password}
                            />
                        </div>
                        <div style={{width:'80%', margin:'auto'}}>
                            <Checkbox
                                checked={isRemeber}
                                onChange={onRememberPasswordChange}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                color="primary"
                            /> 
                            <label className={classes.label} style={{display:'inline', position:'relative', top:'5px'}}>{t('Nhớ mật khẩu')}</label>
                        </div>
                        <div className={classes.action} style={{paddingTop:'0'}}>
                            <Button variant="contained" disableRipple type='submit' autoFocus disabled={checking} color="primary" className={classes.signinAction} onClick={loginSubmit} >
                                {t('Đăng nhập')}
                                {checking && <CircularProgress style={{position:'absolute', left:'0', right:'0', marginLeft:'auto', marginRight:'auto', color:'#0b59c8'}} />}
                            </Button>
                            <Link to="/" className={classes.forgetPassword}>{t('Trở về trang chủ')}</Link>
                        </div>
                    </form>
                </Paper>
            )
        }
    }

    const showEnterPasswordClick = () =>{
        setShowEnterPassword(!showEnterPassword);
    }

    const showReEnterPasswordClick = ()=>{
        setShowReEnterPassword(!showReEnterPassword);
    }

    const handleChangePassword = async () =>{      
        if(isEmpty(enterPassword)) return setErr({...err, enterPassword:t('Không được để trống')});
        if(err.enterPassword !== '') return;
        if(isEmpty(reEnterPassword)) return setErr({...err, reEnterPassword:t('Không được để trống')});
        if(enterPassword !== reEnterPassword) return setErr({...err, reEnterPassword:t('Mật khẩu không giống nhau')});
        if(err.reEnterPassword !== '') return;
        setChecking(true);
        const data = {
            id:account.id, 
            username:account.username, 
            password:md5(enterPassword), 
            status:'active', 
            isReceptionist:true
        }
        try {
            await changePasswordReceptionist(receptionistToken, data);
            setAccount({...account, password:enterPassword});
            setIsChangePassword(false);
            setIsLogin(true);
        } catch (error) {
            setMessage(t('Đã xảy ra lỗi, vui lòng thử lại!'));  
        }
        setChecking(false);
    }

    const onEnterPasswordChange = (e)=>{
        const value = e.target.value;
        setEnterPassword(value);
        if(isEmpty(value)){
            setErr({...err, enterPassword:t('Không được để trống')});
        }else if(!checkPassword(value)){
            setErr({...err, enterPassword:t('Tối thiểu 8 ký tự bao gồm chữ HOA, chữ thường, số và ký tự đặc biệt')});
        }else{
            setErr({...err, enterPassword:''});
        }
        if(passwordRef.current) clearTimeout(passwordRef.current);
        passwordRef.current = setTimeout(() => {
            const result = zxcvbn(value);
            if(result.feedback.warning !== '') setErr({...err, enterPassword:t('Đã trùng mật khẩu phổ biến')});
        },  500);
    }

    const onRePasswordChange = (e) =>{
        const value = e.target.value;
        setReEnterPassword(value);
        if(isEmpty(value)){
            setErr({...err, reEnterPassword:t('Không được để trống')});
        }else{
            setErr({...err, reEnterPassword:''});
        }
    }

    const changePassword = () =>{
        if(isChangePassword){
            return(
                <Paper className={clsx(classes.paper)} >
                    <div style={{height:'180px', textAlign:'center'}}>
                        <Typography variant="h2"  className={classes.title} style={{fontSize:'35px'}}>{t('Đổi mật khẩu lần đầu')}</Typography>
                        <Divider style={{width:'80px', height:'5px', background:'#072A3B', margin:'auto', marginBottom:'20px'}} />
                        <p style={{color:'red'}}>{message}</p>
                    </div>
                    <div className={classes.inputBlock} style={{height:'135px'}}>
                        <label className={classes.label}>{t('Nhập mật khẩu mới')} *</label>
                        <TextField
                            InputProps={{
                                endAdornment: (    
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={showEnterPasswordClick}
                                        >
                                            {showEnterPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            placeholder={t("Mật khẩu")}
                            variant="outlined"
                            style={{width:'100%'}}
                            type={showEnterPassword?"text":"password"}
                            onChange={onEnterPasswordChange}
                            error = {err.enterPassword !== ''}
                        />
                        {enterPassword!=='' && 
                        <PasswordStrengthBar 
                            scoreWords={['', '', '', '']} 
                            shortScoreWord='' 
                            password={enterPassword}
                        />}
                        <p style={{color:'#f44336', paddingTop:'3px', fontSize:'0.75rem'}}>{err.enterPassword}</p>
                    </div>
                    <div className={classes.inputBlock}>
                        <label className={classes.label}>{t('Nhập lại mật khẩu mới')} *</label>
                        <TextField
                            InputProps={{
                                endAdornment: (    
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={showReEnterPasswordClick}
                                        >
                                            {showReEnterPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ), 
                            }}
                            placeholder={t("Mật khẩu")}
                            variant="outlined"
                            style={{width:'100%'}}
                            type={showReEnterPassword?"text":"password"}
                            onChange={onRePasswordChange}
                            error = {err.reEnterPassword !== ''}
                        />  
                        <p style={{color:'#f44336', paddingTop:'3px', fontSize:'0.75rem', width:'150%'}}>{err.reEnterPassword}</p>
                    </div>
                    <div className={classes.action}>
                        <Button variant="contained" disabled={checking} color="primary" className={classes.signinAction} onClick={handleChangePassword} >
                            {t('Xác nhận')}
                        {checking && <CircularProgress style={{position:'absolute', textAlign:'center', color:'#0b59c8'}} />}
                        </Button>
                        <p 
                            onClick={()=>{
                                setIsLogin(true);
                                setIsChangePassword(false);
                            }} 
                            className={classes.forgetPassword}
                        >
                            {t('Trở về trang đăng nhập')}
                        </p>
                    </div>
                    
                </Paper>
            )
        }
    }

    return (
        <div style={{height:'100vh', width:'100%', overflow:'hidden', display:'flex', justifyContent:'center', alignItems:'center'}}>
            {checking && <div className={classes.overlay}></div>}
            {loginForm()}
            {changePassword()}
        </div>
    )
}

export default ReceptionistLogin;
