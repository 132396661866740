import AxiosClient from "../config/AxiosClient";

export const apiDeleteusers = async (bodyRequest) => {
    var url = '';
    if (process.env.REACT_APP_DEPLOY_ENV === 'aws') {
        url = '/deleteusers';
    }
    else {
        url = '/residences/delete';
    }
    return await AxiosClient.post(url, bodyRequest);
}