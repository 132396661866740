import React, { useState, useEffect, useRef } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Button from '@material-ui/core/Button/Button';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { DataGrid } from '@material-ui/data-grid';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import md5 from 'md5';
import EditReceptionist from './EditReceptionist';
import AddReceptionist from './AddReceptionist';
import { v5 } from 'uuid';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import SuccessAlert from '../../alert/SuccessAlert';
import ErrorAlert from '../../alert/ErrorAlert';
import { apiReceptionistUpdate, apiReceptionistGetall, apiReceptionistAdd, apiReceptionistDelete } from '../../../api/receptionist';
import { apiGetapartmentdata } from '../../../api/apartment';

const useStyles = makeStyles((theme) => ({
    error: {
        color: 'red',
        position: 'absolute',
        bottom: '0',
        left: '20px'
    },
    alert: {
        position: 'absolute',
        left: '3px',
        top: '3px',
        width: '99%'
    },
    dialogTitle: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '25px',
        color: '#FFFFFF',
    },
    contentDialog: {
        fontSize: '16px',
        height: '68px',
        borderBottom: '1px solid #EEEDF2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    dialogTitleBackground: theme.primaryColor,
    errorColor: theme.errorColor,
    root: theme.dataGridStyle,
    overlay: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        background: 'rgba(0,0,0,0.1)',
        zIndex: '1300'
    },
    requestError: {
        width: '100%',
        height: '65vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20px',
        color: '#6D6D6D'
    },
    font: {
        fontSize: '16px',
        lineHeight: '22.4px',
        color: '#6D6D6D'
    },
    status: {
        color: '#6D6D6D',
        paddingBottom: '0px'
    },
    statusActive: {
        color: '#2AB930',
        paddingBottom: '0px'
    },
    statusDeActived: {
        color: '#e01010',
        paddingBottom: '0px'
    },
    tableTitle: {
        position: 'relative',
        height: '56px',
        border: '1px solid rgba(224, 224, 224, 1)',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px'
    },
    tableStyle:{
        height: '100%', 
        width: '100%', 
        paddingBottom: '15px'
    },
    tableBlockStyle:{
        marginTop: '10px', 
        width: '100%', 
        clear: 'both', 
        height: '60%'
    },
    circularProgressColor:{
        color: '#0b59c8'
    },
    circularProgressBlock:{
        width: '100%', 
        height: '250px', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center'
    },
    headerTitleStyle:{
        fontSize: '22px', 
        fontWeight: 'bold', 
        paddingTop: '23px' 
    }
}))

const CreateButton = withStyles(theme => ({ root: theme.create_button }))(Button);
const CancelButton = withStyles(theme => ({ root: theme.cancel_button }))(Button);
const ErrorButton = withStyles(theme => ({ root: theme.error_button }))(Button);
const DeleteButton = withStyles(theme => ({ root: theme.delete_button }))(Button);
const TextButton = withStyles(theme => ({ root: theme.moreDetail }))(Button);

const ReceptionistSetting = props => {
    const classes = useStyles();
    const [receptionists, setReceptionists] = useState([]);
    const [blocks, setBlocks] = useState([]);
    const [receptionist, setReceptionist] = useState({ 
        name: '', 
        username: '', 
        phone: '', 
        password: '', 
        block: '' 
    });
    const [alert, setAlert] = useState({ 
        name: '', 
        username: '', 
        password: '', 
        phone: '', 
        block: '', 
        success: '', 
        error: '', 
        request: '', 
        dialogMessage: '' 
    });
    const [ids, setIds] = useState([]);
    const [id, setId] = useState('');
    const [isUpdate, setIsUpdate] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(parseInt(localStorage.getItem('rowsPerPage')));
    const [isAdd, setIsAdd] = useState(true);
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [isExpiredSession, setIsExpiredSession] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const alerRef = useRef();

    const columns = [
        {
            field: 'DisplayName',
            headerName: t('Tên lễ tân'),
            cellClassName: 'super-app-theme--cell',
            flex: 1
        },
        {
            field: 'UserName',
            headerName: t('Tên đăng nhập'),
            cellClassName: 'super-app-theme--cell',
            flex: 1
        },
        {
            field: 'Phone',
            headerName: t('Số điện thoại'),
            cellClassName: 'super-app-theme--cell',
            flex: 1,
        },
        {
            field: 'BlockNumber',
            headerName: 'Block',
            cellClassName: 'super-app-theme--cell',
            flex: 0.5,
        },
        {
            field: 'ReceptionistStatus',
            headerName: t('Trạng thái'),
            flex: 1,
            cellClassName: 'super-app-theme--cell',
            sortComparator: (v1, v2, params1, params2) => sortStatus(v1, v2),
            renderCell: params => showStatus(params.row.ReceptionistStatus),
        },
        {
            field: 'Method',
            headerName: t('Thao tác'),
            width: 130,
            sortable: false,
            renderCell: params => <TextButton onClick={() => onEditClick(params.row.id)} >{t('Chỉnh sửa')}</TextButton>
        },
    ]

    useEffect(() => {
        if (isExpiredSession) {
            history.push('/login');
            dispatch({ type: 'IS_LOGIN_AGAIN_TRUE' });
        }
    }, [isExpiredSession]);

    const sortStatus = (v1, v2) => {
        if (v1 > v2) return -1;
        return v1 < v2 ? 1 : 0;
    }

    const showStatus = (status) => {
        switch (status) {
            case 'inactive':
                return (<div className={clsx(classes.status, classes.font)}>
                    <FiberManualRecordIcon style={{ position: 'relative', top: '0px' }} />
                    {t('Chưa đăng nhập')}
                </div>)
            case 'active':
                return (<div className={clsx(classes.statusActive, classes.font)}>
                    <FiberManualRecordIcon style={{ position: 'relative', top: '0px', color: '#2AB930' }} />
                    {t('Đã đổi mật khẩu')}
                </div>)
            case 'deactived':
                return (<div className={clsx(classes.statusDeActived, classes.font)}>
                    <FiberManualRecordIcon style={{ position: 'relative', top: '0px', color: '#e01010' }} />
                    {t('Đã vô hiệu')}
                </div>)
            default: return '';
        }
    }

    const getDefaultValues = async () => {
        try {
            var res = await apiReceptionistGetall();
            const data = [];
            res.data.Items.forEach(item => {
                data.push({ 
                    ...item, 
                    id: item.ID 
                });
            });
            setReceptionists(data);
            setAlert({ 
                ...alert, 
                name: '', 
                username: '', 
                password: '', 
                phone: '', 
                request: '', 
                block: '' 
            });
            setReceptionist({ 
                ...receptionist, 
                name: '', 
                username: '', 
                phone: '', 
                password: '', 
                block: '' 
            });
            setIds([]);
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({ 
                ...alert, 
                request: t('Không lấy được dữ liệu') 
            });
        }
        setLoading(true);
    }

    const getBlocks = async () => {
        var res = await apiGetapartmentdata();
        var blockArr = [];
        res.data.Items.forEach(item => {
            blockArr.push(item.BlockID);
        })
        blockArr.sort()
        blockArr = [...new Set(blockArr)];
        setBlocks(blockArr);
    }

    useEffect(() => {
        if (!loading) getDefaultValues();
    }, [loading])

    useEffect(() => {
        getBlocks();
    }, [])

    const onEditClick = (receptionistID) => {
        setId(receptionistID);
        setIsUpdate(true);
        setIsAdd(false);
    }

    const onCloseEditClick = () => {
        setIsUpdate(false);
        setIsAdd(true);
    }

    const addDataToDB = async (data) => {
        try {
            const recept = { 
                ...data, 
                password: 
                md5(data.password), 
                signing_key: v5(data.username, v5.URL), 
                status: 'inactive' 
            };
            setIsAdding(true);
            await apiReceptionistAdd(recept);
            setAlert({ 
                ...alert, 
                success: t('Thêm lễ tân thành công!') 
            });
            setLoading(false);
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({ ...alert, dialogMessage: t('Thêm lễ tân không thành công, vui lòng thử lại!') });
            setIsFailed(true);
        }
        setIsAdding(false);
    }

    const updateDataToDB = async (data) => {
        try {
            setIsAdding(true);
            await apiReceptionistUpdate(data);
            setAlert({ 
                ...alert, 
                success: t('Lưu lễ tân thành công!') 
            });
            setLoading(false);
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({ 
                ...alert, 
                dialogMessage: t('Lưu lễ tân không thành công, vui lòng thử lại!') 
            });
            setIsFailed(true);
        }
        onCloseEditClick();
        setIsAdding(false);
    }

    const onDeleteClick = () => {
        setConfirmDialog(true);

    }

    const onSelectedChange = (e) => {
        setIds(e);
    }

    const onConfirmDeleteClick = async () => {
        try {
            setIsDeleting(true);
            await apiReceptionistDelete({ ids: ids });
            setAlert({ 
                ...alert, 
                error: '', 
                success: `${t('Xóa thành công')} ${ids.length} ${ids.length > 1 ? t('nhiều lễ tân') : t('lễ tân')}` 
            });
            setLoading(false);
        } catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.data === "Invalid LoginToken") return setIsExpiredSession(true);
            setAlert({ 
                ...alert, 
                error: t('Xoá không thành công, vui lòng thử lại!'), 
                success: '' 
            });
        }
        setConfirmDialog(false);
        setIsDeleting(false);

    }

    const onCloseDialog = () => {
        setAlert({ 
            ...alert, 
            success: '', 
            error: '' 
        });
    }

    useEffect(() => {
        if (alert.success !== '' || alert.error !== '') {
            if (alerRef.current) clearTimeout(alerRef.current);
            alerRef.current = setTimeout(() => {
                onCloseDialog();
            }, 5000);
        }
    }, [alert])

    const onAgreeClick = () => {
        setIsFailed(false);
    }

    const FailedDialog = () => {
        return (
            <Dialog
                open={isFailed}
                maxWidth="xl"
            >
                <DialogTitle className={classes.errorColor}>
                    <p className={classes.dialogTitle}>{t('Thông báo')}</p>
                </DialogTitle>
                <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center' }}>
                    <p className={classes.contentDialog}>{alert.dialogMessage}</p>
                    <ErrorButton
                        style={{ margin: '10px 0' }}
                        onClick={onAgreeClick}
                    >
                        {t('Đồng ý')}
                    </ErrorButton>
                </DialogContent>
            </Dialog>
        )
    }

    const dialogDelete = () => {
        return (
            <Dialog
                open={confirmDialog}
                maxWidth="xl"
            >
                <DialogTitle className={classes.dialogTitleBackground}>
                    <p className={classes.dialogTitle}>{t('Thông báo')}</p>
                </DialogTitle>
                <DialogContent style={{ width: '448px', padding: '0', textAlign: 'center', position: 'relative' }}>
                    <p className={classes.contentDialog}>{t('Bạn có chắc muốn xóa lễ tân?')}</p>
                    <CancelButton
                        style={{ margin: '10px 0' }}
                        onClick={() => { setConfirmDialog(!confirmDialog) }}
                        disabled={isDeleting}
                    >
                        {t('Trở về')}
                    </CancelButton>
                    <CreateButton
                        style={{ margin: '10px 0 10px 24px' }}
                        onClick={onConfirmDeleteClick}
                        disabled={isDeleting}
                    >
                        {t('Đồng ý')}
                    </CreateButton>
                    {isDeleting && <CircularProgress style={{ position: 'absolute', top: '10%', left: '45%', color: '#0b59c8' }} />}
                </DialogContent>
            </Dialog>
        )
    }

    const EmptyRowsView = () => {
        return (
            <div
                style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <h3 style={{ color: '#6D6D6D', fontSize: '16px' }}>
                    {t('Không tìm thấy lễ tân')}
                </h3>
            </div>
        );
    };

    return (
        <div style={{ position: 'relative', width: '97%', margin: 'auto', height: '100%' }}>
            <SuccessAlert 
                message={alert.success} 
                onCloseDialog={onCloseDialog} 
            />
            <ErrorAlert 
                message={alert.error} 
                onCloseDialog={onCloseDialog} 
            />
            {isAdding && <div className={classes.overlay}></div>}
            {FailedDialog()}
            {dialogDelete()}
            <p className={classes.headerTitleStyle}>{t('Danh sách lễ tân')}</p>
            {alert.request !== '' && <div className={classes.requestError}>{alert.request}</div>}
            {loading && isAdd &&
            <AddReceptionist
                blocks={blocks}
                addDataToDB={addDataToDB}
                isAdding={isAdding}
            />}
            {isUpdate &&
            <EditReceptionist
                blocks={blocks}
                onCloseEditClick={onCloseEditClick}
                updateDataToDB={updateDataToDB}
                id={id}
                isAdding={isAdding}
            />}
            {!loading && alert.request === '' && 
            <div className={classes.circularProgressBlock}>
                <CircularProgress className={classes.circularProgressColor} />
            </div>}
            {loading && alert.request === '' && 
            <div className={classes.tableBlockStyle}>
                <div className={classes.tableTitle}>
                    <DeleteButton 
                        variant="outlined" 
                        style={{ margin: '8px' }} 
                        startIcon={<DeleteOutlineOutlinedIcon />} 
                        disabled={ids.length === 0} 
                        onClick={onDeleteClick} 
                    >
                        {t('Xóa')}
                    </DeleteButton>
                </div>
                <div className={clsx(classes.tableStyle,classes.root)}>
                    <DataGrid
                        componentsProps={{
                            pagination: {
                                labelRowsPerPage: t("Số dòng mỗi trang"),
                                labelDisplayedRows: ({ from, to, count }) => { return `${from}-${to} ${t('trong số')} ${count}` }
                            }
                        }}
                        components={{
                            NoRowsOverlay: EmptyRowsView
                        }}
                        localeText={{
                            footerRowSelected: (count) => count + t(" hàng trong bảng đã được chọn")
                        }}
                        rows={receptionists}
                        columns={columns}
                        pageSize={rowsPerPage}
                        checkboxSelection
                        rowsPerPageOptions={[10, 50, 100]}
                        onPageSizeChange={pageSize => {
                            setRowsPerPage(pageSize);
                            localStorage.setItem('rowsPerPage', pageSize);
                        }}
                        onSelectionModelChange={onSelectedChange}
                        hideFooterRowCount
                        disableSelectionOnClick
                        disableColumnMenu={true}
                        localeText={{ columnHeaderSortIconLabel: t('Sắp xếp') }}
                        scrollbarSize={20}
                    />
                </div>
            </div>}
        </div>
    )
}

export default ReceptionistSetting;
